export interface NavIntegrationResult {
    message: string;
    status: string;
}

export class OrderReturnRequestReason {
    code: string;
    instruct: string;
    description: string;
  
    adapt(obj?): OrderReturnRequestReason {
      if (!obj) {
        return this;
      }
  
      this.code = obj.code;
      this.instruct = obj.instruct;
      this.description = obj.description;
  
      return this;
    }
  }
  