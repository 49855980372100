import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTruncate',
})
export class TextTruncatePipe implements PipeTransform {
  transform(value: string, maxLength?: number): any {
    let truncatedStr = '';
    if (value && value.length > maxLength) {
      truncatedStr = value.substring(0, maxLength) + '...';
      return truncatedStr;
    } else {
      return value;
    }
  }
}
