import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { Doctor, DOCTOR_GROUP, STATUS } from '../../objects/state/Doctor';
import { AkitaClinicDoctorStore } from './akita-clinic-doctor.store';

@Injectable({ providedIn: 'root' })
export class AkitaClinicDoctorQuery extends QueryEntity<Doctor> {
  activeDoctor$ = this.selectAll({
    filterBy: (entity: Doctor) => entity.status === STATUS.ACTIVE,
  });
  anchorDoctor$ = this.selectAll({
    filterBy: (entity: Doctor) => entity.doctorGroup === DOCTOR_GROUP.ANCHOR,
  });
  otherDoctors$ = this.selectAll({
    filterBy: (entity: Doctor) => entity.doctorGroup !== DOCTOR_GROUP.ANCHOR,
  });

  constructor(protected store: AkitaClinicDoctorStore) {
    super(store);
  }

  getActiveDoctor() {
    return this.getAll({
      filterBy: (entity: Doctor) => entity.status === STATUS.ACTIVE,
      sortBy: AkitaClinicDoctorQuery.sortByIdAsc
    });
  }

  getAnchorDoctor() {
    return this.getAll({
      filterBy: (entity: Doctor) => entity.doctorGroup === DOCTOR_GROUP.ANCHOR,
    });
  }

  getActiveAnchorDoctor() {
    return <Doctor[]>this.getAll({
      filterBy: (entity: Doctor) =>
        entity.doctorGroup === DOCTOR_GROUP.ANCHOR &&
        entity.status === STATUS.ACTIVE,
    });
  }

  getActiveOtherDoctor() {
    return <Doctor[]>this.getAll({
      filterBy: (entity: Doctor) =>
        entity.doctorGroup !== DOCTOR_GROUP.ANCHOR &&
        entity.status === STATUS.ACTIVE,
    });
  }

  getOtherDoctor() {
    return this.getAll({
      filterBy: (entity: Doctor) => entity.doctorGroup !== DOCTOR_GROUP.ANCHOR,
    });
  }

  selectActiveDoctors() {
    return this.selectAll({ filterBy: (entity: Doctor) => entity.status === STATUS.ACTIVE });
  }

  selectActiveAnchorDoctor() {
    return this.selectAll({
      filterBy: (entity: Doctor) =>
        entity.doctorGroup === DOCTOR_GROUP.ANCHOR &&
        entity.status === STATUS.ACTIVE,
    });
  }

  selectActiveOtherDoctor() {
    return this.selectAll({
      filterBy: (entity: Doctor) =>
        entity.doctorGroup !== DOCTOR_GROUP.ANCHOR &&
        entity.status === STATUS.ACTIVE,
    });
  }

  isDoctorOnline(doctorId: string, clinicID: string): boolean {
    const doctor = this.getEntity(doctorId) as Doctor;
    if (!!doctor && !!doctor.doctorClinicToggles) {
      return doctor.doctorClinicToggles[clinicID];
    }
    return false;
  }

  selectDoctorOnline(doctorId: string, clinicID: string): Observable<boolean> {
    return this.selectEntity(
      doctorId,
      (doctorEntity: Doctor) => !!doctorEntity.doctorClinicToggles && doctorEntity.doctorClinicToggles[clinicID])
  }

  static readonly sortByIdAsc = (a: Doctor, b: Doctor, state) => {
    return a.id.toUpperCase() < b.id.toUpperCase()
      ? -1
      : a.id.toUpperCase() > b.id.toUpperCase()
      ? 1
      : 0;
  };

}
