<form [formGroup]="" novalidate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="pb-2">
            You have uploaded the following documents as part of a multi-upload process. In order to add these to the
            patients documents, you must fill up the documents respective name and description and hit save to apply
            changes to the uploaded documents.
        </div>
        <div class="row bg-grey-400 pt-2 pb-2 ml-0 mr-0 mb-2">
            <div class="col-md-2">
                <label class="mb-0">Document</label>
            </div>
            <div class="col-4">
                <label class="mb-0 required">Description
                </label>
            </div>
            <div class="col-md-2">
                <label class="mb-0">File Type</label>
            </div>
            <div class="col-md-2">
                <label class="mb-0">Size (KB)</label>
            </div>
            <div class="col-md-2">
                <label class="mb-0">Type</label>
            </div>
        </div>
        <form *ngFor="let form of formGroup.get('newDocumentsArray')['controls']; let i = index;" [formGroup]="form"
            novalidate>
            <div class="row pt-2 pb-2 ml-0 mr-0 mb-2">
                <div class="col-md-2 break-word">
                    {{form.value.document}}
                </div>
                <div class="col-4">
                    <input class="form-control form-control-sm" formControlName="description" />
                </div>
                <div class="col-md-2">
                    <span>{{form.value.type.split('.').slice(-1)}}</span>
                </div>
                <div class="col-md-2">
                    <span>{{form.value.size/1024 | number:'.2'}}</span>
                </div>
                <div class="col-2">
                    <div class="row">
                        <div class="col-md-10">
                            <ng-select
                                class="p-0 form-control form-control-sm custom-select  custom-select-extra-medium-width"
                                [clearable]=false [items]="documentTypes" placeholder="Select Type"
                                formControlName="listType">
                            </ng-select>
                        </div>
                        <div class="col-md-1" dropdown>
                            <button dropdownToggle type="button" class="btn btn-mini p-0" tabindex="-1">...</button>
                            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                                <li role="menuitem">
                                    <a class="dropdown-item" (click)="onDelete(form, i)">Delete</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row pt-2">
            <div class="col-md-12">
                <div class="btn-toolbar float-right" role="toolbar">
                    <div class="btn-group" role="group">
                        <input type="file" ng2FileSelect (onFileSelected)="fileUpload($event)" [uploader]="uploader"
                            multiple />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="uploader.queue.length > 5" class="row pt-2 pl-2">
            Max file number per upload is 5.
        </div>
        <div *ngIf="totalSize > 10 * 1024 * 1024" class="row pt-2 pl-2">
            Max file size per upload is 10MB.
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-pink" (click)="onSave()"
            [disabled]="uploader.queue.length > 5 || totalSize > 10 * 1024 * 1024 || !isDescriptionFilled">Save</button>
    </div>
</form>
