import { FormArray } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-patient-payable',
  templateUrl: './patient-payable.component.html',
  styleUrls: ['./patient-payable.component.scss'],
})
export class PatientPayableComponent implements OnInit {
  @Input() patientPayables: FormArray;
  constructor() {}

  ngOnInit() {}
}
