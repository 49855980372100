import { AbstractControl, ValidationErrors } from '@angular/forms';

export class StringValidators {
  static noSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).includes(' ')) {
      return { noSpace: true };
    }

    return null;
  }

  static containsSimple(control: AbstractControl): ValidationErrors | null {
    if (!/[a-z]/.test(control.value as string)) {
      return { noSimple: true };
    }

    return null;
  }

  static containsCapital(control: AbstractControl): ValidationErrors | null {
    if (!/[A-Z]/.test(control.value as string)) {
      return { noCapital: true };
    }

    return null;
  }

  static containsNumber(control: AbstractControl): ValidationErrors | null {
    if (!/[0-9]/.test(control.value as string)) {
      return { noNumber: true };
    }

    return null;
  }

  static containsSpecialCharacters(control: AbstractControl): ValidationErrors | null {
    if (!/[~`!@#$%^&*()__+=]/.test(control.value as string)) {
      return { noSpecialCharacters: true };
    }

    return null;
  }

  static containsASCIICharacters(control: AbstractControl): ValidationErrors | null {
    if (!/^[\x00-\x7F\xA9\xAE\xB0\xB1\xBC-\xBE\u2122]*$/.test(control.value as string)) {
      return { onlyASCIICharacters: true };
    }
    return null;
  }
}
