import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { createMemoObject } from '../../../objects/state/Case';
import { AlertService } from '../../../services/alert.service';
import { ApiCmsManagementService } from '../../../services/api-cms-management.service';
import { AkitaCaseVisitQuery } from '../../../services/states/akita-case-visit.query';
import { AkitaNoteTemplateQuery } from '../../../services/states/akita-note-template.query';
import { AkitaPatientAppQuery } from '../../../services/states/akita-patient/akita-patient-app.query';
import { PatientVisitHistoryQuery } from '../../../services/states/akita-patient/patient-visit-history/patient-visit-history.query';
import { StoreService } from '../../../services/store.service';
import { VisitManagementService } from '../../../services/visit-management.service';

@Component({
  selector: 'app-consultation-memo',
  templateUrl: './consultation-memo.component.html',
  styleUrls: ['./consultation-memo.component.scss'],
})
export class ConsultationMemoComponent implements OnInit, OnDestroy {

  @Input() memos: FormArray;

  codes: string[];
  isModal: boolean;
  isPaymentMode: boolean;
  title: string;
  visitId = '';

  private componentDestroyed: Subject<void> = new Subject();

  public event: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaNoteTemplateQuery: AkitaNoteTemplateQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private apiCmsManagementService: ApiCmsManagementService,
    private permissionsService: NgxPermissionsService,
    private patientVisitHistoryQuery: PatientVisitHistoryQuery,
    private store: StoreService,
    private fb: FormBuilder,
    private visitManagementService: VisitManagementService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.isPaymentMode = this.store.getVisitStatus() === 'PAYMENT' ? true : false;


    this.subscribeChanges();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  subscribeChanges() {
    this.akitaCaseVisitQuery.selectActiveId().pipe(
      filter(activeId => !!activeId)
    ).subscribe(activeId => {
      this.visitId = String(activeId);
    });

    this.store.getPatientVisitIdRefresh().pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      takeUntil(this.componentDestroyed)
    ).subscribe(res => {
      this.isPaymentMode = this.store.getVisitStatus() === 'PAYMENT' ? true : false;
    });

    this.store.getVisitStatusRefresh().pipe(takeUntil(this.componentDestroyed)).subscribe(res => {
      this.isPaymentMode = this.store.getVisitStatus() === 'PAYMENT' ? true : false;
    });
  }

  loadMemoTemplates() {
    return this.akitaNoteTemplateQuery.memoTemplateList$;
  }

  onMemoTemplateChange(event, index) {
    const visitId = this.store.getPatientVisitRegistryId() ?
      this.store.getPatientVisitRegistryId() : '' + this.patientVisitHistoryQuery.getActiveId();

    if (event) {
      this.apiCmsManagementService
        .loadTemplateWithVisitId(
          event.type,
          event.id,
          this.store.getUser().context['cms-user-id'],
          this.akitaPatientAppQuery.getId(),
          visitId
        )
        .subscribe(
          res => {
            if (res.payload) {
              const data = this.memos.at(index).get('memo').value ? this.memos.at(index).get('memo').value : '';

              this.memos.at(index).get('memo').patchValue(data + '<br>' + res.payload);
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err));
          }
        );
    }
  }

  addMemo() {
    this.memos.push(this.fb.group(createMemoObject()));
  }

  deleteMemo(index) {
    this.memos.removeAt(index);
  }

  printMemo() {
    const currentMemoValue = this.memos.value.filter(memo => memo.memo.trim().length > 0);
    
    if (currentMemoValue.length > 0) {
      this.visitManagementService.setUpdateNPrintMemo(currentMemoValue);
    }
  }

  isDoctor() {
    return this.permissionsService.getPermission('ROLE_DOCTOR');
  }

  onBtnSaveClicked() {
    this.event.emit({ formName: 'CONSULTATION', key: 'memos', value: this.memos.value });
  }

  onBtnCloseClicked() {
    this.event.emit({ formName: 'CLOSE' });
  }

  isMemoPrintDisabled() {
    return this.memos.value.length === 0 || this.memos.value.every(memo => memo.memo.trim().length === 0);
  }
}
