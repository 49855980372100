import { Injectable } from "@angular/core";

import { QueryEntity } from '@datorama/akita';

import { AkitaPatientSourceStore, PatientSource } from './akita-patient-source.store';

@Injectable({ providedIn: 'root' })
export class AkitaPatientSourceQuery extends QueryEntity<PatientSource> {

  constructor(protected store: AkitaPatientSourceStore) {
    super(store);
  }

}
