import { GRNItem } from './OrderGRN';
import * as moment from 'moment'
import { DISPLAY_DATE_FORMAT } from '../constants/app.constants';

import { NavIntegrationResult } from './CommonResponses';

export class DeliveryOrder {
  id: string;
  disabled: boolean;
  delivererId: string;
  receiverId: string;
  createDate: string;
  sendDate: string;
  dnNo: string;
  transferSendItems: Array<DeliveryOrderItem>;
  status: DeliveryOrderStatus;
  courier: string;
  trackingNo: string;
  additionalRemark: string;
  navIntegrationResult: NavIntegrationResult;

  adapt(obj?): DeliveryOrder {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.delivererId = obj.delivererId;
    this.receiverId = obj.receiverId;
    this.createDate = obj.createDate;
    this.sendDate = obj.sendDate;
    this.dnNo = obj.dnNo;
    this.transferSendItems = (obj.transferSendItems || []).map(item =>
      new DeliveryOrderItem().adapt(item)
    );
    this.status = obj.status;
    this.courier = obj.courier;
    this.trackingNo = obj.trackingNo;
    this.additionalRemark = obj.additionalRemark;
    this.navIntegrationResult = obj.navIntegrationResult;

    return this;
  }

  getItemQuantity(itemId: string): number {
    return this.transferSendItems
      .filter(item => item.itemRefId === itemId)
      .reduce((sum, item) => (sum += item.quantity), 0);
  }
}

export class DeliveryOrderItem {
  itemRefId: string;
  uom: string;
  batchNumber: string;
  quantity: number;
  expiryDate: string;
  manufacturerDate: string;
  comment: string;
  lineNo: string;
  purchasePrice: any;

  adapt(obj?): DeliveryOrderItem {
    if (!obj) {
      return this;
    }

    this.itemRefId = obj.itemRefId;
    this.uom = obj.uom;
    this.batchNumber = obj.batchNumber;
    this.quantity = obj.quantity;
    this.expiryDate = obj.expiryDate;
    this.manufacturerDate = obj.manufacturerDate;
    this.comment = obj.comment;
    this.lineNo = obj.lineNo;
    this.purchasePrice = obj.purchasePrice;

    return this;
  }

  toGRNItem(): GRNItem {
    const grnItem = new GRNItem();
    grnItem.itemRefId = this.itemRefId;
    grnItem.uom = this.uom;
    grnItem.batchNumber = this.batchNumber;
    grnItem.quantity = this.quantity;
    grnItem.receiptDate = moment().format(DISPLAY_DATE_FORMAT);
    grnItem.expiryDate = this.expiryDate;
    grnItem.manufacturerDate = this.manufacturerDate;
    grnItem.lineNo = this.lineNo;
    grnItem.purchasePrice = this.purchasePrice;
    return grnItem;
  }
}

export enum DeliveryOrderStatus {
  DRAFT = 'DRAFT',
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
  FAILED = 'FAILED',
}