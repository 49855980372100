import { PrintTemplateService } from './../../../services/print-template.service';
import { AkitaChargeItemQuery } from './../../../services/states/akita-charge-item.query';
import { VisitManagementService } from './../../../services/visit-management.service';
import { ConsultationFormService } from './../../../services/consultation-form.service';
import { AbstractControl, FormArray, Form, FormGroup } from '@angular/forms';
import { StoreService } from './../../../services/store.service';
import { PvmTabsService } from './../../../services/pvm-tabs.service';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import {
  DB_FULL_DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
} from '../../../constants/app.constants';
import * as moment from 'moment'
import { Observable } from 'rxjs';
import { IPackageItem, IDispaches } from './package.model';
import { tap, takeUntil, distinctUntilChanged, map, take } from 'rxjs/operators';
import { AkitaCaseVisitQuery } from '../../../services/states/akita-case-visit.query';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Case } from '../../../objects/Case';
import RefreshSetting from '../../../objects/RefreshSetting';
import { NgxPermissionsService } from 'ngx-permissions';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss'],
})
export class PackageComponent implements OnInit, OnDestroy {
  packageItems$: Observable<IPackageItem[]>;
  @Output() deletePackageItem: EventEmitter<any>;
  @Output() packageClicked = new EventEmitter();
  @Output() onSubItemPriceAdjustment: EventEmitter<any> = new EventEmitter();
  @Output() onItemChecked: EventEmitter<any>;
  case: Case;
  @Input() selectedPlans;
  @Input() itemsFormArray: FormArray;
  @Input() vaccineDosages;
  // showPlansInclusion: boolean;
  // showInventory: boolean;
  inDispensing: boolean;
  isSubsequentCaseForPackage = false;
  isDoctor: boolean = true;

  enablePrintBtn$;

  constructor(
    // private akitaPackageItemQuery: AkitaPackageItemQuery,
    private caseVisitQuery: AkitaCaseVisitQuery,
    private pvmTabs: PvmTabsService,
    private visitManagementService: VisitManagementService,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private printTemplateService: PrintTemplateService,
    private permissionsService: NgxPermissionsService,
    private alertService: AlertService,
  ) {
    this.deletePackageItem = new EventEmitter();
    this.onItemChecked = new EventEmitter();

    this.isSubsequentCaseForPackage = this.caseVisitQuery.isSubsequentVisitForPackagedCase();

    this.enablePrintBtn$ = this.caseVisitQuery.selectHasActiveUtilizeableSubItems();

    this.permissionsService
      .hasPermission('ROLE_DOCTOR')
      .then(res => {
        this.isDoctor = res;
      });

    this.packageItems$ = this.caseVisitQuery
      .selectPackagesForActiveVisit()
      .pipe(
        map(packageItems => {
          return (this.itemsFormArray && !this.caseVisitQuery.isSubsequentVisitForPackagedCase() ) ?  
            this.sortAccordingToDispenseArray(packageItems) : packageItems;
         }
        ),
        untilDestroyed(this)
      );

    this.packageItems$.subscribe(res=> {
      if (res && res.length === 0)
      this.alertService.setPackageItemUpdated(true);
    })

    this.pvmTabs.getActiveTabSwitch().pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      )
      .subscribe(value => {
  
      this.inDispensing = this.pvmTabs.isDispensingTab();
    });
  }

  sortAccordingToDispenseArray(packageItems: IPackageItem[]){
    let packageIndexesInDispense = this.getPackageIndexesInDispatchEntities();
    let result = [];

    // now sort packages according to the indexes 
    packageIndexesInDispense.forEach( (dispensePackage) => {
      const packageFound: IPackageItem = packageItems.find( (p: IPackageItem) => p.packageId === dispensePackage.packageId);
      if(packageFound){
        result.push(packageFound);
      }
    })
  
    return result;
  }
  
  getPackageIndexesInDispatchEntities(){
    return this.itemsFormArray.controls
      .filter( fg => fg.get('itemType').value === 'PACKAGE')
      .map( (fg,index) =>  {
        return {
        'packageId': fg.get('packageId').value,
        'index':  index
        }
      });
  }

  onBtnPrintDrugLabel(){
    this.printTemplateService.onBtnPrintDrugLabelAfterSaving(undefined, true);
  }

  getItemDetailedInfo(itemId) {
    return this.akitaChargeItemQuery.getChargeItem(itemId);
  }

  // getCaseDetails() {
  //   this.storeService
  //     .getCurrentPatientCaseDataLoaded()
  //     .pipe(untilDestroyed(this))
  //     .subscribe((data: Case) => {

  //       this.populateData(data);
  //     });
  // }

  // populateData(data: Case) {
  //   this.case = data;
  //   if (this.case) this.plansInSO = this.case.coverages;

  // }s

  ngOnInit() {
    this.inDispensing = this.pvmTabs.isDispensingTab();
    // this.getCaseDetails();
  }

  ngOnDestroy() {}
}
