<div class="card">
  <div class="card-header p-2">
    Alert
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="card-body">
    <alert></alert>

    <div class="row font-weight-semi-bold py-2">
      <div class="col-md-12">
        {{text}}
      </div>
    </div>

    <div class="row flex-row-reverse">
      <div class="col-md-2 m-1 p-0" *ngIf='title!="LOCUM"'><button class="btn btn-md btn-brand-primary text-white w-100"
          (click)="action(true)">Yes</button></div>
      <div class="col-md-2 m-1 p-0" *ngIf='title!="LOCUM"'><button class="btn btn-md btn-brand-info text-white w-100"
          (click)="action(flase)"> NO</button></div>

      <div class="col-md-2 m-1 p-0" *ngIf='title=="LOCUM"'><button class="btn btn-md btn-brand-info text-white w-100"
          (click)="action(flase)"> OK</button></div>
    </div>
  </div>
</div>