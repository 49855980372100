import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpResponseBody } from './../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ApiAppointmentsService {
  private API_APPOINTMENT_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) {
    this.API_APPOINTMENT_URL = this.appConfig.getConfig().API_APPOINTMENT_URL;
  }

  create(appointment: any): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/create`,
      JSON.stringify(appointment)
    );
  }

  update(
    appointmentId: string,
    appointment: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/update/${appointmentId}`,
      JSON.stringify(appointment)
    );
  }

  remove(appointmentId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/remove/${appointmentId}`,
      {}
    );
  }

  listAppointmentsByDoctor(
    doctorId: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/list/doctor/${doctorId}/${startDate}/${endDate}`,
      {}
    );
  }

  listAppointmentsByClinicId(
    clinicId: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/list/clinic/${clinicId}/${startDate}/${endDate}`,
      {}
    );
  }

  searchAppointment(filter, startDate, endDate, noDoctorPreferred) {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/search/${startDate}/${endDate}/${noDoctorPreferred}`,
      JSON.stringify(filter)
    );
  }

  searchAppintmentsByPatientParameters(requestBody){
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/search`,
      JSON.stringify(requestBody)
    );
  }

  findCalendarByClinicId(clinicId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/find/calendar/${clinicId}`,
      {}
    );
  }

  findCalendarByDoctorId(
    clinicId: string,
    doctorId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/find/calendar/${clinicId}/${doctorId}`,
      {}
    );
  }

  findCalendarByDoctorList(
    clinicId: string,
    doctorList: string[]
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/list/calendar/${clinicId}`,
      JSON.stringify(doctorList)
    );
  }

  checkAvailability(
    clinicId: string,
    doctorId: string,
    dateTime: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/available/doctor/${clinicId}/${doctorId}/${dateTime}`,
      {}
    );
  }

  addClinicBlockTime(
    clinicId: string,
    startTime: string,
    endTime: string,
    payload: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/add/block-time/${clinicId}/${startTime}/${endTime}`,
      JSON.stringify(payload)
    );
  }

  updateClinicBlockTime(
    clinicId: string,
    blockList: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/update/block/${clinicId}`,
      JSON.stringify(blockList)
    );
  }

  deleteClinicBlockTime(
    clinicId: string,
    dateTime: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/remove/block-time/${clinicId}/${dateTime}`,
      {}
    );
  }

  addDoctorBlockTime(
    clinicId: string,
    doctorId: string,
    startTime: string,
    endTime: string,
    payload: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/add/block-time/${clinicId}/${doctorId}/${startTime}/${endTime}`,
      JSON.stringify(payload)
    );
  }

  updateDoctorBlockTime(
    clinicId: string,
    doctorId: string,
    blockList: any
  ): Observable<HttpResponseBody> {
    
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/update/block/${clinicId}/${doctorId}`,
      JSON.stringify(blockList)
    );
  }

  updateDoctorLeave(
    clinicId: string,
    doctorId: string,
    blockList: any
  ): Observable<HttpResponseBody> {
    
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/update/leave/${clinicId}/${doctorId}`,
      JSON.stringify(blockList)
    );
  }

  deleteDoctorBlockTime(
    clinicId: string,
    doctorId: string,
    dateTime: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/remove/block-time/${clinicId}/${doctorId}/${dateTime}`,
      {}
    );
  }

  addDoctorLeave(
    clinicId: string,
    doctorId: string,
    startTime: string,
    endTime: string,
    payload: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/add/leave/${clinicId}/${doctorId}/${startTime}/${endTime}`,
      JSON.stringify(payload)
    );
  }

  deleteDoctorLeave(
    clinicId: string,
    doctorId: string,
    dateTime: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/remove/leave/${clinicId}/${doctorId}/${dateTime}`,
      {}
    );
  }

  updateArrivalStatus(appointmentId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/update/arrived/${appointmentId}`,
      {}
    );
  }

  listAvailableTimeByDoctor(
    clinicId: string,
    date: string,
    doctorId: []
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_APPOINTMENT_URL}/available/time-slot/${clinicId}/${date}`,
      JSON.stringify(doctorId)
    );
  }

  listAvailableTimeByClinic(
    clinicId: string,
    date: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/available/time-slot/clinic/${clinicId}/${date}`,
      {}
    );
  }

  getServices(
    clinicId: string,
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/public-services/get/calendar/${clinicId}`,
      {}
    );
  }

  addServiceBlockTime(
    group: string,
    service: string,
    clinicId: string,
    startTime: string,
    endTime: string,
    payload: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/public-services/add/block-time/${group}/${service}/${clinicId}/${startTime}/${endTime}`,
      JSON.stringify(payload)
    );
  }

  updateServiceBlockTime(
    group: string,
    service: string,
    clinicId: string,
    blockList: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/public-services/update/block-time/${group}/${service}/${clinicId}`,
      JSON.stringify(blockList)
    );
  }

  deleteServiceBlockTime(
    group: string,
    service: string,
    clinicId: string,
    startTime: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_APPOINTMENT_URL
      }/public-services/remove/block-time/${group}/${service}/${clinicId}/${startTime}`,
      {}
    );
  }
}
