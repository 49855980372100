import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  map,
} from 'rxjs/operators';

// General Libraries
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';

// Services
import { AlertService } from './../../../services/alert.service';
import { INPUT_DELAY } from './../../../constants/app.constants';
import { ApiPatientVisitService } from './../../../services/api-patient-visit.service';
import { StoreService } from '../../../services/store.service';

//Objects
import { PatientVisitList } from './../../../objects/response/PatientVisitList';
import ConsultationDocument, {
  createConsultationDocument,
} from '../../../objects/ConsultationDocument';

@Component({
  selector: 'app-consultation-documents',
  templateUrl: './consultation-documents.component.html',
  styleUrls: ['./consultation-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultationDocumentsComponent implements OnInit, OnDestroy {
  @Input() needRefresh: BehaviorSubject<PatientVisitList>;
  @Input() patientVisitHistory: PatientVisitList;

  private componentDestroyed: Subject<void> = new Subject();

  // documents: any;
  originalFlatDocuments: ConsultationDocument[];
  flatDocuments: ConsultationDocument[];
  // pageLimit = 25;

  documentSubject: Subject<Array<ConsultationDocument>>;
  documents$: Observable<Array<ConsultationDocument>>;

  constructor(
    private apiPatientVisitService: ApiPatientVisitService,
    private store: StoreService,
    private alertService: AlertService
  ) {
    this.documentSubject = new Subject();

    this.documents$ = this.documentSubject.pipe(
      map(data => {
        this.flatDocuments = [];
        if (data && data.length) {
          data.forEach(document => {
            this.flatDocuments.push(createConsultationDocument(document));
          });
          return this.flatDocuments;
        }
      }),
      takeUntil(this.componentDestroyed)
    );
  }

  ngOnInit() {
    this.flatDocuments = [];

    this.needRefresh
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(patientHistory => {
        this.patientVisitHistory = patientHistory;
        this.refreshDocuments();
      });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  refreshDocuments() {
    if (this.patientVisitHistory) {
      this.getDocumentsByVisit();
    }
  }

  getDocumentsByVisit() {
    this.apiPatientVisitService
      .listDocuments('visit', this.patientVisitHistory.visitId)
      .pipe(debounceTime(INPUT_DELAY), distinctUntilChanged())
      .subscribe(
        res => {
          this.documentSubject.next(res.payload as Array<ConsultationDocument>);
        },
        err => console.error(err)
      );
  }

  onDownloadNewDocument(config, fileId, fileName) {
    this.apiPatientVisitService
      .downloadDocument(config, this.patientVisitHistory.visitId, fileId)
      .subscribe(
        res => {
          saveAs(res, fileName);
        },
        err => this.alertService.error(JSON.stringify(err))
      );
  }
}
