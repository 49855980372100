import { HttpResponseBody } from './../objects/response/HttpResponseBody';
import { map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AkitaCdlensVisitStore } from './states/akita-cdlens-visit-case.store';

@Injectable()
export class ApiCdlensVisitsService implements OnDestroy {
  private API_SMART_CMS_URL;
  private API_CMS_MANAGEMENT_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private akitaCdlensVisitStore: AkitaCdlensVisitStore
  ) {
    this.API_SMART_CMS_URL = appConfig.getConfig().API_SMART_CMS_URL;
    this.API_CMS_MANAGEMENT_URL = appConfig.getConfig().API_CMS_MANAGEMENT_URL;
  }



  ngOnDestroy() { }

  setActiveCdlensVisitId(visitId: string) {
    this.akitaCdlensVisitStore.setActive(visitId);
  }

  getCdlensMasterData(){
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/master-store/list/CDLENS_TB_SETUP_MAPPING`,
      {}
    );
  }

  listCdlensVisit(
    clinicId: string,
    fromDate: string,
    toDate: string,
    searchValue: string,
    cdlensSubmissionStatus: string
  ): void {
    this.http
      .post(
        `${this.API_SMART_CMS_URL}/cdlens/search/${clinicId}`,
        JSON.stringify({
          fromDate,
          toDate,
          searchValue,
          cdlensSubmissionStatus
        })
      )
      .pipe(
        map((res: HttpResponseBody) => {
          return res.payload.map(item => {
            const {
              visitRegistryEntity,
              patientEntity,
              cdlensSubmission,
              cdlensSubmissionStatus,
              cdlensMd532Submission,
              cdlensMd532SubmissionStatus,
              visitRegistryEntity: { visitId },
            } = item;
            return {
              visitRegistryEntity,
              patientEntity,
              cdlensSubmission,
              cdlensSubmissionStatus,
              cdlensMd532Submission,
              cdlensMd532SubmissionStatus,
              visitId,
            };
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(cdlensVisitCase => this.akitaCdlensVisitStore.set(cdlensVisitCase));
  }

  submitCdlens(payload) {
    return this.http.post<HttpResponseBody>(
      `${this.API_SMART_CMS_URL}/cdlens/submit`,
      JSON.stringify(payload)
    );
  }
}
