import { AkitaCaseVisitQuery } from './../../../../../services/states/akita-case-visit.query';
import { distinctUntilChanged, pairwise, takeUntil, startWith } from 'rxjs/operators';
import { AbstractControl, FormControl, Validators, FormArray } from '@angular/forms';
import { ConsultationFormService } from './../../../../../services/consultation-form.service';
import { IDispaches } from './../../package.model';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../../../constants/app.constants';
import { Subject } from 'rxjs';
import { DispatchItemService } from '../../../../../services/dispatch-item.service';

@Component({
  selector: 'app-package-sub-item-batch-no',
  templateUrl: './package-sub-item-batch-no.component.html',
  styleUrls: ['./package-sub-item-batch-no.component.scss'],
})
export class PackageSubItemBatchNoComponent implements OnInit {
  @Input() subItem: IDispaches;
  @Input() packageId: string;
  @Input() disabled: boolean;

  batchNoSelected;

  batchNo: FormControl;

  items: any[];

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private consultationFormService: ConsultationFormService,
    private caseVisitQuery: AkitaCaseVisitQuery,
    private dispatchItemService: DispatchItemService
  ) {}

  ngOnInit() {
    this.getInventory();

    this.initaliseBatchNo();

    this.subscribeOnBatchNoChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.disabled) {
     
      if (this.batchNo) {
        this.batchNo.disable();
      } else {
        if (this.batchNo) {
          this.batchNo.enable();
        }
      }
    }
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  subscribeOnBatchNoChanges() {
    this.batchNo
      .valueChanges.pipe(
        distinctUntilChanged(),
        startWith(this.batchNo.value),
        pairwise(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(([previousBatchNo, newBatchNo]: [any, any]) => {
        let expiryDate = this.subItem.expiryDate;
        let batchNo = this.subItem.batchNo;

        // Delete previous batch subscription
        if (previousBatchNo !== undefined && previousBatchNo !== null) {
          this.dispatchItemService
          .deleteDrugSubscriptionFromBatch(
            this.subItem.itemId,
            previousBatchNo,
            this.subItem.drugDispenseShortId
          );
        }

        // Add new batch subscription
        if (newBatchNo === null) {
          this.dispatchItemService
            .setDrugDispenseQtyFromBatch(
              this.subItem.itemId,
              newBatchNo,
              0,
              this.subItem.drugDispenseShortId
            );
          batchNo = null;
          expiryDate = this.subItem.expiryDate;
        } else {
          this.dispatchItemService
            .setDrugDispenseQtyFromBatch(
              this.subItem.itemId,
              newBatchNo,
              1,
              this.subItem.drugDispenseShortId
            );
          batchNo = newBatchNo;
          expiryDate = this.items.find(item => item.batchNo === newBatchNo).expiryDate;
        }

        const updatedSubItem: IDispaches = {
          ...this.subItem,
          batchNo: newBatchNo,
          expiryDate,
        };

        this.caseVisitQuery.updateSubItemForActiveVisit(
          this.packageId,
          updatedSubItem
        );
      });


    this.dispatchItemService
    .getUpdateAllInventoriesRefresh()
    .subscribe(value => {
      if (value) {
        this.getInventory();
      }
    });

  }

  initaliseBatchNo() {
    this.batchNo = new FormControl('', Validators.required);
    if (this.subItem.batchNo) {
      this.batchNo.patchValue(this.subItem.batchNo);
      this.batchNo.markAsTouched();
    }

    if (this.disabled) {
      this.batchNo.disable();
    } else {
      this.batchNo.enable();
    }
  }

  getInventory() {
    const itemId = this.subItem.itemId;
    this.items = this.dispatchItemService
      .getDrugBatchDropdownList(itemId);
  }

  inventoryFieldsDisabled(): boolean {
    if (this.subItem.inventoried) {
      var utilizedDate = moment(this.subItem.utilizedDate, DISPLAY_DATE_FORMAT);
      if (
        this.subItem.utilizedDate.length > 0 &&
        moment().diff(utilizedDate) < 0
      ) {
        return true;
      } else {
        if (this.subItem.utilize) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }
}
