<!-- <pre> {{ subItem | json }} </pre> -->
<ng-select style="cursor:help;" class="p-0 form-control form-control-sm custom-select custom-select-extra-extra-medium-width" bindLabel="batchNo" bindValue="batchNo" [formControl]="batchNo"
[items]="items" placeholder="Select Batch Number" [clearable]=false appendTo="body">
    <ng-template ng-header-tmp>
        <div class="row">
            <div class="col-md-3 font-weight-semi-bold">Batch No</div>
            <div class="col-md-3 font-weight-semi-bold">Expiry Date</div>
            <div class="col-md-3 font-weight-semi-bold">Stock Bal.</div>
            <div class="col-md-3 font-weight-semi-bold">Remaining Bal.</div>
        </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="row">
            <div class="col-md-3">{{item.batchNo}}</div>
            <div class="col-md-3">{{item.expiryDate}}</div>
            <div class="col-md-3 text-right">{{item.stockBalance}}</div>
            <div [ngClass]="item.remainingBalance < 0 ? 'invalidItemText' : ''" class="col-md-3 text-right">
              {{item.remainingBalance}}</div>
        </div>
    </ng-template>
</ng-select>