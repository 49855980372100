export class Page {
  // The number of elements in the page


  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber = 0;

  constructor(pageNumber = 0, pageSize = 10 ) {
    this.pageNumber = pageNumber;
    this.size = pageSize;
  }
}
