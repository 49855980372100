import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'displayReturnRequestStatusColorCssClass'
})
export class DisplayReturnRequestStatusColorCssClassPipe implements PipeTransform {

  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    return this.inventory.getReturnRequestStatusColorClass(value)
  }

}
