import { Injectable } from '@angular/core';
import { QueryEntity, EntityUIQuery } from '@datorama/akita';
import {
  AkitaPCNVisitStore,
  AkitaPCNVisitState,
} from './akita-pcn-visit-case.store';
import {
  PCNVisitCase,
  IPCNVisitCase,
  SmartCmsRegistry,
} from '../../objects/response/pcn/PCNVisitCase';
import { Observable } from 'rxjs';
import PCNDetails, {
  PCNPatientExtraDetails,
  AssessmentResult,
  VitalTestTakenEntity,
  AssessmentTestTakenEntity,
  OnGoingMedication,
} from '../../objects/response/pcn/PCNDetails';
import { map, filter } from 'rxjs/operators';
import MedicalConditions from '../../objects/response/MedicalConditions';
import { VitalResponseData } from '../../objects/response/VitalResponseData';

@Injectable({ providedIn: 'root' })
export class AkitaPCNVisitQuery extends QueryEntity<
AkitaPCNVisitState,
IPCNVisitCase
> {
  constructor(protected store: AkitaPCNVisitStore) {
    super(store);
  }

  getSelectedPCNVisitDetails(): Observable<PCNDetails> {
    return this.selectActive(
      entity => {
       return entity.registryEntity.medicalReferenceEntity.cdmpDetails;
      }
    );
  }

  getPCNInfo(): PCNDetails {
    if (this.getActive()) {
      return this.getActive().registryEntity.medicalReferenceEntity.cdmpDetails;
    } else {
      return new PCNDetails();
    }
  }

  getStartTime(): string {
    if (this.getActive()) {
      return this.getActive().registryEntity.startTime;
    } else {
      return '';
    }
  }

  getSelectedPCNVisitPatientExtraDetails(): Observable<PCNPatientExtraDetails> {
    return this.getSelectedPCNVisitDetails().pipe(
      filter(data => !!data),
      map(({ patientExtraDetails }) => patientExtraDetails)
    );
  }

  getSelectedPCNVisitMedicalCondition(): Observable<MedicalConditions[]> {
    return this.getSelectedPCNVisitDetails().pipe(
      filter(data => !!data),
      map(({ conditions }) => conditions)
    );
  }

  getSelectedPCNVisitVitals(): Observable<VitalResponseData[]> {
    return this.getSelectedPCNVisitDetails().pipe(
      filter(data => !!data),
      map(({ vitals }) => vitals)
    );
  }

  getSelectedPCNVisitAssesment(): Observable<AssessmentResult> {
    return this.getSelectedPCNVisitDetails().pipe(
      filter(data => !!data),
      map(({ assessment }) => assessment)
    );
  }

  getSelectedPCNVisitVitalTestTaken(): Observable<VitalTestTakenEntity[]> {
    return this.getSelectedPCNVisitDetails().pipe(
      filter(data => !!data),
      map(({ vitalTestTakenList }) => vitalTestTakenList)
    );
  }

  getSelectedPCNVisitAssessmentTestTaken(): Observable<AssessmentTestTakenEntity[]> {
    return this.getSelectedPCNVisitDetails().pipe(
      filter(data => !!data),
      map(({ assessmentTestTakenList }) => assessmentTestTakenList)
    );
  }

  // getSelectedPCNVisitMedications(): Observable<OnGoingMedication[]> {
  //   return this.getSelectedPCNVisitDetails().pipe(
  //     filter(data => !!data),
  //     map(({ onGoingMedications }) => onGoingMedications)
  //   );
  // }

  getSelectedPCNVisitType(): Observable<string> {
    return this.selectActive(
      entity => entity.registryEntity.medicalReferenceEntity &&
        entity.registryEntity.medicalReferenceEntity.consultation &&
        entity.registryEntity.medicalReferenceEntity.consultation.visitType
    );
  }

  selectPatientId(): Observable<string> {
    return this.selectActive(entity => entity.registryEntity).pipe(
      map(registryEntity => registryEntity && registryEntity.patientId)
    );
  }

  getPatientId(): string {
    if (this.getActive()) {
      return this.getActive().registryEntity.patientId;
    } else {
      return '';
    }
  }

  selectCidcStatus(): Observable<string> {
    return this.selectActive(
      entity => entity.cidcSubmissionStatus
    );
  }

  selectCidcSubmissions(): Observable<SmartCmsRegistry[]> {
    return this.selectActive(
      entity => entity.cidcSubmissions
    );
  }

  resetStore() {
    this.store.reset();
  }

}
