import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PatientVisitHistoryStore, PatientVisitHistoryState } from './patient-visit-history.store';
import { PatientVisitList } from '../../../../objects/response/PatientVisitList';
import { Observable } from 'rxjs';
import { Page } from '../../../../model/page';

@Injectable({ providedIn: 'root' })
export class PatientVisitHistoryQuery extends QueryEntity<PatientVisitHistoryState> {
  visitList$ = this.selectAll();

  constructor(protected store: PatientVisitHistoryStore) {
    super(store);
  }

  storeHasVisitId(visitId: string): boolean {
    return this.hasEntity(visitId);
  }

  selectPage(): Observable<Page> {
    return this.select(state => state.page);
  }

  getPage(): Page {
    return this.getValue().page;
  }

  getStartDate(): Date {
    return this.getValue().startDate;
  }

  getEndDate(): Date {
    return this.getValue().endDate;
  }

  getStatus(): Array<string> {
    return this.getValue().status;
  }

  getActiveDispatchEntities() {
    return this.getActive().medicalReferenceEntity.dispatchItemEntities;
  }
}
