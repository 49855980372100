import { Vendor } from './../../objects/state/Vendor';
import { AKITA_CACHE_DURATION } from './../../constants/akita.config';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';


@Injectable({ providedIn: 'root' })
@StoreConfig({
   name: 'vendor' ,
   idKey: 'id',
   cache: { ttl: AKITA_CACHE_DURATION },
   resettable: true,
})
export class AkitaVendorStore extends EntityStore<Vendor> {

  constructor() {
    super();
  }

}

