import { Injectable } from '@angular/core';

import {
  EntityStore,
  StoreConfig,
  EntityState,
  ActiveState,
} from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { IPCNVisitCase } from '../../objects/response/pcn/PCNVisitCase';
import { RegistryEntity } from '../../objects/response/PatientRegistryListResponse';

export interface AkitaPCNVisitState
  extends EntityState<IPCNVisitCase>,
  ActiveState { }

const initialState = {
  active: null,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'pcnvisit', idKey: 'visitId', resettable: true })
export class AkitaPCNVisitStore extends EntityStore<
AkitaPCNVisitState,
IPCNVisitCase
> {
  constructor() {
    super(initialState);
  }

  updateRegisterEntry(registryEntity: Partial<RegistryEntity>) {
    this.update(
      registryEntity.visitId, entity => {
        return {
          ...entity,
          registryEntity
        }
      }
    )

    this.setActive(registryEntity.visitId);
  }
}
