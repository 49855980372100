<div class="card mb-2" *ngIf="!careReportFlow">
    <div class="card-header">
        SCREENING FOR LIFE (SFL)
    </div>
    <div class="card-body">
        <div class="row mb-0">
            <div class="col-md-1">
                <label class="mb-0">Screening Date</label>
            </div>
            <div class="col-md-3">
                <label class="mb-0">Screening Type</label>
            </div>
            <div class="col-md-1">
                <label class="mb-0">Test Type</label>
            </div>
            <div class="col-md-1">
                <label class="mb-0">Test Order</label>
            </div>
            <div class="col-md-4">
                <label class="mb-0">Followup Outcome</label>
            </div>
            <!-- <div class="col-md-3">
                <label class="mb-0">Screening Outcome</label>
            </div> -->
            <div class="col-md-2 pr-4" style="text-align: end;">
                <label class="mb-0">Actions</label>
            </div>
        </div>

        <div *ngIf="patientInfo">
            <form #f='ngForm' [formGroup]='patientSflFormGroup'>
                <div formArrayName="sflFormArr">
                    <div *ngFor="let item of patientSflFormGroup?.get('sflFormArr')?.controls; let i = index;">
                        <div [formGroupName]="i">
                            <div class="row pt-2">
                                <div class="col-md-1">
                                    {{item.value.screenDate}}
                                </div>
                                <div class="col-md-3">
                                    {{getScreeningTypeName(item.value.screeningType)}}
                                </div>
                                <div class="col-md-1">
                                    {{getScreeningSubTypeName(item.value.testType)}}
                                </div>
                                <div class="col-md-1">
                                    {{getTestOrder(item.value.testOrder)}}
                                </div>
                                <div class="col-md-4">
                                    {{getFollowupOutcome(item.value.followUpOutcome)}}
                                </div>
                                <!-- <div class="col-md-3">
                                    {{getScreeningOutcome(item.value.screeningOutcome)}}
                                </div> -->
                                <div class="col-md-2 pr-4" style="text-align: end;">
                                    <div class="d-flex flex-row-reverse">
                                        <div class="p-2">
                                            <i class="icon-trash cursor-pointer" (click)="deleteItem(item.get('id').value, i)"></i>
                                        </div>
                                        <div class="p-2">
                                            <button class="btn btn-link-grey p-0" (click)="showEditSflDetails(item)">
                                                <i class="icon-pencil " aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="row justify-content-end mr-0 mt-3">
            <button  
                class="btn btn-sm btn-brand-primary"
                type="button" 
                (click)="showEditSflDetails()">
                + Add
            </button>
        </div>
    </div>
</div>

<div class="card mb-2 border-0" *ngIf="careReportFlow">
    <div class="card-header">
        ADD SFL
    </div>
    
    <table class="custom-table custom-table-light">
        <thead>
            <tr>
                <th>Screening Date</th>
                <th>Screening Type</th>
                <th>Test Type</th>
                <th style="max-width: 350px;">Followup Outcome</th>
                <!-- <th style="max-width: 350px;">Screening Outcome</th> -->
                <th style="text-align: end;">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of sflScreening; let i = index;">
                <td>{{item.screenDate}}</td>
                <td>{{getScreeningTypeName(item.screeningType)}}</td>
                <td>{{getScreeningSubTypeName(item.testType)}}</td>
                <td style="max-width: 350px;">{{getFollowupOutcome(item.followUpOutcome)}}</td>
                <!-- <td style="max-width: 350px;">{{getScreeningOutcome(item.screeningOutcome)}}</td> -->
                <td><div class="d-flex flex-row-reverse">
                    <div class="p-2">
                        <i class="icon-trash cursor-pointer" (click)="deleteItem(item.id, i)"></i>
                    </div>
                    <div class="p-2">
                        <button class="btn btn-link-grey p-0" (click)="showEditSflDetailsCareReport(item)">
                            <i class="icon-pencil " aria-hidden="true"></i>
                        </button>
                    </div>
                </div></td>
            </tr>
        </tbody>
    </table>
    <div class="row justify-content-end mr-0 mt-3">
        <button  
            class="btn btn-sm btn-outline-brand-secondary float-right mr-2 mb-2"
            type="button" 
            (click)="showEditSflDetails()">
            + Add
        </button>
    </div>
</div>
