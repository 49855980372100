export class BalancePayload {
  key: string;
  value: string;
  status: BalanceStatus;

  constructor(key?: string, value?: string, status?: BalanceStatus) {
    this.key = key || '';
    this.value = value || '';
    this.status = status || 'HIDDEN';
  }

  setValue(key?: string, value?: string, status?: BalanceStatus) {
    this.key = key || '';
    this.value = value || '';
    this.status = status || 'HIDDEN';
  }
}

export type BalanceStatus = 'SUCCESS' | 'CHECK' | 'FAIL' | 'LOADING' | 'HIDDEN';
