import {
  OrderStatus,
  TransferOrder,
  PurchaseOrder,
  PurchaseRequest,
  OrderItem,
  InventoryOrder,
  Type,
} from '../PurchaseOrder';
import { Price } from '../InventoryItem';

export class PurchaseOrderRequestListResponse<T extends InventoryOrder> {
  orderRequestNo: string;
  createDate: string;
  dataType: Type;
  status: OrderStatus;
  data: T;

  adapt(obj?): PurchaseOrderRequestListResponse<T> {
    if (!obj) {
      return this;
    }
    this.orderRequestNo = obj.orderRequestNo;
    this.createDate = obj.createDate;
    this.dataType = obj.dataType;
    this.status = obj.status;
    let data;

    if (this.dataType === Type.PR) {
      data = new PurchaseRequest().adapt(obj.data);
    } else if (this.dataType === Type.PO) {
      data = new PurchaseOrder().adapt(obj.data);
    } else if (this.dataType === Type.TO) {
      data = new TransferOrder().adapt(obj.data);
    }

    this.data = data;

    return this;
  }

  isPurchaseRequest(): boolean {
    return this.dataType === Type.PR;
  }

  isPurchaseOrder(): boolean {
    return this.dataType === Type.PO;
  }

  isTransferOrder(): boolean {
    return this.dataType === Type.TO;
  }

  getOrderItems(): Array<OrderItem> {
    if (this.isPurchaseOrder() && this.data instanceof PurchaseOrder) {
      return this.data.goodPurchasedItems;
    } else if (
      this.isPurchaseRequest() &&
      this.data instanceof PurchaseRequest
    ) {
      return this.data.requestItems;
    } else if (this.isTransferOrder() && this.data instanceof TransferOrder) {
      return this.data.transferRequestItems;
    }

    return [];
  }

  getOrderNumber() {
    return `${this.dataType}-${this.orderRequestNo}`;
  }
}
