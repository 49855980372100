import { PvmTabsService } from './../../services/pvm-tabs.service';
import { ApiPatientVisitService } from './../../services/api-patient-visit.service';
import { ApiPatientInfoService } from './../../services/api-patient-info.service';
import { VisitManagementService } from './../../services/visit-management.service';
import { StoreService } from './../../services/store.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-consultation-locked',
  templateUrl: './consultation-locked.component.html',
  styleUrls: ['./consultation-locked.component.scss'],
})
export class ConsultationLockedComponent implements OnInit, OnDestroy {
  @Input() doctorId;
  @Input() doctorName;
  @Input() consultationId;
  @Input() consultation;
  @Output() onUnlockEmitter = new EventEmitter<boolean>();
  default = 'Dr';

  constructor(
    private store: StoreService,
    private visitManagementService: VisitManagementService,
    private pvmTabs: PvmTabsService
  ) {}

  private componentDestroyed: Subject<void> = new Subject();

  ngOnInit() {

    if (!this.doctorId) {
      this.doctorId = '';
    } else {
      const doctor = this.store.doctorList.find(
        doctor => doctor.id === this.doctorId
      );
      if (doctor !== undefined) {
        this.doctorName = doctor.displayName;
      } else {
        this.doctorName = this.default;
      }
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  hideUnlockBtn() {
    if (this.consultation === null) return true;

    const { lockStatus } = this.consultation;
    return (lockStatus === 'LOCK_FOR_ALL' || lockStatus === 'OPEN')  || (this.pvmTabs.isCARole() && lockStatus === 'LOCK_FOR_CA') ||
      (this.pvmTabs.isCARole() && lockStatus === 'LOCK_EXCLUDING_CLINIC');
  }

  unlockBtnClicked() {
    this.visitManagementService.setUnlockIndexRefresh(this.consultationId);
    // In Subscriber, use getUnlockIndexRefresh to receive consultationId to be unlocked
  }
}
