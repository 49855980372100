<div class="card">
    <div class="card-header py-1">
      BILL ADJUSTMENT SUBMISSION
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body p-4">
      <alert></alert>
      Once the items have been submitted, no further adjustment can be made. Are you sure to submit this Bill adjustment?
    </div>
    <div class="row flex-row-reverse pb-3 pr-3">
      <div class="col-md-3">
        <button class="btn btn-md btn-brand-primary text-white w-100" (click)="confirm()">Confirm</button>
      </div>
      <div class="col-md-3">
        <button class="btn btn-md btn-grey-600 text-white w-100" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </div>
</div>
  