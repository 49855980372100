import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { ChargeItem } from '../../objects/state/ChargeItem';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'chargeitem',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaChargeItemStore extends EntityStore<ChargeItem> {
  constructor() {
    super();
  }

  addNewData(data) {
    this.set(data);
  }
}
