<ng-container>
  <ng-container *ngIf="status===statusList.LOADED">
    <ngx-doc-viewer 
      *ngIf="isPdf"
      [url]="document" 
      viewer="url" 
      style="width:100%; height:80vh">
    </ngx-doc-viewer>
    
    <div *ngIf="!isPdf" class="doc-img" >
      <div class="icon-download">
        <i class="fa fa-download" (click)="downloadImage()" title="Download"></i>
      </div>
      <img [src]="document">
    </div>
  </ng-container>

  <ng-container *ngIf="status===statusList.NO_DATA">
    <div class="row justify-content-center align-items-center">
      <span>No Data</span>
    </div>
  </ng-container>

  <ng-container *ngIf="status===statusList.LOADING">
    <div class="d-flex align-items-center">
      <strong>Loading...</strong>
      <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
    </div>
  </ng-container>
</ng-container>