import { VersionCheckService } from './services/version-check.service';
import { DocViewModule } from './components/doc-viewer/doc-view.module';
import { DocViewerComponent } from './components/doc-viewer/doc-viewer.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { DocumentManagementService } from './services/document-management.service';
import { PrintTemplateService } from './services/print-template.service';
import { VitalService } from './services/vital.service';
import { ErrorLogService } from './services/error-log.service';

import { AppConfigService } from './services/app-config.service';
import { JwtInterceptor } from './services/jwt-interceptor';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

import {
  APP_SIDEBAR_NAV,
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
} from './components';

import { FullLayoutComponent, SimpleLayoutComponent } from './containers';
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES,
} from './directives';
import { AlertService } from './services/alert.service';
import { ApiCmsManagementService } from './services/api-cms-management.service';
import { ApiPatientInfoService } from './services/api-patient-info.service';
import { ApiPatientVisitService } from './services/api-patient-visit.service';
import { AuthGuardService } from './services/auth-guard.service';
import { PermissionsGuardService } from './services/permissions-guard.service';
import { AuthService } from './services/auth.service';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { ConsoleLoggerService } from './services/console-logger.service';
import { ConsultationFormService } from './services/consultation-form.service';
import { CaseChargeFormService } from './services/case-charge-form.service';
import { DialogService } from './services/dialog.service';
import { LoggerService } from './services/logger.service';
import { PatientService } from './services/patient.service';
import { PaymentService } from './services/payment.service';
import { PolicyHolderService } from './services/policy-holder.service';
import { StoreService } from './services/store.service';
import { UtilsService } from './services/utils.service';
import { VaccinationService } from './services/vaccination.service';
import { SharedModule } from './shared.module';
import { MedicalCoverageFormService } from './services/medical-coverage-form.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { API_DOMAIN } from './constants/app.constants';
import { TempStoreService } from './services/temp-store.service';
import { InventoryService } from './services/inventory.service';
import { ApiInventoryService } from './services/api-inventory.service';
import { ApiCaseManagerService } from './services/api-case-manager.service';
import { ApiConsultaitonTemplateService } from './services/api-consultaiton-template.service';
import { MessageService } from './services/message.service';
import { ApiService } from './services/api.service';
import { PvmResolverService } from './resolvers/pvm-resolver.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ApiPcnVisitsService } from './services/api-pcn-visits.service';
import { MedicalCoverageModule } from './views/components/medical-coverage/medical-coverage.module';
import { CustomRouteReuseStragy } from './services/custom-route-reuse.strategy';
import { RouteReuseStrategy } from '@angular/router';
import { PatientConditionsService } from './components/pcn/patient-conditions/patient-conditions.service';
import { ApiClinicRoomService } from './services/api-clinic-room.service';
import { KonvaManagementService } from './services/konva-management.service';
import { VisibilityService } from './services/visibility.service';
import { KonvaUtilService } from './services/konva-util.service';
import { AkitaStoreService } from './services/states/akita-store.service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { BackendService } from './services/backend.service';
import { CaseDetailResolverService } from './resolvers/case-detail-resolver.service';
import { ApiPersonalNotesService } from './services/api-personal-notes.service';

import { ToastrModule } from 'ngx-toastr';
import { InventoryCanDeactivateGuardService } from './services/inventory-can-deactivate-guard.service';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ChatService } from './services/chat.service';
import { WebsocketService } from './services/websocket.service';
import { ApiHwmedService } from './services/api-hwmed.service';
import { RpaRequestService } from './services/rpa-request.service';
// HTTP MODULE
// Services
// Routing

import { ModalModule } from 'ngx-bootstrap/modal';
import { ApiCdlensVisitsService } from './services/api-cdlens-visits.service';
import { ApiSmartCmsManagementService } from './services/api-smart-cms-management.service';

const APP_CONTAINERS = [FullLayoutComponent, SimpleLayoutComponent];

// Import components
const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
];

// Import directives
const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES,
  AutoFocusDirective,
];

export function getAccessToken() {
  return localStorage.getItem('access_token');
}

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

// Import 3rd party components
@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
  ],
  imports: [
    TimepickerModule.forRoot(),
    BrowserModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    HttpClientModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getAccessToken,
        whitelistedDomains: API_DOMAIN,
      },
    }),
    NgxPermissionsModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    AccordionModule.forRoot(),
    DocViewModule,
    AkitaNgDevtools.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      // closeButton: true,
      // maxOpened: 5,
      // autoDismiss: true
    }),
    TimepickerModule.forRoot(),
  ],
  entryComponents: [DocViewerComponent],

  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ps: NgxPermissionsService) =>
        function () {
          return new Promise((resolve, reject) => {
            if (localStorage.getItem('roles')) {
              resolve(localStorage.getItem('roles'));
            } else {
              resolve('');
            }
          }).then((data: string) => {
            if (data) {
              return ps.loadPermissions(JSON.parse(data));
            }
          });
        },
      deps: [NgxPermissionsService],
      multi: true,
    },
    { provide: LoggerService, useClass: ConsoleLoggerService },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStragy,
    },
    ErrorLogService,
    DatePipe,
    StoreService,
    AlertService,
    AuthService,
    VitalService,
    TempStoreService,
    ApiService,
    ApiCmsManagementService,
    ApiSmartCmsManagementService,
    ApiPatientInfoService,
    ApiPatientVisitService,
    ApiInventoryService,
    DocumentManagementService,
    DialogService,
    PatientService,
    VaccinationService,
    AuthGuardService,
    PermissionsGuardService,
    CanDeactivateGuardService,
    InventoryCanDeactivateGuardService,
    JwtHelperService,
    PolicyHolderService,
    PaymentService,
    ConsultationFormService,
    CaseChargeFormService,
    UtilsService,
    MedicalCoverageFormService,
    BsModalService,
    NgxPermissionsService,
    PrintTemplateService,
    InventoryService,
    ApiCaseManagerService,
    ApiConsultaitonTemplateService,
    MessageService,
    ApiPcnVisitsService,
    ApiCdlensVisitsService,
    ApiClinicRoomService,
    PatientConditionsService,
    PvmResolverService,
    KonvaManagementService,
    KonvaUtilService,
    VisibilityService,
    VersionCheckService,
    AkitaStoreService,
    BackendService,
    CaseDetailResolverService,
    ApiPersonalNotesService,
    ChatService,
    RpaRequestService,
    WebsocketService,
    ApiHwmedService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }