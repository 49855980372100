import { StoreService } from '../services/store.service';

import { DeliveryOrder, DeliveryOrderItem } from './DeliveryOrder';
import { InventorySupplier } from './InventorySupplier';
import { Price } from './InventoryItem';
import { GRNItem, GRNStatus, OrderGRN } from './OrderGRN';
import { OrderReturnRequest, OrderReturnRequestItem } from './OrderReturnRequest';
import { NavIntegrationResult } from './CommonResponses';

import { AkitaClinicPurchasableChargeItemQuery } from '../services/states/akita-clinic-purchasable-charge-item.query';

import { DISPLAY_DATE_FORMAT } from '../constants/app.constants';

import * as moment from 'moment'

export abstract class InventoryOrder {
  id: string;
  requestClinicId: string;
  createDate: string;
  orderDate: string;
  requestNo: string;
  requestDate: string;
  remark: string;
  suppliers: Array<InventorySupplier>;
  navIntegrationResult: NavIntegrationResult;
  protected totals: number = 0;

  abstract getStatus(): OrderStatus;
  abstract getOrderNumber(): string;
  abstract getItems(): Array<OrderItem>;
  abstract getApprovedItems(): Array<OrderItem>;

  toOrderGrn(): OrderGRN {
    return undefined;
  }

  getItemsQuantity(): number {
    return this.getItems().reduce((sum, item) => (sum += item.quantity), 0);
  }
  getItemsQty(): number {
    return this.getItems().reduce((sum, item) => (sum += item.quantity), 0);
  }
  getTotal(orderDetails: InventoryOrder) {
    if (
      orderDetails['deliveryNotes'] == null ||
      orderDetails['deliveryNotes'] == undefined
    ) {
      return (this.totals = 0);
    } else {
      for (var item of orderDetails['deliveryNotes']) {
        for (var items of item.transferSendItems) {
          this.totals = this.totals + items.quantity;
        }
      }
      return this.totals;
    }
  }
}

export class PurchaseRequest extends InventoryOrder {
  requestType: RequestType;
  requestStatus: OrderStatus;
  justificationForPurchase: string;
  requestItems: Array<OrderItem>;
  requiredBeforeDate: string;

  adapt(obj?): PurchaseRequest {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.requestClinicId = obj.requestClinicId;
    this.createDate = obj.createDate;
    this.requestNo = obj.requestNo;
    this.requestDate = obj.requestDate;
    this.remark = obj.remark;
    this.suppliers = (obj.suppliers || []).map(value =>
      new InventorySupplier().adapt(value)
    );
    this.navIntegrationResult = obj.navIntegrationResult;
    this.requestType = obj.requestType;
    this.requestStatus = obj.requestStatus;
    this.justificationForPurchase = obj.justificationForPurchase;
    this.requestItems = (obj.requestItems || []).map(value =>
      new OrderItem().adapt(value)
    );
    this.requiredBeforeDate = obj.requiredBeforeDate;

    return this;
  }

  getStatus(): OrderStatus {
    return this.requestStatus;
  }

  getOrderNumber(): string {
    return this.requestNo;
  }

  getItems(): Array<OrderItem> {
    return this.requestItems;
  }

  getApprovedItems(): Array<OrderItem> {
    return this.requestItems.filter(item => item.approvedQuantity > 0);
  }
}

export abstract class Order extends InventoryOrder {
  requestId: string;
  orderType: OrderType;
  orderNo: string;
  orderStatus: OrderStatus;
  goodReceivedNotes: Array<OrderGRN>;
  deliveryNotes: Array<DeliveryOrder>;
  interComp: boolean;
  clinicFilter: string;
  interCompanyTransferOrderNo: string;

  isPurchaseOrder(): boolean {
    return this.orderType === OrderType.PURCHASE;
  }

  isTransferOrder(): boolean {
    return this.orderType === OrderType.TRANSFER;
  }

  getStatus(): OrderStatus {
    return this.orderStatus;
  }

  getOrderNumber(): string {
    return this.orderNo;
  }

  getReceivedItemsQuantity(): number {
    return this.goodReceivedNotes
      .filter(note => note.status === GRNStatus.CONFIRM)
      .reduce(
        (sum, note) =>
          (sum += note.receivedItems.reduce(
            (sum, item) => (sum += item.quantity),
            0
          )),
        0
      );
  }

  getItemQuantity(itemId: string): number {
    return this.getItems()
      .filter(item => item.itemRefId === itemId)
      .reduce((sum, item) => (sum += item.quantity), 0);
  }

  getItemQuantityByLineNo(lineNo: string): number {
    return this.getItems()
      .filter(item => item.lineNo === lineNo)
      .reduce((sum, item) => (sum += item.quantity), 0);
  }
}

export class PurchaseOrder extends Order {
  supplierId: string;
  goodPurchasedItems: Array<OrderItem>;
  goodReceivedVoidNotes: Array<OrderReturnRequest>;

  adapt(obj?): PurchaseOrder {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.requestClinicId = obj.requestClinicId;
    this.createDate = obj.createDate;
    this.orderDate = obj.orderDate;
    this.requestNo = obj.requestNo;
    this.requestDate = obj.requestDate;
    this.remark = obj.remark;
    this.suppliers = (obj.suppliers || []).map(value =>
      new InventorySupplier().adapt(value)
    );
    this.requestId = obj.requestId;
    this.orderType = obj.orderType;
    this.orderNo = obj.orderNo;
    this.orderStatus = obj.orderStatus;
    this.goodReceivedNotes = (obj.goodReceivedNotes || []).map(note =>
      new OrderGRN().adapt(note)
    );
    this.supplierId = obj.supplierId;
    this.interComp = obj.interComp;
    this.clinicFilter = obj.clinicFilter;
    this.interCompanyTransferOrderNo = obj.interCompanyTransferOrderNo;
    this.goodPurchasedItems = (obj.goodPurchasedItems || []).map(value =>
      new OrderItem().adapt(value)
    );
    this.deliveryNotes = (obj.deliveryNotes || []).map(value =>
      new DeliveryOrder().adapt(value)
    );
    this.goodReceivedVoidNotes = (obj.goodReceivedVoidNotes || []).map(value =>
      new OrderReturnRequest().adapt(value)
    );

    return this;
  }

  getItems(): Array<OrderItem> {
    return this.goodPurchasedItems;
  }

  getApprovedItems(): Array<OrderItem> {
    return this.goodPurchasedItems.filter(item => item.approvedQuantity > 0);
  }

  getSupplierDetail(store: StoreService): InventorySupplier {
    if (!this.supplierId || !store) {
      return undefined;
    }

    return store.getSupplierById(this.supplierId);
  }

  toOrderGrn(): OrderGRN {
    const orderGrn = new OrderGRN();
    orderGrn.delivererId = this.supplierId;
    orderGrn.receiverId = localStorage.getItem('clinicId');
    orderGrn.prNo = this.requestNo;
    orderGrn.prDate = this.requestDate;
    orderGrn.receivedItems = (this.goodPurchasedItems || []).map(item =>
      item.toGRNItem()
    );
    return orderGrn;
  }

  toReturnRequest(): OrderReturnRequest {
    const returnRequest = new OrderReturnRequest();
    returnRequest.requestClinicId = this.requestClinicId;
    returnRequest.supplierId = this.supplierId;
    returnRequest.returnItems = (this.goodPurchasedItems || []).map(item =>
      item.toReturnRequestItem()
    );
    return returnRequest;
  }
}

export class TransferOrder extends Order {
  cPTransfer: boolean;
  senderClinicId: string;
  transferRequestItems: Array<OrderItem>;

  adapt(obj?): TransferOrder {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.requestClinicId = obj.requestClinicId;
    this.createDate = obj.createDate;
    this.orderDate = obj.orderDate;
    this.requestNo = obj.requestNo;
    this.requestDate = obj.requestDate;
    this.remark = obj.remark;
    this.suppliers = (obj.suppliers || []).map(value =>
      new InventorySupplier().adapt(value)
    );
    this.requestId = obj.requestId;
    this.orderType = obj.orderType;
    this.orderNo = obj.orderNo;
    this.orderStatus = obj.orderStatus;
    this.interComp = obj.interComp;
    this.interCompanyTransferOrderNo = obj.interCompanyTransferOrderNo;
    this.goodReceivedNotes = (obj.goodReceivedNotes || []).map(note =>
      new OrderGRN().adapt(note)
    );
    this.cPTransfer = obj.cPTransfer;
    this.senderClinicId = obj.senderClinicId;
    this.transferRequestItems = (obj.transferRequestItems || []).map(value =>
      new OrderItem().adapt(value)
    );
    this.deliveryNotes = (obj.deliveryNotes || []).map(value =>
      new DeliveryOrder().adapt(value)
    );

    return this;
  }

  getItems(): Array<OrderItem> {
    return this.transferRequestItems;
  }

  getApprovedItems(): Array<OrderItem> {
    return this.transferRequestItems.filter(item => item.approvedQuantity > 0);
  }

  getSenderClinic(store: StoreService) {
    if (!this.senderClinicId || !store) {
      return undefined;
    }

    return store
      .getClinicList()
      .find(clinic => clinic.id === this.senderClinicId);
  }

  toOrderGrn(): OrderGRN {
    const orderGrn = new OrderGRN();
    orderGrn.delivererId = this.senderClinicId;
    orderGrn.receiverId = this.requestClinicId;
    orderGrn.prNo = this.requestNo;
    orderGrn.prDate = this.requestDate;
    orderGrn.receivedItems = (this.transferRequestItems || []).map(item =>
      item.toGRNItem()
    );
    return orderGrn;
  }

  toDeliveryOrder(): DeliveryOrder {
    const deliveryOrder = new DeliveryOrder();
    deliveryOrder.delivererId = this.senderClinicId;
    deliveryOrder.receiverId = this.requestClinicId;
    deliveryOrder.sendDate = moment().format(DISPLAY_DATE_FORMAT);
    deliveryOrder.transferSendItems = (
      this.transferRequestItems || []
    ).map(item => item.toDeliveryOrderItem());
    return deliveryOrder;
  }

  calculateRemainingRequestedItems() {
    const requestedItemMap: Map<string, number> = new Map<string, number>();

    if (this.transferRequestItems) {
      this.transferRequestItems.forEach(item => {
        requestedItemMap.set(
          item.itemRefId,
          item.quantity +
            (requestedItemMap.has(item.itemRefId)
              ? requestedItemMap.get(item.itemRefId)
              : 0)
        );
      });
    }

    if (this.deliveryNotes) {
      this.deliveryNotes.forEach(deliveryNote => {
        if (deliveryNote.transferSendItems) {
          deliveryNote.transferSendItems.forEach(item => {
            if (requestedItemMap.has(item.itemRefId))
              requestedItemMap.set(
                item.itemRefId,
                requestedItemMap.get(item.itemRefId) - item.quantity
              );
          });
        }
      });
    }

    return requestedItemMap;
  }
}

export class OrderItem {
  itemRefId: string;
  supplierId: string;
  unitPrice: Price;
  uom: string;
  quantity: number;
  lineNo: string;
  remark: string;
  outOfStockRemarks: string;
  cpRemarks: string;
  additionalDescription: string;
  purchasePrice:any;
  //pr item
  approvedQuantity: number;
  approvedUom: string;

  //po item
  fromClinicCode: string;

  adapt(obj?): OrderItem {
    if (!obj) {
      return this;
    }

    this.itemRefId = obj.itemRefId;
    this.supplierId = obj.supplierId;
    this.uom = obj.uom;
    this.quantity = obj.quantity;
    this.unitPrice = obj.unitPrice
      ? obj.unitPrice !== null && typeof obj.unitPrice === 'object'
        ? new Price().adapt(obj.unitPrice)
        : new Price(obj.unitPrice, false)
      : new Price(0, false);
    this.lineNo = obj.lineNo;
    this.remark = obj.remark;
    this.outOfStockRemarks = obj.outOfStockRemarks;
    this.cpRemarks = obj.cpRemarks;
    this.approvedQuantity = obj.approvedQuantity;
    this.approvedUom = obj.approvedUom;
    this.additionalDescription = obj.additionalDescription;
    this.fromClinicCode = obj.fromClinicCode;

    return this;
  }

  getItemUnitPrice(
    akitaClinicPurchasableChargeItemQuery: AkitaClinicPurchasableChargeItemQuery
  ): number {
    if (this.unitPrice) return this.unitPrice.getPriceWithoutTax();

    const inventoryItem = akitaClinicPurchasableChargeItemQuery.getInventoryItem(
      this.itemRefId
    );
    if (inventoryItem && inventoryItem.cost)
      return new Price(
        inventoryItem.cost.price,
        inventoryItem.cost.taxIncluded
      ).getPriceWithoutTax();
  }

  getItemName(store: StoreService): string {
    const item = store.getItemById(this.itemRefId);
    if (item && item.item) {
      return item.item.name;
    }

    return '';
  }

  getItemAmount(
    akitaClinicPurchasableChargeItemQuery: AkitaClinicPurchasableChargeItemQuery
  ): number {
    return (
      this.getItemUnitPrice(akitaClinicPurchasableChargeItemQuery) *
      (this.quantity || 0)
    );
  }

  getApprovedItemAmount(
    akitaClinicPurchasableChargeItemQuery: AkitaClinicPurchasableChargeItemQuery
  ): number {
    return (
      this.getItemUnitPrice(akitaClinicPurchasableChargeItemQuery) *
      (this.approvedQuantity || 0)
    );
  }

  toGRNItem(): GRNItem {
    const grnItem = new GRNItem();
    grnItem.itemRefId = this.itemRefId;
    grnItem.uom = this.uom;
    grnItem.lineNo = this.lineNo;
    grnItem.purchasePrice = (this.unitPrice.price /100).toFixed(2);
    return grnItem;
  }

  toReturnRequestItem(): OrderReturnRequestItem {
    const returnRequestItem = new OrderReturnRequestItem();
    returnRequestItem.itemRefId = this.itemRefId;
    returnRequestItem.unitPrice = this.unitPrice;
    returnRequestItem.lineNo = this.lineNo;
    return returnRequestItem;
  }

  toDeliveryOrderItem(): DeliveryOrderItem {
    const doItem = new DeliveryOrderItem();
    doItem.itemRefId = this.itemRefId;
    doItem.uom = this.uom;
    doItem.quantity = this.quantity;
    doItem.lineNo = this.lineNo;
    doItem.purchasePrice =   (this.unitPrice.price /100).toFixed(2);
    return doItem;
  }

  isValidForApiRequest() {
    return this.itemRefId && this.supplierId && this.quantity && this.uom;
  }
}

export enum Type {
  PR = 'PR',
  PO = 'PO',
  TO = 'TO',
}

export enum RequestType {
  STANDARD = 'STANDARD',
  LOOSE = 'LOOSE',
}

export enum OrderType {
  TRANSFER = 'TRANSFER',
  PURCHASE = 'PURCHASE',
}

export enum OrderStatus {
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  TRANSFER = 'TRANSFER',
  PARTIAL_APPROVED = 'PARTIAL_APPROVED',

  INITIAL = 'INITIAL',
  PARTIAL_RECEIVED = 'PARTIAL_RECEIVED',
  FULL_RECEIVED = 'FULL_RECEIVED',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
}
