import { AttachedMedicalCoveragesStore } from './../../../services/states/akita-case/akita-attached-medical-coverages/attached-medical-coverages.store';
import { ApiCaseManagerService } from './../../../services/api-case-manager.service';
import { ConsultationFormService } from './../../../services/consultation-form.service';
import { AkitaPatientStoreService } from './../../../services/states/akita-patient/akita-patient-store.service';
import { CaseCoveragePlan } from './../../../objects/state/Case';
import { PvmTabsService } from './../../../services/pvm-tabs.service';
import { AkitaCaseVisitQuery } from './../../../services/states/akita-case-visit.query';
import { MedicalCoverageByCaseRes } from './../../../objects/PolicyHolderInfo';
import { COVERAGE_CONFIG } from './../../../constants/medical-coverage.config';
import { AkitaPatientAppQuery } from './../../../services/states/akita-patient/akita-patient-app.query';
import { PatientMedicalAlertsQuery } from './../../../services/states/akita-patient/patient-medical-alerts/patient-medical-alerts.query';
import { AkitaCaseStoreService } from './../../../services/states/akita-case/akita-case-store.service';
import { AttachedMedicalCoveragesQuery } from './../../../services/states/akita-case/akita-attached-medical-coverages/attached-medical-coverages.query';
import { MedicalCoverage } from './../../../objects/MedicalCoverage';
import { combineLatest, concat, merge } from 'rxjs';
import { ApiPatientVisitService } from './../../../services/api-patient-visit.service';
import { UtilsService } from './../../../services/utils.service';
import { AlertService } from './../../../services/alert.service';
import { FormBuilder, FormArray } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { StoreService } from '../../../services/store.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { Subject, timer } from 'rxjs';
import { DB_FULL_DATE_TIMEZONE_NO_SPACE_Z, DISPLAY_DATE_FORMAT } from '../../../constants/app.constants';
import Allergy from '../../../objects/Allergy';
// import { MedicalCoverageByCaseRes } from '../../../objects/MedicalCoverage';
import { PatientAddQueueConfirmationComponent } from '../../patient-add/patient-add-queue-confirmation/patient-add-queue-confirmation.component';
import { VisitManagementService } from '../../../services/visit-management.service';
import { MedicalAlert } from '../../../objects/response/MedicalAlert';
import PatientInfo, {
  createPatientInfo,
} from '../../../objects/state/PatientInfo';
import { PolicyHolder } from '../../../objects/request/PolicyHolder';
import { AttachedMedicalCoverage } from '../../../objects/AttachedMedicalCoverage';
import {
  filter,
  takeUntil,
  mergeMap,
  tap,
  map,
  concatMap,
  switchMap,
} from 'rxjs/operators';
import { getAge, getDetailAge } from '../../../util/date.util';
import { AkitaAppQuery } from '../../../services/states/akita-app.query';
import { ApiHsgService } from '../../../services/api-hsg.service';
import { AkitaClinicQuery } from '../../../services/states/akita-clinic.query';
@Component({
  selector: 'app-consultation-patient-info',
  templateUrl: './consultation-patient-info.component.html',
  styleUrls: ['./consultation-patient-info.component.scss'],
})
export class ConsultationPatientInfoComponent implements OnInit, OnDestroy {
  @Input() hideAlerts = false;
  @Input() patientNameRedirect = false;
  @Input() patientChange = false;
  bsModalRef: BsModalRef;
  alerts: Array<Allergy>;
  medicalAlerts: Array<MedicalAlert>;
  patientInfo: PatientInfo;
  coverages;
  loading: boolean = false;
  planIdsFromCase: AttachedMedicalCoverage[];

  patientId: string;
  patientNo: string;
  patientName: string;
  age: string;
  sex: string;
  dob: string;
  nric: string;
  occupation: string;
  preferredLanguage: string;
  address: string;
  address1: string;
  address2: string;
  postal: string;
  maritalStatus: string;
  contactNo: string;
  priorityMedicalCoverage: string = '';
  patientCoverages: FormArray;
  attachedPlan: FormArray;
  storedAttachedPlan: FormArray;
  policyHolderInfo: FormArray;
  primaryCareNetwork: string;
  hsgStatus: string;
  consultInTime: string;
  consultOutTime: string;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private apiPatientVisitService: ApiPatientVisitService,
    private store: StoreService,
    private alertService: AlertService,
    private utilService: UtilsService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private caseVisitQuery: AkitaCaseVisitQuery,
    private visitManagementService: VisitManagementService,
    private akitaCaseStoreService: AkitaCaseStoreService,
    private attachedMedCovQuery: AttachedMedicalCoveragesQuery,
    private patientAlertsQuery: PatientMedicalAlertsQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private pvmTabs: PvmTabsService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaPatientStore: AkitaPatientStoreService,
    private conFormService: ConsultationFormService,
    private apiCaseManagerService: ApiCaseManagerService,
    private attachedMedicalCoverageStore: AttachedMedicalCoveragesStore,
    private akitaAppQuery: AkitaAppQuery,
    private apiHsgService: ApiHsgService,
    private akitaClinicQuery: AkitaClinicQuery
  ) { }

  ngOnInit() {
    this.attachedPlan = this.fb.array([]);
    this.store.consultEndTime.subscribe(endTime => this.consultOutTime = endTime)
    this.store.consultStartTime.subscribe(startTime => this.consultInTime = startTime)
    this.initPatientInfo();
    this.subscribeChangesOnPatientInfo();
    this.subscribeMedicalCoverage();

    this.visitManagementService.subscribeModalChanges(this.modalService);

    this.patientAlertsQuery.alerts$.subscribe((data: MedicalAlert[]) => {
      this.medicalAlerts = data;
    });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  subscribeChangesOnPatientInfo() {
    this.akitaPatientAppQuery.patientInfo$
      .pipe(
        filter(value => value !== null && value !== undefined),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(info => {
        this.patientInfo = createPatientInfo(info);
        this.patchPatientInfo();
      });
  }

  checkHSGStatus() {
    const { hsgEnrollment } = this.patientInfo;
    const { hsgDeEnrollment } = this.patientInfo;

    if (hsgEnrollment) {
      const { enrolledDate } = hsgEnrollment;

      if (this.akitaClinicQuery.getCurrentClinic().id === hsgEnrollment.enrolledClinicId) {
        return `HSG Enrolled on ${moment(enrolledDate, DB_FULL_DATE_TIMEZONE_NO_SPACE_Z).format(DISPLAY_DATE_FORMAT)}`;
      } else {
        return 'N/A';
      }
    } else if(hsgDeEnrollment) {
      const { deEnrolledDate } = hsgDeEnrollment;

      if (this.akitaClinicQuery.getCurrentClinic().id === hsgDeEnrollment.deEnrolledClinicId) {
        return `HSG De-enrolled on ${moment(deEnrolledDate, DB_FULL_DATE_TIMEZONE_NO_SPACE_Z).format(DISPLAY_DATE_FORMAT)}`;
      } else {
        return 'N/A';
      }
    } else {
      return 'N/A';
    }
  }

  checkPCNStatus() {
    const { primaryCareNetwork } = this.patientInfo;

    if (primaryCareNetwork) {
      const { optIn } = primaryCareNetwork;
      const { optOut } = primaryCareNetwork;

      if (primaryCareNetwork && optIn) {
        return `PCN Enrolled on ${primaryCareNetwork.optInDate}`;
      } else if (primaryCareNetwork && optOut) {
        return 'PCN Opted Out';
      } else {
        return 'N/A';
      }
    } else {
      return 'N/A';
    }
  }

  private patchPatientInfo() {
    const { address } = this.patientInfo;
    const { company } = this.patientInfo;
    const { dob } = this.patientInfo;

    const separatedAddress = (address.address || '').split('\n');

    let occupation = '';
    if (company) {
      occupation = company ? company.occupation : '';
    }

    let formattedAge = this.birthdayBreakdownClinicFeature() ? getDetailAge(dob) : getAge(dob);

    this.initPatientInfo(
      '',
      this.patientInfo.name,
      this.birthdayBreakdownClinicFeature() ? getDetailAge(dob) : getAge(dob),
      this.patientInfo.gender,
      this.patientInfo.dob.concat(" (" + formattedAge + ")"),
      this.patientInfo.userId.number,
      occupation,
      `${address.address}, ${address.postalCode}`,
      separatedAddress[0] ? separatedAddress[0] : '',
      separatedAddress[1] ? separatedAddress[1] : '',
      `${address.postalCode}`,
      this.patientInfo.maritalStatus,
      `${this.patientInfo.contactNumber.number}`,
      this.checkPCNStatus(),
      this.checkHSGStatus(),
      this.patientInfo.preferredLanguage,
      this.consultInTime || '',
      this.consultOutTime || ''
    );
    this.alerts = this.patientInfo.allergies;
  }

  // getAge(dob) {
  //   const countAge = key =>
  //     moment().diff(moment(dob, DISPLAY_DATE_FORMAT), key);

  //   let age = countAge('years');
  //   let ageQuantifier = ' years';

  //   if (age < 1) {
  //     age = countAge('months');
  //     ageQuantifier = ' months';
  //   }

  //   if (age < 1) {
  //     age = countAge('days');
  //     ageQuantifier = ' days';
  //   }
  //   return age + ageQuantifier;
  // }

  initPatientInfo(
    patientNo: string = '',
    patientName: string = '',
    age: string = null,
    sex: string = '',
    dob: string = '',
    nric: string = '',
    occupation: string = '',
    address: string = '',
    address1: string = '',
    address2: string = '',
    postal: string = '',
    maritalStatus: string = '',
    contactNo: string = '',
    primaryCareNetwork: string = '',
    hsgStatus: string = '',
    preferredLanguage: string = '',
    consultInTime: string = this.consultInTime,
    consultOutTime: string = this.consultOutTime
  ) {
    this.patientNo = patientNo;
    this.patientName = patientName;
    this.age = age;
    this.sex = sex;
    this.dob = dob;
    this.nric = nric;
    this.occupation = occupation;
    this.address = this.utilService.convertToTitleCaseUsingSpace(address);
    this.address1 = this.utilService.convertToTitleCaseUsingSpace(address1);
    this.address2 =
      address2 === undefined
        ? ''
        : this.utilService.convertToTitleCaseUsingSpace(address2);
    this.postal = this.utilService.convertToTitleCaseUsingSpace(postal);
    this.maritalStatus = maritalStatus;
    this.contactNo = contactNo;
    this.primaryCareNetwork = primaryCareNetwork;
    this.hsgStatus = hsgStatus;
    this.preferredLanguage = preferredLanguage;
    this.consultInTime = consultInTime;
    this.consultOutTime = consultOutTime;
  }

  openPatientDetail() {
    window.open(`/pages/patient/detail/${this.patientInfo.id}`);
  }

  getPatientDetailRoute() {
    if (this.patientInfo && this.patientInfo.id) {
      return `/pages/patient/detail/${this.patientInfo.id}`;
    } else {
      return '';
    }
  }

  showUpdateLayoutForMedicalCoverage() {
    if (
      this.pvmTabs.isPaymentState() ||
      // this.caseVisitQuery.isSubsequentVisitForPackagedCase()
      this.caseVisitQuery.isSubsequentVisit()
    ) {
      return false;
    } else {
      return true;
    }
  }

  subscribeMedicalCoverage() {
    // By right the search case data should have been called
    combineLatest(
      this.attachedMedCovQuery.attachedMedicalCoverages$,
      this.akitaCaseVisitQuery.selectCaseCoverage()
    )
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        const medCoverageByRes: MedicalCoverageByCaseRes = data[0];
        const caseCoverages: CaseCoveragePlan[] = data[1];
        this.attachedPlan = this.fb.array([]);
        this.storedAttachedPlan = this.fb.array([]);
        this.priorityMedicalCoverage = '';
        this.planIdsFromCase = [];

        medCoverageByRes.policyHolder.forEach(
          (policyHolder: PolicyHolder, index) => {
            const coverage = medCoverageByRes.coverage.find(
              (coverage: MedicalCoverage) =>
                coverage.id === policyHolder.medicalCoverageId
            );

            const caseCoverage: CaseCoveragePlan = caseCoverages.find(
              (c: CaseCoveragePlan) => c.planId === policyHolder.planId
            );

            this.attachedPlan.push(
              this.fb.group({
                medicalCoverageId: policyHolder.medicalCoverageId,
                planId: policyHolder.planId,
              })
            );

            this.planIdsFromCase.push(
              new AttachedMedicalCoverage(
                policyHolder.medicalCoverageId,
                policyHolder.planId,
                coverage.name,
                policyHolder.id,
                coverage.type,
                caseCoverage ? caseCoverage.remarks : '',
                caseCoverage ? caseCoverage.limit : -1,
                caseCoverage ? caseCoverage.updated : false,
                caseCoverage ? caseCoverage.payerUserId : undefined
              )
            );
            this.storedAttachedPlan.push(
              this.fb.group({
                medicalCoverageId: policyHolder.medicalCoverageId,
                planId: policyHolder.planId,
                name: coverage.name,
              })
            );

            if (index === 0) {
              this.priorityMedicalCoverage = coverage.name;
            }
          }
        );
      });
  }

  ngOnChanges(changes: SimpleChanges): void { }

  resetAttachedPlans() {
    while (this.attachedPlan.length > 0) {
      this.attachedPlan.removeAt(0);
    }

    this.planIdsFromCase = [];

    this.storedAttachedPlan.controls.forEach(plan => {
      this.attachedPlan.push(plan);
      this.planIdsFromCase.push(
        new AttachedMedicalCoverage(
          plan.get('medicalCoverageId').value,
          plan.get('planId').value
        )
      );
    });

    if (this.patientCoverages) {
      this.patientCoverages.controls.forEach(control => {
        const planId = control.get('planId').value;
        const isSelected = control.get('isSelected');

        if (this.attachedPlan.value.includes(planId)) {
          isSelected.patchValue(true);
        } else {
          isSelected.patchValue(false);
        }
      });
    }
  }

  displayMedicalCoveragesModal(hasUpdate?: boolean) {
    const initialState = {
      config: hasUpdate
        ? COVERAGE_CONFIG.PVM.POPUP_ATTACH
        : COVERAGE_CONFIG.PVM.POPUP_DISPLAY,
      patientCoverages: this.patientCoverages,
      // selectedCoverages: this.attachedPlan,
      planIdsFromCase: this.planIdsFromCase,
      policyHolderInfo: this.policyHolderInfo,
      visitId: this.akitaCaseVisitQuery.getActiveVisitId()
      // patientId: this.akitaPatientAppQuery.getId(),
    };

    this.bsModalRef = this.modalService.show(
      PatientAddQueueConfirmationComponent,
      {
        initialState,
        class: 'modal-xl',
        keyboard: false,
        backdrop: 'static',
      }
    );

    this.modalService.onShow
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.resetAttachedPlans();
        this.visitManagementService.setModalShown(true);
      });

    this.bsModalRef.content.event.subscribe(data => {
      if (data) {
        if (data !== 'Close') {
          this.visitManagementService.showFullLayoutLoading();
          this.apiPatientVisitService
            .attachMedicalCoverage(
              this.store.getCaseId(),
              data[1].attachedMedicalCoverages
            )
            .pipe(
              map(data => {
                if (data && data.payload) {
                  return data.payload.coverages
                }
              }),
              switchMap(coverages => {
                return this.apiCaseManagerService.getAttachedPolicyDetailByCaseId(this.store.getCaseId())
                  .pipe(map(data => {
                    return { coverages: <Array<CaseCoveragePlan>>coverages, balance: <MedicalCoverageByCaseRes>data.payload }
                  }))
              })
            )
            .subscribe(
              data => {
                this.bsModalRef.content.event.unsubscribe();
                this.bsModalRef.hide();

                this.store.setCaseId(this.store.getCaseId());

                this.akitaCaseVisitQuery.updateCaseCoverage(data.coverages);
                this.attachedMedicalCoverageStore.update(data.balance);
                this.akitaCaseVisitQuery.updateActiveVisitPlanMaxUsage(data.coverages);

                this.visitManagementService.setResetChargeItemPriceAdjustment();
                this.visitManagementService.hideFullLayoutLoading();

                this.alertService.setMedicalCoverageUpdatedEvent();
              },
              err => {
                this.alertService.error(JSON.stringify(err));
                this.visitManagementService.hideFullLayoutLoading();
              }
            );
        } else {
          this.bsModalRef.hide();
          this.resetAttachedPlans();
          this.visitManagementService.setModalShown(false);
        }
      }
    });
  }


  birthdayBreakdownClinicFeature() {
    return this.akitaAppQuery.checkClinicFeatureExist(
      'BIRTHDAY_BREAKDOWN'
    );
  }
}
