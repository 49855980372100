import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../../constants/akita.config';
import { ICarePlan } from '../../../model/care-plan/CarePlan';

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'carePlan',
    idKey: 'id',
    cache: { ttl: AKITA_CACHE_DURATION },
    resettable: true,
})
export class AkitaCarePlanStore extends EntityStore<ICarePlan> {

    constructor() {
        super();
    }
}