<!-- *ngIf='isStoreReady && !hasError' -->

<app-header></app-header>
<div class="app-body">
    <app-sidebar></app-sidebar>
    <!-- Main content -->
    <main class="main d-flex flex-column">
        <div class="container-fluid px-0 flex-fill">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>
<app-footer></app-footer>




<!-- LOADING SECTION -->
<!-- (isStoreReady || hasError) &&  -->
<div>
    <app-loading [hidden]='!isLoading'></app-loading>
</div>
