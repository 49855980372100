import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { StoreService } from '../../../../../services/store.service';

@Injectable({
  providedIn: 'root',
})
export class PatientListService {
  private componentDestroyed: Subject<void> = new Subject();
  private refreshHistory: Subject<void> = new Subject();

  constructor(private store: StoreService) { }

  checkPatientAlreadyAddedInRegistry(idNumber, idType) {
    let patientList = this.store.getPatientRegistryList();

    if (
      patientList.find(
        patient =>
          patient.userId.number === idNumber && patient.userId.idType === idType
      ) !== undefined
    ) {
      return true;
    } else {
      return false;
    }
  }

  setRefreshHistory() {
    this.refreshHistory.next();
  }

  getRefreshHistory() {
    return this.refreshHistory.asObservable();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}