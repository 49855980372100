import { MedicalAlert } from './../../../../objects/state/MedicalAlert';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patientMedicalAlerts', idKey: 'alertId' })
export class PatientMedicalAlertsStore extends EntityStore<MedicalAlert> {
  constructor() {
    super();
  }

  addNewData(item) {
    this.add(item);
  }
}
