<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>The following item has a invalid expiry date at line number {{ invalidLineNumber }}. Please fix and try again.</p>

    <p class="item">Item Code : <span>{{ inavlidLine.value.itemCode }}</span></p>
    <p class="item">Item Name : <span>{{ inavlidLine.value.itemName }}</span></p>
    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-brand-secondary" (click)="onClose()">Okay</button>
</div>