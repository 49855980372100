import { AkitaCaseStoreService } from './../../../services/states/akita-case/akita-case-store.service';
import { PatientMedicalCoverageStore } from './../../../services/states/akita-patient/patient-medical-coverages/patient-medical-coverage.store';
import { config } from './../../../constants/medical-coverage.config';
import { COVERAGE_MODE } from './../../../constants/app.constants';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormArray,
  Validators,
} from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject, Observable } from 'rxjs';
import { PatientAddQueueConfirmationService } from './patient-add-queue-confirmation.service';
import * as moment from 'moment'
import {
  DB_FULL_DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
} from '../../../constants/app.constants';
import { AkitaAppQuery } from '../../../services/states/akita-app.query';
import { AkitaClinicQuery } from '../../../services/states/akita-clinic.query';
import { groupByKey } from '../../../util/array.util';
import { AttachedMedicalCoverage } from '../../../objects/AttachedMedicalCoverage';
import { IPackageItem } from '../../shared/package/package.model';
import { AkitaPatientAppQuery } from '../../../services/states/akita-patient/akita-patient-app.query';
import {
  CaseCoveragePlan,
  createCaseCoveragePlan,
} from '../../../objects/state/Case';
import { filter } from 'rxjs/operators';
import { PatientService } from '../../../services/patient.service';
import { PatientPayable } from '../../../objects/response/PatientPayable';


@Component({
  selector: 'app-patient-add-queue-confirmation',
  templateUrl: './patient-add-queue-confirmation.component.html',
  styleUrls: ['./patient-add-queue-confirmation.component.scss'],
  styles: [
    `
      :host >>> .tooltip-inner {
        background-color: #757575;
        color: #fff;
      }
      :host >>> .tooltip.top .tooltip-arrow:before,
      :host >>> .tooltip.top .tooltip-arrow {
        border-top-color: #757575;
      }

      :host >>> .tooltip.bottom .tooltip-arrow:before,
      :host >>> .tooltip.bottom .tooltip-arrow {
        border-bottom-color: #757575;
      }

      :host >>> .tooltip.left .tooltip-arrow:before,
      :host >>> .tooltip.left .tooltip-arrow {
        border-left-color: #757575;
      }

      :host >>> .tooltip.right .tooltip-arrow:before,
      :host >>> .tooltip.right .tooltip-arrow {
        border-right-color: #757575;
      }
    `,
  ],
  providers: [PatientAddQueueConfirmationService],
})
export class PatientAddQueueConfirmationComponent implements OnInit, OnDestroy {
  @Input() params;
  @Input() isNewVisit;

  mode = COVERAGE_MODE;

  @Input() patientSources: string;
  @Input() personIncharge: string;
  @Input() planIdsFromCase: AttachedMedicalCoverage[];
  planIdsFromCaseSubject: Subject<AttachedMedicalCoverage[]>;

  @Input() config: config;
  @Input() visitId: string;
  consultationFormGroup: FormGroup;
  event: EventEmitter<any> = new EventEmitter();
  patientCoverages: FormArray;
  selectedCoverages: FormArray;
  policyHolderInfo: FormArray;
  public patientPayables: FormArray;
  // patientId: string;

  // Input Data
  title: string;
  type: string;

  caseRows = [];
  openCases = [];

  packageRows = [];

  selectedCase: any = {};

  isMultiVisitClinic = false;
  isMedicalCoverageSelectionDisabled = false;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private patientAddQueueService: PatientAddQueueConfirmationService,
    private akitaAppQuery: AkitaAppQuery,
    private akitaClinicQuery: AkitaClinicQuery,
    private cdr: ChangeDetectorRef,
    private patientMedCovStore: PatientMedicalCoverageStore,
    private akitaCaseStore: AkitaCaseStoreService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private patientService: PatientService,
  ) {
    this.selectedCoverages = this.fb.array([]);
    const clinicFeatures = this.akitaClinicQuery.getCurrentClinic(
      'clinicFeatures'
    );
    if (clinicFeatures.includes('MULTI_VISIT')) {
      this.isMultiVisitClinic = true;
    } else {
      this.isMultiVisitClinic = false;
    }

    this.planIdsFromCaseSubject = new Subject<AttachedMedicalCoverage[]>();
  }

  ngOnInit() {
    this.akitaPatientAppQuery.patientInfo$.subscribe(result => {
      const { patientPayables } = result;
      if (patientPayables) {
        this.patientPayables = this.patientService.getPopulatedPatientPayableArray(patientPayables);
      } else {
        this.patientPayables = this.patientService.getEmptyPatientPayableArray();
      }
    });

    this.consultationFormGroup = this.createConsultationPage();

    if (!this.selectedCoverages) {
      this.selectedCoverages = this.fb.array([]);
    }

    if (this.config.isNewVisit) {
      this.patientMedCovStore.setLoading(false);
    }

    if (this.isAddToRegistry()) {
      const patientId = this.params
        ? this.params.patientId
          ? this.params.patientId
          : this.akitaPatientAppQuery.getId()
        : this.akitaPatientAppQuery.getId();
      const clinicId = this.params
        ? this.params.clinicId
          ? this.params.clinicId
          : this.akitaAppQuery.getAppClinicId()
        : this.akitaAppQuery.getAppClinicId();

      if (!this.config.isNewPatient) {
        this.getOpenCase(patientId, clinicId);
      }
    }
  }

  ngAfterViewInit(): void { }

  ngOnDestroy() {
    // this.akitaCaseStore.reset();
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  createConsultationPage(): FormGroup {
    const get = (key: string) => (this.params ? this.params[key] : '');

    if (!this.patientSources) this.patientSources = '';

    const patientAddFormGroup = this.fb.group({
      visitDate: new FormControl(),
      patientSources: this.patientSources,
      personIncharge: '',
      preferredDoctor: [get('preferredDoctor')],
      purposeOfVisit: [get('purposeOfVisit')],
      priority: ['', Validators.required],
      remarks: get('remarks'),
    });

    const patientSourceRequiredSub = this.akitaAppQuery.select(entity => entity.clinic).pipe(
      filter(clinic => !!clinic)
    ).subscribe(clinic => {
      let patientSourceRequired = false;
      if (clinic.clinicFeatures && clinic.clinicFeatures.length > 0) patientSourceRequired = clinic.clinicFeatures.indexOf('PATIENT_SOURCES') !== -1;

      if (patientSourceRequired) patientAddFormGroup.get('patientSources').setValidators([Validators.required]);
      patientAddFormGroup.markAllAsTouched();
      if (patientSourceRequiredSub) patientSourceRequiredSub.unsubscribe();
    });

    return patientAddFormGroup;
  }

  getOpenCase(patientId: string, clinicId: string) {
    this.patientAddQueueService
      .getOpenCases(patientId, clinicId)
      .subscribe(responseData => {
        const { payload } = responseData;
        this.openCases = payload;
        this.populateCaseData(payload);
      });
  }

  populateCaseData(data) {
    if (this.isMultiVisitClinic) {
      this.caseRows = this.mapCasePayload(data);
    } else {
      const packagedCases = data.filter(payload => payload.package);
      this.caseRows = this.mapCasePayload(packagedCases);
    }

  }

  mapCasePayload(data: any) {
    return data.map((payload, index) => {
      const tempCase = {
        ...payload,
        date: moment(payload.caseStartDateTime, DB_FULL_DATE_FORMAT).format(
          DISPLAY_DATE_FORMAT
        ),
        noOfVisits: payload.visitIds,
        number: index + 1,
        isPackage: payload.package,
      };
      return tempCase;
    });
  }

  // getPackages(selectedCase: any) {
  //   const packages = selectedCase.
  //   // const groupedPackages = groupByKey(packages, 'itemRefId');
  //   // const purchaseItems = selectedCase.salesOrder.purchaseItem;
  //   // this.addSubItems(groupedPackages, purchaseItems);
  //   // this.packageRows = Object.values(groupedPackages);

  // }

  mapPackageData(packages: IPackageItem[], caseDate: string) {
    return packages.map(pckg => {
      return {
        ...pckg,
        date: caseDate,
        useBy: pckg.expireDate
          ? moment(pckg.expireDate, DB_FULL_DATE_FORMAT).format(
            DISPLAY_DATE_FORMAT
          )
          : '',
        purchaseDate: moment(pckg.purchaseDate, DB_FULL_DATE_FORMAT).format(
          DISPLAY_DATE_FORMAT
        ),
        completed: `${pckg.dispatches.filter(item => item.utilize).length} / ${
          pckg.dispatches.length
          }`,
        dispatches: pckg.dispatches.map(subItem => {
          return {
            ...subItem,
            utilizedDate: subItem.utilizedDate
              ? moment(subItem.utilizedDate, DB_FULL_DATE_FORMAT).format(
                DISPLAY_DATE_FORMAT
              )
              : '',
          };
        }),
      };
    });
  }

  // addSubItems(groupedPackages: any, purchageItems: any[]) {
  //   purchageItems.forEach(item => {
  //     if (!!groupedPackages[item.parentItemRefId]) {
  //       groupedPackages[item.parentItemRefId].subItems.push(item);
  //     }
  //   });
  // }

  expandPackage(event: any, pkg: any) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    pkg.expanded = !pkg.expanded;
    this.packageRows.forEach(pckg => {
      if (pkg.itemCode !== pckg.itemCode) {
        pckg.expanded = false;
      }
    });
  }

  onCaseSelect(event, selectedCase) {
    event.stopImmediatePropagation();
    event.stopPropagation();

    this.resetCaseData(event);
 

    if (event.target.checked) {
      this.selectedCase = selectedCase;

      this.personIncharge = selectedCase.personIncharge || '';
      this.consultationFormGroup.get('personIncharge').patchValue(this.personIncharge);

      if (selectedCase.package) {
        this.packageRows = this.mapPackageData(
          selectedCase.packages,
          selectedCase.date
        );
      }

      this.planIdsFromCase = selectedCase.coverages;
      this.planIdsFromCaseSubject.next(selectedCase.coverages);
      this.isMedicalCoverageSelectionDisabled = true;
    }
  }

  resetCaseData(event) {
    const caseInputCheckboxes = document.querySelectorAll(
      'div.case-input-wrapper input'
    );
    for (let i = 0; i < caseInputCheckboxes.length; i++) {
      const checkbox = caseInputCheckboxes[i];
      if (event.target.name !== checkbox['name'])
        caseInputCheckboxes[i]['checked'] = false;
    }

    this.selectedCase = {};
    this.packageRows = [];
    this.planIdsFromCase = [];
    this.planIdsFromCaseSubject.next([]);
    this.isMedicalCoverageSelectionDisabled = false;
    this.consultationFormGroup.get('personIncharge').patchValue('');
    this.patientSources = '';
    this.personIncharge = '';
    this.consultationFormGroup.get('personIncharge').patchValue(this.personIncharge);
  }

  // setShouldDisable(caseId: string, flag: boolean) {
  //   this.caseRows.forEach(item => {
  //     if (item.caseId !== caseId) {
  //       item.shouldDisable = flag;
  //     }
  //   });
  // }

  getCleanData() {
    const value = this.consultationFormGroup.value;
    const valid = value => value !== null && value !== '';

    let caseCoveragePlan: CaseCoveragePlan[] = [];
    this.selectedCoverages.value.filter(value => {
      
      if (
        valid(value.coverageId) &&
        valid(value.planId) &&
        valid(value.medicalCoverageId)
      ) {
        const casePlanDetails = this.planIdsFromCase
          ? this.planIdsFromCase.find((c: AttachedMedicalCoverage) => {
            return c.planId === value.planId;
          })
          : undefined;

        const coverage = createCaseCoveragePlan(
          value.medicalCoverageId,
          value.planId,
          casePlanDetails ? casePlanDetails.name : '',
          casePlanDetails ? casePlanDetails.remarks : '',
          value.limit,
          value.updated,
          value.payerUserId
        );

        caseCoveragePlan.push(coverage);
      }
    });

    const consultationInfo: ConsultationInfo = new ConsultationInfo(
      value.preferredDoctor,
      value.remarks,
      value.purposeOfVisit,
      value.priority,
      value.patientSources,
      value.personIncharge,
      this.patientPayables !== undefined && this.patientPayables !== null ? this.patientPayables.value : [],
      caseCoveragePlan
    );
    return consultationInfo;
  }

  disableConfirmBtn() {
    if (!this.consultationFormGroup.valid) {
      if (this.isAddToRegistry()) {
        return true;
      }
    }

    return false;
  }

  hideConsultationInfo() {
    if (this.isAttachCoverage() || this.isDisplayCoverage()) {
      return true;
    }
    return false;
  }

  hideCaseInfo(): boolean {
    return !this.caseRows.length;
  }

  showMessage(hoveredCase: any): string {
    if (hoveredCase.hasOpenVisits) {
      return 'You cannot attach this case to visit as there is already a open visit under this case';
    } else {
      return '';
    }
  }

  isAddToRegistry() {
    return this.config.type === this.mode.ADD_TO_REGISTRY;
  }

  isAttachCoverage() {
    return this.config.type === this.mode.ATTACH_MEDICAL_COVERAGE;
  }

  isDisplayCoverage() {
    return this.config.type === this.mode.DISPLAY_MEDICAL_COVERAGE;
  }

  hideModal() {
    if (this.isNewVisit) {
      while (this.selectedCoverages.controls.length > 0) {
        this.selectedCoverages.removeAt(0);
      }

      if (this.patientCoverages) {
        this.patientCoverages.controls.forEach(control => {
          control.get('isSelected').patchValue(false);
        });
      }
    }

    this.bsModalRef.hide();
  }

  onBtnNextClicked() {
    const cleanedData = this.getCleanData();
    const selectedCaseId = this.selectedCase.caseId || '';

    this.event.emit(['ADD_TO_REGISTRY', cleanedData, selectedCaseId]);
  }

  onBtnSavePatientOnly() {
    this.event.emit(['REGISTER_PATIENT_ONLY']);
  }

  @HostListener('document:keyup.f4', ['$event'])
  keyEventF4(event: KeyboardEvent) {
    if (this.consultationFormGroup.valid) {
      event.stopImmediatePropagation();
      this.onBtnNextClicked();
    }
  }

  getPlanIdFromCaseObservable(): Observable<AttachedMedicalCoverage[]> {
    return this.planIdsFromCaseSubject.asObservable();
  }
}

export class ConsultationInfo {
  preferredDoctor: string;
  remarks: string;
  purposeOfVisit: string;
  priority: string;
  patientSources: string;
  personIncharge: string;
  patientPayables?: PatientPayable[];
  attachedMedicalCoverages: CaseCoveragePlan[];

  constructor(
    preferredDoctor?: string,
    remarks?: string,
    purposeOfVisit?: string,
    priority?: string,
    patientSources?: string,
    personIncharge?: string,
    patientPayables?: PatientPayable[],
    attachedMedicalCoverages?: CaseCoveragePlan[]
  ) {
    this.preferredDoctor = preferredDoctor || undefined;
    this.remarks = remarks || '';
    this.purposeOfVisit = purposeOfVisit || '';
    this.priority = priority || '';
    this.patientSources = patientSources || '';
    this.personIncharge = personIncharge || '';
    this.patientPayables = patientPayables || [];
    this.attachedMedicalCoverages = attachedMedicalCoverages || [];
  }
}
