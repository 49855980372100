import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Logger} from "../../../util/logger";
import {WhitelistedItemService} from "../../../services/whitelisted-item.service";

const logger = new Logger('WhitelistedItemChipComponent', 'whitelisted-chip');

@Component({
  selector: 'app-whitelisted-item-chip',
  templateUrl: './whitelisted-item-chip.component.html',
  styleUrls: ['./whitelisted-item-chip.component.scss'],
})
export class WhitelistedItemChipComponent implements OnInit, OnChanges {

  @Input() itemId: string
  whitelisted = false
  mohOrAlps = false

  private inputChangesSubject = new Subject<string>();
  constructor(
    private whitelistedItemService: WhitelistedItemService
  ) {
  }

  ngOnInit(): void {
    this.inputChangesSubject.pipe(
      switchMap(itemId => this.whitelistedItemService.withWhitelistedItemFor(itemId))
    ).subscribe(whitelistedItemInfo => {
      if (!whitelistedItemInfo) {
        logger.log('No whitelisted item info', whitelistedItemInfo)
        this.whitelisted = false
        this.mohOrAlps = false
        return;
      }
      let {whitelistedItem, chargeItem} = whitelistedItemInfo;
      if (!whitelistedItem) {
        logger.log('No whitelisted item', whitelistedItem)
        this.whitelisted = false
        this.mohOrAlps = false
        return;
      }

      this.whitelisted = true
      this.mohOrAlps = !!whitelistedItem.itemRefId
    })
    this.inputChangesSubject.next(this.itemId)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemId) {
      this.inputChangesSubject.next(changes.itemId.currentValue)
    }
  }

}
