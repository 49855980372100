import {
  DB_FULL_DATE_FORMAT,
  DISPLAY_DATE_TIME_NO_SECONDS_FORMAT,
} from './../constants/app.constants';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'displayDateFullDateFormat',
})
export class DisplayDateFullDateFormatPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const date = moment(value, DB_FULL_DATE_FORMAT);
    if (value !== '' && value !== undefined && value !== null) {
      return date.format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT);
    } else {
      return '';
    }
  }
}
