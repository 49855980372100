import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap";
import DatePickerConfig from "../../../../objects/DatePickerConfig";
import { SFL_TEST_ORDER } from "../../../../constants/app.constants";
import { BehaviorSubject } from "rxjs";
import { AkitaSFLSetupQuery } from '../../../../services/states/akita-sfl-setup.query';
import { SFLSetupData } from '../../../../services/states/akita-sfl-setup.store';

@Component({
  selector: 'app-sfl-details-modal',
  templateUrl: './sfl-details-modal.component.html',
  styleUrls: ['./sfl-details-modal.component.scss']
})
export class SflDetailsModalComponent implements OnInit {

  @Input() title: string;
  @Input() data: any

  @Output() submit = new EventEmitter<any>();

  public addFormGroup: FormGroup = this.fb.group({
    id: [],
    screenDate: [, Validators.required],
    screeningType: [, Validators.required],
    testType: [, Validators.required],
    testOrder: [, Validators.required],
    followUpOutcome: [],
    screeningOutcome: [],
    followUpDate: [],
    visitId: [],
    conditionCodes: []
  });

  screeningDateDatePickerConfig = new DatePickerConfig(
    null,
    new Date(),
    null,
    'bottom',
    'none'
  );

  screeningTypes: any[] = [];
  screeningSubTypesFiltered = new BehaviorSubject<any[]>([])
  testOrders: any[] = SFL_TEST_ORDER
  screeningExceptionalConditions = new BehaviorSubject<any[]>([])
  followUpOutcomes: any[] = []
  allScreeningTypes: any[] = [];
  allScreeningExceptionalConditions: any[] = [];
  screeningSubTypes: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private _akitaSLFSetupQuery: AkitaSFLSetupQuery
  ) { }

  ngOnInit(): void {
    this.initSFLSetupData();

    this.addFormGroup.get('screeningType').valueChanges.subscribe(value => {
      const testTypeFc = this.addFormGroup.get('testType');
      testTypeFc.reset()

      this.populateDropdownValues(value);
    });

    this.addFormGroup.get('testType').valueChanges.subscribe(value => {
      this.addFormGroup.patchValue({
        conditionCodes: []
      })
    });

    this.addFormGroup.get('testOrder').valueChanges.subscribe(value => {
      if (value != 4) {
        this.addFormGroup.patchValue({
          followUpOutcome: ''
        })
      }
    });
  }

  private initSFLSetupData(): void {
    const SFLSetupData = (this._akitaSLFSetupQuery.getAll() as Array<SFLSetupData>);

    this.allScreeningTypes = SFLSetupData.find(types => types.name === 'SCREENING_TYPE').values;
    this.screeningSubTypes = SFLSetupData.find(types => types.name === 'SCREENING_SUB_TYPE').values;
    this.followUpOutcomes = SFLSetupData.find(types => types.name === 'FOLLOW_UP_OUTCOME').values;
    this.allScreeningExceptionalConditions = SFLSetupData.find(types => types.name === 'SCREENING_EXCEPTIONAL_CONDITION').values;
    //Filter
    this.screeningTypes = this.allScreeningTypes.filter(item => !(item.externalCode === 'H' || item.externalCode === 'D'));

    this.patchFormGroup();
  }

  private patchFormGroup(): void {
    if (!!this.data) {
      this.addFormGroup.patchValue(this.data);
      this.populateDropdownValues(this.addFormGroup.get('screeningType').value);
    }
  }

  private populateDropdownValues(value: string): void {

    function filterByCmsCode(list: any[], ...codes: string[]) {
      return list.filter(item => codes.includes(item.cmsCode));
    }

    switch (value) {
      case 'COLORECTAL_CANCER_SCREENING': {
        this.screeningSubTypesFiltered.next(filterByCmsCode(this.screeningSubTypes, 'FITT', 'COLO'));
        this.screeningExceptionalConditions.next(filterByCmsCode(this.allScreeningExceptionalConditions, 'SEC04'));
        break
      }

      case 'CHRONIC_SCREENING': {
        this.screeningSubTypesFiltered.next(filterByCmsCode(this.screeningSubTypes, 'OGTT', 'REPEAT_FASTING', 'CHRONIC_HBA1C'));
        break
      }

      case 'CERVICAL_CANCER_SCREENING': {
        this.screeningSubTypesFiltered.next(filterByCmsCode(this.screeningSubTypes, 'REPEAT_PAP_SMEAR', 'REPEAT_HPV_DNA'));
        break
      }

      case 'BREAST_CANCER_SCREENING': {
        this.screeningSubTypesFiltered.next(filterByCmsCode(this.screeningSubTypes, 'MAMMO'));
        this.screeningExceptionalConditions.next(filterByCmsCode(this.allScreeningExceptionalConditions, 'SEC04'));
        break;
      }
    }
  }

  onBtnExit() {
    this.bsModalRef.hide();
  }

  onBtnSave() {
    if (this.addFormGroup.valid) {
      this.submit.next(this.addFormGroup.value)
    }
  }
}
