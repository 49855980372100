import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaStockAdjustmentStore } from './akita-stock-adjustment.store';
import { InventoryInfo } from '../../objects/state/InventoryInfo';

@Injectable({ providedIn: 'root' })
export class AkitaStockAdjustmentQuery extends QueryEntity<InventoryInfo> {
  stockAdjustmentList$ = this.selectAll();

  constructor(protected store: AkitaStockAdjustmentStore) {
    super(store);
  }
}
