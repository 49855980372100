import { Injectable } from '@angular/core';

import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { CidcMapping } from '../../objects/CidcMapping';
import { AkitaCIDCMappingStore } from './akita-cidc-mapping.store';

@Injectable({ providedIn: 'root' })
export class AkitaCIDCMappingQuery extends QueryEntity<CidcMapping> {
  cidcMappingList$ = this.selectAll();

  constructor(protected store: AkitaCIDCMappingStore) {
    super(store);
  }
}
