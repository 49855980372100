import { FormControl, ValidatorFn } from '@angular/forms';

export function PaymentAmountValidator(control: FormControl): ValidatorFn | any {
  if (String(control.value).indexOf('.') !== -1) {
    const amountSplitInString = String(control.value).split('.');

    if (amountSplitInString.length === 2) {
      if (amountSplitInString[1].length > 2) return { invalidAmount: true };
    }

    if (amountSplitInString.length < 1 || amountSplitInString.length > 2) return { invalidAmount: true };
  }
  return null;
}
