<div class="modal-header">
    <h4 class="modal-title pull-left">CMIS Details</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnExit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-danger" role="alert">
                <b>Disclaimer: Currently CMIS data does not sync to CMS. If a line needs to be added to CMS, please manually add the line accordingly.</b>
              </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <tabset #tabs class="custom-tabs">
                <tab heading="ADR Information ({{cmisAlerts.length}})">
                    <table class="custom-table custom-table-light">
                        <thead>
                          <tr>
                            <th>Type </th>
                            <th>Allergy Type</th>
                            <th>Drug / Allergy To</th>
                            <th>Additional Drugs</th>
                            <th>Date Added</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let item of cmisAlerts; let i = index;">
                            <tr >
                                <td>
                                  <div class="wrapper-form-control">
                                      Adverse Drug Reaction
                                    <!-- <input class="input" value="Adverse Drug Reaction" readonly /> -->
                                  </div>
                                </td>
                                <td>
                                  <div class="wrapper-form-control">
                                    Generic Drug
                                    <!-- <input class="input" value="Generic Drug" readonly /> -->
                                  </div>
                                </td>
                                <td >
                                    <div class="wrapper-form-control">
                                        {{item?.mainDrugName}}
                                        <!-- <input class="input" value="{{item.cmisdrugKey}}" readonly /> -->
                                       </div>
                                </td>
                                <td>
                                  <div class="wrapper-form-control">
                                    {{item?.additionalNames}}
                                    <!-- <input class="input" value="" readonly /> -->
                                  </div>
                                </td>
                                <td>
                                  <div class="wrapper-form-control">
                                    {{item?.createdDateStr}}
                                    <!-- <input class="input" value="item.createdDate" readonly /> -->
                                  </div>
                                </td>
                                <td>
                                  <div class="input-group input-group-sm">
                                    <button 
                                    class="btn btn-link-grey p-1"
                                      (click)="updateDetailsPanelVisibility(item)">
                                      <i [ngClass]="(item.viewDetails) ? 'icon-up-open' : 'icon-down-open'"></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                                <tr *ngIf="item.viewDetails">
                                  <td colspan="7">
                                    <div class="card-custom mb-2">
                                      <div class="card-body">
                                        <div class="row">
                                          <div class="col-md-3">
                                            <div class="wrapper-form-control">
                                              <label>Reaction</label>
                                              {{item?.adverseReaction}}
                                            </div>
                                          </div>
                                          <div class="col-md-3">
                                            <div class="wrapper-form-control">
                                              <label>Is this an allergic reaction?</label>
                                              {{item?.drugAllergyIndicatorDesc}}
                                            </div>
                                          </div>
                                          <div class="col-md-3">
                                            <div class="wrapper-form-control">
                                              <label>Information Source</label>
                                              {{item?.infoSourceDesc}}
                                            </div>
                                          </div>
                                          <div class="col-md-3">
                                            <div class="wrapper-form-control">
                                              <label>Reaction Outcomes</label>
                                              {{item?.reactionOutcomeStr}}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row">            
                                          <div class="col-md-3">
                                            <div class="wrapper-form-control">
                                              <label>Causality/Probability to cause reaction?</label>
                                              {{item?.drugProbabilityDesc}}
                                            </div>
                                          </div>                    
                                          <div class="col-md-3">
                                            <div class="wrapper-form-control">
                                              <label>Reporter Type</label>
                                              {{item?.reporterProfessionDesc}}
                                            </div>
                                          </div>                              
                                          <div 
                                            class="col-md-3">
                                            <div class="wrapper-form-control">
                                              <label>Reporter Name</label>
                                              {{item?.reporterName}}
                                            </div>
                                          </div>
                                          <div 
                                            class="col-md-3" >
                                            <div class="wrapper-form-control">
                                              <label>Reporter ID</label>
                                              {{item?.reporterHealthcareEstablishmentCode}}
                                            </div>
                                          </div>
                                          <div class="col-md-12">
                                            <div class="wrapper-form-control">
                                              <label>Remarks</label>
                                                  {{item.updateReason}}
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row">
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                        </tbody>
                      </table>
                </tab>
                <tab heading="Clinical Alert Information ({{cmisMedicalAlerts.length}})">
                    <table class="custom-table custom-table-light">
                        <thead>
                          <tr>
                            <th>Alert Type <span class="compulsory">*</span></th>
                            <th>Alert Name <span class="compulsory"></span></th>
                            <th>Alert Status <span class="compulsory"></span></th>
                            <th>Remarks</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody *ngFor="let alert of cmisMedicalAlerts; let i = index;">
                            <tr>
                                <td>
                                    <div class="wrapper-form-control">
                                        Medical Alert
                                    </div>
                                </td>    
                                <td>
                                    <div class="wrapper-form-control">
                                        {{alert?.alertDesc}}
                                    </div>
                                </td>
                                <td>
                                    <div class="wrapper-form-control">
                                        {{alert?.alertStatusStr}}
                                    </div>
                                </td>
                                <td>
                                    <div class="wrapper-form-control">
                                        {{alert?.alertRemarks}}
                                    </div>
                                </td>
                                <td>
                                    <div class="wrapper-form-control">
                                        <div class="float-right" dropdown>
                                            <button class="btn btn-link-grey p-1" (click)="updateDetailsPanelVisibility(alert)"><i
                                                    [ngClass]="(alert.viewDetails) ? 'icon-up-open' : 'icon-down-open'"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="alert.viewDetails">
                                <td colspan="6">
                                    <div class="card-custom mb-2">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="wrapper-form-control">
                                                        <label>Priority</label>
                                                        {{alert?.alertPriority}}
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="wrapper-form-control">
                                                        <label>Administrator Type</label>
                                                        {{alert?.reporterProfessionDesc}}
                                                    </div>
                                                </div>
                                
                                                <div class="col-md-3">
                                                    <div class="wrapper-form-control">
                                                        <label>Administrator Name</label>
                                                        {{alert?.reporterName}}
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="wrapper-form-control">
                                                        <label>Administrator ID</label>
                                                        {{alert?.reporterRegNo}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                
                                                <div class="col-md-3">
                                                    <div class="wrapper-form-control">
                                                        <label>Start Date</label>
                                                        {{alert?.alertStartDateStr}}
                                                    </div>                        
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="wrapper-form-control">
                                                        <label>End Date</label>
                                                        {{alert?.alertEndDateStr}}
                                                    </div>                        
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="wrapper-form-control">
                                                        <label>Update Reason</label>
                                                        {{alert?.updateReason}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </tab>
            </tabset>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="btn-toolbar float-right mr-2" role="toolbar">
                <div class="btn-group mr-2" role="group">
                    <button 
                    type="button" 
                    class="button-green"
                    (click)="onBtnExit()">
                    Cancel
                </button>
                </div>
            </div>
        </div>
    </div>
</div>