
<div class="card">
    <div class="card-header">
        Medical Coverage
    </div>
    <div class="card-body">
        <!-- <pre>{{ isLoading$ | async }}</pre> -->
        <div class="row">
            <div class="col-12">
                <div class="alert alert-danger mt-2" role="alert" *ngIf="rpaError !== '' ">
                    {{rpaError}}
                </div>                
                <div *ngIf="patientCoverages">
                    <div class="row data-table">
                        <div *ngIf="hasUpdatePriority" class="col-1">
                            <label class="mb-0">Priority</label>
                        </div>
                        <ng-container>
                            <div class="col"><label class="mb-0">Company</label></div>
                            <div class="col"><label class="mb-0">Plan</label></div>
                            <div class="col"><label class="mb-0">Coverage No</label></div>
                            <div *ngIf="hasUpdatePriority || config.showBalance" class="col"><label
                                    class="mb-0">Balance</label></div>
                        </ng-container>
                    </div>
                    <div *ngIf="(isLoading$ | async) === false">
                        <!-- medical coverage disabled: {{ isMedicalCoverageSelectionDisabled }} -->
                        <div *ngFor="let item of patientCoverages.controls; let i = index;">
                            <!-- [visitId]="visitId" -->
                            <app-medical-coverage-item 
                                [patientCoverageItem]='item'
                                [visitId]="visitId"
                                [hasUpdatePriority]='hasUpdatePriority'
                                [showBalance]="config.showBalance"
                                [selectedCoverages]='selectedCoverages'
                                [isMedicalCoverageSelectionDisabled]="isMedicalCoverageSelectionDisabled">
                            </app-medical-coverage-item>
                        </div>
                        <div *ngIf="patientCoverages.controls.length > 0; else emptyListTemplate">
                            <hr class="mt-0 mb-2">
                            <a target="_blank" href="https://tip.healthwaymedical.com/">
                                <span class="icon-link"></span>TIP Portal
                            </a>
                        </div>
                    </div>
                    <div *ngIf="isLoading$ | async" style="text-align:center;">
                        <span class="spinner-border my-5" style="font-size:1rem;color:gray;"></span>
                    </div>
                </div>
                <div class="container-fluid px-0" *ngIf="hasEditModal || hasCoverageModal">
                    <button *ngxPermissionsOnly="['ROLE_MEDICAL_MANAGE_POLICY_HOLDER']"
                        class="btn btn-outline-brand-secondary float-right mt-3" type="button"
                        (click)="addMedicalCoverage($event)">+
                        Add Coverage
                    </button>
                </div>
            </div>
        </div>

        <ng-template #emptyListTemplate>
            <div class="row p-3" style="background-color:#f2f4f7;color:dimgrey">
                <span *ngIf="hasUpdatePriority">
                    <span class="icon-attention"></span>&nbsp;&nbsp; No medical coverage has been added yet. Please go
                    to
                    Patient
                    Details to do so.
                </span>
                <span *ngIf="hasEditModal">
                    <span class="icon-info-circled"></span>&nbsp;&nbsp;Please click below to assign a medical
                    coverage.</span>
                <span *ngIf="!hasUpdatePriority && !hasEditModal">
                    <span class="icon-attention"></span>&nbsp;&nbsp;No medical coverage has been added yet. Please go to
                    Patient
                    Details
                    to do so.
                </span>
            </div>
        </ng-template>
