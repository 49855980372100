import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss'],
})
export class DocViewerComponent implements OnInit, OnChanges {
  @Input() imgSrc;
  @Input() status: Status;
  @Input() isPdf = false

  statusList = Status;

  public document: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadDocument();
  }

  ngOnInit() {
    this.loadDocument();
  }

  private loadDocument(): void {
    if(this.isPdf) {
      const byteArray = new Uint8Array(
        atob(this.imgSrc)
          .split("")
          .map(char => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      this.document = fileURL;
    } else {
      var image = new Image();
      image.src = `data:image/png;base64,${this.imgSrc}`;
      this.document = `data:image/png;base64,${this.imgSrc}`;
    }
  }

  downloadImage(){
    var link = document.createElement('a');
    link.href = this.document;
    link.download = new Date().toString();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export enum Status {
  NO_DATA,
  LOADING,
  LOADED,
}