import {SelectItemOptions} from './../../../../objects/SelectItemOptions';
import {AppointmentsFormService} from './../../../../services/appointments-form.service';
import {
  DB_FULL_DATE_FORMAT,
  DB_FULL_DATE_FORMAT_NO_SECOND,
  DB_VISIT_DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_NO_SECONDS_FORMAT,
} from './../../../../constants/app.constants';
// import { store } from '@angular/core/src/render3/instructions';
import {Appointment} from './../../../../objects/Appointment';
import {ApiAppointmentsService} from './../../../../services/api-appointments.service';
import {AlertService} from './../../../../services/alert.service';
import {ApiPatientInfoService} from './../../../../services/api-patient-info.service';
import {FormControl, FormGroup} from '@angular/forms';

import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit,} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil,} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {StoreService} from '../../../../services/store.service';
import * as moment from 'moment';
import {AppointmentListResponse} from '../../../../objects/response/AppointmentListResponse';
import {Doctor} from '../../../../objects/SpecialityByClinic';
import {AkitaAppQuery} from '../../../../services/states/akita-app.query';
import {BsModalRef} from 'ngx-bootstrap';

import {BsModalService} from 'ngx-bootstrap/modal';
import { AppointmentMasterQuery } from '../../../../state/appointments/appointment-master/appointment-master.query';

@Component({
  selector: 'app-appointments-new',
  templateUrl: './appointments-new.component.html',
  styleUrls: ['./appointments-new.component.scss'],
})
export class AppointmentsNewComponent implements OnInit, OnDestroy {
  public event: EventEmitter<any> = new EventEmitter();
  @Input() appointmentFormGroup: FormGroup;
  @Input() doctors: Array<SelectItemOptions<Doctor>>;
  @Input() clinicId;
  @Input() searchParams: any;
  nextAppointment = ""
  nextFollowUpAppointment = ""
  @Input() editMode = false;
  displayStatusDropdown = false;
  patientListDropdown = [];
  apptStatusDropdown = ['PENDING', 'CONFIRM'];
  searchField: FormControl;
  title: string;
  currentDate: Date;
  storedVisitDate;
  searchName = new Subject<string>();
  loading = false;
  isSaving = false;
  bsModalRef: BsModalRef;


  apptIsAvailable: boolean = true;
  hasDoubleAppointments: boolean = false;
  apptBookedWithin1Hour: boolean = false;
  userConfirmedWarning: boolean = false;
  @Input() consultationFormGroup: FormGroup;

  private componentDestroyed: Subject<void> = new Subject();


  constructor(
    private apiPatientInfoService: ApiPatientInfoService,
    private alertService: AlertService,
    private apiAppointmentService: ApiAppointmentsService,
    private appointmentsFormService: AppointmentsFormService,
    private store: StoreService,
    private akitaAppQuery: AkitaAppQuery,
    private   modalService: BsModalService,
    private appointmentMasterQuery: AppointmentMasterQuery,
  ) {}

  ngOnInit() {
    this.apptIsAvailable = true;
    this.createAppointmentsFormGroup();
    this.subscribeOnFormGroupChanges();
    this.loadPatientDetail();
    this.setDisplayStatusDropDown()
    this.listenForPatientNameInput();

    if (!this.appointmentFormGroup.get('id').value) {
      this.checkWithin1HourBookingPeriod();
    }

  }

  createAppointmentsFormGroup() {
    if (!this.appointmentFormGroup) {
      this.appointmentFormGroup = this.appointmentsFormService.createAppointmentFormGroup(
        null
      );
      this.setMandatoryFields(false);
    }
  }

  subscribeOnFormGroupChanges() {
    this.appointmentFormGroup.get('visitDate').valueChanges.subscribe(value => {
      this.checkErrorMessages();
      this.checkWithin1HourBookingPeriod();
    });

    this.appointmentFormGroup
      .get('preferredDoctor')
      .valueChanges.subscribe(value => {
        this.checkErrorMessages();
      });

    this.appointmentFormGroup.get('duration').valueChanges.subscribe(value => {
      this.checkErrorMessages();
    });

    this.appointmentsFormService
      .getPatientAppointmentConfirm()
      .pipe(
        // distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        if (value) {
          if (this.isNewAppointment()) {
            this.createNewAppointment();
          } else {
            this.updateAppointment();
          }
        } else {
          this.isSaving = false;
        }

      });
  }

  checkErrorMessages() {
    this.checkAvailability();
    this.checkDoubleDoctorAppointments();
  }

  checkWithin1HourBookingPeriod() {
    if (
      this.appointmentFormGroup.get('visitDate').value !== this.storedVisitDate
    ) {
      this.apptBookedWithin1Hour = this.appointmentsFormService.within1HourBookingPeriod(
        this.appointmentFormGroup.get('visitDate')
      );
    }
  }

  checkAvailability() {
    this.apptIsAvailable = true;
    const visitDate = moment(
      this.appointmentFormGroup.get('visitDate').value
    ).format(DB_FULL_DATE_FORMAT_NO_SECOND);
    const doctorId = this.appointmentFormGroup.get('preferredDoctor').value;
    if (
      !this.isNullOrEmptyOrUndefined(visitDate) &&
      !this.isNullOrEmptyOrUndefined(doctorId)
    ) {
      this.apiAppointmentService
        .checkAvailability(this.store.getClinicId(), doctorId, visitDate)
        .subscribe(res => {
          const { payload } = res;
          this.apptIsAvailable = payload;
        });
    }
  }

  checkDoubleDoctorAppointments() {
    this.hasDoubleAppointments = false;
    let date = moment(this.appointmentFormGroup.get('visitDate').value).format(
      DISPLAY_DATE_FORMAT
    );
    if (this.appointmentFormGroup.get('preferredDoctor').value !== null && this.appointmentFormGroup.get('preferredDoctor').value !== "") {
      this.apiAppointmentService
        .listAppointmentsByDoctor(
          this.appointmentFormGroup.get('preferredDoctor').value,
          date,
          date
        )
        .subscribe(res => {
          let otherAppts: AppointmentListResponse[] = res.payload;
          let apptStartTime = moment(
            this.appointmentFormGroup.get('visitDate').value
          ).format(DB_FULL_DATE_FORMAT_NO_SECOND);
          let apptEndTime = moment(
            this.appointmentFormGroup.get('visitDate').value
          ).add(this.appointmentFormGroup.get('duration').value, 'minutes');

          this.hasDoubleAppointments = this.appointmentsFormService.hasDoubleAppointment(
            otherAppts,
            apptStartTime,
            apptEndTime,
            this.appointmentFormGroup.get('id').value
          );
        });
    }
  }

  setMandatoryFields(isNewPatient) {
    this.appointmentsFormService.setMandatoryFields(
      this.appointmentFormGroup,
      isNewPatient
    );
  }
  loadPatientDetail() {
    const patientInfo = <Appointment>this.appointmentFormGroup.value;
    if (patientInfo.id !== '') {

      this.patientListDropdown = [
        this.createPatientDropdownEntry('id', patientInfo),
      ];
    }
  }
  addPatienttoform(){

    var oldPatient=localStorage.getItem('oldPatient');
    if(oldPatient==="true"){
      var apptdata=localStorage.getItem('appointmentObject')
 let resultOldPatient=JSON.parse(apptdata)
      let dropDownValue= {
        "name": resultOldPatient.name?resultOldPatient.name:"",
        "phone": resultOldPatient.contactNumber.number?resultOldPatient.contactNumber.number:"",
        "dob": resultOldPatient.dob?resultOldPatient.dob:"",
        "id": resultOldPatient.userId.number?resultOldPatient.userId.number:"",
        "patientId":resultOldPatient.id?resultOldPatient.id:""

      }
    this.patientListDropdown.push(dropDownValue);
      this.appointmentFormGroup.patchValue({
        patientId: resultOldPatient.id?resultOldPatient.id:""
      });
      this.patientListDropdown= this.patientListDropdown;
    }

    localStorage.removeItem('oldPatient');
    localStorage.removeItem('appointmentObject');
  }

  listenForPatientNameInput() {


    try {


      this.searchName
        .pipe(
          filter(searchInput => {

            this.patientListDropdown = [];
            if (searchInput && searchInput.length > 0) {
              const str = searchInput.replace(/[^a-zA-Z0-9 ]"/g, '');
              if (str.length < 1) {
                return false;
              }
            } else {
              return false;
            }
            return true;
          }),
          distinctUntilChanged((a, b) => {
            return a === b;
          }),
          map(data => {
            data = data.replace(/[^a-zA-Z0-9 ]"/g, '');
            return data;
          }),
          debounceTime(400),
          takeUntil(this.componentDestroyed),
          switchMap(term => this.apiPatientInfoService.search(encodeURI(term)))
        )
        .subscribe(
          data => {

            if (data && data.payload.length > 0) {
              this.patientListDropdown = data.payload.map(patient => {

                return this.createPatientDropdownEntry('name', patient);
              });
            } else {

              this.patientListDropdown = [
                this.createPatientDropdownEntry('', undefined),
              ];
              // this.patientListDropdown = [];
            }

            return data;
          },
          err => {
            try {
              this.loading = false;
            } catch (err) {
              this.alertService.error(JSON.stringify(err.error.message));
            }
          }
        );
    } catch (err) {
      console.error('Search Coverage Error', err);
    }
  }

  setDisplayStatusDropDown(){
    this.displayStatusDropdown = this.akitaAppQuery.getAppClinicId() === this.appointmentFormGroup.get('clinicId').value;
    this.addPatienttoform();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  createPatientDropdownEntry(searchBy, payload) {
    if (payload) {
      return {
        name: searchBy === 'id' ? payload.patientName : payload.name,
        phone: payload.contactNumber.number,
        dob: payload.dob,
        id: payload.userId.number,
        patientId: searchBy === 'id' ? payload.patientId : payload.id,
      };
    } else {
      return {
        name: '',
        phone: '',
        dob: '',
        id: '',
        patientId: '',
      };
    }
  }

  isNullOrEmptyOrUndefined(string) {
    return string === null || string === '' || string === undefined;
  }

  isNewAppointment() {
    const id = this.appointmentFormGroup.get('id').value;
    return this.isNullOrEmptyOrUndefined(id) ? true : false;
  }

  setFormGroupForSaveApi() {
    return this.appointmentsFormService.setAppointmentForApi(
      this.appointmentFormGroup,
      false
    );
  }

  createNewAppointment() {
    const appointment = this.setFormGroupForSaveApi();
    this.apiAppointmentService.create(appointment).subscribe(
      res => {
        this.isSaving = false;

        this.event.emit(res.payload);
      },
      err => {
        this.isSaving = false;
        this.alertService.error(JSON.stringify(err));
       }
    );
  }

  updateAppointment() {
    const appointment = this.setFormGroupForSaveApi();
    const id = this.appointmentFormGroup.get('id').value;

    this.apiAppointmentService.update(id, appointment).subscribe(
      res => {
        this.appointmentsFormService.alertSuccessfulAppointment();
        this.event.emit(res.payload);
        this.isSaving = false;
      },
      err => {
        this.isSaving = false;
        this.alertService.error(JSON.stringify(err));
       }
    );
  }

  addPatient() {
    this.setMandatoryFields(true);
    let url = 'pages/patient/add';
    this.event.emit('AddPattient');
    // const retainAppointmentDetails = confirm(
    //   'Please note that you will be redirected to the patient registration page. \n\nTo create a new appointment, please search for the new patient again after registration is completed. Click OK to proceed.'
    // );

    // if (retainAppointmentDetails) {
    //    this.event.emit('AddPattient');

    //     }
  }

  appendParam() {
    return {
      visitDate: this.appointmentFormGroup.get('visitDate').value,
      duration: this.appointmentFormGroup.get('duration').value,
      reminderDate: this.appointmentFormGroup.get('reminderDate').value,
      purposeOfVisit: this.appointmentFormGroup.get('purposeOfVisit').value,
      preferredDoctor: this.appointmentFormGroup.get('preferredDoctor').value,
      remarks: this.appointmentFormGroup.get('remarks').value,
    };
  }

  @HostListener('document:keyup.f4', ['$event'])
  keyEventF4(event: KeyboardEvent) {
    if (this.appointmentFormGroup.valid && this.apptIsAvailable) {
      this.onBtnSaveClicked();
    }
  }

  onBtnCancelClicked() {

    this.event.emit('Close');
  }

  onBtnSaveClicked() {
    this.isSaving = true;
    this.appointmentsFormService.validateAppointmentCreation(
      this.appointmentFormGroup
    );
    localStorage.setItem('calendarCurrentDate',this.appointmentMasterQuery.getViewDate().toString());
    if(localStorage.getItem('enableDayView') === 'day'){
      this.alertService.sendCalendarEvent();
    }
  }

  onPatientChange(event){
    if(event){
      this.doSearch(event.name);
    } else{
      this.nextAppointment = "";
      this.nextFollowUpAppointment = '';
    }
  }

  doSearch(name: string){
    this.searchParams.patientSearchMap["NAME"] = name;
    this.searchParams.startDate =moment(moment().subtract(1, 'months').toDate()).format(DB_VISIT_DATE_FORMAT);
    this.searchParams.endDate = moment(moment().add(1, 'months').toDate()).format(DB_VISIT_DATE_FORMAT);

    this.apiAppointmentService.searchAppintmentsByPatientParameters(this.searchParams).subscribe(
      res => {
        if (res.payload) {
          // let next;
          // if(res.payload){
          //   for (let index = 0; index < res.payload.length; index++) {
          //     if(moment(res.payload[index].startDate, DB_FULL_DATE_FORMAT) > moment(new Date(), DB_FULL_DATE_FORMAT)){
          //       next = moment(res.payload[index].startDate, DB_FULL_DATE_FORMAT).format(
          //         DISPLAY_DATE_TIME_NO_SECONDS_FORMAT
          //       );
          //       break;
          //     }
          //   }
          // }
          // this.nextAppointment = next ? next : "N/A";

          const nextAppointment = res.payload
            .find(appointment => moment(appointment.startDate, DB_FULL_DATE_FORMAT).isSameOrAfter(moment()) && !appointment.followUp)

          const nextFollowUpAppointment = res.payload
            .find(appointment => moment(appointment.startDate, DB_FULL_DATE_FORMAT).isSameOrAfter(moment()) && appointment.followUp)

          this.nextAppointment = nextAppointment
            ? moment(nextAppointment.startDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT)
            : 'N/A'
          this.nextFollowUpAppointment = nextFollowUpAppointment
            ? moment(nextFollowUpAppointment.startDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT)
            : 'N/A'
        }
      },
      err => {
        this.alertService.error(JSON.stringify(err));
       }
    );
  }

  checkBoxEventCall(event){
    this.userConfirmedWarning = event;
  }

  followUpClinicFeature() {
    return this.akitaAppQuery.checkClinicFeatureExist('FOLLOWUP_APPOINTMENT')
  }
}
