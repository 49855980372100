import { Plan } from './../objects/Plan';
import { CoverageInfo } from './../objects/state/CoverageInfo';
// Libraries
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';

// Objects
import {
  createPatientCoverage,
  MedicalCoverage,
  PatientCoverage,
  createMedicalCoverage,
} from './../objects/MedicalCoverage';
import {
  Insurance,
  createMedicalCoverageResponse,
} from './../objects/response/MedicalCoverageResponse';

// Constants
import {
  DISPLAY_DATE_FORMAT,
  INVENTORY_DATE_FORMAT,
} from './../constants/app.constants';

// Services
import { MedicalCoverageService } from './../views/components/medical-coverage/medical-coverage.service';
import {
  PolicyHolderInfo,
  MedicalCoverageByCaseRes,
} from '../objects/PolicyHolderInfo';
import { createPlan } from '../objects/Plan';
import { Coverages } from '../objects/Case';

@Injectable()
export class MedicalCoverageFormService {
  policyHolder: FormGroup;
  coveragePlan: FormGroup;
  contacts: FormArray;

  today = moment().format(DISPLAY_DATE_FORMAT);

  constructor(
    private fb: FormBuilder,
    private medCovService: MedicalCoverageService
  ) {}

  populateFromInfo(
    info: CoverageInfo,
    caseDetails?: MedicalCoverageByCaseRes,
    chasData?,
    corporateData?,
    medisaveData?,
    insuranceData?,
  ): PatientCoverage[] {
    const patientCoverages: PatientCoverage[] = [];
    const planIds: string[] = [];
    const selectedCoverages = caseDetails
      ? caseDetails.policyHolder.map(policy => {
          return {
            planId: policy.planId,
            medicalCoverageId: policy.medicalCoverageId,
          };
        })
      : [];
    info.coverage.forEach((coverage: MedicalCoverage) => {
      let coveragePlans: Plan[] = coverage.coveragePlans;
      let medicalCoverage: MedicalCoverage = createMedicalCoverage(coverage);

      // coverage.startDate = this.formatDate(coverage.startDate);
      // coverage.endDate = this.formatDate(coverage.endDate);

      coveragePlans.forEach((coveragePlan: Plan) => {
        let policyHolder: PolicyHolderInfo = info.policyHolder.find(
          (policy: PolicyHolderInfo) =>
            policy.medicalCoverageId === medicalCoverage.id &&
            policy.planId === coveragePlan.id
        );

        const chasItem = chasData.find(item => item.coveragePlan.id === coveragePlan.id);
        const corporatItem = corporateData && corporateData.find(item => item.coveragePlan.id === coveragePlan.id);
        const medisaveItem = medisaveData && medisaveData.find(item => item.coveragePlan.id === coveragePlan.id);
        const insuranceItem = insuranceData && insuranceData.find(item => item.coveragePlan.id === coveragePlan.id);
        
        let newCoveragePlan;
        if (chasItem) {
          newCoveragePlan = Object.assign({}, coveragePlan, {
            vaccinationPlan : chasItem && chasItem.vaccinationPlan || false,
            vaccinationStatusList : chasItem && chasItem.vaccinationStatusList || '',
            cdsPlan : chasItem && chasItem.cdsPlan || false,
            cdsQueryStatus : chasItem && chasItem.cdsQueryStatus || '',
            dentalPlan: chasItem && chasItem.dentalPlan || false,
            dentalStatus: chasItem && chasItem.dentalStatus || '',
            hsgFirstVisitStatus: chasItem && chasItem.hsgFirstVisitStatus ? chasItem.hsgFirstVisitStatus : undefined
          });
        } else if (corporatItem) {
          newCoveragePlan = Object.assign({}, coveragePlan, {
            medicalChit : corporatItem && corporatItem.medicalChit || false,
            medicalChitStatusList : corporatItem && corporatItem.medicalChitStatusList || ''
          });
        } else if (medisaveItem) {
          newCoveragePlan = Object.assign({}, coveragePlan, {
            medisavePlan: medisaveItem && medisaveItem.medisavePlan || false,
            medisaveStatus: medisaveItem && medisaveItem.medisaveStatus || ''
          });
        } else if (insuranceData) {
          newCoveragePlan = Object.assign({}, coveragePlan, {
            insuranceItemEligibility: insuranceData && insuranceItem.insuranceItemEligibility || null,
          });
        } else {
          newCoveragePlan = coveragePlan;
        }

        let isDuplicate = this.checkDuplicate(planIds, coveragePlan.id);

        policyHolder.startDate = this.formatDate(policyHolder.startDate);
        policyHolder.endDate = this.formatDate(policyHolder.endDate);

        const plan = createPatientCoverage(
          this.getBalance(info.limit, policyHolder.planId),
          policyHolder.costCenter,
          medicalCoverage,
          policyHolder.policyHolderType,
          this.checkCoverageInactive(medicalCoverage.status),
          policyHolder.endDate,
          isDuplicate,
          false,
          this.checkIsSelected(coveragePlan.id, selectedCoverages, isDuplicate),
          policyHolder.id,
          policyHolder.medicalCoverageId,
          policyHolder.patientCoverageId,
          policyHolder.planId,
          newCoveragePlan,
          this.checkPolicyHolderExpired(
            policyHolder.endDate,
            medicalCoverage.endDate
          ),
          this.checkPolicyExpired(medicalCoverage.endDate),
          this.checkPolicyExcluded(policyHolder.planId),
          this.checkPlanInactive(coveragePlan),
          this.checkPlanExpired(coveragePlan),
          this.checkPolicyInactive(policyHolder.startDate),
          policyHolder.specialRemarks,
          coveragePlan ? coveragePlan.status : 'ACTIVE',
          this.getSelectionOrder(selectedCoverages, policyHolder.planId),
          policyHolder.startDate,
          policyHolder.name,
          this.getCorpIdentificationNumber(policyHolder),
          chasItem && chasItem.sflStatus ? chasItem.sflStatus : undefined
        );
        patientCoverages.push(plan);
      });
    });

    return patientCoverages;
  }

  populateFromInfoForCorpHr(
    info: CoverageInfo,
    caseDetails?: MedicalCoverageByCaseRes,
    chasData?,
    corporateData?,
    insuranceData?,
  ): PatientCoverage[] {
    const patientCoverages: PatientCoverage[] = [];
    const planIds: string[] = [];
    const selectedCoverages = caseDetails
      ? caseDetails.policyHolder.map(policy => {
          return {
            planId: policy.planId,
            medicalCoverageId: policy.medicalCoverageId,
          };
        })
      : [];

    insuranceData.forEach(insuItem => {
      let policyHolder: PolicyHolderInfo = insuItem.policyHolder;
      let coveragePlan = insuItem.coveragePlan;
      let medicalCoverage: MedicalCoverage = createMedicalCoverage(insuItem.medicalCoverage);

      const insuranceItem = corporateData && corporateData.find(item => item.coveragePlan.id === coveragePlan.id);

      let newCoveragePlan;
      if(insuranceItem){
        newCoveragePlan = Object.assign({}, coveragePlan, {
          insuranceItemEligibility: insuranceData && insuranceItem.insuranceItemEligibility || null,
        });
      } else {
        newCoveragePlan = coveragePlan;
      }

      let isDuplicate = this.checkDuplicate(planIds, coveragePlan.id);

      if (policyHolder) {
        policyHolder.startDate = this.formatDate(policyHolder.startDate);
        policyHolder.endDate = this.formatDate(policyHolder.endDate);

        const plan = createPatientCoverage(
          this.getBalance(info.limit, policyHolder.planId),
          policyHolder.costCenter,
          medicalCoverage,
          policyHolder.policyHolderType || 'INSURANCE',
          this.checkCoverageInactive(medicalCoverage.status),
          policyHolder.endDate,
          isDuplicate,
          false,
          this.checkIsSelected(coveragePlan.id, selectedCoverages, isDuplicate),
          policyHolder.id,
          policyHolder.medicalCoverageId,
          policyHolder.patientCoverageId,
          policyHolder.planId,
          newCoveragePlan,
          this.checkPolicyHolderExpired(
            policyHolder.endDate,
            medicalCoverage.endDate
          ),
          this.checkPolicyExpired(medicalCoverage.endDate),
          this.checkPolicyExcluded(policyHolder.planId),
          this.checkPlanInactive(coveragePlan),
          this.checkPlanExpired(coveragePlan),
          this.checkPolicyInactive(policyHolder.startDate),
          policyHolder.specialRemarks,
          coveragePlan ? coveragePlan.status : 'ACTIVE',
          this.getSelectionOrder(selectedCoverages, policyHolder.planId),
          policyHolder.startDate,
          policyHolder.name,
          this.getCorpIdentificationNumber(policyHolder)
        );
        patientCoverages.push(plan);        
      }
    });

    corporateData.forEach(corpItem => {
      let policyHolder: PolicyHolderInfo = corpItem.policyHolder;
      let coveragePlan = corpItem.coveragePlan;
      let medicalCoverage: MedicalCoverage = createMedicalCoverage(corpItem.medicalCoverage);

      const corporatItem = corporateData && corporateData.find(item => item.coveragePlan.id === coveragePlan.id);

      let newCoveragePlan;
      if(corporatItem){
        newCoveragePlan = Object.assign({}, coveragePlan, {
          medicalChit : corporatItem && corporatItem.medicalChit || false,
          medicalChitStatusList : corporatItem && corporatItem.medicalChitStatusList || ''
        });
      } else {
        newCoveragePlan = coveragePlan;
      }
      
      let isDuplicate = this.checkDuplicate(planIds, coveragePlan.id);

      if (policyHolder) {
        policyHolder.startDate = this.formatDate(policyHolder.startDate);
        policyHolder.endDate = this.formatDate(policyHolder.endDate);

        const plan = createPatientCoverage(
          this.getBalance(info.limit, policyHolder.planId),
          policyHolder.costCenter,
          medicalCoverage,
          policyHolder.policyHolderType || 'CORPORATE',
          this.checkCoverageInactive(medicalCoverage.status),
          policyHolder.endDate,
          isDuplicate,
          false,
          this.checkIsSelected(coveragePlan.id, selectedCoverages, isDuplicate),
          policyHolder.id,
          policyHolder.medicalCoverageId,
          policyHolder.patientCoverageId,
          policyHolder.planId,
          newCoveragePlan,
          this.checkPolicyHolderExpired(
            policyHolder.endDate,
            medicalCoverage.endDate
          ),
          this.checkPolicyExpired(medicalCoverage.endDate),
          this.checkPolicyExcluded(policyHolder.planId),
          this.checkPlanInactive(coveragePlan),
          this.checkPlanExpired(coveragePlan),
          this.checkPolicyInactive(policyHolder.startDate),
          policyHolder.specialRemarks,
          coveragePlan ? coveragePlan.status : 'ACTIVE',
          this.getSelectionOrder(selectedCoverages, policyHolder.planId),
          policyHolder.startDate,
          policyHolder.name,
          this.getCorpIdentificationNumber(policyHolder)
        );
        patientCoverages.push(plan);        
      }
    });

    return patientCoverages;
  }

  getCorpIdentificationNumber(policyHolder: PolicyHolderInfo) {
    let num;
    if(policyHolder.corpIdentificationNumber && policyHolder.corpIdentificationNumber.number){
      num = policyHolder.corpIdentificationNumber;
    } else{
      num = policyHolder.identificationNumber;
    }
    return num;
  }

  formatDate(date) {
    if (moment(date, INVENTORY_DATE_FORMAT, true).isValid()) {
      return moment(date, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    } else if (moment(date, DISPLAY_DATE_FORMAT, true).isValid()) {
      return date;
    }
  }

  checkIsSelected(planId: string, selectedCoverages, isDuplicate?: boolean) {
    if (
      selectedCoverages.find(coverage => coverage.planId === planId) !==
        undefined &&
      !isDuplicate
    ) {
      return true;
    } else {
      return false;
    }
  }

  getBalance(balance, planId) {
    return balance && balance[planId] > -1 ? balance[planId] : -1;
  }

  getSelectionOrder(selectedCoverages, planId) {
    if (!selectedCoverages || selectedCoverages.length === 0) {
      return -1;
    } else {
      const index = selectedCoverages.findIndex(
        coverage => coverage.planId === planId
      );
      return index > -1 ? index + 1 : -1;
    }
  }

  checkPolicyInactive(status) {
    return status === 'INACTIVE';
  }

  checkDuplicate(planIds: any[], planId: string) {
    if (planIds.some(plan => plan === planId)) {
      return true;
    } else {
      planIds.push(planId);
      return false;
    }
  }

  checkInactive(status) {

    return status === 'INACTIVE';
  }

  checkPolicyExpired(endDate) {
    return !moment(this.today, DISPLAY_DATE_FORMAT).isSameOrBefore(
      moment(endDate, DISPLAY_DATE_FORMAT)
    );
  }

  checkCoverageInactive(status) {
    return status === 'INACTIVE';
  }

  checkPolicyHolderExpired(holderEndDate, coverageEndDate) {
    return (
      moment(this.today, DISPLAY_DATE_FORMAT).isAfter(
        moment(holderEndDate, DISPLAY_DATE_FORMAT)
      )
    );
  }

  checkPolicyExcluded(planId) {
    return this.medCovService.isPlanExcluedInCurrentClinic(planId);
  }

  checkPlanInactive(element: Plan) {
    return element.status === 'INACTIVE';
  }

  checkPlanExpired(element: Plan) {
    return (
      moment(this.today, DISPLAY_DATE_FORMAT).isAfter(
        moment(element.endDate, DISPLAY_DATE_FORMAT) ))
    
  }

  filterSelectedCoverages(selectedCoverages, patientCoverages) {
    // Filter Coverages that are not assigned to patient anymore,
    // i.e deleted coverages but still may be attached to patient
    //     consultation through separate API

    if (!selectedCoverages) {
      selectedCoverages = this.fb.array([]);
    } else {
      for (var i = selectedCoverages.controls.length - 1; i >= 0; i--) {
        const policyFound = patientCoverages.value.find(curr => {
          return curr.planId === selectedCoverages.at(i).get('planId').value;
        });

        if (!policyFound) {
          selectedCoverages.removeAt(i);
        }
      }
    }
  }

  processResponse(res) {
    var coverages = createMedicalCoverageResponse(
      res.payload.INSURANCE,
      res.payload.CORPORATE,
      res.payload.CHAS,
      res.payload.MEDISAVE
    );

    let plans = [];
    for (const key of Object.keys(res.payload)) {
      res.payload[key].forEach((element: Insurance) => {
        if (!plans.includes(element.policyHolder.planId)) {
          plans.push(element.policyHolder.planId);
        }
      });
    }

    return { coverages, plans };
  }
}
