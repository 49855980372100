import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash'

import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { AkitaStoreService } from '../../services/states/akita-store.service';
import { ClinicSelectComponent } from '../components/clinic/clinic-select/clinic-select.component';
import { environment } from './../../../environments/environment';
import { UserLogin } from './../../objects/User';
import { StoreService } from './../../services/store.service';
import { VersionCheckService } from './../../services/version-check.service';
import { LoaderService } from '../../components/loading/loader.service';
import { DEFAULT_TENANT_ID, DOMAIN_NAMES } from '../../constants/tenants-data';
import { InactivityService } from '../../services/inactivity.service';

import * as moment from 'moment';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  username: string;
  password: string;
  tenantid: string;
  clinic: string;
  tenantId: string;
  tenantidReadOnly: boolean = false;
  registerBtnDisabled: boolean = false;
  buttonText: string;
  isTenantidSaved: boolean = false;

  clinicList = [];
  clinicListOptions = [];
  clinicData = [];
  authorizedClinicList = [];

  loading = false;

  returnUrl = 'pages/patient/list';
  clinicUrl = 'pages/clinic/select';

  bsModalRef: BsModalRef;
  hasShownPopUp = false;

  loginFormGroup: FormGroup;

  public copyYear: number = moment().year();

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private authService: AuthService,
    private alertService: AlertService,
    private storeService: StoreService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private versionCheckService: VersionCheckService,
    private akitaStoreService: AkitaStoreService,
    private permissionsService: NgxPermissionsService,
    private loaderService: LoaderService,
    private _inactivityService: InactivityService
  ) {
    this.loginFormGroup = this.createLoginFormGroup();
  }

  ngOnInit() {
    this.versionCheckService.checkVersion(environment.versionCheckURL);
    this.authorizedClinicList = [];

    const localStorageClinicCode = localStorage.getItem('clinicCode');
    const localStorageClinicId = localStorage.getItem('clinicId');

    if (
      this.authService.isAuthenticated() &&
      localStorageClinicCode &&
      localStorageClinicId
    ) {
      this.router.navigate([this.returnUrl]);
    } else if (
      this.authService.isAuthenticated() &&
      !localStorageClinicCode &&
      !localStorageClinicId
    ) {
      // this.akitaStoreService.init();
      // this.storeService.preInit();
      this.storeService
        .getIsClinicLoaded()
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(data => this.getClinicForModal(data));
    }
  }

  ngOnDestroy() {
    this.storeService.resetClinicLoaded();

    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  onLoginSubmit() {
    this.loaderService.setLoading(true);
    localStorage.removeItem('access_token');
    localStorage.removeItem('clinicId');
    localStorage.removeItem('clinicCode');
    localStorage.removeItem('visitId');
    localStorage.removeItem('previousUrl');
    localStorage.removeItem('previousTitle');
    this.storeService.resetClinicLoaded();

    this.storeService
      .getIsClinicLoaded()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => this.getClinicForModal(data));

    this.username = this.loginFormGroup.get('username').value;
    this.password = this.loginFormGroup.get('password').value;
    this.tenantid = this.loginFormGroup.get('tenantId').value;
    const user = new UserLogin(this.username, this.password, this.tenantid);

    this.loading = true;
    this.authService.login(user).subscribe(
      (resp: any) => {
        const token = resp.headers.get('Authorization')
          ? resp.headers.get('Authorization').substring(7)
          : resp.headers.get('authorization').substring(7);

          const decoded_token = JSON.parse(atob(token.split('.')[1]));
          sessionStorage.setItem('temp_access_token', token);

          if (decoded_token['require-2fa'] !== undefined &&
              decoded_token['require-2fa'] !== null &&
              decoded_token['require-2fa'] === true ) {
                this.router.navigate([`/two-factor/${token}/${resp.body.mobile}`]);
                this.loaderService.setLoading(false);
            } else {
              localStorage.setItem('access_token', token);
              this.akitaStoreService.init();
              this.storeService.startNotificationPolling();
              this._inactivityService.watchUserInactivity();
              this.loaderService.setLoading(false);
            }
      },
      err => {
        this.alertService.error(err.error.error);
        this.loaderService.setLoading(false);
        this.loading = false;
      }
    );
  }

  createLoginFormGroup(): FormGroup {
    this.buttonText = 'Register Computer';
    let defaultTenantID = DOMAIN_NAMES.includes(window.location.hostname) ? DEFAULT_TENANT_ID : null;

    if (!defaultTenantID) {
      defaultTenantID = localStorage.getItem('tenantId');
      if (defaultTenantID) {
        this.buttonText = 'Unregister Computer';
        this.isTenantidSaved = true;
        this.tenantidReadOnly = true;
      }
    } else {
      this.buttonText = 'Unregister Computer';
      this.tenantidReadOnly = true;
      this.registerBtnDisabled = true;
      this.isTenantidSaved = true;
      localStorage.setItem('tenantId', defaultTenantID);
    }

    return this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      tenantId: [defaultTenantID, Validators.required],
    });
  }

  private getClinicForModal(data) {
    this.storeService.getAuthorizedClinicList();
    this.authorizedClinicList = data;

    const numClinics = this.authorizedClinicList.length;
    if (numClinics === 1) {
      this.akitaStoreService.updateClinicCode(
        this.authorizedClinicList[0].clinicCode
      );
      this.akitaStoreService.updateClinicId(this.authorizedClinicList[0].id);

      this.storeService.clinicCode = this.authorizedClinicList[0].clinicCode;
      this.storeService.setClinicId(this.authorizedClinicList[0].id);

      localStorage.setItem('clinicId', this.storeService.getClinicId());
      localStorage.setItem('clinicCode', this.storeService.clinicCode);

      this.permissionsService.hasPermission("ROLE_VIEW_CA_DASHBOARD").then(res => {
        if (res) {

          this.router.navigate(['pages/dashboard']);
        } else {
          this.router.navigate(['pages/patient/list']);
        }
      })

    } else if (numClinics > 1) {
      this.popClinicSelection();
    }
  }

  getClinicList() {
    if (this.clinicList.length < 1) {
      this.clinicList = this.storeService.getClinicList();
    }
  }

  popClinicSelection() {
    const initialState = {
      title: 'Select Clinic',
    };

    if (
      this.authService.isAuthenticated() &&
      !localStorage.getItem('clinicCode') &&
      !localStorage.getItem('clinicId')
    ) {
      this.bsModalRef = this.modalService.show(ClinicSelectComponent, {
        initialState,
        class: 'modal-x-lg Modal is-visuallyHidden',
        backdrop: 'static',
        keyboard: false,
      });

      this.bsModalRef.content.event.subscribe(
        data => {
          this.bsModalRef.hide();
          this.bsModalRef.content.event.unsubscribe();
        },
        err => {
          this.alertService.error(err.error.message);
          this.loading = false;
        }
      );
    }
  }

  saveTenantIdToLocal() {
    if(this.buttonText === 'Register Computer') {
      if(!this.loginFormGroup.get('tenantId').value) {
        this.alertService.error('Please enter Tenant Name');
        return;
      }
      localStorage.setItem('tenantId', this.loginFormGroup.get('tenantId').value);
      this.buttonText = 'Unregister Computer';
      this.tenantidReadOnly = true;
      this.isTenantidSaved = true;
    } else if (this.buttonText === 'Unregister Computer') {
      localStorage.removeItem('tenantId');
      this.buttonText = 'Register Computer';
      this.tenantidReadOnly = false;
      this.isTenantidSaved = false;
    }
  }
}
