import { Injectable } from '@angular/core';

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryEntity } from '@datorama/akita';

import {
  AkitaClinicChargeItemState,
  AkitaClinicChargeItemStore,
} from './akita-clinic-charge-item.store';
import { ChargeItem } from '../../objects/state/ChargeItem';
import { InventoryItem } from '../../objects/InventoryItem';

@Injectable({ providedIn: 'root' })
export class AkitaClinicChargeItemQuery extends QueryEntity<
  AkitaClinicChargeItemState
> {
  consultationSearchCode$ = this.select(state => state.ui.code);
  consultationSearchItemType$ = this.select(state => state.ui.itemType);
  consultationSearchName$ = this.select(state => state.ui.name);
  consultationSearchVendor$ = this.select(state => state.ui.supplierId);

  activeClinicChargedItem$ = this.selectAll({
    filterBy: (entity: ChargeItem) => entity.status === 'ACTIVE' && !entity.dispensingBlocked,
    sortBy: AkitaClinicChargeItemQuery.sortByNameAsc,
  });

  clinicChargeableItem$ = this.selectAll({
    filterBy: (entity: ChargeItem) => entity.itemType === 'DRUG' || entity.itemType === 'VACCINATION',
    sortBy: AkitaClinicChargeItemQuery.sortByNameAsc,
  });

  allInventoryItems$ = this.selectAll({
    filterBy: (entity: ChargeItem) =>
        !!entity.inventoried,
  });

  allClinicChargeableItem$ = this.selectAll();

  clinicPackageItem$ = this.selectAll({
    filterBy: (entity: ChargeItem) =>
      entity.itemType === 'PACKAGE',
      sortBy: AkitaClinicChargeItemQuery.sortByNameAsc,
  });

  clinicChargedItem$ = this.selectAll({
    sortBy: AkitaClinicChargeItemQuery.sortByNameAsc,
  });

  clinicChargedItemCategory$ = this.selectAll().pipe(
    map(entities =>
      entities
        .filter(
          (v, i) =>
            entities.findIndex(item => item.itemType === v.itemType) === i
        )
        .map(item => item.itemType)
    )
  );
  clinicChargedItemOptions$ = combineLatest(
    this.clinicChargedItem$,
    this.chargeItemOptions
  );

  clinicInventoryItem$ = this.selectAll({
    filterBy: (entity: ChargeItem) =>
      entity.itemType === 'DRUG' || entity.category === 'CONSUMABLES',
    sortBy: AkitaClinicChargeItemQuery.sortByNameAsc,
  }).pipe(
    map(data => {
      const inventoryItem = new Array<InventoryItem>();
      data.forEach((item: any) => inventoryItem.push(item));
      return inventoryItem;
    })
  );

  static readonly sortByNameAsc = (a: ChargeItem, b: ChargeItem, state) => {
    return a.name.toUpperCase() < b.name.toUpperCase()
      ? -1
      : a.name.toUpperCase() > b.name.toUpperCase()
      ? 1
      : 0;
  };

  constructor(protected store: AkitaClinicChargeItemStore) {
    super(store);
  }

  private chargeItemOptions(chargeItemList) {
    const chargeItemListOptions = new Array<any>();
    chargeItemList.forEach(item =>
      chargeItemListOptions.push({ label: item.name, value: item.id })
    );
    chargeItemListOptions.sort((a, b) => {
      const aText = a.name.toUpperCase();
      const bText = b.name.toUpperCase();
      return aText < bText ? -1 : aText > bText ? 1 : 0;
    });
    return chargeItemListOptions;
  }

  getChargeItemType() {
    return Array.from(new Set(this.getAll().map(entity => entity.itemType)));
  }

  getChargeItem(id) {
    const chargeItem = <ChargeItem>this.getAll({
      filterBy: (entity: ChargeItem) => entity.id === id,
    })[0];
    return chargeItem || undefined;
  }

  getActiveChargeItemList() {
    return this.getAll({
      filterBy: (entity: ChargeItem) => entity.status === 'ACTIVE',
    });
  }

  getActiveChargeItem(id) {
    const chargeItem = <ChargeItem>this.getAll({
      filterBy: (entity: ChargeItem) =>
        entity.status === 'ACTIVE' && entity.id === id,
    })[0];
    return chargeItem || undefined;
  }

  getPackageItem(id: string) {
    const inventoryItem = <any>(
      this.getAll({ filterBy: (entity: ChargeItem) => entity.id === id })[0]
    );
    return inventoryItem;
  }

  getConsultationSearchItemList() {
    return combineLatest(
      this.activeClinicChargedItem$,
      this.consultationSearchCode$,
      this.consultationSearchItemType$,
      this.consultationSearchName$,
      this.consultationSearchVendor$
    ).pipe(
      map(data => {
        if (data) {
          const updatedSearchItemList = [];
          const nameFilter = data[3];
          const codeFilter = data[1];
          const typeFilter = data[2];
          const supplierFilter = data[4];

          const arrayFilters = [];

          if (!!nameFilter && nameFilter !== '') {
            arrayFilters['name'] = nameFilter;
          }

          if (!!codeFilter && codeFilter !== '') {
            arrayFilters['code'] = codeFilter;
          }

          if (!!typeFilter && typeFilter !== '') {
            arrayFilters['itemType'] = typeFilter;
          }

          if (!!supplierFilter && supplierFilter !== '') {
            arrayFilters['supplierId'] = supplierFilter;
          }

          data[0].forEach((item: ChargeItem) => {
            // Filter By Name
            let match = true;
            Object.keys(arrayFilters).forEach((key: string) => {
              if (!!item[key]) {
                if (
                  !item[key]
                    .toLowerCase()
                    .includes(arrayFilters[key].toLowerCase())
                ) {
                  if (key === 'supplierId') {
                  }
                  match = false;
                }
              } else {
                match = false;
              }
            });

            if (match) {
              updatedSearchItemList.push(item);
            }
          });


          return updatedSearchItemList;
        } else {
          return [];
        }
      })
    );
  }
}
