import { StoreService } from './../services/store.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayDrug'
})
export class DisplayDrugPipe implements PipeTransform {

  constructor(
    private store: StoreService
  ) {}

  transform(value: any, args?: any): any {
    let item = this.store.getItemById(value);
    return item ? item.item[args] : '';
  }
}
