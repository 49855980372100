import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
  name: 'displayDosageInstruction',
})
export class DisplayDosageInstructionPipe implements PipeTransform {
  constructor(private storeService: StoreService) {}

  transform(value: any, args?: any): any {
    let dosageInstruction = this.storeService.getDosageInstructionByCode(value)
      ? this.storeService.getDosageInstructionByCode(value).instruct
      : undefined;
    if (dosageInstruction === undefined || dosageInstruction === null || dosageInstruction === '(leave blank)' )
      dosageInstruction = '';

    if (dosageInstruction.includes('#'))
      dosageInstruction = dosageInstruction.replace('#', args);
    return dosageInstruction;
  }
}
