import { MedicalCoverage } from './../objects/MedicalCoverage';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayMCContactDetail',
})
export class DisplayMCContactDetailPipe implements PipeTransform {
  transform(coverage: MedicalCoverage, args?: any): any {
    if (coverage) {
      if (coverage.contacts && coverage.contacts.length > 0) {
        if (coverage.contacts[0]) {
          if (coverage.contacts[0][args]) {
            return coverage.contacts[0][args];
          }
        }
      }
    }
    return '';
  }
}
