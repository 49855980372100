import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaAssessmentStore } from './akita-assessment.store';
import { MedicalAssessment } from '../../objects/state/MedicalAssessment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AkitaAssessmentQuery extends QueryEntity<MedicalAssessment> {
  assessmentList$ = this.selectAll();

  constructor(protected store: AkitaAssessmentStore) {
    super(store);
  }

  getSmokingUOM(): string {
    const smokeAssessment = this.getAll().find(
      (entity: MedicalAssessment) => entity.code === 'ASSESS_SMOKE'
    ) as MedicalAssessment;
    return smokeAssessment.uom;
  }

  getAssessmentFromCode(code: string){
    const medicalAssessment = <MedicalAssessment> this.getAll({
      filterBy : (entity: MedicalAssessment) => entity.code === code
    })[0];
    return medicalAssessment;
  }
}
