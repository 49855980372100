import { Component, EventEmitter } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-care-plan-confirmation-modal',
  templateUrl: './care-plan-confirmation-modal.component.html',
  styleUrls: ['./care-plan-confirmation-modal.component.scss']
})
export class CarePlanConfirmationModalComponent {

  public title: string;
  public message: string;
  public buttonConfig: any;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  public cancelModal(): void {
    this.bsModalRef.hide();
  }

  public onConfirm(): void {
    this.event.emit(true);
  }
}
