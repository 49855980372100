<div class="modal-body">
    <div class="row pt-4 text-center">
        <div class="col-md-12">
            <h4>There are some changes for this visit, please reload.</h4>
            <br/>
            <h6 *ngIf="showDocNotice">Notice for Doctors: Please save any unsaved consultation notes. If not, changes will be discarded when visit status is changed.</h6>
        </div>
    </div>
    
    
    <div class="row pt-4">
        <div class="col-md-12">
            <div class="btn-toolbar float-right" role="toolbar">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-medium btn-brand-secondary" (click)="onBtnReload()">Reload</button>
                </div>
            </div>
        </div>
    </div>
</div>