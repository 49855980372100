import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,

  filter, takeUntil
} from 'rxjs/operators';
import { DISPLAY_DATE_FORMAT, INPUT_DELAY } from './../../../constants/app.constants';
import { AkitaPatientAppQuery } from './../../../services/states/akita-patient/akita-patient-app.query';

@Component({
  selector: 'app-consultation-ongoing-medication',
  templateUrl: './consultation-ongoing-medication.component.html',
  styleUrls: ['./consultation-ongoing-medication.component.scss'],
})
export class ConsultationOngoingMedicationComponent
  implements OnInit, OnDestroy {
  @Input() needRefresh: Subject<boolean>;
  @Input() basicInfoFormGroup: FormGroup;
  @Input() editMode = true;

  patientInfo;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    private akitaPatientAppQuery: AkitaPatientAppQuery
  ) { }

  ngOnInit() {
    this.akitaPatientAppQuery.patientInfo$
      .pipe(
        filter(info => info !== undefined && info !== null),
        debounceTime(INPUT_DELAY),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(info => {
        this.patientInfo = info;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  addItem() {
    const item = this.fb.group({
      medicationName: '',
      type: ['', Validators.required],
      startDate: moment().format(DISPLAY_DATE_FORMAT),
      itemCode: ['', Validators.required],
    });

    const formArray = <FormArray>this.basicInfoFormGroup.get('onGoingMedications');
    formArray.push(item);
  }

  onBtnAddClicked() {
    if (this.editMode)
      this.addItem();
  }
}
