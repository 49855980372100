import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

import { AuthService } from './auth.service';
import { VisitManagementService } from './visit-management.service';
import { AkitaStoreService } from './states/akita-store.service';
import { Doctor } from '../objects/SpecialityByClinic';
import { StoreService } from './store.service';
import { AkitaClinicDoctorQuery } from './states/akita-clinic-doctor.query';
import { ApiPatientInfoService } from './api-patient-info.service';
import { AkitaClinicDoctorStore } from './states/akita-clinic-doctor.store';

@Injectable({
  providedIn: 'root'
})
export class InactivityService {

  private timeout: any;

  constructor(
    private permissionsService: NgxPermissionsService,
    private authService: AuthService,
    private visiManagementService: VisitManagementService,
    private apiPatientInfoService: ApiPatientInfoService,
    private akitaStoreService: AkitaStoreService,
    public store: StoreService,
    private akitaClinicDoctorQuery: AkitaClinicDoctorQuery,
    private clinicDoctorStore: AkitaClinicDoctorStore
  ) { }

  public watchUserInactivity(): void {
    if (this.timeout) clearTimeout(this.timeout);
    if (this.authService.isAuthenticated() && this.store.getUser()) {
      if (this.store.getUser().logoutInactiveSessionsEnabled) {
        this.timeout = setTimeout(() => {
          this.visiManagementService.showFullLayoutLoading();
          this.authService
            .isLogout()
            .pipe(take(1))
            .subscribe(() => {
              this.visiManagementService.hideFullLayoutLoading();
            });
          const isDoctor = this.permissionsService.getPermission('ROLE_DOCTOR');
          const isCA = this.permissionsService.getPermission('ROLE_CA');
          if (isDoctor) {
            this.updateDoctorOnlineStatus(false, true);
          }

          this.authService.logout();
          this.akitaStoreService.unsubscribeStore()
        }, 4 * 60 * 60 * 1000);
      }
    }
  }

  private updateDoctorOnlineStatus(online: boolean, isLogout = false): void {
    const user = this.store.getUser();
    const doctorId = user.context['cms-user-id'];
    const doctor = this.akitaClinicDoctorQuery.getEntity(
      user.context['cms-user-id']
    ) as Doctor;
    const clinicId = this.store.getClinicId();

    this.apiPatientInfoService.updateDoctorOnlineStatus(
      doctorId,
      online,
      clinicId
    ).subscribe(
      payload => {
        if (payload.statusCode === 'S0000' && !isLogout) {
          this.clinicDoctorStore.update(doctorId, {
            ...doctor,
            doctorClinicToggles: {
              online,
              [clinicId]: online
            }
          });
        }
      });
  }
}