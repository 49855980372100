import { Appointment } from '../../../../objects/Appointment';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface AppointmentState extends EntityState<Appointment> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Appointment' })
export class AppointmentStore extends EntityStore<AppointmentState> {
  constructor() {
    super();
  }
}
