import { AkitaVendorStore } from './akita-vendor.store';
import { Vendor } from './../../objects/state/Vendor';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class AkitaVendorQuery extends QueryEntity<Vendor> {
  vendors = this.getAll();

  constructor(protected store: AkitaVendorStore) {
    super(store);
  }

}
