<div class="card">
  <div class="card-header">PAYMENT METHOD</div>
  <div class="card-body">
    <form [formGroup]="paymentLine" *ngFor="let paymentLine of paymentFormArray.controls; let i = index">
      <div>

        <div class="row">
          <div class="col-md-3 text-indigo font-weight-500">Paid By</div>
          <div *ngIf="paymentLine.value.invoiceType === 'CREDIT'" class="col-md-3 text-indigo font-weight-500">Limit (w/ GST)</div>
          <div class="col-md-2 text-indigo font-weight-500">Amount ($)</div>
          <div *ngIf="paymentLine.value.name === 'CHEQUE'" class="col-md-3 text-indigo font-weight-500">Bank</div>
          <div *ngIf="paymentLine.value.name !== 'CASH' && paymentLine.value.name !== 'FAVEPAY' && paymentLine.value.name !== 'GRABPAY' && paymentLine.value.name !== 'STRIPE' && paymentLine.value.name !== 'PAYNOW' && paymentLine.value.name !== ''" class="text-indigo font-weight-500"
            [ngClass]="{'col-md-2': paymentLine.value.invoiceType === 'CREDIT', 'col-md-3': paymentLine.value.invoiceType === 'DIRECT'}">
            {{ paymentLine.value.invoiceType === 'CREDIT' ? 'Remarks' : (paymentLine.value.name === 'CHEQUE' ? 'Cheque No.' : 'Transaction ID')  }}
          </div>
          <div *ngIf="paymentLine.value.name === 'GIRO'" class="col-md-3 text-indigo font-weight-500">Remarks</div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <input *ngIf="paymentLine.value.invoiceType === 'CREDIT'" type="text" class="form-control form-control-sm" formControlName="name" disabled />
            <ng-select *ngIf="paymentLine.value.invoiceType === 'DIRECT'" class="p-0 form-control form-control-sm custom-select" [items]="paymentMethodSelections"
              bindLabel="label" bindValue="value" placeholder="Select Payment Method" formControlName="name" [virtualScroll]="true" [clearable]="false" [hideSelected]="true">
            </ng-select>
          </div>

          <div *ngIf="paymentLine.value.invoiceType === 'CREDIT'" class="col-md-3">
            <input type="number" class="form-control form-control-sm" formControlName="limit" readonly/>
          </div>

          <div class="col-md" class="col-md-2">
            <input type="number" class="form-control form-control-sm" formControlName="amount" [readOnly]="!(planUsageUpdatable$ | async) && paymentLine.value.invoiceType === 'CREDIT'" />
          </div>

          <div *ngIf="paymentLine.value.name === 'CHEQUE'" class="col-md-3">
            <input type="text" class="form-control form-control-sm" formControlName="bank" />
          </div>

          <div *ngIf="paymentLine.value.name !== 'CASH' && paymentLine.value.name !== 'FAVEPAY'  && paymentLine.value.name !== 'GRABPAY' && paymentLine.value.name !== 'STRIPE' && paymentLine.value.name !== 'PAYNOW'  && paymentLine.value.name !== ''" class="col-md-3">
            <input *ngIf="paymentLine.value.invoiceType === 'CREDIT'" type="text" class="form-control form-control-sm" formControlName="remarks" />
            <input *ngIf="paymentLine.value.name === 'CHEQUE'" type="text" class="form-control form-control-sm" formControlName="chequeNo" />
            <input *ngIf="paymentLine.value.invoiceType !== 'CREDIT' && paymentLine.value.name !== 'CHEQUE'" type="text"
              class="form-control form-control-sm" formControlName="transactionId" maxlength="20" />
         
          </div>
          <div *ngIf="paymentLine.value.name === 'GIRO'" class="col-md-3">
            <input type="text" class="form-control form-control-sm" formControlName="remarks" />
          </div>
          <div *ngIf="paymentLine.value && paymentLine.value.invoiceType !== 'CREDIT'" class="col-md-1">
            <i class="icon-ellipsis-vert" [popover]="popTemplate2" placement="top" [outsideClick]="true"></i>
          </div>

          <ng-template #popTemplate2>
            <div class="row">
              <button class="btn btn-link-grey p-1" (click)="deletePaymentLine(i)"><i class="icon-trash"></i></button>

                <button *ngIf="paymentLine.value.name === 'NETS' && paymentLine.value.name !== ''" class="btn btn-link-grey p-1" (click)="setNetsOn()">
                  <img title="Baby Bonus"
                  src="/assets/svg/child.svg">
                </button>
            </div>
          </ng-template>
        </div>

        <div class="row" *ngIf="paymentLine.value.name === 'NETS' && netsFlag==true">
          <div  class="col-md-9">
          </div>
          <div class="col-md-3 text-indigo font-weight-500">Baby Bonus</div>
        </div>
        <div class="row" *ngIf="paymentLine.value.name === 'NETS' && netsFlag==true">
          <div  class="col-md-9">
          </div>
          <div  class="col-md-2">
           <ng-select *ngIf="paymentLine.value.name === 'NETS' && netsFlag==true" formControlName="babyBonus" class="p-0 form-control form-control-sm custom-select" [items]="babyBonusSelections"
            bindLabel="label" bindValue="value" placeholder="Select Baby Bonus"  [virtualScroll]="true" [clearable]="false" [hideSelected]="true">
          </ng-select>
          </div>
          <div *ngIf="paymentLine.value.name === 'NETS' && netsFlag==true" class="col-md-1">
            <i class="icon-ellipsis-vert" [popover]="popTemplateBB" placement="top" [outsideClick]="true"></i>
          </div>
          <ng-template #popTemplateBB>
            <div class="row">
              <button class="btn btn-link-grey p-1" (click)="setNetsOff()"><i class="icon-trash"></i></button>
            </div>
          </ng-template>

        </div>
      </div>
    </form>

    <br>
    <button class="btn btn-sm btn-outline-brand-secondary float-right" [disabled]="!isOnlinePaymentPending()" (click)="addPaymentLine()">+ Add Another Payment Method</button>
  </div>

  <div class="card-footer">
    <div *ngIf="(activeVisit$ | async)" class="row">
      <div class="col-md-6 text-indigo">Visit Amount</div>
      <div class="col-md-6 text-pink">${{ (visitAmount$ | async) | displayDollar }}</div>
    </div>
    <div class="row" [ngClass]="{'font-weight-bold bg-sandy-yellow': !checkOnlyCashPayment()}">
      <div class="col-md-6 text-indigo">Total Amount Outstanding Balance</div>
      <div class="col-md-6 text-pink">${{ ((outstandingAmount$ | async).totalAmount < 0 ? 0.00 : (outstandingAmount$ | async).totalAmount) | displayDollar }}</div>
    </div>
    <div class="row" [ngClass]="{'font-weight-bold bg-sandy-yellow': checkOnlyCashPayment()}">
      <div class="col-md-6 text-indigo">Adjusted Outstanding Balance</div>
      <div class="col-md-6 text-pink">
        ${{ ((outstandingAmount$ | async).totalAmount - (outstandingAmount$ | async).cashAdjustedTotalAmount < 0 ? 0.00 : ((outstandingAmount$ | async).totalAmount - (outstandingAmount$ | async).cashAdjustedTotalAmount)) | displayDollar }}
        <span class="text-dark-grey-small"> (Cash payment only)</span>
      </div>
    </div>
    <div *ngIf="(outstandingAmount$ | async).changeAmount < 0" class="row">
      <div class="col-md-6 text-indigo">Change Amount</div>
      <div class="col-md-6 text-pink">
        ${{ ((outstandingAmount$ | async).changeAmount * -1) | displayDollar }}
      </div>
    </div>
    <div *ngIf="(outstandingAmount$ | async).totalAmount - (outstandingAmount$ | async).cashAdjustedTotalAmount > 0" class="row text-pink mt-2">
       <span class="icon-info-circled ml-1">
         <span class="pl-2">Paid amount is less than the payable amount charged.</span>
       </span>
    </div>
  </div>
</div>
