import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { filter, map } from 'rxjs/operators';

import { SelectItemOptions } from '../../../objects/SelectItemOptions';
import { Doctor } from '../../../objects/SpecialityByClinic';
import { AlertService } from '../../../services/alert.service';
import { AkitaAppQuery } from '../../../services/states/akita-app.query';
import { AkitaApproverQuery } from '../../../services/states/akita-approver.query';
import { AkitaPatientSourceQuery } from '../../../services/states/akita-patient-source.query';
import { VisitPurpose } from './../../../objects/request/PatientVisit';
import { StoreService } from './../../../services/store.service';
import { CalendarAppointmentService } from '../../../state/appointments/calendar-appointment/calendar-appointment.service';

@Component({
  selector: 'app-patient-add-consultation',
  templateUrl: './patient-add-consultation.component.html',
  styleUrls: ['./patient-add-consultation.component.scss'],
})
export class PatientAddConsultationComponent implements OnInit, OnDestroy {
  @Input() consultationInfoFormGroup: FormGroup;
  @Input() showPrioritySelect = false;
  @Input() isAppointment = false;
  @Input() hidePersonInCharge = false;
  @Input() fromPatientRegPopup = false;
  @Input() status: string;

  @Input()
  public customStyles: boolean = false;
  @Input()
  public fromHSG: boolean = false;

  // @Input() doctorList;

  isMeridian = false;
  showSpinners = false;

  @Input() doctors: Array<SelectItemOptions<Doctor>>;
  visitPurposes: Array<SelectItemOptions<VisitPurpose>>;
  priorities: Array<any> = [
    { label: 'High', value: 'HIGH' },
    { label: 'Normal', value: 'NORMAL' },
    { label: 'Low', value: 'LOW' },
  ];

  approverListEnabled$ = this.akitaAppQuery.select(entity => entity.clinic).pipe(
    filter(clinic => !!clinic),
    map(clinic => {
      let enabled = false;
      if (clinic.clinicFeatures && clinic.clinicFeatures.length > 0) enabled = clinic.clinicFeatures.indexOf('APPROVAL_LIST') !== -1;
      return enabled;
    })
  );
  patientSourcesEnabled$ = this.akitaAppQuery.select(entity => entity.clinic).pipe(
    filter(clinic => !!clinic),
    map(clinic => {
      let enabled = false;
      if (clinic.clinicFeatures && clinic.clinicFeatures.length > 0) enabled = clinic.clinicFeatures.indexOf('PATIENT_SOURCES') !== -1;
      return enabled;
    })
  );

  approverList$ = this.akitaApproverQuery.selectAll();
  patientSourceList$ = this.akitaPatientSourceQuery.selectAll();

  constructor(
    private akitaAppQuery: AkitaAppQuery,
    private akitaApproverQuery: AkitaApproverQuery,
    private akitaPatientSourceQuery: AkitaPatientSourceQuery,
    private alertService: AlertService,
    private storeService: StoreService,
    private calendarAppointmentService: CalendarAppointmentService
  ) { }

  ngOnInit() {
    if (this.showPrioritySelect) {
      if (this.consultationInfoFormGroup.get('priority') && !this.consultationInfoFormGroup.get('priority').value) {
        this.consultationInfoFormGroup.get('priority').patchValue('NORMAL');
      }
    }

    this.populateDoctor();
    this.populateVisitPurposes();
    if(this.fromPatientRegPopup){
      if(this.status === "INITIAL"){
        this.consultationInfoFormGroup.enable();
      } else{
        this.consultationInfoFormGroup.disable();
      }
      if(this.status === "COMPLETE"){
        this.consultationInfoFormGroup.get('skipNir').disable();
      } else{
        this.consultationInfoFormGroup.get('skipNir').enable();
      }
    }
    this.subscribeDoctorChange();
  }

  isNEHROpt() {
    return this.akitaAppQuery.checkClinicFeatureExist('NEHR_SUBMISSION');
  }

  subscribeDoctorChange() {
    this.consultationInfoFormGroup.get('preferredDoctor').valueChanges.subscribe(value => {
      this.calendarAppointmentService.appointmentDocChangeData(value);
    })
  }

  ngOnDestroy() { }

  populateVisitPurposes() {
    if (this.storeService.visitPurposeList.length < 1) {
      if (this.storeService.errorMessages['visitPurposeList'] !== undefined) {
        this.alertService.error(
          this.storeService.errorMessages['visitPurposeList']
        );
      } else {
        this.storeService.getVisitPurposeList();
      }
    } else {
      this.visitPurposes = this.storeService.visitPurposeList
          .filter(purpose => !purpose.isMobileConsultation)
          .filter(purpose => this.fromHSG ? purpose.hsgVisit : true)
          .map((value, index) => {
            return {
              value: value.name,
              label: value.name,
              data: value,
            };
          })
          .sort((visit1: any, visit2: any) => {
            if (visit1.value < visit2.value)
              return -1;
            if (visit1.value > visit2.value)
              return 1;
            return 0;
          })
    }
  }

  populateDoctor() {
    if (!this.doctors) {
      //CONTINUE HERE

      if (this.storeService.doctorListByClinic.length < 1) {
        if (
          this.storeService.errorMessages['listDoctorsByClinic'] !== undefined
        ) {
          this.alertService.error(
            this.storeService.errorMessages['listDoctorsByClinic']
          );
        } else {
          this.storeService.listDoctorsByClinic();
        }
      } else {
        this.doctors = this.storeService.doctorListByClinic
          .filter(item => item.status === 'ACTIVE')
          .map(item => {
            const options = new SelectItemOptions<Doctor>();
            options.value = item.id;
            options.label = item.displayName;
            options.data = item;
            return options;
          })
          .sort((doctor1: any, doctor2: any) => {
            if (doctor1.label < doctor2.label)
              return -1;
            if (doctor1.label > doctor2.label)
              return 1;
            return 0;
          })
      }
    }
  }
}
