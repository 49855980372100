import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {WhitelistedItemService} from "../../../services/whitelisted-item.service";
import {AkitaChargeItemQuery} from "../../../services/states/akita-charge-item.query";
import {combineLatest, Observable, Subject} from "rxjs";
import {first, switchMap} from "rxjs/operators";
import {ChargeItem} from "../../../objects/state/ChargeItem";
import {Logger} from "../../../util/logger";
import {AkitaCaseVisitQuery} from "../../../services/states/akita-case-visit.query";
import {
  AttachedMedicalCoveragesQuery
} from "../../../services/states/akita-case/akita-attached-medical-coverages/attached-medical-coverages.query";
import {coverageCappedAllowed} from "../../../services/states/akita-whitelisted-items.store";

const logger = new Logger('WhitelistedItemNoticeComponent', 'whitelisted-notice');

@Component({
  selector: 'app-whitelisted-item-notice',
  templateUrl: './whitelisted-item-notice.component.html',
  styleUrls: ['./whitelisted-item-notice.component.scss']
})
export class WhitelistedItemNoticeComponent implements OnInit, OnChanges {
  limit = "0.00";
  showNotice = false;
  @Input() itemId: string;
  @Input() quantity: number;
  private itemIdSubject = new Subject<string>();
  private quantitySubject = new Subject<number>();

  constructor(
    private whitelistedItemService: WhitelistedItemService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private attachedMedCovQuery: AttachedMedicalCoveragesQuery,
  ) {
  }

  ngOnInit(): void {
    let attachedMedicalCoverages$ = this.attachedMedCovQuery.attachedMedicalCoverages$.pipe(first())
    let selectCaseCoverage$ = this.akitaCaseVisitQuery.selectCaseCoverage().pipe(first());

    let whitelistedItemInfo$ = this.itemIdSubject.pipe(
      switchMap(itemId => this.whitelistedItemService.withWhitelistedItemFor(itemId)),
    );

    combineLatest([whitelistedItemInfo$, this.quantitySubject, attachedMedicalCoverages$, selectCaseCoverage$])
      .subscribe(([whitelistedItemInfo, quantity, attachedMedicalCoverages, caseCoverage]) => {
        if (!whitelistedItemInfo) {
          logger.log('No whitelisted item info', whitelistedItemInfo)
          this.showNotice = false;
          return;
        }
        let {whitelistedItem, chargeItem} = whitelistedItemInfo;
        if (!whitelistedItem) {
          logger.log('No whitelisted item', whitelistedItem)
          this.showNotice = false;
          return;
        }

        if (!whitelistedItem?.perUnitPriceCap || !whitelistedItem?.uomMatrix) {
          logger.log('No perUnitPriceCap or uomMatrix', whitelistedItem)
          this.showNotice = false;
          return;
        }

        if (!whitelistedItem.uomMatrix[chargeItem.salesUom]) {
          logger.log('No uomMatrix for salesUom', whitelistedItem, chargeItem.salesUom)
          this.showNotice = false;
          return;
        }

        let medicalCoverageIds = caseCoverage.map(({medicalCoverageId}) => medicalCoverageId);
        let coverageInAllowedCoverages = medicalCoverageIds.some(medicalCoverageId => coverageCappedAllowed(whitelistedItem, medicalCoverageId));

        let isCapAllowed = whitelistedItem.itemRefId || coverageInAllowedCoverages;

        if (!isCapAllowed) {
          logger.log('Cap not allowed', whitelistedItem, medicalCoverageIds)
          this.showNotice = false;
          return;
        }

        this.showNotice = true
        this.limit = (whitelistedItem.perUnitPriceCap?.price * whitelistedItem.uomMatrix[chargeItem.salesUom] * quantity).toFixed(2);
      })

    this.itemIdSubject.next(this.itemId);
    this.quantitySubject.next(this.quantity);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemId) {
      this.itemIdSubject.next(changes.itemId.currentValue)
    }
    if (changes.adjustedTotalPrice) {
      this.quantitySubject.next(changes.adjustedTotalPrice.currentValue)
    }
  }

}
