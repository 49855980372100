import { DB_FULL_DATE_FORMAT } from './../../constants/app.constants';
import { Appointment } from '../Appointment';
import * as moment from 'moment';

export class AppointmentRequest {
  patientId?: string;
  clinicId?: string;
  appointmentPurpose?: string;
  remark?: string;
  startDate?: string;
  duration?: number;
  status: string;
  forceAdd: true; // bypasses backend 1 hour constraint
  followUp = false;

  constructor(appointment: Appointment) {
    this.patientId = appointment.patientId;
    this.clinicId = appointment.clinicId;
    this.appointmentPurpose = appointment.purposeOfVisit;
    this.remark = appointment.remarks;
    this.startDate = moment(appointment.visitDate).format(DB_FULL_DATE_FORMAT);
    this.duration = appointment.duration;
    this.status = appointment.status;
    this.forceAdd = true;
    this.followUp = appointment.followUp;
  }

  setReferral(referringClinic?, referringDoctor?) {
    let isReferral: boolean;
    if (referringClinic !== this.clinicId) {
      isReferral = true;
    } else {
      isReferral = false;
    }

    if (isReferral) {
      this['referringDoctorId'] = referringDoctor; // Variable to be added
      this['referringClinicId'] = referringClinic;
    } else {
      this['referringClinicId'] = referringClinic;
    }
  }
  setDoctor(doctor) {
    if (doctor) {
      this['doctorId'] = doctor;
    }
  }
}
