export const PAYMENT_MODES_ALL = [
  { value: 'CASH', label: 'CASH' },
  { value: 'NETS', label: 'NETS' },
  { value: 'VISA', label: 'VISA' },
  { value: 'MASTER_CARD', label: 'MASTER CARD' },
  { value: 'AMERICAN_EXPRESS', label: 'AMERICAN EXPRESS' },
  { value: 'JCB', label: 'JCB' },
  { value: 'OTHER_CREDIT_CARD', label: 'OTHER CREDIT CARD' },
  { value: 'CHEQUE', label: 'CHEQUE' },
  { value: 'GIRO', label: 'GIRO' },
  { value: 'FAVEPAY', label: 'FAVEPAY' },
  { value: 'GRABPAY', label: 'GRABPAY' },
  { value: 'STRIPE', label: 'STRIPE' },
  { value: 'SHOPBACK', label: 'SHOPBACK' },
  { value: 'PAYNOW', label: 'PAYNOW' },
];
