import { Pipe, PipeTransform } from '@angular/core';
import { ItemPriceAdjustment } from '../objects/request/DispatchItemEntity';

@Pipe({
  name: 'adjustedPrice',
})
export class AdjustedPricePipe implements PipeTransform {
  transform(
    value: any,
    qty: number,
    itemPriceAdjustment: ItemPriceAdjustment
  ): any {
    if (!value || !qty || !itemPriceAdjustment) {
      return '0.00';
    }
    if (itemPriceAdjustment.paymentType === 'DOLLAR') {
      const amount = (
        (value + qty * +itemPriceAdjustment.adjustedValue) *
        0.01
      ).toFixed(2);
      return amount;
    } else {
      //return oriTotalPrice + qty * +itemPriceAdjustment.adjustedValue;
      return '0.00';
    }
  }
}
