export interface Claim {
  appealRejection: Array<AppealRejection>;
  attendingDoctorId: string;
  claimDoctorId: string;
  claimExpectedAmt: number;
  claimId: string;
  claimResult: ClaimPaymentResult;
  claimStatus: string;
  consultationAmt: number;
  diagnosisCodes: Array<string>;
  gstAmount: number;
  medicalTestAmt: number;
  medicationAmt: number;
  otherAmt: number;
  paidResult: ClaimPaymentResult;
  payersName: string;
  payersNric: string;
  remark: string;
  submissionDateTime: string;
  submissionResult: SubmissionResult;
}

export function createClaim(claim) {
  const {
    appealRejection = new Array<AppealRejection>(),
    attendingDoctorId = '',
    claimDoctorId = '',
    claimExpectedAmt = 0,
    claimId = '',
    claimResult = createClaimPaymentResult({}),
    claimStatus = '',
    consultationAmt = 0,
    diagnosisCodes = new Array<string>(),
    gstAmount = 0,
    medicalTestAmt = 0,
    medicationAmt = 0,
    otherAmt = 0,
    paidResult = createClaimPaymentResult({}),
    payersName = '',
    payersNric = '',
    remark = '',
    submissionDateTime = '',
    submissionResult = createSubmissionResult({}),
  } = claim;

  return {
    appealRejection,
    attendingDoctorId,
    claimDoctorId,
    claimExpectedAmt,
    claimId,
    claimResult,
    claimStatus,
    consultationAmt,
    diagnosisCodes,
    gstAmount,
    medicalTestAmt,
    medicationAmt,
    otherAmt,
    paidResult,
    payersName,
    payersNric,
    remark,
    submissionDateTime,
    submissionResult,
  } as Claim;
}

export interface AppealRejection {
  reason: string;
}

export function createAppealRejection(appealRejection) {
  const { reason = '' } = appealRejection;
  return { reason } as AppealRejection;
}

export interface ClaimPaymentResult {
  amount: number;
  referenceNumber: string;
  remark: string;
  resultDateTime: string;
  statusCode: string;
}

export function createClaimPaymentResult(claimPaymentResult) {
  const {
    amount = 0,
    referenceNumber = '',
    remark = '',
    resultDateTime = '',
    statusCode = '',
  } = claimPaymentResult;

  return {
    amount,
    referenceNumber,
    remark,
    resultDateTime,
    statusCode,
  } as ClaimPaymentResult;
}

export interface SubmissionResult {
  claimNo: string;
  statusCode: string;
  statusDescription: string;
}

export function createSubmissionResult(submissionResult) {
  const {
    claimNo = '',
    statusCode = '',
    statusDescription = '',
  } = submissionResult;

  return {
    claimNo,
    statusCode,
    statusDescription,
  } as SubmissionResult;
}
