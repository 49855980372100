import { Price } from './InventoryItem';

export class InventorySupplier {
  id: string;
  createdDate: string;
  lastModifiedDate: string;
  name: string;
  address: Address;
  contacts: Array<Contact>;
  minPurchaseAmounts: Array<MinPurchaseAmountItem>;
  status: string;
  items: Array<SupplierItem>;
  purchaseBonuses: Array<SupplierBonusItem>;

  adapt(obj?): InventorySupplier {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.createdDate = obj.createdDate;
    this.lastModifiedDate = obj.lastModifiedDate;
    this.name = obj.name;
    this.address = new Address().adapt(obj.address);
    this.contacts = (obj.contacts || []).map(contact =>
      new Contact().adapt(contact)
    );
    this.minPurchaseAmounts = (obj.minPurchaseAmounts || []).map(item =>
      new MinPurchaseAmountItem().adapt(item)
    );
    this.status = obj.status;
    this.items = (obj.items || []).map(item => new SupplierItem().adapt(item));
    this.purchaseBonuses = (obj.purchaseBonuses || []).map(item =>
      new SupplierBonusItem().adapt(item)
    );

    return this;
  }
}

export class Address {
  attentionTo: string;
  address: string;
  postalCode: string;

  adapt(obj?): Address {
    if (!obj) {
      return this;
    }
    this.attentionTo = obj.attentionTo;
    this.address = obj.address;
    this.postalCode = obj.postalCode;

    return this;
  }
}

export class Contact {
  name: string;
  title: string;
  directNumber: string;
  mobileNumber: string;
  faxNumber: string;
  email: string;

  adapt(obj?): Contact {
    if (!obj) {
      return this;
    }

    this.name = obj.name;
    this.title = obj.title;
    this.directNumber = obj.directNumber;
    this.mobileNumber = obj.mobileNumber;
    this.faxNumber = obj.faxNumber;
    this.email = obj.email;

    return this;
  }
}

export class SupplierItem {
  itemId: string;
  minPurchaseQuantity: number;
  unitPrice: Price;
  status: string;

  adapt(obj?): SupplierItem {
    if (!obj) {
      return this;
    }

    this.itemId = obj.itemId;
    this.minPurchaseQuantity = obj.minPurchaseQuantity;
    this.unitPrice = obj.unitPrice
      ? obj.unitPrice !== null && typeof obj.unitPrice === 'object'
        ? new Price().adapt(obj.unitPrice)
        : new Price(obj.unitPrice, false)
      : new Price(0, false);
    this.status = obj.status;

    return this;
  }
}

export class MinPurchaseAmountItem {
  clinicId: string;
  minPurchaseAmount: number;
  global: boolean;

  adapt(obj?): MinPurchaseAmountItem {
    if (!obj) {
      return this;
    }

    this.clinicId = obj.clinicId;
    this.minPurchaseAmount = obj.minPurchaseAmount;
    this.global = obj.global || false;

    return this;
  }
}

export class SupplierBonusItem {
  itemId: string;
  bonusDetails: Array<BonusItemDetail>;

  adapt(obj?): SupplierBonusItem {
    if (!obj) {
      return this;
    }

    this.itemId = obj.itemId;
    this.bonusDetails = (obj.bonusDetails || []).map(item =>
      new BonusItemDetail().adapt(item)
    );

    return this;
  }
}

export class BonusItemDetail {
  purchaseQuantity: number;
  bonusQuantity: number;
  uom: string;

  adapt(obj?): BonusItemDetail {
    if (!obj) {
      return this;
    }

    this.purchaseQuantity = obj.purchaseQuantity;
    this.bonusQuantity = obj.bonusQuantity;
    this.uom = obj.uom;

    return this;
  }
}
