<ng-container *ngIf="otherHealthGoalDetailsFormGroup" [formGroup]="otherHealthGoalDetailsFormGroup">
    <div class="row mb-2">
        <div class="col-6">
            <p class="title">One thing to start working today</p>
            <div class="wrapper-form-control">
                <textarea class="input" formControlName="thingsToStartToday"></textarea>
                <errors [control]="otherHealthGoalDetailsFormGroup.get('thingsToStartToday')"></errors>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-4">
            <p class="title">Recommended Next Visit</p>

            <div class="wrapper-text">
                <app-date-picker-with-icon [customStyles]="true" [config]="datePickerConfig"
                    [dateControl]="otherHealthGoalDetailsFormGroup.get('nextCheckUpDate')">
                </app-date-picker-with-icon>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-6">
            <p class="title">Record of Discussion</p>
            <div class="wrapper-cke-editor">
                <app-rich-editor-switcher [control]="otherHealthGoalDetailsFormGroup.get('recordOfDiscussion')">
                </app-rich-editor-switcher>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-6">
            <p class="title">Template</p>
            <ng-select class="custom-select-new p-0" bindLabel="name" bindValue="id" groupBy="type" [items]="templates"
                [virtualScroll]="true" (change)="onTemplateChange($event)" placeholder="Select Template">
            </ng-select>
        </div>
    </div>

</ng-container>