import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayDiagnosisCode',
})
export class DisplayDiagnosisCodePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let string = '';
    value.forEach(diagnosis => (string = string + diagnosis.icd10Code + '\n'));

    return string;
  }
}
