<div class="container">
  <div class="d-flex justify-content-center align-items-center h-100">
    <form (ngSubmit)="onLoginSubmit()" #loginForm="ngForm" class="form-signin card  rectangle-2 col-md-6">
      <div class="card-body" [formGroup]="loginFormGroup">
        <div class="div-header d-flex justify-content-between">
          <label class="form-signin-heading align-self-center">Login</label>
          <img src="../../../assets/img/logo.png" class="img-logo rounded float-right" alt="logo">
        </div>
        <alert></alert>
        
        <div class="form-group">
          <div class="row">
            <div class="col-md-7">
              <label class='mb-0'>Tenant Name</label>
              <input name="tenantId" class="form-control form-control-sm" formControlName="tenantId" [readOnly]="tenantidReadOnly" />
            </div>
            <div class="col-md-5">
              <label class='mb-0'></label>
              <button 
                type="button"
                class="btn btn-lg btn-primary btn-block btn-custom"
                (click)="saveTenantIdToLocal()"
                [disabled]="registerBtnDisabled"
                >
                {{this.buttonText}}
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class='mb-0'>User Name</label>
          <input name="username" class="form-control form-control-sm" formControlName="username" [readOnly]="!isTenantidSaved"/>
        </div>
        <div class="form-group">
          <label class='mb-0'>Password</label>
          <input 
            name="password" 
            class="form-control form-control-sm" 
            type="password" 
            formControlName="password" 
            [readOnly]="!isTenantidSaved" 
            #password
            />
          <img 
            class="icon-password" 
            [ngClass]="password.type === 'password' ? 'icon-show-password' : '' "
            [src]=" password.type === 'password' ? '/assets/svg/eye-solid.svg' : '/assets/svg/eye-slash-solid.svg' " 
            (click)="password.type === 'password' ? password.type = 'text' : password.type = 'password' ">
        </div>        
        <button class="btn btn-lg btn-brand-primary btn-block" type="submit" [disabled]="!loginFormGroup.valid || !isTenantidSaved">Sign in</button>

        <div class="wrapper-powered">
          <p>Powered by 
            <a href="https://galenhealth.io/" target="_blank">
              <img src="/assets/img/galen_health_powered_icon.png" alt="">
              <span> Galen Health</span>
            </a>
          </p>
          <p class="copyrights">Copyright &copy; {{ copyYear }} Galen Health. All Rights Reserved.</p>
        </div>
      </div>
    </form>
  </div>
</div>