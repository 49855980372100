// Libraries
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

// Service
import { AppointmentsFactoryService } from './../../../../services/appointments-factory.service';
import { AppointmentMasterService } from './../../appointment-master/appointment-master.service';
import { AppointmentMasterQuery } from './../../appointment-master/appointment-master.query';
import { ApiAppointmentsService } from '../../../../services/api-appointments.service';

// Objects
import { AppointmentListResponse } from '../../../../objects/response/AppointmentListResponse';
import { DISPLAY_DATE_FORMAT } from '../../../../constants/app.constants';
import { Appointment } from '../../../../objects/Appointment';
import { AppointmentStore } from './appointment.store';

import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class AppointmentService {
  constructor(
    private appointmentStore: AppointmentStore,
    private http: HttpClient,
    private apiApptmentService: ApiAppointmentsService,
    private apptFactory: AppointmentsFactoryService,
    private appointmentMasterQuery: AppointmentMasterQuery,
    private appointmentMasterService: AppointmentMasterService
  ) {}

  get() {
    return this.http.get<Appointment[]>('https://api.com').pipe(
      tap(entities => {
        this.appointmentStore.set(entities);
      })
    );
  }

  add(appointment: Appointment[]) {
    this.appointmentStore.add(appointment);
  }

  update(id, appointment: Partial<Appointment>) {
    this.appointmentStore.update(id, appointment);
  }

  upsert(id, appointment: Partial<Appointment>) {
    this.appointmentStore.upsert(id, appointment);
  }

  remove() {
    this.appointmentStore.remove();
  }

  set(list: Appointment[]) {
    this.appointmentStore.set(list);
  }

  getAppointmentsByClinic() {
    const formatDate = (date: Date) => moment(date).format(DISPLAY_DATE_FORMAT);

    // const startDate = formatDate(
    //   this.appointmentMasterQuery.getViewDate1MthBefore()
    // );
    // const endDate = formatDate(
    //   this.appointmentMasterQuery.getViewDate1MthAfter()
    // );

    const startDate = formatDate(
      this.appointmentMasterQuery.getViewDateStartOfMonth()
    );
    const endDate = formatDate(
      this.appointmentMasterQuery.getViewDateEndOfMonth()
    );

    this.apiApptmentService
      .listAppointmentsByClinicId(
        this.appointmentMasterQuery.getClinicSelected(),
        startDate,
        endDate
      )
      .subscribe(res => {
        this.remove();

        const { payload } = res;
        const list: Appointment[] = [];

        payload.forEach((entry: AppointmentListResponse) => {
          const appointment = this.createAppointmentObject(entry);
          list.push(appointment);
        });

        this.add(list);
        this.appointmentMasterService.setStoreReady();
      });
  }

  createAppointmentObject(apiResponse: AppointmentListResponse) {
    const appointment = this.apptFactory.createAppointment(
      <AppointmentListResponse>apiResponse,
      'OBJECT'
    );
    return appointment;
  }
}
