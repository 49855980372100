import { MedicalCoverageByCaseRes, createMedicalCoverageByCaseRes } from './../../../../objects/PolicyHolderInfo';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export function createInitialState(): MedicalCoverageByCaseRes {
  return createMedicalCoverageByCaseRes();
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'attachedMedicalCoverages' })
export class AttachedMedicalCoveragesStore extends Store<MedicalCoverageByCaseRes> {
  constructor() {
    super(createInitialState());
  }
}
