import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import HSGPlan, { HSGGoal, ViewHSGPlanGoal } from '../../../../../model/HSGPlan';
import { StoreService } from '../../../../../services/store.service';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../../../constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PatientHsgService {

  private selectedPlanSub = new BehaviorSubject<ViewHSGPlanGoal[] | undefined>([]);
  private selectedPlan = new Subject<HSGPlan | undefined>();
  private planTypeSub = new Subject<boolean>();
  private newPlanSub = new Subject<HSGPlan>();
  private newPlanAdded = new Subject<boolean>();
  private isAllPlanSubmitted = new Subject<boolean>();
  private chronicDiseaseChange = new Subject<any>();
  private enableConditions = new Subject<boolean>();
  private showCopyLastGoal = new Subject<boolean>();
  private syncPlans = new Subject<boolean>();
  private syncVaccineAndSFL = new Subject<boolean>();
  private planSubmitted = new Subject<boolean>();
  private selectedConditions = new Subject<any[]>();
  private planDeleted = new Subject<boolean>();
  private latestPlan: HSGPlan;

  constructor(
    private store: StoreService,
  ) { }

  public setGoalsOfSelectedPlan(plan: HSGPlan | undefined): void {
    const goals: ViewHSGPlanGoal[] = [];

    if(plan) {
      if (plan.healthPlanStatus === 'SUBMIT') {
        (plan.healthGoals || []).forEach((goal, index) => {
          let constructedGoal: ViewHSGPlanGoal = {
            number: goal.workflow.flow,
            goal: goal.workflow.title,
            target: goal.workflow.body,
            subTarget: goal.workflow.subWorkflow ? goal.workflow.subWorkflow.body : '',
          }
    
          let obj = {};
          let subObj = {};
    
          goal.workflow.body.split(' ').forEach(element => {
            if(element.includes('{{')) {
              const elementPlceholder = element.replace('{{', '').replace('}}', '').replace('.', '');
              const value = goal.values.find(val => val.placeholder === elementPlceholder);
              const section = goal.workflow.sections.find(sec => sec.placeholder === elementPlceholder);
              if (value) {
                obj[element] = '<b>' + (section && section.input === 'date-picker' ? moment(value.value, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : value.value) + '</b>';
              } else {
                obj[element] = '<b>___</b>';
              }
            }
          });

          let isSubValueAvailable = false;
          if (goal.workflow.subWorkflow) {
            goal.workflow.subWorkflow.body.split(' ').forEach(element => {
              if(element.includes('{{')) {
                const elementPlceholder = element.replace('{{', '').replace('}}', '').replace('.', '');
                const value = goal.values.find(val => val.placeholder === elementPlceholder);
                const section = goal.workflow.subWorkflow.sections.find(sec => sec.placeholder === elementPlceholder);
                if (value) {
                  if (!isSubValueAvailable) {
                    isSubValueAvailable = true;
                  }
                  if (section && section.data && section.data.length) {
                    let dataValue = section.data.find(data => data.key === value.value);
                    value.value = dataValue ? dataValue.value : value.value;
                  }
                  subObj[element] = '<b>' + value.value + '</b>';
                } else {
                  subObj[element] = '<b>___</b>';
                }
              }
            });
          }
    
          for (var key in obj) {
            constructedGoal.target = constructedGoal.target.replace(key, obj[key]);
          }

          if (isSubValueAvailable) {
            for (var key in subObj) {
              constructedGoal.subTarget = constructedGoal.subTarget.replace(key, subObj[key]);
            }
          } else {
            constructedGoal.subTarget = '';
          }

          if (!goal.workflow.body.includes('{{') && goal.values) {
            for (var value of goal.values) {
              constructedGoal.target += (' <b>' + value['value'] + '</b>, ');
            }
            constructedGoal.target = constructedGoal.target.replace(/,\s*$/, "");
          }

          if (isSubValueAvailable) {
            if (goal.workflow.subWorkflow) {
              if (!goal.workflow.subWorkflow.body.includes('{{') && goal.values) {
                for (var value of goal.values) {
                  constructedGoal.subTarget += (' <b>' + value['value'] + '</b>, ');
                }
                constructedGoal.subTarget = constructedGoal.subTarget.replace(/,\s*$/, "");
              }
            }
          }
    
          goals.push(constructedGoal);
        });
  
        this.selectedPlanSub.next(goals);
      }

      this.setSelectedPlan(plan);
    } else {
      this.selectedPlanSub.next([]);
    }
  }

  public getGoalMasterData(): HSGGoal[] {
    return this.store.hsgGoalMasterData;
  }

  public getGoalsDropdown() : { value: string, label: string, workflow: any }[] {
    const dropdownData: { value: string, label: string, workflow: any}[] = [];
    this.getGoalMasterData().forEach(element => {
      dropdownData.push({
        value: element.goalId,
        label: element.workflow.title, 
        workflow: element.workflow
      });
    });
    return dropdownData;
  }

  public getSelectedPlanForPreview(): Observable<ViewHSGPlanGoal[] | undefined> {
    return this.selectedPlanSub.asObservable();
  }

  public setIsNewPlan(isNewPlan: boolean): void {
    this.planTypeSub.next(isNewPlan);
  }

  public getIsNewPlan(): Observable<boolean> {
    return this.planTypeSub.asObservable();
  }

  public setNewPlan(newPlan: HSGPlan): void {
    this.newPlanSub.next(newPlan);
  }

  public getNewPlan(): Observable<HSGPlan> {
    return this.newPlanSub.asObservable();
  }

  public setNewPlanAdded(event: any): void {
    this.newPlanAdded.next(event);
  }

  public getNewPlanAdded(): Observable<any> {
    return this.newPlanAdded.asObservable();
  }

  public setSelectedPlan(selectedPlan: HSGPlan): void {
    this.selectedPlan.next(selectedPlan);
  }

  public getSelectedPlan(): Observable<HSGPlan> {
    return this.selectedPlan.asObservable();
  }

  public setChronicDiseaseChange(chronicDiseaseChange: any): void {
    this.chronicDiseaseChange.next(chronicDiseaseChange);
  }

  public getChronicDiseaseChange(): Observable<any> {
    return this.chronicDiseaseChange.asObservable();
  }

  public setIsAllPlanSubmitted(isAllPlanSubmitted: boolean): void {
    this.isAllPlanSubmitted.next(isAllPlanSubmitted);
  }

  public getIsAllPlanSubmitted(): Observable<boolean> {
    return this.isAllPlanSubmitted.asObservable();
  }

  public setShowCopyLastGoal(showCopyLastGoal: boolean): void {
    this.showCopyLastGoal.next(showCopyLastGoal);
  }

  public getsShowCopyLastGoal(): Observable<boolean> {
    return this.showCopyLastGoal.asObservable();
  }

  public setEnableConditions(enableConditions: boolean): void {
    this.enableConditions.next(enableConditions);
  }

  public getEnableConditions(): Observable<boolean> {
    return this.enableConditions.asObservable();
  }

  public setSyncPlans(syncPlans: boolean): void {
    this.syncPlans.next(syncPlans);
  }

  public getSyncPlans(): Observable<boolean> {
    return this.syncPlans.asObservable();
  }

  public setSyncVaccineAndSFL(syncVaccineAndSFL: boolean): void {
    this.syncVaccineAndSFL.next(syncVaccineAndSFL);
  }

  public getSyncVaccineAndSFL(): Observable<boolean> {
    return this.syncVaccineAndSFL.asObservable();
  }

  public setPlanSubmitted(planSubmitted: boolean): void {
    this.planSubmitted.next(planSubmitted);
  }

  public getPlanSubmitted(): Observable<boolean> {
    return this.planSubmitted.asObservable();
  }

  public setSelectedConditions(selectedConditions: any[]): void {
    this.selectedConditions.next(selectedConditions);
  }

  public getSelectedConditions(): Observable<any[]> {
    return this.selectedConditions.asObservable();
  }

  public setPlanDeleted(plandeleted: boolean): void {
    this.planDeleted.next(plandeleted);
  }

  public getPlanDeleted(): Observable<boolean> {
    return this.planDeleted.asObservable();
  }

  public setLatestPlan(plan: HSGPlan): void {
    this.latestPlan = plan;
  }

  public getLatestPlan(): HSGPlan {
    return this.latestPlan;
  }

  public getTarget(goal: { value: string, label: string }) : {type: string; value?: any; placeholder?: string, data?: any}[] {
    const selectedWorkflow = this.getGoalMasterData().find(item => item.goalId === goal.value);
    return this.getTargetsFromWorkflow(selectedWorkflow.workflow);
  }

  public getSubTarget(goal: { value: string, label: string }) : {type: string; value?: any; placeholder?: string, data?: any}[] {
    const selectedWorkflow = this.getGoalMasterData().find(item => item.goalId === goal.value);
    return this.getTargetsFromWorkflow(selectedWorkflow.workflow.subWorkflow);
  }

  private getTargetsFromWorkflow(selectedWorkflow: any) : {type: string; value?: any; placeholder?: string, data?: any}[] {
    const splitTarget = selectedWorkflow.body.split(' ');
    const target: {type: string; value?: any; inputType?: string, minValue?: any; maxValue?: any; placeholder?: string; data?: any}[] = [];

    if(selectedWorkflow.sections[0].input === 'multi-choice') {
      selectedWorkflow.sections[0].data.forEach(data => {
        if(data.captureContext) {
          data.inputValue = '';
          data.showInput = false;
        }
      });;

      target.push({
        type: 'multi-choice',
        value: selectedWorkflow.body,
        data: selectedWorkflow.sections[0].data
      });
    } else {
      splitTarget.forEach(element => {
        if(element.includes('{{')) {
          const newElement = element.replace('{{', '').replace('}}', '').replace('.', '');
          const section = selectedWorkflow.sections.find(item => item.placeholder === newElement);
          
          if(section.input ===  'input') {
            target.push({
              type: 'input',
              inputType: section.minValue || section.maxValue ? 'number' : 'text',
              value: '',
              minValue: section.minValue,
              maxValue: section.maxValue,
              placeholder: newElement
            });
          } else if(section.input === 'input-dropdown') {
            target.push({
              type: 'input-dropdown',
              value: '',
              inputType: section.minValue || section.maxValue ? 'number' : 'text',
              minValue: section.minValue,
              maxValue: section.maxValue,
              placeholder: newElement,
              data: section.data
            });
          } else if(section.input === 'date-picker') {
            target.push({
              type: 'date-picker',
              value: '',
              minValue: section.minValue,
              maxValue: section.maxValue,
              placeholder: newElement,
            });
          }
        } else {
          target.push({
            type: 'text',
            value: element
          });
        }
      });
    }
    
    return target;
  }
}