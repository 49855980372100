import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AkitaSflItemStore, AkitaSflItemState } from './akita-sfl-item.store';

@Injectable({ providedIn: 'root' })
export class AkitaSflItemQuery extends QueryEntity<AkitaSflItemState> {

  constructor(protected store: AkitaSflItemStore) {
    super(store);
  }

}
