export const stockTakeTemplate = `
<!DOCTYPE html>
<html>

<head>
  <title>
    {{title}}
  </title>
  <link rel="stylesheet" href="./lib/bootstrap/bootstrap.min.css">
  <style>
    body {
      background: rgb(204, 204, 204);
      font-size: 12px;
    }

    page {
      background: white;
      display: block;
      box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    }

    page[size="A4"] {
      width: 210mm;
      height: 297mm;
      position: relative;
      page-break-after: avoid;
      page-break-inside: avoid;
    }

    @media print {
      @page {
        size: A4 portrait;
        margin: 4mm 5.5mm;
      }

      body {
        min-width: initial !important;
      }

      page {
        margin: 0mm;
      }
    }

    .margin {
      margin-left: 10px;
      margin-right: 10px;
    }

    .text-size-xl {
      font-size: 1.5em;
    }

    .text-size-l {
      font-size: 1.3em;
    }

    .text-size-m {
      font-size: 1.15em;
    }

    .text-size-s {
      font-size: 0.9em;
    }

    .text-size-xs {
      font-size: 0.7em;
    }
  </style>
</head>

<body>
  <page size="A4" class="px-1">
    <div class="container-fluid pt-2">
      <div class="row border-top border-bottom border-left border-right" style="font-weight: 500;">
        <div class="col-2 py-2  border-right">
          Item Code
        </div>
        <div class="col-3 py-2 border-right">
          Item Name
        </div>
        <div class="col-2 py-2 border-right">
          Batch #
        </div>
        <div class="col-2 py-2 border-right">
          Expiry Date
        </div>
        <div class="col-1 py-2 border-right">
          UOM
        </div>
        <div class="col-2 py-2 border-right">
          Counted Qty
        </div>
      </div>
      {{items}}
    </div>
  </page>
</body>

</html>
`;
