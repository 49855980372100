import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayTwoDecimals'
})
export class DisplayTwoDecimalsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(typeof value === 'string') {
      return parseFloat(value).toFixed(2) || '0';
    }
    
    return value.toFixed(2) || '0';
  }
}