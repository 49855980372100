import { AppConfigService } from './app-config.service';
import { AlertService } from './alert.service';
import { Observable, timer, of, throwError, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { UserRegistration } from '../objects/UserRegistration';
import { map, catchError } from 'rxjs/operators';
import { BackendService } from './backend.service';
import { Doctor } from '../objects/state/Doctor';

@Injectable()
export class ApiPatientInfoService {
  private API_PATIENT_INFO_URL;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private appConfig: AppConfigService,
    private backendService: BackendService
  ) {
    this.API_PATIENT_INFO_URL = this.appConfig.getConfig().API_PATIENT_INFO_URL;
  }

  // Patient Info
  register(user: UserRegistration): Observable<HttpResponseBody> {
    if (user.kycData != null) {
      user.kycData.kycStatus = "ID_UNVERIFIED";
    }
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/patient/register`,
      JSON.stringify(user)
    );
  }

  update(id: string, user: UserRegistration): Observable<HttpResponseBody> {

    if (user.kycData != null && ( user.kycData.kycStatus=="" ||  user.kycData.kycStatus==" "||  user.kycData.kycStatus==null)) {
      user.kycData.kycStatus = "ID_UNVERIFIED";
    }
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/patient/update/${id}`,
      JSON.stringify(user)
    );
  }

  search(searchString: string, filterString: string = ''): Observable<HttpResponseBody> {
    const clinicId = localStorage.getItem('clinicId')

    const withFilterUri = `like-search-with-filter/${clinicId}/${searchString}/${filterString} `;
    const withoutFilterUri = `like-search/${clinicId}/${searchString}`

    const url = `${this.API_PATIENT_INFO_URL}/patient/${filterString ? withFilterUri : withoutFilterUri}`;

    return this.http
      .post<HttpResponseBody>(
        url,
        JSON.stringify({}),
        { observe: 'response' }
      )
      .pipe(
        map(res => res.body),
        catchError(err => throwError(err))
      );
  }

  searchBy(type: string, value: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/patient/search/${type}/${value}`,
      JSON.stringify({})
    );
  }



  mergePatients(primaryPatientId: string, secondaryPatientId: string): Observable<HttpResponseBody> {

    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/patient/merge/${primaryPatientId}/${secondaryPatientId}`,
      JSON.stringify({})
    );
  }


  validateID(value: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/patient/validate/${value}`,
      JSON.stringify({})
    );
  }

  //Patient-Source
  listPatientSources(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_PATIENT_INFO_URL}/patient/patient-sources`;
    const body = JSON.stringify({});
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  //Approver List
  listApprovers(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_PATIENT_INFO_URL}/patient/person-incharge`;
    const body = JSON.stringify({});
    const options = {}

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  // Medical Alert
  updateAlerts(patientId: string, addAlertsArray, trashAlertsArray) {
    const source = timer(500);

    const subscribe = source.subscribe(val => {
      this.deleteAlert(trashAlertsArray).subscribe(
        arr => {},
        err => this.alertService.error(JSON.stringify(err.error['message']))
      );
    });

    this.addAlert(patientId, addAlertsArray).subscribe(
      arr => {},
      err => this.alertService.error(JSON.stringify(err.error['message']))
    );
  }

  addAlert(patientId: string, medicalAlert): Observable<HttpResponseBody> {
    if (medicalAlert.length > 0) {
      return this.http.post<HttpResponseBody>(
        `${this.API_PATIENT_INFO_URL}/medical-alerts/add/${patientId}`,
        JSON.stringify(medicalAlert)
      );
    } else {
      return of(null);
    }
  }

  listAlert(patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/medical-alerts/list/${patientId}`,
      JSON.stringify({})
    );
  }

  deleteAlert(medicalAlert): Observable<HttpResponseBody> {
    if (medicalAlert.length > 0) {
      return this.http.post<HttpResponseBody>(
        `${this.API_PATIENT_INFO_URL}/medical-alerts/delete`,
        JSON.stringify(medicalAlert)
      );
    } else {
      return of(null);
    }
  }

  inActivateAlert(medicalAlert): Observable<HttpResponseBody> {
    if (medicalAlert.length > 0) {
      return this.http.post<HttpResponseBody>(
        `${this.API_PATIENT_INFO_URL}/medical-alerts/inactivate`,
        JSON.stringify(medicalAlert)
      );
    } else {
      return of(null);
    }
  }

  // Policy Holder
  assignPolicy(coverageType, body): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/policyholder/add/${coverageType}`,
      JSON.stringify(body)
    );
  }

  removePolicy(
    policyHolderId,
    coverageType,
    medicalCoverageId,
    planId
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL
      }/policyholder/remove/${policyHolderId}/${coverageType}/${medicalCoverageId}/${planId}`,
      JSON.stringify({})
    );
  }

  searchAssignedPoliciesByUserId(body, visitId?): Observable<HttpResponseBody> {
    if (visitId) {
      return this.http.post<HttpResponseBody>(
        `${this.API_PATIENT_INFO_URL}/policyholder/search-by-user-id/${visitId}`,
        JSON.stringify(body)
      );
    } else {
      return this.http.post<HttpResponseBody>(
        `${this.API_PATIENT_INFO_URL}/policyholder/search-by-user-id`,
        JSON.stringify(body)
      );
    }
  }

  public getRelationsPackageDetails(body, visitId, relativesNRICType, relativeNRICNumber): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/policyholder/search-by-user-id/${visitId}/${relativesNRICType}/${relativeNRICNumber}`,
      JSON.stringify(body)
    );
  }

  editPolicy(
    policyHolderType,
    policyHolderId,
    body
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL
      }/policyholder/edit/${policyHolderType}/${policyHolderId}`,
      JSON.stringify(body)
    );
  }

  // Allergy Management
  listAllergyGroups(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_PATIENT_INFO_URL}/allergy-management/list/groups`;
    const body = JSON.stringify({});
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  checkAllergies(
    patientId: string,
    allergyArray: string[]
  ): Observable<HttpResponseBody> {
    if (allergyArray && allergyArray.length > 0) {
      return this.http.post<HttpResponseBody>(
        `${this.API_PATIENT_INFO_URL
        }/allergy-management/check/allergies/${patientId}`,
        JSON.stringify(allergyArray)
      );
    } else {
      return new Observable<HttpResponseBody>()
    }
  }

  // Notifications
  createNotification(
    username: string,
    type: string,
    priority: string,
    message: string
  ) {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/notification/create`,
      JSON.stringify({
        username,
        addedBy: type,
        priority,
        message,
      })
    );
  }

  listNotifications(clinicId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/notification/list/${clinicId}/true`,
      {}
    );
  }

  markNotificationAsRead(notificationId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/notification/mark-read/${notificationId}`,
      {}
    );
  }

  addPatientVaccineAssociation(
    patientId: string,
    vaccine
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL
      }/vaccination/patient/association/add/${patientId}`,
      JSON.stringify(vaccine)
    );
  }

  addPatientSflAssociation(
    patientId: string,
    body
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL
      }/patient/sfl-history/add/${patientId}`,
      JSON.stringify(body)
    );
  }

  modifyPatientSflAssociation(
    patientId: string,
    body
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL
      }/patient/sfl-history/modify/${patientId}`,
      JSON.stringify(body)
    );
  }

  removePatientSflAssociation(
    patientId: string,
    associateId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL
      }/patient/sfl-history/remove/${patientId}/${associateId}`,
      {}
    );
  }

  modifyPatientVaccineAssociation(
    patientId: string,
    vaccinationId: string,
    vaccine
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL
      }/vaccination/patient/association/modify/${patientId}/${vaccinationId}`,
      JSON.stringify(vaccine)
    );
  }

  removePatientVaccineAssociation(
    patientId: string,
    associateId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL
      }/vaccination/patient/association/remove/${patientId}/${associateId}`,
      {}
    );
  }

  updateDoctorOnlineStatus(
    doctorId: string,
    online: boolean,
    clinicId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/doctor/send/toggle-info/${doctorId}/${clinicId}/${online}`,
      {}
    );
  }

  getDoctorOnlineStatus(doctorId: string, clinicId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/doctor/query/toggle-info/${doctorId}/${clinicId}`,
      {}
    );
  }

  listDentalCharts(patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/patient/dental-chart/list/${patientId}/0/50`,
      JSON.stringify({})
    );
  }

  saveDentalCharts(payload): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/patient/dental-chart/save`,
      JSON.stringify(payload)
    );
  }

  notificationAccept(notificationId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/notification/accept/${notificationId}`,
      {}
    );
  }

  notificationDecline(notificationId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/notification/decline/${notificationId}`,
      {}
    );
  }

  searchCoperatePolicyHolderByIdentity(searchParam): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/policyholder/search-corporate-policy-holder`,
      JSON.stringify(searchParam)
    );
  }

  public updatePatientPayables(payables, patientId): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/patient/update-specific-fields/${patientId}`,
      JSON.stringify(payables)
    );
  }

  public getScreeningEligibilities(patientId, clinicId): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/policyholder/screening-eligibility/${patientId}/${clinicId}`,
      {}
    );
  }

  public getPlanComparison(caseId: string, visitId: string, data: any): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_INFO_URL}/charging/plan-comparison/${caseId}/${visitId}`,
      JSON.stringify(data)
    );
  }
}
