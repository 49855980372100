export class VitalConfiguration {
  code: string;
  name: string;
  uom: string;
  takenDate: string;
  nextDueDate: string;
  disabled: boolean;
  type: string;

  constructor(code?: string, name?: string, uom?: string, disabled?: boolean, type?: string, takenDate?: string, nextDueDate?: string) {
    this.code = code || '';
    this.name = name || '';
    this.uom = uom || '';
    this.takenDate = takenDate || '';
    this.nextDueDate = nextDueDate || '';
    this.disabled = disabled === undefined ? false : disabled;
    this.type = type || '';
  }
}
