import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-addendum',
  templateUrl: './addendum.component.html',
  styleUrls: ['./addendum.component.scss']
})
export class AddendumComponent implements OnInit {
  
  @Input() addendum: FormControl;
  title: string;
  public eventEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
  }

  onSaveAddendum(){
    this.eventEmitter.emit(this.addendum);
  }
}
