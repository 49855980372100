import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';
import { filter, tap, withLatestFrom, distinctUntilChanged, map, take } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject, combineLatest } from 'rxjs';


import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { arrayUpdate } from '@datorama/akita';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { get } from 'lodash'
import * as moment from 'moment'
import { StoreService } from '../../../../../services/store.service';
import { AkitaAppQuery } from '../../../../../services/states/akita-app.query';
import { AlertService } from '../../../../../services/alert.service';
import { AkitaPatientAppQuery } from '../../../../../services/states/akita-patient/akita-patient-app.query';
import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT, HSGFORMS, INVENTORY_DATE_FORMAT } from '../../../../../constants/app.constants';
import { CheckboxModel } from '../../../checkbox/checkbox.model';
import { AkitaChronicConditionQuery } from '../../../../../services/states/akita-chronic-condition.query';
import { ChronicCondition } from '../../../../../objects/state/ChronicCondition';
import { ArrayValidators } from '../../../../../validators/ArrayValidators';
import { PatientHsgService } from './patient-hsg.service';
import { AkitaCaseVisitQuery } from '../../../../../services/states/akita-case-visit.query';
import { AkitaPatientStoreService } from '../../../../../services/states/akita-patient/akita-patient-store.service';
import HSGPlan from '../../../../../model/HSGPlan';

const getBool = (obj, ltr) => !!get(obj, ltr)
@Injectable({
  providedIn: 'root'
})
export class PatientHsgFormService implements OnInit, OnDestroy {

  isHsgPatient: boolean;
  isHsgClinic: boolean;
  hsgForm: FormGroup;
  isInvalid: boolean;
  originalGoalValues: any[] = [];
  updatedGoalValues: any[] = [];

  goalFormGroupSubject: Subject<FormGroup> = new BehaviorSubject(new FormGroup({}));
  goalFormGroup$ = this.goalFormGroupSubject.asObservable();

  conditionFormGroupSubject: Subject<FormGroup> = new BehaviorSubject(new FormGroup({}));
  conditionFormGroup$ = this.conditionFormGroupSubject.asObservable();

  constructor(
    private fb: FormBuilder,
    private formManager: AkitaNgFormsManager,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaChronicConditionQuery: AkitaChronicConditionQuery,
    private patientHsgService: PatientHsgService,
    private alertService: AlertService,
    private akitaAppQuery: AkitaAppQuery,
    private store: StoreService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaPatientStoreService: AkitaPatientStoreService,
  ) { }

  ngOnDestroy() {
    this.formManager.unsubscribe();
    this.formManager.remove('hsgForm');
    if (this.hsgForm) {
      this.hsgForm.removeControl('goalForm')
      this.hsgForm.updateValueAndValidity();
    }

    this.formManager.remove('hsgForm');
  }

  ngOnInit() { }

  resetBasicForms() {
    for (const key in this.getForm(HSGFORMS.PATIENTCONDITIONFORM).controls) {
      if (key == "selected")
        this.getForm(HSGFORMS.PATIENTCONDITIONFORM).get(key).patchValue(false);
    }
  }

  private initFormGroup() {
    this.getUserEnrollmentInfo();
    this.isHsgClinic = this.akitaAppQuery.checkClinicFeatureExist('HSG');

    this.hsgForm = this.fb.group({
      id: '',
      visitId: '',
      patientId: '',
      clinicId: '',
      submittedDateTime: '',
      nextCheckUpDate: moment().add(6, 'months').format(DISPLAY_DATE_FORMAT),
      recordOfDiscussion: '',
      goalForm: this.createGoalForm(),
      patientConditionForm: this.createPatientConditionForm(),
      healthPlanStatus: '',
      responseStatus: null,
      webserviceResponse: null,
      nextCheckUpAppointmentId: '',
    });

    this.formManager.upsert('hsgForm', this.hsgForm);
  }

  initHsgForm() {
    this.initFormGroup();
  }

  resetHsg() {
    this.formManager.remove('hsgForm');
    this.resetGoalForm();
    this.initFormGroup();
  }

  getUserEnrollmentInfo() {
    this.akitaPatientAppQuery.patientInfo$.subscribe(res => {
      this.isHsgPatient = res.hsgEnrollment && res.hsgEnrollment.enrolledClinicId === this.store.getClinicId();
    });
  }

  createPatientConditionForm(): FormGroup {
    return this.fb.group({
      conditions: this.buildConditions()
    })
  }

  createGoalForm(): FormGroup {
    return this.fb.group({
      healthGoals: this.fb.array([])
    })
  }

  getForm(formName: HSGFORMS): FormGroup {
    return this.hsgForm.get(formName) as FormGroup;
  }

  createConditionControl = (value: CheckboxModel) => new FormControl(value);

  buildConditions() {
    const arr = this.akitaChronicConditionQuery.getPatientConditions().
      map(condition => this.createConditionControl(condition));
    return this.fb.array(arr, {
      validators: ArrayValidators.minLengthWithKey(1, 'selected')
    });
  }

  patchHsgForm(hsgPlan: HSGPlan) {
    this.hsgForm.get('id').patchValue(hsgPlan.id);
    this.hsgForm.get('visitId').patchValue(hsgPlan.visitId);
    this.hsgForm.get('patientId').patchValue(hsgPlan.patientId);
    this.hsgForm.get('clinicId').patchValue(hsgPlan.clinicId);
    this.hsgForm.get('submittedDateTime').patchValue(hsgPlan.submittedDateTime);
    this.hsgForm.get('nextCheckUpDate').patchValue(moment(hsgPlan.nextCheckUpDate, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT));
    this.hsgForm.get('recordOfDiscussion').patchValue(hsgPlan.recordOfDiscussion);
    this.hsgForm.get('healthPlanStatus').patchValue(hsgPlan.healthPlanStatus);
    this.hsgForm.get('responseStatus').patchValue(hsgPlan.responseStatus);
    this.hsgForm.get('webserviceResponse').patchValue(hsgPlan.webserviceResponse);
    this.hsgForm.get('nextCheckUpAppointmentId').patchValue(hsgPlan.nextCheckUpAppointmentId);

    let goals = [];
    hsgPlan.healthGoals.forEach((goal, index) => {
      const goalDropdown = this.patientHsgService.getGoalsDropdown().find(gd => gd.value === goal.workflow.flow);
      let subTarget = null;
      if (goal.workflow.subWorkflow) {
        subTarget = this.patientHsgService.getSubTarget(goalDropdown);
      }
      goals.push({
        id: (index + 1).toString(), 
        goal: goalDropdown,
        target: this.patientHsgService.getTarget(goalDropdown),
        subTarget: subTarget,
        enableSubGoals: true,
        values: goal.values,
        workflow: goal.workflow,
      });
    })

    this.patchGoalsForms(goals);
    this.patchConditionsForms(hsgPlan.cmsConditionsForHSG);
    this.alertService.setHsgTargetUpdated(this.isHsgFormInvalidObs());
  }

  resetGoalForm() {
    this.goalFormGroupSubject.next(this.createGoalForm());
  }

  public getHsgPlanForConsultation(): any {
    let submitCarePlan = null;
    if (this.hsgForm && this.hsgForm.value.goalForm && this.hsgForm.value.goalForm.healthGoals && this.hsgForm.value.goalForm.healthGoals.length > 0) {
      const hsgFormValue = this.hsgForm.value;
      submitCarePlan = {
        id: hsgFormValue.id ? hsgFormValue.id : '',
        visitId: hsgFormValue.visitId ? hsgFormValue.visitId : this.akitaCaseVisitQuery.getActiveVisitId(),
        patientId: hsgFormValue.patientId ? hsgFormValue.patientId : this.akitaPatientStoreService.patientId,
        clinicId: hsgFormValue.clinicId ? hsgFormValue.clinicId : this.store.getClinicId(),
        nextCheckUpDate: hsgFormValue.nextCheckUpDate instanceof Date ? moment(hsgFormValue.nextCheckUpDate).format(DISPLAY_DATE_FORMAT) : hsgFormValue.nextCheckUpDate,
        recordOfDiscussion: hsgFormValue.recordOfDiscussion,
        healthGoals: [],
        healthPlanStatus: hsgFormValue.healthPlanStatus ? (hsgFormValue.healthPlanStatus === 'NEW' ? 'DRAFT' : hsgFormValue.healthPlanStatus) : 'DRAFT',
        cmsConditionsForHSG: [],
        submittedDateTime: hsgFormValue.submittedDateTime ? moment(hsgFormValue.submittedDateTime).format(DB_FULL_DATE_FORMAT) : moment().format(DB_FULL_DATE_FORMAT),
        responseStatus: hsgFormValue.responseStatus ? hsgFormValue.responseStatus : null,
        nextCheckUpAppointmentId: hsgFormValue.nextCheckUpAppointmentId ? hsgFormValue.nextCheckUpAppointmentId : '',
        webserviceResponse: hsgFormValue.webserviceResponse ? hsgFormValue.webserviceResponse : null,
      }
  
      submitCarePlan.healthGoals = [];
      for (let goal of hsgFormValue.goalForm.healthGoals) {
        submitCarePlan.healthGoals.push({values: goal.values, workflow: goal.workflow});
      }
  
      submitCarePlan.cmsConditionsForHSG = [];
      for (let condition of hsgFormValue.patientConditionForm.conditions) {
        if (condition.selected) {
          submitCarePlan.cmsConditionsForHSG.push(condition.code);
        }
      }
  
      if (!submitCarePlan.id) {
        delete submitCarePlan.id;
      }
    }

    return submitCarePlan;
  }

  isEmptyGoalsAdded(): boolean {
    if (this.hsgForm && this.hsgForm.value.goalForm && this.hsgForm.value.goalForm.healthGoals && this.hsgForm.value.goalForm.healthGoals.length > 0) {
      const hsgFormValue = this.hsgForm.value;
      for (let goal of hsgFormValue.goalForm.healthGoals) {
        if (Object.keys(goal.goal).length === 0) {
          return true;
        }
      }
    }
    return false;
  }

  patchGoalsForms(goals: any[]) {
    const goalsFG = this.getForm(HSGFORMS.GOALFORM);
    const goalArray = goalsFG.get('healthGoals') as FormArray;
    goalArray.clear();

    goals.forEach((goal => {
      let itemWithCaptureContext = undefined;

      goal.target.forEach(item => {
        if(item.type === 'multi-choice') {
          itemWithCaptureContext = item.data.find(val => val.captureContext === true);
        }
      });

      if(itemWithCaptureContext) {
        const valueWithOtherOption = goal.values.find(val => val.placeholder === itemWithCaptureContext.key);

        if(valueWithOtherOption) {
          itemWithCaptureContext.showInput = true;
          itemWithCaptureContext.inputValue = valueWithOtherOption.value;
        }
      }

      goalArray.push(this.fb.group({
        goal: goal.goal,
        id: goal.id,
        values: this.fb.array(goal.values),
        target: this.fb.array(goal.target),
        subTarget: this.fb.array(goal.subTarget && goal.subTarget.length ? goal.subTarget : []),
        enableSubGoals: goal.enableSubGoals,
        workflow: goal.workflow,
      }));
    }));
    
    this.goalFormGroupSubject.next(goalsFG);
    this.alertService.setHsgTargetUpdated(this.isHsgFormInvalidObs());
  }

  patchConditionsForms(conditionCodes: string[] = []) {
    const conditionsFG = this.getForm(HSGFORMS.PATIENTCONDITIONFORM);
    const conditionArray = conditionsFG.get('conditions') as FormArray;

    conditionArray.controls.forEach((condition => {
      condition.value.selected = conditionCodes.includes(condition.value.code);
      condition.patchValue(condition.value);
    }))

    this.conditionFormGroupSubject.next(conditionsFG);
  }

  addGoalGroup(conditions: Partial<ChronicCondition>[]) {
    const codes = conditions.map(({ code }) => code);
    const goalsArray = this.getForm(HSGFORMS.GOALFORM).get('healthGoals') as FormArray;

    codes.forEach(code => {
      const fg = this.createGoalControl(code);
      goalsArray.push(fg)
    });
  }

  createGoalControl = code => this.fb.group({
    id: '',
    workflow: null,
    goal: null,
    values: this.fb.array([])
  });

  isHsgFormInvalid(): boolean {
    return this.isHsgClinic && this.isHsgPatient && this.isInvalid;
  }

  isHsgFormInvalidObs(): Observable<boolean> {
    return this.isAllTargetValuesAdded();
  }

  isAllTargetValuesAdded(): Observable<boolean> {
    return this.goalFormGroup$
    .pipe(
      map(goalForm => {
        this.isInvalid = false;
        const goalsForm = goalForm.get('healthGoals') as FormArray;
        
        if (goalsForm) {
          const goals = goalsForm.value;
          
          goals.forEach(goal => {
            goal.target.forEach(target => {
              if (target.placeholder && (!goal.values.find(val => val.placeholder === target.placeholder && val.value))) {
                this.isInvalid = true;
                return;
              }

              const value = goal.values.find(val => val.placeholder === target.placeholder);

              if((target.minValue && target.maxValue) && !(parseInt(value.value) >= target.minValue && parseInt(value.value) <= target.maxValue)) {
                this.isInvalid = true;
                return;
              }
            })

            if (goal.subTarget && goal.enableSubGoals) {
              goal.subTarget.forEach(target => {
                if (target.placeholder && (!goal.values.find(val => val.placeholder === target.placeholder && val.value))) {
                  this.isInvalid = true;
                  return;
                }
  
                const value = goal.values.find(val => val.placeholder === target.placeholder);
  
                if((target.minValue && target.maxValue) && !(parseInt(value.value) >= target.minValue && parseInt(value.value) <= target.maxValue)) {
                  this.isInvalid = true;
                  return;
                }
              })
            }

            if(goal.target[0]?.type === 'multi-choice' && goal.values?.length === 0){
              this.isInvalid = true;
                return;
            } else if (goal.target[0]?.type === 'multi-choice' && goal.values?.length > 0) {
              goal.values.forEach(element => {
                if (element.placeholder === 'OTH001' && !element.value){
                  this.isInvalid = true;
                  return;
                }
              });
            }
          })
        }

        return this.isInvalid;
      }),
      untilDestroyed(this)
    )
  }

  isSaveButtonDisabled(): Observable<boolean> {
    return this.formManager.selectValid('hsgForm')
    .pipe(
      map(hsgFormValidity => {
        if (hsgFormValidity) {
          return false;
        }
        return true;
      })
    )
  }
}