import {
  OrderReturnRequestStatus,
  OrderReturnRequest,
} from '../OrderReturnRequest';

export class PurchaseOrderReturnRequestListResponse {
  orderId: string;
  grvnNo: string;
  purchaseOrderId: string;
  poNo: string;

  returnQuantity: number;
  createDate: string;
  status: OrderReturnRequestStatus;
  data: OrderReturnRequest;

  adapt(obj?): PurchaseOrderReturnRequestListResponse {
    if (!obj) {
      return this;
    }

    this.orderId = obj.orderId;
    this.purchaseOrderId = obj.purchaseOrderId;
    this.poNo = obj.poNo;
    this.grvnNo = obj.grvnNo;
    this.returnQuantity = obj.returnQuantity;
    this.createDate = obj.createDate;
    this.status = obj.status;
    this.data = new OrderReturnRequest().adapt(obj.data);

    return this;
  }
}
