import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {MedicalCoverage} from "../../objects/MedicalCoverage";

export interface MedicalCoverageItemInfo {
  _id: string;
  restrictPriceUpdate: boolean;
}

export interface MedicalCoverageItemInfoState extends EntityState<MedicalCoverageItemInfo> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'whitelistedItem', idKey: '_id' })
export class MedicalCoverageItemInfoStore extends EntityStore<MedicalCoverageItemInfoState> {

  constructor() {
    super();
  }

}
