import {
  DB_FULL_DATE_FORMAT,
  DISPLAY_DATE_TIME_NO_SECONDS_FORMAT,
} from './../../../constants/app.constants';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { PatientPayable } from '../../../objects/response/PatientPayable';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-payable-item',
  templateUrl: './patient-payable-item.component.html',
  styleUrls: ['./patient-payable-item.component.scss'],
})
export class PatientPayableItemComponent implements OnInit, OnDestroy {
  @Input() patientPayable: FormGroup;
  patientPayableItem: PatientPayable;

  private componentDestroyed: Subject<void> = new Subject();
  public isPaidByUser: boolean;

  constructor() {
    this.patientPayableItem = new PatientPayable();
  }

  ngOnInit() {
    this.processData();
    this.isPaidByUser = this.patientPayable.get('paidByUser').value;
    
    if(this.patientPayable.get('caseNo') !== null && this.patientPayable.get('caseNo') !== undefined) {
      this.patientPayable
        .get('caseNo')
        .valueChanges.pipe(takeUntil(this.componentDestroyed))
        .subscribe(val => {
          this.processData();
        });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  processData() {
    if (this.patientPayable.value) {
      this.patientPayableItem.billDateTime = moment(
        this.patientPayable.get('billDateTime').value,
        DB_FULL_DATE_FORMAT
      ).format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT);
      this.patientPayableItem.caseNo = this.patientPayable.contains('caseNo')
        ? this.patientPayable.value['caseNo'] : '';
      this.patientPayableItem.amount = this.patientPayable.get('amount').value;
      this.patientPayableItem.amount = this.patientPayableItem.amount / 100;
      this.patientPayableItem.paidByUser = this.patientPayable.get(
        'paidByUser'
      ).value;
    }
  }
}
