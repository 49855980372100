import * as moment from 'moment'
import { DISPLAY_DATE_FORMAT } from '../constants/app.constants';

function getDetailAge(dob) {
    const countAge = (date, key) =>
        moment().diff(date, key);

    let momentDob = moment(dob, DISPLAY_DATE_FORMAT);
    let ageString = '';
    let ageInYears = countAge(momentDob, 'years');
    momentDob.add(ageInYears, 'years');
    let ageInMonths = countAge(momentDob, 'months');
    momentDob.add(ageInMonths, 'months');
    let ageInDays = countAge(momentDob, 'days');

    if (ageInYears > 0)
        ageString += `${ageInYears}Y `;

    if (ageInMonths > 0)
        ageString += `${ageInMonths}M `;

    if (ageInDays > 0)
        ageString += `${ageInDays}D `;

    return ageString;
}


function getAge(dob) {
    const countAge = key =>
        moment().diff(moment(dob, DISPLAY_DATE_FORMAT), key);

    let age = countAge('years');
    let ageQuantifier = ' years';

    if (age < 1) {
        age = countAge('months');
        ageQuantifier = ' months';
    }

    if (age < 1) {
        age = countAge('days');
        ageQuantifier = ' days';
    }
    return age + ageQuantifier;
}

export { getDetailAge, getAge };