import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import AppointmentMaster from '../../../objects/AppointmentMaster';
import { CalendarView } from 'angular-calendar';

export interface AppointmentMasterState extends AppointmentMaster {}

export function createInitialState(): AppointmentMasterState {
  return {
    clinicSelected: '',
    clinicSelectedDoctorList: [],
    isExternal: false,
    view: CalendarView.Month,
    viewDate: new Date(),
    viewDate1MthAfter: new Date(),
    viewDate1MthBefore: new Date(),
    viewDateStartOfMonth: new Date(),
    viewDateEndOfMonth: new Date(),
    lastUpdated: new Date(),
    selectedDoctorFilter: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'AppointmentMaster' })
export class AppointmentMasterStore extends Store<AppointmentMasterState> {
  constructor() {
    super(createInitialState());
  }
}
