import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryEntity } from '@datorama/akita';

import { AkitaMedicalCoverageStore } from './akita-medical-coverage.store';
import { CoveragePlan } from '../../objects/state/CoveragePlan';
import { DISPLAY_DATE_FORMAT } from '../../constants/app.constants';
import { MedicalCoverage } from '../../objects/state/MedicalCoverage';

@Injectable({ providedIn: 'root' })
export class AkitaMedicalCoverageQuery extends QueryEntity<MedicalCoverage> {
  medicalCoverageList$ = this.selectAll();
  validMedicalCoverageList$ = combineLatest(
    this.medicalCoverageList$,
    this.validMedicalCoverage
  );

  constructor(protected store: AkitaMedicalCoverageStore) {
    super(store);
  }

  private validMedicalCoverage(medicalCoverageList) {
    const todayDate = moment();
    return medicalCoverageList.filter(item =>
      todayDate.isSameOrBefore(moment(item.endDate, DISPLAY_DATE_FORMAT), 'day')
    );
  }

  getCoverageByPlanId(planId): MedicalCoverage {
    const medicalCoverage = this.getAll({
      filterBy: (entity: MedicalCoverage) => entity.coveragePlans.some(plan => plan.id === planId),
    });

    return medicalCoverage.length > 0 ? <MedicalCoverage>medicalCoverage[0] : undefined;
  }

  getMedicalCoveragePlan(planId) {
    let coveragePlan: CoveragePlan;

    this.getAll().forEach((medCoverage: MedicalCoverage) => {
      medCoverage.coveragePlans.forEach(plan => {
        if (plan.id === planId) {
          coveragePlan = plan;
        }
      });
    });

    if (!coveragePlan) coveragePlan = undefined;
    return coveragePlan;
  }

  getMedicalCoverageByPlanId(planId) {
    return <MedicalCoverage>this.getAll().find((medCov: MedicalCoverage) => {
      const planIds = medCov.coveragePlans.map(plan => plan.id);
      if (planIds.includes(planId)) return medCov;
    });
  }

  selectMedicalCoverages(planIds: Array<string>) {
    return this.selectAll().pipe(
      map(medicalCoverages => {
        return medicalCoverages.filter((medicalCoverage: MedicalCoverage) => {
          const mcPlanIds = medicalCoverage.coveragePlans.map(plan => plan.id);
          if (mcPlanIds.some(mcPlanId => planIds.indexOf(mcPlanId) !== -1)) return medicalCoverage;
        });
      })
    );
  }
}


