class AttachedMedicalCoverage {
  medicalCoverageId: string;
  planId: string;
  coverageId?: string;
  name?: string;
  type?: string;
  remarks?: string;
  limit?: number;
  updated?: boolean;
  payerUserId? : {
    idType: string;
    number: string;
  }

  constructor(
    medicalCoverageId?: string,
    planId?: string,
    name?: string,
    coverageId?: string,
    type?: string,
    remarks?: string,
    limit?: number,
    updated?: boolean,
    payerUserId? : {
      idType: string;
      number: string;
    }
  ) {
    this.medicalCoverageId = medicalCoverageId || '';
    this.planId = planId || '';
    this.coverageId = coverageId || '';
    this.name = name || '';
    this.type = type || '';
    this.remarks = remarks || '';
    this.limit = limit || 0;
    this.updated = updated || false;
    this.payerUserId = payerUserId;
  }
}

export { AttachedMedicalCoverage };
