<div class="modal-detail">
  <div class="row thin-bottom-border plan-details mx-0">
    <div class="col-4">
      <div>
        <div class="text-indigo">Plan Details</div>
        <span>Cap Per Visit:</span> {{ plan.capPerVisit.limit | displayDollar | currency:'SGD':'code' }}
        <br>
        <span>Cap Per Week:</span> {{ plan.capPerWeek.limit | displayDollar | currency:'SGD':'code' }}
        <br>
        <span>Cap Per Month:</span> {{ plan.capPerMonth.limit | displayDollar | currency:'SGD':'code' }}
        <br>
        <br>
        <span>Remarks:</span> {{ plan.remarks | displayStringEmpty:'-' }}
        <br>
      </div>
    </div>
    <div class="col-4">
      <div>
        <br>
        <span>Cap Per Year:</span> {{ plan.capPerYear.limit | displayDollar | currency:'SGD':'code' }}
        <br>
        <span>Cap Per Lifetime:</span> {{ plan.capPerLifeTime.limit | displayDollar | currency:'SGD':'code' }}
        <br>
        <div *ngIf="plan.copayment && plan.copayment.value > 0 ">
          <span>Co-Payment:</span> {{ plan.copayment.value > 0 ? "Yes " : "No " }}
          <div *ngIf="plan.copayment.paymentType=='DOLLAR'">
            <span>Co-Payment Amount:</span> {{ plan.copayment?.value | displayDollar | currency:'SGD':'code' }}
          </div>
          <div *ngIf="plan.copayment.paymentType=='PERCENTAGE'">
            <span>Co-Payment Percentage:</span> {{ plan.copayment?.value | displayPercentage}}
          </div>
        </div>
        <br>
        <div *ngIf="coverage.startDate && coverage.endDate">
          <span>Coverage Start Date:</span> {{ coverage.startDate| displayStringEmpty:'-' }}
          <br>
          <span>Coverage End Date:</span> {{ coverage.endDate| displayStringEmpty:'-' }}
          <br>
        </div>
        <br>
        <div *ngIf="plan.startDate && plan.endDate">
          <span>Plan Start Date:</span> {{ plan.startDate| displayStringEmpty:'-' }}
          <br>
          <span>Plan End Date:</span> {{ plan.endDate| displayStringEmpty:'-' }}
          <br>
        </div>
      </div>
    </div>
    <div class="col-4">
      <ng-container>
        <div>
          <div class="text-indigo">Company Details</div>
          <span>Contact Person:</span> {{ coverage | displayMCContactDetail : 'name' | displayStringEmpty:'-' }}
          <br>
          <span>Address:</span> {{ coverage.address | displayMCAddress: '-' }}
          <br>
          <span>Email:</span> {{ coverage | displayMCContactDetail : 'email' | displayStringEmpty:'-' }}
          <br>
          <span>Telephone:</span> {{ coverage | displayMCContactDetail : 'directNumber' | displayStringEmpty:'-' }}
          <br>
          <span>Website:</span>
          <ng-container *ngIf="coverage.website && coverage.website.length > 0; else elseTemplate">
            <a [href]="coverage.website" target="_blank"> {{ coverage.website }}</a>
          </ng-container>
          <ng-template #elseTemplate>
            -
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="plan-details">
    <div class="row text-indigo mx-0">
      <div class="col-4">Clinic Remarks</div>
      <div class="col-4">Registration Remarks</div>
      <div class="col-4">TPA/Corporate Remark</div>
    </div>
    <div class="row mx-0">
      <div class="col-4" [innerHTML]="plan.clinicRemarks | displayLinebreakPipe | displayStringEmpty:'-' "></div>

      <div class="col-md-4" *ngIf="plan.medisavePlan; else notMedisavePlan">
        Check Post Registration
      </div>
      <ng-template #notMedisavePlan>
        <div class="col-4">
          <div [innerHTML]="plan.registrationRemarks | displayLinebreakPipe | displayStringEmpty:'-' "> </div>
        </div>
      </ng-template>

      <div class="col-4" [innerHTML]="plan.paymentRemarks | displayLinebreakPipe | displayStringEmpty:'-' "></div>
    </div>
    <div>
      <div class="col-8 offset-4">
 <!-- SFL eligble items -->
              <div
              class="row mt-2"
              *ngIf="sflEligibilityItems.length > 0">
              <br>
              <p class="txt-balance">SFL Eligibility Items</p>
              <table style="width:100%;">
                <tr>
                  <th>Screening Services</th>
                  <th>Screening Eligibility</th>
                  <th>Subsidiary and remuneration Eligibility</th>
                  <th>Date of Next screening</th>
                </tr>
                <tr *ngFor="let item of sflEligibilityItems">
                  <td>{{item?.screeningType}}</td>
                  <td>{{item?.screeningEligibility}}</td>
                  <td>{{item?.subsidyEligibility}}</td>
                  <td>{{item?.nextDueDate}}</td>
                </tr>
              </table>
              </div>
        </div>
    </div>
    <div class="row mx-0">
      <div class="col-4"></div>
      <div class="col-8">
        <!-- Vaccination -->
        <div class="row mt-2"
          *ngIf="plan.vaccinationPlan === true && plan.vaccinationStatusList !== null">
          <table style="width:100%">
            <tr>
              <th>Vaccination</th>
              <th>Dosage</th>
              <th>Eligible</th>
            </tr>
            <ng-container *ngFor="let vaccinationStatus of plan.vaccinationStatusList">
              <tr *ngFor="let vacRecord of vaccinationStatus.vaccinationRecords">
                <td>{{vaccinationStatus.name}} - {{vaccinationStatus.code}}</td>
                <td>{{vacRecord.doseStepCode}}</td>
                <td>{{vacRecord.eligibleForVaccination === true ? 'Yes' : 'No'}}</td>
              </tr>
              <tr *ngIf="vaccinationStatus.vaccinationRecords && vaccinationStatus.vaccinationRecords.length === 0">
                <td colspan="3">No data found, please check MHCP portal for eligibility</td>
              </tr>
              <tr *ngIf="vaccinationStatus && vaccinationStatus.balance === -1 && vaccinationStatus.remark">
                <td colspan="3" style="word-break:break-all;">{{vaccinationStatus.remark}}</td>
              </tr>
            </ng-container>
          </table>
        </div>

        <!-- CDS -->
        <div class="row mt-2"
          *ngIf="plan.cdsPlan === true && plan.cdsQueryStatus !== null && plan.cdsQueryStatus.checkedMilestones !== null">
          <table style="width:100%">
            <tr>
              <th>Visit Date</th>
              <th>Code</th>
              <th>Desc</th>
              <th>Status</th>
            </tr>
            <tr *ngFor="let milestoneRecord of milestoneRecords">
              <td>{{milestoneRecord.visitDate}}</td>
              <td>{{milestoneRecord.milestoneCode}}</td>
              <td>{{milestoneRecord.milestoneDescription}}</td>
              <td>{{milestoneRecord.claimStatusCode}}</td>
            </tr>
            <tr *ngIf="milestoneRecords && milestoneRecords.length === 0">
              <td colspan="4">No data found, please check MHCP portal for eligibility</td>
            </tr>
            <tr *ngIf="plan.cdsQueryStatus && plan.cdsQueryStatus.remark">
              <td colspan="4">{{plan.cdsQueryStatus.remark}}</td>
            </tr>
          </table>
        </div>

        <!-- Dental -->
        <div class="row mt-2"
          *ngIf="plan.dentalPlan === true && plan.dentalStatus !== null">
          <table style="width:100%">
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Ext Code</th>
              <th>Ext Name</th>
              <th>Balance Qty</th>
              <th>Next Claim Date</th>
              <th>Eligible</th>
            </tr>
            <tr *ngFor="let dentalItem of plan.dentalStatus.dentalItems">
              <td>{{dentalItem.code}}</td>
              <td>{{dentalItem.name}}</td>
              <td>{{dentalItem.externalCode}}</td>
              <td>{{dentalItem.externalName}}</td>
              <td>{{dentalItem.remainingForPeriodQty}} of {{dentalItem.limitForPeriodQty}}</td>
              <td>{{dentalItem.nextClaimDate}}</td>
              <td>{{dentalItem.eligible === true ? 'Yes' : 'No'}}</td>
            </tr>
            <tr *ngIf="plan.dentalStatus.dentalItems && plan.dentalStatus.dentalItems.length === 0 && plan.dentalStatus.remark">
              <td colspan="7" style="word-break:break-all;">{{plan.dentalStatus.remark}}</td>
            </tr>
          </table>
        </div>

        <!-- Medical Chit -->
        <div class="row mt-2"
          *ngIf="plan.medicalChit === true && plan.medicalChitStatusList !== null">
          <div class="col-12" *ngFor="let medicalChitStatus of plan.medicalChitStatusList">
            <span><b>Issued Date: {{medicalChitStatus.issuedDate}}</b></span>
            <div class="col-12 pl-0 pr-0" style="float:left;">
              <span><b>Medical Chit - Report Collection : {{medicalChitStatus.reportCollectionMode}} &nbsp;</b></span>
              <span [ngSwitch]="medicalChitStatus.reportCollectionMode">
                <span *ngSwitchCase="'Send to company'"><b>attention to {{medicalChitStatus.attentionTo}}</b></span>
                <span *ngSwitchCase="'Send to email'"><b>: {{medicalChitStatus.emailTo}}</b></span>
              </span>
            </div>
            <table style="width:100%">
              <tr>
                <th>Services</th>
              </tr>
              <tr *ngFor="let medicalChitItem of medicalChitStatus.services">
                <td>{{medicalChitItem.name}}</td>
              </tr>
            </table>
            <span style="display: block;" class="mb-2"><b>Remarks : &nbsp;</b>{{medicalChitStatus.remarks}}</span>
          </div>
        </div>

        <!-- Insurance -->
        <table class="w-100" *ngIf="plan.insuranceItemEligibility && plan.insuranceItemEligibility.eligibleItems.length">
          <tr>
            <th colspan="2">Item</th>
            <th class="w-90">Agree Fee</th>
            <th class="w-90">Co-Pay</th>
          </tr>
          <tr *ngFor="let item of plan.insuranceItemEligibility.eligibleItems">
            <td>{{item.code}}</td>
            <td>{{item.name}}</td>
            <td>${{ (item.limit * 100) | displayDollar }}</td>
            <td>${{ (item.copay) * 100 | displayDollar }}</td>
          </tr>
          <tr *ngIf="plan && plan.medisaveStatus && plan.medisaveStatus.medisaveItems && plan.medisaveStatus.medisaveItems.length === 0 && plan.medisaveStatus.remark">
            <td colspan="4" style="word-break:break-all;">{{plan.medisaveStatus.remark}}</td>
          </tr>
        </table>

        <!-- HSG -->
        <div class="row mt-2" *ngIf="plan.hsgFirstVisitStatus">
          <div class=" col-md-6">
          <label class='mb-0'>First Visit Claimable?</label>
          <p class="text-claimable" [ngClass]="plan.hsgFirstVisitStatus.claimable ? 'yes' : 'no' ">{{ plan.hsgFirstVisitStatus.claimable ? 'YES' : 'NO' }}</p>
        </div>

        <!-- Medisave -->
        <div class="row mt-2" *ngIf="plan.medisavePlan === true && plan.medisaveStatus !== null">
          <div class=" col-md-6">
          <label class='mb-0'>Payer Name</label>
          <ng-select class="p-0 form-control form-control-sm custom-select" [(ngModel)]="selectedPayer"
            placeholder="Self" [items]="relationshipDetails" bindLabel="name" bindValue="name"
            (change)="onSelectPayer($event)" [clearable]="false">
          </ng-select>
        </div>

        <div class="col-md-6">
          <span *ngIf="isPayerBalanceLoading" class="spinner-border">
          </span>
        </div>

        <br>
        <p class="txt-balance">Self Balance</p>
        <table style="width:100%;">
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Utilized Amount ($)</th>
            <th>Balance Amount ($)</th>
          </tr>
          <tr *ngFor="let medisaveItem of plan.medisaveStatus.medisaveItems">
            <td>{{medisaveItem.code}}</td>
            <td>{{medisaveItem.name}}</td>
            <td>{{medisaveItem.utilizedAmount}}</td>
            <td>{{medisaveItem.balanceAmount}}</td>
          </tr>
          <tr *ngIf="plan && plan.medisaveStatus && plan.medisaveStatus.medisaveItems && plan.medisaveStatus.medisaveItems.length === 0 && plan.medisaveStatus.remark">
            <td colspan="4" style="word-break:break-all;">{{plan.medisaveStatus.remark}}</td>
          </tr>
        </table>

        <p class="txt-balance"
          *ngIf="plan.medisaveStatus.medisaveRelationItems && plan.medisaveStatus.medisaveRelationItems.length > 0">
          Payer Balance
        </p>

        <table style="width:100%;"
          *ngIf="plan.medisaveStatus.medisaveRelationItems && plan.medisaveStatus.medisaveRelationItems.length > 0">
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Utilized Amount ($)</th>
            <th>Balance Amount ($)</th>
          </tr>
          <tr *ngFor="let medisaveRelationItem of plan.medisaveStatus.medisaveRelationItems">
            <td>{{medisaveRelationItem.code}}</td>
            <td>{{medisaveRelationItem.name}}</td>
            <td>{{medisaveRelationItem.utilizedAmount}}</td>
            <td>{{medisaveRelationItem.balanceAmount}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
