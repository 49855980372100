import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
// Objects
import { BalancePayload } from './../../../../../objects/response/BalancePayload';
import { DISPLAY_DATE_FORMAT } from './../../../../../constants/app.constants';
import {
  FormGroup,
  FormArray,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';

// Libraries
import { Subject, of, merge, Subscription } from 'rxjs';
import * as moment from 'moment';
import { distinctUntilChanged, retry, timeout } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { get } from 'lodash';

// Services
import { MedicalCoverageService } from '../../medical-coverage.service';
import {
  createAttachedMedicalCoverage,
  AttachedMedicalCoverage,
} from '../../../../../objects/state/AttachedMedicalCoverage';
import { NgxPermissionsService } from 'ngx-permissions';
import { StoreService } from '../../../../../services/store.service';
import { RpaRequestService } from '../../../../../services/rpa-request.service';
import { AkitaPatientStoreService } from '../../../../../services/states/akita-patient/akita-patient-store.service';
import { TempStoreService } from '../../../../../services/temp-store.service';
import { AkitaPatientAppQuery } from '../../../../../services/states/akita-patient/akita-patient-app.query';

@Component({
  selector: 'app-medical-coverage-item',
  templateUrl: './medical-coverage-item.component.html',
  styleUrls: ['./medical-coverage-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalCoverageItemComponent implements OnInit, OnDestroy {
  @Input() patientCoverageItem: FormGroup;
  @Input() selectedCoverages: FormArray;

  @Input() hasUpdatePriority: boolean;
  @Input() isMedicalCoverageSelectionDisabled: boolean;

  @Input() showBalance: boolean;
  isCollapsed: boolean;
  @Input() visitId: string;

  balance: AbstractControl;
  coverageSelected: AbstractControl;
  coverageType: AbstractControl;
  coverageInactive: AbstractControl;
  endDate: AbstractControl;
  id: AbstractControl;
  isNew: AbstractControl;
  isSelected: AbstractControl;
  medicalCoverageId: AbstractControl;
  patientCoverageId: AbstractControl;
  planId: AbstractControl;
  planInactive: AbstractControl;
  planExpired: AbstractControl;
  planSelected: AbstractControl;
  policyExcluded: AbstractControl;
  policyExpired: AbstractControl;
  policyHolderExpired: AbstractControl;
  policyInactive: AbstractControl;
  selectionOrder: AbstractControl;
  startDate: AbstractControl;
  isDuplicate: AbstractControl;

  balanceStatus: BalancePayload;

  caseDetail: AttachedMedicalCoverage;

  isDisabled: boolean;

  initialised: boolean = false;
  sflEligibilityItems: any = [];

  private patientId: string;
  private nric;
  private patientInfo$;
  private patientInfoSubscription: Subscription;
  private componentDestroyed: Subject<void> = new Subject();
  private isThisAllowedWithChas: boolean = false;

  private isPayerEnabled: boolean = false;
  private medisaveStatusWithRelationItems;
  private payerUserId;
  
  public isPayerBalanceLoading: boolean = false;
  private isPayerBalanceLoadingSub : Subscription;

  constructor(
    private fb: FormBuilder,
    private medicalCoverageService: MedicalCoverageService,
    private cdr: ChangeDetectorRef,
    public permissionsService: NgxPermissionsService,
    private store: StoreService,
    private rpaRequestService: RpaRequestService,
    private akitaPatientStoreService: AkitaPatientStoreService,
    private tempStoreService: TempStoreService,
    private akitaPatientAppQuery: AkitaPatientAppQuery
  ) {
    this.balanceStatus = new BalancePayload();
  }

  ngOnInit() {
    this.initialise();
    
    if (this.patientCoverageItem && this.patientCoverageItem.get('sflStatus').value != null && !this.patientCoverageItem.get('sflStatus').value.error) {
      this.sflEligibilityItems = this.patientCoverageItem.get('sflStatus').value.sflEligibilityItems;
    }
    if (this.hasUpdatePriority) {
      this.disableCheckBox();
      this.cdr.markForCheck();
      this.subscribeChangesForUpdate();
    } else {
      this.subscribeChangesForAdd();
    }

    this.isPlanSelected();
    this.getUserId();
    
    this.selectedCoverages.value.forEach((selectedCoverage: AttachedMedicalCoverage) => {
      let plan = {...this.planSelected.value};

      if(selectedCoverage.planId === this.planId.value) {
        if(selectedCoverage.payerUserId) {
          plan.payerUserId = selectedCoverage.payerUserId;
          this.planSelected.patchValue(plan);   
        }
      }
    });

    this.isPayerBalanceLoadingSub = this.medicalCoverageService.getPayerLoadingStatus().subscribe(result => {
      this.isPayerBalanceLoading = result;
    });
  }

  onHandlePlanChange(event) {
    if(event === 'SELF') {
      this.isPayerEnabled = false;
      this.updatePlanAndSelectedCoveragesWithRelationsMedisaveStatus();
    } else {
      this.isPayerEnabled = true;
      
      if(event.payerUserId) {
        this.payerUserId = event.payerUserId;
        this.medisaveStatusWithRelationItems = undefined;
      }

      if(event.medisaveStatus) {
        this.medisaveStatusWithRelationItems = event.medisaveStatus;
      }

      this.updatePlanAndSelectedCoveragesWithRelationsMedisaveStatus();
    }
  }

  private updatePlanAndSelectedCoveragesWithRelationsMedisaveStatus(): void {
    let plan = {...this.planSelected.value};
    
    if(this.isPayerEnabled) {
      if(this.medisaveStatusWithRelationItems) {
        if(plan.id === this.planId.value) {
          plan.medisaveStatus = this.medisaveStatusWithRelationItems;
          this.planSelected.patchValue(plan);
        }
      }
      
      this.selectedCoverages.value.forEach((selectedCoverage: AttachedMedicalCoverage) => {
        if(selectedCoverage.planId === this.planId.value) {
          selectedCoverage.payerUserId = this.payerUserId;
        }
      });
    } else {
      let medisaveStatus = {...plan.medisaveStatus};
      delete medisaveStatus.medisaveRelationItems;

      plan.medisaveStatus = medisaveStatus;
      this.planSelected.patchValue(plan);

      this.selectedCoverages.value.forEach((selectedCoverage: AttachedMedicalCoverage, index) => {
        if(selectedCoverage.planId === this.planId.value) {
          const {payerUserId, ...updatedSelectedCoverage} = selectedCoverage;
          this.selectedCoverages.value[index] = updatedSelectedCoverage;
        }
      });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed?.unsubscribe();
    this.patientInfoSubscription?.unsubscribe();
    this.isPayerBalanceLoadingSub?.unsubscribe();
  }

  initialise() {
    const get = (key: string) => this.patientCoverageItem.get(key);
    this.balance = get('balance');
    this.coverageSelected = get('coverageSelected');
    this.coverageType = get('coverageType');
    this.coverageInactive = get('coverageInactive');
    this.endDate = get('endDate');
    this.id = get('id');
    this.isCollapsed = true;
    this.isNew = get('isNew');
    this.isSelected = get('isSelected');
    this.medicalCoverageId = get('medicalCoverageId');
    this.patientCoverageId = get('patientCoverageId');
    this.planId = get('planId');
    this.planInactive = get('planInactive');
    this.planExpired = get('planExpired');
    this.planSelected = get('planSelected');
    this.policyExcluded = get('policyExcluded');
    this.policyExpired = get('policyExpired');
    this.policyInactive = get('policyInactive');
    this.policyHolderExpired = get('policyHolderExpired');
    this.selectionOrder = get('selectionOrder');
    this.startDate = get('startDate');
    this.isDuplicate = get('isDuplicate');
    this.isThisAllowedWithChas = this.coverageSelected.value.coveragePlans.some(
      coverage => coverage.allowSelectWithChasPlan === true
    );

    this.initialiseCaseDetail();
    this.initialiseBalance();
    this.getPatientId();

    this.initialised = true;
  }

  initialiseBalance() {
    let availableBalance = this.caseDetail
      ? this.caseDetail.updated
        ? this.caseDetail.limit
        : this.balance.value
      : this.balance.value;

    if (
      this.coverageType.value === 'CHAS' ||
      this.coverageType.value === 'CORPORATE'
    ) {
      if (availableBalance !== -1) {
        availableBalance = availableBalance;
        this.balanceStatus = new BalancePayload(
          'Balance',
          availableBalance ? availableBalance.toString() : '',
          'SUCCESS'
        );
      } else {
        this.balanceStatus = new BalancePayload('', '', 'CHECK');
      }
    } else if (
      this.coverageType.value === 'INSURANCE' ||
      this.coverageType.value === 'MEDISAVE'
    ) {
      this.balanceStatus = new BalancePayload('', '', 'CHECK');
    }
  }

  initialiseCaseDetail() {
    this.caseDetail = this.selectedCoverages.value.find(
      (coverage: AttachedMedicalCoverage) =>
        coverage.planId === this.planId.value
    );
  }

  //------------------- hasUpdatePriority methods: Updating Visit Details --------------------//
  checkClick($event) {
    event.stopPropagation(); // Prevents bubbling of click event to parent DOM

    if (!this.isSelected.value) {
      const coverageId = this.id.value !== '' ? this.id.value : '-';
      this.selectedCoverages.push(
        this.fb.group(
          createAttachedMedicalCoverage(
            this.medicalCoverageId.value,
            this.planId.value,
            coverageId,
            this.coverageType.value,
            this.planSelected.value.name,
            this.caseDetail ? this.caseDetail.remarks : '',
            this.balance.value,
            this.caseDetail ? this.caseDetail.updated : false,
            this.coverageSelected.value.coveragePlans.some(
              coverage => coverage.allowSelectWithChasPlan === true
            )
          )
        )
      );
      this.updatePlanAndSelectedCoveragesWithRelationsMedisaveStatus();
      this.selectionOrder.patchValue(this.selectedCoverages.controls.length);
    } else {
      this.isSelected.patchValue(false);
      this.removePlan();
      // this.updatePlanAndSelectedCoveragesWithRelationsMedisaveStatus();
    }

    this.patientCoverageItem.updateValueAndValidity();
  }

  subscribeChangesForUpdate() {
    this.isSelected.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        this.isCollapsed = value ? false : true;
      });

    this.selectedCoverages.valueChanges.subscribe(
      (value: AttachedMedicalCoverage[]) => {
        const selectionOrderResult = value.findIndex(
          plan => plan.planId === this.planId.value
        );

        let selectionOrder =
          selectionOrderResult > -1 ? selectionOrderResult + 1 : -1;
        let isSelected = selectionOrderResult > -1;

        this.selectionOrder.patchValue(selectionOrder);
        this.isSelected.patchValue(isSelected);

        this.disableCheckBox();
        this.cdr.markForCheck();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.initialised && changes.isMedicalCoverageSelectionDisabled) {
      this.disableCheckBox();
    }
  }

  subscribeChangesForAdd() {
    merge(
      this.planId.valueChanges,
      this.medicalCoverageId.valueChanges,
      this.patientCoverageId.valueChanges
    ).subscribe(value => {
      this.cdr.markForCheck();
    });
  }

  isPlanSelected() {
    if (this.selectedCoverages) {
      if (
        this.selectedCoverages.value.some(
          coverage => coverage.planId === this.planId.value
        )
      ) {
        this.isSelected.patchValue(true);
      }
    }
  }

  removePlan() {
    if (this.selectedCoverages) {
      const planId = this.planId.value;

      this.selectedCoverages.value.map((coverage, index) => {
        if (coverage.planId === planId) {
          this.selectedCoverages.removeAt(index);
        }
      });
    }
  }

  disableCheckBox() {
    this.cdr.markForCheck();
    if (this.isMedicalCoverageSelectionDisabled) {
      this.isDisabled = true;
      return;
    } else {
      this.isDisabled = false;
    }

    if (this.isCurrentRow() && !this.isMedicalCoverageSelectionDisabled) {
      this.isDisabled = false;
      return;
    }

    if (
      this.policyExcluded.value ||
      this.policyHolderExpired.value ||
      this.policyExpired.value ||
      this.policyInactive.value ||
      this.planInactive.value ||
      this.coverageInactive.value ||
      this.isDuplicate.value ||
      !this.compatibleWithOtherCoverages()
    ) {
      if (this.isSelected.value) {
        this.isSelected.patchValue(false);
        this.removePlan();
      }
      this.isDisabled = true;
      return;
    }

    this.isDisabled = false;

    this.cdr.markForCheck();
  }

  isCurrentRow() {
    const attachedMedicalCoverages: any[] = this.selectedCoverages.value;
    if (
      attachedMedicalCoverages.some(
        coverage =>
          coverage.medicalCoverageId === this.medicalCoverageId.value &&
          coverage.planId === this.planId.value
      )
    ) {
      return true;
    }

    return false;
  }

  compatibleWithOtherCoverages() {
    // Check whether currently sibling coverages are compatible
    // with currently selected coverage
    let isCompatible = true;

    this.selectedCoverages.value.forEach(
      (selectedCoverage: AttachedMedicalCoverage) => {
        // const siblingCoverageType = this.medCovService.getType(
        //   attachedCoverage.medicalCoverageId
        // );

        if (
          !this.isCompatible(
            [this.coverageType.value, selectedCoverage.type],
            this.isThisAllowedWithChas
          ) &&
          !this.isCorporateCompatible(
            this.coverageType.value,
            selectedCoverage.isAllowedWithChas
          )
        ) {
          isCompatible = false;
        }
      }
    );

    return isCompatible;
  }

  isCompatible(coverages: string[], isAllowedWithChas: boolean) {
    const chas = 'CHAS';
    const medisave = 'MEDISAVE';

    return (
      (coverages.includes(chas) && coverages.includes(medisave)) ||
      coverages.every(value => value === medisave) ||
      (coverages.includes(chas) && isAllowedWithChas === true)
    );
  }

  private isCorporateCompatible(
    coverage: string,
    isAllowedWithChas: boolean
  ): boolean {
    return coverage === 'CHAS' && isAllowedWithChas === true ? true : false;
  }

  hasPermission() {
    return (
      this.permissionsService.getPermission('ROLE_RPA_TPA') &&
      this.medicalCoverageService.getMedicalCoverageRpaEnabled(
        this.coverageSelected.value.id
      )
    );
  }

  private getUserId() {
    this.patientInfo$ = this.akitaPatientAppQuery.patientInfo$;
    this.patientInfoSubscription = this.patientInfo$.subscribe(
      res => (this.nric = get(res, 'userId.number'))
    );
  }

  private getPatientId(): void {
    this.patientId = this.akitaPatientStoreService.patientId;
  }
  checkCoverage() {
    this.rpaRequestService.setError('');
    this.isCollapsed = false;

    const code = `${this.coverageSelected.value.code}`;
    const nric = this.nric;

    this.medicalCoverageService
      .getRpaCredentials(
        this.store.getClinicId(),
        this.coverageSelected.value.code
      )
      .subscribe((loginResp: any) => {
        const { username = '', password = '' } = loginResp.payload;

        this.medicalCoverageService
          .createRpa(code, nric, username, password)
          .subscribe((res: any) => {
            this.medicalCoverageService
              .checkRpaStatus(res.id)
              .pipe(timeout(5000), retry(5))
              .subscribe(
                (result: any) => {
                  if (result.result.error === false) {
                    let plan = { ...this.planSelected.value };
                    plan.paymentRemarks = result.result.remarks;

                    if (plan.paymentRemarks) {
                      const tempStoreKey = `${this.patientId}_${plan.id}`;
                      this.tempStoreService
                        .tempStore(tempStoreKey, plan.paymentRemarks)
                        .subscribe(result => {
                          if (result.statusCode === 'S0000') {
                            this.planSelected.patchValue(plan);
                            this.rpaRequestService.close();
                            this.isCollapsed = true;
                          }
                        });
                    }
                  } else {
                    this.rpaRequestService.close();
                    this.rpaRequestService.setError(
                      'Automatic flow has failed, please check the portal manually'
                    );
                    this.isCollapsed = false;
                  }
                },
                error => {}
              );
          });
      });
  }
}