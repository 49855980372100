import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { MedicalAssessment } from '../../objects/state/MedicalAssessment';
import { AkitaDefaultAssessmentStore } from './akita-default-assessment.store';

@Injectable({ providedIn: 'root' })
export class AkitaDefaultAssessmentQuery extends QueryEntity<
  MedicalAssessment
> {
  constructor(protected store: AkitaDefaultAssessmentStore) {
    super(store);
  }
}
