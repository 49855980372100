import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import {
  AkitaChronicConditionStore,
  ChronicConditionState,
} from './akita-chronic-condition.store';
import { ChronicCondition } from '../../objects/state/ChronicCondition';
import { map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CheckboxModel } from '../../views/components/checkbox/checkbox.model';
import { VitalConfiguration } from '../../objects/state/VitalConfiguration';
import { flattenAndGetUnique } from '../../util/array.util';
import MedicalAssessment from '../../objects/response/MedicalAssessment';
import { flatten } from '@angular/compiler';

@Injectable({ providedIn: 'root' })
export class AkitaChronicConditionQuery extends QueryEntity<
ChronicConditionState,
ChronicCondition
> {
  chronicConditionList$ = this.selectAll();

  constructor(protected store: AkitaChronicConditionStore) {
    super(store);
  }

  selectPatientConditions(): Observable<CheckboxModel[]> {
    return this.selectAll().pipe(
      map(conditions =>
        conditions.map(({ name, code }) => ({ name, code, selected: false }))
      )
    );
  }

  getPatientConditions(): CheckboxModel[] {
    return this.getAll().map(({ name, code }) => ({
      name,
      code,
      selected: false,
    }));
  }

  selectComplications() {
    return this.selectAll({
      filterBy: entity => entity.complications && !!entity.complications.length,
    });
  }

  selectActiveChronicConditionsWithComplication(): Observable<Partial<ChronicCondition>[]> {
    //tobe check side effect of removing filter
    // removed last complication was not removed when all the conditions are deselected
    return this.selectActive().pipe(
      // filter(entities => !!entities.length),
      map(entities =>
        entities.
          map(({ code, name, complications }) => ({ code, name, complications }))
      ));
  }

  isComplicationsAvailable(): Observable<boolean> {
    return this.selectActiveChronicConditionsWithComplication().pipe(
      map(entities => !!entities.length)
    );
  }

  selectVitalsForActiveConditions(): Observable<VitalConfiguration[]> {
    //TBD check side effect of removing filter
    //  last vitals was not removed when all the conditions are deselected

    return this.selectActive(entity => entity.vitals).pipe(
      // filter(entities => !!entities.length),
      map(data => flattenAndGetUnique(data, 'code'))
    );
  }

  isVitalsAvailable(): Observable<boolean> {
    return this.selectVitalsForActiveConditions().pipe(
      map(entities => !!entities.length)
    );
  }

  selectAssesmentForActiveCondition(): Observable<MedicalAssessment[]> {
    //TBD: check side effect of removing filter
    // last assessments was not removed when all the conditions are deselected

    return this.selectActive(entity => {
      let assessments = entity.assessments.map((asses) => ({
        ...asses,
        conditionCode: entity.code,
      }));
      
      return assessments;
    }).pipe(
      // filter(entities => !!entities.length),
      map(assesments => assesments)).pipe(
        map(data => flatten(data))
      );
  }
}
