import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryDisplayReturnRequestGst'
})
export class InventoryDisplayReturnRequestGstPipe implements PipeTransform {
  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    let needConversation = args
    return this.inventory.getReturnRequestGST(needConversation ? this.inventory.convertReturnRequestItemFormArrayValueToOrderReturnRequestItemArray(value) : value);
  }

}
