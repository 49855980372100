import { DoctorCalendar } from '../../../../objects/DoctorCalendar';
import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface DoctorCalendarState extends EntityState<DoctorCalendar> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'DoctorCalendar', idKey: 'doctorId' })
export class DoctorCalendarStore extends EntityStore<DoctorCalendarState> {
  constructor() {
    super();
  }
}
