import { AppointmentMasterService } from './../appointment-master/appointment-master.service';
import { APPOINTMENT } from './../../../constants/app.constants';
import { AppointmentService } from './../event-types/appointment/appointment.service';
import { DoctorCalendarService } from './../event-types/doctor-calendar/doctor-calendar.service';
import { ClinicCalendarService } from './../event-types/clinic-calendar/clinic-calendar.service';
import { CalendarAppointment } from './../../../objects/CalendarAppointment';
import { Injectable } from '@angular/core';
import { CalendarAppointmentStore } from './calendar-appointment.store';
import { Subject } from 'rxjs';
import { ServiceCalendarService } from '../event-types/service-calendar/service-calendar.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarAppointmentService {
  docChangeSubject: Subject<String> = new Subject();

  constructor(
    private clinicCalendarService: ClinicCalendarService,
    private doctorCalendarService: DoctorCalendarService,
    private serviceCalendarService: ServiceCalendarService,
    private appointmentService: AppointmentService,
    private calendarAppointmentStore: CalendarAppointmentStore,
    private appointmentMasterService: AppointmentMasterService
  ) {}

  add(calendarAppointment: CalendarAppointment) {
    this.calendarAppointmentStore.add(calendarAppointment);
  }

  upsert(calendarAppointment: CalendarAppointment) {
    this.calendarAppointmentStore.upsert(
      calendarAppointment.id,
      calendarAppointment
    );
  }

  update(calendarAppointment: CalendarAppointment) {
    this.calendarAppointmentStore.update(
      calendarAppointment.id,
      calendarAppointment
    );
  }

  set(calendarAppointment: CalendarAppointment[]) {
    this.calendarAppointmentStore.set(calendarAppointment);
  }

  remove(id?) {
    if (id) {
      this.calendarAppointmentStore.remove(id);
    } else {
      this.calendarAppointmentStore.remove();
    }
  }

  removeDraftAppointments() {
    this.calendarAppointmentStore.remove(
      (event: CalendarAppointment) =>
        event.type === APPOINTMENT && event.appointment.id === ''
    );
  }

  refreshCalendarEvents() {
    this.appointmentMasterService.resetStoreCount();
    this.clinicCalendarService.getClinicCalendar();
    this.doctorCalendarService.getAllsDoctorCalendars();
    this.serviceCalendarService.getServices();
    this.appointmentService.getAppointmentsByClinic();
  }

  isSame(a: CalendarAppointment[], b: CalendarAppointment[]) {
    if (a.length !== b.length) {
      return false;
    }

    if (a === null || b === null) {
      return false;
    }

    for (var i = 0; i < a.length; i++) {
      if (this.isObjectEquivalent(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }

  isObjectEquivalent(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];
      if (a[propName] instanceof Date) {
        if (this.compareDate(a[propName], b[propName])) {
          return false;
        }
      }

      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  }

  compareDate(a: Date, b: Date) {
    return a.getTime() === b.getTime();
  }

  appointmentDocChangeData(docId: string){
    this.docChangeSubject.next(docId);
  }
}
