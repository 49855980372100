import Allergy from './Allergy';
import OnGoingMedication from './OnGoingMedication';
import PatientNewBorn from './PatientNewBorn';
import PrimaryCareNetwork from './PrimaryCareNetwork';
import { Vaccination } from './Vaccination';
import { RelationshipEntity } from './request/RelationshipEntity';

export class UserRegistration {
  address: Address;
  allergies: Array<Allergy>;
  company: Company;
  contactNumber: Contact;
  consentGiven: boolean;
  marketingOptOut: boolean;
  dob: string;
  emailAddress: string;
  // emergencyContactNumber: EmergencyContactNumber;
  relationshipEntities: RelationshipEntity[];
  gender: string;
  maritalStatus: string;
  name: string;
  nationality: string;
  preferredLanguage: string;
  preferredMethodOfCommunication: string;
  primaryCareNetwork?: PrimaryCareNetwork;
  patientVaccinations?: Vaccination[];
  patientPayables?: any;
  patientSources?: any;
  nehrOptedInStatus?: any;
  race: string;
  patientIdentifier: string;
  additionalIdentifier: string;
  status: string;
  title: string;
  userId: UserId;
  kycData:KycData;
  secondaryNumber?: Contact;
  onGoingMedications?: OnGoingMedication[];
  patientNewBorn: PatientNewBorn;
  savedFromAllergyTab: boolean;
  patientExtraDetails: PatientExtraDetails;

  constructor(
    address?: Address,
    allergies?: Array<Allergy>,
    company?: Company,
    contactNumber?: Contact,
    consentGiven?: boolean,
    marketingOptOut?: boolean,
    dob?: string,
    emailAddress?: string,
    // emergencyContactNumber?: EmergencyContactNumber,
    relationshipEntities?: RelationshipEntity[],
    gender?: string,
    maritalStatus?: string,
    name?: string,
    nationality?: string,
    preferredLanguage?: string,
    preferredMethodOfCommunication?: string,
    primaryCareNetwork?: PrimaryCareNetwork,
    patientVaccinations?: Vaccination[],
    patientPayables?: any,
    patientSources?: any,
    nehrOptedInStatus?: any,
    race?: string,
    patientIdentifier?: string,
    additionalIdentifier?: string,
    status?: string,
    secondaryNumber?: Contact,
    title?: string,
    userId?: UserId,
    kycData?: KycData,
    onGoingMedications?: OnGoingMedication[],
    patientNewBorn?: PatientNewBorn,
    savedFromAllergyTab?: boolean,
    patientExtraDetails?: PatientExtraDetails
  ) {
    // this.id = id || '';
    this.title = title || '';
    this.preferredMethodOfCommunication = preferredMethodOfCommunication || '';
    this.name = name || '';
    this.dob = dob || '';
    this.userId = userId === undefined ? new UserId() : userId;
    this.kycData = this.kycData === undefined ? new KycData() : this.kycData;
    this.gender = gender || '';
    this.contactNumber = contactNumber === undefined ? new Contact(65, '') : contactNumber;
    this.status = status || 'ACTIVE';
    this.address = address === undefined ? new Address('', '', '') : address;
    this.emailAddress = emailAddress || '';
    // this.emergencyContactNumber =
    //   emergencyContactNumber === undefined
    //     ? new EmergencyContactNumber('', '', '')
    //     : new EmergencyContactNumber(
    //       // emergencyContactNumber.countryCode || 65,
    //       emergencyContactNumber.number,
    //       emergencyContactNumber.name,
    //       emergencyContactNumber.relationship
    //     );
    this.relationshipEntities =
      !relationshipEntities ? [] : relationshipEntities;
    this.company = company === undefined ? new Company('', '', 0, '') : company;
    this.nationality = nationality || '';
    this.maritalStatus = maritalStatus || '';
    this.race = race || '';
    this.patientIdentifier = patientIdentifier || '';
    this.additionalIdentifier = additionalIdentifier || '';
    this.preferredLanguage = preferredLanguage || '';
    this.allergies = allergies === undefined ? new Array<Allergy>() : allergies;
    this.primaryCareNetwork = primaryCareNetwork === undefined ? new PrimaryCareNetwork() : primaryCareNetwork;
    this.consentGiven = consentGiven || false;
    this.marketingOptOut = marketingOptOut || false;
    this.patientVaccinations = patientVaccinations || new Array<Vaccination>();
    this.patientPayables = patientPayables || null;
    this.patientSources = patientSources || {};
    this.secondaryNumber = secondaryNumber || new Contact();
    this.onGoingMedications = onGoingMedications !== null ? onGoingMedications : [];
    this.patientNewBorn = patientNewBorn === undefined ? new PatientNewBorn() : patientNewBorn;
    this.savedFromAllergyTab = savedFromAllergyTab || false;
    this.patientExtraDetails = patientExtraDetails ? patientExtraDetails : new PatientExtraDetails(); 
  }
}
export class Company {
  name: string;
  address: string;
  postalCode: number;
  occupation: string;
  constructor(name?: string, address?: string, postalCode?: number, occupation?: string) {
    this.name = name || '';
    this.address = address || '';
    this.postalCode = postalCode || 65;
    this.occupation = occupation || '';
  }
}

export class UserId {
  idType: string;
  number: string;
  constructor(idType?: string, number?: string) {
    this.idType = idType || '';
    this.number = number || '';
  }
}
export class KycData {
  kycStatus: string;
  verifiedDate: string;
  constructor(kycStatus?: string, verifiedDate?: string) {
    this.kycStatus = kycStatus || '';
    this.verifiedDate = verifiedDate || '';
  }
}

export class Contact {
  countryCode: number;
  number: string;
  constructor(countryCode?: number, number?: string) {
    this.countryCode = countryCode || 65;
    this.number = number || '';
  }
}

export class EmergencyContactNumber {
  // countryCode: number;
  number: string;
  name: string;
  relationship: string;

  constructor(number?: string, name?: string, relationship?: string) {
    // this.countryCode = countryCode || 65;
    this.number = number || '';
    this.name = name || '';
    this.relationship = relationship || null;
  }
}

export class Address {
  address: string;
  country: string;
  postalCode: string;
  constructor(address?: string, country?: string, postalCode?: string) {
    this.address = address || '';
    this.country = country || '';
    this.postalCode = postalCode || '';
  }
}

export class PatientExtraDetails {
  chasUsage: string;
  smokingStatus: string;
  rightSiting: boolean;
  referenceSource: string;
  remark: string;
  rightSitingReferenceSource: string;
  yearStartOfSmoke: string;
  stateOfChange: any;
  constructor(chasUsage?: string, smokingStatus?: string, rightSiting?: boolean, referenceSource?: string, remark?: string, rightSitingReferenceSource?: string, yearStartOfSmoke?: string, stateOfChange?: any) {
    this.chasUsage = chasUsage || '';
    this.smokingStatus = smokingStatus || null;
    this.rightSiting = rightSiting || false;
    this.referenceSource = referenceSource || '';
    this.remark = remark || '';
    this.rightSitingReferenceSource = rightSitingReferenceSource || '';
    this.yearStartOfSmoke = yearStartOfSmoke || '';
    this.stateOfChange = stateOfChange || null;
  }
}
