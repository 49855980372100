import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { User } from '../../objects/response/User';
import { ApiState } from '../../objects/state/ApiState';
import { Clinic } from '../../objects/state/Clinic';

export interface AkitaAppState extends EntityState<ApiState> {
  clinic: Clinic;
  clinicCode: string;
  clinicId: string;

  currentUser: User;
}

export function createInitialState(): AkitaAppState {
  return {
    clinic: null,
    clinicCode: '',
    clinicId: '',
    currentUser: null,
  } as AkitaAppState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'apistate', idKey: 'name', resettable: true })
export class AkitaAppStore extends EntityStore<AkitaAppState, ApiState> {
  constructor() {
    super(createInitialState());
  }
}
