import { MedicalCoverage } from './../objects/MedicalCoverage';
import { StoreService } from './../services/store.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Plan } from '../objects/Plan';

@Pipe({
  name: 'displayPlanAttribute',
})
export class DisplayPlanAttributePipe implements PipeTransform {
  constructor(private storeService: StoreService) {}

  transform(value: any, args?: any): any {
    const plan: Plan = this.storeService.getPlanByPlanId(value);
    return plan ? plan[args] : 'N/A';
  }
}
