import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PatientHsgService } from '../services/patient-hsg.service';
import { ApiHsgService } from '../../../../../services/api-hsg.service';
import { AlertService } from '../../../../../services/alert.service';
import { LoaderService } from '../../../../../components/loading/loader.service';

import DatePickerConfig from '../../../../../objects/DatePickerConfig';

import HSGPlan, { Goal, HSGGoal, ViewHSGPlanGoal } from '../../../../../model/HSGPlan';

import { DATA_SAVED_SUCCESSFULLY, DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT, INVENTORY_DATE_FORMAT } from '../../../../../constants/app.constants';

import * as moment from 'moment';
import PCNDetails from '../../../../../objects/response/pcn/PCNDetails';
import { AkitaPCNVisitQuery } from '../../../../../services/states/akita-pcn-visit-case.query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PatientHsgPlanDeleteComponent } from '../patient-hsg-plan-delete/patient-hsg-plan-delete.component';
import { PatientHsgFormService } from '../services/patient-hsg-form.service';
import { PcnFormService } from '../../../../../components/pcn/pcn-form.service';
import { PrintTemplateService } from '../../../../../services/print-template.service';
import { StoreService } from '../../../../../services/store.service';
import { VisitManagementService } from '../../../../../services/visit-management.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AkitaCaseVisitQuery } from '../../../../../services/states/akita-case-visit.query';
import { PatientVisitHistoryQuery } from '../../../../../services/states/akita-patient/patient-visit-history/patient-visit-history.query';
import { ApiCmsManagementService } from '../../../../../services/api-cms-management.service';
import { AkitaPatientAppQuery } from '../../../../../services/states/akita-patient/akita-patient-app.query';

@Component({
  selector: 'app-patient-hsg-health-plan',
  templateUrl: './patient-hsg-health-plan.component.html',
  styleUrls: ['./patient-hsg-health-plan.component.scss']
})
export class PatientHsgHealthPlanComponent implements OnInit, OnDestroy {

  @Input() isConsultation = false;
  @Input() isProjectPage = false;
  @Input() showTabs = false;
  public isNewPlan: boolean = false;
  public isEditPlan: boolean = false;
  public showCopyLastGoal: boolean = false;
  public isVisitPlan: boolean = false;

  private isNewPlanSub: Subscription;

  public selectedGoalsPreview: ViewHSGPlanGoal[] | undefined;
  public selectedPlan: HSGPlan | undefined;
  private selectedGoalsPreviewSub : Subscription;
  private selectedPlanSub : Subscription;
  private chronicDiseaseChange: Subscription;
  private goalsFormGroupSub: Subscription;
  private showCopyLastGoalSub: Subscription;
  private planDeletedSub: Subscription;

  public config = {
    title: false
  }

  public newGoals: Goal[] = [];

  public submitCarePlan: {
    id: string;
    visitId: string;
    patientId: string;
    clinicId: string;
    nextCheckUpDate: string;
    recordOfDiscussion: string;
    healthGoals: any[];
    healthPlanStatus: string;
    cmsConditionsForHSG: string[];
  }

  public patientConditions = [
    {name: 'Pre Diabetes', code: 'PRE_DIABETES', goalId: '3'},
    {name: 'Diabetes Mellitus', code: 'DIABETES', goalId: '3'},
    {name: 'Hypertension', code: 'HYPERTENSION', goalId: '4'},
    {name: 'Hyperlipidemia (Lipid Disorders)', code: 'LIPID_DISORDERS', goalId: '5'},
  ]
  public selectedConditions: string[];

  public goalsDropdown;
  public goalsDropdownFiltered;

  public datePickerConfig: DatePickerConfig;
  public datePickerFG = new FormControl('', [Validators.required]);
  public nextVisitDateFG = new FormControl();

  public cmdpDetails: PCNDetails;
  public bsModalRef: BsModalRef;

  public goalsFG$: Observable<FormGroup>;
  public goalsFG: FormGroup = this.fb.group({
    healthGoals: this.fb.array([])
  });

  public hsgForm: FormGroup;
  public selectedGoals: any[];

  public failedErrorMsg: string = '';
  public enableSubGoals: boolean = false;

  public templates: any;
  public isAppend = true;

  constructor(
    private apiHsgService: ApiHsgService,
    private patientHSGService: PatientHsgService,
    private patientHsgFormService: PatientHsgFormService,
    private pcnFormService: PcnFormService,
    private alertService: AlertService,
    private loaderService: LoaderService,
    private printTemplateService: PrintTemplateService,
    private akitaPCNVisitQuery: AkitaPCNVisitQuery,
    private visitManagementService: VisitManagementService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private store: StoreService,
    private permissionsService: NgxPermissionsService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private patientVisitHistoryQuery: PatientVisitHistoryQuery,
    private apiCmsManagementService: ApiCmsManagementService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
  ) { }

  ngOnInit() {
    this.templates = this.store.getTemplatesByTemplateCategory('HSG_ROD');
    this.pcnFormService.initPCNForm();
    this.hsgForm = this.patientHsgFormService.hsgForm;
    this.goalsFG$ = this.patientHsgFormService.goalFormGroup$;
    
    this.goalsFormGroupSub = this.goalsFG$.subscribe({
      next: goalsFG => {
        this.goalsFG = goalsFG;
        
        if (this.newGoals.length === 0 && goalsFG.value && goalsFG.value.healthGoals && goalsFG.value.healthGoals.length > 0) {
          this.newGoals = goalsFG.value.healthGoals;
          this.patientHsgFormService.originalGoalValues = this.newGoals.map(goal => [...goal.values]);
          this.patientHsgFormService.updatedGoalValues = this.newGoals.map(goal => [...goal.values]);
          
          this.selectedGoals = goalsFG.value.healthGoals;
          if (!this.datePickerFG.value) {
            this.updateTargetDateValues();
          }

          if (this.hsgForm && this.hsgForm.get('id').value) {
            this.isEditPlan = true;
          } else {
            this.isNewPlan =  true;
          }
          this.patientHSGService.setEnableConditions(true);
        }
      }
    });
    
    this.nextVisitDateFG = new FormControl(new Date());
    this.submitCarePlan = {
      id: '',
      visitId: '',
      patientId: '',
      clinicId: '',
      nextCheckUpDate: '',
      recordOfDiscussion: '',
      healthGoals: [],
      healthPlanStatus: '',
      cmsConditionsForHSG: [],
    }

    this.selectedGoalsPreviewSub = this.patientHSGService.getSelectedPlanForPreview().subscribe({
      next: result => {        
        this.selectedGoalsPreview = result;
        this.isNewPlan = false;
        this.isEditPlan = false;
      }
    });

    this.selectedPlanSub = this.patientHSGService.getSelectedPlan().subscribe({
      next: result => {
        this.selectedPlan = result;
        this.selectedConditions = this.selectedPlan.cmsConditionsForHSG || [];
        this.patientHSGService.setSelectedConditions(this.selectedConditions ? this.selectedConditions : []);
        if (this.selectedPlan.healthPlanStatus !== 'SUBMIT') {
          // this.patientHSGService.setEnableConditions(true);
          this.datePickerFG.reset();
          this.patientHsgFormService.patchHsgForm(this.selectedPlan);
          this.editPlan(this.selectedPlan);
          this.hsgForm = this.patientHsgFormService.hsgForm;
        } else {
          this.patientHSGService.setEnableConditions(false);
        }
        if (this.selectedPlan.healthPlanStatus === 'FAILED') {
          this.failedErrorMsg = this.selectedPlan.webserviceResponse ? this.selectedPlan.webserviceResponse.responseStatusDescr : 'Plan submission failed';
        } else {
          this.failedErrorMsg = '';
        }
      }
    });

    this.alertService
      .getSelectPatientFromQueue()
      .subscribe(res => {
        if (res) {
          this.afterPlanDeleted(res.id);
        }
      });

    this.isNewPlanSub = this.patientHSGService.getIsNewPlan().subscribe({
      next: result => {
        this.isNewPlan = result;
        
        if(this.isNewPlan) {
          this.newGoals = [];
          this.selectedGoals = [];
          this.datePickerFG.reset();
          const selectedPcnConditions = this.pcnFormService.getSelectedPcnConditions();
          this.selectedConditions = this.getPcnConditionCodes(selectedPcnConditions);
          this.reloadGoalDropdownOptions();
          
          this.patientHSGService.setSelectedConditions(this.selectedConditions);
          this.onAllApplicableGoals();
          this.updateConditionForm();

          const newPlan: HSGPlan = new HSGPlan();
          newPlan.clinicId = this.store.getClinicId();
          newPlan.healthPlanStatus = 'NEW';
          newPlan.healthGoals = [];
          for (let newGoal of this.newGoals) {
            newPlan.healthGoals.push(new HSGGoal(newGoal.id, newGoal.workflow, newGoal.values));
          }
          newPlan.cmsConditionsForHSG = this.selectedConditions;
          newPlan.nextCheckUpDate = moment().add(6, 'months').format(DISPLAY_DATE_FORMAT);
          newPlan.selected = true;
          this.selectedPlan = newPlan;

          this.patientHSGService.setNewPlan(newPlan);
        }
        this.hsgForm = this.patientHsgFormService.hsgForm;
      }
    });

    this.chronicDiseaseChange = this.patientHSGService.getChronicDiseaseChange().subscribe({
      next: result => {
        this.selectedConditions = result;
        this.updateConditionForm();
        this.reloadGoalDropdownOptions();
        this.updateChronicConditionGoals();

        if (this.selectedPlan && this.selectedPlan.healthPlanStatus === 'NEW') {
          this.selectedPlan.cmsConditionsForHSG = this.selectedConditions;
          this.selectedPlan.healthGoals = [];
          for (let newGoal of this.newGoals) {
            this.selectedPlan.healthGoals.push(new HSGGoal(newGoal.id, newGoal.workflow, newGoal.values));
          }
          this.patientHSGService.setNewPlan(this.selectedPlan);
        }
      }
    });

    this.showCopyLastGoalSub = this.patientHSGService.getsShowCopyLastGoal().subscribe({
      next: result => this.showCopyLastGoal = result
    });

    this.planDeletedSub = this.patientHSGService.getPlanDeleted().subscribe({
      next: result => {
        this.afterPlanDeleted();
      }
    });

    this.datePickerConfig = new DatePickerConfig(
      '',
      null,
      new Date(),
      '',
      ''
    );

    this.goalsDropdown = this.patientHSGService.getGoalsDropdown();
    this.goalsDropdownFiltered = this.goalsDropdown;
    this.cmdpDetails = this.akitaPCNVisitQuery.getPCNInfo();
  }

  ngAfterViewChecked() {
    this.populateEnableSubGoal();
  }

  public getPcnConditionCodes(pcnConditions: any[]): string[] {
    return this.patientConditions.filter(con => pcnConditions.map(pcnCon => pcnCon.code).includes(con.code)).map(con => con.code);
  }

  private updateTargetDateValues() {
    this.newGoals.forEach(goal => {
      goal.target.forEach(target => {
        const value = goal.values.find(val => val.placeholder === target.placeholder);
        if (value) {
          target.value = value ? value.value : '';
          if (target.type === 'date-picker') {
            this.datePickerFG.setValue(value.value.includes('T') ? moment(value.value).toDate() : moment(value.value, DISPLAY_DATE_FORMAT).toDate());
          }
        }
      });
      if (goal.subTarget) {
        goal.subTarget.forEach(target => {
          const value = goal.values.find(val => val.placeholder === target.placeholder);
          if (value) {
            target.value = value ? value.value : '';
          }
        })
      }
    })
  }

  private populateEnableSubGoal() {
    this.newGoals.forEach(goal => {
      if (goal.subTarget) {
        goal.subTarget.forEach(target => {
          const value = goal.values.find(val => val.placeholder === target.placeholder);
          if (value && value !== '') {
            goal.enableSubGoals = true;
          }
        })
      }
    })
  }

  public onSelectGoal(event: any, goalIndex: number): void {
    const target = this.patientHSGService.getTarget(event);
    if (event.workflow.subWorkflow) {
      const subTarget = this.patientHSGService.getSubTarget(event);
      this.newGoals[goalIndex].subTarget = subTarget;
    }
    this.newGoals[goalIndex].target = target;
    this.newGoals[goalIndex].values = [];
    this.newGoals[goalIndex].workflow = event.workflow;
    this.updateGoalsForm();
    this.reloadGoalDropdownOptions();
  }

  private reloadGoalDropdownOptions() {
    this.goalsDropdownFiltered = this.goalsDropdown.filter(dropdown => !this.newGoals.filter(newGoal => newGoal.workflow)
                                                                                      .map(newGoal => newGoal.workflow.flow)
                                                                                      .includes(dropdown.value));
    let idsToRemove = this.patientConditions.map(con => con.goalId);
    if (this.selectedConditions && this.selectedConditions.length > 0) {
      let selectedGoalIds = this.patientConditions.filter(con => this.selectedConditions.includes(con.code))
                                                  .map(con => con.goalId);
      idsToRemove = this.patientConditions.filter(con => !selectedGoalIds.includes(con.goalId))
                                            .map(con => con.goalId);
    } else {
      const pcnConditions = this.pcnFormService.getSelectedPcnConditions();
      if (pcnConditions && pcnConditions.length > 0) {
        let selectedGoalIds = this.patientConditions.filter(con => pcnConditions.map(condition => condition.code).includes(con.code))
                                                  .map(con => con.goalId);
        idsToRemove = this.patientConditions.filter(con => !selectedGoalIds.includes(con.goalId))
                                            .map(con => con.goalId);
      }
    }
    
    this.goalsDropdownFiltered = this.goalsDropdownFiltered.filter(goal => !idsToRemove.includes(goal.value));
  }

  public onAddNew(): void {
    this.newGoals.push({
      id: (this.newGoals.length + 1).toString(),
      goal: {},
      target: [],
      subTarget: [],
      enableSubGoals: false,
      values: [],
      workflow: {},
    });
  }

  public onAllApplicableGoals(): void {
    this.goalsDropdownFiltered.forEach((goal, index) => {
      this.onAddNew();
      this.newGoals[index].goal = goal;
      this.onSelectGoal(goal, index);
    })
    this.updateGoalsForm();
  }

  public updateChronicConditionGoals(): void {
    this.goalsDropdownFiltered.forEach((goal, index) => {
      const patientCondition = this.patientConditions.find(pCon => pCon.goalId === goal.workflow.flow)
      if (patientCondition) {
        if (!this.newGoals.find(newGoal => newGoal.workflow.flow === patientCondition.goalId)) {
          this.onAddNew();
          this.newGoals[this.newGoals.length - 1].goal = goal;
          this.onSelectGoal(goal, this.newGoals.length - 1);
        }
      }
    });
    let selectedGoalIds = this.patientConditions.filter(con => this.selectedConditions.includes(con.code))
                                                  .map(con => con.goalId);
    const excludeGoals = this.patientConditions.filter(con => !selectedGoalIds.includes(con.goalId));
    this.newGoals = this.newGoals.filter(goal => !excludeGoals.find(eGoal => eGoal.goalId === goal.workflow.flow));
    this.updateGoalsForm();
  }

  private updateGoalsForm() {
    this.patientHsgFormService.patchGoalsForms(this.newGoals);
    this.patientHsgFormService.updatedGoalValues = this.newGoals.map(goal => [...goal.values]);
  }

  private updateConditionForm() {
    this.patientHsgFormService.patchConditionsForms(this.selectedConditions ? this.selectedConditions : []);
  }

  public onCancel(): void {
    this.isNewPlan = false;
    this.patientHSGService.setEnableConditions(false);
    this.patientHSGService.setGoalsOfSelectedPlan(undefined);
    this.newGoals = [];
    this.patientHsgFormService.resetHsg();
    this.patientHSGService.setIsAllPlanSubmitted(true);
  }

  public onDeletePlan(): void {
    const initialState = {
      title: 'Delete Plan',
    };
    this.bsModalRef = this.modalService.show(PatientHsgPlanDeleteComponent, {
      initialState,
      class: 'modal-sm',
      keyboard: false,
      backdrop: 'static',
    });
    this.bsModalRef.content.event.subscribe(data => {
      this.bsModalRef.hide();
      this.apiHsgService.deletePlan(this.selectedPlan.id).subscribe(data => {
        if (data) {
          this.afterPlanDeleted(this.selectedPlan.patientId);
          this.alertService.success(DATA_SAVED_SUCCESSFULLY);
        }
      }, 
      err => {
        this.loaderService.setLoading(false);
        this.alertService.error(JSON.stringify(err))
      });
    });
  }

  private afterPlanDeleted(patientId?: string): void {
    this.isEditPlan = false;
    this.isNewPlan = false;
    this.selectedGoalsPreview = [];
    this.newGoals = [];
    this.patientHsgFormService.resetHsg();
    this.patientHSGService.setEnableConditions(false);
    this.patientHSGService.setNewPlanAdded({patientId: patientId, newPlanAdded: true});
  }

  public onDelete(index: number): void {
    this.newGoals.splice(index, 1);
    this.reloadGoalDropdownOptions();
    this.updateGoalsForm();
  }

  public onInput(event: any, index: number, placeholder: string): void {
    const selectedGoal = this.newGoals[index];

    const value = selectedGoal.values.find(val => val.placeholder === placeholder);
    if (value) {
      selectedGoal.values.splice(selectedGoal.values.indexOf(value), 1);
    }
    selectedGoal.values.push({placeholder: placeholder, value: event.target.value});
    this.updateGoalsForm();
  }

  public onSelectInputDropdown(event: any, index: number, placeholder: string): void {
    const selectedGoal = this.newGoals[index];
    
    const value = selectedGoal.values.find(val => val.placeholder === placeholder);
    if (value) {
      selectedGoal.values.splice(selectedGoal.values.indexOf(value), 1);
    }
    selectedGoal.values.push({ placeholder: placeholder, value: event ? event.key || event.value || event : null });

    if(event && !event.key) {
      const newData = {key: event, value: parseInt(event)};

      const currentTarget = selectedGoal.target.find(val => val.placeholder === placeholder);

      if(currentTarget) {
        if(!currentTarget.data.some(val => val.key === newData.value)) {
          let currentTargetData = [...currentTarget.data];
          currentTargetData.push(newData);
          const currentWorkflow = selectedGoal.workflow.sections.find(val => val.placeholder === placeholder);
          currentWorkflow.data = currentTargetData;
        }
      }
    }

    this.updateGoalsForm();
  }

  public addTag(tag: string): any {
    return tag;
  }

  public getDatepickerChangedValue(event: {index: number; value: string}, placeholder: string): void {
    const selectedGoal = this.newGoals[event.index];
    
    const value = selectedGoal.values.find(val => val.placeholder === placeholder);
    if (value) {
      selectedGoal.values.splice(selectedGoal.values.indexOf(value), 1);
    }
    selectedGoal.values.push({placeholder: placeholder, value: moment(event.value).format(DISPLAY_DATE_FORMAT)});
    this.updateGoalsForm();
  }

  public onCheck(event, checkbox, index): void {
    const selectedGoal = this.newGoals[index];

    const value = selectedGoal.values.find(val => val.placeholder === checkbox.key);

    if (value) {
      selectedGoal.values.splice(selectedGoal.values.indexOf(value), 1);
    }

    if (event.target.checked) {
      let value = {};

      if(checkbox.captureContext) {
        value = {  
          placeholder: checkbox.key, 
          captureContext: checkbox.captureContext,
          value: '',
        };
        checkbox.showInput = true;
        checkbox.inputValue = '';
      } else {
        value = {  
          placeholder: checkbox.key, 
          value: checkbox.value
        };
      }

      selectedGoal.values.push(value);
    } else {
      if(checkbox.captureContext) {
        checkbox.showInput = false;
      }
    }

    this.updateGoalsForm();
  }

  public onEnableSubGoalsChange(event, index): void {
    const selectedGoal = this.newGoals[index];
    selectedGoal.enableSubGoals = event.target.checked;

    if (!event.target.checked) {
      selectedGoal.subTarget.forEach(target => {
        const value = selectedGoal.values.find(val => val.placeholder === target.placeholder);
        if (value) {
          selectedGoal.values.splice(selectedGoal.values.indexOf(value), 1);
          target.value = '';
        }
      })
    }

    this.updateGoalsForm();
  }

  public onEnterSpecifyOther(event, checkbox, index): void {
    const selectedGoal = this.newGoals[index];

    const value = selectedGoal.values.find(val => val.placeholder === checkbox.key);

    if (value) {
      selectedGoal.values.splice(selectedGoal.values.indexOf(value), 1);
    }

    selectedGoal.values.push({placeholder: checkbox.key, value: checkbox.inputValue});
    this.updateGoalsForm();
  }

  public onConditionCheck(event, condition): void {
    console.log(event + ' ' + condition);
  }

  public isCheckboxChecked(checkbox, index): boolean {
    const selectedGoal = this.newGoals[index];
    const value = selectedGoal.values.find(val => val.placeholder === checkbox.key);
    return value !== null && value !== undefined;
  }

  public isConditionChecked(condition): boolean {
    if (this.cmdpDetails && this.cmdpDetails.conditions) {
      return this.cmdpDetails.conditions.filter(con => con.code === condition.code).length > 0;
    }
    return false;
  }

  public onPrint() {
    this.printTemplateService.printHsgPlan(this.selectedPlan.id);
  }

  public onReSubmit(): void {
    this.apiHsgService.submitPlan(this.patientHsgFormService.getHsgPlanForConsultation()).subscribe(data => {
      if (data.payload) {
        const plan = data.payload;
        if (plan.healthPlanStatus === 'DRAFT' || plan.responseStatus === 'SUCCESS') {
          this.isEditPlan = false;
          this.isNewPlan = false;
          this.selectedGoalsPreview = [];
          this.alertService.success(DATA_SAVED_SUCCESSFULLY);
        } else {
          this.isEditPlan = true;
          this.submitCarePlan.id = plan.id;
          this.alertService.error(plan.webserviceResponse ? 
            plan.webserviceResponse.submissionStatusDescr : "HSG Health plan submission failed.");
        }
        this.patientHSGService.setPlanSubmitted(true);
      }
      
      this.loaderService.setLoading(false);
    }, err => {
      this.loaderService.setLoading(false);
      this.alertService.error(JSON.stringify(err))
    });
  }

  private copyFieldsForSubmit(plan: HSGPlan) {
    this.submitCarePlan.id = plan.id;
    this.submitCarePlan.visitId = plan.visitId;
    this.submitCarePlan.clinicId = plan.clinicId;
    this.submitCarePlan.patientId = plan.patientId;
  }

  public editPlan(plan: HSGPlan) {
    this.isEditPlan = true;
    this.isNewPlan = false;
    this.isVisitPlan = this.isConsultation && this.akitaCaseVisitQuery.getActiveVisitId() === plan.visitId;
    this.copylastGoals(plan);
    this.submitCarePlan.recordOfDiscussion = plan.recordOfDiscussion;
    
    this.copyFieldsForSubmit(plan);
    this.updateTargetDateValues();
  }

  public copylastGoals(selectedPlan?: HSGPlan) {
    const plan = selectedPlan ? selectedPlan : this.patientHSGService.getLatestPlan();
    
    this.newGoals = [];
    plan.healthGoals.forEach((goal, index) => {
      this.onAddNew();
      const dropdownOption = this.goalsDropdown.find(gd => gd.value === goal.workflow.flow);
      this.onSelectGoal(dropdownOption, index);
      this.newGoals[index].goal = dropdownOption;
      this.newGoals[index].values = goal.values ? goal.values : [];
    });

    if (this.hsgForm) {
      if (plan.nextCheckUpDate) {
        this.hsgForm.get('nextCheckUpDate').patchValue(moment(plan.nextCheckUpDate, INVENTORY_DATE_FORMAT).toDate());
      }
      this.hsgForm.get('recordOfDiscussion').patchValue(plan.recordOfDiscussion);
    }

    this.populateEnableSubGoal();
    this.updateTargetDateValues();
    this.reloadGoalDropdownOptions();
    this.updateGoalsForm();
  }

  onTemplateChange(event) {
    this.hsgForm.get('recordOfDiscussion').markAsDirty();
    if (event) {
      const rod = this.hsgForm.get('recordOfDiscussion');
      let visitId = '';
      if (this.akitaCaseVisitQuery.getActiveId())
        visitId = String(this.akitaCaseVisitQuery.getActiveId());
      else if (this.patientVisitHistoryQuery.getActiveId())
        visitId = String(this.patientVisitHistoryQuery.getActiveId());
      else visitId = this.store.getPatientVisitRegistryId();

      this.apiCmsManagementService
        .loadTemplateWithVisitId(
          event.type,
          event.id,
          this.store.getUser().context['cms-user-id'],
          this.akitaPatientAppQuery.getId(),
          visitId
        )
        .subscribe(
          res => {
            if (res.payload) {
              if (this.isAppend) {
                const data = rod.value ? rod.value : '';
                rod.patchValue(data ? data + '<br>' + res.payload : res.payload);
              } else {
                rod.patchValue(res.payload);
              }
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err));
            console.error(JSON.stringify(err));
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.selectedGoalsPreviewSub.unsubscribe();
    this.isNewPlanSub.unsubscribe();
    this.selectedPlanSub.unsubscribe();
    this.chronicDiseaseChange.unsubscribe();
    this.goalsFormGroupSub.unsubscribe();
    this.showCopyLastGoalSub.unsubscribe();
    this.planDeletedSub.unsubscribe();
  }
}