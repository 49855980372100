import PCNDetails, {
  PCNPatientExtraDetails,
  AssessmentResult,
} from './../../../objects/response/pcn/PCNDetails';
import { Injectable } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import {
  BehaviorSubject,
  config,
  Subject,
  Observable,
  of,
  combineLatest,
} from 'rxjs';

import MedicalAssessment from '../../../objects/response/MedicalAssessment';

import PrimayCareChronicConditions from '../../../objects/response/PrimayCareChronicConditions';
import { VitalConfiguration } from '../../../objects/response/VitalConfiguration';
import { VitalResponseData } from './../../../objects/response/VitalResponseData';
import { StoreService } from '../../../services/store.service';
import MedicalConditions from '../../../objects/response/MedicalConditions';
import { AkitaChronicConditionQuery } from '../../../services/states/akita-chronic-condition.query';
import { AkitaNgFormsManager } from '@datorama/akita-ng-forms-manager';
import { CheckboxModel } from '../../../views/components/checkbox/checkbox.model';
import { arrayUpdate } from '@datorama/akita';
import { AkitaChronicConditionStore } from '../../../services/states/akita-chronic-condition.store';
import { AkitaPCNVisitQuery } from '../../../services/states/akita-pcn-visit-case.query';
import { switchMap, map, mergeMap, tap, take } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Injectable({
  providedIn: 'root',
})
export class PatientConditionsService {
  patientConditionFG: FormGroup;
  conditionsCheckboxFG: FormGroup;
  conditions: PrimayCareChronicConditions[];

  selectedConditions: Array<PrimayCareChronicConditions>;
  selectedVitals: Array<VitalConfiguration>;
  selectedAssessments: Array<MedicalAssessment>;

  selectedVitalsChanged: BehaviorSubject<Array<VitalConfiguration>>;
  selectedAssessmentsChanged: BehaviorSubject<Array<MedicalAssessment>>;

  patientId: string;
  visitId: string;
  visitStartTime: string;

  pcnDetails: PCNDetails;

  pcnRequiredSubject: Subject<boolean> = new BehaviorSubject<boolean>(true);
  pcnRequired$ = this.pcnRequiredSubject
    .asObservable()
    .pipe(tap(val => (this.pcnRequired = val)));
  pcnRequired = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: StoreService,
    private formsManager: AkitaNgFormsManager<any>,
    private akitaChronicConditionQuery: AkitaChronicConditionQuery,
    private akitaChronicConditionStore: AkitaChronicConditionStore,
    private akitaPCNVisitQuery: AkitaPCNVisitQuery
  ) {
    this.selectedConditions = new Array<PrimayCareChronicConditions>();
    this.selectedVitals = new Array<VitalConfiguration>();
    this.selectedAssessments = new Array<MedicalAssessment>();

    this.selectedVitalsChanged = new BehaviorSubject<Array<VitalConfiguration>>(
      this.selectedVitals
    );
    this.selectedAssessmentsChanged = new BehaviorSubject<
      Array<MedicalAssessment>
    >(this.selectedAssessments);

    this.patientId = '';
    this.visitId = '';
    this.visitStartTime = null;
  }

  
  toggleActiveCondition(code: string[]) {
    this.akitaChronicConditionStore.setActive(code);
  }

}
