export class UserLogin {
  userName: string;
  password: string;
  tenantId: string;

  constructor(userName: string, password: string, tenantId: string) {
    this.userName = userName;
    this.password = password;
    this.tenantId = tenantId;
  }
}
