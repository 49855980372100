export interface SFLHistoryList {
    visitId: string;
    id: string;
    testType: string;
    followUpDate: string;
    screeningType: string;
    testOrder: string;
    screeningOutcome: string;
    followUpOutcome: string;
    followUpType: string;
    screenDate: string;
    conditionCodes: Array<any>;
}



export function createSFL({
    visitId = '',
    id = '',
    testType = '',
    followUpDate = '',
    screeningType = '',
    testOrder = '',
    screeningOutcome = '',
    followUpOutcome = '',
    followUpType = '',
    screenDate = '',
    conditionCodes = new Array<any>(),
  } = {}) {
    return {
        visitId,
        id,
        testType,
        followUpDate,
        screeningType,
        testOrder,
        screeningOutcome,
        followUpOutcome,
        followUpType,
        screenDate,
        conditionCodes,
    } as SFLHistoryList;
  }