<div *ngIf="patientInfo">
  <div class="card-header row p-2">
    <div [ngClass]="{'col-7': patientInfo.followUp, 'col-8': !patientInfo.followUp}">
      <h5 class="mb-0">
        <a [title]="patientInfo.patientName" href="" class="font-weight-semi-bold"
          (click)='redirectToPatientDetail(patientInfo.patientId)'>
          <!-- {{ textTruncate(row.name)}} -->
          {{ patientInfo.patientName || '' }}
        </a>
      </h5>

    </div>
    <div [ngClass]="{'col-5': patientInfo.followUp, 'col-4': !patientInfo.followUp, 'pull-right': true}">
      <div class="row">
        <div class="col-md-12">
          <i
            class="fa fa-clock-o fa-lg"
            aria-hidden="true"
            *ngIf="patientInfo.followUp"
            [style.color]="calendarEvent.color.primary">
          </i>
          <span *ngIf="isArrived()" class="badge badge-success pull-right">ARRIVED</span>
          <span *ngIf="isConfirm()" class="badge badge-primary pull-right">CONFIRM</span>
          <span *ngIf="isPending()" class="badge badge-grey-600 text-white pull-right">PENDING</span>
        </div>
        <!-- <div *ngIf="!isArrived()" class="col-md-7">
          <button type="button" class="close pull-right" (click)="deleteItem()">
            <i class="icon-trash"></i>
          </button>
          <button type="button" class="close pull-right">
            <i class="icon-pencil" (click)="onEditClicked()"></i>
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-5 black">
        {{ patientInfo.userId.number || '' }}
      </div>
      <div class="col-3 black">
        {{ patientInfo.gender || '' }}
      </div>
      <div class="col-4 pull-right black">
        {{ patientInfo.dob || '' }}
      </div>
    </div>
    <div class="d-flex mt-2">
      <div class="p-0 flex-grow-1 bd-highlight">
        <span class="icon-phone mr-1"
          style="font-size:15px;color:black"></span><span class="black">{{ patientInfo.contactNumber.number || 'N/A' }}</span>
      </div>
      <div class="p-0 bd-highlight black">{{ getFormattedAge() }}</div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <span class="icon-at mr-1" style="font-size:15px;color:black"></span><span class="black">{{ patientInfo.emailAddress || 'N/A' }}</span>
      </div>
    </div>
    <div class="row my-2 py-2 appt-detail font-weight-semi-bold">
      <div class="col-12 black">
        {{ calendarEvent.appointment.visitDate | displayHour }} for {{ calendarEvent.appointment.duration }} minutes
        <br>{{ calendarEvent.appointment.preferredDoctor | displayDocDisplayName }}
        <!-- <br>
        <br>Reminder at {{ calendarEvent.appointment.reminderDate | displayHour }} -->
      </div>
    </div>

    <div class="row">
      <div class="col-12 form-group">
        <label class="mb-0">Purpose of Visit</label>
        <div class="black">{{ calendarEvent.appointment.purposeOfVisit }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <label>Remarks</label>
        <div class="black" [innerHTML]="calendarEvent.appointment.remarks | displayLinebreakPipe"></div>
      </div>
    </div>
    <div *ngIf="!isArrived()" class="row align-items-center">
      <div class="col-md-5">
        <button type="button" class="close float-left">
          <i class="icon-pencil" (click)="onEditClicked()"></i>
        </button>
        <button type="button" class="close float-left" (click)="deleteItem()">
          <i class="icon-trash"></i>
        </button>
      </div>
      <div *ngIf="!isExternal && !calendarEvent.appointment.followUp" class="col-md-7">
        <button *ngxPermissionsOnly="['ROLE_PATIENT_VISIT_MANAGE']" class="btn btn-sm btn-brand-dark m-2 float-right"
          (click)="onAddToQueue()">
          + Add to Registry
        </button>
      </div>
    </div>
  </div>
</div>
