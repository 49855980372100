import { DISPLAY_DATE_FORMAT, INVENTORY_DATE_FORMAT } from './../constants/app.constants';
import * as moment from 'moment';
import { VaccinationRecords } from './VaccinationRecords';

export interface Plan {
  id?: string;
  name?: string;
  capPerVisit?: CapPerVisit;
  capPerDay?: CapPerVisit;
  capPerWeek?: CapPerVisit;
  capPerMonth?: CapPerVisit;
  capPerYear?: CapPerVisit;
  capPerLifeTime?: CapPerVisit;
  copayment?: Copayment;
  limitResetType?: string;
  code?: string;
  startDate?: string;
  endDate?: string;
  hsgFirstVisitStatus?: any;
  remarks?: string;
  clinicRemarks?: string;
  registrationRemarks?: string;
  paymentRemarks?: string;
  excludedClinics?: any[];
  excludeAllByDefault?: boolean;
  includedMedicalServiceSchemes?: any[];
  excludedMedicalServiceSchemes?: any[];
  minimumNumberOfDiagnosisCodes: number;
  maximumNumberOfDiagnosisCodes: number;
  externalPlanType?: string,
  allowedRelationship?: any[];
  salesDiscount?: Discount;
  website?: string;
  status?: string;
  vaccinationStatusList?: VaccinationStatus[];
  vaccinationPlan? : boolean;
  cdsPlan? : boolean;
  cdsQueryStatus? : CDSQueryStatus;
  dentalPlan? : boolean;
  dentalStatus? : DentalStatus;
  medicalChit? : boolean;
  medicalChitStatusList?: MedicalChitStatus[];
  medisavePlan?: boolean;
  medisaveStatus?: MedisaveStatus;
  payerUserId?: {
    idType: string;
    number: string;
  },
  insuranceItemEligibility: InsuranceItemEligibility;
}

export function createPlan(cov?: Plan) {
  return <Plan>{
    id: cov ? cov.id : '',
    name: cov ? cov.name : '',
    capPerVisit: cov ? cov.capPerVisit : createCapPerVisit(),
    capPerDay: cov ? cov.capPerDay : createCapPerVisit(),
    capPerWeek: cov ? cov.capPerWeek : createCapPerVisit(),
    capPerMonth: cov ? cov.capPerMonth : createCapPerVisit(),
    capPerYear: cov ? cov.capPerYear : createCapPerVisit(),
    capPerLifeTime: cov ? cov.capPerLifeTime : createCapPerVisit(),
    copayment: cov ? cov.copayment : createCopayment(),
    limitResetType: cov ? cov.limitResetType : '',
    code: cov ? cov.code : '',
    startDate: cov ? this.formatDate(cov.startDate) : '',
    endDate: cov ? this.formatDate(cov.endDate) : '',
    hsgFirstVisitStatus: cov && cov.hsgFirstVisitStatus ? cov.hsgFirstVisitStatus : undefined,
    remarks: cov ? cov.remarks : '',
    clinicRemarks: cov ? cov.clinicRemarks : '',
    registrationRemarks: cov ? cov.registrationRemarks : '',
    paymentRemarks: cov ? cov.paymentRemarks : '',
    excludedClinics: cov ? cov.excludedClinics : new Array(),
    excludeAllByDefault: cov ? cov.excludeAllByDefault : false,
    includedMedicalServiceSchemes: cov ? cov.includedMedicalServiceSchemes : new Array(),
    excludedMedicalServiceSchemes: cov ? cov.excludedMedicalServiceSchemes : new Array(),
    minimumNumberOfDiagnosisCodes: cov ? cov.minimumNumberOfDiagnosisCodes : 0,
    maximumNumberOfDiagnosisCodes: cov ? cov.maximumNumberOfDiagnosisCodes : 0,
    allowedRelationship: cov ? cov.allowedRelationship : new Array(),
    salesDiscount: cov
      ? cov.salesDiscount
        ? this.createDiscount(cov.salesDiscount.discountType, cov.salesDiscount.discountValue, name)
        : null
      : null,
    website: cov ? cov.website : '',
    status: cov ? cov.status : 'ACTIVE',
    vaccinationStatusList: cov ? cov.vaccinationStatusList : false,
    vaccinationPlan : cov ? cov.vaccinationPlan : new Array<VaccinationStatus>(),
    cdsPlan : cov ? cov.cdsPlan : false,
    cdsQueryStatus : cov ? cov.cdsQueryStatus : new Array<CDSQueryStatus>(),
    dentalPlan: cov ? cov.dentalPlan : false,
    dentalStatus: cov ? cov.dentalStatus : createDentalStatus(),
    medicalChit : cov ? cov.medicalChit : false,
    medicalChitStatusList : cov ? cov.medicalChitStatusList : new Array<MedicalChitStatus>(),
    medisavePlan: cov ? cov.medisavePlan : false,
    medisaveStatus: cov ? cov.medisaveStatus : createMedisaveStatus(),
    payerUserId: cov? cov.payerUserId : undefined,
    insuranceItemEligibility: cov ? cov.insuranceItemEligibility : undefined,
  };
}

export function formatDate(date) {
  if (moment(date, INVENTORY_DATE_FORMAT, true).isValid()) {
    return moment(date, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
  } else if (moment(date, DISPLAY_DATE_FORMAT, true).isValid()) {
    return date;
  }
}

export interface Copayment {
  value?: number;
  paymentType?: string;
}

export interface CapPerVisit {
  visits?: number;
  limit?: number;
}

export interface ContactPerson {
  name: string;
  title: string;
  directNumber: string;
  mobileNumber: string;
  faxNumber: string;
  email: string;
}

export function createContactPerson(
  name?: string,
  title?: string,
  directNumber?: string,
  mobileNumber?: string,
  faxNumber?: string,
  email?: string
): ContactPerson {
  return <ContactPerson>{
    name: name || '',
    title: title || '',
    directNumber: directNumber || '',
    mobileNumber: mobileNumber || '',
    faxNumber: faxNumber || '',
    email: email || '',
  };
}

export function createCopayment(value?: number, paymentType?: string): Copayment {
  return <Copayment>{
    value: value || 0,
    paymentType: paymentType || '',
  };
}

export function createCapPerVisit(visits?: number, limit?: number): CapPerVisit {
  return <CapPerVisit>{
    visits: visits || 0,
    limit: limit || 0,
  };
}

export interface Discount {
  discountType?: string;
  discountValue?: number;
  planName?: string;
}

export function createDiscount(discountType?: string, discountValue?: number, planName?: string): Discount {
  return <Discount>{
    discountType: discountType ? discountType : '',
    discountValue: discountValue ? discountValue : 0,
    planName: planName ? planName : '',
  };
}

export interface Address {
  attentionTo: string;
  street: string;
  unit: string;
  postalCode: string;
}

export function createAddress(attentionTo?: string, street?: string, unit?: string, postalCode?: string): Address {
  return <Address>{
    attentionTo: attentionTo || '',
    street: street || '',
    unit: unit || '',
    postalCode: postalCode || '',
  };
}

export interface VaccinationStatus {
  code: string;
  name: string;
  balance: string;
  vaccinationRecords: VaccinationRecords[];
  eligibleConditions: EligibleConditions;
  eligibleForVaccination: boolean;
  eligibleForClaim: boolean;
  remark: string;
}

export interface CDSQueryStatus{
  checkedMilestones: CheckedMilestones[];
  milestoneCodes: MilestoneCodes;
  screeningOutcomeCodes: ScreeningOutcomeCodes;
  remarks: string;
}

export interface CDSQueryStatus{
  checkedMilestones: CheckedMilestones[];
  milestoneCodes: MilestoneCodes;
  screeningOutcomeCodes: ScreeningOutcomeCodes;
  remarks: string;
}

export interface EligibleConditions{
  [condition: string]: string;
}

export interface CheckedMilestones{
  claimStatusCode: string;
  milestoneCode: string;
  milestoneDescription: string;
  visitDate: string;
}

export interface MilestoneCodes{
  [code: string]: string;
}

export interface ScreeningOutcomeCodes{
  [code: string]: string;
}

export interface DentalStatus{
  dentalItems: DentalItems[];
  remark: string;
}

export interface DentalItems{
  code: string;
  name: string;
  externalCode: string;
  externalName: string;
  eligible: boolean;
}

export function createDentalStatus(dentalItems?: DentalItems, remark?: string): DentalStatus {
  return <DentalStatus>{
    dentalItems: dentalItems || [],
    remark: remark || '',
  };
}

export interface MedicalChitStatus {
  issuedDate: string;
  medicalChitId: string;
  attentionTo: string;
  reportCollectionMode: string;
  services: string[];
}
export interface MedisaveStatus{
  medisaveItems: MedisaveItems[];
  medisaveRelationItems?: MedisaveItems[];
  remark: string;
}

export interface MedisaveItems{
  code: string;
  name: string;
  utilizedAmount: string;
  balanceAmount: string;
}

export interface InsuranceItemEligibility {
  errorOccurred: boolean;
  payorId: string;
  policyId: string;
  eligibleItems: InsuranceItemEligibilityItem[];
}

export interface InsuranceItemEligibilityItem {
  code: string;
  copay: number;
  limit: number;
  name: string;
}

export function createMedisaveStatus(medisaveItems?: MedisaveItems, remark?: string): MedisaveStatus {
  return <MedisaveStatus>{
    medisaveItems: medisaveItems || [],
    remark: remark || '',
  };
}
