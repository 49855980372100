import { Pipe, PipeTransform } from '@angular/core';
import { Coverage } from '../objects/response/pcn/PCNVisitCase';

@Pipe({
  name: 'attachedPlan',
})
export class AttachedPlanPipe implements PipeTransform {
  transform(excludedCoveragePlanIds: string[], coverages: Coverage[]): any {
    if (
      !coverages ||
      ((!coverages || coverages.length < 1) &&
        (!excludedCoveragePlanIds || excludedCoveragePlanIds.length < 1))
    ) {
      return '-';
    }

    // const coverageSet = new Set(coverages);
    // excludedCoveragePlanIds.forEach(planId => coverages.splice(coverages.findIndex(data => data.planId === planId), 1));d
    let planNames = '';

    if (!excludedCoveragePlanIds || excludedCoveragePlanIds.length < 1) {
      coverages.forEach(plan => {
        planNames += planNames ? `, ${plan.name}` : `${plan.name}`;
      });
    } else {
      coverages.forEach(plan => {
        const index = excludedCoveragePlanIds.findIndex(
          planId => plan.planId === planId
        );

        if (index === -1) {
          planNames += planNames ? `, ${plan.name}` : `${plan.name}`;
        }
      });
    }

    return planNames;
  }
}
