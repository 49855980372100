import { FormControl, FormGroup, Validators } from "@angular/forms";

export class ExercisePlanWorkflow {
    constructor(
        readonly label: string,
        readonly body: string,
        readonly remarks: string,
        readonly comment: boolean,
        readonly enableSection: boolean,
        readonly provider: string,
        readonly bodySections: Array<ExercisePlanSection>,
        readonly bodySectionFormGroup: FormGroup | null,
        readonly sections: Array<ExercisePlanSection>,
        readonly subWorkflowList: Array<ExercisePlanWorkflow>,
        readonly canEnableDisable: boolean,
        readonly type: 'subworkflow-single-choice' | 'subworkflow-only',
        readonly number: string,
        readonly hsgMeasureCode?: string,
        readonly commentFormGroup?: FormGroup,
    ) { }

    static adapt(item: any, values?: Array<ExercisePlanValue>): ExercisePlanWorkflow {
        return new ExercisePlanWorkflow(
            item.context && item.context.label || null,
            item.body,
            item.context && item.context.remarks || null,
            item.context && item.context.comment || false,
            false,
            item.provider,
            ExercisePlanSection.adaptBodySection(item.body, item.sections),
            ExercisePlanSection.adaptBodySectionFormGroup(item, values),
            item.sections ? (item.sections as Array<any>).map(section => ExercisePlanSection.adapt(section)) : [],
            item.subWorkflowList ? (item.subWorkflowList as Array<any>).map(workflow => ExercisePlanWorkflow.adapt(workflow, values)) : [],
            // canEnableDisable is only sent by API when its false. If it doesnt exist, it means it's true
            item.context && item.context.canEnableDisable === false ? false : true,
            item.context && item.context.type || null,
            item.number || null,
            item.hsgMeasureCode || null,
            ExercisePlanSection.adaptCommentFormGroup(item, values),
        )
    }
}

export class ExercisePlanSection {
    constructor(
        readonly number: string,
        readonly input: 'multi-choice' | 'input' | 'input-dropdown' | 'single-choice' | 'subworkflow-single-choice' | null,
        readonly provider: 'static',
        readonly label?: string | null,
        readonly type?: string | null,
        readonly placeholder?: string | null,
        readonly uom?: string | null,
        readonly minValue?: number | null,
        readonly maxValue?: number | null,
        readonly acceptingDecimals?: boolean,
        readonly data?: Array<PlanSectionData>,
    ) { }

    static adapt(item: any): ExercisePlanSection {
        return new ExercisePlanSection(
            item.number,
            item.input,
            item.provider,
            item.label || null,
            item.type || null,
            item.placeholder || null,
            item.oum || null,
            item.minValue || null,
            item.maxValue || null,
            item.acceptingDecimals || false,
            item.data,
        )
    }

    static adaptBodySection(body: string, sections: any): Array<ExercisePlanSection> {
        const splitBody = body.split(' ');

        const bodySection: Array<ExercisePlanSection> = [];

        splitBody.forEach(element => {
            if (element.includes('{{')) {
                const elementPlaceHolder = element.replace('{{', '').replace('}}', '').replace('.', '');
                const section = sections.find((item: any) => item.placeholder === elementPlaceHolder);
                bodySection.push(section!);
            } else {
                bodySection.push(new ExercisePlanSection('', null, 'static', element));
            }
        });

        return bodySection;
    }

    static adaptBodySectionFormGroup(item: any, values?: Array<ExercisePlanValue>): FormGroup | null {
        if (item.sections.length === 0) {
            return null;
        }

        const splitBody = item.body.split(' ');

        const formGroup = new FormGroup({});

        splitBody.forEach(element => {
            if (element.includes('{{')) {
                const elementPlaceHolder = element.replace('{{', '').replace('}}', '').replace('.', '');

                const section: ExercisePlanSection = item.sections.find((item: any) => item.placeholder === elementPlaceHolder);
                const validators = [];

                validators.push(Validators.required);

                if (section.minValue) {
                    validators.push(Validators.min(section.minValue));
                }

                if (section.maxValue) {
                    validators.push(Validators.max(section.maxValue));
                }

                const existingValueForPlaceholder = values?.find(item => item.placeholder === elementPlaceHolder);

                formGroup.addControl(elementPlaceHolder, new FormControl({
                    value: existingValueForPlaceholder ? existingValueForPlaceholder.value : null,
                    disabled: existingValueForPlaceholder ? false : true,
                }, [...validators]
                ));

                formGroup.addControl('selected', new FormControl(existingValueForPlaceholder ? true : false));
            }
        });

        return formGroup;
    }

    static adaptCommentFormGroup(item: any, values?: Array<ExercisePlanValue>): FormGroup {
        const formGroup = new FormGroup({});

        if (item.context && item.context.comment && item.hsgMeasureCode) {
            const existingValueForPlaceholder = values?.find(value => value.placeholder === item.hsgMeasureCode);

            formGroup.addControl('comment', new FormControl({
                value: existingValueForPlaceholder ? existingValueForPlaceholder.contextValue : null,
                disabled: existingValueForPlaceholder ? false : true,
            }));

            formGroup.addControl('placeholder', new FormControl(item.hsgMeasureCode));
            formGroup.addControl('hsgMeasureName', new FormControl(item.hsgMeasureName));
            formGroup.addControl('selected', new FormControl(existingValueForPlaceholder ? true : false));
        }
        return formGroup;
    }
}

export class PlanSectionData {
    constructor(
        readonly key: string,
        readonly value: string,
        readonly captureContext?: boolean,
        readonly contextType?: 'STRING' | null,
    ) { }

    static adapt(item: any): PlanSectionData {
        return new PlanSectionData(
            item.key,
            item.value,
            item.captureContext || false,
            item.contextType || null
        )
    }
}

// TO-DO-SM: This interface name has to be changed to a commonname
export interface ExercisePlanValue {
    placeholder: string,
    value: string,
    contextValue?: string,
}