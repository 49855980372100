// Service
import { ApiAppointmentsService } from './api-appointments.service';
import { AppointmentsFactoryService } from './appointments-factory.service';
import { UtilsService } from './utils.service';

//Objects
import { Appointment, DoctorAvailableSlots } from './../objects/Appointment';
import { AppointmentListResponse } from './../objects/response/AppointmentListResponse';
import { AppointmentRequest } from '../objects/request/AppointmentRequest';
import { AppointmentValidators } from './../validators/AppointmentValidators';
import { CalendarAppointment } from './../objects/CalendarAppointment';
import { DB_FULL_DATE_FORMAT_NO_SECOND, DISPLAY_DATE_FORMAT, noPreferredDoctorColorCode } from './../constants/app.constants';

//Libraries
import * as moment from 'moment';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsFormService {
  private appointmentDetails: BehaviorSubject<any>;
  patientAppointmentConfirm = new Subject<boolean>();
  availableTimesDropDownList = new Array<string>();

  availableTimes: Array<DoctorAvailableSlots>;

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private apptFactory: AppointmentsFactoryService,
    private apiAppointmentsService: ApiAppointmentsService
  ) {
    this.appointmentDetails = new BehaviorSubject<any>([]);
  }

  getPatientAppointmentConfirm() {
    return this.patientAppointmentConfirm.asObservable();
  }

  setPatientAppointmentConfirm(confirm:boolean) {
    this.patientAppointmentConfirm.next(confirm);
  }

  getDoublePatientAppointmentMsgConfirm(date) {
    let confirmMsg =
      'There is already an appointment registered for this patient on ' +
      date +
      '. Do you still want to proceed?';
    return confirm(confirmMsg);
  }

  validateAppointmentCreation(appointmentsFormGroup: FormGroup) {
    let date = moment(appointmentsFormGroup.get('visitDate').value).format(
      DISPLAY_DATE_FORMAT
    );
    let filter = {
      CLINIC_ID_LIST: [appointmentsFormGroup.get('clinicId').value],
    };

    const isWithin1HourConfirm = this.getWithin1HourBookingConfirm(
      appointmentsFormGroup.get('visitDate')
    );

    if (isWithin1HourConfirm) {
      this.apiAppointmentsService
        .searchAppointment(filter, date, date, true)
        .subscribe(res => {
          const appointmentList: AppointmentListResponse[] = res.payload;
          const appointmentFound = appointmentList.findIndex(appt => {
            return (
              appt.patientId === appointmentsFormGroup.get('patientId').value &&
              appt.id !== appointmentsFormGroup.get('id').value
            );
          });

          if (appointmentFound > -1 && !appointmentsFormGroup.get('id').value) {
            let confirmAppt = this.getDoublePatientAppointmentMsgConfirm(date);

            if (confirmAppt) {
              this.setPatientAppointmentConfirm(true);
              return;
            } else {
              this.setPatientAppointmentConfirm(false);
              return;
            }
          } else {
            this.setPatientAppointmentConfirm(true);
          }
        });
    }
  }

  getAvailableTimesDropDownList() {
    return this.availableTimesDropDownList;
  }

  getWithin1HourBookingConfirm(visitDate: AbstractControl) {
    const isWithin1Hour = this.within1HourBookingPeriod(visitDate);
    if (isWithin1Hour) {
      let confirmFwd = confirm(
        'The current timeslot selected is within the 1 hour booking period. Are you sure you want to proceed?'
      );
      if (confirmFwd) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  within1HourBookingPeriod(apptDate: AbstractControl) {
    const appointmentDate = <Date>apptDate.value;
    const bookingDate = new Date();
    const bookingPeriodInMinutes = 60;
    const userActionBufferTime = 0;
    const finalBookingPeriodInMinutes =
      bookingPeriodInMinutes - userActionBufferTime;

    let bookingNotValid = false;
    bookingNotValid =
      moment(appointmentDate).isAfter(bookingDate) &&
      (appointmentDate.getTime() - bookingDate.getTime()) / 60000 <
        finalBookingPeriodInMinutes
        ? true
        : false;

    return bookingNotValid;
  }

  // Create AppointmentsFormGroup
  createAppointmentFormGroup(eventInCalendar?: CalendarAppointment, clinicId?: string) {
    const appointmentFormGroup = this.fb.group(this.apptFactory.createAppointment());
    appointmentFormGroup.get('status').setValidators(Validators.required);
    appointmentFormGroup.get('clinicId').patchValue(clinicId);
    // 2 ways to create appointment in UI
    // 1) Click on Create New Appointment
    // 2) Click on any space inside Calendar
    if (this.clickedWithinCalendar(eventInCalendar)) {
      let appointment: Appointment;
      if (this.isNewAppointment(eventInCalendar.appointment)) {
        appointment = this.apptFactory.createAppointment(
          null,
          null,
          eventInCalendar,
          clinicId
        );
      } else {
        appointment = <Appointment>eventInCalendar.appointment;
      }

      appointmentFormGroup.patchValue(appointment);
    } else {
      this.setDefaultTimesForNewApptBtnClicked(appointmentFormGroup);
    }
    appointmentFormGroup.addControl('currentStatus', new FormControl(appointmentFormGroup.get('status').value));
    return appointmentFormGroup;
  }

  createBlockedTimeFormGroup() {
    return this.fb.group({
      startDate: [new Date(), Validators.required],
      duration: [''],
      doctorId: ['', Validators.required],
      remarks: '',
    });
  }

  createSelectableDoctorFormGroup(doctor): FormGroup {
    let id = doctor !== undefined ? doctor.id : '';
    let displayName = doctor !== undefined ? doctor.displayName : 'Clinic Appointments';
    let colourCode =
      doctor !== undefined
        ? this.apptFactory.getColorByDoctorId(doctor.id)
        : noPreferredDoctorColorCode;
    let doctorGroup = doctor !== undefined ? doctor.doctorGroup : '';

    return this.fb.group({
      id: id,
      displayName: displayName,
      isSelected: true,
      colourCode: colourCode,
      group: doctorGroup,
    });
  }

  clickedWithinCalendar(eventInCalendar) {
    return eventInCalendar !== null ? true : false;
  }

  isNewAppointment(appointment: Appointment) {
    const id = appointment.id;
    if (id === null || id === '' || id === undefined) {
      return true;
    } else {
      return false;
    }
  }

  populateToAppointmentForm(appointment: Appointment, formGroup: FormGroup) {
    formGroup.patchValue(appointment);
  }

  setDefaultTimesForNewApptBtnClicked(formGroup: FormGroup) {
    let visitDate = this.utilsService.roundTimeUpToNearest(
      this.utilsService.increaseTimeByInterval(new Date(), 60),
      15
    );
    let reminderDate = this.utilsService.decreaseTimeByInterval(
      new Date(visitDate),
      60
    );
    let duration = 15;

    this.patchTimes(formGroup, visitDate, reminderDate, duration);
  }

  patchTimes(formGroup: FormGroup, visitDate, reminderDate, duration) {
    formGroup.get('visitDate').patchValue(visitDate);
    formGroup.get('reminderDate').patchValue(reminderDate);
    formGroup.get('duration').patchValue(duration);
  }

  setMandatoryFields(appointmentFormGroup: FormGroup, isNewPatient: boolean) {
    const patientId = appointmentFormGroup.get('patientId');
    const purposeOfVisit = appointmentFormGroup.get('purposeOfVisit');

    if (!isNewPatient) {
      this.setRequired(patientId);
    } else {
      this.clearValidators(patientId);
    }
    this.setRequired(purposeOfVisit);

    this.setAppointmentDateTimeValidation(appointmentFormGroup);
  }

  setRequired(form: AbstractControl) {
    form.setValidators(Validators.required);
  }

  clearValidators(form: AbstractControl) {
    form.clearValidators();
  }

  setAppointmentDateTimeValidation(appointmentFormGroup: FormGroup) {
    const visitDate = appointmentFormGroup.get('visitDate');
    const reminderDate = appointmentFormGroup.get('reminderDate');
    const duration = appointmentFormGroup.get('duration');

    if (!this.isNewAppointment(appointmentFormGroup.value)) {
      // if (this.appointmentIsExpired(visitDate)) {
      //   visitDate.disable();
      //   reminderDate.disable();
      //   duration.disable();
      //   return;
      // } else {
      // }
      // removed logic for #195
    } else {
      // visitDate.setValidators([validateBookingPeriod(visitDate), validateAppointmentTime(visitDate)]);
      visitDate.setValidators([
        AppointmentValidators.validateAppointmentTime(visitDate),
      ]);
      visitDate.markAsTouched();
      visitDate.updateValueAndValidity();
    }

    // reminderDate.setValidators([AppointmentValidators.validateReminderTime(visitDate)]);
    // reminderDate.markAsTouched();
    // reminderDate.updateValueAndValidity();

    duration.setValidators(AppointmentValidators.validateDuration());
    duration.markAsTouched();
    duration.updateValueAndValidity();
  }

  getAvailableTimesDropdownList(date) {
    let availableTimesDropDownList = new Array<string>();
    this.availableTimes.forEach((time: DoctorAvailableSlots) => {
      const availableTimes = time.getTimeSlotsByDate(date);
      availableTimesDropDownList = this.utilsService.mergeArray(
        availableTimesDropDownList,
        availableTimes
      );
    });

    return availableTimesDropDownList;
  }

  timeSlotAvailable(timeSelected, timeSlotsAvailable) {
    if (
      timeSlotsAvailable.findIndex(time => {
        return time === timeSelected;
      }) > -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Set FormGroup for API :  Structure differs slightly
  setAppointmentForApi(input, isReferral?) {
    let appointment = input;

    if (input instanceof FormGroup) {
      appointment = this.apptFactory.createAppointment(input, 'FORMGROUP');
    }
    //
    let appointmentRequest: AppointmentRequest = new AppointmentRequest(
      appointment
    );

    // Set doctor id, if any
    appointmentRequest.setDoctor(appointment.preferredDoctor);

    // Set referral ids, if any
    appointmentRequest.setReferral(this.store.getClinicId(), isReferral);

    return appointmentRequest;
  }

  setAppointmentDetails(formGroup: FormGroup) {
    this.appointmentDetails.next(formGroup);
  }

  getAppointmentDetails() {
    return this.appointmentDetails.asObservable();
  }

  appointmentIsExpired(visitDate: AbstractControl) {
    const currentDate = new Date();
    const appointmentDate = <Date>visitDate.value;
    return moment(appointmentDate).isSameOrBefore(currentDate);
  }

  withinAWindowPeriodFromCurrentTime(visitDate: AbstractControl, minutes) {
    const currentDate = new Date();
    const appointmentDate = <Date>visitDate.value;

    return (
      moment(appointmentDate).isAfter(currentDate) &&
      (appointmentDate.getTime() - currentDate.getTime()) / 60000 < minutes
    );
  }

  alertSuccessfulAppointment() {
    let msg = 'Appointment updated successfully.';
    alert(msg);
  }

  hasDoubleAppointment(
    otherAppts: AppointmentListResponse[],
    apptStartTime,
    apptEndTime,
    apptId
  ) {
    let hasOtherAppointments = false;
    otherAppts.forEach((appointment: AppointmentListResponse) => {
      let otherApptStartTime = moment(
        this.utilsService.convertDateTimeStringToDateObject(
          appointment.startDate
        )
      ).format(DB_FULL_DATE_FORMAT_NO_SECOND);
      let otherApptEndTime = moment(
        otherApptStartTime,
        DB_FULL_DATE_FORMAT_NO_SECOND
      )
        .add(appointment.duration, 'minutes')
        .format(DB_FULL_DATE_FORMAT_NO_SECOND);

      if (
        apptId !== appointment.id &&
        this.appointmentConflictWith(
          apptStartTime,
          apptEndTime,
          otherApptStartTime,
          otherApptEndTime
        )
      ) {
        hasOtherAppointments = true;
      }
    });

    return hasOtherAppointments;
  }

  confirmDoubleDoctorAppointment() {
    return confirm(
      'This doctor has other appointments at this time. Do you still want to proceed to register this appointment?'
    );
  }

  appointmentConflictWith(apptStartTime, apptEndTime, begTime, endTime) {
    if (
      this.isWithin(apptStartTime, begTime, endTime) ||
      this.isWithinEndBoundary(apptEndTime, begTime, endTime)
    ) {
      return true;
    } else {
      return false;
    }
  }

  isWithin(selectedDate: Date, startDate: Date, endDate: Date) {
    if (
      moment(selectedDate, DB_FULL_DATE_FORMAT_NO_SECOND).isSameOrAfter(
        moment(startDate, DB_FULL_DATE_FORMAT_NO_SECOND)
      ) &&
      moment(selectedDate, DB_FULL_DATE_FORMAT_NO_SECOND).isBefore(
        moment(endDate, DB_FULL_DATE_FORMAT_NO_SECOND)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  isWithinEndBoundary(selectedDate: Date, startDate: Date, endDate: Date) {
    if (
      moment(selectedDate, DB_FULL_DATE_FORMAT_NO_SECOND).isAfter(
        moment(startDate, DB_FULL_DATE_FORMAT_NO_SECOND)
      ) &&
      moment(selectedDate, DB_FULL_DATE_FORMAT_NO_SECOND).isSameOrBefore(
        moment(endDate, DB_FULL_DATE_FORMAT_NO_SECOND)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
