import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaRequestReturnStore } from './akita-request-return.store';
import { InventoryInfo } from '../../objects/state/InventoryInfo';

@Injectable({ providedIn: 'root' })
export class AkitaRequestReturnQuery extends QueryEntity<InventoryInfo> {
  requestReturnList$ = this.selectAll();

  constructor(protected store: AkitaRequestReturnStore) {
    super(store);
  }
}
