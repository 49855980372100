import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class WarningMadalService {

  messageSubject: Subject<string> = new BehaviorSubject<string>('');
  adrmessageSubject: Subject<string> = new BehaviorSubject<string>('');
  modalRef: BsModalRef = null;
  constructor(
    private bsModalService: BsModalService
  ) { }

  setMessage(message: string) {
    this.messageSubject.next(message);
  }

  setADRMessage(message: string) {
    this.adrmessageSubject.next(message);
  }

  getMessage(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  getAdRMessage(): Observable<string> {
    return this.adrmessageSubject.asObservable();
  }

  show(cmp, initialState) {
    this.modalRef = this.bsModalService.show(cmp, initialState)
  }

  hide() {
    this.modalRef = null;
    this.messageSubject.next('');
    this.adrmessageSubject.next('');
  }

  isModalAlive(): boolean {
    return !!this.modalRef;
  }

  getModal(): BsModalRef {
    return !!this.modalRef ? this.modalRef : null;
  }

}
