<div class="row" [formGroup]="itemGroup">
  <div [ngClass]="(isCdmClinic || isCaseManagerFlow) ? 'col-md-2' : 'col-md-3'">
    <ng-select class="p-0 form-control form-control-sm custom-select custom-select-extra-width" [items]="codes" placeholder="Select Code" 
    formControlName="id" bindValue='id' bindLabel='icd10Code' [addTag]="false"
     [typeahead]="codesTypeahead" [loading]="loading" [virtualScroll]="true" 
     (change)="onDiagnosisSelected($event, true, true)" (clear)="onClear()">
      <ng-template ng-header-tmp>
        <div class="row">
          <div class="col-md-3 font-weight-semi-bold">Code</div>
          <div class="col-md-9 font-weight-semi-bold">Term</div>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="row">
          <div class="col-md-3">{{item.icd10Code}}</div>
          <div class="col-md-9">{{item.icd10Term}}</div>
        </div>
      </ng-template>
      <ng-template ng-footer-tmp *ngIf="!isGlobalSearch && searchTerm">
        <div class="row">
          <div class="col-md-12 p-0">
            <button class="btn btn-block btn-white text-left" (click)="loadMoreDiagnosis()">+ Load more</button>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <!-- <div *ngIf="isEdit" class="col-md-2">
        <ng-select class="form-control form-control-sm custom-select" [formControl]='codeFilterFC' [items]="codeFilter" [clearable]="false">
        </ng-select>
    </div> -->
  <div class="col">
    <div class="row no-gutters">
      <div [ngClass]="(isCdmClinic || isCaseManagerFlow) ? 'col-md-6' : 'col-md-11'" style="padding-right: 12px;">
        <input class="form-control form-control-sm" type="text" readonly [(ngModel)]="description" [ngModelOptions]="{standalone: true}" />
      </div>
      <div class="col-md-5" *ngIf="isCdmClinic || isCaseManagerFlow">
        <ng-select class="p-0 form-control form-control-sm custom-select custom-select-extra-width" [items]="snomedCodes" placeholder="Select Code" 
         formControlName="snomedId" bindLabel='term' [addTag]="false" appendTo="body"
         [typeahead]="snomedListTypeahead" [loading]="loading" [virtualScroll]="false" 
         (change)="onSnomedSelected($event)" (clear)="onSnomedClear()">
         <ng-template ng-header-tmp>
          <div class="row">
            <div class="col-md-12 font-weight-semi-bold">Term</div>
          </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="row">
              <div class="col-md-12">{{item.term}}</div>
            </div>
          </ng-template>
        </ng-select>
        <errors [control]="itemGroup.get('snomedId')"></errors>
      </div>
      <div class="col-md-1 px-1">
        <button *ngIf="isEdit || isCaseManagerFlow" type="button" class="btn btn-sm" [popover]="popTemplate2" placement="left" [outsideClick]="true">
          <i class="icon-ellipsis-vert"></i>
        </button>

        <ng-template #popTemplate2>
          <div class="row">
            <button class="btn btn-link-grey p-1 " (click)="onBtnDeleteClicked()">
              <i class="icon-trash"></i>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div class="row col-md">
  <small *ngIf="itemGroup.get('id').invalid && itemGroup.get('id').errors.requiredFalse" class="text-danger font-weight-500">
    This diagnosis is not valid with current medical coverage plan
  </small>
</div>
