import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'routeOfAdministration',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaRouteOfAdministrationStore extends EntityStore<any> {
  constructor() {
    super();
  }
}
