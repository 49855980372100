import { Injectable } from '@angular/core';

import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { HSGGoal } from '../../model/HSGPlan';
import { AkitaGoalMasterStore } from './akita-goal-master.store';

@Injectable({ providedIn: 'root' })
export class AkitaGoalMasterQuery extends QueryEntity<HSGGoal> {
  goalMasterList$ = this.selectAll();

  constructor(protected store: AkitaGoalMasterStore) {
    super(store);
  }
}
