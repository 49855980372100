import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { StoreService } from '../../../../../../services/store.service';

import { BsModalService } from 'ngx-bootstrap';

import { HealthPlanWorkflow } from '../../../../../../model/care-plan/CarePlanHealthGoal';

import { PatientConditions, PLAECHOLDERS_TO_OMIT_FOR_OLD_CARE_PLAN_VERSION, REMOVE_GOAL_CONFIRMATION_MESSAGE, REMOVE_GOAL_TITLE } from '../CarePlanConstants';

import { CarePlanConfirmationModalComponent } from '../care-plan-confirmation-modal/care-plan-confirmation-modal.component';

@Component({
  selector: 'app-health-goals',
  templateUrl: './health-goals.component.html',
  styleUrls: ['./health-goals.component.scss']
})
export class HealthGoalsComponent implements OnInit, OnChanges {
  // TO-DO-SM: Refactor this component

  @Input() carePlanFormGroup: FormGroup;
  @Input() existingData: Array<any> = [];

  public dropdownList: Array<any> = [];
  public selectedDropdownList: Array<any> = [];
  private originalDropdownItems: Array<any> = [];

  private originalHealthPlanList: Array<any> = [];
  public healthPlanList: Array<HealthPlanWorkflow> = [];

  public PatientConditions = PatientConditions;

  public maxPlanSelectedError: boolean = false;

  get healthGoalsArray(): FormArray {
    return this.carePlanFormGroup.get('healthGoals') as FormArray;
  }

  get cmsConditionsFormHsgFormArray(): FormArray {
    return this.carePlanFormGroup.get('cmsConditionsForHSG') as FormArray;
  }

  get cmsConditionsFormHsgArray(): Array<FormGroup> {
    return this.cmsConditionsFormHsgFormArray.controls as Array<FormGroup>;
  }

  constructor(
    private modalService: BsModalService,
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
    this.initPlans();
    this.loadGoalsBasedOnPatientConditions();
    this.loadDefaultGoals();
    this.constructorHealthGoalsFormArray();
  }

  private initPlans(): void {
    this.healthPlanList = [];
    this.originalHealthPlanList = this.storeService.originalHsgHealthPlans;
    this.constructDropdown();

    // TO-DO-SM: item.workflow.title !== 'Exercise Recommendation' needs to be fixed
    const existingGoalsData = this.existingData?.filter(item => item.workflow.title !== 'Exercise Recommendation' && item.workflow.hsgMeasureName !== 'Exercise');
    existingGoalsData?.forEach(data => {
      let planForSelectedPlandId;
      let values = data.values;

      if (data.hsgGoalId) {
        planForSelectedPlandId = this.originalHealthPlanList.find(item => item.goalId === data.hsgGoalId);
      } else {
        //If old version, skip the following placeholders without binding
        const arrayOfPlaceholdersToSkip: Array<string> = PLAECHOLDERS_TO_OMIT_FOR_OLD_CARE_PLAN_VERSION;
        const filteredPlaceholdersWithValues = data.values.filter(item => !arrayOfPlaceholdersToSkip.includes(item.placeholder));
        values = filteredPlaceholdersWithValues;
        planForSelectedPlandId = this.originalHealthPlanList.find(item => item.workflow.hsgMappingCode === data.workflow.hsgMappingCode);
      }

      if (planForSelectedPlandId) {
        const healthPlan = HealthPlanWorkflow.adapt(planForSelectedPlandId.goalId || null, planForSelectedPlandId.workflow, false, values);
        this.healthPlanList.push(healthPlan);
      }

      this.selectedDropdownList = this.healthPlanList.map(item => item.id);
    });
  }

  private loadDefaultGoals(): void {
    if (this.carePlanFormGroup.get('healthPlanStatus').value === 'DRAFT') {
      return;
    }

    const defaultGoals = this.originalHealthPlanList.filter(healthPlan => !this.PatientConditions.map(condition => condition.goalId).includes(healthPlan.goalId));

    defaultGoals.forEach(defaultGoal => {
      const healthPlan = HealthPlanWorkflow.adapt(defaultGoal.goalId || null, defaultGoal.workflow);
      this.healthPlanList.push(healthPlan);
      this.selectedDropdownList = this.healthPlanList.map(item => item.id);
    })
  }

  private loadGoalsBasedOnPatientConditions(): void {
    if (this.carePlanFormGroup.get('healthPlanStatus').value === 'DRAFT') {
      return;
    }

    const resultArray: Array<any> = this.cmsConditionsFormHsgFormArray.value;

    resultArray.forEach(condition => {
      if (condition.selected) {
        if (!this.healthPlanList.map(healthPlan => healthPlan.id).includes(condition.goalId)) {
          // If not, create new plan and push to healthPlanList
          const planForSelectedPlandId = this.originalHealthPlanList.find(item => item.goalId === condition.goalId);
          const healthPlan = HealthPlanWorkflow.adapt(planForSelectedPlandId.goalId || null, planForSelectedPlandId.workflow);
          this.healthPlanList.push(healthPlan);
          this.selectedDropdownList = this.healthPlanList.map(item => item.id);
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initPlans();
    this.constructorHealthGoalsFormArray();
  }

  // TO-DO-SM: Remove if not required
  // public onCheckPatientCondition(isChecked: boolean, condition: any): void {
  //   if (isChecked) {
  //     if (!this.healthPlanList.map(healthPlan => healthPlan.id).includes(condition.goalId)) {
  //       // If not, create new plan and push to healthPlanList
  //       const planForSelectedPlandId = this.originalHealthPlanList.find(item => item.goalId === condition.goalId);
  //       const healthPlan = HealthPlanWorkflow.adapt(planForSelectedPlandId.goalId || null, planForSelectedPlandId.workflow);
  //       this.healthPlanList.push(healthPlan);
  //       this.selectedDropdownList = this.healthPlanList.map(item => item.id);
  //     }
  //   } else {
  //     if (!this.healthPlanList.find(item => item.id === condition.goalId)) {
  //       return;
  //     }

  //     const patientConditionArrayValue: Array<any> = this.cmsConditionsFormHsgFormArray.value;
  //     if (patientConditionArrayValue.find(item => item.selected && item.goalId === condition.goalId)) {
  //       return;
  //     } else {
  //       const indexOfPlanToDelete = this.healthPlanList.map(healthPlan => healthPlan.id).indexOf(condition.goalId);
  //       this.healthPlanList.splice(indexOfPlanToDelete, 1);
  //     }
  //   }
  // }

  private constructDropdown(): void {
    this.originalDropdownItems = this.originalHealthPlanList.map(item => {
      return {
        id: item.goalId,
        label: item.workflow.title,
      }
    });

    this.dropdownList = this.originalDropdownItems;
  }

  public onAdd(): void {
    if (this.healthPlanList.length === this.originalHealthPlanList.length) {
      this.maxPlanSelectedError = true;
      return;
    }

    this.maxPlanSelectedError = false;

    const healthPlan = new HealthPlanWorkflow(
      undefined,
      null,
      [],
      new FormGroup({
        goalId: new FormControl(undefined),
      }),
      [],
      false,
      'other',
    );

    this.healthPlanList.push(healthPlan);
  }

  public onSelectGoal(selection: { rowIndex: number, selectedPlanId: string }): void {
    const planForSelectedPlandId = this.originalHealthPlanList.find(item => item.goalId === selection.selectedPlanId);

    const healthPlan = HealthPlanWorkflow.adapt(planForSelectedPlandId.goalId || null, planForSelectedPlandId.workflow);
    this.healthPlanList[selection.rowIndex] = healthPlan;
    this.selectedDropdownList = this.healthPlanList.map(item => item.id);

    this.constructorHealthGoalsFormArray();
  }

  public onDelete(goalId: string): void {
    this.maxPlanSelectedError = false;

    const patientConditionFormValue = this.cmsConditionsFormHsgFormArray.value as Array<any>;
    const goalsToDelete = patientConditionFormValue.filter(item => item.goalId === goalId);

    if (goalsToDelete.length > 0 && goalsToDelete.find(item => item.selected)) {
      const initialState = {
        title: REMOVE_GOAL_TITLE,
        message: REMOVE_GOAL_CONFIRMATION_MESSAGE,
        buttonConfig: {
          isCancel: true,
          isConfirm: true,
        }
      }

      let bsModalRef = this.modalService.show(CarePlanConfirmationModalComponent, {
        initialState,
        class: 'modal-md custom-modal',
        backdrop: 'static',
        keyboard: false,
      });

      bsModalRef.content.event.subscribe(data => {
        const indexOfPlanToDelete = this.healthPlanList.map(healthPlan => healthPlan.id).indexOf(goalId);
        this.healthPlanList.splice(indexOfPlanToDelete, 1);

        this.selectedDropdownList = this.healthPlanList.map(item => item.id);
        bsModalRef.hide();
      })
    } else {
      const indexOfPlanToDelete = this.healthPlanList.map(healthPlan => healthPlan.id).indexOf(goalId);
      this.healthPlanList.splice(indexOfPlanToDelete, 1);

      this.selectedDropdownList = this.healthPlanList.map(item => item.id);
    }
  }

  private constructorHealthGoalsFormArray(): void {
    this.healthGoalsArray.clear();
    const collectFormGroups = (workflow: HealthPlanWorkflow) => {
      if (workflow.bodySectionFormGroup) {
        this.healthGoalsArray.push(workflow.bodySectionFormGroup);
      }

      if (workflow.subWorkflowList && workflow.subWorkflowList.length > 0) {
        workflow.subWorkflowList.forEach(subworkflow => collectFormGroups(subworkflow));
      }
    };

    this.healthPlanList.forEach(item => collectFormGroups(item));
  }
}