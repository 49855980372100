import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';
import { AkitaPCNVisitStore } from './states/akita-pcn-visit-case.store';

import { HealthierSG } from '../model/HealthierSG';
import { HSGGoal } from '../model/HSGPlan';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import PCNDetails from '../objects/response/pcn/PCNDetails';

import { DATE_ASSESSMENT_CODES, DB_FULL_DATE_TIMEZONE_NO_SPACE, DB_FULL_DATE_TIMEZONE_NO_SPACE_Z, DISPLAY_DATE_FORMAT } from '../constants/app.constants';

import * as moment from 'moment';
import { LoaderService } from '../components/loading/loader.service';
import { AlertService } from './alert.service';
import { HSG_LIST_SYNC_SUCCESS } from '../constants/hsg.constants';

import { AkitaClinicQuery } from './states/akita-clinic.query';
import { HSGOnboardingQuestionnaire } from '../model/HSGOnboardingQuestionnaire';

@Injectable({
  providedIn: 'root'
})
export class ApiHsgService implements OnDestroy {

  private API_HSG_URL;

  private patientListSub = new Subject<any[]>();

  private updateHSGListSub = new Subject<boolean>();
  private isFromHSGSub = new Subject<boolean>();
  private HSGFormBulkDataSub = new Subject<any[]>();
  private HSGSubmitSub = new Subject<boolean>();
  private HSGListSelectionSub = new Subject<any[]>();
  private isDisabled: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private akitaPCNVisitStore: AkitaPCNVisitStore,
    private akitaClinicQuery: AkitaClinicQuery,
    private loaderService: LoaderService,
    private alertService: AlertService
  ) {
    this.API_HSG_URL = appConfig.getConfig().API_HSG_URL;
  }

  ngOnDestroy() { }

  public listHsgVisit(
    page,
    periodId: string,
    searchValue
  ): Observable<HttpResponseBody> {
    return this.http
      .post<HttpResponseBody>(
        `${this.API_HSG_URL}/search/care-report/${periodId}/${page.pageNumber}/${page.size}`,
        searchValue
      )
  }

  public getHsgPlans(
    clinicId: string,
    fromDate: string,
    toDate: string,
    searchValue: string,
    status: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/search/plans/${clinicId}`,
      JSON.stringify({
        fromDate,
        toDate,
        searchValue,
        status
      })
    )
  }

  getHsgPlansForPatient(clinicId: string, patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/search/plans/${clinicId}/${patientId}`, {}
    )
  }

  syncHsgPlansForPatient(clinicId: string, patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/sync/plans/${clinicId}/${patientId}`, {}
    )
  }

  getGoalMasterData(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/goal-master`, {}
    )
  }

  submitCareReport(visitId: string, cdmpDetails: PCNDetails) {
    let { conditions, vitals, assessment, patientExtraDetails } = cdmpDetails;

    let valuesofAssesment = [];
    valuesofAssesment = Object.entries(assessment.assessments);
    Object.keys(assessment.assessments).forEach(key => {
      if (DATE_ASSESSMENT_CODES.includes(key)) {
        this.updateTestDates(key, valuesofAssesment, assessment);
      }
    })

    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/care-report/submit/${visitId}`,
      JSON.stringify({ conditions, vitals, assessment, patientExtraDetails })
    );
  }

  submitPlan(plan: any) {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/plan/save`,
      JSON.stringify(plan)
    );
  }

  deletePlan(planId: string) {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/plan/delete/${planId}`,
      {}
    );
  }

  updateTestDates(key, valuesofAssesment, assessment) {
    valuesofAssesment.forEach(value => {
      if (value[0] == key) {
        var screenDate = value[1];
        let dateforDB = moment(screenDate, DISPLAY_DATE_FORMAT).toDate();
        assessment.assessments[key] = dateforDB;
      }
    })
  }

  public getDashboard(clinicId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/dashboard/project/${clinicId}`, {}
    );
  }

  public getPatientList(
    page: number,
    size: number,
    filter: any): void {
    this.loaderService.setLoading(true);
    const clinicId = this.akitaClinicQuery.getCurrentClinic().id;

    this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/enroll/search-patient/${clinicId}/${page}/${size}`, filter
    ).subscribe({
      next: result => {
        if (result.statusCode === 'S0000') {
          this.patientListSub.next(result.payload)
          this.loaderService.setLoading(false);
        }
      },
      error: error => {
        this.loaderService.setLoading(false);
      }
    });
  }

  public getPatientListUpdated(): Observable<any[]> {
    return this.patientListSub.asObservable();
  }

  public updatePatient(data: any): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/enroll/update-patient`, data
    );
  }

  public syncHSGList() {
    this.loaderService.setLoading(true);
    const clinicId = this.akitaClinicQuery.getCurrentClinic().id;

    this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/enroll/sync/${clinicId}`, {}
    ).subscribe({
      next: result => {
        this.alertService.success(HSG_LIST_SYNC_SUCCESS);
        this.setHSGListUpdateStatus(true);
      },
      error: error => {
        this.loaderService.setLoading(false);
        this.alertService.error(error.error.message);
      }
    });
  }

  public syncHSGListByDate(startDate) {
    this.loaderService.setLoading(true);
    const clinicId = this.akitaClinicQuery.getCurrentClinic().id;

    this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/enroll/sync-all-by-date/${startDate}/${clinicId}`, {}
    ).subscribe({
      next: result => {
        if (result.payload && result.payload.length) {
          const errorData = result.payload.filter(item => {
            return item.errorOccurred === true
          });
          if (errorData && errorData.length) {
            this.alertService.error(`There has been error in Sync - ${errorData[0].errorMessage} \n Contact your administration for assistance`);
          } else {
            this.alertService.success(HSG_LIST_SYNC_SUCCESS);
          }
        } else {
          this.alertService.success(HSG_LIST_SYNC_SUCCESS);
        }
        this.setHSGListUpdateStatus(true);
      },
      error: error => {
        this.loaderService.setLoading(false);
        this.alertService.error(error.error.message);
      }
    });
  }

  public getVaccinations(patientId, clinicId): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/nehr/immunization-history/${clinicId}/${patientId}`,
      {}
    );
  }

  public setHSGListUpdateStatus(status: boolean): void {
    this.updateHSGListSub.next(status);
  }

  public updateHSGList(): Observable<boolean> {
    return this.updateHSGListSub.asObservable();
  }

  public createHSGAppointment(hsgPatientId: string, data: any): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/appointment/create/${hsgPatientId}`, data
    );
  }

  public setFromHSGStatus(status: boolean): void {
    this.isFromHSGSub.next(status);
  }

  public getFromHSGStatus(): Observable<boolean> {
    return this.isFromHSGSub.asObservable();
  }

  openCareReport(periodId, patientGroupId): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/care-report/${periodId}/${patientGroupId}`,
      {}
    );
  }

  public getCareReportPeriod(page, clinicId, payload): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/search/care-report-period/${clinicId}/${page.pageNumber}/${page.size}`,
      payload
    );
  }

  public generateCareReport(clinicId, payload): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/care-report-period/create/${clinicId}`,
      payload
    );
  }

  public deleteCareReport(periodId, payload): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/care-report/cancel/period/${periodId}`,
      payload
    );
  }

  public createCareReport(periodId): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/care-report/generate/${periodId}`,
      {}
    );
  }

  public setFromBulkHSGData(data): void {
    this.HSGFormBulkDataSub.next(data);
  }

  public getFromBulkHSGData(): Observable<any> {
    return this.HSGFormBulkDataSub.asObservable();
  }

  public setRefreshSubmitStatus(status: boolean): void {
    this.HSGSubmitSub.next(status);
  }

  public getRefreshSubmitStatus(): Observable<any> {
    return this.HSGSubmitSub.asObservable();
  }

  public setHSGListSelection(data): void {
    this.HSGListSelectionSub.next(data);
  }

  public getHSGListSelection(): Observable<any> {
    return this.HSGListSelectionSub.asObservable();
  }

  setDisableValue(value) {
    return this.isDisabled.next(value);
  }
  getDisableValue() {
    return this.isDisabled.asObservable();
  }

  public getHsgQuestionnaire(clinicId: string, patientId: string, body: any): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/questionnaire/get/${clinicId}/${patientId}`,
      body
    );
  }

  public getHsgPatientJourney(clinicId: string, patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/iquit/get-journey-status/${clinicId}/${patientId}`,
      {}
    );
  }

  public getHsgPatientCareSummary(clinicId: string, patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_HSG_URL}/pcs/get/${clinicId}/${patientId}`,
      {}
    );
  }
}