<div class="row invalidItemText mt-2 p-2" *ngIf="!apptIsAvailable">
    <div class="col-auto pr-0">
        <span class="icon-attention"></span>
    </div>
    <div class="col">
        <span>The following appointment slot is not available. Please try other doctor or timings.</span>
    </div>
</div>
<div *ngIf="apptIsAvailable && hasDoubleAppointments">
    <div class="row text-blue mt-2 p-2">
        <div class="col-auto pr-0">
            <span class="icon-info-circled"></span>
        </div>
        <div class="col">
            <span>Please note that this doctor has been booked for other appointments during this period. However, you may still continue to proceed.</span>
        </div>
    </div>
    <div class="row ml-2">
        <input type="checkbox" (change)="onCheckBoxChanged()" [(ngModel)]="checkbox" class="mt-1 mr-2 ml-1"> 
        <span>Please confirm to continue</span>
    </div>
</div>
<div class="row text-blue mt-2 p-2" *ngIf="apptBookedWithin1Hour">
    <div class="col-auto pr-0">
        <span class="icon-info-circled"></span>
    </div>
    <div class="col">
        <span>Please note that the timeslot selected is currently within the 1-hour booking period.</span>
    </div>
</div>