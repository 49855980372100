import { TopDrugDescription } from './../../objects/DrugDescription';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  selector: '[appTouchedObject]',
})
export class TouchedObjectDirective {
  @Output() onTopDrugDescriptionChanged: EventEmitter<any> = new EventEmitter();

  @Input() drugData: TopDrugDescription;

  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick($event: any) {
    this.onTopDrugDescriptionChanged.emit(this.drugData);
  }
}
