import { FormControl, ValidatorFn } from '@angular/forms';

export function CashAmountValidator(control: FormControl): ValidatorFn | any {
  const invoiceType = control.parent && control.parent.get('invoiceType') ? control.parent.get('invoiceType').value : '';
  const name = control.parent && control.parent.get('name') ? control.parent.get('name').value : '';
  const number = control && control.value ? (Number.parseFloat(control.value) * 100) : 0;
  const finalDigit = number.toFixed(0).slice(-1);
  const failingCond = invoiceType === 'DIRECT' && name === 'CASH' && (finalDigit !== '0' && finalDigit !== '5');

  return failingCond ? { invalidCashAmount: true } : null;
}
