import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiMwocService {

  private API_URL_ENV;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService
  ) {
    this.API_URL_ENV = appConfig.getConfig().API_URL_ENV;
  }

  public uploadPatients(
    purposeOfVisit: string,
    preferredDoctorId: string,
    priority: string,
    remarks: string,
    payload: FormData
  ): Observable<HttpResponseBody> {
    const clinicId = localStorage.getItem('clinicId')

    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/mwoc/register/${clinicId}/${purposeOfVisit}/${preferredDoctorId}/${priority}/${remarks}`,
      payload
    );
  }

  public printPatientLabel(
    payload: any
    ): Observable<HttpResponseBody> {
    const clinicId = localStorage.getItem('clinicId')

    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/mwoc/print-patient-labels/${clinicId}`,
      payload
    );
  }

  public printLabReqeustForm(
    payload: any
    ): Observable<HttpResponseBody> {
    const clinicId = localStorage.getItem('clinicId')

      return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/mwoc/print-lab-request-forms/${clinicId}`,
      payload
    );
  }

  public getBulkLabRequestFormData(payload: any): Observable<HttpResponseBody> {
    const clinicId = localStorage.getItem('clinicId')

    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/mwoc/load-lab-requests/${clinicId}`,
      payload
    );
  }

  public saveLabRequests(payload: any): Observable<HttpResponseBody> {
    const clinicId = localStorage.getItem('clinicId')

    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/mwoc/save-lab-requests/${clinicId}`,
      payload
    );
  }

  public printLabRequestForm(payload: any): Observable<HttpResponseBody> {
    const clinicId = localStorage.getItem('clinicId')

    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/mwoc/print-lab-request-forms/${clinicId}`,
      payload
    );
  }
}
