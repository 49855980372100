<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="cancelModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      Are you sure you want to delete selected plan?
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="button-primary" (click)="cancelModal()">Cancel</button>
  <button type="button" class="button-red" (click)="deletePlan()">Delete</button>
</div>