import { environment } from '../environments/environment';

// export const navigation = () => {

//   if (environment.envName === 'cmsdua' || environment.envName === 'prod') {
//     return initialNavigation;
//   }

//   return initialNavigation.filter(navItem => navItem.name !== 'Inventory');
// };

export const populateNavigationItems = () => {
  if (environment.envName === 'cmsdua' || environment.envName === 'prod') {
    return initialNavigation;
  }

  return initialNavigation.filter(navItem => navItem.name !== 'Inventory');
};

const initialNavigation = [
  {
    name: 'Dashboard',
    url: '/pages/dashboard',
    icon: '',
    svgIcon: '/assets/svg/dashboard.svg',
    permissions: ['ROLE_VIEW_CA_DASHBOARD'],
  },
  {
    name: 'Patient Registry',
    url: '/pages/patient/list',
    icon: '',
    svgIcon: '/assets/svg/queue.svg',
  },
  {
    name: 'Patients',
    url: '/pages/patient/search',
    icon: 'icon-patients',
    svgIcon: '/assets/svg/patients.svg',
    permissions: ['ROLE_PATIENT_VISIT_MANAGE'],
  },
  {
    name: 'Appointments',
    url: '/pages/appointments',
    icon: '',
    svgIcon: '/assets/svg/appointments.svg',
    permissions: ['ROLE_APPOINTMENT'],
  },
  {
    name: 'Case Manager',
    url: '/pages/case/list',
    icon: '',
    svgIcon: '/assets/svg/casemanager.svg',
  },
  {
    name: 'CDM',
    url: '/pages/cdm/list',
    icon: '',
    svgIcon: '/assets/svg/pcn-icon.svg',
    children: [
      {
        name: 'PCN',
        url: '/pages/cdm/list/pcn',
        icon: '',
        svgIcon: '/assets/svg/pcn-icon.svg',
      },
      {
        name: 'CIDC',
        url: '/pages/cdm/list/cidc',
        icon: '',
        svgIcon: '/assets/svg/pcn-icon.svg',
      },
    ]
  },
  {
    name: 'Projects',
    url: '/pages/projects/list',
    icon: '',
    svgIcon: '/assets/svg/edit-white.svg',
    permissions: ['ROLE_NPHS_DATA_ACCESS', 'ROLE_NIR_CHECK_SKIP_ALLOWED', 'ROLE_TEMPLATE_CREATION', 'ROLE_SUBMIT_NIR', 'ROLE_NIR', 'ROLE_HWMED_DATA_ACCESS'],
  },
  {
    name: 'Services',
    url: '/pages/communications/',
    icon: '',
    svgIcon: '/assets/svg/communications.svg',
    permissions: ['ROLE_VIEW_COMMUNICATION', 'ROLE_COM_ONLINE_DOC'],
    children: [
      {
        name: 'Communications',
        url: '/pages/communications/',
        icon: '',
        svgIcon: '/assets/svg/communications.svg',
        permissions: ['ROLE_VIEW_COMMUNICATION'],
      },
      {
        name: 'Online Doctor',
        url: '/pages/communications/online-doctor',
        icon: '',
        svgIcon: '/assets/svg/communications.svg',
        permissions: ['ROLE_COM_ONLINE_DOC'],
      },
    ]
  },
  {
    name: 'Claim',
    url: '/pages/claim/mhcp-claim',
    icon: '',
    svgIcon: '/assets/svg/claims.svg',
    permissions: ['ROLE_MHCP_LIST', 'ROLE_MHCP_LIST_HQ', 'ROLE_VIEW_CLAIM'],
    children: [
      {
        name: 'MHCP/CHAS',
        url: '/pages/claim/mhcp-claim',
        icon: '',
        svgIcon: '/assets/svg/claims.svg',
        permissions: ['ROLE_MHCP_CLAIM_DENTAL'],
      },
      {
        name: 'CHAS Dental',
        url: '/pages/claim/dental-claim',
        icon: '',
        svgIcon: '/assets/svg/claims.svg',
        permissions: ['ROLE_MHCP_CLAIM_DENTAL'],
      },
      {
        name: 'Medisave',
        url: '/pages/claim/medisave-claim',
        icon: '',
        svgIcon: '/assets/svg/claims.svg',
        permissions: ['ROLE_DISPLAY_MEDISAVE_CLAIMS'],
      },
      {
        name: 'Manual Claim',
        url: '/pages/claim/manual-claim',
        icon: '',
        svgIcon: '/assets/svg/inventory.svg',
        permissions: ['ROLE_VIEW_CLAIM'],
      },
      {
        name: 'Refund',
        url: '/pages/claim/refund',
        icon: '',
        svgIcon: '/assets/svg/invoices.svg',
        permissions: ['ROLE_MANAGE_REFUND'],
      },
      {
        name: 'Bill Adjustment',
        url: '/pages/claim/bill-adjustment',
        icon: '',
        svgIcon: '/assets/svg/invoices.svg',
        permissions: ['ROLE_SO_CORRECTION'],
      },
      {
        name: 'SFL',
        url: '/pages/claim/claim-sfl',
        icon: '',
        svgIcon: '/assets/svg/claims.svg',
        permissions: ['ROLE_VIEW_SFL_CLAIM'],
      },
    ],
  },
  {
    name: 'Queries',
    url: '/pages/queries/list',
    icon: '',
    svgIcon: '/assets/svg/search.svg',
  },
  {
    name: 'Inventory',
    url: `/pages/inventory/main/0`,
    icon: '',
    svgIcon: '/assets/svg/inventory.svg',
    permissions: ['ROLE_PR_PO_INVENTORY_NAV'],
  },
  {
    name: 'Invoice',
    url: '/pages/invoice/list',
    icon: '',
    svgIcon: '/assets/svg/invoices.svg',
    permissions: ['ROLE_UPDATE_INVOICE'],
  },

  {
    name: 'Reports',
    url: '/pages/report',
    icon: '',
    svgIcon: '/assets/svg/reports.svg',
  },
  {
    name: 'User Notes',
    url: '/pages/notes/list',
    icon: '',
    svgIcon: '/assets/svg/invoices.svg',
    permissions: ['ROLE_PERSONAL_NOTE_MANAGE'],
  },
  {
    name: 'Settings',
    url: '/pages/settings/templates',
    icon: '',
    svgIcon: '/assets/svg/settings.svg',
    permissions: ['ROLE_VIEW_SYSTEM_CONFIG'],
  },
];
