import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, pairwise, startWith, take, takeUntil } from 'rxjs/operators';

import { ApiCaseManagerService } from './../../../../services/api-case-manager.service';
import { ApiPatientInfoService } from './../../../../services/api-patient-info.service';

import { DispatchItemService } from '../../../../services/dispatch-item.service';
import { AlertService } from './../../../../services/alert.service';
import { DispatchItemDataService } from '../../../../services/dispatch-item-data.service';
import { UtilsService } from '../../../../services/utils.service';
import { CaseChargeFormService } from './../../../../services/case-charge-form.service';
import { ConsultationFormService, mulitplierValidator } from './../../../../services/consultation-form.service';

import { StoreService } from './../../../../services/store.service';
import { AkitaCaseVisitQuery } from '../../../../services/states/akita-case-visit.query';
import { AkitaChargeItemQuery } from '../../../../services/states/akita-charge-item.query';
import { AkitaPatientAppQuery } from './../../../../services/states/akita-patient/akita-patient-app.query';

import DatePickerConfig from '../../../../objects/DatePickerConfig';
import { DosageInstruction, Instruction } from './../../../../objects/DrugItem';
import { Uom } from '../../../../objects/Uom';
import { ChargeItemDescription } from './../../../../objects/ChargeItemDescription';
import { Batch, DrugQtyBalance } from './../../../../objects/DrugQtyBalance';
import { Case } from './../../../../objects/Case';

import { DisplayDollarPipe } from './../../../../pipes/display-dollar.pipe';

import { DISPLAY_DATE_FORMAT, INPUT_DELAY } from '../../../../constants/app.constants';

import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { VaccineDetailsModalComponent } from '../vaccine-details-modal/vaccine-details-modal.component';
import { VisitManagementService } from '../../../../services/visit-management.service';
import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';
import {MedicalCoverageItemInfoQuery} from "../../../../services/states/akita-medical-coverage-item-info.query";
import {SflDetailsModalComponent} from "../sfl-details-modal/sfl-details-modal.component";

@Component({
  selector: 'app-prescription-item',
  templateUrl: './prescription-item.component.html',
  styleUrls: ['./prescription-item.component.scss'],
})
export class PrescriptionItemComponent implements OnInit, OnDestroy {
  @Input() prescriptionItem: FormGroup;
  @Input() index: number;
  @Output() onDelete = new EventEmitter<any>();
  @Output() updatePrice = new EventEmitter<boolean>();
  @Input() vaccineDosages;
  @Output()
  onTopChargeItemDescriptionChanged = new EventEmitter<ChargeItemDescription>();
  @Output() precriptionItemQtyChange = new EventEmitter<string>();
  @Input() drugItemIndex

  loading = false;
  isCollapsed = false;
  isDiscountShown = false;

  plans = [];
  errors = [];
  baseUom = [];
  plansInSO = [];
  selectedItems = [];
  vaccinations: Array<any> = [];
  doctors: any[];
  routeOfAdministrations: any[] = [];

  code;
  itemSelected;
  dosageMin = 1;
  defaultQty = 1;
  FIXED_DECIMAL = 2;
  salesUom = '';
  case: Case;
  price: number;
  totalPrice: FormControl;
  unitPriceDisplay: number;
  calculatedAmountDisplay: string;
  isServiceOrTest: boolean;
  itemIsValid = true;
  isVaccineItemValid = true;

  isPackagedItem = false;

  codesTypeahead = new Subject<string>();
  topChargeItemDescription: ChargeItemDescription;
  instructions: Array<Instruction>;
  dosageInstructions: Array<any>;
  dosageInstruction: FormControl;
  currentDosageInstruction: string;
  dollarMask: DisplayDollarPipe;
  dispensingMultiples;
  restrictPriceUpdate = false

  minDate: Date = new Date();
  datePickerConfig: DatePickerConfig = new DatePickerConfig(
    'Expiry Date',
    null,
    null,
    'left',
    'top'
  );

  private componentDestroyed: Subject<void> = new Subject();

  private originalTotalPriceBeforeAdjusting: number = 0;

  get formErrors(): string[] {
    return Object.keys(this.prescriptionItem.errors || {})
  }

  constructor(
    private apiPatientInfoService: ApiPatientInfoService,
    private apiCaseManagerService: ApiCaseManagerService,
    private caseChargeFormService: CaseChargeFormService,
    private store: StoreService,
    private utils: UtilsService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private consultationFormService: ConsultationFormService,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private caseVisitQuery: AkitaCaseVisitQuery,
    private dispatchItemService: DispatchItemService,
    private dataService: DispatchItemDataService,
    private visitManagementService: VisitManagementService,
    private apiCmsManagementService: ApiCmsManagementService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private modalService: BsModalService,
    private medicalCoverageItemInfoQuery: MedicalCoverageItemInfoQuery
  ) {
    this.vaccinations = this.store.vaccinationList;
  }

  ngOnInit() {
    if(this.prescriptionItem.get('drugId').value){

      const itemId = this.prescriptionItem.get('drugId').value;

      this.medicalCoverageItemInfoQuery.select()
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(() => {
          let item = this.medicalCoverageItemInfoQuery.getEntity(itemId);
          this.restrictPriceUpdate = item?.restrictPriceUpdate
        })

      this.isPackagedItem = this.isPackagedPrescriptionItem();
      if (this.isPackagedItem) {
        this.prescriptionItem.get('purchaseQty').disable();
        const priceAdjType = this.consultationFormService.getItemPriceAjustmentType(
          itemId
        );
        if (priceAdjType !== 'PACKAGE') {
          this.prescriptionItem.get('adjustedTotalPrice').disable();
        }
      }
      this.getRouteOfAdministrations();

      this.initGeneralDetails();

      this.initItemDetails();

      this.subscribeChangeOnItem();

      this.setMandatoryFields();

      this.disableFields();

      this.updatePrice.emit(true);

      this.calculatedAmountDisplay = (
        this.prescriptionItem.get('calculatedTotalPrice').value || 0
      ).toFixed(2);
      this.isDiscountShown = this.prescriptionItem
        .get('priceAdjustment')
        .get('adjustedValue').value;

      this.isServiceOrTest = !this.caseChargeFormService.isDrugOrVaccine(
        this.prescriptionItem.value
      );
    }
  }

  isPackagedPrescriptionItem(): boolean {
    return (
      this.prescriptionItem.get('packageId').value !== '' &&
      this.prescriptionItem.get('itemType').value === 'PACKAGE'
    );
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  initItemDetails() {
    const sig = this.store
      .getInstructions()
      .find(
        x =>
          x.code === this.prescriptionItem.get('instruction').get('code').value
      );

    // Update Item Code Name, Unit Price, Adjusted Unit Prices, Item Description
    const item =
      this.akitaChargeItemQuery.getActiveChargeItem(
        this.prescriptionItem.get('drugId').value
      ) || null;


    if (item) {
      this.itemSelected = item ? item : [];
      this.code = item.code || 'Not available';

      this.salesUom = this.itemSelected.salesUom || '';

      // Check for Drug Allergies
      this.checkDrugAllergies([this.itemSelected.id]);
      let indications = '';
      if (item.indications) {
        indications = `| ${item.indications}`;
      }
      // Initialise charge, cautionary, sig, remarks, qty and uom
      this.topChargeItemDescription = {
        chargeCode: this.itemSelected.code || '',
        charge: this.itemSelected.name || '',
        cautionary: this.itemSelected.precautionaryInstructions || '',
        sig: sig ? `${sig.instruct} ${indications}` : `${indications}`,
        dosageInstruction: '',
        remarks: this.prescriptionItem.get('remark').value || '',
        qty: this.prescriptionItem.get('purchaseQty').value || '',
        uom: this.itemSelected.salesUom || 0,
        itemId: this.itemSelected.itemId
      };
    }
    this.patchDosageInstruction();

    if (this.isVaccine()) {
      this.patchVaccineData();
    }
  }

  patchVaccineData() {
    const itemId = this.prescriptionItem.get('drugId') || this.prescriptionItem.get('itemId');
    const vaccinationInfo = this.prescriptionItem.get('vaccinationInfo');
    const administrationInfo = vaccinationInfo.get('administrationInfo');
    const administrator = vaccinationInfo.get('administrator');

    if (itemId.value) {
      const vaccine = this.vaccinations.find(v => v.id === itemId.value);
      if (vaccine && vaccine.routeOfAdministration && !administrationInfo.get('route').value) {
        administrationInfo.get('route').patchValue(vaccine.routeOfAdministration);
        administrationInfo.get('route').updateValueAndValidity({ emitEvent: true });
      }
    }

    if (!administrator.get('regNo').value) {
      const visit = this.akitaCaseVisitQuery.getActive();
      let doctorId;
      if (visit.medicalReferenceEntity && visit.medicalReferenceEntity.consultation) {
        doctorId = visit.medicalReferenceEntity.consultation.doctorId;
      } else {
        doctorId = visit.preferredDoctorId;
      }

      if (doctorId) {
        this.doctors = this.store.doctorList.filter(doc => doc.id === doctorId);
        const loggedInDoctor = this.doctors.find(doc => doc.id === this.store.getUser().context['cms-user-id']);

        if (loggedInDoctor) {
          administrator.get('profession').patchValue('DOCTOR');
          administrator.get('regNo').patchValue(this.doctors.length > 0 ? this.doctors[0].mcr : loggedInDoctor.mcr);
          administrator.get('name').patchValue(this.doctors.length > 0 ? this.doctors[0].displayName : loggedInDoctor.displayName);
        } else {
          administrator.get('profession').patchValue('NURSE');
        }
      } else {
        administrator.get('profession').patchValue('NURSE');
      }
    }
  }

  initGeneralDetails() {
    this.topChargeItemDescription = {
      chargeCode: '',
      charge: '',
      cautionary: '',
      sig: '',
      dosageInstruction: '',
      remarks: '',
      qty: '',
      uom: '',
      itemId: ''
    };

    if(this.isVaccine()){
      if(this.vaccineDosages === null || this.vaccineDosages === 'undefined' || this.vaccineDosages.length === 0){
        this.vaccineDosages = this.prescriptionItem.get('vaccineDosageAvailableList').value;
        this.dosageInstructions = this.vaccineDosages.map(item => {
          return JSON.parse(item)
        });
      } else{
        if(this.prescriptionItem.get('vaccineDosageAvailableList') && this.prescriptionItem.get('vaccineDosageAvailableList').value){
          this.vaccineDosages = this.prescriptionItem.get('vaccineDosageAvailableList').value;
          this.dosageInstructions = this.vaccineDosages.map(item => {
            return JSON.parse(item)
          });
        } else{
          // first time adding vaccination from user search
          this.dosageInstructions = this.vaccineDosages;
        }
      }

      if(this.vaccineDosages){
        const vaccineDosageList = this.vaccineDosages.map(item => {
          return JSON.stringify(item);
        })
        this.prescriptionItem.addControl('vaccineDosageAvailableList', new FormControl(vaccineDosageList));
      } else{
        this.prescriptionItem.addControl('vaccineDosageAvailableList', new FormControl([]));
      }

    } else{
      this.dosageInstructions = this.store.getDosageInstructions();
    }

    this.dosageInstruction = new FormControl();
    this.instructions = this.store.getInstructions().filter(instr=> !(instr['status'] && instr['status'] === 'INACTIVE'));
    this.baseUom = this.store.uoms;
    // this.dispatchItemService = this.consultationFormService.getDispatchItemService();
    this.dosageInstructions = this.dosageInstructions.filter(dose=> !(dose.status && dose.status === 'INACTIVE'));
  }

  isVaccine(){
    if(this.prescriptionItem.get('itemType').value === 'VACCINATION'){
      return true;
    }
    return false;
  }

  isDrug(){
    if(this.prescriptionItem.get('itemType').value === 'DRUG'){
      return true;
    }
    return false;
  }

  getSalesUom() {
    const salesUom =
      this.prescriptionItem.get('salesUom') &&
      !!this.prescriptionItem.get('salesUom').value
        ? this.prescriptionItem.get('salesUom').value
        : '';
    return salesUom;
  }

  patchOriAndAdjustedTotalPrice(qty) {
    const sellingPrice = this.prescriptionItem.get('unitPrice').get('price').value;

    if (sellingPrice) {
      const oriTotalPrice = this.prescriptionItem.get('oriTotalPrice');
      const adjustedUnitValue = this.prescriptionItem
        .get('priceAdjustment')
        .get('adjustedValue').value
        ? this.prescriptionItem.get('priceAdjustment').get('adjustedValue')
          .value
        : 0;
      const adjustedTotalPrice = this.prescriptionItem.get(
        'adjustedTotalPrice'
      );

      const finalPrice: number = sellingPrice + +adjustedUnitValue;
      this.originalTotalPriceBeforeAdjusting = parseFloat((Number(qty) * +(Number(sellingPrice) + Number(adjustedUnitValue))).toFixed(5));

      // Patch Original Total Price and Adjusted Total Price
      this.dispatchItemService.patchAmount(
        oriTotalPrice,
        qty,
        sellingPrice,
        false
      );
      this.dispatchItemService.patchAmount(
        adjustedTotalPrice,
        qty,
        finalPrice,
        true,
        true,
        false
      );

      this.patchAdjustedUnitPrice();

      if (this.isPackagedItem) {
        const packageId = this.prescriptionItem.get('packageId').value;
        this.caseVisitQuery.updatePackagePrice(
          packageId,
          parseFloat(((Number(sellingPrice) + Number(adjustedUnitValue)) * 100).toFixed(5))
        );
      }

      this.updatePrice.emit(true);
    }
  }

  patchAdjustedUnitPrice() {
    const originalTotalPriceInCents = this.prescriptionItem.get('oriTotalPrice')
      .value;
    const originalUnitPriceInCent = this.dispatchItemService.getOriginalUnitPriceInCent(
      this.prescriptionItem.get('unitPrice').get('price').value
    );

    const adjustedTotalPriceInCents = this.dispatchItemService.getAdjustedTotalPriceInCents(
      this.originalTotalPriceBeforeAdjusting
    );

    const adjustedAmount = this.prescriptionItem
      .get('priceAdjustment')
      .get('adjustedValue');

    const qty = this.prescriptionItem.get('purchaseQty').value
      ? this.prescriptionItem.get('purchaseQty').value
      : this.defaultQty;
    const adjustedUnitPriceInCents = this.dispatchItemService.getAdjustedUnitPriceInCents(
      adjustedTotalPriceInCents,
      qty
    );

    const adjustedAmountInCents = this.dispatchItemService.getAdjustedAmountInCents(
      adjustedUnitPriceInCents,
      originalUnitPriceInCent
    );

    this.setPriceAdjustmentRemarkValidation(adjustedAmountInCents);

    const adjustedUnitPrice = this.dispatchItemService.setAdjustedUnitPriceUponValidation(
      adjustedUnitPriceInCents
    );

    this.prescriptionItem
      .get('adjustedUnitPrice')
      .patchValue(adjustedUnitPrice);

    this.setAdjustedUnitPriceValidation(
      originalUnitPriceInCent,
      adjustedTotalPriceInCents
    );

    this.patchCalculatedAmount(adjustedUnitPriceInCents);
    adjustedAmount.patchValue(parseFloat((adjustedAmountInCents / 100).toFixed(5)));
    this.updatePrice.emit(true);
  }

  setPriceAdjustmentRemarkValidation(adjustedAmountInCents) {
    const priceAdjustmentRemark = this.prescriptionItem
      .get('priceAdjustment')
      .get('remark');
    if (
      this.dispatchItemService.adjustedPriceLowerThanOriginal(
        adjustedAmountInCents
      )
    ) {
      priceAdjustmentRemark.setValidators([Validators.required]);
      this.isDiscountShown = true;
      if (this.isCollapsed) {
        this.isCollapsed = false;
      }
    } else {
      this.isDiscountShown = false;
      priceAdjustmentRemark.setValidators(null);
      priceAdjustmentRemark.reset();
    }
    priceAdjustmentRemark.markAsTouched();
    priceAdjustmentRemark.updateValueAndValidity({ emitEvent: false });
  }

  setAdjustedUnitPriceValidation(
    originalUnitPriceInCent,
    adjustedTotalPriceInCents
  ) {
    const adjustedUnitPrice = this.prescriptionItem.get('adjustedUnitPrice');
    if (
      this.dispatchItemService.adjustedUnitPriceAbove0(
        originalUnitPriceInCent,
        adjustedTotalPriceInCents
      )
    ) {
      adjustedUnitPrice.setValidators(null);
    } else {
      adjustedUnitPrice.setValidators([Validators.min(0.01)]);
    }
    adjustedUnitPrice.markAsTouched({ onlySelf: true });
    adjustedUnitPrice.updateValueAndValidity({ emitEvent: false });
  }

  patchCalculatedAmount(adjustedUnitPriceInCents) {
    const calculatedAmount = this.dispatchItemService.getCalculatedAmount(
      adjustedUnitPriceInCents,
      this.prescriptionItem.get('purchaseQty').value
    );
    this.calculatedAmountDisplay = calculatedAmount.toFixed(2);
    this.prescriptionItem
      .get('calculatedTotalPrice')
      .patchValue(calculatedAmount);
  }

  onItemDeSelect(item: any) {
    const plans = this.prescriptionItem.get('excludedCoveragePlanIds').value;
    plans.push(item['value']['planId']);
  }

  onClear() {
    const plans = this.prescriptionItem.get('excludedCoveragePlanIds').value;
    plans.splice(0);
    this.plansInSO.forEach(element => {
      plans.push(element['planId']);
    });
  }

  onClearBatchNo() {
    this.prescriptionItem.get('batchNumber').setValidators(null);
    this.prescriptionItem.get('batchNumber').markAsUntouched();
    this.prescriptionItem
      .get('batchNumber')
      .updateValueAndValidity({ emitEvent: false });

    this.prescriptionItem.get('expiryDate').setValidators(null);
    this.prescriptionItem.get('expiryDate').markAsUntouched();
    this.prescriptionItem
      .get('expiryDate')
      .updateValueAndValidity({ emitEvent: false });
  }

  checkValidity() {
    if (
      this.prescriptionItem.valid ||
      this.store.getVisitStatus() === 'PAYMENT'
    ) {
      this.itemIsValid = true;
    } else {
      this.itemIsValid = false;
    }

    if (this.prescriptionItem.get('vaccinationInfo').valid) {
      this.isVaccineItemValid = true;
    } else {
      this.isVaccineItemValid = false;
    }
  }

  subscribeChangeOnItem() {
    // Main Form Changes for Copy Prescription
    this.fillItemValues();

    this.prescriptionItem.valueChanges
      .pipe(
        debounceTime(INPUT_DELAY),
        distinctUntilChanged((a, b) => {
          return a.id === b.id;
        }),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data => {
        // Retrieve price
        this.updatePrice.emit(true);
        this.checkValidity();
      });

    this.prescriptionItem.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        this.checkValidity();
      });

    // Instruction Changes
    this.subscribeChangesOnInstruction();

    // Quantity Changes
    this.subscribeChangesOnUOM();

    // Purchase Quantity
    this.subscribeChangesOnPurchaseQuantity();

    // Dosage Instruction Changes
    this.subscribeChangesOnDosage();

    // Expiry Date
    this.subscribeChangesOnExpiryDate();

    // Remark
    this.subscribeChangesOnRemark();

    // Adjusted Price
    // this.subscribeAdjustedValue();

    // Adjusted Total Price
    this.subscribeChangesOnAdjustedTotalPrice();

    // Batch Number
    this.subscribeChangesOnBatchNoAndInventory();

    // Price Adjustment
    // this.subscribeChangesOnPriceAdjustment();

    // Update Inventories
    this.dispatchItemService
      .getUpdateAllInventoriesRefresh()
      .subscribe(value => {
        if (value) {
          this.updateStockDisplay();
          this.updateInventoryList();

          this.patchInventoryInvalidMsg();
        }
      });
  }

  subscribeChangesOnInstruction() {
    this.prescriptionItem
      .get('instruction')
      .get('code')
      .valueChanges.pipe(
      debounceTime(INPUT_DELAY),
      takeUntil(this.componentDestroyed)
    )
      .subscribe(data => {
        this.updateInstructionToTopDescription(
          this.prescriptionItem.get('instruction').value
        );
      });
  }

  subscribeChangesOnUOM() {

  }

  subscribeChangesOnPurchaseQuantity() {
    this.prescriptionItem
      .get('purchaseQty')
      .valueChanges.pipe(
      debounceTime(INPUT_DELAY),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed)
    )
      .subscribe(data => {
        const qty = data || 1;
        if (qty) {
          this.prescriptionItem
            .get('purchaseQty')
            .patchValue(qty, { onlySelf: true });
          this.topChargeItemDescription.qty = qty;

          this.setDrugDispenseInBatch(qty);

          this.updateStockDisplay();
          this.patchInventoryInvalidMsg();
          this.patchOriAndAdjustedTotalPrice(qty);

          this.precriptionItemQtyChange.emit(this.code);
        }
      });
  }

  subscribeChangesOnDosage() {
    this.prescriptionItem
      .get('dosageInstruction')
      .get('code')
      .valueChanges.pipe(
      debounceTime(INPUT_DELAY),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed)
    )
      .subscribe(value => {
        this.prescriptionItem
          .get('dosageInstruction')
          .get('code')
          .patchValue(value);

        const dosageInstructionInstruct = this.prescriptionItem
          .get('dosageInstruction')
          .get('instruct');
        this.getDosageInstruction(value);
        this.updateDosageInstructionToTopDescription(
          dosageInstructionInstruct.value
        );

        if (
          dosageInstructionInstruct.value &&
          this.dosageInstructionIncludesHash()
        ) {
          this.patchDosageInstruction();
        } else {
          this.disableDosageQuantity();
        }
      });

    // Dosage Instruction Quantity Changes
    this.prescriptionItem
      .get('dose')
      .get('quantity')
      .valueChanges.pipe(
      debounceTime(INPUT_DELAY),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed)
    )
      .subscribe(value => {
        // this.setDosageValidators();
        this.patchDosageInstruction();
      });
  }

  subscribeChangesOnExpiryDate() {
    this.prescriptionItem
      .get('expiryDate')
      .valueChanges.pipe(
      map(d => {
        d = moment(d, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
        const isValid = moment(d, DISPLAY_DATE_FORMAT).isValid();
        return isValid ? d : '';
      }),
      takeUntil(this.componentDestroyed)
    )
      .subscribe(data => {
        this.prescriptionItem
          .get('expiryDate')
          .patchValue(data, { emitEvent: false });
      });
  }

  subscribeChangesOnRemark() {
    this.prescriptionItem
      .get('remark')
      .valueChanges.pipe(
      debounceTime(INPUT_DELAY),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed)
    )
      .subscribe(value => {
        this.prescriptionItem
          .get('remark')
          .patchValue(value);
        this.updateRemarkToTopDescription(value);
      });
  }

  subscribeChangesOnAdjustedTotalPrice() {
    this.prescriptionItem
      .get('adjustedTotalPrice')
      .valueChanges.pipe(
      debounceTime(700),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed)
    )
      .subscribe(valueInDollars => {
        this.originalTotalPriceBeforeAdjusting = valueInDollars
        if (valueInDollars !== null && valueInDollars >= 0) {
          if (this.isPackagedItem) {
            const packageId = this.prescriptionItem.get('packageId').value;
            this.caseVisitQuery.updatePackagePrice(
              packageId,
              valueInDollars * 100
            );
          }
          this.patchAdjustedUnitPrice();
        }

        this.prescriptionItem
          .get('adjustedTotalPrice')
          .patchValue(Number(valueInDollars).toFixed(2), { emitEvent: false });
      });
  }

  subscribeChangesOnBatchNoAndInventory() {
    this.prescriptionItem
      .get('batchNumber')
      .valueChanges.pipe(
      distinctUntilChanged(),
      startWith(this.prescriptionItem.get('batchNumber').value),
      pairwise(),
      takeUntil(this.componentDestroyed)
    )
      .subscribe(([previousBatchNo, newBatchNo]: [any, any]) => {
        const drugId = this.prescriptionItem.get('drugId').value;
        const purchaseQty: number = this.prescriptionItem.get('purchaseQty')
          .value;
        const drugDispenseShortId: number = this.prescriptionItem.get(
          'drugDispenseShortId'
        ).value;

        if (
          previousBatchNo !== undefined &&
          previousBatchNo !== null &&
          previousBatchNo !== ''
        ) {
          this.dispatchItemService.deleteDrugSubscriptionFromBatch(
            drugId,
            previousBatchNo,
            drugDispenseShortId
          );
        }

        if (newBatchNo !== undefined && newBatchNo !== null) {
          this.setDrugDispenseInBatch(purchaseQty);
        }
        this.patchInventoryInvalidMsg();

        this.updateSelectedBatch(newBatchNo);

        this.updateStockDisplay();
        this.dispatchItemService.updateAllInventories();
      });
  }

  onDosageInstructionSelect(option) {
    if (option) {
      // this variable to store the original instruction and to be used in case replacement is needed
      const dosageInstruct = this.prescriptionItem
        .get('dosageInstruction')
        .get('instruct');

      if(this.isVaccine()){
        this.currentDosageInstruction = option.description;
        dosageInstruct.patchValue(option.description);
      } else{
        this.currentDosageInstruction = option.instruct;
        dosageInstruct.patchValue(option.instruct);
      }
    }
  }

  setDrugDispenseInBatch(purchaseQty) {
    const batchNo = this.prescriptionItem.get('batchNumber').value;
    const drugId = this.prescriptionItem.get('drugId').value;
    const drugDispenseShortId = this.prescriptionItem.get('drugDispenseShortId')
      .value;
    this.dispatchItemService.setDrugDispenseQtyFromBatch(
      drugId,
      batchNo,
      purchaseQty,
      drugDispenseShortId
    );
  }

  onInstructionSelect(option) {
    this.prescriptionItem.get('instruction').patchValue(option);
    this.updateInstructionToTopDescription(option);
  }

  searchFn(term: string, option) {
    term = term.toLocaleLowerCase();
    const hasWhiteSpace = term.indexOf(' ') > -1 ? true : false;

    // If string is less than 4 characters and does not contain space,
    // search only through code
    if (!hasWhiteSpace) {
      if (term.length < 4) {
        return option.code?.toLocaleLowerCase().indexOf(term) > -1;
      }
    }

    term = term.trim();
    return (
      option.instruct?.toLocaleLowerCase().indexOf(term) > -1 ||
      option.code?.toLocaleLowerCase().indexOf(term) > -1
    );
  }

  /** ng-select change detection */
  resetFields(option: any) {
    this.prescriptionItem.get('drugId').patchValue(option.id);

    this.prescriptionItem
      .get('expiryDate')
      .patchValue(this.utils.getDBDateOnly(''));
    this.prescriptionItem
      .get('instruction')
      .get('code')
      .patchValue('');
    this.prescriptionItem
      .get('instruction')
      .get('instruct')
      .patchValue('');
    this.prescriptionItem.get('duration').patchValue('');
    this.prescriptionItem
      .get('dosageInstruction')
      .get('code')
      .patchValue('');
    this.prescriptionItem
      .get('dosageInstruction')
      .get('instruct')
      .patchValue('');
    // Inventory values reset
    this.prescriptionItem.patchValue({
      batchNumber: '',
      expiryDate: '',
      stock: '-',
    });
  }

  updateStockDisplay() {
    const drugQtyBalance: DrugQtyBalance = this.dispatchItemService.getDrugQtyBalance(
      this.prescriptionItem.get('drugId').value
    );

    if (drugQtyBalance) {
      this.prescriptionItem
        .get('stock')
        .patchValue(drugQtyBalance ? drugQtyBalance.getRemainingQty() : '-', {
          emitEvent: false,
        });
    }
  }

  updateSelectedBatch(batchNo) {
    const inventories = this.prescriptionItem.get('inventories').value;
    const isDrugOrVaccine = this.caseChargeFormService.isDrugOrVaccine(
      this.itemSelected
    );
    const selectedInventory: Batch = this.dispatchItemService.getDrugBatch(
      this.itemSelected.id,
      batchNo
    );
    const expiryDate = selectedInventory
      ? selectedInventory.expiryDate
      : isDrugOrVaccine
        ? moment(new Date()).format(DISPLAY_DATE_FORMAT)
        : '';

    this.prescriptionItem
      .get('expiryDate')
      .patchValue(expiryDate, { emitEvent: false });
  }

  getCaseItemPrice(drugId: string, qty: number) {
    const excludedPlans = this.prescriptionItem.get('excludedCoveragePlanIds')
      .value;

    if (this.itemSelected) {
      const caseItem = {
        chargeDetails: [
          this.caseChargeFormService.buildChargeDetailsItem(
            drugId,
            excludedPlans,
            qty
          ),
        ],
      };

      this.apiCaseManagerService
        .getCaseItemPrices(this.store.getCaseId(), caseItem)
        .pipe(debounceTime(INPUT_DELAY), take(1))
        .subscribe(
          data => {
            const caseItems = data.payload.chargeDetails;
            const caseItem = caseItems.find(data => {
              return data.itemId === this.itemSelected.id;
            });
            const caseItemsInventories = data.payload.inventoryData;
            const caseItemInventories = caseItemsInventories.filter(data => {
              return data.itemId === this.itemSelected.id;
            });
            if (caseItem) {
              this.prescriptionItem
                .get('chargeBigDeci').patchValue(caseItem.chargeBigDeci);
              const price = parseFloat((caseItem.chargeBigDeci.price).toFixed(5));

              this.patchPriceData(price);

              this.patchOriAndAdjustedTotalPrice(qty);

              this.updatePrice.emit(true);
              // caseItemInventories = this.dispatchItemService.sortCaseItemInventories(caseItemInventories);

              // Populating inventory and drug quantity balance in service
              this.updateDrugBalance(caseItemInventories);

              // Retrieve dropdown list for inventory
              this.updateInventoryList();

              // Pre-select batch for dropdown list
              this.updateBatchList();
            }

            this.patchDispatchItem(qty);
          },
          err => {
            this.alertService.error(JSON.stringify(err.error.message));
          }
        );
    }
  }

  patchPriceData(price: number) {
    this.prescriptionItem
      .get('unitPrice')
      .get('price')
      .patchValue(price);
    this.unitPriceDisplay = parseFloat((price).toFixed(5));
  }

  updateDrugBalance(caseItemInventories) {
    if (
      !this.dispatchItemService.drugBalanceAvailable(
        this.prescriptionItem.get('drugId').value
      )
    ) {
      this.dispatchItemService.addNewDrugQtyBalance(
        this.prescriptionItem.get('drugId').value,
        caseItemInventories
      );
    }
  }

  updateBatchList() {
    const drugId = this.prescriptionItem.get('drugId').value;
    const batchList = this.dispatchItemService.getDrugBatchDropdownList(drugId);
    const stockQuantity = this.dispatchItemService.getDrugQtyBalance(drugId);

    const batchNumber = this.prescriptionItem.get('batchNumber').value;
    if (!batchNumber && !!batchList && batchList.length) {
      this.prescriptionItem.get('batchNumber').patchValue(batchList[0].batchNo);
      this.prescriptionItem
        .get('expiryDate')
        .patchValue(batchList[0].expiryDate);
      this.prescriptionItem
        .get('stock')
        .patchValue(
          stockQuantity && stockQuantity.getRemainingQty()
            ? stockQuantity.getRemainingQty()
            : '-'
        );
    }
  }

  updateInventoryList() {
    const inventories: FormArray = this.prescriptionItem.get(
      'inventories'
    ) as FormArray;
    while (inventories.length) {
      inventories.removeAt(0);
    }
    const batchList =
      this.dispatchItemService.getDrugBatchDropdownList(
        this.prescriptionItem.get('drugId').value
      ) || [];
    batchList.forEach(inventory => {
      inventories.push(this.fb.group({ ...inventory }));
    });
  }

  patchDosageInstruction() {
    const code = this.prescriptionItem.get('dosageInstruction').get('code');
    const instruction = this.prescriptionItem
      .get('dosageInstruction')
      .get('instruct');
    const doseQty = this.prescriptionItem.get('dose').get('quantity');

    if (
      instruction.value === undefined ||
      instruction.value === '' ||
      instruction.value === null
    ) {
      this.getDosageInstruction(code.value);
    } else {
      if (this.dosageInstructionIncludesHash()) {
        this.setDosageValidators();
        const instruct = doseQty.value
          ? instruction.value.replace('#', doseQty.value)
          : this.currentDosageInstruction;
        this.updateDosageInstructionToTopDescription(instruct);
      } else {
        this.updateDosageInstructionToTopDescription(instruction.value);
        this.disableDosageQuantity();
      }
    }
  }

  getDosageInstruction(code) {
    const instruction = this.prescriptionItem
      .get('dosageInstruction')
      .get('instruct');
    if (
      instruction.value === '' ||
      instruction.value === undefined ||
      instruction.value === null
    ) {
      const dosageInstruction = this.store.getDosageInstructionByCode(code);
      if (dosageInstruction) {
        instruction.patchValue(dosageInstruction.instruct);
      }
    }
  }

  disableDosageQuantity() {
    const doseQty = this.prescriptionItem.get('dose').get('quantity');
    doseQty.setValidators(null);
    doseQty.markAsTouched();
    doseQty.updateValueAndValidity();
    doseQty.reset();
    doseQty.disable();
  }

  updateTopDescription() {
    this.onTopChargeItemDescriptionChanged.emit(this.topChargeItemDescription);
  }

  updateDrugToTopDescription(chargeItem) {
    this.topChargeItemDescription.charge = chargeItem['name'] || '';
    this.topChargeItemDescription.uom = chargeItem['salesUom'] || '';
    this.updateTopDescription();
  }

  updateDosageInstructionToTopDescription(dosageInstruction) {
    if (dosageInstruction) {
      this.topChargeItemDescription.dosageInstruction = dosageInstruction + '/';
    } else {
      this.topChargeItemDescription.dosageInstruction = '';
    }
    this.updateTopDescription();
  }

  updateCautionariesToTopDescription(chargeItem) {
    this.topChargeItemDescription.cautionary =
      chargeItem['precautionaryInstructions'];
    this.updateTopDescription();
  }

  updateInstructionToTopDescription(chargeItem) {
    const item = this.instructions
      ? this.instructions.filter(x => x.code === chargeItem.code)
      : [];
    if (item[0]) {
      const currentSIG = this.topChargeItemDescription.sig;
      const indications = currentSIG.split('|')[1];
      if (!!indications) {
        this.topChargeItemDescription.sig = `${item[0]['instruct']} | ${indications}`;
      } else {
        this.topChargeItemDescription.sig = `${item[0]['instruct']}`;
      }
      this.updateTopDescription();
    }
  }

  updateRemarkToTopDescription(remark) {
    this.topChargeItemDescription.remarks = remark;
    this.updateTopDescription();
  }

  optionsPressed() {}

  deletePressed() {
    const indexes = {
      caseChargeIndex: this.index,
      dispenseQtyIndex: this.prescriptionItem.get('drugDispenseShortId'),
    };
    this.onDelete.emit(indexes);
    this.precriptionItemQtyChange.emit(this.code);

    if (this.isPackagedItem) {
      const packageId = this.prescriptionItem.get('packageId').value;
      this.consultationFormService.deletePackageItem(packageId);
    }
  }

  disableFields() {
    if (this.store.getVisitStatus() !== 'PAYMENT') {
      this.caseChargeFormService.disableFieldsBasedOnItem(
        this.prescriptionItem,
        this.itemSelected
      );
    } else {
      this.caseChargeFormService.disableAllFields(this.prescriptionItem);
    }
  }

  patchInventoryInvalidMsg() {
    if (!this.itemSelected) {
      return;
    }

    if (this.caseChargeFormService.isDrugOrVaccine(this.itemSelected)) {
      const purchaseQty = this.prescriptionItem.get('purchaseQty').value;
      const batchNumber = this.prescriptionItem.get('batchNumber').value;
      const shortId = this.prescriptionItem.get('drugDispenseShortId').value;
      const inventoryInvalidMsg = this.consultationFormService.inventoryIsValid(
        batchNumber,
        this.itemSelected.id,
        shortId
      );
      this.prescriptionItem
        .get('inventoryInvalid')
        .patchValue(inventoryInvalidMsg);
    }
  }

  setMandatoryFields() {
    if (
      !this.caseChargeFormService.isService(this.itemSelected) &&
      this.store.getVisitStatus() !== 'PAYMENT'
    ) {
      this.prescriptionItem
        .get('instruction')
        .get('code')
        .setValidators([Validators.required]);
      this.prescriptionItem
        .get('instruction')
        .get('code')
        .markAsTouched({ onlySelf: true });
      this.prescriptionItem
        .get('instruction')
        .get('code')
        .updateValueAndValidity();

      if (this.dosageInstructionIncludesHash()) {
        this.setDosageValidators();
      } else {
        this.prescriptionItem
          .get('dose')
          .get('quantity')
          .disable();
      }

      const dosageInstruction = this.prescriptionItem
        .get('dosageInstruction')
        .get('instruct');
      if (
        dosageInstruction.value === undefined ||
        dosageInstruction.value === '' ||
        dosageInstruction.value === null
      ) {
        this.setDosageValidators();
      } else {
        this.prescriptionItem
          .get('dose')
          .get('quantity')
          .disable({ emitEvent: false });
      }
    }

    this.prescriptionItem
      .get('purchaseQty')
      .setValidators([Validators.required, Validators.min(1)]);
    this.prescriptionItem.get('purchaseQty').markAsTouched({ onlySelf: true });
    this.prescriptionItem.get('purchaseQty').updateValueAndValidity();

    this.patchInventoryInvalidMsg();

    if (this.isVaccine()) {
      this.updateVaccinationValidations();
    }
  }

  updateVaccinationValidations() {
    const vaccinationInfo = this.prescriptionItem.get('vaccinationInfo');
    const administrationInfo = vaccinationInfo.get('administrationInfo');
    const administrator = vaccinationInfo.get('administrator');

    administrationInfo.get('route').setValidators([Validators.required]);
    vaccinationInfo.get('givenDate').setValidators([Validators.required]);
    administrator.get('profession').setValidators([Validators.required]);
    administrator.get('regNo').setValidators([Validators.required]);
  }

  dosageInstructionIncludesHash() {
    if (
      this.prescriptionItem
        .get('dosageInstruction')
        .get('instruct')
        .value.includes('#')
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkDrugAllergies(id) {
    if (this.caseChargeFormService.isDrugOrVaccine(this.itemSelected)) {
      this.errors = [];
      this.apiPatientInfoService
        .checkAllergies(this.akitaPatientAppQuery.getId(), id)
        .pipe(debounceTime(INPUT_DELAY))
        .subscribe(
          res => {
            if (res.payload && res.payload.allergies) {
              const drugAllergies: Array<string> = res.payload.allergies;

              // assume only checking for 1 drug
              if (drugAllergies[0] === id[0]) {
                this.errors = [];
                this.errors.push('This patient is allergic to this medicine.');
                this.consultationFormService.showAllergyWarningMessage(
                  this.topChargeItemDescription.charge, undefined
                );
              } else {
                this.errors = [];
              }
            }
            if (res.payload && res.payload.adreactions) {
              const drugReactions: Array<any> = res.payload.adreactions;
              // assume only checking for 1 drug
              if (drugReactions.length && drugReactions[0].drugId === id[0]) {
                this.errors.push('This patient has drug reaction to this medicine.');
                const custmMsg = this.topChargeItemDescription.charge + '('+  drugReactions[0].allergies.toString() + ')';
                this.consultationFormService.showAllergyWarningMessage(
                  undefined, custmMsg
                );
              }
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err.error['message']));
          }
        );
    }
  }

  setDosageValidators() {
    if (this.store.getVisitStatus() !== 'PAYMENT' && !this.isVaccine()) {
      const dosageQty = this.prescriptionItem.get('dose').get('quantity');
      const uomInput = this.prescriptionItem
        .get('dose')
        .get('uom')
        .value.toLocaleLowerCase();

      const uom: Uom =
        this.store.uoms.find(item => item.uom.toLowerCase() === uomInput) ||
        new Uom();
      this.dosageMin = uom.multiply;

      dosageQty.enable();
      dosageQty.setValidators([
        Validators.required,
        Validators.min(this.dosageMin),
        mulitplierValidator(this.dosageMin),
      ]);
      dosageQty.markAsTouched({ onlySelf: true });
      dosageQty.updateValueAndValidity();
    }
  }

  rowClicked($event) {
    this.isCollapsed = !this.isCollapsed;
  }

  fillItemValues() {
    const drugId = this.itemSelected.id;
    if (drugId) {
      // Drug Code
      // this.prescriptionItem.get('drugId').patchValue(drugId);
      this.dispensingMultiples = this.itemSelected['dispensingMultiples'] || 1;
      let puchageQty = this.prescriptionItem.get('purchaseQty').value;
      if (!puchageQty) {
        puchageQty = this.dispatchItemService.getDefaultPurchaseQty(
          this.itemSelected
        );
      }

      const unitPrice = this.prescriptionItem.get('unitPrice').get('price');
      // Unit Price
      if (!unitPrice.value) {
        this.getCaseItemPrice(drugId, puchageQty);
      } else {
        this.unitPriceDisplay = parseFloat(unitPrice.value.toFixed(5));
        this.patchDispatchItem(puchageQty);
      }
    }
  }

  patchDispatchItem(qty: number) {
    this.patchDrugRelatedDefaultInfo();
    const purchaseQty = this.prescriptionItem.get('purchaseQty');
    purchaseQty.patchValue(qty, { onlySelf: true });
    this.setTopDescription(this.itemSelected);
    this.updateTopDescription();
  }

  patchDrugRelatedDefaultInfo() {
    const chargeItemDetail = this.itemSelected;
    if (!this.caseChargeFormService.isService(this.itemSelected)) {
      const dosageInstructionCode = this.prescriptionItem
        .get('dosageInstruction')
        .get('code');

      if (!dosageInstructionCode.value) {
        dosageInstructionCode.patchValue(
          chargeItemDetail['dosageInstructionCode']
        );
      }

      const dosageQty = this.prescriptionItem.get('dose').get('quantity');
      if (!dosageQty.value) {
        dosageQty.patchValue(chargeItemDetail['dosageQty']);
      }

      const instructionCode = this.prescriptionItem
        .get('instruction')
        .get('code');
      if (!instructionCode.value) {
        instructionCode.patchValue(chargeItemDetail['frequencyCode']);
      }

      const duration = this.prescriptionItem.get('duration');
      if (!duration.value) {
        duration.patchValue(1);
      }

      const doseUOM = this.prescriptionItem.get('dose').get('uom');
      if (doseUOM.value === '') {
        doseUOM.patchValue(chargeItemDetail['dosageUom']); //dome
      }
    }

    this.salesUom = chargeItemDetail.salesUom;
    if (this.salesUom) {
      const salesUOM = this.prescriptionItem.get('salesUom');
      salesUOM.patchValue(chargeItemDetail['salesUom']);
    }
  }

  setTopDescription(itemDetailedInfo) {
    const remarks = this.prescriptionItem.get('remark').value || '';
    const purchaseQty = this.prescriptionItem.get('purchaseQty').value || '';
    const instructionCode = this.prescriptionItem.get('instruction.code').value;
    const sig = this.dataService.getInstructions(instructionCode);

    let indications = '';
    if (itemDetailedInfo.indications) {
      indications = `| ${itemDetailedInfo.indications}`;
    }

    // Initialise Top Charge Remarks
    this.topChargeItemDescription = {
      chargeCode: itemDetailedInfo.code || '',
      charge: itemDetailedInfo.name || '',
      cautionary: itemDetailedInfo.precautionaryInstructions || '',
      sig: sig ? `${sig.instruct} ${indications}` : `${indications}`,
      dosageInstruction: '',
      remarks,
      qty: purchaseQty,
      uom: this.salesUom || '',
      itemId: itemDetailedInfo.id,
    };
  }

  showEditVaccineDetails() {
    const initialState = {
      title: 'Vaccination additional details',
      prescriptionItem: this.prescriptionItem,
      vaccineName: this.truncateDesc(this.topChargeItemDescription.charge, 50),
      dosageInstructions: this.dosageInstructions,
      instructions: this.instructions,
      dosageMin: this.dosageMin,
      isDoctorConsult: true,
    };

    this.modalService.show(VaccineDetailsModalComponent, {
      initialState,
      class: 'modal-xl',
      backdrop: 'static',
      keyboard: false,
    });
  }

  isSfl() {
    return this.caseChargeFormService.isSfl(this.prescriptionItem.value.drugId)
  }

  showEditSflDetails() {
    const initialState = {
      title: 'Sfl additional details',
      data: this.prescriptionItem.get('sflData').get('screeningType').value ?
        this.prescriptionItem.get('sflData').value :
        {
          ...this.caseChargeFormService.findSflData(this.prescriptionItem.value.drugId),
          screenDate: moment().toDate()
        }
    }

    let bsModalRef = this.modalService.show(SflDetailsModalComponent, {
      initialState,
      class: 'modal-xl custom-modal',
      backdrop: 'static',
      keyboard: false,
    });

    bsModalRef.content.submit.subscribe(value => {
      this.prescriptionItem.patchValue({
        sflData: value
      })
      bsModalRef.hide()
    })
  }

  getRouteOfAdministrations() {
    const routes = this.store.routeOfAdministrators;
    this.routeOfAdministrations = routes.filter(route => route.nehrCodeDispensedMed);
  }

  searchRouteFn(term: string, option) {
    term = term.toLocaleLowerCase();
    const hasWhiteSpace = term.indexOf(' ') > -1 ? true : false;

    if (!hasWhiteSpace) {
      if (term.length < 4) {
        return option.name.toLocaleLowerCase().indexOf(term) > -1;
      }
    }

    term = term.trim();
    return option.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  checkClick($event) {
    // To prevent bubbling of child click event to parent's onclick event
    event.stopPropagation();
  }

  truncate(text) {
    return this.utilsService.textTruncate(text, 12);
  }
  truncateDesc(text, truncateTo: number) {
    return this.utilsService.textTruncate(text, truncateTo);
  }

  configureOverflowOnMouseOver($event) {
    const chargeItemsList = document.getElementById('chargeItemsList');
    chargeItemsList.style.overflow = 'hidden';
  }

  configureOverflowOnMouseLeave($event) {
    const chargeItemsList = document.getElementById('chargeItemsList');
    chargeItemsList.style.overflow = 'scroll';
  }
}
