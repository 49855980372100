// import { EmergencyContactNumber } from './../UserRegistrationObject';
import Allergy from './../Allergy';
import { Company } from './../Company';
import { Address } from './../Address';
import UserId from '../UserId';
import PrimaryCareNetwork from '../PrimaryCareNetwork';
import { all } from 'q';
import { Vaccination } from '../Vaccination';
import OnGoingMedication from '../OnGoingMedication';
import PatientNewBorn from '../PatientNewBorn';
import { RelationshipEntity } from '../request/RelationshipEntity';

export default class PatientInfo {
  id: string;
  title: string;
  preferredMethodOfCommunication: string;
  consentGiven: boolean;
  race: string;
  preferredLanguage: string;
  primaryCareNetwork: PrimaryCareNetwork;
  registrationDate: string;
  lastVisitedDate: string;
  name: string;
  dob: string;
  userId: UserId;
  gender: string;
  contactNumber: ContactNumber;
  secondaryNumber: ContactNumber;
  // emergencyContactNumber: EmergencyContactNumber;
  relationshipEntities: RelationshipEntity[];
  status: string;
  address: Address;
  emailAddress: string;
  company: Company;
  nationality: string;
  maritalStatus: string;
  allergies: Allergy[];
  patientVaccinations: Vaccination[];
  onGoingMedications: OnGoingMedication[];
  fileMetaData: any[];
  patientNewBorn: PatientNewBorn;
  patientExtraDetails: PatientExtraDetails;

  constructor(
    id?: string,
    title?: string,
    preferredMethodOfCommunication?: string,
    consentGiven?: boolean,
    race?: string,
    preferredLanguage?: string,
    primaryCareNetwork?: PrimaryCareNetwork,
    registrationDate?: string,
    lastVisitedDate?: string,
    name?: string,
    dob?: string,
    userId?: UserId,
    gender?: string,
    contactNumber?: ContactNumber,
    status?: string,
    address?: Address,
    emailAddress?: string,
    company?: Company,
    nationality?: string,
    maritalStatus?: string,
    relationshipEntities?: RelationshipEntity[],
    allergies?: Allergy[],
    patientVaccinations?: Vaccination[],
    onGoingMedications?: OnGoingMedication[],
    fileMetaData?: any[],
    patientNewBorn?: PatientNewBorn,
    patientExtraDetails?: PatientExtraDetails
  ) {
    this.id = id || '';
    this.title = title || '';
    this.preferredMethodOfCommunication = preferredMethodOfCommunication || '';
    this.consentGiven = consentGiven === undefined ? false : consentGiven;
    this.race = race || '';
    this.preferredLanguage = preferredLanguage || '';
    this.primaryCareNetwork =
      primaryCareNetwork === undefined
        ? new PrimaryCareNetwork()
        : primaryCareNetwork;
    this.registrationDate = registrationDate || '';
    this.lastVisitedDate = lastVisitedDate || '';
    this.name = name || '';
    this.dob = dob || '';
    this.userId = userId === undefined ? new UserId() : userId;
    this.gender = gender || '';
    this.contactNumber =
      contactNumber === undefined ? new ContactNumber() : contactNumber;
    this.status = status || '';
    this.address = address === undefined ? new Address() : address;
    this.emailAddress = emailAddress || '';
    this.company = company === undefined ? new Company() : company;
    this.nationality = nationality || '';
    this.maritalStatus = maritalStatus || '';
    this.relationshipEntities = relationshipEntities === undefined ? new Array<RelationshipEntity>() : relationshipEntities;
    this.allergies = allergies === undefined ? new Array<Allergy>() : allergies;
    this.patientVaccinations =
      patientVaccinations === undefined
        ? new Array<Vaccination>()
        : patientVaccinations;
    this.onGoingMedications =
      onGoingMedications === undefined
        ? new Array<OnGoingMedication>()
        : onGoingMedications;
    this.fileMetaData = fileMetaData === undefined ? [] : fileMetaData;
    this.patientNewBorn = patientNewBorn === undefined ? new PatientNewBorn() : patientNewBorn;
    this.patientExtraDetails = patientExtraDetails === undefined ? new PatientExtraDetails() : patientExtraDetails;
  }
}

export class ContactNumber {
  number: string;

  constructor(number?: string) {
    this.number = number || '';
  }
}

export class PatientExtraDetails {
  chasUsage: string;
  smokingStatus: string;
  rightSiting: boolean;
  referenceSource: string;
  remark: string;
  rightSitingReferenceSource: string;
  yearStartOfSmoke: string;
  stateOfChange: any;
  constructor(chasUsage?: string, smokingStatus?: string, rightSiting?: boolean, referenceSource?: string, remark?: string, rightSitingReferenceSource?: string, yearStartOfSmoke?: string, stateOfChange?: any) {
    this.chasUsage = chasUsage || '';
    this.smokingStatus = smokingStatus || null;
    this.rightSiting = rightSiting || false;
    this.referenceSource = referenceSource || '';
    this.remark = remark || '';
    this.rightSitingReferenceSource = rightSitingReferenceSource || '';
    this.yearStartOfSmoke = yearStartOfSmoke || '';
    this.stateOfChange = stateOfChange || null;
  }
}
