import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import {
  createReferralData,
  ReferralData,
} from '../../objects/state/ReferralData';

export function createInitialState(): ReferralData {
  return createReferralData();
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'referraldata', cache: { ttl: AKITA_CACHE_DURATION } })
export class AkitaReferralStore extends Store<ReferralData> {
  constructor() {
    super(createInitialState());
  }

  addNewData(data) {
    this.update(data);
  }

}
