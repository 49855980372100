import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT, DB_FULL_DATE_FORMAT } from '../../../constants/app.constants';
import { PriceAdjustment } from '../../../objects/Case';

import { generate } from 'shortid';

export const enum PRICE_ADJUSTMENT_TYPE {
  'PACKAGE' = 'PACKAGE',
  'SUB_ITEM' = 'SUB_ITEM',
  'FIXED' = 'FIXED'
}

export const enum PACKAGE_STATUS {
  'ON_GOING' = 'ON_GOING',
  'COMPLETED' = 'COMPLETED',
}


export const enum PACKAGE_TYPE {
  'PRE'= 'PRE',
  'PER_SESSION' = 'PER_SESSION',
  'POST' = 'POST'
}

export interface IPackageItem {
  itemRefId: string;
  code: string;
  name: string;
  packageQty: number;
  purchasePrice: number;
  purchaseDate: string;
  expireDate: string;
  packageType: PACKAGE_TYPE;
  // status: string;
  packageId: string;
  dispatches: IDispaches[];
  priceAdjustmentType: PRICE_ADJUSTMENT_TYPE,
  // excludedPlans: string[],
  //fields required by frontend only
  itemIsValid?: boolean,
  noInventoryInfo?: boolean
}

export interface IDispaches {
  itemId: string;
  itemCode: string;
  itemName: string;
  duration: number;
  dosage: number;
  quantity: number;
  oriTotalPrice: number;
  batchNo: string;
  excludedCoveragePlanIds: string[];
  utilize: boolean;
  payable: boolean;
  utilizedDate: string;
  purchasedId?: string;
  inventoried: boolean,
  visitId: string;
  salesItemId: string;
  expiryDate: string;
  itemPriceAdjustment: PriceAdjustment;
  dosageInstruction: any;
  vaccinationInfo: VaccinationInfo;
  routeOfAdministration: string;
  remarks: string;
  // loaclly set for ui only
  drugDispenseShortId: string;
  parentItemPackageId: string;
  isAvailable?: boolean;
  isPatientAllergic: boolean;

}

export class VaccinationInfo {
  givenDate: string;
  vaccineDosage: number;
  administrator: ClinicPerson;
  administrationInfo: AdministrationInfo;
  multiVaccineSubItems: SubItem[];
  vaccinationSchedules: VaccinationSchedule[];
}

export class ClinicPerson {
  regNo: string;
  name: string;
  profession: string;
}

export class AdministrationInfo {
  site: string;
  route: string;
  notGiven: boolean;
  reason: string;
}

export class SubItem {
  code: string;
  description: string;
  doseId: string;
}

export class VaccinationSchedule {
  vaccineId: string;
  doseId: string;
  scheduledDate: string;
  scheduledTime: string;
}

export class PackageMapper {
  packageItem: IPackageItem;

  constructor(packageItem: IPackageItem) {
    this.packageItem = packageItem;
  }

  getPackageForPayload() {
    const subItemsForPayload = this.packageItem.dispatches.map(item => {

      return {
        itemId: item.itemId,
        itemCode: item.itemCode,
        itemName: item.itemName,
        duration: item.duration,
        dosage: item.dosage,
        quantity: item.quantity,
        oriTotalPrice: item.oriTotalPrice,
        batchNo: item.utilize ? item.batchNo : '',
        excludedCoveragePlanIds: item.excludedCoveragePlanIds,
        itemPriceAdjustment: { 
          adjustedValue: item.itemPriceAdjustment.adjustedValue / 100,
          paymentType: item.itemPriceAdjustment.paymentType,
          remark: item.itemPriceAdjustment.remark
        },
        utilize: item.utilize,
        payable: item.payable,
        utilizedDate: !!item.utilizedDate && item.utilizedDate !== '' ? moment(item.utilizedDate, DISPLAY_DATE_FORMAT).format(DB_FULL_DATE_FORMAT) : '',
        inventoried: item.inventoried,
        visitId: item.visitId,
        expiryDate: item.utilize ? item.expiryDate : '',
        salesItemId: item.salesItemId,
        dosageInstruction: item.dosageInstruction.code,
        remarks: item.remarks,
        vaccinationInfo: item.vaccinationInfo,
      }
    });

    return {
      itemRefId: this.packageItem.itemRefId,
      code: this.packageItem.code,
      name: this.packageItem.name,
      packageQty: this.packageItem.packageQty,
      purchasePrice: this.packageItem.purchasePrice,
      packageType: this.packageItem.packageType,
      purchaseDate: moment(this.packageItem.purchaseDate, DISPLAY_DATE_FORMAT).format(
        DB_FULL_DATE_FORMAT
      ),
      expireDate: this.packageItem.expireDate,
      packageId: this.packageItem.packageId,
      priceAdjustmentType: this.packageItem.priceAdjustmentType,
      dispatches: subItemsForPayload
    } as IPackageItem;
  }


  getPackageForStore(): IPackageItem {
    const subItemsForPayload = this.packageItem.dispatches.map(item => {
      return {
        itemId: item.itemId,
        itemCode: item.itemCode,
        itemName: item.itemName,
        duration: item.duration,
        dosage: item.dosage,
        quantity: item.quantity,
        oriTotalPrice: item.oriTotalPrice,
        batchNo: item.inventoried ? item.batchNo : '',
        excludedCoveragePlanIds: item.excludedCoveragePlanIds,
        utilize: item.utilize,
        payable: item.payable,
        utilizedDate: !!item.utilizedDate && item.utilizedDate !== '' ? moment(item.utilizedDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '',
        itemPriceAdjustment: { 
          adjustedValue: item.itemPriceAdjustment.adjustedValue * 100,
          paymentType: item.itemPriceAdjustment.paymentType,
          remark: item.itemPriceAdjustment.remark
        } || { adjustedValue: 0, paymentType: 'DOLLAR', remark: '' } as PriceAdjustment,
        inventoried: item.inventoried,
        visitId: item.visitId,
        expiryDate: item.inventoried ?  item.expiryDate : '',
        salesItemId: item.salesItemId,
        // ui variables to keep track 
        drugDispenseShortId: generate(),
        parentItemPackageId: this.packageItem.packageId,
        isAvailable: true,
        isPatientAllergic: false,
        dosageInstruction: {
          code: item.dosageInstruction,
          instruct: '',
        },
        vaccinationInfo: item.vaccinationInfo,
        routeOfAdministration: item.routeOfAdministration,
        remarks: item.remarks,
      }
    });


    return {
      itemRefId: this.packageItem.itemRefId,
      code: this.packageItem.code,
      name: this.packageItem.name,
      packageQty: this.packageItem.packageQty,
      purchasePrice: this.packageItem.purchasePrice,
      purchaseDate: moment(this.packageItem.purchaseDate, DB_FULL_DATE_FORMAT).format(
        DISPLAY_DATE_FORMAT
      ),
      expireDate: this.packageItem.expireDate,
      packageId: this.packageItem.packageId,
      packageType: this.packageItem.packageType,
      priceAdjustmentType: this.packageItem.priceAdjustmentType,
      itemIsValid: true,
      noInventoryInfo: false,
      dispatches: subItemsForPayload
    }
  }
}
