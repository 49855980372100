import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

const getStr = (obj, ltr) => get(obj, ltr) || ''

const getClearTxt = (obj, ltr) => {
  const str = getStr(obj, ltr)
  return str.replace(/ *\([^)]*\) */g, " (*)")
}

@Pipe({
  name: 'objectListSort'
})
export class ObjectListSortPipe implements PipeTransform {

  transform<T>(objArr: Array<T>, sortOrder: string[], path: string): Array<T> {
    return objArr.sort(
      (a, b) => sortOrder.indexOf(getClearTxt(a, path)) - sortOrder.indexOf(getClearTxt(b, path))
    );
  }
}
