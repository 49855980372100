import { AKITA_MEDICAL_COVERAGE } from './../../../../constants/akita.config';
import { AkitaPatientStoreService } from './../../../../services/states/akita-patient/akita-patient-store.service';
import { COVERAGE_CONFIG } from './../../../../constants/medical-coverage.config';
import { AppointmentMasterQuery } from './../../../../state/appointments/appointment-master/appointment-master.query';
import { AppointmentService } from '../../../../state/appointments/event-types/appointment/appointment.service';
import { AppointmentsFormService } from './../../../../services/appointments-form.service';
import { AppointmentsNewComponent } from './../appointments-new/appointments-new.component';
import { PatientListService } from './../../patient/patient-list/services/patient-list.service';
import {
  CONFIRM_DOUBLE_REGISTRATION,
  INPUT_DELAY,
} from './../../../../constants/app.constants';
import { ApiAppointmentsService } from './../../../../services/api-appointments.service';
import { Router } from '@angular/router';
import { AlertService } from './../../../../services/alert.service';
import { ApiPatientVisitService } from './../../../../services/api-patient-visit.service';
import {
  PatientVisitRegistryEntity,
  PatientVisit,
} from './../../../../objects/request/PatientVisit';
import { Appointment } from './../../../../objects/Appointment';
import { PatientAddQueueConfirmationComponent } from './../../../../components/patient-add/patient-add-queue-confirmation/patient-add-queue-confirmation.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StoreService } from './../../../../services/store.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CalendarAppointment } from '../../../../objects/CalendarAppointment';
import { Subject, of } from 'rxjs';
import { debounceTime, map, mergeMap, catchError } from 'rxjs/operators';
import { HttpResponseBody } from '../../../../objects/response/HttpResponseBody';
import { AkitaAppQuery } from '../../../../services/states/akita-app.query';
import { getDetailAge, getAge } from '../../../../util/date.util';
@Component({
  selector: 'app-appointments-detail',
  templateUrl: './appointments-detail.component.html',
  styleUrls: ['./appointments-detail.component.scss'],
})
export class AppointmentsDetailComponent implements OnInit {
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  @Input() calendarEvent: CalendarAppointment;
  patientInfo: Appointment;
  confirmationBsModalRef: BsModalRef;
  appointmentsBSModalRef: BsModalRef;
  addToRegistryInfo;
  isExternal: boolean = false;

  private componentDestroyed: Subject<void> = new Subject();
  constructor(
    private store: StoreService,
    private appointmentMasterQuery: AppointmentMasterQuery,
    private appointmentsFormService: AppointmentsFormService,
    private apiAppointmentsService: ApiAppointmentsService,
    private apiPatientVisitService: ApiPatientVisitService,
    private appointmentService: AppointmentService,
    private router: Router,
    private alertService: AlertService,
    private modalService: BsModalService,
    private patientListService: PatientListService,
    private akitaPatientStore: AkitaPatientStoreService,
    private akitaAppQuery: AkitaAppQuery
  ) { }

  ngOnInit() {
    this.patientInfo = this.calendarEvent.appointment;
    this.isExternal =
      this.store.getClinicId() !== this.calendarEvent.appointment.clinicId
        ? true
        : false;
  }

  redirectToPatientDetail(
    patientId: string
  ) {
    let newRelativeUrl = this.router.createUrlTree([`/pages/patient/detail/${patientId}`]);
    let baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + newRelativeUrl, '_blank');
    return false;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  deleteItem() {
    if (this.confirmDelete()) {
      this.apiAppointmentsService
        .remove(this.calendarEvent.appointment.id)
        .subscribe(res => {
    
          this.appointmentService.getAppointmentsByClinic();
        });
    }
  }

  confirmDelete() {
    const isDelete = confirm('Delete appointment?');
    return isDelete;
  }

  onEditClicked() {
    const appointmentFormGroup = this.appointmentsFormService.createAppointmentFormGroup(
      this.calendarEvent,
      this.appointmentMasterQuery.getClinicSelected()
    );
    this.appointmentsFormService.setMandatoryFields(
      appointmentFormGroup,
      false
    );

    const initialState = {
      title: event === null || this.calendarEvent.appointment.id === '' || this.calendarEvent.appointment.id === null
        ? 'New Appointment'
        : 'Edit Appointment',
      appointmentFormGroup: appointmentFormGroup,
      doctors: this.appointmentMasterQuery.getClinicSelectedDoctorList(),
      clinicId: this.appointmentMasterQuery.getClinicSelected(),
      editMode: event === null || this.calendarEvent.appointment.id === '' || this.calendarEvent.appointment.id === null
      ? false : true
    };

    this.appointmentsBSModalRef = this.modalService.show(
      AppointmentsNewComponent,
      {
        initialState,
        class: 'modal-lg',
        backdrop: 'static',
      }
    );

    this.appointmentsBSModalRef.content.event.subscribe(res => {
      let url = 'pages/patient/add';

      if (res === 'Add') {
        this.router.navigate([url], {});
      } else if (res === 'Close') {
      } else {
        this.appointmentService.getAppointmentsByClinic();
      }

      this.appointmentsBSModalRef.hide();
    });
  }

  onAddToQueue() {
    // Add to Visit Registry
    // Update Appointment Status

    const clinicId = this.akitaAppQuery.getValue().clinic.id;
    let currentPatientSource = '';
    if (clinicId && clinicId.length > 0 && this.patientInfo.patientSources && Object.keys(this.patientInfo.patientSources).length > 0) {
      currentPatientSource = this.patientInfo.patientSources[clinicId];
    }

    this.akitaPatientStore.setPatientApp(this.patientInfo.patientId, AKITA_MEDICAL_COVERAGE);

    let initialState = {
      params: {
        preferredDoctor: this.patientInfo.preferredDoctor,
        purposeOfVisit: this.patientInfo.purposeOfVisit,
        remarks: this.patientInfo.remarks,
        clinicId: this.patientInfo.clinicId,
        patientId: this.patientInfo.patientId
      },
      patientSources: currentPatientSource,
      config: COVERAGE_CONFIG.APPOINTMENT.POPUP_ATTACH,
    };

    if (
      this.patientListService.checkPatientAlreadyAddedInRegistry(
        this.patientInfo['userId']['number'],
        this.patientInfo['userId']['idType']
      )
    ) {
      let alert = confirm(CONFIRM_DOUBLE_REGISTRATION);
      if (!alert) {
        return;
      }
    }

    this.confirmationBsModalRef = this.modalService.show(
      PatientAddQueueConfirmationComponent,
      {
        initialState,
        class: 'modal-x-lg',
      }
    );

    this.confirmationBsModalRef.content.event.subscribe(data => {
      if (data[0] && data[0] === 'ADD_TO_REGISTRY') {
        this.addToRegistryInfo = data[1];
        const caseId = !!data[2] ? data[2] : '';
        this.updateArrivalStatus(caseId);
      }

      this.confirmationBsModalRef.content.event.unsubscribe();
      this.confirmationBsModalRef.hide();
    });
    // }
  }

  updateArrivalStatus(caseId: string) {
    this.apiAppointmentsService
      .updateArrivalStatus(this.patientInfo.id)
      .pipe(debounceTime(INPUT_DELAY))
      .subscribe(
        res => {
        
          this.addPatientToRegistry(this.addToRegistryInfo, caseId);
        },
        err => this.alertService.error(JSON.stringify(err))
      );
  }

  setPatientVisitForApi(patientVisit: PatientVisit) {
    const registryEntity = patientVisit.registryEntity;

    let request = {
      registryEntity: {
        patientId: registryEntity.patientId,
        clinicId: registryEntity.clinicId,
        preferredDoctorId: registryEntity.preferredDoctorId,
        visitPurpose: registryEntity.visitPurpose,
        priority: registryEntity.priority,
        patientSources: registryEntity.patientSources,
        personIncharge: registryEntity.personIncharge,
        remark: registryEntity.remark,
        appointmentId: this.patientInfo.id,
      },
      attachedMedicalCoverages: patientVisit.attachedMedicalCoverages,
    };

    // paymentReference is an optional param
    if (this.patientInfo.paymentReference) {
      request.registryEntity['paymentReference'] = this.patientInfo.paymentReference;
    }

    return request;
  }

  addPatientToRegistry(data, caseID: string) {
    const patientVisitCreate: PatientVisit = new PatientVisit(
      new PatientVisitRegistryEntity(
        this.patientInfo.patientId,
        this.store.getClinicId(),
        data.preferredDoctor,
        data.purposeOfVisit,
        data.priority,
        data.patientSources,
        data.personIncharge,
        data.remarks
      ),
      data.attachedMedicalCoverages.map(value => value.planId)
    );

    const requestBody = this.setPatientVisitForApi(patientVisitCreate);

    this.apiPatientVisitService
      .initCreate(requestBody, caseID)
      .pipe(debounceTime(INPUT_DELAY))
      .subscribe(
        res => {
          if (res) {
            this.router.navigate(['patient']);
          }
        },
        err => this.alertService.error(JSON.stringify(err))
      );

  }

  isPending() {
    return this.calendarEvent.appointment.status === 'PENDING';
  }

  isConfirm() {
    return this.calendarEvent.appointment.status === 'CONFIRM';
  }

  isArrived() {
    return this.calendarEvent.appointment.status === 'ARRIVED';
  }

  getFormattedAge(){
    let formattedAge = this.birthdayBreakdownClinicFeature() ? getDetailAge(this.patientInfo.dob) : getAge(this.patientInfo.dob);
    return formattedAge;
  }

  birthdayBreakdownClinicFeature() {
    return this.akitaAppQuery.checkClinicFeatureExist(
      'BIRTHDAY_BREAKDOWN'
    );
  }
}
