<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" *ngIf="comparisonData">
    <!-- TO-DO: This comparison UI supports only 2 coverages at the momemnt. Need to improve this to support multiple -->

    <div class="row">
        <div class="col-lg-4">
            <div class="empty-box"></div>
            <div class="card-custom">
                <div class="card-body">
                    <div class="row px-1">
                        <div class="col-md-12 col-title">
                            <p class="title">Charge ($)<br>(Before Subsidy)</p>
                        </div>

                        <div class="col-md-12">
                            <table class="custom-table">
                                <tr>
                                    <td class="bg-consultation border-top" colspan="2">Consultation</td>
                                    <td class="bg-consultation border-top text-center">
                                        ${{comparisonData.itemTypeBreakDown.consultationWithGst |
                                        displayTwoDecimals}}</td>
                                </tr>
                                <tr class="bg-dark">
                                    <td colspan="2">Others</td>
                                    <td class="text-center">${{comparisonData.itemTypeBreakDown.othersWithGst |
                                        displayTwoDecimals}}</td>
                                </tr>
                                <tr>
                                    <td class="bg-drugs" rowspan="2">Drugs</td>
                                    <td class="bg-nonwhitelisted">Non-whitelisted</td>
                                    <td class="bg-nonwhitelisted text-center">
                                        ${{comparisonData.itemTypeBreakDown.nonWhitelistedMedWithGst | displayTwoDecimals}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bg-whitelisted">Whitelisted</td>
                                    <td class="bg-whitelisted text-center">
                                        ${{comparisonData.itemTypeBreakDown.whitelistedMedWithGst |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                                <tr class="bg-total">
                                    <td colspan="2">Total</td>
                                    <td class="text-center">${{comparisonData.itemTypeBreakDown.totalWithGst |
                                        displayTwoDecimals}}</td>
                                </tr>
                                <tr class="bg-payable">
                                    <td colspan="2">Payable</td>
                                    <td class="text-center">${{comparisonData.itemTypeBreakDown.totalWithGst |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 col-lg-4">
            <div class="empty-box" *ngIf="!comparisonData.wdrugCalculations[0].recommended"></div>
            <div class="wrapper-recomended" *ngIf="comparisonData.wdrugCalculations[0].recommended">LOWEST PAYABLE</div>
            <div class="card-custom" [ngClass]="comparisonData.wdrugCalculations[0].selected ? 'card-selected' : '' "
                (click)="onSelectPlan(comparisonData.wdrugCalculations[0].planId)">
                <div class="card-body">
                    <div class="row px-1">
                        <div class="col-md-12 col-title">
                            <p class="title">{{comparisonData.wdrugCalculations[0].planDescription}}<br>
                                (Tier {{comparisonData.wdrugCalculations[0].tier}})</p>
                            <img src="/assets/img/icon-check.png" class="icon-check"
                                *ngIf="comparisonData.wdrugCalculations[0].selected">
                        </div>

                        <div class="col-md-12">
                            <table class="custom-table">
                                <tr>
                                    <td class="bg-consultation border-top border-right" colspan="2">Consultation</td>
                                    <td class="bg-light border-top text-center"
                                        [attr.rowspan]="comparisonData.wdrugCalculations[0].planCategory === 'WDRUG_CHAS' ? '4' : '3' ">
                                        ${{comparisonData.wdrugCalculations[0].totalDollarBasedSubsidy |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="bg-dark border-right">Others</td>
                                </tr>
                                <tr>
                                    <td class="bg-drugs" rowspan="2">Drugs</td>
                                    <td class="bg-nonwhitelisted border-right">Non-whitelisted</td>
                                </tr>
                                <tr>
                                    <td class="bg-whitelisted"
                                        [ngClass]="comparisonData.wdrugCalculations[0].planCategory !== 'WDRUG_CHAS' ? 'bg-whitelisted' : 'border-right' ">
                                        Whitelisted
                                    </td>
                                    <td class="bg-whitelisted border-right text-center"
                                        *ngIf="comparisonData.wdrugCalculations[0].planCategory !== 'WDRUG_CHAS'">
                                        ${{comparisonData.wdrugCalculations[0].totalPercentageBasedSubsidy |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                                <tr class="bg-total">
                                    <td colspan="2" class="border-top">Total</td>
                                    <td class="text-center border-top">
                                        ${{comparisonData.wdrugCalculations[0].totalTreatmentCost |
                                        displayTwoDecimals}}</td>
                                </tr>
                                <tr class="bg-payable">
                                    <td colspan="2">Payable</td>
                                    <td class="text-center">${{comparisonData.wdrugCalculations[0].patientPayable |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                                <tr class="bg-light">
                                    <td colspan="2">CHAS Balance (After Claim)</td>
                                    <td class="text-center">
                                        ${{comparisonData.wdrugCalculations[0].balanceAfterClaim |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 col-lg-4">
            <div class="empty-box" *ngIf="!comparisonData.wdrugCalculations[1].recommended"></div>
            <div class="wrapper-recomended" *ngIf="comparisonData.wdrugCalculations[1].recommended">LOWEST PAYABLE</div>
            <div class="card-custom" [ngClass]="comparisonData.wdrugCalculations[1].selected ? 'card-selected' : '' "
                (click)="onSelectPlan(comparisonData.wdrugCalculations[1].planId)">
                <div class="card-body">
                    <div class="row px-1">
                        <div class="col-md-12 col-title">
                            <p class="title">{{comparisonData.wdrugCalculations[1].planDescription}}<br>
                                (Tier {{comparisonData.wdrugCalculations[1].tier}})</p>
                            <img src="/assets/img/icon-check.png" class="icon-check"
                                *ngIf="comparisonData.wdrugCalculations[1].selected">
                        </div>

                        <div class="col-md-12">
                            <table class="custom-table">
                                <tr class="bg-light">
                                    <td colspan="2" class="border-top border-right bg-consultation">Consultation</td>
                                    <td class="text-center border-top"
                                        [attr.rowspan]="comparisonData.wdrugCalculations[1].planCategory === 'WDRUG_CHAS' ? '4' : '3' ">
                                        ${{comparisonData.wdrugCalculations[1].totalDollarBasedSubsidy |
                                        displayTwoDecimals}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="bg-dark border-right">Others</td>
                                </tr>
                                <tr>
                                    <td class="bg-drugs" rowspan="2">Drugs</td>
                                    <td class="bg-nonwhitelisted border-right">Non-whitelisted</td>
                                </tr>
                                <tr>
                                    <td class="bg-whitelisted border-top"
                                        [ngClass]="comparisonData.wdrugCalculations[1].planCategory !== 'WDRUG_CHAS' ? 'bg-whitelisted' : '' ">
                                        Whitelisted
                                    </td>
                                    <td class="bg-whitelisted text-center border-top"
                                        *ngIf="comparisonData.wdrugCalculations[1].planCategory !== 'WDRUG_CHAS'">
                                        ${{comparisonData.wdrugCalculations[1].totalPercentageBasedSubsidy |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                                <tr class="bg-total">
                                    <td colspan="2">Total</td>
                                    <td class="text-center">${{comparisonData.wdrugCalculations[1].totalTreatmentCost |
                                        displayTwoDecimals}}</td>
                                </tr>
                                <tr class="bg-payable">
                                    <td colspan="2">Payable</td>
                                    <td class="text-center">${{comparisonData.wdrugCalculations[1].patientPayable |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                                <tr class="bg-light">
                                    <td colspan="2">Balance (After Claim)</td>
                                    <td class="text-center">
                                        ${{comparisonData.wdrugCalculations[1].balanceAfterClaim |
                                        displayTwoDecimals}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer justify-content-between">
    <div class="d-flex flex-column">
        <span class="color-warning">&#42;Selection of a new plan will reset prices to Original Plan Pricing - please check amendments before completion of visit.</span>
        <span class="color-warning">&#42;Payable amount can be covered by Medisave or Medisave Flexi.</span>
        <span class="color-warning">&#42;Start Balance for claim can differ based off of annual limits for Original CHAS chronic Tier and HSG Chronic Tier accordingly.</span>
    </div>
    <button class="button-green" (click)="onClose()">Close</button>
</div>