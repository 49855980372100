import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AkitaAppQuery} from "../services/states/akita-app.query";

@Directive({
  selector: '[appClinicFeature]'
})
export class ClinicFeatureDirective {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private akitaAppQuery: AkitaAppQuery,
  ) { }

  @Input() set appClinicFeature(feature: string) {
    this.updateView(feature);
  }
  updateView(flagName: string) {
    const flagEnabled = this.akitaAppQuery.checkClinicFeatureExist(flagName);
    if (flagEnabled) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
