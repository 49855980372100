export const AKITA_CACHE_DURATION = 86400000;
export const AKITA_HTTP_RETRY_DELAY = 10000;
export const AKITA_HTTP_RETRY_MAX = 0;

export const AKITA_PATIENT_DETAIL = [
    'patientInfo',
    'medicalCoverage',
    'medicalAlerts',
    'visitHistory',
];

export const AKITA_CONSULTATION = [
    'patientInfo',
    'medicalCoverage',
    'medicalAlerts',
    'visitHistory',
];

export const AKITA_MEDICAL_COVERAGE = ['patientInfo', 'medicalCoverage'];