import { AppointmentsFactoryService } from '../../../../services/appointments-factory.service';
import { AppointmentMasterService } from '../../appointment-master/appointment-master.service';
import { AppointmentMasterQuery } from '../../appointment-master/appointment-master.query';
import { AlertService } from '../../../../services/alert.service';
import { ApiAppointmentsService } from '../../../../services/api-appointments.service';
import { ServiceCalendarStore } from './service-calendar.store';
import { Injectable } from '@angular/core';
import { ServiceCalendar } from '../../../../objects/ServiceCalendar';

@Injectable({
  providedIn: 'root',
})
export class ServiceCalendarService {
  constructor(
    private serviceCalendarStore: ServiceCalendarStore,
    private appointmentMasterService: AppointmentMasterService,
    private appointmentMasterQuery: AppointmentMasterQuery,
    private apiAppointmentsService: ApiAppointmentsService,
    private apptFactory: AppointmentsFactoryService,
    private alertService: AlertService
  ) {}

  set(calendar: ServiceCalendar[]) {
    this.serviceCalendarStore.set(calendar);
  }

  add(cal: ServiceCalendar) {
    this.serviceCalendarStore.add(cal);
  }

  update(cal: ServiceCalendar) {
    this.serviceCalendarStore.update(cal);
  }

  upsert(service, cal: ServiceCalendar) {
    this.serviceCalendarStore.upsert(service, cal);
  }

  remove(id?) {
    if (id) {
      this.serviceCalendarStore.remove(id);
    } else {
      this.serviceCalendarStore.remove();
    }
  }

  getServiceCalendarById(service) {
    let cal: ServiceCalendar = this.apptFactory.createServiceCalendar(service);
    this.upsert(service.sercice, cal);
  }


  getServices(){
    this.apiAppointmentsService.getServices(this.appointmentMasterQuery.getClinicSelected()).subscribe(res => {
      if(res.statusCode === 'S0000'){
        if(res.payload !== undefined && res.payload.services){
          let array: ServiceCalendar[] = [];
          res.payload.services.forEach(service => {
            let item = this.apptFactory.createServiceCalendar({
              clinicId: res.payload.clinicId,
              blockedTime: service.blockedTime,
              group: service.group,
              service: service.service
            })
            array.push(item);
          });
          this.set(array);
          this.appointmentMasterService.setStoreReady();
        }
      }
    },
    err => {
      this.alertService.error(JSON.stringify(err));
    })
  }
}
