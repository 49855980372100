import { Doctor } from './../../objects/SpecialityByClinic';
import { Injectable } from '@angular/core';
import { DoctorStore } from './doctor.store';
import { tap } from 'rxjs/operators';
import { ApiCmsManagementService } from '../../services/api-cms-management.service';

@Injectable({ providedIn: 'root' })
export class DoctorService {
  constructor(
    private doctorStore: DoctorStore,
    private apiCmsManagementService: ApiCmsManagementService
  ) {}

  getDoctorList() {
    // return this.apiCmsManagementService
    //   .listDoctors()
    //   .pipe(tap(res => this.doctorStore.set(<Array<Doctor>>res.payload || [])));
  }

  set(doctorList: Doctor[]) {
    this.doctorStore.set(doctorList);
  }
}
