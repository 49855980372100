<div>
    <div class="row cursor-pointer"
        [ngClass]="{'modal-sub-header' : isCollapsed , 'modal-sub-header-expanded' : !isCollapsed}"
        (click)="isCollapsed = !isCollapsed">
        <div *ngIf="hasUpdatePriority" class="col-1 input-group-append">
            <div class="row">
                <div class="col-5">
                </div>
                <div class="col-6">
                    <input
                        type='checkbox' 
                        value="{{isSelected.value}}"
                        (click)="checkClick($event)" 
                        [(ngModel)]="isSelected.value"
                        [disabled]="isDisabled || isPayerBalanceLoading" 
                        />
                </div>
            </div>
        </div>
        <ng-container>
            <div class="col ">
                {{ coverageSelected ? coverageSelected.value.name : ''}}
            </div>
            <div [class]="hasUpdatePriority? 'col pl-3' : 'col'">
                {{ planSelected.value.name }}
                <div class="invalidItemText text-queue-s mt-1">
                    <div *ngIf="policyHolderExpired.value">
                        <span class="icon-attention-2">
                            Policy has expired. Please extend policy end date.
                        </span>
                    </div>
                    <div *ngIf="policyExpired.value && !isNew.value">
                        <span class="icon-attention-2">
                            This medical coverage has expired. Please contact administrator.
                        </span>
                    </div>
                    <div *ngIf="policyExcluded.value">
                        <span class="icon-attention-2">
                            Plan is excluded from this clinic.
                        </span>
                    </div>
                    <div *ngIf="policyInactive.value">
                        <span class="icon-attention-2">
                            Policy is inactive. Please delete accordingly or contact administrator.
                        </span>
                    </div>
                    <div *ngIf="coverageInactive.value">
                        <span class="icon-attention-2">
                            Coverage is inactive. Please delete accordingly or contact administrator.
                        </span>
                    </div>
                    <div *ngIf="planInactive.value">
                        <span class="icon-attention-2">
                            Plan is inactive. Please delete accordingly or contact administrator.
                        </span>
                    </div>
                    <div *ngIf="planExpired.value">
                        <span class="icon-attention-2">
                            Plan has expired. Please extend plan end date.
                        </span>
                    </div>
                    <div *ngIf="policyInactive.value">
                        <span class="icon-attention-2">
                            Policy is inactive. Please delete accordingly or contact administrator.
                        </span>
                    </div>
                    <div *ngIf="isDuplicate.value">
                        <span class="icon-attention-2">
                            Duplicate plan added. Please delete accordingly or contact administrator.
                        </span>
                    </div>
                </div>
            </div>
            <div [class]="hasUpdatePriority? 'col pl-4' : 'col'">
                {{ patientCoverageId.value}}
                <span *ngIf="!hasUpdatePriority">
                    <span *ngIf="isSelected.value " class="badge badge-success pull-right">
                        Attached
                    </span>
                </span>
            </div>
            
            <div *ngIf="showBalance" class="col">
                <ng-container *ngIf="!hasPermission()">
                    <ng-container *ngIf="!isNew.value; else elseTemplate">
                        <app-balance-item class="w-100" [balanceStatus]='balanceStatus'></app-balance-item>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <strong>Check post-registration</strong>
                    </ng-template>
                </ng-container>

                <button class="btn btn-primary" *ngIf="hasPermission()" (click)="checkCoverage()">
                  View Coverage
                </button>
            </div>
        </ng-container>
    </div>
    <div [collapse]="isCollapsed">
        <app-medical-coverage-item-detail 
            [remarks]="patientCoverageItem.get('remarks').value"
            [sflEligibilityItems]="sflEligibilityItems"
            [visitId]="visitId"
            [coverage]="coverageSelected.value"
            [plan]="planSelected.value"
            (onHandlePlanChange)="onHandlePlanChange($event)"
            >
        </app-medical-coverage-item-detail>
    </div>
</div>
