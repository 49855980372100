import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { ApiPatientInfoService } from '../../../services/api-patient-info.service';
import { AkitaCaseVisitQuery } from '../../../services/states/akita-case-visit.query';
import { CaseManagerService } from '../../../views/components/case-manager/case-manager.service';
import { CaseManagerEmailConfirmationComponent } from '../../case-manager/case-manager-email-confirmation/case-manager-email-confirmation.component';

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.scss'],
})
export class PaymentConfirmComponent implements OnInit {
  @Output() printClicked = new EventEmitter<string>();
  @Output() emailClicked = new EventEmitter<[string, string]>();
  @Output() nextClicked = new EventEmitter<boolean>();

  constructor(
    private caseManagerService: CaseManagerService,
    private apiPatientInfoService: ApiPatientInfoService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private bsModalService: BsModalService,
    public bsModalRef: BsModalRef,
    private alertService: AlertService,
  ) { }

  ngOnInit() { }

  onBtnPrint(receiptType) {
    this.printClicked.emit(receiptType);
  }

  onBtnEmail(receiptType) {
    const patientId = this.akitaCaseVisitQuery.getValue().patientId;
    this.apiPatientInfoService
      .searchBy('systemuserid', patientId)
      .subscribe(
        res => {
          const initialState = {
            patientEmail: res.payload.emailAddress,
          };
      
          const emailConfirmationModal = this.bsModalService.show(CaseManagerEmailConfirmationComponent, {
            initialState,
            class: 'modal-md',
            backdrop: 'static',
          });
      
          const emailConfirmationModalSubscription: Subscription = emailConfirmationModal.content.emailUpdated.subscribe(
            data => {
              emailConfirmationModal.hide();
              emailConfirmationModalSubscription.unsubscribe();
              this.emailClicked.emit([receiptType, data]);
            }
          );
        },
        err => {
          this.alertService.error(JSON.stringify(err));
        }
      );
  }

  onBtnNext() {
    this.caseManagerService.clearCaseStore();
    this.nextClicked.emit(true);
  }
}
