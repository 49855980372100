import { Component, Input, OnInit } from '@angular/core';

import { HSGOnboardingQuestionnaire } from '../../../../../../model/HSGOnboardingQuestionnaire';
import { BsModalService } from 'ngx-bootstrap';
import { QuestionnaireResponsesComponent } from '../questionnaire-responses/questionnaire-responses.component';
import { AkitaAppQuery } from '../../../../../../services/states/akita-app.query';
import { AkitaPatientAppQuery } from '../../../../../../services/states/akita-patient/akita-patient-app.query';
import { AlertService } from '../../../../../../services/alert.service';
import { ApiHsgService } from '../../../../../../services/api-hsg.service';

@Component({
  selector: 'app-questionnaire-list',
  templateUrl: './questionnaire-list.component.html',
  styleUrls: ['./questionnaire-list.component.scss']
})
export class QuestionnaireListComponent implements OnInit {

  @Input()
  healthQuestionnaire: Array<HSGOnboardingQuestionnaire> = [];

  public isLoading: boolean = false;

  public currentPage: number = 1;
  public itemsPerPage: number = 0;

  constructor(
    private modalService: BsModalService,
    private akitaAppQuery: AkitaAppQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private _alertService: AlertService,
    private _apiHsgService: ApiHsgService,
  ) { }

  ngOnInit(): void { }

  public onView(questionnaire: HSGOnboardingQuestionnaire): void {
    const initialState = {
      questionnaire: questionnaire,
    }

    const modal = this.modalService.show(QuestionnaireResponsesComponent, {
      initialState,
      class: 'modal-xl custom-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  public onPaginate(next: boolean): void {
    this.isLoading = true;

    if (next) {
      this.currentPage = this.currentPage + 1;
    } else {
      if (this.currentPage !== 1) {
        this.currentPage = this.currentPage - 1;
      }
    }

    const clinicId = this.akitaAppQuery.getAppClinicId();
    const patientId = this.akitaPatientAppQuery.getId();
    const body = {
      questionnaireType: 'HQ',
      page: this.currentPage - 1,
      size: 10
    }
    this._apiHsgService.getHsgQuestionnaire(clinicId, patientId, body).subscribe({
      next: result => {
        this.healthQuestionnaire = result.payload;
        this.isLoading = false;
      },
      error: error => {
        this.isLoading = false;
        this._alertService.error(error.error.message);
      }
    });
  }
}