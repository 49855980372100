<div class="row">
    <div class="col-12 mb-3">
        <span class="d-block">National Adult Immunisation Schedule (NAIS) only</span>
        <a class="link" href="https://www.healthhub.sg/programmes/vaccinate" target="_blank">Find Out More</a>
    </div>

    <div class="col-12">
        <h5>Get your recommended vaccination(s):</h5>

        <table class="custom-table">
            <thead>
                <tr>
                    <th></th>
                    <th class="w-60">Vaccination Type</th>
                    <th>Suggested Next Dose Date</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of vaccinationRecommendationList; let i = index;">
                    <tr [formGroup]="formGroupList[i]">
                        <td class="text-center">
                            <input type="checkbox" formControlName="selected" (change)="onCheck(formGroupList[i])">
                        </td>
                        <td>
                            {{item.description}}
                        </td>
                        <td>
                            <app-date-picker-with-icon [customStyles]="true" [config]="datePickerConfig"
                                [disabled]="formGroupList[i].get('nextDoseDate').disabled"
                                [dateControl]="formGroupList[i].get('nextDoseDate')">
                            </app-date-picker-with-icon>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>