<div class="card">
  <div class="card-header">{{title}}</div>
  <div class="card-body">

    Are you sure you want to delete selected plan?

    <div class="row pt-3 mb-1 mt-1">

      <div class="col-5">
        <button class="btn btn-small btn-cancel pr-2" (click)="cancelModal()"> Cancel </button>
      </div>

      <div class="col-1"></div>

      <div class="col-5">
        <button class="btn btn-pink btn-small" (click)="deletePlan()"> Delete</button>
      </div>
    </div>

  </div>
</div>