<table class="custom-table custom-table-light" [formGroup]="medicalAlertFormGroup">
    <thead>
      <tr>
        <th>Alert Type <span class="compulsory">*</span></th>
        <th>Alert Name <span class="compulsory"></span></th>
        <th>Alert Status <span class="compulsory"></span></th>
        <th>Remarks</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngFor="let form of medicalAlertFormGroup.get('alertArray')['controls']; let i = index;" [formGroup]="form">
        <app-patient-add-alerts-info-add-clinical-item 
            (delete)="onDelete(form,i)" 
            (inActivate)="onInActivate(form,i)" 
            [form]="form" 
            [medicalAlertFormGroup]="medicalAlertFormGroup"
            [alertCodes]="alertCodes" 
            [index]="i" 
            [doctors]="doctors">
        </app-patient-add-alerts-info-add-clinical-item>
    </tbody>
</table>

<div class="row mt-2">
    <div class="col-md-12">
        <div class="btn-toolbar float-right" role="toolbar">
            <div class="btn-group" role="group">
                <button class="button-green-inverse" (click)="onBtnAdd()">
                 + ADD ALERT
               </button>
            </div>
        </div>
    </div>
</div>