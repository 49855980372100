export class Address {
  address: string;
  country: string;
  postalCode: string;
  constructor(address?: string, country?: string, postalCode?: string) {
    this.address = address || '';
    this.country = country || '';
    this.postalCode = postalCode || '';
  }
}
