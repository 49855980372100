import { ValidatorFn, AbstractControl } from '@angular/forms';
import { StockItem } from '../objects/StockItem';

export function UniqueBatchNumberValidator(
  itemList: Array<StockItem>
): ValidatorFn | any {
  return (control: AbstractControl) => {
    if (!control || !control.value) {
      return null;
    }
    return itemList.some(
      item => (item.batchNumber || '').toLowerCase() === control.value.toLowerCase()
    )
      ? { uniqueBatch: false }
      : null;
  };
}
