import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AkitaDeliveryLocationStore, AkitaDeliveryLocationState } from './akita-delivery-location.store';
import {AkitaWhitelistedItemState, AkitaWhitelistedItemStore, WhitelistedItem} from "./akita-whitelisted-items.store";

@Injectable({ providedIn: 'root' })
export class AkitaWhitelistedItemQuery extends QueryEntity<AkitaWhitelistedItemState> {

  constructor(protected store: AkitaWhitelistedItemStore) {
    super(store);
  }
}
