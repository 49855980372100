<!-- loading layout replaced by app after startupp -->
<div class="app-loading container align-self-center">
  <div class="row justify-content-center">
    Loading data failed.
  </div>
  <p></p>
  <div class="row justify-content-center">
    <button class="btn btn-large btn-blue" (click)='retry()'>Retry</button>
  </div>

</div>
