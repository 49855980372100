import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryDisplayOrderSubtotal'
})
export class InventoryDisplayOrderSubtotalPipe implements PipeTransform {
  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    let needConversation = args

    if (!value) {
      return 0
    }

    return this.inventory.getOrderSubtotal(needConversation ? this.inventory.convertOrderItemFormArrayValueToOrderItemArray(value) : value);
  }

}
