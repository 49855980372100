import { Injectable } from '@angular/core';

import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryEntity } from '@datorama/akita';

import { ChargeItem } from '../../objects/state/ChargeItem';
import { InventoryItem } from '../../objects/InventoryItem';
import {
  AkitaClinicPurchasableChargeItemState,
  AkitaClinicPurchasableChargeItemStore,
} from './akita-clinic-purchasable-charge-item.store';

@Injectable({ providedIn: 'root' })
export class AkitaClinicPurchasableChargeItemQuery extends QueryEntity<
  AkitaClinicPurchasableChargeItemState
> {
  consultationSearchCode$ = this.select(state => state.ui.code);
  consultationSearchItemType$ = this.select(state => state.ui.itemType);
  consultationSearchName$ = this.select(state => state.ui.name);

  itemsAll: any= new Map();

  constructor(protected store: AkitaClinicPurchasableChargeItemStore) {
    super(store);
  }

  static readonly sortByNameAsc = (a: ChargeItem, b: ChargeItem, state) => {
    return a.name.toUpperCase() < b.name.toUpperCase()
      ? -1
      : a.name.toUpperCase() > b.name.toUpperCase()
      ? 1
      : 0;
  };

  getInventoryItemList() {
    // const inventoryItemList = <any> this.getAll({ filterBy: (entity: ChargeItem) => entity.itemType === 'DRUG' || entity.category === 'CONSUMABLES', sortBy: AkitaClinicChargeItemQuery.sortByNameAsc });
    const inventoryItemList = <any>this.getAll({
      sortBy: AkitaClinicPurchasableChargeItemQuery.sortByNameAsc,
    });
    return inventoryItemList as Array<InventoryItem>;
  }

  selectInventoryItemList(
    excludeItemId: Array<string>,
    supplierList?: Array<string>
  ) {
    let inventoryList;
    if (supplierList) {
      // inventoryList = this.selectAll({ filterBy: (entity: ChargeItem) => (entity.itemType === 'DRUG' || entity.category === 'CONSUMABLES') && supplierList.includes(entity.id), sortBy: AkitaClinicChargeItemQuery.sortByNameAsc }).pipe(
      inventoryList = this.selectAll({
        filterBy: (entity: ChargeItem) => supplierList.includes(entity.id),
        sortBy: AkitaClinicPurchasableChargeItemQuery.sortByNameAsc,
      }).pipe(
        map(data => {
          const inventoryItem = new Array<any>();
          data.forEach((item: any) => {
            const updatedItem = { ...item, disabled: excludeItemId.includes((<InventoryItem> item).id) };
            inventoryItem.push(updatedItem);
          });
          return inventoryItem;
        })
      );
    } else {
      // inventoryList = this.selectAll({ filterBy: (entity: ChargeItem) => entity.itemType === 'DRUG' || entity.category === 'CONSUMABLES', sortBy: AkitaClinicChargeItemQuery.sortByNameAsc }).pipe(
      inventoryList = this.selectAll({
        sortBy: AkitaClinicPurchasableChargeItemQuery.sortByNameAsc,
      }).pipe(
        map(data => {
          const inventoryItem = new Array<any>();
          data.forEach((item: any) => {
            const updatedItem = { ...item, disabled: excludeItemId.includes((<InventoryItem> item).id) };
            inventoryItem.push(updatedItem);
          });
          return inventoryItem;
        })
      );
    }

    return inventoryList;
  }

  getInventoryItem(id) {
    if (this.itemsAll.size === 0) {
    this.itemsAll= new Map(
      this.getInventoryItemList().map(object => {
        return [object.id, object];
      }),
    );
    }
    const inventoryItem = <any>(
      this.itemsAll.get(id)
    );
    return inventoryItem as InventoryItem;
  }
}
