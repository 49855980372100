import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
})
export class RefreshComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  @Input() showDocNotice: boolean;

  constructor(private bsModalRef: BsModalRef,
    private alertService: AlertService,
    ) {}

  ngOnInit() {}

  onBtnReload() {

    this.event.emit();
  }
}
