import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, mergeMap, takeUntil, finalize } from 'rxjs/operators';

import { Subject } from 'rxjs';

import { AkitaChargeItemQuery } from '../../../../services/states/akita-charge-item.query';
import { AkitaAppQuery } from '../../../../services/states/akita-app.query';

import { AlertService } from '../../../../services/alert.service';
import { InventoryService, InventoryTab } from '../../../../services/inventory.service';
import { StoreService } from '../../../../services/store.service';
import { WarningMadalService } from '../../../../components/shared/warning-modal/warning-madal.service';
import { ApiInventoryService } from '../../../../services/api-inventory.service';

import { ChargeItem } from '../../../../objects/state/ChargeItem';
import DatePickerConfig from '../../../../objects/DatePickerConfig';
import { StartStockTakeRequest } from '../../../../objects/request/StartStockTakeRequest';
import { PrintTemplateService } from '../../../../services/print-template.service';
import { StockTake, StockTakeItem, StockTakeStatus, makeDraftId } from '../../../../objects/StockTake';

import { DISPLAY_DATE_FORMAT } from '../../../../constants/app.constants';

import { Page } from '../../../../model/page';

import { WarningModalComponent } from '../../../../components/shared/warning-modal/warning-modal.component';
import { InventoryCountDetailExpiryErrorComponent } from './inventory-count-detail-expiry-error/inventory-count-detail-expiry-error.component';

import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-inventory-count-detail',
  templateUrl: './inventory-count-detail.component.html',
  styleUrls: ['./inventory-count-detail.component.scss']
})
export class InventoryCountDetailComponent implements OnDestroy, OnInit {


  componentDestroyed: Subject<void> = new Subject();

  readonly defaultDatePickerConfig = new DatePickerConfig('', null, null, 'bottom', 'right');
  readonly errorInitialState = {
    title: 'Error',
    iconColor: 'text-danger'
  };
  readonly itemGroupOptions: Array<any> = [
    { index: 0, name: 'All' },
    { index: 1, name: 'Drug' },
    { index: 2, name: 'Vaccination' },
    { index: 3, name: 'Consumable' },
    { index: 4, name: 'Available Stock Count' }
  ];
  readonly itemReasonList = this.storeService.getStockAdjustmentReasonList().filter(reason => !reason.billAdjustmentIdentifier);
  readonly StockTakeStatus = StockTakeStatus;

  stockTake: StockTake;
  stockCountItemList: Array<StockTakeItem>;

  dirtyStockTake = false;
  filteredStockCountItemFA: FormArray;
  itemGroupSelectedOptions: number = 0;
  searchItemFormControl: FormControl;
  selectableStockCountItemList = [];
  selectedStockTakeList: string = 'All';
  stockCountItemFormArray: FormArray;
  stockCountItemPage: Page = new Page();

  retakeStockId = [];
  retakeStockName: string;
  isRequesting = false;

  constructor(
    private akitaAppQuery: AkitaAppQuery,
    private activatedRoute: ActivatedRoute,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private alertService: AlertService,
    private inventoryService: InventoryService,
    private printTemplateService: PrintTemplateService,
    private router: Router,
    private storeService: StoreService,
    private warningModalService: WarningMadalService,
    private apiInventoryService: ApiInventoryService,
    private bsModalService: BsModalService
  ) {
    this.stockCountItemPage.pageNumber = 0;
    this.stockCountItemPage.size = 50;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  ngOnInit() {
    this.activatedRoute.data.pipe(
      takeUntil(this.componentDestroyed)
    ).subscribe(res => {

      if (res.data && res.data.stockTake && res.data.stockTake.id && res.data.stockTake.id.length > 0) {
        this.stockTake = res.data.stockTake;
        this.stockCountItemList = res.data.stockCountItems;

        this.createStockTakeFormArray();
        this.initSearchItemFormControl();

        //fill retake array
        this.stockCountItemList.forEach(item => {
          if (item.status == "REJECTED")
            this.retakeStockId.push(item.itemRefId);
        });
        this.retakeStockName = "Restock take " + res.data.stockTake.transactionNo;

        //

      } else {
        alert('No Stock Take Details');
        this.navigateToStockTakeList();
      }
    });

    this.subscribeToExpiryDate();
  }

  private subscribeToExpiryDate(): void {
    this.filteredStockCountItemFA.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe({
        next: result => {
          if (this.isInFirstStockTake()) {
            const today = moment();

            result.forEach((item: any, index: number) => {
              if (item.expiryDate) {
                const expiriyDate = moment(item.expiryDate);

                if (expiriyDate.diff(today, 'days') < 0) {
                  this.filteredStockCountItemFA.controls[index].get('expiryDate').setErrors({ invalidExpDate: true });
                }
              }
            });
          }
        }
      });
  }

  getCurrentClinicCode() {
    return (this.akitaAppQuery.getValue().clinic || { clinicCode: '' }).clinicCode;
  }

  startRetakeCount() {
    this.isRequesting = true;

    const clinicCode = this.getCurrentClinicCode();
    const clinicId = (this.akitaAppQuery.getValue().clinic || { id: '' }).id;
    if (clinicCode && clinicCode.length > 0) {

      const currentTime = moment();
      const startTime = moment(currentTime).format('HH:mm:ss');
      // const startDate = currentTime.toDate();
      const startDate = moment(
        new Date(),
        'DD-MM-YYYY'
      ).format('DD-MM-YYYY')
      const newStockTakeValue = {
        startTime: startTime,
        startDate: startDate,
        startRange: " ",
        endRange: " ",
        countType: "PARTIAL",
        countName: this.retakeStockName,
        itemStockTakeRange: this.retakeStockId
      };

      this.apiInventoryService.startStockTake(clinicId, new StartStockTakeRequest().adapt(newStockTakeValue)).pipe(
        finalize(() => {
          this.isRequesting = false;
          this.navigateToStockTakeList();
        })
      ).subscribe(data => {
        this.alertService.success('Created new stocktake successfully', true);
      }, err => {
        this.isRequesting = false;
        if (err && err.error && err.error['statusCode'] && err.error['statusCode'] === 'E2000') {
          err.error['message'] = 'No item record found for the following range';
          this.alertService.error(JSON.stringify(err.error));
        }
      });
    }
  }

  refreshData(res) {
    this.dirtyStockTake = false;
    this.stockTake = new StockTake().adapt(res);
    this.stockCountItemList = [...this.stockTake.stockCountItems];
    this.stockTake.stockCountItems = [];

    this.createStockTakeFormArray();
  }

  initSearchItemFormControl() {
    this.searchItemFormControl = new FormControl('');

    this.searchItemFormControl.valueChanges.pipe(
      takeUntil(this.componentDestroyed),
      debounceTime(500)
    ).subscribe(keyword => {
      this.stockCountItemPage.pageNumber = 0;
      this.filterItemList();
    })
  }

  createStockTakeFormArray(customList?) {
    let stockTakeItems = !!customList ? customList : this.stockCountItemList;
    if (this.isInSecondStockTake()) {
      stockTakeItems = (!!customList ? customList : this.stockCountItemList).filter(item => {
        const zeroOrNaNVariance = isNaN(item.availableCount - item.firstQuantity) || (item.availableCount - item.firstQuantity === 0);
        return !zeroOrNaNVariance;
      });
    }

    this.selectableStockCountItemList = this.stockCountItemList.map((item: StockTakeItem) => {
      let disabled = false;
      const refChargeItem = <ChargeItem>this.akitaChargeItemQuery.getChargeItem(item.itemRefId);
      if (refChargeItem) {
        disabled = !refChargeItem.trackingCode;
      }
      return { code: item.itemCode, name: item.itemName, disabled: disabled };
    }).filter((v, i, a) => a.findIndex(t => (t.name == v.name && t.code === v.code)) === i);

    const stockTakeObject = new StockTake().adapt({ ...this.stockTake, stockCountItems: stockTakeItems });
    this.stockCountItemFormArray = this.inventoryService.createStockTakeItemsFormArray(stockTakeObject);
    this.filteredStockCountItemFA = this.stockCountItemFormArray;
    this.subscribeToExpiryDate();
  }

  convertFormArrayToRequest() {
    const items = this.stockCountItemFormArray.getRawValue().map(item => {
      const stockTakeItem = new StockTakeItem().adapt(item);
      if (item.draft) {
        stockTakeItem.id = null;
      }

      if (!stockTakeItem.batchNumber || stockTakeItem.batchNumber.length === 0) stockTakeItem.batchNumber = null;
      stockTakeItem.expiryDate = stockTakeItem.expiryDate ? moment(item.expiryDate).format(DISPLAY_DATE_FORMAT) : null;

      if (!stockTakeItem.remark || stockTakeItem.remark.length === 0) delete stockTakeItem.remark;

      if (stockTakeItem.batchNumber) stockTakeItem.batchNumber = stockTakeItem.batchNumber.trim();

      delete stockTakeItem.availableCount;
      delete stockTakeItem.baseUom;
      delete stockTakeItem.curBatchNumber;
      delete stockTakeItem.curExpiryDate;
      delete stockTakeItem.curUom;
      delete stockTakeItem.inventoryId;
      delete stockTakeItem.manufacturerDate;
      delete stockTakeItem.status;
      delete stockTakeItem.cpRemarks;
      delete stockTakeItem.draft;
      delete stockTakeItem.draftId;
      delete stockTakeItem.unitPrice;
      return stockTakeItem;
    });

    this.stockCountItemList.forEach(item => {
      const index = items.findIndex(each => each.id === item.id);
      if (index === -1) {
        if (this.isInSecondStockTake()) {
          item.adjustedQuantity = item.firstQuantity;
        }

        items.push(item);
      }
    });

    return items;
  }

  getApproveStatus(status) {
    return this.inventoryService.getApproveStatus(status);
  }

  getApproveStatusClass(status) {
    return this.inventoryService.getApproveStatusClass(status);
  }

  getCountedItem() {
    if (!this.stockCountItemFormArray) {
      return [];
    }

    if (this.stockTake.stockTakeStatus === StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE) {
      return this.stockCountItemFormArray.getRawValue().filter(item => item.firstQuantity !== undefined && item.firstQuantity !== null);
    }

    if (this.stockTake.stockTakeStatus === StockTakeStatus.IN_PROCESS_SECOND_STOCK_TAKE) {
      return this.stockCountItemFormArray.getRawValue().filter(item => item.adjustedQuantity !== undefined && item.adjustedQuantity !== null);
    }

    return [];
  }

  getDatePickerConfig(row) {
    if (row.value.drugOrVaccine) {
      const currentTime = moment();
      currentTime.add(row.value.itemType === 'DRUG' ? 3 : 1, 'M');
      return { ...this.defaultDatePickerConfig, minDate: currentTime.toDate() };
    }

    return this.defaultDatePickerConfig;
  }

  getDisplayDate(displayDate, isDrugOrVaccine) {
    if (!displayDate || displayDate.length === 0 || !isDrugOrVaccine) return '-';

    return moment(displayDate).format(DISPLAY_DATE_FORMAT);
  }

  getDisplayDateFromat(displayDate) {
    let data = displayDate ? moment(displayDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '-';
    return data;
  }
  getDisplayTime(displayTime) {

    return displayTime ? moment(displayTime, 'HH:mm:ss').format('HH:mm') : '-';
  }

  getUncountedItem() {
    if (!this.stockCountItemFormArray) {
      return [];
    }

    if (this.stockTake.stockTakeStatus === StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE) {
      return this.stockCountItemFormArray.getRawValue().filter(item => item.firstQuantity === undefined || item.firstQuantity === null);
    }

    if (this.stockTake.stockTakeStatus === StockTakeStatus.IN_PROCESS_SECOND_STOCK_TAKE) {
      return this.stockCountItemFormArray.getRawValue().filter(item => item.adjustedQuantity === undefined || item.adjustedQuantity === null);
    }

    return [];
  }

  draftStockTakeItemValuePatch(row, data?) {
    row.patchValue({
      id: null,
      inventoryId: null,
      itemRefId: data && data.itemRefId ? data.itemRefId : '',
      itemCode: data && data.itemCode ? data.itemCode : '',
      itemName: data && data.itemName ? data.itemName : '',
      curBatchNumber: null,
      curUom: data && data.curUom ? data.curUom : '',
      curExpiryDate: null,
      availableCount: 0,
      baseUom: data && data.baseUom ? data.baseUom : '',
      manufacturerDate: null,
    });
  }

  filterItemList() {
    let nameFilteredControls = this.stockCountItemFormArray.controls;
    if (this.searchItemFormControl.value) {
      nameFilteredControls = this.stockCountItemFormArray.controls.filter(fg =>
        fg.value.itemCode.toLowerCase().includes(this.searchItemFormControl.value.toLowerCase()) || fg.value.itemName.toLowerCase().includes(this.searchItemFormControl.value.toLowerCase()));
    }

    if (this.itemGroupSelectedOptions !== 0) {
      if (this.itemGroupSelectedOptions === 4) {
        nameFilteredControls = nameFilteredControls.filter(fg => fg.value.availableCount > 0);
      } else {
        const selectedItemTypeFilter = this.itemGroupOptions.find(options => options.index === this.itemGroupSelectedOptions).name.toUpperCase();
        nameFilteredControls = nameFilteredControls.filter(fg => this.akitaChargeItemQuery.getChargeItem(fg.value.itemRefId).itemType === selectedItemTypeFilter);
      }
    }

    if (this.selectedStockTakeList === 'Counted') {
      const countedList = this.getCountedItem();
      nameFilteredControls = nameFilteredControls.filter(fg => {
        if (fg.value.draft) return countedList.some(item => item.draftId === fg.value.draftId);
        else return countedList.some(item => item.id === fg.value.id);
      });
    }

    if (this.selectedStockTakeList === 'Uncounted') {
      const uncountedList = this.getUncountedItem();
      nameFilteredControls = nameFilteredControls.filter(fg => {
        if (fg.value.draft) return uncountedList.some(item => item.draftId === fg.value.draftId);
        else return uncountedList.some(item => item.id === fg.value.id);
      });
    }

    this.filteredStockCountItemFA = new FormArray(nameFilteredControls);
  }

  completedStockTake() {
    return this.stockTake.stockTakeStatus === StockTakeStatus.COMPLETED;
  }

  disableStockCountSubmission() {

    // Need to check if this code is required 

    // return this.stockCountItemFormArray.controls.some(item => {
    //   if (!item.value.draft) return !item.valid;
    //   else {
    //     const batchNumberValid = item.value.drugOrVaccine ? item.get('batchNumber').valid : true;
    //     const expiryDateValid = item.value.drugOrVaccine ? item.get('expiryDate').valid : true;
    //     const firstQuantityValid = item.get('firstQuantity').valid;
    //     const itemRefIdValid = !!item.get('itemRefId').value && item.get('itemRefId').value.length > 0;
    //     const otherValuesValid = item.get('baseUom').value && item.get('baseUom').value.length > 0 && item.get('itemCode').value &&
    //       item.get('itemCode').value.length > 0 && item.get('itemName').value && item.get('itemName').value.length > 0;

    //     return !(batchNumberValid && expiryDateValid && firstQuantityValid && itemRefIdValid && otherValuesValid);
    //   }
    // })

    return this.stockCountItemFormArray.controls.some((item : FormGroup) => {
      return item.invalid || item.get('firstQuantity').invalid;
    });
  }

  isInFirstStockTake() {
    return this.stockTake.stockTakeStatus === StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE;
  }

  isInSecondStockTake() {
    return this.stockTake.stockTakeStatus === StockTakeStatus.IN_PROCESS_SECOND_STOCK_TAKE;
  }

  printDisabled() {
    return this.stockCountItemFormArray.getRawValue().some(item => item.draft);
  }

  stockTakeListAll() {
    return this.selectedStockTakeList === 'All';
  }

  stockTakeListCounted() {
    return this.selectedStockTakeList === 'Counted';
  }

  stockTakeListUncounted() {
    return this.selectedStockTakeList === 'Uncounted';
  }

  onItemFilterClicked(filter) {
    this.itemGroupSelectedOptions = 0;
    this.searchItemFormControl.patchValue('', { emitEvent: false });
    this.stockCountItemPage.pageNumber = 0;

    this.selectedStockTakeList = filter;
    this.filterItemList();
  }

  onItemTypeFilterSelected(index) {
    this.itemGroupSelectedOptions = index;
    this.stockCountItemPage.pageNumber = 0;

    this.filterItemList();
  }

  onScroll(event) {
    if (event.srcElement.type === 'number') {
      event.preventDefault();
    }
  }

  onSelectItem(event, row) {
    const selectedItem = (event && event.code && event.code.length > 0) ? this.stockCountItemList.find(item => item.itemCode === event.code) : undefined;

    let isDrugOrVaccine = false;
    let batchNumberDisabled = false;
    let expiryDateDisabled = false;
    let purchasePrice = 0;

    if (selectedItem) {
      const storeItem = this.akitaChargeItemQuery.getChargeItem(selectedItem.itemRefId);
      isDrugOrVaccine = storeItem && storeItem.trackingCode && (storeItem.itemType === 'DRUG' || storeItem.itemType === 'VACCINATION' || storeItem.itemType === 'CONSUMABLE') ? true : false;
      batchNumberDisabled = !isDrugOrVaccine;
      expiryDateDisabled = !isDrugOrVaccine;
      purchasePrice = selectedItem.unitPrice;
    }

    const currentRowValue = row.getRawValue();
    if (currentRowValue.drugOrVaccine !== isDrugOrVaccine) {
      row.patchValue({
        batchNumber: null,
        expiryDate: null,
        firstQuantity: null
      });
    }

    this.draftStockTakeItemValuePatch(row, selectedItem);

    row.patchValue({
      drugOrVaccine: isDrugOrVaccine,
      batchNumberDisabled: batchNumberDisabled,
      expiryDateDisabled: expiryDateDisabled,
      purchasePrice: purchasePrice
    });
  }

  onTableChange(event) {
    this.stockCountItemPage.pageNumber = event.offset;
  }

  addNewLineItem() {
    let draftStockCountItemFG = this.inventoryService.createStockTakeItemFromGroup(
      new StockTakeItem().adapt({ draft: true, draftId: makeDraftId(20) }),
      this.stockTake.stockTakeStatus
    );

    this.inventoryService.setPurchasePrice(new StockTakeItem().adapt({ draft: true, draftId: makeDraftId(20) }), draftStockCountItemFG);

    this.stockCountItemFormArray.push(draftStockCountItemFG);
    this.updateFilteredStockCountArray();

    this.stockCountItemPage.pageNumber = Math.ceil(this.filteredStockCountItemFA.length / this.stockCountItemPage.size) - 1;
    if (!this.dirtyStockTake) {
      this.dirtyStockTake = true;
    }

    this.subscribeToExpiryDate();
  }

  deleteLineItem(row, rowIndex) {
    if (row.get('draft').value) {
      this.stockCountItemFormArray.removeAt(rowIndex);
      this.updateFilteredStockCountArray();

      if (this.stockCountItemFormArray.getRawValue().every(row => !row.draft)) {
        this.dirtyStockTake = false;
      }
    }
  }

  navigateToStockTakeList() {
    this.router.navigate([`pages/inventory/main/${InventoryTab.STOCK_TAKE}`]);
  }

  printStockTakeList() {
    const selectedItem = this.stockCountItemList.filter(item => this.filteredStockCountItemFA.getRawValue().find(each => each.id === item.id && each.inventoryId === item.inventoryId &&
      each.itemRefId === item.itemRefId)).sort((a, b) => a.itemName.localeCompare(b.itemName));

    return this.printTemplateService.printStockTakeList(selectedItem);
  }

  saveAndStopCount() {
    let inavlidLine: FormGroup;
    let invalidLineNumber: number;

    this.stockCountItemFormArray.controls.forEach((formGroup: FormGroup, index: number) => {
      if (formGroup.controls['expiryDate'].invalid) {
        inavlidLine = formGroup;
        invalidLineNumber = index + 1;
        return;
      }
    });

    if (inavlidLine) {
      const initialState = {
        title: `EXPIRY DATE ERROR AT LINE NO ${invalidLineNumber}`,
        inavlidLine: inavlidLine,
        invalidLineNumber: invalidLineNumber
      };

      this.bsModalService.show(
        InventoryCountDetailExpiryErrorComponent,
        {
          initialState,
          class: 'modal-md'
        }
      );
    } else {
      this.inventoryService.saveAndStopStockTake(this.stockTake.id, this.convertFormArrayToRequest()).subscribe(res => {
        this.alertService.success('Stock Take saved successfully', true);
        this.refreshData(res);
      }, err => {
        this.filteredStockCountItemFA.controls.forEach((fg: FormGroup) => fg.updateValueAndValidity({ onlySelf: true, emitEvent: true }));
        let errorMessage = '';
        Object.keys(err.error).forEach(key => errorMessage = errorMessage.concat(`${key}: ${err.error[key]}\n`));
        this.warningModalService.show(WarningModalComponent, this.errorInitialState);
        this.warningModalService.setMessage(errorMessage);
      });
    }
  }

  submitStockTake() {
    this.inventoryService.saveAndStopStockTake(this.stockTake.id, this.convertFormArrayToRequest()).pipe(
      mergeMap(res => this.inventoryService.submitStockTake(this.stockTake.id))
    ).subscribe(res => {
      this.alertService.success('Stock Take submitted successfully', true);
      if (this.isInFirstStockTake()) {
        this.refreshData(res.payload);
      } else this.navigateToStockTakeList();
    }, err => {
      this.filteredStockCountItemFA.controls.forEach((fg: FormGroup) => fg.updateValueAndValidity({ onlySelf: true, emitEvent: true }));
      let errorMessage = '';
      Object.keys(err.error).forEach(key => errorMessage = errorMessage.concat(`${key}: ${err.error[key]}\n`));
      this.warningModalService.show(WarningModalComponent, this.errorInitialState);
      this.warningModalService.setMessage(errorMessage);
    });
  }

  updateFilteredStockCountArray() {
    this.filteredStockCountItemFA = new FormArray([]);
    this.stockCountItemFormArray.controls.forEach(fg => this.filteredStockCountItemFA.push(fg));
  }

  calculatePrice(row) {
    let qty = row.get('firstQuantity').value;
    let price = row.get('unitPrice').value;

    row.patchValue({
      purchasePrice: (qty * 2).toFixed(4),
    });
  }
}
