import { Injectable } from '@angular/core';

import { catchError, map, mergeMap, filter } from 'rxjs/operators';
import { of, BehaviorSubject, Subject, Observable } from 'rxjs';

import { AkitaCaseVisitStore } from '../../../services/states/akita-case-visit.store';
import { AkitaCaseVisitQuery } from '../../../services/states/akita-case-visit.query';
import { ApiCaseManagerService } from '../../../services/api-case-manager.service';
import { ApiPatientVisitService } from '../../../services/api-patient-visit.service';
import { createCase, CaseCoveragePlan } from '../../../objects/state/Case';
import { AkitaPatientStoreService } from '../../../services/states/akita-patient/akita-patient-store.service';
import { AKITA_MEDICAL_COVERAGE } from '../../../constants/akita.config';
import { AkitaAppQuery } from '../../../services/states/akita-app.query';
import { LoaderService } from '../../../components/loading/loader.service';

@Injectable({ providedIn: 'root' })
export class CaseManagerService {

  private caseDetailDestroy = new BehaviorSubject(true);
  private casePaymentCoverageDiscount = new Subject();
  private casePaymentAmount: BehaviorSubject<any> = new BehaviorSubject({ totalAmount: 0, gstAmount: 0, outstandingSubtotal: 0, outstandingGst: 0 });
  private caseSelectedInvoice = new BehaviorSubject([]);
  private currentBatchDetails: Subject<[]> = new Subject();
  private isRefundState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private akitaAppQuery: AkitaAppQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaCaseVisitStore: AkitaCaseVisitStore,
    private akitaPatientStoreService: AkitaPatientStoreService,
    private apiCaseManagerService: ApiCaseManagerService,
    private apiPatientVisitService: ApiPatientVisitService,
    private loaderService: LoaderService,
  ) { }

  updateCaseDetailDestroy(status) {
    this.caseDetailDestroy.next(status);
  }

  selectCaseDetailDestroy() {
    return this.caseDetailDestroy.asObservable();
  }

  updateCasePaymentCoverageDiscount(paymentCoverageDiscount) {
    this.casePaymentCoverageDiscount.next(paymentCoverageDiscount);
  }

  selectCasePaymentCoverageDiscount() {
    return this.casePaymentCoverageDiscount.asObservable();
  }

  updateIsRefundState(status: boolean) {
    this.isRefundState.next(status);
  }

  selectIsRefundState(): Observable<boolean> {
    return this.isRefundState.asObservable();
  }

  updateCasePaymentAmount(paymentAmount) {
    this.casePaymentAmount.next(paymentAmount);
  }

  defaultCasePaymentAmount() {
    this.updateCasePaymentAmount({ totalAmount: 0, gstAmount: 0, outstandingSubtotal: 0, outstandingGst: 0 });
  }

  selectCasePaymentAmount() {
    return this.casePaymentAmount.asObservable();
  }

  updateCaseSelectedInvoice(selectedInvoices) {
    this.caseSelectedInvoice.next(selectedInvoices);
  }

  selectCaseSelectedInvoice() {
    return this.caseSelectedInvoice.asObservable();
  }

  setCurrentBatchDetails(batchItems) {
    this.currentBatchDetails.next(batchItems);
  }

  getCurrentBatchDetails() {
    return this.currentBatchDetails.asObservable();
  }

  getPaymentTypes() {
    return this.apiCaseManagerService.getPaymentTypes();
  }

  invoiceRemoval(invoiceIds, remarks) {
    const caseId = this.akitaCaseVisitQuery.getValue().caseId;
    return this.apiCaseManagerService.deletePayment(caseId, invoiceIds, remarks).pipe(
      catchError(err => of({ payload: [] })),
      mergeMap(invoiceRes => {
        const caseId = this.akitaCaseVisitQuery.getValue().caseId;
        return this.apiCaseManagerService.getCaseDetailsById(caseId).pipe(
          map(detailRes => ({ invoiceRes, detailRes })),
          catchError(err => of({ invoiceRes, detailRes: { payload: {} } }))
        );
      }),
      map(({ invoiceRes, detailRes }) => {
        if (Object.keys(detailRes.payload).length > 0) {
          this.caseUpdateSalesOrder(detailRes.payload.salesOrder);
        }
        return invoiceRes;
      })
    );
  }

  makePayment(caseId: string, payment: any) {
    return this.apiCaseManagerService.recordNewPayment(caseId, payment).pipe(
      catchError(err => of({ payload: [] })),
      mergeMap(res => this.retrieveCase(caseId).pipe(map(data => ({ payment: res, data: data }))))
    );
  }

  retrieveCase(caseId) {
    return this.apiCaseManagerService.getCaseDetailsById(caseId).pipe(
      catchError(err => of({ payload: {} })),
      mergeMap(data => this.akitaAppQuery.select(entity => entity.clinic).pipe(map(clinic => ({ data: data, clinic: clinic })))),
      filter(data => !!data.clinic),
      mergeMap(data => {
        const vendorCostUpdateFeature = data.clinic.clinicFeatures.includes('VENDOR_COST_MANDATORY');
        if (vendorCostUpdateFeature) {
          this.loaderService.setLoading(true);
          return this.apiPatientVisitService.listItemForVendorCostUpdate(caseId).pipe(
            map(res => {
              this.loaderService.setLoading(false);
              return [data.data, res]
            }),
            catchError(err => {
              this.loaderService.setLoading(false);
              return of([data.data, { payload: [] }])
            })
          );
        }
        return of([data.data, { payload: [] }]);
      }),
      mergeMap(data => {
        if (data[0] && data[0].payload) {
          const caseOverview = createCase(data[0].payload);
          this.akitaPatientStoreService.setPatientApp(caseOverview.patientId, AKITA_MEDICAL_COVERAGE);
          this.akitaCaseVisitStore.update(caseOverview);

          if (data[0].payload.patientVisitEntities && data[0].payload.patientVisitEntities.length > 0) {
            this.akitaCaseVisitStore.set(data[0].payload.patientVisitEntities);
          } else this.akitaCaseVisitStore.setLoading(false);
        }

        if (data[1] && data[1].payload && data[1].payload.length > 0) {
          this.akitaCaseVisitStore.update({ vendorChargeItems: data[1].payload });
        }

        return this.akitaCaseVisitQuery.selectLoading().pipe(map(loading => ([loading, data])));
      }),
      filter(data => !data[0])
    );
  }

  updateCase(caseInformation) {
    const { personIncharge, primaryDoctor, remarks, secondaryDoctors } = caseInformation;

    const updatedCaseStructure = {
      caseId: this.akitaCaseVisitQuery.getValue().caseId,
      caseNumber: this.akitaCaseVisitQuery.getValue().caseNumber,
      caseStartDateTime: this.akitaCaseVisitQuery.getValue().caseStartDateTime,
      clinicId: this.akitaCaseVisitQuery.getValue().clinicId,
      coverages: this.akitaCaseVisitQuery.getValue().coverages,
      hasOpenVisits: this.akitaCaseVisitQuery.getValue().hasOpenVisits,
      package: this.akitaCaseVisitQuery.getValue().isPackage,
      packages: this.akitaCaseVisitQuery.getValue().packages,
      patientId: this.akitaCaseVisitQuery.getValue().patientId,
      patientName: this.akitaCaseVisitQuery.getValue().patientName,
      patientVisitEntities: this.akitaCaseVisitQuery.getAll(),
      personIncharge: personIncharge,
      primaryDoctorId: primaryDoctor,
      remarks: remarks,
      salesOrder: this.akitaCaseVisitQuery.getValue().salesOrder,
      secondaryDoctorIds: secondaryDoctors,
      singleVisit: this.akitaCaseVisitQuery.getValue().singleVisit,
      status: this.akitaCaseVisitQuery.getValue().status,
      visitIds: this.akitaCaseVisitQuery.getValue().visitIds
    }

    return this.apiPatientVisitService.updateCase(this.akitaCaseVisitQuery.getValue().caseId, updatedCaseStructure);
  }

  updateMedicalCoverage(caseId: string, medicalCoverageList: CaseCoveragePlan[]) {
    return this.apiPatientVisitService.attachMedicalCoverage(caseId, medicalCoverageList);
  }

  updateVendorItemList(vendorItemList) {
    return this.akitaCaseVisitQuery.select(entity => entity.caseId).pipe(
      filter(caseId => !!caseId && caseId.length > 0),
      mergeMap(caseId => this.apiPatientVisitService.updateVendorItemCost(caseId, vendorItemList)),
      catchError(err => !!err ? of(err) : of({ statusCode: 'E2000', message: 'Failure' }))
    );
  }

  caseCloseUpdate() {
    this.caseStatusUpdate('CLOSED');
  }

  caseDetailUpdate(data) {
    this.akitaCaseVisitStore.update({
      personIncharge: data.personIncharge,
      primaryDoctorId: data.primaryDoctor,
      remarks: data.remarks,
      secondaryDoctorIds: data.secondaryDoctors
    });
  }

  caseStatusUpdate(status) {
    this.akitaCaseVisitStore.update({ status: status });
  }

  caseUpdateActive(active) {
    this.akitaCaseVisitStore.setActive(active);
  }

  caseUpdateMedicalCoverage(coverages) {
    this.akitaCaseVisitStore.update({ coverages: coverages });
  }

  caseUpdateSinglePVE(id, pve) {
    this.akitaCaseVisitStore.update(id, pve);
  }

  caseUpdatePVE(patientVisitEntities) {
    this.akitaCaseVisitStore.set(patientVisitEntities);
  }

  caseUpdateSalesOrder(salesOrder) {
    this.akitaCaseVisitStore.update({ salesOrder: salesOrder });
  }

  caseUpdateVendorChargeItem(vendorChargeItems) {
    this.akitaCaseVisitStore.update({ vendorChargeItems: vendorChargeItems });
  }

  clearCaseStore() {
    this.akitaCaseVisitStore.reset();
  }


}
