import { Injectable } from '@angular/core';

import { Query } from '@datorama/akita';

import { AkitaReferralStore } from './akita-referral.store';
import { ReferralData, ReferralDataChild } from '../../objects/state/ReferralData';

@Injectable({ providedIn: 'root' })
export class AkitaReferralQuery extends Query<ReferralData> {
  constructor(protected store: AkitaReferralStore) {
    super(store);
  }

  getReferral(type: string, speciality: string = ''): ReferralDataChild[] {
    const referralData = this.getValue()[type.toUpperCase()];
    if (!!speciality && speciality !== 'All') {
        return referralData.filter(doc => doc.speciality === speciality);
    }
    return referralData;
  }

  getReferralByDoctor(type: string, speciality: string, doctorId: string): ReferralDataChild {
    let referralData = this.getValue()[type.toUpperCase()];
    if (!!speciality && speciality !== 'All') {
      referralData = referralData.filter(doc => doc.speciality === speciality);
    }
    if (!!doctorId) {
      referralData = referralData.filter(doc => doc.id === doctorId);
    }
    return referralData && referralData.length > 0 ? referralData[0] : null;
  }
}
