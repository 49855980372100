import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { ChargeItem } from '../../objects/state/ChargeItem';

export interface AkitaClinicChargeItemState extends EntityState<ChargeItem> {
  ui: {
    code: string;
    itemType: string;
    name: string;
    supplierId: string;
  };
}

const initialState = {
  ui: {
    code: '',
    itemType: '',
    name: '',
    supplierId: ''
  },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'clinicchargeitem',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaClinicChargeItemStore extends EntityStore<
  AkitaClinicChargeItemState,
  ChargeItem
> {
  constructor() {
    super(initialState);
  }

  addNewData(data) {
    this.set(data);
  }
}
