import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
  name: 'inventoryDoReceiverAddress'
})
export class InventoryDoReceiverAddressPipe implements PipeTransform {
  constructor(private store: StoreService) {}

  transform(value: any, args?: any): any {
    let isFromReturnRequest = args;

    if (!value) {
      return '';
    }

    if (isFromReturnRequest) {
      return (this.store.getSupplierById(value) || { address: { address: '' } }).address.address;
    } else {
      return (
        this.store.getClinicList().find(clinic => clinic.id === value) || {
          address: { address: '' }
        }
      ).address.address;
    }
  }
}
