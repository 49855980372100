const removeElementByKey = <T>(arr: T[], key: string): T[] => {
  const obj: { [key: string]: T } = {};
  arr.forEach(val => {
    if (val[key]) {
      obj[val[key]] = val;
    }
  });
  return Object.values(obj);
};

const difference = (a: string[], b: string[]): string[] => {
  const s = new Set(b);
  return a.filter(x => !s.has(x));
};

const isDifferent = (a: string[], b: string[]): boolean => {
  return !!difference(a, b).length;
};

const differenceByKey = <T>(a: T[], b: T[], key: string): T[] => {
  const s = new Set(b.map(val => val[key]));
  return a.filter(el => !s.has(el[key]));
};

const updateArrayBy = <T>(target: T[], source: T[], key: string): T[] => {
  return target.map(curr => {
    const sourceValue = source.find(val => val[key] === curr[key]);
    return sourceValue ? sourceValue : curr;
  });
};

const flat = <K>(arr: K[][]): K[] => [].concat(...arr);

const flattenAndGetUnique = <T>(source: T[][], code: string): T[] => {
  const flatData = flat(source);
  return removeElementByKey(flatData, code);
};

const filterByKeyValue = <K>(arr: K[], key: string, value: any): K[] => {
  return arr.filter(item => item[key].toLowerCase() === value);
};

const groupByKey = <K>(arr: K[], key: string): {} => {
  return arr.map(val => val[key]).reduce((acc, val, i) => {
    acc[val] = { ...arr[i] };
    return acc;
  }, {})
};


const groupByKeyInArray = <K>(arr: K[], key: string): Map<string, K[]> => {
  const group = new Map();
  arr.forEach(item => {
    const mapKey = item[key];
    if (group.has(mapKey)) {
      const value = group.get(mapKey);
      value.push(item)
    } else {
      group.set(mapKey, [item])
    }
  });

  return group;
};

export {
  removeElementByKey,
  differenceByKey,
  difference,
  isDifferent,
  updateArrayBy,
  flat,
  flattenAndGetUnique,
  filterByKeyValue,
  groupByKey,
  groupByKeyInArray
};
