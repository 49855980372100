import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';

@Injectable()
export class ApiPersonalNotesService {
  private API_PERSONAL_NOTES_URL;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.API_PERSONAL_NOTES_URL =
      appConfig.getConfig().API_URL_ENV + '/personal-note';
  }

  getAllNotes(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PERSONAL_NOTES_URL}/list/all`,
      JSON.stringify({})
    );
  }

  saveNote(id: string, reqBody): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PERSONAL_NOTES_URL}/save/${id}`,
      JSON.stringify(reqBody)
    );
  }

  deleteNote(id: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PERSONAL_NOTES_URL}/delete/${id}`,
      JSON.stringify({})
    );
  }

  addnewNote(reqBody): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PERSONAL_NOTES_URL}/add`,
      JSON.stringify(reqBody)
    );
  }
}
