<div class="row">
    <div class="col-12">
        <h5>Include as part of your exercise routine,</h5>

        <table class="custom-table">
            <thead>
                <tr>
                    <th class="w-20"></th>
                    <th class="w-40">Default Advice Served to Residents</th>
                    <th>Optional Comments</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of exerciseRecommendationList; let i = index;">
                    <tr [formGroup]="item.commentFormGroup">
                        <td>
                            <div class="d-flex">
                                <input *ngIf="item.canEnableDisable" class="mr-2" type="checkbox"
                                    (change)="onCheck($event.target.checked, item)" formControlName="selected">
                                <p class="exercise">{{item.label}} <span>exercise</span></p>
                            </div>
                        </td>
                        <td>
                            <span class="default-advice">{{item.remarks}}</span>
                        </td>
                        <td>
                            <div *ngIf="item.comment && item.commentFormGroup" class="wrapper-form-control">
                                <input class="input" formControlName="comment">
                            </div>
                        </td>
                    </tr>

                    <ng-container *ngFor="let subWorkflow of item.subWorkflowList; let j = index;">

                        <ng-container *ngIf="subWorkflow.subWorkflowList.length > 0; else noChildSubworkflow; let l = index;">
                            <ng-container *ngFor="let subWorkflowItem of subWorkflow.subWorkflowList; let k = index;">
                                <tr class="border-bottom-none">
                                    <td colspan="2" class="pl-5">
                                        <div class="d-flex" [formGroup]="subWorkflowItem.bodySectionFormGroup">
                                            <input class="mr-2" *ngIf="subWorkflow.type === 'subworkflow-single-choice' " type="radio"
                                                [id]="'radioButton' + k" name="selected" [value]="true"
                                                (change)="onRadio($event.target.checked, subWorkflow.subWorkflowList, k)"
                                                formControlName="selected" />
                                            <app-care-plan-body [bodySectionFormGroup]="subWorkflowItem.bodySectionFormGroup"
                                                [bodySection]="subWorkflowItem.bodySections">
                                            </app-care-plan-body>
                                        </div>
                                        <app-care-plan-error-message class="d-block"
                                            [bodySectionFormGroup]="subWorkflowItem.bodySectionFormGroup"
                                            [bodySection]="subWorkflowItem.bodySections">
                                        </app-care-plan-error-message>
                                    </td>

                                    <td>
                                        <div *ngIf="subWorkflowItem.comment && subWorkflowItem.commentFormGroup"
                                            class="wrapper-form-control" [formGroup]="subWorkflowItem.commentFormGroup">
                                            <input class="input" formControlName="comment">
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>

                        <ng-template #noChildSubworkflow>
                            <tr>
                                <td colspan="2" class="pl-5" [formGroup]="subWorkflow.bodySectionFormGroup">
                                    <div class="d-flex">
                                        <input *ngIf="subWorkflow.canEnableDisable" class="mr-2" type="checkbox"
                                            (change)="onCheck($event.target.checked, subWorkflow)" formControlName="selected">
                                        <app-care-plan-body [bodySectionFormGroup]="subWorkflow.bodySectionFormGroup"
                                            [bodySection]="subWorkflow.bodySections">
                                        </app-care-plan-body>
                                    </div>
                                    <app-care-plan-error-message class="d-block" [bodySectionFormGroup]="subWorkflow.bodySectionFormGroup"
                                        [bodySection]="subWorkflow.bodySections">
                                    </app-care-plan-error-message>
                                </td>
                                <td>
                                    <div *ngIf="subWorkflow.comment && subWorkflow.commentFormGroup" class="wrapper-form-control"
                                        [formGroup]="subWorkflow.commentFormGroup">
                                        <input class="input" formControlName="comment">
                                    </div>
                                </td>
                            </tr>
                        </ng-template>

                        <tr>
                            <td></td>
                            <td>
                                <span class="default-advice">{{subWorkflow.remarks}}</span>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>