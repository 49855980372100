import { Injectable } from '@angular/core';

import { StoreConfig, EntityStore } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { Doctor } from '../../objects/state/Doctor';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'clinicdoctor',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaClinicDoctorStore extends EntityStore<Doctor> {
  constructor() {
    super();
  }
}
