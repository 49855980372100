import { Injectable } from "@angular/core";

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { ChargeItem } from '../../objects/state/ChargeItem';

export interface AkitaClinicPurchasableChargeItemState extends EntityState<ChargeItem> {
  ui: {
    code: string
    itemType: string
    name: string
  };
}

const initialState = {
  ui: {
    code: '',
    itemType: '',
    name: ''
  }
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'clinipurchasablecchargeitem', idKey: 'id', cache: { ttl: AKITA_CACHE_DURATION }, resettable: true })
export class AkitaClinicPurchasableChargeItemStore extends EntityStore<AkitaClinicPurchasableChargeItemState, ChargeItem> {

  constructor() {
    super(initialState);
  }

  addNewData(data) {
    this.set(data);
  }

}
