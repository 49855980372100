import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private executingApi: Array<string> = new Array();
  executingApiChanges: BehaviorSubject<Array<string>> = new BehaviorSubject(
    this.executingApi
  );

  constructor() {}

  addExecutingApi(apiUrl: string) {
    this.executingApi.push(apiUrl);
    this.executingApiChanges.next(this.executingApi);
  }

  removeExecutingApi(apiUrl: string) {
    this.executingApi = this.executingApi.filter(api => api !== apiUrl);
    this.executingApiChanges.next(this.executingApi);
  }
}
