import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { NgxPermissionsService } from 'ngx-permissions';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Page } from '../model/page';
import { AssessmentDataContainer } from '../objects/request/AssessmentDataContainer';
import { PatientVisit } from '../objects/request/PatientVisit';
import { VitalDataContainer } from '../objects/request/VitalDataContainer';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { CaseCoveragePlan } from '../objects/state/Case';
import { Vaccination } from '../objects/Vaccination';
import { AlertService } from './alert.service';
import { AppConfigService } from './app-config.service';
import { AkitaCaseVisitStore } from './states/akita-case-visit.store';
import { AkitaClinicQuery } from './states/akita-clinic.query';

@Injectable()
export class ApiPatientVisitService {
  private API_PATIENT_VISIT_URL;
  private API_DOCUMENT_URL;
  private API_INVENTORY_SYSTEM_URL;
  private API_CMS_MANAGEMENT_URL;
  private API_VITAL_URL;
  private API_CLAIM_URL;
  private API_MANUAL_CLAIM_URL;
  private API_COMMUNICATIONS_URL;
  private API_CONSULTATION_URL;
  private API_CASE_INFO_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private permissionService: NgxPermissionsService,
    private alertService: AlertService,
    private caseVisitStore: AkitaCaseVisitStore,
    private akitaClinicQuery: AkitaClinicQuery
  ) {
    this.API_PATIENT_VISIT_URL = appConfig.getConfig().API_PATIENT_VISIT_URL;
    this.API_DOCUMENT_URL = appConfig.getConfig().API_DOCUMENT_URL;
    this.API_INVENTORY_SYSTEM_URL = appConfig.getConfig().API_INVENTORY_SYSTEM_URL;
    this.API_CMS_MANAGEMENT_URL = appConfig.getConfig().API_CMS_MANAGEMENT_URL;
    this.API_VITAL_URL = appConfig.getConfig().API_VITAL_URL;
    this.API_CLAIM_URL = appConfig.getConfig().API_CLAIM_URL;
    this.API_MANUAL_CLAIM_URL = appConfig.getConfig().API_MANUAL_CLAIM_URL;
    this.API_COMMUNICATIONS_URL = appConfig.getConfig().API_COMMUNICATIONS_URL;
    this.API_CONSULTATION_URL = appConfig.getConfig().API_CONSULTATION_URL;
    this.API_CASE_INFO_URL = appConfig.getConfig().API_CASE_INFO_URL;
  }

  initCreate(
    patientVisit: PatientVisit,
    caseId = ''
  ): Observable<HttpResponseBody> {
    let url = `${this.API_PATIENT_VISIT_URL}/create`;
    if (!!caseId) {
      url = `${this.API_PATIENT_VISIT_URL}/create/${caseId}`;
    }
    return this.http.post<HttpResponseBody>(url, JSON.stringify(patientVisit));
  }

  updateVisit(
    visitId: string,
    patientVisit: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/${visitId}`,
      JSON.stringify(patientVisit)
    );
  }

  consult(
    visitId: string,
    doctorId: string,
    notifyQueue: boolean
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/consult/${visitId}/${doctorId}/${notifyQueue}`,
      {}
    );
  }

  noShow(visitId: string, status: boolean): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/queue/no-show/${visitId}/${status}`,
      {}
    );
  }

  saveConsultation(
    visitId: string,
    consultation
  ): Observable<HttpResponseBody> {
    let diagnosisIdsArray = [];
    consultation.diagnosisIds.forEach(diagnosis => {
      if (
        diagnosis == '' ||
        diagnosis == undefined ||
        diagnosis.length < 5
      ) {

      } else {
        diagnosisIdsArray.push(diagnosis)
      }
    });
    consultation.diagnosisIds = diagnosisIdsArray;

    let patientReferrals = [];
    consultation.patientReferral.patientReferrals.forEach(singleRefrral => {
      if (
        singleRefrral.practice == "Other"
      ) {
        singleRefrral.practice = singleRefrral.practiceO

        patientReferrals.push(singleRefrral)
      } else {
        patientReferrals.push(singleRefrral)
      }
    });
    consultation.patientReferral.patientReferrals = patientReferrals;
    let surveyDataList = [];
    if (localStorage.getItem('selectedSurvey')) {
      surveyDataList.push({
        code: localStorage.getItem('selectedSurvey'),
        doctorId: '',
        clinicId: localStorage.getItem('clinicId')
      })
    }
    consultation.surveyDataList = surveyDataList;

    this.clearRemoteDeliveryDetails(consultation);

    this.updateMultiSelectValues(consultation.cdmpDetails);


    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/save/consultation/${visitId}`,
      JSON.stringify(consultation)
    );
  }

  private clearRemoteDeliveryDetails(consultation) {
    if (!consultation.dispatchItemEntities.some(a => a.remoteDelivery)) {
      consultation.remoteDeliveryDetails = null;
    }
  }

  updateMultiSelectValues(cdmpDetails) {
    if (cdmpDetails && cdmpDetails.assessment && cdmpDetails.assessment.assessments) {
      Object.keys(cdmpDetails.assessment.assessments).forEach(key => {
        if (cdmpDetails.assessment.assessments[key] instanceof Array) {
          cdmpDetails.assessment.assessments[key] = cdmpDetails.assessment.assessments[key].join('|');
        }
      })
    }
  }

  saveConsultationForInitialDispensing(
    visitId: string,
    consultation
  ): Observable<HttpResponseBody> {

    let diagnosisIdsArray = [];
    consultation.diagnosisIds.forEach(diagnosis => {
      if (
        diagnosis == '' ||
        diagnosis == undefined ||
        diagnosis.length < 5
      ) {

      } else {
        diagnosisIdsArray.push(diagnosis)
      }
    });
    consultation.diagnosisIds = diagnosisIdsArray;

    this.clearRemoteDeliveryDetails(consultation)

    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/save/initial-consultation/${visitId}`,
      JSON.stringify(consultation)
    );
  }

  saveAddendum(visitId: string, addendum): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/consultation/add/addendum/${visitId}`,
      JSON.stringify(addendum)
    );
  }

  saveDispensing(visitId: string, consultation): Observable<HttpResponseBody> {
    let diagnosisIdsArray = [];
    consultation.diagnosisIds.forEach(diagnosis => {
      if (
        diagnosis == '' ||
        diagnosis == undefined ||
        diagnosis.length < 5
      ) {

      } else {
        diagnosisIdsArray.push(diagnosis)
      }
    });
    consultation.diagnosisIds = diagnosisIdsArray;

    this.clearRemoteDeliveryDetails(consultation)

    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/save/dispensing/${visitId}`,
      JSON.stringify(consultation)
    );
  }

  postConsult(visitId: string, consultation): Observable<HttpResponseBody> {

    let diagnosisIdsArray = [];
    consultation.diagnosisIds.forEach(diagnosis => {
      if (
        diagnosis == '' ||
        diagnosis == undefined ||
        diagnosis.length < 5
      ) {

      } else {
        diagnosisIdsArray.push(diagnosis)
      }
    });
    consultation.diagnosisIds = diagnosisIdsArray;

    this.clearRemoteDeliveryDetails(consultation)

    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/post-consult/${visitId}`,
      JSON.stringify(consultation)
    );
  }

  patientVisitSearch(visitId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/search/${visitId}`,
      {}
    );
  }

  printPatientLabel(
    clinicId: string,
    patientId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/patientLabel/${clinicId}/${patientId}/${templateName}`,
      {}
    );
  }

  printPatientLabelQrCode(
    clinicId: string,
    patientId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/patientLabel/${clinicId}/${patientId}/${templateName}?qrCode=true`,
      {}
    );
  }

  printNewBorn(
    clinicId: string,
    patientId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/newBorn/${clinicId}/${patientId}/${templateName}`,
      {}
    );
  }

  printPatientLabelWithQrCode(
    clinicId: string,
    patientId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/patientLabel/${clinicId}/${patientId}/${templateName}?qrCode=true`,
      {}
    );
  }

  printPatientLabelWithQrCodeInForm(
    visitId: string,
    templateName: string,
    qrCode?: boolean
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/patientLabel/${visitId}/${templateName}?qrCode=${qrCode}`,
      {}
    );
  }

  printLabRequestForm(visitId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/lab-request-form/${visitId}`,
      {}
    );
  }

  printTimeChit(
    visitId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/timeChit/${visitId}/${templateName}`,
      {}
    );
  }

  printDeliveryNotes(
    visitId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/drug-delivery-note/${visitId}/${templateName}`,
      {}
    );
  }

  printDrugLabels(
    visitId: string,
    drugs: any,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/drugLabel/${visitId}/${templateName}`,
      JSON.stringify(drugs)
    );
  }

  printDrugItems(
    visitId: string,
    drugs: any,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/drugItem/${visitId}/${templateName}`,
      JSON.stringify(drugs)
    );
  }

  printReferralLetters(
    visitId: string,
    patientReferrals: any,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/referralLetter/${visitId}/${templateName}`,
      JSON.stringify(patientReferrals)
    );
  }

  printHsgPlan(
    planId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/hsgPlan/${planId}/${templateName}`,
      {}
    );
  }

  printConsultationMemo(
    visitId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/consultationMemo/${visitId}/${templateName}`,
      {}
    );
  }

  printVaccinationCertificate(
    vaccinationsSelected: Vaccination,
    templateName: string,
    patientId: string,
    clinicId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/vaccinationCertificate/${templateName}/${patientId}/${clinicId}`,
      JSON.stringify(vaccinationsSelected)
    );
  }

  printBill(
    visitId: string,
    receiptType: string,
    isDraft: boolean,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/bill/${visitId}/${receiptType}/${isDraft}/${templateName}`,
      {}
    );
  }

  emailBill(
    visitId: string,
    receiptType: string,
    isDraft: boolean,
    templateName: string,
    email: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/send/bill/${visitId}/${receiptType}/${isDraft}/${templateName}/${email}`,
      {}
    );
  }

  printCaseBill(
    caseId,
    receiptType,
    isDraft,
    templateName
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/bill/case/${caseId}/${receiptType}/${isDraft}/${templateName}`,
      JSON.stringify({})
    );
  }

  emailCaseBill(
    caseId,
    receiptType,
    isDraft,
    templateName,
    email
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/send/bill/case/${caseId}/${receiptType}/${isDraft}/${templateName}/${email}`,
      JSON.stringify({})
    );
  }

  printAppointmentLabels(
    param: any,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/label/print/appointment/${templateName}`,
      JSON.stringify(param)
    );
  }


  updateCase(caseId, caseInfo): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/case/update/${caseId}`,
      JSON.stringify(caseInfo)
    );
  }

  attachMedicalCoverage(
    caseId: string,
    medicalCoverages: CaseCoveragePlan[]
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      // `${this.API_PATIENT_VISIT_URL}/attach-coverage/${patientVisitRegistryId}`,
      `${this.API_CMS_MANAGEMENT_URL}/case/update/medical-coverage/${caseId}`,
      JSON.stringify(medicalCoverages)
    );
  }

  getPatientVisitHistoryFullList(
    patientId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/${patientId}`,
      {}
    );
  }

  getPatientVisitHistoryWithDateRange(
    patientId: string,
    startDate: string,
    endDate: string,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/${patientId}/${startDate}/${endDate}/${page.pageNumber}/${page.size}`,
      {}
    );
  }

  getPatientVisitHistoryWithDateRangeAndStatusFilter(
    patientId: string,
    startDate: string,
    endDate: string,
    status: Array<string>,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/filter/${patientId}/${startDate}/${endDate}/${page.pageNumber}/${page.size}`,
      JSON.stringify(status)
    );
  }

  getPatientVisitHistoryWithDateRangeAndClinicGroupFilter(
    patientId: string,
    startDate: string,
    endDate: string,
    status: any,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/filter-with-clinic-group/${patientId}/${startDate}/${endDate}/${page.pageNumber}/${page.size}`,
      JSON.stringify(status)
    );
  }

  getPatientVisitHistory(
    patientId: string,
    page: number = 0,
    size: number = 100
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/${patientId}/${page}/${size}`,
      {}
    );
  }

  // https://devserver.lippoinnolab.com/cms-dua-oct/patient-visit/list/5e12b2ffdbea1b0f7111058c/06-03-2019/06-03-2020/0/1

  // getPatientVisitHistoryPage(patientId: string, page: Page): Observable<HttpResponseBody> {
  //   return this.http.post<HttpResponseBody>(
  //     `${this.API_PATIENT_VISIT_URL}/list/${patientId}/${page.pageNumber}/${page.size}`,
  //     {},
  //
  //   );
  // }

  getPatientVisitHistoryByDate(
    patientId: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/patient-visit-history/by-date/${patientId}/${startDate}/${endDate}`,
      {}
    );
  }

  getPatientVisitHistoryByDateAndFilter(
    patientId: string,
    searchKey: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/patient-visit-history/by-date/${patientId}/${searchKey}/${startDate}/${endDate}`,
      {}
    );
  }

  getPatientConsultationNotes(consultationId: string) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_URL}/get-consultation-notes/${consultationId}`,
      {}
    );
  }

  patientRegistryList(clinicID: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/patient-visit-history/patient-registry/${clinicID}`,
      JSON.stringify({})
    );
  }

  patientRegistryListWithStartTime(
    clinicID: string,
    from: string,
    to: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/by-time/${clinicID}/${from}/${to}`,
      JSON.stringify({})
    );
  }

  consultationCreate(
    patientId: string,
    requestPayload
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/create`,
      JSON.stringify(requestPayload)
    );
  }

  consultationUpdate(
    consultationId: string,
    requestPayload
  ): Observable<HttpResponseBody> {
    var diagnosisIdsArray = [];
    requestPayload.diagnosisIds.forEach(diagnosisId => {
      if (diagnosisId.length > 5) {
        diagnosisIdsArray.push(diagnosisId)
      }
    })
    requestPayload.diagnosisIds = diagnosisIdsArray;
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/consultation/${consultationId}`,
      JSON.stringify(requestPayload)
    );
  }

  medicalCertificatesUpdate(
    consultationId: string,
    medicalCertificates
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/certificates/${consultationId}`,
      JSON.stringify(medicalCertificates)
    );
  }

  patientReferralUpdate(
    consultationId: string,
    patientReferrals
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/referral/${consultationId}`,
      JSON.stringify(patientReferrals)
    );
  }

  consultationSearch(patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/patient-consultation/search/${patientId}`,
      JSON.stringify({})
    );
  }

  listFollowUps(
    clinicId: string,
    numberOfDays: number,
    page: number,
    size: number
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_COMMUNICATIONS_URL}/followup/${clinicId}/${numberOfDays}/${page}/${size}`,
      JSON.stringify({})
    );
  }

  payment(visitId: string, req: string): Observable<HttpResponseBody> {
    this.clearRemoteDeliveryDetails(req)
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/payment/${visitId}`,
      req
    );
  }

  paymentRollback(
    patientVisitRegistryId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/rollback/${patientVisitRegistryId}`,
      {}
    );
  }

  billPay(
    patientVisitRegistryId: string,
    req: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/bill-payment/pay/${patientVisitRegistryId}`,
      req
    );
  }

  completed(
    visitId: string,
    invoiceArray: Array<any>,
    closeCase = false
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/complete/${visitId}/${closeCase}`,
      JSON.stringify(invoiceArray)
    );
  }

  makeOnlinePayment(visitId: string,): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/pay-with-reference/${visitId}`,
      ''
    );
  }

  sendPaymentNotification(
    visitId: string,
    paylaod: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/send-payment-notification/${visitId}`,
      JSON.stringify(paylaod)
    );
  }

  billSearch(patientVisitRegistryId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/bill-payment/search/${patientVisitRegistryId}`,
      JSON.stringify({})
    );
  }

  // addVital(patientId: string, data: any): Observable<HttpResponseBody> {
  //   return this.http.post<HttpResponseBody>(
  //     `${this.API_VITAL_URL}/add/${patientId}`,
  //     JSON.stringify(data),
  //     {
  //       headers: this.headers
  //     }
  //   );
  // }

  /** VITALS */
  addVitalList(
    vitalDataContainer: VitalDataContainer
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/vital/add/`,
      JSON.stringify(vitalDataContainer)
    );
  }

  addAssessmentList(
    assessmentDataContainer: AssessmentDataContainer
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/assessment/add/`,
      JSON.stringify(assessmentDataContainer)
    );
  }

  listVital(
    patientId: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/vital/list/${patientId}/${startDate}/${endDate}`,
      {}
    );
  }

  listAssessments(
    patientId: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/assessment/list/${patientId}/${startDate}/${endDate}`,
      {}
    );
  }

  listVitalByDateTime(
    patientId: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/vital/list-group-by-date-time/${patientId}/${startDate}/${endDate}`,
      {}
    );
  }

  modifyVital(
    vitalDetailContainerObjId: string,
    vitalDataContainer: VitalDataContainer
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/vital/update/${vitalDetailContainerObjId}`,
      JSON.stringify(vitalDataContainer)
    );
  }

  modifyAssessment(
    assessmentDetailContainerObjId: string,
    vitalDataContainer: AssessmentDataContainer
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/assessment/update/${assessmentDetailContainerObjId}`,
      JSON.stringify(vitalDataContainer)
    );
  }

  modifyVitalList(
    vitalDataContainer: VitalDataContainer
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/vital/update/vital/list`,
      JSON.stringify(vitalDataContainer)
    );
  }

  deleteVital(vitalId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/vital/remove/${vitalId}`,
      {}
    );
  }

  /** OBSERVATIONS */

  listObservations(
    patientId: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/observation/list/${patientId}/${startDate}/${endDate}`,
      {}
    );
  }

  addObservationList(
    vitalDataContainer: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/observation/add/list`,
      JSON.stringify(vitalDataContainer)
    );
  }

  updateObservationList(
    vitalDataContainer: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/observation/update/list`,
      JSON.stringify(vitalDataContainer)
    );
  }

  deleteObservation(
    recordId: string,
    recordType: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/observation/remove/${recordId}/${recordType}`,
      {}
    );
  }

  /** DOCUMENTS */

  listDocuments(listType: string, id: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/list/${listType}/${id}`,
      {}
    );
  }

  getDocumentType(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/list/document-types`,
      {}
    );
  }

  listAllFiles(
    patientId: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/list/patient/${patientId}/${startDate}/${endDate}`,
      {}
    );
  }

  downloadDocument(
    config: string,
    id: string,
    fileId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/download/${config}/${id}/${fileId}`,
      {},
      { responseType: 'blob' as 'json' }
    );
  }

  downloadNonVisitDocument(
    patientId: string,
    fileId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/download/patient/${patientId}/${fileId}`,
      {},
      { responseType: 'blob' as 'json' }
    );
  }

  uploadDocumentsByVisit(
    visitId: string,
    uploadTo: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/upload/visit/${visitId}/${uploadTo}`,
      {}
    );
  }

  deleteDocumentByVisit(visitId: string, files: string[]) {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/delete/visit/${visitId}`,
      JSON.stringify(files)
    );
  }

  deleteDocumentByPatient(
    patientId: string,
    clinicId: string,
    files: string[]
  ) {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/delete/patient/${patientId}/${clinicId}`,
      JSON.stringify(files)
    );
  }

  deleteDocuments(files: any[]) {
    return this.http.post<HttpResponseBody>(
      `${this.API_DOCUMENT_URL}/delete/all `,
      JSON.stringify(files)
    );
  }

  /* */

  getInventory(clinicId: string, inventories): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/inventory/get-usage/${clinicId}`,
      JSON.stringify(inventories)
    );
  }

  checkMhcpBalance(
    clinicId: string,
    planId: string,
    nric: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/mhcp-claim/balance/${clinicId}/${planId}/${nric}`,
      {}
    );
  }

  listClaimsByClinlicByDate(
    clinicId: string,
    medicalCoverageType: string,
    status: string,
    startDate: string,
    endDate: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL
      }/mhcp-claim/list-by-type/${clinicId}/${medicalCoverageType}/${status}/${startDate}/${endDate}/`,
      {}
    );
  }

  listSFLClaimHistory(
    clinicId: string,
    patientNric: string,
    slfCategory: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL
      }/mhcp-claim/sfl-history/${clinicId}/${patientNric}/${slfCategory}`,
      {}
    );
  }

  saveClaim(claimId: string, claim): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/mhcp-claim/save/${claimId}/`,
      JSON.stringify(claim)
    );
  }

  submitClaim(claimId: string, claim): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/mhcp-claim/submit/${claimId}/`,
      JSON.stringify(claim)
    );
  }

  rejectClaim(claimId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLAIM_URL}/mhcp-claim/reject/${claimId}/REJECTED_PERMANENT`,
      {}
    );
  }

  updateClaimPaymentAmount(payload: any) {
    let url = `${this.API_MANUAL_CLAIM_URL}/claim/update/claims-amount`;
    return this.http.post<HttpResponseBody>(url, JSON.stringify(payload), {});
  }

  listClaimInvoice(
    clinicIds: string[],
    medicalCoverageId: string,
    medicalCoverageType: string,
    startDate: string,
    endDate: string,
    isSubmission: boolean,
    patientNric: string,
    caseNumber: string
  ) {
    let url = `${this.API_MANUAL_CLAIM_URL}/claim/list/to-update-amount`;
    const payLoad = {
      medicalCoverageId,
      startDate,
      endDate,
      isSubmission,
      clinicIdList: clinicIds,
      patientNric,
      caseNumber,
    };

    if (!!medicalCoverageType) {
      payLoad['coverageType'] = medicalCoverageType;
    }

    return this.http.post<HttpResponseBody>(url, JSON.stringify(payLoad), {});
  }

  listClaimPaymentDetails(
    clinicIds: string[],
    medicalCoverageId: string,
    medicalCoverageType: string,
    startDate: string,
    endDate: string,
    status: string,
    pageNumber: number,
    pageSize: number
  ) {
    const payLoad = {
      medicalCoverageId: medicalCoverageId,
      startDate: startDate,
      endDate: endDate,
      clinicIdList: clinicIds,
      status: status
    };

    if (!!medicalCoverageType) {
      payLoad['coverageType'] = medicalCoverageType;
    }

    let url = `${this.API_MANUAL_CLAIM_URL}/claim/list/claim-payment-details/${pageNumber}/${pageSize}`;

    return this.http.post<HttpResponseBody>(url, JSON.stringify(payLoad));
  }

  listManualClaimsByMultipleClinlicsByDate(
    clinicIds: string[],
    medicalCoverageType: string,
    status: string,
    startDate: string,
    endDate: string,
    payerNric: string,
    page: Page
  ): Observable<HttpResponseBody> {
    let url = `${this.API_MANUAL_CLAIM_URL
      }/claim/list-by-type/${medicalCoverageType}/${payerNric}/${status}/${startDate}/${endDate}`;
    if (payerNric === undefined || payerNric === null || payerNric === '')
      url = `${this.API_CLAIM_URL}/claim/list-by-type/${medicalCoverageType}/${status}/${startDate}/${endDate}`; // list-by-type/${medicalCoverageType}/${status}/${startDate}/${endDate}/${page.pageNumber}/${page.size}`,
    return this.http.post<HttpResponseBody>(url, JSON.stringify(clinicIds));
  }

  saveClaimManual(claimId: string, claim): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_MANUAL_CLAIM_URL}/claim/save/${claimId}`,
      JSON.stringify(claim)
    );
  }

  rejectClaimManual(claimId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_MANUAL_CLAIM_URL}/claim/reject/${claimId}/REJECTED_PERMANENT`,
      {}
    );
  }

  checkDrugBatchNo(
    clinicId: string,
    inventories
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/inventory/get-usage-with-batch-no/${clinicId}`,
      JSON.stringify(inventories)
    );
  }

  getCaseDetailsById(caseId: string) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/get-case-details/${caseId}`,
      JSON.stringify({})
    );
  }

  listItemForVendorCostUpdate(caseId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/list/items-for-vendor-cost/${caseId}`,
      JSON.stringify({})
    );
  }

  updateVendorItemCost(caseId: string, chargeItemList: Array<any>) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/update/items-cost/${caseId}`,
      JSON.stringify(chargeItemList)
    );
  }

  closeCase(caseId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/close/${caseId}`,
      JSON.stringify({})
    );
  }

  forceCloseCase(caseId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/update/close/${caseId}`,
      JSON.stringify({})
    );
  }
  getVisit(caseId): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/by-case/${caseId}`,
      {}
    );
  }

  getVisitByCaseId(caseId: string): void {
    this.http
      .post<HttpResponseBody>(
        `${this.API_PATIENT_VISIT_URL}/list/by-case/${caseId}`,
        {}
      )
      .pipe(tap(data => { }))
      .subscribe(
        data => {
          if (data) {
            const { payload } = data;

            const mappedVisits = payload.map(visit => {
              const tempVisit = {
                visitId: visit.visitId,
                clinicName: visit.clinicName,
                visitDate: moment(
                  moment(visit.startTime, 'DD-MM-YYYYT00:00:00')
                ).format('DD-MM-YYYY'),
                diagnosis: visit.diagnosisEntities,
                drugDispatch: visit.medicalReferenceEntity.dispatchItemEntities,
              };
              return tempVisit;
            });
            this.caseVisitStore.set(mappedVisits);
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  retrieveVisitByCaseId(caseId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/by-case/${caseId}`,
      JSON.stringify({})
    );
  }

  patientVisitList(
    patientId: string,
    date,
    caseId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/list/by-month/${patientId}/${date}/${caseId}/10`,
      {}
    );
  }

  patientVisitRemove(
    visitId: string,
    caseId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/remove/${visitId}/${caseId}`,
      {}
    );
  }

  patientVisitAttach(
    caseId: string,
    visitIds: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/attach/${caseId}`,
      JSON.stringify(visitIds)
    );
  }

  listQueue(clinicId: string): Observable<HttpResponseBody> {
    if (this.permissionService.getPermission('ROLE_DOCTOR')) {
      return this.http.post<HttpResponseBody>(
        `${this.API_PATIENT_VISIT_URL}/list/queue/${clinicId}`,
        {}
      );
    } else {
      return EMPTY;
    }
  }

  nextPatient(
    clinicId: string,
    doctorId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/queue/next-patient/${clinicId}/${doctorId}`,
      {}
    );
  }

  getLabRequestFormData(visitId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/lab-request/${visitId}`,
      {}
    );
  }

  getLabRequestFormDataForSupplierChange(
    visitId: string,
    supplierId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/lab-request/${visitId}/${supplierId}`,
      {}
    );
  }

  saveLabRequestForm(
    visitId: string,
    payload: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/lab-request/save/${visitId}`,
      JSON.stringify(payload)
    );
  }

  searchLabItemByKeyWord(
    selectedSupplierId: string,
    keyword: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/lab-request/search/test/${selectedSupplierId}/${keyword}`,
      {}
    );
  }

  loadLabs(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/load/labs`,
      {}
    );
  }

  getLabRequestList(
    patientId: string,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/lab-request/list/${patientId}/${page.pageNumber}/${page.size}`,
      {}
    );
  }

  generateVideoLink(visitId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/get/video-link/${visitId}`,
      {}
    );
  }

  // CANCEL a patient visit
  cancelVisit(visitId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/cancel-by-id/${visitId}`,
      {}
    );
  }

  addUpdateDiagnosisCode(
    visitId: string,
    payload: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/update/diagnosis/${visitId}`,
      JSON.stringify(payload)
    );
  }
}
