import { MedicalCoverage } from './../objects/MedicalCoverage';
import { StoreService } from './../services/store.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayCoverageAttribute',
})
export class DisplayCoverageAttributePipe implements PipeTransform {
  constructor(private storeService: StoreService) {}

  transform(value: any, args?: any): any {
    const plan: MedicalCoverage = this.storeService.getMedicalCoverageByPlanId(
      value
    );
    return plan ? plan[args] : 'N/A';
  }
}
