import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AkitaPatientAppQuery } from '../../../../../services/states/akita-patient/akita-patient-app.query';
import { PatientMedicalCoverageCorpQuery } from '../../../../../services/states/akita-patient/patient-medical-coverages/patient-medical-coverage-corp.query';

import { AkitaPatientStoreService } from '../../../../../services/states/akita-patient/akita-patient-store.service';

@Component({
  selector: 'app-check-coverage',
  templateUrl: './check-coverage.component.html',
  styleUrls: ['./check-coverage.component.scss']
})
export class CheckCoverageComponent implements OnInit {
  
  @Input() newPatientId: string;
  
  patientInsuranceCoverages: FormArray;
  patientCorporateCoverages: FormArray;
  currentPatientCoverages: FormArray;
  
  public event: EventEmitter<any> = new EventEmitter();
  private componentDestroyed: Subject<void> = new Subject();
  searchParam: any;
  selectedItems = [];
  isRequesting = true;

  constructor(
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaPatientStoreService: AkitaPatientStoreService,
    private patientMedicalCoverageCorpQuery: PatientMedicalCoverageCorpQuery
  ) { }

  ngOnInit() {
    if(this.newPatientId){
      this.searchParam = this.newPatientId;
      this.search();
    } else{
      this.akitaPatientAppQuery.patientInfo$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(info => {
        this.searchParam = info.userId.number;
        this.search();
      });
    }

    this.patientMedicalCoverageCorpQuery.medicalCovToFG$.subscribe(res => {
      // Get INSURANCE formArray
      const insuranceFormArray = new FormArray([]);
      res.controls.forEach(formGroup => {
        if(formGroup.value.coverageType === 'INSURANCE') {
          insuranceFormArray.push(formGroup);
        }
      });

      this.patientInsuranceCoverages = insuranceFormArray;

      // Get CORPORATE formArray
      const corporateFormArray = new FormArray([]);
      res.controls.forEach(formGroup => {
        if(formGroup.value.coverageType === 'CORPORATE') {
          corporateFormArray.push(formGroup);
        }
      });

      this.patientCorporateCoverages = corporateFormArray;

      this.isRequesting = false;
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  save() {
    let item;
    let type: string = '';

    if(this.patientInsuranceCoverages.controls.find(item=> item.get('id').value === this.selectedItems[0])) {
      item = this.patientInsuranceCoverages.controls.find(item=> item.get('id').value === this.selectedItems[0]);
      type = 'INSURANCE';
    } else {
      item = this.patientCorporateCoverages.controls.find(item=> item.get('id').value === this.selectedItems[0]); 
      type = 'CORPORATE';
    }

    if (this.currentPatientCoverages.controls.find(current => 
      current.get('planSelected').value.id === item.get('planSelected').value.id)){
      alert('Duplicate plan found.')
    } else {
      item.get('coverageType').patchValue(type);
      item.get('isNew').patchValue('true');
      this.event.emit(item);
    }
  }

  search(){
    this.isRequesting = true;
    this.patientInsuranceCoverages = null;
    this.selectedItems = [];
    const payload = {"searchInput": this.searchParam};
    this.akitaPatientStoreService.getMedicalCoveragesByIdentity(payload, this.newPatientId);
  }

  onBtnExit() {
    this.event.emit('Close');
  }
}