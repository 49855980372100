export class PurchaseRequestJustification {
    code: string;
    instruct: string;
    description: string;
    billAdjustmentIdentifier: boolean;
    adapt(obj?): PurchaseRequestJustification {
      if (!obj) {
        return this;
      }
  
      this.code = obj.code;
      this.instruct = obj.instruct;
      this.description = obj.description;
      this.billAdjustmentIdentifier = obj.billAdjustmentIdentifier;
  
      return this;
    }
  }