export class StartStockTakeRequest {
  startDate: string;
  startTime: string;
  countType: string;
  startRange: string;
  endRange: string;
  countName: string;
  itemStockTakeRange:[];

  adapt(obj?): StartStockTakeRequest {
    if (!obj) {
      return this;
    }

    this.startDate = obj.startDate;
    this.startTime = obj.startTime;
    this.countType = obj.countType;
    this.startRange = obj.startRange;
    this.endRange = obj.endRange;
    this.countName = obj.countName;
    this.itemStockTakeRange=obj.itemStockTakeRange;

    return this;
  }
}
