<form [formGroup]="patientPayables" novalidate>
    <div class="card">
        <div class="card-header">
            PATIENT PAYABLES
        </div>
        <div class="card-body">
            <div class="row plan-remark-headers align-middle">
                <!-- TITLE -->
                <div class="col-md">Date</div>
                <div class="col-md">Case Number</div>
                <div class="col-md">Amount Before GST</div>
                <div class="col-md">Paid?</div>
            </div>
            <!-- DETAIL -->

            <ng-container *ngIf="patientPayables.value">
                <app-patient-payable-item class="w-100"
                    *ngFor="let patientPayable of patientPayables.controls; let i=index;"
                    [patientPayable]="patientPayable">
                </app-patient-payable-item>
            </ng-container>
        </div>
    </div>
</form>