import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoaderService } from './components/loading/loader.service';
import { AkitaAppQuery } from './services/states/akita-app.query';
import { InactivityService } from './services/inactivity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'app';
  isLoading: Observable<boolean>;
  hasError = false;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private akitaAppQuery: AkitaAppQuery,
    private inactivityService: InactivityService
  ) {}

  ngOnInit() {
    this.storeReadinessSubscription();

    this.isLoading = this.loaderService
      .getLoading()
      .pipe(tap(storeloadingFlg => {}));

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.checkUserActivity()
  }

  public storeReadinessSubscription() {
    this.akitaAppQuery.storeReadiness$.subscribe(data => {
      const count = this.akitaAppQuery.getCount();
      this.hasError =
        data.success + data.init + data.failure === count
        && data.failure > 0
        && count > 0;
    });
  }

  public resetCount() {
    this.hasError = false;
  }

  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:mouseover')
  private checkUserActivity(): void {
    this.inactivityService.watchUserInactivity();
  }
}
