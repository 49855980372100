import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { DB_FULL_DATE_FORMAT } from '../../constants/app.constants';
import { Doctor } from '../../objects/state/Doctor';
import { ApiPatientInfoService } from '../../services/api-patient-info.service';
import { SidebarService } from '../../services/sidebar.service';
import { AkitaAppQuery } from '../../services/states/akita-app.query';
import { AkitaClinicDoctorQuery } from '../../services/states/akita-clinic-doctor.query';
import { AkitaClinicDoctorStore } from '../../services/states/akita-clinic-doctor.store';
import { AkitaStoreService } from '../../services/states/akita-store.service';
import { StoreService } from '../../services/store.service';
import { VisitManagementService } from '../../services/visit-management.service';
import { HEADER_TITLES } from './../../constants/app.constants';
import { MedicalCoverageResponse } from './../../objects/response/MedicalCoverageResponse';
import { AlertService } from './../../services/alert.service';
import { AuthService } from './../../services/auth.service';
import { UtilsService } from './../../services/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnDestroy, OnDestroy {
  showRegistryMenu = false;
  showPatientDetails = false;
  headerTitle = '';
  notifications = [];
  doctor;
  clinic;
  username;
  isDoctorOnline = false;
  doctorGroup;
  speciality;
  patientInfo;
  coverages: MedicalCoverageResponse;
  medicalCoverageFormArray: FormArray;
  promptDisableCalledOnce: boolean = false;
  
  private componentDestroyed: Subject<void> = new Subject();
  handler;
  
  public logo: string;
  public isSidebarOpen: boolean = false;
  private isSidebarOpenSub: Subscription;

  @ViewChild('pop') pop: any;

  constructor(
    private alertService: AlertService,
    private apiPatientInfoService: ApiPatientInfoService,
    private auth: AuthService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    public store: StoreService,
    private utilsService: UtilsService,
    private visiManagementService: VisitManagementService,
    private clinicDoctorQuery: AkitaClinicDoctorQuery,
    private akitaAppQuery: AkitaAppQuery,
    private akitaClinicDoctorQuery: AkitaClinicDoctorQuery,
    private clinicDoctorStore: AkitaClinicDoctorStore,
    private sidebarService: SidebarService,
    private akitaStoreService: AkitaStoreService
  ) {
    this.updateHeaderSpecialUI(this.router.url);
    router.events.pipe(takeUntil(this.componentDestroyed)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/pages/inventory/')) {
          this.updateInventoryModuleHeaderTitle(event.url);
        } else if (event.url.includes('/patient/detail/')) {
          this.headerTitle = 'Patient Particulars';
        } else {
          HEADER_TITLES.forEach(title => {
            if (title.url === event.url) {
              this.headerTitle = title.value;
              if (title.value === 'Appointments' && this.akitaAppQuery.checkClinicFeatureExist('FOLLOWUP_APPOINTMENT')) {
                this.headerTitle = 'Appointments / Follow up'
              }
            }
          });
        }

        this.updateHeaderSpecialUI(event.url);
      }
    });

    this.getNotificationList();
  }

  ngOnInit(): void {
    this.isSidebarOpen = !document.querySelector('body').classList.contains('sidebar-minimized');

    this.promptDisableTranslate();
    const user = this.store.getUser();
    
    if(user.context['cms-user-id'] !== undefined && user.context['cms-user-id'] !== null) {
      this.apiPatientInfoService.getDoctorOnlineStatus(user.context['cms-user-id'], this.store.getClinicId())
        .pipe(
          untilDestroyed(this)
        )
        .subscribe(res => {
          if (res.statusCode === 'S0000') {
            this.isDoctorOnline = res.payload
          }
        });
      this.store.getOpenNotificationPanel().pipe(untilDestroyed(this)).subscribe(res => {
        if (res) {
          this.getNotificationList(true);
          this.pop.show();
        }
      });
    }

    this.isSidebarOpenSub = this.sidebarService.getStatus().subscribe(result => {
      this.isSidebarOpen = result;
      this.setLogo();
    });

    this.setLogo();
  }

  public setLogo(): void {
    const clinic = this.store.getClinic();

    if (clinic.clinicLogo && this.isSidebarOpen) {
      this.logo = '/assets/img/sidebar/opened/'+clinic.clinicLogo;
    } else if (clinic.clinicLogo && !this.isSidebarOpen) {
      this.logo = '/assets/img/sidebar/closed/'+clinic.clinicLogo;
    } else {
      this.logo = undefined;
    }
  }

  updateHeaderSpecialUI(url) {
    if (url.toLowerCase().includes('consultation/add')) {
      this.showRegistryMenu = true;
    } else if (url.toLowerCase().includes('patient/management')) {
      this.showPatientDetails = true;
    } else {
      this.showRegistryMenu = false;
      this.showPatientDetails = false;
    }
  }

  updateInventoryModuleHeaderTitle(url: string) {
    const header = HEADER_TITLES.filter(title =>
      title.url.includes('/pages/inventory/')
    ).find(title => {
      if (url.includes('special')) {
        return title.url.includes('special');
      }
      return url.includes(title.url);
    });
    if (header) {
      this.headerTitle = header.value;
    }
  }

  navHelpPage() {
    window.open('https://faq.healthwaymedical.com/', '_blank');
  }

  getNotificationList(fromNotifiClick?: boolean) {
    this.store.updateNotificationList(fromNotifiClick);
    this.store.startShowingToast = true;
    const clinicId = this.store.getClinicId();

    if (!!clinicId) {
      this.apiPatientInfoService.listNotifications(clinicId).subscribe(res => {
        this.notifications = this.store
          .getNotificationList()
          .sort((a, b) => (a.read === b.read ? 0 : a.read ? -1 : 1))
          .sort((a, b) =>
            moment(b.createdDateTime, DB_FULL_DATE_FORMAT).isAfter(
              moment(a.createdDateTime, DB_FULL_DATE_FORMAT)
            )
              ? 1
              : -1
          )
          .map(notification => {
            return {
              id: notification.id,
              read: notification.read,
              message: notification.message,
              fromNow: moment(
                notification.createdDateTime,
                DB_FULL_DATE_FORMAT
              ).fromNow(),
              type: notification.type
            };
          });
      });
    }
  }

  pick(obj: Object, keys): Object {
    return Object.keys(obj)
      .filter(key => keys.includes(key))
      .reduce((pickedObj, key) => {
        pickedObj[key] = obj[key];
        return pickedObj;
      }, {});
  }

  updatePatientInfo(arr) {
    const keys = [
      'userId',
      'name',
      'Sex',
      'dob',
      'maritalStatus',
      'contactNumber',
      'address',
    ];
    this.patientInfo = this.pick(arr, keys);
  }

  promptDisableTranslate() {
    document.getElementsByTagName('html')[0].addEventListener(
      'DOMSubtreeModified',
      (this.handler = function(e) {
        e.target.removeEventListener('DOMSubtreeModified', this.handler);
        if (!this.promptDisableCalledOnce) {
          let translatedElements = document.getElementsByClassName(
            'translated-ltr'
          );
          if (translatedElements.length > 0) {
            this.promptDisableCalledOnce = true;
            alert(
              'This page has been translated. Please disable translation and refresh browser.'
            );
            return;
          }
        }
      }),
      { capture: true }
    );
  }

  onBtnProfile() {
    const user = this.store.getUser();
    this.clinic = this.store
      .getClinicList()
      .find(clinic => clinic.id === this.store.getClinicId());
    this.doctor = this.store
      .getDoctorList()
      .find(doctor => doctor.id === this.store.getUserId());

    if (
      this.permissionsService.getPermission('ROLE_CA') &&
      !this.permissionsService.getPermission('ROLE_DOCTOR')
    ) {
      // CA Role
      this.username = user.firstName + ' ' + user.lastName;
      this.doctorGroup = 'CA';
      this.speciality = 'Clinic Assistant ';
    } else if (
      this.permissionsService.getPermission('ROLE_DOCTOR') &&
      !this.permissionsService.getPermission('ROLE_CA')
    ) {
      // Doctor Role
      this.username = this.doctor.name;
      this.doctorGroup = this.doctor.doctorGroup;
      this.speciality = this.doctor.speciality;
    } else {
      // Admin / Other Role
      this.username = user.firstName + ' ' + user.lastName;
      this.doctorGroup = 'Admin';
      this.speciality = 'Administration Office';

    }
  }
  onBtnLogout() {
    this.visiManagementService.showFullLayoutLoading();
    this.auth
      .isLogout()
      .pipe(take(1))
      .subscribe(() => {
        this.visiManagementService.hideFullLayoutLoading();
      });
    const isDoctor = this.permissionsService.getPermission('ROLE_DOCTOR');
    if (isDoctor) {
      this.updateDoctorOnlineStatus(false, true);
    }

    this.auth.logout();
    this.akitaStoreService.unsubscribeStore()
  }

  onBtnChangePassword() {
    this.router.navigate(['/pages/profile']);
  }

  markAllAsRead() {
    let markUnread = this.notifications.filter(
      notification => !notification.read
    );
    let apiCalls = [];
    markUnread.forEach(notification => {
      if (!notification.read) {
        apiCalls.push(
          this.apiPatientInfoService.markNotificationAsRead(notification.id)
        );
      }
    });

    forkJoin(apiCalls).subscribe(
      arr => {
        markUnread.forEach(notification => {
          notification.read = true;
        });
        // this.store.updateNotificationList();
        this.getNotificationList();

        this.store.updateUnreadNotificationList();
      },
      err => this.alertService.error(JSON.stringify(err.error['message']))
    );
  }

  hasUnread() {
    return (
      this.notifications.findIndex(notification => !notification.read) > -1
    );
  }

  formatToTitleCase(string) {
    return this.utilsService.convertToTitleCaseUsingSpace(string);
  }

  markAsRead(notification) {
    if (!notification.read) {
      this.apiPatientInfoService
        .markNotificationAsRead(notification.id)
        .subscribe(
          res => {
            notification.read = true;
            this.getNotificationList();
          },
          err => console.error(JSON.stringify(err))
        );
    }
  }

  updateOnlineStatus(online) {
    this.updateDoctorOnlineStatus(online);
  }

  updateDoctorOnlineStatus(online: boolean, isLogout = false) {
    const user = this.store.getUser();
    const doctorId = user.context['cms-user-id'];
    const doctor = this.akitaClinicDoctorQuery.getEntity(
      user.context['cms-user-id']
    ) as Doctor;
    const clinicId = this.store.getClinicId();

    this.apiPatientInfoService.updateDoctorOnlineStatus(
      doctorId,
      online,
      clinicId
    ).subscribe(
      payload => {
        if (payload.statusCode === 'S0000' && !isLogout) {
          this.clinicDoctorStore.update(doctorId, {
            ...doctor,
            doctorClinicToggles: {
              online,
              [clinicId]: online
            }
          }
          );
          this.isDoctorOnline = online;
        }
      });
  }


  isDoctorOnlineOfflineFeature() {
    return this.akitaAppQuery.checkClinicFeatureExist('DOCTOR_ONLINE_STATUS');
  }

  getOnlineOfflineClass(): string {
    const isDoctor = this.permissionsService.getPermission('ROLE_DOCTOR');
    if (isDoctor) {
      return !!this.isDoctorOnline ? 'border-online' : 'border-offline'
    }
    return '';
  }

  onClickAccept(notification) {
    this.apiPatientInfoService.notificationAccept(notification.id).subscribe(res => {
      if (res.statusCode === "S0000") {
        this.alertService.success(res.message);
      } else {
        this.alertService.error(res.message);
      }
    });
  }

  onClickDecline(notification) {
    this.apiPatientInfoService.notificationDecline(notification.id).subscribe(res => {
      if (res.statusCode === "S0000") {
        this.alertService.success(res.message);
      } else {
        this.alertService.error(res.message);
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.isSidebarOpenSub.unsubscribe();
  }
}
