import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NoteTemplate } from '../../objects/state/NoteTemplate';
import { AkitaNoteTemplateStore } from './akita-note-template.store';
import {StoreService} from "../store.service";

@Injectable({ providedIn: 'root' })
export class AkitaNoteTemplateQuery extends QueryEntity<NoteTemplate> {
  noteTemplateList$ = this.selectAll({
    filterBy: (entity: NoteTemplate) =>
      entity.templateCategory === 'CONSULTATION' && (entity.disabledUserIds === undefined || !entity.disabledUserIds.includes(this.storeService.getDoctorId())) ,
  });

  drugTemplateList$ = this.selectAll({
    filterBy: (entity: NoteTemplate) => entity.templateCategory === 'DRUG',
  });

  memoTemplateList$ = this.selectAll({
    filterBy: (entity: NoteTemplate) => entity.templateCategory === 'MEMO' && (entity.disabledUserIds === undefined || !entity.disabledUserIds.includes(this.storeService.getDoctorId()))
  })

  constructor(protected store: AkitaNoteTemplateStore, private storeService: StoreService ) {
    super(store);
  }
}
