import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';
import { LoginComponent } from '../views/pages/login.component';
import { InventoryTab } from './inventory.service';
import { Injectable } from "@angular/core";

@Injectable()
export class CustomRouteReuseStragy implements RouteReuseStrategy {
  private storedRouteHandles = new Map<string, DetachedRouteHandle>();

  private reusingRoute: Array<string> = [
    'pages/patient/search',
    `pages/inventory/main/${InventoryTab.STOCK_LIST}`,
  ];

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.component == LoginComponent) {
      this.storedRouteHandles = new Map<string, DetachedRouteHandle>();
      return false;
    }

    const path = this.getFullPath(route);
    return (
      this.reusingRoute.includes(path) && this.storedRouteHandles.has(path)
    );
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const path = this.getFullPath(route);
    return this.storedRouteHandles.get(path) || null;
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.reusingRoute.includes(this.getFullPath(route));
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const path = this.getFullPath(route);
    this.storedRouteHandles.set(path, handle);
  }

  private getFullPath(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(v =>
        v.url
          .filter(segment => segment.path)
          .map(segment => segment.path)
          .join('/')
      )
      .filter(path => path)
      .join('/');
  }
}
