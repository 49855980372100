import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Subject, Observable, concat, of } from 'rxjs';
import { debounceTime, takeUntil, distinctUntilChanged, filter, tap, switchMap, map, catchError } from 'rxjs/operators';

import { ApiCmsManagementService } from '../../../../../services/api-cms-management.service';
import { MedicalCoverageFormService } from '../../../../../services/medical-coverage-form.service';
import { PrintTemplateService } from '../../../../../services/print-template.service';
import { StoreService } from '../../../../../services/store.service';
import { validateOrder, notBeforeToday, validateMC, planIsInActive } from '../../medical-coverage.service';

import { Plan, createPlan } from '../../../../../objects/Plan';
import { MedicalCoverage } from '../../../../../objects/MedicalCoverage';
import DatePickerConfig from '../../../../../objects/DatePickerConfig';

import { DISPLAY_DATE_FORMAT, INPUT_DELAY } from '../../../../../constants/app.constants';

import * as moment from 'moment'

@Component({
  selector: 'app-check-coverage-item',
  templateUrl: './check-coverage-item.component.html',
  styleUrls: ['./check-coverage-item.component.scss']
})
export class CheckCoverageItemComponent implements OnInit {

  @Input() patientCoverageItem: FormGroup;
  @Input() selectedItems;

  isCollapsed: boolean;
  loading = false;
  codesTypeahead = new Subject<string>();
  datePickerConfigArray: Array<DatePickerConfig>;
  minDate: Date;

  planSelect = new Array<Plan>();
  medicalCoverageSelect = new Array<MedicalCoverage>();

  today = moment().format(DISPLAY_DATE_FORMAT);

  coverageSelected: AbstractControl;
  planSelected: AbstractControl;

  medicalCoverageId: AbstractControl;
  patientCoverageId: AbstractControl;
  planId: AbstractControl;
  coverageInactive: AbstractControl;
  planInactive: AbstractControl;
  coverageType: AbstractControl;
  isSelected: AbstractControl;
  isNew: AbstractControl;
  startDate: AbstractControl;
  endDate: AbstractControl;
  remarks: AbstractControl;
  costCenter: AbstractControl;
  status: AbstractControl;
  policyInactive: AbstractControl;
  isDuplicate: AbstractControl;
  corpIdentificationNumber: AbstractControl;
  name: AbstractControl;

  policyHolderExpired: AbstractControl;
  policyExpired: AbstractControl;
  policyExcluded: AbstractControl;

  medicalCoverages$: Observable<MedicalCoverage[]>;
  medicalCoverageInput$ = new Subject<string>();

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private apiCmsManagementService: ApiCmsManagementService,
    private fb: FormBuilder,
    private medCovService: MedicalCoverageFormService,
    private printTemplateService: PrintTemplateService,
    private store: StoreService
  ) {}

  ngOnInit() {
    this.initialiseValues();
    this.subscribeOnChanges();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  initialiseValues() {
    this.isCollapsed = true;
    this.minDate = new Date();

    const get = (key: string) => this.patientCoverageItem.get(key);

    this.coverageSelected = get('coverageSelected');
    this.coverageInactive = get('coverageInactive');
    this.planSelected = get('planSelected');
    this.medicalCoverageId = get('medicalCoverageId');
    this.patientCoverageId = get('patientCoverageId');
    this.planInactive = get('planInactive');
    this.planId = get('planId');
    this.coverageType = get('coverageType');
    this.isSelected = get('isSelected');
    this.isNew = get('isNew');
    this.startDate = get('startDate');
    this.endDate = get('endDate');
    this.remarks = get('remarks');
    this.costCenter = get('costCenter');
    this.status = get('status');
    this.policyHolderExpired = get('policyHolderExpired');
    this.policyExpired = get('policyExpired');
    this.policyInactive = get('policyInactive');
    this.policyExcluded = get('policyExcluded');
    this.isDuplicate = get('isDuplicate');
    this.corpIdentificationNumber = get('corpIdentificationNumber');
    this.name = get('name');

    if (!this.isNew.value) {
      this.endDate.markAsTouched();
      this.patientCoverageItem.updateValueAndValidity();
      this.checkExpiry();
    } else {
      this.populateInputFields();
    }

    this.initDatePickerConfigsForComponent();

    this.startDate.setValidators([
      Validators.required,
      validateOrder(this.startDate, this.endDate, true),
    ]);

    this.endDate.setValidators([
      notBeforeToday(this.endDate),
      validateMC(this.coverageSelected, this.endDate, true),
      validateOrder(this.startDate, this.endDate, false),
      Validators.required,
    ]);

    this.coverageSelected.setValidators([
      planIsInActive(this.coverageSelected.value.status),
    ]);

    this.loadMedicalCoverages();
  }

  checkExpiry() {
    const isHolderExpired = !moment(
      this.today,
      DISPLAY_DATE_FORMAT
    ).isSameOrBefore(moment(this.endDate.value, DISPLAY_DATE_FORMAT));

    this.policyHolderExpired.patchValue(isHolderExpired);
  }

  subscribeOnChanges() {
    this.startDate.valueChanges
      .pipe(
        debounceTime(50),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        this.startDate.patchValue(moment(value).format(DISPLAY_DATE_FORMAT), {
          emitEvent: false,
        });
      });

    this.endDate.valueChanges
      .pipe(
        debounceTime(50),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        this.endDate.patchValue(moment(value).format(DISPLAY_DATE_FORMAT), {
          emitEvent: false,
        });
        this.checkExpiry();
      });
  }

  populateInputFields() {
    if (
      this.patientCoverageItem &&
      this.medicalCoverageId.value &&
      this.planId.value
    ) {
      this.medicalCoverageSelect.push(this.coverageSelected.value);
      this.populatePlan(this.coverageSelected.value);
    }
  }

  populatePlan(coverage: MedicalCoverage) {
    this.planSelect = coverage.coveragePlans;
    this.patientCoverageItem.updateValueAndValidity();
  }

  initDatePickerConfigsForComponent() {
    this.datePickerConfigArray = new Array<DatePickerConfig>();

    const datepickerStart: DatePickerConfig = this.initDatePickerConfigObject(
      '',
      null,
      this.minDate,
      'bottom',
      'none'
    );
    const datepickerEnd: DatePickerConfig = this.initDatePickerConfigObject(
      '',
      null,
      this.minDate,
      'bottom',
      'none'
    );

    this.datePickerConfigArray['start'] = datepickerStart;
    this.datePickerConfigArray['end'] = datepickerEnd;
  }

  initDatePickerConfigObject(
    label,
    maxDate,
    minDate,
    datePickerPlacement,
    labelPlacement
  ) {
    return new DatePickerConfig(
      label,
      maxDate,
      minDate,
      datePickerPlacement,
      labelPlacement
    );
  }

  onCoverageSelected(event: MedicalCoverage) {
    if (event) {
      this.populatePlan(event);

      this.startDate.patchValue(new Date());
      if(event.type === 'MEDISAVE'){
        this.endDate.patchValue("", { emitEvent: false});
      } else{
        this.endDate.patchValue(
          moment(event.endDate, DISPLAY_DATE_FORMAT).toDate() || ''
        );
      }
      this.coverageType.patchValue(event.type);
      this.coverageSelected.patchValue(event);
      this.planSelected.patchValue(createPlan());
      this.planId.patchValue('');
      this.remarks.patchValue('');
      this.costCenter.patchValue('');

      this.coverageInactive.patchValue(
        this.medCovService.checkCoverageInactive(event.status)
      );
      this.policyExpired.patchValue(
        this.medCovService.checkPolicyExpired(event.endDate)
      );
    } else {
      this.isCollapsed = true;
    }
  }

  onPlanSelected(event: Plan) {
    if (event) {
      this.isCollapsed = false;
      this.planSelected.patchValue(event);

      this.planInactive.patchValue(this.medCovService.checkPlanInactive(event));

      this.policyExcluded.patchValue(
        this.medCovService.checkPolicyExcluded(event.id)
      );

      this.patientCoverageItem.updateValueAndValidity();
    }
  }

  onBtnDeleteClicked() {
    this.isCollapsed = !this.isCollapsed;
  }

  onBtnPrintClicked() {
    let medicalCoverageLabelList = this.printTemplateService.groupMedicalCoverageByCompanyName(
      this.fb.array([this.patientCoverageItem])
    );

    this.printTemplateService.onBtnPrintMedicalCoverageLabelClicked(
      medicalCoverageLabelList
    );
  }

  loadMedicalCoverages() {
    this.medicalCoverages$ = concat(
      of(this.store.getMedicalCoverages()), // default items
      this.medicalCoverageInput$.pipe(
        distinctUntilChanged(),
        debounceTime(INPUT_DELAY),
        filter(
          input =>
            input !== undefined && input !== null && input.trim().length !== 0
        ),
        tap(() => (this.loading = true)),
        switchMap(term =>
          this.apiCmsManagementService
            .searchCoverageByClinicObj(
              this.store.getClinicId(),
              encodeURI(term)
            )
            .pipe(
              map(payload => {
                const filteredPayload = payload.filter(data => {
                  return (
                    data['status'] === 'ACTIVE' &&
                    data['coveragePlans'].length > 0 &&
                    moment().isSameOrBefore(
                      moment(data['endDate'], DISPLAY_DATE_FORMAT)
                    )
                  );
                });
                return filteredPayload;
              }),
              catchError(() => of(this.store.getMedicalCoverages())), // empty list on error
              tap(data => {
                this.loading = false;
              })
            )
        )
      )
    );
  }

  onCheck(event, item){
    if(event.target.checked){
      this.selectedItems.push(item.value.id);
    } else{
      const index: number = this.selectedItems.indexOf(item.value.id);
      if (index !== -1) {
          this.selectedItems.splice(index, 1);
      }      
    }
  }
}