import { Injectable } from "@angular/core";

import { Observable,Observer, Subject } from 'rxjs';


@Injectable()
export class WebsocketService {
  constructor(
  ) {}
   sockets = [];
  private subject: Subject<MessageEvent>;
  private subjectChatStart= new Subject<any>();


  public connect(url): Subject<MessageEvent> {

    this.subject = this.create(url);
    return this.subject;
  }

  private create(url): Subject<MessageEvent> {
    let ws = new WebSocket(url);
    var socketObj = {
      "ws": ws,
      "url": url
    };
    this.sockets.push(socketObj);
    let observable = Observable.create((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    };
    return Subject.create(observer, observable);
  }
   closeAllSockets(urlInput:any) {
    this.sockets.forEach(function(s) {
      if(s.url==urlInput){
        s.ws.close();
      }
    
  });
  
  }

 
  sendChatStartEvent() {
    this.subjectChatStart.next();
  }
  geteExportChatStartEvent(): Observable<any> {

    return this.subjectChatStart.asObservable();
  };

}