import {
  FormGroup,
  ValidatorFn,
  AbstractControl,
  FormControl,
} from '@angular/forms';
// TODO: make sure it is working with FormGroup

/**
 *
 *
 * @export
 * @param {FormGroup} group
 * @returns {null|Object} Null if valid.
 */
export function RequiredFalse(parentFormGroup: FormGroup): ValidatorFn | any {
  return (control: AbstractControl) => {
    if (!parentFormGroup || !parentFormGroup.get('invalidUsage')) {
      return null;
    }
    const value = parentFormGroup.get('invalidUsage').value;
    return value === true ? { requiredFalse: value } : null;
  };
}
