import { StoreService } from './../../services/store.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AkitaStoreService } from '../../services/states/akita-store.service';

@Component({
  selector: 'app-loading-retry',
  templateUrl: './loading-retry.component.html',
  styleUrls: ['./loading-retry.component.scss'],
})
export class LoadingRetryComponent implements OnInit {
  @Output() resetApiCount: EventEmitter<any> = new EventEmitter<any>();

  constructor(private akitaStoreService: AkitaStoreService) {}

  ngOnInit() {}

  retry() {
    this.akitaStoreService.storeReset();
    this.resetApiCount.emit();

    this.akitaStoreService.init(false);
  }
}
