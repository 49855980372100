import { HttpResponseBody } from './../../../objects/response/HttpResponseBody';
import { ConsultationFormService } from './../../../services/consultation-form.service';
import { DispatchItemService } from './../../../services/dispatch-item.service';
import { ApiCmsManagementService } from './../../../services/api-cms-management.service';
import { AlertService } from './../../../services/alert.service';
import { ChargeItemDescription } from './../../../objects/ChargeItemDescription';
import { CaseChargeFormService } from './../../../services/case-charge-form.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConsultationSearchComponent } from './consultation-search/consultation-search.component';
import { BsModalService } from 'ngx-bootstrap';
import { DrugItem, DosageInstruction } from './../../../objects/DrugItem';
import { Subject, timer, of, Observable } from 'rxjs';
import { FormArray, FormControl, Validators, FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { TopDrugDescription } from '../../../objects/DrugDescription';
import { StoreService } from '../../../services/store.service';
import {
  takeUntil,
  filter,
  distinctUntilChanged,
  tap,
  debounceTime,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { AkitaChargeItemQuery } from '../../../services/states/akita-charge-item.query';
import * as moment from 'moment'
import {
  DB_FULL_DATE_FORMAT,
  DISPLAY_DATE_FORMAT,
  INVENTORY_DATE_FORMAT,
  INPUT_DELAY,
} from '../../../constants/app.constants';
import { AkitaCaseVisitQuery } from '../../../services/states/akita-case-visit.query';
import { ChargeItem } from '../../../objects/state/ChargeItem';
import { ApiCaseManagerService } from '../../../services/api-case-manager.service';
import {
  IPackageItem,
  IDispaches,
  PRICE_ADJUSTMENT_TYPE,
} from '../../shared/package/package.model';
import { AllergiesFormService } from '../../../services/allergies-form.service';
import { PrescriptionItemComponent } from './prescription-item/prescription-item.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ApiPatientInfoService } from '../../../services/api-patient-info.service';
import { AkitaPatientAppQuery } from '../../../services/states/akita-patient/akita-patient-app.query';

@Component({
  selector: 'app-consultation-prescription',
  templateUrl: './consultation-prescription.component.html',
  styleUrls: ['./consultation-prescription.component.scss'],
})
export class ConsultationPrescriptionComponent implements OnInit, OnDestroy {
  @Input() dispatchItemEntities: FormArray;
  @Input() index: number;
  @Input() chargesCollapsed: boolean;
  @Output() onFirstChargeItemDetailsAdded = new EventEmitter<FormArray>();
  @Output() toggleChargesCollapsedEvent = new EventEmitter<Boolean>();
  @Input() drugItemIndex
  @Input() checkedItemIndex
  drugSelected: DrugItem;
  bsModalRef: BsModalRef;
  searchKey: FormControl;

  topDrugDescription: TopDrugDescription;
  chargeItemDescription: ChargeItemDescription;
  codesTypeahead = new Subject<string>();
  dosageInstructions: Array<DosageInstruction>;
  dosageInstruction: FormControl;
  setIcdCode: string;
  drugTypeSelected: string;
  totalPrice;
  loading = false;
  // collapsed: boolean = false;
  drugs = [];

  chargeItemsByClinic = [];
  selected = [];

  private componentDestroyed: Subject<void> = new Subject();

  isPackagedCase: Observable<boolean>;

  vaccineDosages = [];

  @ViewChildren(PrescriptionItemComponent) prescriptionItemComponent: QueryList<
    PrescriptionItemComponent
  >;

  constructor(
    private store: StoreService,
    private modalService: BsModalService,
    private caseChargeFormService: CaseChargeFormService,
    private alertService: AlertService,
    private apiCmsManagementService: ApiCmsManagementService,
    private consultationFormService: ConsultationFormService,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private apiCaseManagerService: ApiCaseManagerService,
    private allergiesFormService: AllergiesFormService,
    private apiPatientInfoService: ApiPatientInfoService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private dispatchItemService: DispatchItemService
  ) {}

  ngOnInit() {
    this.initialiseUI();
    this.initialiseStore();
    this.updateOverallPrice(true);
    this.onFilterInputChanged();
    this.store.setIcdType.subscribe(icdCode => this.setIcdCode = icdCode);
    this.isPackagedCase = this.akitaCaseVisitQuery.selectIsPackageCase();
    this.store
      .getPatientVisitIdRefresh()
      .pipe(
        takeUntil(this.componentDestroyed),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe(id => {
        if (id) {
          this.initialiseUI();
          this.initialiseStore();
        }
      });

    this.dispatchItemEntities.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        const isPackageAvailable = this.akitaCaseVisitQuery.isPackageItemsAvailableForActiveVisit();
        if (value.length === 0) {
          this.resetChargeItemDescription();
        }

        if (value.length === 0 && !isPackageAvailable) {
          this.totalPrice = 0;
        }
      });

    this.subscribeAllergyChange();
    this.consultationFormService.showAllergyMessageForPackages();
    this.dispatchItemEntities.controls.forEach(formItem => { });
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();

    this.resetChargeItemDescription();
  }

  subscribeAllergyChange() {
    this.allergiesFormService.isAllergyUpdated$
      .pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.updateAllergy();
      });
  }

  updateAllergy() {
    let itemsForAllergyCheck = this.prescriptionItemComponent
      .filter(prescriptionItem =>
        this.caseChargeFormService.isDrugOrVaccine(
          prescriptionItem.itemSelected
        )
      )
      .map(prescriptionItem => prescriptionItem.itemSelected.id);


    const packageSubItems = this.consultationFormService.getUniqueInventoriedSubItems();

    itemsForAllergyCheck = itemsForAllergyCheck.concat(packageSubItems);

    this.apiPatientInfoService
      .checkAllergies(this.akitaPatientAppQuery.getId(), itemsForAllergyCheck)
      .pipe(debounceTime(INPUT_DELAY))
      .subscribe(
        res => {
          if (res.payload && res.payload.allergies) {
            this.consultationFormService.updatePackageAllergy(
              res.payload.allergies,
              packageSubItems
            );

            const allergySet = new Set(res.payload.allergies);
            const msg = 'This patient is allergic to this medicine.';

            // updating prescription item on allergy update
            this.prescriptionItemComponent.forEach(prescriptionItem => {
              if (allergySet.has(prescriptionItem.itemSelected.id)) {
                if (!prescriptionItem.errors.includes(msg)) {
                  prescriptionItem.errors.push(msg);
                }

                this.consultationFormService.showAllergyWarningMessage(
                  prescriptionItem.topChargeItemDescription.charge
                );
              } else {
                const index = prescriptionItem.errors.indexOf(msg);
                prescriptionItem.errors.splice(index, 1);
              }
            });
          }

          if (res.payload && res.payload.adreactions) {
            const drugReactions: Array<any> = res.payload.adreactions;
            const msgAdr = 'This patient has drug reaction to this medicine.';
            // assume only checking for 1 drug
            this.prescriptionItemComponent.forEach(prescriptionItem => {
              const drugItem = drugReactions.find(item => item.drugId === prescriptionItem.itemSelected.id);
              if (drugItem) {
                if (!prescriptionItem.errors.includes(msgAdr)) {
                  prescriptionItem.errors.push(msgAdr);
                }

                const custmMsg =prescriptionItem.topChargeItemDescription.charge + '('+  drugReactions[0].allergies.toString() + ')';
                this.consultationFormService.showAllergyWarningMessage(
                  undefined, custmMsg
                );
              } else {
                const index = prescriptionItem.errors.indexOf(msgAdr);
                prescriptionItem.errors.splice(index, 1);
              }
            });
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error['message']));
        }
      );
  }

  initialiseStore() {
    // this.chargeItemsByClinic = this.akitaClinicChargeItemQuery.getActiveChargeItemList();
    this.chargeItemsByClinic = [];
  }

  initialiseUI() {
    this.searchKey = new FormControl();

    this.resetChargeItemDescription();

    if (!this.totalPrice) {
      this.totalPrice = 0;
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const medicalAlertsDiv = document.getElementById('medicalAlerts');
    const originalHeight = medicalAlertsDiv.offsetHeight;

    this.adjustHeightUponToggle(originalHeight);

    const allergiesDiv = document.getElementById('allergies');
    const originalAllergyHeight = allergiesDiv.offsetHeight;

    this.adjustHeightUponToggle(originalAllergyHeight);

    const indicationCardDiv = document.getElementById('cardIndication');
    const originalIndicationCardHeight = indicationCardDiv.offsetHeight;
    this.adjustHeightUponToggle(originalIndicationCardHeight);
  }

  extractNumber(string: String) {
    return string.split('px')[0] || '';
  }

  addBottomPaddingTo(offsetHeight, element: HTMLElement) {
    let paddingBottom = Number(this.extractNumber(element.style.paddingBottom));
    let newPaddingBottom = 0;

    if (offsetHeight !== 0) {
      newPaddingBottom = paddingBottom + offsetHeight;
    }

    element.style.setProperty(
      'padding-bottom',
      newPaddingBottom + 'px',
      'important'
    );
  }

  adjustHeightUponToggle(offsetHeight) {
    const chargeItemListPane = document.getElementById('chargeItemsList');
    // Add to Charge Item List
    if (chargeItemListPane) {
      this.addBottomPaddingTo(offsetHeight, <HTMLElement>chargeItemListPane);
    }
  }

  onTopChargeItemDescriptionChanged(event: ChargeItemDescription) {
    this.chargeItemDescription = event;

  }

  getChargeItemClass() {
    return this.akitaCaseVisitQuery.getPackages().length > 0
      ? 'charge-item-with-package'
      : 'charge-item';
  }

  updateOverallPrice(event) {
    if (event) {
      this.totalPrice = 0;
      this.dispatchItemEntities.getRawValue().forEach(item => {
        const price = parseFloat(item.calculatedTotalPrice);
        this.totalPrice += price;
      });

    }
  }

  toggleCharges() {
    this.chargesCollapsed = !this.chargesCollapsed;

    this.toggleChargesCollapsedEvent.emit(this.chargesCollapsed);
  }

  onSubItemPriceAdjustment(value: any) {
    const dispatchPackageItem = this.consultationFormService.findPackageItemInDispatchedEntities(
      this.dispatchItemEntities,
      value.packageId
    ) as FormGroup;
    const priceInDoller = (value.totalPrice / 100).toFixed(2);
    dispatchPackageItem.get('adjustedTotalPrice').patchValue(priceInDoller);
  }

  onDelete(indexes: any) {
    const itemToBeDeleted = this.caseChargeFormService.chargeItemDetails.at(
      indexes.caseChargeIndex
    );
    this.drugItemIndex = this.drugItemIndex.filter(item => item.id !== itemToBeDeleted.get('drugId').value);
    if (itemToBeDeleted.get('itemType').value === 'DRUG') {
      if (this.checkedItemIndex.length !== 0 && !(this.checkedItemIndex.find(element => element === 'ACUTE') && this.checkedItemIndex.find(element => element === 'CHRONIC'))) {
        for (var item of this.drugItemIndex) {
          // if (!(this.checkedItemIndex === undefined || this.checkedItemIndex.length === undefined) && !this.checkedItemIndex.has(item.drugType.toUpperCase())){
          if (!this.checkedItemIndex.find(element => element === item.drugType.toUpperCase())) {
            this.consultationFormService.showIcdTypeWarningMessage();
          }
        }
      }
    }
    this.caseChargeFormService.chargeItemDetails.removeAt(
      indexes.caseChargeIndex
    );
    this.dispatchItemService.deleteDrugSubscriptionFromBatch(
      itemToBeDeleted.get('drugId').value,
      itemToBeDeleted.get('batchNumber').value,
      itemToBeDeleted.get('drugDispenseShortId').value
    );
    this.dispatchItemEntities.markAsDirty();
    this.resetChargeItemDescription();
    this.updateOverallPrice(true);
  }

  promptDelete() {
    return confirm('Delete this item?');
  }

  resetChargeItemDescription() {
    this.chargeItemDescription = new ChargeItemDescription();
  }

  onDrugSelect(selectedOption) {
    let option = { ...selectedOption };
    if (option.itemType === 'VACCINATION'){
      this.vaccineDosages = option.dosages;
    }
    if (option.itemType === 'DRUG') {
      this.drugItemIndex.push(option);
      if (this.checkedItemIndex.length !== 0 && !(this.checkedItemIndex.find(element => element === 'ACUTE') && this.checkedItemIndex.find(element => element === 'CHRONIC'))) {
        if (!this.checkedItemIndex.find(element => element === option.drugType?.toUpperCase())) {
          this.consultationFormService.showIcdTypeWarningMessage();
        }
      }
    }
    this.drugSelected = option;
    this.drugTypeSelected = option.drugType;
    this.store.setSelectedDrugCode(this.drugTypeSelected);
    let mappedPackageItems: IPackageItem[] = [];
    if (option.itemType.toLowerCase() === 'package') {
      mappedPackageItems = this.consultationFormService.mapPackageForFirstVisitItems(
        [option]
      );
      option.packageId = mappedPackageItems[0].packageId;
      this.consultationFormService.checkPackageItemInventory(
        mappedPackageItems[0]
      );
    }

    this.dispatchItemEntities = this.caseChargeFormService.buildDrugDispatchDetails(
      option,
      true
    );
    // Remove remark
    this.dispatchItemEntities.controls[0].get('remark').patchValue("");

    this.dispatchItemEntities.markAsDirty();
    this.caseChargeFormService.chargeItemDetails.markAsDirty();

    if (option.itemType.toLowerCase() === 'package') {
      const formGroupForPackage = this.consultationFormService.findPackageItemInDispatchedEntities(
        this.dispatchItemEntities,
        option.packageId
      );
      if (!!formGroupForPackage) {
        this.changeDispatchItemPropertiesForPackage(
          formGroupForPackage as FormGroup,
          mappedPackageItems[0]
        );
      }
    }

    this.searchKey.reset();
    this.searchKey.patchValue(null);
    this.markAsDirty();
  }

  changeDispatchItemPropertiesForPackage(
    formGroupForPackage: FormGroup,
    mappedPackageItem: IPackageItem
  ) {
    formGroupForPackage.get('purchaseQty').disable();
    formGroupForPackage.get('packageId').setValue(mappedPackageItem.packageId);
    if (
      mappedPackageItem.priceAdjustmentType !== PRICE_ADJUSTMENT_TYPE.PACKAGE
    ) {
      formGroupForPackage.get('adjustedTotalPrice').disable();
    }
  }

  onPackageClicked(packageItem: IPackageItem) {
    this.resetChargeItemDescription();
    this.chargeItemDescription.charge = packageItem.name;
    this.chargeItemDescription.qty = '' + 1;
  }

  onPackageDelete(packageId: string) {
    const packageDispatchItem = this.consultationFormService.findPackageItemInDispatchedEntities(
      this.dispatchItemEntities,
      packageId
    ) as FormGroup;
    const index = this.dispatchItemEntities.controls.findIndex(
      fg => fg.get('packageId').value === packageId
    );
    if (!!packageDispatchItem) {
      const indexes = {
        caseChargeIndex: index,
        dispenseQtyIndex: packageDispatchItem.get('drugDispenseShortId'),
      };
      this.onDelete(indexes);
      this.onPrecriptionItemQtyChange(packageDispatchItem.get('drugId').value);
    }
  }

  onPrecriptionItemQtyChange(subItemCode: string) {
    //TODO in progress
    const packageItems = this.akitaCaseVisitQuery.getPackageBySubItemCode(
      subItemCode
    );
    packageItems.forEach(packageItem => {
      packageItem.dispatches
        .filter(
          subItem =>
            subItem.inventoried &&
            subItem.itemCode === subItemCode &&
            subItem.utilize
        )
        .forEach(subItem => {
          const isAvailable =
            this.dispatchItemService.getRemainingQty(subItem.itemId) > -1;

          const dispatchItem = {
            ...subItem,
            isAvailable,
          };

          this.akitaCaseVisitQuery.updateSubItemForActiveVisit(
            packageItem.packageId,
            dispatchItem
          );
        });
    });
  }

  markAsDirty() {
    this.dispatchItemEntities.controls.forEach((control, index) => {
      if (index === 0) {
      }
      control.markAsDirty({ onlySelf: false });
      control.updateValueAndValidity();
    });
  }

  onSearchClicked() {
    if (!this.inPaymentMode()) {
      const initialState = {
        title: 'Advanced Search',
        itemsFormArray: this.dispatchItemEntities,
      };

      this.bsModalRef = this.modalService.show(ConsultationSearchComponent, {
        initialState,
        class: 'modal-lg',
        keyboard: false,
      });

      this.bsModalRef.content.event.subscribe(data => {
        if (data !== 'close' && data.length > 0) {
          data.forEach(item => {
            this.onDrugSelect(item);
          });
        }
        this.bsModalRef.content.event.unsubscribe();
        this.bsModalRef.hide();
      });
    }
  }

  onFilterInputChanged() {
    try {
      this.codesTypeahead
        .pipe(
          filter(input => {
            if (
              input === undefined ||
              input === null ||
              input.trim().length < 3
            ) {
              this.chargeItemsByClinic = [];
              return false;
            } else {
              return true;
            }
          }),

          tap(() => (this.loading = true)),
          debounceTime(200),
          switchMap((term: string) => {
            const planIds = this.akitaCaseVisitQuery.getCaseCoveragePlanIds();
            return this.apiCmsManagementService
              .searchListItemByKeyWordInventoryAndClinicId(
                this.store.getClinicId(),
                encodeURI(term),
                planIds
              )
              .pipe(
                catchError(error =>
                  of(<HttpResponseBody>{ page: {}, payload: '' })
                )
              );
          }),
          catchError(errors => of(<HttpResponseBody>{ page: {}, payload: '' }))
        )
        .subscribe(
          data => {
            this.loading = false;
            if (data) {
              this.chargeItemsByClinic = data.payload;
            }
          },
          err => {
            this.loading = false;
            this.alertService.error(JSON.stringify(err.error.message));
          }
        );
    } catch (err) {
      console.error('Search Diagnosis Error', err);
    }
  }

  inPaymentMode() {
    if (this.store.getVisitStatus() === 'PAYMENT') {
      return true;
    } else {
      return false;
    }
  }
}
