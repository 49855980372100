import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
  name: 'inventoryDoReceiverFax'
})
export class InventoryDoReceiverFaxPipe implements PipeTransform {

  constructor(private store: StoreService) {}

  transform(value: any, args?: any): any {
    let isFromReturnRequest = args

    if (!value) {
      return '';
    }

    if (isFromReturnRequest) {
      const supplier = this.store.getSupplierById(value);
      if (supplier && supplier.contacts && supplier.contacts.length) {
        return supplier.contacts[0].faxNumber;
      }
    } else {
      return (
        this.store.getClinicList().find(clinic => clinic.id === value) || { faxNumber: '' }
      ).faxNumber;
    }

    return '';
  }

}
