import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaInstructionStore } from './akita-instruction.store';
import {
  createInstruction,
  Instruction,
} from '../../objects/state/Instruction';

@Injectable({ providedIn: 'root' })
export class AkitaInstructionQuery extends QueryEntity<Instruction> {

  activeInstructionList$ = this.selectAll({
    filterBy: (entity: Instruction) => !entity.status || entity.status === 'ACTIVE'
  });
  instructionList$ = this.selectAll();

  constructor(protected store: AkitaInstructionStore) {
    super(store);
  }

  getInstruction(code): Instruction {
    if (this.getAll().length > 0) {
      const instruction = <Instruction>this.getAll().find(
        (entity: Instruction) => entity.code === code
      );
      if (instruction) return instruction;
    }
    return createInstruction();
  }

  getActiveInstruction(): Array<Instruction> {
    if (this.getAll() && this.getAll().length > 0) {
      return (<Array<Instruction>> this.getAll()).filter(entity => !entity.status || entity.status === 'ACTIVE');
    }

    return [];
  }

  getAllInstruction(): Array<Instruction> {
    if (this.getAll() && this.getAll().length > 0) {
      return (<Array<Instruction>> this.getAll());
    }

    return [];
  }
}
