<div style="height: 48px;" class="row align-items-center px-3">
  <div class="col-md-3">
    <button class="btn btn-x-small bg-pale-grey-three border px-1" (click)="navigateToStockTakeList()"><i
        class="icon-left-open-big mr-2"></i> Back to Inventory Count</button>
  </div>
</div>
<alert></alert>
<div class="container-fluid inventory-count-detail-content">
  <div class="row">
    <div class="col-md">
      <div class="card px-2 pb-4">
        <div class="row p-2">
          <div class="col-md-2">
            <label>Stock Take ID</label>
            <input class="form-control form-control-sm" type="text" [ngModel]="stockTake.transactionNo" disabled>
          </div>
          <div class="col-md-2">
            <label>Count Name</label>
            <input class="form-control form-control-sm" type="text" [ngModel]="stockTake.countName" disabled>
          </div>
          <div class="col-md-1">
            <label>Start Date</label>
            <input class="form-control form-control-sm" type="text" [ngModel]="stockTake.startDate"
              disabled>
          </div>
          <div class="col-md-1">
            <label>Start Time</label>
            <input class="form-control form-control-sm" type="text" [ngModel]="getDisplayTime(stockTake.startTime)"
              disabled>
          </div>
          <ng-container *ngIf="stockTake.stockTakeStatus == StockTakeStatus.COMPLETED">
            <div class="col-md-1">
              <label>End Date</label>
              <input class="form-control form-control-sm" type="text"
                [ngModel]="getDisplayDateFromat(stockTake.endDate)" disabled>
            </div>
            <div class="col-md-1">
              <label>End Time</label>
              <input class="form-control form-control-sm" type="text" [ngModel]="getDisplayTime(stockTake.endTime)"
                disabled>
            </div>
          </ng-container>
          <div *ngIf="stockTake.stockTakeStatus === StockTakeStatus.COMPLETED"
            class="col-md-1 offset-md-3 d-flex justify-content-end">
            <label></label>
            <span [class]="getApproveStatusClass(stockTake.approveStatus)">
              {{ getApproveStatus(stockTake.approveStatus) }}
            </span>
          </div>
        </div>

        <div class="row pt-2 px-2 pb-0">
          <div class="col-md-3">
            <div class="row justify-content-center">
              <div class="col-md-3 d-flex">
                <div class="w-100 text-center cursor-pointer font-weight-semi-bold" (click)="onItemFilterClicked('All')"
                  [ngClass]="{'text-cerulean border-bottom border-cerulean': stockTakeListAll(), 'text-light-grey-blue': !stockTakeListAll()}">
                  All
                </div>
              </div>
              <div class="col-md-4 d-flex">
                <div class="w-100 text-center cursor-pointer font-weight-semi-bold"
                  (click)="onItemFilterClicked('Counted')"
                  [ngClass]="{'text-cerulean border-bottom border-cerulean': stockTakeListCounted(), 'text-light-grey-blue': !stockTakeListCounted()}">
                  Counted ({{ getCountedItem().length }})
                </div>
              </div>
              <div class="col-md-5 d-flex">
                <div class="w-100 text-center cursor-pointer font-weight-semi-bold"
                  (click)="onItemFilterClicked('Uncounted')"
                  [ngClass]="{'text-cerulean border-bottom border-cerulean': stockTakeListUncounted(), 'text-light-grey-blue': !stockTakeListUncounted()}">
                  Uncounted ({{ getUncountedItem().length }})
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-2 offset-md-2">
            <div class="row">
              <div class="col-md-5">Item Group</div>
              <div class="col-md-7">
                <ng-select class="ml-1 form-control form-control-sm custom-select custom-select-extra-small-width px-1"
                  [items]="itemGroupOptions" bindLabel="name" bindValue="index" [(ngModel)]="itemGroupSelectedOptions"
                  (ngModelChange)="onItemTypeFilterSelected($event)" [clearable]="false" [virtualScroll]="true">
                </ng-select>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <input class="form-control form-control-sm" type="text" placeholder="Search Item Code / Item Name"
              [formControl]="searchItemFormControl">
          </div>

          <div class="col-md-2">
            <button *ngIf="isInFirstStockTake()" class="btn btn-medium btn-brand-primary" (click)="printStockTakeList()"
              [disabled]="printDisabled()">Print</button>
          </div>
        </div>

        <hr class="m-0">


        <div *ngIf="isInFirstStockTake()" class="row p-2">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap inventory custom-datatable mt-3" [columnMode]="'flex'" [headerHeight]="40"
              [footerHeight]="50" [rowHeight]="55" [limit]="stockCountItemPage.size"
              [offset]="stockCountItemPage.pageNumber" (page)="onTableChange($event)"
              [rows]="filteredStockCountItemFA?.controls">
              <ngx-datatable-column name="Item Code" prop="value.itemCode" [resizeable]=false [flexGrow]=2>
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                  <ng-container *ngIf="!row.value.draft">{{ row.value.itemCode }}</ng-container>
                  <ng-select *ngIf="row.value.draft"
                    class="form-control form-control-sm custom-select custom-select-extra-width" appendTo="body"
                    [items]="selectableStockCountItemList" bindLabel="code" bindValue="code" [clearable]="true"
                    (change)="onSelectItem($event, row)" [formControl]="row.get('itemCode')">
                    <ng-template ng-header-tmp>
                      <div class="row font-weight-semi-bold">
                        <div class="col-md-3">Code</div>
                        <div class="col-md-9">Name</div>
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <div class="row">
                        <div class="col-md-3">{{ item.code }}</div>
                        <div class="col-md-9">{{ item.name }}</div>
                      </div>
                    </ng-template>
                  </ng-select>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Item Name" prop="value.itemName" [resizeable]=false [flexGrow]=3>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.itemName }}
                </ng-template>
              </ngx-datatable-column>

                            <ngx-datatable-column name="Batch #" prop="value.batchNumber" [resizeable]=false [flexGrow]=1.5>
                                <ng-template ngx-datatable-cell-template let-row="row">
                                  <ng-container *ngIf="row.value.batchNumberDisabled">
                                    {{ row.value.batchNumber || '-' }}
                                  </ng-container>
                                  <ng-container *ngIf="!row.value.batchNumberDisabled">
                                    <input 
                                      class="form-control" 
                                      type="text" 
                                      maxlength="20"
                                      [formControl]="row.get('batchNumber')">
                                  </ng-container>
                                </ng-template>
                            </ngx-datatable-column>

              <ngx-datatable-column name="Expiry Date" prop="value.expiryDate" [resizeable]=false [flexGrow]=1.5>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ng-container *ngIf="row.value.expiryDateDisabled">
                    {{ getDisplayDate(row.value.expiryDate, row.value.drugOrVaccine) }}
                  </ng-container>
                  <ng-container *ngIf="!row.value.expiryDateDisabled">
                    <app-date-picker-with-icon 
                      [config]="getDatePickerConfig(row)"
                      [dateControl]="row.get('expiryDate')">
                    </app-date-picker-with-icon>
                    <errors [control]="row.get('expiryDate')"></errors>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="UOM" prop="baseUom" [sortable]=false [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.baseUom }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Purchase Price" prop="purchasePrice" [sortable]=false [resizeable]=false [flexGrow]=2>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.purchasePrice | displayTwoDecimals }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Counted Quantity" prop="firstQuantity" [sortable]=false [resizeable]=false
                [flexGrow]=3>
                <ng-template ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                  <div class="row">
                    <div class="col-md-10">
                      <input class="form-control" type="number" [formControl]="row.get('firstQuantity')"
                        (mousewheel)="onScroll($event)">
                    </div>
                    <div class="col-md-2 d-flex align-items-center" *ngIf="row.get('draft').value">
                      <i class="icon-trash" (click)="deleteLineItem(row, rowIndex)"></i>
                    </div>
                  </div>
                  <errors [control]="row.get('firstQuantity')"></errors>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>

        <div *ngIf="isInSecondStockTake()" class="row p-2">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap inventory custom-datatable mt-3" [columnMode]="'flex'" [headerHeight]="40"
              [footerHeight]="50" [limit]="stockCountItemPage.size" [rowHeight]="55"
              [offset]="stockCountItemPage.pageNumber" (page)="onTableChange($event)"
              [rows]="filteredStockCountItemFA?.controls">
              <ngx-datatable-column name="Item Code" prop="value.itemCode" [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.itemCode }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Item Name" prop="value.itemName" [resizeable]=false [flexGrow]=2.5>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.itemName }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Batch #" prop="value.batchNumber" [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.batchNumber }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Expiry Date" prop="value.expiryDate" [resizeable]=false [flexGrow]=1.5>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ getDisplayDate(row.value.expiryDate, row.value.drugOrVaccine) }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="UOM" [resizeable]=false [sortable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.baseUom }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="First Count" [resizeable]=false [sortable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <input class="form-control" type="number" [formControl]="row.get('firstQuantity')"
                    (mousewheel)="onScroll($event)">
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Variance" prop="value.variance" [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <span [ngClass]="{'text-brand-danger': row.value.variance < 0}">
                    {{ row.getRawValue() | inventoryDisplayStockTakeItemVariance }}
                  </span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Second Count" [resizeable]=false [sortable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <input class="form-control" type="number" [formControl]="row.get('adjustedQuantity')"
                    (mousewheel)="onScroll($event)">
                  <errors [control]="row.get('adjustedQuantity')"></errors>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Reason" [resizeable]=false [sortable]=false [flexGrow]=2>
                <ng-template ngx-datatable-header-template let-column="column">
                  <span>Reason<label class="required"></label></span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ng-select
                    class="p-0 form-control form-control-sm custom-select custom-select-extra-extra-medium-width inventory-count-reason"
                    appendTo="body" [items]="itemReasonList" bindLabel="instruct" bindValue="code" [clearable]="false"
                    [virtualScroll]="true" [formControl]="row.get('purposeOfAdjustmentCode')"></ng-select>
                  <errors [control]="row.get('purposeOfAdjustmentCode')"></errors>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>

        <div *ngIf="completedStockTake()" class="row p-2">
          <div class="col-md-12">
            <ngx-datatable class="bootstrap inventory custom-datatable mt-3" [columnMode]="'flex'" [headerHeight]="40"
              [footerHeight]="50" [rowHeight]="55" [limit]="stockCountItemPage.size"
              [offset]="stockCountItemPage.pageNumber" (page)="onTableChange($event)"
              [rows]="filteredStockCountItemFA?.controls">
              <ngx-datatable-column name="Item Code" prop="value.itemCode" [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.itemCode }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Item Name" prop="value.itemName" [resizeable]=false [flexGrow]=2>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.itemName }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Batch #" prop="value.batchNumber" [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.batchNumber }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Expiry Date" prop="value.expiryDate" [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ getDisplayDate(row.value.expiryDate, row.value.drugOrVaccine) }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="UOM" [sortable]=false [resizeable]=false [flexGrow]=0.5>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.value.baseUom }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="First Count" [sortable]=false [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <input class="form-control" type="number" [formControl]="row.get('firstQuantity')"
                    (mousewheel)="onScroll($event)">
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Second Count" [sortable]=false [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <input class="form-control" type="number" [formControl]="row.get('adjustedQuantity')"
                    (mousewheel)="onScroll($event)">
                  <errors [control]="row.get('adjustedQuantity')"></errors>
                </ng-template>
              </ngx-datatable-column>


              <ngx-datatable-column name="Second Variance" prop="value.variance" [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <span [ngClass]="{'text-brand-danger': row.value.variance < 0}">
                    {{ row.getRawValue() | inventoryDisplayStockAdjustmentVariance }}
                  </span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Reason" [sortable]=false [resizeable]=false [flexGrow]=1.5>
                <ng-template ngx-datatable-header-template let-column="column">
                  <span>Reason<label class="required"></label></span>
                </ng-template>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <ng-select
                    class="form-control form-control-sm custom-select custom-select-extra-extra-medium-width inventory-count-reason"
                    appendTo="body" [items]="itemReasonList" bindLabel="instruct" bindValue="code" [clearable]="false"
                    [virtualScroll]="true" [formControl]="row.get('purposeOfAdjustmentCode')"></ng-select>
                  <errors [control]="row.get('purposeOfAdjustmentCode')"></errors>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Status" prop="value.status" [resizeable]=false [flexGrow]=1>
                <ng-template ngx-datatable-cell-template let-row="row">
                  {{ row.getRawValue().status }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="CP Remarks" prop="value.cpRemarks" [resizeable]=false [flexGrow]=1.5>
                <ng-template ngx-datatable-cell-template let-row="row">
                  <input class="form-control" type="text" [formControl]="row.get('cpRemarks')" readonly>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>

        <div class="row pt-2">
          <div *ngIf="isInFirstStockTake()" class="col-md-1">
            <button type="button" class="btn btn-small btn-outline-brand-primary" (click)="addNewLineItem()">+ Add
              Another Line</button>
          </div>
          <div [ngClass]="{'col-md-8': isInFirstStockTake(), 'col-md-9': !isInFirstStockTake()}"></div>
          <div class="col-md-1">
            <button *ngIf="!completedStockTake()" type="button"
              class="btn btn-small text-white btn-brand-danger float-right" (click)="saveAndStopCount()">Save & Stop
              Count</button>
          </div>
          <div class="col-md-1 offset-md-1">
            <button *ngIf="!completedStockTake()" type="button" class="btn btn-small btn-brand-primary ml-4 float-right"
              (click)="submitStockTake()" [disabled]="disableStockCountSubmission()">Submit Stock Take</button>
          </div>
        </div>

        <div *ngIf="retakeStockId.length>0" class="row mt-3 mb-1 justify-content-end">
          <button type="button" class="btn btn-brand-primary" (click)="startRetakeCount()">
            <span *ngIf='!isRequesting'>Recount Stocktake</span>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf='isRequesting'></span>
          </button>
        </div>
      </div>
    </div>