import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { DosageInstruction } from '../../objects/state/DosageInstruction';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'dosageinstruction',
  idKey: 'code',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaDosageInstructionStore extends EntityStore<
  DosageInstruction
> {
  constructor() {
    super();
  }
}
