import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { StoreService } from '../../../../../../services/store.service';
import { AlertService } from '../../../../../../services/alert.service';
import { ApiCmsManagementService } from '../../../../../../services/api-cms-management.service';

import { PatientVisitHistoryQuery } from '../../../../../../services/states/akita-patient/patient-visit-history/patient-visit-history.query';
import { AkitaPatientAppQuery } from '../../../../../../services/states/akita-patient/akita-patient-app.query';
import { AkitaCaseVisitQuery } from '../../../../../../services/states/akita-case-visit.query';

import DatePickerConfig from '../../../../../../objects/DatePickerConfig';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT, INVENTORY_DATE_FORMAT } from '../../../../../../constants/app.constants';

@Component({
  selector: 'app-other-health-plan-fields',
  templateUrl: './other-health-plan-fields.component.html',
  styleUrls: ['./other-health-plan-fields.component.scss']
})
export class OtherHealthPlanFieldsComponent implements OnInit, OnChanges {

  @Input() existingData: any;
  @Input() carePlanFormGroup: FormGroup;

  get otherHealthGoalDetailsFormGroup(): FormGroup {
    return this.carePlanFormGroup.get('otherHealthGoalDetails') as FormGroup;
  }

  public templates: any;

  public datePickerConfig: DatePickerConfig;

  constructor(
    private store: StoreService,
    private alertService: AlertService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private apiCmsManagementService: ApiCmsManagementService,
    private patientVisitHistoryQuery: PatientVisitHistoryQuery,
  ) { }

  ngOnInit(): void {
    this.templates = this.store.getTemplatesByTemplateCategory('HSG_ROD');

    this.datePickerConfig = new DatePickerConfig(
      '',
      null,
      new Date(),
      '',
      ''
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.existingData) {
      const otherHealthGoalDetailsFormGroup = this.carePlanFormGroup.get('otherHealthGoalDetails');

      otherHealthGoalDetailsFormGroup.patchValue({
        thingsToStartToday: this.existingData.thingsToStartToday ? this.existingData.thingsToStartToday : '',
        nextCheckUpDate: moment(this.existingData.nextCheckUpDate, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT),
        recordOfDiscussion: this.existingData.recordOfDiscussion ? this.existingData.recordOfDiscussion : '',
        nextCheckUpAppointmentId: this.existingData.nextCheckUpAppointmentId ? this.existingData.nextCheckUpAppointmentId : '',
      });
    }
  }

  onTemplateChange(event) {
    const recordOfDiscussionFormControl = this.otherHealthGoalDetailsFormGroup.get('recordOfDiscussion');
    recordOfDiscussionFormControl.markAsDirty();

    if (event) {
      let visitId = '';
      if (this.akitaCaseVisitQuery.getActiveId())
        visitId = String(this.akitaCaseVisitQuery.getActiveId());
      else if (this.patientVisitHistoryQuery.getActiveId())
        visitId = String(this.patientVisitHistoryQuery.getActiveId());
      else visitId = this.store.getPatientVisitRegistryId();

      this.apiCmsManagementService
        .loadTemplateWithVisitId(
          event.type,
          event.id,
          this.store.getUser().context['cms-user-id'],
          this.akitaPatientAppQuery.getId(),
          visitId
        )
        .subscribe(
          res => {
            if (res.payload) {
              recordOfDiscussionFormControl.patchValue(res.payload);
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err));
            console.error(JSON.stringify(err));
          }
        );
    }
  }
}