<tr [formGroup]="form">
  <td>
    <div class="wrapper-form-control">
      <ng-select 
          class="custom-select-new custom-select-extra-medium-width" 
          [items]="reactionTypes" 
          [virtualScroll]="true"
          bindLabel="label" 
          bindValue="value" 
          placeholder="Select Type" 
          formControlName="type" 
          [clearable]=false 
          [readonly]="!form.value.isNewObject"
          (change)="selectReactionType(form)">
        </ng-select>
        <errors [control]="form.get('type')"></errors>
    </div>
  </td>
  <td>
    <div class="wrapper-form-control">
      <ng-select 
          class="custom-select-new  custom-select-extra-medium-width" 
          [items]="allergyTypes" 
          [virtualScroll]="false"
          bindLabel="label" 
          bindValue="value" 
          placeholder="Select Type" 
          formControlName="allergyType" 
          [clearable]=false 
          [readonly]="form.value.type === 'ADVERSE_DRUG_REACTION' || !isEditable"
          (change)="clearAllergyToField()">
        </ng-select>
        <errors [control]="form.get('allergyType')"></errors>
    </div>
  </td>
  <td *ngIf="form.value.type=='ALLERGY'" >
    <div class="wrapper-form-control" [ngSwitch]="form.value.allergyType">
      <ng-select 
        *ngSwitchCase="'NAME_CONTAINING'" 
        class="custom-select-new  custom-select-extra-medium-width" 
        [items]="getDrugList() | async" 
        placeholder="Type or search for drug name"
        formControlName="name" 
        [clearable]=false 
        notFoundMsg="Enter to add new name.." 
        [addTag]="true" 
        bindLabel="name" 
        bindValue="name" 
        [readonly]="!isEditable"
        [virtualScroll]="false">
      </ng-select>
      <ng-select 
        *ngSwitchCase="'NAME_STARTING_WITH'" 
        class="custom-select-new  custom-select-extra-medium-width" 
        [items]="getDrugList() | async" 
        placeholder="Type or search for drug name"
        formControlName="name" 
        [clearable]=false 
        notFoundMsg="Enter to add new name.." 
        [addTag]="true" 
        bindLabel="name" 
        bindValue="name" 
        [readonly]="!isEditable"
        [virtualScroll]="false">
      </ng-select>
      <ng-select 
        *ngSwitchCase="'ALLERGY_GROUP'" 
        class="custom-select-new  custom-select-extra-medium-width" 
        [items]="getAllergyGroup() | async" 
        placeholder="Type or search for drug group" 
        formControlName="name"
        [clearable]=false 
        notFoundMsg="Enter to add new name.." 
        bindLabel="description" 
        bindValue="groupCode" 
        [readonly]="!isEditable"
        [virtualScroll]="false">
      </ng-select>
      <input 
        *ngSwitchCase="'FOOD'" 
        class="input" 
        formControlName="name" 
        (readonly) = "!isEditable"
        value=""/>
      <input 
        *ngSwitchCase="'OTHER'" 
        class="form-control form-control-sm" 
        formControlName="name" 
        (readonly) = "!isEditable"
        value=""/>
      <ng-select 
        *ngSwitchDefault 
        class="custom-select-new  custom-select-extra-medium-width" 
        [items]="getDrugList() | async" 
        [virtualScroll]="true" 
        placeholder="Type or search for drug name"
        formControlName="name" 
        [clearable]=false 
        notFoundMsg="Enter to add new name.." 
        bindLabel="name"
        [readonly]="!isEditable"
        bindValue="name">
      </ng-select>
      <errors [control]="form.get('name')"></errors>
    </div>
  </td>
  <td *ngIf="!form.value.syncedFromCmis && form.value.type != 'ALLERGY' ">
    <div class="wrapper-form-control">
      <ng-select 
        name="drug-code"
        class="custom-select-new custom-select-almost-medium-width"
        [items]="drugGenericCompounds" 
        bindLabel="description" 
        bindValue="description" 
        [addTag]="false"
        [virtualScroll]="false" 
        [typeahead]="codesTypeahead" 
        [clearable]=false 
        formControlName="name"
        [readonly]="!isEditable"
        (change)="onDiagnosisSelected($event, form)">

        <ng-template ng-header-tmp>
          <div class="row">
            <div class="col-md-2 font-weight-semi-bold">Code</div>
            <div class="col-md-5 font-weight-semi-bold">Name</div>
          </div>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="term">
          <div class="row">
            <div class="col-md-2">{{item.code}}</div>
            <div class="col-md-5">{{item.description}}</div>
          </div>
        </ng-template>
      </ng-select>
      <errors [control]="form.get('name')"></errors>
    </div>
  </td>
  <td *ngIf="form.value.syncedFromCmis  && form.value.type != 'ALLERGY' ">
    <div class="wrapper-form-control">
      <input class="input" formControlName="name" readonly/>
    </div>
  </td>
  <td *ngIf="!form.value.syncedFromCmis">
    <div class="wrapper-form-control">
      <ng-select 
        name="drug-code"
        class="custom-select-new custom-select-almost-medium-width"
        [items]="drugGenericCompounds" 
        bindLabel="description" 
        bindValue="description" 
        [addTag]="false"
        [virtualScroll]="true" 
        [typeahead]="codesTypeahead" 
        [clearable]=false 
        formControlName="additionalNames"
        [multiple]="true" 
        [readonly]="form.value.type === 'ALLERGY' || !isEditable"
        (change)="onAdditionalNamesSelected($event, i)">

        <ng-template ng-header-tmp>
          <div class="row">
            <div class="col-md-2 font-weight-semi-bold">Code</div>
            <div class="col-md-5 font-weight-semi-bold">Name</div>
            <!-- <div class="col-md-5 font-weight-semi-bold">Generic Name</div> -->
          </div>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="term">
          <div class="row">
            <div class="col-md-2">{{item.code}}</div>
            <div class="col-md-5">{{item.description}}</div>
            <!-- <div class="col-md-5">{{ (item.genericName || '' )}}</div> -->
          </div>
        </ng-template>
      </ng-select>
    </div>
  </td>
  <td *ngIf="form.value.syncedFromCmis">
    <div class="wrapper-form-control">
      <input class="input" formControlName="additionalNames" readonly />
    </div>
  </td>
  <td>
    <div class="wrapper-form-control">
        <app-date-picker-with-icon [config]="addedDateDatePickerConfig" [customStyles]=true
            [dateControl]="form.get('addedDate')">
        </app-date-picker-with-icon>
        <errors [control]="form.get('addedDate')">
        </errors>
    </div>
  </td>
  <td class="min-width">
    <div class="input-group input-group-m">
      <button 
      class="btn btn-link-grey p-1"
        (click)="updateDetailsPanelVisibility()">
        <i [ngClass]="(this.form.get('showDetails').value) ? 'icon-up-open' : 'icon-down-open'"></i>
      </button>
      <button *ngIf="form.value.allergyType==='OTHER' && form.value.name.toUpperCase()==='NKDA' && isEditable" type="button" class="btn menu-button remarks pt-0 px-0" (click)="updateLatestDate()">
        <i class="icon-calendar"></i>
      </button>
      <button class="btn btn-link-grey p-1" (click)="onDelete()" [disabled]="!isEditable"><i class="icon-trash "></i></button>
      <button class="btn btn-link-grey p-1" *ngIf="!this.form.valid && !this.form.disabled" title="There are errors to be fixed, Please expand and check."
      (click)="updateDetailsPanelVisibility()"><i class="icon-attention redBg"></i></button>
    </div>
    <div *ngIf="!isEditable" class="customMsg">
      {{notificationMsg}}
    </div>
  </td>
</tr>
  <tr *ngIf="this.form.get('showDetails').value" [formGroup]="form">
    <td colspan="7">
      <div class="card-custom mb-2">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div class="wrapper-form-control">
                <label>Reaction</label>
                <input 
                  *ngIf="form.value.syncedFromCmis" 
                  class="input"
                  formControlName="adverseReaction" 
                  readonly />
                <ng-select 
                  *ngIf="!form.value.syncedFromCmis" 
                  class="custom-select-new  custom-select-extra-medium-width"
                  [items]="adverseReactions" 
                  [virtualScroll]="true" 
                  bindLabel="cmsName" 
                  bindValue="externalName"
                  placeholder="Select Type" 
                  formControlName="adverseReaction" 
                  [clearable]=false
                  [readonly]="!isEditable"
                  (change)="clearAdverseReactions()">
                </ng-select>
                <errors [control]="form.get('adverseReaction')"></errors>
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrapper-form-control">
                <label>Is this an allergic reaction?</label>
                <ng-select 
                  *ngIf="!form.value.syncedFromCmis"
                  class="custom-select-new  custom-select-almost-medium-width"
                  [items]="filteredDrugAllergyIndicatorCodes"
                  [virtualScroll]="true" 
                  bindLabel="cmsName" 
                  bindValue="cmsCode" 
                  placeholder="Select Type"
                  formControlName="drugAllergyIndicatorCode" 
                  [clearable]=false
                  [readonly]="drugAllergyIndicatorReadOnly || !isEditable"
                  (change)="clearDrugAllergyIndicator()">
                </ng-select>
                <errors [control]="form.get('drugAllergyIndicatorCode')"></errors>
                <input 
                  *ngIf="form.value.syncedFromCmis" 
                  class="input"
                  formControlName="drugAllergyIndicatorCode" 
                  readonly />
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrapper-form-control">
                <label>Information Source</label>
                <ng-select 
                  *ngIf="!form.value.syncedFromCmis" 
                  class="custom-select-new  custom-select-almost-medium-width"
                  [items]="infoSourseCodes" 
                  [virtualScroll]="true" 
                  [disabled]="!form.value.syncedFromCmis"
                  bindLabel="cmsName" 
                  bindValue="cmsCode" 
                  placeholder="Select Type" 
                  formControlName="infoSourceCode"
                  [clearable]=false 
                  [readonly]="!isEditable"
                  (change)="clearInfoSourceCode()">
                </ng-select>
                <errors [control]="form.get('infoSourceCode')"></errors>

                <input 
                  *ngIf="form.value.syncedFromCmis" 
                  class="input"
                  formControlName="infoSourceCode" 
                  readonly />
              </div>
            </div>
            <div class="col-md-3">
              <div class="wrapper-form-control">
                <label>Reaction Outcomes</label>
                <ng-select 
                  *ngIf="!form.value.syncedFromCmis"
                  class="custom-select-new  custom-select-almost-medium-width" 
                  [items]="reactionOutcomeCodes"
                  [virtualScroll]="true" 
                  bindLabel="cmsName" 
                  bindValue="cmsCode" 
                  placeholder="Select Type"
                  formControlName="reactionOutcomeList" 
                  [multiple]="true" 
                  [clearable]=false
                  [readonly]="!isEditable"
                  (change)="clearReactionOutcome()">
                </ng-select>
                <errors [control]="form.get('reactionOutcomeList')"></errors>
                <input 
                  *ngIf="form.value.syncedFromCmis" 
                  class="input"
                  formControlName="reactionOutcomeList" 
                  readonly />
              </div>
            </div>
          </div>
          <div class="row">            
            <div class="col-md-3">
              <div class="wrapper-form-control">
                <label>Causality/Probability to cause reaction?</label>
                <ng-select 
                  *ngIf="!form.value.syncedFromCmis"
                  class="custom-select-new  custom-select-almost-medium-width" 
                  [items]="drugProbabilityCodes"
                  [virtualScroll]="true" 
                  bindLabel="cmsName" 
                  bindValue="cmsCode" 
                  placeholder="Select Type"
                  formControlName="drugProbability" 
                  [clearable]=false
                  [readonly]="!isEditable"
                  (change)="clearDrugProbability()">
                </ng-select>
                <errors [control]="form.get('drugProbability')"></errors>
                <input 
                  *ngIf="form.value.syncedFromCmis" 
                  class="input"
                  formControlName="drugProbability" 
                  readonly />
              </div>
            </div>                    
            <div class="col-md-3" [formGroup]="form.get('reporter')">
              <div class="wrapper-form-control">
                <label>Administrator Type</label>
                <ng-select
                  *ngIf="!form.value.syncedFromCmis"
                  class="custom-select-new  custom-select-extra-medium-width" 
                  [items]="administratorTypes"
                  [virtualScroll]="true" 
                  bindLabel="name" 
                  bindValue="value" 
                  placeholder="Select Type"
                  formControlName="profession" 
                  [clearable]=false
                  [readonly]="!isEditable"
                  (change)="onAdministratorTypeSelect($event, form)">
                </ng-select>
                <errors [control]="form.get('reporter').get('profession')"></errors>
                <input 
                  *ngIf="form.value.syncedFromCmis" 
                  class="input"
                  formControlName="profession" 
                  readonly />
              </div>
            </div>
            <div class="col-md-3"
              *ngIf="form?.get('reporter')?.get('profession')?.value && form.get('reporter').get('profession').value === 'DOCTOR' && !form.value.syncedFromCmis"
              [formGroup]="form.get('reporter')">
              <div class="wrapper-form-control">
                <label>Administrator </label>
                <ng-select 
                  name="regNo"
                  class="custom-select-new custom-select-almost-medium-width"
                  bindLabel="name" 
                  bindValue="mcr" 
                  [items]="doctors" 
                  [clearable]=true
                  [readonly]="!isEditable"
                  (change)="onDoctorChange($event, form)" 
                  formControlName="regNo" 
                  appendTo="body">
                </ng-select>
                <errors [control]="form.get('reporter').get('regNo')"></errors>
              </div>
            </div>

            <div 
              class="col-md-3" 
              *ngIf="(form?.get('reporter')?.get('profession')?.value && form.get('reporter').get('profession').value != 'DOCTOR') || (form.get('migrated').value && !form?.get('reporter')?.get('profession')?.value)"
              [formGroup]="form.get('reporter')">
              <div class="wrapper-form-control">
                <label>Administrator Name</label>
                <input 
                  class="input" 
                  formControlName="name"
                  [readonly]="form.value.syncedFromCmis || !isEditable" />
                <errors [control]="form.get('reporter').get('name')"></errors>
              </div>
            </div>
            <div 
              class="col-md-3" 
              *ngIf="form?.get('reporter')?.get('profession')?.value && form.get('reporter').get('profession').value != 'DOCTOR' "
              [formGroup]="form.get('reporter')">
              <div class="wrapper-form-control">
                <label>Administrator ID</label>
                <input 
                  class="input" 
                  formControlName="regNo"
                  [readonly]="form.value.syncedFromCmis || !isEditable" />
                <errors [control]="form.get('reporter').get('regNo')"></errors>
              </div>
            </div>
            <div class="col-md-12">
              <div class="wrapper-form-control">
                <label>Remarks</label>
                <textarea class="form-control form-control-sm" rows="3" id="remark"  formControlName="updateRemarks" [readonly]="form.value.syncedFromCmis || !isEditable"></textarea>
<!--               
                <input 
                  class="input" 
                  formControlName="updateRemarks"
                  [readonly]="form.value.syncedFromCmis" /> -->
              </div>
            </div>
          </div>
          <div class="row">
            
          </div>
        </div>
      </div>
    </td>
  </tr>