import { AkitaPatientAppQuery } from './../../../../services/states/akita-patient/akita-patient-app.query';
import { UtilsService } from './../../../../services/utils.service';
// import { PatientNote } from './../../../../objects/response/PatientNote';
import {FormControl, FormGroup} from '@angular/forms';
import { StoreService } from './../../../../services/store.service';
import { ApiCmsManagementService } from './../../../../services/api-cms-management.service';
import { DisplayDatePipe } from './../../../../pipes/display-date.pipe';
import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { PatientNoteAdd } from '../../../../objects/request/PatientNoteAdd';
import * as moment from "moment";
import {DB_FULL_DATE_FORMAT} from "../../../../constants/app.constants";

@Component({
  selector: 'app-problem-list-item',
  templateUrl: './problem-list-item.component.html',
  styleUrls: ['./problem-list-item.component.scss'],
})
export class ProblemListItemComponent implements OnInit {
  patientNotesForm: FormGroup;
  @Input() item: PatientNoteAdd;
  @Output() onDeleteNoteClicked: EventEmitter<void> = new EventEmitter<void>();
  types = [
    { value: 'NOTE', label: 'NOTE' },
    { value: 'SHORT_TERM', label: 'PROBLEM SHORT TERM' },
    { value: 'LONG_TERM', label: 'PROBLEM LONG TERM' },
  ];
  editNote: FormControl;
  editMode: boolean;
  displayProblemState: string;
  constructor(
    private apiCmsManagementService: ApiCmsManagementService,
    private store: StoreService,
    private utilsService: UtilsService,
    private akitaPatientAppQuery: AkitaPatientAppQuery
  ) {}

  ngOnInit() {
    this.editMode = false;
    this.editNote = new FormControl();

    this.getDisplayProblemState();
  }

  editNoteClicked() {
    this.editMode = true;
  }
  updateDetails(){
    this.apiCmsManagementService.updatePatientNote(this.akitaPatientAppQuery.getId(), this.item.patientNoteId, this.item)
      .subscribe(res => {
        window.location.reload()
      })
  }

  deleteNoteClicked() {
    this.item.status = 'INACTIVE';
    this.apiCmsManagementService
      .modifyPatientNote(this.akitaPatientAppQuery.getId(), this.item.patientNoteId, this.item.status)
      .subscribe(res => {
        this.getDisplayProblemState();
        this.onDeleteNoteClicked.emit();
      });
  }

  getDisplayProblemState() {
    if (this.item.status === 'INACTIVE') {
      this.displayProblemState = this.item.status;
    } else {
      this.displayProblemState = this.formatTitleCase(this.item.typeOfProblem);
    }
  }

  formatTitleCase(string) {
    return this.utilsService.convertToTitleCase(string).toUpperCase();
  }

  getDoctorName(doctorId: string): string {
    return (
      this.store.doctorList.find(doctor => doctor.id === doctorId) || {
        displayName: '',
      }
    ).displayName;
  }

  getProblemStateClass() {
    let str = 'problem-list-badge ';
    switch (this.displayProblemState) {
      case 'INACTIVE':
        str += 'inactive';
        break;
      case 'LONG TERM':
        str += 'long-term';
        break;
      case 'SHORT TERM':
        str += 'short-term';
        break;
      case 'NOTE':
        str += 'NOTE';
        break;
    }

    return str;
  }
}
