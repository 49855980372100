import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  MedicalCoverageItemInfo,
  MedicalCoverageItemInfoState,
  MedicalCoverageItemInfoStore
} from "./akita-medical-coverage-item-info.store";

@Injectable({ providedIn: 'root' })
export class MedicalCoverageItemInfoQuery extends QueryEntity<MedicalCoverageItemInfoState> {

  constructor(protected store: MedicalCoverageItemInfoStore) {
    super(store);
  }
}
