import { Pipe, PipeTransform } from '@angular/core';
import { CountType } from '../objects/StockTake';

@Pipe({
  name: 'inventoryDisplayStockTakeCountType'
})
export class InventoryDisplayStockTakeCountTypePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }

    if (value.countType === CountType.PARTIAL) {
      return `Partial(${value.startRange} - ${value.endRange})`;
    } else if (value.countType === CountType.FULL) {
      return 'Full';
    }
  }
}
