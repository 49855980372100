import { StoreService } from './../../../../../services/store.service';
import { MedicalCoverageFormService } from './../../../../../services/medical-coverage-form.service';
// Libraries
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core'
import { Subject, of, Observable, concat, BehaviorSubject } from 'rxjs'
import {
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import * as moment from 'moment'
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  filter,
  tap,
  switchMap,
  catchError,
  map,
  debounce,
  timeout,
  retry,
} from 'rxjs/operators';
import { get } from 'lodash';

// Objects
import DatePickerConfig from '../../../../../objects/DatePickerConfig';
import { HttpResponseBody } from '../../../../../objects/response/HttpResponseBody';
import { createPlan, Plan } from '../../../../../objects/Plan';

// Services
import { ApiCmsManagementService } from '../../../../../services/api-cms-management.service';
import { AlertService } from '../../../../../services/alert.service';
import { PrintTemplateService } from '../../../../../services/print-template.service';
import {
  notBeforeToday,
  validateMC,
  planIsInActive,
  validateOrder, MedicalCoverageService,
} from '../../medical-coverage.service';

// Constants
import {
  DISPLAY_DATE_FORMAT,
  INPUT_DELAY,
} from '../../../../../constants/app.constants';
import { MedicalCoverage } from '../../../../../objects/MedicalCoverage';
import { NgxPermissionsService } from "ngx-permissions";
import { AkitaPatientAppQuery } from '../../../../../services/states/akita-patient/akita-patient-app.query'
import { getAge, getDetailAge } from '../../../../../util/date.util'
import { AppointmentMasterQuery } from '../../../../../state/appointments/appointment-master/appointment-master.query'
import { RpaRequestService } from '../../../../../services/rpa-request.service';
import { AkitaPatientStoreService } from '../../../../../services/states/akita-patient/akita-patient-store.service';
import { TempStoreService } from '../../../../../services/temp-store.service';
// import { MedicalCoverage } from '../../../../../objects/MedicalCoverage';

@Component({
  selector: 'app-medical-coverage-edit-item',
  templateUrl: './medical-coverage-edit-item.component.html',
  styleUrls: ['./medical-coverage-edit-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalCoverageEditItemComponent implements OnInit, OnDestroy {
  @Input() patientCoverageItem: FormGroup;
  @Input() newPatientId: string;
  @Output() onDelete = new EventEmitter<void>();

  isCollapsed: boolean;
  loading = false;
  codesTypeahead = new Subject<string>();
  datePickerConfigArray: Array<DatePickerConfig>;
  minDate: Date;

  planSelect = new Array<Plan>();
  medicalCoverageSelect = new Array<MedicalCoverage>();

  today = moment().format(DISPLAY_DATE_FORMAT);

  coverageSelected: AbstractControl;
  planSelected: AbstractControl;

  medicalCoverageId: AbstractControl;
  patientCoverageId: AbstractControl;
  planId: AbstractControl;
  coverageInactive: AbstractControl;
  planInactive: AbstractControl;
  planExpired: AbstractControl;
  coverageType: AbstractControl;
  isSelected: AbstractControl;
  isNew: AbstractControl;
  startDate: AbstractControl;
  endDate: AbstractControl;
  remarks: AbstractControl;
  costCenter: AbstractControl;
  status: AbstractControl;
  policyInactive: AbstractControl;
  isDuplicate: AbstractControl;

  policyHolderExpired: AbstractControl;
  policyExpired: AbstractControl;
  policyExcluded: AbstractControl;

  medicalCoverages$: Observable<MedicalCoverage[]>;
  medicalCoverageInput$ = new Subject<string>();

  private componentDestroyed: Subject<void> = new Subject();

  nric = ''
  patientInfo$
  patientInfoSubscription
  rpaError = ''
  rpaEnabled$ = new BehaviorSubject<boolean>(false);
  rpaLoading$ = new BehaviorSubject<boolean>(false);
  selectedMedicalCoverage: MedicalCoverage;

  private patientId: string;

  constructor(
    private alertService: AlertService,
    private apiCmsManagementService: ApiCmsManagementService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private medCovService: MedicalCoverageFormService,
    private service: MedicalCoverageService,
    private printTemplateService: PrintTemplateService,
    private store: StoreService,
    public permissionsService: NgxPermissionsService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private rpaRequestService: RpaRequestService,
    private akitaPatientStoreService: AkitaPatientStoreService,
    private tempStoreService: TempStoreService
  ) {}

  ngOnInit() {
    this.initialiseValues();
    this.subscribeOnChanges();
    this.getUserId();
    this.getPatientId();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.rpaEnabled$.unsubscribe()
    this.rpaLoading$.unsubscribe()
    this.patientInfoSubscription.unsubscribe()
  }

  initialiseValues() {
    this.isCollapsed = true;
    this.minDate = new Date();

    const get = (key: string) => this.patientCoverageItem.get(key);

    this.coverageSelected = get('coverageSelected');
    this.coverageInactive = get('coverageInactive');
    this.planSelected = get('planSelected');
    this.medicalCoverageId = get('medicalCoverageId');
    this.patientCoverageId = get('patientCoverageId');
    this.planInactive = get('planInactive');
    this.planExpired = get('planExpired');
    this.planId = get('planId');
    this.coverageType = get('coverageType');
    this.isSelected = get('isSelected');
    this.isNew = get('isNew');
    this.startDate = get('startDate');
    this.endDate = get('endDate');
    this.remarks = get('remarks');
    this.costCenter = get('costCenter');
    this.status = get('status');
    this.policyHolderExpired = get('policyHolderExpired');
    this.policyExpired = get('policyExpired');
    this.policyInactive = get('policyInactive');
    this.policyExcluded = get('policyExcluded');
    this.isDuplicate = get('isDuplicate');

    if (!this.isNew.value) {
      this.endDate.markAsTouched();
      this.patientCoverageItem.updateValueAndValidity();
      this.checkExpiry();
    } else {
      this.populateInputFields();
      // this.onFilterInputChanged();
    }

    this.initDatePickerConfigsForComponent();

    this.startDate.setValidators([
      Validators.required,
      validateOrder(this.startDate, this.endDate, true),
    ]);

    this.endDate.setValidators([
      notBeforeToday(this.endDate),
      validateMC(this.coverageSelected, this.endDate, true),
      validateOrder(this.startDate, this.endDate, false),
      Validators.required,
    ]);

    this.coverageSelected.setValidators([
      planIsInActive(this.coverageSelected.value.status),
    ]);

    this.loadMedicalCoverages();
   }

  checkExpiry() {
    const isHolderExpired = !moment(
      this.today,
      DISPLAY_DATE_FORMAT
    ).isSameOrBefore(moment(this.endDate.value, DISPLAY_DATE_FORMAT));

    this.policyHolderExpired.patchValue(isHolderExpired);
  }

  subscribeOnChanges() {
    this.startDate.valueChanges
      .pipe(debounceTime(50), takeUntil(this.componentDestroyed))
      .subscribe(value => {
        typeof value === 'object' ? this.startDate.patchValue(moment(value).format(DISPLAY_DATE_FORMAT), {
          emitEvent: false,
        }) : null;
      });

    this.endDate.valueChanges
      .pipe(debounceTime(50), takeUntil(this.componentDestroyed))
      .subscribe(value => {
        typeof value === 'object' ? this.endDate.patchValue(moment(value).format(DISPLAY_DATE_FORMAT), {
          emitEvent: false,
        }) : null;
        this.checkExpiry();
      });
  }

  populateInputFields() {
    if (
      this.patientCoverageItem &&
      this.medicalCoverageId.value &&
      this.planId.value
    ) {
      this.medicalCoverageSelect.push(this.coverageSelected.value);
      this.populatePlan(this.coverageSelected.value);
    }
  }

  populatePlan(coverage: MedicalCoverage) {
    this.planSelect = coverage.coveragePlans;
    this.patientCoverageItem.updateValueAndValidity();
  }

  initDatePickerConfigsForComponent() {
    this.datePickerConfigArray = new Array<DatePickerConfig>();

    const datepickerStart: DatePickerConfig = this.initDatePickerConfigObject(
      '',
      null,
      this.minDate,
      'bottom',
      'none'
    );
    const datepickerEnd: DatePickerConfig = this.initDatePickerConfigObject(
      '',
      null,
      this.minDate,
      'bottom',
      'none'
    );

    this.datePickerConfigArray['start'] = datepickerStart;
    this.datePickerConfigArray['end'] = datepickerEnd;
  }

  initDatePickerConfigObject(
    label,
    maxDate,
    minDate,
    datePickerPlacement,
    labelPlacement
  ) {
    return new DatePickerConfig(
      label,
      maxDate,
      minDate,
      datePickerPlacement,
      labelPlacement
    );
  }

  // onFilterInputChanged() {
  //   try {
  //     this.codesTypeahead
  //       .pipe(
  //         filter(input => {
  //           if (input === undefined || input === null || input.trim().length === 0) {
  //             this.medicalCoverageSelect = this.store.getMedicalCoverages();
  //             return false;
  //           } else {
  //             return true;
  //           }
  //         }),
  //         distinctUntilChanged((a, b) => {
  //           return a === b;
  //         }),
  //         tap(input => {
  //           this.loading = true;
  //         }),
  //         debounceTime(500),
  //         switchMap((term: string) => {
  //           return this.apiCmsManagementService
  //             .searchCoverageByClinic(this.store.getClinicId(), encodeURI(term))
  //             .pipe(catchError(error => of(<HttpResponseBody>{ page: {}, payload: [] })));
  //         })
  //       )
  //       .subscribe(
  //         data => {
  //           this.loading = false;
  //           this.cdr.markForCheck();

  //           if (data) {
  //             this.medicalCoverageSelect = data.payload.filter(element => {
  //               const today = moment().format(DISPLAY_DATE_FORMAT);
  //               return (
  //                 element['status'] === 'ACTIVE' &&
  //                 element['coveragePlans'].length > 0 &&
  //                 moment(today, DISPLAY_DATE_FORMAT).isSameOrBefore(moment(element['endDate'], DISPLAY_DATE_FORMAT))
  //               );
  //             });
  //           }
  //         },
  //         err => {
  //           try {
  //             this.loading = false;
  //           } catch (err) {
  //             this.alertService.error(JSON.stringify(err.error.message));
  //           }
  //         }
  //       );
  //   } catch (err) {
  //     console.error('Search Coverage Error', err);
  //   }
  // }

  onCoverageSelected(event: MedicalCoverage) {
    this.rpaError = ''

    if (event) {
      this.rpaEnabled$.next(event.rpaEnabled)
      this.selectedMedicalCoverage = event

      this.populatePlan(event);

      this.startDate.patchValue(new Date());
      if(event.type === 'MEDISAVE'){
        this.endDate.patchValue("", { emitEvent: false});
      } else{
        this.endDate.patchValue(
          moment(event.endDate, DISPLAY_DATE_FORMAT).toDate() || ''
        );
      }
      this.coverageType.patchValue(event.type);
      this.coverageSelected.patchValue(event);
      this.planSelected.patchValue(createPlan());
      this.planId.patchValue('');
      this.remarks.patchValue('');
      this.costCenter.patchValue('');

      this.coverageInactive.patchValue(
        this.medCovService.checkCoverageInactive(event.status)
      );
      this.policyExpired.patchValue(
        this.medCovService.checkPolicyExpired(event.endDate)
      );
    } else {
      this.isCollapsed = true;
      this.rpaEnabled$.next(false)
      this.selectedMedicalCoverage = null
    }
  }

  onPlanSelected(event: Plan) {
    if (event) {
      this.rpaError = ''
      this.isCollapsed = false;
      this.planSelected.patchValue(event);

      this.planInactive.patchValue(this.medCovService.checkPlanInactive(event));
      this.planExpired.patchValue(this.medCovService.checkPlanExpired(event));

      this.policyExcluded.patchValue(
        this.medCovService.checkPolicyExcluded(event.id)
      );

      this.patientCoverageItem.updateValueAndValidity();
      this.rpaEnabled$.next(false)
    } else {
      this.rpaEnabled$.next(this.selectedMedicalCoverage.rpaEnabled)
    }
  }

  onBtnDeleteClicked() {
    this.isCollapsed = !this.isCollapsed;
    this.onDelete.emit();
  }

  onBtnPrintClicked() {
    let medicalCoverageLabelList = this.printTemplateService.groupMedicalCoverageByCompanyName(
      this.fb.array([this.patientCoverageItem])
    );

    this.printTemplateService.onBtnPrintMedicalCoverageLabelClicked(
      medicalCoverageLabelList
    );
  }

  loadMedicalCoverages() {
    this.medicalCoverages$ = concat(
      of(this.store.getMedicalCoverages()), // default items
      this.medicalCoverageInput$.pipe(
        distinctUntilChanged(),
        debounceTime(INPUT_DELAY),
        filter(
          input =>
            input !== undefined && input !== null && input.trim().length !== 0
        ),
        tap(() => (this.loading = true)),
        switchMap(term =>
          this.apiCmsManagementService
            .searchCoverageByClinicObj(
              this.store.getClinicId(),
              encodeURI(term)
            )
            .pipe(
              map(payload => {
                const filteredPayload = payload.filter(data => {
                  return (
                    data['status'] === 'ACTIVE' &&
                    data['coveragePlans'].length > 0 &&
                    moment().isSameOrBefore(
                      moment(data['endDate'], DISPLAY_DATE_FORMAT)
                    )
                  );
                });
                return filteredPayload;
              }),
              catchError(() => of(this.store.getMedicalCoverages())), // empty list on error
              tap(data => {
                this.loading = false;
              })
            )
        )
      )
    );
  }

  getUserId() {
    this.patientInfo$ = this.akitaPatientAppQuery.patientInfo$
    this.patientInfoSubscription = this.patientInfo$.subscribe(res => this.nric = get(res, 'userId.number'))
  }

  private getPatientId(): void {
    this.patientId = this.akitaPatientStoreService.patientId;
  }

  startAutomation() {
    this.rpaLoading$.next(true)

    this.service.getRpaCredentials(this.store.getClinicId(), this.selectedMedicalCoverage.code)
      .subscribe((loginResp: any) => {
        const { username = '', password = '' } = loginResp.payload;
        const nric = this.newPatientId && this.newPatientId !== '' ? this.newPatientId : this.nric;

        this.service.createRpa(this.selectedMedicalCoverage.code, nric, username, password)
        .subscribe(
          (res: any) => {

            this.service.checkRpaStatus(res.id)
            .pipe(
              timeout(5000),
              retry(5)
            )
            .subscribe(
              (resp: any) => {

                if (!resp.result.error) {
                  const { policyDetails, remarks = '' } = resp.result

                  const plan = this.planSelect.find(p => p.code === policyDetails)
                  if (plan) {
                    plan.paymentRemarks = remarks;

                    const tempStoreKey = `${this.patientId}_${plan.id}`;
                    this.tempStoreService.tempStore(tempStoreKey, plan.paymentRemarks).subscribe(result => {
                      if(result.statusCode === 'S0000') {
                        this.planId.patchValue(plan.id)
                        this.onPlanSelected(plan)
                        this.rpaRequestService.close();
                        this.rpaEnabled$.next(false);
                      }
                    });
                  }
                } else {
                  this.rpaError = 'Automatic flow has failed, please check the portal manually.'
                  this.rpaRequestService.close();
                  this.isCollapsed = false
                }

                this.rpaLoading$.next(false)
              },
              error => {
                this.rpaLoading$.next(false)
              }
            )
          },
          error => {
            this.rpaLoading$.next(false)
          }
        )
      })
  }

  hasPermission() {
    return this.permissionsService.getPermission('ROLE_RPA_TPA') && this.rpaEnabled$.value
  }
}
