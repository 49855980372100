import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { map } from 'rxjs/operators';

import { AkitaCaseVisitQuery } from '../../../services/states/akita-case-visit.query';
import { AkitaMedicalCoverageQuery } from '../../../services/states/akita-medical-coverage.query';
import { CoveragePlan } from '../../../objects/state/CoveragePlan';
import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_TIME_NO_SECONDS_FORMAT } from '../../../constants/app.constants';
import { MedicalCoverage } from '../../../objects/state/MedicalCoverage';
import { PAYMENT_MODES_ALL } from '../../../constants/payment-mode';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss']
})
export class PaymentHistoryComponent implements OnInit {

  paymentLines$ = this.akitaCaseVisitQuery.select(entity => entity.salesOrder.invoices);

  paymentHistory$ = this.akitaCaseVisitQuery.select(entity => entity.salesOrder.invoices).pipe(
    map(invoices => invoices.filter(invoice => invoice.invoiceState !== 'DELETED')),
    map(invoices => {

      const invoiceListing = [];

      invoices.forEach(invoice => {
        if (invoice.invoiceType === 'DIRECT') {
          invoice.paymentInfos.forEach(paymentInfo => {
            invoiceListing.push({
              mode: this.getLabel(paymentInfo.billMode),
              amount: paymentInfo.amount,
              dateTime: moment(invoice.paidTime, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT),
              reference: paymentInfo.externalTransactionId
            });
          })
        } else {
          const mode = (<MedicalCoverage>this.akitaMedicalCoverageQuery.getMedicalCoverageByPlanId(invoice.planId)).name + ' - ' + (<CoveragePlan>this.akitaMedicalCoverageQuery.getMedicalCoveragePlan(invoice.planId)).name;

            invoiceListing.push({
              mode: mode,
              amount: invoice.payableAmount,
              dateTime: moment(invoice.paidTime, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT),
              reference: invoice.invoiceNumber
            });
        }
      });

      return invoiceListing;
    })
  );

  paymentMethodSelections = PAYMENT_MODES_ALL;


  constructor(
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaMedicalCoverageQuery: AkitaMedicalCoverageQuery
  ) { }

  ngOnInit() {
  }

  getLabel(value) {
    const label = this.paymentMethodSelections.find(paymentMethod => paymentMethod.value === value);
    return label && label.label && label.label.length > 0 ? label.label : '';
  }

}
