<div class="modal-header">
    <h4 class="modal-title pull-left"> {{ title }} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnCancelClicked()"><span aria-hidden="true">&times;</span></button>
</div>
<alert></alert>
<div [formGroup]="blockedTimeFormGroup" class="container-fluid h-100">
    <div class="row p-2 pb-3">
        <div class="col-12">
            <div class="card">

                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8 pb-0">
                            <h6>Please fill in the following details: </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="mb-0"></label>
                            <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" type="text" name="plan" [items]="selectionTypes" [clearable]="false" [formControl]="selectionType" [readonly]="editMode"></ng-select>
                        </div>
                        <div class="col">
                            <label class="mb-0">Name<span class="compulsory">*</span></label>
                            <ng-select *ngIf="this.selectionType.value  !== 'Services'; else elseTemplate" class="custom-select form-control-sm custom-select-extra-medium-width" type="text" bindValue='value' bindLabel='label' name="plan" [items]="doctors" formControlName="doctorId" [readonly]="editMode"></ng-select>
                            <ng-template #elseTemplate>
                                <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" type="text" bindValue='service' bindLabel='service' [items]="serviceNames" [formControl]="serviceName"  [readonly]="editMode"></ng-select>
                            </ng-template>
                            <errors [control]="blockedTimeFormGroup.get('doctorId')"></errors>
                        </div>
                        <div class="col">
                            <label class="mb-0">Block Type<span class="compulsory">*</span></label>
                            <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" type="text" bindValue='value' bindLabel='name' name="name" [items]="blockTypes" [formControl]="blockType" [readonly]="editMode"></ng-select>
                            <errors [control]="blockedTimeFormGroup.get('doctorId')"></errors>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col pr-0">
                            <app-date-picker-with-icon [config]="datePickerConfigArray['startTime']" [dateControl]="blockedTimeFormGroup.get('startDate')"></app-date-picker-with-icon>
                        </div>
                        <div class="col pr-0">
                            <label class="mb-0"></label>
                            <timepicker class="timepicker-report" [minuteStep]="30" [showMeridian]="true" [showSpinners]="false" [formControl]="startTime"></timepicker>
                        </div>
                        <div class="col pr-0">
                            <app-date-picker-with-icon [config]="datePickerConfigArray['endTime']" [dateControl]="endDateFC">
                            </app-date-picker-with-icon>
                        </div>
                        <div class="col pr-0">
                            <label class="mb-0"></label>
                            <timepicker class="timepicker-report" [minuteStep]="30" [showMeridian]="true" [showSpinners]="false" [formControl]="endTime"></timepicker>
                        </div>
                    </div>

                     <!-- REPEATS -->
                     <div class="row mt-3">
                        <div class="col-1">
                            <label class="mb-0">Repeats</label>
                            <div>Every</div>
                        </div>
                        <div class="col-2">
                            <label class="mb-0"></label>
                            <div>
                                <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" type="text" [items]="weekNoList" formControlName="everyWeek"></ng-select>
                            </div>
                        </div>
                        <div class="col-1">
                            <label class="mb-0"></label>
                            <div>week(s)</div>
                        </div>
                        <div class="col-1">
                            <label class="mb-0"></label>
                            <div>on</div>
                        </div>
                        <div class="col-7">
                            <label class="mb-0"></label>
                            <div>
                                <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" [multiple]="true" type="text"  [items]="dayList" formControlName="daysOfWeek"></ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-12">
                            <label class="mb-0">Remarks </label>
                            <textarea class="form-control form-control-sm" formControlName="remarks"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-toolbar float-right " role="toolbar ">
                <div class="btn-group " role="group ">
                    <button class="btn btn-large btn-brand-secondary mr-2" (click)="onBtnCancelClicked()">Cancel</button>
                    <button class="btn btn-large btn-brand-primary" (click)="onBtnSubmitClicked()" [disabled]="disableSubmit()">Save (F4)</button>
                </div>
            </div>
        </div>
    </div>
</div>