import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';

export interface SFLSetupData {
    name: string;
    values: Array<SFLSetupDataValue>;
}

export interface SFLSetupDataValue {
    cmsCode: string;
    cmsName: string;
    externalCode: string;
    externalName: string;
    defaultAmount: number;
}

function createSFLSetupData(): SFLSetupData {
    return {
        name: '',
        values: []
    } as SFLSetupData;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'sflSetup',
    idKey: 'name',
    cache: { ttl: AKITA_CACHE_DURATION },
    resettable: true,
})
export class AkitaSFLSetupStore extends EntityStore<SFLSetupData> {
    constructor() {
        super(createSFLSetupData());
    }
}