<ng-container>
    <div class="row" [ngClass]="{'modal-sub-header' : isCollapsed , 'modal-sub-header-expanded' : !isCollapsed}" (click)="isCollapsed = !isCollapsed">
        <ng-container [formGroup]='patientCoverageItem'>

            <div class="input-group input-group-sm mb-0">
                <div class="col-1">
                    <input 
                        type="checkbox"
                        (change)="onCheck($event, patientCoverageItem)" 
                        [disabled]="(selectedItems.length > 0 && selectedItems[0] !== patientCoverageItem.value.id) || (coverageSelected && status.value === 'INACTIVE')"
                        >
                </div>
                <div class="col-2">
                    {{ coverageSelected ? name.value : ''}}
                    <p 
                        class="txt-status"
                        *ngIf="coverageSelected && status.value === 'INACTIVE' ">
                        This plan is inactive
                    </p>
                </div>
                <div class="col-1">
                    {{ coverageSelected ? corpIdentificationNumber.value.number : ''}}
                </div>
                <div class="col-2">
                    {{ coverageSelected ? coverageSelected.value.name : ''}}
                </div>
                <div class="col-1">
                    {{ planSelected.value.name}}
                    <p 
                        class="txt-status"
                        *ngIf="coverageSelected && status.value === 'INACTIVE' ">
                        This plan is inactive
                    </p>
                </div>
                <div class="col-1">
                    {{ patientCoverageId.value}}
                </div>
                <div class="col-2">
                    {{ patientCoverageItem.value.costCenter }}
                </div>
                <div class="col-2">
                    {{ remarks.value}}
                    <span class="col-6">
                        <i [ngClass]="isCollapsed ? 'icon-down-open-big':'icon-up-open-big'" class="btn menu-button p-0"></i>
                    </span>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- ITEM DETAILS -->
    <div [collapse]="isCollapsed">
        <app-medical-coverage-item-detail [remarks]="remarks.value" [coverage]="coverageSelected.value"
            [plan]="planSelected.value">
        </app-medical-coverage-item-detail>
    </div>
</ng-container>