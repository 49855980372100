import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayPercentage'
})
export class DisplayPercentagePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const percentageValue = `${(value / 100).toFixed(2)}%`;
    return percentageValue;
  }
}