import { DeliveryOrder, DeliveryOrderItem } from './DeliveryOrder';
import { Price } from './InventoryItem';
import { StoreService } from '../services/store.service';
import { NavIntegrationResult } from './CommonResponses';

export class OrderReturnRequest {

  id: string;
  requestClinicId: string;
  orderType: string;
  orderNo: string;
  createDate: string;
  orderDate: string;
  completeDate: string;
  goodReceivedNotes: Array<any>;
  returnStatus: OrderReturnRequestStatus;
  supplierId: string;
  goodPurchasedItems: Array<any>;
  transferRequestItems: Array<any>;
  returnItems: Array<OrderReturnRequestItem>;
  deliveryNotes: Array<DeliveryOrder>;
  cpTransfer: boolean;
  navIntegrationResult: NavIntegrationResult;
  purchaseOrderId: string;
  poNo: string;

  adapt(obj?): OrderReturnRequest {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.requestClinicId = obj.requestClinicId;
    this.orderType = obj.orderType;
    this.orderNo = obj.orderNo;
    this.createDate = obj.createDate;
    this.orderDate = obj.orderDate;
    this.completeDate = obj.completeDate;
    this.goodReceivedNotes = obj.goodReceivedNotes;
    this.returnStatus = obj.returnStatus;
    this.supplierId = obj.supplierId;
    this.goodPurchasedItems = obj.goodPurchasedItems;
    this.transferRequestItems = obj.transferRequestItems;
    this.returnItems = (obj.returnItems || []).map(item =>
      new OrderReturnRequestItem().adapt(item)
    );
    this.deliveryNotes = (obj.deliveryNotes || []).map(item =>
      new DeliveryOrder().adapt(item)
    );
    this.cpTransfer = obj.cpTransfer;
    this.navIntegrationResult = obj.navIntegrationResult;
    this.purchaseOrderId = obj.purchaseOrderId;
    this.poNo = obj.poNo;

    return this;
  }

  getItemQuantity(itemId: string): number {
    return this.returnItems
      .filter(item => item.itemRefId === itemId)
      .reduce((sum, item) => (sum += item.quantity), 0);
  }

  getPurchaseOrderId() {
    if (this.returnItems.some(item => item.orderId && item.orderId.length > 0)) {
      return Array.from(new Set(this.returnItems.filter(item => item.orderId && item.orderId.length > 0).map(item => item.orderId)))[0];
    }

    return undefined;
  }

  getPurchaseOrderNo() {
    if (this.returnItems.some(item => item.orderNo && item.orderNo.length > 0)) {
      return Array.from(new Set(this.returnItems.filter(item => item.orderNo && item.orderNo.length > 0).map(item => item.orderNo)))[0];
    }

    return undefined;
  }

  toDeliveryOrder(): DeliveryOrder {
    const deliveryOrder = new DeliveryOrder();

    deliveryOrder.delivererId = this.requestClinicId;
    deliveryOrder.receiverId = this.supplierId;
    deliveryOrder.transferSendItems = (this.returnItems || []).map(item =>
      item.toDeliveryOrderItem()
    );
    return deliveryOrder;
  }
}

export class OrderReturnRequestItem {
  itemRefId: string;
  uom: string;
  batchNumber: string;
  quantity: number;
  receiptDate: string;
  invoiceNo: string;
  expiryDate: string;
  manufacturerDate: string;
  reasonCode: string;
  unitPrice: Price;
  lineNo: string;
  orderId: string;
  orderNo: string;

  adapt(obj?): OrderReturnRequestItem {
    if (!obj) {
      return this;
    }

    this.itemRefId = obj.itemRefId;
    this.uom = obj.uom;
    this.batchNumber = obj.batchNumber;
    this.quantity = obj.quantity;
    this.receiptDate = obj.receiptDate;
    this.invoiceNo = obj.invoiceNo;
    this.expiryDate = obj.expiryDate;
    this.manufacturerDate = obj.manufacturerDate;
    this.reasonCode = obj.reasonCode;
    this.unitPrice = obj.unitPrice ? obj.unitPrice !== null && typeof obj.unitPrice === 'object'
      ? new Price().adapt(obj.unitPrice) : new Price(obj.unitPrice, false)
      : new Price(0, false);
    this.lineNo = obj.lineNo;
    this.orderId = obj.orderId;
    this.orderNo = obj.orderNo;

    return this;
  }

  getItemCode(store: StoreService): string {
    const item = store.getItemById(this.itemRefId);
    if (item && item.item) {
      return item.item.code;
    }

    return '';
  }

  getItemName(store: StoreService): string {
    const item = store.getItemById(this.itemRefId);
    if (item && item.item) {
      return item.item.name;
    }

    return '';
  }

  getItemUnitPrice(): number {
    if (this.unitPrice) {
      return this.unitPrice.getPriceWithoutTax();
    }

    return 0;
  }

  getItemAmount(): number {
    return this.getItemUnitPrice() * (this.quantity || 0);
  }

  toDeliveryOrderItem(): DeliveryOrderItem {
    const item = new DeliveryOrderItem();
    item.itemRefId = this.itemRefId;
    item.uom = this.uom;
    item.batchNumber = this.batchNumber;
    item.quantity = this.quantity;
    item.expiryDate = this.expiryDate;
    item.manufacturerDate = this.manufacturerDate;
    item.lineNo = this.lineNo;
    return item;
  }
}

export enum OrderReturnRequestStatus {
  DRAFT = 'DRAFT',
  CONFIRM = 'CONFIRM',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  RETURNED = 'RETURNED',
  FAILED = 'FAILED'
}