import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inventoryDisplayStockTakeUncountedItemSize'
})
export class InventoryDisplayStockTakeUncountedItemSizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return 0;
    }

    return value.getUncountedItem().length;
  }

}
