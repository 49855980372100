<!-- TOP SECTION -->
<form class="h-100" novalidate [formGroup]="patientNotesForm">
  <!-- Search Section -->
  <div class="card scrollable-card h-100">
    <div class="card-header">
      PATIENT NOTES
    </div>
    <div class="card-body">
      <!-- <pre>{{patientNotesForm.value|json}}</pre> -->
      <!-- Add New Note Section -->

      <div class="form-group p-2 mb-0">
        <label class="mb-0">Description</label>
        <textarea class="form-control" formControlName='note'></textarea>
      </div>

      <div class="row p-2 m-0 border-bottom">
        <div class="form-group p-0 col-md-3">
          <label class="mb-0">Type</label>
          <ng-select name="type" class='p-0 form-control form-control-sm custom-select custom-select-extra-medium-width'
            [items]="types" bindLabel='label' bindValue='value' [addTag]="false" [virtualScroll]="true" (change)="buttonDisable()"
            [clearable]=false formControlName="type">
          </ng-select>
        </div>
        <div class="form-group col-md-3">
          <app-date-picker-with-icon [config]="datePickerConfigArray['onset']" [dateControl]="patientNotesForm.get('date')">
          </app-date-picker-with-icon>
        </div>
        <div class="form-group col-md-4"></div>
        <div *ngxPermissionsOnly="['ROLE_MANAGE_PATIENT_NOTES']" class="form-group col-md-2 mb-0 align-self-center">
          <button class='btn btn-sm btn-brand-primary brand pull-right' (click)='onAddNote()'
            [disabled]="!patientNotesForm.get('note').value|| patientNotesForm.get('note').value.length<1">Save Note</button>
        </div>
      </div>

      <!-- Filters -->
      <div class="row align-items-center p-2 mb-0 add-note-bg">
        <div class="col-md-4">
          <label class='mb-0' for='problemListFrom'>From</label>
          <app-date-picker-with-icon id='problemListFrom' [config]="datePickerConfigArray['from']"
            [dateControl]="patientNotesForm.get('filterStart')"></app-date-picker-with-icon>
        </div>
        <div class="col-md-4">
          <label class='mb-0' for='problemListTo'>To</label>
          <app-date-picker-with-icon id='problemListTo' [config]="datePickerConfigArray['to']"
            [dateControl]="patientNotesForm.get('filterEnd')"></app-date-picker-with-icon>
        </div>
        <div class="col-md-4">
          <label class='mb-0' for='problemListStatus'>Status</label>
          <ng-select class='p-0 form-control form-control-sm custom-select' [items]='statusFilter' [clearable]='false'
            id='problemListStatus' formControlName="filterStatus"></ng-select>
        </div>
      </div>

      <!-- Display All Notes Section -->
      <div class="px-2">
        <ng-container *ngIf="patientNotesGrouped$|async as patientNotesData">
          <!-- GROUP ICON -->
          <ng-container *ngFor='let pn of patientNotesData | keyvalue: keyOrderDesc'>
            <div class='timeline__container_group'>
              <div class='timeline__left'></div>
              <div class='timeline__right'>
                <div class="timeline__group_dot">{{ pn.key }}</div>
                <p class='timeline__content'></p>
                <div class='timeline__author'></div>
              </div>
            </div>
            <ng-container *ngFor='let item of pn.value'>
              <app-problem-list-item [item]="item" (onDeleteNoteClicked)="filterData()"></app-problem-list-item>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</form>
<br>
