<div class="card-custom-light">
    <div class="card-header" *ngIf="title.length > 0">
        {{title}}
    </div>

    <div class="card-body">
        <div class="container-fluid p-0">
            <ng-content></ng-content>
        </div>
    </div>
</div>