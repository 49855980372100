import { ExercisePlanValue } from "./CarePlanExerciseRecommendation";

export class DietRecommendation {
    constructor(
        readonly code: string,
        readonly description: string,
        readonly type: string,
        readonly category: string,
        readonly selected?: boolean,
        readonly contextValue?: string,
    ) { }

    static adapt(item: any): DietRecommendation {
        return new DietRecommendation(
            item.code,
            item.description,
            item.type,
            item.category,
            item.selected || false,
            item.contextValue || '',
        )
    }

    static toServer(item: DietRecommendation): ExercisePlanValue {
        return {
            placeholder: item.code,
            value: item.category,
            contextValue: item.contextValue || '',
        }
    }
}