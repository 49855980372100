// Services
import { UtilsService } from './../../../../services/utils.service';
import { AppointmentsFactoryService } from './../../../../services/appointments-factory.service';

// Objects
import { DoctorCalendar } from '../../../../objects/DoctorCalendar';
import {
  DoctorCalendarStore,
  DoctorCalendarState,
} from './doctor-calendar.store';
import { DOCTOR_LEAVE } from '../../../../constants/app.constants';
import { BlockDate } from '../../../../objects/ClinicCalendar';
import { CalendarAppointment } from './../../../../objects/CalendarAppointment';
import {
  DOCTOR_BLOCKED_TIME,
  colors,
  DISPLAY_DATE_FORMAT,
} from './../../../../constants/app.constants';

//Libraries
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import * as moment from 'moment';
import {RRule} from 'rrule';

@Injectable({ providedIn: 'root' })
export class DoctorCalendarQuery extends QueryEntity<
  DoctorCalendarState,
  DoctorCalendar
> {
  selectAllDocCalToAppointment$ = this.selectAll().pipe(
    map((calendar: DoctorCalendar[]) => {
      const list: CalendarAppointment[] = [];
      calendar.map(cal => {
        list.push(...this.setDoctorEvent(cal));
      });
      return list;
    })
  );

  constructor(
    protected store: DoctorCalendarStore,
    private utilsService: UtilsService,
    private apptFactory: AppointmentsFactoryService
  ) {
    super(store);
  }

  setDoctorEvent(doctorCalendar: DoctorCalendar) {
    const docCalendar = <DoctorCalendar>doctorCalendar;
    const list: CalendarAppointment[] = [];
    const setCalAppt = (blockDates: BlockDate[], type) => {
      blockDates.forEach((blockDate: BlockDate) => {
        this.extractDoctorBlockDatesIntoCalendar(
          blockDate,
          docCalendar.doctorId,
          type,
          list
        );
      });
    };

    var doctorEvents = Object.getOwnPropertyNames(docCalendar);
    doctorEvents.forEach(prop => {
      if (prop === 'leaves') {
        setCalAppt(docCalendar[prop], DOCTOR_LEAVE);
      } else if (prop === 'blockedTime') {
        setCalAppt(docCalendar[prop], DOCTOR_BLOCKED_TIME);
      }
    });

    return list;
  }

  createRecurringEvent(day) {
    return [
      {
        title: '',
        color: colors.red,
        rrule: {
          freq: RRule.WEEKLY,
          byweekday: [day],
          dtstart: new Date(Date.UTC(new Date().getFullYear(), 1, 1)),
          until: new Date(Date.UTC(new Date().getFullYear(), 12, 31)),
        },
      },
    ];
  }

  extractDoctorBlockDatesIntoCalendar(
    blockDate: BlockDate,
    doctorId,
    type,
    list
  ) {
    if (blockDate.calendarDayRange || blockDate.calendarDayYear) {
      const event = this.createCalendarEvent(
        type,
        this.apptFactory.createBlockDate(blockDate),
        doctorId
      );
      list.push(event);
    } else if (blockDate.calendarDayWeek) {
      let day = this.utilsService.getDayOfWeekForRRule(
        blockDate.calendarDayWeek.dayOfWeek
      );
      let recurringEvents = this.createRecurringEvent(day);

      recurringEvents.forEach(event => {
        const rule: RRule = new RRule({
          ...event.rrule,
        });

        rule.all().forEach(date => {
          const formattedDate = moment(date).format(DISPLAY_DATE_FORMAT);
          const block = this.apptFactory.createBlockDate({
            calendarDayRange: this.apptFactory.createCalendarDayRange(
              formattedDate,
              formattedDate
            ),
            start: blockDate.start,
            end: blockDate.end,
          });

          const event = this.createCalendarEvent(type, block, doctorId);
          list.push(event);
        });
      });
    }
  }

  createCalendarEvent(type, block, doctorId) {
    const calendarEvent: CalendarAppointment = this.apptFactory.createCalendarEvent(
      type,
      null,
      block,
      doctorId
    );

    return calendarEvent;
  }
}
