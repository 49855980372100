import { AkitaCaseVisitQuery } from './../../../../../services/states/akita-case-visit.query';
import { distinctUntilChanged } from 'rxjs/operators';
import { IDispaches } from './../../package.model';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import DatePickerConfig from '../../../../../objects/DatePickerConfig';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../../../constants/app.constants';

@Component({
  selector: 'app-package-sub-item-expiry',
  templateUrl: './package-sub-item-expiry.component.html',
  styleUrls: ['./package-sub-item-expiry.component.scss'],
})
export class PackageSubItemExpiryComponent implements OnInit {
  @Input() packageId: string
  @Input() subItem: IDispaches;
  @Input() disabled: boolean;

  expiryDate: FormControl;
  datePickerConfigArray: Array<DatePickerConfig>;

  constructor(
    private caseVisitQuery: AkitaCaseVisitQuery
  ) {}

  ngOnInit() {
    this.expiryDate = new FormControl();
    this.initDatePickerConfigsForComponent();

    if (this.subItem.expiryDate) {
      this.expiryDate.patchValue(this.subItem.expiryDate);
    }

    if (this.disabled) {
      this.expiryDate.disable();
    } else {
      this.expiryDate.enable();
    }

    this.expiryDate.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe( value =>{
        
        const updatedSubItem : IDispaches = {
          ...this.subItem,
          expiryDate: moment(value).format(DISPLAY_DATE_FORMAT)
        }

        this.caseVisitQuery.updateSubItemForActiveVisit(
          this.packageId,
          updatedSubItem
        ) 
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.disabled) {
      if (this.expiryDate) {
        this.expiryDate.disable();
      } else {
        if (this.expiryDate) {
          this.expiryDate.enable();
        }
      }
    }
  }

  initDatePickerConfigsForComponent() {
    this.datePickerConfigArray = new Array<DatePickerConfig>();

    const datepickerFrom: DatePickerConfig = this.initDatePickerConfigObject(
      '',
      null,
      new Date(),
      'top',
      'top'
    );
    this.datePickerConfigArray['expiryDate'] = datepickerFrom;
  }

  initDatePickerConfigObject(
    label,
    maxDate,
    minDate,
    datePickerPlacement,
    labelPlacement
  ) {
    return new DatePickerConfig(
      label,
      maxDate,
      minDate,
      datePickerPlacement,
      labelPlacement
    );
  }
}
