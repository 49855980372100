import { DISPLAY_DATE_FORMAT, REACTION_TYPES } from './../../../../../constants/app.constants';
import { StoreService } from './../../../../../services/store.service';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { AlertService } from '../../../../../services/alert.service';
import * as moment from 'moment';
import { AkitaAllergyGroupQuery } from '../../../../../services/states/akita-allergy-group.query';
import { AkitaChargeItemQuery } from '../../../../../services/states/akita-charge-item.query';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CustomConfirmPopupComponent } from '../../../../../views/components/custom-confirm-popup/custom-confirm-popup.component';
import DatePickerConfig from '../../../../../objects/DatePickerConfig';
import { AkitaClinicQuery } from '../../../../../services/states/akita-clinic.query';
import { Clinic } from '../../../../../objects/response/Clinic';
import { pairwise } from 'rxjs/operators';
@Component({
  selector: 'app-patient-detail-tag-add-alert-item',
  templateUrl: './patient-detail-tag-add-alert-item.component.html',
  styleUrls: ['./patient-detail-tag-add-alert-item.component.scss'],
})
export class PatientDetailTagAddAlertItemComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() alertFormGroup: FormGroup;
  @Input() index: number;

  @Input() infoSourseCodes:any = [];
  @Input() drugAllergyIndicatorCodes:any = [];
  @Input() reactionOutcomeCodes:any = [];
  @Input() drugProbabilityCodes:any = [];
  @Input() adverseReactions:any = [];
  @Input() administratorTypes:any = [];
  @Input() doctors: any[];
  @Input() drugGenericCompounds: any[];

  reactionTypes : any[] = REACTION_TYPES;
  filteredDrugAllergyIndicatorCodes : any[];
  allergyTypes : any[];
  viewDetails: boolean = false;
  selectedAdminTypeCode: string;
  public addedDateDatePickerConfig: DatePickerConfig;
  drugAllergyIndicatorReadOnly: boolean = false;
  user: any;
  isEditable = true;
  notificationMsg : string ;

  buttonData: any = [{
    isVisible: true,
    description: 'Delete',
    code: 'DELETE'
    } 
    ]
  constructor(
    private akitaAllergyGroupQuery: AkitaAllergyGroupQuery,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,    
    public store: StoreService,    
    private akitaClinicQuery: AkitaClinicQuery,
  ) {
    this.addedDateDatePickerConfig = new DatePickerConfig(
      '',
      new Date(),
      null,
      'bottom',
      'none'
    );
  }

  ngOnInit() {   
    this.user = this.store.getUser();
    this.selectReactionType(this.form)
    this.filterDrugAllergyIndicatorCodes();
    this.detectTypechanges();
    this.detectAllergyTypechanges();
    this.subscribeUpdateDate();
  }


  validateformEditablity() {
    if (!this.form.get('addedByClinicId').value) {
      return;
    }
    
    if (this.store.getClinicId() != this.form.get('addedByClinicId').value) {    
      const type = this.form.get('allergyType').value;
      if(type === 'FOOD' || type === 'OTHER') {
        return;
      }

      this. notificationMsg = 'This record is not editable as it is added by another clinic (' + this.getClinic(this.form.get('addedByClinicId').value) + ')';
      this.isEditable = false;
      this.form.get('addedDate').disable();
      this.form.get('addedDate').updateValueAndValidity({emitEvent:false})
    }

  }

  getClinic(clinicId: string) {
    if (!!clinicId) {
      const clinic = this.akitaClinicQuery.getEntity(clinicId) as Clinic
      return clinic && clinic.clinicCode;
    } else {
      return '';
    }
  }

  public detectAllergyTypechanges():void{
    this.form.get('allergyType').valueChanges.subscribe({
      next: result => {
        if(result === 'FOOD' || result === 'OTHER') {          
          this.form.get('adverseReaction').clearValidators();
          this.form.get('drugAllergyIndicatorCode').patchValue(undefined);
          this.form.get('drugAllergyIndicatorCode').setValidators(null);
          this.form.get('drugAllergyIndicatorCode').updateValueAndValidity();

          this.form.get('infoSourceCode').setValidators(null);
          this.form.get('infoSourceCode').updateValueAndValidity();

          this.form.get('drugProbability').setValidators(null);
          this.form.get('drugProbability').updateValueAndValidity();

          this.form.get('reactionOutcomeList').setValidators(null);
          this.form.get('reactionOutcomeList').updateValueAndValidity();

          this.form.get('reporter').get('regNo').setValidators(null);
          this.form.get('reporter').get('regNo').updateValueAndValidity();

          const name = this.user?.firstName +" "+ this.user?.lastName
          this.form.get('reporter').get('name').patchValue(name);
          this.drugAllergyIndicatorReadOnly = true;
        } else {          
          this.form.get('adverseReaction').setValidators(Validators.required);
          
          this.form.get('infoSourceCode').setValidators(Validators.required);
          this.form.get('infoSourceCode').updateValueAndValidity();

          this.form.get('drugProbability').setValidators(Validators.required);
          this.form.get('drugProbability').updateValueAndValidity();

          this.form.get('reactionOutcomeList').setValidators(Validators.required);
          this.form.get('reactionOutcomeList').updateValueAndValidity();

          this.form.get('drugAllergyIndicatorCode').setValidators(Validators.required);
          this.form.get('drugAllergyIndicatorCode').updateValueAndValidity();

          this.form.get('reporter').get('regNo').setValidators(Validators.required);
          this.form.get('reporter').get('regNo').updateValueAndValidity();

          if (this.form.get('type').value && this.form.get('type').value === 'ALLERGY') {
            this.form.get('drugAllergyIndicatorCode').patchValue('YES');
            this.drugAllergyIndicatorReadOnly = true;
          } else if (this.form.get('drugAllergyIndicatorCode').value === 'YES')  {
            this.form.get('drugAllergyIndicatorCode').patchValue(undefined);
          }         
        }
        this.form.get('adverseReaction').updateValueAndValidity();
      }
    });
  }

  public detectTypechanges():void{
    this.form.get('type').valueChanges.subscribe({
      next: result => {
        if(result === 'ALLERGY') {          
          this.form.get('additionalNames').patchValue(null);
          this.form.get('allergyType').patchValue(undefined);
        }
        this.form.get('additionalNames').updateValueAndValidity();
        this.form.get('allergyType').updateValueAndValidity();
        this.alertFormGroup.markAllAsTouched();
      }
    });
  }

  updateDetailsPanelVisibility() {
    this.alertFormGroup.markAllAsTouched();
    this.alertFormGroup.updateValueAndValidity();
    this.form.get("showDetails").patchValue(!this.form.get('showDetails').value);
  }

  clearAllergyToField() {
    this.form.get('name').patchValue('', { emitEvent: false });
  }

  clearDrugAllergyIndicator() {
  }

  clearInfoSourceCode() {
  }

  clearReactionOutcome() {

  }
  clearDrugProbability() {
    
  }

  clearAdverseReactions() {

  }

  onDelete() {

    const initialState = {
      isModal: true,
      displayMsg: 'Do you want to Delete the Selected Allergy?',
      cancelBtnText: 'Cancel',
      cancelBtnShow: true,
      buttonData: this.buttonData,
      title: 'Delete  Allergy',
    };

    this.bsModalRef = this.modalService.show(CustomConfirmPopupComponent, {
      initialState,
      class: 'modal-md',
      backdrop: 'static',
      keyboard: false,
    });
    this.bsModalRef.content.closeBtnName = 'Close';

    this.bsModalRef.content.closeEvent.subscribe(data => {
      this.bsModalRef.hide();
      this.bsModalRef.content.closeEvent.unsubscribe();
    });

    this.bsModalRef.content.customBtnEvent.subscribe(data => {
      if (data === 'DELETE') {
        const formArray = this.form.parent as FormArray;
        formArray.removeAt(this.index);
      }

      this.bsModalRef.hide();
      this.bsModalRef.content.customBtnEvent.unsubscribe();      
    });
    
  }

  getAllergyGroup() {
    return this.akitaAllergyGroupQuery.allergyGroupList$;
  }

  getDrugList() {
    return this.akitaChargeItemQuery.drugChargeItemList$;
  }

  updateLatestDate() {
    this.form.get('addedDate').patchValue(moment().format(DISPLAY_DATE_FORMAT));
  }

  subscribeUpdateDate() {
    this.form.get('addedDate').valueChanges
    .pipe(pairwise())
    .subscribe(([previousDate, newDate]) => {
      (previousDate!==newDate) ? this.form.get('addedDate').patchValue(moment(newDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT), {emitEvent: false}) :  null;
    });   
  }

  selectReactionType(form) {
    if (form.get('type').value && form.get('type').value === 'ADVERSE_DRUG_REACTION') {
      this.allergyTypes = form.value.allergyTypes.value;
      form.get('allergyType').patchValue('GENERIC_DRUG');
      this.filterDrugAllergyIndicatorCodes();
      this.form.get('allergyType').updateValueAndValidity();
    }

    if (form.get('type').value && form.get('type').value === 'ALLERGY') {
      this.allergyTypes = form.value.allergyTypes.value.filter(type => type.value != 'GENERIC_DRUG');
      this.filterDrugAllergyIndicatorCodes();
    }
  }

  onAdministratorTypeSelect(option, form) {
    
    form.get('reporter').get('name').patchValue('');
    form.get('reporter').get('regNo').patchValue('');
    if (option) {
      this.selectedAdminTypeCode = option.value;
    }
  }

  onDoctorChange(event, form) {
    if (event) {
      form.get('reporter').get('name').patchValue(event.displayName);
      form.get('reporter').get('regNo').patchValue(event.mcr);
    } else {
      form.get('reporter').get('name').patchValue('');
      form.get('reporter').get('regNo').patchValue('');
    }
  }

  onDiagnosisSelected(event, formGroup){
    formGroup.get('itemId').patchValue(event.id ? event.id : '');
  }

  onAdditionalNamesSelected(event, formGroup){
  }

  filterDrugAllergyIndicatorCodes() {
    if (this.form.get('type').value && this.form.get('type').value === 'ALLERGY') {
      this.filteredDrugAllergyIndicatorCodes = this.drugAllergyIndicatorCodes;

      if (this.form.value.isNewObject) {
        this.form.get('drugAllergyIndicatorCode').patchValue('YES');        
      }

      this.drugAllergyIndicatorReadOnly = true;
      this.validateformEditablity();
      return;
    }
    this.drugAllergyIndicatorReadOnly = false;
    this.filteredDrugAllergyIndicatorCodes = this.drugAllergyIndicatorCodes.filter(item => item.cmsCode != 'YES');
                
    this.validateformEditablity();
  }
}
