import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { StoreService } from '../../../../../../services/store.service';

import { ExercisePlanWorkflow } from '../../../../../../model/care-plan/CarePlanExerciseRecommendation';

@Component({
  selector: 'app-exercise-recommendation',
  templateUrl: './exercise-recommendation.component.html',
  styleUrls: ['./exercise-recommendation.component.scss']
})
export class ExerciseRecommendationComponent implements OnInit, OnChanges {

  @Input() carePlanFormGroup: FormGroup;
  @Input() existingData: Array<any> = [];

  public exerciseRecommendationList: Array<ExercisePlanWorkflow>;

  get exerciseRecommendationListArray(): FormArray {
    return this.carePlanFormGroup.get('exerciseRecommendationList') as FormArray;
  }

  constructor(
    readonly storeService: StoreService,
  ) { }

  ngOnInit(): void {
    this.initPlan();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initPlan();
  }

  private initPlan(): void {
    this.exerciseRecommendationList = [];
    const exerciseExistingData = this.existingData?.find(item => item.workflow.title === 'Exercise Recommendation');

    this.exerciseRecommendationList = this.storeService.originalHsgExerciseRecommendations.map(workflow => {
      return ExercisePlanWorkflow.adapt(workflow, exerciseExistingData?.values);
    });

    this.exerciseRecommendationListArray.clear();

    const collectFormGroups = (workflow: ExercisePlanWorkflow) => {
      if (workflow.bodySectionFormGroup) {
        this.exerciseRecommendationListArray.push(workflow.bodySectionFormGroup);

        if (workflow.commentFormGroup) {
          this.exerciseRecommendationListArray.push(workflow.commentFormGroup);
        }
      }

      if (workflow.subWorkflowList && workflow.subWorkflowList.length > 0) {
        workflow.subWorkflowList.forEach(subworkflow => collectFormGroups(subworkflow));
      }
    };

    this.exerciseRecommendationList.forEach(item => collectFormGroups(item));
  }

  public onCheck(checked: boolean, workflow: ExercisePlanWorkflow): void {
    if (checked) {
      workflow.bodySectionFormGroup.enable();
      if (workflow.comment) workflow.commentFormGroup.enable();
    } else {
      workflow.bodySectionFormGroup.disable();
      if (workflow.comment) workflow.commentFormGroup.disable();
    }

    const selectedFormControl = workflow.commentFormGroup.get('selected');
    if (selectedFormControl) {
      selectedFormControl.enable();
    }
  }

  public onRadio(checked: boolean, workflowList: Array<ExercisePlanWorkflow>, index: number): void {
    workflowList.forEach((workflow, i) => {
      if (i == index) {
        workflow.bodySectionFormGroup.enable();
        if (workflow.comment) workflow.commentFormGroup.enable();
      } else {
        workflow.bodySectionFormGroup.disable();
        if (workflow.comment) workflow.commentFormGroup.disable();
      }
      workflow.bodySectionFormGroup.get('selected').enable();
    });
  }
}