import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GO_BACK_CONFIRMATION_MESSAGE, LEAVE_PLAN_TITLE, PatientConditions } from './CarePlanConstants';
import { CarePlanService } from '../services/care-plan.service';

import { BsModalService } from 'ngx-bootstrap';

import { AkitaCarePlanQuery } from '../../../../../services/states/care-plan/akita-care-plan.query';
import { AkitaPCNVisitQuery } from '../../../../../services/states/akita-pcn-visit-case.query';
import { AkitaCaseVisitQuery } from '../../../../../services/states/akita-case-visit.query';

import { ICarePlan } from '../../../../../model/care-plan/CarePlan';

import { CarePlanConfirmationModalComponent } from './care-plan-confirmation-modal/care-plan-confirmation-modal.component';
import { ViewCarePlanComponent } from './view-care-plan/view-care-plan.component';
import { IquitComponent } from '../iquit/iquit.component';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.scss']
})
export class CarePlanComponent implements OnInit {

  @Input() isInConsultation = false;

  public showListing: boolean = true;

  public carePlanFormGroup: FormGroup;
  public selectedPlan: any;

  public PatientConditions = PatientConditions;
  private selectedPatientConditions = [];

  constructor(
    public router: Router,
    private modalService: BsModalService,
    private carePlanService: CarePlanService,
    private akitaPCNVisitQuery: AkitaPCNVisitQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaCarePlanQuery: AkitaCarePlanQuery
  ) { }

  ngOnInit(): void {
    this.akitaPCNVisitQuery.getSelectedPCNVisitMedicalCondition().subscribe(result => {
      this.selectedPatientConditions = result;
    });

    this.akitaCarePlanQuery.selectAll().subscribe(result => {
      const draftFound = result.find((item: ICarePlan) => item.healthPlanStatus === 'DRAFT') as ICarePlan;

      if (draftFound && this.carePlanFormGroup) {
        this.carePlanFormGroup.get('id').patchValue(draftFound.id);
      }
    });
  }

  private initCarePlanFormGroup(healthPlanStatus: string): void {
    this.carePlanFormGroup = this.carePlanService.getCarePlanFormGroup();

    // Patch id
    if (healthPlanStatus !== 'NEW') {
      this.carePlanFormGroup.get('id').patchValue(this.selectedPlan.id);
    }

    // Patch status
    this.carePlanFormGroup.get('healthPlanStatus').patchValue(healthPlanStatus);

    const visitIdFormControl = this.carePlanFormGroup.get('visitId') as FormControl;
    // Patch visitId
    if (healthPlanStatus === 'NEW') {
      visitIdFormControl.patchValue(this.akitaCaseVisitQuery.getActiveVisitId())
    } else {
      visitIdFormControl.patchValue(this.selectedPlan.visitId)
    }

    // Patch patient conditions
    this.patchPatientConditions(healthPlanStatus);
  }

  private patchPatientConditions(healthPlanStatus: string): void {
    const cmsConditionsForHSGFormArray = this.carePlanFormGroup.get('cmsConditionsForHSG') as FormArray;

    const selectedPatientConditionCodes = this.selectedPatientConditions.map(item => item.code);

    this.PatientConditions.forEach(condition => {
      const selectedFormControl = new FormControl(false);

      // If new plan, take conditions from patient profile
      if (healthPlanStatus === 'NEW') {
        if (selectedPatientConditionCodes.includes(condition.code)) {
          selectedFormControl.patchValue(true);
        }
      } else {
        const mergedUniqueArray = [...new Set([...selectedPatientConditionCodes, this.selectedPlan.cmsConditionsForHSG])];
        // If DRAFT or FAILED, take conditoins from the response 
        if (mergedUniqueArray.includes(condition.code)) {
          selectedFormControl.patchValue(true);
        }
      }

      cmsConditionsForHSGFormArray.push(new FormGroup({
        code: new FormControl(condition.code),
        goalId: new FormControl(condition.goalId),
        selected: selectedFormControl,
      }));

      cmsConditionsForHSGFormArray.disable();
    });
  }

  public onViewEditCarePlan(carePlan: any): void {
    if (!carePlan) {
      this.selectedPlan = undefined;
      this.carePlanService.removeFormGroup();
      return;
    }

    if (carePlan.healthPlanStatus === 'SUBMIT') {
      const initialState = {
        selectedPlan: carePlan
      }

      let bsModalRef = this.modalService.show(ViewCarePlanComponent, {
        initialState,
        class: 'modal-lg custom-modal',
        backdrop: 'static',
        keyboard: false,
      });
    }

    if (carePlan.healthPlanStatus === 'DRAFT' || carePlan.healthPlanStatus === 'FAILED') {
      this.showListing = false;
      this.selectedPlan = carePlan;
      this.carePlanService.initCarePlanFormGroup();
      this.initCarePlanFormGroup(carePlan.healthPlanStatus);
    }
  }

  public onCreatePlan() {
    this.selectedPlan = undefined;
    this.carePlanService.initCarePlanFormGroup();
    this.initCarePlanFormGroup('NEW');

    this.carePlanFormGroup.get('healthPlanStatus').patchValue('NEW');
    this.showListing = false;
  }

  public copyLastGoal(): void {
    const lastGoal = this.akitaCarePlanQuery.getLastGoal();

    if (lastGoal) {
      this.selectedPlan = lastGoal;
    }
  }

  public onGoBack(): void {
    if (this.isInConsultation) {
      const initialState = {
        title: LEAVE_PLAN_TITLE,
        message: GO_BACK_CONFIRMATION_MESSAGE,
        buttonConfig: {
          isCancel: true,
          isConfirm: true,
        }
      };

      const bsModalRef = this.modalService.show(CarePlanConfirmationModalComponent, {
        initialState,
        class: 'modal-md custom-modal',
        backdrop: 'static',
        keyboard: false,
      });

      bsModalRef.content.event.subscribe(data => {
        bsModalRef.hide();
        this.showListing = true;

        if (this.carePlanFormGroup.get('healthPlanStatus').value === 'NEW') {
          this.selectedPlan = undefined;
          this.carePlanService.removeFormGroup();
        }
      });
    }
  }

  public onViewQuestionnaireResponses(): void {
    const modal = this.modalService.show(IquitComponent, {
      class: 'modal-lg custom-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  public onSyncPlan(): void {
    // TO-DO-SM: Check this
    // this.patientHSGService.setSyncPlans(true);
    // this.patientHSGService.setSyncVaccineAndSFL(true);
  }
}