<div class="row mb-2">
    <div class="col-md-12">
        <div class="col-md-12" style="text-align: right;">
            <span>Last sycned : {{plansSyncTime}}</span>
        </div>
        <div class="col-12">
            <table class="custom-table">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Plan date</th>
                        <th>Next appt.</th>
                        <th>Clinic</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let plan of plans; let i = index;">
                        <td>{{ i + 1 }}</td>
                        <td>{{plan.planDate}}</td>
                        <td>{{plan.nextCheckUpDate}}</td>
                        <td>{{plan.clinicName}}</td>
                        <td>{{plan.healthPlanStatus}}</td>
                        <td>
                            <div *ngIf="plan.showActions" class="wrapper-table-action" dropdown container="body" [autoClose]="true">
                                <img src="/assets/svg/left-panel/settings-primary.svg" class="img-table-action" dropdownToggle>

                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu action-drop-down" role="menu"
                                    aria-labelledby="basic-link">
                                    <li (click)="onViewEdit(plan)">
                                        <a class="dropdown-item btn-pointer">
                                            {{plan.healthPlanStatus === 'SUBMIT' ? 'View' : 'Edit'}} Plan
                                        </a>
                                    </li>
                                    <li *ngIf="!plan.hsgId" role="menuitem" (click)="onPrintPlan(plan)">
                                        <a class="dropdown-item btn-pointer">
                                            {{plan.healthPlanStatus === 'SUBMIT' ? 'Print' : 'Print Draft'}}
                                        </a>
                                    </li>
                                    <li role="menuitem" *ngIf="plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED'"
                                        (click)="onDeletePlan(plan)">
                                        <a class="dropdown-item btn-pointer">
                                            Delete Plan
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>

                        <!-- TO-DO-SM: Double check the following -->
                        <!-- <td>{{ getDateString(plan.visitDate || plan.submittedDateTime) }}</td> -->
                        <!-- <td>{{ getDateString(plan.nextCheckUpDate) }}</td> -->
                        <!-- <td>{{ plan.hsgId ? plan.enrolledClinicName : getClinicName(plan.clinicId) }}</td> -->
                        <!-- <td>{{ plan.healthPlanStatus }}</td> -->
                        <!-- <td (click)="$event.stopPropagation()">
                            <div *ngIf="plan.healthPlanStatus !== 'NEW' && (!plan.hsgId || (plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED'))"
                                class="wrapper-table-action" dropdown container="body" [autoClose]="true">
                                <img src="/assets/svg/left-panel/settings-primary.svg" class="img-table-action" dropdownToggle>
                                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu action-drop-down" role="menu"
                                    aria-labelledby="basic-link">
                                    <li *ngIf="!plan.hsgId" role="menuitem" (click)="onPrintPlan(plan)">
                                        <a class="dropdown-item btn-pointer">
                                            {{plan.healthPlanStatus === 'SUBMIT' ? 'Print' : 'Print Draft'}}
                                        </a>
                                    </li>
                                    <li role="menuitem" *ngIf="plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED'"
                                        (click)="onDeletePlan(plan)">
                                        <a class="dropdown-item btn-pointer">
                                            Delete Plan
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>