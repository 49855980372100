export default class PrimaryCareNetwork {
  optIn: boolean;
  optOut: boolean;
  optInDate: string;

  constructor(optIn?: boolean, optOut?: boolean, optInDate?: string) {
    this.optIn = optIn === undefined ? false : optIn;
    this.optOut = optOut === undefined ? false : optOut;
    this.optInDate = optInDate || '';
  }
}
