

import { DISPLAY_DATE_FORMAT } from './../constants/app.constants';
import { ContactPerson, Plan, Address, createPlan, createAddress } from './Plan';
import * as moment from 'moment';
import { INVENTORY_DATE_FORMAT } from '../constants/app.constants';
import { CorpUserId, createCorpUserId } from './Appointment';

export interface PatientCoverage {
  balance: number;
  costCenter: string;
  coverageSelected: MedicalCoverage;
  coverageType: string;
  coverageInactive: boolean;
  endDate: string;
  isDuplicate: boolean;
  isNew: boolean;
  isSelected: boolean;
  id: string;
  medicalCoverageId: string;
  patientCoverageId: string;
  planId: string;
  planSelected: Plan;
  policyHolderExpired: boolean;
  policyExpired: boolean;
  policyExcluded: boolean;
  planInactive: boolean;
  planExpired: boolean;
  policyInactive: boolean;
  remarks: string;
  status: string;
  selectionOrder: number;
  startDate: string;
  corpIdentificationNumber: CorpUserId;
  sflStatus?: any;
}

export function createPatientCoverage(
  balance?: number,
  costCenter?: string,
  coverageSelected?: MedicalCoverage,
  coverageType?: string,
  coverageInactive?: boolean,
  endDate?: string,
  isDuplicate?: boolean,
  isNew?: boolean,
  isSelected?: boolean,
  id?: string,
  medicalCoverageId?: string,
  patientCoverageId?: string,
  planId?: string,
  planSelected?: Plan,
  policyHolderExpired?: boolean,
  policyExpired?: boolean,
  policyExcluded?: boolean,
  planInactive?: boolean,
  planExpired?: boolean,
  policyInactive?: boolean,
  remarks?: string,
  status?: string,
  selectionOrder?: number,
  startDate?: string,
  name?: string,
  corpIdentificationNumber?: CorpUserId,
  sflStatus?: any
): PatientCoverage {
  return <PatientCoverage>{
    balance: balance !== undefined ? balance : -1,
    costCenter: costCenter || '',
    coverageSelected: coverageSelected === undefined ? createMedicalCoverage() : coverageSelected,
    coverageType: coverageType || '',
    coverageInactive: coverageInactive === undefined ? false : coverageInactive,
    endDate: formatDate(endDate) || '',
    isDuplicate: isDuplicate === undefined ? false : isDuplicate,
    isNew: isNew === undefined ? true : isNew,
    isSelected: isSelected === undefined ? false : isSelected,
    id: id === undefined ? '' : id,
    medicalCoverageId: medicalCoverageId || '',
    patientCoverageId: patientCoverageId || '',
    planId: planId || '',
    planSelected: planSelected === undefined ? createPlan() : planSelected,
    policyHolderExpired: policyHolderExpired === undefined ? false : policyHolderExpired,
    policyExpired: policyExpired === undefined ? false : policyExpired,
    policyExcluded: policyExcluded === undefined ? false : policyExcluded,
    planInactive: planInactive === undefined ? false : planInactive,
    planExpired: planExpired === undefined ? false : planExpired,
    policyInactive: policyInactive === undefined ? false : policyInactive,
    remarks: remarks || '',
    selectionOrder: selectionOrder || -1,
    startDate: formatDate(startDate) || '',
    status: status || 'ACTIVE',
    name: name || '',
    corpIdentificationNumber: corpIdentificationNumber === undefined ? createCorpUserId() : corpIdentificationNumber,
    sflStatus: sflStatus || undefined
  };
}

export interface MedicalCoverage {
  accountManager: string;
  address: Address;
  allowedAccessLevel: Array<string>;
  code: string;
  contacts: ContactPerson[];
  costCenter?: string;
  costCenters?: Array<any>;
  coveragePlans?: Plan[];
  creditTerms: number;
  endDate: string;
  id: string;
  medicalRefillAllowed: boolean;
  name: string;
  payAtClinic?: boolean;
  policyHolderCount?: number;
  showDiscount: boolean;
  showMemberCard: boolean;
  startDate: string;
  status?: string;
  trackAttendance: boolean;
  type: string;
  usePatientAddressForBilling: boolean;
  website: string;
  rpaEnabled: boolean;
  sflStatus?: any;
}

export function createMedicalCoverage(value?): MedicalCoverage {
  const accountManager = value && value.accountManager ? value.accountManager : '';
  const address = value && value.address ? value.address : createAddress();
  const allowedAccessLevel = value && value.allowedAccessLevel ? value.allowedAccessLevel : new Array<string>();
  const code = value && value.code ? value.code : '';
  const contact = value && value.contact ? value.contact : new Array<ContactPerson>();
  const costCenters = value && value.costCenters ? value.costCenters : new Array<string>();
  const coveragePlans = value && value.coveragePlans ? value.coveragePlans : new Array<Plan>();
  const creditTerms = value && value.creditTerms ? value.creditTerms : 0;
  const endDate = value && value.endDate ? formatDate(value.endDate) : '';
  const id = value && value.id ? value.id : '';
  const medicalRefillAllowed = value && value.medicalRefillAllowed ? value.medicalRefillAllowed : false;
  const name = value && value.name ? value.name : '';
  const payAtClinic = value && value.payAtClinic ? value.payAtClinic : false;
  const policyHolderCount = value && value.policyHolderCount ? value.policyHolderCount : 0;
  const showDiscount = value && value.showDiscount ? value.showDiscount : false;
  const showMemberCard = value && value.showMemberCard ? value.showMemberCard : false;
  const startDate = value && value.startDate ? formatDate(value.startDate) : '';
  const status = value && value.status ? value.status : 'ACTIVE';
  const trackAttendance = value && value.trackAttendance ? value.trackAttendance : false;
  const type = value && value.type ? value.type : '';
  const usePatientAddressForBilling =
    value && value.usePatientAddressForBilling ? value.usePatientAddressForBilling : false;
  const website = value && value.website ? value.website : '';
  const sflStatus =  value && value.sflStatus || undefined;
  return {
    accountManager: accountManager,
    address: address,
    allowedAccessLevel: allowedAccessLevel,
    code: code,
    contacts: contact,
    costCenters: costCenters,
    coveragePlans: coveragePlans,
    creditTerms: creditTerms,
    endDate: endDate,
    id: id,
    medicalRefillAllowed: medicalRefillAllowed,
    name: name,
    payAtClinic: payAtClinic,
    policyHolderCount: policyHolderCount,
    showDiscount: showDiscount,
    showMemberCard: showMemberCard,
    startDate: startDate,
    status: status,
    trackAttendance: trackAttendance,
    type: type,
    usePatientAddressForBilling: usePatientAddressForBilling,
    website: website,
    sflStatus: sflStatus
  } as MedicalCoverage;
}

export interface IncludedMedicalServiceScheme {
  medicalServiceItemID: string;
}

export function formatDate(date) {
  if (moment(date, INVENTORY_DATE_FORMAT, true).isValid()) {

    return moment(date, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
  } else if (moment(date, DISPLAY_DATE_FORMAT, true).isValid()) {

    return date;
  }
}


// export class MedicalCoverageByCaseRes {
//   coverage: Array<MedicalCoverage>;
//   limit: { [id: string]: number };
//   policyHolder: PolicyHolderInfo[];



// Class
