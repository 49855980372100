import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

import { CanComponentDeactivate } from './can-deactivate-guard.service';
import { InventoryCountDetailComponent } from '../views/components/inventory/inventory-count-detail/inventory-count-detail.component';

@Injectable({ providedIn: 'root' })

export class InventoryCanDeactivateGuardService implements CanDeactivate<CanComponentDeactivate> {

  constructor(
  ) {  }

  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    if (component instanceof InventoryCountDetailComponent) {
      const inventoryComponent = <InventoryCountDetailComponent> component;
      if (inventoryComponent.dirtyStockTake) {
        return confirm( `Your changes have not been saved.\n\nWould you like to discard changes?`);
      }
      return true;
    }
    return true;
  }
}
