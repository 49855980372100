<div bsModal #modalone="bs-modal">
    <div class="modal-header" #modal>
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="col-md-12">
            <p>{{displayMsg}}</p>
        </div>
        <div class="row flex-row-reverse">
            <div  class="col-md-2 m-1 p-0" *ngFor="let button of buttonData; let i = index;">
                <button *ngIf="button.isVisible" [ngClass]="button.buttonClass ? button.buttonClass : 'btn btn-brand-primary w-100'" (click)="onButtonClick(button.code)">{{button.description}}</button>
            </div>
            <div *ngIf="cancelBtnShow" class="col-md-2 m-1 p-0"><button class="btn btn-brand-info text-white w-100" (click)="onNo()">{{cancelBtnText}}</button></div>
        </div>
    </div>
</div>