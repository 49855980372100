import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { BsModalRef, ModalDirective, ModalOptions } from 'ngx-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  title = 'Confirm';
  messages = ['Are you sure ?'];
  afterClose: EventEmitter<boolean>;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.afterClose = new EventEmitter<boolean>();
  }

  confirm(response: boolean) {
    this.bsModalRef.hide();
    this.afterClose.emit(response);
  }
}
