<div class="row align-items-center">
  <div class="col-8">

    <!-- Allergies -->
    <div id="allergies" class="d-flex flex-wrap overflow-auto" [style.height]="isAllergyCollapsed ? '1rem' : 'auto'"
      [style.word-break]="isAllergyCollapsed ? 'hidden': 'break-word'">
      <span><i
          [ngClass]="isAllergyCollapsed ? 'icon-down-open-big float-right mr-1':'icon-up-open-big float-right mr-1'"
          (click)="toggleAllergy()"></i></span>
      <span class="mr-1">DRUG ALLERGIES</span>
      <span class="list-inline-item text-pink"
        *ngFor="let alert of alertFormGroup.get('alertArray').value; let i = index">
        <span [popover]="popTemplate" placement="right" [outsideClick]="true" triggers="hover"
          *ngIf="alert.name.length > 0" [popoverContext]={remark:alert.remarks}>
          <ng-container *ngIf="alert.type === 'ALLERGY_GROUP'; else notAllergyGroup">{{ alert.name |
            displayAllergyGroupDesc }}</ng-container>
          <ng-template #notAllergyGroup>{{ alert.name }}</ng-template>
        </span>
      </span>
      <ng-container *ngTemplateOutlet="noElement;context:{array:alertFormGroup.get('alertArray')}"></ng-container>
    </div>

    <!-- Medical Alerts -->
    <div id="medicalAlerts" class="d-flex flex-wrap mt-1 overflow-auto"
      [style.height]="isClinicAlertCollapsed? '1rem': 'auto'"
      [style.word-break]="isClinicAlertCollapsed ? 'hidden': 'break-word'">
      <span *ngxPermissionsOnly="['ROLE_PATIENT_MEDICAL_ALERTS']">
        <i [ngClass]="isClinicAlertCollapsed ? 'icon-down-open-big float-right mr-1':'icon-up-open-big float-right mr-1'"
          (click)="toggleAlerts()"></i>
      </span>
      <span class="pr-1">CLINIC ALERTS</span>
      <span class="list-inline-item text-squash"
        *ngFor="let alert of medicalAlertFormGroup.get('alertArray').value; let i = index">
        <span *ngIf="alert.priority && alert.priority === 'HIGH' && (alert.alertStatus && alert.alertStatus != 'INACTIVE')" class="icon-up text-high-priority-green"></span>
        <span *ngIf="alert.priority && alert.priority === 'LOW' && (alert.alertStatus && alert.alertStatus != 'INACTIVE')" class="icon-down text-maroon"></span>
        <span [popover]="popTemplate" placement="right" [outsideClick]="true" triggers="hover"
          *ngIf="alert.name && alert.name.length>0 && alert.alertId !== '' && (alert.alertStatus && alert.alertStatus != 'INACTIVE')" container="body" class="text-squash"
          [popoverContext]={remark:alert.remark}>
          {{ alert.name }}
        </span>
      </span>
      <ng-container *ngTemplateOutlet="noElement;context:{array:medicalAlertFormGroup.get('alertArray').value}">
      </ng-container>
    </div>
  </div>

  <div class="col-4" dropdown container="body" [autoClose]="true">
    <button class="btn btn-outline-brand-secondary btn-sm text-nowrap" (click)="onAddMedicalAlertAndAllergy()">Manage Allergy/Alert</button>
    
    <!-- <button class="btn btn-md btn-secondary dropdown-toggle" value="value " dropdownToggle
      aria-controls="Alert/Allergies">Alerts / Allergies</button>
    <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu" role="menu " aria-labelledby="basic-link">
      <li class="menuitem" (click)="onAddAlert()"><a class="dropdown-item" href="javascript:void(0);">Allergies</a></li>
      <li class="divider dropdown-divider m-0"></li>
      <li *ngxPermissionsOnly="ROLE_PATIENT_MEDICAL_ALERTS_MODIFY" class="menuitem" (click)="onAddMedicalAlertAndAllergy()"><a
          class="dropdown-item" href="javascript:void(0);">Alerts</a></li>
    </ul> -->
  </div>

</div>



<!-- Templates -->
<ng-template #popTemplate let-remark="remark">
  <ng-container *ngIf="!remark || remark.length === 0; else elseTemplate">
    <i class="text-gray-450">No Remarks</i>
  </ng-container>
  <ng-template #elseTemplate>
    {{ remark }}
  </ng-template>
</ng-template>

<ng-template #noElement let-array="array">
  <span *ngIf="array.length === 0">
    <li class="list-inline-item text-gray-450">None</li>
  </span>
</ng-template>

