import { PatientCoverage } from './../../../../objects/MedicalCoverage';
import { MedicalCoverageWithBalance } from './../../../../objects/response/MedicalCoverageResponse';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patientMedicalCoverage' })
export class PatientMedicalCoverageStore extends EntityStore<PatientCoverage> {
  constructor() {
    super();
  }

  addNewData(item) {
    this.add(item);
  }
}
