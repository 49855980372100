export default class HSGPlan {
    id: string;
    hsgId?: string;
    visitId?: string;
    patientId: string;
    clinicId: string;
    enrolledClinicHeCode?: string;
    enrolledClinicName?: string;
    submittedDateTime: string;
    nextCheckUpDate: string;
    recordOfDiscussion: string;
    selected?: boolean;
    healthGoals: HSGGoal[];
    healthPlanStatus: string;
    cmsConditionsForHSG: string[];
    responseStatus: string;
    webserviceResponse: WebserviceResponse;
    createdDate: string;
    nextCheckUpAppointmentId: string;

    constructor() {}
}

export class HSGGoal {
    goalId?: string;
    workflow: {
        flow: string;
        title: string;
        body: string;
        sections: HSGWorkflowSection[];
        enabled: boolean;
        hsgMappingCode: string;
        subWorkflow: {
            body: string;
            sections: HSGWorkflowSection[];
            hsgMappingCode: string;
        }
    };
    values?: any;

    constructor( goalId: string, workflow: any, values: any) {
        this.goalId = goalId;
        this.workflow = workflow;
        this.values = values;
    }
}

interface HSGWorkflowSection {
    number: string;
    placeholder?: string;
    input: 'input' | 'input-dropdown' | 'date-picker' | 'multi-choice';
    label: string;
    uom?: string;
    provider: 'static' | 'dynamic';
    data?: {
        key: string;
        value: string
    }[];
}

export interface ViewHSGPlanGoal {
    number: string;
    goal: string;
    target: string,
    subTarget: string,
}

export interface WebserviceResponse {
    submissionStatusCode: string;
    submissionStatusDescr: string;
    responseStatusCode: string;
    responseStatusDescr: string;
    capturedDate: string;
}

export interface HSGDashboard {
    countByStatus: {id: string; count: string}[];
    monthlyEnrolmentCount: any;
    monthlyNewCount: any;
    monthlyContactedCount: any;
    monthlyFirstVisitCount: any;
}

export interface Goal {
    id: string; 
    goal: any; 
    target: any;
    subTarget: any;
    enableSubGoals: boolean;
    values: any;
    workflow: any;
  }