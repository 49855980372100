import { AppointmentsFactoryService } from './../../../../services/appointments-factory.service';
import {
  CLINIC_HOLIDAY,
  CLINIC_BLOCKED_TIME,
} from './../../../../constants/app.constants';
import { map } from 'rxjs/operators';
import { CalendarAppointment } from './../../../../objects/CalendarAppointment';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  ClinicCalendarStore,
  ClinicCalendarState,
} from './clinic-calendar.store';
import { BlockDate, ClinicCalendar } from '../../../../objects/ClinicCalendar';

@Injectable({ providedIn: 'root' })
export class ClinicCalendarQuery extends Query<ClinicCalendarState> {
  clinicSelected$ = this.select(state => state);
  clinicToCalendarAppointments$ = this.select().pipe(
    map((clinic: ClinicCalendar) => {
      return this.setCalendarEvents(
        clinic.clinicHolidays,
        CLINIC_HOLIDAY
      ).concat(
        this.setCalendarEvents(clinic.blockedTimes, CLINIC_BLOCKED_TIME)
      );
    })
  );

  constructor(
    protected store: ClinicCalendarStore,
    private apptFactory: AppointmentsFactoryService
  ) {
    super(store);
  }

  setCalendarEvents(blockedDates: BlockDate[], type) {
    const list: CalendarAppointment[] = [];
    if (blockedDates) {
      blockedDates.forEach((blockedDate: BlockDate) => {
        let blockDate = this.apptFactory.createBlockDate(blockedDate);
        list.push(
          this.apptFactory.createCalendarEvent(type, null, blockDate, null)
        );
      });
    }

    return list;
  }
}
