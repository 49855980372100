<form class="h-100">
    <div class="card scrollable-card h-100">
        <!-- <pre>{{ itemFormGroup.value | json }}</pre>
        <br> ongoing medications<pre>{{ patientInfo.onGoingMedications | json }}</pre> -->
        <div class="card-header">
            ONGOING MEDICATION
        </div>
        <div class="card-body">
            <!-- <div class="row mb-2 grey-header"> -->
            <div class="row mb-2 data-table">
                <div class="col-md-3">
                    <label class="mb-0">
                        Item Code <span class="compulsory">*</span>
                    </label>
                </div>
                <div class="col-md-4">
                    <label class="mb-0">
                        Medication Name
                    </label>
                </div>
                <div class="col-md-3">
                    <label class="mb-0">
                        Type <span class="compulsory">*</span>
                    </label>
                </div>
                <div class="col-md-2">
                    <label class="mb-0">
                        Start Date
                    </label>
                </div>
            </div>
            <span *ngFor="let item of basicInfoFormGroup.get('onGoingMedications')['controls']">
                <app-ongoing-medication-item [editMode]="editMode" [item]='item'></app-ongoing-medication-item>
            </span>
            <button *ngIf="editMode" type="button" class="btn btn-sm btn-outline-dark float-left" (click)="onBtnAddClicked()">
                + Add Another Row
            </button>
            <!-- <button class="btn btn-brand-primary btn-sm float-right" (click)="onBtnSaveClicked()" [disabled]="!basicInfoFormGroup.valid">Save</button> -->
        </div>
    </div>
</form>