import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
  name: 'inventoryDOReceiverName'
})
export class InventoryDOReceiverNamePipe implements PipeTransform {

  constructor(private store: StoreService) {}

  transform(value: any, args?: any): any {
    let isFromReturnRequest = args

    if (!value) {
      return '';
    }

    if (isFromReturnRequest) {
      return (this.store.getSupplierById(value) || { name: '' }).name;
    } else {
      const clinic = this.store.getClinicList().find(clinic => clinic.id === value);
      if (clinic) {
        return `${clinic.clinicCode} / ${clinic.name}`;
      }
    }

    return '';
  }

}
