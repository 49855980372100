import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-view-care-plan',
  templateUrl: './view-care-plan.component.html',
  styleUrls: ['./view-care-plan.component.scss']
})
export class ViewCarePlanComponent implements OnInit {

  public selectedPlan: any;

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    console.log(this.selectedPlan);
  }

  public onClose(): void {
    this.bsModalRef.hide();
  }
}