import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import {MedicalCoverage} from "../../objects/MedicalCoverage";

type SubsidyClass = "SDL" | "WAF";

interface UOMMatrix {
  [key: string]: number;
}

export interface WhitelistedItem {
  _id: string;

  itemRefId: string;
  chargeCode: string;
  subsidyClass: SubsidyClass;
  perUnitPriceCap: {
    price: number;
    taxIncluded: boolean;
  };
  baseUOM: string;
  uomMatrix: UOMMatrix;
  limitOnlyToHSG: boolean;
  cappedCoverages: string[];
  declarationRequired: boolean;
}

export function coverageCappedAllowed(whitelistedItem: WhitelistedItem, medicalCoverageId: string): boolean {
  if (!whitelistedItem.cappedCoverages || !whitelistedItem.cappedCoverages.length) return true;
  return whitelistedItem.cappedCoverages.includes(medicalCoverageId);
}

export interface AkitaWhitelistedItemState extends EntityState<WhitelistedItem> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'whitelistedItem', idKey: '_id' })
export class AkitaWhitelistedItemStore extends EntityStore<AkitaWhitelistedItemState> {

  constructor() {
    super();
  }

}
