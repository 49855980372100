import { DatepickerConfig } from 'ngx-bootstrap';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  DISPLAY_DATE_FORMAT,
  DB_FULL_DATE_FORMAT_NO_SECOND,
} from '../../constants/app.constants';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { INPUT_DELAY } from '../../constants/app.constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import DatePickerConfig from '../../objects/DatePickerConfig';

@Component({
  selector: 'app-primary-care-network',
  templateUrl: './primary-care-network.component.html',
  styleUrls: ['./primary-care-network.component.scss'],
})
export class PrimaryCareNetworkComponent implements OnInit, OnDestroy {
  @Input() primaryCareNetworkForm: FormGroup;
  @Input() readOnly: boolean;
  @Input() disabled: boolean;

  private componentDestroyed: Subject<void> = new Subject();

  enrolledDate: string;

  maxDate: Date;

  datePickerConfig: DatePickerConfig;

  constructor(private fb: FormBuilder) {
    // set max date for date picker to today only
    this.maxDate = new Date();
    this.datePickerConfig = new DatePickerConfig(
      '',
      this.maxDate,
      null,
      'top',
      'none'
    );
  }

  ngOnInit() {
    if (this.readOnly === undefined) {
      this.readOnly = true;
      // this.disabled = false;
    }
    // if (!this.disabled) {
    this.subscribeChanges();
    // } else {
    //   this.primaryCareNetworkForm.disable();
    // }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  private subscribeChanges() {
    this.primaryCareNetworkForm
      .get('optInDate')
      .valueChanges.pipe(
        debounceTime(INPUT_DELAY),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data => {
        if (data) {
          this.enrolledDate = data;
        }
      });

    this.primaryCareNetworkForm
      .get('optOut')
      .valueChanges.pipe(
        debounceTime(INPUT_DELAY),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data => {
        if (!data) {
          this.primaryCareNetworkForm.get('optIn').enable({ emitEvent: false });
          this.primaryCareNetworkForm
            .get('optInDate')
            .enable({ emitEvent: false });
        } else {
          this.primaryCareNetworkForm
            .get('optIn')
            .disable({ emitEvent: false });
          this.primaryCareNetworkForm
            .get('optIn')
            .patchValue(false, { emitEvent: false });
          this.primaryCareNetworkForm
            .get('optInDate')
            .disable({ emitEvent: false });
          this.primaryCareNetworkForm.get('optInDate').setValidators(null);
          this.primaryCareNetworkForm.get('optInDate').markAsTouched();
          this.primaryCareNetworkForm.get('optInDate').updateValueAndValidity();
        }
        if (this.disabled) {
          this.primaryCareNetworkForm.disable();
        }
      });

    this.primaryCareNetworkForm
      .get('optIn')
      .valueChanges.pipe(
        debounceTime(INPUT_DELAY),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data => {
        if (!data) {
          this.primaryCareNetworkForm
            .get('optOut')
            .enable({ emitEvent: false });
          this.primaryCareNetworkForm.get('optInDate').setValidators(null);
          this.primaryCareNetworkForm.get('optInDate').markAsTouched();
          this.primaryCareNetworkForm.get('optInDate').updateValueAndValidity();
        } else {
          this.primaryCareNetworkForm
            .get('optOut')
            .disable({ emitEvent: false });
          this.primaryCareNetworkForm
            .get('optOut')
            .patchValue(false, { emitEvent: false });
          this.primaryCareNetworkForm
            .get('optInDate')
            .setValidators([Validators.required]);
          this.primaryCareNetworkForm.get('optInDate').markAsTouched();
          this.primaryCareNetworkForm.get('optInDate').updateValueAndValidity();
        }
        if (this.disabled) {
          this.primaryCareNetworkForm.disable();
        }
      });
  }

  generateFormGroup() {
    if (!this.primaryCareNetworkForm) {
      this.primaryCareNetworkForm = this.fb.group({
        optIn: false,
        optOut: false,
        optInDate: '',
      });
    }
  }
}
