import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { Uom } from '../../objects/state/Uom';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'uom',
  idKey: 'uom',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaUomStore extends EntityStore<Uom> {
  constructor() {
    super();
  }

  addNewData(data) {
    this.remove();
    this.add(data);
  }
}
