export default interface ConsultationDocument {
  name: string;
  document: string;
  description: string;
  type: string;
  size: string;
  fileId: string;
  clinicId: string;
  uploadDate: string;
  uploader: string;
  fileName?: string;
} //fileName will be mapped to document

export function createConsultationDocument(
  consultationDocument: Partial<ConsultationDocument>
) {
  return {
    ...consultationDocument,
  } as ConsultationDocument;
}
