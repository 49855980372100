<div class="row mb-2" [formGroup]="item">
  <div class="col-md-3">
    <ng-select name="itemCode" class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width' [items]="items" bindLabel='code' bindValue='code' (change)="onDrugSelect($event)"
      [addTag]="false" [virtualScroll]="true" [typeahead]="codesTypeahead" appendTo="body" [clearable]=true placeholder="Type to Search For New Item..." formControlName="itemCode" (clear)="onClear()">
      <ng-template ng-header-tmp>
        <div class="row">
          <div class="col-md-4 font-weight-semi-bold">Code</div>
          <div class="col-md-6 font-weight-semi-bold">Name</div>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="row">
          <div class="col-md-4">{{item.code}}</div>
          <div class="col-md-6">{{item.name}}</div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="col-md-4"><input name="medicationName" class="form-control form-control-sm" formControlName="medicationName"></div>
  <div class="col-md-3">
    <ng-select name="type" class='p-0 form-control form-control-sm  custom-select' bindLabel='label' bindValue='value' [items]="issueType" [clearable]=false (change)="onTypeSelected($event)" formControlName='type'></ng-select>
    <errors [control]="item.get('type')"></errors>
  </div>
  <div class="col-md-2">
    <app-date-picker-with-icon [config]="datePickerConfig" [dateControl]="item.get('startDate')"></app-date-picker-with-icon>
  </div>
</div>
