<form [formGroup]="alertFormGroup" novalidate>
  <div style="overflow-x:auto;">
    <table class="custom-table custom-table-light">
      <thead>
        <tr>
          <th>Type <span class="compulsory">*</span></th>
          <th>Allergy Type <span class="compulsory">*</span></th>
          <th>Drug / Allergy To <span class="compulsory">*</span></th>
          <th>Additional Drugs</th>
          <th>Date Added</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngFor="let form of alertFormGroup.get('alertArray')['controls']; let i = index;">
        <app-patient-detail-tag-add-alert-item 
          *ngIf="enumDataLoaded"
          [form]="form" 
          [alertFormGroup]="alertFormGroup" 
          [index]="i"
          [infoSourseCodes]="infoSourseCodes" 
          [drugAllergyIndicatorCodes]="drugAllergyIndicatorCodes" 
          [reactionOutcomeCodes]="reactionOutcomeCodes" 
          [drugProbabilityCodes]="drugProbabilityCodes" 
          [adverseReactions]="adverseReactions"
          [administratorTypes]="administratorTypes" 
          [doctors]="doctors"
          [drugGenericCompounds]="drugGenericCompounds"
          >
        </app-patient-detail-tag-add-alert-item>
      </tbody>
    </table>

    <div class="row pt-2">
      <div class="col-md-12">
        <div class="btn-toolbar float-right" role="toolbar">
          <div class="btn-group" role="group">
            <button 
              class="button-green-inverse" 
              (click)="onBtnAdd()">
              + ADD ALLERGY/ADR
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>  