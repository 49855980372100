import { ApiCaseManagerService } from './../../../services/api-case-manager.service';
import { get } from 'lodash';
import {
  MedicalCoverageResponse,
  Insurance,
} from './../../../objects/response/MedicalCoverageResponse';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, FormArray } from '@angular/forms';
import * as moment from 'moment'
import { DISPLAY_DATE_FORMAT } from '../../../constants/app.constants';
import { StoreService } from '../../../services/store.service';
import { ApiPatientInfoService } from '../../../services/api-patient-info.service';
import { RpaRequestService } from "../../../services/rpa-request.service";
import { AppConfigService } from '../../../services/app-config.service'
import { TPA_SECRET_KEY } from '../../../constants/medical-coverage.config';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpResponseBody } from '../../../objects/response/HttpResponseBody';

@Injectable({
  providedIn: 'root',
})
export class MedicalCoverageService {

  private isRelationsPackagesLoadingSub = new Subject<boolean>();

  constructor(
    private store: StoreService,
    private apiPatientInfoService: ApiPatientInfoService,
    private apiCaseManager: ApiCaseManagerService,
    private request: RpaRequestService,
    private appConfig: AppConfigService,
    private http: HttpClient,
  ) {
  }

  getMedicalCoverageType(medicalCoverageId: string) {
    return (
      this.store
        .getMedicalCoverages()
        .find(mc => mc.id === medicalCoverageId) || { type: undefined }
    ).type;
  }

  getMedicalCoverageRpaEnabled(id: string) {
    const medicalCoverage = this.store.getMedicalCoverages().find(mc => mc.id === id)
    return get(medicalCoverage, 'rpaEnabled') || false
  }

  isPlanExcluedInCurrentClinic(planId) {
    // return false;
    const plan = this.store.getPlanByPlanId(planId) || { excludedClinics: [] };
    return plan.excludedClinics.some(id => id === this.store.getClinicId());
  }

  getRpaCredentials(clinicId, medicalCoverageCode) {
    const url = `cms-dua/clinic-secret-store/get-secret-value/${clinicId}/${TPA_SECRET_KEY}/${medicalCoverageCode}`
    return this.request.get(url, this.appConfig.getConfig().API_URL)
  }

  getAllTpaCredentials(clinicId) {
    const url = `cms-dua/clinic-secret-store/get-secret-value/${clinicId}`
    return this.request.get(url, this.appConfig.getConfig().API_URL)
  }

  getSecretTypes(clinicId) {
    const url = `cms-dua/clinic-secret-store/get-secret-types/${clinicId}`
    return this.request.get(url, this.appConfig.getConfig().API_URL)
  }

  createRpa(medicalCoverageCode, patientNric, username, password) {
    const data = {
      flowId: medicalCoverageCode,
      jwt: localStorage.getItem('access_token'),
      params: {
        PATIENT_NRIC: patientNric,
        USERNAME: username,
        PASSWORD: password
      }
    }

    return this.request.post('execution/create', data)
  }

  checkRpaStatus(rpaId) {
    this.request.open(`run/${rpaId}`)

    return this.request.get(`execution/status/${rpaId}`)
  }

  saveTpaPassword(storeValue): Observable<HttpResponseBody> {
    const url = this.request._getUrl(`cms-dua/clinic-secret-store/save`, this.appConfig.getConfig().API_URL);
    return this.http.post<HttpResponseBody>(url, JSON.stringify(storeValue));
  }

  public setPayerLoadingStatus(status: boolean): void {
    this.isRelationsPackagesLoadingSub.next(status);
  }

  public getPayerLoadingStatus(): Observable<boolean> {
    return this.isRelationsPackagesLoadingSub.asObservable();
  }
}

export function notBeforeToday(endDate: AbstractControl): ValidatorFn {
  return (control: AbstractControl) => {
    const today = moment().format(DISPLAY_DATE_FORMAT);
    const isPolicyExpired = invalidOrderOfDates(today, endDate.value, false);
    if (isPolicyExpired) {
      return {
        policyExpired: {
          value: moment(endDate.value, DISPLAY_DATE_FORMAT),
        },
      };
    } else {
      return null;
    }
  };
}

export function validateAgainstMC(
  startDate: AbstractControl,
  endDate: AbstractControl
): ValidatorFn {
  return (control: AbstractControl) => {
    const sDate = startDate.value['endDate'];
    const isPolicyExpired = invalidOrderOfDates(endDate.value, sDate, false);
    if (isPolicyExpired) {
      return {
        mcEndDateAfterPolicy: {
          value: moment(endDate.value, DISPLAY_DATE_FORMAT),
        },
      };
    } else {
      return null;
    }
  };
}

export function validateMC(startDate: AbstractControl, endDate: AbstractControl, ischeckwithCoverage:boolean): ValidatorFn {
  return (control: AbstractControl) => {
    const sDate = startDate.value['endDate'];
    const isPolicyExpired = invalidOrderOfDates(endDate.value, sDate, ischeckwithCoverage);
    if (isPolicyExpired) {
      return {
        mcEndDateAfterPolicy: {
          value: moment(endDate.value, DISPLAY_DATE_FORMAT),
        },
      };
    } else {
      return null;
    }
  };
}

export function planAlreadyExists(selectedPlan: AbstractControl, patientCoverages: FormArray): ValidatorFn {
  return (control: AbstractControl) => {
    let coverageMap = new Map<string, number>();
    let getUniqueKeyString = (abstractControl: AbstractControl) =>
      abstractControl.get('medicalCoverageId').value +
      abstractControl.get('planId').value;

    patientCoverages.controls.forEach(coverage => {
      let uniqueKeyString = getUniqueKeyString(coverage);
      let count = 1;
      if (coverageMap.has(uniqueKeyString)) {
        count = coverageMap.get(uniqueKeyString);
        count++;
      }
      coverageMap.set(uniqueKeyString, count);
    });

    if (coverageMap.get(getUniqueKeyString(selectedPlan)) > 1) {
      return {
        planAlreadyExist: {
          value: control.value,
        },
      };
    } else {
      return null;
    }
  };
}

export function planIsInActive(status): ValidatorFn {
  return (control: AbstractControl) => {

    if (control.value.status === 'INACTIVE') {
      return {
        planIsInactive: {
          value: control.value,
        },
      };
    } else {
      return null;
    }
  };
}

export function validateOrder(
  startDate: AbstractControl,
  endDate: AbstractControl,
  controlIsStart: boolean
): ValidatorFn {
  return (control: AbstractControl) => {

    if (invalidOrderOfDates(startDate.value, endDate.value, false)) {
      if (controlIsStart) {
        return {
          invalidStartDate: {
            value: control.value,
          },
        };
      } else {
        return {
          invalidEndDate: {
            value: control.value,
          },
        };
      }
    } else {
      return null;
    }
  };
}

function invalidOrderOfDates(startDate, endDate, ischeckwithCoverage) {
  const isStartValid = moment(startDate, DISPLAY_DATE_FORMAT).isValid();
  const isEndValid = moment(endDate, DISPLAY_DATE_FORMAT).isValid();
  const sDate = isStartValid
    ? startDate
    : moment(startDate).format(DISPLAY_DATE_FORMAT);
  const eDate = isEndValid
    ? endDate
    : moment(endDate).format(DISPLAY_DATE_FORMAT);

    if (ischeckwithCoverage) {
      let today = moment().format(DISPLAY_DATE_FORMAT);
      return moment(today, DISPLAY_DATE_FORMAT).isAfter(
        moment(sDate, DISPLAY_DATE_FORMAT)) || moment(today, DISPLAY_DATE_FORMAT).isAfter(
          moment(eDate, DISPLAY_DATE_FORMAT));
    } else {
      return  moment(eDate, DISPLAY_DATE_FORMAT).isBefore(
        moment(sDate, DISPLAY_DATE_FORMAT));
    }
}
