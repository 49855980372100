import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AkitaCaseStore, AkitaCase } from './akita-case.store';

@Injectable({ providedIn: 'root' })
export class AkitaCaseQuery extends Query<AkitaCase> {
  caseId = this.getValue().caseId || '';
  caseId$ = this.select(entity => entity.caseId);

  constructor(protected store: AkitaCaseStore) {
    super(store);
  }
}
