<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-1 ">
      <span class="text-warning warning-icon">
        <i class="icon-attention" aria-hidden="true"></i>
      </span>
    </div>
    <div class="col-11 d-flex align-items-center">
      <div *ngIf="messages.length">
        <p class="mb-0" *ngFor="let msg of messages"> {{msg}}</p>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="button-green" (click)="confirm(false)">NO</button>
  <button type="button" class="button-primary" (click)="confirm(true)">YES</button>
</div>