import { FormGroup } from '@angular/forms';
// TODO: make sure it is working with FormGroup

/**
 *
 *
 * @export
 * @param {FormGroup} group
 * @returns {null|Object} Null if valid.
 */
export function AtLeastOneFieldValidator(
  group: FormGroup
): { [key: string]: any } {
  let isAtLeastOne = false;
  if (group && group.controls) {
    for (const control in group.controls) {
      if (group.controls[control] instanceof FormGroup) {
        isAtLeastOne = AtLeastOneFieldValidator(<FormGroup>group.controls[
          control
        ])
          ? false
          : true;
        continue;
      }

      if (
        group.controls.hasOwnProperty(control) &&
        group.controls[control].valid &&
        group.controls[control].value
      ) {
        isAtLeastOne = true;
        break;
      }
    }
  }
  return isAtLeastOne ? null : { required: true };
}
