import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryDisplayStockTakeItemVariance'
})
export class InventoryDisplayStockTakeItemVariancePipe implements PipeTransform {

  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {

    if (!value) {
      return ''
    }

    const variance = this.inventory.calculateVariance(value);
    if (variance < 0) {
      return `(${Math.abs(variance)})`;
    } else {
      return variance;
    }
  }

}
