<div class="card" *ngIf="!!(packageItems$ | async)?.length ">
    <div class="card-header">Packages</div>
    <div class="card-body">
        <div class="row data-table">
            <div [ngClass]="inDispensing ? 'col-3' : 'col-5'">Item Code</div>
            <div [ngClass]="inDispensing ? 'col-1' : 'col-2'">Price</div>
            <div [ngClass]="inDispensing ? 'col-1' : 'col-2'">Date</div>
            <div *ngIf="inDispensing" class="col-2">Batch No.</div>
            <div *ngIf="inDispensing" class="col-2">Expiry Date</div>
            <div [ngClass]="inDispensing ? 'col-1' : 'col-2'">Completed</div>
            <!-- <div *ngIf="inDispensing" class="col-2">Plan</div> -->
            <div class="col-2">
                <!-- COMMENT OUT FIRST -->
                <!-- <button *ngIf="inDispensing && isSubsequentCaseForPackage"
                class="btn btn-sm btn-secondary float-right" (click)="onBtnPrintDrugLabel()" style="padding:0.01rem 0.5rem" [disabled]="!(enablePrintBtn$ | async)">
                <i class="icon-print "></i>Print Drug Label(s)</button> -->
            </div>
        </div>
        <div class="package-item-container">
            <app-package-item *ngFor="let packageItem of packageItems$ | async" [packageItem]="packageItem" [isDoctor]="isDoctor"
            (packageClicked)="packageClicked.emit($event)"  [inDispensing]="inDispensing" (deletePackageItem)="deletePackageItem.emit($event)"
            (onSubItemPriceAdjustment)="onSubItemPriceAdjustment.next($event)" [vaccineDosages]="vaccineDosages"
            (onItemChecked)="onItemChecked.next($event)"
            ></app-package-item>
        </div>

    </div>
</div>
