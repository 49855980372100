import { Injectable } from '@angular/core';

import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { AkitaRouteOfAdministrationStore } from './akita-route-of-administration.store';

@Injectable({ providedIn: 'root' })
export class AkitaRouteOfAdministrationQuery extends QueryEntity<any> {
  routeOfAdministrationList$ = this.selectAll();

  constructor(protected store: AkitaRouteOfAdministrationStore) {
    super(store);
  }
}
