import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { StoreService } from './../../../services/store.service';
import { ApiCmsManagementService } from './../../../services/api-cms-management.service';
import { Subject, Subscription } from 'rxjs';
import { AlertService } from './../../../services/alert.service';
import { CaseChargeFormService } from './../../../services/case-charge-form.service';
import { ConsultationFormService } from './../../../services/consultation-form.service';
import { ApiConsultaitonTemplateService } from './../../../services/api-consultaiton-template.service';

import { AkitaPatientAppQuery } from './../../../services/states/akita-patient/akita-patient-app.query';
import { AkitaNoteTemplateQuery } from './../../../services/states/akita-note-template.query';

import { DISPLAY_TIME_FORMAT_WIH_SLASHESH } from './../../../constants/app.constants';

import * as moment from 'moment';

@Component({
  selector: 'app-consultation-notes',
  templateUrl: './consultation-notes.component.html',
  styleUrls: ['./consultation-notes.component.scss'],
})
export class ConsultationNotesComponent implements OnInit, OnDestroy {
  @Input() consultationNotes: FormControl;
  @Input() isClicked : boolean;

  clickEventSubscription: Subscription;

  storeEventSubscription: Subscription;

  templateFC: FormControl;

  templates: {};
  template: any;
  itemTemplate: any;
  content: string;
  isAppend = true;
  title: String;
  isPaymentMode: boolean;

  availableItems = [];
  unavailableItems = [];

  drugTemplates;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private store: StoreService,
    private apiCmsManagementService: ApiCmsManagementService,
    private akitaNoteTemplateQuery: AkitaNoteTemplateQuery,
    private alertService: AlertService,
    private apiConstultationTemplate: ApiConsultaitonTemplateService,
    private consultationFormService: ConsultationFormService,
    private caseChargeFormService: CaseChargeFormService,
    private akitaPatientAppQuery: AkitaPatientAppQuery
  ) {

    this.clickEventSubscription = this.alertService.getClickEvent().subscribe(() => {
      this.onClockCliked();
    })

    this.storeEventSubscription = this.alertService.storeTempNotes().subscribe(() => {
      this.storeCurrentNotes();
    });
  }

  storeCurrentNotes() {
    localStorage.setItem('tempConsultationNotes', '<br>' + this.consultationNotes.value);
    localStorage.setItem('tempConsultationNotesPatientId', this.akitaPatientAppQuery.getId());
  }

  ngOnInit() {
    this.templates = this.store.getTemplatesByTemplateCategory('CONSULTATION');
    this.templateFC = new FormControl();

    this.drugTemplates = this.store.getTemplatesByTemplateCategory('DRUG');

    this.isPaymentMode =
      this.store.getVisitStatus() === 'PAYMENT' ? true : false;
    this.title = 'Notes';

    this.subscribeChanges();
  }

  getNoteTemplates() {
    return this.akitaNoteTemplateQuery.noteTemplateList$;
  }

  getDrugTemplates() {
    return this.akitaNoteTemplateQuery.drugTemplateList$;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.clickEventSubscription.unsubscribe();
  }

  onTemplateChange(event) {
    if (event) {
      this.apiCmsManagementService
        .loadTemplateWithVisitId(
          event.type,
          event.id,
          this.store.getUser().context['cms-user-id'],
          this.akitaPatientAppQuery.getId(),
          this.store.getPatientVisitRegistryId()
        )
        .subscribe(
          res => {
            if (res.payload) {
              if (this.isAppend) {
                const data = this.consultationNotes.value
                  ? this.consultationNotes.value
                  : '';
                this.consultationNotes.patchValue(data ? data + '<br>' + res.payload : res.payload);
              } else {
                this.consultationNotes.patchValue(res.payload);
              }
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err));
          }
        );
    }
  }

  onDrugTemplateChange(templateItem) {
    this.availableItems = [];
    this.unavailableItems = [];
    let itemType;
    if (templateItem) {
      if (templateItem.type === "DOCTOR"){
        itemType = "drug"
      } else if (templateItem.type === "GLOBAL"){
        itemType = "GLOBAL"
      }
      this.apiConstultationTemplate
        .loadDrugTemplate(
          itemType,
          templateItem.id,
          this.store.getClinicId(),
          this.store.getDoctorId()
        )
        .subscribe(
          res => {
            if (res && res.payload) {
              const itemList = res.payload;
              const tempItemIdList = itemList.map(item => {
                const chargeItem = {
                  itemId: item.item.id,
                };
                return chargeItem;
              });

              //Get Available Items
              if (tempItemIdList && tempItemIdList.length) {
                // Retrieve Available Items
                this.consultationFormService.checkIfAvailableInClinic(
                  tempItemIdList,
                  this.availableItems,
                  this.unavailableItems
                );

                // Build Available Items
                this.availableItems.forEach(availableItem => {
                  const itemDetail = itemList.find(
                    item => item.item.id === availableItem.itemId
                  ).item;
                  this.caseChargeFormService.buildDrugDispatchDetails(
                    itemDetail,
                    true
                  );
                });
              } else if (
                templateItem.templates &&
                templateItem.templates.length
              ) {
                // When item list is empty
                this.alertService.error(
                  'Some of the items are not available',
                  false,
                  false
                );
                alert('Some of the items are not available.');
              }
            }
          },
          err => this.alertService.error(JSON.stringify(err.error['message']))
        );
    }
  }

  subscribeChanges() {
    this.store
      .getVisitStatusRefresh()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(res => {
        this.template = {};
        this.itemTemplate = {};
        if (this.store.getVisitStatus() === 'PAYMENT') {
          this.isPaymentMode = true;
          this.consultationNotes.disable();
        } else {
          this.isPaymentMode = false;
          this.consultationNotes.enable();
        }
      });
  }

  onBtnAddClicked() { }

  inPaymentMode() {
    return this.store.getVisitStatus() === 'PAYMENT' ? true : false;
  }

  onClockCliked() {
    if (this.isAppend) {
      const data = this.consultationNotes.value ? this.consultationNotes.value : '';
      this.consultationNotes.patchValue(
        data ? data + '<br>' + moment(new Date()).format(DISPLAY_TIME_FORMAT_WIH_SLASHESH) : moment(new Date()).format(DISPLAY_TIME_FORMAT_WIH_SLASHESH)
      );
    } else {
      this.consultationNotes.patchValue(moment(new Date()).format(DISPLAY_TIME_FORMAT_WIH_SLASHESH));
    }
  }
}