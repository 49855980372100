import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import PatientInfo from '../../../objects/state/PatientInfo';

export interface AkitaPatientApp {
  id: string;
  storeConfig: string[];
  patientInfo: PatientInfo;
}

export function createInitialState(): AkitaPatientApp {
  return {
    id: '',
    storeConfig: [],
    patientInfo: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'akitaPatientApp', resettable: true })
export class AkitaPatientAppStore extends Store<AkitaPatientApp> {
  constructor() {
    super(createInitialState());
  }
}
