import { COVERAGE_MODE } from './app.constants';

export const COVERAGE_CONFIG = {
  PATIENT_DETAIL: {
    TAB_DISPLAY: <config>{
      key: 'PATIENT_TAB_DISPLAY',
      resetListOnDestroy: false,
    },
    POPUP_ATTACH: <config>{
      apiList: ['patientInfo', 'medicalCoverage'],
      key: 'DETAIL_ADD_TO_REGISTRY',
      resetListOnDestroy: false,
      type: COVERAGE_MODE.ADD_TO_REGISTRY,
      title: 'Confirmation of Patient Visit',
      isNewVisit: true,
      isNewPatient: false,
      showBalance: true,
    },
  },
  PATIENT_ADD: {
    DISPLAY: <config>{
      key: 'PATIENT_ADD_DISPLAY',
      isNewVisit: true,
      isNewPatient: true,
      resetListOnDestroy: false,
    },
    POPUP_ATTACH: <config>{
      apiList: [], // Empty , use data from parents
      key: 'NEW_PATIENT_ADD_TO_REGISTRY',
      resetListOnDestroy: false,
      type: COVERAGE_MODE.ADD_TO_REGISTRY,
      title: 'Confirmation of Patient Visit',
      isNewVisit: true,
      isNewPatient: true,
      showBalance: true,
    },
  },
  PATIENT_LIST: {
    POPUP: <config>{
      apiList: ['patientInfo', 'medicalCoverage'],
      key: 'LIST_ATTACH_COVERAGE',
      resetListOnDestroy: true,
      type: COVERAGE_MODE.ATTACH_MEDICAL_COVERAGE,
      title: 'Update Medical Coverage For Visit',
      isNewVisit: false,
      isNewPatient: false,
      showBalance: true,
    },
    DISPLAY: <config>{
      apiList: ['patientInfo', 'medicalCoverage'],
      key: 'PVM_DISPLAY_COVERAGE',
      resetListOnDestroy: true,
      type: COVERAGE_MODE.DISPLAY_MEDICAL_COVERAGE,
      title: 'View Medical Coverage For Visit',
      isNewVisit: false,
      isNewPatient: false,
      showBalance: true,
    },
  },
  PATIENT_SEARCH: {
    POPUP_ATTACH: <config>{
      apiList: ['patientInfo', 'medicalCoverage'],
      key: 'SEARCH_ATTACH_COVERAGE',
      resetListOnDestroy: true,
      type: COVERAGE_MODE.ADD_TO_REGISTRY,
      title: 'Confirmation of Patient Visit',
      isNewVisit: false,
      isNewPatient: false,
      showBalance: true,
    },
  },
  APPOINTMENT: {
    POPUP_ATTACH: <config>{
      apiList: ['patientInfo', 'medicalCoverage'],
      key: 'APPOINTMENT_ADD_TO_REGISTRY',
      resetListOnDestroy: true,
      type: COVERAGE_MODE.ADD_TO_REGISTRY,
      title: 'Confirmation of Patient Visit',
      isNewVisit: true,
      isNewPatient: false,
      showBalance: true,
    },
  },
  PVM: {
    TAB: <config>{
      key: 'PVM_TAB_DISPLAY',
      resetListOnDestroy: false,
    },
    POPUP_ATTACH: <config>{
      apiList: [], // Empty , use data from parents
      key: 'PVM_ATTACH_COVERAGE',
      resetListOnDestroy: false,
      type: COVERAGE_MODE.ATTACH_MEDICAL_COVERAGE,
      title: 'Update Medical Coverage For Visit',
      isNewVisit: false,
      isNewPatient: false,
      showBalance: true,
    },
    POPUP_DISPLAY: <config>{
      apiList: [], // Empty , use data from parents
      key: 'PVM_DISPLAY_COVERAGE',
      resetListOnDestroy: false,
      type: COVERAGE_MODE.DISPLAY_MEDICAL_COVERAGE,
      title: 'List of Medical Coverages Attached',
      isNewVisit: false,
      isNewPatient: false,
      showBalance: true,
    },
  },
  CASE_MANAGER: {
    UPDATE_COVERAGE: <config>{
      apiList: ['patientInfo', 'medicalCoverage'],
      key: 'UPDATE_CASE_COVERAGE',
      resetListOnDestroy: false,
      type: COVERAGE_MODE.ATTACH_MEDICAL_COVERAGE,
      title: 'Update Medical Coverage for Case',
      isNewVisit: false,
      isNewPatient: false,
      showBalance: true,
    },
    DISPLAY_COVERAGE: <config>{
      apiList: ['patientInfo', 'medicalCoverage'],
      key: 'DISPLAY_CASE_COVERAGE',
      resetListOnDestroy: false,
      type: COVERAGE_MODE.DISPLAY_MEDICAL_COVERAGE,
      title: 'View Medical Coverage for Case',
      isNewVisit: false,
      isNewPatient: false,
      showBalance: true,
    },
  },
};

export interface config {
  apiList?: string[];
  key?: string;
  resetListOnDestroy?: boolean;
  type?: string;
  title?: string;
  isNewVisit?: boolean;
  isNewPatient?: boolean;
  showBalance?: boolean;
}

export const TPA_SECRET_KEY = 'RPA_TPA_SECRETS';