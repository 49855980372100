import { Component, Input, OnInit, ViewChild, AfterViewInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormArray } from '@angular/forms';

import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { AkitaCaseVisitQuery } from '../../../services/states/akita-case-visit.query';
import { AlertService } from '../../../services/alert.service';
import { CaseManagerService } from '../../../views/components/case-manager/case-manager.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { buffer, bufferTime, map, tap, filter } from 'rxjs/operators';
import { groupByKey } from '../../../util/array.util';
import { AkitaCaseStoreService } from '../../../services/states/akita-case/akita-case-store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-case-manager-medical-coverage',
  templateUrl: './case-manager-medical-coverage.component.html',
  styleUrls: ['./case-manager-medical-coverage.component.scss']
})
export class CaseManagerMedicalCoverageComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() config;
  @Input() planIdsFromCase;
  @Input() billAdjustmentMode;

  medicalCoveragePlanFormArray = new FormArray([]);
  medicalCoverageLimitChangeSubject = new Subject<any>();
  public event: EventEmitter<any> = new EventEmitter();

  caseOpen$ = this.akitaCaseVisitQuery.select(entity => entity.status === 'OPEN');

  constructor(
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private alertService: AlertService,
    public bsModalRef: BsModalRef,    
    private bsModalService: BsModalService,
    private caseManagerService: CaseManagerService,
    private akitaCaseStoreService: AkitaCaseStoreService,
    private router: Router,
  ) { }

  ngOnInit() {

    this.medicalCoverageLimitChangeSubject.asObservable().pipe(
      bufferTime(500),
      filter(data => !!data.length),
      map(data => {
        const group = groupByKey(data, 'id');
        return Object.values(group);
      }),
      untilDestroyed(this)
    ).subscribe(data => {
      data.forEach((datum: any) => {
        datum.formControl.setValue(datum.value);
      })
    })
  }

  ngOnDestroy() { }

  ngAfterViewInit() {

  }


  updateMedicalCoverageInformation() {
    const coverageInformation = this.medicalCoveragePlanFormArray.getRawValue();
    if(this.billAdjustmentMode){
      this.event.emit(coverageInformation);
      this.closeModal();
    } else{
      coverageInformation.forEach(coverage => {
        if (!coverage.remarks) coverage.remarks = '';
        coverage.updated = true;
  
        delete coverage.type;
        delete coverage.oldLimit;
      });
  
      const caseId = this.akitaCaseVisitQuery.getValue().caseId;
      this.caseManagerService.updateMedicalCoverage(caseId, coverageInformation).subscribe(res => {
        if (res && res.payload) {
          this.akitaCaseStoreService.getCase(caseId);
          this.caseManagerService.caseUpdateMedicalCoverage(res.payload.coverages);
        }
        this.alertService.success('Successfully updated case medical coverage information');
        this.closeModal();
      }, err => this.alertService.error('Error updating case medical coverage information'));
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }


  patchLimitValues(formControl, value, index) {
    this.medicalCoverageLimitChangeSubject.next({
      formControl: formControl,
      value,
      id: index
    })
  }

  addNewMedicalCoverage() {
    let patietId = this.akitaCaseVisitQuery.getValue().patientId;
    if (patietId) {
      this.closeModal();
      this.router.navigate([`/pages/patient/detail/${patietId}`], { queryParams: { tabIndex: 2 } });
      this.closeAllModals();
    }
    
  }

  private closeAllModals() {
    for (let i = 1; i <= this.bsModalService.getModalsCount(); i++) {
      this.bsModalService.hide(i);
    }
  }

}
