<div [formGroup]="appointmentFormGroup" class="container-fluid p-0">
    <div class="row form-group">
        <div [ngClass]="customStyles ? 'col-md-5' : 'col-md-3 pr-2' ">
            <app-date-picker-with-icon 
                [customStyles]="customStyles"
                [config]="datePickerConfigArray['appointment']" 
                [dateControl]="appointmentFormGroup.get('visitDate')">
            </app-date-picker-with-icon>
        </div>
        <div [ngClass]="customStyles ? 'row col-md-6 input-group-append px-0' : 'row col-md-3 input-group-append px-0' ">
            <div class="col-6 px-1">
                <div [ngClass]="customStyles ? 'wrapper-form-control' : '' ">
                    <label class="mb-0">{{ customStyles ? 'Time' : '' }}</label>
                    <ng-select 
                        [ngClass]="customStyles ? 'custom-select-new custom-select-extra-medium-width' : 'custom-select form-control-sm custom-select-extra-medium-width' " 
                        type="text" 
                        bindValue='value' 
                        name="startTime" 
                        [items]="availableTimesDropDownList" 
                        [formControl]="startTime" 
                        [clearable]="false">
                    </ng-select>
                    <errors [control]="startTime.value !== undefined ? appointmentFormGroup.get('visitDate') : startTime"></errors>
                </div>
            </div>
            <div class="col px-1">
                <div [ngClass]="customStyles ? 'wrapper-form-control' : '' ">
                    <label class="mb-0">Dur (mins)<span class="compulsory">*</span></label>
                    <input 
                        type="number"
                        min="1"
                        [ngClass]="customStyles ? 'input' : 'form-control form-control-sm' "
                        formControlName="duration">
                    <errors [control]="appointmentFormGroup.get('duration')"></errors>
                </div>
            </div>
        </div>
        <div class="col-md-3 pl-2 pt-4" *ngIf="!allowDoubleRegistration && !fromHSG">
            <input type="checkbox" (change)="onCheckBoxChanged()" [(ngModel)]="checkbox" class="mt-1 mr-2 ml-1" [ngModelOptions]="{standalone: true}"> 
            <span>Display clinic time slots</span>
        </div>
    </div>
</div>
