import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { timer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  moduleId: module.id.toString(),
  selector: 'alert',
  templateUrl: 'alert.component.html',
})
export class AlertComponent implements OnInit, OnDestroy {
  message: any;
  errorMessages: Array<string>;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.alertService
      .getMessage()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(message => {
        this.message = message;


        const source = timer(20000);
        // hide message
        source.subscribe(val => this.toggleHide());
      });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  toggleHide() {
    document.getElementById('alert');
    this.message = '';
  }
}
