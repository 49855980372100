
import { ClinicCalendar } from '../../../../objects/ClinicCalendar';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ClinicCalendarState extends ClinicCalendar {}

export function createInitialState(): ClinicCalendarState {
  return <ClinicCalendar>{
    id: '',
    clinicId: '',
    avgConsultationTime: null,
    maxWaitTime: null,
    operationHours: null,
    clinicHolidays: null,
    blockedTimes: null,
    allowDoubleRegistration: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ClinicCalendar' })
export class ClinicCalendarStore extends Store<ClinicCalendarState> {
  constructor() {
    super(createInitialState());
  }
}
