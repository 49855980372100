// Services
import { AppointmentsFactoryService } from '../../../../services/appointments-factory.service';

// Objects
import { BlockDate } from '../../../../objects/ClinicCalendar';
import { CalendarAppointment } from '../../../../objects/CalendarAppointment';
import {
  colors,
  SERVICE_BLOCKED_TIME,
} from '../../../../constants/app.constants';

//Libraries
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {RRule} from 'rrule';
import { ServiceCalendar } from '../../../../objects/ServiceCalendar';
import { ServiceCalendarState, ServiceCalendarStore } from './service-calendar.store';

@Injectable({ providedIn: 'root' })
export class ServiceCalendarQuery extends QueryEntity<
  ServiceCalendarState,
  ServiceCalendar
> {
  selectAllServiceCalToAppointment$ = this.selectAll().pipe(
    map((calendar: ServiceCalendar[]) => {
      const list: CalendarAppointment[] = [];
      calendar.map(cal => {
        list.push(...this.setServiceEvent(cal));
      });
      return list;
    })
  );

  constructor(
    protected store: ServiceCalendarStore,
    private apptFactory: AppointmentsFactoryService
  ) {
    super(store);
  }

  setServiceEvent(serviceCalendar: ServiceCalendar) {
    const servCalendar = <ServiceCalendar>serviceCalendar;
    const list: CalendarAppointment[] = [];
    const setCalAppt = (blockDates: BlockDate[], type) => {
      blockDates.forEach((blockDate: BlockDate) => {
        this.extractServiceBlockDatesIntoCalendar(
          blockDate,
          type,
          list,
          servCalendar
        );
      });
    };

    var doctorEvents = Object.getOwnPropertyNames(servCalendar);
    doctorEvents.forEach(prop => {
     if (prop === 'blockedTime') {
        setCalAppt(servCalendar[prop], SERVICE_BLOCKED_TIME);
      }
    });
    return list;
  }

  extractServiceBlockDatesIntoCalendar(
    blockDate: BlockDate,
    type,
    list,
    serviceCalendar: ServiceCalendar
  ) {
    if (blockDate.calendarDayRange) {
      const event = this.createCalendarEvent(
        type,
        this.apptFactory.createBlockDate(blockDate),
        null,
        serviceCalendar
      );
      list.push(event);
    }
  }

  createCalendarEvent(type, block, doctorId, serviceCalendar) {
    const calendarEvent: CalendarAppointment = this.apptFactory.createCalendarEvent(
      type,
      null,
      block,
      doctorId,
      serviceCalendar
    );
    return calendarEvent;
  }

  createRecurringEvent(day) {
    return [
      {
        title: '',
        color: colors.red,
        rrule: {
          freq: RRule.WEEKLY,
          byweekday: [day],
          dtstart: new Date(Date.UTC(new Date().getFullYear(), 1, 1)),
          until: new Date(Date.UTC(new Date().getFullYear(), 12, 31)),
        },
      },
    ];
  }
}
