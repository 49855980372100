import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockAdjustmentItemInvalid'
})
export class StockAdjustmentItemInvalidPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return (!value || value.length === 0 || value === '31-12-0001') ? '' : value;
  }

}
