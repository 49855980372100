import { AkitaPatientStoreService } from './../../../../services/states/akita-patient/akita-patient-store.service';
import { UserRegistration } from './../../../../objects/UserRegistration';
import { filter, distinctUntilChanged, takeUntil, map, tap, debounceTime, switchMap, catchError } from 'rxjs/operators';
import { AkitaPatientAppQuery } from './../../../../services/states/akita-patient/akita-patient-app.query';
import { AllergiesFormService } from './../../../../services/allergies-form.service';
import { ADMINISTRATOR_TYPES, DISPLAY_DATE_FORMAT, PATIENT_INFO_KEYS } from './../../../../constants/app.constants';
import { StoreService } from './../../../../services/store.service';
import { AlertService } from './../../../../services/alert.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { createPatientInfo } from '../../../../objects/state/PatientInfo';
import { ApiPatientInfoService } from '../../../../services/api-patient-info.service';
import { of, Subject, Subscription } from 'rxjs';
import { AkitaAppQuery } from '../../../../services/states/akita-app.query';
import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';
import { AkitaChargeItemQuery } from '../../../../services/states/akita-charge-item.query';
import { ChargeItem } from '../../../../objects/state/ChargeItem';
import { AkitaClinicChargeItemQuery } from '../../../../services/states/akita-clinic-charge-item.query';
import { HttpResponseBody } from '../../../../objects/response/HttpResponseBody';
import { CustomConfirmPopupComponent } from '../../../../views/components/custom-confirm-popup/custom-confirm-popup.component';
@Component({
  selector: 'app-patient-detail-tag-add-alert',
  templateUrl: './patient-detail-tag-add-alert.component.html',
  styleUrls: ['./patient-detail-tag-add-alert.component.scss'],
})
export class PatientDetailTagAddAlertComponent implements OnInit, OnDestroy {
  // Carrier for Alert Information
  private componentDestroyed: Subject<void> = new Subject();
  @Input() alertFormGroup: FormGroup;
  // alertFormGroup: FormGroup;
  title: string;
  drugList: any;
  allergyGroupList: any;

  patientInfo;

  alertArray: FormArray;

  infoSourseCodes:any = [];
  drugAllergyIndicatorCodes:any = [];
  reactionOutcomeCodes:any = [];
  drugProbabilityCodes:any = [];
  adverseReactions:any = [];
  drugGenericCompounds:any = [];
  loading = false;
  codesTypeahead = new Subject<string>();
  showRowNum:number = -1;

  currentChargeItems$;
  currentChargeItems = [];
  administratorTypes: any[] = ADMINISTRATOR_TYPES;  
  selectedAdminTypeCode: string;  
  doctors: any[];
  private allergyModalStatusSub: Subscription;
  private saveAllertAndAllergySub: Subscription;
  enumDataLoaded: boolean = false;

  buttonData: any = [{
    isVisible: true,
    description: 'Delete',
    code: 'DELETE'
    } 
    ]

  constructor(
    private akitaAppQuery: AkitaAppQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaPatientStore: AkitaPatientStoreService,
    private alertService: AlertService,
    private allergiesFS: AllergiesFormService,
    private apiPatientInfoService: ApiPatientInfoService,
    private store: StoreService,
    public bsModalRef: BsModalRef,
    private apiCmsManagementService: ApiCmsManagementService,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private akitaClinicChargeItemQuery: AkitaClinicChargeItemQuery,
    private modalService: BsModalService,
  ) {}

  public event: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.store.listDoctorsByClinic();
    this.loadEnumValues();
    this.initialiseValues();
    this.subscribeChangesOnValues();
    this.getChargeItemDetails();

    this.allergyModalStatusSub = this.allergiesFS.getManageAllergyModalStatus().subscribe({
      next: result => {
        this.onBtnExit();
      }
    })

    this.saveAllertAndAllergySub = this.allergiesFS.getSaveAlertsAndAllergies().subscribe({
      next: result => {
        this.onBtnSave();
      }
    })
  }

  initialiseValues() {
    this.doctors = this.store.doctorListByClinic;


    this.allergyGroupList = this.store.allergyGroupList;

    this.alertArray = this.alertFormGroup.get('alertArray') as FormArray;
  }

  loadEnumValues() {
    this.apiCmsManagementService.listAllergyandADRAlertMappings().subscribe(
      	data => {
      		if (data.payload) {
            this.infoSourseCodes= data.payload.values.find(item => item.name === 'INFO_SOURCE_CODE').values;
            this.drugAllergyIndicatorCodes= data.payload.values.find(item => item.name === 'DRUG_ALLERGY_INDICATOR_CODE').values;
            this.reactionOutcomeCodes= data.payload.values.find(item => item.name === 'REACTION_OUTCOME_CODE').values;
            this.drugProbabilityCodes= data.payload.values.find(item => item.name === 'DRUG_PROBABILITY_CODE').values;
            this.adverseReactions= data.payload.values.find(item => item.name === 'ADVERSE_REACTION').values;
            this.enumDataLoaded = true;
            this.sortAdverseReactions();
      		}
      	},
      	err => {
      		this.alertService.error(JSON.stringify(err.error.message));
      	});

      //Load drug generic compunds
    this.apiCmsManagementService
      .listSystemStoreValuesByKey('DRUG_GENERIC_COMPOUNDS')
      .subscribe(
        res => {
          const drugdsList = res.payload;

          if(drugdsList.values) {
            this.drugGenericCompounds = drugdsList.values;
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
      
  }
  sortAdverseReactions() {
    let sortedReactions = [];
    let rashOption = this.adverseReactions.find(reaction => reaction.cmsName === 'Rash');

    if (rashOption) {
      sortedReactions.push(rashOption);
      this.adverseReactions.splice(this.adverseReactions.indexOf(rashOption), 1);
    }
    this.adverseReactions = [...sortedReactions, ...this.adverseReactions];
  }  

  subscribeChangesOnValues() {
    this.akitaPatientAppQuery.patientInfo$
      .pipe(filter(value => value !== null && value !== undefined), takeUntil(this.componentDestroyed))
      .subscribe(info => {
        this.patientInfo = createPatientInfo(info);
      });
  }

  populateAllergyGroupList() {
    if (this.store.allergyGroupList.length < 1) {
      if (this.store.errorMessages['listAllergyGroups'] !== undefined) {
        this.alertService.error(JSON.stringify(this.store.errorMessages['listAllergyGroups']));
      } else {
        this.store.getAllergyGroupList();
      }
    } else {
      this.allergyGroupList = this.store.allergyGroupList;
    }
  }

  onAdverseBtnAdd() {
    this.alertArray.push(this.allergiesFS.addAllergy());
  }

  onBtnAdd() {

    if (!this.alertFormGroup.valid) {
        this.alertFormGroup.markAllAsTouched();
        this.alertFormGroup.updateValueAndValidity();
        return;
    }

    this.alertArray.controls.forEach(form => {
      form.get("showDetails").patchValue(false);
    });

    const item = this.allergiesFS.addAllergy();
    const reporter = item.get('reporter');
    item.get("addedByClinicId").patchValue(this.store.getClinicId());
    const loggedInDoctor = this.doctors.find(doc => doc.id === this.store.getUser().context['cms-user-id']);
      if (loggedInDoctor) {
        reporter.get('profession').patchValue('DOCTOR');
        reporter.get('regNo').patchValue(loggedInDoctor.mcr);
        reporter.get('name').patchValue(loggedInDoctor.displayName);
        this.selectedAdminTypeCode = 'DOCTOR';
      } else {
        reporter.get('profession').patchValue('NURSE');
        this.selectedAdminTypeCode = 'NURSE';
      }
    this.alertArray.push(item);

  }

  onBtnSave() {
    this.updateAllergies();
    this.bsModalRef.hide();
  }

  toCheck(data) {
  }

  formatAllergyFormGroupForPatientInfo() {
    let allergies = [];
    const alertArray = this.alertArray.getRawValue();
    alertArray.forEach(values => {
      const allergyToBeSaved = <any>{
        allergyType: values.allergyType,
        name: values.name,
        itemId: values.itemId,
        allergyId: values ? values.allergyId: null,
        remarks: values.remarks,
        addedDate: values.addedDate ? values.addedDate : moment().format(DISPLAY_DATE_FORMAT),
        type: values.type,
        additionalNames: values.additionalNames,
        addedByClinicId: values.addedByClinicId ? values.addedByClinicId : this.store.getClinicId(),
        showDetails: false,
        infoSourceCode: values.infoSourceCode,
        adverseReaction: values.adverseReaction,
        onsetDate: values.onsetDate ? values.onsetDate : moment().format(DISPLAY_DATE_FORMAT),
        drugAllergyIndicatorCode: values.drugAllergyIndicatorCode,
        reporter: values.reporter,
        updateRemarks: values.updateRemarks,
        reactionOutcomeList: values.reactionOutcomeList,
        drugProbability: values.drugProbability,
        syncedFromCmis:  values.syncedFromCmis,
      };
      allergies.push(allergyToBeSaved);
    });
    
    this.patientInfo.allergies = allergies;
  }

  updateAllergies() {
    this.formatAllergyFormGroupForPatientInfo();
    const user = <UserRegistration>this.pick(this.patientInfo, PATIENT_INFO_KEYS);

    this.apiPatientInfoService.update(this.patientInfo.id, user).subscribe(
      res => {
 
        this.akitaPatientStore.getPatientInfo();
        this.allergiesFS.isAllergyUpdatedSubject.next(true);
      },
      err => this.alertService.error(JSON.stringify(err))
    );
  }

  // Utils
  pick(obj: Object, keys): Object {
    const refinedUserObj = Object.keys(obj).filter(key => keys.includes(key))
      .reduce((pickedObj, key) => {
        pickedObj[key] = obj[key];
        return pickedObj;
      }, {});

    const patientSourceClinicFeature = this.akitaAppQuery.getValue().clinic && this.akitaAppQuery.getValue().clinic.clinicFeatures && this.akitaAppQuery.getValue().clinic.clinicFeatures.includes('PATIENT_SOURCES');

    if (patientSourceClinicFeature || obj.hasOwnProperty('patientSources')) {
      refinedUserObj['patientSources'] = obj['patientSources'] || {};
    }

    return refinedUserObj;
  }

  onBtnExit() {
    this.alertArray.value.forEach((alert, index) => {
      if (alert.name === '' || alert.type === '')
        this.alertArray.removeAt(index);
    });

    this.bsModalRef.hide();
  }

  patientRegistryExpand() {
    return 'flex';
  }

  clearAllergyToField() {
    
  }

  onDelete(form, index) {
    const initialState = {
      isModal: true,
      displayMsg: 'Do you want to Delete Adverse Drug Reaction ?',
      cancelBtnText: 'Cancel',
      cancelBtnShow: true,
      buttonData: this.buttonData,
      title: 'Delete  Adverse Drug Reaction',
    };

    this.bsModalRef = this.modalService.show(CustomConfirmPopupComponent, {
      initialState,
      class: 'modal-md',
      backdrop: 'static',
      keyboard: false,
    });
    this.bsModalRef.content.closeBtnName = 'Close';

    this.bsModalRef.content.closeEvent.subscribe(data => {
      this.bsModalRef.hide();
      this.bsModalRef.content.closeEvent.unsubscribe();
    });

    this.bsModalRef.content.customBtnEvent.subscribe(data => {
      if (data === 'DELETE') {
        const formArray = form.parent as FormArray;
        formArray.removeAt(index);
      }

      this.bsModalRef.hide();
      this.bsModalRef.content.customBtnEvent.unsubscribe();      
    });
  }

  getChargeItemDetails() {
    this.currentChargeItems$ = this.akitaChargeItemQuery.activeChargeItemList$.pipe(
      map(data => {
        if (data) {
          const updatedData = [];
          const filteredData = data.find(
            (item: ChargeItem) =>
              item.id === ''
          );
          if (filteredData) updatedData.push(filteredData);
          return updatedData;
        }
      })
    );

    this.currentChargeItems$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(value => {
        if (value) {
          this.currentChargeItems = [...value];
        }
      });
    this.onFilterInputChanged();
  }

  onFilterInputChanged() {
    try {
      this.codesTypeahead
        .pipe(
          filter(input => {
            const inputCheck =
              input === undefined || input === null || input.trim().length < 3;
            if (inputCheck) {
              this.currentChargeItems$ = this.akitaClinicChargeItemQuery.activeClinicChargedItem$;
            }
            return !inputCheck;
          }),
          tap(() => (this.loading = true)),
          debounceTime(200),
          switchMap(term => {
            const planIds = [];
            return this.apiCmsManagementService
              .searchListItemByKeyWordInventoryAndClinicId(
                this.store.getClinicId(),
                encodeURI(term),
                planIds
              )
              .pipe(
                catchError(error =>
                  of(<HttpResponseBody>{ page: {}, payload: [] })
                )
              );
          })
        )
        .subscribe(
          data => {
            this.loading = false;
            if (data)
              this.currentChargeItems = data.payload.map(item => item.item);
          },
          err => {
            this.loading = false;
            this.alertService.error(JSON.stringify(err.error.message));
          }
        );
    } catch (err) {
    }
  }
  
  getDrugList() {
    return this.akitaChargeItemQuery.drugChargeItemList$;
  }

  showExtra(rowNum) {
    this.showRowNum = this.showRowNum === -1 ? rowNum : (this.showRowNum === rowNum ? -1 : rowNum);
  }

  onAdministratorTypeSelect(option, form) {
    form.get('reporter').get('name').patchValue('');
    form.get('reporter').get('regNo').patchValue('');
    if (option) {
      this.selectedAdminTypeCode = option.value;
    }
  }

  onDoctorChange(event, form) {
    if (event) {
      form.get('reporter').get('name').patchValue(event.displayName);
    } else {
      form.get('reporter').get('name').patchValue('');
      form.get('reporter').get('regNo').patchValue('');
    }
  }

  ngOnDestroy(): void {
    this.allergyModalStatusSub.unsubscribe();
    this.saveAllertAndAllergySub.unsubscribe();
  }
}
