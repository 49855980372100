<tr [formGroup]="healthPlan.bodySectionFormGroup">
    <td>{{index + 1}}</td>
    <td>
        <ng-select style="width: 300px" class="custom-select-new" [items]="dropdownList" bindLabel="label" bindValue="id"
            [clearable]="false" [virtualScroll]="true" placeholder="Goal" (change)="onSelectGoal($event)" formControlName="goalId">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>{{ item.label }}</div>
            </ng-template>
        </ng-select>
    </td>
    <td>
        <div class="row mb-2" *ngIf="healthPlan.bodySections && healthPlan.bodySections.length">
            <div class="col-12">
                <app-care-plan-body [type]="healthPlan.type" [bodySectionFormGroup]="healthPlan.bodySectionFormGroup"
                    [bodySection]="healthPlan.bodySections">
                </app-care-plan-body>
                <app-care-plan-error-message [bodySectionFormGroup]="healthPlan.bodySectionFormGroup"
                    [bodySection]="healthPlan.bodySections">
                </app-care-plan-error-message>
            </div>
        </div>

        <ng-container *ngFor="let subworkflow of healthPlan.subWorkflowList">
            <div class="row">
                <div class="col-12 d-flex align-items-center mb-2" [formGroup]="subworkflow.bodySectionFormGroup">
                    <input *ngIf="subworkflow.canEnableDisable" class="mr-2" type="checkbox"
                        (change)="onCheck($event.target.checked, subworkflow)" formControlName="selected">

                    <app-care-plan-body [type]="subworkflow.type" [bodySectionFormGroup]="subworkflow.bodySectionFormGroup"
                        [bodySection]="subworkflow.bodySections">
                    </app-care-plan-body>
                </div>
                <div class="col-12">
                    <app-care-plan-error-message [bodySectionFormGroup]="subworkflow.bodySectionFormGroup"
                        [bodySection]="subworkflow.bodySections">
                    </app-care-plan-error-message>
                </div>
            </div>
        </ng-container>
    </td>
    <td class="td-actions">
        <img src="/assets/svg/trash-solid.svg" (click)="onDelete(healthPlan.id)">
    </td>
</tr>