import UserId from '../UserId';

export class RelationshipEntity {
  primary: boolean;
  name: string;
  contactNumber: string;
  relationship: string;
  userId?: UserId;

  dob?: string;
  address?: any;
  email?: string;
  consentValidTo?: string;
  medisave?: boolean;

  constructor(
    primary?: boolean,
    name?: string,
    contactNumber?: string,
    relationship?: string,
    userId?: UserId,
    dob?: string,
    address?: any,
    email?: string,
    consentValidTo?: string,
    medisave?: boolean
  ) {
    this.primary = primary || false;
    this.name = name || '';
    this.contactNumber = contactNumber || '';
    this.relationship = relationship || '';
    this.userId = userId || null;
    this.dob = dob || null;
    this.address = address && address.line1 && address.line2 ? { address: `${address.line1}\n${address.line2}` } : {};
    this.email = email || null;
    this.consentValidTo = consentValidTo || null;
    this.medisave = medisave || false;
  }
}