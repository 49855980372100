import { AkitaMedicalCoverageQuery } from './../../../../services/states/akita-medical-coverage.query';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../../services/alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { config } from '../../../../constants/medical-coverage.config';
import { AttachedMedicalCoverage } from '../../../../objects/AttachedMedicalCoverage';
import { COVERAGE_MODE } from './../../../../constants/app.constants';
import { AkitaPatientStoreService } from './../../../../services/states/akita-patient/akita-patient-store.service';
import { PatientMedicalCoverageQuery } from './../../../../services/states/akita-patient/patient-medical-coverages/patient-medical-coverage.query';
import { MedicalCoverageEditComponent } from './medical-coverage-edit/medical-coverage-edit.component';
import { MedicalCoverage } from '../../../../objects/state/MedicalCoverage';
import { RpaRequestService } from '../../../../services/rpa-request.service';

// Libraries
// Services
// Objects
@Component({
  selector: 'app-medical-coverage',
  templateUrl: './medical-coverage.component.html',
  styleUrls: ['./medical-coverage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalCoverageComponent implements OnInit {
  // Information Inputs
  @Input() patientCoverages: FormArray;
  @Input() selectedCoverages: FormArray;
  @Input() newPatientId: string;

  @Input() planIdsFromCase: AttachedMedicalCoverage[];
  @Input() planIdsFromCaseModal$: Observable<AttachedMedicalCoverage[]>;

  // Boolean Variables for UI customisation
  @Input() hasEditModal: boolean; // For Patient Add and Patient Detail to add new MCs
  @Input() hasCoverageModal: boolean;

  @Input() config: config;
  @Input() visitId: string;

  @Input() isMedicalCoverageSelectionDisabled: boolean;

  @Output() onEditSave: EventEmitter<FormArray> = new EventEmitter();

  @Output() onCoverageSave: EventEmitter<FormArray> = new EventEmitter();

  bsModalRef: BsModalRef;
  loading: boolean = true;
  hasUpdatePriority: boolean;

  patientCoverages$;

  private componentDestroyed: Subject<void> = new Subject();

  isLoading$ = this.akitaPatientStore.selectMedicalCoverageLoading().pipe(
    takeUntil(this.componentDestroyed)
  );

  public rpaError: string = '';
  private rpaErrorSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private modalService: BsModalService,
    private akitaPatientStore: AkitaPatientStoreService,
    private patientMedCovQuery: PatientMedicalCoverageQuery,
    private akitaMedicalCoverageQuery: AkitaMedicalCoverageQuery,
    private rpaRequestService: RpaRequestService
  ) { }

  ngOnInit() {
    // if (!!this.planIdsFromCase$) {
    //   this.planIdsFromCase$ = new Observable<AttachedMedicalCoverage[]>();
    // }
    this.initMedicalCoverages();

    if (!this.config.isNewPatient) {
      this.subscribeOnChanges();
    }

    this.rpaErrorSub = this.rpaRequestService.getError().subscribe((result: string) => {
      this.rpaError = result;
    });
  }

  //------------------- Initialise Info methods --------------------//
  initMedicalCoverages() {
    this.setConfig();

    if (!this.selectedCoverages) {
      this.selectedCoverages = this.fb.array([]);
    }

    /* PLANS FROM CASE **/
    if (!!this.planIdsFromCase) {
      this.planIdsFromCase.forEach( (coverage: AttachedMedicalCoverage) => {
        const medicalCoverage: MedicalCoverage = this.akitaMedicalCoverageQuery.getCoverageByPlanId(coverage.planId);
        const coverageName = coverage.name && coverage.name.length > 0 ? coverage.name : medicalCoverage.name;

        const reducedMedicalCoverage = this.fb.group({
          name: coverageName,
          planId: coverage.planId,
          medicalCoverageId: coverage.medicalCoverageId,
          type: medicalCoverage.type,
          oldLimit: coverage.limit,
          limit: coverage.limit,
          remarks: coverage.remarks,
          updated: coverage.updated,
          payerUserId : coverage.payerUserId
        });
        this.selectedCoverages.push(reducedMedicalCoverage);
      });
    }

    if (!!this.planIdsFromCaseModal$) {
      this.planIdsFromCaseModal$.subscribe(coverages => {
  

        while (this.selectedCoverages.length > 0) {
          this.selectedCoverages.removeAt(0);
        }

        coverages.forEach(coverage => {
          const medicalCoverage: MedicalCoverage = this.akitaMedicalCoverageQuery.getCoverageByPlanId(coverage.planId);
          const coverageName = coverage.name && coverage.name.length > 0 ? coverage.name : medicalCoverage.name;

          const reducedMedicalCoverage = this.fb.group({
            name: coverageName,
            planId: coverage.planId,
            medicalCoverageId: coverage.medicalCoverageId,
            type: medicalCoverage.type,
            oldLimit: coverage.limit,
            limit: coverage.limit,
            remarks: coverage.remarks,
            updated: coverage.updated,
            payerUserId : coverage.payerUserId
          });
          this.selectedCoverages.push(reducedMedicalCoverage);
        });
   
      });
    }

    /* PLANS FROM CASE **/

    if (!this.patientCoverages) {
      this.loading = true;
      this.patientCoverages = this.fb.array([]);
    } else {
      this.loading = false;
      this.cdr.markForCheck();
    }

    this.patientCoverages$ = this.patientMedCovQuery.medicalCovToFG$;

    
  }

  setConfig() {
    this.hasUpdatePriority =
      this.config.type === COVERAGE_MODE.ATTACH_MEDICAL_COVERAGE ||
      this.config.type === COVERAGE_MODE.ADD_TO_REGISTRY;
  }

  subscribeOnChanges() {
    this.isLoading$
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(isLoading => {
    
        this.loading = isLoading;
      });

    this.patientCoverages$
      .pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed))
      .subscribe((coverages: FormArray) => {
     
        this.patientCoverages = coverages;
        this.loading = false;
        this.cdr.markForCheck();

        if (this.patientCoverages.length > 0) {
          this.filterSelectedCoverages();
        }
      });
  }

  filterSelectedCoverages() {
    const patientPlanIds = this.patientCoverages.controls.map(
      control => control.get('planId').value
    );

    const selectedPlanIds = this.selectedCoverages.controls.map(
      control => control.get('planId').value
    );

    selectedPlanIds.forEach(id => {
      if (!patientPlanIds.includes(id)) {
        const deleteIndex = this.selectedCoverages.controls.findIndex(
          control => control.get('planId').value === id
        );
        this.selectedCoverages.removeAt(deleteIndex);
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    if (this.config && this.config.resetListOnDestroy) {
      this.akitaPatientStore.reset();
    }
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  addMedicalCoverage(event) {
    const initialState = {
      patientCoverages: this.patientCoverages,
      hasCoverageModal: this.hasCoverageModal
    };

    if(this.config.key === "PATIENT_ADD_DISPLAY"){
      initialState['newPatientId'] = this.newPatientId;
    }

    this.bsModalRef = this.modalService.show(MedicalCoverageEditComponent, {
      initialState,
      class: 'modal-xl',
      backdrop: 'static',
      keyboard: false,
    });

    this.bsModalRef.content.event.subscribe(data => {
      if (data) {
 
        if(data && data.type === 're-open'){
          if(data.data !== 'Close'){
            this.patientCoverages.push(data.data);
            this.removeEmptyCoverageLine();
          }
          this.addMedicalCoverage('');
        } else{
          this.emit(data);
          this.bsModalRef.hide();
        }
      }
    });
  }

  emit(value) {
    if(this.hasCoverageModal){
      this.onEditSave.emit(this.patientCoverages);
    }else if(this.hasEditModal){
      this.onEditSave.emit(value);
    }
  }

  removeEmptyCoverageLine(){
    let firstCoveragLine = <FormArray> this.patientCoverages.controls[0];
    let id = firstCoveragLine.get('id').value;
    let planId = firstCoveragLine.get('planId').value;
    if(id === '' && planId === ''){
      this.patientCoverages.removeAt(0);
    }
  }
  
}
