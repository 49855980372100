import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-inventory-count-detail-expiry-error',
  templateUrl: './inventory-count-detail-expiry-error.component.html',
  styleUrls: ['./inventory-count-detail-expiry-error.component.scss']
})
export class InventoryCountDetailExpiryErrorComponent implements OnInit {

  @Input()
  public inavlidLine: FormGroup;

  @Input()
  public title: FormGroup;

  @Input()
  public invalidLineNumber: number;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() { }

  onClose(): void {
    this.bsModalRef.hide();
  }
}