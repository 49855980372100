<div class="card">
  <div class="card-header py-1">
    Update Medical Coverage
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <alert></alert>

    <div *ngIf="medicalCoveragePlanFormArray.length > 0" class="py-2">
      <div class="row py-1 bg-warm-grey text-white font-weight-semi-bold">
        <div class="col-md-6">Plan</div>
        <div class="col-md-2">Current Limit ($)</div>
        <div class="col-md-2">Updated Limit ($)</div>
        <div class="col-md-2">Ref. Remarks</div>
      </div>

      <div  *ngFor="let plan of medicalCoveragePlanFormArray.controls; let i = index;">
        <div class="row py-1">
          <div class="col-md-6 font-weight-semi-bold">{{ plan.value.name }}</div>
          <div class="col-md-2">
            <input 
              type="text"
              class="form-control"
              [ngModel]="plan.get('oldLimit').value !== -1 ? plan.get('oldLimit').value : -1" 
              readonly
              >
          </div>
          <div class="col-md-2">
            <input 
              type="text"
              class="form-control" 
              [ngModel]="plan.get('limit').value !== -1 ? plan.get('limit').value : -1"
              (ngModelChange)="patchLimitValues(plan.get('limit'), ($event === -1 ? -1 : $event), i)"
              [readOnly]="!(caseOpen$ | async)"
              >
          </div>
          <div class="col-md-2">
            <input
              type="text"
              class="form-control"
              [ngModel]="plan.get('remarks').value"
              (ngModelChange)="plan.get('remarks').setValue($event)"
              [readOnly]="!(caseOpen$ | async)"
              >
          </div>
        </div>
      </div>

      <hr>
    </div>

    <div class="row py-2">
      <div class="col-md-12">
        <app-medical-coverage [config]="config" [planIdsFromCase]="planIdsFromCase" [selectedCoverages]="medicalCoveragePlanFormArray"></app-medical-coverage>
      </div>
    </div>

    <div class="row flex-row-reverse">
      <div *ngIf="(caseOpen$ | async) || billAdjustmentMode" class="col-md-2">
        <button class="btn btn-md btn-brand-primary text-white w-100" (click)="updateMedicalCoverageInformation()" [disabled]="billAdjustmentMode && medicalCoveragePlanFormArray.getRawValue().length !== 1">Update</button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-md btn-grey-600 text-white w-100" (click)="closeModal()">
          {{ (caseOpen$ | async) ? 'Cancel' : 'Close' }}
        </button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-md btn-brand-primary text-white w-100" (click)="addNewMedicalCoverage()"> Add Medical Coverage</button>
      </div>
    </div>
  </div>
</div>;
