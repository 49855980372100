import { CalendarAppointment } from './../../../objects/CalendarAppointment';
import { Injectable } from '@angular/core';
// import { CalendarAppointmentState } from './calendar-appointment.store';
import { EntityState, EntityStore, StoreConfig, guid } from '@datorama/akita';

export interface CalendarAppointmentState
  extends EntityState<CalendarAppointment> {}
export function createInitialState(): CalendarAppointmentState {
  return { id: guid() };
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'calendarAppointment', idKey: 'id' })
export class CalendarAppointmentStore extends EntityStore<
  CalendarAppointmentState
> {
  constructor() {
    super(createInitialState());
  }
}
