import { Injectable } from '@angular/core';

import { StoreConfig, EntityStore } from '@datorama/akita';

import { Doctor } from '../../objects/state/Doctor';
import { AKITA_CACHE_DURATION } from '../../constants/akita.config';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'doctor',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaDoctorStore extends EntityStore<Doctor> {
  constructor() {
    super();
  }
}
