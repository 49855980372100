import { NavIntegrationResult } from './CommonResponses';
export class OrderGRN {
  id: string;
  grnDate: string;
  createDate: string;
  delivererId: string;
  receiverId: string;
  grnNo: string;
  additionalRemark: string;
  status: GRNStatus;
  dnNo: string;
  dnDate: string;
  prNo: string;
  prDate: string;
  receivedItems: Array<GRNItem>;
  fileMetaData: Array<GRNFile>;
  doCourier: string;
  doAdditionalRemark: string;
  navIntegrationResult: NavIntegrationResult;

  adapt(obj?): OrderGRN {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.grnDate = obj.grnDate;
    this.createDate = obj.createDate;
    this.delivererId = obj.delivererId;
    this.receiverId = obj.receiverId;
    this.grnNo = obj.grnNo;
    this.additionalRemark = obj.additionalRemark;
    this.status = obj.status;
    this.dnNo = obj.dnNo;
    this.dnDate = obj.dnDate;
    this.prNo = obj.prNo;
    this.prDate = obj.prDate;
    this.receivedItems = (obj.receivedItems || []).map(item =>
      new GRNItem().adapt(item)
    );
    this.fileMetaData = (obj.fileMetaData || []).map(item =>
      new GRNFile().adapt(item)
    );
    this.doCourier = obj.doCourier;
    this.doAdditionalRemark = obj.doAdditionalRemark;
    this.navIntegrationResult = obj.navIntegrationResult;

    return this;
  }

  getItemQuantity(itemId: string): number {
    return this.receivedItems
      .filter(item => item.itemRefId === itemId)
      .reduce((sum, item) => (sum += item.quantity), 0);
  }

  getItemQuantityByLineNo(lineNo: string): number {
    return this.receivedItems
      .filter(item => item.lineNo === lineNo)
      .reduce((sum, item) => (sum += item.quantity), 0);
  }

  hasValidFile(): boolean {
    return this.fileMetaData && this.fileMetaData.some(file => !file.deleted);
  }

  getFirstValidFile(): GRNFile {
    return this.fileMetaData.find(file => !file.deleted);
  }

  removeFiles() {
    this.fileMetaData = [];
  }
}

export class GRNItem {
  id: string;
  itemRefId: string;
  uom: string;
  batchNumber: string;
  quantity: number;
  receiptDate: string;
  expiryDate: string;
  manufacturerDate: string;
  comment: string;
  lineNo: string;
  isDrugOrVaccine: boolean;
  purchasePrice:any;

  adapt(obj?): GRNItem {
    if (!obj) {
      return this;
    }

    this.id = obj.id;
    this.itemRefId = obj.itemRefId;
    this.uom = obj.uom;
    this.batchNumber = obj.batchNumber;
    this.quantity = obj.quantity;
    this.receiptDate = obj.receiptDate;
    this.expiryDate = obj.expiryDate;
    this.manufacturerDate = obj.manufacturerDate;
    this.comment = obj.comment;
    this.lineNo = obj.lineNo;
    this.isDrugOrVaccine = obj.isDrugOrVaccine;
    this.purchasePrice = obj.purchasePrice;

    return this;
  }
}

export class GRNFile {
  fileId: string;
  name: string;
  fileName: string;
  clinicId: string;
  type: string;
  deleted: string;

  adapt(obj?): GRNFile {
    if (!obj) {
      return this;
    }

    this.fileId = obj.fileId;
    this.name = obj.name;
    this.fileName = obj.fileName;
    this.clinicId = obj.clinicId;
    this.type = obj.type;
    this.deleted = obj.deleted;

    return this;
  }
}

export enum GRNStatus {
  DRAFT = 'DRAFT',
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
  FAILED = 'FAILED',
}