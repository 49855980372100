import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { HealthPlanSection, PlanSectionData } from '../../../../../../model/care-plan/CarePlanHealthGoal';
import { ExercisePlanSection } from '../../../../../../model/care-plan/CarePlanExerciseRecommendation';
import DatePickerConfig from '../../../../../../objects/DatePickerConfig';

@Component({
  selector: 'app-care-plan-body',
  templateUrl: './care-plan-body.component.html',
  styleUrls: ['./care-plan-body.component.scss']
})
export class CarePlanBodyComponent implements OnInit {

  @Input() bodySection: Array<ExercisePlanSection | HealthPlanSection> = [];
  @Input() bodySectionFormGroup?: FormGroup;
  @Input() type?: 'multi-choice' | 'other';

  public datePickerConfig: DatePickerConfig;

  public multiChoiceErrorMessages: Array<string> = [];

  ngOnInit(): void {
    this.initDatePickerConfig();
  }

  private initDatePickerConfig(): void {
    this.datePickerConfig = new DatePickerConfig(
      '',
      null,
      new Date(),
      '',
      ''
    );
  }

  public onMultiChoiceCheck(isChecked: boolean, question: PlanSectionData, formGroup: FormGroup): void {
    const formCongrol = formGroup.get('captureContext');

    if (!formCongrol) {
      return;
    }

    if (isChecked && question.captureContext) {
      formGroup.get('captureContext').enable();
      formGroup.get('captureContext').markAsTouched();
    } else {
      formGroup.get('captureContext').markAsUntouched();
      formGroup.get('captureContext').disable();
    }
  }

  public addTagFn(newValue: any) {
    return { key: newValue, value: newValue };
  }

  public getErrorMessages(bodySection: HealthPlanSection): Array<string> {
    const multiChoiceErrorMessages: Array<string> = [];
    this.multiChoiceErrorMessages = [];

    const multiChoiceFormArray = this.bodySectionFormGroup.get('multiChoice') as FormArray;

    if (!multiChoiceFormArray) {
      return [];
    }

    if (multiChoiceFormArray.errors !== null && !Object.is(multiChoiceFormArray.errors, {})) {
      multiChoiceErrorMessages.push('At least one should be selected from the above options, if not, delete this goal.');
    }

    const controls = multiChoiceFormArray.controls as Array<FormGroup>;

    controls.forEach(arrayFormGroup => {
      const errorMsg = bodySection.data.find(item => item.key === arrayFormGroup.value.key).errorMsg;

      for (const [key, value] of Object.entries(arrayFormGroup.controls)) {
        if (key === 'captureContext' && value.errors && value.errors['required']) {
          multiChoiceErrorMessages.push(errorMsg ? errorMsg : 'One of the options above requires additional specifications.');
          return;
        }

        if (key === 'captureContext' && value.errors && value.errors !== null && !Object.is(value.errors, {})) {
          multiChoiceErrorMessages.push(errorMsg ? errorMsg : 'There is an error in this goal. Please check again.');
        }
      }
    });

    this.multiChoiceErrorMessages = multiChoiceErrorMessages;

    return multiChoiceErrorMessages;
  }
}