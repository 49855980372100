import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';

export interface Approver {
  approver: string;
}

export function createApprover(approver: string) {
  return {
    approver
  } as Approver;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'approverList', idKey: 'approver', cache: { ttl: AKITA_CACHE_DURATION }, resettable: true })
export class AkitaApproverStore extends EntityStore<Approver> {

  constructor() {
    super();
  }

}
