import { AlertService } from './alert.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  constructor(private http: HttpClient, private alertService: AlertService) {}
  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 1000 * 60 * 30) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
    this.checkVersion(url);
  }
  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  public checkVersion(url) {
    // timestamp these requests to invalidate cachesß
    this.http.get(url + '?t=' + new Date().getTime()).subscribe(
      (response: any) => {
        const hash = response.hash;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        // If new version, do something
        if (hashChanged) {
          // window.location.reload();
          // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
          // for an example: location.reload();

          if (confirm('Version has changed')) {
            window.location.reload();
          }
        }
        // store the new hash so we wouldn't trigger versionChange again
        // only necessary in case you did not force refresh
        this.currentHash = hash;
      },
      err => {
        console.error(err, 'Could not get version');
        this.alertService.error(JSON.stringify(err));
      }
    );
  }
  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }
}
