import { AkitaChargeItemQuery } from './states/akita-charge-item.query';
import { AkitaCaseVisitQuery } from './states/akita-case-visit.query';
import { IPackageItem, IDispaches } from './../components/shared/package/package.model';
import { VisitManagementService } from './visit-management.service';
import { AkitaPatientAppQuery } from './states/akita-patient/akita-patient-app.query';
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxPermissionsService } from 'ngx-permissions';
import { DISPLAY_DATE_FORMAT } from '../constants/app.constants';
import { Discount } from '../objects/Charge';
import { MedicalCoverage } from '../objects/MedicalCoverage';
import { StockTakeItem } from '../objects/StockTake';
import { medicalCoverageLabelTemplate } from '../views/templates/medical.coverage.label';
import { refferalLetterTemplate } from '../views/templates/refferal.letter';
import { stockTakeTemplate } from '../views/templates/stock.take';
import { DocViewerComponent } from './../components/doc-viewer/doc-viewer.component';
import { SILVER_CROSS_GROUP } from './../constants/app.constants';
import { Clinic } from './../objects/response/Clinic';
import { billTemplate } from './../views/templates/bill';
import { drugLabelTemplate } from './../views/templates/drug.label';
import { medicalCertificateTemplate } from './../views/templates/medical.certificate';
import { memoTemplate } from './../views/templates/memo';
import { patientLabelTemplate } from './../views/templates/patient.label';
import { timeChitTemplate } from './../views/templates/time.chit';
import { vaccinationCertificateTemplate } from './../views/templates/vaccination.certificate';
import { AlertService } from './alert.service';
import { ApiCaseManagerService } from './api-case-manager.service';
import { ApiCmsManagementService } from './api-cms-management.service';
import { ApiPatientVisitService } from './api-patient-visit.service';
import { StoreService } from './store.service';
import { UtilsService } from './utils.service';

import { AkitaClinicQuery } from './states/akita-clinic.query';
import { mergeMap, take } from 'rxjs/operators';
import { AkitaAppQuery } from './states/akita-app.query';
import RefreshSetting from '../objects/RefreshSetting';
import { StockItem } from '../objects/StockItem';
import { PurchasableItemLevel } from '../components/inventory/stock-list/stock-list.component';
import { StockItemInvalidPipe } from '../pipes/stock-item-invalid.pipe';
import { ApiMwocService } from './api-mwoc.service';
import { LoaderService } from '../components/loading/loader.service';

@Injectable()
export class PrintTemplateService {
  patientInfo;
  paymentInfo;
  medicalCoverageInfo: Array<MedicalCoverage> = [];

  clinicName;
  logo;
  title;

  consultDoctor;
  consultDoctorName;
  consultDoctorSpeciality;

  currentUserName;

  lineBreakDivTag = '<div class="section-avoid-break">';
  closingDivTag = '</div>';

  bsModalRef: BsModalRef;
  stockItemInvalidPipe = new StockItemInvalidPipe();

  constructor(
    private apiPatientVisitService: ApiPatientVisitService,
    private permissionsService: NgxPermissionsService,
    private apiCmsManagementService: ApiCmsManagementService,
    private apiCaseManageService: ApiCaseManagerService,
    private apiMwocService: ApiMwocService,
    private alertService: AlertService,
    private store: StoreService,
    private vmService: VisitManagementService,
    private akitaClinicQuery: AkitaClinicQuery,
    private akitaAppQuery: AkitaAppQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private loaderService: LoaderService
  ) {
    this.medicalCoverageInfo = this.store.medicalCoverageList;
    this.patientInfo = this.store.getCurrentPatientInfo();
  }

  ngOnInit() { }

  // 1) Medical Coverage
  onBtnPrintMedicalCoverageLabelClicked(
    medicalCoverageLabelListByCompany,
    isPreview = false
  ) {
    const template = 'MEDICAL_COVERAGE_LABEL';
    const clinicId = this.store.getClinicId();

    const companyNameList = Object.keys(medicalCoverageLabelListByCompany);
    const medCoverageList = companyNameList.map(function (companyName) {
      let medCoverage = {};
      medCoverage['companyName'] = companyName;
      medCoverage['address'] =
        medicalCoverageLabelListByCompany[companyName].address;
      medCoverage['postalCode'] =
        medicalCoverageLabelListByCompany[companyName].postalCode;

      return medCoverage;
    });

    this.apiCmsManagementService
      .printMedicalCoverages(medCoverageList, clinicId, template)
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(JSON.stringify(err.error.message))
      );
  }

  // 2) Patient Label
  onBtnPrintPatientLabelClicked(isPreview = false, patientId = null) {
    const template = 'PATIENT_LABEL';
    patientId = patientId || this.akitaPatientAppQuery.getValue().patientInfo.id;
    this.apiPatientVisitService.printPatientLabel(this.akitaAppQuery.getAppClinicId(), patientId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }

  // 2.1) Patient Label with QR Code
  onBtnPrintPatientLabelWithQrCodeClicked(isPreview = false) {
    const template = 'PATIENT_LABEL';
    const patient = this.akitaPatientAppQuery.getValue().patientInfo;
    this.apiPatientVisitService.printPatientLabelWithQrCode(this.akitaAppQuery.getAppClinicId(), patient.id, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }

  // 2.2) Patient lab request form
  onBtnPrintLabRequestFormClicked(isPreview = false) {
    const patientVisitRegistryId = this.store.getPatientVisitRegistryId();
    this.apiPatientVisitService
      .printLabRequestForm(patientVisitRegistryId)
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(err.error.message)
      );
  }

  // 2.3) Patient Label with QR Code
  onBtnPrintPatientLabelWithQrCodeClickedInForm(
    visitId: string,
    isPreview = false,
    qrCode?: boolean
  ) {
    const template = 'PATIENT_LABEL';
    const patient = this.akitaPatientAppQuery.getValue().patientInfo;
    this.apiPatientVisitService.printPatientLabelWithQrCodeInForm(visitId, template, qrCode).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }

  // 2.4) Patient lab request form
  onBtnPrintLabRequestFormInVisitRowClicked(
    visitId: string,
    isPreview = false
  ) {
    this.apiPatientVisitService.printLabRequestForm(visitId).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => {
        this.alertService.error(err.error.message)
      }
    );
  }
  printNewBorn(isPreview = false, patientId = null) {
    const template = 'NewBorn';
    patientId = patientId || this.akitaPatientAppQuery.getValue().patientInfo.id;
    this.apiPatientVisitService.printNewBorn(this.akitaAppQuery.getAppClinicId(), patientId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }

  // 2.5) Address
  onBtnPrintPatientLabelAddressClicked(isPreview = false, patientId = null) {
    const template = 'PATIENT_LABEL_ADDRESS';
    patientId = patientId || this.akitaPatientAppQuery.getValue().patientInfo.id;
    this.apiPatientVisitService.printPatientLabel(this.akitaAppQuery.getAppClinicId(), patientId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }
  // 2.6) Pediatrics
  onBtnPrintPatientLabelPediatricsClicked(isPreview = false, patientId = null) {
    const template = 'PATIENT_LABEL_PEDIATRICS';
    patientId = patientId || this.akitaPatientAppQuery.getValue().patientInfo.id;
    this.apiPatientVisitService.printPatientLabel(this.akitaAppQuery.getAppClinicId(), patientId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }
  // 2.7) NPHS
  onBtnPrintPatientLabelNPHSClicked(isPreview = false, patientId = null) {
    const template = 'PATIENT_LABEL_NPHS';
    patientId = patientId || this.akitaPatientAppQuery.getValue().patientInfo.id;
    this.apiPatientVisitService.printPatientLabel(this.akitaAppQuery.getAppClinicId(), patientId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }

  onBtnPrintPatientLabelNPHSwithQrCodeClicked(isPreview = false, patientId = null) {
    const template = 'PATIENT_LABEL_NPHS';
    patientId = patientId || this.akitaPatientAppQuery.getValue().patientInfo.id;
    this.apiPatientVisitService.printPatientLabelQrCode(this.akitaAppQuery.getAppClinicId(), patientId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }
  // 3) Drug Label
  onBtnPrintDrugLabelClicked(drugs, isPreview = false, visitId = null) {
    const template = 'DRUG_LABEL';
    const patientVisitRegistryId = !!visitId ? visitId : this.store.getPatientVisitRegistryId();
    this.apiPatientVisitService
      .printDrugLabels(patientVisitRegistryId, drugs, template)
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(JSON.stringify(err.error.message))
      );
  }

  onBtnPrintDrugItemClicked(drugs, isPreview = false, visitId = null) {
    const template = 'DRUG_ITEM';
    const patientVisitRegistryId = !!visitId ? visitId : this.store.getPatientVisitRegistryId();
    this.apiPatientVisitService
      .printDrugLabels(patientVisitRegistryId, drugs, template)
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(JSON.stringify(err.error.message))
      );
  }

  onBtnPrintAppointmentClicked(searchParams, isPreview = false, visitId = null) {
    const template = 'APPOINTMENT_PRINT_LABEL';
    this.apiPatientVisitService
      .printAppointmentLabels(searchParams, template)
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/<p>/g, '').replace(/\n|<\/p>/g, '<br>');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => {
          alert(err.error.message)
        }
      );
  }

  onBtnPrintDrugLabelAfterSaving(itemsFormArray?: FormArray, includePackageSubItems?: boolean) {
    const checkedDrugItems = this.filterCheckedDrugItems(itemsFormArray, includePackageSubItems);

    this.vmService.getToPrint()
      .pipe(take(1))
      .subscribe(() => {

        this.onBtnPrintDrugLabelClicked(checkedDrugItems);
      });
    this.vmService.setSaveDispensing(
      new RefreshSetting(true, false, false, true, true)
    );
  }

  filterCheckedDrugItems(itemsFormArray?: FormArray, includePackageSubItems?: boolean) {

    // Add in loose items
    const checkedItems = itemsFormArray ? itemsFormArray.getRawValue().filter(item => {
      return item.isChecked && item.drugId != '';
    }) : [];
    const checkedDrugItems = checkedItems.filter(item => {
      const itemDetailedInfo = this.akitaChargeItemQuery.getChargeItem(
        item.drugId
      );

      return itemDetailedInfo.printDrugLabel;
      // return itemDetailedInfo.itemType === 'DRUG';
    });

    // Add in package
    if (includePackageSubItems) {
      const packageItems: IPackageItem[] = this.akitaCaseVisitQuery.getActivePackages();
      packageItems.forEach((p: IPackageItem) => {
        p.dispatches.forEach((d: IDispaches) => {
          if (d.utilize && (d.visitId === this.akitaCaseVisitQuery.getActiveVisitId())) {
            const itemDetailedInfo = this.akitaChargeItemQuery.getChargeItem(
              d.itemId
            );
            if (itemDetailedInfo.itemType === 'DRUG') {
              checkedDrugItems.push(d);
            }
          }
        })
      })
    }

    return checkedDrugItems;
  }

  printDrugLabel(patientVisitRegistryId, drugs, isPreview = false) {
    const template = 'DRUG_LABEL';

    this.apiPatientVisitService
      .printDrugLabels(patientVisitRegistryId, drugs, template)
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(JSON.stringify(err.error.message))
      );
  }

  configLine(price: number, name: string) {
    return [{ name: name, price: price }];
  }
  getCharge(arr: Array<any>) {
    return arr.reduce((sum, obj) => (sum += this.getCalculatedPrice(obj)), 0);
  }

  toPrintSection(price, itemArray: Array<any>) {
    return price > 0 || itemArray.length > 0;
  }

  onPrintBillReceipt(paymentInfo, receiptType, draft, visitId?: string, isPreview = false) {
    this.paymentInfo = paymentInfo;

    let billTemplate;
    if (receiptType == 'general') {
      billTemplate = 'BILL_GENERAL';
    } else if (receiptType == 'breakdown') {
      billTemplate = 'BILL_BREAKDOWN';
    } else if (receiptType == 'categorised') {
      billTemplate = 'BILL_CATEGORISED';
    } else if (receiptType == 'directInvoice') {
      billTemplate = 'DIRECT_INVOICE';
    }

    this.apiPatientVisitService.printBill(
      visitId || this.store.getPatientVisitRegistryId(),
      receiptType,
      draft,
      billTemplate
    ).subscribe(res => {
      let formattedContent = res.payload.template.replace(/\\n/g, '\n');
      formattedContent = formattedContent.replace(/\\"/g, '"');
      this.printTemplate(formattedContent, isPreview);
    }, err => this.alertService.error(JSON.stringify(err.error.message)));
  }

  onEmailBillReceipt(paymentInfo, receiptType, draft, email: string) {
    this.paymentInfo = paymentInfo;
    this.loaderService.setLoading(true);

    let billTemplate;
    if (receiptType == 'general') {
      billTemplate = 'BILL_GENERAL';
    } else if (receiptType == 'breakdown') {
      billTemplate = 'BILL_BREAKDOWN';
    } else if (receiptType == 'categorised') {
      billTemplate = 'BILL_CATEGORISED';
    } else if (receiptType == 'directInvoice') {
      billTemplate = 'DIRECT_INVOICE';
    }

    this.apiPatientVisitService.emailBill(
      this.store.getPatientVisitRegistryId(),
      receiptType,
      draft,
      billTemplate,
      email
    ).subscribe(res => {
      this.loaderService.setLoading(false);
      this.alertService.success('Email send successfully');
    }, err => {
      this.loaderService.setLoading(false);
      this.alertService.error(JSON.stringify(err.error.message));
    });
  }

  onPrintCaseBillReceipt(caseId, receiptType, draft, isPreview = false) {
    let template;
    if (receiptType === 'general') template = 'BILL_GENERAL';
    if (receiptType === 'breakdown') template = 'BILL_BREAKDOWN';
    if (receiptType === 'categorised') template = 'BILL_CATEGORISED';
    if (receiptType === 'corporateTpaBreakdown') template = 'CORP_TPA_BREAKDOWN_BILL'

    this.apiPatientVisitService.printCaseBill(caseId, receiptType, draft, template).subscribe(res => {
      let formattedContent = res.payload.template.replace(/\\n/g, '\n');
      formattedContent = formattedContent.replace(/\\"/g, '"');
      this.printTemplate(formattedContent, isPreview);
    }, err => this.alertService.error(JSON.stringify(err.error.message)))
  }

  onEmailCaseBillReceipt(caseId, receiptType, draft, email) {
    this.loaderService.setLoading(true);
    let template;
    if (receiptType === 'general') template = 'BILL_GENERAL';
    if (receiptType === 'breakdown') template = 'BILL_BREAKDOWN';
    if (receiptType === 'categorised') template = 'BILL_CATEGORISED';

    if (receiptType === 'directInvoice') {
      this.onEmailDirectClaims(caseId, 'directInvoice', false, 'DIRECT_INVOICE', email);
    } else {
      this.apiPatientVisitService.emailCaseBill(caseId, receiptType, draft, template, email).subscribe(res => {
        this.loaderService.setLoading(false);
        this.alertService.success('Email send successfully');
      }, err => {
        this.loaderService.setLoading(false);
        this.alertService.error(JSON.stringify(err.error.message))
      })
    }
  }

  onPrintManualClaims(
    caseId: string,
    receiptType = 'FORMAL',
    isDraft = false,
    templateName = 'MANUAL_BILL',
    isPreview = false
  ) {
    this.apiCaseManageService.printManualClaimsBill(caseId, receiptType, isDraft, templateName).subscribe(res => {
      let formattedContent = res.payload.template.replace(/\\n/g, '\n');
      formattedContent = formattedContent.replace(/\\"/g, '"');
      this.printTemplate(formattedContent, isPreview);
    }, err => this.alertService.error(JSON.stringify(err.error.message)));
  }
  onPrintDirectClaims(
    caseId: string,
    receiptType = 'directInvoice',
    isDraft = false,
    templateName = 'DIRECT_INVOICE',
    isPreview = false
  ) {
    this.apiCaseManageService.printManualClaimsBill(caseId, receiptType, isDraft, templateName).subscribe(res => {
      let formattedContent = res.payload.template.replace(/\\n/g, '\n');
      formattedContent = formattedContent.replace(/\\"/g, '"');
      this.printTemplate(formattedContent, isPreview);
    }, err => this.alertService.error(JSON.stringify(err.error.message)));
  }

  onEmailDirectClaims(
    caseId: string,
    receiptType = 'directInvoice',
    isDraft = false,
    templateName = 'DIRECT_INVOICE',
    email
  ) {
    this.apiCaseManageService.emailManualClaimsBill(caseId, receiptType, isDraft, templateName, email).subscribe(res => {
      this.loaderService.setLoading(false);
      this.alertService.success('Email send successfully');
    }, err => {
      this.loaderService.setLoading(false);
      this.alertService.error(JSON.stringify(err.error.message));
    });
  }

  onSendEmail(
    caseId: string,
    receiptType
  ) {
    this.loaderService.setLoading(true);
    let templateName;
    if (receiptType === 'general') templateName = 'BILL_GENERAL';
    if (receiptType === 'breakdown') templateName = 'BILL_BREAKDOWN';
    if (receiptType === 'categorised') templateName = 'BILL_CATEGORISED';
    if (receiptType === 'directInvoice') templateName = 'DIRECT_INVOICE';

    this.apiCaseManageService.sendBillEmail(caseId, receiptType, false, templateName)
      .subscribe(res => {
        this.loaderService.setLoading(false);
        this.alertService.success('Email send successfully');
      }, err => {
        this.loaderService.setLoading(false);
        this.alertService.error(JSON.stringify(err.error.message));
      });
  }

  printDiscountByPlans(plansWithDiscount) {
    let str = '';
    plansWithDiscount.forEach(payment => {
      // const planName = this.getCoverageName(payment.planId);
      const planName = this.getPlanName(payment.planId);
      str += this.mapToHtmlBoldNameAndValue([
        {
          name: planName,
          price: -payment.salesDiscount.discountValue,
        },
      ]);
    });
    return str;
  }

  getValuesOfDiscountedPlan(creditPayments) {
    const totalDiscountValue = creditPayments.reduce((disc, payment) => {
      if (payment.salesDiscount && payment.salesDiscount.discountValue > 0) {
        disc += payment.salesDiscount.discountValue;
      }
      return disc;
    }, 0);

    const totalDiscount: Discount = new Discount();
    totalDiscount.discountValue = totalDiscountValue;
    return totalDiscount;
  }

  // 5) Vaccination Certificate
  onBtnPrintVCClicked(patient, vaccines, isPreview = false) {
    const template = 'VACCINATION_CERTIFICATE';
    // const vaccinationSelected = patient.patientVaccinations.filter(v => v.isSelected === true);
    const vaccinationSelected = vaccines;
    const patientId = patient.id;

    this.apiPatientVisitService
      .printVaccinationCertificate(
        vaccinationSelected,
        template,
        patientId,
        this.akitaAppQuery.getAppClinicId()
      )
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(JSON.stringify(err.error.message))
      );
  }

  // 6) Referral Letter
  onBtnPrintReferralClicked(
    consultation,
    patientReferrals,
    visitId?: string,
    isPreview = false
  ) {

    const template = 'REFERRAL_LETTER';
    const patientVisitRegistryId = this.store.getPatientVisitRegistryId();
    patientReferrals = [];
    this.apiPatientVisitService
      .printReferralLetters(
        visitId || patientVisitRegistryId,
        patientReferrals,
        template
      )
      .subscribe(
        res => {
          if (!res.payload.length) {
            this.alertService.error("Please save the details first before printing the referrals");
          }
          res.payload.forEach(element => {
            let formattedContent = element.template.replace(/\\n/g, '\n');
            formattedContent = formattedContent.replace(/\\"/g, '"');
            this.printTemplate(formattedContent, isPreview);
          });
        },
        err => {
          let errorMessage = err.error.message;
          if (errorMessage === 'java.lang.NullPointerException') {
            this.alertService.error("Please save the details first before printing the referrals");
          } else this.alertService.error(JSON.stringify(err.error.message))
        }
      );
  }

  // 7) Time Chit
  onBtnPrintTimeChitClicked(isPreview = false, visitId = null) {
    const template = 'TIME_CHIT';
    const patientVisitRegistryId = visitId ? visitId : this.store.getPatientVisitRegistryId();

    this.apiPatientVisitService
      .printTimeChit(patientVisitRegistryId, template)
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(JSON.stringify(err.error.message))
      );
  }

  printTimeChitClicked(visitId, isPreview = false) {
    const template = 'TIME_CHIT';

    this.apiPatientVisitService.printTimeChit(visitId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      }, err => this.alertService.error(JSON.stringify(err.error.message)));
  }

  printHsgPlan(planId, isPreview = false) {
    const template = 'HSG_PLAN_REPORT';

    this.apiPatientVisitService.printHsgPlan(planId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      }, err => this.alertService.error(JSON.stringify(err.error.message)));
  }

  onBtnPrintDeliveryNotesClicked(visitId: string, isPreview = false) {
    const template = 'DRUG_DELIVERY';

    // const patientVisitRegistryId = !!visitId ? visitId : this.store.getPatientVisitRegistryId();

    this.apiPatientVisitService.printDeliveryNotes(visitId, template).subscribe(
      res => {
        let formattedContent = res.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      },
      err => this.alertService.error(JSON.stringify(err.error.message))
    );
  }

  // 8) Memo
  onBtnPrintMemoClicked(consultation, memoNotes, visitId?: string, isPreview = false) {
    const template = 'MEMO';
    const patientVisitRegistryId = this.store.getPatientVisitRegistryId();

    this.apiPatientVisitService.printConsultationMemo(visitId || patientVisitRegistryId, template).subscribe(res => {
      res.payload.forEach(element => {
        let formattedContent = element.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      });
    }, err => this.alertService.error(JSON.stringify(err.error.message)));
  }

  // 9) Medical Certificate
  onBtnPrintMCClicked(
    medicalCertificates,
    visitId?: string,
    isPreview = false
  ) {
    const certificateRefNumList = medicalCertificates.map(function (
      medicalCertificate
    ) {
      return medicalCertificate.referenceNumber;
    });

    const template = 'MEDICAL_CERTIFICATE';
    const patientVisitRegistryId = this.store.getPatientVisitRegistryId();

    this.apiCmsManagementService
      .printMedicalCertificate(
        certificateRefNumList,
        visitId || patientVisitRegistryId,
        template
      )
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(JSON.stringify(err.error.message))
      );
  }

  // 10) stock take
  printStockTakeList(items: Array<StockTakeItem>) {
    this.apiCmsManagementService.searchLabel('STOCK_TAKE').subscribe(
      res => {
        const template = JSON.parse(res.payload.template);
        const w = window.open();
        w.document.open();

        const html = template.replace(
          '{{items}}',
          items.reduce((html, item) => {
            html += this.mapToHtmlStockTakeItem(item);
            return html;
          }, '')
        );

        w.document.write(html);
        w.document.close();
        w.onload = () => {
          w.window.print();
        };
        if ('matchMedia' in w.window) {
          w.matchMedia('print').addEventListener("change", (media) => {
            if (!media.matches) {
              w.close();
            }
          });
        } else {
          w.onafterprint = function () {
            w.close();
          }
        }
      },
      err => this.alertService.error(JSON.stringify(err.error['message']))
    );
  }

  printCurrentStock(items: Array<StockItem>, itemInfo: Map<string, PurchasableItemLevel>) {
    this.apiCmsManagementService.searchLabel('CURRENT_STOCK').subscribe(res => {
      const template = JSON.parse(res.payload.template);
      const w = window.open();
      w.document.open();

      const data = template.replace("{{items}}", items.reduce((htmlData, item) => {
        htmlData += this.mapToHtmlCurrentStockTakeItem(item, itemInfo);
        return htmlData;
      }, ''));

      w.document.write(data);
      w.document.close();
      w.onload = () => {
        w.window.print();
      };
      if ('matchMedia' in w.window) {
        w.matchMedia('print').addEventListener("change", (media) => {
          if (!media.matches) {
            w.close();
          }
        });
      } else {
        w.onafterprint = function () {
          w.close();
        }
      }
    }, err => this.alertService.error(JSON.stringify(err.error['message'])));
  }

  // 11) Covid-19 Certificate
  onBtnPrintCovid19Cardlicked(patient, vaccines, isPreview = false) {
    const template = 'VACCINATION_CERTIFICATE_COVID';
    const vaccinationSelected = vaccines;
    const patientId = patient.id;

    this.apiPatientVisitService
      .printVaccinationCertificate(
        vaccinationSelected,
        template,
        patientId,
        this.akitaAppQuery.getAppClinicId()
      )
      .subscribe(
        res => {
          let formattedContent = res.payload.template.replace(/\\n/g, '\n');
          formattedContent = formattedContent.replace(/\\"/g, '"');
          this.printTemplate(formattedContent, isPreview);
        },
        err => this.alertService.error(JSON.stringify(err.error.message))
      );
  }

  // 12) MWOC Print Patient Label
  public printMWOCPatientLabel(payload: any): void {
    this.loaderService.setLoading(true);
    const template = 'PATIENT_LABEL_MWOC';
    const isPreview = false;
    payload.templateName = template;

    this.apiMwocService.printPatientLabel(payload).subscribe(result => {
      if (result.statusCode === 'S0000') {
        this.loaderService.setLoading(false);
        let formattedContent = result.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      }
    }, err => {
      this.loaderService.setLoading(false);
      this.alertService.error(err.error.message);
    });
  }

  // 13) MWOC Print Lab Request Form
  public printMWOCLabRequestForm(payload: any): void {
    this.loaderService.setLoading(true);
    const isPreview = false;

    this.apiMwocService.printLabReqeustForm(payload).subscribe(result => {
      if (result.statusCode === 'S0000') {
        this.loaderService.setLoading(false);
        let formattedContent = result.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      }
    }, err => {
      this.loaderService.setLoading(false);
      this.alertService.error(err.error.message);
    });
  }

  // 14) Print Refund Chit
  public printRefundChit(payload: any): void {
    const isPreview = false;

    this.apiCaseManageService.printRefundChit(payload.refundId, payload.templateName).subscribe(result => {
      if (result.statusCode === 'S0000') {
        let formattedContent = result.payload.template.replace(/\\n/g, '\n');
        formattedContent = formattedContent.replace(/\\"/g, '"');
        this.printTemplate(formattedContent, isPreview);
      }
    }, err => {
      this.loaderService.setLoading(false);
      this.alertService.error(err.error.message);
    });
  }


  mapToHtmlStockTakeItem(item: StockTakeItem) {
    return `<div class="row border-bottom border-left border-right">
    <div class="col-2 py-2 border-right">
      ${item.itemCode}
    </div>
    <div class="col-3 py-2 border-right">
    ${item.itemName}
    </div>
    <div class="col-2 py-2 border-right">
    ${item.batchNumber || ''}
    </div>
    <div class="col-2 py-2 border-right">
    ${item.expiryDate || ''}
    </div>
    <div class="col-1 py-2 border-right">
    ${item.baseUom || ''}
    </div>
    <div class="col-2 py-2">
    </div>
  </div>`;
  }

  mapToHtmlCurrentStockTakeItem(item: StockItem, itemInfo: Map<string, PurchasableItemLevel>) {
    return `<tr class="font-weight-semi-bold">
      <td colspan="1">${item.itemCode}</td>
      <td colspan="3">${item.itemName}</td>
      <td colspan="2">${item.batchNumber || ''}</td>
      <td colspan="2">${this.stockItemInvalidPipe.transform(item.expiryDate)}</td>
      <td colspan="1">${item.baseUomQuantity}</td>
      <td colspan="1">${itemInfo.get(item.itemRefId).reorderPoint}</td>
      <td colspan="1">${item.baseUom}</td>
      <td colspan="1">${itemInfo.get(item.itemRefId) && itemInfo.get(item.itemRefId).lowStockLevel ? 'Stock Low' : ''}</td>
    </tr>`
  }

  getTitle() {
    // return this.isSilverCrossGroup() ? SILVER_CROSS_CLINIC_LETTERHEAD : DEFAULT_TEMPLATE_CLINIC_LETTERHEAD;
    return this.akitaClinicQuery.getCurrentClinic().name;
  }

  getClinicName() {
    return this.akitaClinicQuery.getCurrentClinic().name;
  }

  getLogo() {
    const logo_fileName = this.akitaClinicQuery.getCurrentClinic().clinicLogo
      ? this.akitaClinicQuery.getCurrentClinic().clinicLogo
      : '';
    const logo_htmlTag =
      logo_fileName !== ''
        ? `<img src="/assets/img/${logo_fileName}" class="img-fluid"></img>`
        : '';


    return logo_htmlTag;
  }

  getClinicAddress() {
    return `${this.akitaClinicQuery
      .getCurrentClinic()
      .address.address.replace('\\n', '<br>')
      .toUpperCase() || ''}, SINGAPORE ${this.akitaClinicQuery.getCurrentClinic().address.postalCode
      }`;
  }

  isSilverCrossGroup() {
    return (
      this.akitaClinicQuery.getCurrentClinic().groupName === SILVER_CROSS_GROUP
    );
  }

  getAllergies() {
    const patient = this.akitaPatientAppQuery.getValue().patientInfo;
    return patient.allergies && patient.allergies.length
      ? patient.allergies.map(allergy => allergy.name).join(', ')
      : 'NIL';
  }

  groupMedicalCoverageByCompanyName(medicalCoverageArray: FormArray) {
    const medicalCoverageLabelList = [];
    medicalCoverageArray.controls.map(mc => {
      const mcControl: FormGroup = <FormGroup>mc;
      const name = this.getCompanyName(mcControl);
      if (name) {
        medicalCoverageLabelList[name] = {
          address: this.getCompanyAddress(mcControl),
          postalCode: this.getCompanyPostalCode(mcControl),
        };
      }
    });
    return medicalCoverageLabelList;
  }

  getCurrentDateInDisplayDateFormat() {
    return moment().format(DISPLAY_DATE_FORMAT);
  }

  unstringifyJson(template) {
    return JSON.parse(template);
  }

  getCompanyAddress(medicalCoverage: FormGroup) {
    return medicalCoverage.get('coverageSelected').value.address.address || '-';
  }

  getCompanyPostalCode(medicalCoverage: FormGroup) {
    return medicalCoverage.get('coverageSelected').value.address.postalCode
      ? 'SINGAPORE ' +
      medicalCoverage.get('coverageSelected').value.address.postalCode
      : '';
  }

  getCompanyName(medicalCoverage: FormGroup) {
    return medicalCoverage.get('coverageSelected').value.name;
  }

  appendFullStop(str: string) {
    if (str.charAt(str.length - 1) !== '.') {
      return str + '.';
    } else {
      return str;
    }
  }

  getDoseName(doseId) {
    let doseName = '';

    const vaccine = this.store.vaccinationList.find(function (vaccine) {
      return vaccine.dosages
        ? vaccine.dosages.some(dose => dose.doseId === doseId)
        : null;
    });

    if (vaccine) {
      doseName =
        (vaccine.dosages.find(dose => dose.doseId === doseId) || { name: '' })
          .name || '';
    } else {
      doseName = '';
    }
    return doseName;
  }

  selectedCert($event) { }

  getVaccineName(vaccineId) {
    let vaccineName = '';

    if (this.store.vaccinationList) {
      vaccineName =
        (
          this.store.vaccinationList.find(
            vaccine => vaccine.id === vaccineId
          ) || { name: '' }
        ).name || '';
    }
    return vaccineName;
  }

  setConsultDoctor(doctorId) {
    this.consultDoctor = this.store
      .getDoctorList()
      .find(doctor => doctor.id === doctorId);
    const doctorMcr =
      this.consultDoctor && this.consultDoctor.doctorGroup === 'LOCUM'
        ? ''
        : this.consultDoctor.mcr;
    this.consultDoctorName = doctorMcr
      ? this.consultDoctor.name + ' (' + doctorMcr + ')'
      : this.consultDoctor.name;
    this.consultDoctorSpeciality = this.consultDoctor.speciality
      ? this.consultDoctor.speciality
      : '';
  }

  setCurrentUserName() {
    if (
      this.permissionsService.getPermission('ROLE_DOCTOR') &&
      !this.permissionsService.getPermission('ROLE_CA')
    ) {
      // Doctor Role
      this.currentUserName = this.consultDoctor.name;
    } else {
      // CA / Other Rolew
      const currentUser = this.store.getUser();
      this.currentUserName = currentUser.lastName
        ? currentUser.firstName + ' ' + currentUser.lastName
        : currentUser.firstName;
    }
  }

  getDoctorConsultName(doctorId) {
    const consultDoctor = this.store
      .getDoctorList()
      .find(doctor => doctor.id === doctorId);
    const doctorMcr =
      consultDoctor && consultDoctor.doctorGroup === 'LOCUM'
        ? ''
        : consultDoctor.mcr;
    return doctorMcr
      ? consultDoctor.name + ' (' + doctorMcr + ')'
      : consultDoctor.name;
  }

  getChasMedisavePaymentNumber(creditPayments) {
    const result = [];
    creditPayments.map((value, index) => {
      if (value.payableAmount > 0) {
        const coverage = this.store.getMedicalCoverageByPlanId(value.planId);

        if (coverage.type === 'CHAS') {
          result['CHAS'] = this.mapRowToHtml(
            `CHAS Payment No. ${value.invoiceId}`
          );
        } else if (coverage.type === 'MEDISAVE') {
          result['MEDISAVE'] = this.mapRowToHtml(
            `MEDISAVE Payment No. ${value.invoiceId}`
          );
        }
      }
    });

    return result;
  }

  displayPaymentInfo(
    draft,
    creditPayments,
    directPayments,
    checkIsSummaryOrBreakdown
  ) {
    const CASH_UPPER = 'CASH';
    let paymentModeTopSection = '';
    let payByFooterSection = '';
    const directPaymentInfos = directPayments.reduce(
      (array, payment) => array.concat(payment.paymentInfos),
      []
    );
    const sumFromDirectPayments = directPaymentInfos.reduce(
      (sum, obj) => (sum += obj.amount),
      0
    );

    creditPayments.forEach(payment => {
      if (payment.payableAmount > 0) {
        const coverageName = this.getCoverageName(payment.planId);
        // const coverageName = this.getPlanName(payment.planId);

        paymentModeTopSection +=
          paymentModeTopSection === '' ? coverageName : ' / ' + coverageName;
        payByFooterSection += this.mapToHtmlBoldNameAndValue(
          this.configLine(payment.payableAmount, 'PAY BY ' + coverageName)
        );
      }
    });

    if (!draft) {
      if (
        directPayments.length === 1 &&
        directPayments[0].paymentInfos.length === 1 &&
        directPayments[0].paymentInfos[0].billMode === CASH_UPPER &&
        directPayments[0].paymentInfos[0].cashAdjustment > 0
      ) {
        // Pay By Cash Only
        paymentModeTopSection +=
          paymentModeTopSection === '' ? CASH_UPPER : ` / ${CASH_UPPER}`;
        payByFooterSection +=
          this.mapToHtmlAdjustment(
            this.configLine(
              directPayments[0].paymentInfos[0].cashAdjustment,
              'ADJUSTMENT'
            )
          ) + this.mapToHtmlDisplayAdjustment(sumFromDirectPayments);
      } else {
        directPaymentInfos.forEach((paymentInfo, counter) => {
          let billMode: string = paymentInfo.billMode;
          billMode = billMode.replace(/_/g, ' ');
          const formattedBillMode = this.formatChequePaymentDisplay(
            billMode,
            paymentInfo.externalTransactionId
          );

          paymentModeTopSection +=
            paymentModeTopSection === ''
              ? formattedBillMode
              : ' / ' + formattedBillMode;
          payByFooterSection += this.mapToHtmlBoldNameAndValue(
            this.configLine(paymentInfo.amount, 'PAY BY ' + billMode)
          );
        });
      }
    } else {
      // DRAFT RECEIPT
      payByFooterSection += this.mapToHtmlBoldNameAndValue(
        this.configLine(
          directPayments.reduce(
            (sum, obj) => (sum += obj.payableAmount - obj.paidAmount),
            0
          ),
          'OUTSTANDING BALANCE'
        )
      );
    }

    return checkIsSummaryOrBreakdown === 'summary'
      ? paymentModeTopSection
      : payByFooterSection;
  }

  formatChequePaymentDisplay(billMode, transactionId: string) {
    if (billMode === 'CHEQUE') {
      if (transactionId.includes(':')) {
        transactionId = transactionId.replace(':', ' - ');
      }
      return billMode + ' ( ' + transactionId + ' )';
    } else {
      return billMode;
    }
  }

  getCoverageName(planId) {
    this.medicalCoverageInfo = this.store.medicalCoverageList;
    const coverage = this.medicalCoverageInfo.find(function (x) {
      return x.coveragePlans.some(plan => plan.id === planId);
    });
    return coverage.name.toUpperCase();
  }

  getPlanName(planId) {
    const coverage = this.store.getPlanByPlanId(planId);

    return coverage ? coverage.name.toUpperCase() : '';
  }

  updateLabelTemplate(id, templateName, template) {
    this.apiCmsManagementService
      .updateLabel(id, templateName, JSON.stringify(template))
      .subscribe(res => { }, err => console.error(err));
  }

  mapToHtml(array) {
    return array.map(
      obj => `<div class="row">
        <div class="col-6">
          ${obj.name}
        </div>
        <div class="col-4">
          <span class="float-right">
            $${(obj.price / 100).toFixed(2)}
          </span>
        </div>
      </div>`
    );
  }

  mapToHtmlAdjustment(array) {
    return array.map(
      obj => `<div class="row">
        <div class="col-6">
          ${obj.name}
        </div>
        <div class="col-6">
          <span class="float-right">
            ($${(obj.price / 100).toFixed(2)})
          </span>
        </div>
      </div>`
    );
  }

  mapToHtmlNoBold(array) {
    return array.map(
      obj => `<div class="row">
        <div class="col-6">
          ${obj.name}
        </div>
        <div class="col-6">
          <span class="float-right">
            $${(obj.price / 100).toFixed(2)}
          </span>
        </div>
      </div>`
    );
  }

  mapToHtmlBoldNameAndValue(array) {
    return array.map(obj => {
      let priceStr = '';
      if (obj.price >= 0) {
        priceStr = '$' + (obj.price / 100).toFixed(2);
      } else if (obj.price < 0) {
        priceStr = '-$' + (Math.abs(obj.price) / 100).toFixed(2);
      }

      return `<div class="row">
              <div class="col-6">
                <strong>
                  ${obj.name}
                </strong>
              </div>
              <div class="col-6">
                <strong>
                  <span class="float-right">
                    ${priceStr}
                  </span>
                </strong>
              </div>
            </div>`;
    });
  }

  mapToHtmlDisplayAdjustment(price) {
    return `<div class="row">
              <div class="col-6">
                <span>
                  <strong>PAY BY CASH </strong><small>(AFTER ADJUSTMENT)</small>
                </span>
              </div>
              <div class="col-6">
                  <span class="float-right">
                    <strong>
                      $${(price / 100).toFixed(2)}
                    </strong>
                  </span>
              </div>
            </div>`;
  }

  mapRowToHtml(data: string) {
    return `<div class="row">${data}</div>`;
  }

  printTemplate(template: string, isPreview = false) {
    const openWindowBtn = document.createElement('BUTTON');
    openWindowBtn.onclick = () => {
      const w = window.open();
      w.document.open();
      w.document.write(template);
      w.document.close();
      if (!isPreview) {
        w.onload = () => {
          w.window.print();
        };
        if ('matchMedia' in w.window) {
          w.matchMedia('print').addEventListener("change", (media) => {
            if (!media.matches) {
              w.close();
            }
          });
        } else {
          w.onafterprint = function () {
            w.close();
          }
        }
      }
    };
    openWindowBtn.click();
  }

  // Calculate discounted price
  getCalculatedPrice(item) {
    return item.soldPrice || 0;
  }

  updateBill() {
    this.apiCmsManagementService
      .updateLabel(
        '5ae03f77dbea1b12fe7d6685',
        'BILL',
        JSON.stringify(billTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }
  updateDrug() {
    this.apiCmsManagementService
      .updateLabel(
        '5ae03f77dbea1b12fe7d6686',
        'DRUG_LABEL',
        JSON.stringify(drugLabelTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }

  updateMC() {
    this.apiCmsManagementService
      .updateLabel(
        '5ae03f77dbea1b12fe7d6687',
        'MEDICAL_CERTIFICATE',
        JSON.stringify(medicalCertificateTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }

  updateCoverageLabel() {
    this.apiCmsManagementService
      .updateLabel(
        '5c9d8bb998821567017d2731',
        'MEDICAL_COVERAGE_LABEL',
        JSON.stringify(medicalCoverageLabelTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }
  updateMemo() {
    this.apiCmsManagementService
      .updateLabel(
        '5b1f9ef8bf8d8d03a16fd8ed',
        'MEMO',
        JSON.stringify(memoTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }
  updatePatientLabel() {
    this.apiCmsManagementService
      .updateLabel(
        '5ae14ab0dbea1b35bbaa310e',
        'PATIENT_LABEL',
        JSON.stringify(patientLabelTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }

  updateReferral() {
    this.apiCmsManagementService
      .updateLabel(
        '5ae03f77dbea1b12fe7d668a',
        'REFERRAL_LETTER',
        JSON.stringify(refferalLetterTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }
  updateTimeChit() {
    this.apiCmsManagementService
      .updateLabel(
        '5ae03f77dbea1b12fe7d6689',
        'TIME_CHIT',
        JSON.stringify(timeChitTemplate)
      )
      .subscribe(
        res => {
          console.log(res);
        },
        err => console.log(err)
      );
  }
  updateVaccineCert() {
    this.apiCmsManagementService
      .updateLabel(
        '5ae2ae6bdbea1b35bbaa310f',
        'VACCINATION_CERTIFICATE',
        JSON.stringify(vaccinationCertificateTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }

  updateStockTake() {
    this.apiCmsManagementService
      .updateLabel(
        '5d809ff4dbea1b3761ff2bad',
        'STOCK_TAKE',
        JSON.stringify(stockTakeTemplate)
      )
      .subscribe(
        res => {

        },
        err => console.log(err)
      );
  }
}
