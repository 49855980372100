import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { Vaccination } from '../../objects/state/Vaccination';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'vaccination',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaVaccinationStore extends EntityStore<Vaccination> {
  constructor() {
    super();
  }
}
