import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { Instruction } from '../../objects/state/Instruction';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'instruction',
  idKey: 'code',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaInstructionStore extends EntityStore<Instruction> {
  constructor() {
    super();
  }
}
