import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { ApiHsgService } from '../../../../../services/api-hsg.service';
import { PatientHsgService } from '../services/patient-hsg.service';

import { AkitaClinicQuery } from '../../../../../services/states/akita-clinic.query';

import { Clinic } from '../../../../../objects/response/Clinic';

import { DATA_SAVED_SUCCESSFULLY, DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT, HSGFORMS, INVENTORY_DATE_FORMAT, PCNFORMS } from '../../../../../constants/app.constants';

import * as moment from 'moment';
import { AkitaPatientStoreService } from '../../../../../services/states/akita-patient/akita-patient-store.service';
import { ActivatedRoute } from '@angular/router';
import { PatientHsgFormService } from '../services/patient-hsg-form.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PatientHsgPlanDeleteComponent } from '../patient-hsg-plan-delete/patient-hsg-plan-delete.component';
import { AlertService } from '../../../../../services/alert.service';
import { FormArray } from '@angular/forms';
import { VisitManagementService } from '../../../../../services/visit-management.service';
import { take } from 'rxjs/operators';
import { PrintTemplateService } from '../../../../../services/print-template.service';
import { StoreService } from '../../../../../services/store.service';
import { LoaderService } from '../../../../../components/loading/loader.service';
import HSGPlan from '../../../../../model/HSGPlan';
import { AkitaAppQuery } from '../../../../../services/states/akita-app.query';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApiPatientInfoService } from '../../../../../services/api-patient-info.service';

@Component({
  selector: 'app-patient-hsg-plan-listing',
  templateUrl: './patient-hsg-plan-listing.component.html',
  styleUrls: ['./patient-hsg-plan-listing.component.scss']
})
export class PatientHsgPlanListingComponent implements OnInit, OnDestroy {
  @Input() patientInfo;
  public moment = moment;

  // public plans: HSGPlan[] = [];
  public plans: HSGPlan[] = [];

  public selectedPlan: HSGPlan | undefined;
  private newPlanSub : Subscription;
  private newPlanAdded: Subscription;
  private selectedGoalsPreviewSub: Subscription;
  private syncPlansSub: Subscription;

  currentPatientId: string;
  plansSyncTime: string;
  public patientConditions = [
    {name: 'Pre Diabetes', code: 'PRE_DIABETES', selected: false},
    {name: 'Diabetes Mellitus', code: 'DIABETES', selected: false},
    {name: 'Hypertension', code: 'HYPERTENSION', selected: false},
    {name: 'Hyperlipidemia (Lipid Disorders)', code: 'LIPID_DISORDERS', selected: false},
  ];
  public selectedConditions: string[] = [];
  public selectedPcnConditions: any[];
  public showConditions: boolean = false;
  public bsModalRef: BsModalRef;
  isHsgEnrolledPatient: boolean = false;
  isHsgClinic: boolean = false;
  hasHSGRole: boolean = false;
  hasHSGProjectRole: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiHSGService: ApiHsgService,
    private patientHSGService: PatientHsgService,
    private patientHsgFormService: PatientHsgFormService,
    private akitaClinicQuery: AkitaClinicQuery,
    private akitaPatientStoreService: AkitaPatientStoreService,
    private modalService: BsModalService,
    private apiHsgService: ApiHsgService,
    private alertService: AlertService,
    private visitManagementService: VisitManagementService,
    private printTemplateService: PrintTemplateService,
    private store: StoreService,
    private loaderService: LoaderService,
    private akitaAppQuery: AkitaAppQuery,
    private permissionsService: NgxPermissionsService,
    private apiPatientInfoService: ApiPatientInfoService,
  ) { }

  ngOnInit() {
    this.isHsgClinic = this.akitaAppQuery.checkClinicFeatureExist('HSG');
    this.hasHSGRole = this.permissionsService.getPermission(
      'ROLE_HSG_ACCESS'
    )
      ? true
      : false;
    this.hasHSGProjectRole = this.permissionsService.getPermission(
        'ROLE_HSG_PROJECT'
      )
        ? true
        : false;
    this.currentPatientId = this.akitaPatientStoreService.patientId || this.activatedRoute.snapshot.paramMap.get('id');
    
    if (!this.patientInfo) {
      this.apiPatientInfoService
      .searchBy('systemuserid', this.currentPatientId)
      .subscribe(
        res => {
          this.isHsgEnrolledPatient = res.payload.hsgEnrollment && res.payload.hsgEnrollment.enrolledClinicId === this.store.getClinicId();
          if (this.isHsgClinic && this.hasHSGProjectRole && this.isHsgEnrolledPatient){
            this.getPlans(true);
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err));
        }
      );
    } else {
      this.isHsgEnrolledPatient = this.patientInfo.hsgEnrollment && this.patientInfo.hsgEnrollment.enrolledClinicId === this.store.getClinicId();
      if (this.isHsgClinic && this.hasHSGRole && this.isHsgEnrolledPatient){
        this.getPlans(true);
      }
    }
    
    this.newPlanAdded = this.patientHSGService.getNewPlanAdded().subscribe({
      next: result => {
        if (this.isHsgClinic && this.hasHSGRole && this.isHsgEnrolledPatient){
          if (result.patientId) {
            this.currentPatientId = result.patientId;
          }
          this.getPlans(!result.newPlanAdded);
        }
      }
    });

    this.activatedRoute.params.subscribe(result => {
      this.loaderService.setLoading(true);
      this.currentPatientId = result.patientId || result.id;
      this.getPlans(true);
    })

    this.selectedGoalsPreviewSub = this.patientHSGService.getSelectedPlanForPreview().subscribe({
      next: result => {
        if(!result) {
          // this.plans.forEach(plan => {
          //   plan.selected = false;
          // });
        }
      }
    });

    this.syncPlansSub = this.patientHSGService.getSyncPlans().subscribe({
      next: result => {
        if(result) {
          this.syncPlans();
        }
      }
    });

    this.newPlanSub = this.patientHSGService.getNewPlan().subscribe({
      next: result => {
        if (result) {
          this.showConditions = true;
          this.plans = this.plans.filter(plan => plan.healthPlanStatus !== 'NEW');
          this.plans = this.plans.map(plan => {
            return {...plan, selected: false}
          });
          this.plans.unshift(result);
        }
      }
    });

    this.visitManagementService
          .getSavedSuccessfully()
          .subscribe(() => {
            if (this.isHsgClinic && this.hasHSGRole && this.isHsgEnrolledPatient){
              this.getPlans(true);
            }
          });
  }

  syncPlans() {
    this.loaderService.setLoading(true);
    this.apiHSGService.syncHsgPlansForPatient(this.store.getClinicId(), this.currentPatientId).subscribe(
      result => {
        if (result && result.payload) {
          if(result.message === 'Success'){
            this.plansSyncTime = result.timestamp;
          }
          this.plans = result.payload;
          this.plans = this.plans.sort((a,b) => {
            return !a.submittedDateTime || moment(a.submittedDateTime).isAfter(moment(b.submittedDateTime)) ? -1 : 1;
          })
        }
        this.loaderService.setLoading(false);
      },
      err => {
        this.alertService.error('Plans loading failed ' + JSON.stringify(err.error));
      }
    );
  }

  getPlans(isInit: boolean = false) {
    this.loaderService.setLoading(true);
    this.patientHsgFormService.resetHsg();
    this.apiHSGService.getHsgPlansForPatient(this.store.getClinicId(), this.currentPatientId).subscribe(
      result => {
        if (result && result.payload) {
          if(result.message === 'Success'){
            this.plansSyncTime = result.timestamp;
          }
          this.plans = result.payload;
          this.plans = this.plans.sort((a,b) => {
            return !a.submittedDateTime || moment(a.submittedDateTime).isAfter(moment(b.submittedDateTime)) ? -1 : 1;
          })
          if (this.plans.length > 0) {
            const draftFailedPlans = this.plans.filter(plan => plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED');
            const submitedPlans = this.plans.filter(plan => plan.healthPlanStatus === 'SUBMIT');
            const latestPlan = this.plans[0];
            this.patientHSGService.setIsAllPlanSubmitted(!(draftFailedPlans && draftFailedPlans.length > 0));
            this.patientHSGService.setShowCopyLastGoal(submitedPlans && submitedPlans.length > 0);
            if (submitedPlans.length > 0) {
              this.patientHSGService.setLatestPlan(submitedPlans[0]);
            }
            
            if (isInit) {
              if (latestPlan.healthPlanStatus !== 'SUBMIT') {
                this.patientHsgFormService.initHsgForm();
                this.patientHsgFormService.patchHsgForm(latestPlan);
                this.onView(latestPlan);
              } else {
                if (this.patientHsgFormService.hsgForm) {
                  const goalsArray = this.patientHsgFormService.getForm(HSGFORMS.GOALFORM).get('healthGoals') as FormArray;
                  if (!goalsArray || goalsArray.controls.length === 0) {
                    this.onView(latestPlan);
                  }
                } else {
                  this.onView(latestPlan);
                }
              }
            }
          } else {
            this.onView(null);
            this.patientHSGService.setShowCopyLastGoal(false);
            this.patientHSGService.setIsAllPlanSubmitted(true);
          }
        }
        this.loaderService.setLoading(false);
      }
    );
  }

  public onView(plan: HSGPlan, event?: MouseEvent): void {
    if (event && (!plan || plan.selected)) {
      event.preventDefault();
      return;
    }
    if (plan) {
      this.plans.forEach(p => {
        if (p.visitId === plan.visitId && p.id === plan.id) {
          p.selected = true;
        } else {
          p.selected = false;
        }
      })
    }

    this.patientHSGService.setGoalsOfSelectedPlan(plan);
  }

  public onPrintPlan(plan: HSGPlan): void {
    this.printTemplateService.printHsgPlan(plan.id);
  }

  public onDeletePlan(plan: HSGPlan): void {
    const initialState = {
      title: 'Delete Plan',
    };
    this.bsModalRef = this.modalService.show(PatientHsgPlanDeleteComponent, {
      initialState,
      class: 'modal-sm',
      keyboard: false,
      backdrop: 'static',
    });
    this.bsModalRef.content.event.subscribe(data => {
      this.bsModalRef.hide();
      this.apiHsgService.deletePlan(plan.id).subscribe(data => {
        if (data) {
          this.patientHSGService.setPlanDeleted(true);
          this.alertService.success(DATA_SAVED_SUCCESSFULLY);
        }
      }, 
      err => {
        this.alertService.error(JSON.stringify(err))
      });
    });
  }

  public getDateString(date: string): string {
    return date ? moment(date, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : moment().format(DISPLAY_DATE_FORMAT);
  }

  public getClinicName(id: string): string {
    const clinic = <Clinic>this.akitaClinicQuery.getClinic(id);
    return clinic.name;
  }

  ngOnDestroy(): void {
    this.newPlanAdded.unsubscribe();
    this.newPlanSub.unsubscribe();
    this.selectedGoalsPreviewSub.unsubscribe();
    this.syncPlansSub.unsubscribe();
  }
}