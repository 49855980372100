import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { HealthPlanSection } from '../../../../../../model/care-plan/CarePlanHealthGoal';

@Component({
  selector: 'app-care-plan-error-message',
  templateUrl: './care-plan-error-message.component.html',
  styleUrls: ['./care-plan-error-message.component.scss']
})
export class CarePlanErrorMessageComponent implements OnInit, OnDestroy {

  @Input() bodySection: Array<HealthPlanSection> = [];
  @Input() bodySectionFormGroup?: FormGroup;

  public errorMessages: Array<string> = [];

  private formGroupSub: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.populateErrorMessage();

    this.formGroupSub = this.bodySectionFormGroup?.valueChanges.subscribe(result => {
      this.populateErrorMessage();
    });
  }

  private populateErrorMessage(): void {
    const errorMessages: Array<string> = [];
    const controls = this.bodySectionFormGroup.controls;

    for (const [key, formControl] of Object.entries(controls)) {
      if (key !== 'goalId') {
        if (formControl.invalid) {
          const bodySection = this.bodySection.find(item => item.placeholder === key);

          if (bodySection) {
            errorMessages.push(bodySection.errorMsg)
          }
        }
      }
    }

    this.errorMessages = errorMessages;
  }

  ngOnDestroy(): void {
    if (this.formGroupSub) {
      this.formGroupSub.unsubscribe();
    }
  }
}