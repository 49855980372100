import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-patient-details-allert-adr-cmis-view-popup',
  templateUrl: './patient-details-allert-adr-cmis-view-popup.component.html',
  styleUrls: ['./patient-details-allert-adr-cmis-view-popup.component.scss']
})
export class PatientDetailsAllertAdrCmisViewPopupComponent implements OnInit {

  
  cmisAlerts: any[];
  cmisMedicalAlerts: any[];

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
  }

  onBtnExit() {
    this.bsModalRef.hide();
  }

  updateDetailsPanelVisibility(item) {
    item.viewDetails = !item.viewDetails;
  }

}
