import { FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-read-only',
  templateUrl: './read-only.component.html',
  styleUrls: ['./read-only.component.scss'],
})
export class ReadOnlyComponent implements OnInit {
  @Input() title: String;
  @Input() innerHTMLMode: boolean;
  @Input() notes: FormControl;
  isExpanded = true;

  constructor() {}

  ngOnInit() {}
}
