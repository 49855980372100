import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { Page } from '../../../../model/page';
import { Observable, Subject, of } from 'rxjs';
import { ApiAppointmentsService } from '../../../../services/api-appointments.service';
import { map, catchError } from 'rxjs/operators';
import { AlertService } from '../../../../services/alert.service';
import * as moment from 'moment'
import { DISPLAY_DATE_FORMAT, DB_VISIT_DATE_FORMAT, DISPLAY_TIME_NO_SECONDS_FORMAT, DB_FULL_DATE_FORMAT, DISPLAY_TIME_NO_SECONDS_24_FORMAT } from '../../../../constants/app.constants';
import { StoreService } from '../../../../services/store.service';

@Component({
  selector: 'app-appointment-search-result',
  templateUrl: './appointment-search-result.component.html',
  styleUrls: ['./appointment-search-result.component.scss']
})
export class AppointmentSearchResultComponent implements OnInit {
  @Input() title: string;
  @Input() searchParams: any;
  @ViewChild('table', { static: true }) table: any;

  rows = [];
  rows$: Observable<any>;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private apiAppointmentsService: ApiAppointmentsService,
    private alertService: AlertService,
    private store: StoreService
  ) {}

  ngOnInit() {
    this.doSearch(this.searchParams);
  }

  onBtnCancelClicked() {
    this.event.emit('Close');
  }

  doSearch(searchParams){
    this.rows$ = this.apiAppointmentsService.searchAppintmentsByPatientParameters(searchParams).pipe(
      map(data => {
          return data;
      }),
      map(data => this.processData(data)),
      catchError(error => {
        this.alertService.error(JSON.stringify(error));
        return of(error)
      })
    );
  }

  processData(data) {
    if (data.statusCode === 'S0000') {
      const { payload } = data;
      const temp2 = payload.map(payload => {
        const d = {
          patientNumber: payload.userId.number,
          patientName: payload.patientName,
          contactNumber: payload.contactNumber.number,
          gender: payload.gender,
          appointmentPurpose: payload.appointmentPurpose,
          startTime:moment(payload.startDate, DB_FULL_DATE_FORMAT).format(DISPLAY_TIME_NO_SECONDS_24_FORMAT),
          startDate:moment(payload.startDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_FORMAT),
          preferredDoc: this.getPreferredDoc(payload.doctorId),
          status: payload.status,
          followUp: payload.followUp
        };
        return d;
      });
      return temp2;
    }

    return [];
  }

  getPreferredDoc(doctorId: any) {
    if(doctorId){
      return this.store.findDoctorById(doctorId) && this.store.findDoctorById(doctorId).displayName || '-';
    }
    return '-'
  }

  toggleExpandGroup(group) {

    this.table.groupHeader.toggleExpandGroup(group);
  }

}
