import { Pipe, PipeTransform } from '@angular/core';
import { AllergiesFormService } from '../services/allergies-form.service';

@Pipe({
  name: 'displayAllergyGroupDesc',
})
export class DisplayAllergyGroupDescPipe implements PipeTransform {
  constructor(private allergiesFormService: AllergiesFormService) {}

  transform(value: any, args?: any): any {
    return this.allergiesFormService.getAllergyGroupDescription(value);
  }
}
