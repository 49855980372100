import { VisitManagementService } from './../../../services/visit-management.service';
import { AppointmentMasterQuery } from './appointment-master.query';
import { StoreService } from './../../../services/store.service';
import { Injectable } from '@angular/core';
import { AppointmentMasterStore } from './appointment-master.store';
import * as moment from 'moment';
import { CalendarView } from 'angular-calendar';

@Injectable({ providedIn: 'root' })
export class AppointmentMasterService {
  selectViewDate$ = this.appointmentMasterQuery.selectViewDate$;
  selectedDoctorFilter$ = this.appointmentMasterQuery
    .selectSelectedDoctorFilter$;
  storeReadyCount = 0;
  STORE_READINESS = 2;
  dontUpdateDocFilter = false;

  constructor(
    private appointmentMasterStore: AppointmentMasterStore,
    private store: StoreService,
    private appointmentMasterQuery: AppointmentMasterQuery,
    private visitManagementService: VisitManagementService
  ) {
    this.setClinicSelected(this.store.getClinicId());

    this.selectViewDate$.subscribe((viewDate: Date) => {
      this.setViewDateRanges(viewDate);
    });
  }

  setClinicSelected(id: string) {
    this.appointmentMasterStore.update({ clinicSelected: id });
    this.appointmentMasterStore.update({ isExternal: this.setExternal(id) });
    this.setDefaultDate();
  }

  setClinicSelectedDoctorList(list) {
    this.appointmentMasterStore.update({ clinicSelectedDoctorList: list });
  }

  setDefaultClinic() {
    this.appointmentMasterStore.update({
      clinicSelected: this.store.getClinicId(),
    });
    this.setDefaultDate();
  }

  setExternal(id) {
    return id !== this.store.getClinicId();
  }

  setView(view: CalendarView) {
    this.appointmentMasterStore.update({ view: view });
  }

  setViewDate(date: Date, dontUpdateDocFilter?: boolean) {
    this.dontUpdateDocFilter = dontUpdateDocFilter;
    this.appointmentMasterStore.update({ viewDate: date });
  }

  setDefaultDate() {
    this.setViewDate(new Date());
  }

  setViewDateRanges(viewDate: Date) {
    this.appointmentMasterStore.update({
      viewDate1MthBefore: moment(viewDate)
        .subtract(1, 'month')
        .startOf('month')
        .toDate(),
    });

    this.appointmentMasterStore.update({
      viewDate1MthAfter: moment(viewDate)
        .add(1, 'month')
        .endOf('month')
        .toDate(),
    });

    this.appointmentMasterStore.update({
      viewDateStartOfMonth: moment(viewDate)
        .startOf('month')
        .toDate(),
    });

    this.appointmentMasterStore.update({
      viewDateEndOfMonth: moment(viewDate)
        .endOf('month')
        .toDate(),
    });
  }

  setSelectedDoctorFilter(list: string[]) {
    this.appointmentMasterStore.update({
      selectedDoctorFilter: list,
    });
  }

  resetStoreCount() {
    this.storeReadyCount = 0;
    this.visitManagementService.showFullLayoutLoading();
  }

  reduceStoreReady() {
    this.storeReadyCount--;
  }

  setStoreReady() {
    if (this.storeReadyCount === this.STORE_READINESS) {
      this.visitManagementService.hideFullLayoutLoading();
      return;
    }
    this.storeReadyCount++;
  }
}
