import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface SflItem {
  _id: string
  itemRefId: string;
  screeningType: string;
  testOrder: number;
  testType: string;
}


export interface AkitaSflItemState extends EntityState<SflItem> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'akita-sfl-item', idKey: 'itemRefId' })
export class AkitaSflItemStore extends EntityStore<AkitaSflItemState> {

  constructor() {
    super();
  }

}
