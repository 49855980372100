import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { StoreService } from '../../../../../../services/store.service';

import { ExercisePlanValue } from '../../../../../../model/care-plan/CarePlanExerciseRecommendation';
import { DietRecommendation } from '../../../../../../model/care-plan/CarePlanDietRecommendation';

@Component({
  selector: 'app-diet-recommendation',
  templateUrl: './diet-recommendation.component.html',
  styleUrls: ['./diet-recommendation.component.scss']
})
export class DietRecommendationComponent implements OnInit, OnChanges {

  @Input() carePlanFormGroup: FormGroup;
  @Input() existingData: Array<ExercisePlanValue> = [];

  public formGroupList: Array<FormGroup> = []
  public dietRecommendationList: Array<DietRecommendation> = [];

  get dietRecommendationListArray(): FormArray {
    return this.carePlanFormGroup.get('dietRecommendationList') as FormArray;
  }

  constructor(
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
    this.dietRecommendationList = this.storeService.hsgDietRecommendations;

    this.formGroupList = [];

    this.dietRecommendationList.forEach(diet => {
      const existingDiet = this.existingData?.find(item => item.placeholder === diet.code);

      const formGroup = new FormGroup({
        code: new FormControl(diet.code, [Validators.required]),
        selected: new FormControl(existingDiet ? true : false),
        category: new FormControl(existingDiet ? existingDiet.value : diet.category),
        contextValue: new FormControl({
          value: existingDiet ? existingDiet.contextValue : null,
          disabled: existingDiet ? false : true
        }),
      });

      this.formGroupList.push(formGroup);
      this.dietRecommendationListArray.push(formGroup);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formGroupList.forEach(formGroup => {
      const foundValue = this.existingData.find(item => item.placeholder === formGroup.value.code);

      if (foundValue) {
        const formGroupValue = formGroup.value;

        formGroup.enable();
        formGroup.patchValue({
          code: formGroupValue.code,
          selected: true,
          category: formGroupValue.category,
          contextValue: foundValue.contextValue,
        });
      }
    });
  }

  public onCheck(formGroup: FormGroup): void {
    const contextValueFormControl = formGroup.get('contextValue') as FormControl;
    const selectedValue = formGroup.get('selected').value;

    if (selectedValue) {
      contextValueFormControl.enable();
    } else {
      contextValueFormControl.disable();
    }
  }
}