import { Injectable } from '@angular/core';
import { BehaviorSubject } from '../../../node_modules/rxjs';

@Injectable()
export class VitalService {
  private vitalData = new BehaviorSubject<any>(undefined);

  constructor() {}

  getPastVitals() {}

  getObservableVitalData() {
    return this.vitalData.asObservable();
  }

  resetVitalData() {
    this.vitalData = new BehaviorSubject<any>(undefined);
  }
}
