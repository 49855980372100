<!-- TOP SECTION -->
<form novalidate>
  <div class="card mb-2 mt-1" *ngxPermissionsOnly="['ROLE_FILE_LIST']">
    <div class="card-header card-header-transparent d-flex justify-content-between">
      <span class='font-weight-semi-bold'>Documents</span>
    </div>
    <ng-container *ngIf="documents$ | async as documents; else error">

      <div *ngFor="let row of documents; let i=index;">
        <span *ngIf="i!==0">
          <hr class="m-0">
        </span>
        <div class="row">
          <div class="col-md-1"> <i class="icon-clipboard-1 cursor-pointer"></i></div>
          <div class="col-md-10">
            <div *ngxPermissionsOnly="['ROLE_FILE_DOWNLOAD']">
              <span [title]="row.fileName" class='link'
                (click)="onDownloadNewDocument('visit',row.fileId, row.fileName)">{{ row.fileName }} -
                {{row.description}}</span>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

    <ng-template #error>
      <div class="text-grey p-2">
        <i>No Documents Found.</i>
      </div>
    </ng-template>
  </div>
</form>