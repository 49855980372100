import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PatientHsgService } from '../services/patient-hsg.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-patient-hsg-condition',
  templateUrl: './patient-hsg-condition.component.html',
  styleUrls: ['./patient-hsg-condition.component.scss']
})
export class PatientHsgConditionComponent implements OnInit, OnDestroy {

  @Input() enableConditions: boolean;
  @Input() selectedConditions: string[];
  public moment = moment;

  public patientConditions = [
    {name: 'Pre Diabetes', code: 'PRE_DIABETES', selected: false},
    {name: 'Diabetes Mellitus', code: 'DIABETES', selected: false},
    {name: 'Hypertension', code: 'HYPERTENSION', selected: false},
    {name: 'Hyperlipidemia (Lipid Disorders)', code: 'LIPID_DISORDERS', selected: false},
  ];
  public selectedPcnConditions: any[];
  // public selectedConditionsOfPlan: any[];

  private enableConditionSub: Subscription;
  private selectedConditionsSub: Subscription;

  constructor(
    private patientHSGService: PatientHsgService,
  ) { }

  ngOnInit() {
    this.setDefaultConditions();
    
    this.enableConditionSub = this.patientHSGService.getEnableConditions().subscribe({
      next: result => {
        this.enableConditions = result;
        this.setDefaultConditions();
      }
    });

    this.selectedConditionsSub = this.patientHSGService.getSelectedConditions().subscribe({
      next: result => {
        this.selectedConditions = result;
        this.setDefaultConditions();
      }
    });
  }

  public onConditionCheck(event, condition): void {
    condition.selected = event;
    this.selectedConditions = this.patientConditions.filter(con => con.selected).map(con => con.code);
    
    this.patientHSGService.setChronicDiseaseChange(this.selectedConditions);
  }

  public setDefaultConditions(): void {
    for (let condition of this.patientConditions) {
      if (this.selectedConditions) {
        condition.selected = this.selectedConditions.includes(condition.code);
      } else {
        condition.selected = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.enableConditionSub.unsubscribe();
    // this.selectedConditionsSub.unsubscribe();
  }
}