import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import { HSGPlanComparison } from '../../../../../model/HSBPlanComparison';

@Component({
  selector: 'app-hsg-coverage-comparision',
  templateUrl: './hsg-coverage-comparision.component.html',
  styleUrls: ['./hsg-coverage-comparision.component.scss']
})
export class HsgCoverageComparisionComponent implements OnInit {

  @Input()
  title: string = '';

  @Input()
  comparisonData: HSGPlanComparison;

  private selectedPlanIdOnLoad!: string;

  private closeEvent: EventEmitter<{isPlanChanged: boolean, comparisonData: HSGPlanComparison}> = new EventEmitter<{isPlanChanged: boolean, comparisonData: HSGPlanComparison}>();

  constructor() { }

  ngOnInit(): void { 
    this.selectedPlanIdOnLoad = this.comparisonData.wdrugCalculations.find(item => item.selected === true).planId;
  }

  public onSelectPlan(planId: string): void {
    this.comparisonData.wdrugCalculations.forEach(item => item.selected = false);
    let selectedPlan = this.comparisonData.wdrugCalculations.find(item => item.planId === planId);
    selectedPlan.selected = true;
  }

  onClose() {
    let isPlanChanged: boolean = false;

    const selectedPlanIdOnClose = this.comparisonData.wdrugCalculations.find(item => item.selected === true).planId;
    isPlanChanged = selectedPlanIdOnClose !== this.selectedPlanIdOnLoad;

    this.closeEvent.emit({
      isPlanChanged: isPlanChanged, 
      comparisonData: this.comparisonData
    });
  }
}