import { AttachedMedicalCoverage } from '../AttachedMedicalCoverage';

export class PatientVisit {
  registryEntity: PatientVisitRegistryEntity;
  attachedMedicalCoverages: Array<string>;

  constructor(
    registryEntity: PatientVisitRegistryEntity = new PatientVisitRegistryEntity(),
    attachedMedicalCoverages: Array<string> = []
  ) {
    this.registryEntity = registryEntity;
    this.attachedMedicalCoverages = attachedMedicalCoverages;
  }
}

export class PatientVisitRegistryEntity {
  patientId: string;
  clinicId: string;
  preferredDoctorId: string;
  visitPurpose: string;
  priority: string;
  patientSources: string;
  personIncharge: string;
  remark: string;
  skipNir?: boolean;

  constructor(
    patientId: string = '',
    clinicId: string = '',
    preferredDoctorId: string = undefined,
    visitPurpose: string = '',
    priority: string = '',
    patientSources: string = '',
    personIncharge: string = '',
    remark: string = '',
    skipNir: boolean = false
  ) {
    this.patientId = patientId;
    this.clinicId = clinicId;
    this.preferredDoctorId = preferredDoctorId;
    this.visitPurpose = visitPurpose;
    this.priority = priority;
    this.patientSources = patientSources;
    this.personIncharge = personIncharge;
    this.remark = remark;
    this.skipNir = skipNir;
  }
}

export interface VisitPurpose {
  name: string;
  consultationRequired: boolean;
  hsgVisit: boolean;
}
