export default class DatePickerConfig {
  label: string;
  maxDate: Date;
  minDate: Date;
  datePickerPlacement: string;
  labelPlacement: string;
  placeholder: string;
  dateInputFormat?: string;
  datesDisabled?: Date[];

  constructor(
    label?: string,
    maxDate?: Date,
    minDate?: Date,
    datePickerPlacement?: string,
    labelPlacement?: string,
    placeholder?: string,
    dateInputFormat?: string,
    datesDisabled?: Date[],
  ) {
    this.label = label || '';
    this.maxDate = maxDate || null;
    this.minDate = minDate || null;
    this.datePickerPlacement = datePickerPlacement || 'bottom';
    this.labelPlacement = labelPlacement || 'left';
    this.placeholder = placeholder || '';
    this.dateInputFormat = dateInputFormat || '';
    this.datesDisabled = datesDisabled || [];
  }
}