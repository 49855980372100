import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayDollarAboveZero',
})
export class DisplayDollarAboveZeroPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value > 0) {
      return parseFloat((value / 100).toFixed(2));
    } else {
      return value;
    }
  }
}
