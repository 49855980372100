import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AkitaPatientAppQuery } from "../../services/states/akita-patient/akita-patient-app.query";
import { AkitaClinicQuery } from "../../services/states/akita-clinic.query";
import { AkitaAppQuery } from "../../services/states/akita-app.query";

import { Logger } from "../../util/logger";

const logger = new Logger("EnableRemoteDeliveryTabDirective", "whitelisted", "hsg");

@Directive({
  selector: '[appEnableRemoteDeliveryTab]'
})
export class EnableRemoteDeliveryTabDirective {

  private deliveryLocationId: string;

  @Input() set appEnableRemoteDeliveryTab(deliveryLocationId: string) {
    this.deliveryLocationId = deliveryLocationId;
    this.updateView();
  }
  
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private akitaAppQuery: AkitaAppQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaClinicQuery: AkitaClinicQuery
  ) { }

  updateView() {
    if(this.deliveryLocationId && this.deliveryLocationId.length > 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      if (!this.akitaAppQuery.checkClinicFeatureExist('HSG')) {
        logger.log("HSG feature is disabled");
        this.viewContainer.clear();
        return;
      }
  
      if (!this.akitaAppQuery.checkClinicFeatureExist('HSG_1_5')) {
        logger.log("HSG_1_5 feature is disabled");
        this.viewContainer.clear();
        return;
      }
  
      let patientInfo = this.akitaPatientAppQuery.patientInfo;
      if (!patientInfo) {
        logger.log("Patient info is not available");
        this.viewContainer.clear();
        return;
      }
  
      if (patientInfo.hsgEnrollment?.enrolledClinicId !== this.akitaClinicQuery.getCurrentClinic().id) {
        logger.log("Patient is not enrolled for HSG", { patientInfo: patientInfo, clinic: this.akitaClinicQuery.getCurrentClinic() });
        this.viewContainer.clear();
        return;
      }
  
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}