import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { map } from 'rxjs/operators';

import { AlertService } from '../../../../../services/alert.service';
import { ApiHsgService } from '../../../../../services/api-hsg.service';
import { AkitaAppQuery } from '../../../../../services/states/akita-app.query';
import { AkitaPatientAppQuery } from '../../../../../services/states/akita-patient/akita-patient-app.query';

import { HSGPatientJourney } from '../../../../../model/HSGPatientJourney';
import { HSGOnboardingQuestionnaire } from '../../../../../model/HSGOnboardingQuestionnaire';

import { QuestionnaireResponsesComponent } from './questionnaire-responses/questionnaire-responses.component';

import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../../../constants/app.constants';
import * as moment from 'moment';

@Component({
  selector: 'app-iquit',
  templateUrl: './iquit.component.html',
  styleUrls: ['./iquit.component.scss']
})
export class IquitComponent implements OnInit {

  public onboardingQuestionnaire: HSGOnboardingQuestionnaire;
  public healthQuestionnaire: Array<HSGOnboardingQuestionnaire> = [];
  public patientJourney: HSGPatientJourney;

  public isOnboardingLoading: boolean = true;
  public isHealthLoading: boolean = true;
  public isJourneyLoading: boolean = true;

  public isHsgPatient: boolean = false;

  public errorOnboarding: boolean = false;
  public errorMessageOnboarding: string = '';

  public errorPatientJourney: boolean = false;
  public errorMessagePatientJourney: string = '';

  public errorHealthQuestionnaire: boolean = false;
  public errorMessageHealthQuestionnaire: string = '';

  constructor(
    public bsModalRef: BsModalRef,
    private akitaAppQuery: AkitaAppQuery,
    private modalService: BsModalService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private _alertService: AlertService,
    private _apiHsgService: ApiHsgService,
    private patientAppQuery: AkitaPatientAppQuery,
  ) { }

  ngOnInit(): void {
    this.initQuestionnaire('OQ');
    this.initQuestionnaire('HQ');
    this.initPatientJourney();

    this.patientAppQuery.patientInfo$.subscribe(result => {
      this.isHsgPatient = result['hsgPatient'];
    });
  }

  private initQuestionnaire(type: 'HQ' | 'OQ'): void {
    const clinicId = this.akitaAppQuery.getAppClinicId();
    const patientId = this.akitaPatientAppQuery.getId();
    const body = {
      questionnaireType: type,
      page: 0,
      size: 10
    }

    this._apiHsgService.getHsgQuestionnaire(clinicId, patientId, body)
      .pipe(
        map(result => {
          if (result.statusCode === 'S0000') {
            result.payload = (result.payload as Array<HSGOnboardingQuestionnaire>).map(item => {
              item.lastUpdatedDate = moment(item.lastUpdatedDate, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
              return item
            });
          }
          return result;
        })
      )
      .pipe(
        map(result => {
          // Adding N/A as the answer if no answer exists
          if (result.statusCode === 'S0000') {
            result.payload = (result.payload as Array<HSGOnboardingQuestionnaire>).map(item => {
              item.sections.map(section => {
                section.questions.map(question => {
                  if (question.questionType === 'SINGLE_SELECT') {
                    const foundAnswer = question.answer.values.find(item => item.selected);
                    if (!foundAnswer) {
                      question.answer.values.push({
                        linkId: '',
                        code: '',
                        value: 'N/A',
                        unit: 'N/A',
                        selected: true,
                      })
                    }
                  }
                })
              });
              return item
            });
          }
          return result;
        })
      )
      .subscribe({
        next: result => {
          if (type === 'OQ') {
            this.onboardingQuestionnaire = result.payload[0];
            this.isOnboardingLoading = false;
          } else {
            this.healthQuestionnaire = result.payload
            this.isHealthLoading = false;
          }

        },
        error: error => {
          if (type === 'OQ') {
            this.isOnboardingLoading = false;
            this.errorMessageOnboarding = error.error.message;
            this.errorOnboarding = true;
          } else {
            this.isHealthLoading = false;
            this.errorMessageHealthQuestionnaire = error.error.message;
            this.errorHealthQuestionnaire = true;
          }
        }
      });
  }

  private initPatientJourney(): void {
    const clinicId = this.akitaAppQuery.getAppClinicId();
    const patientId = this.akitaPatientAppQuery.getId();

    this._apiHsgService.getHsgPatientJourney(clinicId, patientId).subscribe({
      next: result => {
        this.patientJourney = result.payload;
        this.isJourneyLoading = false;
      },
      error: error => {
        this.isJourneyLoading = false;
        this.errorPatientJourney = true;
        this.errorMessagePatientJourney = error.error.message;
      }
    });
  }

  public onClickOnboardingResponse(): void {
    const initialState = {
      questionnaire: this.onboardingQuestionnaire,
    }

    const modal = this.modalService.show(QuestionnaireResponsesComponent, {
      initialState,
      class: 'modal-xl custom-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  public onClose(): void {
    this.bsModalRef.hide();
  }
}