import { Pipe, PipeTransform } from '@angular/core';
import { Type } from '../objects/PurchaseOrder';

@Pipe({
  name: 'inventoryDisplayOrderDetailTitle'
})
export class InventoryDisplayOrderDetailTitlePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }

    return (
      (value === Type.PR || value === Type.PO ? 'Purchase' : 'Transfer') +
      ' ' +
      (value === Type.PO || value === Type.TO ? 'Order' : 'Request')
    );
  }
}
