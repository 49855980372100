import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inventoryDisplayStockTakeCountedItemSize'
})
export class InventoryDisplayStockTakeCountedItemSizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return 0;
    }

    return value.getCountedItems().length;
  }

}
