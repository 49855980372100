<form novalidate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button *ngIf="inModal" type="button" class="close pull-right" aria-label="Close" (click)="onBtnExit()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-patient-history-detail-edit-certificate-item [startdate]="startDate" *ngFor="let item of certificateArray.controls; let i=index"
            [index]="i" [medicalCertificateItem]="item">
        </app-patient-history-detail-edit-certificate-item>
        <div class="row justify-content-end px-3">
            <button class="btn btn-outline-brand-secondary float-right mr-3" (click)="addItem()"
                [disabled]="!certificateArray.valid">+ Add</button>
            <button class="btn btn-brand-primary float-right" (click)="onBtnSaveClicked()"
                [disabled]="!certificateArray.valid">Save</button>
        </div>
    </div>
</form>
