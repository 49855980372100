import { Injectable } from "@angular/core";

import { QueryEntity } from '@datorama/akita';

import { AkitaApproverStore, Approver } from './akita-approver.store';

@Injectable({ providedIn: 'root' })
export class AkitaApproverQuery extends QueryEntity<Approver> {

  constructor(protected store: AkitaApproverStore) {
    super(store);
  }

}
