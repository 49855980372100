import {Directive, ElementRef, HostListener, Input, Optional} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalNumberDirective]'
})
export class DecimalNumberDirectiveDirective {

    //Created for adding validation for decimal number. Example : appDecimalNumberDirective && decimals="1"
    @Input("decimals") decimals: number = 0;

    constructor(private el: ElementRef) {}

    @HostListener('input', ['$event'])
    onInput(event: KeyboardEvent): void {
        const input = this.el.nativeElement as HTMLInputElement;
        const currentValue = input.value;
        const newValue = this.processInputValue(currentValue);
        
        if (newValue !== currentValue) {
        input.value = newValue;
        input.dispatchEvent(new Event('input'));
        }
    }

    private processInputValue(value: string): string {
        // Remove all non-numeric and non-decimal characters
        const sanitizedValue = value.replace(/[^\d.]/g, '');

        // Split into integer and decimal parts
        const parts = sanitizedValue.split('.');
        let integerPart = parts[0];
        let decimalPart = parts[1];

        // Allow only one decimal point
        if (decimalPart !== undefined) {
            decimalPart = decimalPart.slice(0, this.decimals); // Keep only the given decimal digits
        }

        // Combine the integer and decimal parts
        const newValue = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;

        return newValue;
    }
}
