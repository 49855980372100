import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, of } from 'rxjs';
import {
  map,
  tap,
  catchError,
  defaultIfEmpty,
  takeUntil,
  distinctUntilChanged,
} from 'rxjs/operators';
import { DISPLAY_DATE_FORMAT } from '../constants/app.constants';
import { Page } from '../model/page';
import {
  DeliveryOrder,
  DeliveryOrderItem,
  DeliveryOrderStatus,
} from '../objects/DeliveryOrder';
import { InventorySupplier } from '../objects/InventorySupplier';
import { GRNItem, OrderGRN, GRNStatus } from '../objects/OrderGRN';
import {
  OrderReturnRequest,
  OrderReturnRequestItem,
  OrderReturnRequestStatus,
} from '../objects/OrderReturnRequest';
import {
  InventoryOrder,
  OrderItem,
  OrderStatus,
  OrderType,
  PurchaseOrder,
  PurchaseRequest,
  TransferOrder,
  Type,
} from '../objects/PurchaseOrder';
import { OrderListRequest } from '../objects/request/OrderListRequest';
import { OrderReturnRequestListRequest } from '../objects/request/OrderReturnRequestListRequest';
import { StartStockTakeRequest } from '../objects/request/StartStockTakeRequest';
import { StockAdjustment } from '../objects/request/StockAdjustment';
import { StockListFilter } from '../objects/request/StockListFilter';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { PurchaseOrderRequestListResponse } from '../objects/response/PurchaseOrderRequestListResponse';
import { PurchaseOrderReturnRequestListResponse } from '../objects/response/PurchaseOrderReturnRequestListResponse';
import { StockItem } from '../objects/StockItem';
import {
  CountType,
  StockTake,
  StockTakeItem,
  StockTakeStatus,
} from '../objects/StockTake';
import { UniqueBatchNumberValidator } from '../validators/UniqueBatchNumberValidator';
import { ApiInventoryService } from './api-inventory.service';
import { AkitaChargeItemQuery } from './states/akita-charge-item.query';
import { StoreService } from './store.service';
import * as moment from 'moment'
import { IntValidator } from '../validators/IntValidator';
import { StockAdjustmentListFilter } from '../objects/request/StockAdjustmentListFilter';
import { StockAdjustmentListItem } from '../objects/StockAdjustmentListItem';
import { AkitaClinicPurchasableChargeItemQuery } from './states/akita-clinic-purchasable-charge-item.query';
import { StockTakeApproveStatus } from '../objects/StockTakeApproveStatus';
import { Price } from '../objects/InventoryItem';
import { DisposalItems } from '../objects/disposal-items';
import { NirItems } from '../objects/response/nir-items';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  pendingForEditListResponse: PurchaseOrderRequestListResponse<
    PurchaseRequest
  > = undefined;
  pendingForReturnRequest: OrderReturnRequest = undefined;

  /* STOCK TAKE */
  countTypeOptions: Array<any>;
  refreshStockTake: Subject<boolean> = new Subject();

  /* CURRENT STOCK */
  clearCurrentStockTakeSelectedSet: Subject<boolean> = new Subject<boolean>();

  gst: number = UtilsService.getGST();

  private isPurchaseRequestMinOrderAmountMetSub = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private apiInventoryService: ApiInventoryService,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private akitaClinicPurchasableChargeItemQuery: AkitaClinicPurchasableChargeItemQuery
  ) {
    this.countTypeOptions = [
      { value: CountType.FULL, label: 'FULL' },
      { value: CountType.PARTIAL, label: 'PARTIAL' },
    ];
  }

  /* PURCHASE REQUEST */
  createPurchaseRequestFormGroup(
    requestType: string,
    request?: PurchaseRequest
  ): FormGroup {
    return this.fb.group({
      requestClinicId: [
        request ? request.requestClinicId : this.store.getClinicId(),
        Validators.required,
      ],
      requestType: [
        request ? request.requestType : requestType,
        Validators.required,
      ],
      createDate: [
        request && request.createDate
          ? moment(request.createDate, DISPLAY_DATE_FORMAT).toDate()
          : moment().toDate(),
        Validators.required,
      ],
      requestItems: this.fb.array(
        request
          ? request.requestItems.map(item =>
              this.createPurchaseRequestItemFormGroup(
                item,
                item.approvedQuantity
              )
            )
          : [
              this.createPurchaseRequestItemFormGroup(),
              this.createPurchaseRequestItemFormGroup(),
              this.createPurchaseRequestItemFormGroup(),
            ]
      ),
      justificationForPurchase: [
        request ? request.justificationForPurchase : undefined,
        Validators.required,
      ],
      requiredBeforeDate: [
        request && request.requiredBeforeDate
          ? moment(request.requiredBeforeDate, DISPLAY_DATE_FORMAT).toDate()
          : moment()
              .add(3, 'days')
              .toDate(),
        Validators.required,
      ],
    });
  }

  createPurchaseRequestItemFormGroup(
    item?: OrderItem,
    approvedQty = 0
  ): FormGroup {
    return this.fb.group({
      removeSelection: [false],
      itemRefId: [item ? item.itemRefId : undefined, Validators.required],
      supplierId: [item ? item.supplierId : undefined, Validators.required],
      quantity: [
        item ? item.quantity : undefined,
        [Validators.required, Validators.min(1), IntValidator()],
      ],
      currentQuantity: [{ value: undefined, disabled: true }],
      minOrderQty: [{ value: undefined, disabled: true }],
      reorderPoint: [{ value: undefined, disabled: true }],
      bonusQty: [{ value: undefined, disabled: true }],
      uom: [item ? item.uom : undefined, Validators.required],
      unitPrice: [
        {
          value: item
            ? item.getItemUnitPrice(this.akitaClinicPurchasableChargeItemQuery)
            : undefined,
          disabled: true,
        },
      ],
      totalAmount: [
        {
          value: item
            ? item.getItemAmount(this.akitaClinicPurchasableChargeItemQuery)
            : undefined,
          disabled: true,
        },
      ],
      lineNo: [item && item.lineNo ? item.lineNo : undefined],
      remark: [
        item && item.remark ? item.remark : undefined,
        [Validators.maxLength(250)],
      ],

      outOfStockRemarks: [item ? item.outOfStockRemarks : undefined],

      approvedQuantity: approvedQty,
    });
  }

  convertOrderItemFormArrayValueToOrderItemArray(
    array: Array<any>
  ): Array<OrderItem> {
    return array.map(item => new OrderItem().adapt(item));
  }

  getOrderList(
    clinicId: string,
    filter: OrderListRequest,
    page: Page
  ): Observable<Array<PurchaseOrderRequestListResponse<InventoryOrder>>> {
    return this.apiInventoryService.listAllRequest(clinicId, filter, page).pipe(
      tap(res => {
        page.pageNumber = res.payload['pageNumber'];
        page.totalPages = res.payload['totalPages'];
        page.totalElements = res.payload['totalElements'];
      }),
      map(res => {
        if (!res.payload || !res.payload.content) {
          return [];
        }

        return res.payload.content.map(item => {
          if (item.dataType === Type.PR) {
            return new PurchaseOrderRequestListResponse<
              PurchaseRequest
            >().adapt(item);
          } else if (item.dataType === Type.PO) {
            return new PurchaseOrderRequestListResponse<PurchaseOrder>().adapt(
              item
            );
          } else if (item.dataType === Type.TO) {
            return new PurchaseOrderRequestListResponse<TransferOrder>().adapt(
              item
            );
          } else {
            return new PurchaseOrderRequestListResponse().adapt(item);
          }
        });
      })
    );
  }

  createOrderListFilterFormGroup(
    TRANSFER_PURCHASE_TYPE: string,
    CREATED_DATE_MORE: string,
    CREATED_DATE_LESS: string,
    STATUS: string,
    ORDER_REQUEST_NO: string,
  ): FormGroup {
    return this.fb.group({
      TRANSFER_PURCHASE_TYPE: [TRANSFER_PURCHASE_TYPE ? TRANSFER_PURCHASE_TYPE : ''],
      CREATED_DATE_MORE: [CREATED_DATE_MORE ? moment(CREATED_DATE_MORE).toDate() : moment().subtract(2, 'months').toDate()],
      CREATED_DATE_LESS: [CREATED_DATE_LESS ? moment(CREATED_DATE_LESS).toDate() : moment().toDate()],
      STATUS: [STATUS ? STATUS : ''],
      ORDER_REQUEST_NO: [ORDER_REQUEST_NO ? ORDER_REQUEST_NO : undefined],
    });
  }

  getPurchaseRequestById(id: string): Observable<PurchaseRequest> {
    return this.apiInventoryService.getRequestById(id).pipe(
      map(res => {
        const purchaseRequest = new PurchaseRequest().adapt(
          res.payload.purchaseRequest
        );
        purchaseRequest.suppliers = res.payload.suppliers;

        return purchaseRequest;
      })
    );
  }

  getOrderById(id: string): Observable<InventoryOrder> {
    return this.apiInventoryService.getOrderById(id).pipe(
      map(res => {
        const { payload } = res;
        if (payload.order.orderType === OrderType.PURCHASE) {
          const purchaseOrder = new PurchaseOrder().adapt(payload.order);
          purchaseOrder.suppliers = payload.suppliers;
          return purchaseOrder;
        } else if (payload.order.orderType === OrderType.TRANSFER) {
          const transferOrder = new TransferOrder().adapt(payload.order);
          transferOrder.suppliers = payload.suppliers;
          return transferOrder;
        }
        return payload;
      })
    );
  }

  covertUom(sourceUom: string, targetUom: string): Observable<number> {
    return this.apiInventoryService
      .convertUom(sourceUom, targetUom)
      .pipe(map(res => res.payload));
  }

  convertUomByListOfItemId(itemList: any[]): Observable<any[]> {
    return this.apiInventoryService
      .convertUomByListOfItemId(itemList)
      .pipe(map(res => res.payload));
  }

  getItemSupplierInfo(itemId: string, uom: string): Observable<Array<any>> {
    return this.apiInventoryService
      .getItemSupplierInfo(this.store.getClinicId(), itemId, uom)
      .pipe(
        map(res => [
          res.payload.totalQuantity,
          res.payload.suppliers.map(supplier =>
            new InventorySupplier().adapt(supplier)
          ),
        ])
      );
  }

  getItemSupplierInfoByListOfItemId(itemList: any[]): Observable<Array<any>> {
    return this.apiInventoryService
      .getItemSupplierInfoByListOfItemId(this.store.getClinicId(), itemList)
      .pipe(
        map(res => {
          return res.payload.map(item => {
            return [
              item.totalQuantity,
              item.suppliers.map(supplier =>
                new InventorySupplier().adapt(supplier)
              ),
            ];
          });
        })
      );
  }

  /* END PURCHASE REQUEST */

  /* GRN */
  createGRNFormGroup(
    orderGrn?: OrderGRN,
    isTransferOrder: boolean = false,
    isCPTransferOrder: boolean = false
  ): FormGroup {
    return this.fb.group({
      id: [orderGrn && orderGrn.id ? orderGrn.id : undefined],
      grnDate: [
        orderGrn && orderGrn.grnDate
          ? moment(orderGrn.grnDate, DISPLAY_DATE_FORMAT).toDate()
          : moment().toDate(),
      ],
      createDate: [
        orderGrn && orderGrn.createDate ? orderGrn.createDate : undefined,
      ],
      delivererId: [
        orderGrn && orderGrn.delivererId ? orderGrn.delivererId : undefined,
      ],
      receiverId: [
        orderGrn && orderGrn.receiverId ? orderGrn.receiverId : undefined,
      ],
      grnNo: [orderGrn && orderGrn.grnNo ? orderGrn.grnNo : undefined],
      additionalRemark: [
        orderGrn && orderGrn.additionalRemark
          ? orderGrn.additionalRemark
          : undefined,
        isTransferOrder
          ? [Validators.maxLength(100)]
          : [Validators.maxLength(250)],
      ],
      status: [orderGrn && orderGrn.status ? orderGrn.status : undefined],
      dnNo: [
        orderGrn && orderGrn.dnNo ? orderGrn.dnNo : undefined,
        [Validators.required],
      ],
      dnDate: [
        {
          value:
            orderGrn && orderGrn.dnDate
              ? moment(orderGrn.dnDate, DISPLAY_DATE_FORMAT).toDate()
              : moment().toDate(),
          disabled: isTransferOrder && !isCPTransferOrder,
        },
        [Validators.required],
      ],
      doAdditionalRemark: [
        orderGrn.doAdditionalRemark && orderGrn.doAdditionalRemark.length > 0
          ? orderGrn.doAdditionalRemark
          : '-',
      ],
      doCourier: [
        orderGrn.doCourier && orderGrn.doCourier.length > 0
          ? orderGrn.doCourier
          : '-',
      ],
      prNo: [orderGrn && orderGrn.prNo ? orderGrn.prNo : undefined],
      prDate: [orderGrn && orderGrn.prDate ? orderGrn.prDate : undefined],
      receivedItems: this.fb.array(
        orderGrn && orderGrn.receivedItems
          ? orderGrn.receivedItems.map(item =>
              this.createGRNItemFormGroup(
                item,
                isTransferOrder,
                isCPTransferOrder
              )
            )
          : []
      ),
    });
  }

  createGRNItemFormGroup(
    grnItem?: GRNItem,
    isTransferOrder: boolean = false,
    isCPTransferOrder: boolean = false
  ): FormGroup {
    let inventorised = true;
    let isDrugOrVaccine = true;
    let storeItem;
    if (grnItem) {
      storeItem = this.akitaChargeItemQuery.getChargeItem(grnItem.itemRefId);
      if (storeItem) {
        inventorised =
          storeItem.itemType === 'DRUG' ||
          storeItem.itemType === 'VACCINATION' ||
          (storeItem.itemType === 'CONSUMABLE' &&
            storeItem.inventoried === true);

        isDrugOrVaccine = storeItem.trackingCode &&
          (storeItem.itemType === 'DRUG' ||
          storeItem.itemType === 'VACCINATION' ||
          storeItem.itemType === 'CONSUMABLE');
      }
    }

    const grnItemFG = this.fb.group({
      removeSelection: [false],
      id: [
        {
          value: grnItem && grnItem.id ? grnItem.id : undefined,
          disabled: isTransferOrder && !isCPTransferOrder,
        },
      ],
      itemRefId: [
        {
          value: grnItem && grnItem.itemRefId ? grnItem.itemRefId : undefined,
          disabled: isTransferOrder && !isCPTransferOrder,
        },
        Validators.required,
      ],
      itemType: [
        {
          value: storeItem && storeItem.itemType ? storeItem.itemType : '',
        },
      ],
      uom: [
        {
          value: grnItem && grnItem.uom ? grnItem.uom : undefined,
          disabled: true,
        },
        Validators.required,
      ],
      batchNumber: [
        {
          value:
            grnItem && grnItem.batchNumber ? grnItem.batchNumber : undefined,
          disabled: (isTransferOrder && !isCPTransferOrder) || !isDrugOrVaccine,
        },
        isDrugOrVaccine ? [Validators.required] : [],
      ],
      purchasePrice: [
        {
          value: grnItem && grnItem.purchasePrice ? grnItem.purchasePrice : undefined,
          disabled: isTransferOrder && !isCPTransferOrder,
        },
        [Validators.required],
      ],
      quantity: [
        {
          value: grnItem && grnItem.quantity ? grnItem.quantity : undefined,
          disabled: isTransferOrder && !isCPTransferOrder,
        },
        [Validators.required, Validators.min(1), IntValidator()],
      ],
      receiptDate: [
        {
          value:
            grnItem && grnItem.receiptDate
              ? grnItem.receiptDate
              : moment().format(DISPLAY_DATE_FORMAT),
          disabled: isTransferOrder && !isCPTransferOrder,
        },
        Validators.required,
      ],
      expiryDate: [
        {
          value:
            grnItem && grnItem.expiryDate
              ? moment(grnItem.expiryDate, DISPLAY_DATE_FORMAT).toDate()
              : undefined,
          disabled: (isTransferOrder && !isCPTransferOrder) || !isDrugOrVaccine,
        },
        isDrugOrVaccine ? [Validators.required] : [],
      ],
      manufacturerDate: [
        {
          value:
            grnItem && grnItem.manufacturerDate
              ? moment(grnItem.manufacturerDate, DISPLAY_DATE_FORMAT).toDate()
              : undefined,
          disabled: isTransferOrder && !isCPTransferOrder,
        },
      ],
      comment: [grnItem && grnItem.comment ? grnItem.comment : undefined],
      lineNo: [grnItem && grnItem.lineNo ? grnItem.lineNo : undefined],
      isDrugOrVaccine: isDrugOrVaccine,
    });

    const batchNumberFC = grnItemFG.get('batchNumber');
    const expiryDateFC = grnItemFG.get('expiryDate');

    grnItemFG.get('itemRefId').valueChanges.subscribe(itemRefId => {
      let itemDrugOrVaccine = false;
      const item = this.akitaChargeItemQuery.getChargeItem(itemRefId);
      if (item) {
        itemDrugOrVaccine = item.trackingCode &&
          (item.itemType === 'DRUG' ||
          item.itemType === 'VACCINATION' ||
          item.itemType === 'CONSUMABLE');
      }

      if (!itemDrugOrVaccine || (isTransferOrder && !isCPTransferOrder)) {
        batchNumberFC.disable();
        expiryDateFC.disable();

        if (!itemDrugOrVaccine) {
          batchNumberFC.setValidators([]);
          expiryDateFC.setValidators([]);
        }
      } else {
        batchNumberFC.enable();
        expiryDateFC.enable();

        if (itemDrugOrVaccine) {
          batchNumberFC.setValidators([Validators.required]);
          expiryDateFC.setValidators([Validators.required]);
        }
      }

      grnItemFG.markAllAsTouched();
      batchNumberFC.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      expiryDateFC.updateValueAndValidity({ onlySelf: true, emitEvent: true });
    });

    return grnItemFG;
  }

  addGrn(orderId: string, orderGRN: OrderGRN): Observable<OrderGRN> {
    return this.apiInventoryService
      .addGRN(orderId, orderGRN)
      .pipe(map(res => new OrderGRN().adapt(res.payload)));
  }

  updateGrn(
    orderId: string,
    grnId: string,
    orderGRN: OrderGRN
  ): Observable<OrderGRN> {
    return this.apiInventoryService
      .updateGRN(orderId, grnId, orderGRN)
      .pipe(map(res => new OrderGRN().adapt(res.payload)));
  }

  downloadGRNFile(
    orderId: string,
    grnId: string,
    fileId: string
  ): Observable<HttpResponseBody> {
    return this.apiInventoryService.downloadGRNFile(orderId, grnId, fileId);
  }

  /* END GRN */

  /* RETURN REQUEST */

  createReturnRequestFormGroup(
    componentDestroyed: Subject<void>,
    returnRequest?: OrderReturnRequest
  ): FormGroup {
    let returnItemsArray = [];
    if (returnRequest && returnRequest.returnItems) {
      returnItemsArray = returnRequest.returnItems.map(item =>
        this.createReturnRequestItemFormGroup(componentDestroyed, item)
      );
    }

    const returnReqFG = this.fb.group({
      id: [returnRequest && returnRequest.id ? returnRequest.id : undefined],
      orderDate: [
        {
          value:
            returnRequest && returnRequest.orderDate
              ? moment(returnRequest.orderDate, DISPLAY_DATE_FORMAT).toDate()
              : moment().toDate(),
          disabled: true,
        },
      ],
      requestClinicId: [
        returnRequest && returnRequest.requestClinicId
          ? returnRequest.requestClinicId
          : undefined,
        Validators.required,
      ],
      supplierId: [
        returnRequest && returnRequest.supplierId
          ? returnRequest.supplierId
          : undefined,
        Validators.required,
      ],
      orderNo: [
        returnRequest && returnRequest.orderNo
          ? returnRequest.orderNo
          : undefined,
      ],
      status: [
        returnRequest && returnRequest.returnStatus
          ? returnRequest.returnStatus
          : undefined,
      ],
      returnItems: this.fb.array(returnItemsArray),
    });

    return returnReqFG;
  }

  createReturnRequestItemFormGroup(
    componentDestroyed: Subject<void>,
    requestItem?: OrderReturnRequestItem
  ): FormGroup {
    let isDrugOrVaccine = true;
    if (requestItem) {
      const storeItem = this.akitaChargeItemQuery.getChargeItem(
        requestItem.itemRefId
      );
      if (storeItem) {
        isDrugOrVaccine = storeItem.trackingCode &&
          (storeItem.itemType === 'DRUG' ||
          storeItem.itemType === 'VACCINATION' ||
          storeItem.itemType === 'CONSUMABLE');
      }
    }

    const itemFormGroup = this.fb.group({
      removeSelection: [false],
      itemRefId: [
        requestItem && requestItem.itemRefId
          ? requestItem.itemRefId
          : undefined,
      ],
      uom: [
        {
          value: requestItem && requestItem.uom ? requestItem.uom : undefined,
          disabled: true,
        },
      ],
      batchNumber: [
        requestItem && requestItem.batchNumber
          ? requestItem.batchNumber
          : undefined,
      ],
      quantity: [
        requestItem && requestItem.quantity ? requestItem.quantity : undefined,
      ],
      batchQuantity: [{ value: undefined, disabled: true }],
      expiryDate: [
        {
          value:
            requestItem && requestItem.expiryDate
              ? requestItem.expiryDate
              : undefined,
          disabled: true,
        },
      ],
      reasonCode: [
        requestItem && requestItem.reasonCode
          ? requestItem.reasonCode
          : undefined,
      ],
      lineNo: [
        requestItem && requestItem.lineNo ? requestItem.lineNo : undefined,
      ],
      isDrugOrVaccine: [isDrugOrVaccine],
    });

    const itemRefIdFC = itemFormGroup.get('itemRefId');
    const uomFC = itemFormGroup.get('uom');
    const batchNumberFC = itemFormGroup.get('batchNumber');
    const quantityFC = itemFormGroup.get('quantity');
    const expiryDateFC = itemFormGroup.get('expiryDate');
    const reasonCodeFC = itemFormGroup.get('reasonCode');
    const isDrugOrVaccineFC = itemFormGroup.get('isDrugOrVaccine');

    itemRefIdFC.valueChanges
      .pipe(takeUntil(componentDestroyed), distinctUntilChanged())
      .subscribe(itemRefId => {
        if (!itemRefId || itemRefId.length === 0) {
          itemRefIdFC.setValidators([]);
          uomFC.setValidators([]);
          batchNumberFC.setValidators([]);
          quantityFC.setValidators([]);
          expiryDateFC.setValidators([]);
          reasonCodeFC.setValidators([]);
        } else {
          let isDrugOrVaccine = false;
          const correspondingChargeItem = this.akitaChargeItemQuery.getChargeItem(
            itemRefId
          );
          if (correspondingChargeItem) {
            isDrugOrVaccine = correspondingChargeItem.trackingCode &&
              (correspondingChargeItem.itemType === 'DRUG' ||
              correspondingChargeItem.itemType === 'VACCINATION' ||
              correspondingChargeItem.itemType === 'CONSUMABLE');
          }

          itemRefIdFC.setValidators([Validators.required]);
          uomFC.setValidators([Validators.required]);
          quantityFC.setValidators([
            Validators.required,
            Validators.min(1),
            IntValidator(),
          ]);
          reasonCodeFC.setValidators([Validators.required]);

          if (!isDrugOrVaccine) {
            batchNumberFC.setValidators([]);
            expiryDateFC.setValidators([]);
          } else {
            batchNumberFC.setValidators([Validators.required]);
            expiryDateFC.setValidators([Validators.required]);
          }

          itemFormGroup.patchValue({
            isDrugOrVaccine: isDrugOrVaccine,
          });
        }

        itemFormGroup.markAllAsTouched();
        uomFC.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        batchNumberFC.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });
        quantityFC.updateValueAndValidity({ onlySelf: true, emitEvent: true });
        expiryDateFC.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });
        reasonCodeFC.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });
        isDrugOrVaccineFC.updateValueAndValidity({
          onlySelf: true,
          emitEvent: true,
        });
      });

    return itemFormGroup;
  }

  convertReturnRequestItemFormArrayValueToOrderReturnRequestItemArray(
    array: Array<any>
  ): Array<OrderReturnRequestItem> {
    return array.map(item => new OrderReturnRequestItem().adapt(item));
  }

  createReturnRequestListFilterFormGroup(): FormGroup {
    return this.fb.group({
      startDate: [
        moment()
          .subtract(2, 'months')
          .toDate(),
      ],
      endDate: [moment().toDate()],
      requestStatus: [''],
      number: [undefined],
    });
  }

  getReturnRequestList(
    filter: OrderReturnRequestListRequest,
    page: Page
  ): Observable<Array<PurchaseOrderReturnRequestListResponse>> {
    return this.apiInventoryService
      .getReturnRequestList(this.store.getClinicId(), filter, page)
      .pipe(
        tap(res => {
          page.pageNumber = res.payload['pageNumber'];
          page.totalPages = res.payload['totalPages'];
          page.totalElements = res.payload['totalElements'];
        }),
        map(res => {
          if (!res.payload || !res.payload.content) {
            return [];
          }

          return res.payload.content.map(item =>
            new PurchaseOrderReturnRequestListResponse().adapt(item)
          );
        })
      );
  }
  listStockTake(page: Page, requestBody: any): Observable<Array<StockTake>> {
    return this.apiInventoryService
      .listStockTake(this.store.getClinicId(), page, requestBody)
      .pipe(
        tap(res => {
          page.pageNumber = res['pageNumber'];
          page.totalPages = res['totalPages'];
          page.totalElements = res['totalElements'];
        }),
        map(res => {
          return res.payload.map(item => new StockTake().adapt(item));
        })
      );
  }
  getDisposalList(page: Page, filter: any): Observable<Array<DisposalItems>> {
    return this.apiInventoryService
      .getDisposalList(this.store.getClinicId(), filter, page)
      .pipe(
        tap(res => {
          page.pageNumber = res.payload.pageable['pageNumber'];
          page.totalPages = res.payload['totalPages'];
          page.totalElements = res.payload['totalElements'];
        }),
        map(res => {
          if (!res.payload.content) {
            return [];
          }

          return res.payload.content.map(item =>
            new DisposalItems().adapt(item)
          );
        })
      );
  }

  updateDisposeItem(page: Page, filter: any): Observable<Array<DisposalItems>> {
    return this.apiInventoryService
      .updateDisposalItem(this.store.getClinicId(), filter, page)
      .pipe(
        tap(res => {
          page.pageNumber = res.payload['pageNumber'];
          page.totalPages = res.payload['totalPages'];
          page.totalElements = res.payload['totalElements'];
        }),
        map(res => {
          if (!res.payload.content) {
            return [];
          }
        })
      );
  }

  getReturnRequestById(returnId: string): Observable<OrderReturnRequest> {
    return this.apiInventoryService
      .getReturnRequestById(returnId)
      .pipe(map(res => new OrderReturnRequest().adapt(res.payload)));
  }

  addReturnRequest(
    requestClinicId: string,
    supplierId: string,
    returnRequestStatus: string,
    returnReq: OrderReturnRequest
  ): Observable<OrderReturnRequest> {
    let data;
    if (returnReq.purchaseOrderId && returnReq.purchaseOrderId.length > 0) {
      data = {
        purchaseOrderId: returnReq.purchaseOrderId,
        returnItems: returnReq.returnItems,
      };
    } else {
      data = { returnItems: returnReq.returnItems };
    }

    return this.apiInventoryService
      .addReturnRequest(requestClinicId, supplierId, returnRequestStatus, data)
      .pipe(map(res => new OrderReturnRequest().adapt(res.payload)));
  }

  updateReturnRequest(
    orderId: string,
    supplierId: string,
    returnRequestId: string,
    returnReq: OrderReturnRequest
  ): Observable<OrderReturnRequest> {
    let data;
    if (returnReq.purchaseOrderId && returnReq.purchaseOrderId.length > 0) {
      data = {
        purchaseOrderId: returnReq.purchaseOrderId,
        returnItems: returnReq.returnItems,
      };
    } else {
      data = { returnItems: returnReq.returnItems };
    }

    return this.apiInventoryService
      .updateReturnRequest(orderId, supplierId, returnRequestId, data)
      .pipe(map(res => new OrderReturnRequest().adapt(res.payload)));
  }

  /* END RETURN REQUEST */

  /* DELIVERY ORDER*/
  createDeliveryOrderFormGroup(
    deliverOrder?: DeliveryOrder,
    isFromReturnRequest?: boolean
  ): FormGroup {
    return this.fb.group({
      id: [deliverOrder && deliverOrder.id ? deliverOrder.id : undefined],
      delivererId: [
        deliverOrder && deliverOrder.delivererId
          ? deliverOrder.delivererId
          : undefined,
      ],
      receiverId: [
        deliverOrder && deliverOrder.receiverId
          ? deliverOrder.receiverId
          : undefined,
      ],
      createDate: [
        deliverOrder && deliverOrder.createDate
          ? deliverOrder.createDate
          : undefined,
      ],
      sendDate: [
        deliverOrder && deliverOrder.sendDate
          ? moment(deliverOrder.sendDate, DISPLAY_DATE_FORMAT).toDate()
          : moment().toDate(),
        Validators.required,
      ],
      dnNo: [deliverOrder && deliverOrder.dnNo ? deliverOrder.dnNo : undefined],
      transferSendItems: this.fb.array(
        deliverOrder && deliverOrder.transferSendItems
          ? deliverOrder.transferSendItems.map(item =>
              this.creteDeliveryOrderItemFormGroup(item, isFromReturnRequest)
            )
          : []
      ),
      status: [
        deliverOrder && deliverOrder.status ? deliverOrder.status : undefined,
      ],
      courier: [
        deliverOrder && deliverOrder.courier ? deliverOrder.courier : undefined,
      ],
      trackingNo: [
        deliverOrder && deliverOrder.trackingNo
          ? deliverOrder.trackingNo
          : undefined,
      ],
      additionalRemark: [
        deliverOrder && deliverOrder.additionalRemark
          ? deliverOrder.additionalRemark
          : undefined,
        [Validators.maxLength(100)],
      ],
    });
  }

  creteDeliveryOrderItemFormGroup(
    item?: DeliveryOrderItem,
    isFromReturnRequest?: boolean
  ): FormGroup {
    let isDrugOrVaccine = true;
    if (item) {
      const storeItem = this.akitaChargeItemQuery.getChargeItem(item.itemRefId);
      if (storeItem) {
        isDrugOrVaccine =
          storeItem.itemType === 'DRUG' || storeItem.itemType === 'VACCINATION';
      }
    }

    return this.fb.group({
      removeSelection: [false],
      itemRefId: [
        {
          value: item && item.itemRefId ? item.itemRefId : undefined,
          disabled: isFromReturnRequest,
        },
        Validators.required,
      ],
      uom: [
        { value: item && item.uom ? item.uom : undefined, disabled: true },
        Validators.required,
      ],
      batchNumber: [
        {
          value: item && item.batchNumber ? item.batchNumber : undefined,
          disabled: isFromReturnRequest,
        },
        isDrugOrVaccine ? Validators.required : [],
      ],
      quantity: [
        {
          value: item && item.quantity ? item.quantity : undefined,
          disabled: isFromReturnRequest,
        },
        [Validators.required, Validators.min(1), IntValidator()],
      ],
      expiryDate: [
        {
          value: item && item.expiryDate ? item.expiryDate : undefined,
          disabled: true,
        },
        isDrugOrVaccine ? Validators.required : [],
      ],
      manufacturerDate: [
        {
          value:
            item && item.manufacturerDate ? item.manufacturerDate : undefined,
          disabled: true,
        },
        isDrugOrVaccine ? Validators.required : [],
      ],
      comment: [item && item.comment ? item.comment : undefined],
      lineNo: [item && item.lineNo ? item.lineNo : undefined],
      uniqueId: [makeDraftId(25)],
      stockListEmpty: false,
      purchasePrice: [item && item.purchasePrice ? item.purchasePrice : undefined],
    });
  }

  addReturnRequestDO(
    returnRequestId: string,
    deliveryOrder: DeliveryOrder
  ): Observable<DeliveryOrder> {
    return this.apiInventoryService
      .addReturnRequestDO(returnRequestId, deliveryOrder)
      .pipe(map(res => new DeliveryOrder().adapt(res.payload)));
  }

  updateReturnRequestDO(
    returnRequestId: string,
    deliveryOrderId: string,
    deliveryOrder: DeliveryOrder
  ): Observable<DeliveryOrder> {
    return this.apiInventoryService
      .updateReturnRequestDO(returnRequestId, deliveryOrderId, deliveryOrder)
      .pipe(map(res => new DeliveryOrder().adapt(res.payload)));
  }

  addTODO(
    orderId: string,
    deliveryOrder: DeliveryOrder
  ): Observable<DeliveryOrder> {
    return this.apiInventoryService
      .addTODO(orderId, deliveryOrder)
      .pipe(map(res => new DeliveryOrder().adapt(res.payload)));
  }

  updateTODO(
    orderId: string,
    deliverOrderId: string,
    deliveryOrder: DeliveryOrder
  ): Observable<DeliveryOrder> {
    return this.apiInventoryService
      .updateTODO(orderId, deliverOrderId, deliveryOrder)
      .pipe(map(res => new DeliveryOrder().adapt(res.payload)));
  }
  /* END DELIVERY ORDER*/

  /* ORDER ITEM */

  getApprovedOrderSubtotal(items: Array<OrderItem>): number {
    let amountList = items.map(
      item => item.unitPrice.price * item.approvedQuantity
    );

    let subtotalAmount = 0;
    if (amountList.length > 0) {
      amountList.forEach(amount => (subtotalAmount += amount));
    }

    return subtotalAmount;
  }

  getOrderSubtotal(items: Array<OrderItem>): number {
    let amountList = items.map(item => item.unitPrice.price * item.quantity);

    let subtotalAmount = 0;
    if (amountList.length > 0) {
      amountList.forEach(amount => (subtotalAmount += amount));
    }

    return subtotalAmount;
  }

  getOrderGST(items: Array<OrderItem>): number {
    const gstAmount = this.getOrderSubtotal(items) * (this.gst - 1);
    return gstAmount;
  }

  getOrderTotal(items: Array<OrderItem>): number {
    const totalAmount = this.getOrderSubtotal(items) + this.getOrderGST(items);
    return totalAmount;
  }
  /* END ORDER ITEM */

  /* RETURN REQUEST ITEM */
  getReturnRequestSubtotal(items: Array<OrderReturnRequestItem>): number {
    let amountList = items.map(item => item.getItemAmount());
    if (amountList.length > 0) {
      return amountList.reduce((sum, amount) => (sum += amount));
    } else {
      return 0;
    }
  }

  getReturnRequestGST(items: Array<OrderReturnRequestItem>): number {
    return this.getReturnRequestSubtotal(items) * (this.gst - 1);
  }

  getReturnRequestTotal(items: Array<OrderReturnRequestItem>): number {
    return (
      this.getReturnRequestSubtotal(items) + this.getReturnRequestGST(items)
    );
  }
  /* END RETURN REQUEST ITEM */

  /* ORDER STATUS */

  getOrderStatusLabel(status: OrderStatus) {
    switch (status) {
      case OrderStatus.DRAFT: {
        return `Draft`;
      }
      case OrderStatus.REQUESTED: {
        return `Requested`;
      }
      case OrderStatus.APPROVED: {
        return `Approved`;
      }
      case OrderStatus.INITIAL: {
        return `Initial`;
      }
      case OrderStatus.REJECTED: {
        return `Rejected`;
      }
      case OrderStatus.PARTIAL_APPROVED: {
        return `Partial Approved`;
      }
      case OrderStatus.PARTIAL_RECEIVED: {
        return 'Partially Received';
      }
      case OrderStatus.FULL_RECEIVED: {
        return `Delivered`;
      }
      case OrderStatus.TRANSFER: {
        return `Completed`;
      }
      case OrderStatus.DELETED: {
        return `Deleted`;
      }
      case OrderStatus.FAILED: {
        return 'Failed';
      }
    }

    return '';
  }

  getOrderStatusColorClass(status: OrderStatus) {
    switch (status) {
      case OrderStatus.DRAFT: {
        return `bg-light-grey-blue`;
      }
      case OrderStatus.REQUESTED: {
        return `bg-orangey-yellow`;
      }
      case OrderStatus.PARTIAL_APPROVED:
      case OrderStatus.APPROVED:
      case OrderStatus.INITIAL: {
        return `bg-bluish-green`;
      }
      case OrderStatus.REJECTED:
      case OrderStatus.DELETED: {
        return `bg-lipstick`;
      }
      case OrderStatus.PARTIAL_RECEIVED: {
        return `bg-pale-lime`;
      }
      case OrderStatus.TRANSFER:
      case OrderStatus.FULL_RECEIVED: {
        return `bg-violet`;
      }
      case OrderStatus.FAILED: {
        return 'bg-black';
      }
    }

    return '';
  }
  /* END ORDER STATUS */

  /* RETURN REQUEST STATUS */

  getReturnRequestStatusLabel(status: OrderReturnRequestStatus) {
    switch (status) {
      case OrderReturnRequestStatus.DRAFT: {
        return `Draft`;
      }
      case OrderReturnRequestStatus.CONFIRM: {
        return `Confirm`;
      }
      case OrderReturnRequestStatus.APPROVED: {
        return `Approved`;
      }
      case OrderReturnRequestStatus.REJECTED: {
        return `Rejected`;
      }
      case OrderReturnRequestStatus.RETURNED: {
        return `Returned`;
      }
      case OrderReturnRequestStatus.FAILED: {
        return 'Failed';
      }
    }

    return '';
  }

  getReturnRequestStatusColorClass(status: OrderReturnRequestStatus) {
    switch (status) {
      case OrderReturnRequestStatus.DRAFT: {
        return `bg-light-grey-blue`;
      }
      case OrderReturnRequestStatus.CONFIRM: {
        return `bg-orangey-yellow`;
      }
      case OrderReturnRequestStatus.APPROVED: {
        return `bg-violet`;
      }
      case OrderReturnRequestStatus.REJECTED: {
        return `bg-lipstick`;
      }
      case OrderReturnRequestStatus.RETURNED: {
        return `bg-bluish-green`;
      }
      case OrderReturnRequestStatus.FAILED: {
        return 'bg-black';
      }
    }

    return '';
  }

  /* END RETURN REQUEST STATUS */

  /* START GRN/DO STATUS */

  getGRNStatus(status) {
    switch (status) {
      case GRNStatus.DRAFT: {
        return 'Draft';
      }
      case GRNStatus.CONFIRM: {
        return 'Success';
      }
      case GRNStatus.CANCEL: {
        return 'Rejected';
      }
      case GRNStatus.FAILED: {
        return 'Failed';
      }
      default: {
        return 'Initial';
      }
    }
  }

  getDOStatus(status) {
    switch (status) {
      case DeliveryOrderStatus.DRAFT: {
        return 'Draft';
      }
      case DeliveryOrderStatus.CONFIRM: {
        return 'Success';
      }
      case DeliveryOrderStatus.CANCEL: {
        return 'Rejected';
      }
      case DeliveryOrderStatus.FAILED: {
        return 'Failed';
      }
      default: {
        return 'Initial';
      }
    }
  }

  getGRNClass(status) {
    switch (status) {
      case GRNStatus.DRAFT: {
        return 'bg-light-grey-blue';
      }
      case GRNStatus.CONFIRM: {
        return 'bg-violet';
      }
      case GRNStatus.CANCEL: {
        return 'bg-lipstick';
      }
      case GRNStatus.FAILED: {
        return 'bg-brand-danger';
      }
      default: {
        return 'bg-bluish-green';
      }
    }
  }

  getDOClass(status) {
    switch (status) {
      case DeliveryOrderStatus.DRAFT: {
        return 'bg-light-grey-blue';
      }
      case DeliveryOrderStatus.CONFIRM: {
        return 'bg-violet';
      }
      case DeliveryOrderStatus.CANCEL: {
        return 'bg-lipstick';
      }
      case DeliveryOrderStatus.FAILED: {
        return 'bg-brand-danger';
      }
      default: {
        return 'bg-bluish-green';
      }
    }
  }

  /* END GRN/DO STATUS */

  /* STOCK LIST */
  getCurrentStockList(
    itemGroup: string,
    filter: StockListFilter,
    page: Page
  ): Observable<any> {
    return this.apiInventoryService.listCurrentStockGroupByItem(
      this.store.getClinicId(),
      itemGroup,
      filter,
      page
    );
  }

  getItemUomRatio(
    baseUom: string,
    sourseUom: string
  ): Observable<any> {
    return this.apiInventoryService.getItemUomRatio(
      baseUom,
      sourseUom
    )
  }

  createStockAdjustmentFormGroup(
    item?: StockItem,
    stockList: Array<StockItem> = []
  ) {

    let isDrugOrVaccine = true;
    let allowNegativeInventory = false;
    const storeItem = this.akitaChargeItemQuery.getChargeItem(item.itemRefId);
    if (storeItem) {
        isDrugOrVaccine = storeItem.trackingCode &&
        (storeItem.itemType === 'DRUG' ||
        storeItem.itemType === 'VACCINATION' ||
        storeItem.itemType === 'CONSUMABLE');
        allowNegativeInventory = storeItem.allowNegativeInventory;
    }
    return this.fb.group({
      inventoryId: [item && item.inventoryId ? item.inventoryId : undefined],
      itemCode: [
        { value: item ? item.itemCode : undefined, disabled: true },
        Validators.required,
      ],
      itemName: [{ value: item ? item.itemName : undefined, disabled: true }],
      currentStockLevel: [
        { value: item ? item.availableCount : undefined, disabled: true },
      ],
      newStockLevel: [
        item ? item.availableCount : undefined,
        allowNegativeInventory? [Validators.required] : [Validators.required, Validators.min(0), IntValidator()],
      ],
      batchNo: [
        { value: item ? item.batchNumber : 0, disabled: !isDrugOrVaccine },
        isDrugOrVaccine
          ? [Validators.required, UniqueBatchNumberValidator(stockList)]
          : [],
      ],
      expiryDate: [
        {
          value:
            item && item.expiryDate && item.expiryDate !== '31-12-0001'
              ? moment(item.expiryDate, DISPLAY_DATE_FORMAT).toDate()
              : undefined,
          disabled: !isDrugOrVaccine,
        },
        isDrugOrVaccine ? Validators.required : [],
      ],
      manufacturerDate: [
        {
          value:
            item && item.manufacturerDate
              ? moment(item.manufacturerDate, DISPLAY_DATE_FORMAT).toDate()
              : undefined,
          disabled: false,
        },
      ],
      uom: [
        { value: item ? item.baseUom : 0, disabled: true },
        Validators.required,
      ],
      purposeOfAdjustmentCode: [undefined, Validators.required],
      remark: [undefined, [Validators.maxLength(100)]],
      purchasePrice: [item.purchasePrice, Validators.required],
      isDrugOrVaccine: isDrugOrVaccine,
    });
  }

  setPurchasePrice(item, formgroup) {
    let isDrugOrVaccine = true;
    if (item) {
      const storeItem = this.akitaChargeItemQuery.getChargeItem(item.itemRefId);
      if (storeItem) {
        isDrugOrVaccine = storeItem.trackingCode &&
          (storeItem.itemType === 'DRUG' ||
          storeItem.itemType === 'VACCINATION' ||
          storeItem.itemType === 'CONSUMABLE');

          if (item.purchasePrice == 0 && storeItem.supplierId) {
            const supplier = this.store.getSupplierById(storeItem.supplierId);
            const itemunitPrice = supplier.items.find(sItem => sItem.itemId === item.itemRefId);
            if (!!itemunitPrice && itemunitPrice.unitPrice && itemunitPrice.unitPrice.price) {
              let basePrice = Number((itemunitPrice.unitPrice.price / 100).toFixed(4));
              this.calPurchasePrice(item, storeItem, formgroup, basePrice);
            }
          }

      }
    }
  }
calPurchasePrice(item, storeItem, formgroup, basePrice) {
  this.getItemUomRatio(storeItem.baseUom, storeItem.purchaseUom).pipe(
  ).subscribe(res => {
    item.purchasePrice =(basePrice/res.payload).toFixed(4);
    formgroup.get('purchasePrice').patchValue(item.purchasePrice);
  }, err => {
    item.purchasePrice = basePrice;
  });
}

  adjustStock(stockAdjustment: StockAdjustment) {
    return this.apiInventoryService.adjustStock(
      this.store.getClinicId(),
      stockAdjustment
    );
  }

  getStockByItemId(itemId: string): Observable<Array<StockItem>> {
    return this.apiInventoryService
      .getStockByItemId(this.store.getClinicId(), itemId)
      .pipe(
        map(res => res.payload.map(item => new StockItem().adapt(item))),
        catchError(err => of([]))
      );
  }

  /* END STOCK LIST */

  /* STOCK ADJUSTMENT LIST */

  getStockAdjustmentList(
    itemGroup: string,
    filter: StockAdjustmentListFilter,
    page: Page
  ): Observable<StockAdjustmentListItem[]> {
    return this.apiInventoryService
      .listStockAdjustmentList(
        this.store.getClinicId(),
        itemGroup,
        filter,
        page
      )
      .pipe(
        tap(res => {
          page.pageNumber = res['pageNumber'];
          page.totalPages = res['totalPages'];
          page.totalElements = res['totalElements'];
        }),
        map(res => {
          if (!res.payload) {
            return [];
          }

          const a = res.payload.map(stockTake =>
            new StockTake().adapt(stockTake)
          );
          const b = a.reduce(
            (items: Array<StockAdjustmentListItem>, stockTake: StockTake) => {
              return items.concat(
                stockTake.stockCountItems.map(item => {
                  const listItem = item.toStockAdjustmentListItem(
                    this.store,
                    this.akitaChargeItemQuery
                  );
                  listItem.stockTakeId = stockTake.id;
                  listItem.status = stockTake.approveStatus;
                  listItem.requestDate = stockTake.startDate;
                  listItem.createdDate = stockTake.createdDate;
                  listItem.transactionNo = stockTake.transactionNo;
                  listItem.navIntegrationResult =
                    stockTake.navIntegrationResult;
                  return listItem;
                })
              );
            },
            []
          );

          return b;
        })
      );
  }

  /* END STOCK ADJUSTMENT LIST */

  /* STOCK TAKE */
  createNewStockTakeFormGroup(): FormGroup {
    return this.fb.group({
      startDate: [moment().format(DISPLAY_DATE_FORMAT), Validators.required],
      startTime: [moment().toDate(), Validators.required],
      countName: [undefined, Validators.required],
      countType: [CountType.FULL, Validators.required],
      startRange: [{ value: undefined, disabled: true }, { updateOn: 'blur' }],
      endRange: [{ value: undefined, disabled: true }, { updateOn: 'blur' }],
    });
  }

  createStockTakeItemsFormArray(stockTake: StockTake): FormArray {
    const filteredItems = stockTake.stockCountItems
      .filter(
        item =>
          stockTake.stockTakeStatus ===
            StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE ||
          stockTake.stockTakeStatus === StockTakeStatus.COMPLETED ||
          this.calculateVariance(item) !== 0
      )
      .sort((a, b) => {
        const aItemName = (a && a.itemName ? a.itemName : '').toUpperCase();
        const bItemName = (b && b.itemName ? b.itemName : '').toUpperCase();

        return aItemName > bItemName ? 1 : aItemName < bItemName ? -1 : 0;
      });

    const itemsFormArray = this.fb.array([]);
    filteredItems.forEach(item => {
      const formGroup = this.createStockTakeItemFromGroup(
        item,
        stockTake.stockTakeStatus
      );

      itemsFormArray.push(formGroup);
    });

    return itemsFormArray;
  }

  createStockTakeItemFromGroup(
    item: StockTakeItem,
    status: StockTakeStatus
  ): FormGroup {
    let isDrugOrVaccine = true;
    const storeItem = this.akitaChargeItemQuery.getChargeItem(item.itemRefId);
    if (storeItem) {
        isDrugOrVaccine = storeItem.trackingCode &&
        (storeItem.itemType === 'DRUG' ||
        storeItem.itemType === 'VACCINATION' ||
        storeItem.itemType === 'CONSUMABLE');
    }

    const modifiedExpiryDate =
      item.expiryDate &&
      item.expiryDate.length > 0 &&
      item.expiryDate !== '31-12-0001'
        ? moment(item.expiryDate, DISPLAY_DATE_FORMAT).toDate()
        : undefined;

    const batchNumberDisabled =
      !item.draft &&
      ((isDrugOrVaccine && item.batchNumber && item.batchNumber.length > 0) ||
        !isDrugOrVaccine);
    const expiryDateDisabled =
      !item.draft && ((isDrugOrVaccine && item.expiryDate) || !isDrugOrVaccine);
    const variance =
      status !== StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE
        ? this.calculateVariance(item)
        : 0;

    const currentFG = this.fb.group({
      id: [item.id, Validators.required],
      inventoryId: [item.inventoryId],
      itemRefId: [item.itemRefId, Validators.required],
      itemCode: [item.itemCode, Validators.required],
      itemName: [item.itemName, Validators.required],
      itemType: [
        storeItem && storeItem.itemType && storeItem.itemType.length > 0
          ? storeItem.itemType
          : '',
      ],
      curBatchNumber: [item.curBatchNumber],
      curUom: [item.curUom],
      curExpiryDate: [item.curExpiryDate],
      availableCount: [item.availableCount, Validators.required],
      purchasePrice: [item.purchasePrice],
      unitPrice: [item.purchasePrice],
      batchNumber: [item.batchNumber],
      baseUom: [item.baseUom, Validators.required],
      expiryDate: [modifiedExpiryDate],
      manufacturerDate: [item.manufacturerDate],
      firstQuantity: [
        {
          value: item.firstQuantity,
          disabled: status !== StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE,
        },
      ],
      adjustedQuantity: [
        {
          value: item.adjustedQuantity,
          disabled: status !== StockTakeStatus.IN_PROCESS_SECOND_STOCK_TAKE,
        },
      ],
      purposeOfAdjustmentCode: [
        {
          value: item.purposeOfAdjustmentCode,
          disabled: status === StockTakeStatus.COMPLETED,
        },
      ],
      variance: [
        {
          value: variance,
          disabled: status === StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE,
        },
      ],
      drugOrVaccine: isDrugOrVaccine,
      status: item.status,
      cpRemarks: item.cpRemarks,
      draft: item.draft,
      draftId: item.draftId,
      batchNumberDisabled: batchNumberDisabled,
      expiryDateDisabled: expiryDateDisabled,
    });

    const adjustedQuantityFC = currentFG.get('adjustedQuantity');
    const availableCountFC = currentFG.get('availableCount');
    const batchNumberFC = currentFG.get('batchNumber');
    const expiryDateFC = currentFG.get('expiryDate');
    const firstQuantityFC = currentFG.get('firstQuantity');
    const itemRefIdFC = currentFG.get('itemRefId');
    const purposeOfAdjustmentCodeFC = currentFG.get('purposeOfAdjustmentCode');

    if (!item.draft) {
      if (!!availableCountFC.value && availableCountFC.value > 0) {
        firstQuantityFC.setValidators([
          Validators.required,
          Validators.min(0),
          IntValidator,
        ]);
      }
      adjustedQuantityFC.setValidators([
        Validators.required,
        Validators.min(0),
        IntValidator,
      ]);

      firstQuantityFC.markAsTouched();
      firstQuantityFC.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
    } else {
      itemRefIdFC.valueChanges.subscribe(itemRefId => {
        const chargeItem = this.akitaChargeItemQuery.getChargeItem(itemRefId);
        const isDrugOrVaccine = chargeItem
          ? chargeItem.trackingCode && (chargeItem.itemType === 'DRUG' ||
            chargeItem.itemType === 'VACCINATION' ||
            chargeItem.itemType === 'CONSUMABLE')
          : false;

        if (chargeItem && isDrugOrVaccine) {
          currentFG
            .get('batchNumberDisabled')
            .patchValue(false, { emitEvent: true });
          currentFG
            .get('expiryDateDisabled')
            .patchValue(false, { emitEvent: true });
        } else {
          batchNumberFC.patchValue('');
          expiryDateFC.patchValue('');

          currentFG
            .get('batchNumberDisabled')
            .patchValue(true, { emitEvent: true });
          currentFG
            .get('expiryDateDisabled')
            .patchValue(true, { emitEvent: true });
        }
      });
    }

    currentFG.valueChanges.subscribe(values => {
      const chargeItem = this.akitaChargeItemQuery.getChargeItem(
        itemRefIdFC.value
      );
      const isDrugOrVaccine = chargeItem
        ? chargeItem.trackingCode && (chargeItem.itemType === 'DRUG' ||
          chargeItem.itemType === 'VACCINATION' ||
          chargeItem.itemType === 'CONSUMABLE')
        : false;
      const oneOfThreeFilled =
        (values.batchNumber && values.batchNumber.length > 0) ||
        values.expiryDate ||
        values.firstQuantity;

      if (chargeItem && isDrugOrVaccine && oneOfThreeFilled) {
        batchNumberFC.setValidators([
          Validators.required,
          Validators.minLength(1),
        ]);
        expiryDateFC.setValidators([Validators.required]);
        firstQuantityFC.setValidators([
          Validators.required,
          Validators.min(0),
          IntValidator,
        ]);
      } else {
        batchNumberFC.setValidators([]);
        expiryDateFC.setValidators([]);
        firstQuantityFC.setValidators([]);
      }

      const secondQuantity = values.adjustedQuantity || 0;
      const availableCount = values.availableCount;

      const secondVariance = Math.abs(availableCount - secondQuantity);

      if (
        secondVariance !== 0 &&
        status === StockTakeStatus.IN_PROCESS_SECOND_STOCK_TAKE
      ) {
        purposeOfAdjustmentCodeFC.setValidators([Validators.required]);
      } else {
        purposeOfAdjustmentCodeFC.setValidators([]);
      }

      currentFG.markAllAsTouched();
      adjustedQuantityFC.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
      batchNumberFC.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      expiryDateFC.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      firstQuantityFC.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
      purposeOfAdjustmentCodeFC.updateValueAndValidity({
        onlySelf: true,
        emitEvent: true,
      });
    });

    return currentFG;
  }

  getNirList(page: Page, requestBody: any): Observable<any> {
    return this.apiInventoryService
      .listNirTakeList(this.store.getClinicId(), page, requestBody)
      .pipe(
        tap(res => {
          page.pageNumber = res.payload['pageNumber'];
          page.totalPages = res.payload['totalPages'];
          page.totalElements = res.payload['totalElements'];
        }),
        map(res => {
          return {
            data: res.payload.content.map(item => new NirItems().adapt(item)),
            payload: res,
          };
        })
      );
  }

  submitNirList(
    page: Page,
    vaccinationId: string,
    filter: any
  ): Observable<Array<NirItems>> {
    return this.apiInventoryService
      .submitNirItems(this.store.getClinicId(), vaccinationId, filter, page)
      .pipe(
        tap(res => {
          page.pageNumber = res.payload['pageNumber'];
          page.totalPages = res.payload['totalPages'];
          page.totalElements = res.payload['totalElements'];
        }),
        map(res => {
          if (!res.payload.content) {
            return [];
          }
        })
      );
  }

  startStockTake(startStockTake: StartStockTakeRequest): Observable<StockTake> {
    return this.apiInventoryService
      .startStockTake(this.store.getClinicId(), startStockTake)
      .pipe(map(res => new StockTake().adapt(res.payload)));
  }

  saveAndStopStockTake(
    stockTakeId: string,
    items: Array<StockTakeItem>
  ): Observable<StockTake> {
    return this.apiInventoryService
      .saveAndStopStockTake(stockTakeId, items)
      .pipe(map(res => new StockTake().adapt(res.payload)));
  }

  submitStockTake(stockTakeId: string): Observable<any> {
    return this.apiInventoryService.submitStockTake(stockTakeId);
  }

  calculateVariance(item: StockTakeItem) {
    const firstQuantity = !!item.firstQuantity ? item.firstQuantity : 0;
    const availableCount = !!item.availableCount ? item.availableCount : 0;
    return firstQuantity - availableCount;
  }

  calculateStockAdjustmentVariance(item) {
    const adjustedQuantity = !!item.adjustedQuantity
      ? item.adjustedQuantity
      : 0;
    const availableCount = !!item.availableCount ? item.availableCount : 0;
    return adjustedQuantity - availableCount;
  }

  getApproveStatus(status) {
    switch (status) {
      case StockTakeApproveStatus.APPROVED: {
        return 'Approved';
      }
      case StockTakeApproveStatus.FAILED: {
        return 'Not Approved';
      }
      case StockTakeApproveStatus.NOT_APPROVED: {
        return 'Not Approved';
      }
      case StockTakeApproveStatus.PARTIAL_APPROVED: {
        return 'Partially Approved';
      }
      case StockTakeApproveStatus.REJECTED: {
        return 'Rejected';
      }
    }
  }

  getApproveStatusClass(status) {
    let statusColor = '';

    switch (status) {
      case StockTakeApproveStatus.APPROVED: {
        statusColor = 'bg-bluish-green text-white';
        break;
      }
      case StockTakeApproveStatus.FAILED: {
        statusColor = 'bg-black text-white';
        break;
      }
      case StockTakeApproveStatus.NOT_APPROVED: {
        statusColor = 'bg-black text-white';
        break;
      }
      case StockTakeApproveStatus.PARTIAL_APPROVED: {
        statusColor = 'bg-pale-lime';
        break;
      }
      case StockTakeApproveStatus.REJECTED: {
        statusColor = 'bg-lipstick text-white';
        break;
      }
    }

    return `${statusColor} py-2 px-2 align-self-center`;
  }

  /* END STOCK TAKE */

  /* HELPER FUNCTION */
  isPurchaseOrder(orderType: Type) {
    return orderType === Type.PO;
  }

  isTransferOrder(orderType: Type) {
    return orderType === Type.TO;
  }
  /* END HELPER FUNCTION */

  clearCurrentStockSelectedItemSet(value: boolean) {
    this.clearCurrentStockTakeSelectedSet.next(value);
  }

  selectCurrentStockSelectedItemSet() {
    return this.clearCurrentStockTakeSelectedSet.asObservable();
  }

  emitRefreshStockTake(value) {
    this.refreshStockTake.next(value);
  }

  selectRefreshStockTake() {
    return this.refreshStockTake.asObservable();
  }

  public setPurchaseRequestMiniOrderAmountMet(isMinOrderAmountMet: boolean): void {
    this.isPurchaseRequestMinOrderAmountMetSub.next(isMinOrderAmountMet);
  }

  public getPurchaseRequestMiniOrderAmountMet(): Observable<boolean> {
    return this.isPurchaseRequestMinOrderAmountMetSub.asObservable();
  }
}

export function makeDraftId(length) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export enum InventoryTab {
  STOCK_ADJUSTMENT_LIST = 0,
  STOCK_LIST = 1,
  ORDER_LIST = 2,
  RETURN_LIST = 3,
  STOCK_TAKE = 4,
  DISPOSAL = 5,
}
