import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hyphenToSpace',
})
export class HyphenToSpacePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace('_', ' ');
  }
}
