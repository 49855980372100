import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';

import { VisitManagementService } from '../../../../../../services/visit-management.service';
import { PrintTemplateService } from '../../../../../../services/print-template.service';
import { LoaderService } from '../../../../../../components/loading/loader.service';
import { ApiHsgService } from '../../../../../../services/api-hsg.service';
import { AlertService } from '../../../../../../services/alert.service';

import { AkitaPatientStoreService } from '../../../../../../services/states/akita-patient/akita-patient-store.service';
import { AkitaAppQuery } from '../../../../../../services/states/akita-app.query';
import { AkitaClinicQuery } from '../../../../../../services/states/akita-clinic.query';
import { StoreService } from '../../../../../../services/store.service';

import { PatientHsgService } from '../../services/patient-hsg.service';
import { INVENTORY_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../../../../constants/app.constants';
import HSGPlan from '../../../../../../model/HSGPlan';

import { Clinic } from '../../../../../../objects/response/Clinic';
import { AkitaPatientAppQuery } from '../../../../../../services/states/akita-patient/akita-patient-app.query';
import { map } from 'rxjs/operators';
import { CarePlanService } from '../../services/care-plan.service';
import { AkitaCaseVisitQuery } from '../../../../../../services/states/akita-case-visit.query';
import { DELETE_PLAN_CONFIRMATION_MESSAGE, DELETE_PLAN_TITLE } from '../CarePlanConstants';

import { AkitaCarePlanStore } from '../../../../../../services/states/care-plan/akita-care-plan.store';

import { CarePlanConfirmationModalComponent } from '../care-plan-confirmation-modal/care-plan-confirmation-modal.component';

import * as moment from 'moment';

@Component({
  selector: 'app-care-plan-listing',
  templateUrl: './care-plan-listing.component.html',
  styleUrls: ['./care-plan-listing.component.scss']
})
export class CarePlanListingComponent implements OnInit {

  @Input() setDraftByDefault: boolean = true;

  @Output() onViewEditEmitter = new EventEmitter<any>();

  public moment = moment;

  public plans: any[] = [];
  public patientInfo: any;

  public selectedPlan: any;
  private newPlanSub: Subscription;
  private newPlanAdded: Subscription;
  private selectedGoalsPreviewSub: Subscription;
  private syncPlansSub: Subscription;

  currentPatientId: string;
  plansSyncTime: string;

  public selectedConditions: string[] = [];
  public selectedPcnConditions: any[];
  public showConditions: boolean = false;
  public bsModalRef: BsModalRef;
  isHsgEnrolledPatient: boolean = false;
  isHsgClinic: boolean = false;
  hasHSGRole: boolean = false;
  hasHSGProjectRole: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiHSGService: ApiHsgService,
    private patientHSGService: PatientHsgService,
    private akitaClinicQuery: AkitaClinicQuery,
    private akitaPatientStoreService: AkitaPatientStoreService,
    private modalService: BsModalService,
    private apiHsgService: ApiHsgService,
    private alertService: AlertService,
    private visitManagementService: VisitManagementService,
    private printTemplateService: PrintTemplateService,
    private store: StoreService,
    private loaderService: LoaderService,
    private akitaAppQuery: AkitaAppQuery,
    private permissionsService: NgxPermissionsService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,

    private carePlanService: CarePlanService,
    private akitaCarePlanStore: AkitaCarePlanStore
  ) { }

  ngOnInit() {
    this.isHsgClinic = this.akitaAppQuery.checkClinicFeatureExist('HSG');
    this.hasHSGRole = this.permissionsService.getPermission('ROLE_HSG_ACCESS') ? true : false;
    this.currentPatientId = this.akitaPatientStoreService.patientId || this.activatedRoute.snapshot.paramMap.get('id');

    this.akitaPatientAppQuery.patientInfo$.subscribe(result => {
      this.patientInfo = result;

      this.isHsgEnrolledPatient = this.patientInfo.hsgEnrollment && this.patientInfo.hsgEnrollment.enrolledClinicId === this.store.getClinicId();

      if (this.isHsgClinic && this.hasHSGRole && this.isHsgEnrolledPatient) {
        this.getPlans();
      }
    });

    this.carePlanService.getRefreshCarePlanListing().subscribe(result => {
      this.getPlans(result);
    });


    // TO-DO-SM: Project page loading to be implemented
    // this.hasHSGProjectRole = this.permissionsService.getPermission('ROLE_HSG_PROJECT') ? true : false;


    // if (!this.patientInfo) {
    //   this.apiPatientInfoService
    //     .searchBy('systemuserid', this.currentPatientId)
    //     .subscribe(
    //       res => {
    //         this.isHsgEnrolledPatient = res.payload.hsgEnrollment && res.payload.hsgEnrollment.enrolledClinicId === this.store.getClinicId();
    //         if (this.isHsgClinic && this.hasHSGProjectRole && this.isHsgEnrolledPatient) {
    //           this.getPlans(true);
    //         }
    //       },
    //       err => {
    //         this.alertService.error(JSON.stringify(err));
    //       }
    //     );
    // }

    // this.newPlanAdded = this.patientHSGService.getNewPlanAdded().subscribe({
    //   next: result => {
    //     if (this.isHsgClinic && this.hasHSGRole && this.isHsgEnrolledPatient) {
    //       if (result.patientId) {
    //         this.currentPatientId = result.patientId;
    //       }
    //       this.getPlans(!result.newPlanAdded);
    //     }
    //   }
    // });

    // TO-DO-SM: Double check care plan loadings when swticthing from patient queue
    this.alertService
      .getSelectPatientFromQueue()
      .subscribe(res => {
        if (res) {
          this.loaderService.setLoading(true);
          this.currentPatientId = res.id;
          // this.getPlans(true);
        }
      });

    this.syncPlansSub = this.patientHSGService.getSyncPlans().subscribe({
      next: result => {
        if (result) {
          this.syncPlans();
        }
      }
    });

    this.newPlanSub = this.patientHSGService.getNewPlan().subscribe({
      next: result => {
        if (result) {
          this.showConditions = true;
          this.plans = this.plans.filter(plan => plan.healthPlanStatus !== 'NEW');
          this.plans = this.plans.map(plan => {
            return { ...plan, selected: false }
          });
          this.plans.unshift(result);
        }
      }
    });

    this.visitManagementService
      .getSavedSuccessfully()
      .subscribe(() => {
        if (this.isHsgClinic && this.hasHSGRole && this.isHsgEnrolledPatient) {
          // this.getPlans(true);
        }
      });
  }

  syncPlans() {
    this.loaderService.setLoading(true);
    this.apiHSGService.syncHsgPlansForPatient(this.store.getClinicId(), this.currentPatientId).subscribe(
      result => {
        if (result && result.payload) {
          if (result.message === 'Success') {
            this.plansSyncTime = result.timestamp;
          }
          this.plans = result.payload;
          this.plans = this.plans.sort((a, b) => {
            return !a.submittedDateTime || moment(a.submittedDateTime).isAfter(moment(b.submittedDateTime)) ? -1 : 1;
          })
        }
        this.loaderService.setLoading(false);
      },
      err => {
        this.alertService.error('Plans loading failed ' + JSON.stringify(err.error));
      }
    );
  }

  // TO-DO-SM: This has to be taken to the parent component CarePlanComponent.ts
  private getPlans(isUpdateForm: boolean = false): void {
    this.loaderService.setLoading(true);
    this.apiHSGService.getHsgPlansForPatient(this.store.getClinicId(), this.currentPatientId)
      .pipe(map(
        result => {
          const updatedPlans = [];

          if (result && result.payload) {
            if (result.message === 'Success') {
              this.plansSyncTime = result.timestamp;
            }

            (result.payload as Array<any>).map(data => {
              updatedPlans.push({
                id: data.id,
                visitId: data.visitId,
                hsgPlanVersion: data.hsgPlanVersion,
                healthPlanStatus: data.healthPlanStatus,
                nextCheckUpDate: data.nextCheckUpDate,
                thingsToStartToday: data.thingsToStartToday,
                cmsConditionsForHSG: data.cmsConditionsForHSG,
                planDate: this.getDateString(data.visitDate || data.submittedDateTime),
                clinicName: data.hsgId ? data.enrolledClinicName : this.getClinicName(data.clinicId),
                showActions: data.healthPlanStatus !== 'NEW' && (!data.hsgId || (data.healthPlanStatus === 'DRAFT' || data.healthPlanStatus === 'FAILED')),
                healthGoals: data.healthGoals,
                plannedItemDetails: data.plannedItemDetails,
                recordOfDiscussion: data.recordOfDiscussion,
                nextCheckUpAppointmentId: data.nextCheckUpAppointmentId,
              });
            });
          }

          return updatedPlans;
        }
      ))
      .subscribe(
        result => {
          this.plans = result;
          this.akitaCarePlanStore.set([...this.plans]);

          // if (!this.carePlanService.getCarePlanListLoadedOnce()) {
          //   const draftPlan = this.plans.find(plan => plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED');
          //   if (draftPlan) {
          //     this.onViewEditEmitter.emit(draftPlan);
          //   } else {
          //     this.onViewEditEmitter.emit(undefined);
          //   }

          //   this.carePlanService.setCarePlanListLoadedOnce(true);
          // }

          // if (isUpdateForm) {
          //   const plan = this.plans.find(plan => (plan.visitId === this.akitaCaseVisitQuery.getActiveVisitId()) && (plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED'));
          //   if (plan) {
          //     this.carePlanService.setUpdatedCarePlan(plan)
          //   }
          // }

          // TO-DO-SM: Double check entire code snippet below
          //   this.plans = result.payload;
          //   this.plans = this.plans.sort((a, b) => {
          //     return !a.submittedDateTime || moment(a.submittedDateTime).isAfter(moment(b.submittedDateTime)) ? -1 : 1;
          //   })
          //   if (this.plans.length > 0) {
          //     const draftFailedPlans = this.plans.filter(plan => plan.healthPlanStatus === 'DRAFT' || plan.healthPlanStatus === 'FAILED');
          //     const submitedPlans = this.plans.filter(plan => plan.healthPlanStatus === 'SUBMIT');
          //     const latestPlan = this.plans[0];
          //     this.patientHSGService.setIsAllPlanSubmitted(!(draftFailedPlans && draftFailedPlans.length > 0));
          //     this.patientHSGService.setShowCopyLastGoal(submitedPlans && submitedPlans.length > 0);

          //     if (isInit) {
          //       if (latestPlan.healthPlanStatus !== 'SUBMIT') {
          //         this.patientHsgFormService.initHsgForm();
          //         this.patientHsgFormService.patchHsgForm(latestPlan);
          //         this.onView(latestPlan);
          //       } else {
          //         this.patientHSGService.setLatestPlan(latestPlan);
          //         if (this.patientHsgFormService.hsgForm) {
          //           const goalsArray = this.patientHsgFormService.getForm(HSGFORMS.GOALFORM).get('healthGoals') as FormArray;
          //           if (!goalsArray || goalsArray.controls.length === 0) {
          //             this.onView(latestPlan);
          //           }
          //         } else {
          //           this.onView(latestPlan);
          //         }
          //       }
          //     }
          //   } else {
          //     this.onView(null);
          //     this.patientHSGService.setIsAllPlanSubmitted(true);
          //   }
          // }
          this.loaderService.setLoading(false);
        }
      );
  }

  public getDateString(date: string): string {
    return date ? moment(date, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : moment().format(DISPLAY_DATE_FORMAT);
  }

  public getClinic(id: string): Clinic {
    const clinic = <Clinic>this.akitaClinicQuery.getClinic(id);
    return clinic;
  }

  // TO-Do-SM: Pass correct type
  public onViewEdit(carePlan: any): void {
    this.onViewEditEmitter.emit(carePlan);
  }

  public onPrintPlan(plan: HSGPlan): void {
    this.printTemplateService.printHsgPlan(plan.id);
  }

  public onDeletePlan(plan: HSGPlan): void {
    const initialState = {
      title: DELETE_PLAN_TITLE,
      message: DELETE_PLAN_CONFIRMATION_MESSAGE,
      buttonConfig: {
        isCancel: true,
        isDelete: true,
      }
    };

    const bsModalRef = this.modalService.show(CarePlanConfirmationModalComponent, {
      initialState,
      class: 'modal-sm custom-modal',
      keyboard: false,
      backdrop: 'static',
    });

    bsModalRef.content.event.subscribe(data => {
      this.apiHsgService.deletePlan(plan.id).subscribe(data => {
        if (data) {
          this.carePlanService.removeFormGroup();
          this.getPlans();
          bsModalRef.hide();
          this.alertService.success('Plan deleted successfully!');
        }
      },
        err => {
          this.alertService.error(JSON.stringify(err))
        });
    });
  }

  public getClinicName(id: string): string {
    const clinic = <Clinic>this.akitaClinicQuery.getClinic(id);
    return clinic.name;
  }

  ngOnDestroy(): void {
    // TO-DO-SM: Revisit this section
    // this.newPlanAdded.unsubscribe();
    // this.newPlanSub.unsubscribe();
    // this.selectedGoalsPreviewSub.unsubscribe();
    // this.syncPlansSub.unsubscribe();
  }
}
