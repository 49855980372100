import { Vendor } from './../../../../objects/state/Vendor';
import { AkitaVendorQuery } from './../../../../services/states/akita-vendor.query';
// Libraries
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AkitaChargeItemQuery } from '../../../../services/states/akita-charge-item.query';
// Services
import { StoreService } from './../../../../services/store.service';
import { AkitaClinicChargeItemQuery } from '../../../../services/states/akita-clinic-charge-item.query';
import { ChargeItem } from '../../../../objects/state/ChargeItem';
import { AkitaStoreService } from '../../../../services/states/akita-store.service';

@Component({
  selector: 'app-consultation-search',
  templateUrl: './consultation-search.component.html',
  styleUrls: ['./consultation-search.component.scss'],
})
export class ConsultationSearchComponent implements OnInit, OnDestroy {
  @Input() itemsFormArray: FormArray;
  @Output() onFirstChargeItemDetailsAdded = new EventEmitter<FormArray>();

  itemTypeOptions: Array<any> = [
    { label: 'Medical Test', value: 'LABORATORY' },
    { label: 'Implants', value: 'IMPLANTS' },
    { label: 'Consultation', value: 'CONSULTATION' },
    { label: 'Medical Service', value: 'SERVICE' },
    { label: 'Drug', value: 'DRUG' },
    { label: 'Consumable', value: 'CONSUMABLE' },
    { label: 'Vaccination', value: 'VACCINATION' },
    { label: 'Package', value: 'PACKAGE' },
  ];

  vendors$;

  searchFormGroup: FormGroup;

  searchByCodeField: FormControl;
  searchByNameField: FormControl;
  searchByTypeField: FormControl;
  searchByVendorField: FormControl;
  codesTypeahead = new Subject<string>();

  rows = [];
  selected = [];

  public event: EventEmitter<any> = new EventEmitter();
  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private store: StoreService,
    private fb: FormBuilder,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private akitaClinicChargeItemQuery: AkitaClinicChargeItemQuery,
    private akitaStoreService: AkitaStoreService,
    private akitaVendorQuery: AkitaVendorQuery
  ) {}

  ngOnInit() {
    // this.vendors = this.akitaVendorQuery.vendors as Vendor[];
    this.vendors$ = this.akitaVendorQuery.selectAll();

    this.rows = this.akitaClinicChargeItemQuery.getAll({
      filterBy: (entity: ChargeItem) => entity.status === 'ACTIVE',
    });
    this.initSearchFields();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  initSearchFields() {
    this.searchByCodeField = this.fb.control('');
    this.searchByNameField = this.fb.control('');
    this.searchByTypeField = this.fb.control('');
    this.searchByVendorField = this.fb.control('');

    this.searchByCodeField.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        map(data => {
          data = data.replace(/[^a-zA-Z0-9 ]/g, '');
          this.searchByCodeField.setValue(data, { emitEvent: false });
          return data;
        }),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data => {
        this.akitaStoreService.updateConsultationSearchFilter(
          data,
          this.searchByTypeField.value,
          this.searchByNameField.value,
          this.searchByVendorField.value
        );
      });

    this.searchByNameField.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        map(data => {
          data = data.replace(/[^a-zA-Z0-9 ]/g, '');
          this.searchByNameField.setValue(data, { emitEvent: false });
          return data;
        }),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data => {
        this.akitaStoreService.updateConsultationSearchFilter(
          this.searchByCodeField.value,
          this.searchByTypeField.value,
          data,
          this.searchByVendorField.value
        );
      });

    this.searchByTypeField.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data =>
        this.akitaStoreService.updateConsultationSearchFilter(
          this.searchByCodeField.value,
          data,
          this.searchByNameField.value,
          this.searchByVendorField.value
        )
      );

    this.searchByVendorField.valueChanges
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed)
    )
    .subscribe(data => {

        this.akitaStoreService.updateConsultationSearchFilter(
          this.searchByCodeField.value,
          this.searchByTypeField.value,
          this.searchByNameField.value,
          data
        )
      }
    );
  }

  updateFilter() {
    this.rows = [
      ...this.akitaClinicChargeItemQuery.getAll({
        filterBy: (entity: ChargeItem) => {
          return (
            entity.name.includes(this.searchByNameField.value.toLowerCase()) &&
            entity.code
              .toLowerCase()
              .includes(this.searchByCodeField.value.toLowerCase()) &&
            (this.searchByTypeField.value
              ? entity.itemType === this.searchByTypeField.value
              : true)
          );
        },
      }),
    ];
  }

  
  onAddBtnClicked() {
    if (this.selected.length > 0) {
      this.event.emit(this.selected);

      this.akitaStoreService.updateConsultationSearchFilter(
        '',
        '',
        '',
        ''
      );
    }
  }

  onCancelBtnClicked() {
    this.event.emit('close');

    this.akitaStoreService.updateConsultationSearchFilter(
      '',
      '',
      '',
      ''
    );
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {}

  getConsultationSearchItemList() {
    return this.akitaClinicChargeItemQuery.getConsultationSearchItemList();
  }
}
