import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MedicalCoverage } from '../objects/MedicalCoverage';
import { PatientNoteAdd } from '../objects/request/PatientNoteAdd';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';
import { BackendService } from './backend.service';

// import { API_URL, API_CMS_MANAGEMENT_URL } from './../constants/app.constants';
@Injectable()
export class ApiSmartCmsManagementService {
  private API_URL_ENV;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private backendService: BackendService
  ) {
    this.API_URL_ENV = appConfig.getConfig().API_URL_ENV;
  }

  checkCMIS(clinicId: string, patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/smart-cms/cmis/sync/${clinicId}/${patientId}`,
      {}
    );
  }

  viewCMIS(clinicId: string, patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/smart-cms/cmis/enquiry/${clinicId}/${patientId}`,
      {}
    );
  }
}