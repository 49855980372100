<div class="modal-header">
    <h4 class="modal-title pull-left"> {{ title }} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnCancelClicked()"><span
            aria-hidden="true">&times;</span></button>
</div>
<alert></alert>
<div class="container-fluid h-100">
    <div class="row p-2 pb-3">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <ngx-datatable #table class='bootstrap custom-datatable expandable' [columnMode]="'flex'"
                        [headerHeight]="30" [footerHeight]="0" [rowHeight]="40" [rows]='rows$|async'
                        [groupRowsBy]="'startDate'" [groupExpansionDefault]="true">

                        <ngx-datatable-group-header [rowHeight]="50">
                            <ng-template let-group="group" let-expanded="expanded" ngx-datatable-group-header-template>
                                <div style="padding-left:5px;">
                                    <a [class.datatable-icon-right]="!expanded" [class.datatable-icon-down]="expanded"
                                        class="cursor-pointer" title="Expand/Collapse Group"
                                        (click)="toggleExpandGroup(group)">
                                        <b>Date: {{ group.value[0].startDate }}</b>
                                    </a>
                                </div>
                            </ng-template>
                        </ngx-datatable-group-header>

                        <ngx-datatable-column name="Patient Number" prop="patientNumber" [resizeable]=false
                            [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.patientNumber }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Patient Name" prop="patientName" [resizeable]=false [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.patientName }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Contact Number" prop="contactNumber" [resizeable]=false
                            [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.contactNumber }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Gender" prop="gender" [resizeable]=false [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.gender }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Purpose" prop="appointmentPurpose" [resizeable]=false [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.appointmentPurpose }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Time" prop="startTime" [resizeable]=false [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.startTime }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Type" [resizeable]=false [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.followUp ? 'Follow Up' : 'Appointment' }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Preferred Doctor" prop="preferredDoctor" [resizeable]=false [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.preferredDoc }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Status" prop="status" [resizeable]=false [flexGrow]=2>
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <div>{{ row.status }}</div>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
