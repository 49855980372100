import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaInventoryStore } from './akita-inventory.store';
import { InventorySupplier } from '../../objects/state/InventorySupplier';

@Injectable({ providedIn: 'root' })
export class AkitaInventoryQuery extends QueryEntity<InventorySupplier> {
  inventorySupplierList$ = this.selectAll();

  constructor(protected store: AkitaInventoryStore) {
    super(store);
  }
}
