import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaUomStore } from './akita-uom.store';
import { Uom } from '../../objects/state/Uom';

@Injectable({ providedIn: 'root' })
export class AkitaUomQuery extends QueryEntity<Uom> {
  uomList$ = this.selectAll();

  constructor(protected store: AkitaUomStore) {
    super(store);
  }
}
