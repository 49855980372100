import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PatientConditionsService } from '../../../../components/pcn/patient-conditions/patient-conditions.service';

import { ApiHsgService } from '../../../../services/api-hsg.service';
import { AkitaPCNVisitQuery } from '../../../../services/states/akita-pcn-visit-case.query';
import { PatientHsgFormService } from './services/patient-hsg-form.service';
import { PatientHsgService } from './services/patient-hsg.service';
import { DialogService } from '../../../../services/dialog.service';
import { BsModalService } from 'ngx-bootstrap';
import { QuestionnaireResponsesComponent } from './iquit/questionnaire-responses/questionnaire-responses.component';
import { IquitComponent } from './iquit/iquit.component';

@Component({
  selector: 'app-patient-hsg',
  templateUrl: './patient-hsg.component.html',
  styleUrls: ['./patient-hsg.component.scss']
})
export class PatientHsgComponent implements OnInit, OnDestroy {
  @Input() patientInfo;
  @Input() isProjectPage = false;
  @Input() isInConsultation = false;
  private isAllPlanSubmitted: Subscription;

  public showCreatePlan: boolean = true;

  constructor(
    public router: Router,
    private modalService: BsModalService,
    private patientHSGService: PatientHsgService,
    private patientHsgFormService: PatientHsgFormService,
    private _apiHsgService: ApiHsgService,
  ) { }

  ngOnInit() {
    this.isAllPlanSubmitted = this.patientHSGService.getIsAllPlanSubmitted().subscribe({
      next: result => this.showCreatePlan = result
    });
  }

  public onCreatePlan() {
    this.showCreatePlan = false;
    this.patientHsgFormService.initHsgForm();
    this.patientHSGService.setIsNewPlan(true);
  }

  public onGoBack(): void {
    this.router.navigate(['/pages/projects/hsg/patient_list']);
  }

  public onSyncPlan(): void {
    this.patientHSGService.setSyncPlans(true);
    this.patientHSGService.setSyncVaccineAndSFL(true);
  }

  public onViewQuestionnaireResponses(): void {
    const modal = this.modalService.show(IquitComponent, {
      class: 'modal-lg custom-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  ngOnDestroy(): void {
    this.isAllPlanSubmitted.unsubscribe();
  }
}