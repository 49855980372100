import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayPaymentMode'
})
export class DisplayPaymentModePipe implements PipeTransform {
   paymentModes = {
    'CASH': 'CASH' ,
    'NETS': 'NETS',
    'VISA':  'VISA',
    'MASTER_CARD': 'MASTER CARD',
    'AMERICAN_EXPRESS': 'AMERICAN EXPRESS',
    'JCB': 'JCB',
    'OTHER_CREDIT_CARD': 'OTHER CREDIT CARD',
    'CHEQUE': 'CHEQUE',
    'GRABPAY': 'GRABPAY',
    'STRIPE': 'STRIPE',
    'SHOPBACK': 'SHOPBACK',
    'PAYNOW': 'PAYNOW'
  }

  transform(value: any, args?: any): any {
    return this.paymentModes[value] !== undefined ? this.paymentModes[value] : value;
  }
}