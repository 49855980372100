import { Doctor } from './../../objects/SpecialityByClinic';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface DoctorState extends EntityState<Doctor> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'doctor' })
export class DoctorStore extends EntityStore<DoctorState> {
  constructor() {
    super();
  }
}
