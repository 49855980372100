<div class="modal-header">
    <h4 class="modal-title pull-left">Questionnaire Responses</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-12 mb-1" *ngIf="errorOnboarding">
            <div class="alert alert-danger d-flex justify-content-between" role="alert">
                <div>
                    <span class="title">Onboarding Questionnaire Error</span>
                    {{errorMessageOnboarding}}
                </div>
                <i class="icon-cancel" (click)="errorOnboarding = false"></i>
            </div>
        </div>
        <div class="col-12" *ngIf="errorPatientJourney">
            <div class="alert alert-danger d-flex justify-content-between" role="alert">
                <div>
                    <span class="title">Patient Journey Error</span>
                    {{errorMessagePatientJourney}}
                </div>
                <i class="icon-cancel" (click)="errorPatientJourney = false"></i>
            </div>
        </div>
        <div class="col-12 mb-1" *ngIf="errorHealthQuestionnaire">
            <div class="alert alert-danger d-flex justify-content-between" role="alert">
                <div>
                    <span class="title">Health Questionnaire Error</span>
                    {{errorMessageHealthQuestionnaire}}
                </div>
                <i class="icon-cancel" (click)="errorHealthQuestionnaire = false"></i>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- Onboarding Questionnaire -->
        <div class="col-12 mb-2">
            <div class="row" *ngIf="isOnboardingLoading">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="spinner-border text-secondary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

            <div class="wrapper-main" *ngIf="!isOnboardingLoading && onboardingQuestionnaire">
                <div class="row d-flex align-items-center">
                    <div class="col-6">
                        <div class="wrapper-row">
                            <span class="title d-block">{{onboardingQuestionnaire.title}}</span>
                            <div class="d-flex">
                                <span class="text d-block mr-3">Last Updated :
                                    {{onboardingQuestionnaire.lastUpdatedDate}}</span>
                                <span class="text">Version : {{onboardingQuestionnaire.version}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <button class="button-green w-100" (click)="onClickOnboardingResponse()">
                            View Responses
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Journey -->
        <div class="col-12">
            <div class="card-custom">
                <div class="card-header">
                    Patient Journey
                </div>
                <div class="card-body">
                    <div class="row" *ngIf="isJourneyLoading">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <div class="spinner-border text-secondary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>

                    <app-patient-journey *ngIf="!isJourneyLoading && patientJourney" [patientJourney]="patientJourney">
                    </app-patient-journey>
                </div>
            </div>
        </div>

        <!-- Health Questionnaire -->
        <div class="col-12" *ngIf="isHsgPatient">
            <div class="card-custom">
                <div class="card-header">Health Questionnaire</div>
                <div class="card-body">
                    <div class="row" *ngIf="isHealthLoading">
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <div class="spinner-border text-secondary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <app-questionnaire-list *ngIf="!isHealthLoading" [healthQuestionnaire]="healthQuestionnaire">
                    </app-questionnaire-list>
                </div>
            </div>
        </div>
    </div>
</div>