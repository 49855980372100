<div class='timeline__container no-gutters' >
    <!-- {{ item.value | json }} -->
    <div class='timeline__left pb-4'>
        <div class="d-flex justify-content-center">
            <span *ngIf="item.status === 'ACTIVE'">{{item.addedDateTime|displayDate}}</span>
            <span *ngIf="item.status !== 'ACTIVE'"><del>{{item.addedDateTime|displayDate}}</del></span>
        </div>
        <!-- <div class="row col-12 text-grey-extra-small">{{formatTitleCase(item.typeOfProblem)}}</div> -->
        <div [class]="getProblemStateClass()">{{displayProblemState}} </div>

        <div class="problem-list-badge date">{{item.dateOfOnSet|displayDate}}</div>
    </div>
    <div class='timeline__right'>
        <div class="timeline__dot"></div>
        <div class="float-right">
             <i class="icon-pencil cursor-pointer" (click)="editNoteClicked()"></i>
            <i *ngIf="item.status === 'ACTIVE'" class="icon-trash cursor-pointer text-grey-small" (click)="deleteNoteClicked()"></i>
        </div>
        <p class='timeline__content mb-2'>
            <span *ngIf="!editMode">
        <span *ngIf="item.status === 'ACTIVE'">{{item.note}}</span>
            <span *ngIf="item.status !== 'ACTIVE'"><del>{{item.note}}</del></span>
            </span>
          <span *ngIf="editMode">
            <div class="row">
                 <div class="col-md-5">
            <input type="text" [(ngModel)]="item.note" #note class="form-control form-control-sm" />
                 </div>
               <div class="col-md-5">
             <ng-select name="type" #type1 [(ngModel)]="item.typeOfProblem"
                        class='p-0 form-control form-control-sm custom-select custom-select-extra-medium-width'
                        [items]="types" bindLabel='label' bindValue='value' [addTag]="false" [virtualScroll]="true"
                        [clearable]=false >
          </ng-select>
               </div>
               <div *ngxPermissionsOnly="['ROLE_MANAGE_PATIENT_NOTES']"
                    class="form-group col-md-2 mb-0 align-self-center">
          <button class='btn btn-sm btn-brand-primary brand pull-right' (click)="updateDetails()"
                  >Save</button>
        </div>
            </div>

          </span>
        </p>

        <div class='timeline__author text-grey-small-10 '>
            <!-- Added by {{getDoctorName(item.doctorId)}} -->
            Added by {{item?.doctorName}} ({{item?.clinicId}})
        </div>
        <div class="timeline__separator mb-3"></div>
    </div>
</div>
