import {Injectable} from '@angular/core';
import {AkitaWhitelistedItemQuery} from "./states/akita-whitelisted-items.query";
import {AkitaWhitelistedItemStore, WhitelistedItem} from "./states/akita-whitelisted-items.store";
import {AkitaClinicQuery} from "./states/akita-clinic.query";
import {filter, first, map, switchMap} from 'rxjs/operators';
import {AkitaPatientAppQuery} from "./states/akita-patient/akita-patient-app.query";
import {combineLatest, forkJoin, Observable, of} from "rxjs";
import {ChargeItem} from "../objects/state/ChargeItem";
import {Clinic} from "../objects/state/Clinic";
import {AkitaChargeItemQuery} from "./states/akita-charge-item.query";
import {Logger} from "../util/logger";

const logger = new Logger('WhitelistedItemService', 'whitelisted');

@Injectable({
  providedIn: 'root'
})
export class WhitelistedItemService {
  constructor(
    private store: AkitaWhitelistedItemStore,
    private query: AkitaWhitelistedItemQuery,
    private clinicQuery: AkitaClinicQuery,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaChargeItemQuery: AkitaChargeItemQuery
  ) { }

  withWhitelistedItemFor(itemId?: string): Observable<{ whitelistedItem: WhitelistedItem, chargeItem: ChargeItem }> {
    let chargeItem$ = this.akitaChargeItemQuery.selectEntity(itemId).pipe(first()) as Observable<ChargeItem>;
    let currentClinic = this.clinicQuery.selectCurrentClinic().pipe(first()) as Observable<Clinic>;
    let whitelistedItem = chargeItem$.pipe(switchMap(item => {
      if (!item) return of(null);
      return this.query.selectEntity(item.id)
        .pipe(switchMap(whitelistedItem => {
          if (whitelistedItem) return of(whitelistedItem);
          return this.query.selectEntity(item.genericSddCode)
        }))
    })).pipe(first());

    let patientInfo$ = this.akitaPatientAppQuery.patientInfo$.pipe(first());
    return combineLatest([currentClinic, whitelistedItem, chargeItem$, patientInfo$]).pipe(map((props) => {
      let [clinic, whitelistedItem, chargeItem, patientInfo] = props
      if (!chargeItem) return this.fail('No charge item', props)
      if (!clinic || !clinic.clinicFeatures.includes('HSG')) return this.fail('No clinic or clinic does not have HSG feature', props)
      if (!clinic.clinicFeatures.includes('HSG_1_5')) return this.fail('Clinic does not have HSG 1.5 feature', props)
      if (!whitelistedItem) return this.fail('No whitelisted item', props)
      if (patientInfo?.hsgEnrollment?.enrolledClinicId !== clinic.id) return this.fail('Patient is not enrolled in this clinic', props)
      return {whitelistedItem, chargeItem}
    }))
  }

  withWhitelistedItems(itemIds: any[]) {
    if (itemIds.length === 0) return of([]);
    return forkJoin(itemIds.map(itemId => this.withWhitelistedItemFor(itemId)));
  }

  private fail(msg: string, ...args: any[]): null {
    logger.log(msg, ...args)
    return null
  }
}
