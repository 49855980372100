<div class="modal-header">
    <h4 class="modal-title pull-left"> {{ title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnCancelClicked()"><span aria-hidden="true">&times;</span></button>
</div>
<div [formGroup]="appointmentFormGroup" class="container-fluid h-100">
    <!-- {{ appointmentFormGroup.value | json}} -->
    <div class="row p-2 pb-3">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <alert></alert>
                    <div class="row  justify-content-end">
                        <div class="col-md-8 pb-0">
                            <h6>Patient Lookup</h6>
                        </div>

                        <div class="col-4 align-self-end">
                        </div>
                    </div>
                    <div class="row">
                        <div class="pb-0" [ngClass]="followUpClinicFeature() ? 'col-md-6' : 'col-md-12'">
                            <label>Patient Name<span class="compulsory">*</span></label>
                        </div>
                        <div class="col-md-6 pb-0" *ngIf="followUpClinicFeature()">
                          <input type="checkbox" formControlName="followUp" /> Follow up
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9 appointmentsSelect">
  <ng-select class="custom-select form-control-sm custom-select-extra-medium-width"  (change)="onPatientChange($event)"
                             [items]="patientListDropdown" formControlName="patientId" bindLabel="name" bindValue="patientId" [typeahead]="searchName" (clear)="listenForPatientNameInput()">
                                <ng-template ng-option-tmp let-item="item " let-index="index" let-search="searchTerm">
                                    <div class="card-body">
                                        <span *ngIf="item.id!=='' && patientListDropdown.length > 0">
                                            <h5 class="card-title" style="white-space:normal;" [ngOptionHighlight]="search">{{item.name}}</h5>
                                            <p class="card-text " >
                                                {{item.id}}
                                                <br> {{item.phone}}
                                            </p>
                                        </span>
                                        <span *ngIf="item.id==='' && patientListDropdown.length === 1">
                                            <div *ngxPermissionsOnly="['ROLE_PATIENT_REGISTRATION']" (click)="addPatient()" class="text-grey">+ Add New Patient </div>
                                        </span>
                                    </div>
                                </ng-template>
                            </ng-select>

                            <errors [control]="appointmentFormGroup.get('patientId')"></errors>
                        </div>
                    </div>
                    <hr>
                    <!-- <div *ngIf="edit && appointmentFormGroup.get('currentStatus').value === 'PENDING'" class="row pt-2"> -->
                    <div class="row">
                        <div class="col-6">
                            <div *ngIf="displayStatusDropdown" class="row pt-2">
                            <div class="col-md-6 pb-0">
                                <h6>Status</h6>
                            </div>
                            </div>
                            <div *ngIf="displayStatusDropdown" class="row pt-2 pb-4">
                            <div class="col-md-12">
                                <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" [items]="apptStatusDropdown" formControlName="status"
                                [clearable]="false"></ng-select>
                            </div>
                            </div>
                        </div>
                        <div [ngClass]="followUpClinicFeature() ? 'col-3' : 'col-6'">
                            <div class="col pb-0 pt-2">
                                <h6>Next Appointment</h6>
                            </div>
                            <div class="pt-2">
                                <input type="text" class="form-control form-control-sm" [value]="nextAppointment" readonly>
                            </div>
                        </div>
                        <div class="col-3" *ngIf="followUpClinicFeature()">
                            <div class="col pb-0 pt-2">
                                <h6>Next Follow up</h6>
                            </div>
                            <div class="pt-2">
                                <input
                                  type="text"
                                  class="form-control form-control-sm"
                                  [value]="nextFollowUpAppointment"
                                  readonly>
                            </div>
                        </div>
                    </div>

                    <div class="row pt-2">
                        <div class="col-md-6 pb-0 ">
                            <h6>Schedule Appointment</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col pb-0 ">
                            <app-add-appointment [appointmentFormGroup]="appointmentFormGroup" [clinicId]='clinicId' [editMode]="editMode"></app-add-appointment>
                            <app-patient-add-consultation [consultationInfoFormGroup]="appointmentFormGroup" [isAppointment]='true' [doctors]="doctors"></app-patient-add-consultation>
                            <app-appointments-error-message [apptIsAvailable]="apptIsAvailable" [hasDoubleAppointments]="hasDoubleAppointments" [apptBookedWithin1Hour]="apptBookedWithin1Hour" (checkBoxEvent)="checkBoxEventCall($event)"></app-appointments-error-message>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-toolbar float-right " role="toolbar ">
                <div class="btn-group " role="group ">
                    <button class="btn btn-large btn-brand-secondary mr-2" (click)="onBtnCancelClicked()">Cancel</button>
                    <button class="btn btn-large btn-brand-primary" (click)="onBtnSaveClicked()" [disabled]="!appointmentFormGroup.valid || !apptIsAvailable || isSaving || (hasDoubleAppointments && !userConfirmedWarning) ">
                        <span *ngIf="!isSaving">Save Appointment (F4)</span>
                        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span *ngIf="isSaving" class="sr-only"> Loading...</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>