import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

import { ExercisePlanValue } from "./CarePlanExerciseRecommendation";
import { AtLeastOneRequiredCheckbox } from "../../views/components/patient/patient-hsg/care-plan/care-plan-body/AtLeastOneRequiredCheckbox";

export class HealthPlanWorkflow {
    constructor(
        readonly id: string | null,
        readonly title: string | null,
        readonly bodySections: Array<HealthPlanSection>,
        readonly bodySectionFormGroup: FormGroup,
        readonly subWorkflowList: Array<HealthPlanWorkflow>,
        readonly canEnableDisable: boolean,
        readonly type: 'multi-choice' | 'other',
    ) { }

    static adapt(goalId: string | null, workflow: any, isSubWorkflow: boolean = false, values?: Array<ExercisePlanValue>): HealthPlanWorkflow {
        return new HealthPlanWorkflow(
            goalId || null,
            workflow ? workflow.title : null,
            HealthPlanSection.adaptBodySection(workflow),
            HealthPlanSection.adaptBodySectionFormGroup(goalId, workflow, isSubWorkflow, values),
            workflow.subWorkflowList ? (workflow.subWorkflowList as Array<any>).map(subworkflow => HealthPlanWorkflow.adapt(goalId, subworkflow, true, values)) : [],
            isSubWorkflow,
            this.getWorkflowType(workflow),
        )
    }

    static getWorkflowType(workflow: any): 'multi-choice' | 'other' {
        if (!workflow || !workflow.sections || workflow.sections.length === 0) {
            return 'other';
        } else {
            const multiChoiceSections = (workflow.sections as Array<any>).filter(section => section.input === 'multi-choice');
            if (multiChoiceSections.length > 0) {
                return 'multi-choice';
            } else {
                return 'other';
            }
        }
    }
}

export class HealthPlanSection {
    constructor(
        readonly number: string,
        readonly input: 'multi-choice' | 'input' | 'input-dropdown' | 'date-picker' | 'single-choice' | 'subworkflow-single-choice' | null,
        readonly provider: 'static',
        readonly label?: string | null,
        readonly type?: string | null,
        readonly placeholder?: string | null,
        readonly uom?: string | null,
        readonly minValue?: number | null,
        readonly maxValue?: number | null,
        readonly acceptingDecimals?: boolean,
        readonly data?: Array<PlanSectionData>,
        readonly errorMsg?: string | null,
    ) { }

    static adapt(item: any): HealthPlanSection {
        return new HealthPlanSection(
            item.number,
            item.input,
            item.provider,
            item.label || null,
            item.type || null,
            item.placeholder || null,
            item.oum || null,
            item.minValue || null,
            item.maxValue || null,
            item.acceptingDecimals || false,
            item.data ? (item.data as Array<any>).map(data => PlanSectionData.adapt(data)) : [],
            item.errorMsg || null,
        )
    }

    static adaptBodySection(workflow: any): Array<HealthPlanSection> | null {
        if (!workflow) {
            return null;
        }

        if (workflow.body !== '') {
            const body = workflow.body;
            const sections = workflow.sections;

            const splitBody = body.split(' ');

            const bodySection: Array<HealthPlanSection> = [];

            const multiChoiceSections = (sections as Array<any>).filter(section => section.input === 'multi-choice');

            // This is done here becahse when it is multi-choice, the label should not be split as there is no placeholders to replace from sections.
            // Its the section itself that has to be put in HTML
            if (multiChoiceSections.length > 0) {
                const mappedSections = multiChoiceSections.map(section => {
                    return new HealthPlanSection(
                        '',
                        'multi-choice',
                        'static',
                        workflow.body,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        (section.data as Array<any>).map(data => PlanSectionData.adapt(data)),
                        section.errorMsg || null,
                    )
                });

                if (mappedSections.length > 0) {
                    bodySection.push(...mappedSections);
                }
            } else {
                splitBody.forEach(element => {
                    if (element.includes('{{')) {
                        const elementPlaceHolder = element.replace('{{', '').replace('}}', '').replace('.', '');
                        const section = sections.find((item: any) => item.placeholder === elementPlaceHolder);
                        bodySection.push(HealthPlanSection.adapt(section!));
                    } else {
                        bodySection.push(new HealthPlanSection('', null, 'static', element));
                    }
                });
            }

            return bodySection;
        } else {
            return null;
        }
    }

    static adaptBodySectionFormGroup(goalId: string | null, workflow: any, isSubWorkflow: boolean = false, values?: Array<ExercisePlanValue>): FormGroup | null {
        if (workflow) {
            const splitBody = workflow.body.split(' ');

            const formGroup = new FormGroup({});

            if (goalId) {
                formGroup.addControl('goalId', new FormControl(goalId, [Validators.required]));
            }

            splitBody.forEach(element => {
                if (element.includes('{{')) {
                    const elementPlaceHolder = element.replace('{{', '').replace('}}', '').replace('.', '');

                    const section: HealthPlanSection = workflow.sections.find((item: any) => item.placeholder === elementPlaceHolder);
                    const validators = [];

                    validators.push(Validators.required);

                    if (section.minValue) {
                        validators.push(Validators.min(section.minValue));
                    }

                    if (section.maxValue) {
                        validators.push(Validators.max(section.maxValue));
                    }

                    const existingValueForPlaceholder = values?.find(item => item.placeholder === elementPlaceHolder);

                    formGroup.addControl(elementPlaceHolder, new FormControl({
                        value: existingValueForPlaceholder ? existingValueForPlaceholder.value : null,
                        disabled: existingValueForPlaceholder ? false : isSubWorkflow ? true : false,
                    }, [...validators]
                    ));

                    if (isSubWorkflow) {
                        formGroup.addControl('selected', new FormControl(existingValueForPlaceholder ? true : false));
                    }
                }
            });

            if (HealthPlanWorkflow.getWorkflowType(workflow) === 'multi-choice') {
                const multiChoiceSections = (workflow.sections as Array<any>).filter(section => section.input === 'multi-choice');
                formGroup.addControl('multiChoice', new FormArray([], [Validators.required]));

                multiChoiceSections.forEach(section => {

                    (section.data as Array<any>).forEach(item => {
                        const existingValueForPlaceholder = values?.find(value => value.placeholder === item.key);

                        const multiChoiceFormGroup = new FormGroup({
                            key: new FormControl(item.key),
                            value: new FormControl(item.value),
                            isChecked: new FormControl(existingValueForPlaceholder ? true : false),
                        });

                        if (item.captureContext) {
                            const validators = [];

                            if (!item.contextOptional) {
                                validators.push(Validators.required);
                            }

                            if (item.minLength) {
                                validators.push(Validators.minLength(item.minLength));
                            }

                            if (item.maxLength) {
                                validators.push(Validators.maxLength(item.maxLength));
                            }

                            const captureContextFormControl = new FormControl({
                                value: existingValueForPlaceholder ? existingValueForPlaceholder.contextValue : null,
                                disabled: existingValueForPlaceholder ? false : true
                            }, validators);

                            multiChoiceFormGroup.addControl('captureContext', captureContextFormControl);
                        }

                        const formArray = formGroup.get('multiChoice') as FormArray;
                        formArray.push(multiChoiceFormGroup);
                        formArray.addValidators(AtLeastOneRequiredCheckbox())
                    });
                });
            }

            return formGroup;
        } else {
            return null;
        }
    }

    static adaptCommentFormGroup(item: any): FormGroup {
        const formGroup = new FormGroup({});

        if (item.context && item.context.comment && item.hsgMeasureCode) {
            formGroup.addControl('comment', new FormControl(null));
            formGroup.addControl('placeholder', new FormControl(item.hsgMeasureCode));
            formGroup.disable();
        }
        return formGroup;
    }
}

export class PlanSectionData {
    constructor(
        readonly key: string,
        readonly value: string,
        readonly contextOptional: boolean,
        readonly captureContext?: boolean,
        readonly contextType?: 'STRING' | null,
        readonly errorMsg?: string | null,
    ) { }

    static adapt(item: any): PlanSectionData {
        return new PlanSectionData(
            item.key,
            item.value,
            item.contextOptional || false,
            item.captureContext || false,
            item.contextType || null,
            item.errorMsg || null,
        )
    }
}