<tr [formGroup]="form">  
    <td>
        <div class="wrapper-form-control">
            <ng-select *ngIf="form.value.isEdit" [clearable]="false" class="custom-select-new custom-select-almost-medium-width"
                [items]="form.value.types.value " bindLabel="label" bindValue="value" placeholder="Select Type"
                formControlName="type">
            </ng-select>
            <errors [control]="form.get('type')"></errors>
            <input *ngIf="!form.value.isEdit" class="input" [value]='form.value.type | displayTitleCase'
                 readonly />
        </div>
    </td>    
    <td *ngIf="form.value.type && form.value.type === 'MEDICAL_ALERT'">
        <div class="wrapper-form-control">
            <ng-select *ngIf="form.value.isEdit" [clearable]=false class="custom-select-new custom-select-extra-medium-width" [items]="alertCodes"
                bindLabel="cmsName" bindValue="cmsCode" placeholder="Select Type" formControlName="alertCode">
            </ng-select>
            <errors [control]="form.get('alertCode')"></errors>
            <input *ngIf="!form.value.isEdit" class="input" [value]='form.value.alertCode | displayTitleCase' readonly />
        </div>
    </td>
    <td *ngIf="!form.value.type || form.value.type != 'MEDICAL_ALERT'">
        <div class="wrapper-form-control">
            <input *ngIf="form.value.isEdit" class="input" value="name" formControlName="name" (focusout)="detectNamechange()"/>
            <errors [control]="form.get('name')"></errors>
            <input *ngIf="!form.value.isEdit" class="input" value="name" formControlName="name" readonly />
        </div>
    </td>
    <td >
        <div class="wrapper-form-control">
            <ng-select *ngIf="form.value.isEdit" [clearable]=false class="custom-select-new custom-select-extra-medium-width"
                [items]="status" bindLabel="label" bindValue="value" placeholder="Select Status"
                formControlName="alertStatus">
            </ng-select>
            <input *ngIf="!form.value.isEdit" class="input" formControlName="alertStatus"
                readonly />
        </div>
    </td>
    <td>
        <div class="wrapper-form-control">
            <input *ngIf="form.value.isEdit" class="input" value="remark" formControlName="remark" />
            <input *ngIf="!form.value.isEdit" class="input" value="remark" formControlName="remark" readonly />
        </div>
    </td>
    <td>
        <div class="wrapper-form-control">
            <div class="float-right" dropdown>
                <button class="btn btn-link-grey p-1" (click)="onShowDetails()"><i
                        [ngClass]="(showDetails) ? 'icon-up-open' : 'icon-down-open'"></i></button>
                <button class="btn btn-link-grey p-1" (click)="onDelete(form)">
                    <i class="icon-trash "></i>
                </button>
                <button class="btn btn-link-grey p-1" *ngIf="!this.form.valid" title="There are errors to be fixed, Please expand and check."
                (click)="onShowDetails()"><i class="icon-attention redBg"></i>{{refreshForm()}}</button>
            </div>
        </div>
    </td>
</tr>
<tr [formGroup]="form" *ngIf="showDetails">
    <td colspan="6">
        <div class="card-custom mb-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <div class="wrapper-form-control">
                            <label>Priority</label>
                            <ng-select *ngIf="form.value.isEdit" [clearable]=false class="custom-select-new custom-select-extra-medium-width"
                                [items]="form.value.priorityDropDown.value" bindLabel="label" bindValue="value"
                                placeholder="Select Condition" formControlName="priority">
                            </ng-select>
                            <input *ngIf="!form.value.isEdit" class="input" [value]='form.value.priority | displayTitleCase'
                                formControlName="priority" readonly />
                        </div>
                    </div>
                    <div class="col-md-3" [formGroup]="form.get('reporter')">
                        <div class="wrapper-form-control">
                            <label>Administrator Type</label>
                            <ng-select *ngIf="form.value.isEdit"  class="custom-select-new custom-select-extra-medium-width" [items]="administratorTypes" [virtualScroll]="true"
                                bindLabel="name" bindValue="value" placeholder="Select Type" formControlName="profession"
                                [clearable]=false (change)="onAdministratorTypeSelect($event, form)"></ng-select>
                                <errors [control]="form.get('reporter').get('profession')"></errors>
                                <input *ngIf="!form.value.isEdit" class="input" [value]='form.value.reporter.profession | displayTitleCase'
                                formControlName="profession" readonly />
                        </div>
                    </div>
    
                    <div class="col-md-3" *ngIf="selectedAdminTypeCode === 'DOCTOR'" [formGroup]="form.get('reporter')">
                        <div class="wrapper-form-control">
                            <label>Administrator </label>
                            <ng-select *ngIf="form.value.isEdit" class="custom-select-new custom-select-extra-medium-width" [items]="doctors" [virtualScroll]="true"
                                bindLabel="name" bindValue="mcr" placeholder="Select Doc" formControlName="regNo" name="regNo" appendTo="body"
                                [clearable]=true (change)="onDoctorChange($event, form)"></ng-select>
                                <errors [control]="form.get('reporter').get('regNo')"></errors>
                                <input *ngIf="!form.value.isEdit" class="input" [value]='getDoctorName(form.value.reporter.regNo) | displayTitleCase' readonly />
                        </div>
                    </div>
    
                    <div class="col-md-3" *ngIf="selectedAdminTypeCode !== 'DOCTOR'" [formGroup]="form.get('reporter')">
                        <div class="wrapper-form-control">
                            <label>Administrator Name</label>
                            <input class="input" formControlName="name" [readonly]="form.value.isEdit === false"/>
                            <errors [control]="form.get('reporter').get('name')"></errors>
                        </div>
                    </div>
                    <div class="col-md-3" *ngIf="selectedAdminTypeCode !== 'DOCTOR'" [formGroup]="form.get('reporter')">
                        <div class="wrapper-form-control">
                            <label>Administrator ID</label>
                            <input class="input" formControlName="regNo" [readonly]="form.value.isEdit === false"/>
                            <errors [control]="form.get('reporter').get('regNo')"></errors>
                        </div>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-md-3">
                        <div class="wrapper-form-control">
                            <label>Start Date</label>
                            <app-date-picker-with-icon [config]="startDateDatePickerConfig"
                                [dateControl]="form.get('startDate')" 
                                [disabled] = "form.value.isEdit === false" 
                                [hideIcon] = "form.value.isEdit === false" >
                            </app-date-picker-with-icon>
                            <errors [control]="form.get('startDate')">
                            </errors>
                        </div>                        
                    </div>
                    <div class="col-md-3">
                        <div class="wrapper-form-control">
                            <label>End Date</label>
                            <app-date-picker-with-icon 
                                [config]="endDateDatePickerConfig"
                                [dateControl]="form.get('expiryDate')"
                                [disabled] = "form.value.isEdit === false"
                                [hideIcon] = "form.value.isEdit === false">
                            </app-date-picker-with-icon>
                            <errors [control]="form.get('expiryDate')">
                            </errors>
                        </div>                        
                    </div>
                    <div class="col-md-6">
                        <div class="wrapper-form-control">
                            <label>Update Reason</label>
                            <input class="input" formControlName="updateReason" maxlength="250" [readonly]="form.value.isEdit === false"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </td>
</tr>