import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { DrugQtyBalance } from '../objects/DrugQtyBalance';
import { DISPLAY_DATE_FORMAT, INSUFFICIENT_STOCK, INVENTORY_DATE_FORMAT, SEPARATE_BATCH_AVAILABLE, UNAVAILABLE_INVENTORY } from './../constants/app.constants';

import { Batch } from './../objects/DrugQtyBalance';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DispatchItemService {
  defaultQty = 1;
  drugAvailableBalanceQty: Array<DrugQtyBalance>;
  private updateAllInventoriesRefresh = new Subject();

  constructor() {
    this.drugAvailableBalanceQty = new Array<DrugQtyBalance>();
  }

  getOriginalUnitPriceInCent(value) {
    return parseFloat(((value || 0) * 100).toFixed(5));
  }

  getAdjustedTotalPriceInCents(value) {
    return parseFloat((value * 100).toFixed(5));
  }

  getAdjustedUnitPriceInCents(adjustedTotalPriceInCents, qty) {
    return parseFloat((adjustedTotalPriceInCents / qty).toFixed(5));
  }

  getAdjustedAmountInCents(adjustedUnitPriceInCents, originalUnitPriceInCents) {
    return adjustedUnitPriceInCents >= 0
      ? parseFloat((adjustedUnitPriceInCents - originalUnitPriceInCents).toFixed(5))
      : 0;
  }

  adjustedUnitPriceAboveMin(adjustedUnitPriceInCents) {
    return adjustedUnitPriceInCents >= 1;
  }

  setAdjustedUnitPriceUponValidation(adjustedUnitPriceInCents) {
    if (this.adjustedUnitPriceAboveMin(adjustedUnitPriceInCents)) {
      return parseFloat((adjustedUnitPriceInCents / 100).toFixed(5));
    } else {
      return '0.00';
    }
  }

  updateAllInventories() {
    this.updateAllInventoriesRefresh.next(true);
  }

  getUpdateAllInventoriesRefresh() {
    return this.updateAllInventoriesRefresh;
  }

  getDefaultPurchaseQty(itemDetailedInfo) {
    const dispensingMultiples = itemDetailedInfo['dispensingMultiples'] || '';
    if (itemDetailedInfo['standardDispenseAmount'] > 0) {
      return itemDetailedInfo['standardDispenseAmount'];
    } else {
      return this.defaultQty * dispensingMultiples;
    }
  }

  adjustedAmountInCentsNotZero(adjustedAmountInCents) {
    return adjustedAmountInCents !== 0;
  }

  adjustedPriceLowerThanOriginal(adjustedAmountInCents) {
    return adjustedAmountInCents < 0;
  }

  adjustedUnitPriceAbove0(originalUnitPriceInCent, adjustedTotalPriceInCents) {
    return originalUnitPriceInCent === 0 || adjustedTotalPriceInCents === 0;
  }

  getCalculatedAmount(adjustedUnitPriceInCents, purchaseQty) {
    return ((adjustedUnitPriceInCents / 100) * purchaseQty)
  }

  patchAmount(formControl: AbstractControl, qty, price, toDollars: boolean, onlySelf = false, emitEvent = true) {
    let amount = ((+price * 100 * qty) / 100).toFixed(2);
    formControl.patchValue(amount, { onlySelf, emitEvent });
  }

  addNewDrugQtyBalance(drugId, caseItemInventories) {
    caseItemInventories = this.sortCaseItemInventories(caseItemInventories);
    let caseItemInventoriesInUniqueBatch: Array<Batch> = this.mergeQuantities(
      caseItemInventories
    );

    const drugQtyBalance = new DrugQtyBalance(drugId, null, 0);
    caseItemInventoriesInUniqueBatch.forEach((batch: Batch) => {
      drugQtyBalance.addBatch(batch);
    });

    this.drugAvailableBalanceQty.push(drugQtyBalance);
  }

  buildBatch(inventory) {
    return new Batch(
      inventory.batchNo,
      this.formatInventoryDateToDisplayFormat(inventory.expireDate),
      inventory.saleUomQuantity
    );
  }

  formatInventoryDateToDisplayFormat(date) {

    if (date === '31-12-0001') {
      return moment(date, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
    }
    return moment(date || new Date(), INVENTORY_DATE_FORMAT).format(
      DISPLAY_DATE_FORMAT
    );
  }

  sortCaseItemInventories(caseItemInventories) {
    return caseItemInventories.sort((a, b) => {
      if (!a.expireDate) {
        return 1;
      }
      if (!b.expireDate) {
        return -1;
      }
      const momentA = moment(a.expireDate, INVENTORY_DATE_FORMAT);
      const momentB = moment(b.expireDate, INVENTORY_DATE_FORMAT);
      const batchA = a.batchNo;
      const batchB = b.batchNo;
      if (momentA.isBefore(momentB)){
        return -1;
      } else if (momentA.isSame(momentB)) {
        return (batchA > batchB) ? 1 : -1;
      } else return 1;
    });
  }

  mergeQuantities(caseItemInventories): Array<Batch> {
    let batchArray: Array<Batch> = new Array<Batch>();
    caseItemInventories.forEach(inventory => {
      const batchNo = inventory.batchNo;
      const expiryDate = this.formatInventoryDateToDisplayFormat(
        inventory.expireDate
      );

      const quantity = inventory.saleUomQuantity;
      const existingBatch = batchArray.find(
        (batchInArray: Batch) =>
          batchInArray.getBatchNo() === batchNo &&
          batchInArray.getExpiryDate() === expiryDate
      );

      if (existingBatch === undefined) {
        // Batch not found, add to Array
        const batchToAdd: Batch = this.buildBatch(inventory);
        batchArray.push(batchToAdd);
      } else {
        // Batch found, add to existing batch's quantity
        existingBatch.addToOriTotalQty(quantity);
      }
    });

    return batchArray;
  }

  getRemainingQty(drugId) {
    const drugQtyBalance = this.getDrugQtyBalance(drugId);
    return drugQtyBalance !== null ? drugQtyBalance.getRemainingQty() : -1;
  }

  setDrugDispenseQtyFromBatch(drugId, batchNo, purchaseQty, index) {
    const drugQtyBalance = this.getDrugQtyBalance(drugId);

    if (drugQtyBalance) {
      drugQtyBalance.setBatchDispenseQty(batchNo, purchaseQty, index);
    }

    this.updateAllInventories();
  }

  deleteDrugQtyBalance(drugId) { }

  deleteDrugSubscriptionFromBatch(drugId, batchNo, index) {
    const drugQtyBalance = this.getDrugQtyBalance(drugId);

    if (drugQtyBalance) {
      drugQtyBalance.deleteDrugSubscriptionFromBatch(batchNo, index);

      this.updateAllInventories();
    }
  }

  getDrugBatchDropdownList(drugId) {
    const drugQtyBalance = this.drugAvailableBalanceQty.find(
      item => item.getId() === drugId
    );

    if (drugQtyBalance !== undefined) {
      return drugQtyBalance.getBatchDropdownList();
    } else {
      return null;
    }
  }

  getDrugBatch(drugId, batchNo) {
    const drugQtyBalance: DrugQtyBalance =
      this.drugAvailableBalanceQty.find(item => item.getId() === drugId) ||
      null;
    if (drugQtyBalance) {
      return drugQtyBalance.getBatch(batchNo) || null;
    }
  }

  drugBalanceAvailable(drugId) {
    return (
      this.drugAvailableBalanceQty.findIndex(item => item.getId() === drugId) >
      -1
    );
  }

  getDrugQtyBalance(drugId): DrugQtyBalance {
    return (
      this.drugAvailableBalanceQty.find(item => item.getId() === drugId) || null
    );
  }

  getInventoryValidMsg(
    batchNumber,
    drugId,
    drugDispenseShortId,
    hasCA,
    isConsultationTab
  ) {
    const drugQtyBalance = this.getDrugQtyBalance(drugId);
    const selectedBatch: Batch = this.getDrugBatch(drugId, batchNumber);

    const batchList = this.getDrugBatchDropdownList(drugId);

    const isBatchMandatory =
      batchList &&
      batchList.length > 0 &&
      batchList[0].batchNo !== '' &&
      batchList[0].expiryDate !== '31-12-0001';

    if (!isBatchMandatory && this.getRemainingQty(drugId) < 0) {
      return INSUFFICIENT_STOCK;
    }

    if (!this.checkInventoryIsAvailable(drugQtyBalance, selectedBatch)) {
      return UNAVAILABLE_INVENTORY;
    } else if (
      !this.checkStockIsSufficient(
        drugQtyBalance,
        selectedBatch,
        drugDispenseShortId
      )
    ) {
      return INSUFFICIENT_STOCK;
    } else if (
      this.checkAvailabilityInSeparateBatches(
        drugQtyBalance,
        selectedBatch,
        isConsultationTab,
        hasCA,
        drugDispenseShortId
      )
    ) {
      return SEPARATE_BATCH_AVAILABLE;
    } else {
      return null;
    }
  }

  checkInventoryIsAvailable(
    drugQtyBalance: DrugQtyBalance,
    selectedBatch: Batch
  ) {
    return drugQtyBalance && drugQtyBalance.getBatchList().length > 0;
  }

  checkStockIsSufficient(
    drugQtyBalance: DrugQtyBalance,
    batch: Batch,
    shortId: string
  ) {
    return (
      drugQtyBalance &&
      drugQtyBalance.inventoryStackedStockIsSufficient(batch, shortId)
    );
  }

  checkAvailabilityInSeparateBatches(
    drugQtyBalance: DrugQtyBalance,
    selectedBatch: Batch,
    isConsultationTab,
    hasCA,
    drugDispenseShortId
  ) {
    return (
      selectedBatch &&
      drugQtyBalance.inventoryStockIsSufficient() &&
      !selectedBatch.batchStockIsSufficient() &&
      !isConsultationTab &&
      hasCA
    );
  }

  isService(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType !== 'DRUG') {
        return true;
      } else {
        return false;
      }
    }
  }

  isDrug(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType === 'DRUG') {
        return true;
      } else {
        return false;
      }
    }
  }

  isVaccination(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType === 'VACCINATION') {
        return true;
      } else {
        return false;
      }
    }
  }

  clearDrugQtyBalance() {
    while (this.drugAvailableBalanceQty.length > 0) {
      this.drugAvailableBalanceQty = new Array<DrugQtyBalance>();
    }
  }
}
