import { Injectable } from '@angular/core';

import { combineLatest } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { AkitaChargeItemStore } from './akita-charge-item.store';
import { ChargeItem } from '../../objects/state/ChargeItem';

@Injectable({ providedIn: 'root' })
export class AkitaChargeItemQuery extends QueryEntity<ChargeItem> {
  activeChargeItemList$ = this.selectAll({
    filterBy: (entity: ChargeItem) => entity.status === 'ACTIVE',
    sortBy: AkitaChargeItemQuery.sortByNameAsc,
  });
  chargeItemList$ = this.selectAll({
    sortBy: AkitaChargeItemQuery.sortByNameAsc,
  });
  chargeItemListOptions$ = combineLatest(
    this.chargeItemList$,
    this.chargeItemOptionsAsc
  );
  drugChargeItemList$ = this.selectAll({
    filterBy: (entity: ChargeItem) => entity.itemType === 'DRUG',
    sortBy: AkitaChargeItemQuery.sortByNameAsc,
  });

  static readonly sortByNameAsc = (a: ChargeItem, b: ChargeItem, state) => {
    return a.name.toUpperCase() < b.name.toUpperCase()
      ? -1
      : a.name.toUpperCase() > b.name.toUpperCase()
        ? 1
        : 0;
  };

  constructor(protected store: AkitaChargeItemStore) {
    super(store);
  }

  private chargeItemOptionsAsc(chargeItemList) {
    const chargeItemListOptions = new Array<any>();
    chargeItemList.forEach(item =>
      chargeItemListOptions.push({ label: item.name, value: item.id })
    );
    chargeItemListOptions.sort(
      (a, b) =>
        a.name.toUpperCase() < b.name.toUpperCase()
          ? -1
          : a.name.toUpperCase() > b.name.toUpperCase()
            ? 1
            : 0
    );
    return chargeItemListOptions;
  }

  isChargeItemDrug(id) {
    return (
      this.getAll({
        filterBy: (entity: ChargeItem) =>
          entity.id === id && entity.itemType === 'DRUG',
      }).length > 0
    );
  }

  isChargeItemDrugOrVaccine(id) {
    return (
      this.getAll({
        filterBy: (entity: ChargeItem) =>
          entity.id === id &&
          (entity.itemType === 'DRUG' || entity.itemType === 'VACCINATION'),
      }).length > 0
    );
  }

  getChargeItem(id, property?) {
    const chargeItem = <ChargeItem>this.getEntity(id);
    if (chargeItem) {
      if (property && property.length > 0) {
        return chargeItem[property];
      }
      return chargeItem;
    }
    return undefined;
  }

  getActiveChargeItem(id) {
    const activeChargeItem = <ChargeItem>this.getAll({
      filterBy: (entity: ChargeItem) =>
        entity.status === 'ACTIVE' && entity.id === id,
    })[0];
    return activeChargeItem || undefined;
  }

  getActiveChargeItemByCode(code) {
    const activeChargeItem = <ChargeItem>this.getAll({
      filterBy: (entity: ChargeItem) =>
        entity.status === 'ACTIVE' && entity.code === code,
    })[0];
    return activeChargeItem || undefined;
  }

  getChargeItemByCode(code) {
    const chargeItem = <ChargeItem>this.getAll({
      filterBy: (entity: ChargeItem) => entity.code === code
    })[0];

    return chargeItem || undefined;
  }

  isDrugOrVaccine(id) {
    const chargeItem = <ChargeItem>this.getAll({
      filterBy: (entity: ChargeItem) => entity.id === id,
    })[0];
    return (
      chargeItem.itemType === 'VACCINATION' || chargeItem.itemType === 'DRUG'
    );
  }


  getSalesUOM(id) {
    let item = this.getChargeItem(id);
    return item? item.salesUom : '';
  }
}
