import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryDisplayGRNStatus'
})
export class InventoryDisplayGRNStatusPipe implements PipeTransform {

  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    if (!value.status) {
      value.status = ''
    }

    return this.inventory.getGRNStatus(value.status);
  }

}
