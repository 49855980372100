<form [formGroup]="" novalidate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <app-rich-editor-switcher [control]="addendum">
        </app-rich-editor-switcher>
    </div>
</form>
<div class="float-right px-3 pb-2 mb-2">
    <div class="btn-group">
        <button class="btn btn-medium btn-brand-primary" (click)="onSaveAddendum()">Save </button>
    </div>
</div>