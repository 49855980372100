import { Injectable } from '@angular/core';

import { StoreConfig, EntityStore } from '@datorama/akita';

import { CommunicationType } from '../../objects/state/CommunicationType';
import { AKITA_CACHE_DURATION } from '../../constants/akita.config';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'communicationtype',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaCommunicationTypeStore extends EntityStore<
  CommunicationType
> {
  constructor() {
    super();
  }
}
