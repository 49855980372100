import { Claim, createClaim } from './Claim';
import { createPriceAdjustment, PriceAdjustment, Inventories } from '../Case';

export interface SalesOrder {
  invoices: Array<Invoice>;
  outstanding: number;
  purchaseItem: Array<PurchaseItem>;
  refunds: Array<Refund>;
  salesRefNo: string;
  status: string;
  taxValue: number;
  totalPaid: number;
  totalPayableTax: number;
  totalPrice: number;
  packageItems?: any[];
  approvedRefunds?: any[];
}

export function createSalesOrder(salesOrder) {
  const {
    invoices = new Array<Invoice>(),
    outstanding = 0,
    purchaseItem = new Array<PurchaseItem>(),
    refunds = new Array<Refund>(),
    salesRefNo = '',
    status = '',
    taxValue = 0,
    totalPaid = 0,
    totalPayableTax = 0,
    totalPrice = 0,
    packageItems = [],
    approvedRefunds = []
  } = salesOrder;

  return {
    invoices,
    outstanding,
    purchaseItem,
    refunds,
    salesRefNo,
    status,
    taxValue,
    totalPaid,
    totalPayableTax,
    totalPrice,
    packageItems,
    approvedRefunds
  } as SalesOrder;
}

export interface PurchaseItem {
  batchNo: string;
  cost: CostPrice;
  dispenseQty: number;
  dosage: number;
  duration: number;
  excludedCoveragePlanIds: Array<string>;
  expireDate: string;
  instruct: string;
  inventoried: boolean;
  itemDiscount: number;
  itemPriceAdjustment: PriceAdjustment;
  itemRefId: string;
  itemType: string;
  purchaseQty: number;
  purchaseUom: string;
  remarks: string;
  remoteDelivery: boolean;
  refundQty?: number;
  salesItemId: string;
  sellingPrice: CostPrice;
  soldPrice: number;
  itemCode?: string;
  itemName?: string;
  inventories?: Inventories
}

export function createPurchaseItem(purchaseItem) {
  const {
    batchNo = '',
    cost = createCostPrice({}),
    dispenseQty = 0,
    dosage = 0,
    duration = 0,
    excludedCoveragePlanIds = new Array<string>(),
    expireDate = '',
    instruct = '',
    inventoried = false,
    itemDiscount = 0,
    itemPriceAdjustment = createPriceAdjustment({}),
    itemRefId = '',
    itemType = '',
    purchaseQty = 0,
    purchaseUom = '',
    remarks = '',
    salesItemId = '',
    sellingPrice = createCostPrice({}),
    soldPrice = 0,
  } = purchaseItem;

  return {
    batchNo,
    cost,
    dispenseQty,
    dosage,
    duration,
    excludedCoveragePlanIds,
    expireDate,
    instruct,
    inventoried,
    itemDiscount,
    itemPriceAdjustment,
    itemRefId,
    itemType,
    purchaseQty,
    purchaseUom,
    remarks,
    salesItemId,
    sellingPrice,
    soldPrice,
  } as PurchaseItem;
}

export interface Invoice {
  cashAdjustmentRounding: number;
  claim: Claim;
  discount: Discount;
  invoiceId: string;
  invoiceNumber: string;
  invoiceState: string;
  invoiceTime: string;
  invoiceType: string;
  includedTaxAmount: number;
  paidAmount: number;
  paidTime: string;
  payableAmount: number;
  paymentInfos: Array<PaymentInfo>;
  paymentMode: string;
  planId: string;
  planName: string;
  reasonForDelete: string;
  reference: string;
  salesDiscount: Discount;
  taxAmount: number;
  visitId: string;
}

export function createInvoice(invoice) {
  const {
    cashAdjustmentRounding = 0,
    claim = createClaim({}),
    discount = createDiscount({}),
    invoiceId = '',
    invoiceNumber = '',
    invoiceState = '',
    invoiceTime = '',
    invoiceType = '',
    includedTaxAmount = 0,
    paidAmount = 0,
    paidTime = '',
    payableAmount = 0,
    paymentInfos = new Array<PaymentInfo>(),
    paymentMode = '',
    planId = '',
    planName = '',
    reasonForDelete = '',
    reference = '',
    salesDiscount = createDiscount({}),
    taxAmount = 0,
    visitId = '',
  } = invoice;

  return {
    cashAdjustmentRounding,
    claim,
    discount,
    invoiceId,
    invoiceNumber,
    invoiceState,
    invoiceTime,
    invoiceType,
    includedTaxAmount,
    paidAmount,
    paidTime,
    payableAmount,
    paymentInfos,
    paymentMode,
    planId,
    planName,
    reasonForDelete,
    reference,
    salesDiscount,
    taxAmount,
    visitId,
  } as Invoice;
}

export interface PaymentInfo {
  amount: number;
  billMode: string;
  billTransactionId: string;
  cashAdjustment: number;
  externalTransactionId: string;
  invoiceNumber: string;
  onlinePayment: boolean;
  refundAmount: number;
}

export function createPaymentInfo(paymentInfo) {
  const {
    amount = 0,
    billMode = '',
    billTransactionId = '',
    cashAdjustment = 0,
    externalTransactionId = '',
    invoiceNumber = '',
    onlinePayment = false
  } = paymentInfo;

  return {
    amount,
    billMode,
    billTransactionId,
    cashAdjustment,
    externalTransactionId,
    invoiceNumber,
    onlinePayment
  } as PaymentInfo;
}

export interface Discount {
  discountType: string;
  discountValue: number;
  planName: string;
  remarks: string;
}

export function createDiscount(discount) {
  const { discountType = '', discountValue = 0, planName = '', remarks = '' } = discount;

  return {
    discountType,
    discountValue,
    planName,
    remarks
  } as Discount;
}

export interface CostPrice {
  price: number;
  taxIncluded: boolean;
}

export function createCostPrice(costPrice) {
  const { price = 0, taxIncluded = false } = costPrice;
  return { price, taxIncluded } as CostPrice;
}

export interface Refund {
  billMode: string;
  clinicRefundedAmount: number,
  invoiceNumber: string,
  refundAmount: number
}

export function createRefund(refund) {
  const { billMode = '', clinicRefundedAmount = 0,  invoiceNumber = '', refundAmount = 0 } = refund;
  return { billMode, clinicRefundedAmount, invoiceNumber, refundAmount }  as Refund;
}