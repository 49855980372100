import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { ReferralSpeciality } from '../../objects/state/ReferralSpeciality';

export interface ReferralSpecialityState
  extends EntityState<ReferralSpeciality> {
  ui: {
    clinic: string;
    doctor: string;
    speciality: string;
    type: string;
  };
}

export function createInitialState(): ReferralSpecialityState {
  return {
    ui: {
      clinic: '',
      doctor: '',
      speciality: '',
      type: 'INTERNAL',
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'referralspeciality',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaReferralSpecialityStore extends EntityStore<
  ReferralSpecialityState,
  ReferralSpeciality
> {
  constructor() {
    super();
  }
}
