import { DB_FULL_DATE_FORMAT } from './../constants/app.constants';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'displayLinebreakPipe',
})
export class DisplayLineBreakPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value ? value.replace(/<p>/g, '').replace(/\n|<\/p>/g, '<br>') : '';
  }
}
