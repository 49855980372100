export class StockAdjustment {
  inventoryId: string;
  itemCode: string;
  batchNo: string;
  uom: string;
  expiryDate: string;
  manufacturerDate: string;
  newStockLevel: number;
  purposeOfAdjustmentCode: string;
  remark: string;
  purchasePrice?:any;

  adapt(obj?): StockAdjustment {
    if (!obj) {
      return this;
    }

    this.inventoryId = obj.inventoryId;
    this.itemCode = obj.itemCode;
    this.batchNo = obj.batchNo;
    this.uom = obj.uom;
    this.expiryDate = obj.expiryDate;
    this.manufacturerDate = obj.manufacturerDate;
    this.newStockLevel = obj.newStockLevel;
    this.purposeOfAdjustmentCode = obj.purposeOfAdjustmentCode;
    this.remark = obj.remark;
    this.purchasePrice = obj.purchasePrice;
    return this;
  }
}

export class StockAdjustmentReason {
  code: string;
  instruct: string;
  description: string;
  billAdjustmentIdentifier: boolean;

  adapt(obj?): StockAdjustmentReason {
    if (!obj) {
      return this;
    }

    this.code = obj.code;
    this.instruct = obj.instruct;
    this.description = obj.description;
    this.billAdjustmentIdentifier = obj.billAdjustmentIdentifier ? obj.billAdjustmentIdentifier : false;

    return this;
  }
}
