<div class="card h-100">
    <div class="card-header font-weight-semi-bold">
        MEMO
        <button *ngIf="isModal" type="button" class="close pull-right" aria-label="Close" (click)="onBtnCloseClicked()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="card-body">
        <div class="container-fluid px-0">
            <!-- <div class="row">
         <div class="col-md"><label class="mb-0">To Whom It May Concern</label></div>
      </div> -->
            <ng-container *ngFor="let memoFC of memos.controls; let i = index">
                <div class="row pt-2">
                    <div class="col-md-12">
                        <app-rich-editor-switcher [control]="memoFC.get('memo')" [disabled]="isPaymentMode">
                        </app-rich-editor-switcher>
                        <!-- <app-ckeditor [control]="memoFC.get('memo')" [disabled]="isPaymentMode">
                            <p>Hello
                                <strong>world</strong>
                            </p>
                        </app-ckeditor> -->
                    </div>
                </div>
                <span *ngIf="!isPaymentMode">
                    <div class="row">
                        <div class="col">
                            <label class="mb-0">Memo Template</label>
                            <ng-select class="form-control form-control-sm custom-select p-0" bindLabel="name"
                                bindValue="id" groupBy="type" [items]="loadMemoTemplates() | async"
                                [virtualScroll]="true" (change)="onMemoTemplateChange($event, i)"
                                placeholder="Select Memo Template" appendTo="body">
                            </ng-select>
                        </div>
                    </div>

                    <div class="row flex-row-reverse mt-1 mb-2">
                        <div class="col-md-2">
                            <button class="btn btn-medium btn-outline-grey-800 float-right" (click)="deleteMemo(i)"><i
                                    class="icon-trash mr-2"></i>Delete Memo</button>
                        </div>
                    </div>
                </span>
            </ng-container>
        </div>
    </div>

    <hr>

    <div class="row justify-content-end px-3 pb-2">
        <div class="pr-2"><button *ngIf="!isPaymentMode" class="btn btn-medium btn-outline-brand-secondary"
                (click)="addMemo()">+ Add New Memo</button></div>
        <div class="pr-2"><button *ngIf="!isModal" class="btn btn-medium btn-brand-secondary"
                [disabled]="isMemoPrintDisabled()" (click)="printMemo()"><i class="icon-print mr-2"></i>Print
                Memo</button></div>
        <div class="pr-3"><button *ngIf="isDoctor() && isModal" class="btn btn-medium btn-brand-primary"
                (click)="onBtnSaveClicked()">Save</button></div>
    </div>
</div>
