import { Injectable } from '@angular/core';

import { combineLatest } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { AkitaAllergyGroupStore } from './akita-allergy-group.store';
import { AllergyGroup } from '../../objects/state/AllergyGroup';

@Injectable({ providedIn: 'root' })
export class AkitaAllergyGroupQuery extends QueryEntity<AllergyGroup> {
  allergyGroupList$ = combineLatest(this.selectAll(), this.sortAllergyGroupAsc);

  constructor(protected store: AkitaAllergyGroupStore) {
    super(store);
  }

  private sortAllergyGroupAsc(allergyGroup) {
    const allergyGroupList = [...allergyGroup];
    allergyGroupList.sort(
      (a, b) =>
        a.description.toUpperCase() < b.description.toUpperCase()
          ? -1
          : a.description.toUpperCase() > b.description.toUpperCase()
            ? 1
            : 0
    );
    return allergyGroupList;
  }
}
