<ng-container>
    <div class="row cursor-pointer" [ngClass]="{'modal-sub-header' : isCollapsed , 'modal-sub-header-expanded' : !isCollapsed}" (click)="isCollapsed = !isCollapsed">
        <ng-container [formGroup]='patientCoverageItem'>
            <!-- NEW ITEM -->
            <ng-container *ngIf="isNew.value; else assignedCoveragesTemplate">
                <div class="input-group input-group-sm mb-0 col-6 px-0" (click)="isCollapsed = !isCollapsed">
                    <div class="col-5">
                        <ng-select
                          class="form-control form-control-sm custom-select custom-select-extra-width"
                          [items]="medicalCoverages$|async"
                          [addTag]="false"
                          [loading]="loading"
                          [virtualScroll]="true"
                          [typeahead]="medicalCoverageInput$"
                          (change)="onCoverageSelected($event)"
                          bindLabel='name'
                          bindValue='id'
                          appendTo="body"
                          formControlName='medicalCoverageId'>
                        </ng-select>
                    </div>

                    <div class="col-4">
                        <ng-select
                          class="form-control form-control-sm custom-select custom-select-extra-width"
                          [items]="planSelect"
                          (change)="onPlanSelected($event)"
                          bindLabel='name'
                          bindValue='id'
                          appendTo="body"
                          formControlName='planId'>
                        </ng-select>
                        <div>
                            <errors [control]="patientCoverageItem.get('planId')"></errors>
                        </div>
                    </div>

                    <div class="col-3">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          *ngIf="!hasPermission()"
                          formControlName='patientCoverageId' />

                        <button
                          class="btn btn-outline-brand-secondary find-plan"
                          *ngIf="hasPermission()"
                          (click)="startAutomation()">
                          <i class="fa fa-search" aria-hidden="true" *ngIf="!(rpaLoading$ | async)"></i>
                          <span class="spinner-border text-muted" *ngIf="(rpaLoading$ | async)"></span>
                          Find Plan
                        </button>

                        <div>
                          <errors [control]="patientCoverageItem.get('patientCoverageId')"></errors>
                        </div>
                    </div>
                </div>
                <div class="input-group input-group-sm input-group-append mb-0 col-6 px-0" (click)="isCollapsed = !isCollapsed">
                    <div class="col-3">
                        <app-date-picker-with-icon [config]="datePickerConfigArray['start']" [dateControl]="patientCoverageItem.get('startDate')">
                        </app-date-picker-with-icon>
                        <errors [control]="patientCoverageItem.get('startDate')"></errors>
                    </div>
                    <div class="col-3">
                        <app-date-picker-with-icon [config]="datePickerConfigArray['end']" [dateControl]="patientCoverageItem.get('endDate')">
                        </app-date-picker-with-icon>
                        <errors [control]="patientCoverageItem.get('endDate')"></errors>
                    </div>
                    <div class="col-2">
                        <input class="form-control form-control-sm" type="text" formControlName='costCenter' />
                    </div>
                    <div class="col-3 d-flex justify-content-between">
                        <input class="form-control form-control-sm" type="text" formControlName='remarks' />
                    </div>
                    <div *ngxPermissionsOnly="['ROLE_MEDICAL_MANAGE_POLICY_HOLDER']" class="col-1 input-group-sm input-group-append">
                        <span class="col-6 mr-1">
                          <button
                              *ngxPermissionsOnly="['ROLE_MEDICAL_MANAGE_POLICY_HOLDER']"
                              [popover]="popTemplate2"
                              [outsideClick]="true"
                              type="button"
                              class="btn p-0 menu-button"
                              placement="top">
                                <i class="icon-ellipsis-vert"></i>
                          </button>
                      </span>
                        <span class="col-6">
                          <button type="button" class="btn menu-button p-0">
                              <i [ngClass]="isCollapsed ? 'icon-down-open-big':'icon-up-open-big'"></i>
                          </button>
                      </span>
                    </div>
                </div>
            </ng-container>

            <!-- ALREADY ASSIGNED ITEM -->
            <ng-template #assignedCoveragesTemplate>
                <!-- For simply displaying i.e Patient Details, show these columns -->
                <div class="input-group input-group-sm mb-0 col-6">
                    <div class="col-5">
                        {{ coverageSelected ? coverageSelected.value.name : ''}}
                    </div>
                    <div class="col-4">
                        {{ planSelected.value.name}}
                        <span *ngIf="policyHolderExpired.value" class="invalidItemText text-queue-s mt-1">
                          <br><span class="icon-attention-2">This policy has expired. Please extend policy end date.</span>
                        </span>
                        <span *ngIf="planExpired.value" class="invalidItemText text-queue-s mt-1">
                            <br><span class="icon-attention-2">Plan has expired. Please extend plan end date.</span>
                          </span>
                        <div *ngIf="status.value === 'INACTIVE'" class="invalidItemText  text-queue-s mt-1">
                            <span class="icon-attention-2">This plan is inactive. Please delete accordingly or contact administrator.</span>
                        </div>
                        <div class="invalidItemText text-queue-s mt-1">
                            <div *ngIf="policyInactive.value">
                                <span class="icon-attention-2">
                                  Policy is inactive. Please delete accordingly or contact administrator.
                              </span>
                            </div>
                            <div *ngIf="coverageInactive.value">
                                <span class="icon-attention-2">
                                  Coverage is inactive. Please delete accordingly or contact administrator.
                              </span>
                            </div>
                            <div *ngIf="planInactive.value && status.value != 'INACTIVE'">
                                <span class="icon-attention-2">
                                  Plan is inactive. Please delete accordingly or contact administrator.
                              </span>
                            </div>
                            <div *ngIf="isDuplicate.value">
                                <span class="icon-attention-2">
                                  Duplicate plan added. Please delete accordingly or contact administrator.
                              </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        {{ patientCoverageId.value}}
                    </div>
                </div>
                <div class="input-group input-group-sm mb-0 input-group-append col-6">
                    <div class="col-3" style="padding:0px;">
                        {{ startDate.value}}
                    </div>
                    <div class="col-3" (click)="isCollapsed = !isCollapsed">
                        <span *ngIf="!policyExpired.value">
                          <app-date-picker-with-icon [config]="datePickerConfigArray['end']"
                              [dateControl]="patientCoverageItem.get('endDate')">
                          </app-date-picker-with-icon>
                          <errors [control]="patientCoverageItem.get('endDate')"></errors>
                      </span>
                        <span *ngIf="policyExpired.value">
                          {{ endDate.value}}
                          <br>
                          <span class="invalidItemText text-queue-s mt-1">
                              <span class="icon-attention-2">
                              This medical coverage has expired. Please contact administrator.
                              </span>
                        </span>
                        </span>
                    </div>
                    <div class="col-2">
                        {{ costCenter.value}}
                    </div>
                    <div class="col-3">
                        {{ remarks.value}}
                    </div>
                    <div *ngxPermissionsOnly="['ROLE_MEDICAL_MANAGE_POLICY_HOLDER']" class="col-1 input-group-sm input-group-append">
                        <span class="col-6 mr-1">
                          <button
                              *ngxPermissionsOnly="['ROLE_MEDICAL_MANAGE_POLICY_HOLDER']"
                              [popover]="popTemplate2"
                              [outsideClick]="true"
                              (click)="isCollapsed = !isCollapsed"
                              class="btn p-0 menu-button"
                              type="button"
                              placement="top">
                                    <i class="icon-ellipsis-vert"></i>
                          </button>
                      </span>
                        <span class="col-6">
                          <button
                               (click)="isCollapsed = isCollapsed"
                               type="button"
                               class="btn menu-button p-0">
                              <i [ngClass]="isCollapsed ? 'icon-down-open-big':'icon-up-open-big'"></i>
                          </button>
                      </span>
                    </div>
                </div>
            </ng-template>

        </ng-container>
    </div>

  <div class="alert alert-danger mt-2" role="alert" *ngIf="rpaError">
    {{rpaError}}
  </div>

    <!-- POP UP TEMPLATE -->
    <ng-template #popTemplate2>
        <div class="input-group-append">
            <button class="btn btn-link-grey p-0" (click)="onBtnDeleteClicked()">
              <i class="icon-trash "></i>
          </button>
            <button *ngIf="!isNew.value" class="btn btn-link-grey p-0" (click)="onBtnPrintClicked(patientCoverageItem)">
              <i class="icon-print "></i>
          </button>
        </div>
    </ng-template>

    <!-- ITEM DETAILS -->
    <div [collapse]="isCollapsed">
        <app-medical-coverage-item-detail [remarks]="remarks.value" [coverage]="coverageSelected.value" [plan]="planSelected.value">
        </app-medical-coverage-item-detail>
    </div>
</ng-container>
