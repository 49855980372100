import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { ApiHsgService } from '../../../../../services/api-hsg.service';
import { PatientHsgService } from '../services/patient-hsg.service';

import { AkitaClinicQuery } from '../../../../../services/states/akita-clinic.query';

import { Clinic } from '../../../../../objects/response/Clinic';

import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT, DISPLAY_DATE_MONTH_FORMAT, INVENTORY_DATE_FORMAT } from '../../../../../constants/app.constants';

import * as moment from 'moment';
import { AkitaPatientStoreService } from '../../../../../services/states/akita-patient/akita-patient-store.service';
import { ApiPatientInfoService } from '../../../../../services/api-patient-info.service';
import { StoreService } from '../../../../../services/store.service';
import { SflStatus } from '../../../../../model/HealthierSG';
import { LoaderService } from '../../../../../components/loading/loader.service';
import { AlertService } from '../../../../../services/alert.service';
import { AkitaAppQuery } from '../../../../../services/states/akita-app.query';
import { NgxPermissionsService } from 'ngx-permissions';
import { AkitaPatientAppQuery } from '../../../../../services/states/akita-patient/akita-patient-app.query';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-hsg-vaccine-and-sfl',
  templateUrl: './patient-hsg-vaccine-and-sfl.component.html',
  styleUrls: ['./patient-hsg-vaccine-and-sfl.component.scss']
})
export class PatientHsgVaccineAndSFLComponent implements OnInit, OnDestroy {
  @Input() patientInfo;
  public moment = moment;
  hsgEnrollment: any;

  // public plans: HSGPlan[] = [];
  public screeningEligibility: SflStatus;
  vaccinations: Array<any> = [];
  vaccinationsSyncTime: string;
  sflSyncTime: string;
  currentPatientId: string;
  syncVaccineANdSFLSub: Subscription;
  private componentDestroyed: Subject<void> = new Subject();
  
  constructor(
    private apiHsgService: ApiHsgService,
    private patientHSGService: PatientHsgService,
    private akitaClinicQuery: AkitaClinicQuery,
    private akitaPatientStoreService: AkitaPatientStoreService,
    private apiPatientInfoService: ApiPatientInfoService,
    private loaderService: LoaderService,
    private store: StoreService,
    private alertService: AlertService,
    private akitaAppQuery: AkitaAppQuery,
    private permissionsService: NgxPermissionsService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
  ) { }

  ngOnInit() {
    this.currentPatientId = this.akitaPatientStoreService.patientId;
    this.loadVaccinesAndSFL();
    this.syncVaccineANdSFLSub = this.patientHSGService.getSyncVaccineAndSFL().subscribe({
      next: result => {
        if(result) {
          this.getScreeningEligibilities();
          this.getVaccinations();
        }
      }
    });

    this.akitaPatientAppQuery.patientInfo$
      .pipe(
        debounceTime(500),
        takeUntil(this.componentDestroyed))
      .subscribe(info => {
        if (!this.hsgEnrollment || !this.currentPatientId || this.currentPatientId !== info.id) {
          this.hsgEnrollment = info ? info.hsgEnrollment : null;
          this.currentPatientId = info ? info.id : ''
          this.loadVaccinesAndSFL();
        }
      });
  }

  loadVaccinesAndSFL() {
    const isHsgEnrolledPatient = this.hsgEnrollment && this.hsgEnrollment.enrolledClinicId === this.store.getClinicId();
    const isHsgClinic = this.akitaAppQuery.checkClinicFeatureExist('HSG');
    const hasHSGRole = this.permissionsService.getPermission(
      'ROLE_HSG_ACCESS'
    )
      ? true
      : false;

    if (isHsgClinic && hasHSGRole && isHsgEnrolledPatient){
      this.getScreeningEligibilities();
      this.getVaccinations();
    }
  }

  getScreeningEligibilities() {
    this.loaderService.setLoading(true);
    this.apiPatientInfoService.getScreeningEligibilities(this.currentPatientId, this.store.getClinicId()).subscribe(
      result => {
        this.loaderService.setLoading(false);
        if (result && result.payload) {
          if (result.message === 'Success'){
            this.sflSyncTime = result.timestamp;
          }
          this.screeningEligibility = result.payload;
        }
      },
      err => {
        this.alertService.error('Screening Eligibilities loading failed ' + JSON.stringify(err.error));
      }
    );
  }

  getVaccinations() {
    this.loaderService.setLoading(true);
    this.apiHsgService.getVaccinations(this.currentPatientId, this.store.getClinicId()).subscribe(
      result => {
        this.loaderService.setLoading(false);
        if (result && result.payload && result.payload.bundle && result.payload.bundle.entry) {
          this.vaccinations = result.payload.bundle.entry;
        }
        if (result && result.message === 'Success'){
          this.vaccinationsSyncTime = result.timestamp;
        }
      },
      err => {
        this.alertService.error('Vaccinations loading failed ' + JSON.stringify(err.error));
      }
    );
  }

  public getDateString(date: string): string {
    return date ? moment(date, DB_FULL_DATE_FORMAT).format(DISPLAY_DATE_FORMAT) : '';
  }

  public isDateDue(date: string): boolean {
    return date ? moment(date, DB_FULL_DATE_FORMAT).isBefore(moment()) : false;
  }

  public getClinicName(id: string): string {
    const clinic = <Clinic>this.akitaClinicQuery.getClinic(id);
    return clinic.name;
  }

  getVaccineName(vaccine) {
    const vaccineCode = vaccine?.vaccineCode.find(vaccine => vaccine?.coding?.system === 'Immunisation_Type_(NEHR)');
      return vaccineCode && vaccineCode.coding ? vaccineCode.coding.display : '';
  }

  getAdministratorName(vaccination) {
    if (vaccination.administrator) {
      return vaccination.administrator.profession === 'DOCTOR' ? 
        (vaccination.doctorId ? this.getDoctorName(true, vaccination.doctorId) : 
            this.getDoctorName(false, vaccination.administrator.regNo)) : vaccination.administrator.name
    } else {
      return '';
    }
  }

  public getGivenDate(vaccination): string {
    return vaccination ? moment(vaccination.date, INVENTORY_DATE_FORMAT).format(DISPLAY_DATE_MONTH_FORMAT) : '';
  }

  getDoctorName(isId, doctorId) {
    if (isId) {
      if (doctorId !== 'Other/External') {
        const doctor = this.store.getDoctors().find(doctor => {
          return doctor.id === doctorId;
        });
        if (doctor) {
          return doctor.name;
        } else {
          return '';
        }
      } else if (doctorId === 'Other/External'){
          return 'Other/External';
      }else {
          return '';
      }
    } else {
      const doctor = this.store.getDoctors().find(doctor => {
        return doctor.mcr === doctorId;
      });
      if (doctor) {
        return doctor.name;
      } else {
        return '';
      }
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}