import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { SddCode } from '../../objects/state/SddCode';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'sddCode',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaSddCodeStore extends EntityStore<SddCode> {
  constructor() {
    super();
  }
}
