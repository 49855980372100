import { Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { ApiState, API_STATUS } from '../../objects/state/ApiState';
import { AkitaAppState, AkitaAppStore } from './akita-app.store';
import { map, filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AkitaAppQuery extends QueryEntity<AkitaAppState, ApiState> {
  appClinicCode$ = this.select(entity => entity.clinicCode);
  appClinicId$ = this.select(entity => entity.clinicId);
  appApiState$ = this.selectAll();
  appUserName$ = this.select(entity => entity.currentUser.userName);
  appUser$ = this.select(entity => entity.currentUser);

  storeReadiness$ = combineLatest(this.selectAll(), this.checkStoreReadiness);

  constructor(protected store: AkitaAppStore) {
    super(store);
  }

  private checkStoreReadiness(apiStateList) {
    const apiStatusCount = {
      failure: 0,
      init: 0,
      success: 0,
    };

    apiStateList.forEach(apiState => {
      switch (apiState.apiStatus) {
        case API_STATUS.FAILURE: {
          apiStatusCount.failure += 1;
          break;
        }
        case API_STATUS.INIT: {
          apiStatusCount.init += 1;
          break;
        }
        case API_STATUS.SUCCESS: {
          apiStatusCount.success += 1;
          break;
        }
      }


    });

    return apiStatusCount;
  }

  selectStoreReady(): Observable<boolean> {
    return this.selectAll().pipe(
      filter(statuses => !!statuses && statuses.length > 0),
      map(storeStatuses => {
        const apiStatusCount = {
          failure: 0,
          init: 0,
          success: 0
        }

        storeStatuses.forEach(storeStatus => {
          switch (storeStatus.apiStatus) {
            case API_STATUS.FAILURE: {
              apiStatusCount.failure += 1;
              break;
            }
            case API_STATUS.INIT: {
              apiStatusCount.init += 1;
              break;
            }
            case API_STATUS.SUCCESS: {
              apiStatusCount.success += 1;
              break;
            }
          }
        });

        return (apiStatusCount.failure > 0 || apiStatusCount.init > 0) ? false : true;
      })
    )
  }

  getAppClinicCode() {
    return this.getValue().clinicCode;
  }

  getAppClinicId() {
    return this.getValue().clinicId;
  }

  isPCNClinic(): Observable<boolean> {
    return this.select(entity => entity.clinic.pcnRegistered);
  }

  checkPCNClinic(): boolean {
    return this.getValue().clinic.pcnRegistered;
  }

  checkClinicFeatureExist(clinicFeature) {
    return this.getValue().clinic && this.getValue().clinic.clinicFeatures && this.getValue().clinic.clinicFeatures.includes(clinicFeature);
  }
}
