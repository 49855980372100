<!-- {{attachedMedicalCoverages.value | json}} -->
<div class="px-3">
    <div class="row">
        <div class="col-auto">
            <span class="app-header-data-title">
                    NRIC
                </span>
            <div class="app-header-data-info" style="cursor:help;" [title]="nric">
                {{ nric |textTruncate :10}}
            </div>
        </div>
        <div class="col-auto">
            <span class="app-header-data-title">
                Patient
            </span>
            <div class="app-header-data-info" style="cursor:help;" [title]="patientName">
                {{ patientName |textTruncate :20}}
            </div>
        </div>
        <div class="col-auto">
            <span class="app-header-data-title">
                Sex
            </span>
            <div class="app-header-data-info" [innerHTML]="sex"></div>
        </div>
        <div class="col-auto">
            <span class="app-header-data-title">
                DOB
            </span>
            <div class="app-header-data-info" [innerHTML]="dob"></div>
        </div>
        <!-- <div class="col-auto">
            <span class="app-header-data-title">
                Age
            </span>
            <div class="app-header-data-info" [innerHTML]="age">{{age}}</div>
        </div> -->
        <!-- <div class="col-auto">
            <span class="app-header-data-title">
                Status
            </span>
            <div class="app-header-data-info" [innerHTML]="maritalStatus">{{maritalStatus}}</div>
        </div> -->
        <div class="col-auto">
            <span class="app-header-data-title">
                Occupation
            </span>
            <div *ngIf="occupation!==''" class="app-header-data-info" style="cursor:help;" [title]="occupation">
                {{ occupation | textTruncate :15 }}
            </div>
            <span *ngIf="occupation===''" style="color:darkgray;">
                <div class="app-header-data-info"><i>Not Indicated</i></div>
            </span>
        </div>
        <div class="col-auto">
            <span class="app-header-data-title">
                    Language
                </span>
            <div *ngIf="preferredLanguage!==''" class="app-header-data-info" [innerHTML]="preferredLanguage"></div>
            <span *ngIf="preferredLanguage===''" style="color:darkgray;">
                <div class="app-header-data-info"><i>Not Indicated</i></div>
            </span>
        </div>
        <div class="col-auto">
            <span class="app-header-data-title">
                Contact
            </span>
            <div class="app-header-data-info" [innerHTML]="contactNo"></div>
        </div>
        <!-- <div class="col-auto">
            <span class="app-header-data-title">
                Address
            </span>
            <div class="app-header-data-info" style="cursor:help;" [title]="address1 + ' ' + address2 +' '+ postal">
                {{ address1 + ' ' + address2 +' '+ postal | textTruncate :20 }}
            </div>
        </div> -->
        <div class="col-auto">
            <span class="app-header-data-title">
                Medical Coverage
            </span>
            <span>
            <ng-container *ngIf="showUpdateLayoutForMedicalCoverage(); else isPayment">
                <div class="app-header-data-info link-light-blue">
                    <a (click)="displayMedicalCoveragesModal(true)" class="cursor-pointer"
                        [popover]="planInfoTemplate" placement="bottom" container="header" triggers="mouseenter:mouseleave" 
                        containerClass="plan-info-popover">
                        <span class="spinner-border spinner-border-sm" role="status" *ngIf="loading"></span>
                        <span *ngIf="priorityMedicalCoverage!==''">
                            {{ priorityMedicalCoverage | textTruncate :20}}
                        </span>
                    </a>
                    <a *ngIf="attachedPlan.length === 0 && priorityMedicalCoverage === ''"
                     (click)="displayMedicalCoveragesModal(true)" class="cursor-pointer">
                            Click to assign
                    </a>
                </div>
            </ng-container>
            <ng-template #isPayment>
                <div class="app-header-data-info">
                     <span class="spinner-border spinner-border-sm" role="status" *ngIf="loading"></span>
                    <a *ngIf="priorityMedicalCoverage!==''" (click)="displayMedicalCoveragesModal(false)" class="cursor-pointer"
                    [popover]="planInfoTemplate" placement="bottom" container="header" triggers="mouseenter:mouseleave" 
                    containerClass="plan-info-popover">
                              {{ priorityMedicalCoverage | textTruncate :20}}
                    </a>
                    <div *ngIf="attachedPlan.length === 0 && priorityMedicalCoverage === ''">
                        Unassigned
                    </div>
                </div>
            </ng-template>
        </span>
    </div>
    <div class="col-auto">
        <span class="app-header-data-title">
            PCN
        </span>
        <div class="app-header-data-info" [innerHTML]="primaryCareNetwork"></div>
    </div>
    <div class="col-auto">
        <span class="app-header-data-title">
            HSG
        </span>
        <div class="app-header-data-info" [innerHTML]="hsgStatus"></div>
    </div>
    <div class="col-auto">
        <span class="app-header-data-title">
                Consult in
            </span>
        <div class="app-header-data-info" [innerHTML]="consultInTime"></div>
    </div>
    <div class="col-auto">
        <span class="app-header-data-title">
                Consult out
            </span>
        <div class="app-header-data-info" [innerHTML]="consultOutTime"></div>
    </div>
</div>
</div>

<ng-template #planInfoTemplate class="p-0">
    <div class="card-header">
        Coverage(s) Attached
    </div>
    <div class="container mt-1">
        <div class="row">
            <ul>
                <span *ngFor="let plan of storedAttachedPlan.controls;let i=index;">
                    <li>{{plan.value.planId | displayCoverageAttribute : 'name' }} - {{ plan.value.planId | displayPlanAttribute : 'name' }}</li>
                </span>
            </ul>
        </div>
    </div>
</ng-template>