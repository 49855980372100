import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
  name: 'inventoryDisplayClinicCode'
})
export class InventoryDisplayClinicCodePipe implements PipeTransform {

  constructor(private store: StoreService) {}

  transform(value: any, args?: any): any {
    if (!value) {
      return ''
    }
    
    return (this.store.findClinic(value) || { clinicCode: '' }).clinicCode;
  }

}
