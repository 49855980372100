import { NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawingBoardComponent } from './drawing-board.component';

@NgModule({
  declarations: [DrawingBoardComponent],
  imports: [CommonModule],
  exports: [DrawingBoardComponent],
  entryComponents: [DrawingBoardComponent],
})
export class DrawingBoardModule {}
