<div class="modal-header">
  <h4 class="modal-title pull-left">{{title}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnExit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <alert></alert>
  <form>
    <div class="row">
      <div class="col-md-4">
        <label>Vaccine</label>
        <div class="mb-2">
          <span>{{vaccineName}}</span>
        </div>
      </div>

      <div class="col-md-4">
        <label class="mb-0">Dosage <span *ngIf="!isDoctorConsult || !isDoctor" class="compulsory">*</span></label>
        <div class="input-group input-group-sm mb-2"
            [formGroup]="prescriptionItem.get('dosageInstruction')">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <ng-select name="instruction"
                  class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width'
                  bindLabel='name' bindValue='doseId' [items]="dosageInstructions" [clearable]=true
                  (change)="onDosageInstructionSelect($event)" [searchFn]="searchFn"
                  formControlName='code' appendTo="body">
                  <ng-template ng-header-tmp>
                      <div class="row">
                          <div class="col-md-4 font-weight-semi-bold">Dose Id</div>
                          <div class="col-md-8 font-weight-semi-bold">Name</div>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div class="row">
                          <div class="col-md-4">{{item.doseId}}</div>
                          <div class="col-md-8">{{item.name}}</div>
                      </div>
                  </ng-template>
              </ng-select>
            </div>
            <errors [control]="prescriptionItem.get('dosageInstruction').get('code')"></errors>
        </div>
      </div>

      <div *ngIf="!isPackageItem" class="col-md-2">
        <label class="mb-0">Qty (UOM) <span class="compulsory">*</span></label>
        <div [formGroup]="prescriptionItem" class="input-group input-group-sm mb-2">
            <input name="quantity" class="form-control form-control-sm" type="number" min="0" max="1000"
                formControlName="purchaseQty">
            <div class="input-group-append">
                <span class="input-group-text" id="dosage-addon">{{ getSalesUom() }}</span>
            </div>
        </div>
      </div>

      <div class="col-md-2">
        <label class="mb-0">Dosage Strength </label>
        <div [formGroup]="prescriptionItem.get('vaccinationInfo')" class="input-group input-group-sm mb-2">
          <input name="vaccineDosage" class="form-control form-control-sm" type="number" formControlName="vaccineDosage">
          <div class="input-group-append" *ngIf="!isPackageItem">
            <span class="input-group-text" id="dosage-addon">{{ dosageUom }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label class="mb-0">Route of Administration <span class="compulsory">*</span></label>
        <div class="input-group input-group-sm mb-2"
            [formGroup]="prescriptionItem.get('vaccinationInfo').get('administrationInfo')">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <ng-select name="routeOfAdministrations"
                  class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width'
                  bindLabel='name' bindValue='name' [items]="routeOfAdministrations" [clearable]=true
                  [searchFn]="searchRouteFn" formControlName='route' appendTo="body" (change)="onRouteOfAdministrationSelect($event)">
                  <ng-template ng-header-tmp>
                      <div class="row">
                          <div class="col-md-8 font-weight-semi-bold">Name</div>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div class="row">
                          <div class="col-md-8">{{item.name}}</div>
                      </div>
                  </ng-template>
              </ng-select>
            </div>
            <errors [control]="prescriptionItem.get('vaccinationInfo').get('administrationInfo').get('route')"></errors>
        </div>
      </div>

      <div class="col-md-4">
        <label class="mb-0">Body Site <span *ngIf="isBodySiteRequired && (!isDoctorConsult || !isDoctor)" class="compulsory">*</span></label>
        <div class="input-group input-group-sm mb-2"
            [formGroup]="prescriptionItem.get('vaccinationInfo').get('administrationInfo')">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <ng-select name="bodySites"
                  class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width'
                  bindLabel='name' bindValue='name' [items]="bodySites" [clearable]=true
                  [searchFn]="searchRouteFn" formControlName='site' appendTo="body">
                  <ng-template ng-header-tmp>
                      <div class="row">
                          <div class="col-md-8 font-weight-semi-bold">Name</div>
                      </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div class="row">
                          <div class="col-md-8">{{item.name}}</div>
                      </div>
                  </ng-template>
              </ng-select>
            </div>
            <errors [control]="prescriptionItem.get('vaccinationInfo').get('administrationInfo').get('site')"></errors>
        </div>
      </div>

      <div class="col-md-2">
        <label class="mb-0">Record/Given Date <span class="compulsory">*</span></label>
        <div class="input-group input-group-sm mb-2">
          <app-date-picker-with-icon 
            [config]="datePickerConfig"
            [dateControl]="prescriptionItem.get('vaccinationInfo').get('givenDate')">
          </app-date-picker-with-icon>
          <errors
              [control]="prescriptionItem.get('vaccinationInfo').get('givenDate')">
          </errors>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label class="mb-0">Administrator Type <span class="compulsory">*</span></label>
        <div class="input-group input-group-sm mb-2"
            [formGroup]="prescriptionItem.get('vaccinationInfo').get('administrator')">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <ng-select name="profession"
                  class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width'
                  bindLabel='name' bindValue='value' [items]="administratorTypes" [clearable]=true [readonly]="true"
                  (change)="onAdministratorTypeSelect($event)" formControlName='profession' appendTo="body">
              </ng-select>
            </div>
            <errors [control]="prescriptionItem.get('vaccinationInfo').get('administrator').get('profession')"></errors>
        </div>
      </div>

      <div class="col-md-4">
        <label class="mb-0">Administrator <span class="compulsory">*</span></label>
        <div class="input-group input-group-sm mb-2"
            [formGroup]="prescriptionItem.get('vaccinationInfo').get('administrator')">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <ng-select *ngIf="selectedAdminTypeCode === 'DOCTOR'" name="regNo"
                  class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width'
                  bindLabel='name' bindValue='mcr' [items]="doctors" [clearable]=true
                  formControlName='regNo' appendTo="body">
              </ng-select>
              <input *ngIf="selectedAdminTypeCode !== 'DOCTOR'" name="name" class="form-control form-control-sm" type="text" formControlName="name">
            </div>
            <errors *ngIf="selectedAdminTypeCode === 'DOCTOR'" [control]="prescriptionItem.get('vaccinationInfo').get('administrator').get('regNo')"></errors>
            <errors *ngIf="selectedAdminTypeCode !== 'DOCTOR'" [control]="prescriptionItem.get('vaccinationInfo').get('administrator').get('name')"></errors>
        </div>
      </div>

      <div *ngIf="selectedAdminTypeCode !== 'DOCTOR'" class="col-md-4">
        <label class="mb-0">Administrator ID <span class="compulsory">*</span></label>
        <div class="input-group input-group-sm mb-2"
            [formGroup]="prescriptionItem.get('vaccinationInfo').get('administrator')">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <input name="regNo" class="form-control form-control-sm" type="text" formControlName="regNo">
            </div>
            <errors [control]="prescriptionItem.get('vaccinationInfo').get('administrator').get('regNo')"></errors>
        </div>
      </div>
    </div>
    <div class="row" [formGroup]="prescriptionItem.get('vaccinationInfo')">
      <ng-container formArrayName="vaccinationSchedules">
        <div class="col-md-4" formGroupName="0">
          <label class="mb-0">Next Dose Vaccine</label>
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <ng-select class="p-0 form-control  form-control-sm custom-select custom-select-extra-width" bindLabel="name" bindValue="id" [items]="vaccinations" 
                placeholder="Select Next Dose Vaccine" formControlName="vaccineId">
                <ng-template ng-header-tmp>
                    <div class="row">
                        <div class="col-md-6 font-weight-semi-bold">Code</div>
                        <div class="col-md-6 font-weight-semi-bold">Name</div>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="term">
                    <div class="row">
                        <div class="col-md-6">{{item.code}}</div>
                        <div class="col-md-6">{{item.name}}</div>
                    </div>
                </ng-template>
              </ng-select>
            </div>
            <errors [control]="prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules')['controls'][0].get('vaccineId')"></errors>
          </div>
        </div>
        <div class="col-md-4" formGroupName="0">
          <label class="mb-0">Next Dose</label>
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <ng-select class="p-0 form-control form-control-sm  custom-select custom-select-extra-width" bindLabel="name" bindValue="doseId" [items]="nextDoseVaccine" 
                [virtualScroll]="true" placeholder="Select Next Dose" formControlName="doseId">
                <ng-template ng-header-tmp>
                    <div class="row">
                        <div class="col-md-3 font-weight-semi-bold">Name</div>
                        <div class="col-md-9 font-weight-semi-bold">Description</div>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="term">
                    <div class="row">
                        <div class="col-md-3">{{item.name}}</div>
                        <div class="col-md-9">{{item.description}}</div>
                    </div>
                </ng-template>
              </ng-select>
            </div>
            <errors [control]="prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules')['controls'][0].get('doseId')"></errors>
          </div>
        </div>
        <div class="col-md-2">
          <label class="mb-0">Next Dose Date</label>
          <div class="input-group input-group-sm mb-2">
            <app-date-picker-with-icon [config]="nextDoseDateDatePickerConfig" [dateControl]="prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules')['controls'][0].get('scheduledDate')">
            </app-date-picker-with-icon>
            <errors [control]="prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules')['controls'][0].get('scheduledDate')"></errors>
          </div>
        </div>
        <div class="col-md-2" formGroupName="0">
          <label class="mb-0">Time</label>
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-append col-md-12" style="padding:0px;">
              <ng-select class="custom-select form-control-sm custom-select-extra-medium-width" type="text"  bindValue='value' name="startTime" 
                [items]="availableTimesDropDownListForAdd" formControlName="scheduledTime" [clearable]="false">
              </ng-select>
            </div>
            <errors [control]="prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules')['controls'][0].get('scheduledTime')"></errors>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-md-8">
        <label class="mb-0">Remarks</label>
        <div class="input-group mb-2" [formGroup]="prescriptionItem">
            <input name="remark" class="form-control" type="text" [formControlName]="isPackageItem ? 'remarks' : 'remark'">
        </div>
      </div>
    </div>

  </form>

  <fieldset style="width:75%" *ngIf="vaccineSubItems && vaccineSubItems.length > 0">
    <label>Sub Vaccination</label>
    <div class="row bg-grey-400 pt-2 pb-2 ml-0 mr-0 mb-2">
      <div class="col-sm-2">Code</div>
      <div class="col-sm-4">Name</div>
      <div class="col-md-6">Dosage <span *ngIf="!isDoctorConsult || !isDoctor" class="compulsory">*</span></div>
    </div>
    <form *ngFor="let item of vaccineSubItems; let i = index;" [formGroup]="item"
      class="row thin-bottom-border pt-2 pb-2 ml-0 mr-0" novalidate>
      <div class="col-md-2">
        <span>{{item.get('code').value}}</span>
      </div>
      <div class="col-md-4">
        <span>{{item.get('description').value}}</span>
      </div>
      <div class="col-md-6">
        <ng-select class="form-control form-control-sm custom-select" [items]="item.get('dosages').value"
          placeholder="Select Dose" formControlName="doseId" [clearable]=true bindLabel="name"
          bindValue="doseId">
          <ng-template ng-header-tmp>
            <div class="row">
                <div class="col-md-4 font-weight-semi-bold">Dose Id</div>
                <div class="col-md-8 font-weight-semi-bold">Name</div>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div class="row">
                  <div class="col-md-4">{{item.doseId}}</div>
                  <div class="col-md-8">{{item.name}}</div>
              </div>
          </ng-template>
        </ng-select>
        <errors [control]="item.get('doseId')"></errors>
      </div>
    </form>
  </fieldset>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-pink" (click)="onBtnSave()">Update</button>
</div>