const LOCAL_STORAGE_KEY = 'logger-flags';

export class Logger {
  private tags: string[] = []
  static enabledTags: string[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '[]')
  constructor(
    ...tags: string[]
  ) {
    this.tags = tags;
  }

  private isLogAllowed() {
    return this.tags.some(tag => Logger.enabledTags?.includes(tag));
  }

  log(...args: any[]) {
    if (this.isLogAllowed()) console.log(...args);
  }

  error(...args: any[]) {
    if (this.isLogAllowed()) console.error(...args);
  }

  static enable(flag: string) {
    let item = localStorage.getItem(LOCAL_STORAGE_KEY);
    Logger.enabledTags = item ? JSON.parse(item) : [];
    Logger.enabledTags.push(flag);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(Logger.enabledTags));
  }

  static disable(flag: string) {
    let item = localStorage.getItem(LOCAL_STORAGE_KEY);
    Logger.enabledTags = item ? JSON.parse(item) : [];
    Logger.enabledTags = Logger.enabledTags.filter(f => f !== flag);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(Logger.enabledTags));
  }
}

window['Logger'] = Logger
