import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InventoryItemClinicDetail } from '../../objects/state/InventoryItemClinicDetail';
import { AkitaInventoryItemClinicDetailStore } from './akita-inventory-item-clinic-detail.store';

@Injectable({ providedIn: 'root' })
export class AkitaInventoryItemClinicDetailQuery extends QueryEntity<
  InventoryItemClinicDetail
> {
  constructor(protected store: AkitaInventoryItemClinicDetailStore) {
    super(store);
  }
}
