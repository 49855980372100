import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  AppointmentMasterStore,
  AppointmentMasterState,
} from './appointment-master.store';

@Injectable({ providedIn: 'root' })
export class AppointmentMasterQuery extends Query<AppointmentMasterState> {
  selectClinicId$ = this.select('clinicSelected');
  selectIsExternal$ = this.select('isExternal');
  selectClinicDoctorList$ = this.select('clinicSelectedDoctorList');
  selectView$ = this.select('view');
  selectViewDate$ = this.select('viewDate');
  selectViewAfter1MthAfter$ = this.select('viewDate1MthAfter');
  selectViewAfter1MthBefore$ = this.select('viewDate1MthBefore');
  viewDateStartOfMonth$ = this.select('viewDateStartOfMonth');
  viewDateEndOfMonth$ = this.select('viewDateEndOfMonth');
  selectSelectedDoctorFilter$ = this.select('selectedDoctorFilter');

  constructor(protected store: AppointmentMasterStore) {
    super(store);
  }

  getSelectedDoctorFilter() {
    return this.getValue().selectedDoctorFilter;
  }

  getClinicSelectedDoctorList() {
    return this.getValue().clinicSelectedDoctorList;
  }

  getClinicSelected() {
    return this.getValue().clinicSelected;
  }

  getIsExternal() {
    return this.getValue().isExternal;
  }

  getViewDate() {
    return this.getValue().viewDate;
  }

  getViewDate1MthBefore() {
    return this.getValue().viewDate1MthBefore;
  }

  getViewDate1MthAfter() {
    return this.getValue().viewDate1MthAfter;
  } 
  
  getViewDateStartOfMonth() {
    return this.getValue().viewDateStartOfMonth;
  }

  getViewDateEndOfMonth() {
    return this.getValue().viewDateEndOfMonth;
  }
}
