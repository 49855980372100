<div class="card mb-0">
  <div class="card-header p-2">
    Send Email Receipt
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <div class="row p-2">
      <div class="col-md-12 form-group">
        <label for="patientEmail" class="mb-2">Email Address <span class='text-danger'>*</span></label>
        <input type="text" name="patientEmail" class="ml-1 text-box form-control form-control-sm" [(ngModel)]="patientEmail" #patientEmailModel="ngModel" email required>
        <div *ngIf="patientEmailModel.touched && patientEmailModel.invalid" class="alert alert-danger">
          <div *ngIf="patientEmailModel.errors">Please enter a valid email</div>
        </div>
      </div>
    </div>

    <div class="row flex-row-reverse">
      <div class="col-md-2 m-1 p-0"><button class="btn btn-md btn-brand-primary text-white w-100" (click)="confirmEmail()" [disabled]="patientEmailModel.errors">Send</button></div>
      <div class="col-md-2 m-1 p-0"><button class="btn btn-md btn-brand-info text-white w-100" (click)="closeModal()">Cancel</button></div>
    </div>
  </div>
</div>
