<app-rich-editor-switcher [control]="consultationNotes" [disabled]="isPaymentMode"></app-rich-editor-switcher>

<div [hidden]="isPaymentMode" class="row mt-5px" id="consultation-select">
    <div class="col">
        <ng-select
          class="form-control  form-control-sm custom-select p-0"
          bindLabel="name"
          bindValue="id"
          groupBy="type"
          [items]="getNoteTemplates() | async"
          [virtualScroll]="true"
          (change)="onTemplateChange($event)"
          [(ngModel)]="template"
          [ngModelOptions]="{standalone: true}"
          placeholder="Select Template"
          appendTo="body">
        </ng-select>
    </div>
    <div class="col">
        <ng-select
          class="form-control  form-control-sm custom-select p-0"
          bindLabel="name"
          bindValue="id"
          groupBy="type"
          [items]="getDrugTemplates() | async"
          [virtualScroll]="true"
          (change)="onDrugTemplateChange($event)"
          [(ngModel)]="itemTemplate"
          placeholder="Select Item Template"
          appendTo="body">
        </ng-select>
    </div>
</div>