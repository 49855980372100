import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaCommunicationTypeStore } from './akita-communication-type.store';
import { CommunicationType } from '../../objects/state/CommunicationType';

@Injectable({ providedIn: 'root' })
export class AkitaCommunicationTypeQuery extends QueryEntity<
  CommunicationType
> {
  communicationTypeList$ = this.selectAll();

  constructor(protected store: AkitaCommunicationTypeStore) {
    super(store);
  }
}
