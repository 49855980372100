import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AkitaDeliveryLocationStore, AkitaDeliveryLocationState } from './akita-delivery-location.store';

@Injectable({ providedIn: 'root' })
export class AkitaDeliveryLocationQuery extends QueryEntity<AkitaDeliveryLocationState> {

  constructor(protected store: AkitaDeliveryLocationStore) {
    super(store);
  }

}
