import { Injectable } from '@angular/core';

import { combineLatest } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import {
  AkitaReferralSpecialityStore,
  ReferralSpecialityState,
} from './akita-referral-speciality.store';
import { ReferralSpeciality } from '../../objects/state/ReferralSpeciality';

@Injectable({ providedIn: 'root' })
export class AkitaReferralSpecialityQuery extends QueryEntity<
  ReferralSpecialityState,
  ReferralSpeciality
> {
  clinicState$ = this.select(entity => entity.ui.clinic);
  doctorState$ = this.select(entity => entity.ui.doctor);
  specialityState$ = this.select(entity => entity.ui.speciality);
  typeState$ = this.select(entity => entity.ui.type);

  referralClinicList$ = combineLatest(
    this.typeState$,
    this.specialityState$,
    this.doctorState$,
    this.selectAll(),
    this.getReferralClinicList
  );
  referralDoctorList$ = combineLatest(
    this.typeState$,
    this.specialityState$,
    this.clinicState$,
    this.selectAll(),
    this.getReferralDoctorList
  );
  referralSpecialistList$ = combineLatest(
    this.typeState$,
    this.specialityState$,
    this.doctorState$,
    this.clinicState$,
    this.selectAll(),
    this.getReferralSpecialistList
  );
  referralSpecialityList$ = combineLatest(
    this.selectAll(),
    this.getReferralSpecialityList
  );
  referralTypeList$ = combineLatest(this.selectAll(), this.getReferralTypeList);

  constructor(protected store: AkitaReferralSpecialityStore) {
    super(store);
  }

  private getReferralSpecialityList(list) {
    const referralSpecialityList = new Array<string>();
    list.forEach(item => {
      if (
        !referralSpecialityList.includes(item.speciality) &&
        item.speciality !== '' &&
        item.speciality !== null &&
        item.speciality !== undefined
      )
        referralSpecialityList.push(item.speciality);
    });

    return referralSpecialityList;
  }

  private getReferralTypeList(list) {
    const referralTypeList = new Array<string>();
    list.forEach(item => {
      if (
        !referralTypeList.includes(item.type) &&
        item.type !== '' &&
        item.type !== null &&
        item.type !== undefined
      )
        referralTypeList.push(item.type);
    });
    referralTypeList.push('EXTERNAL');

    return referralTypeList;
  }

  private getReferralClinicList(type, speciality, doctor, list) {
    const referralClinicList = new Array<string>();
    const emptySpeciality =
      speciality === '' || speciality === null || speciality === undefined;

    list.forEach(item => {
      const matchingType = item.type ? item.type === type : false;
      const matchingSpeciality = item.speciality
        ? item.speciality === speciality
        : false;
      const matchingDoctor =
        doctor !== '' && doctor !== null && doctor !== undefined
          ? item.name
            ? item.name === doctor
            : false
          : true;

      if (
        matchingType &&
        matchingSpeciality &&
        matchingDoctor &&
        !emptySpeciality
      ) {
        item.location.forEach(location => {
          if (!referralClinicList.includes(location.code))
            referralClinicList.push(location.code);
        });
      }
    });

    return referralClinicList;
  }

  private getReferralDoctorList(type, speciality, clinic, list) {
    const referralDoctorList = new Array<string>();
    const emptySpeciality =
      speciality === '' || speciality === null || speciality === undefined;

    list.forEach(item => {
      const matchingType = item.type ? item.type === type : false;
      const matchingSpeciality = item.speciality
        ? item.speciality === speciality
        : false;

      if (matchingType && matchingSpeciality && !emptySpeciality) {
        let matchingClinic = true;
        if (clinic !== '' && clinic !== null && clinic !== undefined) {
          matchingClinic = item.location.map(loc => loc.code).includes(clinic);
        }

        if (matchingClinic && !referralDoctorList.includes(item.name))
          referralDoctorList.push(item.name);
      }
    });

    return referralDoctorList;
  }

  private getReferralSpecialistList(type, speciality, doctor, clinic, list) {
    const referralSpecialistList = new Array<any>();

    if (speciality !== '') {
      list.forEach(item => {
        const matchingType = item.type === type;
        const matchingSpeciality = item.speciality
          ? item.speciality === speciality
          : false;
        const matchingDoctor = doctor ? item.name === doctor : true;

        if (clinic) {
          item.location.forEach(loc => {
            if (
              matchingType &&
              matchingSpeciality &&
              matchingDoctor &&
              clinic === loc.code
            ) {
              referralSpecialistList.push({
                id: item.id,
                name: item.name,
                speciality: item.speciality,
                locationId: loc.id,
                code: loc.code,
                address: loc.address,
              });
            }
          });
        } else {
          if (matchingType && matchingSpeciality && matchingDoctor) {
            item.location.forEach(loc => {
              referralSpecialistList.push({
                id: item.id,
                name: item.name,
                speciality: item.speciality,
                locationId: loc.id,
                code: loc.code,
                address: loc.address,
              });
            });
          }
        }
      });
    }

    return referralSpecialistList;
  }
}
