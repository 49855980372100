export const OCCUPATIONS = [
  // 'Businessman',
  // 'Teacher',
  // 'Accountant',
  // 'Executive',
  // 'Others',
  'LEGISLATORS, SENIOR OFFICIALS AND MANAGERS',
  'PROFESSIONALS',
  'ASSOCIATE PROFESSIONALS AND TECHNICIANS',
  'CLERICAL SUPPORT WORKERS',
  'SERVICE AND SALES WORKERS',
  'AGRICULTURAL AND FISHERY WORKERS',
  'CRAFTSMEN AND RELATED TRADES WORKERS',
  'PLANT AND MACHINE OPERATORS AND ASSEMBLERS',
  'CLEANERS, LABOURERS AND RELATED WORKERS',
  'LEGISLATORS, SENIOR OFFICIALS AND CHIEF EXECUTIVES',
  'ADMINISTRATIVE AND COMMERCIAL MANAGERS',
  'PRODUCTION AND SPECIALISED SERVICES MANAGERS',
  'HOSPITALITY, RETAIL AND RELATED SERVICES MANAGERS',
  'SCIENCE AND ENGINEERING PROFESSIONALS',
  'HEALTH PROFESSIONALS',
  'TEACHING AND TRAINING PROFESSIONALS',
  'BUSINESS AND ADMINISTRATION PROFESSIONALS',
  'INFORMATION AND COMMUNICATIONS TECHNOLOGY PROFESSIONALS',
  'LEGAL, SOCIAL AND CULTURAL PROFESSIONALS',
  'OTHER PROFESSIONALS NOT ELSEWHERE CLASSIFIED',
  'PHYSICAL AND ENGINEERING SCIENCE ASSOCIATE PROFESSIONALS',
  'HEALTH ASSOCIATE PROFESSIONALS',
  'BUSINESS AND ADMINISTRATION ASSOCIATE PROFESSIONALS',
  'LEGAL, SOCIAL, CULTURAL AND RELATED ASSOCIATE PROFESSIONALS',
  'INFORMATION AND COMMUNICATIONS TECHNICIANS',
  'TEACHING ASSOCIATE PROFESSIONALS',
  'OTHER ASSOCIATE PROFESSIONALS NOT ELSEWHERE CLASSIFIED',
  'CLERICAL SUPERVISORS',
  'GENERAL AND KEYBOARD CLERKS',
  'CUSTOMER SERVICES OFFICERS AND CLERKS',
  'NUMERICAL AND MATERIAL-RECORDING CLERKS',
  'OTHER CLERICAL SUPPORT WORKERS',
  'PERSONAL SERVICE WORKERS',
  'SALES WORKERS',
  'PERSONAL CARE WORKERS',
  'PROTECTIVE SERVICES WORKERS',
  'SERVICE WORKERS NOT ELSEWHERE CLASSIFIED',
  'AGRICULTURAL WORKERS',
  'FISHERY WORKERS',
  'BUILDING AND RELATED TRADES WORKERS, EXCLUDING ELECTRICIANS',
  'METAL, MACHINERY AND RELATED TRADES WORKERS',
  'PRECISION, HANDICRAFT, PRINTING AND RELATED TRADES WORKERS',
  'ELECTRICAL AND ELECTRONIC TRADES WORKERS',
  'FOOD PROCESSING, WOODWORKING, GARMENT, LEATHER AND OTHER CRAFT AND RELATED TRADES WORKERS',
  'STATIONARY PLANT AND MACHINE OPERATORS',
  'ASSEMBLERS AND QUALITY CHECKERS',
  'DRIVERS AND MOBILE MACHINERY OPERATORS',
  'CLEANERS AND RELATED WORKERS',
  'AGRICULTURAL, FISHERY AND RELATED LABOURERS',
  'LABOURERS AND RELATED WORKERS',
  'FOOD PREPARATION AND KITCHEN ASSISTANTS',
  'WASTE AND RECYCLABLES COLLECTION WORKERS AND OTHER ELEMENTARY WORKERS',
  'LEGISLATORS AND SENIOR OFFICIALS',
  'MANAGING DIRECTORS, CHIEF EXECUTIVES AND GENERAL MANAGERS',
  'BUSINESS SERVICES AND ADMINISTRATION MANAGERS',
  'SALES, MARKETING, DEVELOPMENT AND CUSTOMER SERVICE MANAGERS',
  'PRODUCTION MANAGERS IN AGRICULTURE AND FISHERIES',
  'MANUFACTURING, CONSTRUCTION, TRANSPORT, DISTRIBUTION AND OTHER PRODUCTION AND OPERATIONS MANAGERS',
  'INFORMATION AND COMMUNICATIONS TECHNOLOGY SERVICE MANAGERS',
  'PROFESSIONAL, FINANCIAL, COMMUNITY AND SOCIAL SERVICES MANAGERS',
  'HOTEL AND FOOD AND BEVERAGES SERVICES MANAGERS',
  'RETAIL AND WHOLESALE TRADE MANAGERS',
  'OTHER SERVICES MANAGERS',
  'PHYSICAL AND EARTH SCIENCE PROFESSIONALS',
  'MATHEMATICIANS, ACTUARIES, STATISTICIANS AND OTHER RELATED PROFESSIONALS',
  'LIFE SCIENCE PROFESSIONALS',
  'ENGINEERING PROFESSIONALS (EXCLUDING ELECTROTECHNOLOGY)',
  'ELECTROTECHNOLOGY ENGINEERS',
  'ARCHITECTS, PLANNERS, SURVEYORS AND DESIGNERS',
  'SHIP AND AIRCRAFT RELATED PROFESSIONALS',
  'MEDICAL DOCTORS',
  'NURSING PROFESSIONALS',
  'TRADITIONAL AND COMPLEMENTARY MEDICINE PROFESSIONALS',
  'VETERINARIANS',
  'OTHER HEALTH PROFESSIONALS ',
  'UNIVERSITY, POLYTECHNIC AND HIGHER EDUCATION TEACHERS',
  'JUNIOR COLLEGE AND PRE-UNIVERSITY EDUCATION TEACHERS',
  'VOCATIONAL EDUCATION TEACHERS AND TRAINERS',
  'SECONDARY EDUCATION TEACHERS',
  'PRIMARY EDUCATION TEACHERS',
  'OTHER TEACHING PROFESSIONALS',
  'FINANCE PROFESSIONALS',
  'ADMINISTRATION PROFESSIONALS',
  'MARKETING AND PUBLIC RELATIONS PROFESSIONALS',
  'SOFTWARE AND APPLICATIONS DEVELOPERS AND ANALYSTS',
  'DATABASE, NETWORK AND INFRASTRUCTURE PROFESSIONALS',
  'LEGAL PROFESSIONALS',
  'LIBRARIANS, ARCHIVISTS AND CURATORS',
  'SOCIAL SCIENCE PROFESSIONALS',
  'AUTHORS, JOURNALISTS AND EDITORS',
  'CREATIVE AND PERFORMING ARTISTS',
  'OTHER PROFESSIONALS NOT ELSEWHERE CLASSIFIED',
  'ASSISTANT ENGINEERS',
  'PHYSICAL AND ENGINEERING SCIENCE TECHNICIANS',
  'LIFE SCIENCE TECHNICIANS AND RELATED ASSOCIATE PROFESSIONALS',
  'SHIP AND AIRCRAFT CONTROLLERS AND TECHNICIANS',
  'PREMISES AND PARKS OFFICERS',
  'BUILDING, QUALITY AND SAFETY INSPECTORS',
  'MEDICAL AND PHARMACEUTICAL TECHNICIANS',
  'NURSING ASSOCIATE PROFESSIONALS',
  'TRADITIONAL AND COMPLEMENTARY MEDICINE ASSOCIATE PROFESSIONALS',
  'VETERINARY TECHNICIANS AND ASSISTANTS',
  'OTHER HEALTH ASSOCIATE PROFESSIONALS',
  'FINANCIAL AND MATHEMATICAL ASSOCIATE PROFESSIONALS',
  'SALES AND RELATED ASSOCIATE PROFESSIONALS',
  'BUSINESS SERVICES AGENTS',
  'ADMINISTRATIVE ASSOCIATE PROFESSIONALS',
  'GOVERNMENT ASSOCIATE PROFESSIONALS',
  'LEGAL AND SOCIAL ASSOCIATE PROFESSIONALS',
  'SPORTS AND FITNESS WORKERS',
  'ARTISTIC AND CULTURAL ASSOCIATE PROFESSIONALS',
  'CREATIVE AND PERFORMING ARTS ASSOCIATE PROFESSIONALS',
  'INFORMATION AND COMMUNICATIONS TECHNOLOGY OPERATORS AND TECHNICIANS',
  'TELECOMMUNICATIONS AND BROADCASTING TECHNICIANS AND AUDIO-VISUAL OPERATORS',
  'PRE-PRIMARY EDUCATION TEACHERS',
  'EXTRACURRICULUM INSTRUCTORS',
  'TEACHING ASSOCIATE PROFESSIONALS NOT ELSEWHERE CLASSIFIED',
  'OTHER ASSOCIATE PROFESSIONALS NOT ELSEWHERE CLASSIFIED',
  'CLERICAL SUPERVISORS',
  'GENERAL OFFICE CLERKS ',
  'SECRETARIES AND VERBATIM REPORTERS',
  'KEYBOARD OPERATORS',
  'TELLERS, MONEY COLLECTORS AND RELATED CLERKS',
  'CLIENT INFORMATION CLERKS',
  'NUMERICAL CLERKS',
  'MATERIAL-RECORDING AND TRANSPORT CLERKS',
  'OTHER CLERICAL SUPPORT WORKERS',
  'TRAVEL ATTENDANTS, CONDUCTORS AND GUIDES',
  'COOKS',
  'WAITERS AND BARTENDERS',
  'HAIRDRESSERS, BEAUTICIANS AND RELATED WORKERS',
  'HOUSEKEEPERS AND RELATED WORKERS',
  'MAIL CARRIERS AND SORTING WORKERS',
  'BOOKMAKERS, CROUPIERS AND RELATED GAMING WORKERS',
  'PERSONAL SERVICE WORKERS NOT ELSEWHERE CLASSIFIED',
  'STALL SALES WORKERS',
  'SHOP SALESPERSONS',
  'CASHIERS AND TICKET CLERKS',
  'MODELS, SALES DEMONSTRATORS AND OTHER SALES WORKERS',
  "CHILD CARE WORKERS AND TEACHERS' AIDES",
  'HEALTHCARE ASSISTANTS AND OTHER PERSONAL CARE WORKERS',
  'PROTECTIVE SERVICES WORKERS',
  'SERVICE WORKERS NOT ELSEWHERE CLASSIFIED',
  'MARKET GARDENERS AND CROP GROWERS',
  'ANIMAL PRODUCERS',
  'AGRICULTURAL WORKERS NOT ELSEWHERE CLASSIFIED',
  'FISHERY WORKERS',
  'SUPERVISORS AND GENERAL FOREMEN (BUILDING AND RELATED TRADES)',
  'BUILDING FRAME AND RELATED TRADES WORKERS',
  'BUILDING FINISHERS AND RELATED TRADES WORKERS',
  'PAINTERS, BUILDING STRUCTURE CLEANERS AND RELATED WORKERS',
  'SUPERVISORS AND GENERAL FOREMEN (METAL, MACHINERY AND RELATED TRADES)',
  'SHEET AND STRUCTURAL METAL WORKERS, MOULDERS AND WELDERS AND RELATED TRADES WORKERS',
  'BLACKSMITHS, TOOLMAKERS AND RELATED TRADES WORKERS',
  'MACHINERY MECHANICS, FITTERS AND REPAIRERS',
  'SUPERVISORS AND GENERAL FOREMEN (PRECISION, HANDICRAFT, PRINTING AND RELATED TRADES)',
  'HANDICRAFT AND PRECISION WORKERS',
  'PRINTING TRADES WORKERS',
  'SUPERVISORS AND GENERAL FOREMEN (ELECTRICAL AND ELECTRONIC TRADES)',
  'ELECTRICAL EQUIPMENT RELATED TRADES WORKERS',
  'ELECTRONICS AND TELECOMMUNICATIONS RELATED TRADES WORKERS',
  'SUPERVISORS AND GENERAL FOREMEN (FOOD PROCESSING, WOODWORKING, GARMENT, LEATHER AND RELATED TRADES)',
  'FOOD PROCESSING AND RELATED TRADES WORKERS',
  'WOOD TREATERS, CABINET MAKERS AND RELATED TRADES WORKERS',
  'GARMENT, LEATHER AND RELATED TRADES WORKERS',
  'OTHER CRAFT AND RELATED WORKERS ',
  'STATIONARY PLANT AND MACHINE SUPERVISORS AND GENERAL FOREMEN',
  'MINING AND MINERAL PROCESSING PLANT OPERATORS',
  'METAL PROCESSING, FINISHING AND PRODUCTS  PLANT OPERATORS',
  'CHEMICAL PROCESSING, CHEMICAL AND PHOTOGRAPHIC PRODUCTS PLANT AND MACHINE OPERATORS',
  'RUBBER, PLASTIC AND PAPER PRODUCTS MACHINE OPERATORS',
  'TEXTILE, FUR AND LEATHER PRODUCTS MACHINE OPERATORS',
  'FOOD AND RELATED PRODUCTS MACHINE OPERATORS',
  'WOOD PROCESSING AND PAPERMAKING PLANT OPERATORS',
  'OTHER STATIONARY PLANT AND MACHINE OPERATORS',
  'SUPERVISORS AND GENERAL FOREMEN OF ASSEMBLERS AND QUALITY CHECKERS',
  'ASSEMBLERS, QUALITY CHECKERS AND TESTERS',
  'MOBILE MACHINERY SUPERVISORS AND GENERAL FOREMEN',
  'MRT TRAIN OPERATORS AND RELATED WORKERS',
  'CAR AND LIGHT GOODS VEHICLE DRIVERS',
  'HEAVY TRUCK AND BUS DRIVERS',
  'MOBILE MACHINERY OPERATORS',
  'SHIPS’ DECK CREW AND RELATED WORKERS',
  'CLEANING SUPERVISORS',
  'CLEANERS AND HELPERS IN HOTELS, OFFICES AND OTHER ESTABLISHMENTS',
  'VEHICLE, WINDOW, LAUNDRY AND OTHER HAND CLEANING WORKERS',
  'DOMESTIC HELPERS AND CLEANERS',
  'AGRICULTURAL, FISHERY AND RELATED LABOURERS',
  'CONSTRUCTION LABOURERS AND RELATED WORKERS',
  'MANUFACTURING LABOURERS AND RELATED WORKERS',
  'TRANSPORT AND STORAGE LABOURERS',
  'FOOD PREPARATION AND KITCHEN ASSISTANTS',
  'WASTE AND RECYCLABLES COLLECTION SUPERVISORS',
  'WASTE AND RECYCLABLES COLLECTION WORKERS',
  'PORTERS, ATTENDANTS AND RELATED WORKERS',
  'Legislators',
  'Senior Government and Statutory Board Officials',
  'Senior Officials of Political Party Organisations',
  'Senior Officials of Employers’, Workers’ and Other Economic-Interest Organisations',
  'Senior Officials of Humanitarian and Other Special-Interest Organisations',
  'Managing Directors, Chief Executives and General Managers',
  'Finance and Administration Managers',
  'Human Resource Managers',
  'Policy and Planning Managers',
  'Business Services and Administration Managers Not Elsewhere Classified',
  'Sales, Marketing and Business Development Managers',
  'Advertising and Public Relations Managers',
  'Research and Development Managers',
  'Customer Service and Call Centre Managers',
  'Production Managers in Agriculture and Fisheries',
  'Manufacturing Managers',
  'Construction Managers',
  'Transport, Supply, Distribution and Related Managers',
  'Production and Operations Managers Not Elsewhere Classified',
  'Information and Communications Technology Service Managers',
  'Child Care Services Managers',
  'Health Services Managers',
  'Aged Care Services Managers',
  'Social Welfare Managers',
  'Education and Training Institution Managers',
  'Financial and Insurance Services Managers',
  'Professional, Financial, Community and Social Services Managers Not Elsewhere Classified',
  'Hotel Operations and Lodging Services Managers',
  'Food and Beverages Services Managers',
  'Retail and Wholesale Trade Managers',
  'Sports Centre Managers',
  'Recreation Centre Managers',
  'Arts and Cultural Centre Managers',
  'Other Services Managers Not Elsewhere Classified',
  'Physicists and Astronomers',
  'Meteorologists',
  'Chemists',
  'Geologists, Geophysicists and Other Physical Science Professionals',
  'Mathematicians, Operations Research Analysts and Actuaries',
  'Statisticians',
  'Other Statistical, Mathematical and Actuarial Professionals',
  'Biologists, Botanists, Zoologists and Related Professionals',
  'Farming, Forestry and Fishery Advisers',
  'Environmental Protection and Related Professionals',
  'Pharmacologists and Related Professionals',
  'Industrial and Production Engineers',
  'Civil Engineers',
  'Environmental Engineers',
  'Mechanical Engineers',
  'Chemical Engineers',
  'Mining Engineers, Metallurgists and Related Professionals',
  'Engineering Professionals Not Elsewhere Classified',
  'Electrical Engineers',
  'Electronics Engineers',
  'Telecommunications Engineers',
  'Building Architects',
  'Landscape Architects',
  'Fashion, Garment and Product Designers ',
  'Town and Related Planners',
  'Surveyors and Cartographers',
  'Graphic and Multimedia Designers and Artists',
  'Ship Engineers and Related Professionals',
  'Aircraft Pilots and Related Professionals',
  'Generalist Medical Practitioners',
  'Specialist Medical Practitioners (Medical)',
  'Specialist Medical Practitioners (Surgical)',
  'Specialist Medical Practitioners (Other Specialisations) - I',
  "Specialist Medical Practitioners (Other Specialisations) - II (Con'td)",
  'Nursing Professionals',
  'Traditional and Complementary Medicine Professionals',
  'Veterinarians',
  'Dentists',
  'Pharmacists',
  'Environmental and Occupational Health and Hygiene Professionals',
  'Physiotherapists and Related Professionals',
  'Dietitians and Nutritionists',
  'Audiologists and Speech Therapists',
  'Optometrists',
  'Occupational Therapists',
  'Other Health Professionals Not Elsewhere Classified',
  'University, Polytechnic and Higher Education Teachers',
  'Junior College and Pre-university Education Teachers',
  'Vocational Education Teachers and Trainers',
  'Secondary Education Teachers',
  'Primary School Teachers',
  'Education Methods Specialists',
  'Special Education Teachers',
  'Other Teaching Professionals Not Elsewhere Classified',
  'Accountants',
  'Financial and Investment Advisers',
  'Financial Analysts and Related Professionals',
  'Securities and Finance Dealers and Brokers',
  'Insurance Underwriters',
  'Management and Business Consultants',
  'Policy Administration Professionals',
  'Human Resource Professionals',
  'Other Administration Professionals Not Elsewhere Classified',
  'Advertising and Marketing Professionals',
  'Public Relations Professionals',
  'Specialised Goods Sales Professionals',
  'Trade and Ship Brokers',
  'Systems Analysts',
  'Software, Web and Multimedia Developers',
  'Applications and Systems Programmers',
  'Information Technology Testing and Quality Assurance Professionals',
  'Information Technology Project Managers',
  'Software and Applications Developers and Analysts Not Elsewhere Classified',
  'Database Designers and Administrators',
  'Network, Servers and Computer Systems Administrators',
  'Computer Network and Infrastructure Professionals',
  'Information Technology Security Specialists',
  'Database and Network Professionals Not Elsewhere Classified',
  'Lawyers',
  'Judges',
  'Archivists, Curators and Conservators',
  'Librarians and Related Information Professionals',
  'Economists',
  'Sociologists, Anthropologists and Related Professionals',
  'Historians, Political Scientists and Other Social Science Professionals',
  'Psychologists',
  'Social Work and Counselling Professionals',
  'Authors and Related Writers',
  'Journalists',
  'Translators, Interpreters and Other Linguists',
  'Visual Artists',
  'Musicians, Composers and Singers',
  'Dancers and Choreographers',
  'Film, Stage and Related Directors and Producers',
  'Actors',
  'Radio and Television Presenters and Related Professionals',
  'Religious Professionals',
  'Other Professionals Not Elsewhere Classified',
  'Assistant Engineers',
  'Chemical and Physical Science Technicians',
  'Civil Engineering Technicians',
  'Electrical Engineering Technicians',
  'Electronics Engineering Technicians',
  'Mechanical Engineering Technicians',
  'Chemical Engineering Technicians',
  'Manufacturing Engineering Technicians',
  'Draughtsmen',
  'Physical and Engineering Science Technicians Not Elsewhere Classified',
  'Life Science Technicians (Except Medical)',
  'Agronomy, Horticultural and Farming Technicians',
  'Ship Engineering Officers',
  "Ships' Deck Officers and Pilots",
  'Air Traffic Controllers',
  'Sea Traffic Controllers',
  'Transport Operations Supervisors',
  'Transport Controllers and Related Workers Not Elsewhere Classified',
  'Premises and Parks Officers',
  'Building and Fire Inspectors',
  'Quality and Safety Inspectors',
  'Medical Imaging and Therapeutic Equipment Technicians',
  'Medical and Pathology Laboratory Technicians',
  'Pharmacy Technicians',
  'Medical and Dental Prosthetic Technicians',
  'Nursing Associate Professionals',
  'Traditional and Complementary Medicine Associate Professionals',
  'Veterinary Technicians and Assistants',
  'Dental Assistants and Therapists',
  'Community Health Workers',
  'Opticians',
  'Physiotherapy Technicians and Related Associate Professionals',
  'Environmental and Occupational Health Inspectors',
  'Health Associate Professionals Not Elsewhere Classified',
  'Credit and Loans Officers',
  'Accounting Associate Professionals',
  'Valuers and Loss Assessors',
  'Insurance Representatives and Related Associate Professionals',
  'Commercial and Marketing Sales Executives',
  'Buyers and Purchasing Agents',
  'Sales and Related Associate Professionals Not Elsewhere Classified',
  'Shipping, Clearing and Forwarding Agents',
  'Conference and Event Planners',
  'Employment Agents and Labour Contractors',
  'Real Estate Agents ',
  'Business Services Agents Not Elsewhere Classified',
  'Human Resource Associate Professionals',
  'Administrative and Related Associate Professionals Not Elsewhere Classified',
  'Customs and Immigration Officers',
  'Police Inspectors',
  'Government Associate Professionals Not Elsewhere Classified ',
  'Legal Associate Professionals',
  'Social Work Associate Professionals',
  'Athletes and Sportsmen',
  'Sports Coaches, Instructors, Officials and Other Related Associate Professionals',
  'Photographers',
  'Interior Designers and Decorators',
  'Gallery, Museum and Library Technicians',
  'Chefs',
  'Artistic and Cultural Associate Professionals Not Elsewhere Classified',
  'Creative and Performing Arts Associate Professionals',
  'Computer Systems Operators',
  'Computer Technicians',
  'Website Administrators',
  'Broadcasting Technicians and Audio-Visual Operators',
  'Telecommunications Engineering Technicians ',
  'Telecommunications Equipment Operators',
  'Optical and Electronic Equipment Operators Not Elsewhere Classified',
  'Pre-Primary Education Teachers',
  'Extracurriculum Instructors',
  'Private Tutors',
  'Teaching Associate Professionals Not Elsewhere Classified',
  'Religious Associate Professionals',
  'Other Associate Professionals Not Elsewhere Classified',
  'Clerical Supervisors',
  'General Office Clerks',
  'Secretaries and Verbatim Reporters',
  'Typists and Word Processing Operators',
  'Data Entry Clerks',
  'Bank Tellers and Other Counter Clerks',
  'Pawnbrokers and Moneylenders',
  'Bill Collectors and Related Workers',
  'Travel Agency and Related Clerks',
  'Telephone Operators',
  'Receptionists, Customer Service and Information Clerks',
  'Client Information Clerks Not Elsewhere Classified',
  'Accounting and Bookkeeping Clerks',
  'Finance and Insurance Clerks',
  'Statistical Clerks',
  'Computer Operations Clerks',
  'Stock Clerks',
  'Production Clerks',
  'Transport Clerks',
  'Library Clerks',
  'Legal Clerks',
  'Clerical Support Workers Not Elsewhere Classified',
  'Travel Attendants and Stewards',
  'Transport Service Inspectors and Related Workers',
  'Tour and Other Guides',
  'Cooks',
  'Waiters ',
  'Bartenders and Baristas',
  'Bar/Lounge Hostesses',
  'Waiters and Bartenders Not Elsewhere Classified',
  'Hairdressers, Barbers and Other Related Workers',
  'Beauticians and Related Workers',
  'Wellness Related Workers Not Elsewhere Classified',
  'Housekeepers and Related Workers',
  'Mail Carriers and Sorting Workers',
  'Bookmakers, Croupiers and Related Gaming Workers',
  'Astrologers, Fortune-tellers and Related Workers',
  'Undertakers and Embalmers',
  'Pet Groomers, Animal Keepers and Trainers',
  'Driving Instructors',
  'Personal Service Workers Not Elsewhere Classified',
  'Hawker/Stall Holders (Excluding Prepared Food Or Drinks)',
  'Hawker/Stall Holders (Prepared Food Or Drinks)',
  'Newsvendors',
  'Other Stall Sales Workers',
  'Shop and Store Salespersons',
  'Cashiers and Ticket Clerks',
  'Fashion and Other Models',
  'Sales Demonstrators and Door-to-Door Salespersons',
  'Contact Centre Salespersons',
  'Sales Workers Not Elsewhere Classified',
  'Child Care and Related Workers',
  "Teachers' Aides",
  'Healthcare Assistants and Other Personal Care Workers',
  'Fire-Fighting and Rescue Officers',
  'Police Officers',
  'Prison Officers',
  'Security Guards',
  'Lifeguards',
  'Protective Services and Related Workers Not Elsewhere Classified',
  'Service Workers Not Elsewhere Classified',
  'Vegetable Farm Workers',
  'Gardeners, Horticultural and Nursery Farm Workers',
  'Livestock and Dairy Farm Workers',
  'Poultry Farm Workers',
  'Agricultural Workers Not Elsewhere Classified',
  'Aquaculture Workers',
  'Coastal Waters and Deep Sea Fishery Workers',
  'Supervisors and General Foremen (Building and Related Trades)',
  'Bricklayers and Related Workers',
  'Stone Cutters, Splitters, Carvers and Stonemasons',
  'Concrete Placers, Concrete Finishers and Related Workers',
  'Carpenters and Joiners',
  'Building Frame and Related Trades Workers Not Elsewhere Classified',
  'Roofers',
  'Floor Layers and Tile Setters',
  'Plasterers',
  'Insulation Workers',
  'Glaziers',
  'Plumbers, Pipe Fitters and Related Workers',
  'Air-Conditioning and Refrigeration Mechanics',
  'Building Finishers and Related Trades Workers Not Elsewhere Classified',
  'Building Painters and Related Workers',
  'Spray Painters, Varnishers and Other Painters',
  'Building Maintenance Workers, Building Structure Cleaners and Related Workers',
  'Supervisors and General Foremen (Metal, Machinery and Related Trades)',
  'Metal Moulders and Coremakers',
  'Welders and Flame Cutters',
  'Sheet Metal Workers',
  'Structural Metal Preparers and Erectors',
  'Riggers and Cable Splicers',
  'Blacksmiths, Hammersmiths and Forging-Press Workers',
  'Toolmakers and Related Workers',
  'Metal Polishers, Wheel Grinders and Tool Sharpeners',
  'Motor Vehicle Mechanics and Repairers',
  'Aircraft Engine Mechanics and Repairers',
  'Other Machinery Mechanics and Repairers',
  'Supervisors and General Foremen (Precision, Handicraft, Printing and Related Trades)',
  'Precision Instrument Makers and Repairers',
  'Musical Instrument Makers, Repairers and Tuners',
  'Jewellery and Precious Metal Workers',
  'Potters and Related Workers',
  'Glass Makers, Cutters, Grinders and Finishers',
  'Sign Writers, Decorative Painters, Engravers and Etchers',
  'Pre-Press Trades Workers',
  'Printers',
  'Supervisors and General Foremen (Electrical and Electronic Trades)',
  'Electricians',
  'Electrical Mechanics and Fitters',
  'Electrical Line Installers and Repairers',
  'Electronics Mechanics and Servicers',
  'Information and Communications Technology Installers and Servicers',
  'Supervisors and General Foremen (Food Processing, Woodworking, Garment, Leather and Related Trades)',
  'Meat and Fish Preparers',
  'Bakers, Pastry and Confectionery Makers',
  'Food and Beverage Tasters and Graders',
  'Food Processing and Related Trades Workers Not Elsewhere Classified',
  'Wood Treaters',
  'Cabinet Makers and Wood Related Trades Workers',
  'Furniture Makers and Related Trades Workers Not Elsewhere Classified',
  'Tailors/Dressmakers',
  'Garment and Related Pattern-Makers and Cutters',
  'Upholsterers and Related Workers',
  'Shoemakers and Related Workers',
  'Garment and Related Trades Workers Not Elsewhere Classified ',
  'Other Craft and Related Workers',
  'Stationary Plant and Machine Supervisors and General Foremen',
  'Well Drillers and Borers and Other Mining Related Workers',
  'Cement and Other Mineral Products Machine Operators',
  'Metal Melters, Casters and Rolling Mill Operators',
  'Metal Heat Treating Plant Operators',
  'Metal Drawers and Extruders',
  'Metal Finishing, Plating and Coating Machine Operators',
  'Machine-Tool Setter-Operators',
  'Chemical Processing and Chemical Products Plant and Machine Operators',
  'Photographic Products Machine Operators',
  'Pharmaceutical and Toiletry Products Machine Operators',
  'Chemical Processing, Chemical and Photographic Products Plant and Machine Operators Not Elsewhere Classified',
  'Rubber Products Machine Operators',
  'Plastic Products Machine Operators',
  'Paper and Paperboard Products Machine Operators',
  'Fibre Preparing, Spinning and Winding Machine Operators',
  'Weaving and Knitting Machine Operators',
  'Sewing Machine Operators',
  'Bleaching, Dyeing and Fabric Cleaning Machine Operators',
  'Textile, Fur and Leather Products Machine Operators Not Elsewhere Classified',
  'Meat and Fish Products Machine Operators',
  'Dairy and Confectionery Products Machine Operators',
  'Grain and Spice Milling Machine Operators',
  'Baked and Cereal Products Machine Operators',
  'Fruit, Vegetable and Nut Products Machine Operators',
  'Sugar Processing and Refining Machine Operators',
  'Brewers and Wine and Other Beverage Machine Operators',
  'Tobacco Products Machine Operators',
  'Food and Related Products Machine Operators Not Elsewhere Classified',
  'Pulp and Papermaking Plant Operators',
  'Wood Processing Plant Operators',
  'Wood Products Machine Operators',
  'Glass and Ceramics Plant Operators',
  'Steam Engine and Boiler Operators',
  'Packing, Bottling and Labelling Machine Operators',
  'Printing, Binding and Related Machine Operators',
  'Incineration Plant, Water Treatment and Related Machine Operators',
  'Power Generating Plant Operators',
  'Petroleum and Natural Gas Refining Plant Operators',
  'Automated Assembly Line and Industrial Robot Operators',
  'Stationary Plant and Machine Operators Not Elsewhere Classified',
  'Supervisors and General Foremen of Assemblers and Quality Checkers ',
  'Electrical and Electronic Equipment Assemblers',
  'Quality Checkers and Testers',
  'Assemblers Not Elsewhere Classified',
  'Mobile Machinery Supervisors and General Foremen',
  'MRT Train Operators',
  'Railway Brakers and Related Workers',
  'Motorcycle Delivery Men',
  'Car, Taxi, Van and Light Goods Vehicle Drivers',
  'Bus and Tram Drivers',
  'Heavy Truck and Lorry Drivers',
  'Earth Moving and Related Machinery Operators',
  'Crane, Hoist and Related Equipment Operators',
  'Lifting Truck Operators',
  'Mobile Machinery Operators Not Elsewhere Classified',
  'Ships’ Deck Crew and Related Workers',
  'Cleaning Supervisors',
  'Cleaners and Helpers in Hotels and Related Establishments',
  'Cleaners in Offices ',
  'Cleaners in Industrial Establishments',
  'Cleaners in Food and Beverage Establishments',
  'Cleaners in Residential Areas',
  'Cleaners in Open Areas',
  'Cleaners in Other Establishments',
  'Hand Launderers and Pressers',
  'Vehicle Cleaners',
  'Window Cleaners',
  'Cleaning Workers Not Elsewhere Classified',
  'Domestic Helpers and Cleaners',
  'Park and Garden Maintenance Workers',
  'Agricultural, Fishery Labourers and Related Workers Not Elsewhere Classified',
  'Civil Engineering and Building Construction Labourers',
  'Manufacturing Labourers and Related Workers',
  'Hand and Pedal Vehicle Drivers',
  'Material and Freight Handling Workers',
  'Food Preparation and Kitchen Assistants',
  'Waste and Recyclables Collection Supervisors',
  'Waste and Recyclables Collection Workers',
  'Porters and Related Workers',
  'Attendants',
  'Watchmen and Related Workers',
  'Other Elementary Workers',
  'Legislator',
  'Senior government official',
  'Senior statutory board official',
  'Senior official of political party organisation',
  "Senior official of employers’, workers' and other economic-interest organisation",
  'Senior official of humanitarian and other special-interest organisation',
  'Managing director/Chief executive officer',
  'Company director',
  'Chief operating officer/General Manager',
  'Budgeting and financial accounting manager (including financial controller)',
  'Administration manager',
  'Personnel/Human resource manager',
  'Industrial relations manager',
  'Training manager',
  'Policy and planning manager',
  'Premises and facilities maintenance manager (including building security manager)',
  'Landscape operations manager',
  'Working proprietor (business services and administrative services)',
  'Other business services and administration managers n.e.c.',
  'Sales and marketing manager ',
  'Business development manager',
  'Advertising/Public relations manager ',
  'Research and development manager',
  'Customer service manager',
  'Call centre manager',
  'Production manager in agriculture and fisheries',
  'Working proprietor (agriculture and fisheries)',
  'Manufacturing plant/production manager',
  'Working proprietor (manufacturing)',
  'Building and construction project manager',
  'Working proprietor (construction)',
  'Supply and distribution/Logistics/Warehousing manager',
  'Procurement/Purchasing manager',
  'Transport operations manager',
  'Postal service manager',
  'Working proprietor (transport, storage and courier)',
  'Technical/Engineering services manager (eg shipyard manager)',
  'Quality assurance manager',
  'Other production and operations managers n.e.c. (eg mining manager)',
  'Chief information officer/Chief technology officer/Chief security officer',
  'Software and applications manager',
  'Network and communications manager',
  'IT service manager',
  'Working proprietor (information and communications technology service)',
  'Child care services manager',
  'Health services manager',
  'Aged care services manager',
  'Social welfare manager',
  'School principal',
  'Other education managers (including registrars, deans of education institutions and heads of training institutions)',
  'Financial/Insurance services manager (eg financial institution branch manager)',
  'Working proprietor (professional, financial, community and social services)',
  'Other professional, financial, community and social services managers ',
  'Hotel operations/Lodging services manager',
  'Working proprietor (lodging services)',
  'Restaurant manager',
  'Catering services manager',
  'Working proprietor (restaurant and other catering services)',
  'Retail/Shop sales manager',
  'Wholesale trade manager',
  'Working proprietor (retail trade)',
  'Working proprietor (wholesale trade)',
  'Sports centre manager',
  'Casino operations manager',
  'Amusement/Theme park manager',
  'Cinema manager',
  'Park/Garden/Nature reserve manager',
  'Discotheque/Karaoke/Nightclub manager',
  'Wellness centre manager (eg hair/beauty/slimming/spa/manicure/massage)',
  'Community/Country club manager',
  'Other recreation centre managers ',
  'Arts and cultural centre manager (including gallery and museum manager)',
  'Working proprietor (sports, recreation, arts and other services)',
  'Travel agency manager ',
  'Other services managers n.e.c.',
  'Physicist/Astronomer',
  'Meteorologist',
  'Chemist ',
  'Geologist',
  'Geophysicist',
  'Other physical science professionals ',
  'Mathematician',
  'Operations research analyst',
  'Actuary',
  'Statistician',
  'Statistical officer',
  'Research officer (statistical)',
  'Other statistical, mathematical and actuarial professionals ',
  'Biologist (general)',
  'Botanist',
  'Zoologist',
  'Other biologists, botanists, zoologists and related professionals ',
  'Agronomist',
  'Horticulturist/Arborist',
  'Plant pathologist',
  'Soil scientist',
  'Floriculturist/Orchidologist',
  'Plant tissue culturist',
  'Aquaculturist',
  'Other farming, forestry and fishery advisers ',
  'Environmental officer (environmental protection)',
  'Environment research scientist (eg ecologist)',
  'Energy manager',
  'Other environmental protection and related professionals ',
  'Pharmacologist',
  'Anatomist',
  'Biochemist',
  'Physiologist',
  'Animal scientist',
  'Medical scientist',
  'Clinical research professionals',
  'Other pharmacologists and related professionals ',
  'Manufacturing engineer (general)',
  'Production engineer',
  'Automation engineer',
  'Quality control/assurance engineer',
  'Other industrial and production engineers ',
  'Civil engineer (general)',
  'Structural engineer (general)',
  'Building construction engineer',
  'Soil mechanic/Piling engineer',
  'Other civil engineers ',
  'Environmental engineer',
  'Sewerage/Sanitary engineer',
  'Mechanical engineer (general)',
  'Industrial machinery and tools engineer',
  'Marine engineer (shore-based)',
  'Naval architect',
  'Aeronautical engineer',
  'Automotive engineer',
  'Air-conditioning/Refrigeration engineer',
  'Other mechanical engineers ',
  'Chemical engineer (general)',
  'Chemical engineer (petroleum)',
  'Chemical engineer (petrochemicals)',
  'Other chemical engineers ',
  'Petroleum/Natural gas engineer',
  'Metallurgist',
  'Other mining engineers, metallurgists and related professionals ',
  'Biomedical engineer',
  'Materials engineer',
  'Industrial safety engineer',
  'Quantity surveyor',
  'Ceramics/Glass technologist',
  'Food and drink technologist',
  'Optical engineer (including laser engineer) ',
  'Safety and security consultant',
  'Other engineering professionals n.e.c.',
  'Electrical engineer (general)',
  'Power generation and distribution engineer',
  'Lift engineer',
  'Other electrical engineers ',
  'Electronics engineer (general)',
  'Computer engineer',
  'Semi-conductor engineer',
  'Audio and video equipment engineer',
  'Instrumentation engineer',
  'Embedded systems engineer',
  'Other electronics engineers ',
  'Telecommunications engineer',
  'Building architect',
  'Landscape architect',
  'Fashion/Garment designer (including jewellery designer)',
  'Product and industrial designer',
  'Town planner',
  'Other related planners (eg traffic planner) ',
  'Surveyor (general)',
  'Land surveyor',
  'Hydrographic surveyor',
  'Cartographer',
  'Other surveyors and cartographers ',
  'Graphic designer',
  'Multimedia (including computer games) designer',
  'Multimedia artist',
  'Animator',
  'Interaction/User experience designer',
  'Commercial artist',
  'Other graphic and multimedia designers and artists ',
  'Chief engineer (ship)',
  'Marine superintendent engineer',
  'Ship captain',
  'Marine superintendent (deck)',
  'Commercial airline pilot',
  'Aircraft pilot (except commercial airline and air force)',
  'Flight navigator',
  'Flight engineer',
  'Flying instructor (except air force)',
  'Other aircraft pilots and related associate professionals ',
  'General practitioner/physician',
  'Cardiologist',
  'Dermatologist',
  'Gastroenterologist',
  'Internal medicine physician',
  'Medical oncologist',
  'Paediatrician',
  'Respiratory physician',
  'Psychiatrist',
  'Other specialist medical practitioners (medical) (eg endocrinologist, geriatrician, haematologist, infectious diseases physician, neurologist, nuclear medicine physician, rehabilitation physician, renal physician, rheumatologist)',
  'General surgeon',
  'Cardiothoracic surgeon',
  'Hand surgeon',
  'Neurosurgeon',
  'Orthopaedic surgeon',
  'Paediatric surgeon',
  'Plastic surgeon',
  'Urologist',
  'Other specialist medical practitioners (surgical) ',
  'Anaesthesiologist',
  'Diagnostic radiologist',
  'Emergency physician',
  'Obstetrician/Gynaecologist',
  'Ophthalmologist',
  'Pathologist',
  'Public health physician',
  'Otorhinolaryngologist/Ear, nose, throat (ENT) specialist',
  'Sports physician',
  'Other specialist medical practitioners (other specialisations) (eg occupational physician, radiation oncologist)',
  'Registered nurse and other nursing professionals (eg clinical nurse, nurse educator, excluding enrolled nurse)',
  'Traditional Chinese medicine practitioner',
  'Traditional Chinese medicine acupuncturist',
  'Veterinarian',
  'Dentist (general)',
  'Specialised dentist (eg orthodontist)',
  'Pharmacist (patient care)',
  'Other pharmacists',
  'Occupational health professional (eg occupational hygienists and occupational hygiene officer)',
  'Environmental officer (public health)',
  'Other environmental and occupational health and hygiene professionals',
  'Physiotherapist',
  'Other physical therapists and related professionals',
  'Dietitian',
  'Nutritionist',
  'Audiologist',
  'Speech therapist',
  'Optometrist',
  'Occupational therapist',
  'Osteopath',
  'Chiropractor',
  'Podiatrist',
  'Medical diagnostic radiographer',
  'Radiation therapist',
  'Other health professionals n.e.c.',
  'University lecturer',
  'Polytechnic lecturer',
  'Teaching assistant/Tutor in universities',
  'Other university, polytechnic and higher education teachers ',
  'Junior college and pre-university teacher (including integrated programme year 5-6 teacher)',
  'Technical/Vocational/Commercial education institute teacher and trainer',
  'Secondary school teacher (including integrated programme year  1-4 teacher)',
  'Primary school teacher',
  'Education methods adviser',
  'Teaching aid specialist (including audio-visual aid specialist)',
  'Other education methods specialists',
  'Teacher of the blind',
  'Teacher of the deaf',
  'Teacher of the mentally handicapped',
  'Special education teachers for infants and children (eg Early Intervention Programme for Infants and Children teachers)',
  'Other special education teachers ',
  'Other teaching professionals n.e.c.',
  'Accountant (excluding tax accountant)',
  'Auditor (accounting)',
  'Tax accountant',
  'Financial/Investment adviser (eg relationship manager)',
  'Financial analyst (eg equities analyst, credit analyst, investment research analyst)',
  'Compliance officer/Risk analyst (financial)',
  'Fund/Portfolio manager (including asset allocator)',
  'Treasury manager',
  'Other financial analysts and related professionals (eg financial product structurer/product controller)',
  'Securities and finance dealer/broker',
  'Foreign exchange dealer/broker',
  'Financial derivatives dealer/broker',
  'Commodities derivatives broker',
  'Other finance dealers and brokers',
  'Insurance underwriter',
  'Management consultant',
  'Business consultant',
  'Policy administration professional (eg policy analyst)',
  'Human resource consultant (excluding executive search consultant)',
  'Executive search consultant',
  'Research officer (non-statistical)',
  'Other administration professionals n.e.c.',
  'Creative director (advertising)',
  'Market research analyst',
  'Public relations/Corporate communications professional',
  'Technical sales professionals',
  'Medical and pharmaceutical products sales professionals',
  'ICT sales professionals',
  'Sales professionals (institutional sales of financial products)',
  'Other specialised goods sales professionals ',
  'Trade broker (including oil and bunker trader)',
  'Ship broker',
  'Ship charterer',
  'Systems designer/analyst',
  'IT business process consultant/business analyst',
  'Enterprise/Solution architect',
  'Software developer',
  'Web and mobile applications developer',
  'Multimedia (including computer games) developer',
  'Applications/Systems programmer',
  'Information technology testing/quality assurance specialist',
  'Information technology auditor',
  'Information technology project manager',
  'Software and applications developer and analyst n.e.c.',
  'Database administrator',
  'Database architect',
  'Network, servers and computer systems administrator',
  'Network/Infrastructure architect, engineer and specialist',
  'Information technology security specialist',
  'Data scientist',
  'Database and network professional n.e.c.',
  'Advocate/Solicitor (practising)',
  'In-house legal counsel (except judiciary, ministries and statutory boards) ',
  'Other lawyers (eg prosecutors, legal counsel in ministries and statutory boards) ',
  'Judge (including registrar in judiciary)',
  'Archivist',
  'Curator (art gallery and museum)',
  'Conservator (art works)',
  'Librarian',
  'Other information professionals ',
  'Economist',
  'Sociologist',
  'Anthropologist',
  'Social research analyst',
  'Historian',
  'Political scientist',
  'Other social science professionals (eg philosopher)',
  'Psychologist',
  'Social worker (general)',
  'Social worker (delinquency)',
  'Medical social worker',
  'Counsellor (rehabilitation)',
  'Counsellor (drugs and alcohol)',
  'Counsellor (family)',
  'School counsellor',
  'Other social work and counselling professionals ',
  'Author',
  'Script writer',
  'Advertising copywriter',
  'Technical writer',
  'Continuity and script editor',
  'Book editor',
  'Other authors and related writers',
  'Journalist',
  'Editor (news and periodicals)',
  'Translator',
  'Interpreter',
  'Sculptor',
  'Painter',
  'Cartoonist',
  'Other visual artists ',
  'Instrumentalist',
  'Composer/Orchestrator',
  'Conductor/Director of orchestra/band/choir',
  'Singer',
  'Other musicians, composers and singers ',
  'Dancer',
  'Choreographer',
  'Producer (stage, film, television, computer games, video and radio)',
  'Director (stage, film, television and radio)',
  'Artistic director (stage, film, television and radio)',
  'Producer/Director of commercials',
  'Editor (radio, television and video)',
  'Other film, stage and related directors and producers ',
  'Actor',
  'Radio presenter',
  'Television presenter',
  'Dubbing artiste',
  'Other radio and television presenters and related professionals ',
  'Minister of religion',
  'Other religious professionals',
  'Other professionals n.e.c. (eg patent agent, trademark agent, intellectual property strategist)',
  'Assistant civil and structural engineer',
  'Assistant electrical engineer',
  'Assistant electronics engineer',
  'Assistant mechanical engineer',
  'Assistant chemical engineer',
  'Assistant manufacturing engineer',
  'Other assistant engineers ',
  'Chemistry technician',
  'Physics technician',
  'Other chemical and physical science technicians ',
  'Civil engineering technician',
  'Structural engineering technician',
  'Building technician',
  'Land surveying technician',
  'Resident technical officer',
  'Other civil engineering and related technicians ',
  'Electrical engineering technician (general)',
  'Electrical engineering technician (high voltage)',
  'Other electrical engineering technicians ',
  'Electronics engineering technician (general)',
  'Semi-conductor technician',
  'Audio and video equipment technician',
  'Instrumentation technician',
  'Other electronics engineering technicians ',
  'Mechanical engineering technician (general)',
  'Aeronautical engineering technician',
  'Automotive engineering technician',
  'Air-conditioning/Refrigeration engineering technician',
  'Machining/Tooling technician',
  'Tool/Mould designer',
  'Marine surveyor (ship and nautical)',
  'Airworthiness surveyor',
  'Other mechanical engineering technicians ',
  'Chemical engineering technician (general)',
  'Chemical engineering technician (petroleum and natural gas)',
  'Chemical engineering technician (petrochemicals)',
  'Other chemical engineering technicians ',
  'Manufacturing engineering technician (general)',
  'Production engineering technician',
  'Automation designer',
  'Automation technician',
  'Quality assurance technician',
  'Other manufacturing engineering technicians ',
  'Draughtsman (general)',
  'Mechanical draughtsman',
  'Electrical/Electronics draughtsman',
  'Civil/Structural engineering draughtsman',
  'Architectural draughtsman',
  'Marine draughtsman',
  'Other draughtsmen (eg cartographical draughtsman) ',
  'Mechatronics technician',
  'Refining/Smelting technician',
  'Physical metallurgy technician',
  'Petroleum and natural gas extraction technician',
  'Electronic pre-press technician',
  'Precision optics technician',
  'Quantity surveying technician',
  'Other physical and engineering science technicians n.e.c.',
  'Biological technician',
  'Food science technician',
  'Clinical research coordinator',
  'Other life science technicians',
  'Horticultural technician (including irrigation specialist)',
  'Plant tissue culture technician',
  'Farming technician (animal production)',
  'Other agronomy, horticultural and farming related technicians',
  'Marine engineering officer',
  "Ship's navigating officer (including chief, second and third officer)",
  'Ship pilot',
  "Other ships' deck officers and pilots",
  'Air traffic controller',
  'Sea traffic controller',
  'Air transport service supervisor',
  'MRT service supervisor',
  'Road transport supervisor',
  'Port/Shipping operations supervisor',
  'Other transport operations supervisors ',
  'Port master/Dockmaster ',
  'Port captain',
  'Flight operations officer',
  'Air cargo officer',
  'Load-sheet officer (aircraft)',
  'MRT operations officer',
  'Other transport controllers and related workers n.e.c.',
  'Premises and facilities maintenance officer',
  'Landscape operations officer',
  'Parks officer',
  'Fire and safety inspector',
  'Other building and fire inspectors',
  'Safety inspector (vehicles, processes and products)',
  'Sonographer',
  'Medical X-ray technician',
  'Other medical imaging, therapeutic equipment technicians and related associate professionals',
  'Medical and pathology laboratory technician',
  'Pharmacy technician',
  'Prostheist/Orthopaedic technician',
  'Orthotist/Orthopaedic appliance maker and repairer',
  'Dental technician',
  'Enrolled/Assistant nurse (excluding registered nurse)',
  'Traditional and complementary medicine associate professional (eg homeopath and naturopath)',
  'Veterinary technician/assistant ',
  'Dental assistant',
  'Oral health therapist (eg dental therapists and dental hygienists)',
  'Community health worker',
  'Optician',
  'Massage therapist',
  'Other physiotherapy technicians and related associated professionals',
  'Environmental inspector (environmental public health)',
  'Occupational health inspector',
  'Paramedic',
  'Other health associate professionals n.e.c.',
  'Credit and loans officer',
  'Accounting associate professional (eg assistant accountant, audit (accounting) executive)',
  'Appraiser/Valuer (excluding intangible asset valuer)',
  'Assessor',
  'Intangible asset valuer (eg Intellectual property valuation analyst)',
  'Insurance sales agent/broker (including independent financial planner)',
  'Other insurance representatives and related associate professionals',
  'Business development executive',
  'After sales adviser/Client account service executive',
  'Marketing and sales executive (food & beverage services)',
  'Other commercial and marketing sales executives',
  'Buyer',
  'Purchasing agent',
  'Intellectual property brokers and transaction specialists',
  'Sales and related associate professional n.e.c.',
  'Clearing and forwarding agent',
  'Ship agent',
  'Exhibition/Conference/Event planner (including wedding planner/coordinator)',
  'Employment agent/Labour contractor',
  'Real estate agent',
  'Auctioneer',
  'Advertising salesman',
  'International market agent/representative (eg junket operator)',
  'Other business services agents n.e.c. (eg sports agent, artiste agent)',
  'Personnel/Human resource officer     ',
  'Industrial relations officer  ',
  'Training officer',
  'Crewing executive (ship)',
  'Management executive',
  'Operations officer (except transport operations) ',
  'Other administrative and related associate professionals n.e.c. (eg career consultant/advisor/coach) ',
  'Customs/Immigration officer',
  'Police inspector ',
  'Government tax and excise official',
  'Government social benefits official ',
  'Government licensing official',
  'Other government associate professionals n.e.c.',
  'Legal associate professional (eg paralegal)',
  'Youth worker',
  'Other social work associate professionals',
  'Athlete/Sportsman',
  'Sports coach ',
  'Martial arts instructor',
  'Physical fitness instructor (including yoga instructor and aerobics instructor)',
  'Sports official',
  'Other related instructors and associate professionals (eg adventure training instructor and scuba diving instructor)',
  'Photographer',
  'Interior designer ',
  'Landscape designer',
  'Display artist',
  'Decorator and related worker (eg window dresser and set designer) ',
  'Library officer',
  'Gallery, museum and related technician',
  'Chef',
  'Tattooist',
  'Other artistic and cultural associate professionals n.e.c.',
  'Clown',
  'Magician',
  'Acrobat',
  'Aerialist',
  'Disc jockey',
  'Other creative and performing arts associate professionals',
  'Computer systems operator',
  'Computer technician (including IT user helpdesk technician)',
  'Website administrator/Webmaster',
  'Broadcasting operations technician',
  'Video camera operator ',
  'Television/Motion picture camera operator ',
  'Radio and television studio equipment operator ',
  'Sound recording equipment operator ',
  'Cinema projectionist',
  'Other broadcasting technicians and audio-visual operators (eg microphone operator)',
  'Telecommunications technician',
  'Telecommunications service supervisor',
  'Radio-telephone operator (land-based)',
  "Ship's radio officer",
  'Flight radio operator',
  'Other telecommunications equipment operators',
  'Optical and electronic equipment operator n.e.c.',
  'Pre-primary education teacher ',
  'Language instructor (extracurriculum)',
  'Art and craft instructor (extracurriculum)',
  'Information technology trainer (extracurriculum)',
  'Music instructor (extracurriculum)',
  'Dance instructor (extracurriculum)',
  'Speech and drama instructor (extracurriculum)',
  'Other extracurriculum instructors',
  'Private tutor (academic)',
  'Relief teacher',
  'Other teaching associate professionals n.e.c.',
  'Non-ordained religious associate professional',
  'Other associate professionals n.e.c.',
  'Clerical supervisor ',
  'Office clerk (general)',
  'Filing and copying clerk ',
  'Personnel/Human resource clerk',
  'Other administrative clerks (eg public relations clerk)',
  'Secretary',
  'Verbatim reporter',
  'Stenographer',
  'Typist',
  'Other word processors and related operators',
  'Data entry clerk',
  'Bank teller',
  'Postal service counter clerk',
  'Money changer',
  'Other tellers and counter clerks',
  'Pawnbroker',
  'Moneylender',
  'Bill collector',
  'Other bill collectors and related workers',
  'Travel agency/service clerk',
  'Telephone operator',
  'Receptionist (general)',
  'Hotel receptionist',
  'Medical/Dental receptionist',
  'Airport receptionist/clerk',
  'Customer service officer/clerk',
  'Contact centre information clerk ',
  'Other receptionists and information clerks',
  'Client information clerk n.e.c.',
  'Bookkeeper',
  'Ledger and accounts clerk',
  'Audit clerk',
  'Payroll/Wages clerk',
  'Billing clerk',
  'Purchasing clerk',
  'Other accounting clerks (eg cost clerk)',
  'Bank operations clerk',
  'Insurance/Underwriting clerk',
  'Securities clerk',
  'Other finance and insurance clerks (eg credit clerk)',
  'Statistical clerk (general)',
  'Enumerator/Market research interviewer ',
  'Other statistical clerks',
  'Data processing control clerk',
  'Other computer operations clerks',
  'Stock records clerk',
  'Storekeeper',
  'Other stock clerks',
  'Material planning clerk',
  'Production planning clerk',
  'Other production clerks',
  'Shipping clerk',
  'Land transport clerk',
  'Air transport clerk',
  'Other transport clerks',
  'Library clerk ',
  'Legal clerk ',
  'Proofreading clerk',
  'Other clerical support workers n.e.c.',
  'Chief steward (ship)',
  'Cabin attendant/steward',
  'Rail station service assistant',
  'Ticket inspector',
  'Other transport service inspectors and related workers ',
  'Tour guide',
  'Nature guide (including zoo, birdpark and aquarium)',
  'Theme park guide',
  'Other guides (eg museum, historical sites)',
  'Cook',
  'Captain waiter/Waiter supervisor',
  'Waiter ',
  'Bartender/Mixologist',
  'Barista',
  'Bar/Lounge hostess',
  'Waiter and bartender n.e.c. (eg food checker (catering services))',
  'Hair stylist/Hairdresser',
  'Barber',
  'Other hair care workers (eg hair therapist/consultant)',
  'Beautician',
  'Manicurist',
  'Make-up artist (stage, film and studio)',
  'Masseur (non-medical) (including foot reflexologist)',
  'Slimming consultant',
  'Other wellness related workers n.e.c.',
  'Housekeeper (hotels and other establishments)',
  'House steward',
  'Housekeeping matron',
  'Other housekeepers and related workers (eg personal butler)',
  'Postal/Courier service supervisor',
  'Postman',
  'Process server',
  'Despatch worker',
  'Other mail distribution workers (eg mail sorter)',
  'Gaming supervisor (eg pit manager/supervisor, slot manager/supervisor and casino shift manager/supervisor)',
  'Bookmaker/Croupier/Casino dealer and related gaming worker',
  'Astrologer/Fortune teller and related worker',
  'Undertaker',
  'Embalmer',
  'Pet groomer',
  'Keeper/Trainer in zoological, bird and aquatic parks',
  'Keeper/Trainer in crocodile farm',
  'Horse trainer',
  'Dog trainer',
  'Other animal/bird keepers and animal care workers',
  'Driving instructor/tester',
  'Social escort',
  'Other personal service workers n.e.c.',
  'Hawker/Stall holder (excluding prepared food or drinks)',
  'Hawker/Stall holder (prepared food or drinks)',
  'Newsvendor',
  'Other stall sales workers',
  'Sales supervisor',
  'Shop sales assistant',
  'Cage/Count supervisor',
  'Cashier (general)',
  'Office cashier',
  'Other cashiers, ticket and related clerks',
  'Fashion model',
  "Other models (eg artist's model)",
  'Sales demonstrator ',
  'Salesperson (door-to-door)',
  'Telemarketer',
  'Petrol station attendant',
  'Food service counter attendant  ',
  'Sales worker n.e.c. (eg ship chandler)',
  'Babysitter (household)',
  'Confinement nanny',
  'Child /After school care centre worker',
  'Child/Youth residential care assistant',
  "Teachers' aide",
  'Healthcare assistant (eg therapy aide/assistant)',
  'Other personal care worker (eg old folks home)',
  'Fire-fighting and rescue officer',
  'Police officer',
  'Narcotics officer',
  'Auxiliary police officer',
  'Prison officer',
  'Security supervisor ',
  'Private security officer',
  'Lifeguard',
  'Private investigator',
  'Industrial investigator',
  'Insurance investigator',
  'Park ranger',
  'Other protective services and related workers n.e.c.',
  'Service workers n.e.c. ',
  'Hydroponics farm worker',
  'Mushroom farm worker',
  'Other vegetable farm workers ',
  'Flower/Orchid farm worker',
  'Nursery worker (eg nursery technician)',
  'Gardeners and horticultural workers (eg landscape technician)',
  'Nursery supervisor',
  'Other horticultural and nursery farm workers ',
  'Livestock/Dairy farm worker',
  'Poultry farm worker (general)',
  'Poultry hatchery worker',
  'Poultry inseminator',
  'Other poultry farm workers (eg poultry vaccinator)',
  'Agricultural worker n.e.c.',
  'Fish/Prawn farm worker',
  'Aquarium fish farm worker',
  'Crocodile farm worker',
  'Other aquatic life cultivation workers (eg oyster farm worker)',
  'Coastal waters/Deep sea fishery worker',
  'Supervisor/General foreman (building and related trades)',
  'Bricklayer/Blocklayer',
  'Stone cutter/Carver',
  'Stonemason',
  'Reinforced concreter (general)',
  'Concrete form worker',
  'Cement finisher/Screeder',
  'Cementer (petroleum and gas wells)',
  'Other concrete placers, concrete finishers and related workers',
  'Carpenter',
  'Joiner',
  'Scaffolder',
  'Demolition worker',
  'Cladding/Curtain wall erector ',
  'Other building frame and related trades workers n.e.c. (eg steeplejack)',
  'Roofer',
  'Floor/Wall tiler',
  'Plasterer',
  'Building insulator',
  'Boiler/Pipe insulator',
  'Refrigeration/Air-conditioning equipment insulator',
  'Refractory worker',
  'Other insulation workers',
  'Glazier',
  'Plumber',
  'Pipe fitter',
  'Pipe/Drain layer',
  'Air-conditioning/Refrigeration equipment mechanics',
  'Air-conditioning/Refrigeration plant installer',
  'Building finisher and related trades worker n.e.c.',
  'Building painter',
  'Wall/Ceiling paperhanger',
  'Structural steel/Ship painter',
  'Motor vehicle spray painter',
  'Sign painter',
  'Spray painter (except ships, motor vehicles and signs)',
  'Other painters and related workers',
  'Building maintenance worker',
  'Building structure cleaner and related worker',
  'Supervisor/General foreman (metal, machinery and related trades)',
  'Metal moulder',
  'Coremaker',
  'Welder',
  'Flame cutter',
  'Sheet metal worker (general)',
  'Boilermaker',
  'Panel beater',
  'Other sheet metal workers',
  'Structural steel worker (workshop)/fabricator',
  'Reinforcing iron worker/Steel reinforcement worker',
  'Structural steel erector',
  'Metal shipwright',
  'Ship plater',
  'Mould lofter',
  'Other structural metal preparers and erectors',
  'Hoisting equipment rigger (general)',
  'Rope and cable splicer (general)',
  'Hoisting equipment rigger (construction)',
  'Ship rigger',
  'Oil and gas well drilling rigger',
  'Other riggers and cable splicers',
  'Blacksmith (general)',
  'Forging-press operator',
  'Other blacksmiths, hammersmiths and forging-press workers ',
  'Tool and die maker',
  'Jig and gauge maker',
  'Metal pattern maker',
  'Locksmith',
  'Other toolmakers and related workers',
  'Buffing/Polishing machine operator',
  'Tool grinder (machine tools)',
  'Other metal polishers, grinders and tool sharpeners',
  'Motor vehicle mechanic',
  'Motor vehicle body builder',
  'Aircraft engine mechanic',
  'Aircraft engine fitter',
  'Machinery mechanic (general)',
  'Machinery fitter (general)',
  'Industrial/Office machinery mechanic',
  'Marine engine fitter',
  'Machinery erector/installer',
  'Other machinery mechanics and repairers (eg agricultural machinery mechanic, bicycle repairer)',
  'Supervisor/General foreman (precision, handicraft, printing and related trades)',
  'Watch and clock assembler',
  'Watch and clock repairer',
  'Optical instrument maker/repairer',
  'Medical device assembler/repairer',
  'Other precision instrument makers and repairers ',
  'Musical instrument maker/repairer/tuner',
  'Jewellery worker (general)',
  'Gem setter/cutter and polisher',
  'Goldsmith/Silversmith',
  'Jewellery engraver',
  'Other jewellery and precious metal workers ',
  'Pottery worker',
  'Brick and tile moulder',
  'Other potters and related workers',
  'Glass blower',
  'Glass lens moulder',
  'Optical glass cutter',
  'Lens grinding machine operator',
  'Lens polishing machine operator',
  'Other glass makers, cutters, grinders and finishers',
  'Glass engraver/etcher',
  'Glass and ceramic painter/decorator',
  'Other engravers and related decorative painters',
  'Compositor/Typesetter',
  'Stereotyper',
  'Electrotyper',
  'Photo-engraver',
  'Photogravure cameraman ',
  'Photogravure retoucher',
  'Photogravure etcher',
  'Other pre-press trades workers',
  'Silk screen/Block/Textile printer',
  'Other printers ',
  'Supervisor/General foreman (electrical and electronic trades)',
  'Electrician',
  'Electrical fitter (general)',
  'Electrical lift, escalator and related equipment fitter',
  'Electrical household appliance repairer',
  'Other electrical mechanics and fitters',
  'Electrical line installer',
  'Electric cable jointer',
  'Other electrical line installers and repairers',
  'Electronics fitter (general)',
  'Consumer audio and video equipment mechanic',
  'Other electronics fitters ',
  'Radio, television and radar broadcasting/transmitting equipment fitter',
  'Computer and related electronic equipment mechanic',
  'Telecommunications lineman',
  'Telecommunications installer/servicer',
  'Supervisor/General foreman (food processing, woodworking, garment, leather and related trades)',
  'Slaughterer',
  'Other meat and fish preparers',
  'Baker (general)',
  'Bread baker',
  'Pastry and confectionery maker',
  'Coffee and tea taster',
  'Other food and beverage tasters and graders',
  'Food processing and related trades worker n.e.c. (eg fruit pickler)',
  'Kiln drier',
  'Wood-treating worker',
  'Other wood treaters ',
  'Cabinet and furniture maker',
  'Wood furniture finisher',
  'Picture frame maker',
  'Other cabinet makers and wood related trades workers ',
  'Rattan furniture maker',
  'Other furniture makers and related trades workers n.e.c.',
  'Tailor/Dressmaker',
  'Garment cutter',
  'Garment pattern-maker',
  'Garment marker',
  'Other garment and related pattern-makers and cutters ',
  'Furniture upholsterer',
  'Vehicle upholsterer',
  'Mattress maker',
  'Other upholsterers and related workers ',
  'Shoemaker',
  'Orthopaedic footwear maker',
  'Cobbler',
  'Leather goods maker/assembler',
  'Other shoemakers and related workers',
  'Garment and related trades worker n.e.c. (eg fellmonger, tent sewer, umbrella maker)',
  'Fumigators/Pest and weed controllers',
  'Underwater diver',
  'Other craft and related workers n.e.c.',
  'Stationary plant and machine supervisor/general foreman',
  'Well driller (petroleum and gas wells)',
  'Well driller/borer (except oil and gas wells)',
  'Deep-boring machine operator',
  'Other well drillers and borers and other mining related workers',
  'Precast concrete product making machine operator',
  'Asbestos cement product making machine operator',
  'Terrazzo tile making machine operator',
  'Concrete mixing plant operator (including concrete mixer operator)',
  'Concrete pump operator',
  'Other mineral products and processing machine operators',
  'Ore and metal furnace operator (including furnaceman)',
  'Centrifugal casting machine operator',
  'Die casting machine operator',
  'Continuous rod casting machine operator (non-ferrous metal)',
  'Metal rolling mill worker',
  'Other metal melters, casters and rolling mill operators',
  'Metal heat treating plant operator',
  'Wire drawer',
  'Seamless pipe and tube drawer',
  'Extruder operator (metal)',
  'Other metal drawers and extruders',
  'Electro-plater',
  'Hot-dip plater',
  'Wire-coating machine operator',
  'Galvaniser',
  'Metal sprayer',
  'Anodiser',
  'Sandblaster/Shotblaster (metal)',
  'Metal cleaner',
  'Other metal finishing, plating and coating machine operators',
  'Metalworking machine setter-operator (general)',
  'Lathe setter-operator',
  'Milling machine setter-operator',
  'Planing machine setter-operator',
  'Boring/Drilling machine setter-operator',
  'Shaping and honing machine setter-operator',
  'Precision grinding machine setter-operator',
  'Numerical control machine setter-operator',
  'Other machine-tool setter-operators',
  'Crushing/Grinding/Chemical mixing equipment operator',
  'Chemical heat treating plant operator',
  'Chemical filtering and separating equipment operator',
  'Chemical still/reactor operator',
  'Synthetic fibre making machine operator',
  'Other chemical processing and chemical products plant and machine operators',
  'Photographic products machine operator',
  'Pharmaceutical products machine operator',
  'Soap-making machine operator',
  'Other toiletry products machine operators',
  'Chemical processing, chemical and photographic products plant and machine operator n.e.c.',
  'Rubber millman',
  'Rubber laminating machine operator',
  'Other rubber products machine operators ',
  'Plastic products machine operator',
  'Carton/Paper box making machine operator',
  'Paper product making machine operator',
  'Other paper and paperboard products machine operators ',
  'Spinner (thread and yarn)',
  'Winder (thread and yarn)',
  'Weaving machine operator',
  'Knitting machine operator',
  'Sewing machine operator',
  'Machine embroiderer',
  'Textile bleaching machine operator',
  'Dyeing machine operator',
  'Textile washing/Shrinking machine operator',
  'Other bleaching, dyeing and fabric cleaning machine operators ',
  'Laundry and dry cleaning worker (machine, non-household)',
  'Other textile, fur and leather products machine operators n.e.c. (eg shoemaking and related machine operators)',
  'Sausage making machine operator',
  'Other meat and fish processing machine operators',
  'Dairy product processing machine operator (general)',
  'Dairy product pasteurising machine operator',
  'Butter making machine operator',
  'Ice-cream making machine operator',
  'Chocolate making machine operator',
  'Confectionery making machine operator',
  'Other dairy and confectionery products machine operators ',
  'Grain/Spice milling machine operator',
  'Biscuit making machine operator',
  'Macaroni/Noodle/Vermicelli making machine operator',
  'Other baked and cereal products machine operators',
  'Fruit-press operator',
  'Oil pressman (edible oils)',
  'Refining machine operator (oils and fats)',
  'Hydrogenation operator (oils and fats)',
  'Sauce making machine operator',
  'Other fruit, vegetable and nut processing machine operators ',
  'Sugar processing and refining machine operator',
  'Germination worker (malting)',
  'Kilnman (malting)',
  'Brewer',
  'Fermenting-room man',
  'Pasteuriser attendant (malt liquor)',
  'Syrup making machine operator',
  'Coffee/Tea blending machine operator',
  'Coffee/Cocoa bean roasting and grinding machine operator',
  'Other brewers and wine and other beverage machine operators',
  'Cigarette making machine operator',
  'Other tobacco products machine operators ',
  'Bean curd making machine operator',
  'Ice making machine operator',
  'Feed mixing machine operator',
  'Fish feed making machine operator',
  'Other food and related products machine operators n.e.c.',
  'Paper pulp plant operator',
  'Papermaking machine operator',
  'Other pulp and papermaking plant operators ',
  'Sawyer',
  'Veneer cutter',
  'Plywood core layer',
  'Plywood press operator',
  'Wood grader',
  'Other wood processing plant operators ',
  'Woodworking machine setter-operator',
  'Precision wood sawyer',
  'Other wood products machine operators ',
  'Glass/Ceramics kiln machine operator',
  'Fibreglass maker',
  'Other glass and ceramics plant operators',
  'Boiler fireman',
  "Ship's fireman",
  "Ship's oiler/greaser",
  'Other steam turbine, boiler and engine operators ',
  'Packing/Bottling/Labelling machine operator',
  'Printing machine operator',
  'Bookbinding machine operator',
  'Book embossing machine operator',
  'Other printing, binding and related machine operators',
  'Stationary engine operator (general)',
  'Compressor operator',
  'Pumping station operator',
  'Water treatment machine operator (waterworks)',
  'Refrigeration system operator',
  'Other incineration plant, water treatment and related machine operators ',
  'Power generating plant operator',
  'Petroleum and natural gas refining plant operator',
  'Cylinder filler/tester (compressed and liquefied gases)',
  'Automated assembly line/Industrial robot operator',
  'Stationary plant and machine operator n.e.c.',
  'Supervisor/general foreman of assemblers and quality checkers',
  'Electrical equipment/component assembler',
  'Electronic equipment/component assembler',
  'Battery assembler',
  'Electrical/Electronic products quality checker and tester',
  'Mechanical products quality checker and tester',
  'Other quality checkers and testers',
  'Toy assembler',
  'Other assemblers n.e.c. (eg plastic products assembler, rubber products assembler)',
  'Mobile machinery supervisor and general foreman',
  'MRT train operator',
  'Railway brakeman/signalman/shunter',
  'Other railway brakers and related workers ',
  'Motorcycle delivery man',
  'Taxi driver',
  'Chauffeur',
  'Van driver',
  'Ambulance driver',
  'Parking valet',
  'Other car and light goods vehicle drivers ',
  'Bus driver',
  'Tram driver',
  'Lorry driver',
  'Trailer-truck driver (including prime mover driver)',
  'Fire-engine driver',
  'Airport mobile equipment driver',
  'Concrete mix truck driver',
  'Waste truck driver (including hooklift trucks with roll-on containers)',
  'Other heavy truck and lorry drivers',
  'Excavating/Trench digging machine operator',
  'Bulldozer operator',
  'Dredge operator',
  'Pile driver/Drilling machine operator',
  'Road making machine operator',
  'Tunnelling machine operator (including pipe jacking machine operator)',
  'Other earth moving and related machinery operators ',
  'Crane/Hoist operator (general)',
  'Crane operator (port)',
  'Other crane, hoist and related equipment operators ',
  'Fork lift truck operator',
  'Other lifting truck operators ',
  'Motorised sweeper operator',
  'Mobile machinery operator n.e.c. (eg mobile farm plant operator)',
  'Helmsman/Steersman',
  'Deckhand (including lighterman)',
  'Other ships’ deck crew and related workers',
  'Cleaning supervisor',
  'Room steward/Chambermaid',
  'Hotel cleaner',
  'Other cleaners and helpers in hotels and related establishments ',
  'Office cleaner',
  'Industrial establishment cleaner',
  'Food and beverage establishment cleaner (eg restaurants, food courts, hawker centres)',
  'Dish washer/Plate collector/Table-top cleaner',
  'Residential area cleaner (eg HDB estates, condominiums, private apartments, common areas within residential estates)',
  'Cleaner in open areas (eg bus stops, drains, waterways, overhead bridges, roads, expressways, parks, beaches)',
  'Cleaner in other establishments (eg shopping malls, schools, hospitals, places of worship)',
  'Hand launderer/presser (non-household)',
  'Motor vehicle cleaner/polisher',
  'Window cleaner',
  'Ship/Ship tank cleaner',
  'Aircraft cleaner',
  'Railway carriage cleaner (include MRT, LRT and monorail)',
  'Other cleaning workers n.e.c.',
  'Domestic helper (general)',
  'Other domestic helpers and cleaners',
  'Garden labourer',
  'Grass cutter',
  'Tree cutter',
  'Other park and garden maintenance workers (including landscape worker)',
  'Agricultural and fishery labourer and related worker n.e.c. (eg farm labourer)',
  'Civil engineering/Building construction labourer ',
  'Hand packer ',
  'Manufacturing labourer and related worker n.e.c. (eg rubber sheets clipper and sorter)',
  'Hand/Pedal vehicle driver',
  'Stevedore',
  'Railway/Road vehicle loader',
  'Aircraft loader (eg airport baggage/cargo handler)',
  'Godown labourer',
  'Lorry attendant',
  'Mover',
  'Lashing worker',
  'Other material and freight handling workers',
  'Kitchen assistant',
  'Food/Drink stall assistant  ',
  'Fast food preparer ',
  'Tea server/steward (excluding bartender, barista and food/drink stall assistant)',
  'Waste and recyclables collection supervisor',
  'General waste and recyclables collection worker',
  'Recyclables sorter',
  'Greasy waste collection worker',
  'Other waste and recyclables collection worker',
  'Bell captain',
  'Bellboy/Hotel porter',
  'Porter (except hotel)',
  'Office/Library attendant',
  'Laboratory attendant',
  'Hospital/Clinic attendant',
  'Car park attendant',
  'Sports and recreational attendant (eg golf marshal, golf caddie, fun fair attendant, bowling alley attendant, swimming pool attendant)',
  'Bus attendant',
  'Restroom attendant',
  'Other attendants',
  'Building caretaker/Watchman',
  'Doorman',
  'Other watchmen and related workers (eg lighthouse keeper)',
  'Leaflet and newspaper distributor/deliverer',
  'Meter reader/Vending-machine collector',
  'Odd job person ',
  'Other elementary workers n.e.c.',
  'WORKERS NOT ELSEWHERE CLASSIFIED',
  'Worker reporting unidentifiable or inadequately described occupation',
  'Worker not reporting any occupation',
  'Singapore Armed Forces personnel',
  'Foreign Armed Forces personnel',
  'Foreign Diplomatic personnel',
  'Migrant Worker',
];
