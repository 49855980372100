import { Pipe, PipeTransform } from '@angular/core';

import { ItemPriceAdjustment } from '../objects/request/DispatchItemEntity';

@Pipe({
  name: 'unitPrice',
})
export class UnitPricePipe implements PipeTransform {
  transform(
    value: any,
    qty: number,
    itemPriceAdjustment: ItemPriceAdjustment
  ): any {
    if (!value || !qty || !itemPriceAdjustment) {
      return '0.00';
    }
    if (itemPriceAdjustment.paymentType === 'DOLLAR') {
      const amount = ((value + (+itemPriceAdjustment.adjustedValue * 100)) * 0.01).toFixed(2);
      return amount;
    } else {
      //return oriTotalPrice + qty * +itemPriceAdjustment.adjustedValue;
      return '0.00';
    }
  }
}