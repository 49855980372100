export interface PatientPayableInterface {
  billId: string;
  billNumber: string;
  caseNo: string;
  billDateTime: string;
  amount: number;
  rejectionReason: string;
  paidByUser: boolean;
}

export class PatientPayable implements PatientPayableInterface {
  billId: string;
  billNumber: string;
  caseNo: string;
  billDateTime: string;
  amount: number;
  rejectionReason: string;
  paidByUser: boolean;

  constructor(
    billId?: string,
    billNumber?: string,
    caseNo?: string,
    billDateTime?: string,
    amount?: number,
    rejectionReason?: string,
    paidByUser?: boolean
  ) {
    this.billId = billId || '';
    this.billNumber = billNumber || '';
    this.caseNo = caseNo || '';
    this.billDateTime = billDateTime || '';
    this.amount = amount === undefined ? 0 : amount;
    this.rejectionReason = rejectionReason || '';
    this.paidByUser = paidByUser === undefined ? false : paidByUser;
  }
}
