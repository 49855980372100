import { MedicalCoverageByCaseRes } from './../../../../objects/PolicyHolderInfo';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AttachedMedicalCoveragesStore } from './attached-medical-coverages.store';

@Injectable({ providedIn: 'root' })
export class AttachedMedicalCoveragesQuery extends Query<MedicalCoverageByCaseRes> {
  attachedMedicalCoverages$ = this.select();

  constructor(protected store: AttachedMedicalCoveragesStore) {
    super(store);
  }

  getLimit() {
    return this.getValue().limit;
  }
}
