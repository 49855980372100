<div class="modal-header">
    <h4 class="modal-title pull-left">Allergy and Alerts</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnExit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<alert></alert>
<div class="modal-body">
    <div class="row">
        <div class="col-8">
        </div>
        <div class="col-4">
            <div class="btn-toolbar float-right mr-2" role="toolbar" *ngxPermissionsOnly="['ROLE_CMIS_ENQUIRY']">
                <div class="btn-group mr-2" role="group" *ngIf="!isNewPatient">
                    <!-- // This commented button is  needed for future enhansement -->
                    <!-- <button class="button-primary" (click)="doCheckCMIS()" title="Load Data  from Critical Medical Information Store (CMIS)"
                    [disabled]="medicalAlertFormGroup.invalid || alertFormGroup.invalid">
                        Load From CMIS
                        <i class="icon-info-circled"></i>
                    </button> -->
                    <button class="button-primary" (click)="doViewCMIS()" title="View  from Critical Medical Information Store (CMIS)"
                    *ngIf="showcmisButton">
                            View CMIS
                        <i class="icon-info-circled"></i>
                    </button>
                    <button class="button-primary" title="View  from Critical Medical Information Store (CMIS)"
                    *ngIf="!showcmisButton">
                    <p *ngIf="!showcmisButton" class="animate">Loading Data</p>
                    </button>
                    
                </div>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <tabset #tabs class="custom-tabs">
                <tab heading="Allergy / ADR Information ({{alertFormGroup.get('alertArray')['controls'].length}})">
                    <ng-template tabHeading>
                        <span *ngIf="!this.alertFormGroup.valid" class="icon-attention redBg" tooltip="There are errors Please fix them before saving.">
                        </span>
                    </ng-template>
                    <app-patient-detail-tag-add-alert 
                        [alertFormGroup]="alertFormGroup">
                    </app-patient-detail-tag-add-alert>
                </tab>
                <tab heading="Clinical Alert Information ({{medicalAlertFormGroup.get('alertArray')['controls'].length}})">
                    <ng-template tabHeading>
                        <span *ngIf="!this.medicalAlertFormGroup.valid" class="icon-attention redBg" tooltip="There are errors Please fix them before saving.">
                        </span>
                    </ng-template>
                    <app-patient-add-alerts-info-add-clinical
                        [medicalAlertFormGroup]="medicalAlertFormGroup">
                    </app-patient-add-alerts-info-add-clinical>
                </tab>
            </tabset>
        </div>
    </div>
    
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="btn-toolbar float-right mr-2" role="toolbar">
                <div class="btn-group mr-2" role="group">
                    <button 
                        type="button" 
                        class="button-green"
                        [disabled]="!this.alertFormGroup.valid || !this.medicalAlertFormGroup.valid"
                        (click)="onBtnSave()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>