export default class RefreshSetting {
  reloadPatientInfo?: boolean;
  defaultTabBehaviour?: boolean;
  saveOnlyIfDirty?: boolean;
  showAlert?: boolean;
  hasPrintAction?: boolean;
  hasPrintMemoAction?: boolean;

  constructor(
    reloadPatientInfo?: boolean,
    defaultTabBehaviour?: boolean,
    showAlert?: boolean,
    saveOnlyIfDirty?: boolean,
    hasPrintAction?: boolean,
    hasPrintMemoAction?: boolean
  ) {
    this.reloadPatientInfo =
      reloadPatientInfo === undefined ? true : reloadPatientInfo;
    this.defaultTabBehaviour =
      defaultTabBehaviour === undefined ? true : defaultTabBehaviour;
    this.showAlert = showAlert === undefined ? true : showAlert;
    this.saveOnlyIfDirty =
      saveOnlyIfDirty === undefined ? false : saveOnlyIfDirty;
    this.hasPrintAction = hasPrintAction === undefined ? false : hasPrintAction;
    this.hasPrintMemoAction = hasPrintMemoAction === undefined ? false : hasPrintMemoAction;
  }
}
