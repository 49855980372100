import { Injectable } from '@angular/core';

import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import {
  Case,
  PatientVisitEntities,
  createCase,
} from '../../objects/state/Case';
import { MedicalCoverageByCaseRes } from '../../objects/PolicyHolderInfo';

export interface AkitaCaseVisitState
  extends ActiveState,
    Case,
    MedicalCoverageByCaseRes,
    EntityState<PatientVisitEntities> {}

const initialState = { ...createCase({}), active: undefined };

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'caseVisit', idKey: 'visitId', resettable: true })
export class AkitaCaseVisitStore extends EntityStore<
  AkitaCaseVisitState,
  PatientVisitEntities
> {
  constructor() {
    super(initialState);
  }
}
