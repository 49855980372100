export const medicalCoverageLabelTemplate = `
<!DOCTYPE html>
<html>

<head>
    <title>
        {{ title }}
    </title>
    <link rel="stylesheet" href="./lib/bootstrap/bootstrap.min.css">
    <style>
        body {
            background: rgb(255, 255, 255);
            font-size: 12px;
            font-family: Arial, Helvetica, sans-serif;
        }

        .font-9 {
            font-size: 9px;
        }

        page {
            /*border: 1px solid black;*/
            background: white;
            display: block;
            box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
            page-break-after: auto;
            page-break-inside: avoid;
            margin: 4mm auto;
            font-size: 11px;
            font-family: Arial, Helvetica, sans-serif;
        }

        page[size="MedicalCoverageLabel"] {
            width: 10cm;
            height: 5.4cm;
        }

        @media print {

            body,
            page {
                min-width: initial !important;
                margin: 0;
            }

            @page {
                size: landscape;
                margin: 0mm 0mm 0mm 6mm;
            }
        }

        .overflow-text {
            overflow: auto;
        }

        .text-size-xl {
            font-size: 14px;
        }

        .text-size-l-12 {
            font-size: 12px;
        }

        .text-size-l {
            font-size: 11px;
        }

        .text-size-m {
            font-size: 10px;
        }

        .text-size-s {
            font-size: 8px;
        }

        .text-size-xs {
            font-size: 7px;
        }

        .address {
            line-height: 0.9;
        }
    </style>
</head>
<page size="MedicalCoverageLabel">
    <div class='container-fluid px-1'>
        <br>
        <br>
        <div class=" ml-2 text-size-xl">
            <div class="row">
                <div class="col">
                <strong>{{ name }}</strong>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    {{ address }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    {{ postalCode }}
                </div>
            </div>
        </div>
    </div>
    </div>
</page>

</html>
`;
