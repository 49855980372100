import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, share, take, takeUntil } from 'rxjs/operators';

import { AkitaChargeItemQuery } from './states/akita-charge-item.query';
import { UtilsService } from './utils.service';
import { VisitManagementService } from './visit-management.service';
import { ApiCaseManagerService } from './api-case-manager.service';
import { ApiPatientInfoService } from './api-patient-info.service';
import { ApiPatientVisitService } from './api-patient-visit.service';
import { ApiPcnVisitsService } from './api-pcn-visits.service';
import { AuthService } from './auth.service';
import { AlertService } from '../services/alert.service';
import { ApiCmsManagementService } from '../services/api-cms-management.service';
import { PaymentService } from '../services/payment.service';
import { DoctorService } from '../state/doctor/doctor.service';

import { AkitaInstructionQuery } from './states/akita-instruction.query';
import { AkitaDosageInstructionQuery } from './states/akita-dosage-instruction.query';

import { Country } from '../objects/Country';
import { Nationality } from '../objects/Nationality';
import { SpecialityMaster } from '../objects/SpecialityMaster';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { CidcMapping } from '../objects/CidcMapping';
import { OrderReturnRequestReason } from '../objects/CommonResponses';
import { PurchaseRequestJustification } from '../objects/PurchaseRequestJustification';
import { InventoryItemClinicDetail } from '../objects/InventoryItemClinicDetail';
import { InventorySupplier } from '../objects/InventorySupplier';
import { MedicalCoverage } from '../objects/MedicalCoverage';
import { StockAdjustmentReason } from '../objects/request/StockAdjustment';
import { MedicalAlertResponse } from '../objects/response/MedicalAlertResponse';
import MedicalAssessment from '../objects/response/MedicalAssessment';
import PatientInfo from '../objects/response/PatientInfo';
import PrimayCareChronicConditions from '../objects/response/PrimayCareChronicConditions';
import Template from '../objects/response/Template';
import { VitalConfiguration } from '../objects/response/VitalConfiguration';
import { Case } from './../objects/Case';
import { Discount } from './../objects/Charge';
import { DosageInstruction, Instruction } from './../objects/DrugItem';
import { createMedicalCoverageByCaseRes, MedicalCoverageByCaseRes } from './../objects/PolicyHolderInfo';
import { AllergyGroup } from './../objects/response/AllergyGroup';
import { Clinic } from './../objects/response/Clinic';
import { createMedicalCoverageResponse, Insurance, MedicalCoverageWithBalance } from './../objects/response/MedicalCoverageResponse';
import { PatientVisitListResponse, PreRegistration } from './../objects/response/PatientRegistryListResponse';
import { PatientVisitList } from './../objects/response/PatientVisitList';
import ReferralData from './../objects/response/RefferalData';
import { User } from './../objects/response/User';
import { Doctor } from './../objects/SpecialityByClinic';
import { StoreStatus } from './../objects/StoreStatus';
import { Uom } from './../objects/Uom';

import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT, INPUT_DELAY } from './../constants/app.constants';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';
import { get } from 'lodash';
import { ApiHsgService } from './api-hsg.service';
import { HSGGoal } from '../model/HSGPlan';

import { ExercisePlanWorkflow } from '../model/care-plan/CarePlanExerciseRecommendation';
import { DietRecommendation } from '../model/care-plan/CarePlanDietRecommendation';
import { VaccinationRecommendation } from '../model/care-plan/CarePlanVaccination';
import { HealthPlanWorkflow } from '../model/care-plan/CarePlanHealthGoal';

const getStr = (obj, ltr) => get(obj, ltr) || '';

@Injectable()
export class StoreService implements OnDestroy {
  private isClinicLoaded = new Subject();
  private isClinicSelected = new Subject();
  private headerRegistry = new Subject();
  private isStoreReady: BehaviorSubject<StoreStatus>;
  private currentPatientInfoLoaded: BehaviorSubject<PatientInfo>;
  private currentPatientMedicalAlertLoaded: BehaviorSubject<
    Array<MedicalAlertResponse>
  >;
  private currentMedicalCoverageWithBalanceLoaded: BehaviorSubject<
    MedicalCoverageWithBalance
  >;
  private currentPatientVisitHistoryListLoaded: BehaviorSubject<
    Array<PatientVisitList>
  >;
  private attachedToCaseMedicalCoverageLoaded: BehaviorSubject<
    MedicalCoverageByCaseRes
  >;

  currentPatientInfo: PatientInfo;
  currentPatientMedicalAlert: Array<MedicalAlertResponse>;
  currentPatientVisitHistoryList: Array<PatientVisitList>;
  currentMedicalCoverageFormArray: FormArray;
  attachedToCaseMedicalCoverage: MedicalCoverageByCaseRes;
  currentBalance: any;
  currentMedicalCoverageWithBalance: MedicalCoverageWithBalance;

  private patientIdRefresh = new Subject<string>();
  private patientVisitIdRefresh = new BehaviorSubject<any>([]);
  private visitStatusRefresh = new BehaviorSubject<any>([]);
  currentPatientId = this.patientIdRefresh.asObservable();

  currentConsultationRoute = 'route1';

  private patientId: string;
  private _caseId: string;
  private _caseStatus: string;
  private _caseData: Case;
  private queueNumber: string;
  private visitStatus: string;
  private visitStartTime: string;
  startShowingToast = false;

  consultationId: string;
  private patientVisitRegistryId: string;
  private user: User;

  medicalCoverageList: Array<MedicalCoverage> = [];
  medicalCoverageListWithExpired = [];
  visitPurposeList = [];

  authorizedClinicList = [];
  private clinicList = new Array<Clinic>();

  doctorList = [];
  doctorListByClinic = [];
  private clinicId: string;
  clinicCode: string;
  clinic: Clinic;

  communicationTypes = [];
  vaccinationList = [];
  sddCodeList = [];
  allergyGroupList: AllergyGroup[] = [];
  allergyGroupListOptions = [];
  primaryCareChronicCondition: Array<PrimayCareChronicConditions>;
  medicalAssessments: Array<MedicalAssessment>;
  // NEW REFERRAL
  referralData: ReferralData;
  referralSpecialities: string[];
  private referralSpecialitiesSub = new Subject<HttpResponseBody>();

  // NEW REFERRAL
  uoms: Array<Uom> = new Array();
  countries: Array<Country> = new Array();
  nationalities: Array<Nationality> = new Array();
  referralSpecialitiesMap: Array<SpecialityMaster> = new Array();
  exemptionConditions = new Array<any>();
  mhcpVaccineMapping = new Array<any>();
  hsgVaccineConditions = new Array<any>();
  hsgAssessmentVitalMaster = new Array<any>();
  hsgConditions = new Array<any>();
  // specialitiesList: Practice[];

  patientRegistry: PatientVisitListResponse;

  errorMessages = [];

  notificationList = [];
  unreadNotificationList = [];
  notificationPolling: any;
  registryPolling: any;

  routeOfAdministrators: any[];
  cdlensMappings: any[] = [];
  deliveryLocations: any[] = [];
  remoteDeliveryOrderStatus: any[] = []
  whitelistedItems: any[] = []
  sflItems: any[] = []
  SFLSetupData: any[] = []

  private templates = new Array<Template>();
  private instructions: Array<Instruction>;
  private dosageInstructions: Array<DosageInstruction>;
  vitalConfigurations: Array<VitalConfiguration>;
  vitalConfigurationsForAdd: Array<VitalConfiguration>;
  defaultVitals: Array<VitalConfiguration>;
  defaultAssessments: Array<MedicalAssessment>;

  //inventory
  private itemTypeList: Array<string> = [];
  private supplierList: Array<InventorySupplier> = [];
  private supplierListMap: Map<string, InventorySupplier> = new Map();
  private stockAdjustmentReason: Array<StockAdjustmentReason> = [];
  private returnRequestReason: Array<OrderReturnRequestReason> = [];
  private purchaseRequestJustification: Array<
    PurchaseRequestJustification
  > = [];
  private inventoryItemClinicDetail: Array<InventoryItemClinicDetail> = [];

  // Store Status
  private storeSuccessCount = 0;
  private storeFailCount = 0;
  private storeStatus: StoreStatus;
  private API_COUNTS = 20;

  //CIDC
  cidcTreatmentMappings: Array<CidcMapping> = new Array();

  //HSG Goal Master
  hsgGoalMasterData: Array<HSGGoal> = [];

  originalHsgHealthPlans: Array<any> = [];
  hsgHealthPlans: Array<HealthPlanWorkflow> = [];

  originalHsgVaccinationRecommendations: Array<any> = [];
  hsgVaccinationRecommendations: Array<VaccinationRecommendation> = [];

  originalHsgDietRecommendations: Array<any> = [];
  hsgDietRecommendations: Array<DietRecommendation> = [];

  originalHsgExerciseRecommendations: Array<any> = [];
  originalHsgExerciseRecommendationsFromPayload: any;
  hsgExerciseRecommendations: Array<ExercisePlanWorkflow> = [];

  private consultEndTimeSource = new BehaviorSubject('');
  consultEndTime = this.consultEndTimeSource.asObservable();

  private consultStartTimeSource = new BehaviorSubject('');
  consultStartTime = this.consultStartTimeSource.asObservable();

  private icdType = new BehaviorSubject('');
  setIcdType = this.icdType.asObservable();

  private drugTypeSelected = new BehaviorSubject('');
  drugTypeSelect = this.drugTypeSelected.asObservable();

  private openNotificationPanel = new Subject<boolean>();

  isRefundAllowed: boolean = true;
  isBillAdjAllowed: boolean = true;

  private selectedPatientDiseases = new BehaviorSubject({});
  private browserNotifications: Array<{ notification: any, isDisplayed: boolean }> = [];

  constructor(
    private permissionsService: NgxPermissionsService,
    private visitManagementService: VisitManagementService,
    private authService: AuthService,
    private alertService: AlertService,
    private apiCmsManagementService: ApiCmsManagementService,
    private apiCaseManagerService: ApiCaseManagerService,
    private apiPatientInfoService: ApiPatientInfoService,
    private utilsService: UtilsService,
    private apiPatientVisitService: ApiPatientVisitService,
    private apiPcnVisitsService: ApiPcnVisitsService,
    private doctorService: DoctorService,
    private router: Router,
    private paymentService: PaymentService,
    private akitaChargeItemQuery: AkitaChargeItemQuery,
    private toastr: ToastrService,
    private akitaInstructionQuery: AkitaInstructionQuery,
    private akitaDosageInstructionQuery: AkitaDosageInstructionQuery,
    private apiHSGService: ApiHsgService,
    private titleService: Title,
  ) {
    this.storeStatus = new StoreStatus(false, false, false);
    this.isStoreReady = new BehaviorSubject(this.storeStatus);

    this.currentPatientInfoLoaded = new BehaviorSubject(
      this.currentPatientInfo
    );

    this.currentPatientMedicalAlertLoaded = new BehaviorSubject(
      this.currentPatientMedicalAlert
    );

    this.currentPatientVisitHistoryListLoaded = new BehaviorSubject(
      this.currentPatientVisitHistoryList
    );

    this.attachedToCaseMedicalCoverageLoaded = new BehaviorSubject(
      this.attachedToCaseMedicalCoverage
    );

    this.currentMedicalCoverageWithBalanceLoaded = new BehaviorSubject(
      this.currentMedicalCoverageWithBalance
    );

    if (
      localStorage.getItem('access_token') &&
      localStorage.getItem('clinicCode') &&
      localStorage.getItem('clinicId')
    ) {
      this.clinicCode = localStorage.getItem('clinicCode');
      this.clinicId = localStorage.getItem('clinicId'); // preInit would have been called
    }
  }

  preInit(_user: User) {
    this.storeSuccessCount = 0;
    this.storeFailCount = 0;
    this.storeStatus.hasError = false;
    this.storeStatus.isLoaded = false;
    this.storeStatus.isReseting = true;
    this.isStoreReady.next(this.storeStatus);

    this.user = _user;
    localStorage.setItem('roles', JSON.stringify(this.user.roles));
    this.permissionsService.loadPermissions(this.user.roles);
    this.authService.permissionsLoaded = true;
    this.initStore();
  }

  refreshPatientId(patientId: string) {
    this.patientIdRefresh.next(patientId);
  }

  ngOnDestroy() {
    this.unsubscribeNotificationPolling();
    this.unsubscribeRegistryPolling();
  }

  initStore() {
    this.authService.isLogout().subscribe(data => this.logoutClearUp());
    this.alertService.getMessage().subscribe(msg => { });

    this.listReferralSpecialities();
    this.getVaccinationExemptionConditions();
    this.getMhcpVaccineMapping();
    this.getHsgVaccineConditions();
    this.getHsgAssessmentVitalMaster();
    this.getHsgConditions();
    this.startHeaderRegistryPolling();
    this.startNotificationPolling();
  }

  afterClinicDataLoaded(res) {
    this.setClinic([...res.payload]);
    this.isClinicLoaded.next(res.payload);
    this.isClinicLoaded.complete();
    this.clinic = this.clinicList.find(clinic => clinic.id === this.clinicId);
  }

  afterGettingMedicalCoverageList(res) {
    const data = res.payload.content;
    const today = moment();

    this.medicalCoverageListWithExpired = Object.assign(data);
    data.forEach(item => {
      const isValid = this.utilsService.validateDates(
        today,
        moment(item.endDate, DISPLAY_DATE_FORMAT)
      );
      if (item.coveragePlans.length !== 0) {
        if (isValid) {
          this.medicalCoverageList.push(item);
        }
      }
    });

    this.medicalCoverageList.map(item => {
      return {
        name: item.name,
        coveragePlans: item.coveragePlans.map(plan => plan.name),
      };
    });

    const stopTimer = new Subject();
    timer(1000, 2000)
      .pipe(takeUntil(stopTimer))
      .subscribe(resData => {
        if (
          this.medicalCoverageListWithExpired &&
          this.medicalCoverageListWithExpired.length > 0
        ) {
          stopTimer.next();

          const attachedMcs = this.attachedToCaseMedicalCoverage;
          if (attachedMcs) {
            this.getAttachedToCaseMedicalCoverageLoadedSubject().next(
              createMedicalCoverageByCaseRes(
                attachedMcs.coverage,
                attachedMcs.limit,
                attachedMcs.policyHolder
              )
            );
          }
        }
      });
  }

  afterAllergyGroupDataLoaded(res) {
    this.allergyGroupList = res;
    this.allergyGroupListOptions = this.allergyGroupList.map(allergy => {
      return {
        value: allergy.id,
        label: allergy.groupCode,
      };
    });
  }

  afterInstructionsLoaded(data) {
    const { payload } = data;
    if (payload) {
      this.instructions = payload['INSTRUCTIONS']
        ? payload['INSTRUCTIONS']
        : [];
      this.dosageInstructions = payload['DOSAGE_INSTRUCTIONS']
        ? payload['DOSAGE_INSTRUCTIONS']
        : [];
      this.uoms = payload['DOSAGE_UOM'] ? payload['DOSAGE_UOM'] : [];
      this.countries = payload['COUNTRIES'] ? payload['COUNTRIES'] : [];
      this.nationalities = payload['NATIONALITIES']
        ? payload['NATIONALITIES']
        : [];
    }
  }

  afterVitalConfigurationLoaded(data) {
    this.vitalConfigurationsForAdd = [];
    data.payload.forEach(vital => {
      const { code, name, enabled } = vital;
      const newVital = new VitalConfiguration(
        code,
        name,
        vital.uom || '',
        enabled,
        vital.type || ''
      );
      this.vitalConfigurationsForAdd.push(newVital);
    });
  }

  afterDefaultVitalLoaded(data) {
    const strDefaultVitals = JSON.stringify(data.payload);
    this.defaultVitals = [];
    this.defaultVitals = JSON.parse(strDefaultVitals);
  }

  afterListDoctorsByClinic(res) {
    // Sort Anchor Doctor to Display First
    const anchorDoctors = [];
    const otherDoctors = [];
    this.doctorListByClinic = res.payload.forEach(element => {
      if (element.doctorGroup === 'ANCHOR') {
        anchorDoctors.push(element);
      } else {
        otherDoctors.push(element);
      }
    });
    this.doctorListByClinic = [...anchorDoctors, ...otherDoctors];
  }

  setDoctorData(res) {
    this.doctorList = res.payload;
    this.doctorService.set(<Array<Doctor>>res.payload || []);
  }

  afterCommunicationTypes(data) {
    this.communicationTypes = data;
  }

  listReferralSpecialities() {
    this.apiCmsManagementService.listReferralSpeciality().subscribe(
      res => {
        this.referralSpecialities = Object.keys(res.payload);
        this.referralSpecialitiesSub.next(res);
        this.referralSpecialities.forEach(specialityKey => {
          if (specialityKey != null && specialityKey != '') {
            let typesArray = [];

            res.payload[specialityKey].forEach(specialityKeyItem => {
              typesArray.push(specialityKeyItem.type.toString());
            });

            let obj = {
              "speciality": specialityKey,
              "types": [...new Set(typesArray)],
            }

            let inputObj: SpecialityMaster = <SpecialityMaster>obj;

            this.referralSpecialitiesMap.push(inputObj)
          }
        });
        this.setStoreReady(true);
      },
      err => {
        this.alertService.error(JSON.stringify(err));
        this.setStoreReady(false);
      }
    );
  }

  afterReferralByType(data) {
    this.referralData = data;
  }

  listDoctorsByClinic() {
    if (this.clinicId) {
      this.apiCmsManagementService
        .listDoctorsByClinic(this.clinicId)
        .pipe(take(1))
        .subscribe(
          res => {
            if (res.payload) {
              this.afterListDoctorsByClinic(res);
            }
            this.setStoreReady(true);
          },
          err => {
            this.alertService.error(JSON.stringify(err));
            this.errorMessages['listDoctors'] = err;
            this.setStoreReady(false);
          }
        );
    }
  }

  listChronicConditions() {
    this.apiPcnVisitsService.listAllChronicConditions().subscribe(
      ({ payload }) => {
        this.primaryCareChronicCondition = payload;
        this.setStoreReady(true);
      },
      err => {
        this.alertService.error(JSON.stringify(err));
        this.errorMessages['listChronicConditions'] = err;
        this.setStoreReady(false);
      }
    );
  }

  afterListAllAssessments(data) {
    this.medicalAssessments = [...data.payload];
  }

  setStoreReady(hasSucceeded: boolean) {
    if (hasSucceeded) {
      this.storeSuccessCount++;
    } else {
      this.storeFailCount++;
    }

    if (this.storeSuccessCount >= this.API_COUNTS) {
      this.storeStatus.hasError = false;
      this.storeStatus.isLoaded = true;
      this.storeStatus.isReseting = false;
      this.isStoreReady.next(this.storeStatus);
    } else if (
      this.storeFailCount > 0 &&
      this.storeSuccessCount + this.storeFailCount === this.API_COUNTS
    ) {
      this.storeStatus.hasError = true;
      this.storeStatus.isLoaded = true;
      this.storeStatus.isReseting = false;
      this.isStoreReady.next(this.storeStatus);
    }
  }

  getStoreStatus(): StoreStatus {
    return this.storeStatus;
  }

  startHeaderRegistryPolling() {
    if (!this.registryPolling) {
      this.registryPolling = timer(0, 20000).subscribe(val => {
        this.updatePatientRegistryList();
        this.visitManagementService.setQueueRefresh();
      });
    }
  }

  unsubscribeNotificationPolling() {
    if (this.notificationPolling) {
      this.notificationPolling.unsubscribe();
      this.notificationPolling = null;
    }
  }

  startNotificationPolling() {
    if (!this.notificationPolling) {
      this.notificationPolling = timer(1000, 20000).subscribe(val => {
        if (!!this.clinicId) {
          this.updateNotificationList();
        }
      });
    }
  }

  unsubscribeRegistryPolling() {
    if (this.registryPolling) {
      this.registryPolling.unsubscribe();
      this.registryPolling = null;
    }
  }

  getMedicalCoveragesWithPagination() {
    this.apiCmsManagementService.listMedicalCoverages().subscribe(
      res => {
        this.medicalCoverageList = res.payload.content;
      },
      err => {
        this.alertService.error(JSON.stringify(err));
        this.errorMessages['getMedicalCoveragesWithPagination'] = err;
      }
    );
  }

  getVitalConfigurations() {
    this.apiCmsManagementService.listAllVitalConfigurations().subscribe(
      res => {
        if (res.payload) {
          this.afterVitalConfigurationLoaded(res);
          this.setStoreReady(true);
        }
      },
      err => {
        this.errorMessages['getVitalConfiguration'] = err;
        this.alertService.error(JSON.stringify(err));
        this.setStoreReady(false);
      }
    );
  }

  getDefaultVitals() {
    this.apiCmsManagementService.listDefaultVitals().subscribe(
      res => {
        if (res.payload) {
          this.afterDefaultVitalLoaded(res);
          this.setStoreReady(true);
        }
      },
      err => {
        this.errorMessages['getDefaultVitals'] = err;

        this.alertService.error(JSON.stringify(err));
        this.setStoreReady(false);
      }
    );
  }

  afterDefaultAssessments(data) {
    this.defaultAssessments = [];
    this.defaultAssessments = JSON.parse(JSON.stringify(data.payload));
  }

  afterInventoryInfo(data) {
    this.supplierList = data.payload.map(supplier =>
      new InventorySupplier().adapt(supplier)
    );

    this.supplierListMap = new Map(
      data.payload.map(supplier => {
        return [supplier.id, new InventorySupplier().adapt(supplier)];
      }),
    );
  }

  afterInventoryStockAdjustmentReasonList(data) {
    this.stockAdjustmentReason = data.payload.map(item =>
      new StockAdjustmentReason().adapt(item)
    );
  }

  afterInventoryReturnRequestItemReasonList(data) {
    this.returnRequestReason = data.payload.map(item =>
      new OrderReturnRequestReason().adapt(item)
    );
  }

  afterInventoryItemClinicDetail(data) {
    this.inventoryItemClinicDetail = data;
  }

  afterPurchaseRequestJustification(data) {
    this.purchaseRequestJustification = data.payload.map(item =>
      new PurchaseRequestJustification().adapt(item)
    );
  }

  getAuthorizedClinicList() {
    const tempArray = [];

    if (this.user) {
      this.clinicList.map(clinic => {
        clinic.status &&
          clinic.status === 'ACTIVE' &&
          clinic.clinicStaffUsernames.forEach(staffUsername => {
            if (staffUsername === this.user.userName) {
              tempArray.push(clinic);
            }
          });
      });
    }

    this.authorizedClinicList = tempArray;
    return tempArray;
  }

  getAllergyGroupList() {
    this.apiPatientInfoService.listAllergyGroups().subscribe(
      res => {
        this.allergyGroupList = res.payload;
        this.allergyGroupListOptions = this.allergyGroupList.map(allergy => {
          if (allergy.status && allergy.status === 'ACTIVE')
            return {
              value: allergy.id,
              label: allergy.groupCode,
            };
        });
      },
      err => {
        this.alertService.error(JSON.stringify(err));
        this.errorMessages['listAllergyGroups'] = err;
      }
    );
  }

  getVisitPurposeList() {
    this.apiCmsManagementService.listVisitPurposes().subscribe(
      res => {
        this.visitPurposeList = res.payload;
      },
      err => {
        this.alertService.error(JSON.stringify(err));
        this.errorMessages['listVisitPurposes'] = err;
      }
    );
  }

  getListOfDoctors() {
    this.apiCmsManagementService.listDoctors().subscribe(
      res => {
        this.doctorList = res.payload;
      },
      err => {
        this.alertService.error(JSON.stringify(err));
        this.errorMessages['listDoctors'] = err;
      }
    );
  }

  setCaseDetails(selectedCase?: Case) {
    if (this.getCaseId()) {
      if (selectedCase) {
        this._caseStatus = selectedCase.status;
        this._caseData = selectedCase;
      } else {
        this.apiCaseManagerService
          .searchCase(this.getCaseId())
          .pipe(share())
          .subscribe(
            pagedData => {
              if (pagedData) {
                const { payload } = pagedData;
                this._caseStatus = payload.status;
                this._caseData = payload;
              }
            },
            err => this.alertService.error(JSON.stringify(err.error.message))
          );
      }
    } else {
      this._caseStatus = '';
      this._caseData = undefined;
    }
  }

  getCaseDetails() {
    return this._caseData;
  }

  updatePatientRegistryList() {
    if (this.clinicId) {
      const startTime = this.utilsService.getPatientRegistryStartTime();
      const endTime = moment()
        .endOf('day')
        .add(2, 'hour')
        .format(DB_FULL_DATE_FORMAT);

      this.apiPatientVisitService
        .patientRegistryListWithStartTime(
          this.getClinicId(),
          startTime,
          endTime
        )
        .pipe(distinctUntilChanged(), debounceTime(INPUT_DELAY))
        .subscribe(
          data => {
            if (data.payload) {
              this.patientRegistry = { ...data.payload };
              this.headerRegistry.next(this.patientRegistry);
            }
          },
          err => this.alertService.error(JSON.stringify(err))
        );
    }
  }

  listTemplates() {
    if (this.permissionsService.getPermission('ROLE_CONSULTATION_TEMPLATE')) {
      this.apiCmsManagementService
        .listTemplates(this.clinicId, this.getUser().context['cms-user-id'])
        .subscribe(
          res => {
            if (res.payload && res.payload) this.setTemplates(res.payload);
            if (res.payload && res.payload) this.setTemplates(res.payload);
          },
          err => this.alertService.error(JSON.stringify(err))
        );
    }
  }

  findClinic(clinicId: string) {
    return this.clinicList.find(element => element.id === clinicId);
  }

  getDoctors() {
    return this.doctorList;
  }

  getActiveDoctors() {
    return this.doctorList.filter(element => element.status === 'ACTIVE');
  }

  findDoctorById(doctorId: string) {
    return this.doctorList.find(element => element.id === doctorId);
  }

  getUser(): User {
    return this.user;
  }

  getUserId(): string {
    return this.user.context['cms-user-id'];
  }

  getUserLabel(): string {
    return this.user ? this.user.userName.slice(0, 1).toUpperCase() : '';
  }

  setClinicId(id) {
    this.clinicId = id;
    this.isClinicSelected.next();
  }

  getClinicId(): string {
    return this.clinicId;
  }

  getClinic(): Clinic {
    return this.clinicList.find(clinic => clinic.id === this.clinicId);
  }

  setClinic(data) {
    this.clinicList = data;
  }

  getPatientVisitRegistryId(): string {
    return this.patientVisitRegistryId;
  }

  getConsultationId(): string {
    return this.consultationId;
  }

  getPlansByCoverageId(medicalCoverageId: string) {
    return this.getPlanByCoverageId(
      this.medicalCoverageListWithExpired,
      medicalCoverageId
    );
  }

  getPlanByCoverageId(medicalCoverages, medicalCoverageId: string) {
    return medicalCoverages.filter(elem => elem.id === medicalCoverageId);
  }

  getPlan(medicalCoverageId: string, planId: string) {
    const coverage = this.getPlansByCoverageId(medicalCoverageId);
    if (coverage.length === 0) {
      return {
        id: '0',
        name: 'CASH',
        coveragePlans: [
          {
            id: '0',
          },
        ],
      };
    }
    const plan = this.getPlanFromCoveragesByPlanId(
      coverage[0].coveragePlans,
      planId
    );
    coverage[0].coveragePlans = plan;
    return coverage[0];
  }

  getMedicalCoverages() {
    return this.medicalCoverageList;
  }

  getMedicalCoverageList(page: number = 0, size: number = 10000) {
    return this.medicalCoverageList.slice(size * page, size * (page + 1));
  }

  getCoveragesByCoverageType(coverageType?: string) {
    return !!coverageType
      ? this.medicalCoverageList.filter(
        coverage => coverage.type === coverageType
      )
      : this.medicalCoverageList.filter(
        coverage =>
          getStr(coverage, 'type').toUpperCase() === 'CORPORATE' ||
          getStr(coverage, 'type').toUpperCase() === 'INSURANCE'
      );
  }

  getMedicalCoverageByPlanId(planId) {
    if (this.medicalCoverageListWithExpired) {
      return this.medicalCoverageListWithExpired.find(element =>
        element.coveragePlans.some(childElement => childElement.id === planId)
      );
    }
    return undefined;
  }

  getCoverageByPlanId(planId) {
    let coverage = null;
    this.medicalCoverageListWithExpired.forEach(element => {
      const _coverage = element.coveragePlans.find(
        childElement => childElement.id === planId
      );

      if (_coverage) {
        coverage = _coverage;
        return;
      }
    });
    return coverage;
  }

  getPlanFromCoveragesByPlanId(plans, planId: string) {
    return plans.filter(elem => elem.id === planId);
  }

  getPlanByPlanId(planId) {
    const coverage = this.getMedicalCoverageByPlanId(planId);
    if (coverage) {
      return coverage.coveragePlans.find(plan => plan.id === planId);
    }

    return undefined;
  }

  getDiscountFromPlan(plan): Discount {
    if (plan.salesDiscount) {
      const discount = new Discount(plan.salesDiscount, plan.name);
      return discount;
    } else {
      return new Discount();
    }
  }

  getClinicList(page: number = 0, size: number = 10000) {
    return this.clinicList.slice(size * page, size * (page + 1));
  }

  getOrderedClinicList(page: number = 0, size: number = 10000) {
    return this.clinicList
      .slice(size * page, size * (page + 1))
      .sort((a, b) => a.clinicCode.localeCompare(b.clinicCode));
  }

  getDoctorList(page: number = 0, size: number = 10000) {
    if (this.doctorList.length === 0) {
      this.getListOfDoctors();
    } else {
      return this.doctorList.slice(size * page, size * (page + 1));
    }
  }

  getPatientIdRefresh() {
    return this.patientIdRefresh.asObservable();
  }

  getPatientVisitIdRefresh() {
    return this.patientVisitIdRefresh.asObservable();
  }

  setPatientIdWithoutRefresh(id: string) {
    this.patientId = id;
  }

  processResponse(res) {
    const coverages = createMedicalCoverageResponse(
      res.payload.INSURANCE,
      res.payload.CORPORATE,
      res.payload.CHAS,
      res.payload.MEDISAVE
    );

    const set = new Set();
    for (const key of Object.keys(res.payload)) {
      res.payload[key].forEach((element: Insurance) => {
        set.add(element.policyHolder.planId);
      });
    }

    const plans = Array.from(set);
    return { coverages, plans };
  }

  setPatientInfoLoaded(payload) {
    this.currentPatientInfo = payload;
    this.currentPatientInfoLoaded.next(this.currentPatientInfo);
  }

  setCurrentMedicalCoverageWithBalance(res) {
    this.currentMedicalCoverageWithBalance = res;
    this.currentMedicalCoverageWithBalanceLoaded.next(
      this.currentMedicalCoverageWithBalance
    );
  }

  setCurrentPatientMedicalAlert(res) {
    this.currentPatientMedicalAlert = res;
    this.currentPatientMedicalAlertLoaded.next(this.currentPatientMedicalAlert);
  }

  reloadPatientMedicalAlert(id: string) {
    this.apiPatientInfoService.listAlert(id).subscribe(
      res => {
        if (res.payload) {
          const alertDetails = <Array<MedicalAlertResponse>>res.payload.details;
          const tempAlertArray = Array<MedicalAlertResponse>();
          alertDetails.forEach(alert => {
            if (alert.alertType !== 'ALLERGY') {
              tempAlertArray.push(alert);
            }
          });
          this.setCurrentPatientMedicalAlert(tempAlertArray);
        }
      },
      err => {
        this.alertService.error(JSON.stringify(err.error.message));
      }
    );
  }

  setVisitStatusRefresh(status: string) {
    this.visitStatusRefresh.next(status);
  }

  setPatientVisitRegistryId(id: string, withRefresh: boolean) {
    this.patientVisitRegistryId = id;

    this.paymentService.resetChargeFormGroup();
    this.paymentService.resetCollectFormGroup();

    if (id) {
      localStorage.setItem('visitId', id);
    } else {
      localStorage.removeItem('visitId');
    }

    if (withRefresh) {
      this.patientVisitIdRefresh.next(id);
    }
  }

  setConsultationId(id: string) {
    this.consultationId = id;
    this.paymentService.resetChargeFormGroup();
    this.paymentService.resetCollectFormGroup();
  }

  getClinicIsPCN() {
    // Please uncomment this when backend is ready with PCN clinics
    return this.clinic && this.clinic.pcnRegistered;
    // return true;
  }

  getNotificationList() {
    return this.notificationList;
  }

  getUnreadNotificationList() {
    return this.unreadNotificationList;
  }

  getIsClinicLoaded(): Observable<any> {
    return this.isClinicLoaded.asObservable();
  }

  getIsClinicLoadedSubject() {
    return this.isClinicLoaded;
  }

  getDoctorId() {
    if (this.userHasDoctorRole()) {
      return this.getUserId();
    } else {
      return null;
    }
  }

  getPatientRegistryList() {
    return this.patientRegistry.patientVisitRegistration;
  }

  getPreRegistrationList(): PreRegistration[] {
    return this.patientRegistry.preRegistration;
  }

  userHasDoctorRole() {
    return this.permissionsService.getPermission('ROLE_DOCTOR');
  }

  userHasAdminRole() {
    return this.permissionsService.getPermission('ROLE_AA_ADMIN');
  }

  resetClinicLoaded() {
    this.isClinicLoaded = new Subject();
  }

  getHeaderRegistry() {
    return this.headerRegistry.asObservable();
  }

  resetHeaderRegistry() {
    this.headerRegistry = new Subject();
  }

  getIsStoreReady(): Observable<any> {
    return this.isStoreReady.asObservable();
  }

  resetIsStoreReady() {
    this.isStoreReady = new BehaviorSubject(this.storeStatus);
  }

  updateNotificationList(fromNotifiClick?: boolean) {
    const clinicId = this.getClinicId();
    if (this.authService.isAuthenticated() && !!clinicId) {
      this.apiPatientInfoService.listNotifications(clinicId).subscribe(res => {
        this.notificationList = res.payload.filter(notifi =>
          this.isRelatedDoctor(notifi)
        );

        this.unreadNotificationList = this.notificationList.filter(
          notification => !notification.read
        );

        if (this.startShowingToast && !fromNotifiClick) {
          this.showToastNotifications(this.unreadNotificationList);
        }

        if (this.startShowingToast && !fromNotifiClick) {
          this.showToastNotifications(this.unreadNotificationList);
        }

        // This sets and resets unread notification count from browser title
        const filteredUnreadNotificationList = this.unreadNotificationList.filter(item => (item.type === 'REQUESTING_NEXT_AVAILABLE_DOCTOR' || item.type === 'MOBILE_CONSULTATION_QUEUE'));
        this.titleService.setTitle(`${filteredUnreadNotificationList.length ? `(${filteredUnreadNotificationList.length})` : ''} CMS`);
      });
    } else {
      this.unsubscribeNotificationPolling();
    }
  }

  filterNotificationForDoctor(notification: any) {
    if (!!notification.clinicId && this.userHasDoctorRole()) {
      if (this.userHasAdminRole()) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }

  showToastNotifications(unreadNotifications: any[]) {
    const highPriorityUnreadNotifications = unreadNotifications.filter(
      notification =>
        notification.priority.toLowerCase() === 'high' &&
        this.timeElapsedIsGreaterThan(5, notification.createdDateTime) &&
        this.filterNotificationForDoctor(notification)
    );

    highPriorityUnreadNotifications.forEach(notiftn => {
      const activeToast = this.toastr.info(notiftn.message, undefined, {
        messageClass: 'toastr-text-black'
      });

      activeToast.onTap
        .pipe(
          map(_ => notiftn),
          take(1)
        )
        .subscribe(notiftn => this.toasterClickedHandler(notiftn));
    });

    highPriorityUnreadNotifications.forEach(notiftn => {
      if (notiftn.type === 'REQUESTING_NEXT_AVAILABLE_DOCTOR' || notiftn.type === 'MOBILE_CONSULTATION_QUEUE') {

        const isNotificationAlreadyDisplayed = this.browserNotifications.filter(item => JSON.stringify(item.notification) === JSON.stringify(notiftn));

        if (isNotificationAlreadyDisplayed.length === 0) {
          this.browserNotifications.push({
            notification: notiftn,
            isDisplayed: true,
          });

          this.onNotify(notiftn);
        }
      }
    });
  }

  private onNotify(note: any): void {
    if (!('Notification' in window)) {
      console.log('Web Notification not supported');
      return;
    }

    const toasterClickedHandler = this.toasterClickedHandler.bind(this);

    Notification.requestPermission(function (permission) {
      var browserNotification = new Notification(
        'Important!',
        {
          body: note.message,
          icon: 'assets/notification/icon-notification.png',
          dir: 'auto',
          requireInteraction: true,
        }
      );

      const audio = new Audio('/assets/sound/notification.mp3');
      audio.play();

      browserNotification.onclick = (event) => {
        window.focus();
        toasterClickedHandler(note);
        browserNotification.close();
      }

      browserNotification.onclose = (event) => {
        // NOTE: This is to show the noficiation agian if the user closes it accidentally or if the user did not interact with the notification. 
        // setBrowserNotificationVisibility(false);
      }
    });
  }

  toasterClickedHandler(notification: any) {
    if (notification.type === 'REQUESTING_NEXT_AVAILABLE_DOCTOR' || notification.type === 'MOBILE_CONSULTATION_QUEUE') {
      this.openPopUp();
    } else {
      if (!notification.read) {
        this.apiPatientInfoService
          .markNotificationAsRead(notification.id)
          .subscribe(
            res => {
              notification.read = true;
              this.getNotificationList();
            },
            err => console.error(JSON.stringify(err))
          );
      }
    }
  }

  timeElapsedIsGreaterThan(minutes: number, createdDateTime: string): boolean {
    const dbDate = moment(createdDateTime, DB_FULL_DATE_FORMAT).toDate();

    const currentDate = new Date();
    return moment(currentDate).diff(dbDate, 'minutes') < minutes;
  }

  updateUnreadNotificationList() {
    this.unreadNotificationList = this.notificationList.filter(
      notification => !notification.read
    );
  }

  openPopUp() {
    this.openNotificationPanel.next(true);
  }

  getOpenNotificationPanel() {
    return this.openNotificationPanel.asObservable();
  }

  logoutClearUp() {
    this.unsubscribeNotificationPolling();
    this.unsubscribeRegistryPolling();
    this.clinicCode = '';
    this.clinicId = '';
    this.appStateReset();
    this.router.navigate(['login']);
    this.storeSuccessCount = 0;
  }

  getTemplates() {
    return this.templates;
  }

  getTemplatesByTemplateCategory(templateCategory) {
    return this.templates.filter(
      item =>
        item.templateCategory === templateCategory &&
        item.disabledUserIds &&
        !item.disabledUserIds.includes(this.getDoctorId())
    );
  }

  setTemplates(templates) {
    this.templates = templates;
  }

  getInstructions(): Array<Instruction> {
    return this.akitaInstructionQuery.getActiveInstruction();
  }

  getAllInstructions(): Array<Instruction> {
    return this.akitaInstructionQuery.getAllInstruction();
  }

  getAllInstructionByCode(code: string) {
    return this.getAllInstructions().find(
      instruction => instruction.code === code
    );
  }

  getInstructionByCode(code: string) {
    return this.instructions.find(instruction => instruction.code === code);
  }

  getDosageInstructions(): Array<DosageInstruction> {
    return this.akitaDosageInstructionQuery.getActiveDosageInstruction();
  }

  getAllDosageInstructions(): Array<DosageInstruction> {
    return this.akitaDosageInstructionQuery.getAllDosageInstruction();
  }

  getDosageInstructionByCode(code: string) {
    return this.akitaDosageInstructionQuery.getDosageInstruction(code);
  }

  getCaseId(): string {
    return this._caseId;
  }
  setCaseId(
    value: string,
    getAttachedPolicyDetail: boolean = false,
    refreshCase?: boolean,
    selectedCase?: Case
  ) {
    const lastCaseId = this._caseId;
    this._caseId = value;
    if ((value && lastCaseId !== value) || refreshCase) {
      this.setCaseDetails(selectedCase);
    } else {
    }
  }

  getVisitStatus(): string {
    return this.visitStatus;
  }

  getVisitStartTime(): string {
    return this.visitStartTime;
  }

  setVisitStatus(value: string) {
    this.visitStatus = value;
  }

  setVisitStartTime(value: string) {
    this.visitStartTime = value;
  }

  getVisitStatusRefresh() {
    return this.visitStatusRefresh.asObservable();
  }

  getQueueNumber(): string {
    return this.queueNumber;
  }
  setQueueNumber(value: string) {
    this.queueNumber = value;
  }

  getCurrentPatientInfo() {
    return this.currentPatientInfo;
  }

  getCurrentPatientInfoLoaded() {
    return this.currentPatientInfoLoaded.asObservable();
  }

  getCurrentPatientMedicalAlertLoaded() {
    return this.currentPatientMedicalAlertLoaded.asObservable();
  }

  getAttachedToCaseMedicalCoverageLoadedSubject() {
    return this.attachedToCaseMedicalCoverageLoaded;
  }

  getCurrentPatientVisitHistoryListLoaded() {
    return this.currentPatientVisitHistoryListLoaded.asObservable();
  }

  getMedicalCoverageWithBalanceLoaded() {
    return this.currentMedicalCoverageWithBalanceLoaded.asObservable();
  }

  getItemById(id) {
    return this.akitaChargeItemQuery.getChargeItem(id);
  }

  hasClinicFeatures(feature: string) {
    const hasFeature =
      this.clinic.clinicFeatures.findIndex(feat => feat === feature) > -1
        ? true
        : false;
    return hasFeature;
  }

  getSupplierList(): Array<InventorySupplier> {
    return this.supplierList;
  }

  getSupplierById(id: string): InventorySupplier {
    return this.supplierListMap.get(id);
  }

  getStockAdjustmentReasonList(): Array<StockAdjustmentReason> {
    return this.stockAdjustmentReason;
  }

  getReturnRequestReasonList(): Array<OrderReturnRequestReason> {
    return this.returnRequestReason;
  }

  getPurchaseRequestJustificationList(): Array<PurchaseRequestJustification> {
    return this.purchaseRequestJustification;
  }

  getInventoryItemClinicDetailList(): Array<InventoryItemClinicDetail> {
    return this.inventoryItemClinicDetail;
  }

  getInventoryItemClinicDetailByItemId(
    itemId: string
  ): InventoryItemClinicDetail {
    return this.inventoryItemClinicDetail.find(
      detail => detail.itemRefId === itemId
    );
  }

  getDosageInstruction(dispatchItemEntity) {
    if (
      !dispatchItemEntity.derivedDosageInstruction ||
      dispatchItemEntity.derivedDosageInstruction.length === 0
    ) {
      if (
        dispatchItemEntity.dosageInstruction &&
        dispatchItemEntity.dosageInstruction.length > 0
      ) {
        const dosageInstructionObj = this.akitaDosageInstructionQuery.getDosageInstruction(
          dispatchItemEntity.dosageInstruction
        );
        if (
          dosageInstructionObj &&
          dosageInstructionObj.instruct &&
          dosageInstructionObj.instruct.length > 0 &&
          dispatchItemEntity.dosageUom &&
          dispatchItemEntity.dosageUom.length > 0
        ) {
          return `${dosageInstructionObj.instruct} ${dispatchItemEntity.dosage} ${dispatchItemEntity.dosageUom}`;
        }
        return dosageInstructionObj &&
          dosageInstructionObj.instruct &&
          dosageInstructionObj.instruct.length > 0
          ? dosageInstructionObj.instruct
          : '';
      }
      return '';
    }
    return dispatchItemEntity.derivedDosageInstruction;
  }

  getInstruction(dispatchItemEntity) {
    if (
      !dispatchItemEntity.derivedInstruction ||
      dispatchItemEntity.derivedInstruction.length === 0
    ) {
      const instructionObj = this.akitaInstructionQuery.getInstruction(
        dispatchItemEntity.instruction
      );
      return instructionObj &&
        instructionObj.instruct &&
        instructionObj.instruct.length > 0
        ? instructionObj.instruct
        : '';
    }
    return dispatchItemEntity.derivedInstruction;
  }

  getQuantityUom(dispatchItemEntity) {
    if (
      !dispatchItemEntity.salesUom ||
      dispatchItemEntity.salesUom.length === 0
    ) {
      return dispatchItemEntity.dosageUom;
    }
    return dispatchItemEntity.salesUom;
  }

  appStateReset() {
    this.authService.appStateReset();
  }

  setConsultEndTime(endTime: string) {
    this.consultEndTimeSource.next(endTime);
  }

  setConsultStartTime(startTime: string) {
    this.consultStartTimeSource.next(startTime);
  }

  setIcdCode(icdCode) {
    this.icdType.next(icdCode);
  }

  setSelectedDrugCode(drugTypeSelected: string) {
    this.drugTypeSelected.next(drugTypeSelected);
  }

  isRelatedDoctor(notification) {
    if (notification.type === 'DEFAULT') {
      return true;
    }
    const user = this.getUser();
    if (
      notification.username === user.userName &&
      (notification.type === 'PATIENT_WAITING_FOR_VIDEO_CONSULT' || notification.type === 'REQUESTING_NEXT_AVAILABLE_DOCTOR' || notification.type === 'MOBILE_CONSULTATION_QUEUE')
    ) {
      return true;
    }
    return false;
  }

  setRefundAllowed(isAllowed) {
    this.isRefundAllowed = isAllowed;
  }

  getIsRefundAllowed() {
    return this.isRefundAllowed;
  }

  setBillAdjAllowed(isAllowed) {
    this.isBillAdjAllowed = isAllowed;
  }

  getIsBillAdjAllowed() {
    return this.isBillAdjAllowed;
  }

  getReferralSpecialitiesUpdated(): Observable<HttpResponseBody> {
    return this.referralSpecialitiesSub.asObservable();
  }

  getVaccinationExemptionConditions() {
    this.apiCmsManagementService.listVaccinationExemptionConditions().subscribe(
      res => {
        if (res.payload) {
          for (let [key, value] of Object.entries(res.payload[0])) {
            this.exemptionConditions.push({ code: key, value: value })
          }
        }
      },
      err => {
        this.alertService.error(err.message);
      }
    )
  }

  getMhcpVaccineMapping() {
    this.apiCmsManagementService.listMhcpVaccineMapping().subscribe(
      res => {
        if (res.payload) {
          this.mhcpVaccineMapping = res.payload.values;
        }
      },
      err => {
        this.alertService.error(err.message);
      }
    )
  }

  getHsgVaccineConditions() {
    this.apiCmsManagementService.listHsgVaccineConditions().subscribe(
      res => {
        if (res.payload) {
          this.hsgVaccineConditions = res.payload.values;
        }
      },
      err => {
        this.alertService.error(err.message);
      }
    )
  }

  getHsgAssessmentVitalMaster() {
    this.apiCmsManagementService.listHsgAssessmentVitalMaster().subscribe(
      res => {
        this.hsgAssessmentVitalMaster = res.payload;
      },
      err => {
        this.alertService.error(err.message);
      }
    )
  }

  getHsgConditions() {
    this.apiCmsManagementService.listHsgConditions().subscribe(
      res => {
        this.hsgConditions = res.payload;
      },
      err => {
        this.alertService.error(err.message);
      }
    )
  }

  getSelectedPatientDiseases() {
    return this.selectedPatientDiseases.asObservable();
  }

  setSelectedPatientDiseases(diseasesSelected) {
    this.selectedPatientDiseases.next(diseasesSelected);
  }
}
