import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
  name: 'displayClinicName',
})
export class DisplayClinicNamePipe implements PipeTransform {
  constructor(private storeService: StoreService) {}

  transform(value: any, args?: any): any {
    const currentClinic = this.storeService
      .getClinicList()
      .find(clinic => clinic.id === value);
    return currentClinic.clinicCode;
  }
}
