import { Injectable } from '@angular/core';

import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { AkitaVisitPurposeStore } from './akita-visit-purpose.store';
import { VisitPurpose } from '../../objects/state/VisitPurpose';

@Injectable({ providedIn: 'root' })
export class AkitaVisitPurposeQuery extends QueryEntity<VisitPurpose> {
  visitPurposeList$ = this.selectAll();

  constructor(protected store: AkitaVisitPurposeStore) {
    super(store);
  }

  isPurposeTypeDelivery(visitPurpose: string): boolean {
    if (!visitPurpose) return false;
    return !!this.getAll().find(
      (entity: VisitPurpose) =>
        entity.name.toLowerCase() === visitPurpose.toLowerCase() &&
        entity.delivery
    );
  }

  isPurposeTypeMobileConsultation(visitPurpose: string): boolean {
    if (!visitPurpose) return false;
    const all = this.getAll();
    return !!this.getAll().find(
      (entity: VisitPurpose) =>
        entity.name.toLowerCase() === visitPurpose.toLowerCase() &&
        entity.mobileConsultation
    );
  }

  selectPurposeTypeDelivery(visitPurpose: string) {
    return this.selectAll().pipe(
      map(visitPurposes =>
        visitPurposes.find(
          (eachVP: VisitPurpose) =>
            eachVP.name.toLowerCase() === visitPurpose.toLocaleLowerCase() &&
            eachVP.delivery
        )
      )
    );
  }
}
