import { Injectable } from '@angular/core';

import {
  EntityStore,
  StoreConfig,
  EntityState,
  ActiveState,
} from '@datorama/akita';

import { Case } from '../../objects/Case';

export interface AkitaCaseDetailsState extends EntityState<Case> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'caseDetails', idKey: 'caseId', resettable: true })
export class AkitaCaseDetailsStore extends EntityStore<
  AkitaCaseDetailsState,
  Case
> {
  constructor() {
    super();
  }
}
