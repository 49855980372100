import { Pipe, PipeTransform } from '@angular/core';
import { StockItem } from '../objects/StockItem';

@Pipe({ name: 'stockItemInvalid' })
export class StockItemInvalidPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return (!value || value.length === 0 || value === '31-12-0001') ? '' : value;
  }

}
