
export interface KycData {
  kycStatus: string;
  verifiedDate: string;
}
export function createKycData(kycData) {
  const {
    kycStatus = "",

    verifiedDate = '',

  } = kycData;

  return {
    kycStatus,
    verifiedDate,
  } as KycData;
}

export interface Consultation {
  clinicId: string;
  clinicNotes: string;
  consultationEndTime: string;
  consultationId: string;
  consultationNotes: string;
  consultationStartTime: string;
  doctorId: string;
  drawingBinary: string;
  lockStatus: string;
  patientId: string;
}

export function createConsultation(consultation) {
  const {
    clinicId = '',
    clinicNotes = '',
    consultationEndTime = '',
    consultationId = '',
    consultationNotes = '',
    consultationStartTime = '',
    doctorId = '',
    drawingBinary = '',
    lockStatus = '',
    patientId = '',
  } = consultation;

  return {
    clinicId,
    clinicNotes,
    consultationEndTime,
    consultationId,
    consultationNotes,
    consultationStartTime,
    doctorId,
    drawingBinary,
    lockStatus,
    patientId,
  } as Consultation;
}

export interface ConsultationFollowup {
  clinicId: string;
  doctorId: string;
  followupDate: string;
  followupId: string;
  patientId: string;
  patientVisitId: string;
  remarks: string;
  reminderStatus: ReminderStatus;
}

export function createConsultationFollowup(consultationFollowup) {
  const {
    clinicId = '',
    doctorId = '',
    followupDate = '',
    followupId = '',
    patientId = '',
    patientVisitId = '',
    remarks = '',
    reminderStatus = createReminderStatus({}),
  } = consultationFollowup;

  return {
    clinicId,
    doctorId,
    followupDate,
    followupId,
    patientId,
    patientVisitId,
    remarks,
    reminderStatus,
  } as ConsultationFollowup;
}

export interface ReminderStatus {
  externalReferenceNumber: string;
  remark: string;
  reminderSent: boolean;
  reminderSentTime: string;
  sentSuccessfully: boolean;
}

export function createReminderStatus(reminderStatus) {
  const {
    externalReferenceNumber = '',
    remark = '',
    reminderSent = false,
    reminderSentTime = '',
    sentSuccessfully = false,
  } = reminderStatus;

  return {
    externalReferenceNumber,
    remark,
    reminderSent,
    reminderSentTime,
    sentSuccessfully,
  } as ReminderStatus;
}
