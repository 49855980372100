import { AkitaPatientAppQuery } from './states/akita-patient/akita-patient-app.query';
import { FormArray, FormBuilder, AbstractControl } from '@angular/forms';
import { PatientService } from './patient.service';
import { saveAs } from 'file-saver';
import { AlertService } from './alert.service';
import {
  INPUT_DELAY,
  DISPLAY_DATE_FORMAT,
  DB_FULL_DATE_FORMAT,
} from './../constants/app.constants';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
  map,
} from 'rxjs/operators';
import { StoreService } from './store.service';
import { ApiPatientVisitService } from './api-patient-visit.service';
import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentManagementService implements OnDestroy {
  bsModalRef: BsModalRef;

  documentReady: Subject<any>;

  private componentDestroyed: Subject<void> = new Subject();
  patientDocuments;
  visitDocuments;

  constructor(
    private fb: FormBuilder,
    private store: StoreService,
    private alertService: AlertService,
    private patientService: PatientService,
    private apiPatientVisitService: ApiPatientVisitService,
    private modalService: BsModalService,
    private akitaPatientAppQuery: AkitaPatientAppQuery
  ) {
    this.documentReady = new Subject();
  }

  getDocumentReady() {
    return this.documentReady.asObservable();
  }

  getDocumentType() {
    return this.apiPatientVisitService.getDocumentType().pipe(
      map(({ payload }) => {
        payload.unshift('All');
  
        return payload;
      })
    );
  }

  getAllDocumentListByPatientId(patientId, start, end) {
    const startDate = moment(start).format(DISPLAY_DATE_FORMAT);
    const endDate = moment(end).format(DISPLAY_DATE_FORMAT);
    return this.apiPatientVisitService
      .listAllFiles(patientId, startDate, endDate)
      .pipe(
        debounceTime(INPUT_DELAY),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .pipe(map(res => this.processDocumentResponse(res)));
  }

  // getAllDocumentList(start, end, formGroup: AbstractControl) {
  //   const startDate = moment(start).format(DISPLAY_DATE_FORMAT);
  //   const endDate = moment(end).format(DISPLAY_DATE_FORMAT);
  //   this.apiPatientVisitService
  //     .listAllFiles(this.akitaPatientAppQuery.getId(), startDate, endDate)
  //     .pipe(
  //       debounceTime(INPUT_DELAY),
  //       distinctUntilChanged(),
  //       takeUntil(this.componentDestroyed)
  //     )
  //     .subscribe(
  //       res => {
  //         this.setPatientDocuments(this.processDocumentResponse(res));
  //         this.updateDocumentList(formGroup);
  //       },
  //       err => this.alertService.error(JSON.stringify(err))
  //     );
  // }

  // setPatientDocuments(documents) {
  //   this.patientDocuments = documents;
  // }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.patientDocuments = [];
    this.visitDocuments = [];
  }

  processDocumentResponse(res) {
    const { payload } = res;

    const flattenPayload = payload.reduce((documents, documentGroup) => {
      if (documentGroup.fileMetadataEntities) {
        documentGroup.fileMetadataEntities.forEach(file => {
          file.localDate = documentGroup.localDate;
          //file.listType = !!file.listType ? file.listType : documentGroup.listType;
          file.patientVisitId = file.patientVisitId || '';
        });
        documents = documents.concat(documentGroup.fileMetadataEntities);
      }
      return documents;
    }, []);
    const allDocuments = flattenPayload.reduce(
      (allDocuments, documents) => allDocuments.concat(documents),
      []
    );
    allDocuments.sort((a, b) => this.compareDate(a, b));
    return allDocuments;
  }

  compareDate(a, b) {
    const momentA = moment(a.uploadDate, DB_FULL_DATE_FORMAT);
    const momentB = moment(b.uploadDate, DB_FULL_DATE_FORMAT);

    if (momentA && momentB) {
      if (moment(a.uploadDate, DB_FULL_DATE_FORMAT).isBefore(b.uploadDate)) {
        return -1;
      } else {
        return 1;
      }
    } else {
      return -1;
    }
  }

  onDeleteDocument(selectedFileArray) {
    if (this.confirmDelete()) {
      let formattedFileArray = this.formatFileArrayForDeletionApi(
        selectedFileArray
      );

      this.apiPatientVisitService.deleteDocuments(formattedFileArray).subscribe(
        res => {
        

          this.patientService.callDocumentRefresh();
        },
        err => {
          this.alertService.error(JSON.stringify(err));
          console.error('Error updating Patient Info');
        }
      );
    }
  }

  confirmDelete() {
    const isDelete = confirm('Delete selected document/s?');
    return isDelete;
  }
  formatFileArrayForDeletionApi(selectedFileArray: Array<any>): any[] {
    return selectedFileArray.map(selectedFile =>
      this.formatObjectForPatientId(selectedFile)
    );
  }
  // formatFileArrayForDeletionApi2(selectedFileArray: Array<any>): any[] {

  //   let formattedArray = [];

  //   selectedFileArray.forEach((file, index) => {
  //     if (file.listType === 'PATIENT') {
  //       let indexOfFoundObjectInArray = this.findIndexOfExistingObjectByPatientId(
  //         formattedArray,
  //         file
  //       );
  //       if (indexOfFoundObjectInArray > -1) {
  //         formattedArray[indexOfFoundObjectInArray].fileIds.push(file.fileId);
  //       } else {
  //         formattedArray.push(this.formatObjectForPatientId(file));
  //       }
  //     } else {
  //       let indexOfFoundObjectInArray = this.findIndexOfExistingObjectByVisitId(
  //         formattedArray,
  //         file
  //       );
  //       if (indexOfFoundObjectInArray > -1) {
  //         formattedArray[indexOfFoundObjectInArray].fileIds.push(file.fileId);
  //       } else {
  //         formattedArray.push(this.formatObjectForVisitId(file));
  //       }
  //     }
  //   });
  //   return formattedArray;
  // }

  formatObjectForPatientId(file) {
    return {
      patientId: this.akitaPatientAppQuery.getId(),
      clinicId: file.clinicId,
      patientVisitId: file.patientVisitId,
      fileId: file.fileId,
    };
  }

  formatObjectForVisitId(file) {
    return {
      patientVisitId:
        file.patientVisitId || this.store.getPatientVisitRegistryId(),
      fileIds: [file.fileId],
    };
  }

  findIndexOfExistingObjectByPatientId(formattedArray: any[], file: any) {
    return formattedArray.findIndex(x => {
      return x.patientId === file.patientId && x.clinicId === file.clinicId;
    });
  }

  findIndexOfExistingObjectByVisitId(formattedArray: any[], file: any) {
    return formattedArray.findIndex(x => {
      return x.patientVisitId === file.patientVisitId;
    });
  }

  onDownloadDocument(file) {
    let config =
      file.listType.toUpperCase() === 'PATIENT' ? 'patient' : 'visit';
    let idByType =
      file.listType.toUpperCase() === 'PATIENT'
        ? this.akitaPatientAppQuery.getId()
        : file.patientVisitId
        ? file.patientVisitId
        : this.store.getPatientVisitRegistryId();
    let fileId = file.fileId;

    this.apiPatientVisitService
      .downloadDocument(config, idByType, fileId)
      .pipe(
        // debounceTime(INPUT_DELAY),
        distinctUntilChanged()
      )
      .subscribe(
        res => {
          saveAs(res, file.document);
        },
        err => this.alertService.error(JSON.stringify(err))
      );
  }

  downloadDocumentForPreview(file) {
    // the listType only PATIENT and VISIT
 
    const isVisitFile = file.patientVisitId && file.patientVisitId !== '';

    let config = isVisitFile ? 'visit' : 'patient';
    let idByType = isVisitFile
      ? file.patientVisitId
        ? file.patientVisitId
        : this.store.getPatientVisitRegistryId()
      : this.akitaPatientAppQuery.getId();

    let fileId = file.fileId;

    if (!isVisitFile) {
      this.apiPatientVisitService
        .downloadNonVisitDocument(idByType, fileId)
        .subscribe(
          res => {
            if (!(/(gif|jpe?g|tiff?|png|webp|bmp|pdf)$/i).test(file.type.toLowerCase())) {
              saveAs(res, file.document);
            } else {
              const data = { payload: res, fileType: file.type };
              this.documentReady.next(data);
            }
            // saveAs(res, file.document);
            // this.openModalWithComponent(res);
          },
          err => this.alertService.error(JSON.stringify(err))
        );
    } else {
      this.apiPatientVisitService
        .downloadDocument(config, idByType, fileId)
        .subscribe(
          res => {
            if (!(/(gif|jpe?g|tiff?|png|webp|bmp|pdf)$/i).test(file.type.toLowerCase())) {
              saveAs(res, file.document);
            } else {
              const data = { payload: res, fileType: file.type };
              this.documentReady.next(data);
            }
            // saveAs(res, file.document);
            // this.openModalWithComponent(res);
          },
          err => this.alertService.error(JSON.stringify(err))
        );
    }
  }

  downloadDocumentToLocal(file) {
    // the listType only PATIENT and VISIT
 

    const isVisitFile = file.patientVisitId && file.patientVisitId !== '';

    let config = isVisitFile ? 'visit' : 'patient';
    let idByType = isVisitFile
      ? file.patientVisitId
        ? file.patientVisitId
        : this.store.getPatientVisitRegistryId()
      : this.akitaPatientAppQuery.getId();

    let fileId = file.fileId;

    if (!isVisitFile) {
      this.apiPatientVisitService
        .downloadNonVisitDocument(idByType, fileId)
        .subscribe(
          res => {
     
            saveAs(res, file.fileName);
          },
          err => this.alertService.error(JSON.stringify(err))
        );
    } else {
      this.apiPatientVisitService
        .downloadDocument(config, idByType, fileId)
        .subscribe(
          res => {
      
            saveAs(res, file.fileName);
          },
          err => this.alertService.error(JSON.stringify(err))
        );
    }
  }

  // updateDocumentList(formGroup: AbstractControl) {
  //   const toLowerCase = str => (str || '').toLowerCase();

  //   const filteredDocuments = this.patientDocuments
  //     .filter(document => {
  //       const name = toLowerCase(document.name);
  //       const description = toLowerCase(document.description);
  //       const fileName = toLowerCase(document.fileName);
  //       const filter = toLowerCase(formGroup.get('filter').value);
  //       return (
  //         name.includes(filter) ||
  //         description.includes(filter) ||
  //         fileName.includes(filter)
  //       );
  //     })
  //     .sort((a, b) => {
  //       const momentA = moment(
  //         a.uploadDate ? a.uploadDate : new Date(),
  //         DB_FULL_DATE_FORMAT
  //       );
  //       const momentB = moment(
  //         b.uploadDate ? b.uploadDate : new Date(),
  //         DB_FULL_DATE_FORMAT
  //       );
  //       return momentB.diff(momentA);
  //     });

  //   const documentsArray = formGroup.get('documentsArray') as FormArray;
  //   while (documentsArray.length) {
  //     documentsArray.removeAt(0);
  //   }

  //   filteredDocuments.forEach(document => {
  //     documentsArray.push(
  //       this.fb.group({
  //         name: document.name || '',
  //         date: document.uploadDate
  //           ? moment(document.uploadDate, DB_FULL_DATE_FORMAT).format(
  //             DISPLAY_DATE_FORMAT
  //           )
  //           : '-',
  //         document: document.fileName,
  //         description: document.description,
  //         type: document.type,
  //         size: document.size,
  //         listType: document.listType || '',
  //         patientVisitId: document.patientVisitId || '',
  //         isSelected: false,

  //         fileId: document.fileId,
  //         clinicId: document.clinicId,
  //       })
  //     );
  //   });
  // }
}
