<div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-1 ">
        <span class="warning-icon" [ngClass]="iconColor" *ngIf="!isSuccessIcon">
          <i class="icon-attention" aria-hidden="true"></i>
        </span>
        <span class="warning-icon" [ngClass]="iconColor" *ngIf="isSuccessIcon">
          <i class="fa fa-check" aria-hidden="true"></i>
        </span>
      </div>
      <div class="col-11 d-flex align-items-center">
        <div *ngIf="messages.length">
          <div class="mb-0" *ngFor="let msg of messages" [innerHTML]="msg"></div>
        </div>
        <!-- <ng-template #defaultTemplate></ng-template> -->

        <!-- <ng-container *ngTemplateOutlet="warningTemplate ? warningTemplate : defaultTemplate"></ng-container> -->
      </div>
    </div>
    <div class="row" *ngIf="adrmessages.length">
      <div class="col-1 ">
        <span class="warning-icon" [ngClass]="iconColor" *ngIf="!isSuccessIcon">
          <i class="icon-attention" aria-hidden="true"></i>
        </span>
        <span class="warning-icon" [ngClass]="iconColor" *ngIf="isSuccessIcon">
          <i class="fa fa-check" aria-hidden="true"></i>
        </span>
      </div>
      <div class="col-11 d-flex align-items-center">
        <br/>
        <div *ngIf="adrmessages.length">
          <div class="mb-0" *ngFor="let msg of adrmessages" [innerHTML]="msg"></div>
        </div>
        <!-- <ng-template #defaultTemplate></ng-template> -->

        <!-- <ng-container *ngTemplateOutlet="warningTemplate ? warningTemplate : defaultTemplate"></ng-container> -->
      </div>
    </div>
  </div>
  <div class="modal-footer p-2">

    <button type="button" class="btn btn-brand-primary"  (click)="hide()">OK</button>
  </div>
  </div>
