import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import Konva from 'konva';

import { KonvaObject } from '../objects/KonvaObject';

@Injectable({
  providedIn: 'root',
})
export class KonvaManagementService {
  currentComponent;

  private compressedDrawingData = new Subject<string>();
  fileReader = new FileReader();

  dragoverFunc;
  dropFunc;

  constructor() {}

  getCurrentComponent() {
    return this.currentComponent;
  }

  setCurrentComponent(component) {
    this.currentComponent = component;
  }

  stage(width, height, container: string) {
    const konvaStage = new Konva.Stage({
      container: container,
      width: width,
      height: height,
    });

    return konvaStage;
  }

  setCompressedDrawingData(data) {
    this.compressedDrawingData.next(data);
  }

  getCompressedDrawingData() {
    return this.compressedDrawingData.asObservable();
  }

  addDnDListener(stage: Konva.Stage, layer: Konva.Layer) {
    const component = this;
    const container = stage.container();

    container.addEventListener(
      'dragover',
      (this.dragoverFunc = function(e) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
      })
    );

    container.addEventListener(
      'drop',
      (this.dropFunc = function(e) {
        e.preventDefault();
        stage.setPointersPositions(e);

        const imageFile = e.dataTransfer.items[0].getAsFile();
        component.fileReader.onload = function(f: any) {
          const image = new Image();
          image.src = f.target.result;

          const konvaImage = component.image(image, stage, layer);
          component.currentComponent.konvaShape.push(konvaImage);
          component.currentComponent.konvaTransformerArray.push(
            konvaImage.transformer
          );

          component.currentComponent.clearSelection();

          if (
            component.currentComponent.konvaShapeHistory &&
            component.currentComponent.konvaShapeHistory.length > 0
          )
            component.currentComponent.konvaShapeHistory.splice(
              0,
              component.currentComponent.konvaShapeHistory.length
            );
        };
        component.fileReader.readAsDataURL(imageFile);
      })
    );
  }

  layer(stage: Konva.Stage, layer?: Konva.Layer) {
    const konvaLayer = layer ? layer : new Konva.Layer();
    if (!layer) stage.add(konvaLayer);
    return konvaLayer;
  }

  line(pos, stroke, color, mode: string = 'brush') {
    const line = new Konva.Line({
      stroke: `#${color}`,
      strokeWidth: stroke,
      globalCompositeOperation:
        mode === 'brush' ? 'source-over' : 'destination-out',
      points: [pos.x, pos.y],
      draggable: false,
      listening: false,
    });
    line.transformsEnabled('none');

    return line;
  }

  text(
    option,
    stage: Konva.Stage,
    layer: Konva.Layer,
    hideBoundingBox?: boolean
  ) {
    layer
      .getChildren(node => node.className === 'Transformer')
      .toArray()
      .forEach((tr: Konva.Transformer) => tr.detach());

    const { offsetHeight, scrollTop } = stage.container();
    let konvaText;
    if (option instanceof Array) {
      konvaText = new Konva.Text({
        text: 'Type text here',
        x: stage.getPosition().x + Math.round(stage.getSize().width / 2),
        y: stage.getPosition().y + scrollTop + Math.round(offsetHeight / 2),
        fill: `#${option[0]}`,
        fontSize: option[1] ? option[1] : 14,
        width: 100,
        draggable: true,
      });
    } else if (option instanceof Konva.Text) konvaText = option;
    else konvaText = option.text;

    layer.add(konvaText);
    const konvaObject = new KonvaObject(
      this.currentComponent,
      konvaText,
      layer,
      stage,
      hideBoundingBox
    );

    return konvaObject;
  }

  image(option: any, stage: Konva.Stage, layer: Konva.Layer) {
    let konvaImage;

    if (option instanceof Image) {
      konvaImage = new Konva.Image({
        image: option,
        x: stage.getPosition().x + Math.round(stage.getSize().width / 2),
        y: stage.getPosition().y + Math.round(stage.getSize().height / 2),
        position: stage.getPointerPosition(),
        draggable: true,
      });
    } else if (option instanceof Konva.Image) konvaImage = option;
    else konvaImage = option.image;
    layer.add(konvaImage);

    const konvaObject = new KonvaObject(
      this.currentComponent,
      konvaImage,
      layer,
      stage
    );
    return konvaObject;
  }
}
