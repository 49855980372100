import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceBrPipe'
})
export class ReplaceBrPipe implements PipeTransform {

  transform(value: string): string {
    // replace \\.br\\ with <br/>>
    return value.replace(/\\.br\\/g, "<br/>");
  }
}