import { CASH } from '../constants/app.constants';
import { UtilsService } from '../services/utils.service';

export class Charge {
  invoiceNumber?: string;
  invoiceType?: string;
  paymentInfos?: any[];
  invoiceTime?: string;
  payableAmount?: number;
  paidAmount?: number;
  taxAmount?: number;
  cashAdjustmentRounding?: number;
  planId?: string;
  planName?: string;
  invoiceState?: string;
  discount?: Discount;
  visitAmount?: number;

  constructor(charge?: Charge) {
    this.invoiceNumber = charge? charge.invoiceNumber  : '';
    this.invoiceType = charge? charge.invoiceType : '';
    this.paymentInfos = charge? charge.paymentInfos : [];
    this.invoiceTime = charge? charge.invoiceTime :'';
    this.payableAmount = charge? charge.payableAmount : 0;
    this.paidAmount = charge? charge.paidAmount : 0;
    this.taxAmount =charge?  charge.taxAmount : 0;
    this.cashAdjustmentRounding = charge? charge.cashAdjustmentRounding : 0;
    this.planId = charge? charge.planId : '';
    this.planName = charge? charge.planName : '';
    this.invoiceState = charge? charge.invoiceState : '';
    this.discount = charge? charge.discount
      ? new Discount(charge.discount, this.planName)
      : new Discount(): new Discount();
    this.visitAmount = charge? charge.visitAmount : 0;
  }

  getDiscount() {
    return this.discount;
  }
}

export class Discount {
  discountType?: string;
  discountValue?: number;
  planName?: string;

  constructor(discount?: Discount, planName?: string) {
    this.discountType = discount ? discount.discountType : 'DOLLAR';
    this.discountValue = discount ? discount.discountValue : 0;
    this.planName = planName || '';
  }

  getType() {
    return this.discountType;
  }

  getValue() {
    return this.discountValue;
  }

  getPlanName() {
    return this.planName;
  }
}

export class ChargeBreakdown {
  paymentMode?: string;
  charge?: number;
  gst?: number;
  planName?: string;
  planId?: string;
  discountType?: string;
  discountValue?: number;
  discountPlanName?: string;
  visitAmount?: number;
  invoiceNumber?: string;
  planLimitWithGST?: number;

  constructor(invoiceCharge?: Charge , planMaxUsage?) {
    this.paymentMode =
      invoiceCharge.invoiceType === 'DIRECT' ? CASH : invoiceCharge.invoiceType;
    this.charge = invoiceCharge.payableAmount - invoiceCharge.taxAmount;
    this.gst = invoiceCharge.taxAmount;
    this.planName = invoiceCharge.planName || '';
    this.planId = invoiceCharge.planId || '';
    this.discountType = invoiceCharge.getDiscount().getType();
    this.discountValue = invoiceCharge.getDiscount().getValue();
    this.discountPlanName = invoiceCharge.getDiscount().getPlanName();
    this.visitAmount = invoiceCharge.visitAmount || 0;
    this.invoiceNumber = invoiceCharge.invoiceNumber || '';
    this.planLimitWithGST = planMaxUsage ? ( planMaxUsage[this.planId] * UtilsService.getGST() || 0 ) : 0;
  }

  setDiscount(type, value, planName) {
    this.discountType = type || '';
    this.discountValue = value || 0;
    this.discountPlanName = planName || '';
  }
}
