import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inventoryDisplayOrderName'
})
export class InventoryDisplayOrderNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (!value) {
      return ''
    }

    return value.getOrderNumber();
  }

}
