import { Component, OnDestroy } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AkitaAppQuery } from '../../services/states/akita-app.query';
import { StoreService } from './../../services/store.service';
import { VisitManagementService } from '../../services/visit-management.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent implements OnDestroy {
  isStoreReady = false;
  hasError = false;
  isLoading = false;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private akitaAppQuery: AkitaAppQuery,
    private router: Router,
    private store: StoreService,
    private visitManagementService: VisitManagementService
  ) {
    // this.storeReadinessSubscription();
    this.router.events
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          const urlStr = (<NavigationStart>event).url;
          const url = new URL(window.location.origin + urlStr);
          if (url.pathname === '/pages/patient/management') {
            const urlVisitId = url.searchParams.get('visitId');
            const storeVisitId = this.store.getPatientVisitRegistryId();
            if (
              urlVisitId &&
              urlVisitId !== this.store.getPatientVisitRegistryId()
            ) {
              this.isLoading = true;
            }
          } else {
            this.isLoading = true;
          }
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.isLoading = false;
        }
      });

    this.visitManagementService
      .getFullLayoutLoadingShown()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(show => {
        this.isLoading = show;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  // storeReadinessSubscription() {
  //   // this.akitaAppQuery.storeReadiness$.subscribe(data => {
  //   //   const count = this.akitaAppQuery.getCount();
  //   //   this.isLoading = data.init > 0;
  //   //   this.isStoreReady = data.success === count && count > 0;
  //   //   this.hasError =
  //   //     data.success + data.failure === count && data.failure > 0 && count > 0;


  //   // });
  // }

  resetCount() {
    this.isLoading = false;
    this.isStoreReady = false;
    this.hasError = false;
  }
}
