<div class="col-md-12 ">
    <div class="card-custom wrapper-conditions">
        <div class="card-header">Chronic Disease Management</div>
        <p class="sub-title ml-2 mb-2">Tick the condition(s) that the patient has:</p>

        <div class="row ml-2">
            <div class="col-md-3 checkbox form-check" *ngFor="let condition of patientConditions">
                <input type="checkbox" (ngModelChange)="onConditionCheck($event, condition)" [ngModel]="condition.selected"
                [disabled]="!enableConditions">
                <label>{{ condition.name }}</label>
            </div>
        </div>
    </div>
</div>