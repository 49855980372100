import { NgxPermissionsService } from 'ngx-permissions';
import { AkitaAppStore } from './../akita-app.store';
import { AuthService } from './../../auth.service';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';
import { AttachedMedicalCoveragesStore } from './akita-attached-medical-coverages/attached-medical-coverages.store';
import { AttachedMedicalCoveragesQuery } from './akita-attached-medical-coverages/attached-medical-coverages.query';
import { MedicalCoverageByCaseRes } from './../../../objects/PolicyHolderInfo';
import { AlertService } from './../../alert.service';
import { ApiCaseManagerService } from './../../api-case-manager.service';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AkitaCaseQuery } from './akita-case.query';
import { AkitaCase, AkitaCaseStore } from './akita-case.store';

@Injectable({
  providedIn: 'root',
})
export class AkitaCaseStoreService {
  private attachedToCaseMedicalCoverage: MedicalCoverageByCaseRes;
  private storeRefresh: Subject<void> = new Subject();
  private caseId: string;

  // readonly functionMap = {
  //   caseId: () => (() => this.getCase())()
  // };

  constructor(
    private akitaCaseQuery: AkitaCaseQuery,
    private akitaCaseStore: AkitaCaseStore,
    private attachedMedCovStore: AttachedMedicalCoveragesStore,
    private apiCaseManagerService: ApiCaseManagerService,
    private alertService: AlertService,
    private authService: AuthService
  ) {
    // if (this.authService.isAuthenticated()) {
    //   this.authService.getUser().subscribe(res => {
    //   });
    // }
    this.subscribeChangesOnApp();
  }

  subscribeChangesOnApp() {
    this.akitaCaseQuery.caseId$.pipe(distinctUntilChanged(), filter(value => !!value)).subscribe((value: string) => {
      this.caseId = value;
      this.loadStore();
    });
  }

  loadStore() {
    // config.forEach(key => {
    //   this.functionMap[key]();
    // });
    // this.functionMap['caseId']();
    this.getCase();
  }

  reset(refresh?) {
    this.akitaCaseStore.update({ caseId: '' });
    this.storeRefresh.next();
    this.attachedMedCovStore.reset();
  }

  getCaseId() {
    return this.caseId;
  }

  getCase(caseId = this.caseId) {
    this.apiCaseManagerService
      .getAttachedPolicyDetailByCaseId(caseId)
      .pipe(takeUntil(this.storeRefresh))
      .subscribe(
        res => {
          this.attachedMedCovStore.update(res.payload);
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }
}
