import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
  name: 'displayDocDisplayName',
})
export class DisplayDocDisplayNamePipe implements PipeTransform {
  constructor(private storeService: StoreService) {}

  transform(value: any, args?: any): any {
    const doctor = this.storeService.findDoctorById(value);

    if (args === undefined || args === null) args = '';
    return doctor ? (doctor.displayName ? doctor.displayName : '') : args;
  }
}
