import { AlertService } from './alert.service';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { delay, retryWhen, tap, takeUntil } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { AppConfigService } from './app-config.service';
import { StoreService } from './store.service';
import { Observable, Subject, interval } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RefreshComponent } from '../components/consultation/refresh/refresh.component';
import { Router } from '@angular/router';

export class Message {
  constructor(
    public sender: string,
    public content: string,
    public isBroadcast = false
  ) { }
}

@Injectable()
export class MessageService {
  reloadPvm: Subject<void> = new Subject();

  private WEB_SOCKET_URL;
  private socket$: WebSocketSubject<any>;

  readonly reload_time = 3000;

  constructor(
    private appConfig: AppConfigService,
    private alertService: AlertService,
    private store: StoreService,
    private authService: AuthService,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.WEB_SOCKET_URL = appConfig.getConfig().WEB_SOCKET_URL;

    this.resetWebSocket();

    this.authService.isLogout().subscribe(() => {
      if (this.socket$ && !this.socket$.closed) {
        this.socket$.unsubscribe();
      }
    });

    interval(55000).subscribe(val => {
      this.send(new Message('', ''));
    });
  }

  resetWebSocket() {
    if (localStorage.getItem('clinicId')) {
      const uId = localStorage.getItem('clinicId')
        ? `${localStorage.getItem('clinicId')}_${moment()}`
        : '';
      localStorage.setItem('uId', uId);

      this.createWebSocket(
        this.WEB_SOCKET_URL +
        '?token=Bearer ' +
        localStorage.getItem('access_token') +
        '&clinicId=' +
        localStorage.getItem('clinicId') +
        '&uId=' +
        uId +
        '&X-Tenant=' +
        localStorage.getItem('tenantId')
      )
        .pipe(
          takeUntil(this.authService.isLogout()),
          retryWhen(errors => {
            return errors.pipe(
              tap(err => {
                // this.alertService.error(JSON.stringify(err));
                console.error('Got error', err);
              }),
              delay(this.reload_time)
            );
          })
        )
        .subscribe(
          message => {
        
            const data = <any>message;
            const payload = data.payload;
            if (data.target === 'visit') {
              if (
                data.changerUsername !== this.store.getUser().userName &&
                payload.clinicId === localStorage.getItem('clinicId') &&
                payload.visitId === localStorage.getItem('visitId') &&
                this.router.url.startsWith('/pages/patient/management')
              ) {
                let showDocNotice = true;
                if(payload.changeType === "INITIAL" || payload.changeType === "PAYMENT_ROLLBACK"){
                  showDocNotice = false;
                }
                const initialState = {
                  showDocNotice: showDocNotice
                };

                if (this.modalService.getModalsCount() > 0) return;
                this.alertService.sendstoreTempNotesEvent();
                const bsModalRef = this.modalService.show(RefreshComponent, {
                  initialState,
                  class: 'modal-lg',
                  keyboard: false,
                  backdrop: 'static',
                });

                bsModalRef.content.event.subscribe(data => {

                  bsModalRef.content.event.unsubscribe();
                  bsModalRef.hide();
                  this.reloadPvm.next();

                });
              }
            }
          },
          err => console.error(err)
        );
    }
  }

  createWebSocket = uri => {
    return Observable.create(observer => {
      try {
        const subject = webSocket(uri);
        this.socket$ = subject;

        const subscription = subject.asObservable().subscribe(
          data => observer.next(data),
          error => {
            this.authService.getUser().subscribe(
              res => {
                if (res.payload && this.authService.isAuthenticated()) {
                  observer.error(error);
                } else {
                  observer.complete();
                }
              },
              err => {
                // this.alertService.error(JSON.stringify(err));
                console.error('API ERROR', err);
                observer.complete();
              }
            );
          }
        );

        return () => {
          if (!subscription.closed) {
            subscription.unsubscribe();
          }
        };
      } catch (error) {
        observer.error(error);
        this.authService.getUser().subscribe(
          res => {
            if (res.payload && this.authService.isAuthenticated()) {
              observer.error(error);
            } else {
              observer.complete();
            }
          },
          err => {
            //  this.alertService.error(JSON.stringify(err));
            console.error('API ERROR', err);
            observer.complete();
          }
        );
      }
    });
  };

  public send = (message: Message) => {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.next(<any>JSON.stringify(message));
    }
  };
}
