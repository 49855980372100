import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { InventorySupplier } from '../../objects/state/InventorySupplier';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'inventory',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaInventoryStore extends EntityStore<InventorySupplier> {
  constructor() {
    super();
  }
}
