<div *ngIf="!type || type === 'other'; else multiChoice " class="d-flex align-items-center">
    <ng-container *ngIf="bodySectionFormGroup" [formGroup]="bodySectionFormGroup">
        <ng-container *ngFor="let section of bodySection; let i = index">
            <ng-container *ngIf="section.input !== null; else labelBody">
                <!-- Input -->
                <ng-container *ngIf="section.input === 'input' ">
                    <div class="wrapper-form-control mr-1">
                        <input [type]="section.type === 'QUANTITY' ? 'number' : 'text' " class="input w-100px"
                            [formControlName]="section.placeholder!">
                    </div>
                </ng-container>

                <!-- Dropdown -->
                <ng-container *ngIf="section.input === 'input-dropdown' ">
                    <div class="wrapper-form-control mr-1">
                        <ng-select class="custom-select-new" [items]="section.data" bindLabel="value" bindValue="key"
                            [addTag]="section.type !== 'UOM' ? addTagFn : null" [clearable]="true" [formControlName]="section.placeholder">
                        </ng-select>
                    </div>
                </ng-container>

                <!-- Datepicker -->
                <ng-container *ngIf="section.input === 'date-picker' ">
                    <app-date-picker-with-icon [customStyles]="true" [config]="datePickerConfig"
                        [disabled]="bodySectionFormGroup.get(section.placeholder).disabled"
                        [dateControl]="bodySectionFormGroup.get(section.placeholder)">
                    </app-date-picker-with-icon>
                </ng-container>
            </ng-container>

            <ng-template #labelBody>
                <span class="mr-1">{{section.label}}</span>
            </ng-template>
        </ng-container>
    </ng-container>
</div>

<ng-template #multiChoice>
    <ng-container *ngIf="bodySectionFormGroup" [formGroup]="bodySectionFormGroup">
        <ng-container *ngFor="let section of bodySection; let i = index">
            <span class="d-block mb-1">{{section.label}}</span>

            <div class="row" formArrayName="multiChoice">
                <ng-container *ngFor="let question of section.data; let j = index" [formGroupName]="j">
                    <div class="col-6">
                        <input #multiChoiceCheckbox class="mr-2" type="checkbox"
                            (change)="onMultiChoiceCheck($event.target.checked, question, bodySectionFormGroup.get('multiChoice').controls[j])"
                            formControlName="isChecked">
                        <span>{{question.value}}</span>

                        <div class="wrapper-form-control mt-2">
                            <textarea class="input" *ngIf="question.captureContext && multiChoiceCheckbox.checked"
                                formControlName="captureContext">
                            </textarea>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="row mt-2" *ngIf="getErrorMessages(section).length > 0">
                <div class="col-12">
                    <div class="alert alert-danger d-inline-block" role="alert">
                        <ul class="m-0 p-0 ml-2">
                            <li *ngFor="let error of multiChoiceErrorMessages;">
                                {{error}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-template>