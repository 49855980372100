import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class TempStoreService {
  private API_CMS_MANAGEMENT_URL;
  private access_token;
  private TEMP_STORE_URL;
  private TEMP_STORE_RETRIEVE_URL;
  private TEMP_STORE_REMOVE_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService
  ) {
    this.API_CMS_MANAGEMENT_URL = this.appConfig.getConfig().API_CMS_MANAGEMENT_URL;
    this.access_token = `Bearer ${localStorage.getItem('access_token')}`;
    this.TEMP_STORE_URL = `${this.API_CMS_MANAGEMENT_URL}/temp-store/store/`;
    this.TEMP_STORE_RETRIEVE_URL = `${this.API_CMS_MANAGEMENT_URL}/temp-store/retrieve/`;
    this.TEMP_STORE_REMOVE_URL = `${this.API_CMS_MANAGEMENT_URL}/temp-store/remove/`;
  }

  tempStore(key: string, data: string): Observable<HttpResponseBody> {

    return this.http.post<HttpResponseBody>(
      `${this.TEMP_STORE_URL}${key}`,
      data
    );
  }

  tempStoreRetrieve(key: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.TEMP_STORE_RETRIEVE_URL}${key}`,
      {}
    );
  }

  tempStoreRemove(key: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.TEMP_STORE_REMOVE_URL}${key}`,
      {}
    );
  }

  tempStoreInSync(key: string, data: string): string {

    return this.apiCallInSync(`${this.TEMP_STORE_URL}${key}`, data);
  }

  tempStoreRetrieveInSync(key: string): string {
    return this.apiCallInSync(`${this.TEMP_STORE_RETRIEVE_URL}${key}`);
  }

  tempStoreRemoveInSync(key: string): string {
    return this.apiCallInSync(`${this.TEMP_STORE_REMOVE_URL}${key}`);
  }

  private apiCallInSync(url: string, data: string = ''): string {
    let http = new XMLHttpRequest();
    http.overrideMimeType('application/json');
    http.open('POST', url, false);
    http.setRequestHeader('Authorization', this.access_token);
    http.send(data);
    return http.responseText;
  }
}