<form class="" [formGroup]='labTestItem'>
  <div class="row p-2">
    <div style="padding:2px 5px 0 8px;">
      <input
        type="checkbox"
        name=""
        (change)="onItemChecked($event.target.checked)"
        formControlName="isChecked"
        [attr.disabled]="disabled ? true : null"
      >
    </div>
    <div class="col-md-2">
      <ng-select name="testCode"
        class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width' [items]="items"
        bindLabel='testCode' bindValue='testCode' (change)="onDrugSelect($event)" [addTag]="false" [readonly]="disabled"
        [virtualScroll]="true" [typeahead]="codesTypeahead" [loading]="loading" appendTo="body" [clearable]=true formControlName="testCode"
        (clear)="onClear()">
        <ng-template ng-header-tmp>
          <div class="row">
            <div class="col-md-4 font-weight-semi-bold">Code</div>
            <div class="col-md-6 font-weight-semi-bold">Name</div>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div class="row">
            <div class="col-md-4">{{item.testCode}}</div>
            <div class="col-md-6">{{item.testName}}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col-md-4">
      <input class="form-control form-control-sm" formControlName="testName" required [readOnly]="disabled"/>
    </div>
    <div class="col-md-2">
        <ng-select
          class="p-0 custom-select"
          formControlName="category"
          [items]="categoryItems"
          [clearable]='false'
          [readonly]="disabled"
        >
        </ng-select>
    </div>
    <div class="col-md-3">
      <input class="form-control form-control-sm" formControlName="remarks" [readOnly]="disabled"/>
    </div>
  </div>
  <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
</form>
