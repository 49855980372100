export class PatientNoteAdd {
  note: string;
  doctorId: string;
  clinicId: string;
  doctorName: string;
  addedDateTime: string;
  typeOfProblem: string;
  dateOfOnSet: string;
  status?: string;
  patientNoteId?: string;

  constructor(
    note: string,
    doctorId: string,
    clinicId: string,
    addedDateTime: string,
    typeOfProblem: string,
    dateOfOnSet: string,
    status?: string,
    patientNoteId?: string,
    doctorName?: string
  ) {
    this.note = note || '';
    this.doctorId = doctorId || '';
    this.clinicId = clinicId || '';
    this.doctorName = doctorName || '';
    this.addedDateTime = addedDateTime || '';
    this.typeOfProblem = typeOfProblem || 'NOTE';
    this.dateOfOnSet = dateOfOnSet || '';
    this.status = status || 'ACTIVE';
    this.patientNoteId = patientNoteId || undefined;
  }
}
