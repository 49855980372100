<div class="row mt-2" *ngIf="errorMessages.length > 0">
    <div class="col-12">
        <div class="alert alert-danger d-inline-block" role="alert">
            <ul class="m-0 p-0 ml-2">
                <li *ngFor="let error of errorMessages;">
                    {{error}}
                </li>
            </ul>
        </div>
    </div>
</div>