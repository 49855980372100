<div class="row">
    <div class="col-12 mb-3">
        <div class="alert alert-info m-0" role="alert">
            Note: The system has auto-selected chronic condition goal(s) based on chronic condition(s) tagging in this
            HealthPlan. Body weight goal will be auto-selected for all residents.
        </div>
    </div>

    <div class="col-12 mb-3" *ngIf="carePlanFormGroup" [formGroup]="carePlanFormGroup">
        <h5>Chronic Disease Management:</h5>
        <span class="d-block">Tick the condition(s) that the patient has:</span>

        <div class="alert alert-warning m-0 mt-2" role="alert">
            If you wish to update patient conditions, please update from CDM tab.
        </div>

        <div class="row mt-2" formArrayName="cmsConditionsForHSG">
            <ng-container *ngFor="let condition of PatientConditions; let i = index;">
                <div class="col-md-3 checkbox form-check" [formGroupName]="i">
                    <input type="checkbox" [value]="condition.code" formControlName="selected">
                    <!-- TO-DO-SM: Remove if not required -->
                    <!-- (change)="onCheckPatientCondition($event.target.checked, condition)" -->
                    <label>{{ condition.name }}</label>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="col-12">
        <table class="custom-table">
            <thead>
                <tr>
                    <th style="width: 3%; text-align: center;">No.</th>
                    <th style="width: 25%">Goal</th>
                    <th style="width: 65%;">Target</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let healthPlan of healthPlanList; let i = index;">
                    <app-health-plan [index]="i" [dropdownList]="dropdownList" [selectedDropdownList]="selectedDropdownList"
                        [healthPlan]="healthPlan" (selecttGoalEventEmitter)="onSelectGoal($event)"
                        (deletedGoalEventEmitter)="onDelete($event)">
                    </app-health-plan>
                </ng-container>

                <tr *ngIf="healthPlanList.length === 0">
                    <td></td>
                    <td></td>
                    <td>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="alert alert-danger d-block w-100" role="alert">
                                    At least 1 goal is required.
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="col-12 mt-2 d-flex flex-column align-items-end justify-content-end">
        <span class="error" *ngIf="maxPlanSelectedError">You have added all plans available.</span>
        <button class="button-green mr-2" (click)="onAdd()">Add New</button>
    </div>
</div>