import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommunicationCenter } from '../objects/request/CommunicationCenter';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root',
})
export class ApiCommunicationsService {
  private API_URL;
  private API_COMMUNICATIONS_URL;
  API_CMS_MANAGEMENT_URL: any;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private backendService: BackendService
  ) {
    this.API_URL = this.appConfig.getConfig().API_URL;
    this.API_COMMUNICATIONS_URL = this.appConfig.getConfig().API_COMMUNICATIONS_URL;
    this.API_CMS_MANAGEMENT_URL = this.appConfig.getConfig().API_CMS_MANAGEMENT_URL;
  }

  getCommunicationTypes(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_COMMUNICATIONS_URL}/type/all`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  getCommunicationTemplates(commType: string): Observable<HttpResponseBody> {
    const types = this.http.post<HttpResponseBody>(
      `${this.API_COMMUNICATIONS_URL}/type/${commType}/templates`,
      {}
    );
    return types;
  }

  searchContacts(term: string, commType: string): Observable<HttpResponseBody> {
    const contacts = this.http.post<HttpResponseBody>(
      `${this.API_COMMUNICATIONS_URL}/contact/search?param=${term}&type=${commType}`,
      {}
    );
    return contacts;
  }

  saveMessage(
    communicationCenter: CommunicationCenter
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_COMMUNICATIONS_URL}/save`,
      JSON.stringify(communicationCenter)
    );
  }

  searchSMSLog(
    toDate,
    fromDate,
    type,
    searchText,
    clinicId,
    page
  ): Observable<HttpResponseBody> {
    const smsLog = this.http.post<HttpResponseBody>(
      `${this.API_COMMUNICATIONS_URL}/log-list/${clinicId}/${fromDate}/${toDate}/${type}/${page.pageNumber}/${page.size}`,
      { searchValue: searchText }
    );
    return smsLog;
  }

  getOnlineDoctor(): Observable<HttpResponseBody> {
    return this.http.get<HttpResponseBody>(
      `${this.API_URL}/cms-dua/doctor/list/online`
    );
  }

  searchLabs(payload, page): Observable<HttpResponseBody> {
    const labs = this.http.post<HttpResponseBody>(
      `${this.API_COMMUNICATIONS_URL}/lab/results/${page.pageNumber}/${page.size}`,
      JSON.stringify(payload)
    );
    return labs;
  }

  searchLogs(payload, page): Observable<HttpResponseBody> {
    const logs = this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/smart-cms/search/submissions/${page.pageNumber}/${page.size}`,
      JSON.stringify(payload)
    );
    return logs;
  }


  updateLabs(payload): Observable<HttpResponseBody> {
    const labs = this.http.post<HttpResponseBody>(
      `${this.API_COMMUNICATIONS_URL}/lab/edit-remark`,
      JSON.stringify(payload)
    );
    return labs;
  }


}
