import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { Vaccination } from '../../objects/state/Vaccination';
import { AkitaVaccinationStore } from './akita-vaccination.store';

@Injectable({ providedIn: 'root' })
export class AkitaVaccinationQuery extends QueryEntity<Vaccination> {
  vaccinationList$ = this.selectAll();

  constructor(protected store: AkitaVaccinationStore) {
    super(store);
  }
}
