import { Component, EventEmitter, Input, OnInit } from '@angular/core';

import Konva from 'konva/lib/Core';

import { KonvaUtilService } from '../../services/konva-util.service';
import { UtilsService } from '../../services/utils.service';
import { BsModalRef } from 'ngx-bootstrap';

import { ungzip, gzip } from 'pako';

@Component({
  selector: 'app-drawing-board',
  templateUrl: './drawing-board.component.html',
  styleUrls: ['./drawing-board.component.scss'],
})
export class DrawingBoardComponent implements OnInit {
  static readonly STAGE_WIDTH = 794;
  static readonly STAGE_HEIGHT = 1123;
  static readonly FILE_READER = new FileReader();

  @Input() compressedImage: string;
  @Input() isInitial = false;
  @Input() isEdit = false;
  @Input() isPreview = false;

  public eventEmitter: EventEmitter<any> = new EventEmitter();

  stage: Konva.Stage;
  layer: Konva.Layer;

  shapeArray = new Array<any>();
  shapeHistoryArray = new Array<any>();
  transformerArray = new Array<Konva.Transformer>();

  shapeButton = {
    text: false,
    line: false,
    erase: false,
  };

  colorButton = {
    black: { selected: false, color: '000000' },
    'dark-grey': { selected: false, color: '9b9b9b' },
    'light-grey': { selected: false, color: 'd9d9d8' },
    blue: { selected: false, color: '1a8cff' },
    red: { selected: false, color: 'ff1a40' },
    yellow: { selected: false, color: 'ffdd32' },
  };

  originalValue: string;

  constructor(
    public bsModalRef: BsModalRef,
    private konvaUtilService: KonvaUtilService,
    private utilService: UtilsService
  ) {}

  ngOnInit() {
    this.stage = new Konva.Stage({
      container: 'container',
      width: DrawingBoardComponent.STAGE_WIDTH,
      height: DrawingBoardComponent.STAGE_HEIGHT,
    });

    if (this.compressedImage && this.compressedImage.length > 0) {
      this.originalValue = this.compressedImage;
      const uncompressedString = JSON.parse(
        ungzip(this.utilService.fromHexString(this.compressedImage), {
          to: 'string',
        })
      );
      this.layer = Konva.Node.create(uncompressedString);

      if (this.isInitial || this.isEdit) {
        this.pickShapeSelection('line');
        this.pickColorSelection('black');

        this.shapeArray = new Array<any>();
        this.shapeHistoryArray = new Array<any>();
        this.transformerArray = new Array<Konva.Transformer>();

        const ctx = this;
        this.layer
          .getChildren(
            node =>
              node.getClassName() === 'Text' || node.getClassName() === 'Image'
          )
          .toArray()
          .forEach(node => {
            switch (node.getClassName()) {
              case 'Text': {
                const text = this.konvaUtilService.konvaText(
                  this.stage,
                  this.layer,
                  ctx,
                  node
                );
                break;
              }
              case 'Image': {
                const imageNode = <Konva.Image>node;
                if (node.getAttr('source')) {
                  const imageObject = new Image();
                  imageObject.src = node.getAttr('source');
                  (<Konva.Image>node).setAttr('image', imageObject);

                  const image = this.konvaUtilService.konvaImage(
                    this.stage,
                    this.layer,
                    ctx,
                    node
                  );
                }
                break;
              }
            }
          });

        this.detachTransformers();
      }
    } else {
      this.layer = new Konva.Layer();
      this.pickShapeSelection('line');
      this.pickColorSelection('black');

      this.originalValue = '';
    }

    this.stage.add(this.layer);
    this.addLineListener();
    this.addImageListener();
  }

  resetColorSelection() {
    Object.keys(this.colorButton).forEach(
      color => (this.colorButton[color].selected = false)
    );
  }

  pickColorSelection(color: string) {
    this.resetColorSelection();
    this.colorButton[color].selected = true;
  }

  resetShapeSelection() {
    Object.keys(this.shapeButton).forEach(
      shape => (this.shapeButton[shape] = false)
    );
  }

  pickShapeSelection(shape: string) {
    this.resetShapeSelection();
    this.shapeButton[shape] = true;
  }

  addShape(shape: string) {
    this.pickShapeSelection(shape);

    if (shape === 'text') this.addText();
    else if (shape === 'line') this.addLine();
  }

  addLine() {
    this.shapeButton.line = true;
  }

  addLineListener() {
    const ctx = this;
    let lastLine;
    let isPaint;

    this.stage.on('mousedown touchstart', function(e) {
      if (!ctx.shapeButton.line && !ctx.shapeButton.erase) return;

      isPaint = true;
      const options = {
        x: ctx.stage.getPointerPosition().x,
        y: ctx.stage.getPointerPosition().y,
        stroke: ctx.getColorCode(),
        mode: ctx.shapeButton.line ? 'brush' : 'eraser',
      };

      lastLine = ctx.konvaUtilService.konvaLine(options);

      ctx.layer.add(lastLine);
      ctx.shapeArray.push(lastLine);
    });

    this.stage.on('mouseup touchend', function() {
      isPaint = false;
      ctx.clearHistory();
    });

    this.stage.on('mousemove touchmove', function() {
      if (!isPaint) return;

      const pos = ctx.stage.getPointerPosition();
      const newPoints = lastLine.points().concat([pos.x, pos.y]);
      lastLine.points(newPoints);
      ctx.layer.batchDraw();
    });
  }

  addImageListener() {
    const ctx = this;

    this.stage.container().addEventListener('dragover', function(e) {
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    });

    this.stage.container().addEventListener('drop', function(e) {
      e.preventDefault();
      ctx.stage.setPointersPositions(e);

      const droppedFile = e.dataTransfer.items[0].getAsFile();
      DrawingBoardComponent.FILE_READER.onload = function(f: any) {
        ctx.konvaUtilService.konvaImage(
          ctx.stage,
          ctx.layer,
          ctx,
          f.target.result
        );
      };
      DrawingBoardComponent.FILE_READER.readAsDataURL(droppedFile);
    });
  }

  addText() {
    const ctx = this;
    const options = {
      fontSize: 14,
      fill: this.getColorCode(),
    };

    const text = this.konvaUtilService.konvaText(
      this.stage,
      this.layer,
      ctx,
      options
    );
    this.shapeArray.push(text.node);
    this.transformerArray.push(text.transformer);

    ctx.clearHistory();
  }

  noActionMode() {
    this.resetShapeSelection();
    this.resetColorSelection();
  }

  setDefaultMode() {
    this.pickShapeSelection('line');
    this.pickColorSelection('black');
  }

  private clearHistory() {
    if (this.shapeHistoryArray && this.shapeHistoryArray.length > 0)
      this.shapeHistoryArray.splice(0, this.shapeHistoryArray.length);
  }

  clearLayer() {
    const clearConfirm = confirm(
      'Are you sure you want to erase the canvas? Note that any changes will not be saved.'
    );

    if (clearConfirm) {
      this.shapeArray.splice(0, this.shapeArray.length);
      this.shapeHistoryArray.splice(0, this.shapeHistoryArray.length);
      this.transformerArray.splice(0, this.transformerArray.length);

      this.layer.destroyChildren();
      this.layer.draw();
    }
  }

  undoShape() {
    if (this.shapeArray && this.shapeArray.length > 0) {
      const popShape = this.shapeArray.pop();

      this.transformerArray.forEach(transformer => transformer.detach());
      if (popShape) {
        this.shapeHistoryArray.push(popShape);
        popShape.remove();
        this.layer.draw();
      }
    }
  }

  redoShape() {
    if (this.shapeHistoryArray && this.shapeHistoryArray.length > 0) {
      const unpopShape = this.shapeHistoryArray.pop();

      this.transformerArray.forEach(transformer => transformer.detach());
      if (unpopShape) {
        this.shapeArray.push(unpopShape);
        this.layer.add(unpopShape);
        this.layer.draw();
      }
    }
  }

  saveLayer() {
    this.detachTransformers();
    const rect = this.konvaUtilService.konvaRect(this.stage, this.layer);
    this.layer
      .getChildren(node => node.getClassName() !== 'Rect')
      .toArray()
      .forEach(node => rect.moveDown());
    const dataURL = this.stage.toDataURL();

    const link = document.createElement('a');
    link.download = `consultation-drawing.png`;
    link.href = dataURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    rect.remove();
  }

  closePreview() {
    if (this.isEdit) this.detachTransformers();
    this.eventEmitter.unsubscribe();
    this.bsModalRef.hide();
  }

  saveEdit() {
    this.detachTransformers();
    let compressedString = '';
    if (this.layer.getChildren().toArray().length > 0) {
      compressedString = this.utilService.toHexString(
        gzip(JSON.stringify(this.layer.toJSON()))
      );
    }
    this.eventEmitter.emit({
      key: 'drawingBinary',
      value: compressedString
    });
  }

  private detachTransformers() {
    this.layer
      .getChildren(node => node.getClassName() === 'Transformer')
      .toArray()
      .forEach((transformer: Konva.Transformer) => transformer.detach());
  }

  private getColorCode() {
    let colorCode = '';

    Object.keys(this.colorButton).forEach(color => {
      if (this.colorButton[color].selected)
        colorCode = this.colorButton[color].color;
    });

    return colorCode;
  }
}
