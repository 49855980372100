<div class="row bg-warm-grey text-white font-weight-semi-bold mb-0">
  <div class="col-md">Mode</div>
  <div class="col-md-2">Amount</div>
  <div class="col-md-2">Date/Time</div>
  <div class="col-md-2">Reference</div>
</div>

<div *ngFor="let invoice of paymentHistory$ | async" class="row py-1">
  <div class="col-md">{{ invoice.mode | titlecase }}</div>
  <div class="col-md-2">${{ invoice.amount | displayDollar }}</div>
  <div class="col-md-2">{{ invoice.dateTime }}</div>
  <div class="col-md-2">{{ invoice.reference }}</div>
</div>
