import { Address } from './../objects/Address';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayMCAddress',
})
export class DisplayMCAddressPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const address = value;
      const tempAdd =
        Object.keys(address).length !== 0 &&
        address !== undefined &&
        address !== null
          ? address
          : new Address('', '', '');

      const addString =
        (tempAdd.address && tempAdd.address.length > 0) ||
        (tempAdd.postalCode && tempAdd.postalCode.length > 0)
          ? tempAdd.address + ' ' + tempAdd.postalCode
          : args;

      return addString;
    } else {
      return '';
    }
  }
}
