import { Component, Input, OnInit } from '@angular/core';
import { HSGPatientJourney } from '../../../../../../model/HSGPatientJourney';

@Component({
  selector: 'app-patient-journey',
  templateUrl: './patient-journey.component.html',
  styleUrls: ['./patient-journey.component.scss']
})
export class PatientJourneyComponent implements OnInit {

  @Input()
  patientJourney: HSGPatientJourney;

  constructor() { }

  ngOnInit(): void {
  }

}
