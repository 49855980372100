import { DISPLAY_DATE_FORMAT } from './../constants/app.constants';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'sortVaccine',
})
export class SortVaccinePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let sortedValue = value.sort((a, b) => {
      if (
        moment(a.givenDate, DISPLAY_DATE_FORMAT).isSameOrBefore(
          moment(b.givenDate, DISPLAY_DATE_FORMAT)
        )
      ) {
        return 1;
      }
      return -1;
    });

    return sortedValue;
  }
}
