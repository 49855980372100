import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';

@Injectable()

export class ApiHwmedService {

  private API_URL_ENV;
  clinicId: string

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.API_URL_ENV = appConfig.getConfig().API_URL_ENV;
    this.clinicId = localStorage.getItem('clinicId')
  }

  saveHwmedSurvey(
    visitId: string,
    payload: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/hwmed/save/${visitId}`,
      JSON.stringify(payload)
    );
  }

  listVisits(
    payload: any,
    clinicId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/hwmed/visits/${clinicId}`,
      JSON.stringify(payload)
    );
  }

  getHwmedSurveyItem(
    visitId: string,
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_URL_ENV}/hwmed/get/${visitId}`,
      {}
    );
  }
}
