import { CaseCoveragePlan } from './../../../../objects/state/Case';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, debounceTime, take, bufferTime, groupBy, toArray, mergeMap, map, concatAll, filter, distinctUntilKeyChanged } from 'rxjs/operators';
import { PvmTabsService } from './../../../../services/pvm-tabs.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  AfterViewInit,
  ViewChildren,
  ElementRef,
  QueryList,
} from '@angular/core';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../../constants/app.constants';
import { StoreService } from '../../../../services/store.service';
import { DispatchItemService } from '../../../../services/dispatch-item.service';
import { CaseChargeFormService } from '../../../../services/case-charge-form.service';
import { ApiCaseManagerService } from '../../../../services/api-case-manager.service';
import { ConsultationFormService } from '../../../../services/consultation-form.service';
import { IPackageItem, IDispaches } from '../package.model';
import { Observable, Subject, of, from } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AkitaCaseVisitQuery } from '../../../../services/states/akita-case-visit.query';
import { Case } from '../../../../objects/Case';
import DatePickerConfig from '../../../../objects/DatePickerConfig';
import { UtilsService } from '../../../../services/utils.service';
import { groupByKey } from '../../../../util/array.util';
import { BsModalService } from 'ngx-bootstrap';
import { VaccineDetailsModalComponent } from '../../../consultation/consultation-prescription/vaccine-details-modal/vaccine-details-modal.component';
import { ChargeItemDescription } from '../../../../objects/ChargeItemDescription';
import { Instruction } from '../../../../objects/DrugItem';
import { VisitManagementService } from '../../../../services/visit-management.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AlertService } from '../../../../services/alert.service';
import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';

@Component({
  selector: 'app-package-item',
  templateUrl: './package-item.component.html',
  styleUrls: ['./package-item.component.scss'],
})
export class PackageItemComponent implements OnInit, OnDestroy, AfterViewInit {
  isCollapsed = false;
  hasAllergicSubItem = false;
  selectedSubItems: Observable<number>;
  visitStatus: string;
  itemIsValid = true;
  loading = true;
  // plansInSO = [];
  selectedItems = [];
  excludedItems: string[] = [];
  case: Case;
  isDisabled: boolean;
  visitId: any;
  @Input() vaccineDosages;
  @Input() packageItem: IPackageItem;
  @Input() subItemFormGroup;
  @Input() inDispensing;
  @Input() isDoctor: boolean = true;
  selectedPlans: CaseCoveragePlan[];
  isSecondVisitOnwards: boolean;
  datePickerConfigArray: Array<DatePickerConfig>;
  items = ['test', '12']
  // expiryDate: FormControl;

  itemFormGroups: Map<number, any> = new Map();
  isVaccineItemValid: boolean = true;
  topChargeItemDescription: ChargeItemDescription;
  instructions: Array<Instruction>;
  dosageInstructions: Array<any>;
  vaccinations: Array<any> = [];
  doctors: any[];
  routeOfAdministrations: any[] = [];

  @Output() packageClicked = new EventEmitter();

  @Output() deletePackageItem: EventEmitter<any>;
  @Output() onSubItemPriceAdjustment: EventEmitter<any> = new EventEmitter();
  @Output() onItemChecked: EventEmitter<any>;

  priceChangeSubject = new Subject<{ id: string, valueInDoller: number, subItem: IDispaches }>();

  private adjustedPriceValue: number;
  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private storeService: StoreService,
    private consultationFormService: ConsultationFormService,
    private caseVisitQuery: AkitaCaseVisitQuery,
    private utilsService: UtilsService,
    private dispatchItemService: DispatchItemService,
    private visitManagementService: VisitManagementService,
    private apiCmsManagementService: ApiCmsManagementService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private modalService: BsModalService,
    private store: StoreService,
    private alertService: AlertService,
  ) {
    this.deletePackageItem = new EventEmitter();
    this.onItemChecked = new EventEmitter();
    this.isSecondVisitOnwards = this.caseVisitQuery.isSubsequentVisitForPackagedCase();
    this.vaccinations = this.store.vaccinationList;
  }

  ngOnInit() {
    const self = this;
    this.getRouteOfAdministrations();
    // this.expiryDate = new FormControl();
    this.visitStatus = this.storeService.getVisitStatus();
    this.visitId = this.caseVisitQuery.getActiveId();
    this.selectedSubItems = this.caseVisitQuery
      .selectTotalDispenseItems(this.packageItem.packageId)
      .pipe(untilDestroyed(this));

    this.selectedPlans = this.caseVisitQuery.getCaseCoverage();
    this.buildFormGroupsForCompleted();
  }

  ngAfterViewInit() {
    this.priceChangeSubject.asObservable().pipe(
      bufferTime(0),
      filter(data => !!data.length),
      map(data => {

        const group = groupByKey(data, 'id');
        return Object.values(group);
      }),
      untilDestroyed(this)
    ).subscribe((data: { id: string, valueInDoller: number, subItem: IDispaches }[]) => {
      data.forEach(({ valueInDoller, subItem }) => {
        this.subItemPriceChange(valueInDoller, subItem);
      })
    })

    this.isPatientAllergic(this.packageItem);
  }

  ngOnDestroy() { }

  buildFormGroupsForCompleted () {
    this.itemFormGroups.clear();
    this.packageItem.dispatches
      .forEach((item, index) => {
        if (item.utilize && this.isVaccine(item) && !this.isItemDisabled(item)) {
          this.createFormFroup(item, index);
        }
      });

    this.alertService.setPackageItemUpdated(this.isPackageVaccinesValid());
  }

  checkValidity(index) {
    const itemFormGroup = this.itemFormGroups.get(index);

    if (itemFormGroup) {
      return itemFormGroup.valid;
    } else {
      return true;
    }
  }

  isPackageVaccinesValid() {
    let isPackageVaccineValid = true;
    for (let itemFormGroup of this.itemFormGroups.values()) {
      if (!itemFormGroup.valid) {
        isPackageVaccineValid = false;
        break;
      }
    }
    return isPackageVaccineValid;
  }

  truncate(text) {
    return this.utilsService.textTruncate(text, 20);
  }
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.packageClicked.emit(this.packageItem);
  }

  createSubItemPriceChangeStream(event: any, subItem: IDispaches) {
    const valueInDoller = event.target.value;
    this.priceChangeSubject.next({ valueInDoller, subItem, id: subItem.itemId });
  }

  onChangeAdjustedPrice(event: any) {
    this.adjustedPriceValue = event.target.value;
  }

  subItemPriceChange(valueInDoller: number, subItem: IDispaches) {
    let totalPrice = 0;
    const adjustedPrice = (valueInDoller * 100) - subItem.oriTotalPrice;

    const priceWithoutCurrentSubItem = this.packageItem.purchasePrice - (subItem.oriTotalPrice + subItem.itemPriceAdjustment.adjustedValue)
    totalPrice = priceWithoutCurrentSubItem + (valueInDoller * 100);

    const updatedSubItem: IDispaches = {
      ...subItem,
      itemPriceAdjustment: {
        ...subItem.itemPriceAdjustment,
        adjustedValue: adjustedPrice
      }
    };

    this.onSubItemPriceAdjustment.emit({
      totalPrice,
      packageId: this.packageItem.packageId,
    });

    this.caseVisitQuery.updateSubItemForActiveVisit(
      this.packageItem.packageId,
      updatedSubItem
    );
  }

  onItemClick(event) {
    event.stopImmediatePropagation();
    this.packageClicked.emit(this.packageItem);
  }

  checkboxClick(event, subItem: IDispaches) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    let dispenseDate = '';
    // let dispenseQty = 0;
    let utilize = false;
    let visitId = '';
    if (event.target.checked) {
      dispenseDate = moment().format(DISPLAY_DATE_FORMAT);
      // dispenseQty = 1;
      visitId = this.visitId;
      utilize = true;
    }
    // debugger;
    const dispatchEntity: IDispaches = {
      ...subItem,
      utilizedDate: dispenseDate,
      utilize,
      visitId: visitId
    };

    if (subItem.inventoried) {
      this.updateSubItemAvailability(dispatchEntity, event.target.checked);
    } else {
      this.caseVisitQuery.updateSubItemForActiveVisit(
        this.packageItem.packageId,
        dispatchEntity
      );
    }

    this.onItemChecked.emit(this.packageItem);
  }

  updatePlans() {
    this.selectedPlans.forEach((element: any) => {
      if (!this.excludedItems.includes(element.planId)) {
        this.selectedItems.push(element);
      }
    });
  }

  isPatientAllergic(packageItem: IPackageItem): boolean {
    return packageItem.dispatches.some(item => item.isPatientAllergic);
  }


  updateSubItemAvailability(subItem: IDispaches, isChecked: boolean) {
    const dispenseQty = subItem.utilize ? 1 : 0;
    this.dispatchItemService
      .setDrugDispenseQtyFromBatch(
        subItem.itemId,
        subItem.batchNo,
        dispenseQty,
        subItem.drugDispenseShortId
      );

    const subItemCode = subItem.itemCode;
    const packageItems = this.caseVisitQuery.getPackageBySubItemCode(subItemCode);
    // const isAvailable = isChecked ?
    //   this.dispatchItemService
    //     .getRemainingQty(subItem.itemId) > -1 : true;

    const isAvailable = this.dispatchItemService
        .getRemainingQty(subItem.itemId) > -1;

    packageItems.forEach(packageItem => {
      packageItem.dispatches
        .filter(item => item.inventoried && item.itemCode === subItemCode)
        .forEach(item => {
          let dispatchItem = item;

          if (item.drugDispenseShortId === subItem.drugDispenseShortId) {

            // update pricing if price of subitem has changed
            if(this.adjustedPriceValue !== undefined) {

              let totalPrice = 0;
              const adjustedPrice = (this.adjustedPriceValue * 100) - subItem.oriTotalPrice;
              const priceWithoutCurrentSubItem = this.packageItem.purchasePrice - (subItem.oriTotalPrice + subItem.itemPriceAdjustment.adjustedValue)
              totalPrice = priceWithoutCurrentSubItem + (this.adjustedPriceValue * 100);

              const updatedSubItem: IDispaches = {
                ...subItem,
                itemPriceAdjustment: {
                  ...subItem.itemPriceAdjustment,
                  adjustedValue: adjustedPrice
                }
              };

              this.onSubItemPriceAdjustment.emit({
                totalPrice,
                packageId: this.packageItem.packageId,
              });

              dispatchItem = updatedSubItem;

            } else {
              dispatchItem = {
                ...subItem,
                isAvailable,
              };
            }
          } else {
            dispatchItem = {
              ...item,
              isAvailable,
            };
          }

          this.caseVisitQuery.updateSubItemForActiveVisit(
            packageItem.packageId,
            dispatchItem
          );
        });
    });
  }

  buildVaccinationInfo(dispatchItem, vaccineSubItems = []) {
    const vaccineFormGroupArray = vaccineSubItems.map(item => {
      return new FormGroup({
        code: new FormControl(item.code),
        description: new FormControl(item.description),
        doseId: new FormControl(item.doseId ? item.doseId : '', this.isDoctor ? null : [Validators.required]),
        dosages: new FormControl(item.dosages)
      })
    });

    const itemFormGroup = new FormGroup({
      itemId: new FormControl(dispatchItem.itemId),
      dosageInstruction: new FormGroup({
        code: new FormControl(dispatchItem.dosageInstruction ? dispatchItem.dosageInstruction.code : '', this.isDoctor ? null : [Validators.required]),
        instruct: new FormControl(''),
      }),
      vaccinationInfo: this.getVaccinationInfoFG(dispatchItem.vaccinationInfo || {}, vaccineFormGroupArray),
      remarks: new FormControl(dispatchItem.remarks)
    });

    return itemFormGroup;
  }

  getVaccinationInfoFG(vaccinationInfo, vaccineFormGroupArray) {
    return new FormGroup({
      givenDate: new FormControl(vaccinationInfo.givenDate ? vaccinationInfo.givenDate : moment().format(DISPLAY_DATE_FORMAT), [Validators.required]),
      vaccineDosage: new FormControl(vaccinationInfo.vaccineDosage),
      administrator: new FormGroup({
        regNo: new FormControl(vaccinationInfo.administrator ? vaccinationInfo.administrator.regNo : '', [Validators.required]),
        name: new FormControl(vaccinationInfo.administrator ? vaccinationInfo.administrator.name : ''),
        profession: new FormControl(vaccinationInfo.administrator ? vaccinationInfo.administrator.profession : 'DOCTOR', [Validators.required])
      }),
      administrationInfo: new FormGroup({
        site: new FormControl(vaccinationInfo.administrationInfo ? vaccinationInfo.administrationInfo.site : '', this.isDoctor ? null : [Validators.required]),
        route: new FormControl(vaccinationInfo.administrationInfo ? vaccinationInfo.administrationInfo.route : '', [Validators.required]),
        notGiven: new FormControl(vaccinationInfo.administrationInfo ? vaccinationInfo.administrationInfo.notGiven : false),
        reason: new FormControl(vaccinationInfo.administrationInfo ? vaccinationInfo.administrationInfo.reason: '')
      }),
      multiVaccineSubItems: new FormArray(vaccineFormGroupArray),
      vaccinationSchedules: new FormArray([
        new FormGroup({
          vaccineId: new FormControl(vaccinationInfo.vaccinationSchedules && vaccinationInfo.vaccinationSchedules.length > 0 ? vaccinationInfo.vaccinationSchedules[0].vaccineId : ''),
          doseId: new FormControl(vaccinationInfo.vaccinationSchedules && vaccinationInfo.vaccinationSchedules.length > 0 ? vaccinationInfo.vaccinationSchedules[0].doseId : ''),
          scheduledDate: new FormControl(vaccinationInfo.vaccinationSchedules && vaccinationInfo.vaccinationSchedules.length > 0 ? vaccinationInfo.vaccinationSchedules[0].scheduledDate : ''),
          scheduledTime: new FormControl(vaccinationInfo.vaccinationSchedules && vaccinationInfo.vaccinationSchedules.length > 0 ? vaccinationInfo.vaccinationSchedules[0].scheduledTime : '')
        }),
      ])
    })
  }

  stopEventPropogation($event) {
    // To prevent bubbling of child click event to parent's onclick event
    event.stopPropagation();
  }

  deletePressed(event) {
    this.stopEventPropogation(event);
    this.consultationFormService.deletePackageItem(this.packageItem.packageId);
    this.deletePackageItem.emit(this.packageItem.packageId);
  }

  getInventory(subItem: IDispaches) {
    const itemId = subItem.itemId;

    return this.dispatchItemService
      .getDrugBatchDropdownList(itemId);
  }

  isItemDisabled(subItem: IDispaches): boolean {
    return (subItem.utilize && this.isSecondVisitOnwards && subItem.visitId !== this.visitId) || this.visitStatus.toLowerCase() === 'payment';
  }

  stopEvent() {
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  onItemSelect(item: any) {
    event.stopImmediatePropagation();
    event.stopPropagation();

    if (item) {
      const index: number = this.excludedItems.indexOf(item['planId']);
      if (index !== -1) {
        this.excludedItems.splice(index, 1);
        this.selectedItems.push(item);
      }
    }
    this.caseVisitQuery.updatePackageExcludedCoverage(
      this.packageItem.packageId,
      [...this.excludedItems]
    );
  }

  subscribeVaccineDetailsChange() {
    this.visitManagementService
      .getUpdateItemDetails()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((value) => {
        this.packageItem.dispatches.forEach((item, index) => {
          const itemFG = value[1];
          if (value[0] === index) {
            const updatedSubItem: IDispaches = {
              ...item,
              dosageInstruction: itemFG.get('dosageInstruction').value,
              remarks: itemFG.get('remarks').value,
              vaccinationInfo: itemFG.get('vaccinationInfo').getRawValue(),
            };

            this.caseVisitQuery.updateSubItemForActiveVisit(
              this.packageItem.packageId,
              updatedSubItem
            );
          }
        });

        this.alertService.setPackageItemUpdated(this.isPackageVaccinesValid());
      });
  }

  onItemDeSelect(item: any) {
    event.stopImmediatePropagation();
    event.stopPropagation();

    const planId = item.value.planId;
    this.excludedItems.push(planId);

    const index = this.selectedItems.indexOf(item => item.planId === planId);
    this.selectedItems.splice(index, 1);

    this.caseVisitQuery.updatePackageExcludedCoverage(
      this.packageItem.packageId,
      [...this.excludedItems]
    );
  }

  initGeneralDetails(prescriptionItem: FormGroup, option) {
    this.topChargeItemDescription = {
      chargeCode: '',
      charge: '',
      cautionary: '',
      sig: '',
      dosageInstruction: '',
      remarks: '',
      qty: '',
      uom: '',
      itemId: ''
    };

    prescriptionItem.addControl('vaccineDosageAvailableList', new FormControl(option.dosages.map(item => {
      return JSON.stringify(item);
    })));
    if(this.vaccineDosages === null || this.vaccineDosages === undefined || this.vaccineDosages.length === 0){
      this.vaccineDosages = prescriptionItem.get('vaccineDosageAvailableList').value;
      this.dosageInstructions = this.vaccineDosages.map(item => {
        return JSON.parse(item)
      });
    } else{
      if(prescriptionItem.get('vaccineDosageAvailableList') && prescriptionItem.get('vaccineDosageAvailableList').value){
        this.vaccineDosages = prescriptionItem.get('vaccineDosageAvailableList').value;
        this.dosageInstructions = this.vaccineDosages.map(item => {
          return JSON.parse(item)
        });
      } else{
        // first time adding vaccination from user search
        this.dosageInstructions = this.vaccineDosages;
      }
    }

    if(this.vaccineDosages){
      const vaccineDosageList = this.vaccineDosages.map(item => {
        return JSON.stringify(item);
      })
      prescriptionItem.addControl('vaccineDosageAvailableList', new FormControl(vaccineDosageList));
    } else{
      prescriptionItem.addControl('vaccineDosageAvailableList', new FormControl([]));
    }

    this.instructions = this.store.getInstructions().filter(instr=> !(instr['status'] && instr['status'] === 'INACTIVE'));
    this.dosageInstructions = this.dosageInstructions.filter(dose=> !(dose.status && dose.status === 'INACTIVE'));
  }

  createFormFroup(dispatchItem, index) {
    let vaccine = this.vaccinations.find(v => v.id === dispatchItem.itemId);
    const multiVaccineSubItems = dispatchItem.vaccinationInfo && dispatchItem.vaccinationInfo.multiVaccineSubItems;
    let subItems = vaccine && vaccine.multiVaccineSubItems;

    if (multiVaccineSubItems && multiVaccineSubItems.length > 0) {
      subItems = multiVaccineSubItems.map(subItem => {
        if (vaccine && vaccine.multiVaccineSubItems) {
          const item = vaccine.multiVaccineSubItems.find(item => item.code === subItem.code);
          return {...subItem, dosages: item.dosages}
        }
      });
    }

    let itemFormGroup = this.buildVaccinationInfo(dispatchItem, subItems && subItems.length > 0 ? subItems : []);
    this.patchVaccineData(itemFormGroup);
    this.itemFormGroups.set(index, itemFormGroup);

    return itemFormGroup;
  }

  patchVaccineData(itemFormGroup: FormGroup) {
    const itemId = itemFormGroup.get('drugId') || itemFormGroup.get('itemId');
    const vaccinationInfo = itemFormGroup.get('vaccinationInfo');
    const administrationInfo = vaccinationInfo.get('administrationInfo');
    const administrator = vaccinationInfo.get('administrator');

    if (itemId.value) {
      const vaccine = this.vaccinations.find(v => v.id === itemId.value);
      if (vaccine && vaccine.routeOfAdministration && !administrationInfo.get('route').value) {
        administrationInfo.get('route').patchValue(vaccine.routeOfAdministration);
        administrationInfo.get('route').updateValueAndValidity({ emitEvent: true });
      }
    }

    if (!administrator.get('regNo').value) {
      const visit = this.akitaCaseVisitQuery.getActive();
      let doctorId;
      if (visit.medicalReferenceEntity && visit.medicalReferenceEntity.consultation) {
        doctorId = visit.medicalReferenceEntity.consultation.doctorId;
      } else {
        doctorId = visit.preferredDoctorId;
      }

      if (doctorId) {
        this.doctors = this.store.doctorList.filter(doc => doc.id === doctorId);
        const loggedInDoctor = this.doctors.find(doc => doc.id === this.store.getUser().context['cms-user-id']);

        if (loggedInDoctor) {
          administrator.get('profession').patchValue('DOCTOR');
          administrator.get('regNo').patchValue(this.doctors.length > 0 ? this.doctors[0].mcr : loggedInDoctor.mcr);
          administrator.get('name').patchValue(this.doctors.length > 0 ? this.doctors[0].displayName : loggedInDoctor.displayName);
        } else {
          administrator.get('profession').patchValue('NURSE');
        }
      } else {
        administrator.get('profession').patchValue('NURSE');
      }
    }

    this.updateBodySiteValidity(itemFormGroup);
  }

  updateBodySiteValidity(itemFormGroup: FormGroup) {
    const administrationInfo = itemFormGroup.get('vaccinationInfo').get('administrationInfo');

    if (administrationInfo.get('route').value && this.routeOfAdministrations.length > 0) {
      const selectedROA = this.routeOfAdministrations.find(roa => roa.name === administrationInfo.get('route').value);
      if (selectedROA.bodySiteRequired && !this.isDoctor) {
        administrationInfo.get('site').setValidators([Validators.required]);
      } else {
        administrationInfo.get('site').setValidators(null);
      }

      administrationInfo.get('site').updateValueAndValidity({ emitEvent: true });
    }
  }

  getRouteOfAdministrations() {
    const routes = this.store.routeOfAdministrators;
    this.routeOfAdministrations = routes.filter(route => route.nehrCodeVaccine);
    for (let itemFormGroup of this.itemFormGroups.values()) {
      this.updateBodySiteValidity(itemFormGroup);
    }
    this.alertService.setPackageItemUpdated(this.isPackageVaccinesValid());
  }

  showEditVaccineDetails(dispatchItem, index) {
    let vaccine = this.vaccinations.find(v => v.id === dispatchItem.itemId);
    let itemFormGroup = this.createFormFroup(dispatchItem, index);
    this.initGeneralDetails(itemFormGroup, vaccine);
    this.subscribeVaccineDetailsChange();

    const initialState = {
      title: 'Vaccination additional details',
      prescriptionItem: this.itemFormGroups.get(index),
      vaccineName: dispatchItem.itemName,
      dosageInstructions: this.dosageInstructions,
      instructions: this.instructions,
      isPackageItem: true,
      isDoctorConsult: this.isDoctor,
      isDoctor: this.isDoctor,
      itemIndex: index,
    };

    this.modalService.show(VaccineDetailsModalComponent, {
      initialState,
      class: 'modal-xl',
      backdrop: 'static',
      keyboard: false,
    });
  }

  isVaccine(subItem) {
    const vaccine = this.vaccinations.find(vac => vac.id === subItem.itemId);

    if (vaccine) {
      return vaccine.itemType === 'VACCINATION';
    } else {
      return false;
    }
  }

  onClear() {
    this.selectedItems = [];
    this.selectedPlans.forEach(plan => {
      this.excludedItems.push(plan.planId);
    });

    this.caseVisitQuery.updatePackageExcludedCoverage(
      this.packageItem.packageId,
      [...this.excludedItems]
    );
  }
}
