import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AkitaCase {
  caseId: string;
}

export function createInitialState(): AkitaCase {
  return {
    caseId: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'akitaCase' })
export class AkitaCaseStore extends Store<AkitaCase> {
  constructor() {
    super(createInitialState());
  }
}
