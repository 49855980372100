import { PolicyHolderInfo } from './../PolicyHolderInfo';
import { Plan, ContactPerson, Address } from '../Plan';

export interface MedicalCoverageWithBalance {
  medicalCoverageResponse: MedicalCoverageResponse;
  balance: any;
}

export function createMedicalCoverageWithBalance(
  medicalCoverageResponse?: MedicalCoverageResponse,
  balance?: any
): MedicalCoverageWithBalance {
  return <MedicalCoverageWithBalance>{
    medicalCoverageResponse:
      medicalCoverageResponse === undefined
        ? this.createMedicalCoverageResponse()
        : medicalCoverageResponse,
    balance: balance === undefined ? {} : balance,
  };
}

// export class Address {
//   attentionTo?: string;
//   address?: string;
//   postalCode?: string;
// }

export interface MedicalCoverageResponse {
  INSURANCE?: Array<Insurance>;
  CORPORATE?: Array<Insurance>;
  CHAS?: Array<Insurance>;
  MEDISAVE?: Array<Insurance>;
}

// constructor(attentionTo?: string, address?: string, postalCode?: string) {
//   this.attentionTo = attentionTo || '';
//   this.address = address || '';
//   this.postalCode = postalCode || '';
// }
export function createMedicalCoverageResponse(
  INSURANCE?: Array<Insurance>,
  CORPORATE?: Array<Insurance>,
  CHAS?: Array<Insurance>,
  MEDISAVE?: Array<Insurance>
): MedicalCoverageResponse {
  return <MedicalCoverageResponse>{
    INSURANCE: INSURANCE === undefined ? new Array<Insurance>() : INSURANCE,
    CORPORATE: CORPORATE === undefined ? new Array<Insurance>() : CORPORATE,
    CHAS: CHAS === undefined ? new Array<Insurance>() : CHAS,
    MEDISAVE: MEDISAVE === undefined ? new Array<Insurance>() : MEDISAVE,
  };
}
// class Insurance {
//     id: string;
//     identificationNumber: IdentificationNumber;
//     name: string;
//     medicalCoverageId: string;
//     planId: string;
//     patientCoverageId: string;
//     specialRemarks: string;
//     status: string;
//     startDate: string;
//     endDate: string;

//     constructor(
//         id?: string,
//         identificationNumber?: IdentificationNumber,
//         name?: string,
//         medicalCoverageId?: string,
//         planId?: string,
//         patientCoverageId?: string,
//         specialRemarks?: string,
//         status?: string,
//         startDate?: string,
//         endDate?: string
//     ) {
//         this.id = id || '';
//         this.identificationNumber =
//             identificationNumber || new IdentificationNumber();
//         this.name = name || '';
//         this.medicalCoverageId = medicalCoverageId || '';
//         this.planId = planId || '';
//         this.patientCoverageId = patientCoverageId || '';
//         this.specialRemarks = specialRemarks || '';
//         this.status = status || '';
//         this.startDate = startDate || '';
//         this.endDate = endDate || '';
//     }
// }
export interface Insurance {
  policyHolder?: PolicyHolderInfo;
  coveragePlan?: Plan;
  contacts?: Array<ContactPerson>;
  address?: Address;
  medicalCoverageName?: string;
}

export function // Medical Coverage Response
createInsurance(
  policyHolder?: PolicyHolderInfo,
  coveragePlan?: Plan,
  contacts?: Array<ContactPerson>,
  address?: Address,
  medicalCoverageName?: string
): Insurance {
  return <Insurance>{
    policyHolder:
      policyHolder === undefined ? this.createPolicyHolderInfo() : policyHolder,
    coveragePlan:
      coveragePlan === undefined ? this.createCoverageSelected() : coveragePlan,
    contacts: contacts === undefined ? new Array() : contacts,
    address: address === undefined ? this.createAddress() : address,
    medicalCoverageName: medicalCoverageName || '',
  };
}
