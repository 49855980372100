// import { store } from '@angular/core/src/render3/instructions';
import { StoreService } from './../../../services/store.service';
import {
  MC_REASONS_DROPDOWN,
  MC_HALFDAY_OPTIONS,
} from './../../../constants/app.constants';
import { LoggerService } from './../../../services/logger.service';
import {
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  distinctUntilChanged,
  debounceTime,
  filter,
  takeUntil,
} from 'rxjs/operators';
import DatePickerConfig from '../../../objects/DatePickerConfig';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-medical-certificate-item-control',
  templateUrl: './medical-certificate-item-control.component.html',
})
export class MedicalCertificateItemControlComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() public index: number;
  @Input() public medicalCertificateItem: FormGroup;
  @Output() public removed: EventEmitter<number> = new EventEmitter<number>();
  @Output() onDelete = new EventEmitter<number>();

  minDate = new Date();
  maxDate = new Date(2018, 9, 15);

  bsValue: Date = new Date();
  bsRangeValue: any = [new Date(2017, 7, 4), new Date()];

  datePickerConfig: DatePickerConfig = new DatePickerConfig(
    'Start From',
    null,
    this.minDate,
    'bottom',
    'top'
  );

  private componentDestroyed: Subject<void> = new Subject();

  reasons = [];
  halfDayOptions = [];
  isOtherShown = false;
  otherReason = '';
  showDeleteBtn = true;

  static buildItem(
    purpose: string,
    startDate: string,
    numberOfDays: number,
    otherReason: string,
    remark: string,
    referenceNumber: string,
    halfDayOption: string
  ) {
    let form = new FormGroup({
      purpose: new FormControl(purpose),
      startDate: new FormControl(startDate),
      numberOfDays: new FormControl(numberOfDays),
      otherReason: new FormControl(otherReason),
      remark: new FormControl(remark),
      referenceNumber: new FormControl(referenceNumber),
      halfDayOption: new FormControl(halfDayOption),
    });
    return form;
  }

  constructor(private logger: LoggerService, private store: StoreService) {
    this.generateReasonsDropdown();
  }

  ngOnInit() {
    const purpose = this.medicalCertificateItem.get('purpose');
    const startDate = this.medicalCertificateItem.get('startDate');
    const numberOfDays = this.medicalCertificateItem.get('numberOfDays');
    const remark = this.medicalCertificateItem.get('remark');

    if (
      startDate.value === '' ||
      startDate.value === null ||
      startDate.value === undefined
    ) {
      startDate.patchValue(new Date());
    }

    if (
      numberOfDays.value === '' ||
      numberOfDays.value === null ||
      numberOfDays.value === 0
    ) {
      numberOfDays.patchValue(1);
    } else {
      this.roundValueToPointFive();
    }

    this.store
      .getVisitStatusRefresh()
      .pipe(
        distinctUntilChanged(),
        debounceTime(200),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(status => {
        this.showDeleteBtn = status !== 'PAYMENT' ? true : false;
      });

    remark.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(200),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        if (value) {
          this.addMandatorySetting(purpose);
        } else {
          this.removeMandatorySetting(purpose);
        }
      });

    purpose.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(200),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        this.onPurposeSelected(value);

        if (value) {
          this.addMandatorySetting(startDate);
          this.addMandatorySetting(numberOfDays);
        } else {
          this.removeMandatorySetting(startDate);
          this.removeMandatorySetting(numberOfDays);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  generateReasonsDropdown() {
    this.reasons = MC_REASONS_DROPDOWN;
    this.halfDayOptions = MC_HALFDAY_OPTIONS;
  }

  onPurposeSelected(option) {
    if (option) {
      if (option === 'OTHERS') {
        this.medicalCertificateItem
          .get('remark')
          .setValidators([Validators.required]);
        this.medicalCertificateItem.get('remark').markAsTouched();
        this.medicalCertificateItem.get('remark').updateValueAndValidity();
        // this.isOtherShown = true;
        // const otherReasonControl = this.medicalCertificateItem.get('otherReason');
        // otherReasonControl.setValidators(Validators.required);
        // otherReasonControl.markAsTouched();
        // otherReasonControl.updateValueAndValidity();
      } else {
        // this.hideAndRemoveOtherReasonValidator();
        this.medicalCertificateItem.get('remark').setValidators(null);
        this.medicalCertificateItem.get('remark').markAsTouched();
        this.medicalCertificateItem.get('remark').updateValueAndValidity();
      }
    } else {
      // this.hideAndRemoveOtherReasonValidator();
    }
  }

  addMandatorySetting(control: AbstractControl) {
    control.setValidators([Validators.required]);
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  removeMandatorySetting(control: AbstractControl) {
    control.setValidators(null);
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  hideAndRemoveOtherReasonValidator() {
    this.isOtherShown = false;
    const otherReasonControl = this.medicalCertificateItem.get('otherReason');
    otherReasonControl.patchValue('');
    otherReasonControl.setValidators(null);
    otherReasonControl.updateValueAndValidity();
  }

  roundValueToPointFive() {
    this.medicalCertificateItem
      .get('numberOfDays')
      .valueChanges.pipe(
        filter(term => {
          const isWholeNumber = term - Math.floor(term) == 0 ? true : false;
          if (!isWholeNumber) {
            const halfDayOption = this.medicalCertificateItem.get(
              'halfDayOption'
            );
            halfDayOption.setValidators([Validators.required]);
            halfDayOption.markAsTouched();
            halfDayOption.updateValueAndValidity({ emitEvent: false });
            halfDayOption.enable({ emitEvent: false });

            return term;
          } else {
            const halfDayOption = this.medicalCertificateItem.get(
              'halfDayOption'
            );
            halfDayOption.patchValue('', { emitEvent: false });
            halfDayOption.setValidators(null);
            halfDayOption.updateValueAndValidity({ emitEvent: false });
            halfDayOption.disable({ emitEvent: false });
          }
        }),
        distinctUntilChanged(),
        debounceTime(400),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        const roundedValue = Math.floor(value) + 0.5;
        value = roundedValue;
        this.medicalCertificateItem.get('numberOfDays').patchValue(value);
      });
  }

  isRoundNumber(value) {
    return value - Math.floor(value) == 0 ? true : false;
  }

  onBtnAddClicked() {}

  onbtnDeleteClicked() {
    this.removed.emit(this.index);
  }
}
