import { Injectable } from '@angular/core';
import { HttpResponseBody } from '../../../objects/response/HttpResponseBody';
import { Observable, of, Subject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../../services/app-config.service';

@Injectable()
export class PatientAddQueueConfirmationService {
  
  API_CASE_INFO_URL;

  selectMedicalCoverageSubject = new Subject();

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.API_CASE_INFO_URL = appConfig.getConfig().API_CASE_INFO_URL;
  }

  getOpenCases(patientId: string, clinicId: string): Observable<any> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/list/all/${clinicId}/${patientId}`,
      ''
    );
    // return this.getMockData();
  }

  getMockData() {
    return of({
      "statusCode": "S0000",
      "timestamp": "03-02-2020 05:24:06",
      "message": "Success",
      "payload": [{
        "caseId": "2018120500001",
        "caseNumber": "CASE_CODE_124",
        "patientId": "1234",
        "patientNRIC": "8699743438",
        "patientName": "Mr. Jhon",
        "clinicId": "1234",
        "visitIds": [{
          "visitId": "VISIT_ID_123"
        }],
        "coverages": [{
          "medicalCoverageId": "5b55aca80550de00210a7c53",
          "planId": "19457",
          "coverageId": "5da41a7bdbea1b10d3127ba7",
          "type": "MEDISAVE",
        }],
        "salesOrder": {
          "taxValue": 10,
          "salesRefNo": "SALE1234",
          "purchaseItem": [{
            "itemRefId": "13f2523df1fced99becba2fb78a4c457",
            "parentItemRefId": "ITEM_001",
            "cost": {
              "price": 0,
              "taxIncluded": false
            },
            "sellingPrice": {
              "price": 1050,
              "taxIncluded": false
            },
            "soldPrice": 100,
            "dosage": 10.0,
            "duration": 2,
            "purchaseQty": 100,
            "dispenseQty": 0,
            "instruct": "Eat every day",
            "batchNo": "BB-101",
            "purchaseUom": "TABLET",
            "expireDate": "03-02-2020",
            "remarks": "This is a remark",
            "itemPriceAdjustment": {
              "adjustedValue": 10,
              "paymentType": "DOLLAR",
              "remark": ""
            },
            "itemType": "DRUG",
            "excludedCoveragePlanIds": [],
            "itemDiscount": 0,
            "salesItemId": "c2603aafabbb144d9bb7ad822d1e62aa",
            "itemCode": "ITEM_CODE_001",
            "itemName": "ITEM_NAME1",
            "inventoried": true
          }],
          "invoices": [{
            "invoiceNumber": "123456677",
            "paymentMode": "CASH",
            "invoiceTime": "03-02-2020T17:24:06",
            "paidTime": "03-02-2020T17:24:06",
            "payableAmount": 1000,
            "paidAmount": 1000,
            "includedTaxAmount": 10,
            "planId": "PLAN_ID_1111",
            "reference": "References",
            "invoiceState": "INITIAL",
            "invoiceType": "DIRECT",
            "claim": {
              "claimId": "CLAIM_ID",
              "submissionDateTime": "03-02-2020T17:24:06",
              "attendingDoctorId": "DoctorId",
              "claimDoctorId": "ClaimedDoctor_ID",
              "payersNric": "863743629",
              "payersName": "Mr.Jhon",
              "diagnosisCodes": ["DIAG_CODE_12"],
              "consultationAmt": 1000,
              "medicationAmt": 100,
              "medicalTestAmt": 1000,
              "otherAmt": 1000,
              "claimExpectedAmt": 1000,
              "gstAmount": 0,
              "remark": "Claim is pending",
              "claimStatus": "PENDING",
              "claimResult": {
                "referenceNumber": "REF_NUMBER_100",
                "resultDateTime": "03-02-2020T17:24:06",
                "amount": 2000,
                "statusCode": "STATUS_111",
                "remark": "Last claim"
              },
              "paidResult": {
                "referenceNumber": "REF_NUMBER_100",
                "resultDateTime": "03-02-2020T17:24:06",
                "amount": 2000,
                "statusCode": "STATUS_111",
                "remark": "Last claim"
              },
              "submissionResult": {
                "claimNo": "CLAIM_NO",
                "statusCode": "STATUS_CODE",
                "statusDescription": "STATUS DESCRIPTION"
              },
              "appealRejections": [{
                "reason": "PolicyExpired"
              }]
            },
            "salesDiscount": {
              "discountType": "DOLLAR",
              "discountValue": 500
            }
          }],
          "status": "OPEN",
          "totalPrice": 11000,
          "totalPayableTax": 100,
          "totalPaid": 10000,
          "outstanding": 1000
        },
        "status": "OPEN",
        "caseStartDateTime": "03-02-2020T17:24:06",
        "patientVisitEntities": [],
        "remarks": "Created case for package",
        "hasOpenVisits": false,
        "packages": [{
          "itemRefId": "ITEM_001",
          "code": "ITEM_CODE",
          "name": "Package1",
          "packageQty": 10,
          "purchasePrice": 10000,
          "purchaseDate": "03-02-2020T17:24:06",
          "expireDate": "03-02-2020T17:24:06",
          "status": "ON_GOING",
          "dispatches": [{
            "itemRefId": "SUB_ITEM_REF_ID",
            "purchasedId": "SALES_ITEM_CODE",
            "itemCode": "ITEM_CODE",
            "itemName": "Item Name",
            "utilize": true,
            "payable": false,
            "utilizedDate": "03-02-2020T17:24:06"
          }, {
            "itemRefId": "SUB_ITEM_REF_ID",
            "purchasedId": "SALES_ITEM_CODE",
            "itemCode": "ITEM_CODE",
            "itemName": "Item Name",
            "utilize": false,
            "payable": false,
            "utilizedDate": "03-02-2020T17:24:06"
          }]
        }, {
          "itemRefId": "ITEM_001",
          "code": "ITEM_CODE",
          "name": "Package2",
          "packageQty": 10,
          "purchasePrice": 10000,
          "purchaseDate": "03-02-2020T17:24:06",
          "expireDate": "03-02-2020T17:24:06",
          "status": "ON_GOING",
          "dispatches": [{
            "itemRefId": "SUB_ITEM_REF_ID",
            "purchasedId": "SALES_ITEM_CODE",
            "itemCode": "ITEM_CODE",
            "itemName": "Item Name",
            "utilize": false,
            "payable": false,
            "utilizedDate": "03-02-2020T17:24:06"
          }]
        }],
        "secondaryDoctorIds": [],
        "package": true,
        "singleVisit": false
      }, {
        "caseId": "20181205000001",
        "caseNumber": "CASE_CODE_1234",
        "patientId": "1234",
        "patientNRIC": "8699743438",
        "patientName": "Mr. Jhon",
        "clinicId": "1234",
        "visitIds": [{
          "visitId": "VISIT_ID_123"
        }],
        "coverages": [{
          "medicalCoverageId": "MED_COV_ID_001",
          "planId": "PLAN_ID_001",
          "name": "PLAN_NAME"
        }],
        "salesOrder": {
          "taxValue": 10,
          "salesRefNo": "SALE1234",
          "purchaseItem": [{
            "itemRefId": "c5e3c8f749bc07d0e9e1bc7f6d572816",
            "parentItemRefId": "ITEM_001",
            "cost": {
              "price": 0,
              "taxIncluded": false
            },
            "sellingPrice": {
              "price": 1050,
              "taxIncluded": false
            },
            "soldPrice": 100,
            "dosage": 10.0,
            "duration": 2,
            "purchaseQty": 100,
            "dispenseQty": 0,
            "instruct": "Eat every day",
            "batchNo": "BB-101",
            "purchaseUom": "TABLET",
            "expireDate": "03-02-2020",
            "remarks": "This is a remark",
            "itemPriceAdjustment": {
              "adjustedValue": 10,
              "paymentType": "DOLLAR",
              "remark": ""
            },
            "itemType": "DRUG",
            "excludedCoveragePlanIds": [],
            "itemDiscount": 0,
            "salesItemId": "bc02ec0c7a16fce18d387672a6aef049",
            "itemCode": "ITEM_CODE_001",
            "itemName": "ITEM_NAME1",
            "inventoried": true
          }],
          "invoices": [{
            "invoiceNumber": "123456677",
            "paymentMode": "CASH",
            "invoiceTime": "03-02-2020T17:24:06",
            "paidTime": "03-02-2020T17:24:06",
            "payableAmount": 1000,
            "paidAmount": 1000,
            "includedTaxAmount": 10,
            "planId": "PLAN_ID_1111",
            "reference": "References",
            "invoiceState": "INITIAL",
            "invoiceType": "DIRECT",
            "claim": {
              "claimId": "CLAIM_ID",
              "submissionDateTime": "03-02-2020T17:24:06",
              "attendingDoctorId": "DoctorId",
              "claimDoctorId": "ClaimedDoctor_ID",
              "payersNric": "863743629",
              "payersName": "Mr.Jhon",
              "diagnosisCodes": ["DIAG_CODE_12"],
              "consultationAmt": 1000,
              "medicationAmt": 100,
              "medicalTestAmt": 1000,
              "otherAmt": 1000,
              "claimExpectedAmt": 1000,
              "gstAmount": 0,
              "remark": "Claim is pending",
              "claimStatus": "PENDING",
              "claimResult": {
                "referenceNumber": "REF_NUMBER_100",
                "resultDateTime": "03-02-2020T17:24:06",
                "amount": 2000,
                "statusCode": "STATUS_111",
                "remark": "Last claim"
              },
              "paidResult": {
                "referenceNumber": "REF_NUMBER_100",
                "resultDateTime": "03-02-2020T17:24:06",
                "amount": 2000,
                "statusCode": "STATUS_111",
                "remark": "Last claim"
              },
              "submissionResult": {
                "claimNo": "CLAIM_NO",
                "statusCode": "STATUS_CODE",
                "statusDescription": "STATUS DESCRIPTION"
              },
              "appealRejections": [{
                "reason": "PolicyExpired"
              }]
            },
            "salesDiscount": {
              "discountType": "DOLLAR",
              "discountValue": 500
            }
          }],
          "status": "OPEN",
          "totalPrice": 11000,
          "totalPayableTax": 100,
          "totalPaid": 10000,
          "outstanding": 1000
        },
        "status": "OPEN",
        "caseStartDateTime": "03-02-2020T17:24:06",
        "patientVisitEntities": [],
        "remarks": "Created case for package",
        "hasOpenVisits": false,
        "packages": [],
        "secondaryDoctorIds": [],
        "package": false,
        "singleVisit": false
      }]
    })
  }
}
