import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ApiPatientInfoService } from '../../../../../services/api-patient-info.service';
import { MedicalCoverageService } from '../../medical-coverage.service';

import { Plan } from '../../../../../objects/Plan';
import { MedicalCoverage } from './../../../../../objects/MedicalCoverage';
import PatientInfo from '../../../../../objects/response/PatientInfo';
import { AkitaPatientAppQuery } from '../../../../../services/states/akita-patient/akita-patient-app.query';
import { AkitaCaseVisitQuery } from '../../../../../services/states/akita-case-visit.query';

import * as moment from 'moment'

@Component({
  selector: 'app-medical-coverage-item-detail',
  templateUrl: './medical-coverage-item-detail.component.html',
  styleUrls: ['./medical-coverage-item-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalCoverageItemDetailComponent implements OnInit, OnDestroy {
  @Input() remarks: string;
  @Input() coverage: MedicalCoverage;
  @Input() plan: Plan;
  @Input() visitId: string;
  @Input() sflEligibilityItems: any[]=[];

  
  
  private patientInfo: PatientInfo;
  @Output() onHandlePlanChange = new EventEmitter<any>();

  milestoneRecords;
  public relationshipDetails = [];
  public selectedPayer: any;
  private componentDestroyed: Subject<void> = new Subject();

  public isPayerBalanceLoading: boolean = false;

  constructor(
    private medicalCoverageService: MedicalCoverageService,
    private patientAppQuery: AkitaPatientAppQuery,
    private apiPatientInforService: ApiPatientInfoService,
    private akitaVisitQuery: AkitaCaseVisitQuery
    ) {}

  ngOnInit() {
    if (
      this.plan.cdsPlan &&
      this.plan.cdsQueryStatus &&
      this.plan.cdsQueryStatus.checkedMilestones
    ) {
      this.milestoneRecords = this.plan.cdsQueryStatus.checkedMilestones;
    }
    this.getPatientInfo();
  }
  
  getPatientInfo() {
    this.patientAppQuery.patientInfo$
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      )
      .subscribe((info: any) => {
        this.patientInfo = info;
        
        this.relationshipDetails = [];

        let selfObj = {
          name: 'Self',
          selected: true,
        };
        this.relationshipDetails.push(selfObj);
        info.relationshipEntities.forEach(item => {
          if (item.medisave == true ) {
            if(moment(item.consentValidTo, 'DD-MM-YYYY') >= moment().startOf('day') || item.consentValidTo === undefined || item.consentValidTo === null ) {
              this.relationshipDetails.push(item);
            }
          }
        });

        if(this.plan.medisavePlan && this.plan.medisavePlan === true && this.plan.payerUserId) {
          this.relationshipDetails.forEach(element => {
            if (element.userId && (element.userId.number === this.plan.payerUserId.number)) {
              this.selectedPayer = element.name;
              this.onSelectPayer(element);
            }
          });
        } else {
          this.selectedPayer = 'Self';
        }
      });
  }

  public onSelectPayer(data: any): void {
    if(data.name && data.name === 'Self') {
      this.onHandlePlanChange.emit('SELF');
      this.isPayerBalanceLoading = false;
    } else {
      this.medicalCoverageService.setPayerLoadingStatus(true);
      this.onHandlePlanChange.emit({
        payerUserId: {
          idType: data.userId.idType,
          number: data.userId.number
        },
      });
      this.isPayerBalanceLoading = true;
      const visitId = this.visitId ? this.visitId : this.akitaVisitQuery.getActiveVisitId();
      this.apiPatientInforService.getRelationsPackageDetails(this.patientInfo.userId, visitId, data.userId.idType, data.userId.number).subscribe(result => {
        if(result.statusCode === 'S0000') {
          if(result.payload && result.payload.MEDISAVE) {
            
            let medisaveStatus;
            
            result.payload.MEDISAVE.forEach(item => {
              if(item.coveragePlan.id === this.plan.id) {
                medisaveStatus = item.medisaveStatus;
              }
            });

            this.onHandlePlanChange.emit({
              medisaveStatus: medisaveStatus
            });
            
            this.isPayerBalanceLoading = false;
            this.medicalCoverageService.setPayerLoadingStatus(false);
          }
        }
      }, error => {
        this.isPayerBalanceLoading = false;
        this.medicalCoverageService.setPayerLoadingStatus(false);
      });
    }
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}