import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaDoctorStore } from './akita-doctor.store';
import { Doctor, STATUS } from '../../objects/state/Doctor';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AkitaDoctorQuery extends QueryEntity<Doctor> {
  activeDoctor$ = this.selectAll({
    filterBy: (entity: Doctor) => entity.status === STATUS.ACTIVE,
  });
  doctors$ = this.selectAll();

  constructor(protected store: AkitaDoctorStore) {
    super(store);
  }

  getDoctor(doctorId, val?) {
    const doctor = <Doctor>this.getAll({
      filterBy: (entity: Doctor) => entity.id === doctorId,
    })[0];
    return doctor
      ? val && doctor.hasOwnProperty(val)
        ? doctor[val]
        : doctor
      : undefined;
  }

  selectDoctorsById(doctorIdArray) {
    if (doctorIdArray && doctorIdArray.length > 0) {
      return this.selectAll().pipe(
        map((doctors: Array<Doctor>) => doctors.filter(doctor => doctorIdArray.includes(doctor.id)))
      );
    }
    return of([]);
  }
}
