import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'
import { DISPLAY_DATE_FORMAT } from '../constants/app.constants';

@Pipe({
  name: 'inventoryDisplayItemsSumQuantity'
})
export class InventoryDisplayItemsSumQuantityPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.reduce((sum, item) => {
      if (!item.expiryDate || !this.isItemExpired(item.expiryDate)) {
        sum += item.baseUomQuantity;
      }
      return sum;
    }, 0);
  }

  isItemExpired(expiryDateStr) {
    if (!expiryDateStr || expiryDateStr === '31-12-0001') return false;
    const expiryDate = moment(expiryDateStr, DISPLAY_DATE_FORMAT);
    return expiryDate.isBefore(moment()) && expiryDate.format(DISPLAY_DATE_FORMAT) !== moment().format(DISPLAY_DATE_FORMAT);
  }

}
