import { ClinicCalendarService } from './../../../../state/appointments/event-types/clinic-calendar/clinic-calendar.service';
import { DoctorCalendarService } from './../../../../state/appointments/event-types/doctor-calendar/doctor-calendar.service';
import { AppointmentMasterQuery } from './../../../../state/appointments/appointment-master/appointment-master.query';
import { UtilsService } from './../../../../services/utils.service';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import {
  DOCTOR_BLOCK_TYPE,
  INPUT_DELAY,
  DB_FULL_DATE_FORMAT_NO_SECOND,
  DISPLAY_DATE_FORMAT,
  DOCTOR_BLOCKED_TIME,
  DOCTOR_LEAVE,
  DAY_OF_WEEK,
  SERVICE_BLOCKED_TIME,
} from './../../../../constants/app.constants';
import { ApiAppointmentsService } from './../../../../services/api-appointments.service';
import { AlertService } from './../../../../services/alert.service';
import { Doctor } from './../../../../objects/SpecialityByClinic';
import { SelectItemOptions } from './../../../../objects/SelectItemOptions';
import { StoreService } from './../../../../services/store.service';
import {
  FormGroup,
  FormControl,
  AbstractControl,
  Validators,
} from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  HostListener,
} from '@angular/core';
import DatePickerConfig from '../../../../objects/DatePickerConfig';

import * as moment from 'moment';
import { ClinicCalendarQuery } from '../../../../state/appointments/event-types/clinic-calendar/clinic-calendar.query';
import { DoctorCalendarQuery } from '../../../../state/appointments/event-types/doctor-calendar/doctor-calendar.query';
import { BlockDate } from '../../../../objects/ClinicCalendar';
import { AppointmentsFactoryService } from '../../../../services/appointments-factory.service';
import { ServiceCalendarQuery } from '../../../../state/appointments/event-types/service-calendar/service-calendar.query';

@Component({
  selector: 'app-appointments-block-doctor-time',
  templateUrl: './appointments-block-doctor-time.component.html',
  styleUrls: ['./appointments-block-doctor-time.component.scss'],
})
export class AppointmentsBlockDoctorTimeComponent implements OnInit {
  @Input() title: string;
  @Input() blockedTimeFormGroup: FormGroup;
  @Input() editMode: boolean;
  @Input() editBlockTime: any;
  @Input() endDateForEdit: string;
  @Input() startDateForEdit: string;
  @Input() leaveType: string;
  @Input() doctorIdForEdit: string;
  @Input() leaveEvent: any;

  public event: EventEmitter<any> = new EventEmitter();
  doctors: Array<SelectItemOptions<Doctor>>;
  datePickerConfigArray: Array<DatePickerConfig>;
  startTime: FormControl = new FormControl();
  endTime: FormControl = new FormControl();
  endDateFC: FormControl = new FormControl();
  blockType: FormControl = new FormControl();
  selectionType: FormControl = new FormControl();
  serviceName: FormControl = new FormControl('', Validators.required);

  blockTypes = [];
  selectionTypes = ['Clinic', 'Doctor', 'Services'];
  defaultMinutes = 60;
  weekNoList = ['1', '2', '3', '4'];
  dayList = DAY_OF_WEEK;
  serviceNames = [];

  doctorId: AbstractControl;
  startDate: AbstractControl;
  duration: AbstractControl;
  remarks: AbstractControl;

  private onSubmitClicked: boolean = false;

  constructor(
    private storeService: StoreService,
    private alertService: AlertService,
    private utilsService: UtilsService,
    private apiAppointmentService: ApiAppointmentsService,
    private appointmentMasterQuery: AppointmentMasterQuery,
    private doctorCalendarService: DoctorCalendarService,
    private clinicCalendarService: ClinicCalendarService,
    private clinicCalendarQuery: ClinicCalendarQuery,
    private doctorCalendarQuery: DoctorCalendarQuery,
    private serviceCalendarQuery: ServiceCalendarQuery,
    private apptFactory: AppointmentsFactoryService
  ) { }

  ngOnInit() {
    this.initialiseVariables();
    this.subscribeChanges();
    this.initDatePickerConfigsForComponent();
    this.populateDoctor();

    this.blockTypes = DOCTOR_BLOCK_TYPE;
    if (!this.editMode) {
      this.startDate.patchValue(
        this.utilsService.roundTimeUpToNearest(new Date(), 15)
      );
      this.selectionType.patchValue(this.selectionTypes[0]);
    } else {
      this.setFormValuesToEditMode();
    }
  }

  setFormValuesToEditMode() {
    this.startTime.patchValue(this.startDateForEdit);
    this.endDateFC.patchValue(this.endDateForEdit);
    if (this.leaveType === DOCTOR_BLOCKED_TIME || this.leaveType === DOCTOR_LEAVE) {
      this.selectionType.patchValue(this.selectionTypes[1]);
      if (this.leaveType === DOCTOR_BLOCKED_TIME) {
        this.blockType.patchValue('BL');
      } else if (this.leaveType === DOCTOR_LEAVE) {
        this.blockType.patchValue('AL');
      }
      this.doctorId.patchValue(this.doctorIdForEdit);
    } else if (this.leaveType === SERVICE_BLOCKED_TIME) {
      this.selectionType.patchValue(this.selectionTypes[2]);
      this.serviceName.patchValue(this.leaveEvent.serviceCalendar && this.leaveEvent.serviceCalendar.service);
    } else {
      this.selectionType.patchValue(this.selectionTypes[0]);
    }
  }

  initialiseVariables() {
    this.doctorId = this.blockedTimeFormGroup.get('doctorId');
    this.startDate = this.blockedTimeFormGroup.get('startDate');
    this.duration = this.blockedTimeFormGroup.get('duration');
    this.remarks = this.blockedTimeFormGroup.get('remarks');
    this.blockedTimeFormGroup.addControl('everyWeek', new FormControl());
    this.blockedTimeFormGroup.addControl('daysOfWeek', new FormControl());
    this.getServices();
  }

  onBtnCancelClicked() {
    this.event.emit('Close');
  }

  onBtnSubmitClicked() {
    this.onSubmitClicked = true;
    const clinic = this.storeService.getClinicId();
    const doctorId = this.doctorId.value;
    const startTime = moment(this.startTime.value).format(
      DB_FULL_DATE_FORMAT_NO_SECOND
    );
    let endTime = moment(this.endTime.value).format(
      DB_FULL_DATE_FORMAT_NO_SECOND
    );
    let type = this.blockType.value;
    let remark = { remark: this.remarks.value };
    let payload = {
      remark: this.remarks.value,
      everyWeek: this.blockedTimeFormGroup.get('everyWeek').value,
      daysOfWeek: this.blockedTimeFormGroup.get('daysOfWeek').value && this.blockedTimeFormGroup.get('daysOfWeek').value.toString()
    }
    let isRepeatsOn = payload.daysOfWeek ? true : false;
    if (!this.validateInput(startTime, endTime, isRepeatsOn)) {
      return;
    }

    if (payload.everyWeek && !payload.daysOfWeek) {
      alert("Pleae select day(s) of week to add repeats")
    } else {
      if (this.selectionType.value === this.selectionTypes[0]) {
        if (this.editMode) {
          let blockList;
          this.clinicCalendarQuery.clinicToCalendarAppointments$.subscribe(item => {
            blockList = [];
            item.forEach(appo => {
              if (appo.type === "CLINIC_BLOCKED_TIME") {
                if (JSON.stringify(appo.blockDate) === JSON.stringify(this.editBlockTime)) {
                  if (payload.everyWeek && payload.daysOfWeek) {
                    // Edit mode has repeats
                    let start = moment(startTime, DISPLAY_DATE_FORMAT);
                    let end = moment(endTime, DISPLAY_DATE_FORMAT);
                    let datesUntil: Array<any> = this.getDatesBetweenRange(start, end);

                    if (payload.daysOfWeek) {
                      payload.daysOfWeek.split(',').forEach(dayOfWeek => {
                        let filteredDates = datesUntil.filter(date => moment(date, DISPLAY_DATE_FORMAT).format('dddd').toUpperCase() === dayOfWeek);
                        filteredDates.forEach((d, index) => {

                          if (index % payload.everyWeek === 0) {
                            let temp = {
                              calendarDayRange: {
                                endDate: d,
                                startDate: d
                              },
                              start: startTime.split('T')[1],
                              end: endTime.split('T')[1],
                              remarks: this.remarks.value
                            }
                            blockList.push(temp);
                          }
                        })
                      });
                    }

                  } else if (payload.daysOfWeek && !payload.everyWeek) {
                    // Edit mode with only days of week
                    let start = moment(startTime, DISPLAY_DATE_FORMAT);
                    let end = moment(endTime, DISPLAY_DATE_FORMAT);
                    let datesUntil: Array<any> = this.getDatesBetweenRange(start, end);

                    if (payload.daysOfWeek) {
                      payload.daysOfWeek.split(',').forEach(dayOfWeek => {
                        let filteredDates = datesUntil.filter(date => moment(date, DISPLAY_DATE_FORMAT).format('dddd').toUpperCase() === dayOfWeek);
                        filteredDates.forEach((d, index) => {
                          let temp = {
                            calendarDayRange: {
                              endDate: d,
                              startDate: d
                            },
                            start: startTime.split('T')[1],
                            end: endTime.split('T')[1],
                            remarks: this.remarks.value
                          }
                          blockList.push(temp);
                        })
                      });
                    }

                  } else {
                    // Edit mode without repeats
                    appo.blockDate.calendarDayRange = {
                      endDate: endTime.split('T')[0],
                      startDate: startTime.split('T')[0]
                    }
                    appo.blockDate.start = startTime.split('T')[1];
                    appo.blockDate.end = endTime.split('T')[1];
                    appo.blockDate.remarks = this.remarks.value;
                    blockList.push(appo.blockDate);
                  }
                } else {
                  blockList.push(appo.blockDate);
                }
              }
            })
          });

          this.apiAppointmentService
            .updateClinicBlockTime(clinic, blockList)
            .subscribe(
              (res) => {
                if (res.statusCode === 'S0000') {
                  this.alertService.success('Clinic block time updated successfully');
                  this.clinicCalendarService.getClinicCalendar();
                } else {
                  this.alertService.error(res.message);
                }
              },
              err => {
                this.alertService.error(JSON.stringify(err.error.message));
              }
            );
        } else {
          this.apiAppointmentService
            .addClinicBlockTime(clinic, startTime, endTime, payload)
            .subscribe(
              () => {
                this.clinicCalendarService.getClinicCalendar();
              },
              err => {
                this.alertService.error(JSON.stringify(err.error.message));
              }
            );
        }
      } else if (this.selectionType.value === this.selectionTypes[1]) {
        if (type === 'AL') {
          if (this.editMode) {
            let blockList = this.getUpdatedBlockOrLeaveList(startTime, endTime, remark, doctorId, "DOCTOR_LEAVE", payload);
            this.apiAppointmentService
              .updateDoctorLeave(clinic, doctorId, blockList)
              .subscribe(
                (res) => {
                  if (res.statusCode === 'S0000') {
                    this.alertService.success('Doctor leave updated successfully');
                    this.doctorCalendarService.getDoctorCalendarById(doctorId);
                  } else {
                    this.alertService.error(res.message);
                  }
                },
                err => {
                  this.alertService.error(JSON.stringify(err.error.message));
                }
              );
          } else {
            this.apiAppointmentService
              .addDoctorLeave(clinic, doctorId, startTime, endTime, payload)
              .subscribe(
                () => {
                  this.doctorCalendarService.getDoctorCalendarById(doctorId);
                },
                err => {
                  this.alertService.error(JSON.stringify(err.error.message));
                }
              );
          }
        } else if (type === 'BL') {
          if (this.editMode) {
            let blockList = this.getUpdatedBlockOrLeaveList(startTime, endTime, remark, doctorId, "DOCTOR_BLOCKED_TIME", payload);
            this.apiAppointmentService
              .updateDoctorBlockTime(clinic, doctorId, blockList)
              .subscribe(
                (res) => {
                  if (res.statusCode === 'S0000') {
                    this.alertService.success('Doctor block time updated successfully');
                    this.doctorCalendarService.getDoctorCalendarById(doctorId);
                  } else {
                    this.alertService.error(res.message);
                  }
                },
                err => {
                  this.alertService.error(JSON.stringify(err.error.message));
                }
              );
          } else {
            this.apiAppointmentService
              .addDoctorBlockTime(clinic, doctorId, startTime, endTime, payload)
              .subscribe(
                () => {
                  this.doctorCalendarService.getDoctorCalendarById(doctorId);
                },
                err => {
                  this.alertService.error(JSON.stringify(err.error.message));
                }
              );
          }
        }
      } else {
        // Services
        let selectedService = this.serviceNames.find(serv => serv.service === this.serviceName.value);
        let group = selectedService.group;
        let service = this.serviceName.value;
        if (this.editMode) {
          let blockList = this.getUpdatedServiceBlockList(startTime, endTime, remark, "SERVICE_BLOCKED_TIME", payload, service);
          
          this.apiAppointmentService
            .updateServiceBlockTime(group, service, clinic, blockList)
            .subscribe(
              () => {
                this.clinicCalendarService.getClinicCalendar();
              },
              err => {
                this.alertService.error(JSON.stringify(err.error.message));
              }
            );
        } else {
          this.apiAppointmentService
            .addServiceBlockTime(group, service, clinic, startTime, endTime, payload)
            .subscribe(
              () => {
                this.clinicCalendarService.getClinicCalendar();
              },
              err => {
                this.alertService.error(JSON.stringify(err.error.message));
              }
            );
        }
      }
      this.event.emit('Close');
    }
    localStorage.setItem('calendarCurrentDate', this.appointmentMasterQuery.getViewDate().toString());
    this.alertService.sendCalendarEvent();
  }

  validateInput(startTime: string, endTime: string, isRepeatsOn: boolean) {
    if (isRepeatsOn) {
      if (this.validateInput(startTime, endTime, false)) {
        const start = moment(startTime, DB_FULL_DATE_FORMAT_NO_SECOND);
        const sameDayWithEndTime = startTime.split('T')[0].concat(endTime.split('T')[1]);
        const end = moment(sameDayWithEndTime, DB_FULL_DATE_FORMAT_NO_SECOND);
        if (start.isBefore(end)) {
          return true;
        } else {
          alert('Start time should be lower than end time for a selected day')
          return false;
        }
      }

    } else {
      const start = moment(startTime, DB_FULL_DATE_FORMAT_NO_SECOND);
      const end = moment(endTime, DB_FULL_DATE_FORMAT_NO_SECOND);
      if (start.isBefore(end)) {
        return true;
      } else {
        alert('Start date/time should be lower than end date/time');
        this.onSubmitClicked = false;
        return false;
      }
    }
  }

  getUpdatedBlockOrLeaveList(startTime: string, endTime: string, remark: any, doctorId: string, type: string, payload: any) {
    let blockList;
    this.doctorCalendarQuery.selectAllDocCalToAppointment$.subscribe(item => {
      blockList = [];
      item.forEach(appo => {
        if (appo.type === type && appo.doctorId === doctorId) {
          if (JSON.stringify(appo.blockDate) === JSON.stringify(this.editBlockTime)) {
            if (payload.everyWeek && payload.daysOfWeek) {
              // Edit mode has repeats
              let start = moment(startTime, DISPLAY_DATE_FORMAT);
              let end = moment(endTime, DISPLAY_DATE_FORMAT);
              let datesUntil: Array<any> = this.getDatesBetweenRange(start, end);

              if (payload.daysOfWeek) {
                payload.daysOfWeek.split(',').forEach(dayOfWeek => {
                  let filteredDates = datesUntil.filter(date => moment(date, DISPLAY_DATE_FORMAT).format('dddd').toUpperCase() === dayOfWeek);
                  filteredDates.forEach((d, index) => {

                    if (index % payload.everyWeek === 0) {
                      let temp = {
                        calendarDayRange: {
                          startDate: d,
                          endDate: d
                        },
                        start: startTime.split('T')[1],
                        end: endTime.split('T')[1],
                        remarks: this.remarks.value
                      }
                      blockList.push(temp);
                    }
                  })
                });
              }

            } else if (payload.daysOfWeek && !payload.everyWeek) {
              // Edit mode with only days of week
              let start = moment(startTime, DISPLAY_DATE_FORMAT);
              let end = moment(endTime, DISPLAY_DATE_FORMAT);
              let datesUntil: Array<any> = this.getDatesBetweenRange(start, end);

              if (payload.daysOfWeek) {
                payload.daysOfWeek.split(',').forEach(dayOfWeek => {
                  let filteredDates = datesUntil.filter(date => moment(date, DISPLAY_DATE_FORMAT).format('dddd').toUpperCase() === dayOfWeek);
                  filteredDates.forEach((d, index) => {
                    let temp = {
                      calendarDayRange: {
                        startDate: d,
                        endDate: d
                      },
                      start: startTime.split('T')[1],
                      end: endTime.split('T')[1],
                      remarks: this.remarks.value
                    }
                    blockList.push(temp);
                  })
                });
              }

            } else {
              // Edit mode without repeats
              appo.blockDate.calendarDayRange = {
                startDate: startTime.split('T')[0],
                endDate: endTime.split('T')[0]
              }
              appo.blockDate.start = startTime.split('T')[1];
              appo.blockDate.end = endTime.split('T')[1];
              appo.blockDate.remarks = this.remarks.value;
              blockList.push(appo.blockDate);
            }

          } else {
            blockList.push(appo.blockDate);
          }
        }
      })
    });
    return blockList;
  }

  getUpdatedServiceBlockList(startTime: string, endTime: string, remark: any, type: string, payload: any, service?:any) {
    let blockList;
    this.serviceCalendarQuery.selectAllServiceCalToAppointment$.subscribe(item => {
      const updatedItems = item.filter(calendarItem => calendarItem.serviceCalendar.service === service);
      
      blockList = [];
      updatedItems.forEach(appo => {
        if (appo.type === type) {
          if (JSON.stringify(appo.blockDate) === JSON.stringify(this.editBlockTime)) {
            if (payload.everyWeek && payload.daysOfWeek) {
              // Edit mode has repeats
              let start = moment(startTime, DISPLAY_DATE_FORMAT);
              let end = moment(endTime, DISPLAY_DATE_FORMAT);
              let datesUntil: Array<any> = this.getDatesBetweenRange(start, end);

              if (payload.daysOfWeek) {
                payload.daysOfWeek.split(',').forEach(dayOfWeek => {
                  let filteredDates = datesUntil.filter(date => moment(date, DISPLAY_DATE_FORMAT).format('dddd').toUpperCase() === dayOfWeek);
                  filteredDates.forEach((d, index) => {

                    if (index % payload.everyWeek === 0) {
                      let temp = {
                        calendarDayRange: {
                          startDate: d,
                          endDate: d
                        },
                        start: startTime.split('T')[1],
                        end: endTime.split('T')[1],
                        remarks: this.remarks.value
                      }
                      blockList.push(temp);
                    }
                  })
                });
              }

            } else if (payload.daysOfWeek && !payload.everyWeek) {
              // Edit mode with only days of week
              let start = moment(startTime, DISPLAY_DATE_FORMAT);
              let end = moment(endTime, DISPLAY_DATE_FORMAT);
              let datesUntil: Array<any> = this.getDatesBetweenRange(start, end);

              if (payload.daysOfWeek) {
                payload.daysOfWeek.split(',').forEach(dayOfWeek => {
                  let filteredDates = datesUntil.filter(date => moment(date, DISPLAY_DATE_FORMAT).format('dddd').toUpperCase() === dayOfWeek);
                  filteredDates.forEach((d, index) => {
                    let temp = {
                      calendarDayRange: {
                        startDate: d,
                        endDate: d
                      },
                      start: startTime.split('T')[1],
                      end: endTime.split('T')[1],
                      remarks: this.remarks.value
                    }
                    blockList.push(temp);
                  })
                });
              }

            } else {
              // Edit mode without repeats
              appo.blockDate.calendarDayRange = {
                startDate: startTime.split('T')[0],
                endDate: endTime.split('T')[0]
              }
              appo.blockDate.start = startTime.split('T')[1];
              appo.blockDate.end = endTime.split('T')[1];
              appo.blockDate.remarks = this.remarks.value;
              blockList.push(appo.blockDate);
            }

          } else {
            blockList.push(appo.blockDate);
          }
        }
      })
    });
    return blockList;
  }

  getDatesBetweenRange(a, b) {
    let dates: Array<any> = [];
    for (var m = a; m.isSameOrBefore(b); m.add(1, 'days')) {
      dates.push(m.format(DISPLAY_DATE_FORMAT));
    }
    return dates;
  }

  formatVariables;

  initDatePickerConfigsForComponent() {
    this.datePickerConfigArray = new Array<DatePickerConfig>();

    const datepickerStartTime: DatePickerConfig = this.initDatePickerConfigObject(
      'Start Date / Time <span class="compulsory">*</span>',
      null,
      this.editMode ? moment(this.startDate.value, DISPLAY_DATE_FORMAT).toDate() : new Date(),
      'bottom',
      'top'
    );

    const datepickerEndTime: DatePickerConfig = this.initDatePickerConfigObject(
      'End Date / Time <span class="compulsory">*</span>',
      null,
      new Date(),
      'bottom',
      'top'
    );

    this.datePickerConfigArray['startTime'] = datepickerStartTime;
    this.datePickerConfigArray['endTime'] = datepickerEndTime;
  }

  initDatePickerConfigObject(
    label,
    maxDate,
    minDate,
    datePickerPlacement,
    labelPlacement
  ) {
    return new DatePickerConfig(
      label,
      maxDate,
      minDate,
      datePickerPlacement,
      labelPlacement
    );
  }

  populateDoctor() {
    this.doctors = this.appointmentMasterQuery.getClinicSelectedDoctorList();
  }

  subscribeChanges() {
    this.startDate.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: Date) => {
        this.patchStartTimeFromPicker();
        this.patchEndDateFromStartDate(value);
      });

    this.duration.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      const startDate = this.blockedTimeFormGroup.value.startDate;
      const endDate = this.utilsService.addMinutes(startDate, value);

      this.endDateFC.patchValue(endDate);
    });

    // Commenting out this code snippet fixes the issue #1310
    // this.startTime.valueChanges
    //   .pipe(distinctUntilChanged())
    //   .subscribe((value: Date) => {
    //     this.patchStartDateFromPicker();
    //   });

    this.endDateFC.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(INPUT_DELAY))
      .subscribe((value: Date) => {
        this.patchEndTimeFromPicker();
      });

    // Commenting out this code snippet fixes the issue #1310
    // this.endTime.valueChanges
    //   .pipe(distinctUntilChanged())
    //   .subscribe((value: Date) => {
    //     this.patchEndDateFromPicker();
    //   });

    this.selectionType.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        this.configureValidityOfControls();
      });
  }

  patchEndDateFromStartDate(value: Date) {
    this.endDateFC.patchValue(
      this.utilsService.addMinutes(value, this.defaultMinutes)
    );
  }

  patchStartTimeFromPicker() {
    this.startTime.patchValue(
      this.blockedTimeFormGroup.get('startDate').value,
      { emitEvent: false }
    );
  }

  patchStartDateFromPicker() {
    this.blockedTimeFormGroup
      .get('startDate')
      .patchValue(this.startTime.value, { emitEvent: true });
  }

  patchEndTimeFromPicker() {
    this.endTime.patchValue(this.endDateFC.value, { emitEvent: false });
  }

  patchEndDateFromPicker() {
    this.endDateFC.patchValue(this.endTime.value, { emitEvent: true });
  }

  calculateDuration() {
    const duration = this.utilsService.calculateDuration(
      this.startDate.value,
      this.endDateFC.value
    );

    this.duration.patchValue(duration);
  }

  disableSubmit() {
    if (
      !this.blockedTimeFormGroup.valid ||
      (this.selectionType.value === this.selectionTypes[1] && !this.blockType.value) ||
      (this.selectionType.value === this.selectionTypes[2] && !this.serviceName.valid) ||
      this.onSubmitClicked === true
    ) {
      return true;
    }
  }

  @HostListener('document:keyup.f4', ['$event'])
  keyEventF4(event: KeyboardEvent) {
    if (!this.disableSubmit()) {
      this.onBtnSubmitClicked();
    }
  }

  configureValidityOfControls() {
    if (this.selectionType.value === this.selectionTypes[0]) {
      this.resetAndDisable(this.doctorId);
      this.resetAndDisable(this.blockType);
    } else if (this.selectionType.value === this.selectionTypes[1]) {
      this.resetAndEnable(this.doctorId);
      this.resetAndEnable(this.blockType);
    } else {
      this.resetAndDisable(this.doctorId);
      this.resetAndDisable(this.blockType);
    }
  }

  resetAndDisable(control: AbstractControl) {
    control.clearValidators();
    control.reset();
    control.disable();
  }

  resetAndEnable(control: AbstractControl) {
    control.reset();
    control.setValidators(Validators.required);
    control.enable();
  }

  getServices() {
    this.apiAppointmentService.getServices(this.storeService.getClinicId()).subscribe(res => {
      if (res.statusCode === 'S0000') {
        this.serviceNames = [];
        this.serviceNames = res.payload?.services;
      }
    })
  }
}
