import { FormControl, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';

export function DateOfBirthValidator(dateOfBirthFC: FormControl): ValidatorFn | any {
    let validator = null;

    const dateOfBirth = dateOfBirthFC.value ? moment(dateOfBirthFC.value) : undefined;

    if (dateOfBirth) {
        if (dateOfBirth.isAfter(moment())) {
            validator = { invalidDateOfBirth: true };
        } else {
            validator = null;
        }
    }

    return validator;
}