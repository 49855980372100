import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { resetStores } from '@datorama/akita';
import { Observable, Subject, timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { UserLogin } from '../objects/User';
import { HttpResponseBody } from './../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';
import { AkitaAllergyGroupStore } from './states/akita-allergy-group.store';
import { AkitaAppStore } from './states/akita-app.store';
import { AkitaApproverStore } from './states/akita-approver.store';
import { AkitaAssessmentStore } from './states/akita-assessment.store';
import { AkitaChargeItemStore } from './states/akita-charge-item.store';
import { AkitaChronicConditionStore } from './states/akita-chronic-condition.store';
import { AkitaClinicChargeItemStore } from './states/akita-clinic-charge-item.store';
import { AkitaClinicDoctorStore } from './states/akita-clinic-doctor.store';
import { AkitaClinicStore } from './states/akita-clinic.store';
import { AkitaCommunicationTypeStore } from './states/akita-communication-type.store';
import { AkitaDefaultAssessmentStore } from './states/akita-default-assessment.store';
import { AkitaDefaultVitalsStore } from './states/akita-default-vitals.store';
import { AkitaDoctorStore } from './states/akita-doctor.store';
import { AkitaDosageInstructionStore } from './states/akita-dosage-instruction.store';
import { AkitaInstructionStore } from './states/akita-instruction.store';
import { AkitaInventoryItemClinicDetailStore } from './states/akita-inventory-item-clinic-detail.store';
import { AkitaInventoryStore } from './states/akita-inventory.store';
import { AkitaMedicalCoverageStore } from './states/akita-medical-coverage.store';
import { AkitaNoteTemplateStore } from './states/akita-note-template.store';
import { AkitaPatientSourceStore } from './states/akita-patient-source.store';
import { AkitaPCNVisitStore } from './states/akita-pcn-visit-case.store';
import { AkitaPRJustificationStore } from './states/akita-pr-justification.store';
import { AkitaReferralSpecialityStore } from './states/akita-referral-speciality.store';
import { AkitaReferralStore } from './states/akita-referral.store';
import { AkitaRequestReturnStore } from './states/akita-request-return.store';
import { AkitaStockAdjustmentStore } from './states/akita-stock-adjustment.store';
import { AkitaUomStore } from './states/akita-uom.store';
import { AkitaVaccinationStore } from './states/akita-vaccination.store';
import { AkitaVisitPurposeStore } from './states/akita-visit-purpose.store';
import { AkitaVitalConfigurationStore } from './states/akita-vital-configuration.store';

@Injectable()
export class AuthService {
  permissionsLoaded = false;
  private beforeLogoutEvent: Subject<Subject<void>> = new Subject();
  private logoutEvent = new Subject();
  private API_AA_URL;

  constructor(
    private appConfig: AppConfigService,
    private akitaAllergyGroupStore: AkitaAllergyGroupStore,
    private akitaAppStore: AkitaAppStore,
    private akitaApproverStore: AkitaApproverStore,
    private akitaAssessmentStore: AkitaAssessmentStore,
    private akitaChargeItemStore: AkitaChargeItemStore,
    private akitaChronicConditionStore: AkitaChronicConditionStore,
    private akitaClinicChargeItemStore: AkitaClinicChargeItemStore,
    private akitaClinicDoctorStore: AkitaClinicDoctorStore,
    private akitaClinicStore: AkitaClinicStore,
    private akitaCommunicationTypeStore: AkitaCommunicationTypeStore,
    private akitaDefaultAssessmentStore: AkitaDefaultAssessmentStore,
    private akitaDefaultVitalsStore: AkitaDefaultVitalsStore,
    private akitaDoctorStore: AkitaDoctorStore,
    private akitaDosageInstructionStore: AkitaDosageInstructionStore,
    private akitaInstructionStore: AkitaInstructionStore,
    private akitaInventoryItemClinicDetailStore: AkitaInventoryItemClinicDetailStore,
    private akitaInventoryStore: AkitaInventoryStore,
    private akitaMedicalCoverageStore: AkitaMedicalCoverageStore,
    private akitaNoteTemplateStore: AkitaNoteTemplateStore,
    private akitaPRJustificationStore: AkitaPRJustificationStore,
    private akitaPatientSourceStore: AkitaPatientSourceStore,
    private akitaReferralStore: AkitaReferralStore,
    private akitaReferralSpecialityStore: AkitaReferralSpecialityStore,
    private akitaRequestReturnStore: AkitaRequestReturnStore,
    private akitaStockAdjustmentStore: AkitaStockAdjustmentStore,
    private akitaUomStore: AkitaUomStore,
    private akitaVaccinationStore: AkitaVaccinationStore,
    private akitaVisitPurposeStore: AkitaVisitPurposeStore,
    private akitaVitalConfigurationStore: AkitaVitalConfigurationStore,
    private akitaPCNVisitStore: AkitaPCNVisitStore,
    private http: HttpClient,
    public jwtHelper: JwtHelperService
  ) {
    this.API_AA_URL = this.appConfig.getConfig().API_AA_URL;
  }

  login(user: UserLogin) {
    return this.http.post(`${this.API_AA_URL}/login`, JSON.stringify(user), {
      observe: 'response',
    });
  }

  getUser(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(`${this.API_AA_URL}/user`, {});
  }

  changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_AA_URL}/user/change/password`,
      {
        oldPassword: oldPassword,
        newPassword: newPassword
      }
    );
  }

  public loginWithTwoFA(data: { token: string; twoFactorPin: string}): Observable<{message: string, payload: string, status: string}> {   
    return this.http.post<{message: string, payload: string, status: string}>(`${this.API_AA_URL}/user/2fa`, data);
  }

  public resendOTP(data: { token: string}): Observable<{message: string, payload: string, status: string}> {   
    return this.http.post<{message: string, payload: string, status: string}>(`${this.API_AA_URL}/user/resend-otp`, data);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('access_token');
    if (token === null) {
      return false;
    }

    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  public isPermissionsLoaded(): boolean {
    return this.permissionsLoaded;
  }

  public logout() {
    const continueLogoutEvnet: Subject<void> = new Subject();
    this.beforeLogoutEvent.next(continueLogoutEvnet);

    timer(800).subscribe(() => {
      if (continueLogoutEvnet && !continueLogoutEvnet.closed) {
        continueLogoutEvnet.next();
      }
    });

    continueLogoutEvnet.pipe(take(1)).subscribe(() => {
      this.http
        .post(`${this.API_AA_URL}/user/logout`, {})
        .subscribe(
          res => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('clinicId');
            localStorage.removeItem('clinicCode');
            localStorage.removeItem('visitId');
            localStorage.removeItem('notes_excluded_roles');
            localStorage.removeItem('previousUrl');
            localStorage.removeItem('previousTitle');
            localStorage.removeItem('searchStatus');
            localStorage.removeItem('searchDcotor');
            localStorage.removeItem('clinicGroupSelection');
            this.appStateReset();

            continueLogoutEvnet.complete();
            this.logoutEvent.next();
          },
          err => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('clinicId');
            localStorage.removeItem('clinicCode');
            localStorage.removeItem('visitId');
            localStorage.removeItem('notes_excluded_roles');
            localStorage.removeItem('previousUrl');
            localStorage.removeItem('previousTitle');
            localStorage.removeItem('searchStatus');
            localStorage.removeItem('searchDcotor');
            localStorage.removeItem('clinicGroupSelection');
            this.appStateReset();

            continueLogoutEvnet.complete();
            this.logoutEvent.next();
          }
        );
    });
  }

  triggerLogout() {
    this.appStateReset();
    this.logoutEvent.next();
  }

  getBeforeLogoutEvent() {
    return this.beforeLogoutEvent.asObservable();
  }

  isLogout() {
    return this.logoutEvent.asObservable();
  }

  appStateReset() {
    resetStores();
    this.akitaAllergyGroupStore.setHasCache(false);
    this.akitaApproverStore.setHasCache(false);
    this.akitaAssessmentStore.setHasCache(false);
    this.akitaAppStore.setHasCache(false);
    this.akitaChargeItemStore.setHasCache(false);
    this.akitaChronicConditionStore.setHasCache(false);
    this.akitaClinicChargeItemStore.setHasCache(false);
    this.akitaClinicDoctorStore.setHasCache(false);
    this.akitaClinicStore.setHasCache(false);
    this.akitaCommunicationTypeStore.setHasCache(false);
    this.akitaDefaultAssessmentStore.setHasCache(false);
    this.akitaDefaultVitalsStore.setHasCache(false);
    this.akitaDoctorStore.setHasCache(false);
    this.akitaDosageInstructionStore.setHasCache(false);
    this.akitaInstructionStore.setHasCache(false);
    this.akitaInventoryItemClinicDetailStore.setHasCache(false);
    this.akitaInventoryStore.setHasCache(false);
    this.akitaMedicalCoverageStore.setHasCache(false);
    this.akitaNoteTemplateStore.setHasCache(false);
    this.akitaPRJustificationStore.setHasCache(false);
    this.akitaPatientSourceStore.setHasCache(false);
    this.akitaReferralStore.setHasCache(false);
    this.akitaReferralSpecialityStore.setHasCache(false);
    this.akitaRequestReturnStore.setHasCache(false);
    this.akitaStockAdjustmentStore.setHasCache(false);
    this.akitaUomStore.setHasCache(false);
    this.akitaVaccinationStore.setHasCache(false);
    this.akitaVisitPurposeStore.setHasCache(false);
    this.akitaVitalConfigurationStore.setHasCache(false);
    this.akitaPCNVisitStore.setHasCache(false);
  }
}
