import { ConsultationFormService } from './../../../services/consultation-form.service';
import { FormArray } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { StoreService } from '../../../services/store.service';

@Component({
  selector: 'app-consultation-referral',
  templateUrl: './consultation-referral.component.html',
  styleUrls: ['./consultation-referral.component.scss'],
})
export class ConsultationReferralComponent implements OnInit {
  @Input() itemsFormArray: FormArray;
  minDate = new Date(2017, 5, 10);
  maxDate = new Date(2018, 9, 15);

  bsValue: Date = new Date();
  bsRangeValue: any = [new Date(2017, 7, 4), new Date(2017, 7, 20)];

  codes: string[];

  isPaymentMode: boolean;

  constructor(
    private consultationFormService: ConsultationFormService,
    private store: StoreService
  ) {}

  ngOnInit() {
    this.isPaymentMode =
      this.store.getVisitStatus() === 'PAYMENT' ? true : false;
  }

  onBtnAddClicked() {
    this.consultationFormService.initPatientReferral();
  }

  onDelete(index) {
    this.itemsFormArray.removeAt(index);
  }
}
