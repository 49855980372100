import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayStringEmpty',
})
export class DisplayStringEmptyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === '' || value === undefined || value === null) {
      if (args) {
        return args;
      } else {
        return '';
      }
    } else {
      return value;
    }
  }
}
