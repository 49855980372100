import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryDisplayOrderStatus'
})
export class InventoryDisplayOrderStatusPipe implements PipeTransform {

  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    if (!value) {
      return ''
    }
    return this.inventory.getOrderStatusLabel(value.getStatus());
  }

}
