import { CalendarEventAction, CalendarEvent } from 'angular-calendar';
import { CalendarAppointment } from './../objects/CalendarAppointment';
const actions: CalendarEventAction[] = [
  {
    label: '<i class="fa fa-fw fa-pencil"></i>',
    onClick: ({ event }: { event: CalendarEvent }): void => {
      // this.handleEvent('Edited', event);
    },
  },
  {
    label: '<i class="fa fa-fw fa-times"></i>',
    onClick: ({ event }: { event: CalendarEvent }): void => {
      // this.calendarEvents = this.calendarEvents.filter(iEvent => iEvent !== event);
      // this.handleEvent('Deleted', event);
    },
  },
];
export const calendarEventTemplate: CalendarAppointment[] = [
  {
    id: '',
    start: null,
    end: null,
    title: '',
    color: { primary: '#FFFFFF', secondary: '#FFFFFF' },
    actions: actions,
    resizable: {
      beforeStart: false,
      afterEnd: false,
    },
    draggable: false,
    appointment: null,
    type: 'APPOINTMENT',
  },
  {
    id: '',
    start: null,
    end: null,
    title: 'CLINIC HOLIDAY',
    color: { primary: '#FFFFFF', secondary: '#FFFFFF' },
    resizable: {
      beforeStart: false,
      afterEnd: false,
    },
    draggable: false,
    appointment: null,
    type: 'CLINIC_HOLIDAY',
    allDay: true,
  },
  {
    id: '',
    start: null,
    end: null,
    title: 'CLINIC_BLOCKED_TIME',
    color: { primary: '#FFFFFF', secondary: '#FFFFFF' },
    resizable: {
      beforeStart: false,
      afterEnd: false,
    },
    draggable: false,
    appointment: null,
    doctorId: '',
    type: 'CLINIC_BLOCKED_TIME',
  },
  {
    id: '',
    start: null,
    end: null,
    title: 'DOCTOR_LEAVE',
    color: { primary: '#FFFFFF', secondary: '#FFFFFF' },
    resizable: {
      beforeStart: false,
      afterEnd: false,
    },
    draggable: false,
    appointment: null,
    doctorId: '',
    type: 'DOCTOR_LEAVE',
  },
  {
    id: '',
    start: null,
    end: null,
    title: 'DOCTOR_BLOCKED_TIME',
    color: { primary: '#FFFFFF', secondary: '#FFFFFF' },
    resizable: {
      beforeStart: false,
      afterEnd: false,
    },
    draggable: false,
    appointment: null,
    doctorId: '',
    type: 'DOCTOR_BLOCKED_TIME',
  },
];

export interface RecurringEvent {
  title: string;
  color: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
  };
}
