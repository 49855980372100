export interface DosageInstruction {
  code: string;
  instruct: string;
  status: string;
}

export function createDosageInstruction({ code = '', instruct = '', status = '' } = {}) {
  return {
    code,
    instruct,
    status
  } as DosageInstruction;
}
