import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Address } from '../../objects/Address';

export interface DeliveryLocation {
  id: string;
  code: string;
  name: string;
  status: string;
  items: ItemRef[];
  deliveryTimeSlots: DeliveryTimeSlot[],
  holidays: string[];
  deliveryUnavailableDays: Array<string>;
  context: any;
  address?: Address
}

export interface DeliveryTimeSlot {
  startTime: string;
  endTime: string;
  label: string;
}

interface ItemRef {
  itemRefId: string;
  status: string;
}

export interface AkitaDeliveryLocationState extends EntityState<DeliveryLocation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'deliveryLocation' })
export class AkitaDeliveryLocationStore extends EntityStore<AkitaDeliveryLocationState> {
  
  constructor() {
    super();
  }
}