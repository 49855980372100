import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayTitleCase',
})
export class DisplayTitleCasePipe implements PipeTransform {
  transform(data: any, args?: any): any {
    let tempString: string = data + '';
    tempString = tempString
      .split('_')
      .map(word => {
        const length = word.length;
        return (
          word.charAt(0).toUpperCase() + word.slice(1, length).toLowerCase()
        );
      })
      .join(' ');
    return tempString;
  }
}
