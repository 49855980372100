import { Injectable } from '@angular/core';

import { combineLatest } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { AkitaAppQuery } from './akita-app.query';
import { AkitaClinicStore } from './akita-clinic.store';
import { Clinic } from '../../objects/state/Clinic';
import { mergeMap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AkitaClinicQuery extends QueryEntity<Clinic> {
  clinicList = this.getAll();
  clinicList$ = this.selectAll();

  authorizedClinicList$ = combineLatest(
    this.akitaAppQuery.appUserName$,
    this.clinicList$,
    this.getAuthorizedClinicList
  );

  constructor(
    protected store: AkitaClinicStore,
    private akitaAppQuery: AkitaAppQuery
  ) {
    super(store);
  }

  private getAuthorizedClinicList(filter, clinicList) {
    const authorizedClinicAccess = [];

    clinicList.forEach(clinic => {
      if (clinic.clinicStaffUsernames.includes(filter))
        authorizedClinicAccess.push(clinic);
    });

    return authorizedClinicAccess;
  }

  selectCurrentClinic(property?) {
    return this.akitaAppQuery.appClinicId$.pipe(
      mergeMap(clinicId =>
        this.selectAll({ filterBy: (entity: Clinic) => entity.id === clinicId })
      ),
      map(data => data[0]),
      map((clinic: Clinic) => {
        if (clinic && property)
          return property in clinic ? clinic[property] : undefined;
        return clinic;
      })
    );
  }

  getCurrentClinic(property?) {
    const clinic = <Clinic>this.getAll().find(
      (item: Clinic) => item.id === this.akitaAppQuery.getAppClinicId()
    );
    if (clinic && property)
      return property in clinic ? clinic[property] : undefined;
    return clinic;
  }

  getClinic(clinicId) {
    return this.getEntity(clinicId);
  }

  getClinicCode(clinicId) {
    if (clinicId) {
      return this.getAll() && this.getAll().length > 0
        ? (<Clinic>this.getAll().find((clinic: Clinic) => clinic.id === clinicId))
          .clinicCode
        : '';
    }
  }
}
