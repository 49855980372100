import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { SddCode } from '../../objects/state/SddCode';
import { AkitaSddCodeStore } from './akita-sdd-code.store';

@Injectable({ providedIn: 'root' })
export class AkitaSddCodeQuery extends QueryEntity<SddCode> {
  sddCodeList$ = this.selectAll();

  constructor(protected store: AkitaSddCodeStore) {
    super(store);
  }
}
