import { ClinicCalendarQuery } from '../state/appointments/event-types/clinic-calendar/clinic-calendar.query';
import {
  DISPLAY_TIME_NO_SECONDS_24_FORMAT,
  DAY_OF_WEEK,
} from './../constants/app.constants';
import {
  ClinicCalendar,
  OperationHour,
  CalendarDayWeek,
} from './../objects/ClinicCalendar';
import { StoreService } from './store.service';
import { CalendarAppointment } from './../objects/CalendarAppointment';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsPresenterService {
  constructor(
    private store: StoreService,
    private clinicCalendarQuery: ClinicCalendarQuery
  ) {}

  getOperationHourByCalendarDayWeek(clinicCalendar: ClinicCalendar, dayNo) {
    const dayName = DAY_OF_WEEK[dayNo];
    const operationHour: OperationHour[] = clinicCalendar.operationHours.filter(
      operationHours => operationHours.calendarDayWeek.dayOfWeek === dayName
    );

    if (operationHour !== undefined) {
      return operationHour;
    } else {
      return [
        <OperationHour>{
          calendarDayWeek: <CalendarDayWeek>{
            dayOfWeek: '',
          },
          start: '',
          end: '',
        },
      ];
    }
  }

  isReferredAppointmentByLoggedInClinic(event: CalendarAppointment) {
    if (event && event.appointment) {
      return event.appointment.referringClinicId === this.store.getClinicId();
    } else {
      return false;
    }
  }

  refreshCurrentTimeIndicator() {
    let currentTimeSegments = document.getElementsByClassName('currentTime');

    for (let i = 0; i < currentTimeSegments.length; i++) {
      let element = currentTimeSegments[i] as HTMLElement;

      if (element.innerHTML.search('redLine') === -1) {
        const currentMinutesIn30Part = new Date().getMinutes();
        const offset =
          currentMinutesIn30Part > 30
            ? currentMinutesIn30Part - 30
            : currentMinutesIn30Part;

        var nowIndicator = document.createElement('div');
        nowIndicator.classList.add('redLine');
        nowIndicator.style.height = offset / 30 * 100 + '%';
        nowIndicator.setAttribute(
          'title',
          moment().format(DISPLAY_TIME_NO_SECONDS_24_FORMAT)
        );

        element.appendChild(nowIndicator);
      }
    }
  }

  setStyleOfHourSegment(segment) {
    this.clinicCalendarQuery.clinicSelected$.subscribe(
      (clinicCalendar: ClinicCalendar) => {
        if (clinicCalendar && clinicCalendar.operationHours !== null) {
          let cssClass = 'off-hours';

          let viewStartTime = moment(segment.date).format(
            DISPLAY_TIME_NO_SECONDS_24_FORMAT
          );
          let viewEndTime = moment(segment.date)
            .add(30, 'minutes')
            .format(DISPLAY_TIME_NO_SECONDS_24_FORMAT);

          let clinicOperationHour: OperationHour[] = this.getOperationHourByCalendarDayWeek(
            clinicCalendar,
            segment.date.getDay()
          );

          const HHmm = 'HH:mm';

          clinicOperationHour.forEach((block: OperationHour) => {
            let clinicStartTime = block.start;
            let clinicEndTime = block.end;

            if (
              moment(segment.date).isSameOrAfter(new Date()) &&
              moment(viewStartTime, HHmm).isSameOrAfter(
                moment(clinicStartTime, HHmm)
              ) &&
              moment(viewEndTime, HHmm).isSameOrBefore(
                moment(clinicEndTime, HHmm)
              ) &&
              moment(viewStartTime, HHmm).isBefore(moment(clinicEndTime, HHmm))
            ) {
              cssClass = '';
            }
          });

          if (
            moment(new Date(), HHmm).isSameOrAfter(
              moment(segment.date, HHmm)
            ) &&
            moment(new Date(), HHmm).isBefore(
              moment(moment(segment.date).add(30, 'minutes'), HHmm)
            )
          ) {
            cssClass += ' currentTime';
          }
          segment.cssClass = cssClass;
        } else {
          segment.cssClass = '';
        }
      }
    );
  }

  scrollToBottomForDayAndWeek() {
    let weekView = document.getElementsByClassName('cal-week-view');
    let dayView = document.getElementsByClassName('cal-day-view');

    if (weekView.length > 0) {
      weekView[0].childNodes.forEach((child: HTMLElement) => {
        if (child.className === 'cal-time-events') {
          child.scrollTop = child.scrollHeight;
        }
      });
    }

    if (dayView.length > 0) {
      dayView[0].childNodes.forEach((child: HTMLElement) => {
        if (child.className === 'cal-hour-rows') {
          child.scrollTop = child.scrollHeight;
        }
      });
    }
  }
}
