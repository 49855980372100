export interface Instruction {
  cautionary: string;
  code: string;
  frequencyPerDay: number;
  instruct: string;
  status: string;
}

export function createInstruction({
  cautionary = '',
  code = '',
  frequencyPerDay = 0,
  instruct = '',
  status = ''
} = {}) {
  return {
    cautionary,
    code,
    frequencyPerDay,
    instruct,
    status
  } as Instruction;
}
