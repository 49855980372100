import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';
import * as moment from 'moment';

import { AllergyGroup } from '../../objects/state/AllergyGroup';
import { AKITA_CACHE_DURATION } from '../../constants/akita.config';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'allergygroup',
  idKey: 'id',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaAllergyGroupStore extends EntityStore<AllergyGroup> {
  constructor() {
    super();
  }
}
