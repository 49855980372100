<div class="card" style="height: calc(100vh - 80px); padding-bottom:80px;">
<!--  <div class="row mx-0">-->
<!--    <div class="col-auto text-white pt-1" (click)="toggleCharges()" style="background-color: #4a4a4a;">-->
<!--      <span [ngClass]="{'icon-left-open': chargesCollapsed, 'icon-right-open': !chargesCollapsed}"></span>-->
<!--    </div>-->
<!--    <div class="col card-header">Charges</div>-->
<!--  </div>-->
  <div class="p-1" style="display: -webkit-inline-box; height: 50px;" *ngIf="!(isPackagedCase | async)">
    <div class="col-11">
      <ng-select *ngIf="!inPaymentMode()" name="drug-code"
        class='p-0 form-control form-control-sm  custom-select custom-select-extra-width prescr-dropdown-right'
        [items]="chargeItemsByClinic" bindLabel='item.code' bindValue='item.id' (change)="onDrugSelect($event.item)"
        [addTag]="false" [virtualScroll]="true" [typeahead]="codesTypeahead" [clearable]=false
        placeholder="Type to Search For New Item..." [formControl]="searchKey">
        <ng-template ng-header-tmp>
          <div class="row">
            <div class="col-md-2 font-weight-semi-bold">Code</div>
            <div class="col-md-6 font-weight-semi-bold">Name</div>
            <div class="col-md-4 font-weight-semi-bold">Generic Name</div>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item.item" let-index="index" let-search="searchTerm">
          <div class="row">
            <div class="col-md-2">{{ item.code }}</div>
            <div class="col-md-6">{{ item.name }}</div>
            <div class="col-md-4">{{ (item.genericName || '') }} <app-whitelisted-item-chip [itemId]="item.id"></app-whitelisted-item-chip></div>
          </div>
        </ng-template>
      </ng-select>
      <input *ngIf="inPaymentMode()" data-cy="searchDrug" class="form-control form-control-sm" type="text" readonly
        [formControl]="searchKey" />
    </div>
    <div class="col-1 pl-0">
      <svg-icon style="cursor:pointer; " (click)="onSearchClicked() " src='/assets/svg/othericons/lookup.svg'>
      </svg-icon>
    </div>
  </div>
  <!-- <div class="sub-heading-grey mx-0 cursor-pointer " (click)="onSearchClicked() ">
      <div class="btn-link ">Advanced Search</div>
  </div> -->

  <div id="cardIndication" class="card-header bg-white">
    <div class="row ">
      <div class="col-md-4 text-indigo ">Charge</div>
      <!-- charge item : {{chargeItemDescription.qty }} -->
      <div class="col-md-8 text-pink">{{chargeItemDescription.charge}}
        <span *ngIf="chargeItemDescription.charge ">/</span> {{chargeItemDescription.qty}} {{chargeItemDescription.uom}} <app-whitelisted-item-chip [itemId]="chargeItemDescription.itemId"></app-whitelisted-item-chip>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-4 text-indigo ">SIG & Indication</div>
      <div class="col-md-8 text-pink ">{{chargeItemDescription.dosageInstruction}} {{chargeItemDescription.sig}}</div>
    </div>
    <div class="row ">
      <div class="col-md-4 text-indigo ">Cautionary</div>
      <div class="col-md-8 text-pink ">{{chargeItemDescription.cautionary}}</div>
    </div>
    <div class="row ">
      <div class="col-md-4 text-indigo ">Remarks</div>
      <div class="col-md-8 text-pink">{{chargeItemDescription.remarks}}</div>
    </div>
  </div>

  <div class="row mx-0 data-table" style="display: -webkit-inline-box; height:20px ">
    <div class="col-10 pl-3"><label class="mb-0 p-0">Item Code</label></div>
    <div class="col-2 mr-2 pl-0"><label class="mb-0 p-0">Price ($)</label></div>
  </div>
  <div class="d-flex flex-column flex-grow-2">
    <div id="chargeItemsList" class="px-0 pt-0 pb-0" [ngClass]="getChargeItemClass()" style=" overflow-y: scroll; overflow-x:hidden;">
      <div style="position:relative;" *ngIf="!(isPackagedCase | async)">
        <span *ngFor="let item of dispatchItemEntities.controls; let i=index ">
        <app-prescription-item *ngIf="!item?.value?.remoteDelivery" [index]="i"
                               [prescriptionItem]="item" [vaccineDosages]="vaccineDosages" (onTopChargeItemDescriptionChanged)="onTopChargeItemDescriptionChanged($event) "
                               (onDelete)="onDelete($event)" (updatePrice)="updateOverallPrice($event)"
                               (precriptionItemQtyChange)="onPrecriptionItemQtyChange($event)" [drugItemIndex]="drugItemIndex"></app-prescription-item>
        </span>
      </div>
    </div>

    <!-- packages starts -->

    <app-package [itemsFormArray]="dispatchItemEntities" (packageClicked)="onPackageClicked($event)" [vaccineDosages]="vaccineDosages"
      (onSubItemPriceAdjustment)="onSubItemPriceAdjustment($event)" (deletePackageItem)="onPackageDelete($event)">
    </app-package>
  </div>
  <!-- packages ends -->
</div>

<!--<div class="row p-2 mx-0 charges-fixed-bottom" *ngIf="!(isPackagedCase | async)">-->
<!--  <div class="col-2 ">Total Bill Amount</div>-->
<!--  <div class="col-1 ">${{ totalPrice.toFixed(2) }}</div>-->
<!--</div>-->
