import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-case-summery-bill-adj-confirmation',
  templateUrl: './case-summery-bill-adj-confirmation.component.html',
  styleUrls: ['./case-summery-bill-adj-confirmation.component.scss']
})
export class CaseSummeryBillAdjConfirmationComponent implements OnInit {

  public event: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
  }

  closeModal() {
    this.bsModalRef.hide();
  }
  
  confirm(){
    this.event.emit(true);
    this.closeModal();
  }
}
