import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  isLoadingSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading = this.isLoadingSubject.asObservable();

  constructor() { }

  setLoading(isLoading) {
    this.isLoadingSubject.next(isLoading);
  }

  getLoading(): Observable<boolean> {
    return this.isLoading;
  }




}
