import { Injectable } from '@angular/core';
import { StoreService } from './store.service';
import { Instruction } from '../objects/DrugItem';

@Injectable()
export class DispatchItemDataService {

  constructor(
    private store: StoreService
  ) { }



  getInstructions(instructionCode: string): Instruction {
    return this.store
      .getInstructions()
      .find(x => x.code ===  instructionCode);
  }


  isDrugOrVaccine(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType === 'VACCINATION' || itemType === 'DRUG') {
        return true;
      } else {
        return false;
      }
    }
  }

  isVaccination(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType === 'VACCINATION') {
        return true;
      } else {
        return false;
      }
    }
  }

  isService(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType !== 'DRUG') {
        return true;
      } else {
        return false;
      }
    }
  }

  isDrug(itemSelected) {
    if (itemSelected) {
      const itemType = itemSelected.itemType;
      if (itemType === 'DRUG') {
        return true;
      } else {
        return false;
      }
    }
  }




}
