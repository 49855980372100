import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, of } from 'rxjs';
import {
  distinctUntilChanged,
  debounceTime,
  filter,
  tap,
  switchMap,
  takeUntil,
  catchError,
} from 'rxjs/operators';
import { StoreService } from '../../../services/store.service';
import { ApiCmsManagementService } from '../../../services/api-cms-management.service';
import { AlertService } from '../../../services/alert.service';
import { HttpResponseBody } from '../../../objects/response/HttpResponseBody';
import { ApiPatientVisitService } from '../../../services/api-patient-visit.service';

@Component({
  selector: 'app-payment-lab-request-form-item',
  templateUrl: './payment-lab-request-form-item.component.html',
  styleUrls: ['./payment-lab-request-form-item.component.scss'],
})
export class PaymentLabRequestFormItemComponent implements OnInit {
  @Input() labTestItem: FormGroup;
  @Input() labRequestForm: FormGroup;
  @Input() isFromPatientDetails: boolean;
  @Input() disabled = false;

  categoryItems = ['BLOOD', 'URINE', 'STOOL', 'OTHER'];
  codesTypeahead = new Subject<string>();
  items;
  loading = false;

  constructor(
    private store: StoreService,
    private apiPatientVisitService: ApiPatientVisitService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.onFilterInputChanged();
    if (this.isFromPatientDetails) {
      this.labRequestForm.disable();
    }
  }

  onItemChecked(event) {
    this.labTestItem.get('isChecked').patchValue(event);
  }

  onDrugSelect(event) {
    if (event !== undefined) {
      this.labTestItem.get('testName').patchValue(event.testName);
      this.labTestItem.get('itemId').patchValue(event.itemId);
    }
  }

  onClear() {
    this.labTestItem.get('testName').patchValue('');
  }

  onFilterInputChanged() {
    try {
      this.codesTypeahead
        .pipe(
          distinctUntilChanged((a, b) => {
            return a === b;
          }),
          tap(() => (this.loading = true)),
          debounceTime(500),
          switchMap((term: string) => {
            return this.apiPatientVisitService
              .searchLabItemByKeyWord(
                this.labRequestForm.get('supplierId').value,
                encodeURI(term)
              )
              .pipe(
                catchError(error =>
                  of(<HttpResponseBody>{ page: {}, payload: '' })
                )
              );
          }),
          catchError(errors => of(<HttpResponseBody>{ page: {}, payload: '' }))
        )
        .subscribe(
          data => {
            this.loading = false;
            if (data) {
              this.items = data.payload;
            }
          },
          err => {
            this.loading = false;
            this.alertService.error(JSON.stringify(err.error.message));
          }
        );
    } catch (err) {
      console.error('Search Diagnosis Error', err);
    }
  }
}
