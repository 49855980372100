<div class="row" *ngIf="isLoading">
    <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>

<table class="custom-table custom-table-light" *ngIf="!isLoading">
    <thead>
        <tr>
            <th>Title</th>
            <th>Version</th>
            <th>Last Updated</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="healthQuestionnaire.length === 0">
            <tr>
                <td colspan="4">
                    <span class="text-danger fw-500 d-block w-100 text-center m-0">No records available</span>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngFor="let questionnaire of healthQuestionnaire">
            <tr>
                <td>{{questionnaire.title}}</td>
                <td>{{questionnaire.version}}</td>
                <td>{{questionnaire.lastUpdatedDate}}</td>
                <td><img src="/assets/svg/eye-solid.svg" class="icon-eye" (click)="onView(questionnaire)"></td>
            </tr>
        </ng-container>
    </tbody>
</table>

<div class="row" *ngIf="!isLoading">
    <div class="col-12">
        <div class="wrapper-pagination mt-2 d-flex align-items-center justify-content-end">
            <img src="/assets/icons/icon-left.png" (click)="onPaginate(false)">
            <span class="mx-3">{{currentPage}}</span>
            <img src="/assets/icons/icon-right.png" (click)="onPaginate(true)">
        </div>
    </div>
</div>