import { MedicalAlert } from './../../../../objects/state/MedicalAlert';
import { filter, distinctUntilChanged, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PatientMedicalAlertsStore } from './patient-medical-alerts.store';

@Injectable({ providedIn: 'root' })
export class PatientMedicalAlertsQuery extends QueryEntity<MedicalAlert> {
  alerts$ = this.selectAll().pipe(
    tap(),
    distinctUntilChanged(),
    filter(value => value !== undefined && value !== null)
  );

  constructor(protected store: PatientMedicalAlertsStore) {
    super(store);
  }
}
