import { StoreService } from './store.service';
import { calendarEventTemplate } from './../model/AppointmentEvent';
import {
  APPOINTMENT,
  DOCTOR_BLOCKED_TIME,
  DOCTOR_LEAVE,
  CLINIC_HOLIDAY,
  noPreferredDoctorColorCode,
  locumColorCode,
  doctorColorCode,
  DEFAULT_DOC_COLOR,
} from './../constants/app.constants';
import { guid } from '@datorama/akita';
import { UtilsService } from './utils.service';
import { CustomisedApptInput, DoctorColorMap } from './../objects/Appointment';
import { CalendarAppointment } from './../objects/CalendarAppointment';
import { DoctorCalendar } from './../objects/DoctorCalendar';
import {
  ClinicCalendar,
  CalendarDayRange,
  BlockDate,
} from './../objects/ClinicCalendar';
import { Injectable } from '@angular/core';
import {
  Appointment,
  UserId,
  ContactNumber,
  Address,
} from '../objects/Appointment';
import { AkitaDoctorQuery } from './states/akita-doctor.query';
import { Doctor } from '../objects/state/Doctor';
import { AkitaClinicDoctorQuery } from './states/akita-clinic-doctor.query';
import { DOC_COLORS } from '../constants/docColors';
import { ServiceCalendar } from '../objects/ServiceCalendar';

let doctorColorMap: Array<DoctorColorMap>;
@Injectable({
  providedIn: 'root',
})
export class AppointmentsFactoryService {
  newDoctorColorMap = [];

  constructor(
    private store: StoreService,
    private utilsService: UtilsService,
    private akitaDoctorQuery: AkitaDoctorQuery,
    private akitaClinicDoctorQuery: AkitaClinicDoctorQuery
  ) { }

  createClinicCalendar(clinicConfig?: ClinicCalendar) {
    if (clinicConfig) {
      return this.setValuesFromClinicResponse(clinicConfig);
    } else {
      return <ClinicCalendar>{
        id: '',
        clinicId: '',
        avgConsultationTime: null,
        maxWaitTime: null,
        operationHours: null,
        clinicHolidays: null,
        blockedTimes: null,
        allowDoubleRegistration: false,
      };
    }
  }

  setValuesFromClinicResponse(clinicConfig: ClinicCalendar) {
    return <ClinicCalendar>{
      id: clinicConfig.id || '',
      clinicId: clinicConfig.clinicId || '',
      avgConsultationTime: clinicConfig.avgConsultationTime || null,
      maxWaitTime: clinicConfig.maxWaitTime || null,
      operationHours: clinicConfig.operationHours || null,
      clinicHolidays: clinicConfig.clinicHolidays || null,
      blockedTimes: clinicConfig.blockedTimes || null,
      allowDoubleRegistration: clinicConfig.allowDoubleRegistration || false
    };
  }

  createDoctorCalendar(doctorConfig?: DoctorCalendar) {
    if (doctorConfig) {
      return this.setValuesFromDoctorResponse(doctorConfig);
    } else {
      return <DoctorCalendar>{
        id: '',
        doctorId: '',
        clinicId: '',
        workingDays: null,
        leaves: null,
        blockedTime: null,
      };
    }
  }

  createServiceCalendar(serviceCal) {
    if (serviceCal) {
      return this.setValuesFromServiceResponse(serviceCal);
    } else {
      return <ServiceCalendar>{
        clinicId: '',
        blockedTime: null,
        group: '',
        service: ''
      };
    }
  }

  setValuesFromDoctorResponse(doctorConfig: DoctorCalendar) {
    return <DoctorCalendar>{
      id: doctorConfig.id || '',
      doctorId: doctorConfig.doctorId || '',
      clinicId: doctorConfig.clinicId || '',
      workingDays: doctorConfig.workingDays || null,
      leaves: doctorConfig.leaves || null,
      blockedTime: doctorConfig.blockedTime || null,
    };
  }

  setValuesFromServiceResponse(serviceCal) {
    return <ServiceCalendar>{
      clinicId: serviceCal.clinicId || '',
      blockedTime: serviceCal.blockedTime || null,
      group: serviceCal.group || '',
      service: serviceCal.service || ''
    };
  }

  createCalendarDayRange(startDate: string = '', endDate: string = '') {
    return <CalendarDayRange>{
      startDate: startDate,
      endDate: endDate,
    };
  }

  createBlockDate(blockedDate?) {
    return <BlockDate>{
      calendarDayRange: blockedDate.calendarDayRange
        ? blockedDate.calendarDayRange
        : null,
      calendarDayYear: blockedDate.calendarDayYear
        ? blockedDate.calendarDayYear
        : null,
      calendarDayWeek: blockedDate.calendarDayWeek
        ? blockedDate.calendarDayWeek
        : null,
      start: blockedDate.start || '',
      end: blockedDate.end || '',
      remarks: blockedDate.remarks || '',
    };
  }

  createAppointment(
    input?,
    format?,
    eventInCalendar?: CalendarAppointment,
    clinicId?
  ) {
    let appointment: Appointment = {
      id: '',
      patientId: '',
      clinicId: '',
      preferredDoctor: null,
      referringDoctorId: '',
      referringClinicId: '',
      purposeOfVisit: null,
      remarks: '',
      reminderDate: null,
      visitDate: null,
      duration: 0,
      status: 'PENDING',
      followUp: false,
      patientName: '',
      userId: <UserId>{
        idType: '',
        number: '',
      },
      gender: '',
      dob: '',
      contactNumber: <ContactNumber>{
        number: '',
      },
      emailAddress: '',
      address: <Address>{
        address: '',
        country: '',
        postalCode: '',
      },
      patientSources: {}
    };

    if (input && format) {
      this.setValues(appointment, input, format);
    }

    if (eventInCalendar && clinicId) {
      this.setValuesFromCalendar(appointment, eventInCalendar, clinicId);
    }

    return appointment;
  }

  setValues(appt: Appointment, input, format) {
    let apptValues;
    let customisedInput: CustomisedApptInput;

    if (format === 'OBJECT') {
      apptValues = Object.assign(input);
      customisedInput = new CustomisedApptInput(
        apptValues.doctorId,
        apptValues.appointmentPurpose,
        apptValues.reminderDate,
        apptValues.startDate,
        apptValues.dob,
        apptValues.remark
      );
    } else if (format === 'FORMGROUP') {
      apptValues = <Appointment>input.getRawValue();
      customisedInput = new CustomisedApptInput(
        apptValues.preferredDoctor,
        apptValues.purposeOfVisit,
        apptValues.reminderDate,
        apptValues.visitDate,
        apptValues.dob,
        apptValues.remarks
      );
    }

    appt.id = apptValues.id;
    appt.patientId = apptValues.patientId;
    appt.clinicId = apptValues.clinicId;
    appt.preferredDoctor = customisedInput.preferredDoctor;
    appt.referringDoctorId = apptValues.referringDoctorId;
    appt.referringClinicId = apptValues.referringClinicId;
    appt.purposeOfVisit = customisedInput.purposeOfVisit;
    appt.remarks = customisedInput.remarks;
    appt.reminderDate = customisedInput.reminderDate;
    appt.visitDate = customisedInput.visitDate;
    appt.duration = apptValues.duration;
    appt.status = apptValues.status;
    appt.followUp = apptValues.followUp;
    appt.patientName = apptValues.patientName;
    appt.userId = apptValues.userId;
    appt.gender = apptValues.gender;
    appt.dob = apptValues.dob;
    appt.contactNumber = apptValues.contactNumber;
    appt.emailAddress = apptValues.emailAddress;
    appt.address = apptValues.address;
    appt.remarks = customisedInput.remarks;
    appt.patientSources = apptValues.patientSources;

    // Payment Reference will be provided only via online bookings
    // hence will be populated directly in appointment list and not
    // created newly from calendar
    if(apptValues.paymentReference){
      appt.paymentReference = apptValues.paymentReference;
    }
  }

  setValuesFromCalendar(
    appt: Appointment,
    eventInCalendar: CalendarAppointment,
    clinicId
  ) {
    let visitDate = eventInCalendar.start;
    let duration = this.calculateDuration(
      eventInCalendar.start,
      eventInCalendar.end
    );
    let reminderDate = this.decreaseTimeByInterval(new Date(visitDate), 60);

    appt.clinicId = eventInCalendar.clinicId;
    appt.referringClinicId = clinicId;
    appt.reminderDate = reminderDate;
    appt.visitDate = visitDate;
    appt.duration = duration;
  }

  decreaseTimeByInterval(startDateTime: Date = null, minuteInterval) {
    const dateTime = startDateTime ? startDateTime : new Date();
    dateTime.setMinutes(dateTime.getMinutes() - minuteInterval);

    return dateTime;
  }

  calculateDuration(startDateTime, endDateTime) {
    var difference = endDateTime.getTime() - startDateTime.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes;
  }

  createCalendarEvent(
    eventType: string,
    appointment?: Appointment,
    blockDate?: BlockDate,
    doctorId?: string,
    serviceCalendar?: ServiceCalendar
  ): CalendarAppointment {
    let startDate, endDate;

    const calendarConfig = <CalendarAppointment>this.getTemplate(eventType);
    let calendarEvent: CalendarAppointment = Object.assign({}, calendarConfig);

    if (appointment) {
      // IF APPOINTMENT
      startDate = appointment.visitDate;
      endDate = this.utilsService.addMinutes(startDate, appointment.duration);
      calendarEvent.id = appointment.id;
      calendarEvent.draggable =
        appointment.status === 'ARRIVED' ? false : calendarConfig.draggable;
      calendarEvent.appointment = appointment;
    } else if (blockDate) {
      // IF CLINIC_HOLIDAY, CLINIC_BLOCKED_TIME, DOCTOR_LEAVE, DOCTOR_BLOCKED_TIME
      let startDateDisplay = this.getStartDate(blockDate);
      let endDateDisplay = this.getEndDate(blockDate);
      startDate = this.utilsService.convertDateTimeStringToDateObject(
        this.utilsService.formatToDateTimeString(
          startDateDisplay,
          blockDate.start
        )
      );
      endDate = this.utilsService.convertDateTimeStringToDateObject(
        this.utilsService.formatToDateTimeString(endDateDisplay, blockDate.end)
      );
      calendarEvent.id = guid();
      calendarEvent.blockDate = blockDate;
    }

    calendarEvent.start = startDate;
    calendarEvent.end = endDate;
    calendarEvent.color = this.getColor(eventType, doctorId);
    calendarEvent.doctorId = doctorId ? doctorId : '';
    calendarEvent.type = eventType;
    if(serviceCalendar){
      calendarEvent.serviceCalendar = serviceCalendar;
    }

    const meta = {
      user : {
        id: doctorId,
        name: this.getDoctor(doctorId),
        color:  this.getColor(eventType, doctorId)
      }
    }
    calendarEvent.meta = meta;

    return calendarEvent;
  }

  getDoctor(doctorId: string): string {
    if (!!doctorId) {
      const doctor = this.akitaDoctorQuery.getEntity(doctorId) as Doctor;
      if (!doctor)
        return '';
      return doctor.name;
    } else {
      return '';
    }
  }

  getColor(eventType, doctorId?: string) {
    const calendarConfig = <CalendarAppointment>this.getTemplate(eventType);
    switch (eventType) {
      case APPOINTMENT:
      case DOCTOR_BLOCKED_TIME:
      case DOCTOR_LEAVE:
        return {
          primary: this.getColorByDoctorId(doctorId),
          secondary: this.getColorByDoctorId(doctorId),
        };
      case CLINIC_HOLIDAY:
        return calendarConfig.color;
      default:
        return { primary: '#FFFFFF', secondary: '#FFFFFF' };
    }
  }

  getColorByDoctorId(doctorId) {
    if (doctorId && doctorId.length > 0) {
      if (this.doctorIsLocum(doctorId)) {
        return locumColorCode;
      } else {
        const colorMapFound: DoctorColorMap = this.newDoctorColorMap.find(
          (map: DoctorColorMap) => {
            return map.getDoctorId() === doctorId;
          }
        );

        if (colorMapFound !== undefined) {
            return colorMapFound.getColor();
        }

        // const colorMapFound: DoctorColorMap = doctorColorMap.find(
        //   (map: DoctorColorMap) => {
        //     return map.getDoctorId() === doctorId;
        //   }
        // );

        // if (colorMapFound !== undefined) {
        //   return colorMapFound.getColor();
        // }
        // else {
        //   let nextAvailableMap: DoctorColorMap = this.findNextAvailableColour();

        //   if (nextAvailableMap === undefined) {
        //     return noPreferredDoctorColorCode;
        //   } else {
        //     nextAvailableMap.setDoctorId(doctorId);
        //     return nextAvailableMap.getColor();
        //   }
        // }
      }
    } else {
      return noPreferredDoctorColorCode;
    }
  }

  findNextAvailableColour(): DoctorColorMap {
    return doctorColorMap.find((map: DoctorColorMap) => {
      const doctorId = map.getDoctorId();
      return (
        (doctorId === '' || doctorId === null || doctorId === undefined) &&
        map.getColor() !== ''
      );
    });
  }

  initDoctorColorMap() {
    // Apply doc colors for clinic-wise
    const doctor = this.store.doctorListByClinic as Doctor[];
    doctor.forEach((dr, index )=> {
      if(index >= DOC_COLORS.length){
        this.newDoctorColorMap.push(new DoctorColorMap(dr.id, DEFAULT_DOC_COLOR));
      } else{
        this.newDoctorColorMap.push(new DoctorColorMap(dr.id, DOC_COLORS[index]));
      }
    });
    doctorColorMap = new Array<DoctorColorMap>();
    doctorColorCode.forEach(color => {
      const map: DoctorColorMap = new DoctorColorMap('', color);
      doctorColorMap.push(map);
    });
  }

  doctorIsLocum(doctorId) {
    const doctor = this.store.doctorList.find(
      doctor => doctor.id === doctorId && doctor.doctorGroup === 'LOCUM'
    );

    return doctor !== undefined;
  }

  getTemplate(eventType) {
    return calendarEventTemplate.find(x => {
      return x.type === eventType;
    });
  }

  getStartDate(blockedDate: BlockDate) {
    return blockedDate.calendarDayRange
      ? blockedDate.calendarDayRange.startDate
      : blockedDate.calendarDayYear
        ? blockedDate.calendarDayYear.date
        : null;
  }

  getEndDate(blockedDate: BlockDate) {
    return blockedDate.calendarDayRange
      ? blockedDate.calendarDayRange.endDate
      : blockedDate.calendarDayYear
        ? blockedDate.calendarDayYear.date
        : null;
  }
}
