import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryGrnDeliverAddress'
})
export class InventoryGrnDeliverAddressPipe implements PipeTransform {
  constructor(private store: StoreService, private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    let orderType = args;

    if (!value || !orderType){
      return ''
    }

    if (this.inventory.isPurchaseOrder(orderType)) {
      const supplier = this.store.getSupplierById(value);
      if (supplier) {
        return supplier.address.address;
      }
    } else if (this.inventory.isTransferOrder(orderType)) {
      const clinic = this.store.getClinicList().find(clinic => clinic.id === value);
      if (clinic) {
        return clinic.address.address;
      }
    }

    return '';
  }

}
