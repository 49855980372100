import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointments-change-clinic',
  templateUrl: './appointments-change-clinic.component.html',
  styleUrls: ['./appointments-change-clinic.component.scss'],
})
export class AppointmentsChangeClinicComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  onBtnCancelClicked() {}
}
