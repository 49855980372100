export class NirItems {
  id: string;
  doctorId: string;
  vaccineId: string;
  doseId: string;
  givenDate: string;
  placeGiven: string;
  batchNo: string;
  nirSubmissionDate: string;
  nirSubmissionStatus: string;
  vaccinationSchedules: string;
  patientId: string;
  patientIdno: PatientIdNo;
  patientDob: string;
  patientName: string;
  vaccineItemCode: string;
  vaccineItemDescription: string;
  vaccineDoctorName: string;
  vaccinationSDDCode: string;
  nirSubmissionError: string;

  adapt(obj?): NirItems {
    if (!obj) {
      return this;
    }
    this.id = obj.id || '';
    this.doctorId = obj.doctorId || '';
    this.vaccineId = obj.vaccineId || '';
    this.doseId = obj.doseId || '';
    this.givenDate = obj.givenDate || '';
    this.placeGiven = obj.placeGiven || '';
    this.batchNo = obj.batchNo || '';
    this.nirSubmissionDate = obj.nirSubmissionDate || '';
    this.nirSubmissionStatus = obj.nirSubmissionStatus || '';
    this.vaccinationSchedules = obj.vaccinationSchedules || '';
    this.patientId = obj.patientId || '';
    this.patientIdno = obj.patientIdno || '';
    this.patientDob = obj.patientDob || '';
    this.patientName = obj.patientName || '';
    this.vaccineItemCode = obj.vaccineItemCode || '';
    this.vaccineItemDescription = obj.vaccineItemDescription || '';
    this.vaccineDoctorName = obj.vaccineDoctorName || '';
    this.vaccinationSDDCode = obj.vaccinationSDDCode || '';
    this.nirSubmissionError = obj.nirSubmissionError || '';

    return this;
  }
}
export class PatientIdNo {
  idType: string;
  number: string;

  constructor(idType?: string, number?: string) {
    this.idType = idType || '';
    this.number = number || '';
  }
}
export enum NirStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}
