import { UtilsService } from './../../../services/utils.service';
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_NO_SECONDS_24_FORMAT,
  DB_FULL_DATE_FORMAT_ZERO_SECOND,
  DB_FULL_DATE_FORMAT_NO_SECOND,
  INPUT_DELAY,
  DB_FULL_DATE_TIMEZONE,
} from './../../../constants/app.constants';
import { AlertService } from './../../../services/alert.service';
import { ApiAppointmentsService } from './../../../services/api-appointments.service';
import { AppointmentsFormService } from './../../../services/appointments-form.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import DatePickerConfig from '../../../objects/DatePickerConfig';
import { StoreService } from '../../../services/store.service';
import * as moment from 'moment';
import {
  DoctorAvailableSlots,
  DateTimeSlots,
} from '../../../objects/Appointment';
import { pairwise, distinctUntilChanged, debounceTime, tap, startWith } from 'rxjs/operators';
import { ClinicCalendarQuery } from '../../../state/appointments/event-types/clinic-calendar/clinic-calendar.query';
import { CalendarAppointmentService } from '../../../state/appointments/calendar-appointment/calendar-appointment.service';
@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.scss'],
})
export class AddAppointmentComponent implements OnInit {
  @Input() appointmentFormGroup: FormGroup;
  @Input() clinicId;
  @Input() editMode = false;

  @Input()
  public customStyles: boolean = false;
  
  @Input()
  public fromHSG: boolean = false;

  appointmentMinDate: Date = new Date();
  startTime: FormControl = new FormControl();
  reminderTime: FormControl = new FormControl();
  availableTimes: Array<DoctorAvailableSlots>;
  availableTimesDropDownList: Array<string>;
  checkbox: false;
  allowDoubleRegistration: boolean = false;
  selectedDoc: String;

  constructor(
    private appointmentsFormService: AppointmentsFormService,
    private apiAppointmentsService: ApiAppointmentsService,
    private store: StoreService,
    private alertService: AlertService,
    private utilsService: UtilsService,
    private clinicCalendarQuery: ClinicCalendarQuery,
    private calendarAppointmentService: CalendarAppointmentService
  ) {
    // this.appointmentMinDate = new Date();
  }

  datePickerConfigArray: Array<DatePickerConfig>;

  ngOnInit() {
    if (!this.clinicId) {
      this.clinicId = this.store.getClinicId();
    }
    if(this.editMode){
      this.selectedDoc = this.appointmentFormGroup.get('preferredDoctor').value;
    }

    this.getAvailableTimes();
    this.configureTimePicker();
    this.initDatePickerConfigsForComponent();
    this.subscribeChangesOnDate();

    this.clinicCalendarQuery.clinicSelected$.subscribe(item => {
      this.allowDoubleRegistration = item.allowDoubleRegistration;
    })

    this.calendarAppointmentService.docChangeSubject.subscribe(docId => {
      this.selectedDoc = docId;
      this.getAvailableTimes();
    })
  }

  getAvailableTimes() {
    this.availableTimes = new Array<DoctorAvailableSlots>();
    this.apiAppointmentsService
      .listAvailableTimeByDoctor(
        this.clinicId,
        moment(this.appointmentFormGroup.get('visitDate').value).format(
          DISPLAY_DATE_FORMAT
        ),
        []
      )
      .subscribe(
        res => {
          if(!this.allowDoubleRegistration && this.selectedDoc && this.selectedDoc !== ""){
            res.payload.filter(slot => slot.timeSlot.length > 0).forEach(slot => {
              if(slot.doctorId === this.selectedDoc){
                this.showOnlyDocTimeSlots(slot.timeSlot);
              }
            })
          } else{
            res.payload.filter(slot => slot.timeSlot.length > 0).forEach(slot => {
              let timeSlots = new Array<string>();
              slot.timeSlot.forEach(time => {
                timeSlots.push(time.start);
              });
              const dateTime: DateTimeSlots = new DateTimeSlots(
                slot.timeSlot[0].calendarDayYear.date,
                timeSlots
              );
              const arrayDateTimeSlots = new Array<DateTimeSlots>();
              arrayDateTimeSlots.push(dateTime);

              const doctorAvailableSlots = new DoctorAvailableSlots(
                slot.doctorId,
                arrayDateTimeSlots
              );
              this.availableTimes.push(doctorAvailableSlots);
            });
            this.populateAvailableTimesDropdownList();
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  showOnlyDocTimeSlots(timeSlot) {
    this.availableTimesDropDownList = new Array<string>();
    let tempList = new Array<string>();
    if(this.editMode){
      tempList.push(moment(this.appointmentFormGroup.get('visitDate').value).format(
        DISPLAY_TIME_NO_SECONDS_24_FORMAT
      ));
    }
    timeSlot.forEach(slot => {
      this.availableTimesDropDownList.push(slot.start);
    });

    this.availableTimesDropDownList = this.mergeArray(
      this.availableTimesDropDownList,
      tempList
    );

    if(this.editMode){
      this.patchStartTime();
    }
  }

  populateAvailableTimesDropdownList() {
    this.availableTimesDropDownList = new Array<string>();
    if(this.editMode){
      this.availableTimesDropDownList.push(moment(this.appointmentFormGroup.get('visitDate').value).format(
        DISPLAY_TIME_NO_SECONDS_24_FORMAT
      ));
    }
    this.availableTimes.forEach((time: DoctorAvailableSlots) => {
      const availableTimes = time.getTimeSlotsByDate(
        moment(this.appointmentFormGroup.get('visitDate').value).format(
          DISPLAY_DATE_FORMAT
        )
      );
      this.availableTimesDropDownList = this.mergeArray(
        this.availableTimesDropDownList,
        availableTimes
      );
    });

    this.patchStartTime();
  }

  mergeArray(array1, array2) {
    let resultArray = [];
    let arr = array1.concat(array2);
    let len = arr.length;
    let assoc = {};

    while (len--) {
      let item = arr[len];

      if (!assoc[item]) {
        resultArray.unshift(item);
        assoc[item] = true;
      }
    }
    return resultArray.sort();
  }

  configureTimePicker() {
    const visitDate = this.appointmentFormGroup.get('visitDate');
    const reminderDate = this.appointmentFormGroup.get('reminderDate');

    // this.patchStartTime();
    this.patchReminderTimeInPicker();

    if (visitDate.disabled) {
      this.startTime.disable();
    }
    if (reminderDate.disabled) {
      this.reminderTime.disable();
    }
  }

  getVisitTime() {
    const date: Date = this.appointmentFormGroup.get('visitDate').value as Date;
    const time = this.utilsService.convertDateToTime(date);

    if (this.availableTimesDropDownList) {
      const availableTimeSlot = this.availableTimesDropDownList.find(
        timeOption => {
          return moment(
            timeOption,
            DISPLAY_TIME_NO_SECONDS_24_FORMAT
          ).isSameOrAfter(moment(time, DISPLAY_TIME_NO_SECONDS_24_FORMAT));
        }
      );

      if (availableTimeSlot === undefined) {
        return undefined;
      } else {
        return availableTimeSlot;
      }
    }
  }

  patchStartTime() {
    const visitTime = this.getVisitTime();
    if(this.editMode){
      this.startTime.patchValue(this.utilsService.convertDateToTime(
        this.appointmentFormGroup.get('visitDate').value));
    } else{
      if (
        this.utilsService.convertDateToTime(
          this.appointmentFormGroup.get('visitDate').value
        ) !== this.startTime.value
      ) {
        this.startTime.patchValue(visitTime);
      } else {
        this.startTime.patchValue(visitTime, { emitEvent: false });
      }

      // if visitTime is available, run the following set up
      if (visitTime != undefined && visitTime != null && visitTime != '') {
        // get startTime when component initiating && format visitDateString to ddd MMM DD YYYY HH:mm:ss ZZ
        const visitDateString = this.appointmentFormGroup.get('visitDate').value;
        let convertedVisitDate = moment(visitDateString, DB_FULL_DATE_TIMEZONE);
  
        // set visitTime hours and minutes to visitDate
        const splitTime = visitTime.split(':');
        convertedVisitDate.set({h: parseInt(splitTime[0]), m: parseInt(splitTime[1])});
        this.patchVisitDateFromTimeSlotPicked(convertedVisitDate.toDate());
      }
    }
  }

  patchReminderTimeInPicker() {
    this.reminderTime.patchValue(
      this.appointmentFormGroup.get('reminderDate').value,
      { emitEvent: false }
    );
  }

  patchVisitDateFromTimeSlotPicked(date: Date) {
    this.appointmentFormGroup.get('visitDate').patchValue(date);
  }

  getMinutes(timeString) {
    if(timeString !== undefined)
      return timeString.split(':')[1];
  }

  getHours(timeString) {
    if(timeString !== undefined)
      return timeString.split(':')[0];
  }

  patchReminderDateFromPicker() {
    this.appointmentFormGroup
      .get('reminderDate')
      .patchValue(this.reminderTime.value, { emitEvent: false });
  }

  initDatePickerConfigsForComponent() {
    this.datePickerConfigArray = new Array<DatePickerConfig>();

    const datepickerFrom: DatePickerConfig = this.initDatePickerConfigObject(
      this.customStyles ? 'Visit Date' : 'Visit Date / Time',
      null,
      this.appointmentMinDate,
      'bottom',
      this.customStyles ? '' : 'top'
    );
    
    const datepickerTo: DatePickerConfig = this.initDatePickerConfigObject(
      'Reminder Date / Time',
      null,
      this.appointmentMinDate,
      'bottom',
      'top'
    );
    this.datePickerConfigArray['appointment'] = datepickerFrom;
    this.datePickerConfigArray['reminder'] = datepickerTo;
  }

  initDatePickerConfigObject(
    label,
    maxDate,
    minDate,
    datePickerPlacement,
    labelPlacement
  ) {
    return new DatePickerConfig(
      label,
      maxDate,
      minDate,
      datePickerPlacement,
      labelPlacement
    );
  }

  subscribeChangesOnDate() {
    this.subscribeChangesOnVisitDateOrTime();
    this.subscribeChangesOnReminderDateOrTime();
  }

  subscribeChangesOnVisitDateOrTime() {
    this.appointmentFormGroup
      .get('visitDate')
      .valueChanges.pipe(
        tap(value => {}),
        debounceTime(INPUT_DELAY),
        startWith(this.appointmentFormGroup.get('visitDate').value),
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([previousDate, newDate]: [any, any]) => {
        if (
          moment(previousDate).format(DISPLAY_DATE_FORMAT) !==
          moment(newDate).format(DISPLAY_DATE_FORMAT)
        ) {
          if(this.checkbox){
            this.getClinicTimeSlots();
          } else {
            this.getAvailableTimes();
          }
        }
        this.setReminderDateTimeFromVisitDateChange(newDate);
      });

    this.startTime.valueChanges
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevTime, newTime]: [any, any]) => {
        const date: Date = this.appointmentFormGroup.get('visitDate')
          .value as Date;
        date.setHours(this.getHours(newTime));
        date.setMinutes(this.getMinutes(newTime));
        this.patchVisitDateFromTimeSlotPicked(date);

        if (prevTime !== newTime) {
          this.setReminderDateTimeFromVisitDateChange(date);
        }
      });
  }

  subscribeChangesOnReminderDateOrTime() {
    this.appointmentFormGroup
      .get('reminderDate')
      .valueChanges.subscribe((value: Date) => {
        this.patchReminderTimeInPicker();
      });

    this.reminderTime.valueChanges.subscribe((value: Date) => {
      this.patchReminderDateFromPicker();
    });
  }

  setReminderDateTimeFromVisitDateChange(date: Date) {
    const updatedReminderTime = this.utilsService.decreaseTimeByInterval(
      new Date(date),
      60
    );
    this.appointmentFormGroup
      .get('reminderDate')
      .patchValue(updatedReminderTime, { emitEvent: false });
    this.reminderTime.patchValue(updatedReminderTime, { emitEvent: false });
  }

  onCheckBoxChanged(){
   if(this.checkbox){
    // this.getAvailableTimes();
    this.getClinicTimeSlots();
   } else{
    this.getAvailableTimes();
   }
  }

  getClinicTimeSlots() {
     this.clinicCalendarQuery.clinicSelected$.subscribe(clinicItem => {
       if(clinicItem){
        this.availableTimesDropDownList = new Array<string>();
        clinicItem.operationHours.filter(item => item.calendarDayWeek.dayOfWeek === moment(this.appointmentFormGroup.get('visitDate').value).format('dddd').toUpperCase()).map(item => {
          
          let createdSlot = item.start;
          this.availableTimesDropDownList.push(createdSlot);

          while(moment(createdSlot,DISPLAY_TIME_NO_SECONDS_24_FORMAT).isBefore(moment(item.end,DISPLAY_TIME_NO_SECONDS_24_FORMAT))){
            createdSlot = moment(createdSlot,DISPLAY_TIME_NO_SECONDS_24_FORMAT)
              .add(clinicItem.avgConsultationTime, 'minutes')
              .format(DISPLAY_TIME_NO_SECONDS_24_FORMAT);

            if(createdSlot !== item.end){
              this.availableTimesDropDownList.push(createdSlot);
            }
          }          
        });
      }
    })
  }

  getAvailableTimesByClinic() {
    this.apiAppointmentsService
      .listAvailableTimeByClinic(
        this.clinicId,
        moment(this.appointmentFormGroup.get('visitDate').value).format(
          DISPLAY_DATE_FORMAT
        )
      )
      .subscribe(
        res => {
          this.availableTimesDropDownList = new Array<string>();
          res.payload.forEach(slot => {
            this.availableTimesDropDownList.push(slot.start);
          });
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }
}