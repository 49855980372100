import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'approvedInventoryDisplayOrderSubtotal'
})
export class ApprovedInventoryDisplayOrderSubtotalPipe implements PipeTransform {

  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    let needConversation = args

    if (!value) {
      return 0
    }

    return this.inventory.getApprovedOrderSubtotal(needConversation ? this.inventory.convertOrderItemFormArrayValueToOrderItemArray(value) : value);

  }

}
