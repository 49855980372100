import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryDisplayReturnRequestSubtotal'
})
export class InventoryDisplayReturnRequestSubtotalPipe implements PipeTransform {
  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    let needConversation = args

    return this.inventory.getReturnRequestSubtotal(needConversation ? this.inventory.convertReturnRequestItemFormArrayValueToOrderReturnRequestItemArray(value) : value);
  }
}
