import { Pipe, PipeTransform } from '@angular/core';
import { OrderReturnRequestStatus } from '../objects/OrderReturnRequest';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'displayReturnRequestStatusLabel'
})
export class DisplayReturnRequestStatusLabelPipe implements PipeTransform {
  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }

    return this.inventory.getReturnRequestStatusLabel(value);
  }
}
