import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { MedicalCoverage } from './../objects/MedicalCoverage';
import { PatientNoteAdd } from './../objects/request/PatientNoteAdd';
import { HttpResponseBody } from './../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';
import { BackendService } from './backend.service';

// import { API_URL, API_CMS_MANAGEMENT_URL } from './../constants/app.constants';
@Injectable()
export class ApiCmsManagementService {
  private API_URL;
  private API_CMS_MANAGEMENT_URL;
  private API_PACKAGE_ITEM_INFO_URL;
  private API_ASSESSMENT_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private backendService: BackendService
  ) {
    this.API_URL = appConfig.getConfig().API_URL;
    this.API_CMS_MANAGEMENT_URL = appConfig.getConfig().API_CMS_MANAGEMENT_URL;
    this.API_PACKAGE_ITEM_INFO_URL = appConfig.getConfig().API_PACKAGE_ITEM_INFO_URL;
    this.API_ASSESSMENT_URL = appConfig.getConfig().API_ASSESSMENT_URL;
  }

  uploadLabel(name: string, template: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/label/add`,
      {
        name,
        template,
      }
    );
  }

  searchCoverage(value: string): Observable<HttpResponseBody> {
    const list = this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/coverage/search/${value}/false`,
      {}
    );
    return list;
  }

  searchCoverageByClinic(
    clinicId: string,
    value: string
  ): Observable<HttpResponseBody> {
    const list = this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/coverage/search/${clinicId}/${value}/false`,
      {}
    );
    return list;
  }

  searchCoverageByClinicObj(
    clinicId: string,
    value: string
  ): Observable<MedicalCoverage[]> {
    const list: Observable<MedicalCoverage[]> = this.http
      .post<HttpResponseBody>(
        `${this.API_CMS_MANAGEMENT_URL}/coverage/search/${clinicId}/${value}/false`,
        {}
      )
      .pipe(
        map((res: HttpResponseBody) => {
          return res.payload;
        })
      );
    return list;
  }

  searchCoverageByPlanId(value: string): Observable<HttpResponseBody> {
    const list = this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/coverage/search/${value}`,
      {}
    );
    return list;
  }

  listMedicalCoveragesWithPagination(
    page: number = 0,
    size: number = 10000,
    queue = false
  ): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/coverage/list/${page}/${size}/false`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listMedicalCoverages(
    page: number = 0,
    size: number = 100
  ): Observable<HttpResponseBody> {
    const list = this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/coverage/list/all/false`,
      {}
    );
    return list;
  }

  listClinics(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/clinic/list/all`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listDoctors(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/doctor/list/all`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listDoctorsByClinic(clinicId, queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/doctor/list/all/${clinicId}`;
    const body = {};
    const options = new HttpHeaders({});

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  searchDrugsBy(
    searchBy: string = 'name',
    keyword: string
  ): Observable<HttpResponseBody> {
    const list = this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/drug/search/${searchBy}/${keyword}`,
      {}
    );
    return list;
  }

  searchDrugs(keyword: string): Observable<HttpResponseBody> {
    const list = this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/drug/search/${keyword}`,
      {}
    );
    return list;
  }

  listVisitPurposes(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/visit-purpose/list/all`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listSpecialities(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/list/speciality`,
      {}
    );
  }

  listAllergyandADRAlertMappings(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/list/ALLERGY_ADR_ALERT_MAPPING`,
      {}
    );
  }

  listSFLSetupMapping(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/list/SFL_SETUP_MAPPING`,
      {}
    );
  }

  listAddress(zipcode: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/postcode/${zipcode}`,
      {}
    );
  }

  listSystemStoreValuesByKey(key: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/list/${key}`,
      {}
    );
  }

  searchDiagnosis(
    term: string,
    planIds?: Array<string>,
    globalSearch?: boolean,
    clinicId: string = null
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/diagnosis/search/${term}`,
      { planIds, globalSearch, clinicId }
    );
  }

  searchAllDiagnosis(
    planIds?: Array<string>,
    globalSearch?: boolean,
    clinicId: string = null
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/diagnosis/search-all`,
      { planIds, globalSearch, clinicId }
    );
  }

  searchDiagnosisByIds(ids: String[]): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/diagnosis/search`,
      ids
    );
  }

  searchDiagnosisByIcdCodes(codes: String[]): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/diagnosis/search/bycode`,
      codes
    );
  }

  addLabel(name: string, template: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/label/add`,
      {
        name,
        template,
      }
    );
  }

  updateLabel(
    labelId: string,
    name: string,
    template: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/label/modify/${labelId}`,
      {
        name,
        template,
      }
    );
  }

  searchLabel(name: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/label/search/${name}`,
      {}
    );
  }

  printMedicalCertificate(
    certificateRefNumList: any[],
    visitId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/label/print/medicalCertificate/${visitId}/${templateName}`,
      JSON.stringify(certificateRefNumList)
    );
  }

  sendSmsForMedicalCertificate(
    refNo: string,
    reqBody: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/label/send/medicalCertificate/${refNo}`,
      JSON.stringify(reqBody)
    );
  }

  printMedicalCoverages(
    medicalCoverageList: any[],
    clinicId: string,
    templateName: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/label/print/medicalCoverage/${clinicId}/${templateName}`,
      JSON.stringify(medicalCoverageList)
    );
  }

  validateIdentification(
    type: string,
    idNumber: string
  ): Observable<HttpResponseBody> {
    const list = this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/validate/${type}/${idNumber}`,
      {}
    );
    return list;
  }

  loadTemplate(
    templateType = 'DOCTOR',
    templateId: string,
    doctorId: string,
    patientId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/consultation-template/load/${templateType}/${templateId}/${doctorId}/${patientId}`,
      {}
    );
  }

  loadTemplateWithVisitId(
    templateType = 'DOCTOR',
    templateId: string,
    doctorId: string,
    patientId: string,
    visitId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/consultation-template/load/${templateType}/${templateId}/${doctorId}/${patientId}/${visitId}`,
      {}
    );
  }

  listTemplates(
    clinicId: string,
    doctorId: string,
    queue = false
  ): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/consultation-template/list/${clinicId}/${doctorId}/`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  /**
   * @param {string} doctorId
   * @returns {Observable<HttpResponseBody>}
   * @memberof ApiCmsManagementService
   */
  listPatientNotes(patientId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/patient-note/load/${patientId}/`,
      {}
    );
  }

  addPatientNote(
    patientId: string,
    note: PatientNoteAdd
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/patient-note/new-note/${patientId}/`,
      JSON.stringify(note)
    );
  }
  updatePatientNote(
    patientId: string,
    patientNoteId: string,
    note: PatientNoteAdd
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/patient-note/update-note/${patientId}/${patientNoteId}`,
      JSON.stringify(note)
    );
  }

  modifyPatientNote(
    patientId: string,
    patientNoteDetailId: string,
    status: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/patient-note/change-state/${patientId}/${patientNoteDetailId}/${status}`,
      {}
    );
  }

  listInstructions(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_PACKAGE_ITEM_INFO_URL}/list/instructions`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listChargeItems(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_PACKAGE_ITEM_INFO_URL}/list`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService
        .invoke(url, body, options)
        .pipe(map(data => data.payload.map(value => {
          value.item.nameCode = `${value.item.name}-${value.item.code}`;

          if (value.item.sellingPrice) {
            value.item.sellingPrice.price = value.item.sellingPrice.price * 100;
          }

          if (value.item.subItems && value.item.subItems.length > 0) {
            value.item.subItems.forEach(subItem => {
              subItem.sellingPrice.price = subItem.sellingPrice.price * 100;
            });
          }
          return value.item;
        })))

      : this.http
        .post<HttpResponseBody>(url, body)
        .pipe(map(data => data.payload.map(value => {
          value.item.nameCode = `${value.item.name}-${value.item.code}`;

          if (value.item.sellingPrice) {
            value.item.sellingPrice.price = value.item.sellingPrice.price * 100;
          }

          if (value.item.subItems && value.item.subItems.length > 0) {
            value.item.subItems.forEach(subItem => {
              subItem.sellingPrice.price = subItem.sellingPrice.price * 100;
            });
          }
          return value.item;
        })));
  }

  listChargeItemsByClinicId(
    clinicId: string[],
    queue = false
  ): Observable<HttpResponseBody> {
    const url = `${this.API_PACKAGE_ITEM_INFO_URL}/list/clinic`;
    const body = clinicId;
    const options = {};

    return queue
      ? this.backendService
        .invoke(url, body, options)
        .pipe(map(data => data.payload.map(value => {
          value.item.nameCode = `${value.item.name}-${value.item.code}`;
          return value.item;
        })))
      : this.http
        .post<HttpResponseBody>(url, body)
        .pipe(map(data => data.payload.map(value => {
          value.item.nameCode = `${value.item.name}-${value.item.code}`;
          return value.item;
        })));
  }

  listPurchasableChargeItemsByClinicId(
    clinicId: string[],
    queue = false
  ): Observable<HttpResponseBody> {
    const url = `${this.API_PACKAGE_ITEM_INFO_URL}/purchasable/list/clinic`;
    const body = clinicId;
    const options = {};
    return queue
      ? this.backendService
        .invoke(url, body, options)
        .pipe(map(data => data.payload.map(value => value.item)))
      : this.http
        .post<HttpResponseBody>(url, body)
        .pipe(map(data => data.payload.map(value => value.item)));
  }

  searchListItem(drugId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PACKAGE_ITEM_INFO_URL}/search/by-id/${drugId}`,
      {}
    );
  }

  searchListItemByKeyWord(keyword: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PACKAGE_ITEM_INFO_URL}/filter/${keyword}`,
      {}
    );
  }

  searchListItemByIdAndClinicId(
    clinicId: string[],
    id: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PACKAGE_ITEM_INFO_URL}/search/clinic/by-id/${id}`,
      clinicId
    );
  }

  searchListItemByKeyWordAndClinicIdAndCategory(
    clinicId: string,
    category: string,
    itemIds: string[],
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PACKAGE_ITEM_INFO_URL}/search/${clinicId}/${category}`,
      itemIds
    );
  }

  searchListItemByKeyWordAndClinicId(
    clinicId: string[],
    keyword: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PACKAGE_ITEM_INFO_URL}/filter/clinic/${keyword}`,
      clinicId
    );
  }

  searchListItemByKeyWordInventoryAndClinicId(
    clinicId: string,
    keyword: string,
    planIds: string[] = []
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PACKAGE_ITEM_INFO_URL}/filter/clinic/inventory/${keyword}`,
      {
        clinicId,
        planIds,
      }
    );
  }

  listAllObservations(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/observation/list-all-masters`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listAllVitalConfigurations(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/system-config/list/vitals`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listDefaultVitals(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/system-config/list/vitals/default`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listDefaultAssessments(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_ASSESSMENT_URL}/master/get/default`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listTemplateTag(templateType): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/template/tags/add/${templateType}`,
      {}
    );
  }

  listVaccinations(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/vaccination/list/all`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listVaccinationExemptionConditions(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/vaccination/list/vaccination-exceptional-conditions`,
      {}
    );
  }

  listVaccinationByClinicId(clinicId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/vaccination/list/clinic/${clinicId}`,
      {}
    );
  }

  listReferral(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/referral/list`,
      {}
    );
  }

  listReferralSpeciality(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/referral/list/speciality`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listReferralByType(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_CMS_MANAGEMENT_URL}/referral/list/type`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listSupplier(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/supplier/list/all`,
      {}
    );
  }

  listAllChronicConditions() {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/list/pcn-conditions`,
      JSON.stringify({})
    );
  }

  listDeliveryLocations() {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/remote-delivery/list/delivery-location`,
      JSON.stringify({})
    );
  }

  listWhitelistedItems() {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/item/list/whitelisted`,
      JSON.stringify({})
    );
  }

  listSflItems() {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/item/list/sfl`,
      JSON.stringify({})
    );
  }

  listRemoteDeliverySetup() {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/system-config/list/REMOTE_DELIVERY_SETUP`,
      JSON.stringify({})
    );
  }
}
