import { combineLatest, Subject, of, BehaviorSubject, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, switchMap, filter, distinctUntilChanged, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AlertService } from './../../alert.service';
import { ApiCaseManagerService } from './../../api-case-manager.service';
import { ApiPatientInfoService } from '../../api-patient-info.service';
import { MedicalCoverageFormService } from './../../medical-coverage-form.service';
import { TempStoreService } from '../../temp-store.service';

import { createMedicalCoverageResponse } from './../../../objects/response/MedicalCoverageResponse';
import { AttachedMedicalCoveragesQuery } from './../akita-case/akita-attached-medical-coverages/attached-medical-coverages.query';
import { AkitaCaseStoreService } from './../akita-case/akita-case-store.service';

import { StoreService } from './../../store.service';
import { ApiPatientVisitService } from './../../api-patient-visit.service';
import { AkitaMedicalCoverageQuery } from './../akita-medical-coverage.query';
import { PatientMedicalAlertsStore } from './patient-medical-alerts/patient-medical-alerts.store';

import { PatientCoverage } from './../../../objects/MedicalCoverage';
import PatientInfo from '../../../objects/state/PatientInfo';
import { createCoverageInfo, CoverageInfo } from '../../../objects/state/CoverageInfo';
import { MedicalCoverageByCaseRes } from '../../../objects/PolicyHolderInfo';
import { MedicalAlert } from '../../../objects/response/MedicalAlert';
import { Page } from '../../../model/page';

import { AkitaAppQuery } from './../akita-app.query';
import { AkitaPatientAppQuery } from './akita-patient-app.query';
import { AkitaPatientAppStore, AkitaPatientApp } from './akita-patient-app.store';
import { PatientMedicalCoverageStore } from './patient-medical-coverages/patient-medical-coverage.store';
import { PatientVisitHistoryStore } from './patient-visit-history/patient-visit-history.store';
import { PatientMedicalCoverageCorpStore } from './patient-medical-coverages/patient-medical-coverage-corp.store';

import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../constants/app.constants';

import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})
export class AkitaPatientStoreService {
  patientId: string;
  visitId: string;
  storeConfig: string[];
  patientVisitHistoryLoaded$ = new Subject();

  private storeRefresh: Subject<void> = new Subject();
  private medicalCoverageTakeUnitill = new Subject();
  private medicalCoverageLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private chasData: any;
  private medisaveData: any;
  private insuranceData: any;
  private chasDataSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private selectedVaccineDataSrc = new BehaviorSubject('');
  public selectedVaccineData = this.selectedVaccineDataSrc.asObservable();

  private isValidVaccineAndCDSSrc = new BehaviorSubject(false);
  public isValidVaccineAndCDS = this.isValidVaccineAndCDSSrc.asObservable();

  private updateKycState = new Subject();
  private corporateData: any;

  constructor(
    private akitaAppQuery: AkitaAppQuery,
    private akitaMedCovQuery: AkitaMedicalCoverageQuery,
    private apiCaseManagerService: ApiCaseManagerService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaPatientAppStore: AkitaPatientAppStore,
    private alertService: AlertService,
    private apiPatientInfoService: ApiPatientInfoService,
    private patientMedicalCoverageStore: PatientMedicalCoverageStore,
    private patientMedicalCoverageCorpStore: PatientMedicalCoverageCorpStore,
    private patientMedicalAlertsStore: PatientMedicalAlertsStore,
    private patientVisitHistoryStore: PatientVisitHistoryStore,
    private apiPatientVisitService: ApiPatientVisitService,
    private medCovFormService: MedicalCoverageFormService,
    private akitaCaseStore: AkitaCaseStoreService,
    private attachedMedicalCoverageQuery: AttachedMedicalCoveragesQuery,
    private router: Router,
    private store: StoreService,
    private tempStoreService: TempStoreService
  ) {
    this.subscribeChangesOnApp();
  }

  subscribeChangesOnApp() {
    this.akitaPatientAppQuery.$selectIdAndConfig
      .pipe(
        distinctUntilChanged(),
        filter((app: AkitaPatientApp) => app && app.id && app.id !== '')
      )
      .subscribe((app: AkitaPatientApp) => {
        this.patientId = app.id;
        this.store.refreshPatientId(this.patientId);
        this.loadStore();
      });
  }

  public loadStore(): void {
    this.medicalCoverageTakeUnitill.next();
    this.getPatientInfo();
    this.getMedicalCoveragesByUserName();
    this.getAlerts();
    this.getPatientVisitHistoryWithDateRangeAndStatusFilter()
  }

  setPatientApp(id: string, storeConfig: string[], visitId?: string) {
    this.akitaPatientAppStore.update({ id: id, storeConfig: storeConfig });
    this.patientId = id;
    this.visitId = visitId;
    this.storeConfig = storeConfig;
  }

  setPatientInfo(info: PatientInfo) {
    this.akitaPatientAppStore.update({ patientInfo: info });
    this.getAlerts();
  }

  reset(refresh?) {
    this.storeRefresh.next();
    this.patientMedicalCoverageStore.remove();
    this.patientMedicalCoverageCorpStore.remove();
    this.patientMedicalAlertsStore.remove();
    this.akitaPatientAppStore.reset();
    this.patientVisitHistoryStore.remove();

    this.akitaCaseStore.reset();

    if (refresh) {
      this.setPatientApp(this.patientId, this.storeConfig, this.visitId);
    } else {
      this.patientId = '';
      this.storeConfig = [];
    }
  }

  getPatientInfo() {

    this.apiPatientInfoService
      .searchBy('systemuserid', this.patientId)
      .subscribe(
        res => {
          this.setPatientInfo(res.payload);
          this.updateKycState.next(res.payload && res.payload.kycData && res.payload.kycData.kycStatus)
        },
        err => {
          this.alertService.error(JSON.stringify(err));
          this.router.navigate(['pages/patient/list']);
        }
      );
  }

  getMedicalCoveragesByUserName() {
    this.medicalCoverageLoading.next(true);
    combineLatest(
      this.akitaPatientAppQuery.patientInfo$.pipe(
        filter(res => res !== null && res !== undefined && res.id === this.patientId),
        distinctUntilChanged((a, b) => a.id === b.id),
        switchMap((info: PatientInfo) => this.getMedicalCoverageList(info.userId, this.visitId)),
        takeUntil(this.storeRefresh)
      ),
      this.attachedMedicalCoverageQuery.attachedMedicalCoverages$.pipe(
        takeUntil(this.storeRefresh)
      ),
      this.akitaMedCovQuery
        .selectAll()
        .pipe(filter(x => x !== null && x.length > 0))
    ).pipe(
      takeUntil(this.medicalCoverageTakeUnitill)
    ).subscribe(data => {
      this.patientMedicalCoverageStore.remove();
      const info: CoverageInfo = data[0];
      const caseDetail: MedicalCoverageByCaseRes = data[1];
      const patientCoverages: PatientCoverage[] = this.medCovFormService.populateFromInfo(
        info, 
        caseDetail,
        this.chasData,
        this.corporateData,
        this.medisaveData,
        this.insuranceData
      );

      const tempServicesToBeRun = [];

      if (patientCoverages.length > 0) {
        patientCoverages.forEach((coverage: PatientCoverage) => {
          const tempKey = `${this.patientId}_${coverage.planId}`;
          tempServicesToBeRun.push(this.tempStoreService.tempStoreRetrieve(tempKey));
        });

        forkJoin(tempServicesToBeRun).subscribe(result => {
          result.forEach((result: any) => {
            if (result.payload) {
              const foundCoverage = patientCoverages.filter((coverage: PatientCoverage) => result.payload.id.split('_')[1] === coverage.planId);
              const indexOfFoundCoverage = patientCoverages.indexOf(foundCoverage[0]);
              patientCoverages[indexOfFoundCoverage].planSelected.paymentRemarks = result.payload.value;
            }
          })
          this.patientMedicalCoverageStore.set(patientCoverages);
          this.medicalCoverageLoading.next(false);
        });
      } else {
        this.patientMedicalCoverageStore.set(patientCoverages);
        this.medicalCoverageLoading.next(false);
      }
    });
  }

  getMedicalCoverageList(userId, visitId?) {
    return this.apiPatientInfoService.searchAssignedPoliciesByUserId(userId, visitId).pipe(
      takeUntil(this.storeRefresh),
      distinctUntilChanged(),
      map(policies => this.medCovFormService.processResponse(policies)),
      catchError(err => {
        this.alertService.error(JSON.stringify(err));
        return of({ coverages: createMedicalCoverageResponse(), plans: [] });
      }),
      mergeMap(policies => this.akitaAppQuery.appClinicId$.pipe(map(clinicId => [clinicId, policies]))),
      filter(data => !!data && !!data[0]),
      switchMap(data => {
        this.chasData = data[1]['coverages']['CHAS'];
        this.corporateData = data[1]['coverages']['CORPORATE'];
        this.medisaveData = data[1]['coverages']['MEDISAVE'];
        this.insuranceData = data[1]['coverages']['INSURANCE'];
        this.chasDataSubject.next(this.chasData);
        let coverageInfo$;

        if (data[1]['plans'].length < 1) {
          coverageInfo$ = of(createCoverageInfo({}));
        } else {
          coverageInfo$ = this.apiCaseManagerService.getBalanceByPatientId(
            this.patientId,
            this.akitaAppQuery.getValue().clinicId,
            data[1]['plans']
          ).pipe(
            map(res => createCoverageInfo(<CoverageInfo>res.payload), err => this.alertService.error(JSON.stringify(err))),
            takeUntil(this.storeRefresh),
          );
        }

        return coverageInfo$.pipe(takeUntil(this.storeRefresh));
      })
    );
  }

  getMedicalCoveragesByIdentity(searchParam, newPatientId?) {

    if (newPatientId) {
      combineLatest(
        this.getMedicalCoverageListByIdentity(searchParam),
        this.attachedMedicalCoverageQuery.attachedMedicalCoverages$.pipe(
          takeUntil(this.storeRefresh)
        ),
        this.akitaMedCovQuery
          .selectAll()
          .pipe(filter(x => x !== null && x.length > 0))
      ).pipe(
        takeUntil(this.medicalCoverageTakeUnitill)
      ).subscribe(data => {
        this.patientMedicalCoverageCorpStore.remove();
        const info: CoverageInfo = data[0];
        const caseDetail: MedicalCoverageByCaseRes = data[1];
        const patientCoverages: PatientCoverage[] = this.medCovFormService.populateFromInfoForCorpHr(info, caseDetail, this.chasData, this.corporateData, this.insuranceData);
        this.patientMedicalCoverageCorpStore.set(patientCoverages);
      });
    } else {
      combineLatest(
        this.akitaPatientAppQuery.patientInfo$.pipe(
          filter(res => res !== null && res !== undefined && res.id === this.patientId),
          distinctUntilChanged((a, b) => a.id === b.id),
          switchMap((info: PatientInfo) => this.getMedicalCoverageListByIdentity(searchParam)),
          takeUntil(this.storeRefresh)
        ),
        this.attachedMedicalCoverageQuery.attachedMedicalCoverages$.pipe(
          takeUntil(this.storeRefresh)
        ),
        this.akitaMedCovQuery
          .selectAll()
          .pipe(filter(x => x !== null && x.length > 0))
      ).pipe(
        takeUntil(this.medicalCoverageTakeUnitill)
      ).subscribe(data => {
        this.patientMedicalCoverageCorpStore.remove();
        const info: CoverageInfo = data[0];
        const caseDetail: MedicalCoverageByCaseRes = data[1];
        const patientCoverages: PatientCoverage[] = this.medCovFormService.populateFromInfoForCorpHr(info, caseDetail, this.chasData, this.corporateData, this.insuranceData);
        this.patientMedicalCoverageCorpStore.set(patientCoverages);
      });
    }
  }

  getMedicalCoverageListByIdentity(searchParam) {
    return this.apiPatientInfoService.searchCoperatePolicyHolderByIdentity(searchParam).pipe(
      takeUntil(this.storeRefresh),
      distinctUntilChanged(),
      map(policies => this.medCovFormService.processResponse(policies)),
      catchError(err => {
        this.alertService.error(JSON.stringify(err));
        return of({ coverages: createMedicalCoverageResponse(), plans: [] });
      }),
      mergeMap(policies => this.akitaAppQuery.appClinicId$.pipe(map(clinicId => [clinicId, policies]))),
      filter(data => !!data && !!data[0]),
      switchMap(data => {
        let corporateList = data[1]['coverages']['CORPORATE'];
        this.corporateData = data[1]['coverages']['CORPORATE'];
        this.chasData = data[1]['coverages']['CHAS'];
        this.insuranceData = data[1]['coverages']['INSURANCE'];
        this.chasDataSubject.next(this.chasData);
        let coverageInfo$;

        if (data[1]['plans'].length < 1) {
          coverageInfo$ = of(createCoverageInfo({}));
        } else {
          coverageInfo$ = this.apiCaseManagerService.getBalanceByPatientId(
            this.patientId || null,
            this.akitaAppQuery.getValue().clinicId,
            data[1]['plans']
          ).pipe(
            map(res => {
              let customRes = res;
              let policyHolderList = [];
              corporateList.forEach(element => {
                policyHolderList.push(element.policyHolder);
              });
              customRes.payload.policyHolder = policyHolderList;
              return createCoverageInfo(<CoverageInfo>customRes.payload)
            }, err => this.alertService.error(JSON.stringify(err))),
            takeUntil(this.storeRefresh),
          );
        }
        return coverageInfo$.pipe(takeUntil(this.storeRefresh));
      })
    );
  }

  getAlerts() {

    this.patientMedicalAlertsStore.remove();
    this.apiPatientInfoService
      .listAlert(this.patientId)
      .pipe(takeUntil(this.storeRefresh))
      .subscribe(
        res => {
          if (res.payload) {
            const alertDetails = <Array<MedicalAlert>>res.payload.details;
            const tempAlertArray: MedicalAlert[] = [];
            alertDetails.forEach(alert => {
              if (alert.alertType !== 'ALLERGY') {
                tempAlertArray.push(alert);
              }
            });

            tempAlertArray.sort((a, b) => a.alertStatus.localeCompare(b.alertStatus));
            this.patientMedicalAlertsStore.addNewData(tempAlertArray);
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err));
        }
      );
  }

  getVisitHistory() {

    this.patientVisitHistoryStore.remove();

    this.apiPatientVisitService
      .getPatientVisitHistory(this.patientId)
      .subscribe(
        res => {
          if (res.payload) {
            this.patientVisitHistoryStore.add(res.payload);
          }
        },
        err => this.alertService.error(JSON.stringify(err))
      );
  }

  getPatientVisitHistoryWithDateRangeAndStatusFilter(
    startDate = moment().subtract(1, 'years').format('DD-MM-YYYY'),
    endDate = moment().format('DD-MM-YYYY'),
    status = ['INITIAL', 'CONSULT', 'PAYMENT', 'POST_CONSULT', 'COMPLETE'],
    page = new Page(0, 15)
  ) {
    this.apiPatientVisitService
      .getPatientVisitHistoryWithDateRangeAndStatusFilter(this.patientId, startDate, endDate, status, page)
      .subscribe(
        res => {
          if (res.payload) {
            const data = res.payload.sort((a, b) => {
              const momentA = moment(
                a.startTime ? a.startTime : new Date(),
                DB_FULL_DATE_FORMAT
              );
              const momentB = moment(
                b.startTime ? b.startTime : new Date(),
                DB_FULL_DATE_FORMAT
              );
              return momentB.diff(momentA);
            });

            this.patientVisitHistoryStore.remove();

            this.patientVisitHistoryStore.upsertMany(data);
            page.pageNumber = res['pageNumber'];
            page.totalPages = res['totalPages'];
            page.totalElements = res['totalElements'];
            this.patientVisitHistoryStore.updatePageDate(page, moment(startDate, DISPLAY_DATE_FORMAT).toDate(), moment(endDate, DISPLAY_DATE_FORMAT).toDate());

            this.patientVisitHistoryLoaded$.next(true);
          }
        },
        err => this.alertService.error(JSON.stringify(err))
      );
  }



  getDocuments() {

  }

  getVaccinationCerts() {

  }

  getProblemList() {
  }

  selectMedicalCoverageLoading() {
    return this.medicalCoverageLoading.asObservable();
  }

  getChasData() {
    return this.chasDataSubject.asObservable();
  }

  setSelctedVaccineData(data: any) {
    this.selectedVaccineDataSrc.next(data)
  }

  setValidVaccineAndCDSSrc(data: any) {
    this.isValidVaccineAndCDSSrc.next(data)
  }

  getUpdateKycState() {
    return this.updateKycState;
  }
}
