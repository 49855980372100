import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { DosageInstruction, createDosageInstruction } from '../../objects/state/DosageInstruction';
import { AkitaDosageInstructionStore } from './akita-dosage-instruction.store';

@Injectable({ providedIn: 'root' })
export class AkitaDosageInstructionQuery extends QueryEntity<
DosageInstruction
> {
  dosageInstructionList$ = this.selectAll();

  activeDosageInstructionList$ = this.selectAll({
    filterBy: ({ status }) => !status || status === 'ACTIVE'
  });

  constructor(protected store: AkitaDosageInstructionStore) {
    super(store);
  }


  getDosageInstruction(code): DosageInstruction {
    if (this.getAll().length > 0) {
      const instruction = <DosageInstruction>this.getAll().find(
        (entity: DosageInstruction) => entity.code === code
      );
      if (instruction) return instruction;
    }
    return createDosageInstruction();
  }

  getActiveDosageInstruction(): DosageInstruction[] {
    if (this.getAll().length > 0) {
      const instructions = <DosageInstruction[]>this.getAll().filter(
        (entity: DosageInstruction) => !entity.status || entity.status === 'ACTIVE'
      );
      if (instructions) return instructions;
    }

    return [];
  }

  getAllDosageInstruction(): DosageInstruction[] {
    if (this.getAll().length > 0) {
      const instructions = <DosageInstruction[]>this.getAll();
      if (instructions) return instructions;
    }

    return [];
  }
}
