import {
  Consultation,
  ConsultationFollowup,
  createConsultation,
  createConsultationFollowup,
} from './Consultation';
import { createPatientReferral, PatientReferral } from './ReferralData';
import { createPCNDetail, PCNDetail } from './Pcn';
import { createSalesOrder, SalesOrder } from './SalesOrder';
import { Diagnosis } from './Diagnosis';
import { DispatchItem } from './ChargeItem';
import { MedicalCertificate } from './MedicalCertificate';
import { IPackageItem } from '../../components/shared/package/package.model';
import { Address } from '../Address';

export enum VisitStatus {
  INITIAL = 'Initial',
  PRECONSULT = 'Pre-Consult',
  CONSULT = 'Consult',
  PAYMENT = 'Payment',
  POST_CONSULT = 'Post-Consult',
  COMPLETE = 'Complete',
}

export interface Case {
  caseEndDateTime: string;
  caseId: string;
  caseNumber: string;
  caseStartDateTime: string;
  clinicId: string;
  coverages: CaseCoveragePlan[];
  hasOpenVisits: false;
  isPackage: boolean;
  packages: Array<IPackageItem>;
  patientId: string;
  patientName: string;
  patientNRIC: string;
  personIncharge: string;
  primaryDoctorId: string;
  remarks: string;
  billAdjReasonCode: string;
  salesOrder: SalesOrder;
  secondaryDoctorIds: Array<string>;
  singleVisit: boolean;
  status: string;
  vendorChargeItems: Array<VendorChargeItem>;
  visitIds: { [key: string]: string }[];
}

export function createCase(caseItem) {
  let isPackage = false;
  if (caseItem['package']) isPackage = caseItem['package'];

  const {
    caseEndDateTime = '',
    caseId = '',
    caseNumber = '',
    caseStartDateTime = '',
    clinicId = '',
    coverages = new Array<CaseCoveragePlan>(),
    hasOpenVisits = false,
    packages = new Array<IPackageItem>(),
    patientId = '',
    patientName = '',
    patientNRIC = '',
    personIncharge = '',
    primaryDoctorId = '',
    remarks = '',
    billAdjReasonCode = '',
    salesOrder = createSalesOrder({}),
    secondaryDoctorIds = new Array<string>(),
    singleVisit = true,
    status = '',
    vendorChargeItems = new Array<VendorChargeItem>(),
    visitIds = [],
  } = caseItem;

  return {
    caseEndDateTime,
    caseId,
    caseNumber,
    caseStartDateTime,
    clinicId,
    coverages,
    hasOpenVisits,
    isPackage,
    packages,
    patientId,
    patientName,
    patientNRIC,
    personIncharge,
    primaryDoctorId,
    remarks,
    billAdjReasonCode,
    salesOrder,
    secondaryDoctorIds,
    singleVisit,
    status,
    vendorChargeItems,
    visitIds,
  } as Case;
}

export interface PatientVisitEntities {
  remoteDeliveryDetails: RemoteDeliveryDetails;
  visitId: string;
  patientId: string;
  visitNumber: string;
  visitPurpose: string;
  visitCancelled: boolean;
  visitStatus: string;
  onlinePaid: boolean;
  clinicCode: string;
  clinicId: string;
  clinicName: string;

  attachedToCase: boolean;
  diagnosisEntities: Array<Diagnosis>;
  endTime: string;
  medicalReferenceEntity: MedicalReferenceEntity;
  preferredDoctorId: string;
  priority: string;
  remark: string;
  startTime: string;
  videoLink?: string;
  paymentReference?: string;
  caseId?: string
}

export function createPatientVisitEntities(patientVisitEntities) {
  const {
    visitId = '',
    visitNumber = '',
    visitPurpose = '',
    visitStatus = '',
    clinicCode = '',
    clinicId = '',
    clinicName = '',
    attachedToCase = false,
    diagnosisEntities = new Array<Diagnosis>(),
    endTime = '',
    medicalReferenceEntity = createMedicalReferenceEntity({}),
    preferredDoctorId = '',
    priority = '',
    remark = '',
    startTime = '',
    onlinePaid = false,
    videoLink = '',
    paymentReference = '',
  } = patientVisitEntities;

  return {
    visitId,
    visitNumber,
    visitPurpose,
    visitStatus,
    clinicCode,
    clinicId,
    clinicName,
    attachedToCase,
    diagnosisEntities,
    endTime,
    medicalReferenceEntity,
    preferredDoctorId,
    priority,
    remark,
    startTime,
    onlinePaid,
    videoLink,
    paymentReference,
  } as PatientVisitEntities;
}

export interface RemoteDeliveryDetails {
  deliveryLocationId: string;
  deliveryLocationCode?: string;
  deliveryLocationName?: string;
  deliveryDate: string; // JSON date format
  deliveryInstruction?: string;
  deliveryTimeSlot: string;
  deliveryAddress: Address;
}

export interface MedicalReferenceEntity {
  consultation: Consultation;
  consultationFollowup: ConsultationFollowup;
  diagnosisIds: Array<string>;
  dispatchItemEntities: Array<DispatchItem>;
  medicalCertificates: Array<MedicalCertificate>;
  memos: Array<MemoObject>;
  packages: IPackageItem[];
  patientReferral: PatientReferral;
  pcnDetails: PCNDetail;
  planMaxUsage: CaseCoveragePlan[];
  secondaryDiagnosisIds: Array<string>;
  visitTimeChit: VisitTimeChit;
  conditionCodes: Array<string>;
  cdsMilestoneCode: string;
  screeningOutcomeCode: string;
  remoteDeliveryDetails: RemoteDeliveryDetails
}

export function createMedicalReferenceEntity(medicalReferenceEntity) {
  const {
    consultation = createConsultation({}),
    consultationFollowup = createConsultationFollowup({}),
    diagnosisIds = new Array<string>(),
    dispatchItemEntities = new Array<DispatchItem>(),
    medicalCertificates = new Array<MedicalCertificate>(),
    memos = new Array<MemoObject>(),
    patientReferral = createPatientReferral({}),
    pcnDetails = createPCNDetail({}),
    planMaxUsage = new Array<CaseCoveragePlan>(),
    secondaryDiagnosisIds = new Array<string>(),
    visitTimeChit = createVisitTimeChit({}),
    conditionCodes = new Array<string>(),
    cdsMilestoneCode = '',
    screeningOutcomeCode = ''
  } = medicalReferenceEntity;

  return {
    consultation,
    consultationFollowup,
    diagnosisIds,
    dispatchItemEntities,
    medicalCertificates,
    memos,
    patientReferral,
    pcnDetails,
    planMaxUsage,
    secondaryDiagnosisIds,
    visitTimeChit,
    conditionCodes,
    cdsMilestoneCode,
    screeningOutcomeCode,
  } as MedicalReferenceEntity;
}

export interface VisitTimeChit {
  from: string;
  to: string;
}

export function createVisitTimeChit(visitTimeChit) {
  const { from = '', to = '' } = visitTimeChit;
  return { from, to } as VisitTimeChit;
}

export interface CaseCoveragePlan {
  medicalCoverageId: string;
  planId: string;
  name: string;
  remarks: string;
  limit: number;
  updated: boolean;
  isDentalChas?: boolean;
  payerUserId?: {
    idType: string;
    number: string;
  }
}

export function createCaseCoveragePlan(
  medicalCoverageId = '',
  planId = '',
  name = '',
  remarks = '',
  limit = -1,
  updated = false,
  payerUserId?,
  isDentalChas = false
) {
  return {
    medicalCoverageId,
    planId,
    name,
    remarks,
    limit,
    updated,
    isDentalChas,
    payerUserId
  } as CaseCoveragePlan;
}

export interface VendorChargeItem {
  batchNo: string;
  cost: {
    price: number;
    taxIncluded: boolean;
  };
  dispenseQty: number;
  dosage: number;
  duration: number;
  excludedCoveragePlanIds: Array<string>;
  expireDate: string;
  instruct: string;
  inventoried: boolean;
  itemCode: string;
  itemDiscount: number;
  itemPriceAdjustment: {
    adjustedValue: number;
    paymentType: string;
    remark: string;
  };
  itemRefId: string;
  itemType: string;
  purchaseQty: number;
  purchaseUom: string;
  remarks: string;
  salesItemId: string;
  soldPrice: number;
  sellingPrice: {
    price: number;
    taxIncluded: boolean;
  };
}

export function createVendorChargeItem(vendorChargeItem) {
  const {
    batchNo = '',
    cost = { price: 0, taxIncluded: false },
    dispenseQty = 0,
    dosage = 0.0,
    duration = 0,
    excludedCoveragePlanIds = new Array<string>(),
    expireDate = '',
    instruct = '',
    inventoried = false,
    itemCode = '',
    itemDiscount = 0,
    itemPriceAdjustment = { adjustedValue: 0, paymentType: '', remark: '' },
    itemRefId = '',
    itemType = '',
    purchaseQty = 0,
    purchaseUom = '',
    remarks = '',
    salesItemId = '',
    soldPrice = 0,
    sellingPrice = { price: 0, taxIncluded: false },
  } = vendorChargeItem;

  return {
    batchNo,
    cost,
    dispenseQty,
    dosage,
    duration,
    excludedCoveragePlanIds,
    expireDate,
    instruct,
    inventoried,
    itemCode,
    itemDiscount,
    itemPriceAdjustment,
    itemRefId,
    itemType,
    purchaseQty,
    purchaseUom,
    remarks,
    salesItemId,
    soldPrice,
    sellingPrice,
  } as VendorChargeItem;
}

export interface MemoObject {
  memoId: string;
  memo: string;
}

export function createMemoObject({ memo = '', memoId = '' } = {}) {
  return {
    memoId,
    memo,
  } as MemoObject;
}
