import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayLockOptions',
})
export class DisplayLockOptionsPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    switch (value) {
      case 'OPEN':
        return 'Open';
      case 'LOCK_FOR_CA':
        return 'Lock For CA';
      case 'LOCK_FOR_ALL':
        return 'Lock For All';
      case 'LOCK_EXCLUDING_CLINIC':
        return 'Lock excluding clinic';
    }
    return null;
  }
}
