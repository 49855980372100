import { AppointmentsFactoryService } from './../../../../services/appointments-factory.service';
import { AppointmentsPresenterService } from './../../../../services/appointments-presenter.service';
import { AppointmentMasterService } from './../../appointment-master/appointment-master.service';
import { AppointmentMasterQuery } from './../../appointment-master/appointment-master.query';
import { AlertService } from '../../../../services/alert.service';
import { ApiAppointmentsService } from '../../../../services/api-appointments.service';
import { Doctor } from '../../../../objects/SpecialityByClinic';
import { SelectItemOptions } from '../../../../objects/SelectItemOptions';
import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';
import { StoreService } from '../../../../services/store.service';
import { DoctorCalendarStore } from './doctor-calendar.store';
import { Injectable } from '@angular/core';
import { DoctorCalendar } from '../../../../objects/DoctorCalendar';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DoctorCalendarService {
  constructor(
    private store: StoreService,
    private doctorCalendarStore: DoctorCalendarStore,
    private apiCmsManagementService: ApiCmsManagementService,
    private appointmentMasterService: AppointmentMasterService,
    private appointmentMasterQuery: AppointmentMasterQuery,
    private apiAppointmentsService: ApiAppointmentsService,
    private apptFactory: AppointmentsFactoryService,
    private appointmentPresenter: AppointmentsPresenterService,
    private alertService: AlertService
  ) {}

  set(calendar: DoctorCalendar[]) {
    this.doctorCalendarStore.set(calendar);
  }

  add(cal: DoctorCalendar) {
    this.doctorCalendarStore.add(cal);
  }

  update(cal: DoctorCalendar) {
    this.doctorCalendarStore.update(cal);
  }

  upsert(id, cal: DoctorCalendar) {
    this.doctorCalendarStore.upsert(id, cal);
  }

  remove(id?) {
    if (id) {
      this.doctorCalendarStore.remove(id);
    } else {
      this.doctorCalendarStore.remove();
    }
  }

  getDoctorCalendarById(id) {
    this.apiAppointmentsService
      .findCalendarByDoctorId(
        this.appointmentMasterQuery.getClinicSelected(),
        id
      )
      .subscribe(value => {
      
        let cal: DoctorCalendar = this.apptFactory.createDoctorCalendar(
          <DoctorCalendar>value.payload[0]
        );
      
        this.upsert(id, cal);
      });
  }

  getAllsDoctorCalendars() {
    let list = this.store.doctorListByClinic;

    this.apiCmsManagementService
      .listDoctorsByClinic(this.appointmentMasterQuery.getClinicSelected())
      .subscribe(
        res => {
          if (res.payload) {
            list = (<Array<Doctor>>res.payload)
              .sort(a => {
                if (a.doctorGroup === 'ANCHOR') {
                  return -1;
                }
              })
              .map(item => {
                const options = new SelectItemOptions<Doctor>();
                options.value = item.id;
                options.label = item.displayName;
                options.data = item;
                return options;
              });

            this.appointmentMasterService.setClinicSelectedDoctorList(list);

            const ids = res.payload.map(doc => doc.id);

            this.apiAppointmentsService
              .findCalendarByDoctorList(
                this.appointmentMasterQuery.getClinicSelected(),
                ids
              )
              .subscribe(
                res => {
                  let { payload } = res;
                  let array: DoctorCalendar[] = payload.map(calendar =>
                    this.apptFactory.createDoctorCalendar(
                      <DoctorCalendar>calendar
                    )
                  );
                  this.set(array);
                  this.appointmentMasterService.setStoreReady();
                },
                err => {
                  this.alertService.error(JSON.stringify(err.error.message));
                }
              );
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err));
        }
      );
  }
}
