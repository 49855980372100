import { HttpResponseBody } from './../../../../objects/response/HttpResponseBody';
import { NgxPermissionsService } from 'ngx-permissions';
import { AlertService } from './../../../../services/alert.service';
import { Subject, throwError, of } from 'rxjs';
import { StoreService } from './../../../../services/store.service';
import { SelectItemOptions } from './../../../../objects/SelectItemOptions';
import { Diagnosis } from './../../../../objects/response/Diagnosis';
import { ApiCmsManagementService } from './../../../../services/api-cms-management.service';
import { ConsultationFormService } from './../../../../services/consultation-form.service';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {

  distinctUntilChanged,
  debounceTime,
  switchMap,
  tap,
  takeUntil,
  filter,
  retryWhen,
  delay,
  catchError,
} from 'rxjs/operators';
import { PcnFormService } from '../../../pcn/pcn-form.service';
import { AkitaAppQuery } from '../../../../services/states/akita-app.query';
@Component({
  selector: 'app-consultation-diagnosis-item',
  templateUrl: './consultation-diagnosis-item.component.html',
  styleUrls: ['./consultation-diagnosis-item.component.scss'],
})
export class ConsultationDiagnosisItemComponent implements OnInit, OnDestroy {
  @Input() itemGroup: FormGroup;
  @Input() checkedItemIndexes;
  @Input() attachedMedicalCoverages: FormArray;
  @Input() index: number;
  @Input() isEdit: boolean;
  @Input() secondaryDiagnosis: boolean = false;
  @Output() onDelete = new EventEmitter<number>();
  @Output() checkedIcd = new EventEmitter<string>();
  @Input() drugItemIndex;
  @Input() isCaseManagerFlow;
  isGlobalSearch: boolean = false;

  attachedPlanId: Array<string> = new Array();

  searchKey = new FormControl();
  codes: Array<SelectItemOptions<Diagnosis>>;
  snomedCodes: any[] = [];
  searchTerm: string = '';
  codeFilterFC = new FormControl('All');
  diagnosisItems: Diagnosis[] = [];
  diagnosisId;
  description: string;
  loading = false;
  private icdType: string;
  codeFilter = ['All', 'Clinic'];
  checkedIcdType: any
  codesTypeahead = new Subject<string>();
  snomedListTypeahead = new Subject<string>();

  private componentDestroyed: Subject<void> = new Subject();
  drugTypeSelect: string;
  isCdmClinic: boolean = false;

  constructor(
    private apiCmsManagementService: ApiCmsManagementService,
    private consultationFormService: ConsultationFormService,
    private alertService: AlertService,
    private permissionsService: NgxPermissionsService,
    private store: StoreService,
    private pcnFormService: PcnFormService,
    private akitaAppQuery: AkitaAppQuery,
  ) {}

  ngOnInit() {
    if (
      this.itemGroup.get('id').value !== '' &&
      this.itemGroup.get('icd10Term') &&
      this.itemGroup.get('icd10Term').value
    ) {
      this.diagnosisId = this.itemGroup.get('id').value;
      this.codes = [this.itemGroup.getRawValue()];
      this.onDiagnosisSelected(this.itemGroup.getRawValue(), false);
    } else if (this.itemGroup.get('id').value) {
      const group = this.itemGroup.getRawValue();
      this.diagnosisId = group.id;
      this.getDiagnosisDetail(this.itemGroup.get('id').value);
    } else {
      this.itemGroup.get('id').markAsTouched();
    }

    if (!this.secondaryDiagnosis) {
      this.attachedPlanId =
        this.attachedMedicalCoverages &&
        this.attachedMedicalCoverages.value.map(coverage => coverage.planId);
      this.attachedMedicalCoverages.valueChanges
        .pipe(takeUntil(this.componentDestroyed))
        .subscribe(coverages => {
          this.attachedPlanId = coverages.map(coverage => coverage.planId);
        });
      if (this.attachedPlanId && this.attachedPlanId.length) {
        this.searchAllDiagnosis().subscribe(res=> {
          this.codes = res.payload;
        })
      }
    }
    this.store.drugTypeSelect.subscribe(icdCodes => this.drugTypeSelect = icdCodes);
    this.onFilterInputChanged();
    this.onSnomedListChanged();

    if (!this.isEdit && !this.isCaseManagerFlow) {
      this.itemGroup.disable();
      this.itemGroup.get('id').disable();
    }

    this.isCdmClinic = this.pcnFormService.isCdmClinic;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  getDiagnosisDetail(id: string) {
    if (!id) {
      return;
    }
    this.apiCmsManagementService.searchDiagnosisByIds([id]).subscribe(
      res => {
        const diagnosis = res.payload;
        const item = diagnosis.find(item => item.id === id);
        if (item) {
          this.itemGroup.patchValue(item, { emitEvent: false });
          this.codes = [item];
          this.onDiagnosisSelected(item);
        }
      },
      err => {
        this.alertService.error(JSON.stringify(err.error.message));
      }
    );
  }

  searchDiagnosisGlobal(term) {
    return this.apiCmsManagementService
      .searchDiagnosis(encodeURI(term), this.attachedPlanId, true)
      .pipe(
        catchError(error => of(<HttpResponseBody>{ page: {}, payload: '' }))
      );
  }

  searchSnomedTermGlobal(term) {
    return this.apiCmsManagementService
      .searchDiagnosis(encodeURI(term), [], true)
      .pipe(
        catchError(error => of(<HttpResponseBody>{ page: {}, payload: '' }))
      );
  }

  searchDiagnosisClinic(term) {
    return this.apiCmsManagementService
      .searchDiagnosis(
        encodeURI(term),
        this.attachedPlanId,
        false,
        this.store.getClinicId()
      )
      .pipe(
        catchError(error => of(<HttpResponseBody>{ page: {}, payload: '' }))
      );
  }

  searchAllDiagnosis() {
    return this.apiCmsManagementService
      .searchAllDiagnosis(
        this.attachedPlanId,
        false,
        this.store.getClinicId()
      )
      .pipe(
        catchError(error => of(<HttpResponseBody>{ page: {}, payload: '' }))
      );
  }

  onFilterInputChanged() {
    try {
      this.codesTypeahead
        .pipe(
          filter(
            input =>
              input !== undefined && input !== null && input.trim().length > 0
          ),
          debounceTime(400),
          tap((term: string) => {
            this.loading = true;
            this.searchTerm = term;
          }),
          switchMap((term: string) => {
            if (this.isGlobalSearch) {
              return this.searchDiagnosisGlobal(term);
            } else {
              return this.searchDiagnosisClinic(term);
            }
          }),
          catchError(errors => of(<HttpResponseBody>{ page: {}, payload: '' }))
        )
        .subscribe(
          data => {
            this.loading = false;
            if (data) {
              const codes = data.payload.filter(item=>{
                return !item.snomedCode;
              });
              this.codes = [...codes];
            }
          },
          err => {
            this.loading = false;
            throwError('Error');
            if (err && err.error && err.error.message) {
              this.alertService.error(JSON.stringify(err.error.message));
            }
          }
        );
    } catch (err) {
      console.error('Search Diagnosis Error', err);
    }
  }


  onSnomedListChanged() {
    try {
      this.snomedListTypeahead
        .pipe(
          filter(
            input =>
              input !== undefined && input !== null && input.trim().length > 2
          ),
          debounceTime(400),
          tap((term: string) => {
            this.loading = true;
            this.searchTerm = term;
          }),
          switchMap((term: string) => {
            this.isGlobalSearch = true;
            return this.searchSnomedTermGlobal(term);
          }),
          catchError(errors => of(<HttpResponseBody>{ page: {}, payload: '' }))
        )
        .subscribe(
          data => {
            this.loading = false;
            if (data) {
              const snomedList = data.payload.filter(item=>{
                return item.snomedCode;
              });
              this.snomedCodes = [];
              snomedList.forEach(element => {
                this.snomedCodes.push({conceptId: element.snomedCode, term: element.snomedTerm, defaultSnomed: false})
              });             
            }
          },
          err => {
            this.loading = false;
            throwError('Error');
            if (err && err.error && err.error.message) {
              this.alertService.error(JSON.stringify(err.error.message));
            }
          }
        );
    } catch (err) {
      console.error('Search Diagnosis Error', err);
    }
  }

  onDiagnosisSelected(option, resetInvalidUsage = true, setDefault = false) {
    if (option) {
      this.addNewOption(option);
    }
    if(option!=undefined && option.filterablePlanIds!=undefined){
      this.alertService.sendAddDiagnosisEvent();
    }
    if (resetInvalidUsage && this.itemGroup.get('invalidUsage')) {

      this.itemGroup
        .get('invalidUsage')
        .patchValue(false, {emitEvent: false});
    }
    if (option) {
      this.description = option.icd10Term;
      this.itemGroup.patchValue(option);
      this.searchKey.patchValue(option.icd10Code);
      this.icdType = option.icdType;
      this.snomedCodes = option.snomedList || [];
      const snomedTerm = option.snomedTerm;
      const snomedId = option.snomedId;
      if ((this.snomedCodes && this.snomedCodes.length > 0 && snomedId) || snomedTerm || (snomedId && snomedId.conceptId)) {
        this.itemGroup.get('snomedId').patchValue(setDefault ? this.snomedCodes[0] : {conceptId: option.snomedId.conceptId || option.snomedId, term: option.snomedId.term || option.snomedTerm});
      // } else if (snomedTerm || (snomedId && snomedId.conceptId)) {
      //   this.itemGroup.get('snomedId').patchValue({conceptId: option.snomedId.conceptId || option.snomedId, term: option.snomedId.term || option.snomedTerm});
      } else if ((this.store.getVisitStatus() === 'POST_CONSULT' || this.store.getVisitStatus() === 'PAYMENT') && this.itemGroup.get('snomedId')?.value?.term) {
        this.itemGroup.get('snomedId').patchValue(option.snomedId);
      } else {
        if (!this.itemGroup.get('snomedId')) {
          this.itemGroup.addControl('snomedId', new FormControl(''));
        }
        this.itemGroup.get('snomedId').patchValue({term: ''});
      }
      if(!this.itemGroup.get('snomedId')?.value?.term && this.isNEHROpt() && !this.secondaryDiagnosis){
        this.itemGroup.get('snomedId').setValue(null)
        this.itemGroup.get('snomedId').setValidators(Validators.required);
      }
      if (!setDefault && this.itemGroup.get('snomedId')?.value) {
        let selectedSnomed = this.snomedCodes.find(snomed => snomed.conceptId === this.itemGroup.get('snomedId')?.value?.conceptId);
        if (!selectedSnomed) {
          this.snomedCodes.push(this.itemGroup.get('snomedId')?.value);
        }
      }
      let snomedListFA = this.itemGroup.get('snomedList') as FormArray;
      if (snomedListFA && snomedListFA.value.length){
        snomedListFA.clear();
        this.snomedCodes.forEach(snomed => {
          snomedListFA.push(new FormControl({conceptId: snomed.conceptId, term: snomed.term}));
        })
      }
      
    } else {
      this.description = '';
      this.onFilterInputChanged();
    }
    
    this.itemGroup.get('snomedId').updateValueAndValidity({ emitEvent: false });
    this.itemGroup.get('snomedId').markAsTouched();
    this.itemGroup.get('snomedList')?.updateValueAndValidity({ emitEvent: false });
    this.itemGroup.get('snomedList')?.markAsTouched();
  }

  onSnomedSelected(option) {

  }

  addNewOption(event) {
    let itm = this.checkedItemIndexes;
    if (itm) {
      if (event && event.icdType) {
        itm.push(event.icdType.toUpperCase());
        this.checkedIcdType = itm;
      }
      if (this.checkedIcdType && this.checkedIcdType.length !== 0 && !(this.checkedItemIndexes.find(element => element === 'ACUTE') && this.checkedItemIndexes.find(element => element === 'CHRONIC'))) {
        for (var item of this.drugItemIndex) {
          if (event.icdType.toUpperCase() !== item.drugType.toUpperCase()) {
            this.consultationFormService.showIcdTypeWarningMessage();
          }
        }
      }
    }
  }

  loadMoreDiagnosis() {
    this.isGlobalSearch = true;
    this.codesTypeahead.next(this.searchTerm);
  }

  onBtnDeleteClicked() {
    this.onClear();
  }

  onClear() {
    this.itemGroup.get('id').markAsTouched();
    this.description = '';
    this.onDelete.emit(this.index);
    if (!this.secondaryDiagnosis) {
      this.checkedItemIndexes.splice(this.index, 1);
    }
    if (this.checkedIcdType && this.checkedIcdType.length !== 0 && !(this.checkedItemIndexes.find(element => element === 'ACUTE') && this.checkedItemIndexes.find(element => element === 'CHRONIC'))) {
      for (var item of this.drugItemIndex) {
        if (!this.checkedIcdType.find(element => element === item.drugType.toUpperCase())) {
          this.consultationFormService.showIcdTypeWarningMessage();
        }
      }
    }
  }

  onSnomedClear() {
    if(this.isNEHROpt() && !this.secondaryDiagnosis && this.itemGroup.get('id').value){
      this.itemGroup.get('snomedId').setValidators(Validators.required);
    }
    this.itemGroup.get('snomedId').updateValueAndValidity({ emitEvent: false });
    this.itemGroup.get('snomedId').markAsTouched();
  }

  isNEHROpt() {
    return this.akitaAppQuery.checkClinicFeatureExist('NEHR_SUBMISSION');
  }
}
