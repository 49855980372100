import * as moment from 'moment';

export enum API_STATUS {
  FAILURE = 'FAILURE',
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
}

export interface ApiState {
  apiStatus: API_STATUS;
  lastUpdated: string;
  name: string;
}

export function createApiState(name, apiStatus) {
  return {
    apiStatus: apiStatus,
    lastUpdated: moment().format(),
    name,
  } as ApiState;
}
