import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
  BsDatepickerViewMode,
} from 'ngx-bootstrap/datepicker';

import DatePickerConfig from '../../objects/DatePickerConfig';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-date-picker-with-icon',
  templateUrl: './date-picker-with-icon.component.html',
  styleUrls: ['./date-picker-with-icon.component.scss'],
})
export class DatePickerWithIconComponent implements OnInit, OnDestroy {
  @Input() dateControl: FormControl;
  @Input() config: DatePickerConfig;
  @Input() disabled: boolean;
  @Input() hideIcon = false;
  @Input() customStyles: boolean = false;
  @Input() datepickerViewMode: BsDatepickerViewMode = 'day';
  @Input() readOnly: boolean;

  @Input()
  private index: number = 0;

  @Output()
  public changedValue: EventEmitter<{ index: number; value: string }> | undefined = new EventEmitter<{ index: number; value: string }>();
  private changedValueSub: Subscription;
  bsConfig: Partial<BsDatepickerConfig>;
  constructor() { }

  ngOnInit() {
    if (this.disabled) {
      this.dateControl.disable();
    }

    this.bsConfig = {
      dateInputFormat: 'DD-MM-YYYY',
      containerClass: 'theme-blue',
      // minDate: this.config.minDate ? this.config.minDate : undefined,
      // maxDate: this.config.maxDate ? this.config.maxDate : undefined
    };

    if (this.config.minDate) {
      this.bsConfig.minDate = this.config.minDate;
    }

    if (this.config.maxDate) {
      this.bsConfig.maxDate = this.config.maxDate;
    }

    if (this.config.dateInputFormat) {
      this.bsConfig.dateInputFormat = this.config.dateInputFormat;
    }

    this.onValueChange();
  }

  bsDatePickerConfig(): Partial<BsDatepickerConfig> {
    const bsConfig: Partial<BsDatepickerConfig> = {
      dateInputFormat: 'DD-MM-YYYY',
      containerClass: 'theme-blue',
      minMode: this.datepickerViewMode
    };

    if (this.config.minDate) bsConfig.minDate = this.config.minDate;
    if (this.config.maxDate) bsConfig.maxDate = this.config.maxDate;
    if (this.config.dateInputFormat) bsConfig.dateInputFormat = this.config.dateInputFormat;
    if (this.config.datesDisabled) bsConfig.datesDisabled = this.config.datesDisabled

    return bsConfig;
  }

  private onValueChange(): void {
    this.changedValueSub = this.dateControl.valueChanges.subscribe({
      next: result => this.changedValue.emit({
        index: this.index,
        value: result
      })
    });
  }

  ngOnDestroy(): void {
    this.changedValueSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.disabled) {
      this.dateControl.disable();
    } else {
      this.dateControl.enable();
    }
  }
}