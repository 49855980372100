import { Injectable } from '@angular/core';

import { map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { AkitaCdlensMappingStore } from './akita-cdlens-mapping.store';

@Injectable({ providedIn: 'root' })
export class AkitaCdlensMappingQuery extends QueryEntity<any> {
  cdlensMappingList$ = this.selectAll();

  constructor(protected store: AkitaCdlensMappingStore) {
    super(store);
  }
}
