import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { PatientDetailTagAddAlertComponent } from './../../../patient-detail/patient-detail-tag/patient-detail-tag-add-alert/patient-detail-tag-add-alert.component';
import { PatientAddAlertsInfoAddClinicalComponent } from './../../../patient-add/patient-add-alerts-info/patient-add-alerts-info-add-clinical/patient-add-alerts-info-add-clinical.component';
import { PatientDetailAllergyAlertComponent } from '../../../patient-detail/patient-detail-allergy-alert/patient-detail-allergy-alert.component';

@Component({
  selector: 'app-consultation-patient-alert-info',
  templateUrl: './consultation-patient-alert-info.component.html',
  styleUrls: ['./consultation-patient-alert-info.component.scss'],
})
export class ConsultationPatientAlertInfoComponent implements OnInit, OnDestroy {

  @Input() alertFormGroup: FormGroup;
  @Input() medicalAlertFormGroup: FormGroup;

  constructor(
    private modalService: BsModalService,
  ) { }

  bsModalRef: BsModalRef;

  // OPTIONS
  allergyTypes = [];
  allergyNames = [];
  medicalAlertTypes = [];
  medicalAlertPriorities = [];

  private componentDestroyed: Subject<void> = new Subject();
  //Arrows
  isAllergyCollapsed = true;
  isClinicAlertCollapsed = true;
  private scrollContainer: any;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.toggleAlerts();
    this.toggleAllergy();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  onAddMedicalAlertAndAllergy() {
    const initialState = {
      title: 'Allergy Information',
      alertFormGroup: this.alertFormGroup,
      medicalAlertFormGroup: this.medicalAlertFormGroup,
    };
    this.bsModalRef = this.modalService.show(PatientDetailAllergyAlertComponent, {
      initialState,
      class: 'modal-x-lg custom-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  onAddAlert() {
    const initialState = {
      title: 'Allergy Information',
      alertFormGroup: this.alertFormGroup,
    };

    this.bsModalRef = this.modalService.show(PatientDetailTagAddAlertComponent, {
      initialState,
      class: 'modal-xl',
      keyboard: false,
      backdrop: 'static',
    });

    this.bsModalRef.content.event.subscribe(data => {
      const allergiesDiv = document.getElementById('allergies');
      const originalHeight = allergiesDiv.offsetHeight;
      this.adjustHeightUponToggle(originalHeight);

      this.bsModalRef.content.event.unsubscribe();
      this.bsModalRef.hide();
    });
  }

  onAddMedicalAlert() {
    const initialState = {
      title: 'Clinical Alert Information',
      medicalAlertFormGroup: this.medicalAlertFormGroup,
    };

    this.bsModalRef = this.modalService.show(PatientAddAlertsInfoAddClinicalComponent, {
      initialState,
      class: 'modal-xl',
      keyboard: false,
      backdrop: 'static',
    });

    if (this.bsModalRef.content.event) {
      this.bsModalRef.content.event.subscribe(data => {
        const medicalAlertsDiv = document.getElementById('medicalAlerts');
        const originalHeight = medicalAlertsDiv.offsetHeight;
        this.adjustHeightUponToggle(originalHeight);

        this.bsModalRef.content.event.unsubscribe();
        this.bsModalRef.hide();
      });
    }
  }

  toggleAllergy() {
    this.isAllergyCollapsed = !this.isAllergyCollapsed;
    const allergiesDiv = document.getElementById('allergies');
    const originalHeight = allergiesDiv.offsetHeight;

    setTimeout(() => {
      const offsetHeight = allergiesDiv.offsetHeight - originalHeight;
      this.adjustHeightUponToggle(offsetHeight);
    });
  }

  toggleAlerts() {
    this.isClinicAlertCollapsed = !this.isClinicAlertCollapsed;
    const medicalAlertsDiv = document.getElementById('medicalAlerts');
    const originalHeight = medicalAlertsDiv.offsetHeight;

    setTimeout(() => {
      const offsetHeight = medicalAlertsDiv.offsetHeight - originalHeight;

      this.adjustHeightUponToggle(offsetHeight);
    });
  }

  addBottomPaddingTo(offsetHeight, element: HTMLElement) {
    let paddingBottom = Number(this.extractNumber(element.style.paddingBottom));
    let newPaddingBottom = 0;

    if (offsetHeight !== 0) {
      newPaddingBottom = paddingBottom + offsetHeight;
    }

    element.style.setProperty(
      'padding-bottom',
      newPaddingBottom + 'px',
      'important'
    );
  }

  adjustHeightUponToggle(offsetHeight) {
    const chargeItemListPane = document.getElementById('chargeItemsList');
    const element = document.getElementsByClassName('tab-pane');

    for (let i = 0; i < element.length; i++) {
      if (<HTMLElement>element[i]) {
        this.addBottomPaddingTo(offsetHeight, <HTMLElement>element[i]);
      }
    }

    if (chargeItemListPane) {
      this.addBottomPaddingTo(offsetHeight, <HTMLElement>chargeItemListPane);
    }
  }

  extractNumber(string: String) {
    return string.split('px')[0] || '';
  }
}