<header class="app-header navbar">
    <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
        <span class="navbar-toggler-icon"></span>
    </button>
    <a
     *ngIf="logo" 
     class="navbar-brand bg-brand-primary navbar-logo" 
     href="/"
     [style.background-image]="'url(' + logo + ')'">
    </a>

    <a *ngIf="!logo" class="navbar-brand bg-brand-primary navbar-logo" href="/"></a>
    
    <h4 *ngIf="!showPatientDetails" class="pl-4 mb-0">{{ headerTitle }}</h4>
    
    <app-consultation-patient-info *ngIf="showPatientDetails"></app-consultation-patient-info>

    <div class="d-flex justify-content-end align-items-center ml-auto">
        <div class="mr-4">
            <i 
                class="fa fa-info-circle fa-lg hover" 
                aria-hidden="true" 
                (click)="navHelpPage()"
                [popover]=" 'Help & Support' " 
                [adaptivePosition]="false"
                triggers="mouseenter:mouseleave">
            </i>
        </div>

        <div class="mr-4" *ngxPermissionsOnly="['ROLE_NOTIFICATION_MANAGEMENT']">
            <i 
                class="icon-bell i-dot" 
                (click)="getNotificationList(true)" 
                [popover]="notificationTemplate"
                placement="bottom" 
                [outsideClick]="true" 
                containerClass="notification-popover" 
                #pop="bs-popover">
                <span class="dot" *ngIf="store.getUnreadNotificationList().length"></span>
            </i>
            <ng-template #notificationTemplate>
                <div class="p-2">
                    <div class="row pt-2">
                        <div class="col-md-6">
                            <strong>Notifications</strong>
                        </div>
                        <div class="col-md-6">
                            <strong class="cursor-pointer" (click)="markAllAsRead()">Mark All As Read</strong>
                        </div>
                    </div>
                    <div *ngFor="let notification of notifications; let i = index;"
                        [ngClass]="{'bg-grey font-weight-bold cursor-pointer': !notification.read}">

                        <hr class="mt-0">
                        <div class="row pb-2" (click)="markAsRead(notification)">
                            <div class="col-md-9">
                                {{notification.message}}
                            </div>
                            <div class="col-md-3 text-right text-xx-small">
                                <div>{{notification.fromNow}}</div>
                            </div>

                            <div *ngIf="notification.type === 'REQUESTING_NEXT_AVAILABLE_DOCTOR'" class="row p-1">
                                <div class="col-6">
                                    <div class="btn btn-sm btn-brand-positive"
                                        (click)="[pop.hide(), onClickAccept(notification)]">Accept</div>
                                </div>
                                <div class="col-6">
                                    <div class="btn btn-sm btn-brand-danger text-white"
                                        (click)="[pop.hide(), onClickDecline(notification)]">Decline</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>

        <span 
            class="profile mr-4" 
            [ngClass]="getOnlineOfflineClass()" 
            (click)="onBtnProfile()"
            [popover]="profileTemplate" 
            placement="bottom" 
            [outsideClick]="true"
            containerClass="profile-popover logout-popover">
            {{store.getUserLabel()}}
        </span>
        <ng-template #profileTemplate>
            <div class="pl-2">
                <div class="row pt-2 ">
                    <div class="col-md-6">
                        <span>{{username}}</span>
                    </div>
                    <ng-container *ngIf="isDoctorOnlineOfflineFeature()">
                        <div class="col-md-6" *ngxPermissionsOnly="['ROLE_DOCTOR']">
                            <div class="d-flex align-items-center justify-content-end">
                                <span class="mr-2" [ngClass]="{'text-success': isDoctorOnline }">({{isDoctorOnline ?
                                    'Online' : 'Offline'}})
                                </span>

                                <label class="switch">
                                      <input type="checkbox" (click)="updateOnlineStatus($event.target.checked)"
                                        [ngModel]="isDoctorOnline" id="onlineSlider">
                                      <span class="slider round"></span>
                                </label>

                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="row pt-1">
                    <div class="col-md-12">
                        <span>{{doctorGroup}} | {{speciality}}</span>
                    </div>
                </div>
                <div class="row pt-1">
                    <div class="col-md-12">
                        <span class="icon-location"></span>{{ formatToTitleCase(clinic.clinicCode)|uppercase }}
                    </div>
                </div>
                <div class="row pt-2 pb-2">
                    <div class="col-md-12">
                        <div class="btn-toolbar" role="toolbar">
                            <div class="btn-group" role="group">
                                <button class="btn btn-brand-primary p-1 mr-2" (click)="onBtnChangePassword()">
                                    Change Password
                                </button>
                            </div>
                            <div class="btn-group" role="group">
                                <button class="btn btn-brand-primary p-1" (click)="onBtnLogout()">
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</header>