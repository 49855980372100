import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment'

import { DISPLAY_DATE_FORMAT } from '../constants/app.constants';

@Pipe({
  name: 'displayMedicalCert',
})
export class DisplayMedicalCertPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let string = '';
    value.forEach(mc => {
      const adjustedEndDate = mc.numberOfDays - 1 > 0 ? mc.numberOfDays - 1 : 0;
      const endDate = moment(mc.startDate, DISPLAY_DATE_FORMAT)
        .add(adjustedEndDate, 'days')
        .format(DISPLAY_DATE_FORMAT);

      const mcString = `${mc.purpose} (${mc.numberOfDays} DAYS)\n${
        mc.startDate
      } - ${endDate}\n`;
      string = string + mcString;
    });

    return string;
  }
}
