<div class="card-header mt-2 border-bottom-0" *ngIf="!readOnly">
    <form novalidate [formGroup]="primaryCareNetworkForm">
        <div class="row p-1"><label>Primary Care Network(PCN) Enrolment</label></div>
        <div class="row p-1 justify-content-start h-75">
            <div>
                <label class="checkmark-container">
          <input name="pcnCheck" type="checkbox" formControlName="optIn">
          <span class="checkmark"></span>
        </label>
            </div>
            <div class="font-weight-normal align-self-center">
                <span>Enrol Patient</span>
            </div>
            <div class="pl-2 mb-0 col">
                <app-date-picker-with-icon [config]="datePickerConfig" [dateControl]="primaryCareNetworkForm.get('optInDate')"></app-date-picker-with-icon>
            </div>

            <div class="pl-4">
                <label class="checkmark-container">
          <input type="checkbox" formControlName="optOut">
          <span class="checkmark"></span>
        </label>
            </div>
            <div class="font-weight-normal align-self-center">
                <span>Opt-Out from PCN</span>
            </div>
        </div>


        <!-- <pre>{{primaryCareNetworkForm.get('optInDate').value}}</pre> -->

    </form>

</div>
<div class="bg-duck-egg-blue container" *ngIf="readOnly && this.primaryCareNetworkForm && this.primaryCareNetworkForm.value.optIn && this.primaryCareNetworkForm.value.optInDate && !this.primaryCareNetworkForm.value.optOut">
    <div class="row">
        <label class="mb-0 font-weight-semi-bold">PCN</label>
    </div>

    <div class="row">
        <label class="p-0 m-0 patient-info-label__description">Enrolled on {{this.primaryCareNetworkForm.value.optInDate}}</label>
    </div>
</div>