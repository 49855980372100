export class StockItem {
  inventoryId: string;
  itemRefId: string;
  itemName: string;
  itemCode: string;
  batchNumber: string;
  baseUom: string;
  expiryDate: string;
  manufacturerDate: string;
  availableCount: number;
  baseUomQuantity: number;
  purchasePrice:any;
  adapt(obj?): StockItem {
    if (!obj) {
      return this;
    }

    this.inventoryId = obj.inventoryId;
    this.itemRefId = obj.itemRefId;
    this.itemName = obj.itemName;
    this.itemCode = obj.itemCode;
    this.batchNumber = obj.batchNumber;
    this.baseUom = obj.baseUom;
    this.expiryDate = obj.expiryDate;
    this.manufacturerDate = obj.manufacturerDate;
    this.availableCount = obj.availableCount;
    this.baseUomQuantity = obj.baseUomQuantity;
    this.purchasePrice = obj.purchasePrice;
    return this;
  }
}
