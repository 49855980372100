export const DOC_COLORS = [
    "#800080",
    "#FF0000",
    "#BC8F8F",
    "#4169E1",
    "#8B4513",
    "#FA8072",
    "#F4A460",
    "#2E8B57",
    "#A0522D",
    "#87CEEB",
    "#6A5ACD",
    "#00FF7F",
    "#4682B4",
    "#008080",
    "#FF6347",
    "#40E0D0",
    "#EE82EE",
    "#20B2AA",
    "#87CEFA",
    "#778899",
    "#0000FF",
    "#8A2BE2",
    "#00FF00",
    "#32CD32",
    "#FF00FF",
    "#800000",
    "#66CDAA",
    "#0000CD",
    "#BA55D3",
    "#9370DB",
    "#3CB371",
    "#7B68EE",
    "#00FA9A",
    "#48D1CC",
    "#C71585",
    "#191970",
    "#FFFF00",
    "#9ACD32",
    "#98FB98",
    "#000080",
    "#6B8E23",
    "#FFA500",
    "#FF4500",
    "#D2691E",
    "#FF7F50",
    "#6495ED",
    "#DC143C",
    "#00FFFF",
    "#00008B",
    "#008B8B",
    "#B8860B",
    "#006400",
    "#8B008B",
    "#556B2F",
    "#FF8C00",
    "#9932CC",
    "#8B0000",
    "#483D8B",
    "#2F4F4F",
    "#00DED1",
    "#9400D3",
    "#FF1493",
    "#00BFFF",
    "#1E90FF",
    "#B22222",
    "#228B22",
    "#FF00FF",
    "#FFD700",
    "#DAA520",
    "#008000",
    "#ADFF2F",
    "#FF69B4",
    "#CD5C5C",
    "#4B0082",
    "#A52A2A",
    "#5F9EA0",
    "#7FFF00",
    "#7CFC00",
    "#F08080",
    "#90EE90",
    "#DB7093",
    "#CD853F",
]