import { PatientMedicalCoverageStore } from './../../../../../services/states/akita-patient/patient-medical-coverages/patient-medical-coverage.store';
// Libraries
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

// Objects
import { createPatientCoverage } from '../../../../../objects/MedicalCoverage';

// Services
import { AlertService } from './../../../../../services/alert.service';
import { ApiPatientInfoService } from './../../../../../services/api-patient-info.service';
import { PatientMedicalCoverageQuery } from './../../../../../services/states/akita-patient/patient-medical-coverages/patient-medical-coverage.query';
import { planAlreadyExists, notBeforeToday, validateMC, planIsInActive } from '../../medical-coverage.service';
import { CheckCoverageComponent } from '../check-coverage/check-coverage.component';
import Timer = NodeJS.Timer;
import { MedicalCoverageAutomationService } from "../../../../../services/medical-coverage-automation.service";

@Component({
  selector: 'app-medical-coverage-edit',
  templateUrl: './medical-coverage-edit.component.html',
  styleUrls: ['./medical-coverage-edit.component.scss'],
})
export class MedicalCoverageEditComponent implements OnInit {
  @Input() patientCoverages: FormArray;
  @Input() newPatientId: string;
  @Input() hasCoverageModal;

  private componentDestroyed: Subject<void> = new Subject();
  public event: EventEmitter<any> = new EventEmitter();
  bsModalRef: BsModalRef;

  constructor(
    private alertService: AlertService,
    private patientMedCovStore: PatientMedicalCoverageStore,
    private apiPatientInfoService: ApiPatientInfoService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private mcAutomationService: MedicalCoverageAutomationService
  ) {}

  ngOnInit() {
    this.initMedicalCoverages();
  }

  //------------------- Initialise Info methods --------------------//
  initMedicalCoverages() {
    if (!this.patientCoverages) {
      this.patientCoverages = this.fb.array([]);
    } else {
      this.cdr.markForCheck();
    }

    if (this.patientCoverages.value.length === 0) {
      this.addNewItem();
    }
  }

  addNewItem() {
    const formGroup: FormGroup = this.fb.group(createPatientCoverage());
    this.setValidators(formGroup);

    this.patientCoverages.push(formGroup);
    this.patientCoverages.updateValueAndValidity();
  }

  setValidators(plan: FormGroup) {
    plan.setControl('patientCoverageId', new FormControl('', [Validators.required, Validators.maxLength(20)]));
    plan.setControl('medicalCoverageId', new FormControl('', Validators.required));
    plan.setControl(
      'planId',
      new FormControl('', [Validators.required, planAlreadyExists(plan, this.patientCoverages)])
    );

    const endDate = plan.get('endDate');
    endDate.setValidators([
      notBeforeToday(endDate),
      validateMC(plan.get('coverageSelected'), endDate, true),
      Validators.required,
    ]);

    plan.get('coverageSelected').setValidators([planIsInActive]);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  onbtnDeleteClicked(index, $event) {
    const deleteConfirm = confirm('Are you sure you want to delete coverage?');

    if (deleteConfirm) {
      const toDelete = this.patientCoverages.at(index);

      if (!toDelete.get('isNew').value) {
        this.apiPatientInfoService
          .removePolicy(
            toDelete.get('id').value,
            toDelete.get('coverageType').value,
            toDelete.get('medicalCoverageId').value,
            toDelete.get('planId').value
          )
          .subscribe(
            () => {
              this.patientCoverages.removeAt(index);
            },
            err => {
              this.alertService.error('Error from medical coverage removal', err);
            }
          );
      } else {
        this.patientCoverages.removeAt(index);
      }
    }
  }

  onBtnSaveClicked() {
    this.event.emit(this.patientCoverages);
  }

  //------------------- hasUpdatePriority methods: Updating Visit Details --------------------//
  onBtnExit() {
    this.cleanData();
    this.event.emit('Close');
  }

  cleanData() {
    const filteredCoverage = this.patientCoverages.controls.filter(coverage => {
      const isValid = str => str !== null && str !== undefined && str !== '';
      const { value } = coverage;
      const { patientCoverageId, planId, medicalCoverageId, startDate, endDate } = value;

      return (
        isValid(patientCoverageId) &&
        isValid(planId) &&
        isValid(medicalCoverageId) &&
        isValid(startDate) &&
        isValid(endDate)
      );
    });

    while (this.patientCoverages.length > 0) {
      this.patientCoverages.removeAt(0);
    }
    filteredCoverage.forEach(coverage => {
      this.patientCoverages.push(coverage);
    });
  }

  checkCoverage(){
    const initialState = {
      currentPatientCoverages: this.patientCoverages,
    };
    if(this.newPatientId){
      initialState['newPatientId'] = this.newPatientId;
    }

    this.bsModalRef = this.modalService.show(CheckCoverageComponent, {
      initialState,
      class: 'modal-xl',
      backdrop: 'static',
      keyboard: false,
    });

    this.bsModalRef.content.event.subscribe(data => {
      if (data) {
        this.event.emit({type: 're-open', data: data });
        this.bsModalRef.hide();
      }
    });
    this.event.emit('Close');
  }

  showSpinner = false;
  countDown = 5
  countDownTimer: Timer;

  clearInterval() {
    clearInterval(this.countDownTimer)
  }
}
