import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';

import { AlertService } from '../../../services/alert.service';
import { ApiPatientVisitService } from '../../../services/api-patient-visit.service';

import { PrintTemplateService } from '../../../services/print-template.service';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-payment-lab-request-form',
  templateUrl: './payment-lab-request-form.component.html',
  styleUrls: ['./payment-lab-request-form.component.scss'],
})
export class PaymentLabRequestFormComponent implements OnInit {
  @Input() disabled = false;

  title: string;
  labRequestFormGroup: FormGroup;
  checkboxValue = false;
  visitId: string;
  visitState: string;
  labFormData = [];
  suppliers = [];
  doctors = [];
  selectedSupplierId: string;
  selectedDoctorId: string;
  referenceNumber: string;
  isFromVisitRow: boolean;
  isFromPatientDetails: boolean;
  isDeleteClicked: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private alertService: AlertService,
    private apiPatientVisitService: ApiPatientVisitService,
    private printTemplateService: PrintTemplateService
  ) {}

  ngOnInit() {
    this.isDeleteClicked = false;
    this.initForm();
    this.getLabFormData();
    if (this.isFromPatientDetails) {
      this.labRequestFormGroup.disable();
    }
  }

  initForm() {
    this.labRequestFormGroup = this.fb.group({
      labRequestForm: new FormGroup({
        formId: new FormControl(),
        supplierId: new FormControl('', Validators.required),
        doctorId: new FormControl('', Validators.required),
        referenceNumber: new FormControl(),
        labFormData: this.fb.array([]),
      }),
    });
  }

  populateLabItemData() {
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    fg.get('supplierId').patchValue(this.selectedSupplierId);
    fg.get('doctorId').patchValue(this.selectedDoctorId);

    // commenting this code as we need doctor id field to be enabled in all states
    // if (this.visitState !== 'INITIAL') {
    //   fg.get('doctorId').disable();
    // } else {
    //   fg.get('doctorId').enable();
    // }

    fg.get('referenceNumber').patchValue(this.referenceNumber);
    fg.get('referenceNumber').disable();
    this.labFormData.forEach(element => {
      const labFormDataGroup = this.fb.group({
        itemId: new FormControl(element.itemId),
        testCode: new FormControl(element.testCode),
        testName: new FormControl(element.testName),
        remarks: new FormControl(element.remarks),
        category: new FormControl(element.category),
        isChecked: new FormControl(false),
      });
      const fa = <FormArray>fg.controls['labFormData'];
      fa.push(labFormDataGroup);

    });
  }

  isFormValid() {
    return this.labRequestFormGroup.valid;
  }

  onBtnExit() {
    this.bsModalRef.hide();
  }

  printLabLabel() {
    this.save('LAB_LABLE');
  }

  printRequestForm() {
    this.save('REQUEST_FORM');
  }

  addNewLabItem() {
    const newLabTestItemGroup = this.fb.group({
      itemId: new FormControl(),
      testCode: new FormControl(),
      testName: new FormControl(),
      remarks: new FormControl(),
      category: new FormControl(),
      isChecked: new FormControl(false),
    });
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    const fa = <FormArray>fg.controls['labFormData'];
    fa.push(newLabTestItemGroup);
  }

  deleteLabItem() {
    const IndicesOfItemsToBeDeleted = [];
    let index = 0;
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    const labTestItemGroup = <FormArray>fg.controls['labFormData'];

    labTestItemGroup.value.forEach(element => {
      const isChecked = labTestItemGroup.value[index]['isChecked'];
      if (isChecked === true) IndicesOfItemsToBeDeleted.push(index);
      index++;
    });
    if (IndicesOfItemsToBeDeleted.length > 0) {
      this.isDeleteClicked = true;
    }
    IndicesOfItemsToBeDeleted.sort(function(a, b) {
      return b - a;
    });
    IndicesOfItemsToBeDeleted.forEach((element, index) => {
      const item = labTestItemGroup.at(element).value;
      labTestItemGroup.removeAt(element);
    });
    this.checkboxValue = false;
  }

  onCheckAll(event) {
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    const labTestItemGroup = <FormArray>fg.controls['labFormData'];
    if (event === 'T') {
      labTestItemGroup.controls.forEach(element => {
        element.get('isChecked').patchValue(true);
      });
    } else {
      labTestItemGroup.controls.forEach(element => {
        element.get('isChecked').patchValue(false);
      });
    }
  }

  save(type: string) {
    if (this.isFromPatientDetails) {
      this.saveAndShowForms(type);
    } else {
      if (this.labRequestFormGroup.valid) {
        this.saveAndShowForms(type);
      } else {

      }
    }
  }

  saveAndShowForms(type: string) {
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    let selectedSupplier = this.suppliers.find(item=> item.id === fg.get('supplierId').value);
    let qrCode = false;
    if(selectedSupplier){
      qrCode = selectedSupplier.qrcodeRequire;
    }
    
    this.apiPatientVisitService
      .saveLabRequestForm(this.visitId, fg.value)
      .subscribe(
        res => {
          if (res.payload) {

            if (res.statusCode === 'S0000') {
              this.isDeleteClicked = false;
              if (type === 'LAB_LABLE') {
                this.printTemplateService.onBtnPrintPatientLabelWithQrCodeClickedInForm(
                  this.visitId,
                  false,
                  qrCode
                );
              } else if (type === 'REQUEST_FORM') {
                  this.printTemplateService.onBtnPrintLabRequestFormInVisitRowClicked(
                    this.visitId
                  );

                // if (this.isFromVisitRow || this.isFromPatientDetails) {
                //   this.printTemplateService.onBtnPrintLabRequestFormInVisitRowClicked(
                //     this.visitId
                //   );
                // } else {
                //   this.printTemplateService.onBtnPrintLabRequestFormClicked();
                // }
              }
            } else {
              alert('Error occured while saving Lab form!');
            }
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err));
        }
      );
  }

  getLabFormData() {
    this.apiPatientVisitService.getLabRequestFormData(this.visitId).subscribe(
      res => {
        if (res.payload) {

          this.labFormData = res.payload.labRequestForm.labFormData;
          this.suppliers = res.payload.suppliers;
          this.doctors = res.payload.doctors;
          this.selectedSupplierId = res.payload.labRequestForm.supplierId;
          this.selectedDoctorId = res.payload.labRequestForm.doctorId;
          this.referenceNumber = res.payload.labRequestForm.referenceNumber;
          this.populateLabItemData();
        }
      },
      err => {
        this.alertService.error(JSON.stringify(err.error['message']));
      }
    );
  }

  isSupplierIdEmpty() {
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    const supplierId = <FormArray>fg.controls['supplierId'].value;
    return supplierId ? false : true;
  }

  onSupplierChanged(event: any) {
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    const fa = <FormArray>fg.controls['labFormData'];

    if(fa && fa.length > 0){
      const changeConfirm = confirm('Are you sure you want to change lab?');
      if (changeConfirm) {
        this.selectedSupplierId = event.id;
        this.labFormData = [];
        this.clearLabItems();
      } else{
        const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
        fg.get('supplierId').patchValue(this.selectedSupplierId);
      }
    }
  }

  clearLabItems(){
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    const fa = <FormArray>fg.controls['labFormData'];
    while (fa.length) {
      fa.removeAt(0);
    }
  }

  onSelectDoctor(event: any) {
    this.selectedDoctorId = event.id;
  }

  isItemsAvailable() {
    const fg = <FormGroup>this.labRequestFormGroup.controls['labRequestForm'];
    const fa = <FormArray>fg.controls['labFormData'];
    return fa.length > 0 ? true : false;
  }

  // TODO : It's need to disable doctor list to be disabled when visit status not INITIAL, but it's not working.
  // Need to further check it out.
  // isVisitInitialState() {
  //   return this.visitState == 'INITIAL' ? true : false;
  // }

  notDeletedAllItems() {
    if (!this.isItemsAvailable()) {
      if (this.isDeleteClicked) {
        return false;
      }
    }
    return true;
  }
}