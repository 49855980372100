<table class="table-custom table-custom-light">
    <tbody>
        <tr>
            <td class="td-title">Journey ID</td>
            <td>{{patientJourney.journeyId}}</td>
        </tr>
        <tr>
            <td class="td-title">NRIC</td>
            <td>{{patientJourney.nric}}</td>
        </tr>
        <tr>
            <td class="td-title">Journey Registration Date</td>
            <td>{{patientJourney.journeyRegistrationDate}}</td>
        </tr>
        <tr>
            <td class="td-title">Journey Pathway</td>
            <td>{{patientJourney.journeyPathway}}</td>
        </tr>
        <tr>
            <td class="td-title">Journey Status</td>
            <td>{{patientJourney.journeyStatus}}</td>
        </tr>
        <tr>
            <td class="td-title">Journey Status</td>
            <td>{{patientJourney.journeyStatus}}</td>
        </tr>
        <tr>
            <td class="td-title">Milestone Month</td>
            <td>{{patientJourney.milestoneMonth}}</td>
        </tr>
        <tr>
            <td class="td-title">Milestone Month Reply</td>
            <td>{{patientJourney.milestoneMonthReply}}</td>
        </tr>
        <tr>
            <td class="td-title">Quit Status</td>
            <td>{{patientJourney.quitStatus}}</td>
        </tr>
    </tbody>
</table>


<!-- <div class="row">
    <div class="col-md-5">
        <span class="title">NRIC</span>
    </div>
    <div class="col-md-7">
        <span class="title">{{patientJourney.nric}}</span>
    </div>
</div>

<div class="row">
    <div class="col-md-5">
        <span class="title">NRIC</span>
    </div>
    <div class="col-md-7">
        <span class="title">{{patientJourney.nric}}</span>
    </div>
</div> -->