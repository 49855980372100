import { Clinic } from './../../../../objects/response/Clinic';

import { Router } from '@angular/router';
import { StoreService } from './../../../../services/store.service';
import { MessageService } from './../../../../services/message.service';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { AkitaStoreService } from '../../../../services/states/akita-store.service';
import { AkitaClinicDoctorQuery } from '../../../../services/states/akita-clinic-doctor.query';
import { filter } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApiClinicRoomService } from '../../../../services/api-clinic-room.service';
import { ApiPatientInfoService } from '../../../../services/api-patient-info.service';
import { AkitaClinicDoctorStore } from '../../../../services/states/akita-clinic-doctor.store';
import { Doctor } from '../../../../objects/state/Doctor';
import { AkitaAppQuery } from '../../../../services/states/akita-app.query';
import { AlertService } from '../../../../services/alert.service';
import * as moment from 'moment';
import { DB_FULL_DATE_TIMEZONE_NO_SPACE, DISPLAY_DATE_TIME } from '../../../../constants/app.constants';

@Component({
  selector: 'app-clinic-select',
  templateUrl: './clinic-select.component.html',
  styleUrls: ['./clinic-select.component.scss'],
})
export class ClinicSelectComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  clinicList = Array<Clinic>();
  authorizedClinicList = Array<Clinic>();
  userName = '';
  testLength = 12;
  maxWidthPerLine = 24;
  doctor;
  lastLogin = "";

  constructor(
    private alertService: AlertService,
    private storeService: StoreService,
    private messageService: MessageService,
    private router: Router,
    private akitaStoreService: AkitaStoreService,
    private akitaClinicDoctorQuery: AkitaClinicDoctorQuery,
    private permissionsService: NgxPermissionsService,
    private apiPatientInfoService: ApiPatientInfoService,
    private clinicDoctorStore: AkitaClinicDoctorStore,
    public store: StoreService,
    private akitaAppQuery: AkitaAppQuery,
  ) { }

  ngOnInit() {
    this.getClinicList();
    this.getListOfAuthorizedClinics();
    this.lastLogin = this.getLastLogin(this.store.getUser().lastLogin);
  }

  getLastLogin(date){
    if(date){
      return moment(date, DB_FULL_DATE_TIMEZONE_NO_SPACE).format(DISPLAY_DATE_TIME);
    }
    return date;
  }

  getClinicList() {
    if (this.clinicList.length < 1) {
      this.clinicList = this.storeService.getClinicList();
    }
  }

  getListOfAuthorizedClinics() {
    this.authorizedClinicList = this.storeService.authorizedClinicList.sort((clinic1, clinic2) => {
      if (clinic1.clinicCode < clinic2.clinicCode)
        return -1;
      if (clinic1.clinicCode > clinic2.clinicCode)
        return 1;
      return 0;
    });
  }

  onClick() { }

  onSelect(clinic: Clinic) {

    this.storeService.clinicCode = clinic.clinicCode;
    this.storeService.setClinicId(clinic.id);
    this.storeService.clinic = this.storeService.getClinic();
    this.akitaStoreService.updateClinicCode(clinic.clinicCode);
    this.akitaStoreService.updateClinicId(clinic.id);
    localStorage.setItem('clinicName', clinic.name);
    localStorage.setItem('clinicCode', this.storeService.clinicCode);
    localStorage.setItem('clinicId', clinic.id);
    
    this.messageService.resetWebSocket();
    // this.storeService.listDoctorsByClinic();

    const clinicDoctorCache = this.akitaClinicDoctorQuery
      .selectHasCache()
      .pipe(filter(cache => !!cache))
      .subscribe(cache => {
        if (clinicDoctorCache) clinicDoctorCache.unsubscribe();
        const clinicSelected = this.storeService
          .getClinicList()
          .find(perClinic => perClinic.clinicCode === clinic.clinicCode);

        if (clinicSelected) {
          const userWithinClinic =
            clinic.status &&
            clinic.status === 'ACTIVE' &&
            !!clinicSelected.clinicStaffUsernames.find(
              username => username === this.storeService.getUser().userName
            );
          //demo
          if (userWithinClinic) {

            this.event.emit('USER ACCESSED');
            this.permissionsService.hasPermission("ROLE_AA_ADMIN").then(res => {
              if (res) {
                this.permissionsService.hasPermission("ROLE_VIEW_CA_DASHBOARD").then(res => {
                  if (res) {
                    this.router.navigate(['pages/dashboard']);
                  } else {
                    this.router.navigate(['pages/patient/list']);
                  }
                })

              } else {

                try {

                  //start
                  this.permissionsService.hasPermission("ROLE_DOCTOR").then(res => {


                    if (res && this.isDoctorOnlineOfflineFeature()) {

                      this.doctor = this.store
                        .getDoctorList()
                        .find(doctor => doctor.id === this.store.getUserId());
                      if (this.doctor.externalAppData && this.doctor.externalAppData.availableForApp) {

                        this.updateDoctorOnlineStatus();
                      } else {

                        this.permissionsService.hasPermission("ROLE_VIEW_CA_DASHBOARD").then(res => {
                          if (res) {
                            this.router.navigate(['pages/dashboard']);
                          } else {
                            this.router.navigate(['pages/patient/list']);
                          }
                        })
                      }



                    } else {
                      this.permissionsService.hasPermission("ROLE_VIEW_CA_DASHBOARD").then(res => {
                        if (res) {
                          this.router.navigate(['pages/dashboard']);
                        } else {
                          this.router.navigate(['pages/patient/list']);
                        }
                      })
                    }
                  });



                  //end

                }
                catch (err) {
                  console.error('Doctor online toggle Error', err);
                }
              }
            });

            // this.event.emit('USER ACCESSED');
            // this.router.navigate(['pages/patient/list']);
          }
        }
      });
  }
  isDoctorOnlineOfflineFeature() {
    return this.akitaAppQuery.checkClinicFeatureExist('DOCTOR_ONLINE_STATUS');
  }

  updateDoctorOnlineStatus() {
    const user = this.store.getUser();
    const doctorId = user.context['cms-user-id'];
    const doctor = this.akitaClinicDoctorQuery.getEntity(user.context['cms-user-id']) as Doctor;
    const clinicId = this.store.getClinicId()
    this.apiPatientInfoService.updateDoctorOnlineStatus(
      doctorId,
      true,
      clinicId
    )


    //estart
    this.apiPatientInfoService.updateDoctorOnlineStatus(
      doctorId,
      true,
      clinicId
    ).subscribe(
      payload => {
        if (payload.statusCode === 'S0000') {

          this.permissionsService.hasPermission("ROLE_VIEW_CA_DASHBOARD").then(res => {
            if (res) {
              this.router.navigate(['pages/dashboard']);
            } else {
              this.router.navigate(['pages/patient/list']);
            }
          })
        } else {
          this.permissionsService.hasPermission("ROLE_VIEW_CA_DASHBOARD").then(res => {
            if (res) {
              this.router.navigate(['pages/dashboard']);
            } else {
              this.router.navigate(['pages/patient/list']);
            }
          })
        }
      },
      err => {
        const slider: HTMLInputElement = document.getElementById('onlineSlider') as HTMLInputElement;
        slider.checked = false;
        this.alertService.error(JSON.stringify(err.error['message']));
      }
    );

    //end





  }

  formatAddressMultiLine(clinic: string, rowNumber: number) {
    let splitAt: number;

    if (clinic && clinic.length > this.maxWidthPerLine) {
      splitAt = clinic
        .substring(this.maxWidthPerLine, clinic.length)
        .search(' ');
      return rowNumber === 0
        ? clinic.substring(0, this.maxWidthPerLine + splitAt)
        : clinic.substring(this.maxWidthPerLine + splitAt + 1, clinic.length);
    } else {
      return clinic;
    }
  }

  checkPostalCodeExistsInAddressParam(address: string) {
    if (address.search('SINGAPORE') !== 0) {
      return true;
    } else {
      return false;
    }
  }
}
