import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';

export interface PatientSource {
  source: string;
}

export function createPatientSource(source: string) {
  return {
    source
  } as PatientSource;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patientSource', idKey: 'source', cache: { ttl: AKITA_CACHE_DURATION }, resettable: true })
export class AkitaPatientSourceStore extends EntityStore<PatientSource> {

  constructor() {
    super();
  }

}
