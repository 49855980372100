import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss']
})
export class QuillEditorComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() disabled = false;

  quill: any;
  modules = {
    toolbar: [
      [{ 'header': [false, 1, 2, 3] }],

      ['bold', 'italic', 'blockquote'],

      [{ 'list': 'bullet' }, { 'list': 'ordered' }]
    ]
  };

  ngOnInit() {
  }

  ngOnDestroy() {
    this.quill = null;
  }

  constructor() {

  }

  onEditorCreated(event) {
    this.quill = event;
  }

}
