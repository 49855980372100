import { Vaccination } from './../../../objects/Vaccination';
import { DISPLAY_DATE_FORMAT } from './../../../constants/app.constants';
import { map, filter, groupBy, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AkitaPatientAppStore, AkitaPatientApp } from './akita-patient-app.store';
import PatientInfo from '../../../objects/state/PatientInfo';
import * as moment from 'moment';
import { SFLHistoryList } from '../../../model/SFLItem';

@Injectable({ providedIn: 'root' })
export class AkitaPatientAppQuery extends Query<AkitaPatientApp> {
  get patientInfo() {
    return this.getValue().patientInfo || null;
  }
  storeConfig = this.getValue().storeConfig || [];
  id = this.getValue().id || '';

  $patientId = this.select(entity => entity.id);
  patientInfo$ = this.select(entity => entity.patientInfo).pipe(filter(info => info !== null && info !== undefined));

  patientVaccinations$ = this.patientInfo$.pipe(
    filter(info => info != undefined),
    map(info => info.patientVaccinations),
    map((vaccine: Vaccination[]) => {
      let sortedValue = vaccine.slice().sort((a, b) => {
        if (moment(a.givenDate, DISPLAY_DATE_FORMAT).isSameOrBefore(moment(b.givenDate, DISPLAY_DATE_FORMAT))) {
          return 1;
        }
        return -1;
      });

      return sortedValue;
    })
  );

  patientSfl$ = this.patientInfo$.pipe(
    filter(info => info != undefined),
    map(info => info.sflHistoryList),
    map((vaccine: SFLHistoryList[]) => {
      let sortedValue = vaccine.slice().sort((a, b) => {
        if (moment(a.screenDate, DISPLAY_DATE_FORMAT).isSameOrBefore(moment(b.screenDate, DISPLAY_DATE_FORMAT))) {
          return 1;
        }
        return -1;
      });

      return sortedValue;
    })
  );

  $selectAll = this.select();
  $storeConfig = this.select(entity => entity.storeConfig);
  $selectIdAndConfig = this.select(['id', 'storeConfig']);

  constructor(protected store: AkitaPatientAppStore) {
    super(store);
  }

  getId() {
    return this.getValue().id || '';
  }
}
