<div class="row">
    <div class="col-12 mb-3">
        <div class="alert alert-info m-0" role="alert">
            Note: The system has auto-selected the top 2 diet recommendations of focus based on chronic condition(s)
            tagging in this Health Plan, you may select or unselect as needed.
        </div>
    </div>

    <div class="col-12">
        <h5>When planning your meals,</h5>

        <table class="custom-table">
            <thead>
                <tr>
                    <th class="w-20"></th>
                    <th class="w-40">Default Advice Served to Residents</th>
                    <th>Optional Comments</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of dietRecommendationList; let i = index;">
                    <tr [formGroup]="formGroupList[i]">
                        <td>
                            <input type="checkbox" formControlName="selected" (change)="onCheck(formGroupList[i])">
                            {{item.category}}
                        </td>
                        <td>{{item.description}}</td>
                        <td>
                            <div class="wrapper-form-control">
                                <textarea class="input" formControlName="contextValue"></textarea>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>