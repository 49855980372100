import { Injectable } from '@angular/core';

import { PatientVisitList } from '../../../../objects/response/PatientVisitList';
import { Page } from '../../../../model/page';

import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import * as moment from 'moment';

export interface PatientVisitHistoryState extends EntityState<PatientVisitList>, ActiveState {
  page: Page,
  endDate: Date,
  startDate: Date
}

const initialState = {
  active: null,
  page: new Page(0, 15),
  endDate: new Date(),
  startDate: moment().subtract(1, 'years').toDate()
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patientVisitHistory', idKey: 'visitId' })
export class PatientVisitHistoryStore extends EntityStore<PatientVisitHistoryState, PatientVisitList> {
  constructor() {
    super(initialState);
  }

  updatePageDate(page: Page, startDate: Date, endDate: Date) {
    this.update({
      page,
      startDate,
      endDate
    })
  }

  updatePage(page: Page) {
    this.update({
      page
    });
  }

  updateStartDate(startDate: Date) {
    this.update({
      startDate
    });
  }

  updateEndDate(endDate: Date) {
    this.update({
      endDate
    });
  }

  updateStatus(status: Array<string>) {
    this.update({
      status
    });
  }
}