import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';

@Injectable()
export class ApiClinicRoomService {
 
  private API_CLINIC_ROOM_URL;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.API_CLINIC_ROOM_URL = appConfig.getConfig().API_CLINIC_ROOM_URL;
  }

  getDetails(clinicId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLINIC_ROOM_URL}/details/${clinicId}`,
      JSON.stringify({})
    );
  }

  save(roomList, clinicId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CLINIC_ROOM_URL}/save/${clinicId}`,
      JSON.stringify(roomList)
    );
  }
}
