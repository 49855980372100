import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private sidebarStateSub = new Subject<boolean>();

  constructor() { }

  public setStatus(status: boolean): void {
    this.sidebarStateSub.next(status);
  }

  public getStatus(): Observable<boolean> {
    return this.sidebarStateSub.asObservable();
  }
}