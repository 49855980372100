import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { HealthPlanWorkflow } from '../../../../../../../model/care-plan/CarePlanHealthGoal';

@Component({
  selector: 'app-health-plan',
  templateUrl: './health-plan.component.html',
  styleUrls: ['./health-plan.component.scss']
})
export class HealthPlanComponent implements OnInit, OnChanges {

  @Input() index: number = 0;
  @Input() healthPlan: HealthPlanWorkflow;
  @Input() dropdownList: Array<any> = [];
  @Input() selectedDropdownList: Array<any> = [];

  @Output() selecttGoalEventEmitter = new EventEmitter<{ rowIndex: number, selectedPlanId: string }>();
  @Output() deletedGoalEventEmitter = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.dropdownList = this.dropdownList.map(dropdown => {
      return {
        ...dropdown,
        disabled: this.selectedDropdownList.includes(dropdown.id),
      }
    });
  }

  public onSelectGoal(event: any): void {
    this.selecttGoalEventEmitter.emit({ rowIndex: this.index, selectedPlanId: event.id });
  }

  public onCheck(checked: boolean, workflow: HealthPlanWorkflow): void {
    if (checked) {
      workflow.bodySectionFormGroup.enable();
    } else {
      workflow.bodySectionFormGroup.disable();
    }

    const selectedFormControl = workflow.bodySectionFormGroup.get('selected');
    if (selectedFormControl) {
      selectedFormControl.enable();
    }
  }

  public onDelete(id: string): void {
    this.deletedGoalEventEmitter.emit(id);
  }
}