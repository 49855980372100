import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';

@Injectable()
export class ApiConsultaitonTemplateService {
  private API_CONSULTATION_TEMPLATE_URL;

  constructor(private http: HttpClient, private appConfig: AppConfigService) {
    this.API_CONSULTATION_TEMPLATE_URL = appConfig.getConfig().API_CONSULATION_TEMPLATE_URL;
  }

  getAllTemplateList(
    clinicId: string,
    doctorId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/list/${clinicId}/${doctorId}`,
      JSON.stringify({})
    );
  }

  getConsulationTemplateList(doctorId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/list/${doctorId}`,
      JSON.stringify({})
    );
  }

  searchConsulationTemplate(
    doctorId: string,
    templateBody
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/search/${doctorId}`,
      JSON.stringify(templateBody)
    );
  }

  addGlobalConsulationTemplate(templateBody): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/add`,
      JSON.stringify(templateBody)
    );
  }

  addDoctorConsulationTemplate(
    doctorId: string,
    templateBody
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/add/${doctorId}`,
      JSON.stringify(templateBody)
    );
  }

  updateGlobalConsulationTemplate(
    templateId: string,
    templateBody
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/update/${templateId}`,
      JSON.stringify(templateBody)
    );
  }

  updateGlobalDisableTemplate(
    doctorId: string,
    templateId: string,
    templateBody
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/updateDisable/${doctorId}/${templateId}`,
      JSON.stringify(templateBody)
    );
  }

  updateDoctorConsulationTemplate(
    doctorId: string,
    templateId: string,
    templateBody
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/update/${doctorId}/${templateId}`,
      JSON.stringify(templateBody)
    );
  }

  deleteGlobalConsulationTemplate(templateBody): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/delete`,
      JSON.stringify(templateBody)
    );
  }

  deleteDoctorlConsulationTemplate(
    doctorId: string,
    templateBody
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/delete/${doctorId}`,
      JSON.stringify(templateBody)
    );
  }

  getTagList(level: string, type: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/tags/list/${level}/${type}`,
      JSON.stringify({})
    );
  }

  //DRUG TEMPLATE
  addDoctorDrugTemplate(doctorId, body) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/item/add/${doctorId}`,
      JSON.stringify(body)
    );
  }

  addGlobalDrugTemplate(body) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/item/add`,
      JSON.stringify(body)
    );
  }

  updateDoctorDrugTemplate(doctorId, templateId, body) {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_CONSULTATION_TEMPLATE_URL
      }/item/update/${doctorId}/${templateId}`,
      JSON.stringify(body)
    );
  }

  updateGlobalDrugTemplate(templateId, body) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/item/update/${templateId}`,
      JSON.stringify(body)
    );
  }

  deleteDoctorlDrugTemplate(
    doctorId: string,
    ids
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/item/delete/${doctorId}`,
      JSON.stringify(ids)
    );
  }

  deleteGlobalDrugTemplate(ids): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CONSULTATION_TEMPLATE_URL}/item/delete`,
      JSON.stringify(ids)
    );
  }

  loadDrugTemplate(itemType: string, templateId: string, clinicId: string, doctorId: string) {
    return this.http.post<HttpResponseBody>(
      `${
        this.API_CONSULTATION_TEMPLATE_URL
      }/item/load/${itemType}/${templateId}/${clinicId}/${doctorId}`,
      JSON.stringify({})
    );
  }
}
