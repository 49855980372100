import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-patient-detail-add-document',
  templateUrl: './patient-detail-add-document.component.html',
  styleUrls: ['./patient-detail-add-document.component.scss'],
})
export class PatientDetailAddDocumentComponent implements OnInit, OnDestroy {
  @Input() uploader: FileUploader;
  @Input() formGroup: FormGroup;
  byPatientId: boolean;
  title: string;
  queueLength = 0;
  totalSize = 0;
  isDescriptionFilled = false;
  names = [];
  labItemArray = [];

  documentTypes: string[];

  private componentDestroyed: Subject<void> = new Subject();

  constructor(private fb: FormBuilder, public bsModalRef: BsModalRef) { }

  ngOnInit() {
    if (!this.byPatientId) {
      const items = this.formGroup.get('itemArray') as FormArray;
      const itemsArray = !!items ? items.value : (this.labItemArray.length > 0 ? this.labItemArray : [])
      this.names = itemsArray
        .filter(item => item.itemType === 'LABORATORY')
        .map(item => item.name)
        .map(name => {
          return {
            value: name,
            label: name,
          };
        });
      this.names.push({ value: 'OTHER', label: 'Other' });
    }
    this.refreshNewDocumentsArray();

    this.formGroup.get(
      'newDocumentsArray'
    ).valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.isDescriptionFilled = value.every(doc => !!doc.description.trim())
    })
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  fileUpload(event) {
    this.updateNewDocumentsArray();
  }

  refreshNewDocumentsArray() {
    const newDocumentsArray = this.formGroup.get(
      'newDocumentsArray'
    ) as FormArray;
    while (newDocumentsArray.length) {
      newDocumentsArray.removeAt(0);
    }

    this.queueLength = this.uploader.queue.length;
    this.isDescriptionFilled = false;
    this.totalSize = 0;

    this.uploader.queue.forEach(document => {
      const doc: any = document; //cast and avoid error on doc.description
      const newDocument = this.fb.group({
        names: { value: this.names },
        name: [this.names.length === 1 ? 'OTHER' : '', Validators.required],
        document: doc.file.name,
        description: [doc.description || '', Validators.required],
        type: doc.file.type,
        size: doc.file.size,
        listType: [this.documentTypes[0]],
        fileId: '',
      });
      // newDocument
      //   .get('description')
      //   .valueChanges.pipe(
      //     takeUntil(this.componentDestroyed)
      //     )
      //   .subscribe(description => {
      //     debugger
      //     if (!description) {
      //       this.isDescriptionFilled = false;
      //       return;
      //     }
      //     const numOfFilledDescriptions = newDocumentsArray.value.reduce(
      //       (sum, document) => (sum += document.description ? 1 : 0),
      //       0
      //     );
      //     if (numOfFilledDescriptions !== newDocumentsArray.value.length) {
      //       this.isDescriptionFilled = false;
      //     } else {
      //       this.isDescriptionFilled = true;
      //     }
      //   });
      newDocumentsArray.push(newDocument);
      this.totalSize += document.file.size;
    });
  }

  updateNewDocumentsArray() {
    this.isDescriptionFilled = false;

    const newDocumentsArray = this.formGroup.get(
      'newDocumentsArray'
    ) as FormArray;
    for (let i = this.queueLength; i < this.uploader.queue.length; i++) {
      const document: any = this.uploader.queue[i]; //cast to `any` and avoid error on doc.description
      const newDocument = this.fb.group({
        names: { value: this.names },
        name: [this.names.length === 1 ? 'OTHER' : '', Validators.required],
        document: document.file.name,
        description: [document.description || '', Validators.required],
        type: document.file.type,
        size: document.file.size,
        listType: [this.documentTypes[0]],
        fileId: '',
      });
      // newDocument
      //   .get('description')
      //   .valueChanges.pipe(takeUntil(this.componentDestroyed))
      //   .subscribe(description => {
      //     debugger
      //     if (!description) {
      //       this.isDescriptionFilled = false;
      //       return;
      //     }
      //     const numOfFilledDescriptions = newDocumentsArray.value.reduce(
      //       (sum, document) => (sum += document.description ? 1 : 0),
      //       0
      //     );

      //     if (numOfFilledDescriptions !== newDocumentsArray.value.length) {
      //       this.isDescriptionFilled = false;
      //     } else {
      //       this.isDescriptionFilled = true;
      //     }
      //   });
      newDocumentsArray.push(newDocument);
      this.totalSize += document.file.size;
    }
    this.queueLength = this.uploader.queue.length;
  }

  onDelete(form: FormGroup, index: number) {
    const document = this.uploader.queue[index];

    this.uploader.removeFromQueue(document);
    this.queueLength = this.uploader.queue.length;
    this.totalSize -= document.file.size;

    form.patchValue({
      isDelete: true,
      deleteIndex: index,
    });

    const formArray = form.parent as FormArray;
    formArray.removeAt(index);
  }

  onSave() {
    this.uploader.uploadAll();
    this.bsModalRef.hide();
  }
}
