import { AKITA_CONSULTATION } from './../constants/akita.config';
import { AkitaCaseStore } from './../services/states/akita-case/akita-case.store';
import { AkitaPatientStoreService } from './../services/states/akita-patient/akita-patient-store.service';
import { CaseChargeFormService } from './../services/case-charge-form.service';
import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { EMPTY } from 'rxjs';
import { StoreService } from '../services/store.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApiCaseManagerService } from '../services/api-case-manager.service';
import { ConsultationFormService } from '../services/consultation-form.service';

import { IPackageItem } from '../components/shared/package/package.model';

import { PaymentService } from '../services/payment.service';
import { AkitaCaseStoreService } from '../services/states/akita-case/akita-case-store.service';
import { DB_FULL_DATE_FORMAT, DISPLAY_TIME_NO_SECONDS_24_FORMAT } from '../constants/app.constants';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PvmResolverService implements Resolve<any> {
  constructor(
    private store: StoreService,
    private apiCaseManagerService: ApiCaseManagerService,
    private caseChargeFormService: CaseChargeFormService,
    private akitaCaseStore: AkitaCaseStore,
    private akitaPatientStore: AkitaPatientStoreService,
    private consultationFormService: ConsultationFormService,
    private router: Router,
    private paymentService: PaymentService,
    private akitaCaseStoreService: AkitaCaseStoreService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.paymentService.updateCoverageVisitBreakdown([]);

    const patientId = route.params['patientId'];
    const visitId = route.params['visitId'];
    const caseId = route.params['caseId'];
    const visitStatus = route.params['visitStatus'];
    // extra state and reload is used to relaod PVM and only being set from method reloadPVM in ConsultationFormService
    const extraState = this.router.getCurrentNavigation().extras.state;
    const reload = !!extraState ? Boolean(extraState['reload']) : false;

    if (
      visitId === this.store.getPatientVisitRegistryId() &&
      visitStatus === this.store.getVisitStatus() &&
      !reload
    ) {
      return '';
    }
    if (reload) {
      this.akitaCaseStoreService.getCase(caseId);
    }

    this.akitaPatientStore.setPatientApp(patientId, AKITA_CONSULTATION, visitId);
    this.store.setPatientVisitRegistryId(visitId, true);
    // this.store.setCaseId(caseId, true);
    this.store.setCaseId(caseId);
    this.akitaCaseStore.update({ caseId: caseId });

    return this.apiCaseManagerService.getCaseDetailsById(caseId, visitId).pipe(
      map(data => {
        if (data && data.payload) {
          this.consultationFormService.updateCaseInStore(data.payload, visitId);
          this.store.setVisitStatus(data.payload.patientVisitEntities[0].visitStatus);

          let consultation = data.payload.patientVisitEntities[0].medicalReferenceEntity.consultation;
          if(consultation && consultation.consultationEndTime){
            let consultEndTime = moment(consultation.consultationEndTime, DB_FULL_DATE_FORMAT).format(DISPLAY_TIME_NO_SECONDS_24_FORMAT);
            this.store.setConsultEndTime(consultEndTime);
          } else{
            this.store.setConsultEndTime("");
          }

          if(consultation && consultation.consultationStartTime){
            let consultStartTime = moment(consultation.consultationStartTime, DB_FULL_DATE_FORMAT).format(DISPLAY_TIME_NO_SECONDS_24_FORMAT);
            this.store.setConsultStartTime(consultStartTime);
          } else{
            this.store.setConsultStartTime("");
          }
        }

        const currentVisit = {
          ...data.payload.patientVisitEntities.find(
            visit => visit.visitId === visitId
          ),
        };
        let item = [];
        this.store.setVisitStartTime(currentVisit.startTime);
        this.consultationFormService.clearDrugQtyBalance();

        if (currentVisit) {
          item = currentVisit.medicalReferenceEntity.dispatchItemEntities.map(
            item => {
              return this.caseChargeFormService.buildChargeDetailsItem(
                item.itemId,
                item.excludedCoveragePlanIds,
                item.quantity
              );
            }
          );
        }
        const packages = currentVisit.medicalReferenceEntity
          .packages as IPackageItem[];
        if (!!packages.length) {
          const uniqueSubItemIds = this.consultationFormService.getUniqueInventoriedSubItems();
          const subItemsForCaseCharge = uniqueSubItemIds.map(id => {
            return this.caseChargeFormService.buildChargeDetailsItem(id, [], 1);
          });

          item = item.concat(...subItemsForCaseCharge);
        }

        return { currentVisit, item };
      }),
      switchMap(data => {
        const currentVisit = data.currentVisit;
        const item = data.item;
        if (currentVisit) {
          return this.apiCaseManagerService
            .getCaseItemPrices(caseId, {
              chargeDetails: item,
            })
            .pipe(
              tap(res => {
                this.consultationFormService.updateAllergyAndInventory(
                  res.payload.inventoryData,
                  true
                );
              }),
              map(res2 => {
                return [currentVisit, res2];
              })
            );
        } else {
          return EMPTY;
        }
      })
    );
  }
}
