import { Injectable } from "@angular/core";

import { QueryEntity } from '@datorama/akita';

import { AkitaPRJustificationStore } from './akita-pr-justification.store';
import { InventoryInfo } from '../../objects/state/InventoryInfo';

@Injectable({ providedIn: 'root' })
export class AkitaPRJustificationQuery extends QueryEntity<InventoryInfo> {

  constructor(protected store: AkitaPRJustificationStore) {
    super(store);
  }

}
