<div class="card">
    <div class="card-header">
        Possible Matches for Medical Coverage
        <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnExit()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="card-body">
        <div class="d-flex flex-row">
            <div class="pl-1 pr-2 pt-2">Identity:</div>
            <div class="pl-1 pr-2 pt-2">
                <input class="form-control form-control-sm" type="text" [(ngModel)]="searchParam"/>
            </div>
            <div class="p-1">
                <button class="btn btn-large btn-brand-primary" [disabled]="!searchParam" (click)="search()">
                    <span *ngIf="!isRequesting">
                        Search
                      </span>
                    <span *ngIf="isRequesting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                    </span>
                    <span class="sr-only" *ngIf="isRequesting">
                      Loading...
                    </span>
                </button>
            </div>
            <div class="pt-2 pl-2">
                <label style="font-size: 13px;"> Can search by Patient Identity, Employee ID or Policy number. </label>
            </div>
          </div>
  
        <div class="row" *ngIf="patientCorporateCoverages && patientCorporateCoverages.controls.length > 0">
            <div class="col-md-12">
                <p class="title fw-bold mt-2 mb-1">Coporate Medical Coverages</p>
            </div>
            <div class="col-12">
                <div class="row data-table">
                    <div class="input-group input-group-sm input-group-append mb-0 px-0">
                        <div class="col-1"><label class="mb-0">Select</label></div>
                        <div class="col-2"><label class="mb-0">Name</label></div>
                        <div class="col-1"><label class="mb-0 px-0">ID</label></div>
                        <div class="col-2"><label class="mb-0">Company</label></div>
                        <div class="col-1"><label class="mb-0">Plan</label> </div>
                        <div class="col-1"><label class="mb-0">Policy No.</label></div>
                        <div class="col-2"><label class="mb-0">Cost Centre</label> </div>
                        <div class="col-2"><label class="mb-0">Remarks</label> </div>
                    </div>
                </div>
                <div *ngFor="let item of patientCorporateCoverages.controls; let i = index;">
                    <app-check-coverage-item
                        [patientCoverageItem]="item"
                        [selectedItems]="selectedItems">
                    </app-check-coverage-item>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="patientInsuranceCoverages && patientInsuranceCoverages.controls.length > 0">
            <div class="col-md-12">
                <p class="title fw-bold mt-2 mb-1">Insurance</p>
            </div>
            <div class="col-12">
                <div class="row data-table">
                    <div class="input-group input-group-sm input-group-append mb-0 px-0">
                        <div class="col-1"><label class="mb-0">Select</label></div>
                        <div class="col-2"><label class="mb-0">Name</label></div>
                        <div class="col-1"><label class="mb-0 px-0">ID</label></div>
                        <div class="col-2"><label class="mb-0">Company</label></div>
                        <div class="col-1"><label class="mb-0">Plan</label> </div>
                        <div class="col-1"><label class="mb-0">Policy No.</label></div>
                        <div class="col-2"><label class="mb-0">Cost Centre</label> </div>
                        <div class="col-2"><label class="mb-0">Remarks</label> </div>
                    </div>
                </div>
                <div *ngFor="let item of patientInsuranceCoverages.controls; let i = index;">
                    <app-check-coverage-item 
                        [patientCoverageItem]="item"
                        [selectedItems]="selectedItems">
                    </app-check-coverage-item>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div *ngIf="patientInsuranceCoverages && patientInsuranceCoverages.controls.length === 0 && patientCorporateCoverages && patientCorporateCoverages.controls.length === 0" class="d-flex justify-content-center">
                    <div class="p-1">No data available</div>
                </div>
                <div class="text-right pt-3">
                    <button class="btn btn-brand-secondary" [disabled]="!(selectedItems.length === 1)"
                        (click)="save()">Save</button>
                </div>
            </div>
        </div>
    </div>
  </div>