import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {Observable, EMPTY, Subject} from 'rxjs';
import {distinctUntilChanged, startWith, tap} from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { StoreService } from './store.service';

import { INVALID_TOKEN_ERROR_MESSAGE } from '../constants/error-messages.constant';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  exceptionUrl = 'aacore/login';
  authService: AuthService;
  apiService: ApiService;
  private alertSubject = new Subject<string | undefined>();

  constructor(
    private router: Router,
    private injector: Injector,
    private storeService: StoreService
  ) {
    this.alertSubject
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe((message) => {
        if (message) {
          alert(message);
          this.alertSubject.next(undefined);
        }
      })
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers.set('Content-Type', 'application/json'),
    });

    const clinicId = this.storeService.getClinicId();
    if (!!clinicId) {
      request = request.clone({
        headers: request.headers.set('X-LOGGED-IN-CLINIC-ID', clinicId),
      });
    }
    let tenantID = localStorage.getItem('tenantId');
    if (!!tenantID) {
      request = request.clone({
        headers: request.headers.set('X-Tenant', tenantID),
      });
    }


    if (!this.authService) {
      this.authService = <AuthService>this.injector.get(AuthService);
    }
    if (!this.apiService) {
      this.apiService = <ApiService>this.injector.get(ApiService);
    }

    this.apiService.addExecutingApi(request.url);
    if (
      !request.url.includes('aacore/login') &&
      !request.url.includes('aacore/user/2fa') &&
      !request.url.includes('aacore/user/resend-otp') &&
      !localStorage.getItem('access_token')
    ) {
      this.apiService.removeExecutingApi(request.url);
      return EMPTY;
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
            this.apiService.removeExecutingApi(request.url);
          }
        },
        (err: any) => {
          this.apiService.removeExecutingApi(request.url);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              let defaultTenantID = localStorage.getItem('tenantId');
              localStorage.clear();
              localStorage.setItem('tenantId', defaultTenantID);

              const temp_access_token: string = sessionStorage.getItem('temp_access_token');

              if(temp_access_token) localStorage.setItem('access_token', temp_access_token);

              if (
                !err.url.includes('aacore/login') &&
                !request.url.includes('aacore/user/2fa') &&
                !err.url.includes('aacore/logout') &&
                err.error.status !== 'FORBIDDEN'
              ) {
                this.authService.triggerLogout();
                this.alertSubject.next(INVALID_TOKEN_ERROR_MESSAGE);
                this.router.navigate(['login']);
              }
            }
          }
        }
      )
    );
  }
}
