<div class="row row-filter mb-2">
    <div class="col-md-6 col-filter-left">
        <button class="button-primary" *ngIf="this.router.url.includes('projects/hsg/list') || (isInConsultation && !showListing)"
            (click)="onGoBack()">
            go back
        </button>
    </div>
    <div class="col-md-6 d-flex justify-content-end" *ngIf="showListing; else otherButtons">
        <button class="button-green ml-2" (click)="onViewQuestionnaireResponses()" style="text-transform: capitalize;">
            View Questionnaire Responses
        </button>
        <button class="button-green ml-2" (click)="onSyncPlan()" style="text-transform: capitalize;">
            Load HSG Data
        </button>
        <button class="button-primary ml-2" *ngIf="isInConsultation" (click)="onCreatePlan()">
            create plan
        </button>
    </div>
    <ng-template #otherButtons>
        <div class="col-6 d-flex justify-content-end">
            <button class="button-green ml-2" *ngIf="isInConsultation" (click)="copyLastGoal()">
                copy last goal
            </button>
        </div>
    </ng-template>
</div>

<app-care-plan-card *ngIf="showListing; else careplan" title="Plan Listing">
    <app-care-plan-listing (onViewEditEmitter)="onViewEditCarePlan($event)"></app-care-plan-listing>
</app-care-plan-card>

<ng-template #careplan>
    <app-care-plan-card title="Health Goals">
        <app-health-goals [carePlanFormGroup]="carePlanFormGroup" [existingData]="selectedPlan && selectedPlan.healthGoals">
        </app-health-goals>
    </app-care-plan-card>

    <app-care-plan-card title="Vaccination Recommendation">
        <app-vaccination-recommendation [carePlanFormGroup]="carePlanFormGroup"
            [existingData]="selectedPlan && selectedPlan.plannedItemDetails && selectedPlan.plannedItemDetails.vaccination && selectedPlan.plannedItemDetails.vaccination.recordedValues || null">
        </app-vaccination-recommendation>
    </app-care-plan-card>

    <app-care-plan-card title="Exercise Recommendation">
        <app-exercise-recommendation [carePlanFormGroup]="carePlanFormGroup" [existingData]="selectedPlan && selectedPlan.healthGoals">
        </app-exercise-recommendation>
    </app-care-plan-card>

    <app-care-plan-card title="Diet Recommendation">
        <app-diet-recommendation [carePlanFormGroup]="carePlanFormGroup"
            [existingData]="selectedPlan && selectedPlan.plannedItemDetails && selectedPlan.plannedItemDetails.diet && selectedPlan.plannedItemDetails.diet.recordedValues || null">
        </app-diet-recommendation>
    </app-care-plan-card>

    <app-care-plan-card title="" class="d-block other-fields-card">
        <app-other-health-plan-fields [carePlanFormGroup]="carePlanFormGroup" [existingData]="selectedPlan"></app-other-health-plan-fields>
    </app-care-plan-card>
</ng-template>