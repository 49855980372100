import { FormGroup, FormControl } from '@angular/forms';
export class ChargeItemDescription {
  chargeCode: string;
  charge: string;
  qty?: string;
  uom?: string;
  sig: string;
  cautionary?: string;
  remarks: string;
  dosageInstruction?: string;
  itemId: string;
}

export function createChargeItemDescription() {
  return {
    chargeCode: '',
    charge: '',
    qty: '',
    uom: '',
    sig: '',
    cautionary: '',
    remarks: '',
    dosageInstruction: '',
    itemId: '',
  };
}

export class ChargeItemDescriptionFormGroup {
  static getFormGroup() {
    return new FormGroup({
      chargeCode: new FormControl(),
      charge: new FormControl(),
      qty: new FormControl(),
      uom: new FormControl(),
      sig: new FormControl(),
      cautionary: new FormControl(),
      remarks: new FormControl(),
      dosageInstruction: new FormControl(),
    });
  }
}
