import { StockTakeApproveStatus } from './StockTakeApproveStatus';
import { StoreService } from '../services/store.service';
import { NavIntegrationResult } from './CommonResponses';

export class StockAdjustmentListItem {
  itemCode: string;
  itemName: string;
  batchNumber: string;
  expiryDate: string;
  availableCount: number;
  adjustedQuantity: number;
  baseUom: string;
  requestDate: string;
  createdDate: string;
  transactionNo: string;
  remark: string;
  stockTakeId: string;
  cpRemarks: string;
  navIntegrationResult: NavIntegrationResult;

  private _status: StockTakeApproveStatus;
  get status() {
    return this._status;
  }
  set status(newStatus: StockTakeApproveStatus) {
    this._status = newStatus;
    this.updateStatusLabelAndCssClass();
  }
  statusLabel: string;
  statusCssClass: string;
  statusCssClassConstant: string = 'text-white py-2 px-2';

  _purposeOfAdjustmentCode: string;
  set purposeOfAdjustmentCode(newCode: string) {
    this._purposeOfAdjustmentCode = newCode;
    this.purposeOfAdjustmentLabel =
      (this.store.getStockAdjustmentReasonList().find(item => item.code === newCode) || { instruct: '' }).instruct ||
      '';
  }
  purposeOfAdjustmentLabel: string;

  constructor(private store: StoreService) {}

  private updateStatusLabelAndCssClass() {
    switch (this._status) {
      case StockTakeApproveStatus.NOT_APPROVED:
        this.statusLabel = 'Requested';
        this.statusCssClass = `bg-orangey-yellow ${this.statusCssClassConstant}`;
        break;
      case StockTakeApproveStatus.APPROVED:
        this.statusLabel = 'Approved';
        this.statusCssClass = `bg-bluish-green ${this.statusCssClassConstant}`;
        break;
      case StockTakeApproveStatus.REJECTED:
        this.statusLabel = 'Rejected';
        this.statusCssClass = `bg-lipstick ${this.statusCssClassConstant}`;
        break;
      case StockTakeApproveStatus.FAILED:
        this.statusLabel = 'Failed';
        this.statusCssClass = `bg-black ${this.statusCssClassConstant}`;
        break;
      default:
        this.statusLabel = '';
        this.statusCssClass = '';
    }
  }
}
