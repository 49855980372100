  import { AkitaPatientAppQuery } from './../../../services/states/akita-patient/akita-patient-app.query';
import { Subject, Observable } from 'rxjs';
import { PatientNoteAdd } from './../../../objects/request/PatientNoteAdd';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  map,
  filter,
  tap,
} from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DISPLAY_DATE_FORMAT,
  DB_FULL_DATE_FORMAT,
  PROBLEM_LIST_STATUS,
} from './../../../constants/app.constants';
import { AlertService } from './../../../services/alert.service';
import { StoreService } from './../../../services/store.service';
import { ApiCmsManagementService } from './../../../services/api-cms-management.service';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
// import { PatientNote } from '../../../objects/response/PatientNote';
import * as moment from 'moment';
import DatePickerConfig from '../../../objects/DatePickerConfig';
import { VisitManagementService } from '../../../services/visit-management.service';
import { KeyValue } from '@angular/common';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-consultation-problem-list',
  templateUrl: './consultation-problem-list.component.html',
  styleUrls: ['./consultation-problem-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultationProblemListComponent implements OnInit, OnDestroy {
  @Input() needRefresh: Subject<boolean>;
  patientNotesForm: FormGroup;

  patientNotes: Array<PatientNoteAdd>;
  patientNotesGrouped;
  patientNotesGroupSubject: Subject<any>;
  patientNotesGrouped$: Observable<any>;
  processedPatientNotes;

  objectKeys = Object.keys;
  types = [
    { value: 'NOTE', label: 'NOTE' },
    { value: 'SHORT_TERM', label: 'PROBLEM SHORT TERM' },
    { value: 'LONG_TERM', label: 'PROBLEM LONG TERM' },
  ];

  statusFilter = PROBLEM_LIST_STATUS;

  filterStartMaxDate: Date = new Date();
  filterEndMaxDate: Date = new Date();

  datePickerConfigArray: Array<DatePickerConfig>;

  keyOrderDesc = (
    a: KeyValue<string, Array<any>>,
    b: KeyValue<string, Array<any>>
  ): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private alertService: AlertService,
    private visitManagementService: VisitManagementService,
    private apiCmsManagementService: ApiCmsManagementService,
    private store: StoreService,
    private alert: AlertService,
    private fb: FormBuilder,
    private akitaPatientAppQuery: AkitaPatientAppQuery
  ) {
    this.patientNotes = [];
    this.patientNotesGroupSubject = new Subject();
    this.patientNotesGrouped$ = this.patientNotesGroupSubject.pipe(
      map(array => {
        if (
          !this.processedPatientNotes ||
          array !== this.processedPatientNotes
        ) {
          array.sort(this.compareValues('addedDateTime', 'desc'));
          this.processedPatientNotes = array;
        }

        const updated = {};
        this.processedPatientNotes.forEach(each => {
          const time = moment(each.addedDateTime, DISPLAY_DATE_FORMAT)
            .year()
            .toString();
          if (!(time in updated)) updated[time] = [];
          updated[time].push(each);
        });

        return updated;
      }),
      map(object => {
        return this.dataFilter(
          object,
          this.patientNotesForm.get('searchKeyword').value,
          this.patientNotesForm.get('filterStart').value,
          this.patientNotesForm.get('filterEnd').value,
          this.patientNotesForm.get('filterStatus').value
        );
      })
    );
  }

  ngOnInit() {

    this.patientNotesForm = this.visitManagementService.getPatientNotesFormGroup();
    this.patientNotesForm.get('filterStatus').patchValue('ACTIVE');
    this.getPatientNotes();
    this.subscribeOnChange();
    this.initDatePickerConfigsForComponent();
  }

  ngOnDestroy(): void {
    if (this.patientNotesForm)
      this.patientNotesForm.get('note').patchValue('', { emitEvent: false });

    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  initDatePickerConfigsForComponent() {
    this.datePickerConfigArray = new Array<DatePickerConfig>();

    const datepickerFrom: DatePickerConfig = this.initDatePickerConfigObject(
      '',
      this.filterStartMaxDate,
      null,
      'bottom',
      'left'
    );
    const datepickerTo: DatePickerConfig = this.initDatePickerConfigObject(
      '',
      this.filterEndMaxDate,
      null,
      'bottom',
      'left'
    );
    const datepickerOnset: DatePickerConfig = this.initDatePickerConfigObject(
      'Date of Onset/Note',
      '',
      null,
      'top',
      'top'
    );

    this.datePickerConfigArray['from'] = datepickerFrom;
    this.datePickerConfigArray['to'] = datepickerTo;
    this.datePickerConfigArray['onset'] = datepickerOnset;
  }

  initDatePickerConfigObject(
    label,
    maxDate,
    minDate,
    datePickerPlacement,
    labelPlacement
  ) {
    return new DatePickerConfig(
      label,
      maxDate,
      minDate,
      datePickerPlacement,
      labelPlacement
    );
  }

  buttonDisable(){
  if (this.patientNotesForm.get('type').value === 'NOTE' ){
    this.patientNotesForm.get('date').disable();
  }else {
    this.patientNotesForm.get('date').enable();
  }
}

  getPatientNotes() {
    this.patientNotes = [];
    this.apiCmsManagementService
      .listPatientNotes(this.akitaPatientAppQuery.getId())
      .subscribe(
        res => {
          if (res.payload) {
            this.patientNotesForm.get('filterStart').patchValue(
              moment()
                .subtract(10, 'year')
                .toDate(),
              { emitEvent: false }
            );
            this.patientNotesForm
              .get('filterEnd')
              .patchValue(moment().toDate(), { emitEvent: false });

            const { noteDetails } = res.payload;
            this.patientNotes = noteDetails;
            this.patientNotesGroupSubject.next(this.patientNotes);
          }
          // else {
          //   this.patientNotes = [];
          //   this.patientNotesGroupSubject.next(this.patientNotes);
          // }
        },
        err => this.alert.error(err)
      );
  }

  subscribeOnChange() {
    this.patientNotesForm.controls['searchKeyword'].valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(item => {
        this.patientNotesGroupSubject.next(this.processedPatientNotes);
      });

    this.patientNotesForm.controls['filterStart'].valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        this.patientNotesGroupSubject.next(this.processedPatientNotes);
      });

    this.patientNotesForm.controls['filterEnd'].valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        this.filterStartMaxDate = moment(value, DISPLAY_DATE_FORMAT).toDate();
        this.patientNotesGroupSubject.next(this.processedPatientNotes);
      });

    this.patientNotesForm.controls['filterStatus'].valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(value => {
        this.patientNotesGroupSubject.next(this.processedPatientNotes);
      });

    this.patientNotesForm.controls['note'].valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(item => {
        if (item) {
          this.patientNotesForm.get('type').markAsTouched();
          this.patientNotesForm.get('type').updateValueAndValidity();
          this.patientNotesForm.get('date').markAsTouched();
          this.patientNotesForm.get('date').updateValueAndValidity();
        } else {
          this.patientNotesForm.get('note').markAsUntouched();
          this.patientNotesForm.get('note').updateValueAndValidity();
          this.patientNotesForm.get('type').markAsUntouched();
          this.patientNotesForm.get('type').updateValueAndValidity();
          this.patientNotesForm.get('date').markAsUntouched();
          this.patientNotesForm.get('date').updateValueAndValidity();
        }
      });

    this.store
      .getPatientVisitIdRefresh()
      .pipe(takeUntil(this.componentDestroyed), distinctUntilChanged())
      .subscribe(id => {
        this.patientNotesForm.get('note').patchValue('', { emitEvent: false });
      });

    this.store
      .getPatientIdRefresh()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(id => {
        if (id) {
          this.getPatientNotes();
        }
      });
  }

  dataFilter(element, keyword, startDate, endDate, status) {
    startDate = moment(startDate, DISPLAY_DATE_FORMAT).startOf('day');
    endDate = moment(endDate, DISPLAY_DATE_FORMAT).startOf('day');

    Object.keys(element).forEach(key => {
      element[key] = element[key].filter(each => {
        const addedDate = moment(
          each.addedDateTime,
          DB_FULL_DATE_FORMAT
        ).startOf('day');

        const validDate =
          addedDate.isSameOrAfter(startDate) &&
          addedDate.isSameOrBefore(endDate);
        const validStatus = status === each.status || status === 'ALL';
        const validKeyword = keyword
          ? each.note.toLowerCase().includes(keyword.toLowerCase())
          : true;

        return validDate && validStatus && validKeyword;
      });

      if (element[key].length < 1) delete element[key];
    });

    return element;
  }

  filterData() {
    this.patientNotesGroupSubject.next(this.processedPatientNotes);
  }

  sortDataDescending() {
    this.patientNotes.sort((a, b) => {
      if (a && b) {
        const firstDate = moment(a.addedDateTime, DB_FULL_DATE_FORMAT);
        const secondDate = moment(b.addedDateTime, DB_FULL_DATE_FORMAT);

        if (firstDate.isAfter(secondDate)) return -1;
        if (firstDate.isBefore(secondDate)) return 1;
        return 0;
      }
    });
  }

  // function for dynamic sorting
  compareValues(key, order = 'asc') {
    if (key === 'addedDateTime') this.sortDataDescending();
    else {
      return function(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;

        let varA, varB;
        varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
        varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) comparison = 1;
        else if (varA < varB) comparison = -1;
        return order === 'desc' ? comparison * -1 : comparison;
      };
    }
  }

  groupDataByYear(objectArray) {
    const groupedData = objectArray.reduce((acc, obj) => {
      const year = moment(obj.addedDateTime, DB_FULL_DATE_FORMAT).year();

      if (!acc[year]) acc[year] = [];
      acc[year].push(obj);
      return acc;
    }, {});
    const a = Object.keys(groupedData).map(key => ({
      key,
      value: groupedData[key],
    }));
    return a;
  }

  onAddNote() {
    const note = new PatientNoteAdd(
      this.patientNotesForm.get('note').value,
      this.store.getUser().context['cms-user-id'] ,
      this.store.getClinic().clinicCode,
      moment().format(DB_FULL_DATE_FORMAT),
      this.patientNotesForm.get('type').value,
      this.patientNotesForm.get('date').value
        ? moment(this.patientNotesForm.get('date').value).format(
            DB_FULL_DATE_FORMAT
          )
        : moment().format(DB_FULL_DATE_FORMAT)
    );
    if (note) {
      this.apiCmsManagementService
        .addPatientNote(this.akitaPatientAppQuery.getId(), note)
        .subscribe(
          res => {
            this.patientNotesForm.get('note').patchValue('');
            this.patientNotesForm.get('note').markAsUntouched();
            this.patientNotesForm.get('note').updateValueAndValidity();
            this.patientNotesForm.get('type').patchValue('');
            this.patientNotesForm.get('type').markAsUntouched();
            this.patientNotesForm.get('type').updateValueAndValidity();
            this.patientNotesForm.get('date').patchValue('');
            this.patientNotesForm.get('date').markAsUntouched();
            this.patientNotesForm.get('date').updateValueAndValidity();
            if (res.payload && res.payload['noteDetails']) {
              this.patientNotes = res.payload['noteDetails'];
              this.patientNotesGroupSubject.next(this.patientNotes);
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err));
          }
        );
    }
  }
}
