import { FormBuilder, FormArray } from '@angular/forms';
import { map } from 'rxjs/operators';
import { PatientCoverage } from './../../../../objects/MedicalCoverage';

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PatientMedicalCoverageStore } from './patient-medical-coverage.store';

@Injectable({ providedIn: 'root' })
export class PatientMedicalCoverageQuery extends QueryEntity<PatientCoverage> {
  medicalCovToFG$ = this.selectAll().pipe(
    map(res => {
   
      let coverages: FormArray = this.fb.array([]);
      res.forEach(coverage => {
        coverages.push(this.fb.group(coverage));
      });
      return coverages;
    })
  );

  constructor(protected store: PatientMedicalCoverageStore, private fb: FormBuilder) {
    super(store);
  }
}
