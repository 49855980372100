import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ServiceCalendar } from '../../../../objects/ServiceCalendar';

export interface ServiceCalendarState extends EntityState<ServiceCalendar> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ServiceCalendar', idKey: 'service' })
export class ServiceCalendarStore extends EntityStore<ServiceCalendarState> {
  constructor() {
    super();
  }
}
