export class Company {
  name: string;
  address: string;
  postalCode: string;
  occupation: string;
  constructor(
    name?: string,
    address?: string,
    postalCode?: string,
    occupation?: string
  ) {
    this.name = name || '';
    this.address = address || '';
    this.postalCode = postalCode || '';
    this.occupation = occupation || '';
  }
}
