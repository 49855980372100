import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { AkitaRemoteDeliveryOrderStatusStore } from './akita-remote-delivery-order-status.store';
import { RemoteDeliveryOrderStatus } from '../../model/RemoteDelivery';

@Injectable({ providedIn: 'root' })
export class AkitaRemoteDeliveryOrderStatusQuery extends QueryEntity<RemoteDeliveryOrderStatus> {

  constructor(protected store: AkitaRemoteDeliveryOrderStatusStore) {
    super(store);
  }

  public getStatusByCode(code: string): RemoteDeliveryOrderStatus {
    const status = this.getAll().find((item: RemoteDeliveryOrderStatus) => item.code === code);
    return status as RemoteDeliveryOrderStatus;
  }
}