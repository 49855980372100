import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-patient-hsg-plan-delete',
  templateUrl: './patient-hsg-plan-delete.component.html',
  styleUrls: ['./patient-hsg-plan-delete.component.scss'],
})
export class PatientHsgPlanDeleteComponent implements OnInit {
  title: string;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef,
  ) {}

  ngOnInit() {
    
  }

  cancelModal() {
    this.bsModalRef.hide();
  }

  deletePlan() {
    this.event.emit(true);
  }
}
