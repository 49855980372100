import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
@Injectable()
export class PermissionsGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    private permissionService: NgxPermissionsService,
    public router: Router
  ) {}
  canActivate(): boolean {
    if (!this.auth.isPermissionsLoaded() && !localStorage.getItem('roles')) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
