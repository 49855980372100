import { Injectable } from '@angular/core';
import {
  EntityStore,
  StoreConfig,
  EntityState,
  ActiveState,
} from '@datorama/akita';
import { ICdlensVisitCase } from '../../objects/response/cdlens/CdlensVisitCase';

export interface AkitaCdlensVisitState
  extends EntityState<ICdlensVisitCase>,
  ActiveState { }

const initialState = {
  active: null,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cdlensvisit', idKey: 'visitId', resettable: true })
export class AkitaCdlensVisitStore extends EntityStore<
AkitaCdlensVisitState,
ICdlensVisitCase
> {
  constructor() {
    super(initialState);
  }

  // updateEntry(registryEntity: Partial<RegistryEntity>) {
  //   this.update(
  //     registryEntity.visitId, entity => {
  //       return {
  //         ...entity,
  //         registryEntity
  //       }
  //     }
  //   )

  //   this.setActive(registryEntity.visitId);
  // }
}
