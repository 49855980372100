import { BsModalService } from 'ngx-bootstrap';

import { INPUT_DELAY } from './../constants/app.constants';
import { debounceTime } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ApiPatientVisitService } from './api-patient-visit.service';
import { DialogService } from './dialog.service';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { PaymentService } from './payment.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment'
import RefreshSetting from '../objects/RefreshSetting';
import { MemoObject } from '../objects/state/Case';

@Injectable({
  providedIn: 'root',
})
export class VisitManagementService {
  private queueRefresh = new Subject();
  private reloadConsultationInfo: Subject<string> = new Subject<string>();
  private resetChargeItemPriceAdjustment: Subject<void> = new Subject<void>();
  private saveDispensing = new Subject<RefreshSetting>();
  private savedSuccessfully: Subject<void> = new Subject();
  private toPrint: Subject<boolean> = new Subject();
  private updatePrice = new Subject();
  private isRollbacked = new Subject<any>();
  private rollbackFromPayment = new Subject<any>();
  private modalShown: boolean = false;
  private unlockIndexRefresh = new Subject();
  private fullLayoutLoadingShown = new Subject<boolean>();
  private updateNPrintMemo = new Subject<Array<MemoObject>>();
  private patientNotesFormGroup: FormGroup;
  private isDentalDirty: Subject<boolean> = new Subject();
  private dentalSub: Subject<any> = new Subject();
  private updateItemDetails: Subject<any> = new Subject<any>();
  isDiscarded: boolean;

  private isEditPatientClicked: Subject<boolean> = new Subject<boolean>();
  private isPatientChanged: Subject<boolean> = new Subject<boolean>();
  private isSavePatientClicked: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialogService: DialogService,
    private apiPatientVisitService: ApiPatientVisitService,
    private alertService: AlertService,
    private paymentService: PaymentService,
    private fb: FormBuilder,
  ) {
    this.patientNotesFormGroup = this.fb.group({
      filterStart: moment()
        .subtract(2, 'year')
        .toDate(),
      filterEnd: moment().toDate(),
      filterStatus: ['ACTIVE'],
      note: [''],
      type: [''],
      date: [''],
      searchKeyword: '',
    });
  }

  public setPatientEditClicked(status: boolean): void {
    this.isEditPatientClicked.next(status);
  }

  public getPatientEditClicked(): Observable<boolean> {
    return this.isEditPatientClicked.asObservable();
  }

  public setPatientChanged(status: boolean): void {
    this.isPatientChanged.next(status);
  }

  public getPatientChanged(): Observable<boolean> {
    return this.isPatientChanged.asObservable();
  }

  public setPatientSaveClicked(): void {
    this.isSavePatientClicked.next(true);
  }

  public getPatientSaveClicked(): Observable<boolean> {
    return this.isSavePatientClicked.asObservable();
  }

  getQueueRefresh() {
    return this.queueRefresh.asObservable();
  }

  getSaveDispensing() {
    return this.saveDispensing.asObservable();
  }

  getSavedSuccessfully() {
    return this.savedSuccessfully.asObservable();
  }

  getUpdatePrice() {
    return this.updatePrice.asObservable();
  }

  getIsRollBacked() {
    return this.isRollbacked;
  }

  getRollbackFromPayment() {
    return this.rollbackFromPayment;
  }

  getUnlockIndexRefresh() {
    return this.unlockIndexRefresh;
  }

  getReloadConsultationInfo() {
    return this.reloadConsultationInfo.asObservable();
  }

  getResetChargeItemPriceAdjustment() {
    return this.resetChargeItemPriceAdjustment.asObservable();
  }

  getUpdateItemDetails() {
    return this.updateItemDetails.asObservable();
  }

  getUpdateNPrintMemo() {
    return this.updateNPrintMemo.asObservable();
  }

  setSavedSuccessfully() {
    this.savedSuccessfully.next();
  }

  subscribeModalChanges(modalService: BsModalService, args?: any) {
    const showModal = () => this.setModalShown(true);
    const hideModal = () => this.setModalShown(false);
    modalService.onShow.subscribe(() => showModal());
    modalService.onShown.subscribe(() => showModal());
    modalService.onHide.subscribe(() => hideModal());
    modalService.onHidden.subscribe(() => hideModal());
  }

  setModalShown(show: boolean) {
    this.modalShown = show;
  }

  getModalShown() {
    return this.modalShown;
  }

  setQueueRefresh() {
    this.queueRefresh.next(true);
  }
  setSaveDispensing(refreshSetting: RefreshSetting) {
    this.saveDispensing.next(refreshSetting);
  }

  setUpdatePrice() {
    this.updatePrice.next(true);
  }

  setUnlockIndexRefresh(index) {
    this.unlockIndexRefresh.next(index);
  }

  setReloadConsultationInfo(str?: string) {
    this.reloadConsultationInfo.next(str || '');
  }

  setResetChargeItemPriceAdjustment() {
    this.resetChargeItemPriceAdjustment.next();
  }

  setUpdateItemDetails(item) {
    this.updateItemDetails.next(item);
  }

  setUpdateNPrintMemo(memoValue: Array<MemoObject>) {
    this.updateNPrintMemo.next(memoValue);
  }

  setToPrint() {

    this.toPrint.next(true);
  }

  getToPrint() {
    return this.toPrint.asObservable();
  }

  onBtnBackClicked(patientVisitRegistry) {
    const confirm = this.dialogService.confirm(
      'Your changes will be rolled back. \n\nAre you sure want to leave this page?'
    );

    confirm.subscribe(isConfirm => {
      if (isConfirm) {
        this.apiPatientVisitService
          .paymentRollback(patientVisitRegistry)
          .pipe(debounceTime(INPUT_DELAY))
          .subscribe(
            res => {
              this.paymentService.resetChargeFormGroup();
              this.paymentService.resetCollectFormGroup();
              this.isRollbacked.next(true);
              if (res.payload && res.payload.medicalReferenceEntity && res.payload.medicalReferenceEntity.consultation) {
                this.rollbackFromPayment.next(res.payload.medicalReferenceEntity.consultation.doctorId);
              }
            },
            err => this.alertService.error(JSON.stringify(err.error.message))
          );
      } else {
        this.isRollbacked.next(false);
      }
    });
  }

  updateChargeBreakdownPrice() { }

  showFullLayoutLoading() {
    this.fullLayoutLoadingShown.next(true);
  }

  hideFullLayoutLoading() {
    this.fullLayoutLoadingShown.next(false);
  }

  getFullLayoutLoadingShown() {
    return this.fullLayoutLoadingShown;
  }

  getPatientNotesFormGroup() {
    return this.patientNotesFormGroup;
  }

  getIsDentalDirty() {
    return this.isDentalDirty.asObservable();
  }

  setIsDentalDirty(isDirty: boolean) {
    this.isDentalDirty.next(isDirty);
  }

  getDentalChanges() {
    return this.dentalSub.asObservable();
  }

  callDentalChanges() {
    this.dentalSub.next();
  }

  getDentalChangesInConsult() {
    return this.dentalSub.asObservable();
  }
}