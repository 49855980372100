import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { AkitaAppQuery } from '../../../services/states/akita-app.query';

@Component({
  selector: 'app-rich-editor-switcher',
  templateUrl: './rich-editor-switcher.component.html',
  styleUrls: ['./rich-editor-switcher.component.scss']
})
export class RichEditorSwitcherComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() disabled = false;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(private akitaAppQuery: AkitaAppQuery) 
  { }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  ngOnInit() { }

  getEditor() {
    return this.akitaAppQuery.checkClinicFeatureExist(
      'QUILL_EDITOR'
    );
  }
}