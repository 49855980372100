import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryDisplayDOStatus'
})
export class InventoryDisplayDOStatusPipe implements PipeTransform {

  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {
    if (!value.status) {
      value.status = ''
    }

    return this.inventory.getDOStatus(value.status);
  }

}
