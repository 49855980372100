export enum DOCTOR_GROUP {
  ANCHOR = 'ANCHOR',
  FLOATING = 'FLOATING',
  LOCUM = 'LOCUM',
}

export enum STATUS {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  INACTIVE = 'INACTIVE',
}

export interface Doctor {
  displayName: string;
  doctorGroup: DOCTOR_GROUP;
  education: string;
  id: string;
  mcr: string;
  name: string;
  nric: string;
  speciality: string;
  status: STATUS;
  username: string;
  doctorClinicToggles: {[key: string]: boolean};
  externalAppData: DoctorExternalAppData;
}


export class DoctorExternalAppData {
  availableForApp: boolean;
}

export function createDoctor({
  displayName = '',
  doctorGroup = '',
  education = '',
  id = '',
  mcr = '',
  name = '',
  nric = '',
  speciality = '',
  status = '',
  username = '',
  doctorClinicToggles = {},
  externalAppData = new DoctorExternalAppData(),
} = {}) {
  return {
    displayName,
    doctorGroup: DOCTOR_GROUP[doctorGroup],
    education,
    id,
    mcr,
    name,
    nric,
    speciality,
    status: STATUS[status],
    username,
    doctorClinicToggles,
    externalAppData,
  } as Doctor;
}
