import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { AkitaCarePlanStore } from './akita-care-plan.store';

import { ICarePlan } from '../../../model/care-plan/CarePlan';

@Injectable({ providedIn: 'root' })
export class AkitaCarePlanQuery extends QueryEntity<ICarePlan> {

    constructor(protected store: AkitaCarePlanStore) {
        super(store);
    }

    public isDraftAvailable(): boolean {
        const draftFound = this.getAll().find((item: ICarePlan) => item.healthPlanStatus === 'DRAFT')

        return draftFound ? true : false;
    }

    public getDraft(): ICarePlan | null {
        const draftFound = this.getAll().find((item: ICarePlan) => item.healthPlanStatus === 'DRAFT') as ICarePlan;

        return draftFound ? draftFound : null;
    }

    public getLastGoal(): ICarePlan | null {
        const submittedGoals = this.getAll().filter((item: ICarePlan) => item.healthPlanStatus === 'SUBMIT');

        if (submittedGoals.length) {
            return submittedGoals[submittedGoals.length - 1] as ICarePlan;
        } else {
            return null;
        }
    }
}