import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { akitaConfig } from '@datorama/akita';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import './scss/style.scss';

akitaConfig({
  resettable: true,
});

if (environment.production) {
  enableProdMode();
  // Disable console log
  if (window) {
    window.console.log = window.console.count = function () { };
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
