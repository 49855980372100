import { AlertService } from './../../../../services/alert.service';
import { AkitaPatientStoreService } from './../../../../services/states/akita-patient/akita-patient-store.service';
import { ApiPatientInfoService } from './../../../../services/api-patient-info.service';
import { AkitaPatientAppQuery } from './../../../../services/states/akita-patient/akita-patient-app.query';
import { mergeMap, map } from 'rxjs/operators';
import { AllergiesFormService } from './../../../../services/allergies-form.service';
import { PatientService } from './../../../../services/patient.service';
import { Component, OnInit, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilsService } from '../../../../services/utils.service';
import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';
import { StoreService } from '../../../../services/store.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-patient-add-alerts-info-add-clinical',
  templateUrl: './patient-add-alerts-info-add-clinical.component.html',
  styleUrls: ['./patient-add-alerts-info-add-clinical.component.scss'],
})
export class PatientAddAlertsInfoAddClinicalComponent implements OnInit, OnDestroy {
   @Input() medicalAlertFormGroup: FormGroup;
  medicalAlertFormGroupOriginal: FormGroup;
  originalAlertArray: any;
  alertArray: FormArray;

  trashArray: FormArray;
  inActivateArray: FormArray;
  title: string;  
  alertCodes:any = [];

  isNewPatient;
   
  doctors: any[];

  private allergyModalStatusSub: Subscription;
  private saveAllertAndAllergySub: Subscription;

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private allergiesFS: AllergiesFormService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private apiPatientInfoService: ApiPatientInfoService,
    private akitaPatientStore: AkitaPatientStoreService,
    private alertService: AlertService,    
    private apiCmsManagementService: ApiCmsManagementService,    
    private store: StoreService,
  ) {}

  ngOnInit() {
    this.store.listDoctorsByClinic();
    this.loadEnumValues();
    this.doctors = this.store.doctorListByClinic;
    this.medicalAlertFormGroupOriginal = Object.assign(
      {},
      this.medicalAlertFormGroup
    ) as FormGroup;
    this.originalAlertArray = this.medicalAlertFormGroupOriginal.value.alertArray;
    this.alertArray = (<FormArray>(
      this.medicalAlertFormGroup.get('alertArray')
    )) as FormArray;
    this.trashArray = this.fb.array([]);
    this.inActivateArray = this.fb.array([]);

    

    this.allergyModalStatusSub = this.allergiesFS.getManageAllergyModalStatus().subscribe({
      next: result => {
        this.onBtnExit();
      }
    })

    this.saveAllertAndAllergySub = this.allergiesFS.getSaveAlertsAndAllergies().subscribe({
      next: result => {
        this.onBtnSave();
      }
    })
  }

  loadEnumValues() {
    this.apiCmsManagementService.listAllergyandADRAlertMappings().subscribe(
      	data => {
      		if (data.payload) {
            this.alertCodes= data.payload.values.find(item => item.name === 'MEDICAL_ALERT_CODES').values;
      		}
      	},
      	err => {
      		this.alertService.error(JSON.stringify(err.error.message));
      	}
      );
  }

  onDelete(form: FormGroup, index: number) {
    form.patchValue({
      isDelete: true,
      deleteIndex: index,
      requiredSave: true,
    });
    const formArray = form.parent as FormArray;
    formArray.removeAt(index);

    let id = form.get('alertId');

    if (
      id &&
      (id.value !== null || id.value !== '' || id.value !== undefined)
    ) {
      this.medicalAlertFormGroup.get('trashArray').value.push(id.value);
    }
  }

  onInActivate(form: FormGroup, index: number) {
    let id = form.get('alertId');

    if (id && (id.value !== null || id.value !== '' || id.value !== undefined)) {
      this.medicalAlertFormGroup.get('inActivateArray').value.push(id.value);
    }
  }

  onBtnAdd() {

    if (!this.medicalAlertFormGroup.valid) {
      this.medicalAlertFormGroup.markAllAsTouched();
      this.medicalAlertFormGroup.updateValueAndValidity();
      return;
  }
    const allert = this.allergiesFS.addMedicalAlert();
    const reporter = allert.get('reporter');

    const loggedInDoctor = this.doctors.find(doc => doc.id === this.store.getUser().context['cms-user-id']);
      if (loggedInDoctor) {
        reporter.get('profession').patchValue('DOCTOR');
        reporter.get('regNo').patchValue(loggedInDoctor.mcr);
        reporter.get('name').patchValue(loggedInDoctor.displayName);
      } else {
        reporter.get('profession').patchValue('NURSE');
      }
    this.alertArray.push(allert);
  }

  onBtnSave() {
    if (!this.isNewPatient) {
      this.updateMedicalAlerts();
    }
    this.bsModalRef.hide();
  }

  formatMedicalAlertArrayItem(medicalAlert) {
    const formattedMedicalItem = {
      alertType: medicalAlert.type,
      name: medicalAlert.name,
      remark: medicalAlert.remark,
      priority: medicalAlert.priority,
      addedDate: medicalAlert.addedDate,
      expiryDate: medicalAlert.expiryDate,
      alertCode: medicalAlert.alertCode,
      alertStatus: medicalAlert.alertStatus,
      reporter: medicalAlert.reporter,
      startDate: medicalAlert.startDate,
      updateReason: medicalAlert.updateReason,

    };

    return formattedMedicalItem;
  }

  updateMedicalAlerts() {
    const addedMedicalAlerts = [];

    // // Add Medical Alerts ++
    this.alertArray.value.map(medicalAlert => {
      if (
        medicalAlert.alertId === '' ||
        medicalAlert.alertId === undefined ||
        medicalAlert.alertId.length === 0
      ) {
        addedMedicalAlerts.push(this.formatMedicalAlertArrayItem(medicalAlert));
      }
    });

    // Delete Medical Alerts --
    const deleteArray = this.medicalAlertFormGroup.get('trashArray').value;
    const inActivateArray = this.medicalAlertFormGroup.get('inActivateArray').value;

    // Observables
    const deleteAlert$ = this.apiPatientInfoService.deleteAlert(deleteArray);
    const inActivateAlert$ = this.apiPatientInfoService.inActivateAlert(inActivateArray);
    const addAlert$ = this.apiPatientInfoService.addAlert(
      this.akitaPatientAppQuery.getId(),
      addedMedicalAlerts
    );

    if (!this.isEmpty(inActivateArray)) {
      inActivateAlert$.subscribe(
        value => {
          this.medicalAlertFormGroup.get('inActivateArray').patchValue([]);
          this.akitaPatientStore.getAlerts();
        },
        err => {
          this.printError(err);
        }
      );
    }

    if (!this.isEmpty(deleteArray) && this.isEmpty(addedMedicalAlerts)) {
      deleteAlert$.subscribe(
        value => {
          this.akitaPatientStore.getAlerts();
        },
        err => {
          this.printError(err);
        }
      );
    } else if (this.isEmpty(deleteArray) && !this.isEmpty(addedMedicalAlerts)) {
      addAlert$.subscribe(
        value => {
          this.akitaPatientStore.getAlerts();
        },
        err => {
          this.printError(err);
        }
      );
    } else if (
      !this.isEmpty(deleteArray) &&
      !this.isEmpty(addedMedicalAlerts)
    ) {
      addAlert$
        .pipe(
          map(res => res),
          mergeMap(value => deleteAlert$)
        )
        .subscribe(
          resp => {
            this.akitaPatientStore.getAlerts();
          },
          err => {}
        );
    }
  }

  isEmpty(arr) {
    return arr.length === 0;
  }

  printError(err) {
    this.alertService.error(JSON.stringify(err.error.message));
  }

  onBtnExit() {
    this.alertArray.value.forEach((allergy, index) => {
      if (
        allergy.name === '' ||
        allergy.type === '' ||
        allergy.priority === ''
      ) {
        this.alertArray.removeAt(index);
      }
    });
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.allergyModalStatusSub.unsubscribe();
    this.saveAllertAndAllergySub.unsubscribe();
  }
}
