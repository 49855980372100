import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

import { PatientHistoryDetailEditCertificateItemComponent } from './patient-history-detail-edit-certificate-item.component';
import { DISPLAY_DATE_FORMAT } from '../../../../constants/app.constants';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-patient-history-detail-edit-certificate',
  templateUrl: './patient-history-detail-edit-certificate.component.html',
  styleUrls: ['./patient-history-detail-edit-certificate.component.scss'],
})
export class PatientHistoryDetailEditCertificateComponent implements OnInit {
  @Input() certificateArray: FormArray;
  title: string;
  inModal: boolean;
  startDate : string;
  public event: EventEmitter<any> = new EventEmitter<any>();
  // removedCertificates: any [] = [];
  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private permissionsService: NgxPermissionsService
  ) { }

  ngOnInit() {}

  addItem() {
    this.certificateArray.push(
      PatientHistoryDetailEditCertificateItemComponent.buildItem(
        '',
        moment(new Date()).format(DISPLAY_DATE_FORMAT),
        moment(new Date()).format(DISPLAY_DATE_FORMAT),
        1,
        '',
        '',
        '',
        ''
      )
    );
  }

  nullOrEmptyOrUndefined(value) {
    return value === null || value === '' || value === undefined;
  }

  itemIsValid(item) {
    if (this.nullOrEmptyOrUndefined(item.purpose)) {
      return false;
    } else {
      return true;
    }
  }

  // onRemove(cert) {
  //   this.removedCertificates.push(cert.value.referenceNumber);
  // }

  removeItem(index) {
    this.certificateArray.removeAt(index);
  }

  cleanMCData() {
    const certificateArrayValues = this.certificateArray.value;

    certificateArrayValues.map((mc, index) => {
      if (this.itemIsValid(mc)) {
      } else {
        this.removeItem(index);
      }
    });
  }

  onBtnExit() {
    this.cleanMCData();
    this.event.emit({ key: 'Close', value: null });
  }

  onBtnSaveClicked() {
    this.event.emit({
      key: 'MEDICAL_CERTIFICATES',
      value: this.certificateArray
     });   // Memo is saved under CONSULTATION
  }

  isDoctor() {
    return this.permissionsService.getPermission('ROLE_DOCTOR');
  }
}
