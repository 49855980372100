import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpResponseBody } from './../objects/response/HttpResponseBody';
import { AppConfigService } from './app-config.service';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root',
})
export class ApiAssessmentManagementService {
  private API_ASSESSMENT_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private backendService: BackendService
  ) {
    this.API_ASSESSMENT_URL = this.appConfig.getConfig().API_ASSESSMENT_URL;
  }

  listAllAssessment(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_ASSESSMENT_URL}/master/get`;
    const body = {};
    const options = {  };

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  addAssessment(
    patientId: string,
    visitId: string,
    takenTime: string,
    assessments: any,
    comment: string
  ) {
    return this.http.post<HttpResponseBody>(
      `${this.API_ASSESSMENT_URL}/add`,
      {}
    );
  }
}
