import { Case, CaseCoveragePlan } from './../../../objects/state/Case';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  Validators,
} from '@angular/forms';


import * as moment from 'moment';
import { forkJoin, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  takeUntil,
} from 'rxjs/operators';

import { AkitaAppQuery } from '../../../services/states/akita-app.query';
import { ApiCaseManagerService } from '../../../services/api-case-manager.service';
import { AkitaCaseVisitQuery } from '../../../services/states/akita-case-visit.query';
import { AkitaMedicalCoverageQuery } from '../../../services/states/akita-medical-coverage.query';
import { CaseManagerService } from '../../../views/components/case-manager/case-manager.service';
import { CoverageLimitValidator } from '../../../validators/CoverageLimitValidator';
import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../constants/app.constants';
import { PaymentAmountValidator } from '../../../validators/PaymentAmountValidator';
import { PaymentService } from '../../../services/payment.service';
import { CashAmountValidator } from '../../../validators/CashAmountValidator';
import { PAYMENT_MODES_ALL } from '../../../constants/payment-mode';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-payment-collect-method',
  templateUrl: './payment-collect-method.component.html',
  styleUrls: ['./payment-collect-method.component.scss'],
})
export class PaymentCollectMethodComponent implements OnInit, OnDestroy {
  @Input() payButtonStatus: boolean;
  @Input() inPopup = false;
  @Input() payNCloseButtonStatus: boolean;
  @Input() onlineDrugDeliveryMode = false;
  @Output() submitPayment: EventEmitter<any> = new EventEmitter();

  draftMinInvoices;
  draftMaxInvoices;
  outstandingAmount$ = of({
    cashAdjustedTotalAmount: 0,
    categorised: false,
    changeAmount: 0,
    subTotal: 0,
    totalAmount: 0,
    totalTaxableAmount: 0,
  });

  activeVisit$ = this.akitaCaseVisitQuery
    .selectActiveId()
    .pipe(map(id => !!id && id.toString().length > 0));
  visitAmount$ = of(0.0);

  multiVisit$ = this.akitaAppQuery.select(entity =>
    entity.clinic.clinicFeatures.includes('MULTI_VISIT')
  );
  package$ = this.akitaCaseVisitQuery.selectPackageCase();
  planUsageUpdatable$ = this.akitaAppQuery.select(entity =>
    entity.clinic.clinicFeatures.includes('PLAN_USAGE_UPDATABLE')
  );

  caseLevel = false;
  paymentMethodSelections = PAYMENT_MODES_ALL;

  babyBonusSelections = [
    { value: 'BB OCBC', label: 'BB OCBC' },
    { value: 'BB UOB', label: 'BB UOB' },
    { value: 'BB POSB', label: 'BB POSB' },

  ];
  paymentFormArray = new FormArray([]);
  runningPayment = false;
  paymentInfo: any = {};
netsFlag:boolean = false;
  private componentDestroyed: Subject<void> = new Subject();
  isLocked: boolean = false;

  gst: number = UtilsService.getGST();

  constructor(
    private akitaAppQuery: AkitaAppQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaMedicalCoverageQuery: AkitaMedicalCoverageQuery,
    private apiCaseManagerService: ApiCaseManagerService,
    private caseManagerService: CaseManagerService,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
  ) { }

  ngOnInit() {
    this.generateDraftInvoice();
    this.subscribePaymentChanges();
  }
setNetsOn(){
  this.netsFlag=true;
}
setNetsOff(){
  this.netsFlag=false;
}
  // FIRST CALL WITH API
  generateDraftInvoice() {
    this.isLocked = false;
    this.akitaCaseVisitQuery
      .select(entity => [entity.caseId, entity.active])
      .pipe(
        takeUntil(this.componentDestroyed),
        filter(([caseId, active]) => !!caseId && String(caseId).length > 0),
        map(([caseId, active]) =>
          !active || active.toString().length === 0
            ? [caseId, '']
            : [caseId, active]
        ),
        mergeMap(([caseId, active]) => {
          if (String(active).length > 0) {
            if (this.akitaCaseVisitQuery.getActive().onlinePaid) {
              return of([{ payload: [] }, { payload: [] }]);
            }
            if(!this.isLocked){
              this.isLocked = true;
              return forkJoin(
                this.apiCaseManagerService.getInvoiceBreakdown(
                  String(caseId),
                  String(active)
                ),
                this.apiCaseManagerService.getInvoiceBreakdown(
                  String(caseId),
                  String(active),
                  true
                )
              ).pipe(catchError(err => of([{ payload: [] }, { payload: [] }])));
            }
          }
          return this.apiCaseManagerService
            .getInvoiceBreakdown(String(caseId), String(active))
            .pipe(
              map(res => [res, res]),
              catchError(err => of([{ payload: [] }, { payload: [] }]))
            );
        })
      )
      .subscribe(([minRes, maxRes]) => {
        if (minRes && minRes.payload && maxRes && maxRes.payload) {
          this.paymentInfo = minRes.payload;
          while (this.paymentFormArray.length > 0) {
            this.paymentFormArray.removeAt(0);
          }

          this.caseLevel = !this.akitaCaseVisitQuery.getActive();
          if (this.caseLevel) {
            this.draftMinInvoices = minRes.payload.invoices
              .filter(invoice => {
                if (invoice.invoiceType === 'DIRECT')
                  return (
                    invoice.paidAmount === 0 && invoice.payableAmount !== 0
                  );
                else return invoice.invoiceState === 'INITIAL';
              })
              .sort((a, b) => a.invoiceType.localeCompare(b.invoiceType));
            this.draftMaxInvoices = maxRes.payload.invoices
              .filter(invoice => {
                if (invoice.invoiceType === 'DIRECT')
                  return (
                    invoice.paidAmount === 0 && invoice.payableAmount !== 0
                  );
                else return invoice.invoiceState === 'INITIAL';
              })
              .sort((a, b) => a.invoiceType.localeCompare(b.invoiceType));
          } else {
            if (minRes.payload.invoices) {
              this.draftMinInvoices = minRes.payload.invoices.sort((a, b) =>
                a.invoiceType.localeCompare(b.invoiceType)
              );
              this.draftMaxInvoices = minRes.payload.invoices.sort((a, b) =>
                a.invoiceType.localeCompare(b.invoiceType)
              );
            }

            // const packageCase = this.akitaCaseVisitQuery.packageCase();
            // if (packageCase) {
            //   const packageType = this.akitaCaseVisitQuery.getActive().medicalReferenceEntity.packages.some(perPackage => perPackage.packageType === 'PRE');
            //   if (packageType) this.draftMaxInvoices = [...this.draftMinInvoices];
            //   else this.draftMaxInvoices = maxRes.payload.invoices.sort((a, b) => a.invoiceType.localeCompare(b.invoiceType));
            // } else this.draftMaxInvoices = maxRes.payload.invoices.sort((a, b) => a.invoiceType.localeCompare(b.invoiceType));
          }

          if (maxRes.payload.itemDiscount) this.caseManagerService.updateCasePaymentCoverageDiscount(maxRes.payload.itemDiscount);

          this.visitAmount$ = this.akitaCaseVisitQuery.getActive() && this.akitaCaseVisitQuery.getActive().onlinePaid ? of(0.00) : of(
            maxRes.payload.payableAmount -
            maxRes.payload.outstandingBalance -
            maxRes.payload.gstOfOutstandingBalance
          );
          this.caseManagerService.updateCasePaymentAmount({
            totalAmount: maxRes.payload.payableAmount,
            gstAmount: maxRes.payload.gstAmount,
            outstandingSubtotal: maxRes.payload.outstandingBalance,
            outstandingGst: maxRes.payload.gstOfOutstandingBalance,
          });

          const updatedInvoiceData = this.generateInvoiceData(true);
          this.paymentFormArray.markAllAsTouched();
          const updatedPaymentData = this.generatePaymentLineData();
          this.outstandingAmount$ = of(
            this.calculateOutstanding(updatedInvoiceData, updatedPaymentData)
          );
          // this.visitAmount$ = of(this.calculateVisitAmount(updatedInvoiceData));
          this.checkDisablePayBtn(updatedInvoiceData, updatedPaymentData);
        }
      });
  }

  // SUBSEQUENT CALL WITH PAYMENT FORM ARRAY CHANGES
  subscribePaymentChanges() {

    this.paymentFormArray.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.componentDestroyed))
      .subscribe(arrayChanges => {
        this.paymentFormArray.markAllAsTouched();

        const creditPaymentLines = arrayChanges.filter(
          paymentLine => paymentLine.invoiceType === 'CREDIT'
        );

        this.paymentService.updateCoverageVisitBreakdown(
          creditPaymentLines.map(paymentLines => ({
            coverageName: this.akitaMedicalCoverageQuery.getMedicalCoverageByPlanId(
              paymentLines.planId
            ).name,
            planName: this.akitaMedicalCoverageQuery.getMedicalCoveragePlan(
              paymentLines.planId
            ).name,
            amount: paymentLines.amount,
          }))
        );

        const updatedInvoiceData = this.generateInvoiceData();
        const updatedPaymentData = this.generatePaymentLineData();
        this.outstandingAmount$ = of(
          this.calculateOutstanding(updatedInvoiceData, updatedPaymentData)
        );
        this.checkDisablePayBtn(updatedInvoiceData, updatedPaymentData);
      });
  }

  // CREATE PAYMENT LINE
  createPaymentLine(
    invoice,
    name = '',
    paymentInfo = { amount: 0, externalTransactionId: '' }
  ) {

    const { invoiceType = 'DIRECT', planId } = invoice;

    let planName;
    if (invoiceType === 'CREDIT')
      planName = this.akitaMedicalCoverageQuery.getMedicalCoverageByPlanId(
        planId
      ).name;
    else planName = name;

    const newForm = this.formBuilder.group({
      accumulatedAmount: 0,
      amount: [
        paymentInfo.amount,
        [PaymentAmountValidator, CashAmountValidator, Validators.required],
      ],
      bank: '',
      chequeNo: '',
      invoiceType: invoiceType,
      limit: 0,
      limitHit: false,
      name: [planName, [Validators.required]],
      planId: '',
      remarks: '',
      babyBonus: '',
      transactionId: paymentInfo.externalTransactionId,
    });

    this.akitaCaseVisitQuery.selectCase().subscribe(activeCase => {
      this.gst = UtilsService.getGST(activeCase.caseStartDateTime);
    });

    if (invoiceType === 'CREDIT') {
      const visitPlanMaxUsage = this.akitaCaseVisitQuery.getActive()
        ? this.akitaCaseVisitQuery.getActiveVisitPlanMaxUsage()
        : this.akitaCaseVisitQuery.getValue().coverages;
      const planMaxUsage = visitPlanMaxUsage.find(
        (visit: CaseCoveragePlan) => visit.planId === planId
      );
      
      let limit = '',
        remarks = '';
      if (planMaxUsage) {
        const tempLimit = planMaxUsage.limit * this.gst;

        limit = tempLimit.toFixed(2);
        remarks = planMaxUsage.remarks;
      }

      newForm.get('limit').patchValue(limit);
      newForm.get('planId').patchValue(planId);
      newForm.get('remarks').patchValue(remarks);
      newForm
        .get('amount')
        .setValidators([Validators.required, CoverageLimitValidator]);
    }

    if (newForm.get('invoiceType').value === 'DIRECT') {
      const paymods = ['CASH', 'FAVEPAY', 'GRABPAY', 'SHOPBACK', 'STRIPE', 'PAYNOW'];
      newForm.get('name').valueChanges.subscribe(changes => {
        if (changes === 'CHEQUE') {
          newForm.get('bank').setValidators(Validators.required);
          newForm.get('chequeNo').setValidators(Validators.required);
          newForm.get('transactionId').clearValidators();
        } else if (paymods.includes(changes)) {
          newForm.get('bank').clearValidators();
          newForm.get('chequeNo').clearValidators();
          newForm.get('transactionId').clearValidators();
        } else {
          newForm.get('bank').clearValidators();
          newForm.get('chequeNo').clearValidators();
          newForm.get('transactionId').setValidators(Validators.required);
        }
if(this.netsFlag==true){

  newForm.get('babyBonus').setValidators(Validators.required);
}
        newForm.get('bank').updateValueAndValidity();
        newForm.get('chequeNo').updateValueAndValidity();
        newForm.get('transactionId').updateValueAndValidity();
        newForm.get('babyBonus').updateValueAndValidity();
      });
    }

    if (invoice.invoiceType === 'CREDIT') {
      // const visitAmount = invoice.payableAmount - invoice.paidAmount;
      const visitAmount = invoice.visitAmount;
      newForm.get('amount').patchValue(Number(visitAmount / 100).toFixed(2));
      newForm
        .get('accumulatedAmount')
        .patchValue(Number(invoice.paidAmount / 100).toFixed(2));

      if (invoice.payableAmount === invoice.paidAmount)
        newForm.get('limitHit').patchValue(true);
    }

    return newForm;
  }

  // TAKES IN ALL INVOICES AND GENERATE TOTAL INVOICE DATA
  generateInvoiceData(includeLineItemCreation = false, invoiceType = 'MAX') {
    let cashAdjustedTotalAmount = 0;
    let totalAmount = 0;
    let totalCreditAmount = 0;
    let totalDirectAmount = 0;
    let totalTaxableAmount = 0;
    let hasCorpOrIns = false;

    let invoices = [];
    if (invoiceType === 'MAX')
      invoices =
        this.draftMaxInvoices && this.draftMaxInvoices.length > 0
          ? this.draftMaxInvoices
          : [];
    else if (invoiceType === 'MIN')
      invoices =
        this.draftMinInvoices && this.draftMinInvoices.length > 0
          ? this.draftMinInvoices
          : [];

    const directInvoice =
      invoices && invoices.length > 0
        ? invoices.find(invoice => invoice.invoiceType === 'DIRECT')
        : undefined;

    invoices.forEach(invoice => {
      const invoiceCashAdjustment =
        this.caseLevel && invoice.invoiceType === 'CREDIT'
          ? 0
          : invoice.cashAdjustmentRounding;
      // const invoiceCreditPayableAmount = (invoice.invoiceType === 'CREDIT') ? (this.caseLevel ? invoice.paidAmount : invoice.payableAmount) : 0;
      // const invoiceCreditPayableAmount = (invoice.invoiceType === 'CREDIT') ? (invoice.payableAmount - invoice.paidAmount) : 0
      const invoiceCreditPayableAmount =
        invoice.invoiceType === 'CREDIT' ? invoice.visitAmount : 0;
      const invoiceDirectPayableAmount =
        invoice.invoiceType === 'DIRECT' ? invoice.payableAmount : 0;
      const invoiceTaxAmount =
        this.caseLevel && invoice.invoiceType === 'CREDIT'
          ? 0
          : invoice.taxAmount;

      cashAdjustedTotalAmount += invoiceCashAdjustment;
      totalCreditAmount += invoiceCreditPayableAmount;
      totalDirectAmount += invoiceDirectPayableAmount;
      totalAmount += invoiceCreditPayableAmount + invoiceDirectPayableAmount;
      totalTaxableAmount += invoiceTaxAmount;

      if (invoice.invoiceType === 'CREDIT') {
        if (invoice.planId) {
          const plan = this.akitaMedicalCoverageQuery.getMedicalCoverageByPlanId(
            invoice.planId
          );
          if (plan.type === 'CORPORATE' || plan.type === 'INSURANCE')
            hasCorpOrIns = true;
        }
      }

      if (includeLineItemCreation) {
        const paymentLineToBeAdded = true;
        if (invoice.planId && invoice.planId.length > 0) {
          const medicalCoverage = this.akitaMedicalCoverageQuery.getMedicalCoverageByPlanId(
            invoice.planId
          );
          // if ((medicalCoverage.type === 'CHAS' || medicalCoverage.type === 'MEDISAVE') && this.caseLevel) {
          //   paymentLineToBeAdded = false;
          // }
        }

        if (paymentLineToBeAdded) {
          if (
            this.onlineDrugDeliveryMode &&
            !this.isOnlinePaymentPending() &&
            invoice.paymentInfos.length > 0
          ) {
            invoice.paymentInfos.forEach(info => {
              const amount = +(info.amount / 100).toFixed(2);
              const paymentInfo = { ...info, amount };

              const form = this.createPaymentLine(
                invoice,
                invoice.invoiceType.toUpperCase(),
                paymentInfo
              );

              this.paymentFormArray.push(form);
            });
          } else {

            const form = this.createPaymentLine(
              invoice,
              invoice.invoiceType === 'DIRECT' ? 'CASH' : ''
            );

            this.paymentFormArray.push(form);
          }
        }
      }
    });

    let currentTotalAmount = 0;
    this.paymentFormArray
      .getRawValue()
      .forEach(
        invoice =>
          (currentTotalAmount +=
            invoice.invoiceType === 'CREDIT'
              ? Math.round(Number(invoice.amount) * 100)
              : 0)
      );

    const totalAmountSingleCent = String(
      totalDirectAmount + totalCreditAmount - currentTotalAmount
    ).slice(-1);
    if (
      totalAmount - currentTotalAmount > 0 &&
      (Number(totalAmountSingleCent) !== 0 ||
        Number(totalAmountSingleCent) !== 5)
    ) {
      if (Number(totalAmountSingleCent) < 5)
        cashAdjustedTotalAmount = Number(totalAmountSingleCent);
      else cashAdjustedTotalAmount = Number(totalAmountSingleCent) - 5;
    }

    return {
      cashAdjustedTotalAmount: cashAdjustedTotalAmount,
      categorised: hasCorpOrIns && !directInvoice,
      subTotal: totalAmount - totalTaxableAmount,
      totalAmount: totalAmount,
      totalTaxableAmount: totalTaxableAmount,
    };
  }

  // TAKES IN ALL THE PAYMENT LINE AND GENERATE TOTAL PAYMENT DATA
  generatePaymentLineData() {
    let totalCashAmount = 0;
    let totalCreditAmount = 0;
    let totalOtherAmount = 0;

    this.paymentFormArray.getRawValue().forEach(paymentLine => {
      totalCashAmount +=
        paymentLine.invoiceType === 'DIRECT' && paymentLine.name === 'CASH'
          ? Math.round(Number(paymentLine.amount) * 100)
          : 0;
      totalCreditAmount +=
        paymentLine.invoiceType === 'CREDIT'
          ? Math.round(Number(paymentLine.amount) * 100)
          : 0;
      totalOtherAmount +=
        paymentLine.invoiceType === 'DIRECT' && paymentLine.name !== 'CASH'
          ? Math.round(Number(paymentLine.amount) * 100)
          : 0;
    });

    return {
      totalCashAmount: totalCashAmount,
      totalCreditAmount: totalCreditAmount,
      totalOtherAmount: totalOtherAmount,
    };
  }

  calculateOutstanding(invoiceData, paymentData) {
    let balance = Math.round(
      invoiceData.totalAmount - paymentData.totalCreditAmount
    );
    if (paymentData.totalOtherAmount === 0)
      balance -=
        paymentData.totalCashAmount + invoiceData.cashAdjustedTotalAmount;
    else balance -= paymentData.totalOtherAmount + paymentData.totalCashAmount;

    const outstanding = {
      cashAdjustedTotalAmount: Number(invoiceData.cashAdjustedTotalAmount),
      categorised: Boolean(invoiceData.categorised),
      changeAmount: paymentData.totalCashAmount > 0 ? balance : 0,
      subTotal:
        Number(invoiceData.totalAmount) -
        Number(invoiceData.totalTaxableAmount),
      totalAmount:
        Number(invoiceData.totalAmount) -
        Number(paymentData.totalCreditAmount) -
        Number(paymentData.totalCashAmount) -
        Number(paymentData.totalOtherAmount),
      totalTaxableAmount: Number(invoiceData.totalTaxableAmount),
    };
    return outstanding;
  }

  calculateVisitAmount(invoiceData) {
    const visitAmount = Math.round(
      invoiceData.totalAmount -
      this.akitaCaseVisitQuery.getValue().salesOrder.outstanding
    );
    return visitAmount;
  }

  checkDisablePayBtn(invoiceData, paymentData) {
    this.payButtonStatus = true;
    this.payNCloseButtonStatus = true;

    if (this.caseLevel) {
      const completeWithoutPayment =
        this.akitaAppQuery.getValue().clinic &&
        this.akitaAppQuery.getValue().clinic.clinicFeatures &&
        this.akitaAppQuery
          .getValue()
          .clinic.clinicFeatures.includes('COMPLETE_WITHOUT_PAYMENT');

      const totalPaidAmount =
        paymentData.totalCashAmount +
        paymentData.totalOtherAmount +
        paymentData.totalCreditAmount;
      if (!completeWithoutPayment && totalPaidAmount === 0) {
        this.payButtonStatus = true;
        return;
      }

      let balance = invoiceData.totalAmount - paymentData.totalCreditAmount;

      if (
        paymentData.totalCashAmount === 0 &&
        paymentData.totalOtherAmount === 0
      ) {
        if (
          !completeWithoutPayment &&
          balance - invoiceData.cashAdjustedTotalAmount !== 0
        ) {
          this.payButtonStatus = true;
          return;
        }

        if (balance < 0) {
          this.payButtonStatus = true;
          return;
        }
      }

      const cashPaymentLine = this.paymentFormArray.value.filter(
        paymentLine =>
          paymentLine.invoiceType === 'DIRECT' && paymentLine.name === 'CASH'
      );
      const otherPaymentLine = this.paymentFormArray.value.filter(
        paymentLine =>
          paymentLine.invoiceType === 'DIRECT' && paymentLine.name !== 'CASH'
      );

      if (
        !completeWithoutPayment &&
        cashPaymentLine.length > 0 &&
        otherPaymentLine.length === 0
      ) {
        balance -=
          paymentData.totalCashAmount + invoiceData.cashAdjustedTotalAmount;
        if (balance > 0) {
          this.payButtonStatus = true;
          return;
        }
      }

      if (otherPaymentLine.length > 0) {
        balance -= paymentData.totalCashAmount + paymentData.totalOtherAmount;

        if (!completeWithoutPayment && balance > 0) {
          this.payButtonStatus = true;
          return;
        }

        if (balance < 0) {
          if (paymentData.totalCashAmount === 0) {
            this.payButtonStatus = true;
            return;
          } else {
            if (paymentData.totalCashAmount < Math.abs(balance)) {
              this.payButtonStatus = true;
              return;
            }
          }
        }
      }

      this.payButtonStatus = false;
    } else {
      const completeWithoutPayment =
        this.akitaAppQuery.getValue().clinic &&
        this.akitaAppQuery.getValue().clinic.clinicFeatures &&
        this.akitaAppQuery
          .getValue()
          .clinic.clinicFeatures.includes('COMPLETE_WITHOUT_PAYMENT');
      const minInvoiceData = completeWithoutPayment
        ? this.generateInvoiceData(false, '')
        : this.generateInvoiceData(false, 'MIN');

      let minBalanceAmount =
        minInvoiceData.totalAmount - paymentData.totalCreditAmount;
      let maxBalanceAmount =
        invoiceData.totalAmount - paymentData.totalCreditAmount;

      if (
        paymentData.totalCashAmount === 0 &&
        paymentData.totalOtherAmount === 0
      ) {
        if (minBalanceAmount - minInvoiceData.cashAdjustedTotalAmount > 0) {
          this.payButtonStatus = true;
          return;
        }

        if (maxBalanceAmount < 0) {
          this.payButtonStatus = true;
          return;
        }
      }

      if (
        paymentData.totalCashAmount > 0 &&
        paymentData.totalOtherAmount === 0
      ) {
        minBalanceAmount -=
          paymentData.totalCashAmount + invoiceData.cashAdjustedTotalAmount;
        maxBalanceAmount -=
          paymentData.totalCashAmount + invoiceData.cashAdjustedTotalAmount;

        if (minBalanceAmount > 0) {
          this.payButtonStatus = true;
          return;
        }
      }

      if (paymentData.totalOtherAmount > 0) {
        minBalanceAmount -=
          paymentData.totalCashAmount + paymentData.totalOtherAmount;
        maxBalanceAmount -=
          paymentData.totalCashAmount + paymentData.totalOtherAmount;

        if (minBalanceAmount > 0) {
          this.payButtonStatus = true;
          return;
        }

        if (maxBalanceAmount < 0) {
          if (paymentData.totalCashAmount === 0) {
            this.payButtonStatus = true;
            return;
          } else {
            if (paymentData.totalCashAmount < Math.abs(maxBalanceAmount)) {
              this.payButtonStatus = true;
              return;
            }
          }
        }
      }

      this.payButtonStatus = false;

      if (!this.payButtonStatus) {
        const packageCheck = this.akitaCaseVisitQuery.packageCase();

        if (packageCheck) {
          const unutilizedPackage = this.akitaCaseVisitQuery
            .getPackagesForPayload()
            .filter(perPackage =>
              perPackage.dispatches.some(dispatch => !dispatch.utilize)
            );

          if (unutilizedPackage.length > 0) {
            this.payNCloseButtonStatus = true;
            return;
          }
        }

        if (maxBalanceAmount > 0) {
          this.payNCloseButtonStatus = true;
          return;
        }

        if (maxBalanceAmount < 0) {
          if (paymentData.totalCashAmount === 0) {
            this.payNCloseButtonStatus = true;
            return;
          } else {
            if (paymentData.totalCashAmount < Math.abs(maxBalanceAmount)) {
              this.payNCloseButtonStatus = true;
              return;
            }
          }
        }

        this.payNCloseButtonStatus = false;
      }
    }
  }

  checkOnlyCashPayment() {
    const directLine = this.paymentFormArray
      .getRawValue()
      .filter(paymentLine => paymentLine.invoiceType === 'DIRECT');
    return directLine.length > 0
      ? directLine.every(invoice => invoice.name === 'CASH')
      : false;
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  addPaymentLine() {
    this.paymentFormArray.push(this.createPaymentLine({}, ''));
  }

  deletePaymentLine(index) {
    this.paymentFormArray.removeAt(index);
  }

  clickPayBtn() {
    const multiVisitCheck =
      this.akitaAppQuery.getValue().clinic &&
      this.akitaAppQuery.getValue().clinic.clinicFeatures &&
      this.akitaAppQuery
        .getValue()
        .clinic.clinicFeatures.includes('MULTI_VISIT');
    if (!multiVisitCheck) this.prePaymentPatch(true);
    else this.prePaymentPatch(this.caseLevel);
  }

  clickPayNCloseBtn() {
    this.prePaymentPatch(true, true);
  }

  prePaymentPatch(forceClose = false, payNClose = false) {
    this.runningPayment = true;
    const updatedPaymentData = this.generatePaymentLineData();

    if (updatedPaymentData.totalCashAmount > 0) {
      const updatedInvoiceData = this.generateInvoiceData();
      let balance =
        updatedInvoiceData.totalAmount - updatedPaymentData.totalCreditAmount;
      if (updatedPaymentData.totalOtherAmount === 0)
        balance -=
          updatedPaymentData.totalCashAmount +
          updatedInvoiceData.cashAdjustedTotalAmount;
      else
        balance -=
          updatedPaymentData.totalCashAmount +
          updatedPaymentData.totalOtherAmount;

      if (balance < 0) {
        if (
          confirm(`Change needed: $${(Math.abs(balance) / 100).toFixed(2)}`)
        ) {
          let cashPaymentLine;
          let updatedCashAmount;
          if (
            this.paymentFormArray.controls.filter(
              paymentLine => paymentLine.value.name === 'CASH'
            ).length > 1
          ) {
            cashPaymentLine = this.paymentFormArray.controls
              .filter(paymentLine => paymentLine.value.name === 'CASH')
              .reduce((prev, curr) =>
                Number(prev.value.amount) > Number(curr.value.amount)
                  ? prev
                  : curr
              );
            updatedCashAmount = Number(
              (Number(cashPaymentLine.value.amount) * 100 + balance) / 100
            ).toFixed(2);
          } else {
            cashPaymentLine = this.paymentFormArray.controls.find(
              paymentLine => paymentLine.value.name === 'CASH'
            );
            updatedCashAmount = Number(
              ((updatedPaymentData.totalCashAmount + balance) / 100).toFixed(2)
            );
          }
          cashPaymentLine.get('amount').patchValue(updatedCashAmount);
          this.executePay(forceClose);
        } else {
          this.payButtonStatus = false;
          if (payNClose) this.payNCloseButtonStatus = false;
          return;
        }
      } else this.executePay(forceClose);
    } else this.executePay(forceClose);
  }

  executePay(close = false) {
    this.runningPayment = true;

    const planUsageUpdatable =
      this.akitaAppQuery.getValue().clinic &&
      this.akitaAppQuery.getValue().clinic.clinicFeatures &&
      this.akitaAppQuery
        .getValue()
        .clinic.clinicFeatures.includes('PLAN_USAGE_UPDATABLE');
    const creditPaymentLine = this.paymentFormArray
      .getRawValue()
      .filter(paymentLine => paymentLine.invoiceType === 'CREDIT');
    const directPaymentLine = this.paymentFormArray
      .getRawValue()
      .filter(paymentLine => paymentLine.invoiceType === 'DIRECT');
      
    const updatedInvoice = [];

    if (this.draftMaxInvoices && this.draftMaxInvoices.length > 0) {
      const updatedInvoiceData = this.generateInvoiceData();

      creditPaymentLine.forEach(paymentLine => {
        const correspondingCDraftInvoice = this.draftMaxInvoices.find(
          invoice => invoice.planId === paymentLine.planId
        );
        if (correspondingCDraftInvoice) {
          correspondingCDraftInvoice.invoiceTime = moment().format(
            DB_FULL_DATE_FORMAT
          );
          correspondingCDraftInvoice.paidTime = moment().format(
            DB_FULL_DATE_FORMAT
          );
          correspondingCDraftInvoice.paidAmount = Math.round(
            (Number(paymentLine.amount) +
              Number(paymentLine.accumulatedAmount)) *
            100
          );
          if (!this.caseLevel)
            correspondingCDraftInvoice.visitId = this.akitaCaseVisitQuery.getActiveId();
          updatedInvoice.push(correspondingCDraftInvoice);
        }
      });
    
      let correspondingDDraftInvoice = this.draftMaxInvoices.find(
        invoice => invoice.invoiceType === 'DIRECT'
      );
      if (!correspondingDDraftInvoice) {
        correspondingDDraftInvoice = {
          invoiceType: 'DIRECT',
          payableAmount: 0,
          paidAmount: 0,
          cashAdjustmentRounding: 0,
          taxAmount: 0,
          paymentInfos: [],
          visitId: '',
        };
      }

      if (!!this.akitaCaseVisitQuery.getActive()) {
        correspondingDDraftInvoice.visitId = this.akitaCaseVisitQuery.getActiveId();
      }

      let paidAmount = 0;
      let updatedCashAdjustmentRounding = 0;

      const updatedPaymentData = this.generatePaymentLineData();
      let updatedPayment = updatedPaymentData.totalCreditAmount;
      if (updatedPaymentData.totalOtherAmount > 0)
        updatedPayment +=
          updatedPaymentData.totalOtherAmount +
          updatedPaymentData.totalCashAmount;
      else
        updatedPayment +=
          updatedPaymentData.totalCashAmount +
          updatedInvoiceData.cashAdjustedTotalAmount;
      const partialPayment = updatedInvoiceData.totalAmount > updatedPayment;

      const otherPaymentAvailable = updatedPaymentData.totalOtherAmount > 0;
      let adjustedCashApplied = false;
      const billInfos = [];

      directPaymentLine.forEach(paymentLine => {
        if (paymentLine.amount > 0) {
          let externalTransactionId;
          if (!paymentLine) {
            externalTransactionId = '';
          } else if (paymentLine.transactionId) {
            externalTransactionId = paymentLine.transactionId;
          } else if (paymentLine.bank || paymentLine.chequeNo) {
            externalTransactionId = `${paymentLine.bank ||
              ''} - ${paymentLine.chequeNo || ''}`;
          }

          const billInfo = {
            amount: Math.round(Number(paymentLine.amount) * 100),
            billMode: paymentLine.name,
            externalTransactionId: externalTransactionId,
            cashAdjustment:
              otherPaymentAvailable || adjustedCashApplied || partialPayment
                ? 0
                : updatedInvoiceData.cashAdjustedTotalAmount,
            invoiceNumber: '',
            babyBonus:paymentLine.babyBonus!=null?paymentLine.babyBonus :''

          };

          if (
            billInfo.cashAdjustment ===
            updatedInvoiceData.cashAdjustedTotalAmount
          ) {
            adjustedCashApplied = true;
          }
          paidAmount += Math.round(Number(paymentLine.amount) * 100);
          updatedCashAdjustmentRounding += billInfo.cashAdjustment;
          billInfos.push(billInfo);
        }
      });

      correspondingDDraftInvoice.cashAdjustmentRounding =
        !otherPaymentAvailable && updatedPaymentData.totalCashAmount === 0
          ? correspondingDDraftInvoice.cashAdjustmentRounding
          : otherPaymentAvailable || partialPayment
            ? 0
            : updatedCashAdjustmentRounding;
      correspondingDDraftInvoice.invoiceTime = moment().format(
        DB_FULL_DATE_FORMAT
      );
      correspondingDDraftInvoice.paidAmount = Math.round(paidAmount);
      correspondingDDraftInvoice.paidTime = moment().format(
        DB_FULL_DATE_FORMAT
      );
      correspondingDDraftInvoice.paymentInfos = billInfos;

      if (
        planUsageUpdatable &&
        correspondingDDraftInvoice.payableAmount === 0
      ) {
        correspondingDDraftInvoice.payableAmount = paidAmount;
      }

      if (!planUsageUpdatable) {
        if (correspondingDDraftInvoice.payableAmount !== 0)
          updatedInvoice.push(correspondingDDraftInvoice);
      } else updatedInvoice.push(correspondingDDraftInvoice);
    }

    const data = {
      close: close,
      invoice: updatedInvoice,
    };
    this.submitPayment.emit(data);
  }

  executeComplete(close = false) {
    this.submitPayment.emit({
      close: close,
      invoice: [],
    });
  }

  isOnlinePaymentPending(): boolean {
    return this.inPopup || !this.akitaCaseVisitQuery.getActive().onlinePaid;
  }
}
