<div class="modal-header">
    <h4 class="modal-title pull-left">Questionnaire Responses</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <alert></alert>
    <div class="row">
        <!-- Onboarding/Health Questionnaire -->
        <div class="col-12" *ngIf="questionnaire">
            <table class="custom-table custom-table-light">
                <thead>
                    <tr>
                        <th class="border-right">Section</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let section of questionnaire.sections; let i = index;">
                        <tr>
                            <td [attr.rowspan]="section.questions.length + 1"
                                [ngClass]="(i % 2) !== 0 ? 'striped-background' : '' "
                                [ngClass]="questionnaire.questionnaireType === 'HQ' ? 'text-center' : ''"
                                class="border-right border-top border-bottom">
                                {{questionnaire.questionnaireType === 'HQ' ? i + 1 : section.title}}
                            </td>
                        </tr>
                        <ng-container *ngFor="let question of section.questions; let j = index;">
                            <tr>
                                <td class="p-0 border-top" [ngClass]="(i % 2) !== 0 ? 'striped-background' : '' ">
                                    <div class="container-fluid">
                                        <div class="row row-value"
                                            [ngClass]="section.questions.length === (j + 1) ? 'border-bottom' : '' ">
                                            <div class="col-12">
                                                {{question.question}}

                                                <!-- VALUE -->
                                                <span *ngIf="question.questionType === 'VALUE' " class="txt-value">
                                                    {{question.answer.values[0].value}}
                                                </span>

                                                <!-- SINGLE_SELECT -->
                                                <span *ngIf="question.questionType === 'SINGLE_SELECT' "
                                                    class="txt-value">
                                                    <ng-container *ngFor="let value of question.answer.values">
                                                        <ng-container
                                                            *ngIf="value.selected">{{value.value}}</ng-container>
                                                    </ng-container>
                                                </span>
                                            </div>
                                            <!-- SINGLE_SELECT -->
                                            <div class="col-12" *ngIf="question.questionType === 'MULTI_SELECT' ">
                                                <ul>
                                                    <li *ngFor="let value of question.answer.values">
                                                        <div class="checkbox"
                                                            [ngClass]="value.selected ? '' : 'empty-checkbox' ">
                                                            <img *ngIf="value.selected"
                                                                src="/assets/icons/icon-green-tick.png"
                                                                class="icon-tick">
                                                        </div>
                                                        {{value.value}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="modal-footer">

</div>