import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hsgDisplayStatus'
})
export class HsgDisplayStatusPipe implements PipeTransform {

  transform(value: string): string {
    let statusValue: string = '';

    if(value === 'CONTACTED') {
      statusValue = 'Appointment Made';
    } else {
      statusValue = value ? value.replace(/_/g, ' ') : '';
    }

    return statusValue;
  }
}