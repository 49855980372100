import { FormArray, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ADMINISTRATOR_TYPES, DISPLAY_DATE_FORMAT, MEDICAL_ALERT_STATUS } from '../../../../../constants/app.constants';
import DatePickerConfig from '../../../../../objects/DatePickerConfig';
import { DialogService } from '../../../../../services/dialog.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CustomConfirmPopupComponent } from '../../../../../views/components/custom-confirm-popup/custom-confirm-popup.component';
import * as moment from 'moment';
import { pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-patient-add-alerts-info-add-clinical-item',
  templateUrl: './patient-add-alerts-info-add-clinical-item.component.html',
  styleUrls: ['./patient-add-alerts-info-add-clinical-item.component.scss'],
})
export class PatientAddAlertsInfoAddClinicalItemComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() medicalAlertFormGroup: FormGroup;
  @Input() alertCodes: any = [];
  @Input() doctors: any = [];
  @Output() delete = new EventEmitter<boolean>();
  @Output() inActivate = new EventEmitter<boolean>();
  @Input() index: number;

  status: any = MEDICAL_ALERT_STATUS;
  showDetails: boolean = false;
  administratorTypes: any[] = ADMINISTRATOR_TYPES; 
  selectedAdminTypeCode: string;
  
  bsModalRef: BsModalRef;

  public startDateDatePickerConfig: DatePickerConfig;
  public endDateDatePickerConfig: DatePickerConfig;

  buttonData: any = [{
                      isVisible: true,
                      description: 'Delete',
                      code: 'DELETE'
                    },
                    {
                      isVisible: true,
                      description: 'Inactive',
                      code: 'IN-ACTIVE'
                    }, 
                  ]

  constructor(
    private dialogService: DialogService,    
    private modalService: BsModalService,
  ) {
    this.startDateDatePickerConfig = new DatePickerConfig(
      '',
      new Date(),
      null,
      'bottom',
      'none'
    );
    this.endDateDatePickerConfig = new DatePickerConfig(
      '',
      null,
      null,
      'bottom',
      'none'
    )
  }

  ngOnInit() {
    this.showDetails = this.form.value.isEdit;
    this.selectedAdminTypeCode = this.form.get('reporter').get('profession').value;
    this.detectAlertTypechanges();
    this.detectAlertCodechanges();
    this.subscribeUpdateDate();
  }

  subscribeUpdateDate() {
    this.form.get('startDate').valueChanges
    .pipe(pairwise())
    .subscribe(([previousDate, newDate]) => {
      (previousDate!==newDate) ? this.form.get('startDate').patchValue(moment(newDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT), {emitEvent: false}) :  null;
    }); 
    this.form.get('expiryDate').valueChanges
    .pipe(pairwise())
    .subscribe(([previousDate, newDate]) => {
      (previousDate!==newDate) ? this.form.get('expiryDate').patchValue(moment(newDate, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT), {emitEvent: false}) :  null;
    });   
  }
  
  detectAlertCodechanges() {
    this.form.get('alertCode').valueChanges.subscribe({
      next: result => {
        const selectedType = this.alertCodes.find(code => code.cmsCode === result);

        if (selectedType && this.medicalAlertFormGroup.value.alertArray ) {
          let exsists = this.medicalAlertFormGroup.value.alertArray.find(allert => allert.name === selectedType.cmsName);
          
          if (exsists) {
            alert("Alert is already exsists, Please add a new!");
            this.form.get('alertCode').patchValue(undefined);
          } else {
            this.form.get('name').patchValue(selectedType.cmsName);
          }
        } else if (selectedType) {
          this.form.get('name').patchValue(selectedType.cmsName);
        }
        
        
      }
    });
  }

  detectNamechange() {
    let exsists = this.medicalAlertFormGroup.value.alertArray.filter(allert => allert.name === this.form.get('name').value && allert.type === this.form.get('type').value);
    
    if (exsists.length > 1) {
      alert("Alert is already exsists, Please add a new!");
      this.form.get('name').patchValue(undefined);
    }
  }

  refreshForm() {
    if (!this.form.valid && !this.form.value.isEdit) {
      this.showDetails = !this.showDetails;
    }
    
  }

  public detectAlertTypechanges():void{
    this.form.get('type').valueChanges.subscribe({
      next: result => {
        const reporter = this.form.get('reporter');
        if(result === 'MEDICAL_ALERT') {         
          this.form.get('alertCode').setValidators(Validators.required);  
          this.form.get('name').clearValidators();
          reporter.get('regNo').setValidators(Validators.required);
          reporter.get('name').setValidators(Validators.required);
          reporter.get('profession').setValidators(Validators.required);
        } else {
          this.form.get('name').patchValue(null);
          this.form.get('alertCode').patchValue(null);
          this.form.get('name').setValidators(Validators.required);
          this.form.get('alertCode').clearValidators();

          reporter.get('regNo').clearValidators();
          reporter.get('name').clearValidators();
          reporter.get('profession').clearValidators();
        }
        this.form.get('name').updateValueAndValidity();
        this.form.get('alertCode').updateValueAndValidity();
        reporter.get('regNo').updateValueAndValidity();
        reporter.get('name').updateValueAndValidity();
        reporter.get('profession').updateValueAndValidity();
        this.medicalAlertFormGroup.markAllAsTouched();
      }
    });
    
  }

  onDelete(form) {
    const initialState = {
      isModal: true,
      displayMsg: 'Do you want to Delete or Inactivate the alert ?',
      cancelBtnText: 'Cancel',
      cancelBtnShow: true,
      buttonData: this.buttonData,
      title: 'Delete Clinical Alert',
    };

    this.bsModalRef = this.modalService.show(CustomConfirmPopupComponent, {
      initialState,
      class: 'modal-md',
      backdrop: 'static',
      keyboard: false,
    });
    this.bsModalRef.content.closeBtnName = 'Close';

    this.bsModalRef.content.closeEvent.subscribe(data => {
      this.bsModalRef.hide();
      this.bsModalRef.content.closeEvent.unsubscribe();
    });

    this.bsModalRef.content.customBtnEvent.subscribe(data => {

      if (data === 'DELETE') {
        if (form.get('isEdit').value) {
          const formArray = this.form.parent as FormArray;
            formArray.removeAt(this.index);
        } else {
          this.delete.emit(true);
        }
      } 
      if (data === 'IN-ACTIVE') {        
        if (form.get('isEdit').value) {
          form.get('alertStatus').patchValue('INACTIVE');
          form.get('expiryDate').patchValue(moment().format(DISPLAY_DATE_FORMAT));
        } else {
          form.get('alertStatus').patchValue('INACTIVE');
          form.get('expiryDate').patchValue(moment().format(DISPLAY_DATE_FORMAT));
          this.inActivate.emit(true);
        }
      }

      this.bsModalRef.hide();
      this.bsModalRef.content.customBtnEvent.unsubscribe();      
    });
  }

  getDoctorName(regNo) {

    if (!regNo) {
      return '';
    }
    const slectedDoc = this.doctors.find(doctor => doctor.mcr === regNo);

    if (slectedDoc) {
      return slectedDoc.name;
    }else {
      return '';
    }
  }

  onShowDetails() {
    this.showDetails = !this.showDetails;
  }

  onAdministratorTypeSelect(option, form) {
    if (option) {
      form.get('reporter').get('regNo').patchValue(undefined);
      form.get('reporter').get('regNo').patchValue(undefined);
      this.selectedAdminTypeCode = option.value;
    }
  }

  onDoctorChange(event, form) {
    if (event) {
      form.get('reporter').get('name').patchValue(event.displayName);
    } else {
      form.get('reporter').get('name').patchValue(undefined);
      form.get('reporter').get('regNo').patchValue(undefined);
    }
  }
}
