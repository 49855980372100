import { AkitaChargeItemQuery } from './../services/states/akita-charge-item.query';
import { Item } from './../objects/response/master/Item';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displaySalesUom'
})
export class DisplaySalesUomPipe implements PipeTransform {
  constructor(private akitaChargeItem: AkitaChargeItemQuery) {}

  transform(value: any, args?: any): any {

    let item: Item = this.akitaChargeItem.getChargeItem(value);
    return item? item.salesUom : '';
  }

  // transform(value: any, args?: any): any {
  //   let dosageInstruction = this.storeService.getDosageInstructionByCode(value)
  //     ? this.storeService.getDosageInstructionByCode(value).instruct
  //     : undefined;
  //   if (dosageInstruction === undefined || dosageInstruction === null)
  //     dosageInstruction = '';

  //   if (dosageInstruction.includes('#'))
  //     dosageInstruction = dosageInstruction.replace('#', args);
  //   return dosageInstruction;
  // }

}
