import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';

// import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-ckeditor',
  templateUrl: './ckeditor.component.html',
  styleUrls: ['./ckeditor.component.scss'],
})
export class CkeditorComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() disabled = false;
  
  public editor = ClassicEditor;
  
  public config = {
    removePlugins: ['Autoformat'],
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'blockQuote',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'insertTable',
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      '|',
      'undo',
      'redo',
    ],
    typing: {
      transformations: {
        remove: ['mathematical']
      }
    }
  };
 
  constructor() {}
  
  public onReady(editor) { }

  ngOnInit() {}

  ngOnDestroy() {
    this.editor = null;
  }
}
