<!-- <div class="container-fluid h-100"> -->
<!-- <app-patient-list></app-patient-list>
<div class="row shadow">
    <div class="col-8">
        <table>
            <tr class="text-pink">
                <td>DRUG ALLERGIES</td>
                <td>SALMON</td>
                <td>PENICILLIN</td>
            </tr>
            <tr class="text-yellow">
                <td>CLINIC ALERTS</td>
                <td>LIVER CANCER</td>
                <td>HEART DISEASE</td>
            </tr>
        </table>
    </div>
</div>
<div class="row">
    <div class="col-3">
        <app-patient-list></app-patient-list>
    </div>
    <div class="col-9">
        <tabset type="pills">
            <tab heading="Profile">
                <app-patient-detail></app-patient-detail>
            </tab>
            <tab heading="Vital Signs">
                Here are patient's vital signs
            </tab>
            <tab heading="Consultation">
                This is patient's consultation flow.
            </tab>
            <tab heading="Labs">
                Here are the laboratory reports.
            </tab>
            <tab heading="Medical Coverage">
                Here are the patient's medical coverage.
            </tab>
            <tab heading="Documents">
                Here are patient's documents.
            </tab>
        </tabset>
    </div>

</div>
 -->


<router-outlet></router-outlet>
<!-- </div> -->