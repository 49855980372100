import { StockAdjustmentListItem } from './StockAdjustmentListItem';
import { StockTakeApproveStatus } from './StockTakeApproveStatus';
import { StoreService } from '../services/store.service';
import { NavIntegrationResult } from './CommonResponses';
import * as moment from 'moment';
import { DISPLAY_DATE_TIME_NO_SECONDS_FORMAT } from '../constants/app.constants';
import { AkitaChargeItemQuery } from '../services/states/akita-charge-item.query';

export class StockTake {
  id: string;
  stockTakeName: string;
  clinicId: string;
  countType: CountType;
  startRange: string;
  endRange: string;
  countName: string;
  stockTakeStatus: StockTakeStatus;
  stockCountItems: Array<StockTakeItem>;
  createdDate: string;
  transactionNo: string;

  approveStatus: StockTakeApproveStatus;
  startDate: string;
  startTime: string;
  endDate?: string;
  endTime?: string;
  navIntegrationResult: NavIntegrationResult;

  adapt(obj?): StockTake {
    if (!obj) {
      return this;
    }

    this.id = obj.id || '';
    this.stockTakeName = obj.stockTakeName || '';
    this.clinicId = obj.clinicId || '';
    this.countType = obj.countType || '';
    this.startRange = obj.startRange || '';
    this.endRange = obj.endRange || '';
    this.countName = obj.countName || '';
    this.stockTakeStatus = obj.stockTakeStatus || '';
    this.stockCountItems = obj.stockCountItems ? obj.stockCountItems.map(item =>
      new StockTakeItem().adapt(item)
    ) : [];
    this.createdDate = obj.createdDate || '';

    this.approveStatus = obj.approveStatus || '';
    this.startDate = obj.startDate || '';
    this.startTime = obj.startTime || '';
    this.endDate = obj.endDate || '';
    this.endTime = obj.endTime || '';
    this.transactionNo = obj.transactionNo || '';
    this.navIntegrationResult = obj.navIntegrationResult;

    return this;
  }

  getCountedItems(): Array<StockTakeItem> {
    if (!this.stockCountItems) {
      return [];
    }

    if (this.stockTakeStatus === StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE) {
      return this.stockCountItems.filter(
        item => item.firstQuantity !== undefined && item.firstQuantity !== null
      );
    }

    if (this.stockTakeStatus === StockTakeStatus.IN_PROCESS_SECOND_STOCK_TAKE) {
      return this.stockCountItems.filter(
        item =>
          item.adjustedQuantity !== undefined &&
          item.adjustedQuantity !== null &&
          item.purposeOfAdjustmentCode
      );
    }

    return [];
  }

  getUncountedItem(): Array<StockTakeItem> {
    if (!this.stockCountItems) {
      return [];
    }

    if (this.stockTakeStatus === StockTakeStatus.IN_PROCESS_FIRST_STOCK_TAKE) {
      return this.stockCountItems.filter(
        item => item.firstQuantity === undefined || item.firstQuantity === null
      );
    }

    if (this.stockTakeStatus === StockTakeStatus.IN_PROCESS_SECOND_STOCK_TAKE) {
      return this.stockCountItems.filter(
        item =>
          item.adjustedQuantity === undefined || item.adjustedQuantity === null
      );
    }

    return [];
  }

  getFormattedDateTime() {
    return moment(this.createdDate).format(DISPLAY_DATE_TIME_NO_SECONDS_FORMAT);
  }
  
  getStartTime() {
    return moment(this.startTime, 'HH:mm:ss').format('HH:mm');
  }
}

export class StockTakeItem {
  id: string;
  inventoryId: string;
  itemRefId: string;
  itemCode: string;
  itemName: string;
  curBatchNumber: string;
  curUom: string;
  curExpiryDate: string;
  availableCount: number;
  batchNumber: string;
  baseUom: string;
  expiryDate: string;
  manufacturerDate: string;
  firstQuantity: number;
  adjustedQuantity: number;
  purposeOfAdjustmentCode: string;
  remark: string;
  status: string;
  cpRemarks: string;
  draft: boolean;
  draftId: string;
  purchasePrice: any;
  unitPrice: any;
  adapt(obj?): StockTakeItem {
    if (!obj) {
      return this;
    }

    this.id = obj.id || '';
    this.inventoryId = obj.inventoryId || '';
    this.itemRefId = obj.itemRefId || '';
    this.itemCode = obj.itemCode || '';
    this.itemName = obj.itemName || '';
    this.curBatchNumber = obj.curBatchNumber || '';
    this.curUom = obj.curUom || '';
    this.curExpiryDate = obj.curExpiryDate || '';
    this.availableCount = obj.availableCount || 0;
    this.purchasePrice = obj.purchasePrice || 0;
    this.unitPrice = obj.unitPrice || 0;
    this.batchNumber = obj.batchNumber || '';
    this.baseUom = obj.baseUom || '';
    this.expiryDate = obj.expiryDate || '';
    this.manufacturerDate = obj.manufacturerDate || '';
    this.firstQuantity = obj.firstQuantity;
    this.adjustedQuantity = obj.adjustedQuantity;
    this.purposeOfAdjustmentCode = obj.purposeOfAdjustmentCode;
    this.remark = obj.remark || '';
    this.status = obj.status;
    this.cpRemarks = obj.cpRemarks;
    this.draft = (!!obj.draft) ? obj.draft : false;
    this.draftId = (obj.draftId && obj.draftId.length > 0) ? obj.draftId : '';

    return this;
  }

  toStockAdjustmentListItem(
    store: StoreService,
    akitaChargeItemQuery: AkitaChargeItemQuery
  ): StockAdjustmentListItem {
    const listItem = new StockAdjustmentListItem(store);

    let isDrugOrVaccine = false;
    const correspondingChargeItem = akitaChargeItemQuery.getChargeItemByCode(this.itemCode);
    if (correspondingChargeItem) {
      isDrugOrVaccine = correspondingChargeItem.trackingCode && (correspondingChargeItem.itemType === 'DRUG' || correspondingChargeItem.itemType === 'VACCINATION' ||
        correspondingChargeItem.itemType === 'CONSUMABLE');
    }


    listItem.itemCode = this.itemCode;
    listItem.itemName = this.itemName;
    listItem.batchNumber = isDrugOrVaccine ? this.batchNumber : '-';
    listItem.expiryDate = this.expiryDate;
    listItem.availableCount = this.availableCount;
    listItem.adjustedQuantity = this.adjustedQuantity;
    listItem.baseUom = this.baseUom;
    listItem.remark = this.remark;
    listItem.cpRemarks = this.cpRemarks;

    listItem.purposeOfAdjustmentCode = this.purposeOfAdjustmentCode;

    return listItem;
  }
}

export enum StockTakeStatus {
  IN_PROCESS_FIRST_STOCK_TAKE = 'IN_PROCESS_FIRST_STOCK_TAKE',
  IN_PROCESS_SECOND_STOCK_TAKE = 'IN_PROCESS_SECOND_STOCK_TAKE',
  COMPLETED = 'COMPLETED',
}

export enum CountType {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

export function makeDraftId(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
