<div appChargeItemTouchedObject (onTopChargeItemDescriptionChanged)="updateTopDescription()" *ngIf="prescriptionItem.get('drugId').value"
    [formGroup]="prescriptionItem" [chargeItemData]="topChargeItemDescription"
    [ngClass]="itemIsValid ? 'validItem' : 'invalidItem'">
    <div #entryHeaderSection *ngIf="prescriptionItem.get('drugId').value!==''"
        class="row mx-0  py-1" style="background-color: #f7f7f7;border: solid 1px #cdcdcd;"
        (mousedown)="rowClicked($event)">
        <!-- Item Code  -->
      <div class="col col-sm-6 pr-0 my-auto" [ngClass]="isVaccine() ? 'col-sm-6' : 'col-sm-7'">
        <span [title]="code" class="cursor-pointer font-weight-500">{{ truncate(code)}}</span> &nbsp; <span
        [title]="this.topChargeItemDescription.charge">{{truncateDesc(this.topChargeItemDescription.charge, 34)}}</span>
      </div>


        <!-- Inventory Msg -->
        <div class="col px-0 my-auto" [ngClass]="isVaccine() ? 'col-sm-2' : 'col-sm-3'">
            <span class="icon-info-circled text-black-50" [popover]="popoverPriceTemplate"
                triggers="mouseenter:mouseleave" placement="top" container="body" containerClass="popoverPrice"
                (mouseover)="configureOverflowOnMouseOver($event)"
                (mouseleave)="configureOverflowOnMouseLeave($event)"></span>

            <!-- Uncomment this to make popover triggered upon click for testing instead -->
            <!-- <span class="icon-info-circled text-black-50" [popover]="popoverPriceTemplate" [outsideClick]="true" placement="top" container="body" containerClass="popoverPrice" (mouseover)="configureOverflowOnMouseOver($event)" (mouseleave)="configureOverflowOnMouseLeave($event)"></span> -->
            <span *ngIf="itemSelected.inventoried" class="font-weight-semi-bold" style="font-size: 90%" [class]="prescriptionItem.get('stock').value>-1?'badge badge-pill badge-success':'badge badge-pill badge-danger'">R.
                Qty:{{ prescriptionItem.get('stock').value }}</span><br>
            <span *ngIf="prescriptionItem.get('inventoryInvalid') && prescriptionItem.get('inventoryInvalid').value"
                style="font-size: 80%;padding-left:0.2rem;"
                class="invalidItemText">{{prescriptionItem.get('inventoryInvalid').value}}</span>
            <app-simple-error [errors]='formErrors'></app-simple-error>
            <app-simple-error [errors]='errors'></app-simple-error>

        </div>

        <div *ngIf="isVaccine()" class="col col-sm-2 px-0 mt-0">
            <button class="btn btn-brand-secondary p-1 button-width" [style.border]="!isVaccineItemValid ? '1px solid red' : 'none'" (click)="showEditVaccineDetails()" (mousedown)="checkClick($event)">
                Update
                <img src="/assets/svg/syringe-solid.svg" class="syringe-icon ml-1" />
            </button>
            <span *ngIf="!isVaccineItemValid" class="compulsory large-star">*</span>
        </div>

        <div *ngIf="isSfl()" class="col col-sm-2 px-0 mt-0">
            <button class="btn btn-brand-secondary p-1 button-width"
                [style.border]="!prescriptionItem.get('sflData').valid ? '1px solid red' : 'none'" (click)="showEditSflDetails()"
                (mousedown)="checkClick($event)">
                Update
            </button>
            <span *ngIf="!isVaccineItemValid" class="compulsory large-star">*</span>
        </div>

        <!-- Price ($) -->
        <div [formGroup]="prescriptionItem" class="col col-sm-2 mb-0 input-group ">
            <input class="form-control form-control-sm input-group-append col-10" type="number" step='0.01'
                placeholder="0.00" name="adjustedTotalPrice" formControlName="adjustedTotalPrice"
                (click)="checkClick($event)" (mousedown)="checkClick($event)" [readonly]="restrictPriceUpdate">
            <span *ngIf="store.getVisitStatus()!=='PAYMENT'" style="position:relative;color:gray"
                class="icon-trash input-group-append pt-1 col-2 text-gray" (mousedown)="checkClick($event)"
                (click)="deletePressed()"></span>
        </div>
      <app-whitelisted-item-notice [itemId]="prescriptionItem.get('drugId').value" [quantity]="prescriptionItem.get('purchaseQty').value"></app-whitelisted-item-notice>

    </div>

    <!-- Updated Pricing Tooltip -->
    <ng-template #popoverPriceTemplate>
        <div class="row mb-1 font-weight-500">
            <!-- Unit Price ($) -->
            <div class="form-group col-md-3 mb-0 pr-0" [formGroup]="prescriptionItem.get('unitPrice')">
                <span class="mb-0">Unit Price ($)</span>
                <input class="form-control form-control-sm" type="text" step='0.01' name="unitPriceDisplay"
                    [(ngModel)]="unitPriceDisplay" [ngModelOptions]="{standalone: true}" readonly>
            </div>

            <!-- Updated Unit Price ($) -->
            <div [formGroup]="prescriptionItem" class="form-group col mb-0 pr-0">
                <span class="mb-0">Updated Unit Price ($)</span>
                <input class="form-control form-control-sm" type="text" step='0.01' name="adjustedUnitPriceDisplay"
                    formControlName="adjustedUnitPrice" readonly>
                <errors [control]="prescriptionItem.get('adjustedUnitPrice')"></errors>
            </div>

            <!-- Calculated Amount ($) -->
            <div [formGroup]="prescriptionItem" class="form-group col mb-0">
                <span class="mb-0">Calculated Amount ($)</span>
                <input class="form-control form-control-sm" type="number" step='0.01' placeholder="0.00"
                    name="calculatedTotalPrice" [(ngModel)]="calculatedAmountDisplay"
                    [ngModelOptions]="{standalone: true}" readonly>
            </div>
        </div>
    </ng-template>

    <!-- Outer Grey Shade -->
    <div *ngIf="!isCollapsed" class="pt-0 px-1 pb-1" style="background-color:#d7dae2;">

        <!-- Inner White Section -->
        <div class="px-2 pt-1 bg-white">
            <div class="row mb-1">

                <!-- Dosage instruction -->
                <div class="form-group col-md-6 mb-0">
                    <label class="mb-0">Dosage</label>
                    <div class="input-group input-group-sm mb-0"
                        [formGroup]="prescriptionItem.get('dosageInstruction')">
                        <div class="input-group-append col-md-8" style="padding:0px;">
                            <ng-select name="instruction" *ngIf="!isVaccine(); else vaccine"
                                class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width'
                                bindLabel='code' bindValue='code' [items]="dosageInstructions" [clearable]=false
                                (change)="onDosageInstructionSelect($event)" [searchFn]="searchFn"
                                formControlName='code' appendTo="body">
                                <ng-template ng-header-tmp>
                                    <div class="row">
                                        <div class="col-md-4 font-weight-semi-bold">Code</div>
                                        <div class="col-md-8 font-weight-semi-bold">Instruction</div>
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <div class="row">
                                        <div class="col-md-4">{{item.code}}</div>
                                        <div class="col-md-8">{{item.instruct}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>

                            <ng-template #vaccine>
                                <ng-select name="instruction"
                                    class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width'
                                    bindLabel='name' bindValue='doseId' [items]="dosageInstructions" [clearable]=true
                                    (change)="onDosageInstructionSelect($event)" [searchFn]="searchFn"
                                    formControlName='code' appendTo="body">
                                    <ng-template ng-header-tmp>
                                        <div class="row">
                                            <div class="col-md-4 font-weight-semi-bold">Dose Id</div>
                                            <div class="col-md-8 font-weight-semi-bold">Name</div>
                                        </div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                        <div class="row">
                                            <div class="col-md-4">{{item.doseId}}</div>
                                            <div class="col-md-8">{{item.name}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </ng-template>
                        </div>
                        <div class="input-group-append col-md-3">
                            <input name="dosageQty" class="form-control form-control-sm" type="number" align="right"
                                min="0" max="100" [formControl]="prescriptionItem.get('dose').get('quantity')"
                                step="{{dosageMin}}">
                        </div>
                        <errors [control]="prescriptionItem.get('dose').get('quantity')"></errors>
                    </div>
                </div>

                <!-- Instruction -->
                <div class="form-group col-md-3 mb-0">
                    <label class="mb-0">Instr</label>
                    <div [formGroup]="prescriptionItem.get('instruction')">
                        <ng-select name="instruction"
                            class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width instr-dropdown-right'
                            bindLabel='code' bindValue='code' [items]="instructions" [clearable]=false
                            [virtualScroll]="false" (change)="onInstructionSelect($event)" [searchFn]="searchFn"
                            formControlName="code" appendTo="body">
                            <ng-template ng-header-tmp>
                                <div class="row">
                                    <div class="col-md-4 font-weight-semi-bold">Code</div>
                                    <div class="col-md-8 font-weight-semi-bold">Instruction</div>

                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <div class="row">
                                    <div class="col-md-4">{{item.code}}</div>
                                    <div class="col-md-8">{{item.instruct}}</div>
                                </div>
                            </ng-template>
                        </ng-select>
                        <errors [control]="prescriptionItem.get('instruction').get('code')"></errors>
                    </div>
                </div>

                <!-- Purchase Qty -->
                <div class="form-group col-md-3 mb-0">
                    <label class="mb-0" *ngIf="isPackagedItem">Qty</label>
                    <label class="mb-0" *ngIf="!isPackagedItem">Qty (UOM)</label>
                    <div [formGroup]="prescriptionItem" class=" input-group input-group-sm">
                        <input name="quantity" class="form-control form-control-sm" type="number" min="0" max="1000"
                            formControlName="purchaseQty">
                        <div class="input-group-append" *ngIf="!isPackagedItem">
                            <span class="input-group-text" id="dosage-addon">{{ getSalesUom() }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="isDrug()" class="row" [formGroup]="prescriptionItem">
                <!-- Route Of Administration -->
                <div class="col-md-4 mb-1">
                    <label class="mb-0">Route of Administration</label>
                    <ng-select name="routeOfAdministrations"
                        class='p-0 form-control form-control-sm  custom-select custom-select-almost-medium-width'
                        bindLabel='name' bindValue='name' [items]="routeOfAdministrations" [clearable]=true
                        [searchFn]="searchRouteFn" formControlName='routeOfAdministration' appendTo="body">
                        <ng-template ng-header-tmp>
                            <div class="row">
                                <div class="col-md-8 font-weight-semi-bold">Name</div>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="row">
                                <div class="col-md-8">{{item.name}}</div>
                            </div>
                        </ng-template>
                    </ng-select>
                    <errors [control]="prescriptionItem.get('routeOfAdministration')"></errors>
                </div>
                <!-- Remarks -->
                <div class="col-md-8 mb-1">
                    <label class="mb-0">Remarks</label>
                    <input class="form-control form-control-sm" type="text" formControlName="remark">
                </div>
            </div>
            <!-- Remarks -->
            <div *ngIf="!isDrug()" class="row" [formGroup]="prescriptionItem">
                <div class="col-md-2">
                    <label class="mb-0">Remarks</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input class="form-control form-control-sm" type="text" formControlName="remark">
                </div>
            </div>
            <!-- Adjusted Remarks -->
            <div *ngIf="isDiscountShown" [formGroup]=" prescriptionItem.get('priceAdjustment')" class="row pb-1">
                <div class="col-md-2 pr-0">
                    <label class="mb-0">Adj Remarks</label>
                </div>
                <div class="col-md-10 mb-1">
                    <input class="form-control form-control-sm" type="text" formControlName="remark">
                </div>
            </div>
        </div>

    </div>
