import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';

import { AkitaDefaultVitalsStore } from './akita-default-vitals.store';
import { VitalConfiguration } from '../../objects/state/VitalConfiguration';

@Injectable({ providedIn: 'root' })
export class AkitaDefaultVitalsQuery extends QueryEntity<VitalConfiguration> {
  defaultVitalList$ = this.selectAll();

  constructor(protected store: AkitaDefaultVitalsStore) {
    super(store);
  }
}
