import { DocViewerComponent } from './doc-viewer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  declarations: [
    DocViewerComponent
  ],
  entryComponents: [
    DocViewerComponent
  ],
  imports: [
    CommonModule,
    NgxDocViewerModule,
  ],
  exports: [
    DocViewerComponent
  ],
})
export class DocViewModule {}