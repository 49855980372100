import { ValidatorFn, AbstractControl, FormArray } from '@angular/forms';

// Array Validators
export class ArrayValidators {
  // max length
  public static maxLength(max: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) return;
      return control.length > max ? { maxLength: true } : null;
    };
  }

  // min length
  public static minLength(min: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) return;
      const cleanedArray = control.controls.filter(formControl => {
        return (
          formControl.value.id !== '' &&
          formControl.value.id !== null &&
          formControl.value.id !== undefined
        );
      });
      return cleanedArray.length < min ? { minLength: true } : null;
    };
  }

  public static minLengthWithKey(min: number, idKey: string): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) return;
      const cleanedArray = control.controls.filter(formControl => {
        return !!formControl.value[idKey];
      });
      return cleanedArray.length < min ? { minLength: true } : null;
    };
  }
}
