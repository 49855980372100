<div class="card">
    <!-- <div class="card-header font-weight-semi-bold d-flex justify-content-between">
        <span>{{ title }}</span>
        <button class="btn-sm-sq btn-outline-gray-200 bg-white align-items-center" (click)="isExpanded=!isExpanded">
            <i class="text-black" [ngClass]="{'icon-up-open':isExpanded,'icon-down-open':!isExpanded}"></i>
        </button>
    </div> -->
    <div class="card-body" style="background-color:#f0f3f5">
        <div class="container-fluid px-0">
            <!-- TITLE -->
            <div class="row mb-2">
                <div class="col-md-12">
                    <!-- <textarea cols="10" rows="5" class="form-control" [formControl]="clinicNotes"></textarea> -->
                    <textarea *ngIf="!innerHTMLMode" cols="10" rows="5" class="form-control" [formControl]="notes"></textarea>
                    <div *ngIf="innerHTMLMode" [innerHTML]="notes.value"></div>
                </div>
            </div>
        </div>
    </div>

</div>