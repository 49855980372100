import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-case-manager-email-confirmation',
  templateUrl: './case-manager-email-confirmation.component.html',
  styleUrls: ['./case-manager-email-confirmation.component.scss']
})
export class CaseManagerEmailConfirmationComponent implements OnInit {

  @Output() emailUpdated = new EventEmitter<string>();

  patientEmail: string;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {}

  closeModal() {
    this.bsModalRef.hide();
  }

  confirmEmail() {
    this.emailUpdated.emit(this.patientEmail);
    this.bsModalRef.hide();
  }
}
