import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';

import { AkitaCaseVisitStore } from '../services/states/akita-case-visit.store';
import { CaseManagerService } from '../views/components/case-manager/case-manager.service';

@Injectable({ providedIn: 'root' })
export class CaseDetailResolverService implements Resolve<any> {
  constructor(
    private akitaCaseVisitStore: AkitaCaseVisitStore,
    private caseManagerService: CaseManagerService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.akitaCaseVisitStore.reset();

    if (route.params['caseId'] && route.params['caseId'].length > 0) {
      const caseId = route.params['caseId'];
      this.caseManagerService.retrieveCase(caseId).pipe(tap(data => {
        if (route.children && route.children.length > 0) {
          const visitId = route.children[0].params.visitId;
          if (visitId && visitId.length > 0) {
            this.akitaCaseVisitStore.setActive(visitId);
          } else this.akitaCaseVisitStore.setActive(null);
        }
      })).subscribe(data => {
        if (!data) return;
      });
    }
  }
}
