import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import DatePickerConfig from '../../../../objects/DatePickerConfig';
import { HttpResponseBody } from '../../../../objects/response/HttpResponseBody';
import { ChargeItem } from '../../../../objects/state/ChargeItem';
import { AlertService } from '../../../../services/alert.service';
import { AkitaChargeItemQuery } from '../../../../services/states/akita-charge-item.query';
import {
  DISPLAY_DATE_FORMAT,
  ISSUE_TYPE,
} from './../../../../constants/app.constants';
import { ApiCmsManagementService } from './../../../../services/api-cms-management.service';
@Component({
  selector: 'app-ongoing-medication-item',
  templateUrl: './ongoing-medication-item.component.html',
  styleUrls: ['./ongoing-medication-item.component.scss'],
})
export class OngoingMedicationItemComponent implements OnInit, OnDestroy {
  @Input() item: FormGroup;
  @Input() editMode = true;
  searchKey: FormControl;
  minDate: Date;
  items;
  loading = false;
  issueType;
  codesTypeahead = new Subject<string>();
  datePickerConfig: DatePickerConfig;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    private apiCmsManagementService: ApiCmsManagementService,
    private alertService: AlertService,
    private akitaChargeItemQuery: AkitaChargeItemQuery
  ) { }

  ngOnInit() {
    if (this.editMode)
      this.item.enable();
    else
      this.item.disable();

    this.issueType = ISSUE_TYPE;
    this.searchKey = new FormControl();
    this.datePickerConfig = new DatePickerConfig(
      '',
      null,
      this.minDate,
      'left',
      'top'
    );
    this.init();

    this.item
      .get('startDate')
      .valueChanges.pipe(
        map(d => {
          d = moment(d, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
          const isValid = moment(d, DISPLAY_DATE_FORMAT).isValid();
          return isValid ? d : '';
        }),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(data => {
        this.item.get('startDate').patchValue(data, { emitEvent: false });
      });

    this.onFilterInputChanged();
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  init() {
    this.minDate = new Date();
    this.initAkitaDrugList();
  }

  initAkitaDrugList() {
    this.akitaChargeItemQuery.selectAll({
      filterBy: (entity: ChargeItem) =>
        entity.status === 'ACTIVE' && entity.itemType === 'DRUG',
    }).pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => { this.items = data });
  }

  onClear() {
    this.initAkitaDrugList();
    const itemCode = this.item.get('itemCode');
    itemCode.setValidators(Validators.required);
    itemCode.markAsTouched();
    itemCode.updateValueAndValidity();
  }

  onDrugSelect(event) {
    if (event !== undefined) {
      this.item.patchValue({
        itemCode: event.code,
        medicationName: event.name,
      });
    }
  }

  onTypeSelected(event) {
    this.item.get('type').patchValue(event.value);
  }

  onFilterInputChanged() {
    try {
      this.codesTypeahead
        .pipe(
          filter(input => {
            if (!input) {
              this.initAkitaDrugList();
              return false;
            } else return true;
          }),
          takeUntil(this.componentDestroyed),
          distinctUntilChanged((a, b) => a === b),
          tap(() => (this.loading = true)),
          debounceTime(500),
          switchMap((term: string) => {
            return this.apiCmsManagementService
              .searchListItemByKeyWord(encodeURI(term))
              .pipe(
                catchError(error =>
                  of(<HttpResponseBody>{ page: {}, payload: '' })
                )
              );
          }),
          catchError(errors => of(<HttpResponseBody>{ page: {}, payload: '' }))
        )
        .subscribe(
          data => {
            this.loading = false;
            if (data && data.payload) {
              const payload = data.payload.filter(
                item => item.item.itemType === 'DRUG'
                //  && item.item.status === 'ACTIVE'
              );

              const d = [];
              payload.forEach(item => {
                d.push(item.item);
              });

              this.items = d;
            }
          },
          err => {
            this.loading = false;
            this.alertService.error(JSON.stringify(err.error.message));
          }
        );
    } catch (err) {
      console.error('Search Diagnosis Error', err);
    }
  }

  setTouched() {
    this.item.get('type').markAsTouched();
    this.item.get('type').updateValueAndValidity();
  }
}
