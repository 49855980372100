import { Component } from '@angular/core';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html',
})

export class AppAsideComponent {
  constructor() {}
}
