<div id="alert" *ngIf="message" [ngClass]="{ 'alert': message,
'alert-success': message.type === 'success',
'alert-danger': message.type === 'warn'||message.type === 'error' }">
  <!-- <div id="alert" *ngIf="message" class="alert"> -->
  <div class="float-right">

    <i class="icon-cancel" (click)="toggleHide()"></i>

  </div>
  <div *ngIf="message.type==='success'">
    <strong>Success!</strong>
    <br>
    <br> {{message.text}}.

  </div>
  <div *ngIf="message.type==='error'">
    <span *ngIf="message.showHeader"><strong>Oops!</strong> Something went wrong.
      <br>
      <br> </span>
    {{message.text}}.
  </div>
  <div *ngIf="message.type==='warn'">
    <br> {{message.text}}.
  </div>
</div>
