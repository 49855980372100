import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AkitaStoreService } from './states/akita-store.service';
import { AkitaAppQuery } from './states/akita-app.query';
import { Observable } from 'rxjs';
import { tap, filter, map, take, skipWhile } from 'rxjs/operators';
import { LoaderService } from '../components/loading/loader.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateChildrenService implements CanActivateChild {
  constructor(
    private akitaAppQuery: AkitaAppQuery,
    private akitaStoreService: AkitaStoreService,
    private loaderService: LoaderService
  ) { 
  
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.akitaAppQuery.selectStoreReady().pipe(
      tap(isStoreReady => {
        if (!isStoreReady) {
          this.loaderService.setLoading(true);
        } else {
          this.loaderService.setLoading(false);
        }
      }),
      skipWhile(data => !data),
      take(1)
    );
  }

}
