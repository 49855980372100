import { AkitaPatientAppQuery } from './../../../../services/states/akita-patient/akita-patient-app.query';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AlertService } from '../../../../services/alert.service';
import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';
import { StoreService } from '../../../../services/store.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { PatientVisitHistoryQuery } from '../../../../services/states/akita-patient/patient-visit-history/patient-visit-history.query';

@Component({
  selector: 'app-patient-history-detail-edit-note',
  templateUrl: './patient-history-detail-edit-note.component.html',
  styleUrls: ['./patient-history-detail-edit-note.component.scss'],
})
export class PatientHistoryDetailEditNoteComponent implements OnInit {
  @Input() consultationNotes: FormControl;

  title: string;
  templates: {};
  template: any;
  content: string;
  isAppend = true;

  public event: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private store: StoreService,
    private apiCmsManagementService: ApiCmsManagementService,
    private alertService: AlertService,
    private permissionsService: NgxPermissionsService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private patientVisitHistoryQuery: PatientVisitHistoryQuery
  ) { }

  ngOnInit() {
    this.templates = this.store.getTemplatesByTemplateCategory('CONSULTATION');
  }

  onTemplateChange(event) {
    if (event) {
      this.apiCmsManagementService
        // .loadTemplate(event.type, event.id, this.store.getUser().context['cms-user-id'], this.akitaPatientAppQuery.getId())
        .loadTemplateWithVisitId(
          event.type,
          event.id,
          this.store.getUser().context['cms-user-id'],
          this.akitaPatientAppQuery.getId(),
          '' + this.patientVisitHistoryQuery.getActiveId()
        )
        .subscribe(
          res => {
            if (res.payload) {
              if (this.isAppend) {
                const data = this.consultationNotes.value
                  ? this.consultationNotes.value
                  : '';
                this.consultationNotes.patchValue(data + '<br>' + res.payload);
              } else {
                this.consultationNotes.patchValue(res.payload);
              }
            }
          },
          err => {
            this.alertService.error(JSON.stringify(err));
          }
        );
    }
  }

  onConsultationBtnSaveClicked() {

    this.event.emit({
      formName: 'CONSULTATION',
      key: 'consultationNotes',
      value: this.consultationNotes.value
    });
  }

  isDoctor() {
    return this.permissionsService.getPermission('ROLE_DOCTOR');
  }
}
