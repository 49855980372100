import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';

@Pipe({
  name: 'displayInstruction',
})
export class DisplayInstructionPipe implements PipeTransform {
  constructor(private storeService: StoreService) {}

  transform(value: any, args?: any): any {
    let instruction = this.storeService.getInstructionByCode(value)
      ? this.storeService.getInstructionByCode(value).instruct
      : undefined;

    if (instruction === undefined || instruction === null || instruction === '(leave blank)') instruction = '';

    return instruction;
  }
}
