<div class="card mb-4">
    <div class="card-header font-weight-semi-bold bg-sandy-yellow d-flex justify-content-between  pr-0 py-0" (click)="clinicNotesToggle()">
        <span>Note to Clinic Staff</span>
        <div *ngIf="isDispensingTab()" class="col-auto text-white pt-1" style="background-color: #4a4a4a;">
            <span [ngClass]="{'icon-left-open': !expanded, 'icon-right-open': expanded}"></span>
        </div>
        <button *ngIf="isConsultationTab()" class="btn-sm-sq btn-outline-gray-200 bg-white align-items-center" (click)="showBody=!showBody">
            <i class="text-black" [ngClass]="{'icon-up-open':showBody,'icon-down-open':!showBody}"></i>
        </button>
    </div>
    <div *ngIf="isConsultationTab()" class="card-body" [hidden]="hideBody()">
        <div class="container-fluid px-0">
            <!-- TITLE -->
            <div class="row mb-2">
                <div class="col-md-12">
                    <textarea cols="10" rows="5" class="form-control" [formControl]="clinicNotes"></textarea>
                </div>
            </div>
        </div>
    </div>
    <!-- {{clinicNotes.value }} -->
    <div *ngIf="isDispensingTab()" [hidden]="hideBody()">
        <textarea class="form-control pt-2 px-2 notes-scroll scrollable bg-white" style="border-style:none;" [formControl]="clinicNotes"></textarea>
    </div>
</div>