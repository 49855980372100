import {
  FormGroup,
  ValidatorFn,
  AbstractControl,
  FormControl,
} from '@angular/forms';
// TODO: make sure it is working with FormGroup

/**
 *
 *
 * @export
 * @param {FormGroup} group
 * @returns {null|Object} Null if valid.
 */
export function IntValidator(): ValidatorFn | any {
  return (control: AbstractControl) => {
    const value = control.value;
    return new RegExp('^[0-9][^\\.]*$').test(value)
      ? null
      : { intValidator: false };
  };
}

export function outOfStockRemarksValidator(): ValidatorFn | any {
  return (control: AbstractControl) => {
    const value = control.value;
    if (value == null || value == undefined || value == '') {
      return null;
    } else {
      return {
        maxlengthOutOfStock: {
          value: control.value,
        },
      };
    }
  };
}

outOfStockRemarksValidator;
