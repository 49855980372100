import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { VitalConfiguration } from '../../objects/state/VitalConfiguration';

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'defaultvitals',
  idKey: 'code',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaDefaultVitalsStore extends EntityStore<VitalConfiguration> {
  constructor() {
    super();
  }
}
