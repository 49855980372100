import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from '../services/store.service';
import { InventoryService } from '../services/inventory.service';
import { TransferOrder, PurchaseOrder } from '../objects/PurchaseOrder';

@Pipe({
  name: 'inventoryOrderDetailDeliverName'
})
export class InventoryOrderDetailDeliverNamePipe implements PipeTransform {
  constructor(private store: StoreService, private inventory: InventoryService) {}

  transform(value: any, args?: any): any {

    let orderType = args;

    if (!value || !orderType) {
      return '';
    }

    if (this.inventory.isPurchaseOrder(orderType)) {
      const supplier = (<PurchaseOrder>value).getSupplierDetail(this.store);
      if (supplier) {
        return supplier.name;
      }
    } else if (this.inventory.isTransferOrder(orderType)) {
      const clinic = (<TransferOrder>value).getSenderClinic(this.store);
      if (clinic) {
        return `${clinic.clinicCode} / ${clinic.name}`;
      }
    }
  }

}
