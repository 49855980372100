import * as moment from "moment"
import { ExercisePlanValue } from "./CarePlanExerciseRecommendation"

export class VaccinationRecommendation {
    constructor(
        readonly code: string,
        readonly description: string,
        readonly type: string,
        readonly selected?: boolean,
        readonly nextDoseDate?: string | null,
    ) { }

    static adapt(item: any): VaccinationRecommendation {
        return new VaccinationRecommendation(
            item.code,
            item.description,
            item.type,
            item.selected || false,
            item.nextDoseDate || null,
        )
    }

    static toServer(item: VaccinationRecommendation): ExercisePlanValue {
        let data: any = {
            placeholder: item.code,
            value: item.description,
        }

        if (item.nextDoseDate) {
            data.contextValue = item.nextDoseDate as any instanceof Date ?  moment(item.nextDoseDate).format('DD-MM-YYYY') : item.nextDoseDate
        }

        return data;
    }
}