export const PatientConditions = [
    { name: 'Pre Diabetes', code: 'PRE_DIABETES', goalId: '3' },
    { name: 'Diabetes Mellitus', code: 'DIABETES', goalId: '3' },
    { name: 'Hypertension', code: 'HYPERTENSION', goalId: '4' },
    { name: 'Hyperlipidemia (Lipid Disorders)', code: 'LIPID_DISORDERS', goalId: '5' },
];

export const PLAECHOLDERS_TO_OMIT_FOR_OLD_CARE_PLAN_VERSION: Array<string> = ['GM0032', 'GM0043', 'GM0063', 'GM0012'];

export const LEAVE_PLAN_TITLE = 'Leave Plan';
export const GO_BACK_CONFIRMATION_MESSAGE = 'Are you sure you want to leave? Please make sure you have saved before leaving as your unsaved data will be lost. If you have saved, please click on "Confirm" to go back.';

export const DELETE_PLAN_TITLE = 'Delete Plan';
export const DELETE_PLAN_CONFIRMATION_MESSAGE = 'Are you sure you want to delete selected plan?';

export const REMOVE_GOAL_TITLE = 'Delete Plan';
export const REMOVE_GOAL_CONFIRMATION_MESSAGE = 'Are you sure you want to remove this goal? This goal is linked to a patient condition.';