import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

import {
  DB_FULL_DATE_FORMAT,
  DISPLAY_DATE_TIME,
} from '../constants/app.constants';

@Pipe({
  name: 'displayFullDate',
})
export class DisplayFullDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const date = moment(value, DB_FULL_DATE_FORMAT);

    return date.format(DISPLAY_DATE_TIME);
  }
}
