import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Page } from '../model/page';
import { DeliveryOrder } from '../objects/DeliveryOrder';
import { OrderGRN } from '../objects/OrderGRN';
import { OrderReturnRequest } from '../objects/OrderReturnRequest';
import { PurchaseRequest } from '../objects/PurchaseOrder';
import { OrderListRequest } from '../objects/request/OrderListRequest';
import { OrderReturnRequestListRequest } from '../objects/request/OrderReturnRequestListRequest';
import { StartStockTakeRequest } from '../objects/request/StartStockTakeRequest';
import { StockAdjustment } from '../objects/request/StockAdjustment';
import { StockAdjustmentListFilter } from '../objects/request/StockAdjustmentListFilter';
import { StockListFilter } from '../objects/request/StockListFilter';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { StockTakeItem } from '../objects/StockTake';
import { AppConfigService } from './app-config.service';
import { BackendService } from './backend.service';
import {DisposalItemList} from "../objects/request/disposal-item-list";
import {DisposalItems} from "../objects/disposal-items";
import {NirSearchList} from "../objects/request/nir-search-list";
import {NirItems} from "../objects/response/nir-items";

@Injectable({
  providedIn: 'root',
})
export class ApiInventoryService {
  private API_INVENTORY_URL: string;
  private API_PATIENT_VISIT_URL;

  constructor(
    private http: HttpClient,
    appConfig: AppConfigService,
    private backendService: BackendService
  ) {
    this.API_INVENTORY_URL = appConfig.getConfig().API_INVENTORY_URL;
    this.API_PATIENT_VISIT_URL = appConfig.getConfig().API_PATIENT_VISIT_URL;
  }

  listAllRequest(
    clinicId: string,
    filter: OrderListRequest,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/order-request/list/${clinicId}/${page.pageNumber}/${page.size}`,
      JSON.stringify(filter)
    );
  }

  listAllDrugItem(clinicId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/inventory/list/drug-item/${clinicId}`,
      JSON.stringify({})
    );
  }

  getRequestById(id: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/get/request/${id}`,
      JSON.stringify({})
    );
  }

  getOrderById(id: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/get/order/${id}`,
      JSON.stringify({})
    );
  }

  createPurchaseRequest(
    request: PurchaseRequest
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/create/request`,
      JSON.stringify(request)
    );
  }

  updatePurchaseRequest(
    id: string,
    request: PurchaseRequest
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/update/request/${id}`,
      JSON.stringify(request)
    );
  }

  listCurrentStockGroupByItem(
    clinicId: string,
    itemGroup: string,
    filter: StockListFilter,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/search/item/${clinicId}/${itemGroup}/${page.pageNumber}/${page.size}`,
      JSON.stringify(filter)
    );
  }

  getItemUomRatio(
    baseUom: string,
    sourseUom: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/search/item/uom-ratio/${baseUom}/${sourseUom}`, undefined
    );
  }

  getStockAdjustmentReason(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_INVENTORY_URL}/stock/list/adjustment/reason`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  getReturnRequestItemReason(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_INVENTORY_URL}/purchase/list/grvn/reason`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  getPurchaseRequestJustification(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_INVENTORY_URL}/purchase/list/request/justification`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  adjustStock(
    clinicId: string,
    stockAdjustment: StockAdjustment
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/adjustment/stock-take/${clinicId}`,
      JSON.stringify(stockAdjustment)
    );
  }

  startStockTake(
    clinicId: string,
    startStockTake: StartStockTakeRequest
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/start/stock-take/${clinicId}`,
      JSON.stringify(startStockTake)
    );
  }

  listStockTake(
    clinicId: string,
    page: Page,
    requestBody: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/list/stock-take/${clinicId}/${page.pageNumber}/${page.size}`,
      JSON.stringify(requestBody)
    );
  }
  listNirTakeList(
    clinicId: string,
    page: Page,
    requestBody: NirSearchList

  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/vaccinationnir/list/${clinicId}/${page.pageNumber}/${page.size}`,
      JSON.stringify(requestBody)
    );
  }

  submitNirItems(
    clinicId: string,
    vaccinationId: string,
    filter: NirItems ,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PATIENT_VISIT_URL}/vaccinationnir/submit/${clinicId}/${vaccinationId}`,
      JSON.stringify(filter)

    );


  }

  saveAndStopStockTake(
    stockTakeId: string,
    items: Array<StockTakeItem>
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/stop/stock-take/${stockTakeId}`,
      JSON.stringify(items)
    );
  }

  submitStockTake(stockTakeId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/submit/stock-take/${stockTakeId}`,
      {}
    );
  }

  convertUom(
    sourceUom: string,
    targetUom: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/uom-matrix/search/ratio/${sourceUom}/${targetUom}`,
      {}
    );
  }

  convertUomByListOfItemId(itemList: any[]): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/uom-matrix/search-multiple/ratio/`,
      itemList
    );
  }

  getSupplierInfo(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_INVENTORY_URL}/supplier/list/all`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  getItemSupplierInfo(
    clinicId: string,
    itemId: string,
    uom: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/supplier/get/item/${clinicId}/${itemId}/${uom}`,
      {}
    );
  }

  getItemSupplierInfoByListOfItemId(
    clinicId: string,
    itemList: any[]
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/supplier/list-by-items/${clinicId}`,
      itemList
    );
  }

  addGRN(orderId: string, orderGrn: OrderGRN): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/add/grn/${orderId}`,
      JSON.stringify(orderGrn)
    );
  }

  updateGRN(
    orderId: string,
    grnId: string,
    orderGrn: OrderGRN
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/update/grn/${orderId}/${grnId}`,
      JSON.stringify(orderGrn)
    );
  }

  getReturnRequestList(
    clinicId: string,
    filter: OrderReturnRequestListRequest,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/order-request/list/return-order/${clinicId}/${page.pageNumber}/${page.size}`,
      JSON.stringify(filter)
    );
  }

  getDisposalList(
    clinicId: string,
    filter: DisposalItemList,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/disposal/list/${clinicId}/${page.pageNumber}/${page.size}`,
      JSON.stringify(filter)
    );
  }

  updateDisposalItem(
    clinicId: string,
    filter: DisposalItems,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/disposal/mark/complete/${clinicId}`,
      JSON.stringify(filter)
    );
  }

  getReturnRequestById(
    returnId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/get/grvn/${returnId}`,
      {}
    );
  }

  addReturnRequest(
    requestClinicId: string,
    supplierId: string,
    returnOrderStatus: string,
    returnReq: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/add/grvn/${requestClinicId}/${supplierId}/${returnOrderStatus}`,
      JSON.stringify(returnReq)
    );
  }

  updateReturnRequest(
    returnRequestId: string,
    supplierId: string,
    returnRequestStatus: string,
    returnReq: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/update/grvn/${returnRequestId}/${supplierId}/${returnRequestStatus}`,
      JSON.stringify(returnReq)
    );
  }

  addReturnRequestDO(
    returnRequestId: string,
    deliveryOrder: DeliveryOrder
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/add/grvdn/${returnRequestId}`,
      JSON.stringify(deliveryOrder)
    );
  }

  updateReturnRequestDO(
    returnRequestId: string,
    deliveryOrderId: string,
    deliveryOrder: DeliveryOrder
  ) {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/update/grvdn/${returnRequestId}/${deliveryOrderId}`,
      JSON.stringify(deliveryOrder)
    );
  }

  addTODO(orderId: string, deliveryOrder: DeliveryOrder) {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/add/dn/${orderId}`,
      JSON.stringify(deliveryOrder)
    );
  }

  updateTODO(
    orderId: string,
    deliveryOrderId: string,
    deliveryOrder: DeliveryOrder
  ) {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/update/dn/${orderId}/${deliveryOrderId}`,
      JSON.stringify(deliveryOrder)
    );
  }

  getStockByItemId(
    clinicId: string,
    itemId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/inventory/list/${clinicId}/${itemId}`,
      {}
    );
  }

  downloadGRNFile(
    orderId: string,
    grnId: string,
    fileId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/document-management/download/grn/${orderId}/${grnId}/${fileId}`,
      {},
      { responseType: 'blob' as 'json' }
    );
  }

  getInventoryItemClinicDetail(
    clinicId: string,
    queue = false
  ): Observable<HttpResponseBody> {
    const url = `${this.API_INVENTORY_URL}/purchase/list/item-clinic-detail/${clinicId}`;
    const body = {};
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body, options);
  }

  listStockAdjustmentList(
    clinicId: string,
    itemGroup: string,
    filter: StockAdjustmentListFilter,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/list/adjustment/${clinicId}/${itemGroup}/${page.pageNumber}/${page.size}`,
      JSON.stringify(filter)
    );
  }

  listInventoryBySupplierId(clinicId: string, supplierId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/inventory/list/supplier/${clinicId}/${supplierId}`,
      JSON.stringify({})
    );
  }

  deleteDraftPR(id: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/delete/request/${id}`,
      JSON.stringify({})
    );
  }

  deleteIncompleteStockTake(id: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/delete/stock-take/${id}`,
      JSON.stringify({})
    );
  }

  deletePOFailedGRN(
    orderId: string,
    grnId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/delete/grn/${orderId}/${grnId}`,
      JSON.stringify({})
    );
  }

  deletePOFailedPRO(
    orderId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/delete/grvn/${orderId}`,
      JSON.stringify({})
    );
  }

  deletePROFailedDO(
    orderId: string,
    grvnDoId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/delete/grvn/do/${orderId}/${grvnDoId}`,
      JSON.stringify({})
    );
  }

  deleteTOFailedDO(
    orderId: string,
    doId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/delete/transfer/${orderId}/${doId}`,
      JSON.stringify({})
    );
  }

  deleteTOFailedGRN(
    orderId: string,
    grnId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/delete/transfer/grn/${orderId}/${grnId}`,
      JSON.stringify({})
    );
  }

  retryPRSubmission(
    clinicId: string,
    requestId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/retry/nav/request/${clinicId}/${requestId}`,
      JSON.stringify({})
    );
  }

  retryPROSubmission(
    clinicId: string,
    requestId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/retry/nav/grvn/${clinicId}/${requestId}`,
      JSON.stringify({})
    );
  }

  retryPRODOSubmission(
    clinicId: string,
    proId: string,
    doId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/retry/nav/grvdn/${clinicId}/${proId}/${doId}`,
      JSON.stringify({})
    );
  }

  retryPOGRNSubmission(
    clinicId: string,
    requestId: string,
    grnId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/retry/nav/grn/${clinicId}/${requestId}/${grnId}`,
      JSON.stringify({})
    );
  }

  retryTODOSubmission(
    clinicId: string,
    requestId: string,
    doId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/retry/nav/do/${clinicId}/${requestId}/${doId}`,
      JSON.stringify({})
    );
  }

  retryTOGRNSubmission(
    clinicId: string,
    requestId: string,
    grnId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/purchase/retry/nav/transfer-grn/${clinicId}/${requestId}/${grnId}`,
      JSON.stringify({})
    );
  }

  retryStockTakeSubmission(stockTakeId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/retry/nav/stock-take/${stockTakeId}`,
      JSON.stringify({})
    );
  }

  retryStockAdjustmentSubmission(
    stockTakeId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/retry/nav/adjustment/${stockTakeId}`,
      JSON.stringify({})
    );
  }

  getStockTakeById(stockTakeId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_URL}/stock/search/stock-take/${stockTakeId}`,
      JSON.stringify({})
    );
  }

}
