import { Pipe, PipeTransform } from '@angular/core';
import { InventoryService } from '../services/inventory.service';

@Pipe({
  name: 'inventoryDisplayStockAdjustmentVariance'
})
export class InventoryDisplayStockAdjustmentVariancePipe implements PipeTransform {

  constructor(private inventory: InventoryService) {}

  transform(value: any, args?: any): any {

    if (!value) return '';

    const variance = this.inventory.calculateStockAdjustmentVariance(value);
    if (variance < 0) {
      return `(${Math.abs(variance)})`;
    } else {
      return variance;
    }
  }

}
