import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { DISPLAY_DATE_FORMAT } from '../../../constants/app.constants';
import PatientInfo from '../../../objects/response/PatientInfo';
import { AlertService } from '../../../services/alert.service';
import { AllergiesFormService } from '../../../services/allergies-form.service';
import { ApiCmsManagementService } from '../../../services/api-cms-management.service';
import { ApiPatientInfoService } from '../../../services/api-patient-info.service';
import { ApiSmartCmsManagementService } from '../../../services/api-smart-cms-management.service';
import { AkitaPatientAppQuery } from '../../../services/states/akita-patient/akita-patient-app.query';
import { StoreService } from '../../../services/store.service';
import { CustomConfirmPopupComponent } from '../../../views/components/custom-confirm-popup/custom-confirm-popup.component';
import { LoaderService } from '../../loading/loader.service';
import { PatientDetailsAllertAdrCmisViewPopupComponent } from './patient-details-allert-adr-cmis-view-popup/patient-details-allert-adr-cmis-view-popup.component';

@Component({
  selector: 'app-patient-detail-allergy-alert',
  templateUrl: './patient-detail-allergy-alert.component.html',
  styleUrls: ['./patient-detail-allergy-alert.component.scss']
})
export class PatientDetailAllergyAlertComponent implements OnInit {

  private componentDestroyed: Subject<void> = new Subject();
  alertFormGroup: FormGroup;
  medicalAlertFormGroup: FormGroup;
  saveInPopup: boolean = true;
  cmisAlerts: any[];
  cmisMedicalAlerts: any[];
  title: string;
  drugList: any;
  allergyGroupList: any;
  showcmisButton: boolean = true;
  isNewPatient: boolean = false;


  constructor(
    private allgergiesFormService: AllergiesFormService,
    private apiCmsManagementService: ApiSmartCmsManagementService,
    private alertService: AlertService,
    private store: StoreService, 
    private akitaPatientAppQuery: AkitaPatientAppQuery,    
    private allergiesFS: AllergiesFormService,
    private apiPatientInfoService: ApiPatientInfoService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,    
    private fb: FormBuilder,
    private loaderService: LoaderService,
  ) { }

  ngOnInit() {
  }

  onBtnExit() {
    this.allgergiesFormService.setManageAllergyModalStatus(true);
  }

  onBtnSave() {
    if (!this.alertFormGroup.valid || !this.medicalAlertFormGroup.valid) {
      this.alertFormGroup.markAllAsTouched();
      this.alertFormGroup.updateValueAndValidity();
      this.medicalAlertFormGroup.markAllAsTouched();
      this.medicalAlertFormGroup.updateValueAndValidity();
      return;
  }

  if (this.saveInPopup) {
    this.allgergiesFormService.setSaveAlertsAndAllergies(true);
  } else {
    this.bsModalRef.hide();
  }
  }

  doViewCMIS() {
    this.showcmisButton = false;
    const clinicid = this.store.getClinicId();
    const patientId = this.akitaPatientAppQuery.getId();
    this.apiCmsManagementService.viewCMIS(clinicid, patientId).subscribe(
      	res => {
              if (res.payload) {
                if (res.payload.adrdetails) {
                  res.payload.adrdetails.forEach(adrItem => {
                    adrItem['viewDetails'] =  false;
                    if (adrItem.reactionOutcome){
                      const reactionOutcomeStr = adrItem.reactionOutcome.map(x=>x.reactionOutcomeDesc).join(", ");
                      adrItem['reactionOutcomeStr'] = reactionOutcomeStr.toString();
                    }
                    if (adrItem.createdDate){
                      adrItem['createdDateStr'] =  moment(adrItem.createdDate).format(DISPLAY_DATE_FORMAT);
                    }

                    if (adrItem.drug) {
                      let mainDrug = adrItem.drug.filter(drug => drug.drugTypeCode === 'S');
                      adrItem['drugProbabilityDesc'] = mainDrug.map(x=>x.drugProbabilityDesc).join(", ");
                      adrItem['mainDrugName'] = mainDrug.map(x=>x.drugDisplayDesc).join(", ");

                      let additionalNames = adrItem.drug.filter(drug => drug.drugTypeCode === 'C');
                      adrItem['additionalNames'] = additionalNames.map(x=>x.drugDisplayDesc).join(", ");

                    }
                  });
                  this.cmisAlerts = res.payload.adrdetails;
                }

                if (res.payload.medicalAlertSummary) {
                  this.cmisMedicalAlerts = res.payload.medicalAlertSummary;
                  this.cmisMedicalAlerts.forEach(alert => {
                    alert['viewDetails'] =  false;
                    if(alert.alertStartDate) {
                      alert['alertStartDateStr'] =  moment(alert.alertStartDate).format(DISPLAY_DATE_FORMAT);
                    }

                    if(alert.alertStatus) {
                      alert['alertStatusStr'] =  alert.alertStatus === 'N' ? 'Inactive' : 'Active';
                    }

                    if(alert.alertEndDate) {
                      alert['alertEndDateStr'] =  moment(alert.alertEndDate).format(DISPLAY_DATE_FORMAT);
                    }

                  });


                }

                this.viewCMISDataPopup();
                this.showcmisButton = true;
              }
      	},
      	err => {
      		this.alertService.error('Failed while loading data from  CMIS , Please try again later.');
          this.showcmisButton = true;
      	}
      );

  }

  viewCMISDataPopup() {
    const initialState = {
      title: 'CMIS Allergy Information',
      cmisAlerts: this.cmisAlerts,
      cmisMedicalAlerts: this.cmisMedicalAlerts,
    };
    this.bsModalRef = this.modalService.show(PatientDetailsAllertAdrCmisViewPopupComponent, {
      initialState,
      class: 'modal-mxl custom-modal',
      backdrop: 'static',
      keyboard: false,
    });
  }

  // doCheckCMIS() {
  //   let alertArray = this.alertFormGroup.get('alertArray') as FormArray;
  //   let newObjects = alertArray.controls.filter(form => form.get("isNewObject").value);
  //   if (newObjects.length > 0) {
  //     const buttonData = [{
  //       isVisible: true,
  //       description: 'Save',
  //       code: 'SAVEANDLOAD'
  //       }, 
  //       {
  //         isVisible: true,
  //         description: 'discard',
  //         code: 'DISCARDANDLOAD'
  //         } 
  //       ]
  //     const initialState = {
  //       isModal: true,
  //       displayMsg: 'You have unsaved data, Please select and action to perform',
  //       cancelBtnText: 'Cancel',
  //       cancelBtnShow: true,
  //       buttonData: buttonData,
  //       title: 'Confirmation',
  //     };
  
  //     this.bsModalRef = this.modalService.show(CustomConfirmPopupComponent, {
  //       initialState,
  //       class: 'modal-md',
  //       backdrop: 'static',
  //       keyboard: false,
  //     });
  //     this.bsModalRef.content.closeBtnName = 'Close';
  
  //     this.bsModalRef.content.closeEvent.subscribe(data => {
  //       this.bsModalRef.hide();
  //       this.bsModalRef.content.closeEvent.unsubscribe();
  //     });
  
  //     this.bsModalRef.content.customBtnEvent.subscribe(data => {
  //       if (data === 'DISCARDANDLOAD') {
  //         this.loadCMISData();
  //       }

  //       if (data === 'SAVEANDLOAD') {
  //         this.onBtnSave();
  //         this.loadCMISData();
  //       }
  
  //       this.bsModalRef.hide();
  //       this.bsModalRef.content.customBtnEvent.unsubscribe();      
  //     });

  //     return;
  //   }

  //   this.loadCMISData();
    
  // }

  // loadCMISData() {
  //   const clinicid = this.store.getClinicId();
  //   const patientId = this.akitaPatientAppQuery.getId();
  //   this.apiCmsManagementService.checkCMIS(clinicid, patientId).subscribe(
  //     	res => {
  //     		    let alertArray = this.alertFormGroup.get('alertArray') as FormArray;
  //             if (res.payload) {
  //               alertArray.clear();
  //               res.payload.allergies.forEach(element => {
  //                 let item = this.allergiesFS.addAllergy(element);
  //                 alertArray.push(item);
  //               });
  //             }
  //     	},
  //     	err => {
  //     		this.alertService.error(JSON.stringify(err.error.message));
  //     	}
  //     );
  // }
  
}