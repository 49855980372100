import { Injectable } from "@angular/core";
import { Observable,Observer, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { ChatMessage } from "../objects/ChatMessage";
import { AppConfigService } from "./app-config.service";
import { AkitaCaseVisitQuery } from "./states/akita-case-visit.query";
import { WebsocketService } from "./websocket.service";



@Injectable()
export class ChatService {
  private WEB_SOCKET_CHAT_URL;
  public messages: Subject<ChatMessage>;

  constructor(private wsService: WebsocketService,
    private appConfig: AppConfigService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,) {
  
      this.WEB_SOCKET_CHAT_URL = appConfig.getConfig().WEB_SOCKET_CHAT_URL;
  }

  getVisitId(): string {
    return this.akitaCaseVisitQuery.getActive().visitId;
  }

  initiateChatService(){
    try {
      let visitId=localStorage.getItem("visitId");
      let tokenValue=localStorage.getItem('access_token');
      let tenantId=localStorage.getItem('tenantId');
      let finalChatUrl=encodeURI(this.WEB_SOCKET_CHAT_URL + '?token=Bearer ' + tokenValue + '&topic=' + visitId
        + '&X-Tenant=' + tenantId); 
   
   
    this.wsService.closeAllSockets(finalChatUrl);
    
    this.messages = <Subject<ChatMessage>>this.wsService.connect(finalChatUrl).pipe(map(
      (response: MessageEvent): ChatMessage => {
       
        let data = JSON.parse(response.data);
        return {
            "sequence": data.sequence,
            "type": data.type,
            "payload": data.payload
           
        };
      }
    ));
  } catch (err) {
    console.error('Ssocket connection Error', err);
  }
  }
  closeChat(){
    let visitId=localStorage.getItem("visitIdChat");
    if(visitId!=null || visitId!=""){
      let tokenValue=localStorage.getItem('access_token');
      let finalChatUrl=encodeURI(this.WEB_SOCKET_CHAT_URL + '?token=Bearer ' + tokenValue +'&topic=' + visitId); 
      this.wsService.closeAllSockets(finalChatUrl);
      
    }

  }
}