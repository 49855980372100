<form [formGroup]="labRequestFormGroup" novalidate>
  <!-- <pre>{{ labRequestFormGroup.value | json }}</pre> -->
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnExit()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">

    <div class="row">
      <div class="col-4">
        <label style="margin-bottom: 0px;">Lab</label>
        <div formGroupName="labRequestForm">
          <ng-select class="p-0 custom-select" formControlName="supplierId" [items]="suppliers" bindLabel='label'
            bindValue='id' [clearable]='false' (change)="onSupplierChanged($event)" [readonly]="disabled">
          </ng-select>
        </div>
      </div>

      <div class="col-4">
        <label style="margin-bottom: 0px;">Doctor</label>
        <div formGroupName="labRequestForm">
          <ng-select class="p-0 custom-select" formControlName="doctorId" [items]="doctors" bindLabel='label'
            bindValue='id' [clearable]='false' (change)="onSelectDoctor($event)" [readonly]="disabled">
          </ng-select>
        </div>
      </div>

      <div class="col-4">
        <label style="margin-bottom: 0px;">Lab Reference No:</label>
        <div formGroupName="labRequestForm">
          <input class="form-control form-control-sm" formControlName="referenceNumber" required />
        </div>
      </div>
    </div>

    <div class="row  pt-2 pb-2 ml-0 mr-0 mb-2 mt-3" style="color: white; background: #9b9b9b">
      <div style="padding:2px 5px 0 10px;">
        <input type="checkbox" name="" (change)="onCheckAll($event.target.checked ? 'T':'F')"
          [(ngModel)]="checkboxValue" [ngModelOptions]="{standalone: true}" [disabled]="isFromPatientDetails || disabled">
      </div>
      <div class="col-md-2">
        Test Code
      </div>
      <div class="col-md-4">
        Test Name
      </div>
      <div class="col-md-2">
        Category
      </div>
      <div class="col-md-3">
        Remarks
      </div>
    </div>
    <form
      *ngFor="let labTestItem of labRequestFormGroup?.get('labRequestForm')?.get('labFormData').controls; let i = index;"
      novalidate>
      <app-payment-lab-request-form-item [labTestItem]="labTestItem" [disabled]="disabled"
        [labRequestForm]="labRequestFormGroup?.controls['labRequestForm']"
        [isFromPatientDetails]="isFromPatientDetails">
      </app-payment-lab-request-form-item>
    </form>

    <div class="default-panel-footer">
      <button *ngIf="isFromPatientDetails; else notFromPatientDetails" type="button" class="btn btn-light"
        (click)="addNewLabItem()" [disabled]="true">
        + Add Another Row
      </button>

      <ng-template #notFromPatientDetails>
        <button
          type="button"
          class="btn btn-light"
          (click)="addNewLabItem()"
          [disabled]="isSupplierIdEmpty() || disabled">
          + Add Another Row
        </button>
      </ng-template>

      <button
        type="button"
        class="btn delete-btn btn-pink"
        (click)="deleteLabItem()"
        [disabled]="isFromPatientDetails || disabled">
        <i class="icon-trash "></i> Delete Row(s)
      </button>
      <div class="clearfix"></div>
    </div>

  </div>
  <div *ngIf="isFromPatientDetails; else notFromPatientDetails2" class="modal-footer">
    <button type="button" class="btn btn-brand-primary" (click)="printRequestForm()">Print
      Request Form</button>
    <button type="button" class="btn btn-brand-primary" (click)="printLabLabel()">Print Lab
      Label</button>
  </div>

  <ng-template #notFromPatientDetails2>
    <div class="modal-footer">
      <button type="button" class="btn btn-brand-primary" (click)="printRequestForm()"
        [disabled]="!isFormValid()">Print
        Request Form</button>
      <button type="button" class="btn btn-brand-primary" (click)="printLabLabel()" [disabled]="!isFormValid()">Print
        Lab
        Label</button>
      <button type="button" class="btn btn-brand-primary" (click)="save()"
        [disabled]="(!(isFormValid() && isItemsAvailable()) && notDeletedAllItems() || disabled)">Save</button>
    </div>
  </ng-template>

</form>
