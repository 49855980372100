<div class="card scroll-y" [formGroup]='billAdjustmentFormGroup'>
    <div class="card-header py-1">
      BILL ADJUSTMENT
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <alert></alert>
      <div class="p-2">
        <div class="row">
          <div class="col-md-3">Primary Doctor <span class="compulsory">*</span></div>
          <div class="col-md-9">
            <ng-select class="form-control form-control-sm custom-select" [items]="primaryDoctorList" bindLabel='displayName' bindValue='id'
              [addTag]="false" [virtualScroll]="true" [multiple]=false formControlName="primaryDoctorId"></ng-select>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-3">Secondary Doctor(s)</div>
          <div class="col-md-9">
            <ng-select class="form-control form-control-sm custom-select-multiple" [items]="secondaryDoctorList$ | async" bindLabel='displayName' bindValue='id'
              [addTag]="false" [virtualScroll]="true" [multiple]=true formControlName="secondaryDoctorIds"></ng-select>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-3" *ngIf="isEdit">Medical Coverage</div>
          <div class="col-md-6" *ngIf="(attachedMedicalCoverages$ | async).length === 0 && isEdit; else hasMC">
            <a  (click)="updateMedicalCoverage(true)"
              [popover]="planInfoTemplate" placement="bottom" container="header"
             triggers="mouseenter:mouseleave" containerClass="plan-info-popover">
             <!-- <span>{{ (coverageNames$ | async) }}</span> -->
             <label class="cursor-pointer">Add Coverage</label>
           </a>
          </div>
          <ng-template #hasMC class="col-md-6 app-header-data-info link-light-blue">
            <a *ngIf="(attachedMedicalCoverages$ | async).length > 0 && isEdit" (click)="updateMedicalCoverage(true)"
               [popover]="planInfoTemplate" placement="bottom" container="header"
              triggers="mouseenter:mouseleave" containerClass="plan-info-popover">
              <!-- <span>{{ (coverageNames$ | async) }}</span> -->
              <label class="cursor-pointer">Click to change</label>
            </a>

            <!-- <a *ngIf="(attachedMedicalCoverages$ | async).length > 0 && !isEdit">
              <span>{{ (coverageNames$ | async) }}</span>
            </a> -->
          </ng-template>
        </div>
      </div>

      <!-- Items -->
      <div class="p-2">
        <div class="card mh-100">
          <div class="card-header bg-duck-egg-blue">
              <div class="row p-2">
                  <div class="col-md-6 mr-auto">ITEMS</div>
              </div>
          </div>
          <div class="card-body">
            <div class="row bg-warm-grey text-white font-weight-semi-bold m-2 mb-0 p-1">
              <div class="col-md-5">Item</div>
              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-6">Batch No</div>
                  <div class="col-md-6">Expiry Date</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-5">Qty (UOM)</div>
                  <div class="col-md-7">Final Amt($)</div>
                </div>
              </div>
              <div class="col-md-1">Actions</div>
            </div>

            <div formArrayName="purchaseItemsAdj">
              <div class="m-2" *ngFor="let item of billAdjustmentFormGroup.get('purchaseItemsAdj').value; let i = index; trackBy: trackById">
                <div class="row p-1 ml-1" [formGroupName]="i">
                  <!-- Added new item -->
                  <div *ngIf="isEdit && item.isNew; else notNew" class="col-md-5 mb-0 pl-0">
                    <div class="row no-gutters">
                      <div class=" input-group input-group-sm">
                        <ng-select name="drug-code" class='p-0 form-control form-control-sm  custom-select custom-select-extra-width col-md-5'
                          [items]="!item.remoteDelivery ? currentChargeItems : remoteDeliveryChargeItems" bindLabel='code'  bindValue='id' [addTag]="false" [virtualScroll]="true"
                          [typeahead]="!item.remoteDelivery ? codesTypeahead : codesTypeaheadRemote" [clearable]=false formControlName="itemRefId" (change)="onDiagnosisSelected($event, i)">
                          <ng-template ng-header-tmp>
                            <div class="row">
                              <div class="col-md-2 font-weight-semi-bold">Code</div>
                              <div class="col-md-5 font-weight-semi-bold">Name</div>
                              <div class="col-md-5 font-weight-semi-bold">Generic Name</div>
                            </div>
                          </ng-template>

                          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="term">
                            <div class="row">
                              <div class="col-md-2">{{item.code}}</div>
                              <div class="col-md-5">{{item.name}}</div>
                              <div class="col-md-5">{{ (item.genericName || '' )}}</div>
                            </div>
                          </ng-template>
                        </ng-select>
                        <input name="DESC" class="form-control form-control-sm col-md-12 mr-0" style="margin-right:6px;" type="text"
                               min="0" max="100" formControlName="itemName" readonly>
                      </div>
                    </div>
                  </div>
                  <ng-template #notNew>
                    <div class="col-md-5 mb-0 pl-0">
                      <span *ngIf="item.remoteDelivery" class="txt-remote-delivery">[EXT.DELIVERY]</span> {{ item.itemCode }} ({{item.itemName}})
                    </div>
                  </ng-template>
                  
                  <div class="col-md-3">
                    <div class="row" *ngIf="!isEdit && !item.remoteDelivery">
                      <div class="col-md-6 mb-0 pl-0">
                        <div class=" input-group input-group-sm">
                          <ng-select style="cursor:help;" [title]="item.batchNo"
                          class="p-0 form-control form-control-sm custom-select custom-select-extra-extra-medium-width"
                          [items]="item.inventories" bindLabel="batchNo" bindValue="batchNo"
                          placeholder="Select Batch Number" formControlName="batchNo" [clearable]="true">
                            <!-- <ng-template ng-header-tmp>
                              <div class="row">
                                <div class="col-md-3 font-weight-semi-bold">Batch No</div>
                                <div class="col-md-3 font-weight-semi-bold">Expiry Date</div>
                                <div class="col-md-3 font-weight-semi-bold">Stock Bal.</div>
                                <div class="col-md-3 font-weight-semi-bold">Remaining Bal.</div>
                              </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                              <div class="row">
                                <div class="col-md-3">{{ item.batchNo }}</div>
                                <div class="col-md-3">{{ item.expireDate }}</div>
                                <div class="col-md-3 text-right">{{ item.remainingQuantity }}</div>
                                <div [ngClass]="item.remainingBalance < 0 ? 'invalidItemText' : ''" class="col-md-3 text-right">
                                  {{item.remainingBalance}}</div>
                              </div>
                            </ng-template> -->
                          </ng-select>
                        </div>
                      </div>
    
                      <div class="col-md-6 mb-0 pl-0">
                        <input class="input-group-append form-control form-control-sm"  [readonly]="true" type="text " formControlName="expiryDate">
                      </div>
                    </div>
                    <div class="row" *ngIf="isEdit && !(item.remoteDelivery)">
                      <div class="col-md-6 mb-0 pl-0">
                        <div class=" input-group input-group-sm">
                          <ng-select style="cursor:help;" [title]="item.batchNo" [readonly]="!item.inventoried"
                          class="p-0 form-control form-control-sm custom-select custom-select-extra-extra-medium-width"
                          [items]="item.inventories" bindLabel="batchNo" bindValue="batchNo"
                          placeholder="Select Batch Number" formControlName="batchNo" [clearable]="true">
                            <ng-template ng-header-tmp>
                              <div class="row">
                                <div class="col-md-3 font-weight-semi-bold">Batch No</div>
                                <div class="col-md-3 font-weight-semi-bold">Expiry Date</div>
                                <div class="col-md-3 font-weight-semi-bold">Stock Bal.</div>
                                <div class="col-md-3 font-weight-semi-bold">Remaining Bal.</div>
                              </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-element="item" let-index="index">
                              <div class="row">
                                <div class="col-md-3">{{ element.batchNo }}</div>
                                <div class="col-md-3">{{ element.expireDate }}</div>
                                <div class="col-md-3 text-right">{{ element.saleUomQuantity }}</div>
                                <div [ngClass]="getRemainingQuantity(element, item) < 0 ? 'invalidItemText' : ''" class="col-md-3 text-right">
                                  {{ getRemainingQuantity(element, item)}}</div>
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>
                      </div>
    
                      <div class="col-md-6 mb-0 pl-0">
                        <input class="input-group-append form-control form-control-sm"  [readonly]="true" type="text " formControlName="expiryDate">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-md-5 mb-0 pr-0">
                        <div class=" input-group input-group-sm">
                          <input name="quantity" class="form-control form-control-sm col-md-4 mr-0" style="margin-right:6px;" type="number"
                            min="1" max="1000" id="qty" formControlName="purchaseQty">
                          <div class="input-group-append" style="padding:0px;">
                            <span class="input-group-text" style="font-size:11px; padding:0 2px"
                              id="dosage-addon">{{ item.purchaseUom }}</span>
                          </div>
                        </div>
                      </div>
    
                      <div class="col-md-7">
                        <input class="form-control form-control-sm" type="number" step='0.01' name="adjustedTotalPrice" formControlName="soldPrice" (change)="onChangeFinalAmt()">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button class="btn btn-link-grey p-1 " [disabled]="!isEdit" (click)="onbtnDeleteClicked(item)">
                      <i class="icon-trash"></i>
                    </button>
                  </div>
                </div>
                <hr class="my-0">
              </div>
            </div>
            <div class="d-flex flex-row-reverse p-1">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-5">Charges</div>
                  <div class="col-md-7"><input class="form-control form-control-sm" readonly="true" [(ngModel)]="charges" [ngModelOptions]="{standalone: true}"></div>
                </div>
              </div>
            </div>

            <div class="d-flex flex-row-reverse p-1" *ngIf="discount > 0">
              <div class="col-md-1"></div>
             <div class="col-md-3">
              <div class="row">
                <div class="col-md-5">Discount</div>
                <div class="col-md-7"><input class="form-control form-control-sm" readonly="true" [(ngModel)]="discount" [ngModelOptions]="{standalone: true}"></div>
              </div>
             </div>
            </div>
            <div class="d-flex flex-row-reverse p-1">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-5">Payable w/ GST</div>
                  <div class="col-md-7"><input class="form-control form-control-sm" readonly="true" [(ngModel)]="payableWithGST" [ngModelOptions]="{standalone: true}"></div>
                </div>
               </div>
            </div>

            <div *ngIf="isEdit" class="d-flex flex-row-reverse">
              <div class="p-2">
                <button type="button"  class="btn btn-light" (click)="addNewItem()">+ Add New Item</button>
              </div>
              <div class="p-2">
                <button type="button" *ngIf="allowAddRemoteDelivery && allowRemoteDelivery" class="btn btn-light" (click)="addNewItem(true)">+ Add New Remote Item</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Breakdown -->
      <div class="p-2">
        <div class="card mh-100">
          <div class="card-header bg-duck-egg-blue">
            <div class="row p-2">
                <div class="col-md-3 mr-auto">CHARGES BREAKDOWN</div>
            </div>
          </div>
          <div class="card-body">
              <div class="row bg-warm-grey text-white font-weight-semi-bold m-2 mb-0 p-1">
                <div class="col-md-8">Paid By</div>
                <div class="col-md-3">Amount($)(w/ GST)</div>
                <div class="col-md-1">Actions</div>
                <!-- <div class="col-md-3">Reference</div> -->
              </div>

                <!-- <div class="row p-2">
                  <div class="col-md-7">
                    <ng-select class="p-0 form-control form-control-sm custom-select" [items]="paymentMethodSelections"
                        bindLabel="label" bindValue="value" placeholder="Select Payment Method" [virtualScroll]="true" [clearable]="false" [hideSelected]="true">
                    </ng-select>
                  </div>
                  <div class="col-md-2">
                    <input type="number" class="form-control form-control-sm"/>
                  </div>
                  <div class="col-md-3">
                    <input type="number" class="form-control form-control-sm"/>
                  </div>
                </div> -->

              <div formArrayName="invoiceAdjEntities">
                <div *ngFor="let item of billAdjustmentFormGroup.get('invoiceAdjEntities').value; let iae = index; trackBy: trackByInvoiceId">
                  <div [formGroupName]="iae">
                      <div formArrayName="paymentInfos">

                        <div *ngIf="item.invoiceType === 'DIRECT'">
                          <div *ngFor="let paymentInfo of item.paymentInfos; let i = index; trackBy: trackByPaymentInfo">
                            <div class="row m-2" [formGroupName]="i">
                              <div class="col-md-8">
                                <ng-select class="p-0 form-control form-control-sm custom-select" [items]="paymentMethodSelections" formControlName="billMode" [readonly]="paymentInfo.billMode === 'CASH' && !paymentInfo.isNew ? true : false"
                                  bindLabel="label" bindValue="value" placeholder="Select Payment Method" [virtualScroll]="true" [clearable]="false" [hideSelected]="true">
                                </ng-select>
                              </div>
                              <div class="col-md-3">
                                <input class="form-control form-control-sm" type="number" step='0.01' name="amount" formControlName="amount" [readonly]="paymentInfo.billMode === 'CASH' && !paymentInfo.isNew ? true : false" (change)="calculateRemainingAmount()">
                              </div>
                              <div class="col-md-1">
                                <button class="btn btn-link-grey p-1 " *ngIf="!!paymentInfo.isNew" (click)="onbtnNewItemDeleteClicked(i)">
                                  <i class="icon-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="item.invoiceType === 'CREDIT'">
                        <div class="row m-2">
                          <div class="col-md-8">
                            <div class="row">
                              <div class="col-md-11">{{ getMedicalCoverage(item.planId)?.name | uppercase }}</div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <input class="form-control form-control-sm" type="number" step='0.01' name="paidAmount" formControlName="paidAmount" (change)="calculateRemainingAmount()">
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row-reverse p-1" >
                <div class="col-md-1"></div>
                <div class="col-md-3">
                  <input class="form-control form-control-sm" type="number" step='0.01' readonly="true" [(ngModel)]="totalBillValue" [ngModelOptions]="{standalone: true}"></div>
                <div class="p-2">Total Bill</div>
              </div>

              <div class="d-flex flex-row-reverse p-1">
                <div class="col-md-1"></div>
                <div class="col-md-3">
                  <input class="form-control form-control-sm" type="number" step='0.01' readonly="true" [(ngModel)]="remainingAmount" [ngModelOptions]="{standalone: true}"></div>
                <div class="p-2">Remaining Amount</div>
              </div>

              <div *ngIf="isEdit" class="d-flex flex-row-reverse">
                <div class="p-2">
                  <button type="button"  class="btn btn-light" (click)="addPaymentMethod()">+ Add Another Payment Method</button>
                </div>
              </div>

              <p style="color: red; padding-left: 10px;">*If a refund is already done for this case, please verify and take note of the charges before proceeding.</p>
                <!-- <div class="row m-2">
                  <div class="col-md-7">Total Amount</div>
                  <div class="col-md-2">
                    {{ (salesOrder$ | async).totalPrice | displayDollar | currency:'$' }}
                    <input class="form-control form-control-sm" type="number" step='0.01' name="paidAmount" formControlName="paidAmount">
                  </div>
                </div> -->
            </div>
          </div>
      </div>

      <div class="row p-2">
        <div class="col-md-2">Reason<span class="compulsory">*</span></div>
        <div class="col-md-10">
          <ng-select
          class="custom-select form-control-sm custom-select-extra-medium-width"
          name="billAdjReasonCode"
          type="text"
          bindValue="code"
          bindLabel="description"
          [items]="billAdjustmentResons"
          formControlName="billAdjReasonCode">

        </ng-select>
        </div>
        <div class="col-md-1"></div>
      </div>

      <div class="row p-2" *ngIf="exsistingfiles  && exsistingfiles.length > 0">
        <div class="col-md-2">Uploaded Documents<span class="compulsory"></span></div>
        <div class="col-md-10">
          <ul *ngIf="exsistingfiles.length > 0">
            <li *ngFor="let file of exsistingfiles">
              <span class="link" (click)="onDocumentClicked(file)"
                *ngxPermissionsOnly="['ROLE_FILE_DOWNLOAD']">{{file.fileName}}</span>
            </li>
          </ul>
          <p class="p-no-files" *ngIf="exsistingfiles.length === 0">No files uploaded</p>
        </div>
        <div class="col-md-1"></div>
      </div>

      <ng-container *ngIf="uploader && isEdit">
        <div ng2FileDrop [ngClass]="{'file-over': hasDropZoneOver}" (fileOver)="fileOver($event)"
            (onFileDrop)="openModal($event)" [uploader]="uploader" class="well drop-zone p-2 mt-2">
            Drag and Drop to upload or
            <input type="file" ng2FileSelect (onFileSelected)="openModal($event)"
                [uploader]="uploader" multiple />
        </div>
    </ng-container>

    <div *ngFor="let item of uploader?.queue; let i=index;">
      <div class="row pt-2">
          <div class="col-md-2">
              {{ item.file.name }}
          </div>
          <div class="col-md-2">
              {{ item?.file?.size/1024/1024 | number:'.2' }} MB
          </div>
          <div class="col-md-4">
              <div class="progress mb-0">
                  <div class="progress-bar" role="progressbar"
                      [ngStyle]="{ 'width': item.progress + '%' }">
                  </div>
              </div>
              <div *ngIf="item.errorMsg" style="color: red;"> {{ item.errorMsg }} </div>
          </div>
          <div class="col-md-4">
              <button type="button" class="btn btn-danger btn-xs" (click)="onRemoveDocumemt(i)"
                  *ngIf="!item.isSuccess">
                  <span class="glyphicon glyphicon-trash"></span> Remove
              </button>
              <span *ngIf="item.isSuccess">
                  File is successfully uploaded.
              </span>
          </div>
      </div>
  </div>



      <div class="row p-2">
        <div class="col-md-2">Remarks<span class="compulsory">*</span></div>
        <div class="col-md-9">
          <textarea rows="3" type="text"  class="form-control form-control-sm" placeholder="Please enter your remarks here" formControlName='remark'></textarea>
        </div>
        <div class="col-md-1"></div>
      </div>

      <div class="row p-2" *ngIf="isFailed()">
        <div class="col-md-2">Reason To Fail</div>
        <div class="col-md-9">
          {{billAdjItem?.failedMsg}}
        </div>
        <div class="col-md-1"></div>
      </div>

    </div>
    <div class="row flex-row-reverse pb-3 pr-3">
      <div class="col-md-2" *ngIf="isEdit">
        <button class="btn btn-md btn-brand-primary text-white w-100" (click)="submit()" [disabled]="!billAdjustmentFormGroup.valid || ( -0.04 > remainingAmount)">Submit</button>
      </div>
      <div class="col-md-2" *ngIf="isFailed()">
        <button class="btn btn-md btn-brand-primary text-white w-100" (click)="submit()">Resubmit</button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-md btn-grey-600 text-white w-100" (click)="closeModal()">
          Cancel
        </button>
      </div>
    </div>
</div>
