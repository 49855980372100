<div class="row" *ngIf="(selectedGoalsPreview && selectedGoalsPreview.length) || isNewPlan || isEditPlan">
    <div class="col-md-12 mb-2 condition-wrapper">
        <app-patient-hsg-condition *ngIf="selectedConditions && selectedConditions.length >= 0" 
        [enableConditions]="isNewPlan || isEditPlan" [selectedConditions]="selectedConditions"></app-patient-hsg-condition>
    </div>
    <div class="col-md-12 mb-2">
        <div class="wrapper-buttons">
            <button class="button-primary ml-2" *ngIf="(isNewPlan || isEditPlan) && showCopyLastGoal" (click)="copylastGoals()">copy last goals</button>
        </div>
    </div>
    <div class="col-md-12">
        <div class="col-md-12" *ngIf="selectedPlan && selectedPlan.healthPlanStatus === 'FAILED'">
            <p class="sub-title error-note p-1">Error: {{failedErrorMsg}}</p>
        </div>
        <div class="col-md-12" *ngIf="isConsultation && selectedPlan && (selectedPlan.healthPlanStatus === 'FAILED' || selectedPlan.healthPlanStatus === 'DRAFT') && !isVisitPlan">
            <p class="sub-title error-note p-1">Warning: Do not update, this plan belongs to a different visit.</p>
        </div>
        <div class="col-md-12" *ngIf="isNewPlan || isEditPlan">
            <p class="sub-title info-note p-1">Note: The information below will be shared with the Patient</p>
        </div>
        <div class="card-custom-light">
            <div class="card-header">Health Goals</div>
    
            <table class="custom-table">
                <thead>
                    <tr>
                        <th class="tw-60 text-center">No.</th>
                        <th>Goal</th>
                        <th>Target</th>
                        <th *ngIf="isNewPlan || isEditPlan">Actions</th>
                    </tr>
                </thead>
                <tbody *ngIf="!isNewPlan && !isEditPlan">
                    <tr *ngFor="let goal of selectedGoalsPreview; let i = index;">
                        <td class="tw-60 text-center">{{ i + 1 }}</td>
                        <td>{{ goal.goal }}</td>
                        <td class="td-target">
                            <p [innerHTML]="goal.target | safeHtml"></p>
                            <p *ngIf="goal.subTarget" [innerHTML]="goal.subTarget | safeHtml"></p>
                            <ul *ngIf="goal.type === 'list' ">
                                <li *ngFor="let item of goal.data" [innerHTML]="item | safeHtml"></li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="isNewPlan || isEditPlan">
                    <tr *ngFor="let goal of newGoals; let i = index;">
                        <td class="tw-60 text-center">{{ i + 1 }}</td>
                        <td class="tw-350">
                            <ng-select
                                style="width: 300px"
                                class="custom-select-new"
                                [items]="goalsDropdownFiltered" 
                                bindLabel="label" 
                                [clearable]="true" 
                                [virtualScroll]="true" 
                                [(ngModel)]="goal.goal"
                                placeholder="Goal"
                                (change)="onSelectGoal($event, i)"
                                required>
                            </ng-select>
                        </td>
                        <td class="td-target">
                            <div class="td-target-div" *ngFor="let item of goal.target; let index = index">
                                <span *ngIf="item.type === 'text' || item.type === 'multi-choice'">
                                    {{ item.value }} <span *ngIf="item.type === 'multi-choice'" class="compulsory">*</span>
                                </span>
                                <div class="wrapper-form-control" *ngIf="item.type === 'input' && item.inputType === 'number' ">
                                    <input type="number" [min]="item.minValue" [max]="item.maxValue" class="input" (input)="onInput($event, i, item.placeholder)" [(ngModel)]="item.value" required>
                                </div>
                                <div class="wrapper-form-control" *ngIf="item.type === 'input' && item.inputType === 'text' ">
                                    <input type="text" [min]="item.minValue" [max]="item.maxValue" class="input" (input)="onInput($event, i, item.placeholder)" [(ngModel)]="item.value" required>
                                </div>
                                <ng-select
                                    *ngIf="item.type === 'input-dropdown'"
                                    style="width: auto; min-width: 80px;"
                                    class="custom-select-new"
                                    [ngClass]="(item.minValue && item.maxValue ) && !(item.value >= item.minValue && item.value <= item.maxValue) ? 'ng-invalid' : ''"
                                    [items]="item.data"
                                    bindLabel="value" 
                                    bindValue="key"
                                    [clearable]="true"
                                    [(ngModel)]="item.value"
                                    [addTag]="addTag.bind(this)"
                                    (change)="onSelectInputDropdown($event, i, item.placeholder)"
                                    (clear)="onSelectInputDropdown($event, i, item.placeholder)"
                                    required>
                                </ng-select>
                                <div *ngIf="item.type === 'line-break' " style="display: block; width: 100%"></div>
                                <app-date-picker-with-icon
                                    *ngIf="item.type === 'date-picker' "
                                    [customStyles]="true"
                                    [config]="datePickerConfig" 
                                    [dateControl]="datePickerFG"
                                    [index]="i"
                                    (changedValue)="getDatepickerChangedValue($event, item.placeholder)"
                                    required>
                                </app-date-picker-with-icon>
                                <div class="row" *ngIf="item.type === 'multi-choice' ">
                                    <div class="col-md-6 checkbox form-check" *ngFor="let checkbox of item.data; let j = index;">
                                        <input type="checkbox" (click)="onCheck($event, checkbox, i)" [checked]="isCheckboxChecked(checkbox, i)" class="form-check-input">
                                        <label>{{ checkbox.value }}</label>
                                        
                                        <div class="wrapper-form-control" *ngIf="checkbox.showInput ">
                                            <textarea class="input textarea" placeholder="Please specify" cols="30" rows="3" (input)="onEnterSpecifyOther($event, checkbox, i)" [(ngModel)]="checkbox.inputValue" required></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="td-sub-item-div">
                                <div class="row" *ngIf="goal.workflow.subWorkflow">
                                    <div class="col-md-6 checkbox form-check">
                                        <input type="checkbox" class="form-check-input mr-2" [(ngModel)]="goal.enableSubGoals" 
                                        (click)="onEnableSubGoalsChange($event, i)">
                                        <!-- <label>{{goal.workflow.subWorkflow.body}}</label> -->
                                        <div class="td-target-div" *ngFor="let item of goal.subTarget; let index = index">
                                            <span *ngIf="item.type === 'text' || item.type === 'multi-choice'">
                                                {{ item.value }} <span *ngIf="item.type === 'multi-choice'" class="compulsory">*</span>
                                            </span>
                                            <div class="wrapper-form-control" *ngIf="item.type === 'input' ">
                                                <input type="number" class="input" (input)="onInput($event, i, item.placeholder)" 
                                                [(ngModel)]="item.value" required [disabled]="!goal.enableSubGoals">
                                            </div>
                                            <ng-select
                                                *ngIf="item.type === 'input-dropdown'"
                                                style="width: auto; min-width: 80px;"
                                                class="custom-select-new"
                                                [items]="item.data"
                                                bindLabel="value" 
                                                bindValue="key"
                                                [clearable]="true"
                                                [(ngModel)]="item.value"
                                                [addTag]="true"
                                                (change)="onSelectInputDropdown($event, i, item.placeholder)"
                                                (clear)="onSelectInputDropdown($event, i, item.placeholder)"
                                                required
                                                [disabled]="!goal.enableSubGoals">
                                            </ng-select>
                                            <div *ngIf="item.type === 'line-break' " style="display: block; width: 100%"></div>
                                            <app-date-picker-with-icon
                                                *ngIf="item.type === 'date-picker' "
                                                [customStyles]="true"
                                                [config]="datePickerConfig" 
                                                [dateControl]="datePickerFG"
                                                [index]="i"
                                                (changedValue)="getDatepickerChangedValue($event, item.placeholder)"
                                                required
                                                [disabled]="!goal.enableSubGoals">
                                            </app-date-picker-with-icon>
                                            <div class="row" *ngIf="item.type === 'multi-choice' ">
                                                <div class="col-md-6 checkbox form-check" *ngFor="let checkbox of item.data; let j = index;">
                                                    <input type="checkbox" (click)="onCheck($event, checkbox, i)" [checked]="isCheckboxChecked(checkbox, i)" class="form-check-input">
                                                    <label>{{ checkbox.value }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="goal.goal.label === 'Activity Goal - Eldercare Care'" style="font-weight: 500;"><i>Note: This goal is applicable for residents >= 60 years old.</i></div>
                        </td>
                        <td class="tw-100 td-actions" *ngIf="isNewPlan || isEditPlan">
                            <img src="/assets/svg/trash-solid.svg" (click)="onDelete(i)">
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="wrapper-buttons">
                <button class="button-green m-2" *ngIf="(isNewPlan || isEditPlan) && goalsDropdownFiltered.length" (click)="onAddNew()">add new</button>
            </div>

            <div class="wrapper-next-visit" *ngIf="!isNewPlan && !isEditPlan && selectedGoalsPreview">
                <p class="sub-title">Recommended Next Visit</p>
    
                <div class="wrapper-text">
                    <p class="date">{{selectedPlan?.nextCheckUpDate}}</p>
                    <p class="sub-title">Record of Discussion</p>
                    <div class="hsg-discussion" [innerHTML]="selectedPlan?.recordOfDiscussion  ? (selectedPlan?.recordOfDiscussion | safeHtml) : '' "></div>

                    <button *ngIf="!selectedPlan?.hsgId" class="button-primary" (click)="onPrint()">Print</button>
                </div>
            </div>

            <div class="wrapper-next-visit pb-0" *ngIf="(isNewPlan || isEditPlan) && hsgForm">
                <p class="sub-title">Recommended Next Visit</p>
    
                <div class="wrapper-text">
                    <app-date-picker-with-icon
                        [customStyles]="true"
                        [config]="datePickerConfig" 
                        [dateControl]="hsgForm.get('nextCheckUpDate')">
                    </app-date-picker-with-icon>
                </div>

                <div class="wrapper-cke-editor mt-3">
                    <p class="sub-title">Record of Discussion</p>
                    <app-rich-editor-switcher [control]="hsgForm.get('recordOfDiscussion')">
                    </app-rich-editor-switcher>
                    <p class="sub-title mt-2 mb-2">Template</p>
                    <ng-select class="custom-select-new p-0" bindLabel="name" bindValue="id"
                            groupBy="type" [items]="templates" [virtualScroll]="true" (change)="onTemplateChange($event)"
                            [(ngModel)]="template" [ngModelOptions]="{standalone: true}" placeholder="Select Template"></ng-select>
                </div>
            </div>
            

            <div class="row p-2 pl-4" *ngIf="(isNewPlan || isEditPlan) && hsgForm">
                <button class="button-green" *ngIf="!isNewPlan" (click)="onPrint()">Print Draft</button>
                <button class="button-red ml-2" *ngIf="isNewPlan && !isEditPlan" (click)="onCancel()">cancel</button>
                <button class="button-red ml-2" *ngIf="isEditPlan && !isNewPlan && !isProjectPage" (click)="onDeletePlan()">Delete Plan</button>
                <div class="d-inline-block ml-2" *ngIf="isProjectPage && selectedPlan.healthPlanStatus === 'FAILED'">
                    <button *ngxPermissionsOnly="['ROLE_HSG_ACCESS']" class="button-primary" (click)="onReSubmit()" [disabled]="!isNewPlan && !isEditPlan">Resubmit</button>
                </div>
            </div>
        </div>
    </div>
</div>