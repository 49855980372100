import { MedicalCoverage, formatDate } from './MedicalCoverage';
import { UserId, createUserId, CorpUserId, createCorpUserId } from './Appointment';

export interface PolicyHolderInfo {
  id?: string;
  identificationNumber?: UserId;
  name?: string;
  medicalCoverageId?: string;
  planId?: string;
  patientCoverageId?: string;
  policyHolderType?: string;
  specialRemarks?: string;
  costCenter?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  corpIdentificationNumber?: CorpUserId;
}

export function createPolicyHolderInfo(
  id?: string,
  identificationNumber?: UserId,
  name?: string,
  medicalCoverageId?: string,
  planId?: string,
  patientCoverageId?: string,
  policyHolderType?: string,
  specialRemarks?: string,
  costCenter?: string,
  status?: string,
  startDate?: string,
  endDate?: string,
  corpIdentificationNumber?: CorpUserId
): PolicyHolderInfo {
  return <PolicyHolderInfo>{
    id: id || '',
    identificationNumber: identificationNumber === undefined ? createUserId() : identificationNumber,
    name: name || '',
    medicalCoverageId: medicalCoverageId || '',
    planId: planId || '',
    patientCoverageId: patientCoverageId || '',
    policyHolderType: policyHolderType || '',
    specialRemarks: specialRemarks || '',
    costCenter: costCenter || '',
    status: status || '',
    startDate: formatDate(startDate) || '',
    endDate: formatDate(endDate) || '',
    corpIdentificationNumber: corpIdentificationNumber === undefined ? createCorpUserId() : corpIdentificationNumber,
  };
}

export interface MedicalCoverageByCaseRes {
  coverage?: Array<MedicalCoverage>;
  limit?: { [id: string]: number };
  policyHolder?: PolicyHolderInfo[];
}

export function createMedicalCoverageByCaseRes(
  coverage?: Array<MedicalCoverage>,
  limit?: { [id: string]: number },
  policyHolder?: PolicyHolderInfo[]
): MedicalCoverageByCaseRes {
  return <MedicalCoverageByCaseRes>{
    coverage: coverage || [],
    limit: limit || {},
    policyHolder: policyHolder || [],
  };
}
