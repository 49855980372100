<div class="container-fluid">
    <div class="row pt-3">
        <div class="col-md-4"></div>
        <div class="col-md-4" style="text-align: center;"> <h3>Please select your clinic</h3> </div>
        <div class="col-md-4 mt-1" style="text-align: end; font-size: medium; padding-right: 30px;"> Last Log in: {{lastLogin}} </div>
    </div>
    <div class="row justify-content-md-center pb-3 px-3">
        <div *ngFor="let clinic of authorizedClinicList" class="col-md-2 p-3">
            <button
                [id]="clinic.clinicCode"
                class="clinicBtn w-100"
                style="text-align:center; color:black"
                (click)="onSelect(clinic)"
                value="clinic.clinicCode"
                >
                <!-- style="width: 40%; height: 30%;"  -->
                <img 
                    *ngIf="!clinic.clinicLogo"
                    src="../../../assets/img/logo.png" 
                    class="pt-2 logo-default"
                    >
                <!-- style="width: 70%; height: 30%;" -->
                <img 
                    *ngIf="clinic.clinicLogo"
                    src="../../../assets/img/colored/{{clinic.clinicLogo}}"
                    class="pt-2 logo"
                    >
                 <div class="hmcClinic">
                   <div style="font-size:22px;">{{clinic.clinicCode}}</div>
                   <div>{{clinic.name}}</div>
                 </div>
                 <ng-container *ngIf="clinic.address">
                     <div class="hmcClinic" style="text-overflow:ellipsis; overflow:hidden; white-space: nowrap">{{formatAddressMultiLine(clinic.address.address, 0)}}</div>
                     <div class="hmcClinic" style="text-overflow:ellipsis; overflow:hidden; white-space: nowrap" *ngIf="clinic.address.address && clinic.address.address.length>maxWidthPerLine">{{formatAddressMultiLine(clinic.address.address, 1)}}</div>
                     <div class="hmcClinic" style="font-size:11px;" *ngIf="clinic.address.address && !checkPostalCodeExistsInAddressParam(clinic.address.address)">SINGAPORE {{clinic.address.postalCode}}</div>
                 </ng-container>
               </button>
        </div>
    </div>
</div>