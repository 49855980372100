import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
@Component({
  selector: 'app-patient-referral-alert',
  templateUrl: './patient-referral-alert.component.html',
  styleUrls: ['./patient-referral-alert.component.scss'],
})
export class PatientReferralAlertComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter<any>();
  title: string;
  text: string;
  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  public action(state: boolean): void {
    this.event.emit(state);
    this.bsModalRef.hide();
  }
  
  closeModal() {
    this.bsModalRef.hide();
  }
}
