import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { AKITA_CACHE_DURATION } from '../../constants/akita.config';

import { RemoteDeliveryOrderStatus } from '../../model/RemoteDelivery';

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'remoteDeliveryOrderStatus',
    idKey: 'code',
    cache: { ttl: AKITA_CACHE_DURATION },
    resettable: true,
})
export class AkitaRemoteDeliveryOrderStatusStore extends EntityStore<RemoteDeliveryOrderStatus> {

    constructor() {
        super();
    }
}