export interface AttachedMedicalCoverage {
  medicalCoverageId: string;
  planId: string;
  coverageId: string;
  name?: string;
  type?: string;
  remarks?: string;
  limit?: number;
  updated?: boolean;
  isAllowedWithChas?: boolean;
  payerUserId?: {
    idType: string;
    number: string;
  };
  sflStatus:any;
}

export function createAttachedMedicalCoverage(
  medicalCoverageId: string,
  planId: string,
  coverageId?: string,
  type?: string,
  name?: string,
  remarks?: string,
  limit?: number,
  updated?: boolean,
  isAllowedWithChas?: boolean,
  sflStatus?: any
) {
  return <AttachedMedicalCoverage>{
    medicalCoverageId: medicalCoverageId || '',
    planId: planId || '',
    coverageId: coverageId || '',
    type: type || '',
    name: name || '',
    remarks: remarks || '',
    oldLimit: limit || 0,
    limit: limit || 0,
    updated: updated || false,
    isAllowedWithChas: isAllowedWithChas || false,
    sflStatus: sflStatus || undefined
  };
}
