import {CountType, StockTakeItem, StockTakeStatus} from "./StockTake";

export class DisposalItems {
  disposeItemRefId: string
  itemName: string;
  itemCode: string;
  clinicId: string;
  cpRemark: string;
  remark: string;
  disposedDate: string;
  disposeStatus: string;
  quantity: number;
  batchNo: string;
  manufacturerDate: string;
  expiryDate: string;
  disposalRequestDate: string;


  adapt(obj?): DisposalItems {
    if (!obj) {
      return this;
    }
    this.disposeItemRefId = obj.disposeItemRefId || '';
    this.itemCode = obj.itemCode || '';
    this.itemName = obj.itemName || '';
    this.clinicId = obj.clinicId || '';
    this.cpRemark = obj.cpRemark || '';
    this.remark = obj.remark || '';
    this.disposeStatus = obj.disposeStatus || '';
    this.quantity = obj.quantity || '0';
    this.batchNo = obj.batchNo || '';
    this.manufacturerDate = obj.manufacturerDate || '';
    this.disposalRequestDate = obj.disposalRequestDate || '';
    this.expiryDate = obj.expiryDate || '';
    this.disposedDate = obj.disposedDate || ''

    return this;
  }

}
export enum DisposalStatus {
  REQUESTED = 'REQUESTED',
  COMPLETED = 'COMPLETED',

}
