import { Pipe, PipeTransform } from '@angular/core';
import { AkitaChargeItemQuery } from '../services/states/akita-charge-item.query';

@Pipe({
  name: 'inventoryDisplayItemSellingPrice'
})
export class InventoryDisplayItemSellingPricePipe implements PipeTransform {
  constructor(private akitaChargeItemQuery: AkitaChargeItemQuery) {}

  transform(value: any, args?: any): any {
    let sellingPrice = this.akitaChargeItemQuery.getChargeItem(value, 'sellingPrice');
    if (!sellingPrice) {
      return '';
    }

    return (sellingPrice.price / 100).toFixed(2)
  }
}
