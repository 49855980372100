import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuardService
  implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {


    const url: string = state.url.split('?')[0];
    const nextUrl: string = nextState.url.split('?')[0];

    if (url !== nextUrl) {
      return component.canDeactivate ? component.canDeactivate() : true;
    } else {
      return true;
    }
  }
}
