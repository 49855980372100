import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Subject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { AlertService } from './alert.service';

import { AkitaPatientAppQuery } from './states/akita-patient/akita-patient-app.query';
import { StoreService } from './store.service';
import { AkitaCaseVisitQuery } from './states/akita-case-visit.query';
import { AkitaAppQuery } from './states/akita-app.query';

import PatientInfo from '../objects/state/PatientInfo';

import { TABS, STATUS_INITIAL, STATUS_CONSULT, STATUS_POST_CONSULT, STATUS_PAYMENT, STATUS_COMPLETE } from './../constants/app.constants';

import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root',
})
export class PvmTabsService {

  private activeTab: TabConfig;
  private subjectUpdateProfile = new Subject<any>();

  sendupdateProfile() {
    this.subjectUpdateProfile.next();
  }

  getupdateProfile(): Observable<any> {
    return this.subjectUpdateProfile.asObservable();
  };

  private activeTabSwitch: Subject<any> = new Subject<any>();

  private updatePriceLoading$ = new Subject<boolean>();
  private updatePriceLoading: boolean = false;

  private saveDraftBtn: ButtonConfig;
  private completeBtn: ButtonConfig;
  private nextBtnInConsult: ButtonConfig;
  private nextBtnInPostConsult: ButtonConfig;

  private patientInfo: PatientInfo;
  private selectedVisitType: string;

  tabConfig: TabConfig[] = [
    { title: TABS.PROFILE, content: 'PVM_TAB_PROFILE' },
    { title: TABS.DOCUMENTS, content: 'PVM_TAB_DOCUMENTS' },
    { title: TABS.VITALS, content: 'PVM_TAB_VITAL_SIGNS' },
    { title: TABS.ASSESSMENTS, content: 'PVM_TABS_ASSESSMENT' },
    { title: TABS.DENTAL, content: 'PVM_TAB_DENTAL' },
    { title: TABS.HISTORY, content: 'PVM_TAB_HISTORY' },
    { title: TABS.CONSULTATION, content: 'PVM_TAB_CONSULTATION' },
    { title: TABS.PCN, content: 'PVM_TAB_PCN' },
    { title: TABS.HSG, content: 'PVM_TAB_HSG' },
    { title: TABS.SERVICES, content: 'PVM_TAB_MEDICAL_SERVICES' },
    { title: TABS.COVERAGE, content: 'PVM_TAB_COVERAGE' },
    { title: TABS.DISPENSING, content: 'PVM_TAB_DISPENSING' },
    { title: TABS.PRINTING, content: 'PVM_TAB_PRINTING' },
    { title: TABS.PAYMENT, content: 'PVM_TAB_PAYMENT' },
    { title: TABS.SURVEY, content: 'PVM_TAB_SURVEY' },
  ];

  constructor(
    private store: StoreService,
    private permissionsService: NgxPermissionsService,
    private akitaPatientAppQuery: AkitaPatientAppQuery,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private akitaAppQuery: AkitaAppQuery,
    private alertService: AlertService,
  ) {
    this.activeTab = this.tabConfig[0];

    this.initialiseButtonConfigs();

    this.activeTabSwitch.subscribe(value => {
      this.setButtonVisibility();
    });

    combineLatest(
      [this.store.getVisitStatusRefresh().pipe(distinctUntilChanged()),
      this.akitaPatientAppQuery.patientInfo$.pipe(distinctUntilChanged())]
    ).subscribe(data => {
      this.lockConsultationOnInitial();
      this.setButtonVisibility();

      this.patientInfo = data[1];
    });

    this.alertService.getVisitTypeChangeEvent().subscribe(res => {
      this.selectedVisitType = res;
      this.setButtonVisibility();
    });

  }

  getTabIndexByKey(key: string): number {
    return this.tabConfig.findIndex(
      tab => tab.title === key || tab.content === key
    );
  }

  getTabByKey(key: string): TabConfig {
    return this.tabConfig.find(tab => tab.title === key || tab.content === key);
  }

  getTabConfig() {
    return this.tabConfig;
  }

  getActiveTab(): TabConfig {
    return this.activeTab;
  }

  getActiveTabIndex(): number {
    return this.getTabIndexByKey(this.activeTab.title);
  }

  getActiveTabSwitch() {
    return this.activeTabSwitch.asObservable();
  }


  getSaveDraftButton(): ButtonConfig {
    return this.saveDraftBtn;
  }

  getCompleteButton(): ButtonConfig {
    return this.completeBtn;
  }

  getNextButtonInConsult(): ButtonConfig {
    return this.nextBtnInConsult;
  }

  getNextButtonInPostConsult(): ButtonConfig {
    return this.nextBtnInPostConsult;
  }

  setActiveTab(key?: string, index?: number) {
    this.tabConfig.forEach(tab => (tab.active = false));
    const tab = key ? this.getTabByKey(key) : this.tabConfig[index];
    tab.disabled = false
    tab.active = true
    this.activeTab = tab;
    this.activeTabSwitch.next(this.activeTab);
  }

  setActiveTabWithoutReloading(key?: string, index?: number) {
    this.tabConfig.forEach(tab => (tab.active = false));
    const tab = key ? this.getTabByKey(key) : this.tabConfig[index];
    tab.active = true;
    this.activeTab = tab;

  }

  setInactiveTab(key?: string, index?) {
    let tab: TabConfig;
    tab = key ? this.getTabByKey(key) : this.tabConfig[index];

    tab.active = false;
  }

  resetTabConfig() {
    this.tabConfig.forEach((tab, index) => {
      this.setInactiveTab(null, index);
      // if (index === this.getTabIndexByKey(TABS.DOCUMENTS)) {
      //   tab.customClass = 'hiddenTab';
      // } else {
      tab.customClass = '';
      // }
    });
  }

  hideTab(key: string) {
    const tab = this.getTabByKey(key);
    tab.customClass = 'hiddenTab';
  }

  displayTab(key: string) {
    const tab = this.getTabByKey(key);
    tab.customClass = '';
  }

  disableTab(key: string) {
    const tab = this.getTabByKey(key);
    tab.disabled = true;
  }

  enableTab(key: string) {
    const tab = this.getTabByKey(key);
    tab.disabled = false;
  }

  initialiseButtonConfigs() {
    this.saveDraftBtn = {
      visible: true,
      disabled: false,
    };

    this.completeBtn = {
      visible: true,
      disabled: false,
    };

    this.nextBtnInConsult = {
      visible: true,
      disabled: false,
    };

    this.nextBtnInPostConsult = {
      visible: true,
      disabled: false,
    };
  }

  setButtonVisibility() {
    this.showSaveDraftButton();
    this.showCompleteBtn();
    this.showNextInConsultBtn();
    this.showNextInPostConsultBtn();
  }

  setButtonsDisabled(formGroup: FormGroup, isVaccinePackageValid: boolean = true, isHsgValid: boolean = true) {
    this.disableSaveDraftButton(formGroup, isHsgValid);
    this.disableCompleteButton(formGroup, false, isVaccinePackageValid, isHsgValid);
    this.disableNextButtonInConsult(formGroup, false, isVaccinePackageValid);
    this.disableNextButtonInPostConsult(formGroup, false, isVaccinePackageValid);
  }

  disableSaveDraftButton(formGroup: FormGroup, isHsgValid: boolean) {
    const invalidFG = !formGroup.get('consultationFormGroup').valid;
    const parentFG = (<FormGroup>formGroup.get(
      'consultationFormGroup'
    )) as FormGroup;
    const childFG = (<FormGroup>parentFG.get('consultation')) as FormGroup;
    const dispatchItems = (<FormArray>parentFG.get('dispatchItemEntities')) as FormArray;

    const invalid = [];

    const getInvalidControls = (formGroup: FormGroup) => {
      for (const name in formGroup.controls) {
        if (name !== 'consultation' && name !== 'dispatchItemEntities') {
          if (formGroup.get(name).invalid) {
            invalid.push(name);
          }
        }
      }
    };

    if (this.isConsultState()) {
      getInvalidControls(parentFG);
      getInvalidControls(childFG);

      for (let dispatchItem of dispatchItems.controls) {
        getInvalidControls((<FormGroup> dispatchItem) as FormGroup);
      }

      const isPackageValid = this.akitaCaseVisitQuery.isPackagesValidForActiveVisit();

      if (!isPackageValid) {
        this.saveDraftBtn.disabled = true;
      } else if (this.saveWithoutValidating(invalid)) {
        this.saveDraftBtn.disabled = false;
      } else {
        this.saveDraftBtn.disabled = invalidFG;
      }
    } else {
      const isPackageValid = this.akitaCaseVisitQuery.isPackagesValidForActiveVisit();
      this.saveDraftBtn.disabled = invalidFG || !isPackageValid;
    }

    const isPackageValid = this.akitaCaseVisitQuery.isPackagesValidForActiveVisit();

    // If is CA and is in Dispensing or Printing Tab, Validation should extend to chargeFormGroup
    if (
      (this.isDispensingTab() || this.isPrintingTab()) &&
      this.isPostConsultState()
    ) {
      if (
        !(
          formGroup.get('consultationFormGroup').valid &&
          formGroup.get('chargeFormGroup').valid
        ) || !isPackageValid || this.updatePriceLoading
      ) {
        this.saveDraftBtn.disabled = true;

      } else {
        this.saveDraftBtn.disabled = false;
      }
    }

    if(this.isHSGTab() && this.isPostConsultState() && !isHsgValid) {
      this.saveDraftBtn.disabled = true;
    } else {
      this.saveDraftBtn.disabled = false;
    }
  }

  disableCompleteButton(formGroup: FormGroup, isRequesting?: boolean, isVaccinePackageValid: boolean = true, isHsgValid: boolean = true) {
    if (!formGroup.get('consultationFormGroup').valid || isRequesting || !isVaccinePackageValid || !isHsgValid) {
      this.completeBtn.disabled = true;
    } else {
      this.completeBtn.disabled = false;
    }
  }

  disableNextButtonInConsult(formGroup: FormGroup, isRequesting?: boolean, isVaccinePackageValid: boolean = true) {
    const isPackageValid = this.akitaCaseVisitQuery.isPackagesValidForActiveVisit();
    if (!formGroup.get('consultationFormGroup').valid || !isPackageValid || !isVaccinePackageValid) {
      this.nextBtnInConsult.disabled = true;
    } else {
      this.nextBtnInConsult.disabled = false;
    }
  }

  disableNextButtonInPostConsult(formGroup: FormGroup, isRequesting?: boolean, isVaccinePackageValid: boolean = true) {
    const isPackageValid = this.akitaCaseVisitQuery.isPackagesValidForActiveVisit();
    if (isRequesting) {
      this.nextBtnInPostConsult.disabled = true;
    }

    if (
      (this.isDispensingTab() || this.isPrintingTab()) &&
      this.isPostConsultState()
    ) {
      if (
        !(
          formGroup.get('consultationFormGroup').valid &&
          formGroup.get('chargeFormGroup').valid
        ) || !isPackageValid || !isVaccinePackageValid
      ) {
        this.nextBtnInPostConsult.disabled = true;

      } else {
        this.nextBtnInPostConsult.disabled = false;
      }
    } else {
      this.nextBtnInPostConsult.disabled = false;
    }
  }

  saveWithoutValidating(array: Array<any>) {
    if (
      array.length === 1 &&
      (array[0] === 'diagnosisIds' || array[0] === 'visitType' || array[0] === 'vaccinationInfo')
    ) {
      return true;
    } else if (
      array.length === 2 &&
      array.includes('diagnosisIds') &&
      array.includes('visitType')
    ) {
      return true;
    } else if (
      array.length === 2 &&
      array.includes('diagnosisIds') &&
      array.includes('vaccinationInfo')
    ) {
      return true;
    } else if (
      array.length === 2 &&
      array.includes('vaccinationInfo') &&
      array.includes('visitType')
    ) {
      return true;
    } else if (
      array.length === 3 &&
      array.includes('diagnosisIds') &&
      array.includes('visitType') &&
      array.includes('vaccinationInfo')
    ) {
      return true;
    }
  }

  // Business Logic
  showSaveDraftButton() {
    if (
      (this.isServicesTab() &&
        ((this.isPostConsultState() && this.isCARole()) ||
          ((this.isConsultState() || this.isPostConsultState()) &&
            (this.isDoctorRole() || this.isAdminRole())))) || // On Medical Services Tab for 'CONSULT' and 'POSTCONSULT'
      (this.isConsultationTab() && !this.isPaymentState()) || // On Consult Tab -- For 'CONSULT' Patients only
      (this.isPCNTab() && !this.isPaymentState()) || // On PCN Tab
      (this.isDispensingTab() && this.isPostConsultState() ||
      (this.isHSGTab() && !this.isPaymentState())) // On PostConsult Tab -- For 'POSTCONSULT' Patients only
    ) {
      this.saveDraftBtn.visible = true;
    } else {
      this.saveDraftBtn.visible = false;
    }
  }

  showNextInConsultBtn() {
    if (
      this.isConsultState() &&
      this.isConsultationTab() &&
      this.selectedVisitType === 'CHRONIC'
    ) {
      this.nextBtnInConsult.visible = true;
    } else {
      this.nextBtnInConsult.visible = false;
    }
  }

  showNextInPostConsultBtn() {
    if (this.isPostConsultState()) {
      if (
        (this.isCARole() || this.isAdminRole()) &&
        (this.isDispensingTab() || this.isPrintingTab())
      ) {
        this.nextBtnInPostConsult.visible = true;
      } else {
        this.nextBtnInPostConsult.visible = false;
      }
    } else {
      this.nextBtnInPostConsult.visible = false;
    }
  }

  showCompleteBtn() {

    if (this.isConsultState()) {
      if (this.isConsultationTab()) {
        if (this.selectedVisitType === 'CHRONIC') {
          this.completeBtn.visible = false;
        } else {
          this.completeBtn.visible = true;
        }
      } else if (this.isPCNTab()) {
        this.completeBtn.visible = true;
      } else if (this.isHSGTab()) {
        this.completeBtn.visible = true;
      } else if (this.isServicesTab()) {
        this.completeBtn.visible = true;
      } else {
        this.completeBtn.visible = false;
      }
    } else {
      this.completeBtn.visible = false;
    }
  }

  lockConsultationOnInitial() {
    const tab = this.getTabByKey(TABS.CONSULTATION);

    if (this.isInitialState()) {
      tab.disabled = true;
    } else {
      tab.disabled = false;
    }
  }

  disablePrinting(valid) {
    if (this.isCARole() || this.isAdminRole()) {
      const printing = this.getTabByKey('Printing');
      if (!valid) {
        printing.disabled = true;
        printing.customClass = 'printing-disabled';
      } else {
        printing.disabled = false;
        printing.customClass = '';
      }
    }
  }

  disableMedicalServicesAndPCNForDirectDispensing(state: boolean) {
    const pcn = this.getTabByKey('CDM');
    const services = this.getTabByKey('Medical Services');
    if (this.isCARole() || this.isAdminRole()) {
      if (state) {
        if(!this.isDrugRefillFeature()){
          services.disabled = true;
          services.customClass = 'services-disabled';
        }
        if (pcn) {
          pcn.disabled = true;
          pcn.customClass = 'pcn-disabled';
        }
      } else {
        services.disabled = false;
        services.customClass = '';
        if (pcn) {
          pcn.disabled = false;
          pcn.customClass = '';
        }
      }
    } else {
      services.disabled = false;
      services.customClass = '';
      if (pcn) {
        pcn.disabled = false;
        pcn.customClass = '';
      }
    }
  }

  isDrugRefillFeature() {
    return this.akitaAppQuery.checkClinicFeatureExist('DRUG_REFILL_MC');
  }

  displayPaymentTab() {
    if (this.isPaymentState()) {
      this.displayTab(TABS.PAYMENT);
    } else {
      this.hideTab(TABS.PAYMENT);
    }
  }

  displayPCNTab(isCDM: boolean) {
    if (isCDM) {
      this.displayTab(TABS.PCN);
    } else {
      this.hideTab(TABS.PCN);
    }
  }

  disableDispensingTab() {
    if (this.isPaymentState() && this.akitaCaseVisitQuery.getActive().onlinePaid) {
      this.disableTab(TABS.DISPENSING);
    } else {
      this.enableTab(TABS.DISPENSING);
    }
  }

  displayHsgTab(showHsgTab) {
    if (showHsgTab) {
      this.displayTab(TABS.HSG);
    } else {
      this.hideTab(TABS.HSG);
    }
  }

  configureTabVisibilityByRole(isPcn, showHsgTab) {
    // PAYMENT TAB CONFIG
    this.displayPaymentTab();

    // PCN TAB CONFIG
    this.displayPCNTab(isPcn);

    //HSG TAB CONFIG
    this.displayHsgTab(showHsgTab && this.hasHSGRole());

    if (!this.hasDoctorRole()) {
      // CA Role
      this.configureTabsVisibilityForCA();
    } else if (!this.hasCARole()) {
      // Doctor Role
      this.configureTabsVisibilityForDoctor();
    }

    // Other Tabs
    if (this.permissionsService.getPermission('ROLE_VIEW_VITALS')) {
      this.displayTab(TABS.VITALS);
    } else {
      this.hideTab(TABS.VITALS);
    }

    if (this.permissionsService.getPermission('ROLE_ASSESSMENT_ENQUIRE')) {
      this.displayTab(TABS.ASSESSMENTS);
    } else {
      this.hideTab(TABS.ASSESSMENTS);
    }

    if (this.permissionsService.getPermission('ROLE_VIEW_LABEL')) {
      this.displayTab(TABS.PRINTING);
    } else {
      this.hideTab(TABS.PRINTING);
    }

    if (
      this.permissionsService.getPermission('ROLE_MEDICAL_VIEW_POLICY_HOLDER')
    ) {
      this.displayTab(TABS.COVERAGE);
    } else {
      this.hideTab(TABS.COVERAGE);
    }

    if (this.permissionsService.getPermission('ROLE_SURVEY')) {
      this.displayTab(TABS.SURVEY);
    } else {
      this.hideTab(TABS.SURVEY);
    }

    if (this.permissionsService.getPermission('ROLE_VIEW_DENTAL_CHART')) {
      this.displayTab(TABS.DENTAL);
    } else {
      this.hideTab(TABS.DENTAL);
    }
  }

  configureTabsVisibilityForCA() {
    // const consultationTab = this.getTabByKey(TABS.CONSULTATION);
    // CA Role
    this.hideTab(TABS.CONSULTATION);
    this.displayTab(TABS.DISPENSING);
    if (this.permissionsService.getPermission('ROLE_VIEW_LABEL')) {
      this.displayTab(TABS.PRINTING);
    } else {
      this.hideTab(TABS.PRINTING);
    }

    const tab = this.getTabByKey(TABS.CONSULTATION);
    if (this.isInitialState()) {
      tab.disabled = true;
    } else {
      tab.disabled = false;
    }
  }

  configureTabsVisibilityForDoctor() {
    this.displayTab(TABS.CONSULTATION);
    this.hideTab(TABS.DISPENSING);
    this.hideTab(TABS.PRINTING);
    this.hideTab(TABS.PAYMENT);
  }

  configureInitialTabByRoleAndStatus() {
    // Listen for Tab Changes
    // and switch tab content to current tab selected
    if (this.getActiveTab()) {
      const status = this.store.getVisitStatus();
      if (this.isDoctorRole()) {
        // Doctor Role
        if (this.isInitialState()) {
          this.openProfileTab();
        } else if (this.isConsultState() || this.isPostConsultState()) {
          this.openConsultationTab();
        } else if (this.isPaymentState()) {
          this.openConsultationTab();
        }
      } else if (this.isCARole()) {
        // CA role
        if (this.isConsultState()) {
          this.openProfileTab();
        } else if (this.isPostConsultState()) {
          this.openDispensingTab();
        } else if (this.isPaymentState()) {
          this.openPaymentTab();
        }
      } else {
        if (this.isInitialState()) {
          this.openProfileTab();
        } else if (this.isConsultState()) {
          this.openConsultationTab();
        } else if (this.isPostConsultState()) {
          this.openDispensingTab();
        } else if (this.isPaymentState()) {
          this.openPaymentTab();
        }
      }
    }
  }

  // Role Checks
  isCARole() {
    return (
      !this.permissionsService.getPermission('ROLE_DOCTOR') &&
      this.permissionsService.getPermission('ROLE_CA')
    );
  }

  isDoctorRole() {
    return (
      this.permissionsService.getPermission('ROLE_DOCTOR') &&
      !this.permissionsService.getPermission('ROLE_CA')
    );
  }
  isAdminRole() {
    return (
      this.permissionsService.getPermission('ROLE_DOCTOR') &&
      this.permissionsService.getPermission('ROLE_CA')
    );
  }

  hasDoctorRole() {
    return this.permissionsService.getPermission('ROLE_DOCTOR');
  }

  hasCARole() {
    return this.permissionsService.getPermission('ROLE_CA');
  }

  hasHSGRole() {
    return this.permissionsService.getPermission('ROLE_HSG_ACCESS');
  }

  // Open Tabs
  openProfileTab() {
    this.setActiveTab(TABS.PROFILE);
  }

  openDocumentsTab() {
    this.setActiveTab(TABS.DOCUMENTS);
  }

  openVitalsTab() {
    this.setActiveTab(TABS.VITALS);
  }

  openHistoryTab() {
    this.setActiveTab(TABS.HISTORY);
  }

  openPCNTab() {
    this.setActiveTab(TABS.PCN);
  }

  openConsultationTab() {
    this.setActiveTab(TABS.CONSULTATION);
  }

  openServicesTab() {
    this.setActiveTab(TABS.SERVICES);
  }

  openCoverageTab() {
    this.setActiveTab(TABS.COVERAGE);
  }

  openDispensingTab() {
    this.setActiveTab(TABS.DISPENSING);
  }

  openPrintingTab() {
    this.setActiveTab(TABS.PRINTING);
  }

  openPaymentTab() {
    this.setActiveTab(TABS.PAYMENT);
  }

  // Check which tab
  isProfileTab() {
    return this.activeTab.title === TABS.PROFILE;
  }


  isDocumentsTab() {
    return this.activeTab.title === TABS.DOCUMENTS;
  }

  isConsultationTab() {
    return this.activeTab.title === TABS.CONSULTATION;
  }

  isDispensingTab() {
    return this.activeTab.title === TABS.DISPENSING;
  }

  isPaymentTab() {
    return this.activeTab.title === TABS.PAYMENT;
  }

  isPrintingTab() {
    return this.activeTab.title === TABS.PRINTING;
  }

  isServicesTab() {
    return this.activeTab.title === TABS.SERVICES;
  }

  isPCNTab() {
    return this.activeTab.title === TABS.PCN;
  }

  isHSGTab() {
    return this.activeTab.title === TABS.HSG;
  }

  // Check Status
  isInitialState() {
    return this.store.getVisitStatus() === STATUS_INITIAL;
  }

  isConsultState() {
    return this.store.getVisitStatus() === STATUS_CONSULT;
  }

  isPostConsultState() {
    return this.store.getVisitStatus() === STATUS_POST_CONSULT;
  }

  isPaymentState() {
    return this.store.getVisitStatus() === STATUS_PAYMENT;
  }

  isCompleteState() {
    return this.store.getVisitStatus() === STATUS_COMPLETE;
  }

  getUpdatePriceLoading() {
    return this.updatePriceLoading$.asObservable();
  }

  showUpdatePriceLoading() {
    this.updatePriceLoading = true;
    this.updatePriceLoading$.next(true);
  }

  hideUpdatePriceLoading() {
    this.updatePriceLoading = false;
    this.updatePriceLoading$.next(false);
  }
}

export interface ButtonConfig {
  disabled?: boolean;
  visible?: boolean;
}

export interface TabConfig {
  title?: string;
  content?: string;
  active?: boolean;
  disabled?: boolean;
  customClass?: string;
}
