<div class="modal-header">
    <h4 class="modal-title pull-left">Care Plan</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-care-plan-card title="Health Goals">
        <div class="row">
            <div class="col-12">

            </div>
        </div>
    </app-care-plan-card>
</div>
<div class="modal-footer">
    <button class="button-green" (click)="onClose()">Close</button>
</div>