import {
  PolicyHolderInfo,
  createPolicyHolderInfo,
} from './../PolicyHolderInfo';
import { MedicalCoverage, createMedicalCoverage } from './../MedicalCoverage';

export interface CoverageInfo {
  coverage?: MedicalCoverage[];
  limit?: any;
  policyHolder?: PolicyHolderInfo[];
}

export function createCoverageInfo({
  coverage = [createMedicalCoverage()],
  limit = {},
  policyHolder = [createPolicyHolderInfo()],
}: CoverageInfo): CoverageInfo {
  return <CoverageInfo>{
    coverage,
    limit,
    policyHolder,
  };
}
