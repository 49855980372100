import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-appointments-error-message',
  templateUrl: './appointments-error-message.component.html',
  styleUrls: ['./appointments-error-message.component.scss'],
})
export class AppointmentsErrorMessageComponent implements OnInit {
  @Input() apptIsAvailable: boolean;
  @Input() hasDoubleAppointments: boolean;
  @Input() apptBookedWithin1Hour: boolean;
  checkbox = false;
  @Output() checkBoxEvent = new EventEmitter<boolean>();
  constructor() {}

  ngOnInit() {}

  onCheckBoxChanged(){
    this.checkBoxEvent.next(this.checkbox);
  }
}
