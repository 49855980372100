import { Injectable } from '@angular/core';

import {
  EntityStore,
  StoreConfig,
  EntityState,
  MultiActiveState,
} from '@datorama/akita';

import { AKITA_CACHE_DURATION } from '../../constants/akita.config';
import { ChronicCondition } from '../../objects/state/ChronicCondition';

export interface ChronicConditionState
  extends EntityState<ChronicCondition>,
    MultiActiveState {}

const initialState = {
  active: [],
};
@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'chroniccondition',
  idKey: 'code',
  cache: { ttl: AKITA_CACHE_DURATION },
  resettable: true,
})
export class AkitaChronicConditionStore extends EntityStore<
  ChronicConditionState,
  ChronicCondition
> {
  constructor() {
    super(initialState);
  }
}
