import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-confirm-popup',
  templateUrl: './custom-confirm-popup.component.html',
  styleUrls: ['./custom-confirm-popup.component.scss']
})
export class CustomConfirmPopupComponent implements OnInit {

  @Input() buttonData: any;

  cancelBtnShow: boolean = false;
  title: any = '';
  displayMsg:any =  '';

  public closeEvent: EventEmitter<boolean> = new EventEmitter();
  public customBtnEvent: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.closeEvent.emit();
  }

  public onButtonClick(code): void {
    this.customBtnEvent.emit(code);
  }

  public onNo(): void {
    this.onClose();
  }

  public onClose(): void {
    this.closeEvent.emit(true);
  }

}
