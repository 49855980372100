<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancelModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            {{message}}
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" *ngIf="buttonConfig.isCancel" class="button-primary" (click)="cancelModal()">Cancel</button>
    <button type="button" *ngIf="buttonConfig.isDelete" class="button-red" (click)="onConfirm()">Delete</button>
    <button type="button" *ngIf="buttonConfig.isConfirm" class="button-green" (click)="onConfirm()">Confirm</button>
</div>