import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

import { HSGOnboardingQuestionnaire } from '../../../../../../model/HSGOnboardingQuestionnaire';

@Component({
  selector: 'app-questionnaire-responses',
  templateUrl: './questionnaire-responses.component.html',
  styleUrls: ['./questionnaire-responses.component.scss']
})
export class QuestionnaireResponsesComponent implements OnInit {

  public questionnaire: HSGOnboardingQuestionnaire;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void { }

  public onClose(): void {
    this.bsModalRef.hide();
  }
}