import { FormArray, FormGroup, ValidatorFn } from '@angular/forms';

export function AtLeastOneRequiredCheckbox(minRequired = 1): ValidatorFn {
    return function validate(formArray: FormArray) {
        let checked = 0;

        formArray.controls.forEach(control => {
            if (control.value.isChecked) {
                checked++;
            }
        });

        if (checked < minRequired) {
            return {
                requireCheckboxesToBeChecked: true,
            };
        }

        return null;
    };
}