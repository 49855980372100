<div class="card" class="resizeNav" style="background-color:#f2f2f2">
  <div class="card-header">
    {{ (isInitial || isEdit) ? 'Sketch Pad' : 'Preview'}}
    <button type="button" class="close pull-right" aria-label="Close" (click)="isEdit || isPreview ? closePreview() : saveEdit()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="card-body">
    <div class="row">
      <div id="drawing-board-large" class="col-12">
        <div *ngIf="!isPreview" class="row justify-content-between">
          <div class="col-2">
            <button class="btn btn-sm btn-grey-600 m-1" [disabled]="shapeArray && shapeArray.length === 0" (click)="undoShape()"><i class="icon-action-undo"></i></button>
            <button class="btn btn-sm btn-grey-600 m-1" [disabled]="shapeHistoryArray && shapeHistoryArray.length === 0" (click)="redoShape()"><i class="icon-action-redo"></i></button>
          </div>

          <div class="col-5">
            <div class="row m-1 justify-content-end">
              <div [ngClass]="shapeButton.text ? 'selector' : ''"><i class="icon-font drawing-icon" (click)="addShape('text')"></i></div>
              <div [ngClass]="shapeButton.line ? 'selector' : ''"><i class="icon-pencil drawing-icon" (click)="addShape('line')"></i></div>
              <div [ngClass]="shapeButton.erase ? 'selector' : ''"><i class="icon-eraser drawing-icon" (click)="addShape('erase')"></i></div>
              <div [ngClass]="colorButton['black'].selected ? 'selector' : ''"><button class="ml-2 btn btn-circle btn-circle-sm btn-black m-1 border-dark" (click)="pickColorSelection('black')"></button></div>
              <div [ngClass]="colorButton['dark-grey'].selected ? 'selector' : ''"><button class="btn btn-circle btn-circle-sm btn-grey-600 m-1 border-dark" (click)="pickColorSelection('dark-grey')"></button></div>
              <div [ngClass]="colorButton['light-grey'].selected ? 'selector' : ''"><button class="btn btn-circle btn-circle-sm btn-light-gray m-1 border-dark" (click)="pickColorSelection('light-grey')"></button></div>
              <div [ngClass]="colorButton['blue'].selected ? 'selector' : ''"><button class="btn btn-circle btn-circle-sm btn-blue m-1 border-dark" (click)="pickColorSelection('blue')"></button></div>
              <div [ngClass]="colorButton['red'].selected ? 'selector' : ''"><button class="btn btn-circle btn-circle-sm btn-maroon m-1 border-dark" (click)="pickColorSelection('red')"></button></div>
              <div [ngClass]="colorButton['yellow'].selected ? 'selector' : ''"><button class="btn btn-circle btn-circle-sm btn-sandy-yellow m-1 border-dark" (click)="pickColorSelection('yellow')"></button></div>
            </div>
          </div>

          <div class="col-5">
            <div class="row m-1 justify-content-end">
              <button *ngIf="isEdit" class="btn btn-sm btn-brand-primary m-1 float-right" (click)="saveEdit()" >Save</button>
              <!-- <button class="btn btn-sm btn-brand-primary m-1 float-right" (click)="saveLayer()" ><i class="icon-floppy"></i> Download</button> -->
              <button class="btn btn-sm btn-grey-600 m-1 float-right" [disabled]="layer.getChildren().toArray().length < 1" (click)="clearLayer()">Clear Canvas</button>
            </div>
          </div>
        </div>
        <div id="container" [ngClass]="shapeButton.erase ? 'eraser' : ''"></div>
      </div>
    </div>
  </div>
</div>
