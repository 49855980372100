// Service
import { AppointmentsFactoryService } from './../../../../services/appointments-factory.service';
import { AppointmentMasterService } from './../../appointment-master/appointment-master.service';
import { ApiAppointmentsService } from '../../../../services/api-appointments.service';

//Libraries
import { Injectable } from '@angular/core';

//Objects
import { CalendarAppointment } from '../../../../objects/CalendarAppointment';
import {
  CLINIC_HOLIDAY,
  CLINIC_BLOCKED_TIME,
} from '../../../../constants/app.constants';
import { ClinicCalendar, BlockDate } from '../../../../objects/ClinicCalendar';
import { ClinicCalendarStore } from './clinic-calendar.store';
import { AppointmentMasterQuery } from '../../appointment-master/appointment-master.query';

@Injectable({
  providedIn: 'root',
})
export class ClinicCalendarService {
  constructor(
    protected store: ClinicCalendarStore,
    private apptFactory: AppointmentsFactoryService,
    private apiAppointmentsService: ApiAppointmentsService,
    private appointmentMasterQuery: AppointmentMasterQuery,
    private appointmentMasterService: AppointmentMasterService
  ) {}

  set(clinic: ClinicCalendar) {
    this.store.update(clinic);

    this.setCalendarEvents(clinic.clinicHolidays, CLINIC_HOLIDAY);
    this.setCalendarEvents(clinic.blockedTimes, CLINIC_BLOCKED_TIME);
  }

  getClinicCalendar() {
    this.apiAppointmentsService
      .findCalendarByClinicId(this.appointmentMasterQuery.getClinicSelected())
      .subscribe(res => {
        const clinicCalendar: ClinicCalendar = this.apptFactory.createClinicCalendar(
          <ClinicCalendar>res.payload
        );
        this.set(clinicCalendar);
        this.appointmentMasterService.setStoreReady();
      });
  }

  setCalendarEvents(blockedDates: BlockDate[], type) {
    blockedDates.forEach((blockedDate: BlockDate) => {
      let blockDate = this.apptFactory.createBlockDate(blockedDate);
      const event: CalendarAppointment = this.apptFactory.createCalendarEvent(
        type,
        null,
        blockDate,
        null
      );
    });
  }
}
