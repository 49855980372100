import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { WarningMadalService } from './warning-madal.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent implements OnInit, OnDestroy {

  title = 'Warning';
  messages = [];
  adrmessages = [];
  iconColor = 'text-danger';
  isSuccessIcon = false;
  // warningTemplate: TemplateRef<any>;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: WarningMadalService
    ) {}

  ngOnInit() {

    this.modalService.getMessage().pipe(filter(msg => !!msg && !!msg.length), untilDestroyed(this)).subscribe(msg => {
      this.messages.push(msg)
    })

    this.modalService.getAdRMessage().pipe(filter(msg => !!msg && !!msg.length), untilDestroyed(this)).subscribe(msg => {
      this.adrmessages.push(msg)
    })
  
  }

  ngOnDestroy() {

  }

  hide() {
    this.bsModalRef.hide();
    this.modalService.hide();
  }

  

}
