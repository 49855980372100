<div class="card">
  <div class="card-header">
      Medical Coverage
      <button type="button" class="close pull-right" aria-label="Close" (click)="onBtnExit()">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
  <div class="card-body">
    <div class="alert alert-secondary" role="alert" *ngIf="countDown > 0 && countDownTimer">
      Please don't interact with the pc, automation will start in ... {{countDown}}
    </div>

      <!-- <div class="col-md-6 pl-3 pb-2">
          <label> (Fields marked as
            <span class="compulsory">*</span> are mandatory)
          </label>
      </div> -->
      <div class="d-flex">
        <div class="mr-auto">
            <label> (Fields marked as
                <span class="compulsory">*</span> are mandatory)
            </label>
        </div>
        <div *ngxPermissionsOnly="['ROLE_SEARCH_CORPORATE_POLICY_HOLDER']" class="mb-2">
            <button class="btn btn-outline-brand-secondary" (click)="checkCoverage()">+ Check Coverage</button>
        </div>
      </div>

      <div class="row">
          <div class="col-12">
              <div *ngIf="patientCoverages">
                  <div class="row data-table">
                      <div class="input-group input-group-sm mb-0 col-6 px-0">
                          <div class="col-5"><label class="mb-0">Company <span class="compulsory">*</span></label></div>
                          <div class="col-4"><label class="mb-0">Plan<span class="compulsory">*</span></label></div>
                          <div class="col-3">
                              <label class="mb-0">Policy No.<span class="compulsory">*</span>
                                   <span class="icon-info-circled" [popover]="info" [outsideClick]="true" placement="top"></span>
                                </label>
                          </div>
                      </div>
                      <div class="input-group input-group-sm input-group-append mb-0 col-6 px-0">
                          <div class="col-3"><label class="mb-0 px-0">Start Date <span class="compulsory">*</span></label></div>
                          <div class="col-3"><label class="mb-0">End Date <span class="compulsory">*</span></label></div>
                          <div class="col-2"><label class="mb-0">Cost Center</label> </div>
                          <div class="col-3"><label class="mb-0">Remarks</label> </div>
                      </div>
                  </div>
                    <div *ngFor="let item of patientCoverages.controls; let i = index;">
                        <app-medical-coverage-edit-item
                            [patientCoverageItem]='item'
                            [newPatientId]="newPatientId"
                            (onDelete)="onbtnDeleteClicked(i,$event)"
                            >
                        </app-medical-coverage-edit-item>
                    </div>
                    <div *ngIf="patientCoverages.controls.length > 0; else emptyListTemplate">
                        <hr class="mt-0 mb-2">
                        <a target="_blank" href="https://tip.healthwaymedical.com/">
                            <span class="icon-link"></span>TIP Portal
                        </a>
                    </div>
                  <div class="text-right mt-3">
                      <button name="btnAdd" class="btn btn-outline-brand-secondary" [disabled]='!patientCoverages.valid' (click)="addNewItem()">+ Add Another Coverage</button>
                  </div>
              </div>
              <!-- Button for closing popup -->
              <div class="text-right pt-3">
                  <button name="btnSave" class="btn btn-brand-secondary" *ngIf="!hasCoverageModal" [disabled]='!patientCoverages.valid' (click)="onBtnSaveClicked()">Close</button>
                  <button name="btnSave" class="btn btn-brand-secondary" *ngIf="hasCoverageModal" [disabled]='!patientCoverages.valid' (click)="onBtnSaveClicked()">Save</button>
              </div>
          </div>
      </div>
  </div>
</div>

<ng-template #info>
    You may use Policy No. / Employee No.
</ng-template>

<ng-template #emptyListTemplate>
  <div class="row p-3" style="background-color:#f2f4f7;color:dimgrey">
      <span class="icon-info-circled"></span>&nbsp;&nbsp;Please click below to assign a medical coverage.
  </div>
</ng-template>
