import { PCNVisitCase } from '../objects/response/pcn/PCNVisitCase';
import { HttpResponseBody } from './../objects/response/HttpResponseBody';
import { map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';
import MedicalReferenceEntity from '../objects/response/pcn/MedicalReferenceEntity';
import PCNDetails from '../objects/response/pcn/PCNDetails';
import { BackendService } from './backend.service';
import { AkitaPCNVisitStore } from './states/akita-pcn-visit-case.store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DISPLAY_DATE_FORMAT, DB_FULL_DATE_TIMEZONE_NO_SPACE, DB_FULL_DATE_TIMEZONE_NO_SPACE_Z, DATE_ASSESSMENT_CODES } from '../constants/app.constants';
import * as moment from 'moment'

@Injectable()
export class ApiPcnVisitsService implements OnDestroy {
  private API_PCN_VISITS_URL;
  private API_NPHS_VISITS_URL;
  private API_SMART_CMS_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private backendService: BackendService,
    private akitaPCNVisitStore: AkitaPCNVisitStore
  ) {
    this.API_PCN_VISITS_URL = appConfig.getConfig().API_PCN_VISITS_URL;
    this.API_NPHS_VISITS_URL = appConfig.getConfig().API_URL_ENV;
    this.API_SMART_CMS_URL = appConfig.getConfig().API_SMART_CMS_URL;
  }



  ngOnDestroy() { }

  getVisits(reqBody): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PCN_VISITS_URL}/visits/get`,
      JSON.stringify(reqBody)
    );
  }

  getChronicConditions(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PCN_VISITS_URL}/diagnosis/conditions/all`,
      JSON.stringify({})
    );
  }

  // getDiagnosisCodes(conditionId): Observable<HttpResponseBody> {
  //   return this.http.post<HttpResponseBody>(
  //     `${this.API_PCN_VISITS_URL}/diagnosis/${conditionId}/diagnosisCodes/list
  //     `,
  //     JSON.stringify({}),
  //     {
  //       headers: this.headers
  //     }
  //   );
  // }

  setActivePCNVisitId(visitId: string) {
    this.akitaPCNVisitStore.setActive(visitId);
  }

  listAllChronicConditions(queue = false): Observable<HttpResponseBody> {
    const url = `${this.API_PCN_VISITS_URL}/diagnosis/conditions/all`;
    const body = JSON.stringify({});
    const options = {};

    return queue
      ? this.backendService.invoke(url, body, options)
      : this.http.post<HttpResponseBody>(url, body);
  }

  listPCNVisit(
    clinicId: string,
    fromDate: string,
    toDate: string,
    patientId: string,
    visitType: string,
    chronicAcuteType: string,
    searchValue: string,
    visitStates: string[]
  ): void {
    this.http
      .post(
        `${this.API_PCN_VISITS_URL}/search/${clinicId}`,
        JSON.stringify({
          fromDate,
          toDate,
          patientId,
          chronicAcuteType,
          visitType,
          searchValue,
          visitStates
        })
      )
      .pipe(
        map((res: HttpResponseBody) => {
          // const {visitId, patientId, medicalReferenceEntity: {cdmpDetails}}  = registryEntity;
          return res.payload.map(item => {
            const {
              patientName,
              patientIdentifier,
              registryEntity,
              userId,
              registryEntity: { visitId },
            } = item;
            return {
              patientName,
              patientIdentifier,
              registryEntity,
              userId,
              visitId,
            };
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(pcnVisitCase => this.akitaPCNVisitStore.set(pcnVisitCase));
  }

  listCIDCVisit(
    clinicId: string,
    fromDate: string,
    toDate: string,
    cidcSubmissionStatus: string,
    chronicAcuteType: string,
    searchValue: string,
  ): void {
    this.http
      .post(
        `${this.API_SMART_CMS_URL}/cidc/search/${clinicId}`,
        JSON.stringify({
          fromDate,
          toDate,
          chronicAcuteType,
          cidcSubmissionStatus,
          searchValue
        })
      )
      .pipe(
        map((res: HttpResponseBody) => {
          return res.payload.map(item => {
            const {
              patientName,
              patientIdentifier,
              registryEntity,
              userId,
              registryEntity: { visitId },
              cidcSubmissionStatus,
              cidcSubmissions,
            } = item;
            return {
              patientName,
              patientIdentifier,
              registryEntity,
              userId,
              visitId,
              cidcSubmissionStatus,
              cidcSubmissions,
            };
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(pcnVisitCase => this.akitaPCNVisitStore.set(pcnVisitCase));
  }

  getPCNVisit(
    clinicId: string,
    fromDate: string,
    toDate: string,
    patientId: string,
    visitType: string,
    chronicAcuteType: string,
    searchValue: string,
    visitStates: string[]
  ) : Observable<HttpResponseBody> {
    return this.http
      .post<HttpResponseBody>(
        `${this.API_PCN_VISITS_URL}/search/${clinicId}`,
        JSON.stringify({
          fromDate,
          toDate,
          patientId,
          chronicAcuteType,
          visitType,
          searchValue,
          visitStates
        })
      );
  }

  listNPHSVisit(
    clinicId: string,
    fromDate: string,
    toDate: string,
    searchValue: string,

  ): void {
    this.http
      .post(
        `${this.API_NPHS_VISITS_URL}/nphs/search/${clinicId}`,
        JSON.stringify({
          fromDate,
          toDate,
          searchValue
        })
      )
      .pipe(
        map((res: HttpResponseBody) => {
          // const {visitId, patientId, medicalReferenceEntity: {cdmpDetails}}  = registryEntity;
          return res.payload.map(item => {
            const {
              patientName,
              registryEntity,
              userId,
              registryEntity: { visitId },
            } = item;
            return {
              patientName,
              registryEntity,
              userId,
              visitId,
            };
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(pcnVisitCase => this.akitaPCNVisitStore.set(pcnVisitCase));
  }




  updateNphsDetails(visitId: string, patientinfo: any) {


    return this.http.post<HttpResponseBody>(
      `${this.API_NPHS_VISITS_URL}/nphs/update/${visitId}`,
      patientinfo
    );
  }

  updateTestDates(key, valuesofAssesment, assessment) {
    valuesofAssesment.forEach(value => {
      if (value[0] == key) {
        if(value[1]){
          var screenDate = value[1];
          let dateforDB = moment(screenDate, DISPLAY_DATE_FORMAT).toDate();
          assessment.assessments[key] = dateforDB;
        } else {
          assessment.assessments[key] = "";
        }
      } 
    })
  }

  updateMultiSelectValues(assessment) {
    if (assessment && assessment.assessments) {
      Object.keys(assessment.assessments).forEach(key => {
        if (assessment.assessments[key] instanceof Array) {
          assessment.assessments[key] = assessment.assessments[key].join('|');
        }
      })
    }
  }

  updatePCNDetails(patientId: string, pcnDetails: PCNDetails, updateAssessments: boolean = true) {
    let { conditions, vitals, assessment, patientExtraDetails } = pcnDetails;

    if (updateAssessments) {
      let valuesofAssesment = [];
      valuesofAssesment = Object.entries(assessment.assessments);
      Object.keys(assessment.assessments).forEach(key => {
        if (DATE_ASSESSMENT_CODES.includes(key)) {
          this.updateTestDates(key, valuesofAssesment, assessment);
        }
      })
      this.updateMultiSelectValues(assessment);
    } else {
      assessment = null;
      vitals = [];
    }

    return this.http.post<HttpResponseBody>(
      `${this.API_PCN_VISITS_URL}/update/${patientId}`,
      JSON.stringify({ conditions, vitals, assessment, patientExtraDetails })
    );
  }

  submitCidc(visitId: string, patientId: string, cdmpDetails: PCNDetails, cidcSubmissionAction: string) {
    let { assessment } = cdmpDetails;

    let valuesofAssesment = [];
    valuesofAssesment = Object.entries(assessment.assessments);
    Object.keys(assessment.assessments).forEach(key => {
      if (DATE_ASSESSMENT_CODES.includes(key)) {
        this.updateTestDates(key, valuesofAssesment, assessment);
      }
    })

    return this.http.post<HttpResponseBody>(
      `${this.API_SMART_CMS_URL}/cidc/submit`,
      JSON.stringify({ visitId, patientId, cdmpDetails, cidcSubmissionAction })
    );
  }

  updateVisitType(visitId: string, visitType: string) {
    return this.http.post<HttpResponseBody>(
      `${this.API_PCN_VISITS_URL}/update/${visitId}/${visitType}`,
      JSON.stringify({})
    );
  }

  listCIDCMappings(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_SMART_CMS_URL}/cidc/get-mapping`,
      {}
    );
  }
}
