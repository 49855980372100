import { CoveragePlan } from './../../objects/MedicalCoverageAdd';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { QueryEntity } from '@datorama/akita';

import {
  AkitaCaseVisitStore,
  AkitaCaseVisitState,
} from './akita-case-visit.store';
import { DB_FULL_DATE_FORMAT } from '../../constants/app.constants';
import {
  PatientVisitEntities,
  CaseCoveragePlan,
} from '../../objects/state/Case';
import { map, mergeMap, filter, tap } from 'rxjs/operators';
import { DispatchItem } from '../../objects/state/ChargeItem';
import { forkJoin, merge, Observable } from 'rxjs';
import {
  IPackageItem,
  IDispaches,
  PACKAGE_TYPE,
} from '../../components/shared/package/package.model';
import { MedicalCoverageByCaseRes } from '../../objects/PolicyHolderInfo';

import { get } from 'lodash';
const getArr = (obj, ltr) => get(obj, ltr) || []

@Injectable({ providedIn: 'root' })
export class AkitaCaseVisitQuery extends QueryEntity<
  AkitaCaseVisitState,
  PatientVisitEntities
> {
  static readonly sortAscending = (
    a: PatientVisitEntities,
    b: PatientVisitEntities,
    state
  ) => {
    const aDate = moment(a.startTime, DB_FULL_DATE_FORMAT);
    const bDate = moment(b.startTime, DB_FULL_DATE_FORMAT);

    return aDate.isBefore(bDate) ? -1 : aDate.isAfter(bDate) ? 1 : 0;
  };

  static readonly sortDescending = (
    a: PatientVisitEntities,
    b: PatientVisitEntities,
    state
  ) => {
    const aDate = moment(a.startTime, DB_FULL_DATE_FORMAT);
    const bDate = moment(b.startTime, DB_FULL_DATE_FORMAT);

    return aDate.isBefore(bDate) ? 1 : aDate.isAfter(bDate) ? -1 : 0;
  };

  constructor(protected store: AkitaCaseVisitStore) {
    super(store);
  }

  isPackagedCase(): boolean {
    return this.getValue().isPackage;
  }

  selectIsPackageCase(): Observable<boolean> {
    return this.select().pipe(map(caseData => caseData.isPackage));
  }

  isSubsequentVisitForPackagedCase(): boolean {
    return this.isSubsequentVisit() && this.isPackagedCase();
  }

  isSubsequentVisit(): boolean {
    return this.getValue().visitIds && this.getValue().visitIds.length > 1;
  }
  isPackagedVisitForFirstVisit(): boolean {
    return (
      !this.isSubsequentVisit() &&
      this.getAll()[0].medicalReferenceEntity.packages &&
      this.getAll()[0].medicalReferenceEntity.packages.length > 0
    );
  }

  packageCase(): boolean {
    let activeCasePackage = false;
    if (this.getActive()) {
      if (
        this.getActive().medicalReferenceEntity &&
        this.getActive().medicalReferenceEntity.packages &&
        this.getActive().medicalReferenceEntity.packages.length > 0
      ) {
        activeCasePackage = true;
      }
    }

    return this.isPackagedCase() || activeCasePackage;
  }

  getPackages(): IPackageItem[] {
    return !!this.getActive()
      ? this.getActive().medicalReferenceEntity.packages
      : [];
  }

  selectPackageCase(): Observable<boolean> {
    return this.selectActive().pipe(
      map(
        data =>
          data &&
          data.medicalReferenceEntity.packages &&
          data.medicalReferenceEntity.packages.length > 0
      ),
      mergeMap(packages =>
        this.select(entity => entity.isPackage).pipe(
          map(isPackage => [packages, isPackage])
        )
      ),
      map(([packages, isPackage]) => packages || isPackage)
    );
  }

  getActiveVisitId() {
    return this.getActive().visitId;
  }

  selectVisitEntitiesAsc() {
    return this.selectAll({ sortBy: AkitaCaseVisitQuery.sortDescending });
  }

  selectVisitEntities() {
    return this.selectAll();
  }

  selectActiveVisit() {
    return this.select(entity => entity.active).pipe(
      mergeMap(visitId => this.selectEntity(visitId))
    );
  }

  selectCaseId(){
    return this.select(entity => entity.caseId);
  }

  selectCaseNumber(){
    return this.select(entity => entity.caseNumber);
  }

  selectCaseStatus() {
    return this.select(entity => entity.status);
  }

  selectCaseSalesOrder(){
    return this.select(entity => entity.salesOrder);
  }

  selectCase() {
    return this.select();
  }

  selectCasePurchaseItems(){
    return this.select(entity => entity.salesOrder.purchaseItem);
  }

  selectCaseInvoices() {
    return this.select(entity => entity.salesOrder.invoices);
  }

  selectOutstanding() {
    return this.select(entity => entity.salesOrder.outstanding);
  }

  selectPackagedCase() {
    return this.select(entity => {
  
      return this.isPackagedCase() || this.isSubsequentVisitForPackagedCase();
    });
  }

  getCaseDate(): string {
    return this.getValue().caseStartDateTime;
  }

  getCasePlanMaxUsageById(planId): CaseCoveragePlan {
    const planMaxUsages = this.getValue().planMaxUsages || [];
    return planMaxUsages.find(
      (plan: CaseCoveragePlan) => plan.planId === planId
    );
  }

  getActiveVisitPlanMaxUsage(): CaseCoveragePlan[] {
    return this.getActive().medicalReferenceEntity.planMaxUsage;
  }

  getActivePackages() {
    return (
      !!this.getActive() && this.getActive().medicalReferenceEntity.packages
    );
  }

  getActiveMedicalReferenceEntity() {
    return this.selectActive(
      activeVisit => activeVisit.medicalReferenceEntity
    ).pipe(filter(data => !!data));
  }

  getActiveUtilizeableSubItems() {
    const pack: IPackageItem[] = this.getActive().medicalReferenceEntity
      .packages;
    const subItems: IDispaches[] = [];

    pack.forEach((p: IPackageItem) => {
      p.dispatches.forEach((d: IDispaches) => {
        if (d.utilize && d.visitId === this.getActiveVisitId()) {
          subItems.push(d);
        }
      });
    });

    return subItems;
  }

  selectActivePackages() {
    return this.selectActive(
      activeVisit => activeVisit.medicalReferenceEntity.packages
    ).pipe(filter(data => !!data));
  }

  selectHasActiveUtilizeableSubItems(): Observable<boolean> {
    return this.selectActive(activeVisit => {
      let hasActiveUtilizeableSubItems = false;
      const packages: IPackageItem[] = this.getActivePackages();
      packages.forEach((p: IPackageItem) => {
        p.dispatches.forEach((d: IDispaches) => {
          if (d.utilize && d.visitId === this.getActive().visitId) {
            hasActiveUtilizeableSubItems = true;
          }
        });
      });

      return hasActiveUtilizeableSubItems;
    });
  }

  selectActiveVisitPlanMaxUsage() {
    return this.selectActive(
      activeVisit => activeVisit.medicalReferenceEntity.planMaxUsage
    ).pipe(filter(data => !!data));
  }

  selectPackagesForActiveVisit(): Observable<IPackageItem[]> {
    return this.selectActive(
      activeVisit => activeVisit.medicalReferenceEntity.packages
    ).pipe(filter(data => !!data));
  }

  getPackageFromActiveVisit(uniquePackageId: string): IPackageItem {
    return this.getActive().medicalReferenceEntity.packages.filter(
      item => item.packageId === uniquePackageId
    )[0];
  }

  getPackagesForPayload(): IPackageItem[] {
    return this.getActive()
      ? this.getActive().medicalReferenceEntity.packages
      : [];
  }

  selectCaseCoverage() {
    return this.select(entity => entity.coverages);
  }

  selectActiveVisitDispatchEntities() {
    return this.selectActive(
      entity => entity.medicalReferenceEntity.dispatchItemEntities
    );
  }

  getCaseCoverage() {
    return this.getValue().coverages;
  }

  getCaseCoveragePlanIds() {
    return this.getValue().coverages.map(cov => cov.planId);
  }

  updateCaseCoverage(coverage: CaseCoveragePlan[]) {
    this.store.update({ coverages: coverage });
  }

  updateActiveVisitPlanMaxUsage(coverage: CaseCoveragePlan[]) {
    this.store.updateActive(entity => {
      return {
        ...entity,
        medicalReferenceEntity: {
          ...entity.medicalReferenceEntity,
          planMaxUsage: coverage,
        },
      };
    });
  }

  updatePackageExcludedCoverage(
    uniquePackageId: string,
    excludedPlanIds: string[]
  ) {
    const packageItem = this.getPackageFromActiveVisit(uniquePackageId);
    if (!!packageItem) {
      this.store.updateActive(entity => {
        return {
          ...entity,
          medicalReferenceEntity: {
            ...entity.medicalReferenceEntity,
            packages: entity.medicalReferenceEntity.packages.map(pack => {
              if (pack.packageId !== uniquePackageId) {
                return pack;
              } else {
                return {
                  ...pack,
                  excludedPlans: [...excludedPlanIds],
                };
              }
            }),
          },
        };
      });
    }
  }

  updatePackagePrice(uniquePackageId: string, price: number) {
    this.store.updateActive(entity => {
      return {
        ...entity,
        medicalReferenceEntity: {
          ...entity.medicalReferenceEntity,
          packages: entity.medicalReferenceEntity.packages.map(pack => {
            if (pack.packageId !== uniquePackageId) {
              return pack;
            } else {
              return {
                ...pack,
                purchasePrice: price,
              };
            }
          }),
        },
      };
    });
  }

  deletePackageItem(uniquePackageId: string) {
    this.store.updateActive(entity => {
      return {
        ...entity,
        medicalReferenceEntity: {
          ...entity.medicalReferenceEntity,
          packages: entity.medicalReferenceEntity.packages.filter(
            pack => pack.packageId !== uniquePackageId
          ),
          dispatchItemEntities: entity.medicalReferenceEntity.dispatchItemEntities.filter(
            item => item.packageId !== uniquePackageId
          ),
        },
      };
    });
  }

  updatePackageValidityForActiveVisit(uniquePackageId: string) {
    const packageItem = this.getPackageFromActiveVisit(uniquePackageId);
    if (!!packageItem) {
      const invalidSubItems = packageItem.dispatches.filter(
        item => item.inventoried && item.utilize && !item.isAvailable
      );
      this.store.updateActive(entity => {
        return {
          ...entity,
          medicalReferenceEntity: {
            ...entity.medicalReferenceEntity,
            packages: entity.medicalReferenceEntity.packages.map(pack => {
              if (pack.packageId !== uniquePackageId) {
                return pack;
              } else {
                return {
                  ...pack,
                  itemIsValid: invalidSubItems.length > 0 ? false : true,
                };
              }
            }),
          },
        };
      });
    }
  }

  isPackagesValidForActiveVisit(): boolean {
    const packageItems =
      this.getActive() && this.getActive().medicalReferenceEntity.packages;
    if (packageItems && packageItems.length) {
      return packageItems.filter(items => !items.itemIsValid).length === 0;
    } else {
      return true;
    }
  }

  getPackageBySubItemCode(subItemCode: string): IPackageItem[] {
    const packageItems =
      this.getActive() && this.getActive().medicalReferenceEntity.packages;
    return (
      packageItems &&
      packageItems.filter(item =>
        item.dispatches.find(subItem => subItem.itemCode === subItemCode)
      )
    );
  }

  getPackageBySubItemId(subItemID: string): IPackageItem[] {
    const packageItems =
      this.getActive() && this.getActive().medicalReferenceEntity.packages;
    return (
      packageItems &&
      packageItems.filter(item =>
        item.dispatches.find(subItem => subItem.itemId === subItemID)
      )
    );
  }

  selectTotalDispenseItems(uniquePackageId: string): Observable<number> {
    return this.selectActive(activeVisit =>
      activeVisit.medicalReferenceEntity.packages.find(
        pck => pck.packageId === uniquePackageId
      )
    ).pipe(
      filter(data => !!data),
      map(({ dispatches }) => {
        return dispatches.filter(item => item.utilize).length;
      })
    );
  }

  updateSubItemForActiveVisit(uniquePackageId: string, subItem: IDispaches) {
    this.store.updateActive(entity => {
      return {
        ...entity,
        medicalReferenceEntity: {
          ...entity.medicalReferenceEntity,
          packages: entity.medicalReferenceEntity.packages.map(pck => {
            if (pck.packageId !== uniquePackageId) {
              return pck;
            } else {
              return {
                ...pck,
                dispatches: pck.dispatches.map(item => {
                  if (
                    item.drugDispenseShortId !== subItem.drugDispenseShortId
                  ) {
                    return item;
                  } else {
                    return subItem;
                  }
                }),
              };
            }
          }),
        },
      };
    });
    this.updatePackageValidityForActiveVisit(uniquePackageId);
  }

  updateActiveVisitPlanMaxUsageByPlanId(planToBeUpdated: CaseCoveragePlan) {
    let planMaxUsage: CaseCoveragePlan[] = this.getActive()
      .medicalReferenceEntity.planMaxUsage;

    const updatedPlans: CaseCoveragePlan[] = planMaxUsage.map(
      (caseCoverage: CaseCoveragePlan) => {
        if (
          caseCoverage.planId === planToBeUpdated.planId &&
          caseCoverage.medicalCoverageId === planToBeUpdated.medicalCoverageId
        ) {
          return planToBeUpdated;
        } else {
          return caseCoverage;
        }
      }
    );

    this.updateActiveVisitPlanMaxUsage(updatedPlans);
  }

  addPackageToActiveVisit(packageItem: IPackageItem) {
    this.store.updateActive(entity => {
      const pack = entity.medicalReferenceEntity.packages
        ? entity.medicalReferenceEntity.packages
        : [];
      return {
        ...entity,
        medicalReferenceEntity: {
          ...entity.medicalReferenceEntity,
          packages: [...pack, packageItem],
        },
      };
    });
  }

  isPackageItemsAvailableForActiveVisit(): boolean {
    const pckg =
      this.getActive() && this.getActive().medicalReferenceEntity.packages;
    return pckg ? pckg.length > 0 : false;
  }

  getPackageTotalPrice() {
    const packageItems = this.getActive().medicalReferenceEntity.packages;
    const totalPriceOfAllAddedPackages =
      packageItems &&
      packageItems
        .map(item => item.purchasePrice)
        .reduce((acc, val) => acc + val, 0) / 100;

    return totalPriceOfAllAddedPackages;
  }

  getAllInventoriedSubItemPackageForActiveVisit(): IPackageItem[] {
    return this.getActive().medicalReferenceEntity.packages.reduce(
      (utilizedPackages, packageItem) => {
        const isUtilizedSubItemAvailable =
          packageItem.dispatches.filter(subItem => subItem.inventoried).length >
          0;
        const finalPackage = isUtilizedSubItemAvailable
          ? { ...packageItem }
          : [];
        return utilizedPackages.concat(finalPackage);
      },
      []
    ) as IPackageItem[];
  }

  updatePackageInActiveVisit(packageItem: IPackageItem) {
    this.store.updateActive(entity => {
      return {
        ...entity,
        medicalReferenceEntity: {
          ...entity.medicalReferenceEntity,
          packages: entity.medicalReferenceEntity.packages.map(pck => {
            if (pck.packageId !== packageItem.packageId) {
              return pck;
            } else {
            
              return packageItem;
            }
          }),
        },
      };
    });
  }

  //RESET PACKAGE PRICE
  resetPackagePrice(packageItemPriceMap: Map<string, number>) {
    this.store.updateActive(entity => {
      return {
        ...entity,
        medicalReferenceEntity: {
          ...entity.medicalReferenceEntity,
          packages: entity.medicalReferenceEntity.packages.map(pck => {
            const origPrice = packageItemPriceMap.get(pck.packageId);
            return {
              ...pck,
              purchasePrice: !!pck.purchasePrice ? pck.purchasePrice : origPrice,
              dispatches: pck.dispatches.map(d => {
                return {
                  ...d,
                  itemPriceAdjustment: {
                    adjustedValue: 0,
                    paymentType: 'DOLLAR',
                    remark: '',
                  },
                };
              }),
            };
          }),
        },
      };
    });
  }
}