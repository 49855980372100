<!-- TOP ROW -->
<div class="row modal-sub-header package-detail cursor mt-1"
  [ngClass]=" (packageItem.itemIsValid && !isPatientAllergic(packageItem)) ? 'isValid' : 'invalidItem'"
  (click)="toggleCollapse()">
  <!-- <pre> {{ packageItem | json }} </pre> -->
  <div></div>
  <!-- Item Code -->
  <div [ngClass]="inDispensing ? 'col-3' : 'col-5'">
    <i [ngClass]="isCollapsed ? 'icon-right-open-big mr-2':'icon-down-open-big mr-2'"></i> <span
      [tooltip]="packageItem.name">{{ packageItem.name }}</span>
    <span class="icon-info-circled text-danger" *ngIf="!packageItem.itemIsValid" placement="auto"
      [tooltip]="packageItem.noInventoryInfo ? 'This package is unvailable because there is no inventory information for one of the items.' : 'This package is unvailable because one of the items inside the package is unavailable.'"></span>
    
    
      <span class="icon-info-circled text-danger" *ngIf="isPatientAllergic(packageItem)" placement="auto"
      [tooltip]="'This patient is allergic to one of the items in package.'"></span>
  
  
    </div>

  <!-- Price -->
  <div [ngClass]="inDispensing ? 'col-1' : 'col-2'">{{packageItem.purchasePrice | displayDollar}}</div>

  <!-- Date -->
  <div [ngClass]="inDispensing ? 'col-1' : 'col-2'">{{packageItem.purchaseDate}}</div>

  <!-- Blank for Batch No and Expiry Date In Dispensing -->
  <div *ngIf="inDispensing" class="col-2">
  </div>
  <div *ngIf="inDispensing" class="col-2">
  </div>

  <!-- Completed -->
  <!-- <div class="col-2 d-flex justify-content-center">{{selectedSubItems | async}} / {{packageItem.subItems.length}}</div> -->
  <div [ngClass]="inDispensing ? 'col-1 pl-4' : 'col-2 pl-4'">{{selectedSubItems | async}} /
    {{packageItem.dispatches.length}}</div>

  <!-- Trash Bin -->
  <div class="col-1">
    <span *ngIf="visitStatus !=='PAYMENT'" [hidden]="isSecondVisitOnwards" style="position:relative;color:gray"
      class="icon-trash input-group-append pt-1 col-2 text-gray" (click)="deletePressed($event)"></span>
  </div>
</div>


<!-- SUB ITEM  -->
<ng-container>
  <div [class.hidden]="isCollapsed" class="row modal-sub-header package-item cursor"
    [ngClass]="(subItem.inventoried && !subItem.isAvailable) || subItem.isPatientAllergic ? 'invalidItem' : 'validItem'"
    *ngFor="let subItem of packageItem.dispatches ; let i = index" (click)="onItemClick($event)">

    <!-- Item Name -->
    <!-- <pre> {{ subItem | json }}</pre> -->
    <div [ngClass]="inDispensing ? 'col-3 pl-5' : 'col-5 pl-5'">
      <span [tooltip]="subItem.itemName">{{ subItem.itemName }}</span>
      <span class="icon-info-circled text-danger" *ngIf="subItem.inventoried && !subItem.isAvailable" placement="auto"
      [tooltip]="packageItem.noInventoryInfo ? 'There is no inventory information for this item.' : 'This item is unavailable.'"></span>

      <span class="icon-info-circled text-danger" *ngIf="subItem.isPatientAllergic" placement="auto"
      [tooltip]="'This patient is allergic to this medicine'"></span>
    </div>

    <!-- Price -->
    <div [ngClass]="inDispensing ? 'col-1' : 'col-2'">
      <span *ngIf="packageItem.priceAdjustmentType !== 'SUB_ITEM'; else subItemEdit">/</span>
      <ng-template #subItemEdit>
        <ng-container *ngIf="!isSecondVisitOnwards; else priceSpan">
          <input type="number" class="form-control form-control-sm" placeholder="0.00" [ngModel]="(subItem.oriTotalPrice + subItem.itemPriceAdjustment.adjustedValue) | displayDollar"
            [disabled]="visitStatus ==='PAYMENT'" (blur)="createSubItemPriceChangeStream($event, subItem)" (input)="onChangeAdjustedPrice($event)">
        </ng-container>
        <ng-template #priceSpan>
          <span>{{(subItem.oriTotalPrice + subItem.itemPriceAdjustment.adjustedValue) | displayDollar }}</span>
        </ng-template>
      </ng-template>
    </div>

    <!-- Date -->
    <div [ngClass]="inDispensing ? 'col-1' : 'col-2'" class="d-flex flex-row align-items-center">
      <span>{{subItem.utilizedDate}}</span>
    </div>

    <!-- Batch No -->
    <div *ngIf="inDispensing" class="col-2">
      <span *ngIf="subItem.inventoried && subItem.utilize">
        <app-package-sub-item-batch-no [packageId]="packageItem.packageId" [subItem]="subItem"
          [disabled]="isItemDisabled(subItem)"></app-package-sub-item-batch-no>
      </span>
    </div>

    <!-- Expiry Date -->
    <div *ngIf="inDispensing" class="col-2">

    


      <span *ngIf="subItem.inventoried && subItem.utilize">
        {{subItem.expiryDate}}
        <!-- <app-package-sub-item-expiry [packageId]="packageItem.packageId" [subItem]="subItem"
          [disabled]="isItemDisabled(subItem)">
        </app-package-sub-item-expiry> -->
      </span>
    </div>

    <!-- CheckBox  -->
    <!-- <div class="col-2 d-flex justify-content-center"> -->
    <div class="col-1 pl-4">
      <input type="checkbox" [attr.checked]="subItem.utilize ? 'checked' : null" [disabled]="isItemDisabled(subItem)"
        (click)="checkboxClick($event, subItem)">
    </div>

    <!-- <div class="col-2" *ngIf="inDispensing">
    </div> -->

    <!-- Edit Vaccine details -->
    <div *ngIf="isVaccine(subItem) && subItem.utilize" class="col-2">
      <button class="btn btn-brand-secondary p-1 button-width" [style.border]="!checkValidity(i) ? '1px solid red' : 'none'" 
        (click)="showEditVaccineDetails(subItem, i)" [disabled]="isItemDisabled(subItem)">
        Update
        <img src="/assets/svg/syringe-solid.svg" class="syringe-icon ml-1" />
      </button>
      <span *ngIf="!checkValidity(i)" class="compulsory large-star">*</span>
    </div>
  </div>
</ng-container>