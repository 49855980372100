import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-patient-history-detail-add-document',
  templateUrl: './patient-history-detail-add-document.component.html',
  styleUrls: ['./patient-history-detail-add-document.component.scss'],
})
export class PatientHistoryDetailAddDocumentComponent implements OnInit, OnDestroy {
  @Input() uploader: FileUploader;
  @Input() formGroup: FormGroup;
  title: string;
  queueLength = 0;
  totalSize = 0;
  names = [];
  labItemArray = []
  documentTypes = []
  isDescriptionFilled = false;
  constructor(private fb: FormBuilder, public bsModalRef: BsModalRef) {}

  ngOnInit() {
    const items = this.formGroup.get('itemArray') as FormArray;
    const itemsArray = !!items  ? items.value : (this.labItemArray.length > 0 ? this.labItemArray : [])
    this.names = itemsArray
      .filter(item => item.itemType === 'LABORATORY')
      .map(item => item.name)
      .map(name => {
        return {
          value: name,
          label: name,
        };
      });
    this.names.push({ value: 'OTHER', label: 'Other' });
    this.refreshNewDocumentsArray();

    this.formGroup.get(
      'newDocumentsArray'
    ).valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.isDescriptionFilled = value.every(doc => !!doc.description.trim())
    })
  }

  ngOnDestroy() {}

  fileUpload(event) {
    this.updateNewDocumentsArray();
  }

  refreshNewDocumentsArray() {
    const newDocumentsArray = this.formGroup.get(
      'newDocumentsArray'
    ) as FormArray;
    while (newDocumentsArray.length) {
      newDocumentsArray.removeAt(0);
    }

    this.queueLength = this.uploader.queue.length;
    this.totalSize = 0;

    this.uploader.queue.forEach(document => {
      const doc: any = document; //cast and avoid error on doc.description
      newDocumentsArray.push(
        this.fb.group({
          names: { value: this.names },
          name: [this.names.length === 1 ? 'OTHER' : '', Validators.required],
          document: doc.file.name,
          description: doc.description || '',
          type: doc.file.type,
          size: doc.file.size,
          listType: [this.documentTypes[0]],
          fileId: doc.fileId || '',
        })
      );
      this.totalSize += document.file.size;
    });
  }

  updateNewDocumentsArray() {
    const newDocumentsArray = this.formGroup.get(
      'newDocumentsArray'
    ) as FormArray;
    for (let i = this.queueLength; i < this.uploader.queue.length; i++) {
      const document: any = this.uploader.queue[i]; //cast to `any` and avoid error on document.description
      newDocumentsArray.push(
        this.fb.group({
          names: { value: this.names },
          name: [this.names.length === 1 ? 'OTHER' : '', Validators.required],
          document: document.file.name,
          description: document.description || '',
          type: document.file.type,
          size: document.file.size,
          listType: [this.documentTypes[0]],
          fileId: document.fileId || '',
        })
      );
      this.totalSize += document.file.size;
    }

    this.queueLength = this.uploader.queue.length;
  }

  onDelete(form: FormGroup, index: number) {
    const document = this.uploader.queue[index];

    this.uploader.removeFromQueue(document);
    this.queueLength = this.uploader.queue.length;
    this.totalSize -= document.file.size;

    form.patchValue({
      isDelete: true,
      deleteIndex: index,
    });

    const formArray = form.parent as FormArray;
    formArray.removeAt(index);
  }

  onSave() {
    this.uploader.uploadAll();
    this.bsModalRef.hide();
  }
}
