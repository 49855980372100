import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import { ApiCmsManagementService } from '../../../../services/api-cms-management.service';
import { PatientService } from '../../../../services/patient.service';
import { ApiPatientInfoService } from '../../../../services/api-patient-info.service';

import DatePickerConfig from '../../../../objects/DatePickerConfig';

import { BsModalRef } from 'ngx-bootstrap';
import { ConsultationFormService } from '../../../../services/consultation-form.service';
import { Instruction } from '../../../../objects/DrugItem';
import { AlertService } from '../../../../services/alert.service';
import { DoctorStore } from '../../../../state/doctor/doctor.store';
import { AkitaClinicDoctorQuery } from '../../../../services/states/akita-clinic-doctor.query';
import { StoreService } from '../../../../services/store.service';
import { VisitManagementService } from '../../../../services/visit-management.service';
import { AuthService } from '../../../../services/auth.service';
import * as moment from 'moment'
import { DB_FULL_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../../constants/app.constants';
import { ApiAppointmentsService } from '../../../../services/api-appointments.service';
import { AkitaCaseVisitStore } from '../../../../services/states/akita-case-visit.store';
import { AkitaCaseVisitQuery } from '../../../../services/states/akita-case-visit.query';

@Component({
  selector: 'app-vaccine-details-modal',
  templateUrl: './vaccine-details-modal.component.html',
  styleUrls: ['./vaccine-details-modal.component.scss'],
})
export class VaccineDetailsModalComponent implements OnInit, OnDestroy {
  public prescriptionItem: FormGroup;
  public title: string;
  public vaccineName: string;
  public dosageInstructions: Array<any>;
  public instructions: Array<Instruction>;
  public dosageMin = 1;
  public isPackageItem: boolean = false;
  public isDoctorConsult: boolean = false;
  public isDoctor: boolean = true;
  public itemIndex: number;
  dosageUom: string;

  vaccinations: Array<any> = [];
  nextDoseVaccine = [];
  doctors: any[];
  routeOfAdministrations: any[] = [];
  bodySites: any[];
  vaccineSubItems: AbstractControl[];
  administratorTypes: any[] = [
    {name: 'Dentist', value: 'DENTIST'},
    {name: 'Doctor', value: 'DOCTOR'},
    {name: 'Pharmacist', value: 'PHARMACIST'},
    {name: 'Nurse', value: 'NURSE'},
  ];
  isBodySiteRequired: boolean = false;

  datePickerConfig: DatePickerConfig;
  nextDoseDateDatePickerConfig: DatePickerConfig;
  selectedAdminTypeCode: string;
  vaccineId: AbstractControl;
  doseId: AbstractControl;
  scheduledDate: AbstractControl;
  scheduledTime: AbstractControl;

  availableTimesDropDownList: Array<string>;
  availableTimesDropDownListForAdd: Array<string>;

  private componentDestroyed: Subject<void> = new Subject();

  constructor(
    public bsModalRef: BsModalRef,
    private apiCmsManagementService: ApiCmsManagementService,
    private visitManagementService: VisitManagementService,
    private apiAppointmentsService: ApiAppointmentsService,
    private akitaCaseVisitQuery: AkitaCaseVisitQuery,
    private alertService: AlertService,
    private store: StoreService,
  ) {
    this.datePickerConfig = new DatePickerConfig(
      null,
      new Date(),
      null,
      'bottom',
      'none'
    );
    this.nextDoseDateDatePickerConfig = new DatePickerConfig(
      null,
      null,
      new Date(),
      'top',
      'top'
    );

    this.vaccinations = this.store.vaccinationList;
  }

  ngOnInit() {
    this.getRouteOfAdministrations();
    this.getBodySites();
    this.initialiseValues();
    this.subscribeFormGroupChange();
    this.updateValidations();
  }

  initialiseValues() {
    const vaccinationSchedulesFA = this.prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules') as FormArray;
    
    this.vaccineId = vaccinationSchedulesFA.at(0).get('vaccineId');
    this.doseId = vaccinationSchedulesFA.at(0).get('doseId');
    this.scheduledDate = vaccinationSchedulesFA.at(0).get('scheduledDate');
    this.scheduledTime = vaccinationSchedulesFA.at(0).get('scheduledTime');

    if (!this.scheduledDate) {
      let scheduleGroup = vaccinationSchedulesFA.at(0) as FormGroup;
      scheduleGroup.addControl('scheduledDate', new FormControl(''));
    }

    const subItemFA = this.prescriptionItem.get('vaccinationInfo').get('multiVaccineSubItems') as FormArray;
    const code = this.prescriptionItem.get('dosageInstruction').get('code');
    this.vaccineSubItems = subItemFA.controls;

    if (code.value && this.vaccineSubItems && this.vaccineSubItems.length > 0) {
      this.vaccineSubItems.forEach(vaccine => {
        if (vaccine.get('dosages') && !vaccine.get('doseId').value) {
          let sameDose = vaccine.get('dosages').value.find(dose => dose.doseId === code.value);
          if (sameDose) {
            vaccine.get('doseId').patchValue(code.value);
          } else {
            vaccine.get('doseId').patchValue('');
          }
        }
        if (this.isDoctor || this.isDoctorConsult) {
          vaccine.get('doseId').setValidators(null);
        } else {
          vaccine.get('doseId').setValidators([Validators.required]);
        }
      })
    }

    if (this.vaccineId && this.vaccineId.value) {
      const vaccine = this.vaccinations.find(item => item.id === this.vaccineId.value);
      if (vaccine) {
        this.nextDoseVaccine = [...vaccine.dosages];
      }

      if(this.scheduledDate.value){
        let d = moment(this.scheduledDate.value, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
        this.getAvailableTimesByClinicForAdd(d);
      }
    }

    const administrationInfo = this.prescriptionItem.get('vaccinationInfo').get('administrationInfo');
    const administrator = this.prescriptionItem.get('vaccinationInfo').get('administrator');
    const visit = this.akitaCaseVisitQuery.getActive();
    let doctorId;

    if (visit.medicalReferenceEntity && visit.medicalReferenceEntity.consultation) {
      doctorId = visit.medicalReferenceEntity.consultation.doctorId;
    } else {
      doctorId = visit.preferredDoctorId;
    }

    this.doctors = this.store.doctorList.filter(doc => doc.id === doctorId);
    if (!administrator.get('regNo').value) {
      const loggedInDoctor = this.doctors.find(doc => doc.id === this.store.getUser().context['cms-user-id']);

      administrator.get('profession').patchValue('DOCTOR');
      administrator.get('regNo').patchValue((this.doctors.length > 0 && !loggedInDoctor) ? this.doctors[0].mcr : loggedInDoctor.mcr);
      administrator.get('name').patchValue((this.doctors.length > 0 && !loggedInDoctor) ? this.doctors[0].displayName : loggedInDoctor.displayName);
      administrator.get('regNo').disable();
      this.selectedAdminTypeCode = 'DOCTOR';
    } else {
      if (administrator.get('profession').value) {
        this.selectedAdminTypeCode = administrator.get('profession').value;

        if (this.selectedAdminTypeCode !== 'DOCTOR') {
          administrator.get('name').setValidators([Validators.required]);
          administrator.get('regNo').enable();
        } else {
          administrator.get('regNo').disable();
        }
      }
    }

    administrator.get('name').updateValueAndValidity({ emitEvent: true });
    
    const itemId = this.prescriptionItem.get('drugId') || this.prescriptionItem.get('itemId');
    
    if (itemId.value) {
      const vaccine = this.vaccinations.find(v => v.id === itemId.value);
      if (vaccine) {
        this.dosageUom = vaccine.dosageUom;

        if (vaccine.routeOfAdministration && !administrationInfo.get('route').value) {
          administrationInfo.get('route').patchValue(vaccine.routeOfAdministration);
        }
      }
    }

    if (administrationInfo.get('route').value && this.routeOfAdministrations.length > 0) {
      this.onRouteOfAdministrationSelect(this.routeOfAdministrations.find(roa => roa.name === administrationInfo.get('route').value));
    }
  }

  subscribeFormGroupChange() {
    const vaccinationSchedulesFA = this.prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules') as FormArray;
    vaccinationSchedulesFA.at(0).get('vaccineId')
      .valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(vaccineId => {
        const vaccine = this.vaccinations.find(item => item.id === vaccineId);
        if (vaccine) {
          this.clearFields();
          vaccinationSchedulesFA.at(0).get('doseId').markAsTouched();
        }
      });

    this.vaccineId.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)), takeUntil(this.componentDestroyed))
      .subscribe(vaccineId => {
        this.doseId.patchValue(undefined);

        const vaccine = this.vaccinations.find(item => item.id === vaccineId);
        if (vaccine) {
          this.nextDoseVaccine = [...vaccine.dosages];
        }

        if (vaccineId) {
          this.doseId.setValidators([Validators.required]);
          this.doseId.markAsTouched();
          this.scheduledDate.setValidators([Validators.required]);
          this.scheduledDate.markAsTouched();
          this.scheduledTime.setValidators([Validators.required]);
          this.scheduledTime.markAsTouched();
        } else {
          this.doseId.setValidators(null);
          this.scheduledDate.setValidators(null);
          this.scheduledTime.setValidators(null);
        }

        this.doseId.updateValueAndValidity({ emitEvent: false });
        this.scheduledDate.updateValueAndValidity({ emitEvent: false });
        this.scheduledTime.updateValueAndValidity({ emitEvent: false });
      });

      vaccinationSchedulesFA.at(0).get('scheduledDate').valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(date => {
        if(date){
          let d = moment(date, DISPLAY_DATE_FORMAT).format(DISPLAY_DATE_FORMAT);
          this.getAvailableTimesByClinicForAdd(d);
        }
      });
  }

  updateValidations() {
    const administrationInfo = this.prescriptionItem.get('vaccinationInfo').get('administrationInfo');
    const multiVaccineSubItems = this.prescriptionItem.get('vaccinationInfo').get('multiVaccineSubItems') as FormArray;

    if (!this.isDoctor) {
      this.prescriptionItem.get('dosageInstruction').get('code').setValidators([Validators.required]);

      if (multiVaccineSubItems.length > 0) {
        for (const item of multiVaccineSubItems.controls) {
          item.get('doseId').setValidators([Validators.required]);
          item.get('doseId').updateValueAndValidity({ emitEvent: true });
        }
      }
    } else {
      this.prescriptionItem.get('dosageInstruction').get('code').setValidators(null);

      if (multiVaccineSubItems.length > 0) {
        for (const item of multiVaccineSubItems.controls) {
          item.get('doseId').setValidators(null);
          item.get('doseId').updateValueAndValidity({ emitEvent: true });
        }
      }
    }

    this.updateBodySiteValidation();
    this.prescriptionItem.get('dosageInstruction').get('code').updateValueAndValidity({ emitEvent: true });
    this.prescriptionItem.get('vaccinationInfo').get('givenDate').updateValueAndValidity({ emitEvent: true });
    this.prescriptionItem.get('vaccinationInfo').get('givenDate').setValidators([Validators.required]);
  }

  getAvailableTimesByClinicForAdd(dateForApi) {
    this.apiAppointmentsService
      .listAvailableTimeByClinic(
        this.store.getClinicId(),
        dateForApi
      )
      .subscribe(
        res => {
          this.availableTimesDropDownListForAdd = new Array<string>();
          res.payload.forEach(slot => {
            this.availableTimesDropDownListForAdd.push(slot.start);
          });
          const vaccinationSchedulesFA = this.prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules') as FormArray;
          let currentSetTime = vaccinationSchedulesFA.at(0).get('scheduledTime').value;
          if(!this.availableTimesDropDownListForAdd.some(item=> item === currentSetTime)){
            vaccinationSchedulesFA.at(0).get('scheduledTime').patchValue('');
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  clearFields() {
    const vaccinationSchedulesFA = this.prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules') as FormArray;
    vaccinationSchedulesFA.at(0).get('doseId').patchValue(undefined);
    //this.vaccineId.patchValue(undefined);
    this.doseId.patchValue(undefined);
    this.scheduledDate.patchValue(undefined);
    this.scheduledTime.patchValue(undefined);
  }

  getRouteOfAdministrations() {
    const routes = this.store.routeOfAdministrators;
    this.routeOfAdministrations = routes.filter(route => route.nehrCodeVaccine);
  }

  getBodySites() {
    this.apiCmsManagementService
      .listSystemStoreValuesByKey('VACCINE_BODY_SITE_VALUES')
      .subscribe(
        res => {
          const sites = res.payload;

          if (sites.values) {
            this.bodySites = sites.values.map(site => {
              return {name: site}
            });
          }
        },
        err => {
          this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  onDosageInstructionSelect(option) {
    if (option) {
      if (this.vaccineSubItems && this.vaccineSubItems.length > 0) {
        this.vaccineSubItems.forEach(vaccine => {
          if (vaccine.get('dosages')) {
            let sameDose = vaccine.get('dosages').value.find(dose => dose.doseId === option.doseId);
            if (sameDose) {
              vaccine.get('doseId').patchValue(option.doseId);
            } else {
              vaccine.get('doseId').patchValue('');
            }
          }
        })
      }

      this.prescriptionItem.get('vaccinationInfo').get('vaccineDosage').patchValue(option.vaccineDosage);

      // this variable to store the original instruction and to be used in case replacement is needed
      this.prescriptionItem.get('dosageInstruction').get('instruct').patchValue(option.description);
    }
  }

  onAdministratorTypeSelect(option) {
    if (option) {
      this.selectedAdminTypeCode = option.value;
      const administrator = this.prescriptionItem.get('vaccinationInfo').get('administrator');

      administrator.get('regNo').patchValue('');
      administrator.get('name').patchValue('');

      if (this.selectedAdminTypeCode !== 'DOCTOR') {
        administrator.get('name').setValidators([Validators.required]);
        administrator.get('regNo').enable();
      } else {
        administrator.get('name').setValidators(null);
        administrator.get('regNo').patchValue(this.doctors.length ? this.doctors[0].mcr : '');
        administrator.get('name').patchValue(this.doctors.length ? this.doctors[0].displayName : '');
        administrator.get('regNo').disable();
      }

      administrator.get('name').updateValueAndValidity({ emitEvent: true });
    }
  }

  onRouteOfAdministrationSelect(option) {
    if (option) {
      this.updateBodySiteValidation(option);
    }
  }

  updateBodySiteValidation(option?) {
    const adminInfoForm = this.prescriptionItem.get('vaccinationInfo').get('administrationInfo');
    const bodySite = adminInfoForm.get('site');
    if (option || (adminInfoForm.get('route') && adminInfoForm.get('route').value)) {
      const routrOfAdminObj = option ? option : this.routeOfAdministrations.find(routeOfAdmin => routeOfAdmin.name === adminInfoForm.get('route').value);
      if (routrOfAdminObj && routrOfAdminObj.bodySiteRequired && (!this.isDoctor || !this.isDoctorConsult)) {
        bodySite.setValidators([Validators.required]);
        this.isBodySiteRequired = true;
      } else {
        bodySite.setValidators(null);
        this.isBodySiteRequired = false;
      }
    } else {
      bodySite.setValidators(null);
      this.isBodySiteRequired = false;
    }
    
    bodySite.updateValueAndValidity({ emitEvent: true });
  }

  searchFn(term: string, option) {
    term = term.toLocaleLowerCase();
    term = term.trim();
    return (
      option.doseId.toLocaleLowerCase().indexOf(term) > -1 ||
      option.name.toLocaleLowerCase().indexOf(term) > -1
    );
  }

  onBtnExit() {
    this.updateDates();
    this.bsModalRef.hide();
  }

  onBtnSave() {
    this.updateDates();
    if (this.isPackageItem) {
      this.visitManagementService.setUpdateItemDetails([this.itemIndex, this.prescriptionItem]);
    }
    this.bsModalRef.hide();
  }

  updateDates() {
    if (this.scheduledDate && this.scheduledDate.value && typeof this.scheduledDate.value !== 'string') {
      const vaccineSchedules = this.prescriptionItem.get('vaccinationInfo').get('vaccinationSchedules') as FormArray;
      vaccineSchedules.at(0).get('scheduledDate').patchValue(moment(this.scheduledDate.value).format(DISPLAY_DATE_FORMAT));
    }
    if (this.prescriptionItem.get('vaccinationInfo').get('givenDate') && this.prescriptionItem.get('vaccinationInfo').get('givenDate').value
      && typeof this.prescriptionItem.get('vaccinationInfo').get('givenDate').value !== 'string') {
      const givenDate = this.prescriptionItem.get('vaccinationInfo').get('givenDate').value;
      this.prescriptionItem.get('vaccinationInfo').get('givenDate').patchValue(moment(givenDate).format(DISPLAY_DATE_FORMAT));
    }
  }

  searchRouteFn(term: string, option) {
    term = term.toLocaleLowerCase();
    const hasWhiteSpace = term.indexOf(' ') > -1 ? true : false;

    if (!hasWhiteSpace) {
      if (term.length < 4) {
        return option.name.toLocaleLowerCase().indexOf(term) > -1;
      }
    }

    term = term.trim();
    return option.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  isPackagedPrescriptionItem(): boolean {
    return (
      this.prescriptionItem.get('packageId').value !== '' &&
      this.prescriptionItem.get('itemType').value === 'PACKAGE'
    );
  }

  getSalesUom() {
    const salesUom =
      this.prescriptionItem.get('salesUom') &&
      !!this.prescriptionItem.get('salesUom').value
        ? this.prescriptionItem.get('salesUom').value
        : '';
    return salesUom;
  }

  onInstructionSelect(option) {
    this.prescriptionItem.get('instruction').patchValue(option);
  }

  markAsTouched(form: AbstractControl) {
    form.markAsTouched();
    form.updateValueAndValidity({ emitEvent: false });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }
}