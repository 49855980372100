import { PatientCoverage } from '../../../../objects/MedicalCoverage';
import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'patientMedicalCoverageCorp' })
export class PatientMedicalCoverageCorpStore extends EntityStore<PatientCoverage> {
  constructor() {
    super();
  }

  addNewData(item) {
    this.add(item);
  }
}
