import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  delay,
  retryWhen,
  take,
  concatMap,
  tap,
  catchError,
  takeUntil,
} from 'rxjs/operators';
import { Subject, iif, throwError, of } from 'rxjs';

import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import {
  AKITA_HTTP_RETRY_MAX,
  AKITA_HTTP_RETRY_DELAY,
} from '../constants/akita.config';

export interface PendingRequest {
  url: string;
  body: any;
  options: any;
  subscription: Subject<any>;
}

export function createPendingRequest(url, body, options, subscription) {
  return {
    url: url,
    body: body,
    options: options,
    subscription: subscription,
  } as PendingRequest;
}

@Injectable({ providedIn: 'root' })
export class BackendService {
  private request$ = new Subject<any>();
  private queue: Array<PendingRequest> = new Array<PendingRequest>();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) {
    this.request$.subscribe(request => this.execute(request));
  }

  invoke(url, body, options) {
    return this.addRequestToQueue(url, body, options);
  }

  private addRequestToQueue(url, body, options) {
    const sub = new Subject<any>();
    const request = createPendingRequest(url, body, options, sub);

    this.queue.push(request);
    if (this.queue.length > 0) this.startNextRequest();
    return sub;
  }

  private execute(requestData: PendingRequest) {
    this.httpClient
      .post<HttpResponseBody>(
        requestData.url,
        requestData.body,
        requestData.options
      )
      .pipe(
        retryWhen(error =>
          error.pipe(
            concatMap((e, i) =>
              iif(
                () => i >= AKITA_HTTP_RETRY_MAX,
                throwError(e),
                of(e).pipe(delay(AKITA_HTTP_RETRY_DELAY))
              )
            )
          )
        )
      )
      .subscribe(
        data => {
          const sub = requestData.subscription;
          sub.next(data);
          // this.queue.shift();
          this.startNextRequest();
        },
        err => {
          const sub = requestData.subscription;
          sub.next(<HttpResponseBody>{});
          // this.queue.shift();
          this.startNextRequest();
        }
      );
  }

  private startNextRequest() {
    if (this.queue.length > 0) {
      this.execute(this.queue[0]);
      this.queue.shift();
    }
  }
}
