<ng-container *ngIf="!hasError">
    <router-outlet>
    </router-outlet>
</ng-container>
<!-- RETRY  -->
<div>
    <app-loading-retry [hidden]='!hasError' (resetApiCount)="resetCount()"></app-loading-retry>
</div>

<app-loading [hidden]="!(isLoading | async) || hasError"></app-loading>
