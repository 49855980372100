<div class="container" [hidden]='balanceStatus.status==="HIDDEN"'>
    <div *ngIf='balanceStatus.status==="LOADING"'>
        Checking for balance...
    </div>
    <div *ngIf='balanceStatus.status==="SUCCESS"' class="balance-positive">
        <ng-container *ngIf="balanceStatus.value === '0'; else aboveZeroTemplate"> No Cap</ng-container>
        <ng-template #aboveZeroTemplate>
            {{balanceStatus.value|currency:'SGD':'symbol-narrow'}}
        </ng-template>
    </div>
    <div *ngIf='balanceStatus.status==="CHECK"' class="balance-unknown">
        Check balance on coverage portal.
    </div>
    <div *ngIf='balanceStatus.status==="FAIL"' class="balance-negative">
        <span>{{getErrorMessage()}}</span>
    </div>
</div>