export const LANGUAGES = [
  'Amharic',
  'Akan',
  'Albanian',
  'Armenian',
  'Annamese',
  'Arabic',
  'Assamese',
  'Azerbaijani',
  'Batak',
  'Bulgarian',
  'BANGLA',
  'Bugis',
  'Bengali',
  'Banjarese',
  'Bauan',
  'Bhutanese',
  'Burmese',
  'Boyanese',
  'Croatian',
  'Chekiang',
  'Chaldean',
  'Cambodian',
  'Creole',
  'Cantonese',
  'Chawan',
  'Czech',
  'Danish',
  'Dusun',
  'Dutch',
  'Dayak',
  'English',
  'Farsi',
  'Finnish',
  'Flemish',
  'Foochow',
  'French',
  'Gujarati',
  'Ghanaian',
  'German',
  'Goanese',
  'Greek',
  'Gurkhali',
  'Hunan',
  'Hebrew',
  'Hakka',
  'Hindi',
  'Hubei',
  'Henghua',
  'Hockchia',
  'Hokkien',
  'Hainanese',
  'Hindustani',
  'Hungarian',
  'Iban',
  'Icelandic',
  'Indonesian',
  'Ilongo',
  'Ilocano',
  'Irish',
  'Italian',
  'Javanese',
  'JAFFNESE',
  'Japanese',
  'Kannada',
  'Khasi',
  'Kikuyu',
  'Khmer',
  'Kiangsi',
  'Khek',
  'Kelabit',
  'Kashmiri',
  'Karen',
  'Konkani',
  'Korean',
  'Kwongsai',
  'Kayan',
  'Kadazan',
  'Lao',
  'LITHUANIAN',
  'Luichew',
  'Maltese',
  'Malabari',
  'Myanmar',
  'Mandingo',
  'Minangkabau',
  'Montenegrin',
  'Malagasy',
  'Manchu',
  'Mongolian',
  'Maldivian',
  'Malayalam',
  'Mandarin',
  'Maori',
  'Marathi',
  'Multani',
  'Malay',
  'Melanau',
  'Nepali',
  'Norwegian',
  'Nepalese',
  'Newari',
  'Oriya',
  'Persian',
  'Filipino',
  'Pekinese',
  'Punjabi',
  'Polish',
  'Pathan',
  'Portuguese',
  'Pampangan',
  'Pushtu',
  'Rakhine',
  'Romanian',
  'Russian',
  'Shan',
  'Scottish',
  'Szechuan',
  'Sindhi',
  'Serbian',
  'Swiss German',
  'Shanghainese',
  'Sinhalese',
  'Slovak',
  'Shansi',
  'Spanish',
  'Sundanese',
  'Shantung',
  'Slavic',
  'Swedish',
  'Tagalog',
  'Teochew',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tamil',
  'Tongan',
  'Turkish',
  'Taiwanese',
  'Unknown',
  'Urdu',
  'Vietnamese',
  'Visayan',
  'Wenchow',
  'Welsh',
  'Others',
  'Yiddish',
];
