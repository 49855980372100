import { Pipe, PipeTransform } from '@angular/core';

interface Address {
  "address": string;
  "country": string;
  "postalCode": string
}

@Pipe({
  name: 'displayAddress'
})
export class DisplayAddressPipe implements PipeTransform {

  transform(value: Address, separator: string = '\n'): unknown {
    return [value.address, value.country, value.postalCode]
      .join('\n')
      .split('\n')
      .filter(a => a).join(separator);
  }

}
