import { Injectable } from '@angular/core';

import Konva from 'konva';

@Injectable({
  providedIn: 'root',
})
export class KonvaUtilService {
  static readonly DEFAULT_TEXT = 'Type Text Here';

  constructor() {}

  konvaLine(options) {
    const line = new Konva.Line({
      stroke: `#${options.stroke}`,
      strokeWidth: options.mode === 'brush' ? 2 : 15,
      globalCompositeOperation:
        options.mode === 'brush' ? 'source-over' : 'destination-out',
      points: [options.x, options.y],
      draggable: false,
      listening: false,
    });
    return line;
  }

  konvaText(stage: Konva.Stage, layer: Konva.Layer, context, options?) {
    layer
      .getChildren(node => node.getClassName() === 'Transformer')
      .toArray()
      .forEach((node: Konva.Transformer) => node.detach());

    let text: Konva.Text;
    if (options instanceof Konva.Text) {
      text = options;
    } else {
      const { offsetHeight, scrollTop } = stage.container();

      text = new Konva.Text({
        text: KonvaUtilService.DEFAULT_TEXT,
        x: stage.getPosition().x + 100,
        y: stage.getPosition().y + scrollTop + 100,
        fill: `#${options.fill}`,
        fontSize: options.fontSize,
        width: options.fontSize * 10,
        draggable: true,
      });

      layer.add(text);
    }

    const transformer = new Konva.Transformer({
      node: text as any,
      enabledAnchors: ['middle-left', 'middle-right'],
      boundBoxFunc: (oldBox, newBox) => {
        newBox.width = Math.max(30, newBox.width);
        return newBox;
      },
    });

    let deleteEventHandler;
    stage.on('click', function(e) {
      if (!this.clickStartShape) return;

      if (e.target._id === this.clickStartShape._id) {
        context.noActionMode();
        layer.add(transformer);
        transformer.attachTo(e.target);
        window.addEventListener(
          'keydown',
          (deleteEventHandler = function(event) {
            if (event.keyCode === 46) {
              e.target.remove();
              context.transformerArray.forEach(node => node.detach());
              const selectedShape = context.shapeArray.find(
                shape => shape._id === e.target._id
              );
              if (selectedShape) {
                selectedShape.remove();
                event.preventDefault();
                layer.batchDraw();
              }
            }
          })
        );
      } else {
        context.setDefaultMode();
        window.removeEventListener('keydown', deleteEventHandler);
        transformer.detach();
      }

      layer.draw();
    });

    text.on('transform', function() {
      text.setAttrs({
        width: text.width() * text.scaleX(),
        scaleX: 1,
      });
    });

    layer.add(transformer);
    layer.draw();

    text.on('dblclick', () => {
      text.hide();
      transformer.hide();
      layer.draw();

      const areaPosition = {
        x:
          stage.container().getBoundingClientRect().left +
          (<any>text).absolutePosition().x,
        y:
          stage.container().getBoundingClientRect().top +
          (<any>text).absolutePosition().y,
      };

      const textArea = document.createElement('textarea');
      document.body.appendChild(textArea);
      textArea.value =
        text.text() === KonvaUtilService.DEFAULT_TEXT ? '' : text.text();
      textArea.style.position = 'absolute';
      textArea.style.zIndex = '1052';
      textArea.style.top = `${areaPosition.y}px`;
      textArea.style.left = `${areaPosition.x}px`;
      textArea.style.width = `${text.width() - text.padding() * 2}px`;
      textArea.style.height = `${text.height() - text.padding() * 2 + 5}px`;
      textArea.style.fontSize = `${text.fontSize()}px`;
      textArea.style.border = 'none';
      textArea.style.padding = '0px';
      textArea.style.margin = '0px';
      textArea.style.overflow = 'hidden';
      textArea.style.background = 'none';
      textArea.style.outline = 'none';
      textArea.style.resize = 'none';
      (<any>textArea).style.lineHeight = text.lineHeight();
      textArea.style.fontFamily = text.fontFamily();
      textArea.style.transformOrigin = 'left top';
      textArea.style.textAlign = text.align();
      textArea.style.color = text.fill();
      let transform = '';
      if (text.rotation()) transform += `rotateZ(${text.rotation()}deg)`;
      let px = 0;
      const isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) px += 2 + Math.round(text.fontSize() / 20);
      transform += `translateY(-${px}px)`;
      textArea.style.transform = transform;
      textArea.style.height = 'auto';
      textArea.style.height = `${textArea.scrollHeight + 3}px`;
      textArea.focus();

      function removeTextArea() {
        textArea.parentNode.removeChild(textArea);
        window.removeEventListener('click', handleOutsideClick);
        text.show();
        transformer.show();
        transformer.forceUpdate();
        layer.draw();
      }

      function setTextAreaWidth(newWidth) {
        if (!newWidth)
          newWidth = (text as any).placeholder.length + text.fontSize();

        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari || isFirefox) newWidth = Math.ceil(newWidth);
        const isEdge =
          (document as any).documentMode || /Edge/.test(navigator.userAgent);
        if (isEdge) newWidth += 1;

        textArea.style.width = `${newWidth}px`;
      }

      textArea.addEventListener('keydown', function(e) {
        if ((e.keyCode === 13 && !e.shiftKey) || e.keyCode === 27) {
          text.text(
            textArea.value.length === 0
              ? KonvaUtilService.DEFAULT_TEXT
              : textArea.value
          );
          removeTextArea();
        }
      });

      textArea.addEventListener('keydown', function(e) {
        const scale = text.getAbsoluteScale().x;
        setTextAreaWidth(text.width() * scale);
        textArea.style.height = 'auto';
        textArea.style.height = `${textArea.scrollHeight + text.fontSize()}px`;
      });

      function handleOutsideClick(e) {
        if (e.target !== textArea) {
          text.text(
            textArea.value.length === 0
              ? KonvaUtilService.DEFAULT_TEXT
              : textArea.value
          );
          removeTextArea();
        }
      }

      setTimeout(() => window.addEventListener('click', handleOutsideClick));
    });

    return { node: text, transformer: transformer };
  }

  konvaImage(stage: Konva.Stage, layer: Konva.Layer, context, options?) {
    layer
      .getChildren(node => node.getClassName() === 'Transformer')
      .toArray()
      .forEach((node: Konva.Transformer) => node.detach());

    let transformer, deleteEventHandler;
    if (options instanceof Konva.Image) {
      layer.draw();

      transformer = new Konva.Transformer({
        node: options as any,
        enabledAnchors: [
          'bottom-left',
          'bottom-right',
          'top-left',
          'top-right',
        ],
      });

      layer.add(transformer);
      layer.draw();

      stage.on('click', function(e) {
        if (!this.clickStartShape) return;

        if (e.target._id === this.clickStartShape._id) {
          context.noActionMode();
          layer.add(transformer);
          transformer.attachTo(e.target);
          options.setAttr('draggable', true);
          window.addEventListener(
            'keydown',
            (deleteEventHandler = function(event) {
          
              if (event.keyCode === 46) {
                e.target.remove();
                context.transformerArray.forEach(node => node.detach());
                const selectedShape = context.shapeArray.find(
                  shape => shape._id === e.target._id
                );
                if (selectedShape) {
                  selectedShape.remove();
                  event.preventDefault();
                  layer.batchDraw();
                }
              }
            })
          );
        } else {
          context.setDefaultMode();
          window.removeEventListener('keydown', deleteEventHandler);
          transformer.detach();
          options.setAttr('draggable', false);
        }

        layer.draw();
      });

      return { node: options, transformer: transformer };
    } else {
      const { offsetHeight, scrollTop } = stage.container();

      Konva.Image.fromURL(options, function(f) {
        f.setAttrs({
          source: options,
          x: stage.getPosition().x + Math.round(stage.getSize().width / 2),
          y: stage.getPosition().y + scrollTop + Math.round(offsetHeight / 2),
          position: stage.getPointerPosition(),
          draggable: true,
        });

        layer.add(f);

        transformer = new Konva.Transformer({
          node: f as any,
          enabledAnchors: [
            'bottom-left',
            'bottom-right',
            'top-left',
            'top-right',
          ],
        });

        layer.add(transformer);
        layer.draw();

        stage.on('click', function(e) {
          if (!this.clickStartShape) return;

          if (e.target._id === this.clickStartShape._id) {
            context.noActionMode();
            layer.add(transformer);
            transformer.attachTo(e.target);
            f.setAttr('draggable', true);
            window.addEventListener(
              'keydown',
              (deleteEventHandler = function(event) {
             
                if (event.keyCode === 46) {
                  e.target.remove();
                  context.transformerArray.forEach(node => node.detach());
                  const selectedShape = context.shapeArray.find(
                    shape => shape._id === e.target._id
                  );
                  if (selectedShape) {
                    selectedShape.remove();
                    event.preventDefault();
                    layer.batchDraw();
                  }
                }
              })
            );
          } else {
            context.setDefaultMode();
            window.removeEventListener('keydown', deleteEventHandler);
            transformer.detach();
            f.setAttr('draggable', false);
          }

          layer.draw();
        });

        context.shapeArray.push(f);
        context.transformerArray.push(transformer);
        context.clearHistory();
      });
    }
  }

  konvaRect(stage: Konva.Stage, layer: Konva.Layer) {
    const rect = new Konva.Rect({
      x: stage.x(),
      y: stage.y(),
      width: stage.width(),
      height: stage.height(),
      fill: '#FFFFFF',
      draggable: false,
    });

    layer.add(rect);
    return rect;
  }
}
