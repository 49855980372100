import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from 'rxjs';

const defaultBaseUrl = 'https://rautomation.healthwaymedical.info/rpa-api'

@Injectable({
  providedIn: 'root'
})
export class RpaRequestService {

  private errorSub = new Subject<string>();
  private currentWindow;

  constructor (
    private http: HttpClient
    ) {}

  post(url, data, baseUrl?) {
    return this.http.post(this._getUrl(url, baseUrl), data)
  }

  get(url, baseUrl?) {
    return this.http.get(this._getUrl(url, baseUrl))
  }

  open(url) {
    this.currentWindow = window.open(this._getUrl(url), '_blank')
  }

  _getUrl(url, baseUrl?) {
    return `${baseUrl || defaultBaseUrl}/${url}`
  }

  public close(): void {
    this.currentWindow.close();
  }

  public setError(error: string): void {
    this.errorSub.next(error);
  }

  public getError(): Observable<string> {
    return this.errorSub.asObservable();
  }
}