import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { FullLayoutComponent, SimpleLayoutComponent } from './containers';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { PermissionsGuardService as PermissionsGuard } from './services/permissions-guard.service';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { CanActivateChildrenService } from './services/can-activate-children.service';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'pages',
    component: FullLayoutComponent,
    canActivate: [AuthGuard, PermissionsGuard],
    canActivateChild: [CanActivateChildrenService],
    data: { title: 'Home' }, // children: [
    //   {
    //     path: 'profile',
    //     component: './views/components/profile/profile.module#ProfileModule'
    //   },
    //   {
    //     path: 'patient',
    //     component:'./views/components/patient/patient.module#PatientModule'
    //   },
    //   {
    //     path: 'consultation',
    //     component: ConsultationModule,
    //     runGuardsAndResolvers: 'always'
    //   },
    //   {
    //     path: 'payment',
    //     component: PaymentModule
    //   },
    //   {
    //     path: 'clinic',
    //     component: ClinicModule
    //   },
    //   {
    //     path: 'report',
    //     component: ReportModule
    //   },
    //   {
    //     path: 'communications',
    //     component: CommunicationsModule
    //   }
    // ]
    children: [
      {
        path: 'profile',
        loadChildren: () =>
          import('./views/components/profile/profile.module').then(
            m => m.ProfileModule
          ),
      },
      {
        path: 'patient',

        loadChildren: () =>
          import('./views/components/patient/patient.module').then(
            m => m.PatientModule
          ),
      },
      {
        path: 'dashboard',

        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            m => m.DashboardModule
          ),
          canActivate: [NgxPermissionsGuard],
          data: {
            permissions: {
              only: 'ROLE_VIEW_CA_DASHBOARD',
            },
          },
      },
      {
        path: 'appointments',
        loadChildren: () =>
          import('./views/components/appointments/appointments.module').then(
            m => m.AppointmentsModule
          ),
      },
      {
        path: 'claim',
        loadChildren: () =>
          import('./views/components/claim/claim.module').then(
            m => m.ClaimModule
          ),
      },
      // {
      //   path: 'consultation',
      //   loadChildren: './views/components/consultation/consultation.module#ConsultationModule',
      //   runGuardsAndResolvers: 'always'
      // },
      {
        path: 'payment',
        loadChildren: () =>
          import('./views/components/payment/payment.module').then(
            m => m.PaymentModule
          ),
      },
      {
        path: 'clinic',
        loadChildren: () =>
          import('./views/components/clinic/clinic.module').then(
            m => m.ClinicModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./views/components/report/report.module').then(
            m => m.ReportModule
          ),
      },
      {
        path: 'communications',
        loadChildren: () =>
          import(
            './views/components/communications/communications.module'
          ).then(m => m.CommunicationsModule),
      },
      {
        path: 'case',
        loadChildren: () =>
          import('./views/components/case-manager/case-manager.module').then(
            m => m.CaseManagerModule
          ),
      },
      // {
      //   path: 'test',
      //   loadChildren: './views/components/test/test.module#TestModule'
      // },
      {
        path: 'communications',
        loadChildren: () =>
          import(
            './views/components/communications/communications.module'
          ).then(m => m.CommunicationsModule),
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('./views/components/inventory/inventory.module').then(
            m => m.InventoryModule
          ),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'ROLE_PR_PO_INVENTORY_NAV',
          },
        },
      },
      {
        path: 'invoice',
        loadChildren: () =>
          import(
            './views/components/invoices/invoice/invoices/invoices.module'
          ).then(m => m.InvoicesModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./views/components/settings/settings.module').then(
            m => m.SettingsModule
          ),
      },
      {
        path: 'cdm',
        loadChildren: () =>
          import('./views/components/pcn/pcn.module').then(m => m.PcnModule),
      },
      {
        path: 'cdlens',
        loadChildren: () =>
          import('./views/components/cdlens/cdlens.module').then(m => m.CdlensModule),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./views/components/nhps/nhps.module').then(m => m.NhpsModule),
      },
      {
        path: 'notes',
        loadChildren: () =>
          import('./views/components/user-notes/user-notes.module').then(
            m => m.UserNotesModule
          ),
      },
      {
        path: 'queries',
        loadChildren: () =>
          import('./views/components/queries/queries.module').then(
            m => m.QueriesModule
          ),
      }
    ],
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    data: { title: 'Pages' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./views/pages/pages.module').then(m => m.PagesModule),
      },
    ],
  },
  { path: '**', redirectTo: '/pages/patient/list' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    useHash: true,
    enableTracing: false,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
