import { ClassField } from '@angular/compiler';

export class DrugQtyBalance {
  id: string;
  batchList: Array<Batch>;
  oriTotalQty: number;
  totalDispenseQty: number;
  batchMandatory: boolean;

  constructor(id?: string, batchList?: Array<Batch>, oriTotalQty?: number) {
    this.id = id || '';
    this.batchList = batchList || new Array<Batch>();
    this.oriTotalQty = oriTotalQty || 0;
    this.totalDispenseQty = 0;

    this.batchMandatory = true;
    if (
      batchList &&
      batchList.length > 0 &&
      batchList[0].batchNo !== '' &&
      batchList[0].expiryDate !== '31-12-0001'
    ) {
      this.batchMandatory = false;
    }

    // this.batchMandatory = batchMandatory === undefined ? true : false;
  }

  isBatchMandatory() {
    return (
      this.batchList &&
      this.batchList.length > 0 &&
      this.batchList[0].batchNo !== '' &&
      this.batchList[0].expiryDate !== '31-12-0001'
    );
  }

  getId() {
    return this.id;
  }

  getBatchList() {
    return this.batchList;
  }

  addBatch(batch: Batch) {
    this.batchList.push(batch);
    this.updateTotalQty();
  }

  deleteBatch(batchNo) {
    const deleteIndex = this.batchList.findIndex(batch => {
      return batch.batchNo === batchNo;
    });
  }

  updateTotalQty() {
    this.oriTotalQty = this.getSumOfOriTotalQtyFromBatches();
    this.totalDispenseQty = this.getSumOfDispenseQtyFromBatches();
  }

  getSumOfOriTotalQtyFromBatches() {
    return this.batchList.reduce(
      (total, batch) => total + batch.oriTotalQty,
      0
    );
  }

  getSumOfDispenseQtyFromBatches() {
    return this.batchList.reduce(
      (total, batch) => total + batch.dispenseQty,
      0
    );
  }

  setBatchDispenseQty(batchNo, purchaseQty, index) {
    if (!this.batchMandatory) {
      this.batchList[0] &&
        this.batchList[0].setDrugListDispenseQty(index, purchaseQty);
    } else {
      const selectedBatch = this.getBatch(batchNo);

      if (selectedBatch) {
        selectedBatch.setDrugListDispenseQty(index, purchaseQty);
      }
    }

    this.updateTotalQty();
  }

  deleteDrugSubscriptionFromBatch(batchNo, index) {
    const selectedBatch = this.getBatch(batchNo);
    if (selectedBatch) {
      selectedBatch.removeFromDrugListDispenseQty(index);
    }

    this.updateTotalQty();
  }

  getRemainingQty() {
    return this.oriTotalQty - this.totalDispenseQty;
  }

  getoriTotalQty() {
    return this.oriTotalQty;
  }

  getBatch(batchNo) {
    return this.batchList.find(batch => {
      return batch.batchNo === batchNo;
    });
  }

  getBatchDropdownList() {
    return this.batchList.map(batch => {
      return {
        batchNo: batch.batchNo,
        expiryDate: batch.expiryDate,
        stockBalance: batch.getOriTotalQty(),
        remainingBalance: batch.getBatchRemainingQty(),
      };
    });
  }

  qtyIsAvailable(qty) {
    return qty > 0;
  }

  inventoryStockIsSufficient() {
    return this.getRemainingQty() >= 0;
  }

  inventoryStackedStockIsSufficient(currBatch: Batch, shortId: string) {
    // let stackedTotalQty = 0;

    // const currentDispenseQty = currBatch.getDispenseQtyByShortId(shortId);

    const stackedDispenseEntriesByDateAsc = this.getUniqueDispenseEntriesByDateAsc();

    let stackedTotal = 0;

    let index = stackedDispenseEntriesByDateAsc.findIndex(
      entry => entry.getShortId() === shortId
    );

    if (index > -1) {
      for (var i = 0; i <= index; i++) {
        stackedTotal += stackedDispenseEntriesByDateAsc[i].getDispenseQty();
      }
    }

    return this.oriTotalQty - stackedTotal >= 0;
    // return this.getRemainingQty() >= 0;
  }

  getUniqueDispenseEntriesByDateDesc() {
    let stackedDispenseEntries: Array<UniqueDispenseEntry> = this.getUniqueDispenseEntriesByDateAsc();
    stackedDispenseEntries.sort(this.sortByLastUpdated);

    return stackedDispenseEntries;
  }

  getUniqueDispenseEntriesByDateAsc() {
    let stackedDispenseEntries: Array<UniqueDispenseEntry> = new Array<
      UniqueDispenseEntry
    >();

    this.batchList.forEach((batch: Batch) => {
      const drugListDispenseQty = batch.getDrugListDispenseQty();
      drugListDispenseQty.forEach(entry => {
        stackedDispenseEntries.push(entry);
      });
    });

    return stackedDispenseEntries;
  }

  sortByLastUpdated(entry1: UniqueDispenseEntry, entry2: UniqueDispenseEntry) {
    if (entry1.getLastUpdated().getTime() > entry2.getLastUpdated().getTime()) {
      return -1;
    }

    if (entry1.getLastUpdated().getTime() < entry2.getLastUpdated().getTime()) {
      return 1;
    }
    return 0;
  }
}

export class Batch {
  batchNo: string;
  expiryDate: string;
  oriTotalQty: number;
  dispenseQty: number;
  drugListDispenseQty: Map<string, UniqueDispenseEntry>;

  constructor(
    batchNo?: string,
    expiryDate?: string,
    qty?: number,
    dispenseQty?: number
  ) {
    this.batchNo = batchNo || '';
    this.expiryDate = expiryDate || '';
    this.oriTotalQty = qty || 0;
    this.dispenseQty = this.dispenseQty || 0;
    this.drugListDispenseQty = new Map<string, UniqueDispenseEntry>();
  }

  getBatchNo() {
    return this.batchNo;
  }

  getExpiryDate() {
    return this.expiryDate;
  }

  getOriTotalQty() {
    return this.oriTotalQty;
  }

  getDispenseQty() {
    return this.dispenseQty;
  }

  getDrugListDispenseQty() {
    return this.drugListDispenseQty;
  }

  getDispenseQtyByShortId(shortId: string) {
    return this.drugListDispenseQty.get(shortId);
  }

  addToOriTotalQty(qty) {
    this.oriTotalQty += qty;
  }

  stockIsEmpty() {
    return this.oriTotalQty <= 0;
  }

  batchStockIsSufficient() {
    return this.getBatchRemainingQty() >= 0;
  }

  getBatchRemainingQty() {
    return this.oriTotalQty - this.dispenseQty;
  }

  displayStockForDropdown() {
    return this.getBatchRemainingQty() >= 0 ? this.getBatchRemainingQty() : 0;
  }

  reduceQty(purchaseQty) {
    this.oriTotalQty = this.oriTotalQty - purchaseQty;
  }

  setBatchTotalDispenseQty() {
    // this.dispenseQty = 0;
    let total = 0;
    this.drugListDispenseQty.forEach(entry => {
      total += entry.getDispenseQty();
    });
    this.dispenseQty = total;
  }

  setDrugListDispenseQty(index, dispenseQty: number) {
    this.drugListDispenseQty.set(
      index,
      new UniqueDispenseEntry(index, dispenseQty)
    );
    this.setBatchTotalDispenseQty();
  }

  removeFromDrugListDispenseQty(index) {
    this.drugListDispenseQty.delete(index);
    this.setBatchTotalDispenseQty();
  }

  getUniqueDispenseEntry(shortid) {
    this.drugListDispenseQty.get(shortid);
  }
}

export class UniqueDispenseEntry {
  shortid: string;
  dispenseQty: number;
  lastUpdated: Date;

  constructor(shortid?: string, dispenseQty?: number, lastUpdated?: Date) {
    this.shortid = shortid || '';
    this.dispenseQty = dispenseQty || 0;
    this.lastUpdated = lastUpdated ? lastUpdated : new Date();
  }

  getShortId() {
    return this.shortid;
  }

  getDispenseQty() {
    return this.dispenseQty;
  }

  getLastUpdated() {
    return this.lastUpdated;
  }
}
