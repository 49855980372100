<div class="modal-body">
    <div class="row pt-4 text-center">
        <div class="col-md-12">
            <h4>
                Visit Completed
            </h4>
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-md-12">
            <div class="btn-toolbar" role="toolbar">
              <div class="col-md-1"></div>
              <div class="col-md-10">
                <div class="btn-group" role="group" dropdown>
                  <button class="btn btn-medium dropdown-toggle btn-cool-grey text-white mr-4" dropdownToggle>
                    EMAIL RECEIPT & NEXT
                  </button>
                  <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
                    <button class="dropdown-item" (click)="onBtnEmail('general')">General</button>
                    <button class="dropdown-item" (click)="onBtnEmail('breakdown')">Breakdown</button>
                    <button class="dropdown-item" (click)="onBtnEmail('categorised')">Categorised</button>
                    <button class="dropdown-item" (click)="onBtnEmail('directInvoice')">Direct Invoice</button>
                  </div>
                </div>
                <div class="btn-group" role="group" dropdown>
                  <button class="btn btn-medium dropdown-toggle btn-cool-grey text-white mr-4" dropdownToggle>
                    PRINT RECEIPT & NEXT
                  </button>
                  <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
                    <button class="dropdown-item" (click)="onBtnPrint('general')">General</button>
                    <button class="dropdown-item" (click)="onBtnPrint('breakdown')">Breakdown</button>
                    <button class="dropdown-item" (click)="onBtnPrint('categorised')">Categorised</button>
                  </div>
                </div>
                <div class="btn-group" role="group">
                  <button class="btn btn-medium btn-brand-secondary" (click)="onBtnNext()">
                    NEXT
                  </button>
                </div>
              </div>
              <div class="col-md-1"></div>
            </div>
        </div>
    </div>
</div>
