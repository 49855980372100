export interface ReferralCalendarDay {
  dayOfWeek: string;
}

export function createReferralCalendarDay(referralCalendarDay) {
  const { dayOfWeek = '' } = referralCalendarDay;
  return {
    dayOfWeek,
  } as ReferralCalendarDay;
}

export interface ReferralWorkingDay {
  calendarDay: ReferralCalendarDay;
  end: string;
  start: string;
  validate: boolean;
}

export function createReferralWorkingDay(referralWorkingDay) {
  const {
    calendarDay = createReferralCalendarDay({}),
    end = '',
    start = '',
    validate = false,
  } = referralWorkingDay;

  return {
    calendarDay,
    end,
    start,
    validate,
  } as ReferralWorkingDay;
}

export interface ReferralDoctorCalendar {
  blockedTime: ReferralWorkingDay[];
  clinicId: string;
  doctorId: string;
  leaves: ReferralWorkingDay[];
  workingDays: ReferralWorkingDay[];
}

export function createReferralDoctorCalendar(referralDoctorCalendar) {
  const {
    blockedTime = new Array<ReferralWorkingDay>(),
    clinicId = '',
    doctorId = '',
    leaves = new Array<ReferralWorkingDay>(),
    workingDays = new Array<ReferralWorkingDay>(),
  } = referralDoctorCalendar;

  return {
    blockedTime,
    clinicId,
    doctorId,
    leaves,
    workingDays,
  } as ReferralDoctorCalendar;
}

export interface ReferralLocation {
  address: string;
  code: string;
  id: string;
  name: string;
  primary?: boolean;
}

export function createReferralLocation(referralLocation) {
  const { address = '', code = '', id = '', name = '', primary = false } = referralLocation;

  return {
    address,
    code,
    id,
    name,
    primary,
  } as ReferralLocation;
}

export interface ReferralDataChild {
  doctorCalendar: ReferralDoctorCalendar;
  id: string;
  location: ReferralLocation[];
  name: string;
  specialities: string[];
  speciality: string;
  type: string;
  panels?: string;
  medicalCoverageIds?: any[]
}

export function createReferralDataChild(referralDataChild) {
  const {
    doctorCalendar = createReferralDoctorCalendar({}),
    id = '',
    location = new Array<ReferralLocation>(),
    name = '',
    specialities = new Array<string>(),
    speciality = '',
    type = '',
    panels = '',
  } = referralDataChild;

  return {
    doctorCalendar,
    id,
    location,
    name,
    specialities,
    speciality,
    type,
    panels,
  } as ReferralDataChild;
}

export interface ReferralData {
  [key: string]: ReferralDataChild[];
}

export function createReferralData(referralData = {}) {
  return referralData as ReferralData;
}

export interface PatientReferral {
  patientReferrals: Array<Referral>;
  referralId: string;
}

export function createPatientReferral(patientReferral) {
  const {
    patientReferrals = new Array<Referral>(),
    referralId = '',
  } = patientReferral;
  return { patientReferrals, referralId } as PatientReferral;
}

export interface Referral {
  appointmentDateTime: string;
  clinicId: string;
  doctorId: string;
  externalReferral: boolean;
  externalReferralDetails: ExternalReferralDetails;
  memo: string;
  practice: string;
  referralType: string;
  internalNotFound: boolean;
}

export function createReferral(referral) {
  const {
    appointmentDateTime = '',
    clinicId = '',
    doctorId = '',
    externalReferral = false,
    externalReferralDetails = createExternalReferralDetails({}),
    memo = '',
    practice = '',
    referralType = '',
    internalNotFound = false,
  } = referral;

  return {
    appointmentDateTime,
    clinicId,
    doctorId,
    externalReferral,
    externalReferralDetails,
    memo,
    practice,
    referralType,
    internalNotFound,
  } as Referral;
}

export interface ExternalReferralDetails {
  address: string;
  doctorName: string;
  phoneNumber: string;
  externalReferralType: string;
}

export function createExternalReferralDetails(externalReferralDetails) {
  const {
    address = '',
    doctorName = '',
    phoneNumber = '',
    externalReferralType = '',
  } = externalReferralDetails;

  return {
    address,
    doctorName,
    phoneNumber,
    externalReferralType,
  } as ExternalReferralDetails;
}
