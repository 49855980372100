import { Pipe, PipeTransform } from '@angular/core';

import { AkitaChargeItemQuery } from '../services/states/akita-charge-item.query';
import { ChargeItem } from '../objects/state/ChargeItem';

@Pipe({ name: 'displayDrugItemCode' })
export class DisplayDrugItemCodePipe implements PipeTransform {
  constructor(private akitaChargeItemQuery: AkitaChargeItemQuery) {}

  transform(value: any, args?: any): any {
    let item = this.akitaChargeItemQuery.getChargeItem(value);
    if (item === undefined || item === null) item = <ChargeItem>{ code: '' };

    return item.code;
  }
}
