import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
const qs = require('querystringify');

import { Page } from '../model/page';
import { Case } from '../objects/Case';
import { HttpResponseBody } from '../objects/response/HttpResponseBody';
import { Invoice } from '../objects/state/SalesOrder';
import { AlertService } from './alert.service';
import { AppConfigService } from './app-config.service';
import { AkitaCaseDetailsStore } from './states/akita-case-details.store';
import {MedicalCoverageItemInfoStore} from "./states/akita-medical-coverage-item-info.store";

@Injectable()
export class ApiCaseManagerService implements OnDestroy {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private API_CASE_INFO_URL;
  private API_PAYMENT_URL;
  private API_CMS_MANAGEMENT_URL;
  private API_INVENTORY_SYSTEM_URL;

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private caseDetailsStore: AkitaCaseDetailsStore,
    private alertService: AlertService,
    private medicalCoverageItemInfoStore: MedicalCoverageItemInfoStore
  ) {
    this.API_CASE_INFO_URL = this.appConfig.getConfig().API_CASE_INFO_URL;
    this.API_PAYMENT_URL = this.appConfig.getConfig().API_PAYMENT_URL;
    this.API_CMS_MANAGEMENT_URL = this.appConfig.getConfig().API_CMS_MANAGEMENT_URL;
    this.API_INVENTORY_SYSTEM_URL = this.appConfig.getConfig().API_INVENTORY_SYSTEM_URL;
  }

  ngOnDestroy() {}

  create(createCase: Case): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/case/create`,
      JSON.stringify(createCase)
    );
  }

  getCaseList(
    clinicID: string,
    caseBody,
    page: Page
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/list/all/${clinicID}/${page.pageNumber}/${page.size}`,
      JSON.stringify(caseBody)
    );
  }

  update(id: string, cases: Case): void {
    this.http
      .post<HttpResponseBody>(
        `${this.API_CASE_INFO_URL}/update/${id}`,
        JSON.stringify(cases)
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        data => {
          if (data.statusCode === 'S0000') {
            alert('Case details has been updated.');
            const { payload } = data;
            this.caseDetailsStore.set([payload]);
          } else alert(data.message);
        },
        err => {
          if (err.error)
            this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  searchCase(caseId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/search/${caseId}`,
      JSON.stringify({})
    );
  }

  getCaseById(caseId: string): void {
    this.http
      .post<HttpResponseBody>(
        `${this.API_CASE_INFO_URL}/search/${caseId}`,
        JSON.stringify({})
      )
      .pipe(
        map(res => res.payload),
        untilDestroyed(this)
      )
      .subscribe(
        data => this.caseDetailsStore.set([data]),
        err => {
          if (err.error)
            this.alertService.error(JSON.stringify(err.error.message));
        }
      );
  }

  retrieveCaseById(caseId: string): Observable<HttpResponseBody> {
    return this.http
      .post<HttpResponseBody>(
        `${this.API_CASE_INFO_URL}/search/${caseId}`,
        JSON.stringify({})
      )
      .pipe(tap(data => {}));
  }

  getCaseDetailsById(caseId: string, visitId = '') {
    let url: string;
    if (visitId === '')
      url = `${this.API_CASE_INFO_URL}/get-case-details/${caseId}`;
    else
      url = `${this.API_CASE_INFO_URL}/get-case-details/${caseId}/${visitId}`;

    return this.http.post<HttpResponseBody>(url, JSON.stringify({}));
  }

  closeCase(caseId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/close/${caseId}`,
      JSON.stringify({})
    );
  }

  createDirectDispensing(caseId: string, visitId: string) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/direct/dispensing/${caseId}/${visitId}`,
      JSON.stringify({})
    );
  }

  recordNewPayment(caseId: string, payment: any): Observable<HttpResponseBody> {
    if (payment && payment.length > 0) {
      payment.map(val => {
        val.amount = Math.round(val.amount);
      });
    }
    return this.http.post<HttpResponseBody>(
      `${this.API_PAYMENT_URL}/payment/direct/${caseId}`,
      JSON.stringify(payment)
    );
  }

  getPaymentTypes(): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PAYMENT_URL}/payment/modes`,
      JSON.stringify({})
    );
  }

  deletePayment(
    caseId: string,
    invoiceId: any,
    reason: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PAYMENT_URL}/invoice/delete/${caseId}`,
      JSON.stringify({
        invoiceIds: invoiceId,
        reason: reason,
      })
    );
  }

  getInvoicesUpdates(caseId: string, item): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/item/prices/${caseId}`,
      JSON.stringify(item)
    );
  }

  getCaseItemPrices(caseId: string, items): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/item/prices/${caseId}`,
      JSON.stringify(items)
    ).pipe(
      tap(res => {
        res.payload?.chargeDetails?.forEach(a => {
          this.medicalCoverageItemInfoStore.upsert(a.itemId, {
            _id: a.itemId,
            restrictPriceUpdate: a.restrictPriceUpdate
          })
        })
      })
    );
  }

  getInvoiceBreakdown(
    caseId: string,
    visitId = '',
    maximum = false
  ): Observable<HttpResponseBody> {
    const url =
      visitId === ''
        ? `${this.API_PAYMENT_URL}/invoice/breakdown/${caseId}`
        : `${this.API_PAYMENT_URL}/invoice/breakdown/${caseId}/${visitId}/${maximum}`;

    return this.http.post<HttpResponseBody>(url, JSON.stringify({}));
  }

  getDynamicInvoiceBreakdown(
    caseId: string,
    items
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PAYMENT_URL}/invoice/breakdown-dynamic/${caseId}`,
      JSON.stringify(items)
    );
  }

  getDynamicInvoiceBreakdownRemote(
    caseId: string,
    items
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_PAYMENT_URL}/invoice/breakdown-dynamic/${caseId}`,
      JSON.stringify(items)
    );
  }

  getAttachedPolicyDetailByCaseId(
    caseId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/policyholder/get-balance/${caseId}`,
      JSON.stringify({})
    );
  }

  updatePayment(
    invoiceId: string,
    billTransactionId: string,
    billingMode: string,
    amount: string,
    externalTransactionId?: string,
    queryParams?: Record<string, string>
  ): Observable<HttpResponseBody> {

    let url = `${this.API_CMS_MANAGEMENT_URL}/charging/payment/update/${invoiceId}/${billTransactionId}/${billingMode}/${amount}`

    if (!isEmpty(queryParams)) {
      const queryString = qs.stringify(queryParams);
      url = `${url}?${queryString}`
    }

    return this.http.post<HttpResponseBody>(
      url,
      JSON.stringify({ externalTransactionId: externalTransactionId || '' })
    );
  }

  // getBalanceByCase(caseId): Observable<HttpResponseBody> {
  //   return this.http.post<HttpResponseBody>(
  //     `${this.API_CMS_MANAGEMENT_URL}/policyholder/get-balance/${caseId}`,
  //     JSON.stringify({})––
  //   );
  // }

  getBalanceByPatientId(
    patientId,
    clinicId,
    plans: any[]
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CMS_MANAGEMENT_URL}/policyholder/get-balance/${patientId}/${clinicId}`,
      JSON.stringify(plans)
    );
  }

  makeCasePayment(caseId, invoices): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/make/payment/${caseId}`,
      JSON.stringify(invoices)
    );
  }

  printManualClaimsBill(
    caseId: string,
    receiptType: string,
    isDraft: boolean,
    templateName: string
  ) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/print/bill/${caseId}/${receiptType}/${isDraft}/${templateName}`,
      JSON.stringify({})
    );
  }

  emailManualClaimsBill(
    caseId: string,
    receiptType: string,
    isDraft: boolean,
    templateName: string,
    email: string
  ) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/send/bill/${caseId}/${receiptType}/${isDraft}/${templateName}/${email}`,
      JSON.stringify({})
    );
  }

  sendBillEmail(
    caseId: string,
    receiptType: string,
    isDraft: boolean,
    templateName: string
  ) {
    return this.http.post<HttpResponseBody>(
      `${this.API_CASE_INFO_URL}/send/bill/${caseId}/${receiptType}/${isDraft}/${templateName}`,
      JSON.stringify({})
    );
  }

  updateInvoice(
    caseId: string,
    invoice: Invoice
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/sales-order/update/invoice/${caseId}/${invoice.invoiceId}`,
      JSON.stringify(invoice)
    );
  }

  submitBillAdjustment(
    caseId: string,
    reqObj: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/sales-order/billadjustment/submit/${caseId}`,
      JSON.stringify(reqObj)
    );
  }

  getBillAdjustment(
    caseId: string,
    reqObj: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/sales-order/billadjustment/get/${caseId}`,
      JSON.stringify(reqObj)
    );
  }

  getAllBillAdjustments(
    page: number,
    size: number,
    reqObj: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/sales-order/billadjustment/list/${page}/${size}`,
      JSON.stringify(reqObj)
    );
  }

  createRefund(
    caseId: string,
    refundType: string,
    request
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/sales-order/create/refund/${caseId}/${refundType}`,
      request,
    );
  }

  getRefunds(
    page: number,
    size: number,
    request: any
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/sales-order/get/all/refunds/${page}/${size}`,
      JSON.stringify(request)
      // { headers: this.headers }
    );
  }

  getRefundByCaseId(caseId: string): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/sales-order/get/refunds/${caseId}`,
      JSON.stringify({})
      // { headers: this.headers }
    );
  }

  downloadRefundDocuments(
    caseId: string,
    fileId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/sales-order/download/refundFile/${caseId}/${fileId}`,
      JSON.stringify({}),
      { headers: this.headers, responseType: 'blob' as 'json' }
    );
  }

  downloadRefundFiles(
    refundId: string,
    fileId: string
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/document-management/download/refund/${refundId}/${fileId}`,
      JSON.stringify({}),
      { headers: this.headers, responseType: 'blob' as 'json' }
    );
  }

  uploadRefundDocuments(
    caseId: string,
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/document-management/upload/refund/${caseId}`,
      JSON.stringify({}),
      { headers: this.headers, responseType: 'blob' as 'json' }
    );
  }

  public printRefundChit(
    refundId: string,
    templateName: string,
  ): Observable<HttpResponseBody> {
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/label/print/refund-chit/${refundId}/${templateName}`,
      JSON.stringify({}),
    );
  }

  public getRemoteDeliveryItemDetails(deliveryLocationId: string): Observable<HttpResponseBody>{
    return this.http.post<HttpResponseBody>(
      `${this.API_INVENTORY_SYSTEM_URL}/item/list-remote-delivery-items/${deliveryLocationId}`,
      JSON.stringify({}),
    );
  }
}
