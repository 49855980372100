import Konva from 'konva';

export class KonvaObject {
  context;
  node;
  transformer: Konva.Transformer;
  layer: Konva.Layer;
  stage: Konva.Stage;

  constructor(
    context,
    node,
    layer: Konva.Layer,
    stage: Konva.Stage,
    hideTransformer = false
  ) {
    let transformer;
    switch (true) {
      case node instanceof Konva.Text: {
        transformer = new Konva.Transformer({
          node: node as any,
          enabledAnchors: [
            'bottom-left',
            'bottom-right',
            'top-left',
            'top-right',
          ],
          boundBoxFunc: function(oldBox, newBox) {
            newBox.height = Math.max(
              node.getAttr('fontSize') + 10,
              newBox.height
            );
            newBox.width = Math.max(30, newBox.width);
            return newBox;
          },
        });
        break;
      }
      case node instanceof Konva.Image: {
        transformer = new Konva.Transformer({
          node: node as any,
          enabledAnchors: [
            'bottom-left',
            'bottom-right',
            'top-left',
            'top-right',
          ],
        });
        break;
      }
    }

    layer
      .getChildren(childNode => childNode.getClassName() === 'Transformer')
      .toArray()
      .forEach((childTransformer: Konva.Transformer) =>
        childTransformer.detach()
      );

    const deleteEvent = function(e: KeyboardEvent) {
      if (e.keyCode === 8 || e.keyCode === 46) {
        if (transformer._node !== undefined) {
          const currentKonvaObj = context.konvaShape.find(each => {
            if (each instanceof KonvaObject)
              return each.transformer === transformer;
          });
          context.konvaTransformerArray = context.konvaTransformerArray.filter(
            eachTransformer => eachTransformer !== transformer
          );
          context.konvaShape = context.konvaShape.filter(
            shape => shape !== currentKonvaObj
          );

          transformer._node.remove();
          transformer.remove();

          context.konvaShapeHistory.push(currentKonvaObj);
          layer
            .getChildren(
              childNode => childNode.getClassName() === 'Transformer'
            )
            .toArray()
            .forEach((eachTransformer: Konva.Transformer) =>
              eachTransformer.detach()
            );
          layer.draw();
        }
      }
    };

    stage.on('click', function(e) {
      if (!this.clickStartShape) return;

      if (e.target._id === this.clickStartShape._id) {
        layer.add(transformer);
        transformer.attachTo(e.target);
        node.setAttr('draggable', true);
        window.addEventListener('keydown', deleteEvent);
        context.clearSelection();
      } else {
        transformer.detach();
        node.setAttr('draggable', false);
        window.removeEventListener('keydown', deleteEvent);
        context.setDefaultMode();
      }

      layer.draw();
    });

    if (node instanceof Konva.Text) {
      node.on('transform', function() {
        node.setAttrs({
          width: node.width() * node.scaleX(),
          height: node.height() * node.scaleY(),
          scaleX: 1,
          scaleY: 1,
        });
      });

      if (!hideTransformer) layer.add(transformer);
      layer.draw();

      node.on('dblclick', function() {
        node.hide();
        transformer.hide();
        layer.draw();

        const { scrollTop } = stage.container();
        const areaPos = {
          x:
            stage.container().getBoundingClientRect().left +
            (<any>node).getAbsolutePosition().x,
          y:
            stage.container().getBoundingClientRect().top +
            (<any>node).getAbsolutePosition().y -
            scrollTop,
        };

        const textArea = document.createElement('textarea');
        document.body.append(textArea);
        textArea.value = node.text() === 'Type text here' ? '' : node.text();
        textArea.style.position = 'absolute';
        textArea.style.zIndex =
          document.getElementById('konvaLargeContainer') !== null
            ? '1052'
            : '1';
        textArea.style.top = `${areaPos.y}px`;
        textArea.style.left = `${areaPos.x}px`;
        textArea.style.width = `${node.width() - node.padding() * 2}px`;
        textArea.style.height = `${node.height() - node.padding() * 2 + 5}px`;
        textArea.style.fontSize = `${node.fontSize()}px`;
        textArea.style.border = 'none';
        textArea.style.padding = '0px';
        textArea.style.margin = '0px';
        textArea.style.overflow = 'hidden';
        textArea.style.background = 'none';
        textArea.style.outline = 'none';
        textArea.style.resize = 'none';
        (<any>textArea).style.lineHeight = node.lineHeight();
        textArea.style.fontFamily = node.fontFamily();
        textArea.style.transformOrigin = 'left top';
        textArea.style.textAlign = node.align();
        textArea.style.color = node.fill();

        const isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        const px = isFirefox ? 2 + Math.round(node.fontSize() / 20) : 0;
        textArea.style.transform =
          (node.rotation() ? `rotateZ(${node.rotation()}deg)` : '') +
          `translateY(-${px}px)`;
        textArea.style.height = 'auto';
        textArea.style.height = `${textArea.scrollHeight + 3}px`;
        textArea.focus();

        function removeTextArea() {
          textArea.parentNode.removeChild(textArea);
          window.removeEventListener('click', handleOutsideClick);
          node.show();
          transformer.show();
          transformer.forceUpdate();
          layer.draw();
        }

        function setTextAreaWidth(newWidth) {
          if (!newWidth)
            newWidth = (<any>node).placeholder.length * node.fontSize();

          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          if (isSafari || isFirefox) newWidth = Math.ceil(newWidth);
          const isEdge =
            (<any>document).documentMode || /Edge/.test(navigator.userAgent);
          if (isEdge) newWidth += 1;

          textArea.style.width = `${newWidth}px`;
        }

        textArea.addEventListener('keydown', function(e) {
          if ((e.keyCode === 13 && !e.shiftKey) || e.keyCode === 27) {
            if (e.keyCode === 13 && !e.shiftKey)
              node.setAttr('text', textArea.value);
            removeTextArea();
          }
        });

        textArea.addEventListener('keydown', function(e) {
          if (<Konva.Text>node) {
            let scale = node.getAbsoluteScale().x;
            setTextAreaWidth(node.width() * scale);
            textArea.style.height = 'auto';
            textArea.style.height = `${textArea.scrollHeight +
              node.fontSize()}px`;
          }
        });

        function handleOutsideClick(e) {
          if (e.target !== textArea) removeTextArea();
        }

        setTimeout(() => window.addEventListener('click', handleOutsideClick));
      });
    } else {
      if (!hideTransformer) layer.add(transformer);
      layer.draw();
    }

    if (node instanceof Konva.Image) layer.batchDraw();

    this.context = context;
    this.node = node;
    this.transformer = transformer;
    this.layer = layer;
    this.stage = stage;
  }
}
