import {
  BalancePayload,
  BalanceStatus,
} from './../../../../objects/response/BalancePayload';
import {
  CHAS_BALANCE_UNAVAILABLE,
  BALANCE_UNAVAILABLE,
} from './../../../../constants/app.constants';

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-balance-item',
  templateUrl: './balance-item.component.html',
  styleUrls: ['./balance-item.component.scss'],
})
export class BalanceItemComponent implements OnInit {
  @Input() balanceStatus: BalancePayload;

  constructor() {}

  ngOnInit() {}

  getErrorMessage() {
    // return CHAS_BALANCE_UNAVAILABLE;
    return BALANCE_UNAVAILABLE;
  }
}
