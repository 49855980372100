import { PvmTabsService } from './../../../services/pvm-tabs.service';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ConsultationFormService } from '../../../services/consultation-form.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-clinic-notes',
  templateUrl: './clinic-notes.component.html',
  styleUrls: ['./clinic-notes.component.scss'],
})
export class ClinicNotesComponent implements OnInit, OnDestroy {
  @Input() clinicNotes: FormControl;
  @Input() showBody: boolean = true;
  @Input() expanded: boolean;
  @Output() expandedEmitter: EventEmitter<boolean> = new EventEmitter();

  private componentDestroyed: Subject<void> = new Subject();

  currentTabIndex;

  constructor(
    private pvmTabs: PvmTabsService,
    private consultationFormService: ConsultationFormService
  ) {}
  ngOnInit() {
    this.currentTabIndex = this.pvmTabs.getActiveTab();

    this.pvmTabs
      .getActiveTabSwitch()
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed)
      )
      .subscribe(index => {
        this.currentTabIndex = index;
        this.consultationFormService.disableClinicNotes(
          index,
          this.clinicNotes.parent
        );
      });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
  }

  clinicNotesToggle() {
    // this.expanded = !this.expanded;
    this.expandedEmitter.emit(this.expanded);
  }

  isConsultationTab() {
    return this.pvmTabs.isConsultationTab();
  }

  isDispensingTab() {
    return this.pvmTabs.isDispensingTab();
  }

  hideBody() {
    return !this.showBody;
  }
}
