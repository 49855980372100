<div class="container-fluid px-0" [formGroup]="medicalCertificateItem">
    <!-- TITLE -->
    <div class="row">
        <div class="col">
            <label class="mb-0">Reason:</label>
            <ng-select name="reason" class='p-0 form-control form-control-sm  custom-select' bindLabel='label' bindValue='value' [items]="reasons" [clearable]=false (change)="onPurposeSelected($event)" formControlName='purpose'></ng-select>
            <errors [control]="medicalCertificateItem.get('purpose')"></errors>
        </div>
        <!-- <div class="col-md-6" [hidden]='!isOtherShown'>
            <label> </label>
            <input type="text " class="form-control form-control-sm" formControlName='otherReason' />
            <errors [control]="medicalCertificateItem.get('otherReason')"></errors>
        </div> -->
        <div class="col">
            <div class="row">
                <!-- {{ medicalCertificateItem.value | json }} -->
                <div class="form-control-group col">
                    <app-date-picker-with-icon [config]="datePickerConfig" [dateControl]="medicalCertificateItem.get('startDate')"></app-date-picker-with-icon>
                    <errors [control]="medicalCertificateItem.get('startDate')"></errors>
                </div>
                <div class="form-control-group col-2 pl-0 ">
                    <label class="mb-0 ">Days</label>
                    <input type="number" class="form-control form-control-sm" formControlName="numberOfDays" />
                    <errors [control]="medicalCertificateItem.get('numberOfDays')"></errors>
                </div>
                <div *ngIf="!isRoundNumber(medicalCertificateItem.get('numberOfDays').value)">
                    <div class="form-control-group col pl-0 ">
                        <label class="mb-0 "></label>
                        <!-- <input type="text " class="form-control form-control-sm " formControlName="halfDayOption" /> -->
                        <ng-select class='p-0 form-control form-control-sm  custom-select custom-select-small-width' bindLabel='label' bindValue='value' [dropdownPosition]="'left'" [items]="halfDayOptions" [clearable]=false formControlName='halfDayOption'></ng-select>
                    </div>
                </div>
                <div *ngIf="showDeleteBtn" class="col-md-1 pt-2 align-self-center">
                    <div class="row no-gutters">
                        <label class="mb-0 "></label>
                        <i class="icon-ellipsis-vert" [popover]="popTemplate2" placement="top" [outsideClick]="true"></i>
                    </div>
                </div>
                <ng-template #popTemplate2>
                    <div class="row">
                        <button class="btn btn-link-grey p-1 " (click)="onbtnDeleteClicked()">
                          <i class="icon-trash"></i>
                        </button>
                    </div>
                </ng-template>
            </div>

        </div>
    </div>
    <div class="row ">
        <div class="col form-control-group ">
            <label class="mb-0 ">Remarks:</label>
            <textarea type="text " class="form-control form-control-sm " formControlName='remark'></textarea>
            <errors [control]="medicalCertificateItem.get('remark')"></errors>
        </div>
        <!-- <div class="col-md-3 clearfix">
      <button class="btn btn-outline-pink float-right" (click)="onBtnAddClicked() ">+</button>
    </div> -->
    </div>
</div>
<hr>