import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './patient-detail-layout.component.html',
})
export class PatientDetailLayoutComponent {
  constructor() {}

  ngOnInit() {}
}
